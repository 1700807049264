import React from 'react'
import { Button, Input, Form, Typography } from "antd";
class EditMenuClass extends React.Component {
    handleSubmit = e => {
        e.preventDefault();
        let _this = this;
        const { selectNode } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values["key"] = selectNode.key
                values["index"] = selectNode.index
                values["parentKey"] = selectNode.parentKey
                console.log('Received values of form: ', values);
                _this.props.onSubmit(values);
                _this.props.form.resetFields();
            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { selectNode } = this.props;
        return <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onSubmit={this.handleSubmit}>
            <Form.Item
                label="区域名称"
            >
                {getFieldDecorator('title', {
                    initialValue: selectNode.title || "",
                    rules: [{
                        required: true, message: '请输入字符!',
                    }],
                    placeholder: "区域名称"
                })(<Input />)}
            </Form.Item>
            <Form.Item
                label="描述"
            >
                {getFieldDecorator('description', {
                    initialValue: selectNode.description || "",
                })(
                    // <Select
                    //     mode="tags"
                    //     placeholder="请输入后台接口"
                    // >
                    // </Select>
                    <Input />
                )}
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 6 }}>
                <Button type="primary" htmlType="submit" icon="check-circle">提交修改</Button>&nbsp;
                <Button type="primary" icon="plus-circle" onClick={() => this.props.add()}>新建根节点</Button>
                <br />
                <Typography.Text type="secondary">备注：树形图可以右键点击节点进行编辑~</Typography.Text>
            </Form.Item>
        </Form>
    }

}
const EditMenuObj = Form.create()(EditMenuClass)
export default EditMenuObj