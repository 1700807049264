import React from 'react'
import { Card, Table, Button, Icon, Input, message, Popconfirm, Tag, Modal, Row, Col, Dropdown, List } from "antd";
import axios from '@/axios/axios_utils';
import Utils from "../../../utils/utils";
import FilterForm from '@/components/FilterForm/index';
import CollapseList from '@/components/CollapseList/index'

const eLineInfo_Url = "http://" + backup_address + "/ZJNMS_V1/fw_manage/";
export default class ELineInformation extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _pageSize: 20,    //当前每页显示条数
        total: 0,
        loading: false,
        querys: null,    //查询条件
        tableData: [],
        completeTableData: [],
    };
    componentWillMount() {
        this.updata();
    }
    componentDidMount() {
        this.props.onRef(this);
    }
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {
            page_size: pageSize || _pageSize,
            page_no: pageNum || _current,
            ...querys
        };
        axios.get(eLineInfo_Url + 'srcTrans', bodyJson, (res) => {
            if (res.code === "00") {
                this.setState({
                    tableData: JSON.parse(JSON.stringify(res.data)), // 深拷贝
                    completeTableData: res.data,
                    loading: false,
                    total: res.count,
                    _current: pageNum || 1,
                    _pageSize: pageSize || _pageSize,
                })
            } else {
                message.error(res.msg);
            }
        })
    };
    // 更新列表数据
    updateData(tableData) {
        this.setState({ tableData })
    }

    render() {
        let { _current, _pageSize, querys, tableData, loading, total, completeTableData } = this.state;
        let columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                width: 80,
                ellipsis: true,
                render: (text, item, index) => {
                    return <span>{index + 1 + (_current - 1) * _pageSize}</span>
                }
            },
            {
                title: '源ip',
                align: 'center',
                dataIndex: 'src_ips',
                ellipsis: true,
                render: (text, item, index) => {
                    if (Array.isArray(text)) {
                        return <CollapseList
                            listData={text}
                            listItem={item}
                            listIndex={index}
                            tableData={tableData}
                            keyName={"src_ips"}
                            completeTableData={completeTableData}
                            updateData={this.updateData.bind(this)}
                        />
                    }
                }
            },
            {
                title: '源ip转化后ip',
                align: 'center',
                dataIndex: 'srcIp_trans_to',
                ellipsis: true,
            },
        ];
        const formList = [
            {
                label: '源ip',
                type: 'INPUT',
                field: 'src_ip',
                initialValue: '',
                placeholder: '源ip'
            },
            {
                label: '源ip转化后ip',
                type: 'INPUT',
                field: 'srcIp_trans_to',
                initialValue: '',
                placeholder: '源ip转化后ip'
            },
        ]

        return (
            <div>
                <Card>
                    <FilterForm formList={formList}
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(_pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(_pageSize, 1, {})
                        }}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>源ip转换前后的地址表</span>} style={{ marginTop: 8 }}>
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        size='small'
                        loading={loading}
                        rowKey={(text) => { return text._id }}
                        pagination={{
                            pageSize: _pageSize,
                            current: _current,
                            total: total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onChange: (page, pageSize) => {
                                this.updata(pageSize, page, querys);
                            },
                            onShowSizeChange: (current, size) => {
                                this.updata(size, 1, querys)
                            }
                        }}
                    />
                </Card>
            </div>
        )
    }
}
