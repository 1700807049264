/**
 * Created by YIMINE on 2019/1/24.
 */
import React from 'react'
import { Typography, Table, Modal, message, Button, Tag, Form, Select, Card, Icon, Collapse } from 'antd';
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import FilterForm from "@/components/FilterForm/index";
import BaseForm from "@/components/BaseForm/index";
const { Title } = Typography;
const { Panel } = Collapse;
const Option = Select.Option;
class WarnModal extends React.Component {
    state = {
        TrapsPagesData: [],
        Trapsloading: false,
        _current: 1, _pageSize: 10, Trapotal: 10,
    };
    componentWillMount() {

    }

    openMonitor(deviceIp) {
        let path = {
            pathname: '/resMonitor',
            deviceIp,
        };
        this.props.history.push("/resource");
        this.props.history.push(path);
        this.props.onCancel();
    }
    QueryTrapsPages = (pageSize, pageNum, deviceIp) => {
        this.setState({ Trapsloading: true, })
        Socket.ExecuteAction('QueryTrapsPages',
            { pageNum, pageSize, deviceIp }, "/QueryTrapsPages", (result) => {
                console.log(result);
                this.setState({
                    TrapsPagesData: result.data,
                    Trapotal: result.total,
                    _current: pageNum,
                    Trapsloading: false
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };
    DelTrap = (_id, deviceIp) => {
        const { _current, _pageSize } = this.state;
        this.setState({ Trapsloading: true })
        Socket.ExecuteAction('DeleteTrap',
            { _id }, "/DeleteTrap", () => {
                this.QueryTrapsPages(_pageSize, _current, deviceIp);
                message.success("删除成功!");
                this.setState({ Trapsloading: false })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };
    render() {
        const { WarnData } = this.props
        let { Trapsloading, _current, _pageSize, Trapotal, TrapsPagesData } = this.state
        // const trapColum = [
        //     { title: "时间", dataIndex: "addTime", width: 150 },
        //     {
        //         title: "内容", dataIndex: "data", render: (text) => {
        //             return JSON.stringify(text);
        //         }
        //     },
        //     {
        //         title: "操作", render: (text, item) => {
        //             return <Button type="danger" onClick={() => { this.DelTrap(item._id); }}>删除</Button>
        //         }
        //     },
        // ]
        return (
            <Modal
                {...this.props}
                afterClose={() => {

                }}
            >
                {WarnData.map((item, key1) => {
                    return <Card
                        title={<div className="titleCard"><Icon type="pushpin" theme="twoTone" />  设备IP：{item.deviceIp}</div>}
                        style={{ marginTop: 5 }}
                        key={key1}
                        extra={<Button type="primary" onClick={() => { this.openMonitor(item.deviceIp); }}>查看监控</Button>}
                    //actions={[<Icon type="setting" />,
                    //    <Icon type="edit" onClick={()=>{this.props.history.push('/newTask:'+item._id);}}
                    //   <Icon type="ellipsis" />]}>
                    // actions={['','','']}
                    >
                        <div>告警数量 <Tag color="#ff0000">{item.num}</Tag></div>
                        <hr style={{ border: "0.5px solid #e2e2e2" }} />
                        <div>告警详情 {item.reason && item.reason.map((infoItem, key2) => {
                            return <Tag color="#ff0000" key={key2}>{infoItem}</Tag>
                        })}</div>
                        <hr style={{ border: "0.5px solid #e2e2e2" }} />
                        <Collapse accordion key={key1} onChange={() => { this.QueryTrapsPages(_pageSize, _current, item.deviceIp); }}>
                            <Panel header="查看trap" key="1">
                                <Table
                                    key={key1}
                                    dataSource={TrapsPagesData}
                                    columns={[
                                        { title: "时间", dataIndex: "addTime", width: 150 },
                                        {
                                            title: "内容", dataIndex: "data", render: (text) => {
                                                return Utils.hidenText(JSON.stringify(text));
                                            }
                                        },
                                        {
                                            title: "操作", render: (text, item) => {
                                                return <Button type="danger" icon="delete" size="small" onClick={() => { this.DelTrap(item._id, item.deviceIp); }}>删除</Button>
                                            },
                                            // fixed: 'right',
                                        },
                                    ]}
                                    loading={Trapsloading}
                                    size="small"
                                    // expandedRowRender={record => <p style={{ margin: 0, maxWidth: 1000 }}>{JSON.stringify(record.data)}</p>}
                                    // expandedRowRender={record => record.data.map((item22) => {
                                    //     return <p style={{ textAlign: "left" }} >{item22}</p>
                                    // })}
                                    expandedRowRender={(record) => {

                                        return <table style={{ lineHeight: 2, width: "80%" }}><thead>
                                            <tr><th colSpan="2">告警详情</th></tr>
                                            {record.data.map((textAll, key00) => {
                                                //tcpMIB:1.3.6.1.4.1
                                                let textArr = textAll.split(":");
                                                return <tr key={key00}><td>{textArr[0]}</td><td>{textArr[1]}</td></tr>
                                            })}

                                        </thead></table>
                                    }}
                                    pagination={{
                                        current: _current, pageSize: _pageSize, total: Trapotal,
                                        onChange: (page, pageSize) => {
                                            //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                            console.log(page, pageSize);
                                            this.QueryTrapsPages(pageSize, page, item.deviceIp);
                                        }
                                    }}
                                />
                            </Panel>
                        </Collapse>

                    </Card>
                })}
            </Modal>
        );
    }
}

export default WarnModal;