import React, { PureComponent, memo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Card, Row, Col, Button, Typography, message } from 'antd';
import "./index.scss"
// 引入 ECharts 主模块
import echarts from 'echarts/lib/echarts';
export default class Airport extends PureComponent {
    // state = {
    //     data: {
    //         "上海": 605,
    //         "北京": 600,
    //         "广州": 604,
    //         "成都": 602,
    //         "杭州": 603,
    //         "西安": 601,
    //     }
    // }
    // componentWillReceiveProps(props) {
    //     console.log(props);
    //     // this.setState({ data: props.data })
    // }
    getOption() {
        const { type, city, data } = this.props;
        // const {  data} = this.state;
        // console.log(type, city, data);
        const option = {
            title: {
                text: type === "out" ? city + '市流出流量' : city + "市流入流量",
                textStyle: {
                    color: "#e2e2e2",
                },
            },
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    return type === "out" ? city + "->" + params.name + ":" + params.value + "Bitps" : params.name + "->" + city + ":" + params.value + "Bitps"
                }
            },
            yAxis: {
                type: 'category',
                // name: '流量情况',
                nameGap: 16,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#ddd'
                    }
                },
                axisTick: {
                    show: false,
                    lineStyle: {
                        color: '#ddd'
                    }
                },
                axisLabel: {
                    interval: 0,
                    textStyle: {
                        color: '#ddd'
                    }
                },
                data: Object.keys(data)
                // data: [
                //     '北京',
                //     "西安",
                //     "成都",
                //     "杭州",
                //     "广州",
                //     '上海',
                //     "珠海",].filter((i) => (i != city))
            },
            xAxis: {
                type: 'value',
                scale: true,
                position: 'top',
                min: 0,
                splitLine: {
                    show: false
                },
                // axisLine: {
                //     show: false
                // },
                axisLabel: {
                    margin: 1,
                    textStyle: {
                        color: '#e2e2e2'
                    }
                },
            },
            series: [                //柱状图
                {
                    zlevel: 1.5,
                    name: "柱状图",
                    type: 'bar',
                    barMaxWidth: 20,
                    symbol: 'none',
                    label: {
                        show: true,
                        formatter: '{c}Bitps'
                    },
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                offset: 0,
                                color: 'rgb(57,89,255,1)'
                            }, {
                                offset: 1,
                                color: 'rgb(46,200,207,1)'
                            }]),
                            barBorderRadius: [0, 10, 10, 0]
                        }
                    },
                    data: Object.values(data)
                    // data: [{
                    //     value: 35,
                    // itemStyle: {
                    //     color: "rgba(247, 21, 21, 1)"
                    // },
                    // label: {
                    //     show: true,
                    //     color: "#fff",//文字的颜色。
                    //     position: 'right'
                    // },
                    // tooltip: {
                    //     formatter: function (params) {
                    //         console.log(params)
                    //         return '<div>' + params.name + '<br/>带宽异常，请检查</div>'
                    //     }
                    // }
                    // }, 15, 56, 22, 32, 11, 34],
                    // markPoint: {
                    //     data: [{
                    //         type: "max"
                    //       }],
                    //     symbol: "image://image/l2-switch.png"
                    // }
                }]
        };
        return option;
    }

    render() {
        return (<ReactEcharts
            option={this.getOption()}
            className='total_echarts'
        />
        );
    }
}
