import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Divider, Popconfirm
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import { dashboard } from '../utils/api';
import FormModal from '../utils/formModal';
import BaseForm from '../utils/baseForm';
import { connect } from 'react-redux'
import "./index.scss"
import "animate.css"

function DashBoard(props) {
    const [visible, setVisible] = useState(false);
    const [total, set_total] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [, set_querys] = useState({});
    const [selectItem, setSelectItem] = useState(null);
    const [selectedRowKeys, setRowKeys] = useState([])
    const [tableData, setTabData] = useState([])
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
        },
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    useEffect(() => {//componentDidMount;
        getData();
        http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(query = {}) {
        http.get(dashboard(), query, (r) => {
            setTabData(r.data);
            // setTotal(r.data.total);
            set_querys(query);
            // set_current(page);
        })
    }
    const columns = [
        {
            title: '序号',
            ellipsis: true,
            width: 40,
            render: (text, item, c) => { return c + 1 + (_current - 1) * _pageSize }
        },
        {
            title: '名称',
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: 'url',
            dataIndex: 'url',
            ellipsis: true,
            render: (text) => { return <a href={text} target="_blank">{text}</a> }
        },
        {
            title: '描述',
            dataIndex: 'description',
            ellipsis: true,
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            ellipsis: true,
        },
        {
            title: '操作',
            render: (text, item) => {
                return <div>
                    {/* <Button type="link" size="small" icon="edit" onClick={() => { setSelectItem(item); setVisible(true) }}>编辑</Button>
                    <Divider type="vertical" />
                    <Button type="link" icon="delete" size="small" onClick={() => deleteData(item)}>删除</Button> */}
                    <a onClick={() => { setSelectItem(item); setVisible(true) }}><Icon type="edit" />&nbsp;编辑</a>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData([item.id]) }}
                        okText="是"
                        cancelText="否"
                    >
                        <a><Icon type="delete" />&nbsp;删除</a>
                    </Popconfirm>
                </div>
            }
        },
    ]
    function saveDataSource(val) {
        const { id } = selectItem;
        if (id) {//修改
            http.put(dashboard(), { id, ...val }, () => {
                getData();
                message.success("修改成功");
            })
        } else {//新增
            val.creator = sessionStorage.getItem("userName")
            http.post(dashboard(), val, () => {
                getData();
                message.success("新增成功");
            })
        }
        setVisible(false)
    }
    function deleteData(id) {
        http.delete_body(dashboard(), { id }, () => {
            getData();
            message.success("删除成功");
        })
    }
    return <div className="monitorDashBoard">
        <CustomBreadcrumb arr={["监控告警", { title: "告警大屏", to: "/monitorAlert/alertBigScreen" }, "仪表盘管理"]} />
        <Card title="仪表盘管理" extra={<div>

        </div>}>
            <div className="searchDiv">
                <BaseForm
                    onSubmit={(val) => { getData(val); }}
                    onCancel={() => { getData(); }}
                    initialValues={{}}
                    formList={[
                        { type: "input", name: "alarmGroupName", label: "仪表盘名称", placeholder: "仪表盘名称" },
                    ]}
                />
                <div><Button type="primary" icon="file-add" onClick={() => {
                    setSelectItem({})
                    setVisible(true)
                }}>添加</Button>&nbsp;
                  <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData(selectedRowKeys) }}
                        okText="是"
                        cancelText="否"
                    >
                        <Button type="danger" icon="delete" >删除</Button>
                    </Popconfirm>&nbsp;
                    <Button icon="rollback" onClick={() => {
                        props.history.push("/monitorAlert/alertBigScreen")
                    }}>返回</Button>
                </div>
            </div>
            <Table
                dataSource={tableData}
                rowSelection={rowSelection}
                columns={columns}
                size="small"
                rowKey={r => r.id}
                pagination={
                    {
                        current: _current, pageSize: _pageSize, total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            // 前端分页
                            set_pageSize(size)
                            set_current(1)
                        },
                        onChange: (page, pageSize) => {
                            // 前端分页
                            // getData({});
                            set_current(page)
                        }
                    }
                }
            />
        </Card>
        <FormModal
            title="编辑仪表盘信息"
            visible={visible}
            onCancel={() => setVisible(false)}
            onSubmit={(val) => {
                saveDataSource(val);
            }}
            width={"50%"}
            footer={null}
            initialValues={selectItem}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            destroyOnClose
            formList={[
                { type: "input", name: "name", label: "名称", required: true, placeholder: "名称" },
                { type: "input", name: "url", label: "url", required: true, placeholder: "地址" },
                // { type: "input", name: "creator", label: "创建者", required: true, placeholder: "创建者" },
                { type: "textArea", name: "description", label: "描述", placeholder: "描述" },
            ]}
        />
    </div>
}

export default connect()(DashBoard)