import React from 'react'
import { Card, Table, Icon, notification, Tag, Modal, Spin } from "antd";
import socket from "@/socket/index";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm';
export default class PwdHistory extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _pageSize: 10,    //当前每页显示条数
        loading: false,
        detailLoading: false,
        detailModal: false,
        detailData: [],
        querys: null,    //查询条件
    };
    componentWillMount() {
        this.updata();
    }
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        socket.ExecuteAction("QueryPwdHistorysPage",
            bodyJson, "/QueryPwdHistorysPage", (result) => {
                let tableData = result.data;
                tableData.map((item, index) => { item.key = index; return item });
                this.setState({
                    tableData,
                    _current: pageNum || 1,
                    total: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };
    render() {
        let { _current, _pageSize, querys, tableData, loading, total, detailModal, detailData, detailLoading } = this.state;
        const columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                render: (text) => { return <span>{text + 1}</span> }
            },
            { title: "资源名称", dataIndex: "resourceName" },
            { title: "设备IP", dataIndex: "deviceIp" },
            { title: "时间", dataIndex: "addTime" },
            { title: "输入", dataIndex: "input" },
            { title: "旧账户", dataIndex: "old" },
            { title: "新账户", dataIndex: "new" },
            { title: "结果", dataIndex: "result", render: (text) => { return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>) } },
        ];
        const FormList = [
            {
                label: "资源名称",
                type: 'INPUT',
                field: "resourceName",
                initialValue: '',
                placeholder: "资源名称"
            }
        ];

        return (
            <div>
                <CustomBreadcrumb arr={["密码变更管理", "历史记录"]} />
                <Card>
                    <FilterForm
                        formList={FormList}
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(_pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(_pageSize, 1, {})
                        }}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>历史记录列表</span>}
                    style={{ marginTop: 8 }}>
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        size="small"
                        loading={loading}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: total,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                this.updata(pageSize, page, querys);
                            }
                        }}
                    />
                </Card>
                <Modal
                    title="详情"
                    visible={detailModal}
                    onCancel={() => this.setState({ detailModal: false })}
                    onOk={() => this.setState({ detailModal: false })}
                    width="60%"
                    style={{ top: "20px" }}
                    destroyOnClose
                >
                    <Spin spinning={detailLoading}>
                        {detailData.map((item, key) => {
                            return <p key={key}>{item}</p>
                        })}
                    </Spin>
                </Modal>
            </div>
        )
    }
}
