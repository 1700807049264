import React from 'react'
import {
    Table, Button, Divider, Row, Col, Icon,
    Modal, Collapse, Tag, Descriptions,
} from "antd";
import MonacaEditor from "react-monaco-editor"
import Utils from '@/utils/utils';
import Socket from '@/socket/index'
import api from '@/config/api/deliveryTask';
import * as XLSX from 'xlsx';
import ReactEcharts from "echarts-for-react"
let utils = {}
function downloadReport(_id) {
    window.open(api.exportExcel + _id);
}
function getOption(success, error) {
    return {
        title: {
            text: '下发结果统计(总数:' + (success + error) + ')',
            x: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        // legend: {
        //     // orient: 'horizontal',
        //     left: 0,
        //     bottom: 0,
        //     data: ['失败', '成功',]
        // },
        // color: ["#3fb1e3", "#6be6c1", "#6be6c1", "#626c91", "#c4ebad", "#96dee8"],
        // color: ["#6be6c1", "#a0a7e6"],
        color: ["#6be6c1", "#f50"],
        // color: ["#62daab", "#e96c5b"],
        series: [
            {
                name: '成功率统计',
                type: 'pie',
                radius: '70%',
                label: { show: true, position: "outside", formatter: "{b}:{c}\n({d}%)", },
                center: ['50%', '60%'],
                data: [
                    { value: success, name: '成功' },
                    { value: error, name: '失败' },
                ],
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }
};
function connectSSH(item) {
    let urlTemp = Socket.getDeviceSSHUrl(item.ssh_user,
        item.ssh_pwd, item.ip);
    window.open(urlTemp, '_blank', "toolbar=yes, location=no, directories=no, status=no, menubar=yes, scrollbars=yes,"
        + "resizable=no, copyhistory=yes, width=700, height=600");
}
utils.showTaskInfo = function showTaskInfo(data) {
    const saveTask = data.task_detail || {};
    const excelData = saveTask.excel_cmd;
    const resourceData = data.execute_result;
    const success = resourceData.filter((device) => { return device.code === "00" })//执行成功的设备;
    const error = resourceData.length - success.length;
    let htmlStr = "";
    if (excelData && excelData[0]) {
        const sheetData = XLSX.utils.json_to_sheet(excelData || []);
        htmlStr = XLSX.utils.sheet_to_html(sheetData, { id: "xlslTab", editable: false, });
    }
    const colums = [
        {
            title: "设备IP地址", dataIndex: "ip", render: (a, b) => {
                return <a onClick={() => { connectSSH(b) }}><Icon type="login" />&nbsp;{a}</a>
            }
        },
        { title: "设备名称", dataIndex: "device_name" },
        {
            title: "执行结果", dataIndex: "code", render: (a) => {
                if (a === "00") {
                    return <Tag color="#87d068">成功</Tag>
                } else {
                    return <Tag color="#f50">失败</Tag>
                }
            }
        },
        { title: "设备厂商", dataIndex: "device_firm" },
        { title: "设备型号", dataIndex: "device_version" },
        { title: "设备类型", dataIndex: "device_type", render: (a) => { return Utils.Translation(a) } },
        { title: "执行时间", dataIndex: "execute_time" },
        {
            title: "详情", dataIndex: "msg", render: (a) => {
                if ((typeof a === "string") && a.length > 30) {
                    return <Button type="link" size="small" onClick={() => {
                        Modal.info({
                            title: "交互信息",
                            width: "65%",
                            style: { top: 10 },
                            okText: "关闭",
                            content: <MonacaEditor
                                theme="vs-dark"
                                value={a}
                                language="python"
                                options={{ minimap: { enabled: false }, lineNumbers: "off", scrollBeyondLastLine: false, }}
                                height="600"
                            />
                        })
                    }}>详情</Button>
                } else {
                    return a;
                }
            }
        }]
    Modal.info({
        title: <div><strong>执行结果</strong><Divider type="vertical" /><Button type="primary" icon="export"
            onClick={() => downloadReport(data._id)} >导出报表</Button></div>,
        okText: "关闭",
        width: "90%",
        style: { top: 20 },
        content: <div className="alcTaskModal" >
            <Row>
                <Col span={16}>
                    <Descriptions column={2} bordered>
                        <Descriptions.Item label={"任务名称"} span={1} >{saveTask.task_name}</Descriptions.Item>
                        <Descriptions.Item label={"任务状态"} span={1} >{saveTask.status}</Descriptions.Item>
                        <Descriptions.Item label={"任务类型"} span={1} >{saveTask.type}</Descriptions.Item>
                        <Descriptions.Item label={"任务并发"} span={1} >{saveTask.concurrent ? "是" : "否"}</Descriptions.Item>
                        <Descriptions.Item label={"定时执行"} span={1} >{saveTask.automatic ? "是" : "否"}</Descriptions.Item>
                        <Descriptions.Item label={"定时执行时间"} span={1} ><Icon type="clock-circle" />&nbsp;{saveTask.auto_execute_time}</Descriptions.Item>
                        <Descriptions.Item label={"执行过程中遇见错误终止"} span={1} >{saveTask.ignore_error ? "是" : "否"}</Descriptions.Item>
                        <Descriptions.Item label={"运行耗时"} span={1} ><Icon type="hourglass" />&nbsp;{data.consume_time}</Descriptions.Item>
                        <Descriptions.Item label={"创建用户"} span={1} ><Icon type="user" />&nbsp;{saveTask.username}</Descriptions.Item>
                        <Descriptions.Item label={"创建时间"} span={1} ><Icon type="clock-circle" />&nbsp;{saveTask.create_time}</Descriptions.Item>
                        <Descriptions.Item label={"审批人"} span={1} ><Icon type="solution" />&nbsp;{saveTask.reviewer}</Descriptions.Item>
                        <Descriptions.Item label={"审批人邮箱"} span={1} ><Icon type="mail" />&nbsp;{saveTask.mail}</Descriptions.Item>
                    </Descriptions> <p />

                </Col>
                <Col span={8}>
                    <div className="resultChart">
                        <ReactEcharts
                            option={getOption(success.length || 0, error || 0)}
                        />
                    </div>
                </Col>
            </Row>
            <Descriptions column={1} bordered className="tabDescription">
                <Descriptions.Item label={"下发指令"} span={1} >
                    <Collapse bordered={false} defaultActiveKey="cmd"><Collapse.Panel header="详情" key="cmd">
                        <div className="cmdDiv">{saveTask.commands}</div>
                    </Collapse.Panel></Collapse>
                </Descriptions.Item>
                <Descriptions.Item label={"指令参数"} span={1} >
                    {excelData && excelData[0] ? <Collapse bordered={false} defaultActiveKey="detail"><Collapse.Panel header="详情" key="detail">
                        <div className="paramDiv" dangerouslySetInnerHTML={{ __html: htmlStr }} />
                    </Collapse.Panel></Collapse> : <div className="noneDiv">无</div>}
                </Descriptions.Item>
            </Descriptions>
            <p />
            <Table
                bordered
                size="small"
                columns={colums}
                dataSource={data.execute_result}
                pagination={false}
                scroll={{ y: 350, }}
                rowKey={(r) => r.ip}
            />
        </div>
    })
}

export default utils