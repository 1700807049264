/**
 * Created by YIMINE on 2019/1/24.
 */
import React, { Fragment } from 'react'
import {
    Card, Form, Input, Modal, Spin, notification, message, Tag, Tooltip, Icon,
    Select, Checkbox, Button, Row, Col,
} from 'antd';
import './index.scss'
import Utils from "../../../utils/utils";
import socket from "../../../socket/index";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb/index";
import TestOperating from "./TestOperating";
import $ from 'jquery'
import MonacoEditor from 'react-monaco-editor';
import FlowChar from '@/components/FlowChar';
import DeviceInfo from './deviceInfo';
const { Option } = Select;
const TextArea = Input.TextArea;
let operIn = { select: [], script: [] };
let operOut = { script: [] };
// let MonacoEditorVal = '';
class newOperating extends React.Component {
    state = {
        themeStyle: 'vs',
        ScriptType: 'json',
        RadioValue: 1,
        visible: false,
        cardTitle: "新建操作",
        visible2: false,
        Text_Area: {},
        AutoObject: null,
        inputVisible: false,
        inputValue: '',
        outputVisible: false,
        pageSpinning: false,
        outputValue: '',
        Tags: [],
        slt_oper: null,
        InShowArr: { select: [], script: [] },
        OutShowArr: { inherit: [], script: [] },
        _defaultSelect: [],
        operation: {
            operationType: "",
            operationIn: { select: [], script: [] },
            operationOut: { inherit: [''], script: [] },
        },
        MonacoEditorVal: ''
    };
    componentWillMount() {
        let thisTemp = this;
        let IdData = (this.props.match.params.Id);
        if (IdData) {
            IdData = (IdData).replace(":", "");
            let strList = IdData.split('&');
            this.setState({ pageSpinning: true })
            socket.ExecuteAction('QueryOperationDetail', { _id: strList[0] }, "/queryOperation",
                (resp) => {
                    let operation = resp.operation;
                    let MonacoEditorVal = operation.operationScript;
                    if ((operation.operationType).toLowerCase() === 'cli' ||
                        (operation.operationType).toLowerCase() === 'task') {
                        MonacoEditorVal = JSON.stringify(operation.operationScript, null, 5)
                    }
                    let InShowArr = { select: [], script: [] };
                    let OutShowArr = { inherit: [], script: [] };

                    let Inselect = operation.operationIn.select ? operation.operationIn.select : [];
                    let Outinherit = operation.operationOut.inherit ? operation.operationOut.inherit : [];
                    InShowArr['select'] = Inselect;
                    OutShowArr['inherit'] = Outinherit;
                    operIn = operation.operationIn;
                    operOut = operation.operationOut;

                    let cardTitle = "编辑操作";
                    let AutoObject = operation;
                    let operationName = operation.operationName;
                    //复制功能
                    if (strList[1]) {
                        cardTitle = "复制操作";
                        AutoObject = null;
                        operationName = '';
                    }
                    thisTemp.setState({
                        operation, MonacoEditorVal, InShowArr, OutShowArr,
                        pageSpinning: false,
                        cardTitle,
                        Text_Area: operation.operationScript,
                        AutoObject,
                    });

                    thisTemp.props.form.setFieldsValue({
                        operationName,
                        operationDes: operation.operationDes,
                        tags: operation.tags,
                        // groupNames: operation.groupNames,
                        // level: operation.level + '',
                        operationType: operation.operationType,
                        firm: operation.firm || {},
                        operationScript: operation.operationScript,
                    });
                    //设置编辑框语言
                    this.setScriptType(operation.operationType);
                    thisTemp.updataScript(false);
                }, (error) => {
                    message.error('数据返回错误:' + error);
                    thisTemp.setState({ pageSpinning: false });
                });

        }

        socket.ExecuteAction('QueryTagsGroups', { tagClass: 'operation' }, "/QueryTagsGroups", (result) => {
            let GroupNames = [];
            if (result.groupNames) {
                result.groupNames.map((text) => {
                    GroupNames.push('(分组)' + text)
                });
            }
            thisTemp.setState({
                Tags: result.tags,
            });
        });
    }
    componentDidMount() {
        this.fileListener();
    }
    handleSubmit = () => {
        let { AutoObject, exit, MonacoEditorVal } = this.state;
        let thisTemp = this;
        // e.preventDefault();

        // this.updataScript(null, () => {

        thisTemp.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
                //标签分组
                // let tags = [];
                // let groupNames = [];
                // (values.tags).map((text) => {
                //     if (text.indexOf('(分组)') > -1) {
                //         groupNames.push(text.replace('(分组)', ''));
                //     } else {
                //         tags.push(text);
                //     }
                // });
                let body = values;
                body.operationScript = MonacoEditorVal;
                body.operationExit = exit;
                console.log('body', body);
                thisTemp.setState({ pageSpinning: true });
                if (!AutoObject) {
                    socket.ExecuteAction("AddOperation", body, "/newOperation", (resp) => {
                        message.success('新增成功');
                        thisTemp.props.history.goBack();
                    }, (error) => {
                        notification.open({
                            message: '新增失败',
                            description: error,
                            icon: <Icon type="frown" style={{ color: 'red' }} />,
                        });
                        thisTemp.setState({ pageSpinning: false });
                    });
                } else {
                    body._id = AutoObject._id;
                    socket.ExecuteAction("UpdateOperation", body, "/editOperation", (resp) => {
                        message.success('修改成功');
                        thisTemp.goBackPage();
                    }, (error) => {
                        notification.open({
                            message: '修改失败',
                            description: error,
                            icon: <Icon type="frown" style={{ color: 'red' }} />,
                        });
                        thisTemp.setState({ pageSpinning: false });
                    });
                }
            }
        });
        // });

    };
    check01OnChange = (e) => {
        operIn.select = e;
        let _InShowArr = this.state.InShowArr;
        _InShowArr.select = e;
        this.setState({ InShowArr: _InShowArr })
    };
    //继承上级输出
    check02OnChange = (e) => {
        operOut.inherit = e;
        let _OutShowArr = this.state.OutShowArr;
        _OutShowArr.inherit = e;
        this.setState({ OutShowArr: _OutShowArr })
    };
    handleOk = (e) => {
        this.setState({
            visible: false,
        });
    }
    handleCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    }
    updataScript = (isShow, callback) => {
        let { OutShowArr, InShowArr, Text_Area, MonacoEditorVal } = this.state;
        let body = {};
        let thisTemp = this;
        body.operationType = this.props.form.getFieldValue('operationType');
        // body.operationScript = this.props.form.getFieldValue('operationScript');
        body.operationScript = MonacoEditorVal;
        socket.ExecuteAction("GetScriptParam", body, "/GetScriptParam", (resp) => {
            operIn.script = resp.in ? resp.in : [];
            operOut.script = resp.out ? resp.out : [];
            // console.log(operOut);
            let inArr = [];
            let outArr = [];
            let exit = resp.exit ? resp.exit : [];
            (operIn.script).map((val) => {
                inArr.push(val.display);
            });
            (operOut.script).map((val) => {
                outArr.push(val.display);
            });

            let inShow = InShowArr;
            // inShow = Utils.MergeArray(inShow,inArr);
            inShow.script = inArr;
            let outShow = OutShowArr;
            // outShow = Utils.MergeArray(outShow,outArr);
            outShow.script = outArr;
            thisTemp.setState({
                InShowArr: inShow,
                pageSpinning: false,
                OutShowArr: outShow,
                exit
            });
            if (isShow) {
                message.success('更新成功');
            }
            // console.log(callback, typeof callback);
            if (callback && typeof callback === "function") {
                callback();
            }

        }, (error) => {
            notification.open({
                message: '失败',
                description: error,
                icon: <Icon type="frown" style={{ color: 'red' }} />,
            });
        });
    };
    checkTag = (Arr) => {
        Arr.map((text) => {
            if (this.state.Tags.indexOf(text) === -1) {
                Modal.confirm({
                    title: "消息提示",
                    content: "是否添加标签" + text + "到标签管理?",
                    onOk() {
                        Socket.ExecuteAction('AddTag', { tagClass: 'operation', tagName: text }, "/AddTag",
                            (result) => { message.success('标签新增成功'); }
                        );
                    }
                })
            }
        });
    };
    setValue = () => {
        //设置表单值
        this.props.form.setFieldsValue({
            operationIn: operIn,
            operationOut: operOut
        });
        this.updataScript();
        const _this = this;
        setTimeout(() => {
            _this.handleSubmit();
        }, 200)
    };
    testBegin = () => {
        let { OutShowArr, InShowArr } = this.state;
        //设置表单值
        this.props.form.setFieldsValue({
            operationIn: operIn,
            operationOut: operOut
        });
        this.updataScript();
        const _this = this;
        const { MonacoEditorVal } = this.state;
        setTimeout(function () {
            _this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let body = values;

                    body.operationScript = MonacoEditorVal;

                    body.operationExit = _this.state.exit;
                    console.log('body', body);
                    _this.setState({
                        testVisible: true,
                        slt_oper: body
                    });
                }
            });
        }, 0);


    };
    inherit = () => {
        let OutShowArr = this.state.OutShowArr;
        if (OutShowArr.indexOf('inherit:["upOut"]') === -1) {
            // OutShowArr.push('inherit:["upOut"]');
            OutShowArr.push('继承上级输出');
            this.setState({ OutShowArr });
            operOut.inherit = ["upOut"]
        }
    };
    renderIn = () => {
        let { InShowArr, } = this.state;
        // console.log(InShowArr);
        let countArr = Utils.MergeArray(InShowArr.select, InShowArr.script);
        // let countArr =InShowArr;
        // console.log(countArr);
        return countArr.map((tag, index) => {
            const isLongTag = tag.length > 20;
            const tagElem = (
                <Tag key={tag} closable={false}
                    color="#108ee9">
                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </Tag>
            );
            return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
        })
    };
    renderOut = () => {
        let { OutShowArr, } = this.state;
        let countArr = Utils.MergeArray(OutShowArr.inherit, OutShowArr.script);
        // let countArr =InShowArr;
        // console.log(countArr);
        return countArr.map((tag, index) => {
            const isLongTag = tag.length > 20;
            const tagElem = (
                <Tag key={index} closable={false}
                    color="#108ee9">
                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </Tag>
            );
            return isLongTag ? <Tooltip title={tag} key={index}>{tagElem}</Tooltip> : tagElem;
        })
    };
    setScriptType = (values) => {
        let type = 'json';
        switch (values) {
            case 'linuxShell': type = 'shell'; break;
            case 'perl': type = 'perl'; break;
            case 'python': type = 'python'; break;
            case 'nodejs': type = 'javascript'; break;
            // case 'task': type = 'task'; break;
            default: type = 'json'; break
        }
        this.setState({ ScriptType: type });
        // console.log(this.state.ScriptType);
        // console.log(values);
    };
    fileListener = () => {
        const _this = this;
        $('#file_operation').unbind();
        $('#file_operation').change(function () {
            let file = this.files[0];
            let _fileData = null;
            if (!file) { return; }
            // $(this).val("");	//清空文件控件的内容
            let _fileName = file.name;
            // $('#uploadBtn').text(file.name + " 读取中");
            //开始读取文件数据
            let reader = new FileReader();
            //读取UTF-8文件
            reader.readAsText(file, "UTF-8");
            //文件读取完毕后该函数响应
            reader.onload = function () {
                //读取完成  
                _fileData = this.result;
                try {
                    _fileData = JSON.parse(_fileData);
                } catch (e) {
                    message.error('错误', e);
                    return;
                }
                const MonacoEditorVal = JSON.stringify(_fileData, null, 5)
                _this.setState({ MonacoEditorVal });
                message.success(_fileName + "导入成功！")
            }
        });
    }
    goBackPage() {
        let bodyJson = this.props.location.bodyJson;
        let path = {
            pathname: '/operating',
            bodyJson,
        };
        this.props.history.push(path);
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { operation, InShowArr, OutShowArr, slt_oper, cardTitle, Text_Area,
            Tags, MonacoEditorVal, pageSpinning, ScriptType } = this.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 19 },
            },
        };
        return (
            <div className={"newOperatingDiv"}>
                <Spin spinning={pageSpinning}>
                    <CustomBreadcrumb arr={["自定义任务", { title: "操作管理", to: '/operating' }, cardTitle]} />
                    <Card title={<span style={{ fontWeight: 600 }}>{cardTitle}</span>}>
                        <Form onSubmit={this.handleSubmit}>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label="操作名称"
                                        {...formItemLayout}
                                    >
                                        {getFieldDecorator('operationName', {
                                            rules: [{
                                                type: 'string', message: '请输入正确字符！',
                                            }, {
                                                pattern: new RegExp(/^[^\s]+$/, "g"), message: '含有空格等非法字符！',
                                            }, {
                                                required: true, message: '请输入字符!',
                                            }],
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="操作类型"
                                        {...formItemLayout}
                                    >
                                        {getFieldDecorator('operationType', {
                                            rules: [{
                                                required: true, message: '请选择操作类型!',
                                            }],
                                        })(
                                            <Select
                                                showSearch
                                                placeholder="选择类型"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                onChange={(values) => { this.setScriptType(values) }}
                                            >
                                                <Option value="cli">cli</Option>
                                                <Option value="linuxShell">linuxShell</Option>
                                                <Option value="python">python</Option>
                                                <Option value="perl">perl</Option>
                                                <Option value="nodejs">nodejs</Option>
                                                <Option value="system">system</Option>
                                                <Option value="task">task</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label="添加标签"
                                        {...formItemLayout}
                                    >
                                        {getFieldDecorator('tags', {
                                        })(
                                            <Select
                                                mode="tags"
                                                placeholder="选择标签"
                                                onChange={this.checkTag}
                                            >
                                                {Tags.map(owner => (
                                                    <Option key={owner} value={owner}>
                                                        {owner}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="操作描述"
                                        {...formItemLayout}
                                    >
                                        {getFieldDecorator('operationDes', {
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label="输入参数"
                                        {...formItemLayout}
                                    >
                                        {getFieldDecorator('operationIn', {})(
                                            <div>
                                                <div style={{ height: 100, border: '1px solid #e2e2e2', padding: "0 3px" }}>
                                                    {this.renderIn()}
                                                </div>
                                                {/*<Button type="primary" icon='edit'*/}
                                                {/*onClick={()=>{*/}
                                                {/*this.setState({visible:true});*/}
                                                {/*}}*/}
                                                {/*>编辑</Button>*/}
                                                <Checkbox.Group style={{ width: '100%' }} value={InShowArr.select}
                                                    onChange={this.check01OnChange}>
                                                    <Checkbox value="resource" id="In_resource" >资源</Checkbox>
                                                    {/* <Checkbox value="upOut" id="In_upOut">上级输出</Checkbox> */}
                                                </Checkbox.Group>

                                            </div>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="输出参数"
                                        {...formItemLayout}
                                    >
                                        {getFieldDecorator('operationOut', {})(
                                            <div>
                                                <div style={{ minHeight: 100, border: '1px solid #e2e2e2', padding: "0 3px" }}>
                                                    {this.renderOut()}
                                                </div>
                                            </div>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                label="设备信息"
                                {...{
                                    labelCol: {
                                        xs: { span: 24 },
                                        sm: { span: 2 },
                                    },
                                    wrapperCol: {
                                        xs: { span: 24 },
                                        sm: { span: 22 },
                                    },
                                }}
                            >
                                {getFieldDecorator('firm', {})(
                                    <DeviceInfo />
                                )}
                            </Form.Item>

                            <Form.Item
                                label=" "
                                colon={false}
                                labelCol={{
                                    xs: { span: 24 },
                                    sm: { span: 2 },
                                }}
                                wrapperCol={{
                                    xs: { span: 24 },
                                    sm: { span: 22 },
                                }}
                            >

                                {getFieldDecorator('btn', {})(
                                    < div>
                                        <Button type="primary" icon="reload"
                                            onClick={() => this.updataScript(true)}
                                        >更新脚本</Button>&nbsp;
                                        <Button type="primary" icon="save"
                                            onClick={() => {
                                                Utils.download(operation.operationName + ".json", MonacoEditorVal);
                                            }}
                                        >导出</Button>&nbsp;
                                        <Button type="primary" icon="import"
                                            onClick={() => {
                                                $("#file_operation").click();
                                            }}
                                        >导入</Button>&nbsp;
                                        <span >编辑框风格：</span>
                                        <Select
                                            style={{ width: 160 }}
                                            defaultValue='vs'
                                            onChange={(themeStyle) => { this.setState({ themeStyle }); }}>
                                            <Option value="vs">Visual Studio</Option>
                                            <Option value="vs-dark">Visual Studio Dark</Option>
                                            <Option value="hc-black">High Contrast Dark</Option>
                                        </Select>
                                        <input id="file_operation" type="file" style={{ display: "none" }} />
                                    </div>
                                )}
                            </Form.Item>

                            <Form.Item
                                label="操作脚本"
                                labelCol={{
                                    xs: { span: 24 },
                                    sm: { span: 2 },
                                }}
                                wrapperCol={{
                                    xs: { span: 24 },
                                    sm: { span: 22 },
                                }}
                            >
                                {getFieldDecorator('operationScript', {})(
                                    < Fragment
                                    // style={{ border: '1px solid #e2e2e2', width: "100%", fontSize: '19px', fontWeight: '600', }}
                                    >
                                        {ScriptType === "task" ?
                                            <FlowChar onRef={(_this) => { this.child = _this }}
                                                initValue={operation.operationScript}
                                                onFlowDataChange={(data) => {
                                                    this.setState({ MonacoEditorVal: data })
                                                    // console.log(data);
                                                }}
                                            />
                                            : <MonacoEditor
                                                height="500"
                                                language={this.state.ScriptType}
                                                theme={this.state.themeStyle}
                                                // value={JSON.stringify(Text_Area, null, 5)}
                                                // value={JSON.stringify(this.state.operation.operationScript, null, 5)}
                                                value={MonacoEditorVal}
                                                options={{ selectOnLineNumbers: true }}
                                                editorDidMount={(editor, monaco) => {
                                                    // console.log('editorDidMount', editor);
                                                    editor.focus();
                                                }}
                                                onChange={(newValue, e) => {
                                                    this.setState({ MonacoEditorVal: newValue })
                                                }}
                                            />}
                                    </Fragment>
                                )}
                            </Form.Item>
                            <Form.Item
                                // label=" "
                                colon={false}
                                wrapperCol={{
                                    xs: { span: 24 },
                                    sm: { span: 24 },
                                }}
                            >
                                <div style={{ width: "100%", textAlign: "center" }}>
                                    <Button type="primary" icon="check-circle"
                                        // htmlType="submit"
                                        style={{ width: 100 }}
                                        onClick={() => { this.setValue() }}
                                    >
                                        确定
                                    </Button>
                                    <Button type="primary" icon="issues-close" ghost
                                        style={{ width: 100, marginLeft: 5 }}
                                        onClick={() => {
                                            this.testBegin();
                                        }}
                                    >
                                        测试
                                    </Button>
                                    <Button type="danger" icon="close-circle" style={{ width: 100, marginLeft: 5 }}
                                        onClick={() => { this.goBackPage(); }}>
                                        取消
                                    </Button>
                                </div>
                            </Form.Item>

                        </Form>

                        <Modal
                            title="测试"
                            visible={this.state.testVisible}
                            onCancel={() => { this.setState({ testVisible: false, }); }}
                            footer={null}
                            width="60%"
                            destroyOnClose
                        >
                            <TestOperating slt_oper={slt_oper} onRef={this.onRef}
                                closeWin={() => { this.setState({ testVisible: false }) }}
                            />
                        </Modal>
                    </Card>
                </Spin>
            </div>
        );
    }
}
const newAutoObjectFrom = Form.create()(newOperating);

export default newAutoObjectFrom;