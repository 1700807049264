import React, { useState, useEffect } from 'react'
import {
    Card, Tabs, Button, Icon, message, Divider, Alert, Checkbox, Row, Col, Cascader, Select, Radio, Modal, Input
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import Socket from "@/socket/index";
import { connect } from 'react-redux'
import TabForm from './EditTab/index'
import TopHeader from "../main/TopHeader";
import DigitalFlop from "../main/DigitalFlop";
import ScrollBoard from "../main/ScrollBoard";
import Socket_Char from "../chartSwitch/socket_Char";
import Http_Char from "../chartSwitch/http_Char";
import Area_device from "../chartSwitch/area_Char";
import Add_del_char from "../chartSwitch/add_del_char";
import DevicePort from "../devicePort/index";
import { interfaces } from "@/pages/monitorAlert/dataCollection/utils/api"
import "./index.scss"
function ScreenCfg(props) {
    const [tabData, setTabData] = useState([])
    const [modalVis, setModalVis] = useState(false)
    const [CascaderData, setCascaderData] = useState([])
    const [CascaderVale, setCascaderVale] = useState([])
    const [devCfg, setDevCfg] = useState({})
    const [deviceTheme, setDeviceTheme] = useState("dark")
    // const [titleName, setTitleName] = useState("大屏")
    const [ScreenInterval, setScreenInterval] = useState({
        statis: 10 * 60 * 1000,
        alert: 10 * 60 * 1000
    })
    const [checkKeys, setCheckKeys] = useState([])
    const [cfgData, setCfgData] = useState([
        { key: "total_list", description: "数量统计栏", type: "title", timer: 10 * 60 * 1000 },
        { key: "dev_firm", description: "设备厂商分布图", type: "column", timer: 10 * 60 * 1000 },
        { key: "dev_type", description: "设备类型分布图", type: "column", timer: 10 * 60 * 1000 },
        { key: "dev_alert", description: "设备告警信息列表", type: "column", timer: 10 * 60 * 1000 },
        { key: "dev_line", description: "设备在线/离线图", type: "column", timer: 10 * 60 * 1000 },
        { key: "dev_port_traffic", description: "设备端口流量图", type: "column", timer: 10 * 60 * 1000 },
        { key: "alert_level", description: "告警等级统计图", type: "column", timer: 10 * 60 * 1000 },
        { key: "cpu_top10", description: "设备CPU使用率前十台", type: "column", timer: 10 * 60 * 1000 },
        { key: "mem_top10", description: "设备内存使用率前十台", type: "column", timer: 10 * 60 * 1000 },
        { key: "temperature_top10", description: "设备温度前十台", type: "column", timer: 10 * 60 * 1000 },
        { key: "alert_time", description: "最近一个月告警趋势", type: "column", timer: 10 * 60 * 1000 },
        { key: "area_device", description: "区域设备统计", type: "column", timer: 10 * 60 * 1000 },
        { key: "add_del_char", description: "本月设备上下线统计图", type: "column", timer: 60000 * 5 },
    ])

    const columns = [
        { title: '序号', dataIndex: 'key', width: 100, render: (text, item, c) => { return <span key={"number" + c}>{c + 1}</span> } },
        {
            title: '图表名称', dataIndex: 'description', editable: true,
        },
        {
            title: '图表类型', dataIndex: 'type', editable: true,
        },
        { title: '刷新间隔时间', dataIndex: 'timer', editable: true, },
    ]

    const getCheckBox = () => cfgData.map((item, index) => <p span={8} key={index}><Checkbox value={item.key}>{item.description}</Checkbox></p>);

    useEffect(() => {//componentDidMount;
        getCfg();
        // getTitleName();
        getInterValCfg();
        getFirmIpPortData();
        getCascaderVal();
        // http.setDispatch(props.dispatch);
        return () => { //componentWillUnmount
        }
    }, []);
    const filterTabData = checkData => {
        let num_flag = 0  //版面占格数，头部统计栏不占格数
        const newTabData = checkData.map((item) => {
            //判断版面是否已满
            if (item === "total_list") {

            } else if (item === "dev_port_traffic" || item === "dev_alert") {
                num_flag += 2
            } else {
                num_flag += 1
            }
            return (cfgData.filter((i) => i.key === item))[0]
        })

        if (num_flag > 8) {
            message.info("版面已满！")
            return
        }
        setCheckKeys(checkData)
        setTabData(newTabData)
    }
    const saveCfg = () => {
        const userName = sessionStorage.getItem('userName');
        Socket.setConfig(userName+"_bigScreenCfg", tabData, (r) => {
            if (r.retcode == "00") {
                message.success("保存成功！")
            }
        })

    };
    const getCfg = () => {
        const userName = sessionStorage.getItem('userName');
        Socket.getConfig(userName+"_bigScreenCfg", (r) => {
            let c_keys = []
            if (r && JSON.stringify(r) != "{}") {
                setTabData(r)
                c_keys = r.map((item) => item.key)
            }
            setCheckKeys(c_keys)

        })

    };
    // const getTitleName = () => {

    //     Socket.getConfig("bigScreenTitle", (r) => {
    //         if (r && JSON.stringify(r) != "{}") {
    //             setTitleName(r)
    //         }
    //     })

    // };
    const getScreenContent = () => {
        return tabData.map((item, i) => {
            switch (item.key) {
                case "total_list": return <DigitalFlop {...item} key1={item.key} />;
                case "dev_firm": return <Socket_Char {...item} key1={item.key} unit="台" />
                case "dev_type": return <Socket_Char {...item} key1={item.key} unit="台" />
                case "dev_alert": return <ScrollBoard timer={item.timer} />
                case "dev_line": return <Socket_Char {...item} key1={item.key} unit="台" />
                case "dev_port_traffic": return <DevicePort {...item} key1={item.key} />
                case "alert_level": return <Http_Char  {...item} key1={item.key} unit="个" />
                case "cpu_top10": return <Http_Char  {...item} key1={item.key} unit="%" />
                case "mem_top10": return <Http_Char  {...item} key1={item.key} unit="%" />
                case "temperature_top10": return <Http_Char  {...item} key1={item.key} unit="℃" />
                case "alert_time": return <Http_Char  {...item} key1={item.key} unit="个" />
                case "area_device": return <Area_device  {...item} key1={item.key} />
                case "add_del_char": return <Add_del_char  {...item} key1={item.key} unit="台" />
                default: return <div className="card-item"> </div>
            }
        })
    }

    function getFirmIpPortData() {
        http.get(interfaces(), { user_id: sessionStorage.getItem("userId") }, (r) => {
            handleFrimIpPortData(r.data || {})
        })
    }
    function handleFrimIpPortData(h_Data) {
        if (!h_Data || JSON.stringify(h_Data) === "{}") {
            message.error("数据为空！")
            return;
        }
        let t_CascaderData = []
        //获取第一个数据
        for (let frim in h_Data) {
            let temp_c_data = []
            const ip_inter = h_Data[frim]
            for (let ip in ip_inter) {
                const inter_list = ip_inter[ip]
                const c_inter_data = inter_list.map((i) => ({ "label": i, "value": i }))
                let f_data_child = {
                    "label": ip,
                    "value": ip,
                    children: c_inter_data
                }
                temp_c_data.push(f_data_child)
            }
            let f_data = {
                "label": frim,
                "value": frim,
                children: temp_c_data
            }
            t_CascaderData.push(f_data)
        }
        setCascaderData(t_CascaderData)
    }
    function CascaderonChange(e) {
        setCascaderVale(e)
    }
    function saveDevCfg() {
        let newCfg = devCfg
        const userName = sessionStorage.getItem('userName');
        newCfg[userName] = {
            info: CascaderVale,
            theme: deviceTheme
        }
        Socket.setConfig("deviceScreenCfg", newCfg, (r) => {
            if (r.retcode == "00") {
                message.success("保存成功！")
            }
        })
    }
    function saveInterValCfg(e) {
        const userName = sessionStorage.getItem('userName');
        Socket.setConfig(userName+"_ScreenInterval", e, (r) => {
            if (r.retcode == "00") {
                message.success("保存成功！")
            }
        })
    }
    function savebigScreenTitle() {
        // Socket.setConfig("bigScreenTitle", titleName, (r) => {
        //     if (r.retcode == "00") {
        //         message.success("保存成功！")
        //     }
        // })
    }
    function getInterValCfg(e) {
        const userName = sessionStorage.getItem('userName');
        Socket.getConfig(userName+"_ScreenInterval", (r) => {
            if (r && JSON.stringify(r) != "{}") {
                setScreenInterval(r)
            }
        })
    }
    function getCascaderVal(e) {
        Socket.getConfig("deviceScreenCfg", (r) => {
            if (r && JSON.stringify(r) != "{}") {
                setDevCfg(r)
                const r_user = r[sessionStorage.getItem('userName')] || {}
                setCascaderVale(r_user.info || [])
                setDeviceTheme(r_user.theme || "dark")
            }
        })
    }
    return <div className="BigScreenCfg">
        <CustomBreadcrumb arr={["大屏展示", "图表配置"]} />
        <Tabs type="card">
            <Tabs.TabPane tab="自定义大屏" key="diy">
                <Alert
                    message="提示"
                    description="请自定义选择大屏展示的图形，选择后的图表会按照从上到下，从左到右顺序排列，请注意排版~"
                    type="info"
                    showIcon
                /><p />

                {/* <Row>
                    <Col span={3}></Col>
                    <Col span={18}>
                        <Row>
                            <Col span={3}><label>大屏名称：</label></Col>
                            <Col span={4}><Input value={titleName} onChange={(e) => setTitleName(e.target.value)} /></Col>
                        </Row>
                    </Col>
                </Row><Divider type="horizontal" /> */}
                <Row>
                    <Col span={3} />
                    <Col span={4}>
                        <Checkbox.Group value={checkKeys} style={{ width: '100%' }} onChange={(e) => { console.log(e); filterTabData(e) }}>
                            {getCheckBox()}
                        </Checkbox.Group>
                    </Col>
                    <Col span={14}>
                        <TabForm columns={columns} value={tabData} rowKey={(r) => r.key} onChange={(e) => { console.log(e); setTabData(e) }} />
                    </Col>
                    <Col span={3} />
                </Row>
                <Divider />
                <div style={{ textAlign: "center" }}>
                    <Button type="primary" icon="save" onClick={() => { saveCfg() }}>保存配置</Button><Divider type="vertical" />
                    <Button type="primary" icon="bar-chart" onClick={() => setModalVis(true)} >页面预览</Button><Divider type="vertical" />
                    <Button type="primary" icon="reload" onClick={() => { setCheckKeys([]); setTabData([]) }}>清空列表</Button>
                </div>
                <Divider />
            </Tabs.TabPane>
            <Tabs.TabPane tab="设备大屏" key="device"><Alert
                message="提示"
                description="请自定义选择设备大屏监控展示的设备~"
                type="info"
                showIcon
            /><p /><p />
                <p>&nbsp;&nbsp;&nbsp;&nbsp;设备大屏监控展示设备:&nbsp;
                <Cascader options={CascaderData}
                        allowClear={false}
                        value={CascaderVale}
                        onChange={(e) => { CascaderonChange(e) }}
                        placeholder="Please select"
                        style={{ width: 300 }}
                    />
                </p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;设备大屏主题:&nbsp;
                <Radio.Group value={deviceTheme} onChange={(e) => {
                        setDeviceTheme(e.target.value)
                    }} >
                        <Radio.Button value={"dark"}>深色</Radio.Button>
                        <Radio.Button value={"light"}>浅色</Radio.Button>
                    </Radio.Group>
                </p>
                <div style={{ textAlign: "center" }}>
                    <Button type="primary" icon="save" onClick={() => { saveDevCfg() }}>保存配置</Button>
                </div><Divider />
            </Tabs.TabPane>
            <Tabs.TabPane tab="更新速率" key="interval"><Alert
                message="提示"
                description="请自定义选择大屏监控图表更新速率~"
                type="info"
                showIcon
            /><p /><p />
                &nbsp;&nbsp;&nbsp;&nbsp;统计大屏图表更新速率:&nbsp;
                <Select style={{ width: 300 }} value={ScreenInterval.statis} onChange={(e) => {
                    setScreenInterval({ alert: ScreenInterval.alert, statis: e })
                }}>
                    <Select.Option value={10 * 1000}>10s</Select.Option>
                    <Select.Option value={30 * 1000}>30s</Select.Option>
                    <Select.Option value={60 * 1000}>1min</Select.Option>
                    <Select.Option value={5 * 60 * 1000}>5min</Select.Option>
                    <Select.Option value={10 * 60 * 1000}>10min</Select.Option>
                    <Select.Option value={20 * 60 * 1000}>20min</Select.Option>
                    <Select.Option value={30 * 60 * 1000}>30min</Select.Option>
                    <Select.Option value={60 * 60 * 1000}>1h</Select.Option>
                    <Select.Option value={2 * 60 * 60 * 1000}>2h</Select.Option>
                </Select>
                <p />
                &nbsp;&nbsp;&nbsp;&nbsp;告警大屏图表更新速率:&nbsp;
                <Select style={{ width: 300 }} value={ScreenInterval.alert} onChange={(e) => {
                    setScreenInterval({ statis: ScreenInterval.statis, alert: e })
                }}>
                    <Select.Option value={10 * 1000}>10s</Select.Option>
                    <Select.Option value={30 * 1000}>30s</Select.Option>
                    <Select.Option value={60 * 1000}>1min</Select.Option>
                    <Select.Option value={5 * 60 * 1000}>5min</Select.Option>
                    <Select.Option value={10 * 60 * 1000}>10min</Select.Option>
                    <Select.Option value={20 * 60 * 1000}>20min</Select.Option>
                    <Select.Option value={30 * 60 * 1000}>30min</Select.Option>
                    <Select.Option value={60 * 60 * 1000}>1h</Select.Option>
                    <Select.Option value={2 * 60 * 60 * 1000}>2h</Select.Option>
                </Select>
                <p />
                <div style={{ textAlign: "center" }}>
                    <Button type="primary" icon="save" onClick={() => { saveInterValCfg(ScreenInterval); savebigScreenTitle() }}>保存配置</Button>
                </div><Divider />
            </Tabs.TabPane>
        </Tabs>
        <Modal
            title="页面预览"
            visible={modalVis}
            onOk={() => { setModalVis(false) }}
            onCancel={() => setModalVis(false)}
            width="95%"
            style={{ top: 10 }}
            destroyOnClose
        >
            <div id="onShowDiv">
                <TopHeader />
                <div className="main-content">
                    {getScreenContent()}
                </div>
            </div>
        </Modal>
    </div>
}

export default connect()(ScreenCfg)