

import React, { Fragment } from 'react'
import {
    Card, Form, Input, Modal, Spin, notification, message, Tag, Tooltip, Icon,
    Select, Checkbox, Button, AutoComplete, Collapse
} from 'antd';

export default class DeviceInfo extends React.Component {
    state = {
        initValue: {},
    }
    componentWillReceiveProps(props) {
        const initValue = props.value;
        console.log(initValue);
        if (initValue) {
            this.setState({ initValue })
        }
    }
    onChangeData(fedid, value) {
        let temp = this.state.initValue;
        temp["" + fedid] = value;
        this.setState({ initValue: temp })
        this.props.onChange(temp);
    }
    render() {
        let { initValue } = this.state;
        return (
            <Fragment>
                <Collapse>
                    <Collapse.Panel header={"设备信息"}>
                        <table style={{ minHeight: 100, width: "100%", textAlign: 'right' }}>
                            <tbody>
                                <tr>
                                    <td>设备厂商：</td><td>
                                        {/* <Input value={initValue["deviceFirm"]} onChange={(e) => { this.onChangeData("deviceFirm", e.target.value); }} /> */}
                                        <Select showSearch
                                            value={initValue["deviceFirm"]} onChange={(val) => { this.onChangeData("deviceFirm", val); }}>
                                            {["CISCO", "H3C", "HUAWEI", "MAIPU", "RUIJIE", "TP-LINK", "ZTE", "TENDA"].map((vit) => {
                                                return <Select.Option value={vit}>{vit}</Select.Option>
                                            })}
                                        </Select>
                                        {/* <AutoComplete
                                    value={initValue["deviceFirm"]} onChange={(val) => { this.onChangeData("deviceFirm", val); }}
                                    dataSource={["CISCO", "H3C", "HUAWEI", "MAIPU", "RUIJIE", "TP-LINK", "ZTE", "TENDA"]}
                                    placeholder="选择厂商"
                                // filterOption={(inputValue, option) =>
                                //     option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                // }
                                /> */}
                                    </td>
                                    <td>设备类型：</td><td>
                                        <Input value={initValue["deviceType"]} onChange={(e) => { this.onChangeData("deviceType", e.target.value); }} />
                                    </td>

                                </tr>
                                <tr>
                                    <td>设备型号：</td><td><Input value={initValue["deviceVersion"]}
                                        onChange={(e) => { this.onChangeData("deviceVersion", e.target.value); }} /></td>
                                    <td>设备版本号：</td><td><Input value={initValue["deviceOsVersion"]}
                                        onChange={(e) => { this.onChangeData("deviceOsVersion", e.target.value); }} /></td>
                                </tr>
                            </tbody>
                        </table>

                    </Collapse.Panel>
                </Collapse>
            </Fragment>
        );
    }
}