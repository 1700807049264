import React from 'react'
import { Card, Table, message, Popconfirm, Button, notification, Icon, Tag, Tree, Modal, Input, Spin } from "antd";
import Socket from '@/socket/index'
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import FileModal from '@/components/FileModal/index';
import ExportButton from '@/components/ExportButton/index';
import SystemEsInfo from '@/axios/sysGetEs'
import Utils from '@/utils/utils';
import Axios from '@/axios/index';
import $ from 'jquery';
const TextArea = Input.TextArea
const { TreeNode, DirectoryTree } = Tree;
class FtpFile extends React.Component {
  state = {
    fileVis: false,
    spinning: true,
    fileText: "aaaaaaaabbbbbbbb",
    fileName: "XX.txt",
    treeData: [
      { title: '查询目录中,请勿操作...', key: "" },
    ],
  };
  componentWillMount() {
    const tempThis = this;
    let deviceIp = this.props.location.deviceIp;
    if (deviceIp) {
      tempThis.FTPQueryDir(deviceIp, (data) => {
        console.log(data);
        const treeData = data.data.map((item) => {
          const isLeaf = item.type === "d" ? false : true
          return { title: item.name, key: item.name, isLeaf }
        })
        tempThis.setState({
          treeData, spinning: false
        });
      });
    } else {
      this.props.history.push("/resource");
    }
  }
  onSelect = (keys, event) => {
    if (event.node.props.dataRef.isLeaf) {
      this.setState({ spinning: true });
      Socket.ExecuteAction('FTPQueryFile',
        { path: this.props.location.deviceIp + '/' + keys[0] }, "/FTPQueryFile", (res) => {
          console.log(res);
          this.setState({ fileVis: true, fileText: res.data, fileName: res.fileName, spinning: false })
        }, (error) => {
          this.setState({ spinning: false })
          notification.open({
            message: '查询失败',
            description: error,
            icon: <Icon type="frown" style={{ color: 'red' }} />,
          });
        });
    }


  };
  FTPQueryDir(_path, callback) {
    let path = _path;
    // if(path==="undefined"){
    //   path = "";
    // }
    Socket.ExecuteAction('FTPQueryDir',
      { path }, "/FTPQueryDir", (res) => {
        callback(res);
      }, (error) => {
        notification.open({
          message: '查询失败',
          description: error,
          icon: <Icon type="frown" style={{ color: 'red' }} />,
        });
      });
  }
  DowdloadFtpFile() {
    let { fileName } = this.state;
    // Axios.HTTP_POST('http://' + Socket.getAddress() + ':50034/ftpdownload', { fileName }, (resp) => { });
    const http_url = 'http://' + Socket.getAddress() + ':50034/ftpdownload?fileName=' + fileName;


    let a = document.createElement('a')


    $.get(http_url, function (data, status) {
      console.log("数据: " + data + "\n状态: " + status);
      let blob = new Blob([data])
      a.download = fileName
      a.href = URL.createObjectURL(blob)
      a.click()
      URL.revokeObjectURL(blob)
    })

    // window.open('http://' + Socket.getAddress() + ':50034/ftpdownload?fileName=' + fileName);
    // let path = _path;
    // // if(path==="undefined"){
    // //   path = "";
    // // }
    // Socket.ExecuteAction('FTPDownLoadFile',
    //   { path }, "/FTPDownLoadFile", (resAPI) => {

    //   }, (error) => {
    //     notification.open({
    //       message: '下载失败',
    //       description: error,
    //       icon: <Icon type="frown" style={{ color: 'red' }} />,
    //     });
    //   });
  }
  onLoadData = treeNode => {
    const _this = this;
    return new Promise(resolve => {
      if (treeNode.props.children) {
        resolve();
        return;
      }

      _this.FTPQueryDir(this.props.location.deviceIp + '/' + treeNode.props.eventKey, (data) => {
        console.log(data);
        treeNode.props.dataRef.children = data.data.map((item) => {
          const isLeaf = item.type === "d" ? false : true
          return { title: item.name, key: `${treeNode.props.eventKey}/${item.name}`, isLeaf }
        })
        _this.setState({
          treeData: [..._this.state.treeData],
        });
        resolve();
      });
    });
  }


  renderTreeNodes = data =>
    data.map(item => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} dataRef={item} />;
    });
  render() {
    let { _current, _pageSize, querys, FileVis, ImportVis, fileName, treeData, fileVis, fileText, spinning } = this.state;


    return (
      <div>
        <CustomBreadcrumb arr={["文件管理", "文件目录"]} />
        <Card title="文件目录" extra={<Button type="primary" icon="rollback" onClick={() => { history.go(-1) }}>返回</Button>}>
          <Spin tip="Loading..." spinning={spinning}>
            <DirectoryTree onSelect={this.onSelect} loadData={this.onLoadData}>{this.renderTreeNodes(this.state.treeData)}</DirectoryTree>
          </Spin>
        </Card>
        <Modal
          title={<div>文件内容<Button type="primary" icon="download" onClick={() => this.DowdloadFtpFile()} style={{ marginLeft: 8 }}>
            下载文件
          </Button></div>}
          visible={fileVis}
          onCancel={() => this.setState({ fileVis: false })}
          onOk={() => this.setState({ fileVis: false })}
          width={"70%"}
          style={{ top: 20 }}
        >
          <TextArea value={fileText} style={{ height: 700 }} />
          {/* <sapn  >{fileText}</sapn> */}
        </Modal>
      </div>
    )
  }
}


export default FtpFile