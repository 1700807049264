import React from 'react'
import {Card, Select, Input, Button, Form, message, notification, Icon, Row, Col, Modal, Tabs} from 'antd';
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import AxiosObj from "../../../axios";
// monitor_address 配置地址：public/flow/config.js
const backUp_Url = monitor_address;

class addSnmpModule extends React.Component{
    state = {
        rule : {
            name : 'example',
            rule : {
                alert : 'APIHighRequestLatency',
                expr : 'api_http_request_latencies_second{quantile=\\"0.5\\"} > 1',
                for : '1m',
                annotations :{
                    description :'demo description',
                }
            }
        },
    }

    //表格提交
    handleSubmit = (e) => {
        let { rule } = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                //let body = values;
                rule.name = values.group;//
                rule.rule.alert = values.alertName;
                rule.rule.expr = values.expr;
                rule.rule.for = values.for;
                rule.rule.annotations.description = values.description;
                console.log(rule);
                //POST 方法第一个参数是与axios中地址的选择
                AxiosObj.HTTP_POST('alarm','/snmpexporter/addRule',rule,(data)=>{
                    message.success('success');
                    this.props.history.goBack();
                },(error)=>{
                    message.error(error);
                });

            }
        });
    };
    onTabChange = (key, type) => {
        console.log(key, type);
        this.setState({ [type]: key });
    };
    render(){
        const { rule } =this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };

        return(
            <div>
                <CustomBreadcrumb arr={["监控告警", "告警","添加监控指标"]} />
                <Card
                    style={{ width: '100%', marginTop: 30  }}
                    title={<span style={{ fontWeight: 600 }}>添加监控指标</span>}
                    onTabChange={key => {
                        this.onTabChange(key, 'key');
                    }}
                >
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item
                            label="设备类型"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('alertName', {
                                initialValue:  "",
                                rules: [{
                                    type: 'string', message: '请输入正确字符！',
                                }, {
                                    pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                }, {
                                    required: true, message: '请输入字符!',
                                }],
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label="告警规则组"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('group', {
                                initialValue:  rule.name || "example",
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="告警表达式"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('expr', {
                                initialValue:  rule.rule.expr,
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="触发时长"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('for', {
                                initialValue:  "1m",
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="描述"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('description', {
                                initialValue:  "",
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item
                            label=" "
                            colon={false}
                            {...formItemLayout}
                        >
                            <div >
                                <Button type="primary" icon="check-circle" htmlType="submit" style={{ width: 150 }}>
                                    保存
                                </Button>
                                <Button type="danger" icon="close-circle" style={{ width: 150, marginLeft: 10 }}
                                        onClick={() => { this.props.history.goBack(); }}>
                                    取消
                                </Button>
                            </div>
                        </Form.Item>

                    </Form>
                </Card>
            </div>
        )
    }
}
export default Form.create()(addSnmpModule);