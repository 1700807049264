import React from 'react'
import {
    Card, Button, Icon, message, notification, Tabs, Table, Switch, Divider,
    Tag, Modal, Menu, Dropdown, Timeline, Collapse, Typography, Descriptions
} from "antd";
import socket from "../../../socket/index";
import Utils from "../../../utils/utils";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import FileModal from '@/components/FileModal/index';
// import ImportModal from '../operating/impInfoModal';
import ExportButton from '@/components/ExportButton/index';
import MonacoEditor from 'react-monaco-editor';
import TaskResult from '@/components/taskResult/index';
import DyHeadTable from '@/components/DyHeadTable/index';
const { Text } = Typography;
const { confirm } = Modal
export default class Task extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _pageSize: 20,    //当前每页显示条数
        loading: false,
        querys: null,    //查询条件
        taskLogData: null,    //查询条件
        FileVis: false,
        ImportVis: false,
        logVisible: false,
        fileName: "",
        selectedRowKeys: [],
        progressObj: {},
        elapsedTimeObj: {},
        visible_report: false,
        visible_port: false,
        visible_monitor: false,
        visible_param: false,
        visible_find: false,
        visible_IpConfig: false,
        visible_managePort: false,
        visible_topology: false,
        httpSrc: '',
        actType: 'all',
        TaskType: [],
        replaceVis: false,
        dataSource: [],
    };
    componentWillMount() {
        let bodyJson = this.props.location.bodyJson || {};
        this.updata(bodyJson.pageSize, bodyJson.pageNum, bodyJson.querys);
        this.onNotice();
        this.getTaskType();
    }
    onNotice() {
        socket.Notice((resp) => {
            let { progressObj, elapsedTimeObj, _pageSize, _current, querys } = this.state;
            if (progressObj.hasOwnProperty(resp.taskName)) {
                progressObj[resp.taskName] = resp.taskRate;
                if (resp.taskRate === 100) {
                    this.updata(_pageSize, _current, querys);
                }
            }
            if (elapsedTimeObj.hasOwnProperty(resp.taskName)) {
                elapsedTimeObj[resp.taskName] = resp.elapsedTime;
            }
            this.setState({
                progressObj, elapsedTimeObj
            })
        });
    }
    getTaskType() {
        socket.ExecuteAction('QueryTaskTypes', {}, "", (result) => {
            this.setState({ TaskType: result.data, });
        });
    }
    excuteTask = (taskId, taskName) => {
        const { _pageSize, _current, elapsedTimeObj, progressObj } = this.state;
        this.setState({ loading: true });
        socket.Action("ExcuteTask",
            { _id: taskId }, (result) => {
                if (result.retcode === '00') {
                    message.success('执行成功');
                    elapsedTimeObj[taskName] = "0:0:0";
                    progressObj[taskName] = 0;
                    this.setState({ elapsedTimeObj, progressObj });
                    this.updata(_pageSize, _current, {});
                } else {
                    this.setState({ loading: false });
                    notification.open({
                        message: '执行失败',
                        description: result.error,
                        icon: <Icon type="frown" style={{ color: 'red' }} />,
                    });
                }
            }, "/taskMangerExe");
    };
    TerminalTask = (taskId) => {
        const { _pageSize, _current } = this.state;
        this.setState({ loading: true });
        socket.Action("TerminalTask",
            { _id: taskId }, (result) => {
                if (result.retcode === '00') {
                    message.success('终止成功');
                    this.updata(_pageSize, _current, {});
                } else {
                    message.error('操作失败');
                    this.setState({ loading: false });
                }
            }, "/taskMangerTer");
    };
    openChar = (taskId) => {
        this.child.updataCharData(taskId);
        this.setState({ visible_monitor: true })
    };
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || { ifTemplate: true };
        console.log(bodyJson)
        let actType = bodyJson.querys.reportTags || "all";
        if (bodyJson.querys.reportTags === "all") {
            bodyJson.querys.reportTags = undefined;
            actType = "all"
        }
        socket.ExecuteAction("QueryTasksPage",
            bodyJson, "/taskQueryTask", (result) => {
                let tableData = result.tasks;
                tableData.map((item, index) => { item.key = index; return item });
                this.setState({
                    tableData,
                    querys: bodyJson.querys,
                    _current: pageNum || 1,
                    _pageSize: pageSize || _pageSize,
                    total: result.total,
                    actType,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };
    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };
    DeleteTasks = (ids) => {
        let thisTemp = this;
        socket.ExecuteAction("DeleteTask",
            { _id: ids }, "/delTask", () => {
                message.success('删除成功');
                thisTemp.updata();
                this.setState({ visible: false, selectedRowKeys: [] });
            });
    }
    cancel = () => {
        this.setState({ visible: false });
        message.error('删除取消');
    };
    handleMenuClick = (e, item) => {
        this.setState({ resItem: item });
        const { _current, _pageSize, querys, actType } = this.state
        let _this = this;
        let bodyJson = {
            pageNum: _current,      //当前页码数
            pageSize: _pageSize,    //每页显示条数
            querys,    //查询条件
        }
        switch (e.key) {
            case 'log':
                this.updataTaskLog(item._id);
                // let path1 = {
                //     pathname: `/TaskLog:${item._id}`,
                //     bodyJson,
                // };
                // this.props.history.push(path1);
                break;
            case 'edit':
                let path = {
                    pathname: `/newTask:${item._id}`,
                    bodyJson,
                    actType
                };
                this.props.history.push(path);
                break;
            case 'result':
                break;
            case 'del':
                confirm({
                    title: '消息提示',
                    content: '你确定删除该条记录吗？',
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        _this.DeleteTasks([item._id]);;
                    },
                    onCancel() { },
                });
                break;
            case 'copy':
                this.props.history.push({
                    pathname: `/newTask:${item._id}&copy`,
                    bodyJson,
                    actType
                });
                break;
        }
    }
    menuButton(resItem) {
        return <Menu onClick={(e) => this.handleMenuClick(e, resItem)}>
            {/* <Menu.Item key="log">
                <Icon type="file" />
                日志
      </Menu.Item>
            <Menu.Item key="result">
                <Icon type="diff" />
                结果
      </Menu.Item> */}
            <Menu.Item key="copy">
                <Icon type="copy" />
                复制
      </Menu.Item>
            <Menu.Item key="del">
                <Icon type="delete" />
                删除
      </Menu.Item>
        </Menu>
    }
    getInput(data) {
        console.log(data);
        let redata = [];
        for (let lab in data) {
            const value = data[lab];
            if (Array.isArray(value)) {
                redata.push(<Descriptions.Item label={lab}>{value.map((item) => {
                    return <div>{JSON.stringify(item)}</div>
                })}</Descriptions.Item>)
            } else {
                redata.push(<Descriptions.Item label={lab}>{JSON.stringify(value)}</Descriptions.Item>)
            }
        }
        // return <Descriptions.Item label={"lab"}>{"JSON.stringify(value)"}</Descriptions.Item>

        return redata
    }
    getTimeLineLog() {
        let taskLogData = this.state.taskLogData || {};
        const elemt = [];
        for (let id in taskLogData) {
            let item = taskLogData[id];
            elemt.push(<Timeline.Item >
                <p><Text strong>操作名称:</Text>{item.nodeName}</p>
                <p><Text strong>开始时间:</Text>{item.beginTime}</p>
                {/* <p><Text strong>输入参数:</Text>{JSON.stringify(item.input, null, 5)}</p>
                <p><Text strong>输出参数:</Text>{JSON.stringify(item.output, null, 5)}</p> */}
                <div>
                    <Collapse><Collapse.Panel header="输入参数"><MonacoEditor
                        height="300"
                        language="json"
                        theme="vs"
                        value={JSON.stringify(item.input, null, 5)}
                        options={{ selectOnLineNumbers: true }}
                        editorDidMount={(editor) => {
                            editor.focus();
                        }}
                    /></Collapse.Panel>

                        <Collapse.Panel header="输出参数"><MonacoEditor
                            height="300"
                            language="json"
                            theme="vs"
                            value={JSON.stringify(item.output, null, 5)}
                            options={{ selectOnLineNumbers: true }}
                            editorDidMount={(editor) => {
                                editor.focus();
                            }}
                        /></Collapse.Panel></Collapse>
                </div>
                <p><Text strong>结束时间:</Text>{item.endTime}</p>
            </Timeline.Item>)
        }
        return elemt;
    }
    updataTaskLog(_id) {
        socket.ExecuteAction("QueryTaskHistory",
            { "_id": _id }, "/QueryTaskHistory", (result) => {
                console.log(result);
                this.setState({
                    taskLogData: result.data.nodeLog,
                    logVisible: true
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    }
    importStep1(fileName) {
        socket.ExecuteAction("UploadTasks_step_1",
            { fileName }, "", (result) => {
                console.log(result);
                if (result.data && result.data.length != 0) {
                    this.setState({ FileVis: false, dataSource: result.data, replaceVis: true, fileName });
                } else {
                    this.setState({ FileVis: false });
                    this.replaceData(fileName);
                }
            });
    }
    replaceData(fileName) {
        const { dataSource, } = this.state;
        socket.ExecuteAction("UploadTasks_step_2",
            { fileName, data: dataSource }, "", (result) => {
                console.log(result);
                this.updata();
                this.setState({ replaceVis: false });
            });
    }
    mutilDelete() {
        const { selectedRowKeys } = this.state;
        const _this = this;
        if (Utils.isEmpty(selectedRowKeys)) {
            message.error("请勾选设备！");
            return;
        }
        confirm({
            title: "消息提示",
            maskClosable: true,
            onOk() { _this.DeleteTasks(selectedRowKeys) },
            content: <div>你确定删除选中操作吗？<br />该操作不可恢复</div>
        })
    }
    render() {
        let { _current, _pageSize, querys, FileVis, logVisible, tableData, TaskType, actType, replaceVis, dataSource,
            visible_monitor, visible_port, visible_IpConfig, visible_param, visible_topology, fileName,
            visible_find, visible_report, taskId } = this.state;
        let paramResult = {
            visible_report, visible_port, visible_monitor, visible_param,
            visible_find, visible_IpConfig, visible_topology, taskId,
        }
        const _this = this;
        const columns = [
            {
                title: '模板名称',
                align: 'left',
                width: 200,
                ellipsis: true,
                dataIndex: 'taskName',
                render: (text, itemTask, index) => {
                    return <a onClick={() => {
                        this.handleMenuClick({ key: "edit" }, itemTask)
                    }}>{text}</a>
                }
            }, {
                title: '状态',
                align: 'center',
                width: 100,
                dataIndex: 'taskStatus',
                render: (text) => {
                    return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>)
                }
            },
            {
                title: '类型',
                align: 'left',
                width: 100,
                dataIndex: 'reportTags',
            },
            {
                title: '标签',
                align: 'left',
                width: 200,
                dataIndex: 'tags',
                render: (text) => {
                    return Utils.hidenTag(text);
                }
            },
            {
                title: '创建时间',
                width: 200,
                dataIndex: 'createTime',
            },
            {
                title: '操作',
                align: 'center',
                dataIndex: "op",
                width: 180,
                fixed: "right",
                render: (text, itemTask) => {
                    return <div>
                        <Dropdown.Button overlay={this.menuButton(itemTask)}
                            type="primary" icon={<Icon type="down" />} size="small"
                            onClick={() => { this.handleMenuClick({ key: "edit" }, itemTask) }}
                        ><Icon type="edit" />编辑
                        </Dropdown.Button>
                    </div>
                }
            }
        ];
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys:`, selectedRowKeys, 'selectedRows: ', selectedRows);
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        return (
            <div>
                <CustomBreadcrumb arr={["自定义任务", { title: "模板管理", to: "/task" }]} />
                <Collapse defaultActiveKey={"key"}>
                    <Collapse.Panel header="筛选条件" key={"key"}>
                        <FilterForm
                            tagClass="temp"
                            filterSubmit={(query) => {
                                query.ifTemplate = true;
                                this.updata(this.state._pageSize, 1, {...querys, ...query})
                            }}
                            resetTab={() => {
                                this.updata(this.state._pageSize, 1, { ifTemplate: true, reportTags: "all" })
                            }}
                        />
                    </Collapse.Panel>
                </Collapse>
                <Card title={<span style={{ fontWeight: 600 }}>模板列表</span>}
                    style={{ marginTop: 8, }}
                    extra={
                        <div>
                            <Button type="primary" icon="import"
                                onClick={() => { this.setState({ FileVis: true }); }}>
                                导入模板
                        </Button>
                            <ExportButton APIName="DownloadTasks" selectedRowKeys={this.state.selectedRowKeys} />
                            {/* <Button type="primary" icon="import"
                                onClick={() => { this.setState({ FileVis: true }) }}>导入任务</Button><Button type="primary" icon="shop"
                                    onClick={() => { this.props.history.push('/appStore'); }}>
                                应用商店
                            </Button> */}
                            <Button type="primary" icon="edit"
                                onClick={() => { this.props.history.push('/newTask'); }}>
                                新建模板
                        </Button>
                            <Button type="danger" ghost icon="delete" onClick={() => { this.mutilDelete() }}>删除</Button>
                        </div>}>
                    <Tabs type="card" activeKey={actType} onChange={(key) => {
                        // this.setState({ querys: { reportTags: key } });
                        this.updata(_pageSize, 1, { ...querys, reportTags: key, ifTemplate: true });
                    }}>
                        <Tabs.TabPane tab={<div><Icon type="setting" />全部</div>} key="all" />
                        {TaskType.map((_type) => {
                            return <Tabs.TabPane tab={<div><Icon type="setting" />{_type.name}</div>} key={_type.name} />
                        })}
                    </Tabs>
                    <DyHeadTable
                        type="task"
                        dataSource={tableData}
                        columns={columns}
                        rowKey={(text) => { return text._id }}
                        rowSelection={rowSelection}
                        size="small"
                        loading={this.state.loading}
                        onChange={() => this.handleChange}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                this.updata(pageSize, page, querys);
                            }
                        }}
                    />
                </Card>
                {/* <div style={{ marginTop: 10 }}><StatiTask /></div> */}
                <FileModal FileVis={FileVis} title="导入模板" APIName="UploadTasks_step_1"
                    uploadCallBack={(fileName) => {
                        this.importStep1(fileName);
                    }}
                    onCancel={() => this.setState({ FileVis: false })} />
                {/* <ImportModal visible={ImportVis}
                    title="填写信息"
                    fileName={fileName}
                    tagClass={"task"}
                    APIName={"UploadTasks"}
                    callback={() => this.updata()}
                    footer={null}
                    onCancel={() => this.setState({ ImportVis: false })} /> */}
                <TaskResult
                    {...paramResult}
                    setVisible={(name) => {
                        let Obj = {};
                        Obj[name] = false;
                        this.setState(Obj);
                    }}
                    onRef={(ref) => { this.child = ref; }}
                />
                <Modal visible={logVisible}
                    title="日志"
                    footer={null}
                    width={"80%"}
                    onCancel={() => this.setState({ logVisible: false })}>
                    <div style={{ maxHeight: 800, overflowY: "auto", paddingTop: 10 }}>

                        <Timeline>
                            {this.getTimeLineLog()}
                        </Timeline>
                    </div>

                </Modal>
                <Modal visible={replaceVis}
                    title="消息提示"
                    width={"80%"}
                    onOk={() => this.replaceData(fileName)}
                    onCancel={() => this.setState({ replaceVis: false })}>

                    <div><p>列表重复数据<Divider type="vertical" />全部替换:<Switch checked
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />} onChange={(e) => {
                            const newData = dataSource.map((rec) => { rec.replace = e; return rec; });
                            console.log(newData)
                            _this.setState({ dataSource: newData })
                        }} /></p>

                        <Table
                            dataSource={dataSource || []}
                            bordered
                            size="small"
                            columns={[{
                                title: '序号', width: 100,
                                render(a, b, c) { return c + 1 }
                            }, {
                                title: '模板名称',
                                align: 'left',
                                ellipsis: true,
                                dataIndex: 'name',
                            }, {
                                title: '是否替换',
                                dataIndex: 'replace',
                                render(a, b, c) {
                                    return <Switch key={c} checked={a} checkedChildren={<Icon type="check" />}
                                        unCheckedChildren={<Icon type="close" />} onChange={(e) => {
                                            dataSource[c].replace = e;
                                            _this.setState({ dataSource })
                                        }} />
                                }
                            }]}
                            rowKey={(e) => e.name}
                            pagination={false}
                        />
                    </div>

                </Modal>

            </div>
        )
    }
}
