const formItemLayout = {
    labelCol: {
        xs: {span: 15},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 16},
        sm: {span: 14},
    },
};
export let formList = [
    {
        label: '网点名称',
        type: 'INPUT',
        field: 'netName',
        initialValue: '',
        placeholder: '网点名称'
    },
    {
        label: '设备型号',
        type: 'INPUT',
        field: 'equipmentMode',
        initialValue: '',
        placeholder: '设备型号'
    },
    {
        label: '端口',
        type: 'INPUT',
        field: 'port',
        initialValue: '',
        placeholder: '端口'
    },
    {
        label: '管理IP地址',
        type: 'INPUT',
        field: 'bIP',
        initialValue: '',
        placeholder: '管理IP地址'
    },
    {
        label: '专线号',
        type: 'INPUT',
        field: 'netLineNo',
        initialValue: '',
        placeholder: '专线号'
    },
]
