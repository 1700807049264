import React from 'react'
import {
    Card, message, Modal, Steps, Tabs, Alert
} from "antd";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import AxiosObj from '@/axios/index';
import TolopogyChart from '../Topology/index';
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import './index.scss';
const { Step } = Steps;
const { TabPane } = Tabs;
const { confirm } = Modal;


class AutoFind extends React.Component {
    state = {
        topoData: {},
        flag: {},//是否需要自动排序
        chartData: [],
        activiKey: "",
    }
    componentWillMount() {
        this.getAreaTopo();
    }
    componentDidMount() {

    }
    actionData(data) {
        let nodes = [];
        let edges = [];
        let groups = [];
        let combos = [];
        data.map((item) => {
            let tdata = Object.values(item)[0];
            if (!tdata.nodes) {//没保存过的数据
                tdata = Utils.createTopoData(tdata);
            }
            nodes = nodes.concat(tdata.nodes || []);
            edges = edges.concat(tdata.edges || []);
            groups = groups.concat(tdata.groups || []);
            combos = combos.concat(tdata.combos || []);
        });
        let flowData = {
            nodes,
            edges,
            groups,
            combos,
        };
        return flowData;
    }
    getAreaTopo() {
        const { dispatch } = this.props
        dispatch(isLoading(true));
        AxiosObj.HTTP_POST("topology", "zoneTopology", {}, (result) => {
            let topoData = {};
            let flag = {};
            result.data.map((item) => {
                // 
                let flowData = {};
                if (item.data && item.data.nodes) {//保存过的数据
                    flowData = item.data;
                    flag[item.area] = false;
                } else {
                    flag[item.area] = true;

                    flowData = this.actionData(item.topology);
                }
                topoData[item.area] = flowData;
            })
            this.setState({
                topoData, flag,
                activiKey: Object.keys(topoData)[0],
                chartData: topoData[Object.keys(topoData)[0]]
            });
            dispatch(isLoading(false));
            //初始化第一个数据
            // if (this.graph) {
            //     this.graph.read(topoData[Object.keys(topoData)[0]] || {});
            //     if (flag[0]) {
            //         this.graph.updateLayout({
            //             type: 'dagre',
            //             rankdir: 'TB', // 可选，默认为图的中心'TB'：从上至下布局'BT'：从下至上布局；'LR'：从左至右布局；'RL'：从右至左布局。 
            //             nodesep: 20, // 可选
            //             ranksep: 50, // 可选
            //             controlPoints: true, // 可选
            //         });
            //     }
            //     this.graph.fitView();
            // }
        });
    }
    saveAreaTopo(area, data, show) {
        AxiosObj.HTTP_POST("topology", "saveAreaTopology", { area, data }, (result) => {
            message.success("保存成功!");
        });
    }
    tabOnChange(key) {
        const { topoData, flag } = this.state;
        this.setState({ chartData: topoData[key], activiKey: key });


        // this.graph.updateLayout({});
        // this.graph.clear();
        // this.graph.read(topoData[key]);

        // if (Array.isArray(topoData[key]) && topoData[key] > 0) {//源数据  
        // if (flag[key]) {//源数据  
        // this.graph.updateLayout({
        //     type: 'dagre',
        //     rankdir: 'TB', // 可选，默认为图的中心'TB'：从上至下布局'BT'：从下至上布局；'LR'：从左至右布局；'RL'：从右至左布局。 
        //     nodesep: 20, // 可选
        //     ranksep: 50, // 可选
        //     controlPoints: true, // 可选
        // });
        // } else {
        // this.graph.updateLayout({});
        // }
        // //数据多的让画布内容适应视口。
        // this.graph.fitView();
    }

    render() {
        const { topoData, chartData, activiKey, flag } = this.state;
        const bodyWidth = document.getElementsByTagName("body")[0].clientWidth;
        const width = bodyWidth - bodyWidth * 0.10;
        return <div className="autoFindTolopogyDiv">
            <CustomBreadcrumb
                arr={["运维管理", { title: "网络发现", to: "/autoFind" }, "网络拓扑"]}
            />

            <div className="tolopogyCrad" id="flowCard">
                <Tabs type="card" onChange={(key) => { this.tabOnChange(key); }}>
                    {Object.keys(topoData).map((area, key) => {
                        return <TabPane tab={area} key={area}>
                            <TolopogyChart refThis={(s, graph) => {
                                // this.chartThis = s;
                                // this.graph = graph;
                            }}
                                isLayout={flag[area]}
                                id={area}
                                data={chartData} save={(data, show) => { this.saveAreaTopo(activiKey, data, show) }} onChange={() => { }} width={width} />

                        </TabPane>
                    })}
                </Tabs>
            </div>

        </div>
    }
}
export default connect()(AutoFind)  