import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Divider, Popconfirm
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import { dataSource, dataSources } from '../utils/api';
import FormModal from '../utils/formModal';
import { connect } from 'react-redux'
import BaseForm from '../utils/baseForm';
import moment from 'moment';
import "./index.scss"
import "animate.css"

function DataSource(props) {
    const [visible, setVisible] = useState(false);
    const [total, setTotal] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const [selectItem, setSelectItem] = useState(null);
    const [selectedRowKeys, setRowKeys] = useState([])
    const [tableData, setTabData] = useState([])
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
        },
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'ip',
            ellipsis: true,
            width: 80,
            render: (text, item, c) => { return c + 1 + (_current - 1) * _pageSize }
        },
        {
            title: '名称',
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: 'URL',
            dataIndex: 'url',
            ellipsis: true,
            render: (text) => {
                return <a href={text} target="_blank">{text}</a>
            }
        },
        {
            title: '用户名',
            dataIndex: 'username',
            ellipsis: true,
        },
        {
            title: '类型',
            dataIndex: 'type',
            ellipsis: true,
            render: (text,) => {
                return text === 0 ? "promethus" : "zabbix"
            }
        },
        {
            title: '操作',
            render: (text, item) => {
                return <div>
                    <a onClick={() => { setSelectItem(item); setVisible(true) }}><Icon type="edit" />&nbsp;编辑</a>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData([item.dataSourceId]) }}
                        okText="是"
                        cancelText="否"
                    >
                        <a><Icon type="delete" />&nbsp;删除</a>
                    </Popconfirm>
                </div>
            }
        },
    ]
    useEffect(() => {//componentDidMount;
        getData();
        http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(page = 1, size = _pageSize, query = {}) {
        http.get(dataSources(), { page, size, ...query }, (r) => {
            setTabData(r.data.records);
            setTotal(r.data.total);
            set_querys(query);
            set_current(page);
            set_pageSize(size);
        })
    }
    function saveDataSource(val) {
        const { dataSourceId } = selectItem;
        if (dataSourceId) {//修改
            http.put(dataSource(), { dataSourceId, ...val }, () => {
                getData(1, _pageSize, {});
                message.success("修改成功");
            })
        } else {//新增
            http.post(dataSource(), val, () => {
                getData(1, _pageSize, {});
                message.success("新增成功");
            })
        }
        setVisible(false)
    }
    function deleteData(dataSourceIds) {
        http.delete_body(dataSource(), { dataSourceIds }, () => {
            getData(1, _pageSize, {});
            message.success("删除成功");
        })
    }
    return <div className="monitorDataSource">
        <CustomBreadcrumb arr={["监控告警", "数据源配置"]} />
        <Card title="数据源配置" extra={<div>
            <Button type="primary" icon="file-add" onClick={() => {
                setSelectItem({ type: 1, url: "http://" })
                setVisible(true)
            }}>添加</Button>&nbsp;

            <Popconfirm
                title="你确定删除所选记录吗?"
                onConfirm={() => { deleteData(selectedRowKeys) }}
                okText="是"
                cancelText="否"
            ><Button type="danger" icon="delete" >删除</Button>
            </Popconfirm>
        </div>}>
            <BaseForm
                onSubmit={(val) => {
                    const rangeTime = val.rangeTime;
                    let filter = {
                        name: val.name,
                        startTime: rangeTime && moment(rangeTime[0]).format("YYYY-MM-DD HH:mm:ss"),
                        endTime: rangeTime && rangeTime[1] && moment(rangeTime[1]).format("YYYY-MM-DD HH:mm:ss")
                    }
                    getData(1, _pageSize, filter)
                }}
                onCancel={() => { getData(1, _pageSize, {}) }}
                initialValues={{}}
                formList={[
                    { type: "input", name: "name", label: "数据源名称", placeholder: "数据源名称" },
                    { type: "rangePicker", name: "rangeTime", label: "起止时间" },
                ]}
            />
            <Table
                dataSource={tableData}
                rowSelection={rowSelection}
                columns={columns}
                size="small"
                rowKey={r => r.dataSourceId}
                pagination={
                    {
                        current: _current, pageSize: _pageSize, total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            getData(1, size, _querys)
                        },
                        onChange: (page, pageSize) => { getData(page, pageSize, _querys); }
                    }
                }
            />
        </Card>
        <FormModal
            title="编辑数据源配置"
            visible={visible}
            onCancel={() => setVisible(false)}
            onSubmit={(val) => {
                saveDataSource(val);
            }}
            footer={null}
            initialValues={selectItem}
            destroyOnClose
            formList={[
                { type: "input", name: "name", label: "名称", required: true, placeholder: "数据源名称" },
                { type: "input", name: "url", label: "URL", required: true, placeholder: "数据源URL" },
                {
                    type: "select", name: "type", label: "类型", placeholder: "类型",
                    selectOptions: [{ name: "prometheus", value: 0 }, { name: "zabbix", value: 1 }]
                },
                { type: "input", name: "username", label: "用户名", placeholder: "用户名" },
                { type: "password", name: "password", label: "密码", placeholder: "密码" },
            ]}
        />
    </div>
}

export default connect()(DataSource)