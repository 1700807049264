import React from 'react';
import Axios from 'axios'
import * as XLSX from 'xlsx';

export default {
    // 导出excel
    exportFiles(url, bodyJson, fileName, callback) {
        Axios({
            method: "POST",
            url: url,
            data: bodyJson,
            responseType: 'blob'
        }).then((result) => {
            const link = document.createElement('a');
            let blob = new Blob([result.data], { type: "application/vnd.ms-excel" }); // 转化为 blob 对象 导出.xls文件
            // let blob = new Blob([result.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }); // 转化为 blob 对象  导出.xlsx文件
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = fileName; // 文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            if(callback) {
                callback();
            }
        })
    },
    exportFilesGet(url, fileName, callback) {
        Axios({
            method: "GET",
            url: url,
            responseType: 'blob'
        }).then((result) => {
            const link = document.createElement('a');
            let blob = new Blob([result.data], { type: "application/vnd.ms-excel" }); // 转化为 blob 对象 导出.xls文件
            // let blob = new Blob([result.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }); // 转化为 blob 对象  导出.xlsx文件
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = fileName; // 文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            if(callback) {
                callback();
            }
        })
    },
    // 导入excel
    importFiles(file, callback, headerChange) {
        // file：上传的文件   headerChange：表头替换  callback：回调函数
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file.file);
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = {};
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    let tempData = [];
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 修改表头
                        if(headerChange) {
                            let sheets = workbook.Sheets[sheet];
                            let wss = XLSX.utils.decode_range(sheets["!ref"])
                            for (let c = wss.s.c; c <= wss.e.c; c++) {
                                let header = XLSX.utils.encode_col(c) + '1'
                                // sheets[header].w = DumbTerminalUtils.replaceSheetHeader(sheets[header].w)
                                sheets[header].w = headerChange(sheets[header].w)
                            }
                        }
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据    {defval: ""}没有值的内容传空字符串
                        data[sheet] = tempData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet], { defval: '' }));
                    }
                }
                const excelData = data;
                callback(excelData);
                // console.log(excelData);
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                console.log(e)
                message.error('上传失败！');
            }
        }
    }
}