/**
 * Created by YIMINE on 2019/4/25.
 */
import React, { Fragment } from 'react'
import { Input, Select, Form, Checkbox, DatePicker, Button, Tag, Col } from 'antd'
import GroupElemt from "./groupElemt";
import Upload from './upload';
import Utils from "@/utils/utils";
import './index.scss';
const FormItem = Form.Item;
const Option = Select.Option;
import TaskElemt from "./taskElemt";
import { Row } from 'antd/es/grid';
class ScriptModal extends React.Component {
    state = {
        scriptSel: this.props.initScript || {}
    }


    componentWillMount() {
        if (this.props.getThis) {
            this.props.getThis(this);
        }

    }


    handleSubmit = () => {
        // this.props.handleSubmit(fieldsValue);
    };
    InScriptMap = () => {
        let _InScript = this.props.InScript || [];
        if (Utils.isEmpty(_InScript)) {
            _InScript = [];
        }
        let initScript = this.props.initScript ? this.props.initScript : {};
        // console.log(_InScript);
        return _InScript.map((item, key) => {
            // console.log(item);
            return (
                <Row key={key}>
                    <div className="formRightDiv">
                        <Col span={6}>
                            <label>{item.display}</label>
                        </Col>
                        <Col span={18} style={{ borderLeft: "1px solid #333333", paddingLeft: "5px" }}>
                            {item.type === 'input' && <Input
                                defaultValue={initScript[item.name]}
                                onChange={(e) => {
                                    let obj = this.state.scriptSel; obj[item.name] = e.target.value;
                                    this.setState({ scriptSel: obj })
                                }}
                                className="width300" />}
                            {item.type === 'textarea' && <Input.TextArea
                                style={{ height: 200, width: 500 }}
                                defaultValue={(typeof initScript[item.name]) === "object" ? JSON.stringify(initScript[item.name], null, 5) : initScript[item.name]}
                                onChange={(e) => {
                                    let val = e.target.value;
                                    try { val = JSON.parse(val); } catch (e) { }
                                    let obj = this.state.scriptSel; obj[item.name] = val;
                                    this.setState({ scriptSel: obj })
                                }}
                                className="width300" />}
                            {item.type === 'select' &&
                                <Select
                                    getPopupContainer={() => document.getElementById('tabContent')}
                                    defaultValue={initScript[item.name]}
                                    className="width300"
                                    onChange={(value) => {
                                        let obj = this.state.scriptSel; obj[item.name] = value;
                                        this.setState({ scriptSel: obj })
                                    }}>
                                    {item.option.map((itemOption) => {
                                        return <Select.Option key={itemOption} value={itemOption}>
                                            {itemOption}
                                        </Select.Option>
                                    })}
                                </Select>}
                            {item.type === 'upload' &&
                                <Upload value={initScript[item.name] || []} fileName={initScript["fileName"] || null}
                                    onChange={(newData) => {
                                        let obj = this.state.scriptSel;
                                        obj[item.name] = newData.data;
                                        obj["fileName"] = newData.name;
                                        this.setState({ scriptSel: obj })
                                    }} />}
                            {item.type === 'group' && <GroupElemt
                                defaultValue={initScript[item.name]}
                                onDataChange={(newData) => {
                                    let obj = this.state.scriptSel;
                                    obj[item.name] = newData;
                                    this.setState({ scriptSel: obj })
                                }}
                                group={item.group} addFlag={item.addFlag} taskFlag={item.taskFlag} />}
                            {item.type === 'task' && <TaskElemt
                                defaultValue={initScript[item.name]}
                                onDataChange={(newData) => {
                                    let obj = this.state.scriptSel;
                                    obj[item.name] = newData;
                                    this.setState({ scriptSel: obj })
                                }}
                            />}
                        </Col>
                    </div>
                </Row>
            )
        })
    };
    InScriptLabel = () => {
        let _InScript = this.props.InScript || [];
        let initScript = this.props.initScript ? this.props.initScript : {};

        return _InScript.map((item, key) => {
            let initData = initScript[item.name] || [];
            return (
                <Row key={key}>
                    <div className="formRightDiv">
                        <Col span={6}>
                            <label>{item.display}</label>
                        </Col>
                        <Col span={18} style={{ borderLeft: "1px solid #333333", paddingLeft: "5px" }}>
                            {item.type != "upload" ?
                                (item.type != "group" ? <label>{initData}</label> :
                                    <label>{initData.map((item_init, key_init) => {
                                        let addData = "";

                                        item.group.map((groupItem, kky) => {
                                            if (groupItem["type"] === "label") {
                                                addData = addData + groupItem["display"]
                                            } else {
                                                addData = addData + item_init[groupItem.name]
                                            }
                                        })

                                        // for (let i in item_init) {
                                        //     if (i != "key") {
                                        //         addData = addData + item_init[i]
                                        //         console.log(addData);
                                        //     }
                                        // }
                                        return <p key={key_init}>{addData}</p>;
                                    })}</label>)
                                : <label />
                            }
                        </Col>
                    </div>
                </Row>
            )
        })
    };
    OutScriptMap = () => {
        let exit = this.props.operationExit;
        let _OutScript = this.props.OutScript;
        if (Utils.isEmpty(_OutScript)) { _OutScript = []; }
        if (Utils.isEmpty(exit)) { exit = []; }
        return (
            <Fragment>
                <FormItem label="输出参数">
                    <div style={{ fontWeight: 600 }}>
                        {_OutScript.map((item, key) => {
                            // console.log(item);
                            // return <span key={key}>{item.display + '、'}</span>
                            // return item.display + '、' + ' '
                            return <Tag key={key} color="blue" >{item.display}</Tag>
                        })}
                    </div>
                </FormItem>
                <FormItem label="出口数据">
                    <div style={{ fontWeight: 600 }}>
                        {exit ? exit.map((item, key) => {
                            // return item + '、' + ' '
                            return <Tag key={key} color="blue" >{item}</Tag>
                        }) : ''}
                    </div>
                </FormItem>
            </Fragment>
        )
    };
    render() {
        const { isOut, isLabel } = this.props;

        return (
            <Form id="tabContent" style={{ textAlign: "left" }}>
                <FormItem
                    label="输入参数"
                    className="inputFormDiv"
                >
                    {isLabel ? this.InScriptLabel() : this.InScriptMap()}
                </FormItem>

                {isOut ? this.OutScriptMap() : <FormItem></FormItem>}
            </Form>
        )
    }
}
export default (ScriptModal);