/**
 * Created by YIMINE on 2019/5/22.
 */
import React from 'react'
import DataSet from "@antv/data-set";
import {
    G2,
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Label,
    Legend,
    View,
    Guide,
    Shape,
    Facet,
    Util
} from "bizcharts";
// const chartData= [
//     { name: "CPU", "12:30": 10, "12:35": 30, "12:40": 50, "12:45": 30, "12:50": 20 },
//     { name: "内存", "12:30": 20, "12:35": 30, "12:40": 50, "12:45": 10, "12:50": 20 },
//     { name: "硬盘", "12:30": 30, "12:35": 50, "12:40": 60, "12:45": 20, "12:50": 10 },
//     { name: "流量-vlan1", "12:30": 40, "12:35": 30, "12:40": 50, "12:45": 20, "12:50": 20 },
//     { name: "流量-vlan2", "12:30": 50, "12:35": 70, "12:40": 50, "12:45": 40, "12:50": 20 }
// ]
export default class OverlapHis extends React.Component {
    state = {
    };
    componentWillMount() {
        this.createChar(this.props.chartData);
    }
    componentWillReceiveProps(newData) {
        this.createChar(newData.chartData);
    }

    createChar = (data) => {
        console.log(data)
        if (!data) return;
        const ds3 = new DataSet();
        const dvSet = ds3.createView().source(data);
        let newArr = [];
        for (let filed in data[0]) {
            // console.log(filed);
            if (filed != 'name') {
                newArr.push(filed);
            }
        }
        dvSet.transform({
            type: "fold",
            fields: newArr,
            // 展开字段集
            key: "时间",
            // key字段
            value: "百分比" // value字段
        });
        this.setState({ dvSet });
    };

    render() {
        let { dvSet, } = this.state;
        let { height } = this.props;
        return (
            <Chart height={height} data={dvSet}
                // padding={[10, 20, 80, 20]}
                // style={{border:'1px solid red'}}
                forceFit>

                <Axis name="时间" />
                <Axis name="百分比" />
                <Legend
                    position="bottom"
                    offsetY={-10}
                    // offsetX={-50}
                    height={200}
                />
                <Tooltip
                    crosshairs={{
                        type: "y"
                    }}
                />
                <Geom
                    type="interval"
                    position="时间*百分比"
                    // color={["name",['#3aa1ff','#ff9900','#ff5722']]}
                    color={["name", ['#3aa1ff', '#ff9900', '#ff5722']]}
                    adjust={[
                        {
                            type: "dodge",
                            marginRatio: 1 / 32
                        }
                    ]}
                />
            </Chart>
        );
    }
}