import React from 'react'
import {
    Card, Tabs,
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import { connect } from 'react-redux'
import AlertStra from "./alert"
import ShieldStra from "./shield"
import "./index.scss"
import "animate.css"
function Strategy(props) {
    return <div className="monitorStrategy">
        <CustomBreadcrumb arr={["监控告警", "策略管理"]} />
        <Card>
            <Tabs>
                <Tabs.TabPane tab="告警策略" key="alert">
                    <AlertStra {...props} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="屏蔽策略" key="shield">
                    <ShieldStra />
                </Tabs.TabPane>
            </Tabs>
        </Card>
    </div>
}

export default connect()(Strategy)