import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Divider, Popconfirm, Tag
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import { scrape_job, scrape_jobs } from '../utils/api';
import FormModal from '../../utils/formModal';
import { connect } from 'react-redux'
import BaseForm from '../../utils/baseForm';
import moment from 'moment';
import "./index.scss"
import "animate.css"
function SnmpJob(props) {
    const [total, setTotal] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const [selectItem, setSelectItem] = useState(null);
    const [selectedRowKeys, setRowKeys] = useState([])
    const [tableData, setTabData] = useState([])
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
        },
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'ip',
            ellipsis: true,
            width: 50,
            render: (text, item, c) => { return c + 1 + (_current - 1) * _pageSize }
        },
        {
            title: '采集任务名',
            dataIndex: 'job_name',
            ellipsis: true,
        },
        {
            title: '任务类型',
            dataIndex: 'module_status',
            ellipsis: true,
            render: (text, item, c) => { return text === 0 ? <Tag color='geekblue'>SNMP采集</Tag> : <Tag color='blue'>其他任务</Tag> }
        },
        {
            title: '采集频率',
            dataIndex: 'scrape_interval',
            ellipsis: true,
        },
        {
            title: '采集超时',
            dataIndex: 'scrape_timeout',
            ellipsis: true,
        },
        {
            title: '路径',
            dataIndex: 'metrics_path',
            ellipsis: true,
        },
        {
            title: 'scheme',
            dataIndex: 'scheme',
            ellipsis: true,
        },
        {
            title: '操作',
            width: 120,
            render: (text, item) => {
                return <div>
                    <a onClick={() => {
                        sessionStorage.setItem("job_name", item.job_name);
                        props.history.push("/monitorAlert/dataCollection/detaiJob")
                    }}><Icon type="edit" />&nbsp;编辑</a>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData([item.job_name]) }}
                        okText="是"
                        cancelText="否"
                    >
                        <a><Icon type="delete" />&nbsp;删除</a>
                    </Popconfirm>
                </div>
            }
        },
    ]
    useEffect(() => {//componentDidMount;
        getData();
        http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(page = 1, size = _pageSize, query = {}) {
        http.get(scrape_jobs(), { page, size, ...query }, (r) => {
            setTabData(r.data.records);
            setTotal(r.data.total);
            set_querys(query);
            set_current(page);
            set_pageSize(size);
        })
    }
    function deleteData(job_names) {
        if (!job_names[0]) {
            message.error("请选择模块!");
            return;
        }
        http.delete_body(scrape_job(), { job_names }, () => {
            getData(1, _pageSize, {});
            message.success("删除成功");
        })

    }
    return <div className="monitorSnmpJob">
        <CustomBreadcrumb arr={["监控告警", "数据采集", "采集任务"]} />
        <Card title="采集任务" extra={<div>
            <Button type="primary" icon="file-add" onClick={() => {
                sessionStorage.removeItem("job_name");
                props.history.push("/monitorAlert/dataCollection/detaiJob")
            }}>添加</Button>&nbsp;
            <Popconfirm
                title="你确定删除所选记录吗?"
                onConfirm={() => { deleteData(selectedRowKeys) }}
                okText="是"
                cancelText="否"
            ><Button type="danger" icon="delete" >删除</Button>
            </Popconfirm>
        </div>}>
            {/* <BaseForm
                onSubmit={(val) => {
                    getData(1, _pageSize, val)
                }}
                onCancel={() => { getData(1, _pageSize, {}) }}
                initialValues={{}}
                formList={[
                    { type: "input", name: "job_name", label: "任务名称", placeholder: "任务名称" },
                ]}
            /> */}
            <Table
                dataSource={tableData}
                rowSelection={rowSelection}
                columns={columns}
                size="small"
                rowKey={r => r.job_name}
                pagination={
                    {
                        current: _current, pageSize: _pageSize, total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            getData(1, size, _querys)
                        },
                        onChange: (page, pageSize) => { getData(page, pageSize, _querys); }
                    }
                }
            />
        </Card>
    </div>
}

export default connect()(SnmpJob)