import React from 'react'
import axios from '@/axios/axios_utils';
import Axios from 'axios'
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import DyHeadTable from '@/components/DyHeadTable/index';
import FilterForm from '@/components/FilterForm/index';
import CascOrgain from '@/components/CascOrgain/index';
import Utils from "@/utils/utils";
import FilesUtils from "@/utils/FilesUtils";
import DumbTerminalUtils from "@/utils/DumbTerminalUtils";
import PortCheckConfig from './portCheckConfig';
import RecentlyDeleteDumb from './recentlyDeleteDumb';
import DumbTerminalType from './dumbTerminalType';
import './index.scss'

import { Table, Collapse, Card, Button, message, Modal, Icon, Dropdown, Menu, Row, Col, Upload, Tooltip, Tag, Spin, Badge, Radio, Typography, Input, Tabs, Alert } from "antd";
import moment from 'moment';
const { Dragger } = Upload;
const { TabPane } = Tabs;
const dumbTerminal_Url = "http://" + backup_address + "/ZJNMS_V1/dumb_termial/";
const versionName = sys_config.versionName;

class DumbTerminal extends React.Component {
    state = {
        visible: false,
        tableData: [], // 表格数据
        current_page: 1,  // 当前页
        pageSize: 20,
        total: 0,
        loading: false,
        query: {},
        selectedRowKeys: [],
        selectedData: [],
        excelData: {},
        fileList: [],
        description: "",
        flagOnce: false,
        fold: false,
        treeDataIds: [],
        treeData: [],
    }

    componentWillMount() {
    //    this.initData();
    }
    componentWillUnmount() {
        this.clearTimer();
    }
    clearTimer() {
        if (this.timer != null) {
            clearInterval(this.timer);
        }
    }
    timerControl() {
        let { current_page, pageSize, query } = this.state;
        let _this = this;
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
            _this.getTableData(current_page, pageSize, query, 'noLoading')
        }, 5000)
    }
    initData() {
        let bodyJson = this.props.location.bodyJson
        if (bodyJson) {
            this.getTableData(bodyJson.page_no, bodyJson.page_size, bodyJson.querys);
        } else {
            this.getTableData();
        }
        this.timerControl();
    }
    handleMenuClick = (e, item) => {
        this.setState({ resItem: item });
        let _this = this;
        const { current_page, pageSize, querys } = this.state
        let bodyJson = {
            page_no: current_page,      //当前页码数
            page_size: pageSize,    //每页显示条数
            querys,    //查询条件
        }
        switch (e.key) {
            case 'edit':
                this.props.history.push({
                    pathname: `/newTerminal`,
                    bodyJson,
                    item,
                });
                break;
            case 'del':
                Modal.confirm({
                    title: '消息提示',
                    content: '你确定删除该设备吗？',
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        _this.deleteDumbTerminal([item._id]);
                    },
                });
                break;
            case "check": this.accessCheck(item); break;
            case "bind": this.bindUnbindDumbTerminal(item, 'bind'); break;
            case "unbind": this.bindUnbindDumbTerminal(item, 'unbind'); break;
            case "bindRecord":
                this.props.history.push({
                    pathname: `/dumbTerminal/bindDumbRecord`,
                    bodyJson,
                    item,
                    type: 'single'
                });
                break;
        }
    }
    deleteDumbTerminal(id) {
        let deleteTerminal = {};
        deleteTerminal.ids = id;
        if (id.length === 0) {
            message.error("请选择需要删除的哑终端！")
        } else {
            axios.delete_body(dumbTerminal_Url + "terminal", deleteTerminal, (res) => {
                if (res.code === "00") {
                    message.success("删除成功");
                    this.setState({ selectedRowKeys: [], selectedData: [] });
                    this.getTableData();
                } else {
                    message.error("删除失败")
                }
            })
        }
    }
    // 绑定解绑哑终端
    bindUnbindDumbTerminal(item, type) {
        let { current_page, pageSize, query } = this.state;
        let _this = this;
        let bodyJson = {
            bind_action: type,
            switch_ip: item.switch_ip,
            switch_port: item.switch_port,
            ip: item.ip,
            mac_address: item.mac_address,
            _id: item._id,
            operator: sessionStorage.getItem("userName"),
            userId: sessionStorage.getItem("userId")
        }
        if (this.noEmpty(item)) {
            return
        } else {
            Modal.confirm({
                title: '消息提示',
                content: <div>确定对接入交换机
                    <span style={{ fontWeight: 'bold' }}> {item.switch_ip} </span>的端口
                    <span style={{ fontWeight: 'bold' }}> {item.switch_port} </span>
                    <span style={{ fontWeight: 'bold' }}> {type === 'bind' ? '绑定' : '解绑'} </span>ip为
                    <span style={{ fontWeight: 'bold' }}> {item.ip} </span>、mac为
                    <span style={{ fontWeight: 'bold' }}> {item.mac_address} </span>的哑终端吗？
                    {type === 'bind' ? <p style={{ marginTop: 10 }}>
                        描述信息：<Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} allowClear placeholder="请输入描述信息" style={{ marginTop: 5 }}
                            onChange={(e) => { this.setState({ description: e.target.value }) }} />
                    </p> : ''}
                </div>,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    if (type === 'bind') {
                        bodyJson = { ...bodyJson, description: _this.state.description }
                    }
                    setTimeout(() => {
                        _this.getTableData(current_page, pageSize, query, 'noLoading');
                    }, 500)
                    _this.timerControl();
                    Axios({ method: "POST", url: dumbTerminal_Url + "bindDumbTerminal", data: bodyJson, }).then((result) => {
                        if (result.data.code === "00") {
                            _this.getTableData()
                            message.success("操作成功");
                            _this.clearTimer();
                        } else {
                            message.error(result.data.msg);
                        }
                    })
                },
            });
        }
    }
    noEmpty(item) {
        if (!item.switch_ip) {
            message.error("哑终端 " + item.name + " 的接入交换机不能为空");
            return true;
        }
        if (!item.switch_port) {
            message.error("哑终端 " + item.name + " 的接入端口不能为空");
            return true;
        }
        if (!item.ip) {
            message.error("哑终端 " + item.name + " 的哑终端IP不能为空");
            return true;
        }
        if (!item.mac_address) {
            message.error("哑终端 " + item.name + " 的MAC地址不能为空");
            return true;
        }
        return false;
    }
    menuButton(resItem) {
        return (
            <Menu onClick={(e) => this.handleMenuClick(e, resItem)}>
                <Menu.Item key="check">
                    <Icon type="file-search" />接入检查
                </Menu.Item>
                {versionName === "pinganNanJing" ? '' : <Menu.Item key="bind">
                    <Icon type="link" />绑定
                </Menu.Item>}
                {versionName === "pinganNanJing" ? '' : <Menu.Item key="unbind">
                    <Icon type="disconnect" />解绑
                </Menu.Item>}
                {versionName === "pinganNanJing" ? '' : <Menu.Item key="bindRecord">
                    <Icon type="profile" />绑定记录
                </Menu.Item>}
                <Menu.Item key="del">
                    <Icon type="delete" />删除
                </Menu.Item>
            </Menu>
        )
    }
    getTableData(page, page_size, querys, loadingFlag) {
        let flagOnce = false;
        if (loadingFlag !== "noLoading") {
            this.setState({ loading: true, });
        }
        const { current_page, pageSize, query, treeDataIds } = this.state;
        let query2 = querys || query;
        let bodyJson = {
            page_no: page || current_page,
            page_size: page_size || pageSize,
            dep_ids: treeDataIds.join(","),
            ...query2
        };
        // console.log(bodyJson)
        axios.get(dumbTerminal_Url + "terminal", bodyJson, (result) => {
            if (result.code === "00") {
                if (result.count !== 0 && result.data.length === 0) {
                    let pageNum = result.count % pageSize > 0 ? parseInt(result.count / pageSize) + 1 : parseInt(result.count / pageSize);
                    this.getTableData(pageNum, page_size, querys)
                } else {
                    result.data = result.data.map(item => { // 加上开放端口检查状态，默认为false
                        return {
                            ...item,
                            port_check_status: false,
                        }
                    })
                    if (result.is_updating) {
                        flagOnce = true
                    }
                    this.setState({
                        tableData: result.data,
                        total: result.count,
                        current_page: page || current_page || 1,
                        loading: false,
                        query: query2,
                    })
                }
                if (!flagOnce) {
                    this.clearTimer();
                }
            } else {
                message.error("查询失败");
                this.setState({ loading: false })
            }
        })
    }
    importFiles(file) {
        if (Utils.isEmpty(file.fileList)) {
            return;
        }
        let _this = this;
        let fileList = [file.file];
        FilesUtils.importFiles(file, (excelData) => {
            // 数据更新
            _this.setState({ excelData, fileList });
        }, (headerName) => {
            // 表头替换
            return DumbTerminalUtils.replaceSheetHeader(headerName)
        })
    }
    updateBindStatus() {
        let { selectedData, tableData, current_page, pageSize, query } = this.state;
        let bindTaskData = [];
        let loadingArr = []
        if (selectedData.length !== 0) {
            selectedData.map((item, index) => {
                if (this.noEmpty(item)) {
                    return
                } else {
                    tableData.map((dataItem, ind) => {
                        if (item._id === dataItem._id) {
                            loadingArr[ind] = true;
                        }
                    })
                    bindTaskData.push({
                        _id: item._id,
                        switch_ip: item.switch_ip,
                        switch_port: item.switch_port,
                        ip: item.ip,
                        mac_address: item.mac_address,
                    })
                }
            })
            if (bindTaskData.length === selectedData.length) {
                // console.log(bindTaskData, loadingArr)
                setTimeout(() => {
                    this.getTableData(current_page, pageSize, query, 'noLoading');
                }, 500)
                this.timerControl();
                Axios({ method: "PUT", url: dumbTerminal_Url + "updateBindStatus", data: bindTaskData, }).then((result) => {
                    // console.log(result, bindTaskData)
                    if (result.data.code === "00") {
                        message.success("更新成功");
                        this.getTableData();
                        this.clearTimer();
                    } else {
                        message.error("更新失败");
                    }
                })
            }
        } else {
            message.error("请选择需要更新状态的哑终端设备！");
        }
    }
    exportDumbFiles(allFlag) {
        const { selectedRowKeys, treeDataIds } = this.state;
        if (!allFlag) {
            if (selectedRowKeys.length === 0) {
                return message.error("请勾选需要导出的设备！")
            }
        }
        let bodyJson = {
            is_all: allFlag ? true : false,
            _ids: selectedRowKeys,
            dep_ids: treeDataIds,
        }
        let exportUrl = dumbTerminal_Url + "exportDumpTerminalData";
        let fileName = "哑终端列表-" + new Date().toLocaleDateString().replace(/\//g, "-")
        FilesUtils.exportFiles(exportUrl, bodyJson, fileName)
    }
    onSubmit() {
        const { excelData } = this.state;
        const data = Object.values(excelData)[0];
        // console.log(excelData, data)
        if (data) {
            axios.post(dumbTerminal_Url + "terminal", data, (res) => {
                if (res.code === "00") {
                    message.success("导入成功");
                    this.setState({
                        visible: false,
                        fileList: [],
                        excelData: {},
                    });
                    this.getTableData();
                    res.existed_ip_list.length === 0 ? '' : Modal.confirm({
                        title: '消息提示',
                        cancelText: "关闭",
                        content: <div>
                            <Typography.Text strong>以下ip已存在于哑终端列表中：</Typography.Text>
                            <div style={{ maxHeight: 500, overflowY: "auto", columns: 2, marginTop: 5 }}>
                                {res.existed_ip_list.map((ip, index) => {
                                    return <div key={index} style={{ margin: "3px 0" }}>{ip}</div>
                                })}
                            </div>
                        </div>,
                        okText: '确定',
                        onOk() { },
                    });
                } else {
                    message.error("导入失败")
                }
            })
        } else {
            message.error("请选择文件")
        }
    }
    accessCheck(checkItem) {
        let { selectedData } = this.state;
        let terminals_data = [];
        if (checkItem) {
            terminals_data = [{
                _id: checkItem._id || "",
                ip: checkItem.ip || "",
                mac_address: checkItem.mac_address || "",
                switch_port: checkItem.switch_port || "",
                switch_ip: checkItem.switch_ip || "",
            }]
            console.log(terminals_data)
        } else {
            selectedData.map((item) => {
                terminals_data.push({
                    _id: item._id || "",
                    ip: item.ip || "",
                    mac_address: item.mac_address || "",
                    switch_port: item.switch_port || "",
                    switch_ip: item.switch_ip || "",
                })
            })
        }
        if (terminals_data.length === 0) {
            message.error("请选择需要执行的哑终端！")
        } else {
            axios.put(dumbTerminal_Url + "checkTerminalInsert", { terminals_data }, (res) => {
                if (res.code === "00") {
                    message.success("执行成功")
                    this.getTableData();
                } else {
                    message.error("执行失败")
                }
            })
        }
    }
    openPortAccess() {
        let { selectedData, tableData, current_page, pageSize, query } = this.state;
        let typeEmpty = [];
        if (selectedData.length === 0) {
            return message.error("请选择需要检查的哑终端！")
        }
        let tableDataCheck = tableData.map(item => {
            if (JSON.stringify(selectedData).indexOf(JSON.stringify(item)) !== -1) {
                return {
                    ...item,
                    port_check_status: true
                }
            } else return item
        })
        this.setState({ tableData: tableDataCheck })
        let terminal_data = selectedData.map(dataItem => {
            if (!dataItem.type) {
                return typeEmpty.push(dataItem.ip)
            } else {
                return {
                    _id: dataItem._id || '',
                    ip: dataItem.ip || '',
                    type: dataItem.type || '',
                }
            }
        })
        if (typeEmpty.length !== 0) {
            return message.error(typeEmpty.join('，') + '的哑终端类型为空！')
        }
        setTimeout(() => {
            this.getTableData(current_page, pageSize, query, 'noLoading');
        }, 500)
        this.timerControl();
        Axios({ method: "POST", url: dumbTerminal_Url + "portCheck", data: { terminal_data }, }).then((result) => {
            if (result.data.code === "00") {
                message.success("执行成功");
                this.getTableData()
                this.setState({ selectedRowKeys: [], selectedData: [] });
            } else {
                message.error(result.data.msg);
                this.setState({ tableData })
            }
        })
        // console.log(selectedData, terminal_data)
    }
    delRef = (ref) => { this.recentlyDeleteDumb = ref; }
    cascOrgainRef = ref => { this.cascOrgain = ref; }
    updateDumbTerminal() {
        let { selectedData } = this.state;
        let mac_list = selectedData.map(item => {
            return item.mac_address
        })
        console.log(mac_list, selectedData)
        if (mac_list.length === 0) {
            message.error("请选择需要更新的哑终端！")
        } else {
            axios.put(dumbTerminal_Url + "dtForSysData", { mac_list }, (res) => {
                console.log(res)
                if (res.code === "00") {
                    message.success("更新成功");
                    this.setState({ selectedRowKeys: [], selectedData: [] });
                    this.getTableData();
                } else {
                    message.error("更新失败")
                }
            })
        }
    }
    editRegion() {
        let { selectedRowKeys, selectedData } = this.state;
        let columns = DumbTerminalUtils.getRegionColumns();
        let _this = this;
        if (Utils.isEmpty(selectedRowKeys)) {
            message.error("请勾选设备！");
            return;
        } else {
            let orgName = null;
            Modal.confirm({
                title: "请选择划分的区域",
                width: "50%",
                maskClosable: true,
                icon: "bell",
                onOk() {
                    if (orgName) {
                        _this.actionOrgin(selectedRowKeys, orgName);
                    } else { message.error("请选择区域！") }
                },
                content: <div><p /><p />选择区域
                    <CascOrgain type="add" value={[]} onChange={(val, option) => {
                        orgName = option[option.length - 1]["label"];
                        _this.setState({ areaId: option[option.length - 1]["value"] });
                    }} />已选设备
                    <Table size={"small"} scroll={{ y: 500 }} pagination={false}
                        columns={columns}
                        dataSource={selectedData}
                    />
                </div>
            })
        }
    }
    actionOrgin(deviceIds, areaName) {
        const areaId = this.state.areaId;
        // console.log("actionOrgin", areaName, deviceIds, areaId)
        axios.put(dumbTerminal_Url + "department", { _ids: deviceIds, dep_id: areaId }, (res) => {
            console.log(res)
            if (res.code === "00") {
                message.success("区域划分成功");
                this.setState({ selectedRowKeys: [], selectedData: [] });
                this.getTableData();
                this.getAreaOfDumb(this.state.treeDataIds.join(','))
            } else {
                message.error("区域划分失败")
            }
        })
    }
    getAreaOfDumb(dep_ids) {
        axios.get(dumbTerminal_Url + "department", { dep_ids }, (res) => {
            if (res.code === "00") {
                this.setState({ treeData: res.data })
            } else {
                message.error("获取组织区域失败")
            }
        })
    }
    getTreeData(data) {
        let treeDataIds = this.dealTreeData(data);
        this.getAreaOfDumb(treeDataIds.join(","))
        this.setState({ treeDataIds });
        this.initData();
    }
    dealTreeData(data) {
        let that = this;
        let dep_ids = [];
        if(Array.isArray(data)) {
            data.map((item) => {
                let children = item.props ? item.props.children : item.children
                if(children && children.length > 0) {
                    dep_ids = dep_ids.concat(that.dealTreeData(children));
                } else {
                    dep_ids.push(item.key || item.eventKey)
                }
            })
        }
        return dep_ids
    }
    openMonitor(monitorFlag) {
        let { selectedRowKeys, selectedData } = this.state;
        if (Utils.isEmpty(selectedRowKeys)) {
            message.error("请选择需要操作的哑终端设备！");
            return;
        } else {
            let mac_list = selectedData.map(item => {
                return item.mac_address
            })
            let bodyJson = {
                is_monitor: monitorFlag,
                mac_addresses: mac_list
            }
            axios.post(dumbTerminal_Url + "monitor", bodyJson, (res) => {
                console.log(res)
                if (res.code === "00") {
                    message.success("操作成功");
                    this.setState({ selectedRowKeys: [], selectedData: [] });
                    this.getTableData();
                } else {
                    message.error(res.msg)
                }
            }, (msg, err) => {
                let failDumbName = err.data.join(',')
                if(err.code === '01') {
                    message.error(`哑终端设备：${failDumbName} 无IP`)
                } else if(err.code === '02') {
                    message.error(`哑终端设备：${failDumbName} 所在区域无告警规则，请先添加对应区域的告警规则`)
                }
            })
            // console.log(selectedData, mac_list)
        }
    }
    handleDropdownMenuClick(e, type) {
        const { current_page, pageSize, query } = this.state;
        if(type === 'export') {
            switch (e.key) {
                case 'is_all': this.exportDumbFiles("is_all"); break;
                case 'selected': this.exportDumbFiles(); break;
            }
        } else if(type === 'add') {
            switch (e.key) {
                case 'manual': {
                    if (sessionStorage.getItem("dumbTerminal")) {
                        sessionStorage.removeItem("dumbTerminal");
                    }
                    let bodyJson = {
                        page_no: current_page,      //当前页码数
                        page_size: pageSize,    //每页显示条数
                        query,    //查询条件
                    }
                    let path = {
                        pathname: `/newTerminal`,
                        bodyJson,
                    };
                    this.props.history.push(path);
                    break;
                }
                case 'import': this.setState({ visible: true }); break;
            }
        } else if(type === 'monitor') {
            switch (e.key) {
                case 'open': this.openMonitor("true"); break;
                case 'close': this.openMonitor("false"); break;
            }
        }
    }

    render() {
        const { current_page, total, pageSize, query, selectedRowKeys, selectedData, fold } = this.state;
        const columns = [
            ...DumbTerminalUtils.getDumbTerminalColumns(current_page, pageSize),
            {
                title: '操作',
                align: 'center',
                flag: true,
                dataIndex: "op",
                fixed: 'right',
                width: 150,
                render: (text, item) => {
                    return (
                        <div>
                            <Dropdown.Button
                                overlay={this.menuButton(item)} type='primary' size='small' icon={<Icon type="down" />}
                                onClick={() => {
                                    sessionStorage.setItem("dumbTerminal", JSON.stringify(item));
                                    this.handleMenuClick({ key: "edit" }, item)
                                }}
                            >
                                <Icon type='edit' /> 编辑
                            </Dropdown.Button>
                        </div>
                    )
                }
            }
        ]

        let scrollX = 0;
        columns.map((item, index) => {
            scrollX += item.width;
        })
        scrollX = scrollX + 260;

        // 多选
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            hideDefaultSelections: 'true',
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });
            },
            onSelect: (record, selected, selectedRows) => { // 选择单行
                if (selected) {
                    if (JSON.stringify(selectedData).indexOf(JSON.stringify(record)) === -1) {
                        selectedData.push(record);
                    }
                } else {
                    selectedData.splice(selectedData.findIndex(item => item._id === record._id), 1)
                }
                // console.log("最终的selectedData：", selectedData)
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                let beforeSelectAll = [];
                if (selected) {
                    if (selectedData.length === 0) {
                        selectedData.push(...selectedRows)
                    } else {
                        selectedData.map((item, index) => {
                            if (JSON.stringify(selectedRows).indexOf(JSON.stringify(item)) === -1) {
                                // console.log("selectedData:", item)
                                beforeSelectAll.push(item);
                            }
                        })
                        selectedData.splice(0, selectedData.length, ...selectedRows, ...beforeSelectAll)
                    }
                } else {
                    changeRows.map((item, index) => {
                        selectedData.splice(selectedData.findIndex(selectItem => selectItem._id === item._id), 1)
                    })
                }
                this.setState({ selectedData })
                // console.log("onSelectAll", selected, selectedRows, selectedData)
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [], selectedData: [] });
                    },
                },
            ],
        };


        return (
            <div className="dumbTerminalBody">
                <div className="CustomBreadcrumb"><CustomBreadcrumb arr={["哑终端管理", { title: "哑终端列表", to: "/dumbTerminal" }]} /> </div>
                <Tabs type="card" onChange={(activeKey) => {
                    switch (activeKey) {
                        case "dumbTerminalDevice": this.getTableData(1, pageSize); this.timerControl(); break;
                        case "recentlyDeleteDumb":
                            if (this.recentlyDeleteDumb) { this.recentlyDeleteDumb.getTableData(1, pageSize); }
                            break;
                    }
                }}>
                    <TabPane tab="哑终端设备" key="dumbTerminalDevice">
                        <div className="searchDiv">
                            <Collapse defaultActiveKey={"key"}>
                                <Collapse.Panel header="筛选条件" key={"key"}>
                                    {query.month_add ?
                                        <Alert message={`${moment(query.month_add).format("YYYY年M月")}新增的数量：${total}`} type="info" closable style={{ marginBottom: 8 }} /> : ''}
                                    <FilterForm tagClass="dumbTerminal"
                                        filterSubmit={(querys) => {
                                            querys.month_add ? querys.month_add = moment(querys.month_add).format("YYYY-MM") : "";
                                            this.getTableData(1, pageSize, querys);
                                        }}
                                        resetTab={() => {
                                            this.getTableData(1, pageSize, {})
                                        }}
                                    />
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                        <Card
                            title={<span style={{ fontWeight: 600 }}>哑终端列表</span>}
                            style={{ marginTop: 8 }}
                            extra={
                                <>
                                    <Row>
                                        <Button type="primary" icon="star" onClick={() => { this.editRegion() }}>区域划分</Button>
                                        <Button type="primary" icon="apartment" onClick={() => { this.openPortAccess() }}>开放端口检查</Button>
                                        <Button type="primary" icon="file-search" onClick={() => { this.accessCheck() }}>接入检查</Button>
                                        {versionName === "pinganNanJing" ? '' : <Button type="primary" icon="sync" onClick={() => { this.updateBindStatus() }}>更新绑定状态</Button>}
                                        <Tooltip title="更新哑终端的ip、接入信息（包括接入端口、接入交换机ip和名称）为系统检测到的数据">
                                            <Button type="primary" icon="reload" onClick={() => { this.updateDumbTerminal() }}>数据更新 <Icon type="exclamation-circle" /></Button>
                                        </Tooltip>
                                        <Dropdown className="dropDownBtn" style={{ marginRight: 8 }}
                                            overlay={
                                                <Menu onClick={(e) => { this.handleDropdownMenuClick(e, 'monitor') }}>
                                                    <Menu.Item  key="open"> <Icon type="desktop" />开启 </Menu.Item>
                                                    <Menu.Item  key="close"> <Icon type="poweroff" />关闭 </Menu.Item>
                                                </Menu>
                                            }
                                        >
                                            <Button type="primary">
                                                <Icon type="desktop" />监控 <Icon type="down" />
                                            </Button>
                                        </Dropdown>
                                        <Dropdown className="dropDownBtn" style={{ marginRight: 8 }}
                                            overlay={
                                                <Menu onClick={(e) => { this.handleDropdownMenuClick(e, 'export') }}>
                                                    <Menu.Item  key="selected"> <Icon type="export" />导出选中 </Menu.Item>
                                                    <Menu.Item  key="is_all"> <Icon type="export" />导出全部 </Menu.Item>
                                                </Menu>
                                            }
                                        >
                                            <Button type="primary">
                                                <Icon type="export" />导出 <Icon type="down" />
                                            </Button>
                                        </Dropdown>
                                        <Dropdown className="dropDownBtn" style={{ marginRight: 8 }}
                                            overlay={
                                                <Menu onClick={(e) => { this.handleDropdownMenuClick(e, 'add') }}>
                                                    <Menu.Item  key="manual"> <Icon type="edit" />手动新增 </Menu.Item>
                                                    <Menu.Item  key="import"> <Icon type="import" />批量导入 </Menu.Item>
                                                </Menu>
                                            }
                                        >
                                            <Button type="primary">
                                                <Icon type="edit" />新建 <Icon type="down" />
                                            </Button>
                                        </Dropdown>
                                        <Button type="danger" icon="delete" onClick={() => {
                                            let _this = this;
                                            Modal.confirm({
                                                title: '消息提示',
                                                content: '你确定删除该设备吗？',
                                                okText: '确定',
                                                okType: 'danger',
                                                cancelText: '取消',
                                                onOk() {
                                                    _this.deleteDumbTerminal(selectedRowKeys)
                                                },
                                            });
                                        }}>删除</Button>
                                    </Row>
                                </>
                            }
                        >
                            <div style={{ border: "1px solid #e2e2e2", borderRadius: 2 }}>
                                <Row>
                                    <Col span={fold ? 1 : 4} >
                                        <div style={{ overflowY: 'auto', maxHeight: 680 }}>
                                            <CascOrgain ref={this.cascOrgainRef} style={{ width: 300 }} onChange={(value, title, data) => {
                                                let { pageSize, querys } = this.state;
                                                const selectID = this.dealTreeData([data]);
                                                if (selectID) {
                                                    querys = { dep_ids: selectID.join(',') };
                                                }
                                                this.getTableData(1, pageSize, querys);
                                            }} foldChange={(fold) => {
                                                this.setState({ fold });
                                            }} getTreeData={(data) => this.getTreeData(data)}
                                            treeData={this.state.treeData || []}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={fold ? 23 : 20} style={{ borderLeft: "1px solid #e2e2e2", }}>
                                        <DyHeadTable
                                            // type="dumbTerminal"
                                            className="dumbTerminalTable"
                                            dataSource={this.state.tableData || []}
                                            columns={columns}
                                            size='small'
                                            loading={this.state.loading}
                                            rowKey={(text) => { return text._id }}
                                            rowSelection={rowSelection}
                                            pagination={{
                                                pageSize: pageSize,
                                                current: current_page,
                                                total: total,
                                                showTotal: (total) => { return <span>共 {total} 项</span> },
                                                pageSizeOptions: ["20", "50", "100", "500", "1000"],
                                                showSizeChanger: true,
                                                onChange: (page, pageSize) => {
                                                    this.setState({ current_page: page, pageSize: pageSize });
                                                    this.getTableData(page, pageSize);
                                                },
                                                onShowSizeChange: (current, size) => {
                                                    this.setState({ current_page: current, pageSize: size });
                                                    this.getTableData(1, size)
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </TabPane>
                    <TabPane tab="最近删除" key="recentlyDeleteDumb">
                        <RecentlyDeleteDumb onRef={this.delRef} />
                    </TabPane>
                    <TabPane tab="哑终端类型" key="dumbTerminalType">
                        <DumbTerminalType />
                    </TabPane>
                    <TabPane tab="端口检查配置" key="portCheckConfig">
                        <PortCheckConfig />
                    </TabPane>
                </Tabs>
                <Modal
                    title={<div>导入excel文件&nbsp;&nbsp;&nbsp;<Button type="primary" icon="download" onClick={() => { DumbTerminalUtils.downloadTemplate() }}>下载模板</Button></div>}
                    visible={this.state.visible}
                    onOk={() => { this.onSubmit() }}
                    onCancel={() => this.setState({ visible: false })}
                    destroyOnClose
                >
                    <Dragger
                        beforeUpload={function () { return false; }}
                        onChange={this.importFiles.bind(this)}
                        fileList={this.state.fileList}
                        onRemove={() => {
                            this.setState({
                                fileList: [],
                                excelData: {},
                            })
                        }
                        }
                    >
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">单击或将文件拖到该区域上传</p>
                    </Dragger>
                </Modal>
            </div>
        )
    }
}

export default DumbTerminal;
