/**
 * 系统前后端API配置 模拟器
 */
// import { getSysConfig } from '@/utils/func'
const tempAddress = sys_config.monitorAlert
const Version = "/MonitorAlert/v1/"
const BASEURL = "http://" + tempAddress + Version
export function dataSource() { //增加、删除、修改、根据dataSourceId查询数据源配置
    return BASEURL + "dataSource";
}
export function dataSources() { // 查询数据源配置
    return BASEURL + "dataSources";
}
export function strategys() { // 查询策略配置
    return BASEURL + "strategys";
}
export function strategyex() { // 查询主策略+子策略
    return BASEURL + "strategyex";
}
export function strategy() { // 查询策略配置
    return BASEURL + "strategy";
}
export function alarmRules() {
    return BASEURL + "alarmRules";
}
export function alarmRule() {
    return BASEURL + "alarmRule";
}
export function alarmBlockRules() {
    return BASEURL + "alarmBlockRules";
}
export function alarmBlockRule() {
    return BASEURL + "alarmBlockRule";
}
// export function alarmRecords() {
//     return BASEURL + "alarmRecords";
// }
export function alarmRecords() {
    return BASEURL + "alarmRecordTmps";
}
export function restoreRules() {
    return BASEURL + "restoreRules";
}
export function restoreRule() {
    return BASEURL + "restoreRule";
}
export function alarmGroups() {
    return BASEURL + "alarmGroups";
}
export function alarmGroup() {
    return BASEURL + "alarmGroup";
}
export function dashboard() {
    return BASEURL + "dashboard";
}
export function groupMember() {
    return BASEURL + "groupMember";
}
export function alarmSortRank() {
    return BASEURL + "alarmSortRank";
}
export function alarmTrend() {
    return BASEURL + "alarmTrend";
}
export function alarmRecordTotal() {
    return BASEURL + "alarmRecordTmpTotal";
}
export function alarmTemplates() {
    return BASEURL + "alarmTemplates";
}
export function alarmRecordTmp() {
    return BASEURL + "alarmRecordTmp";
}
export function alarmRecordDetail() {
    return BASEURL + "alarmRecordTmpDetail";
}
export function alarmRecordTmpExport() { // 导出告警记录报表
    return BASEURL + "alarmRecordTmpExport";
}