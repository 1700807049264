import React from 'react'
import {notification,Icon} from 'antd'

export default class Notification {
    state={
    };
    static error(error){
        notification.open({
            message: '操作失败！',
            description: error,
            icon: <Icon type="frown" style={{color: 'red'}}/>,
        });
    }
    static success(info){
        notification.success({
            message: '操作成功!',
            description: info,
        });
    }
}