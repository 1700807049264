import React from 'react'
import {
    Card, Table, Input, Typography, Popconfirm, Descriptions, Button, Icon, Tabs, Form,
    Row, Col, message, Tag, Modal, Tooltip, Popover, Dropdown, Menu, Collapse
} from "antd";
import http from '@/axios/axios_utils';
import http_axios from 'axios'
import Socket from "@/socket/index";
import CustomBreadcrumb from "@/components/CustomBreadcrumb/index";
import DyHeadTable from '@/components/DyHeadTable/index';
import FilterForm from '@/components/FilterForm/index';
import FirewallSNat from './firewallSNat'
import FirewallDNat from './firewallDNat'
import FirewallUtils from "@/utils/FirewallUtils";
import moment from 'moment';
import './index.scss';
const firewall_url = "http://" + backup_address + "/ZJNMS_V1/";
const { Title, Text } = Typography;
const { confirm } = Modal;
const { TabPane } = Tabs;
import { SubmitButton, FormItem, Radio, DatePicker } from "formik-antd";
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik";

export default class FireWallDetail extends React.Component {
    state = {
        s_current: 1,      //当前页码数
        s_pageSize: 20,    //每页显示条数
        s_total: 0,
        s_querys: {},    //查询条件
        s_loading: false,
        s_tableData: [],
        deviceInfo: {},
        hisVis: false,
        modalTitle: "",
        hisData: [],
        _current: 1,      //当前页码数
        _pageSize: 20,    //每页显示条数
        _total: 0,
        itemName: "",
        allHtml: null,
        natVis: false,
        dnatData: [],
        snatData: [],
        TemPoliceVis: false,
        radioValue: false,
        policeItem: {},
        tabsKey: "strategyList",
        btnLoading: false,
        userMail: "",
    };
    componentWillMount() {
        const deviceInfo = JSON.parse(sessionStorage.getItem("device"));
        this.setState({ deviceInfo });
        this.updata(null, null, null, deviceInfo.deviceIp);
        this.getUser();
    }
    componentWillUnmount() {
        this.clearTimer();
    }
    getUser() {
        Socket.QueryDataByPage('QueryUsersPage', { pageNum: 1, pageSize: 100000000, querys: {} }, (result) => {
            if (result.retcode === '00') {
                result.users.map((userItem) => {
                    if (userItem.name === sessionStorage.getItem("userName")) {
                        this.setState({ userMail: userItem.mail })
                    }
                })
            }
        }, "/queryUser");
    };
    updata = (pageNum, pageSize, querys, ip, loadingFlag) => {
        if (loadingFlag !== "noLoading") {
            this.setState({ s_loading: true, });
        }
        let { s_pageSize, s_current, s_querys, deviceInfo } = this.state;
        let query = querys || s_querys;
        let bodyJson = {
            page_size: pageSize || s_pageSize,
            page_no: pageNum || s_current,
            fw_ip: ip || deviceInfo.deviceIp,
            ...query,
        };
        // console.log(bodyJson)
        http.get(firewall_url + "fw_manage/fwSecurityPolicy", bodyJson, (result) => {
            if (result.code === "00") {
                this.setState({
                    s_tableData: result.data,
                    s_total: result.count,
                    s_loading: false,
                    s_querys: query,
                    s_current: pageNum || 1,
                    s_pageSize: pageSize || s_pageSize,
                    btnLoading: result.is_updating,
                })
                if (result.is_updating) {
                    this.timerControl();
                } else {
                    this.clearTimer();
                }
            } else {
                message.error("查询失败");
            }
        });
    };
    clearTimer() {
        if (this.timer != null) {
            clearInterval(this.timer);
        }
    }
    timerControl() {
        let { s_current, s_pageSize, s_querys, deviceInfo, tabsKey } = this.state;
        let _this = this;
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
            _this.switchTab(tabsKey, { s_current, s_pageSize, s_querys })
            // _this.updata(s_current, s_pageSize, s_querys, deviceInfo.deviceIp, 'noLoading')
        }, 5000)
    }
    modalDetail(titleData, record) {
        confirm({
            content: <Descriptions bordered title="策略详情" size="small"
                layout="vertical"
            >
                {titleData.map((rec) => {
                    const temp = record[rec[0]]
                    return <Descriptions.Item label={rec[1]}>{
                        Array.isArray(temp) ? temp.map((DET, i) => { return <Tag key={i}>{DET}</Tag> }) : temp
                    }</Descriptions.Item>
                })}
            </Descriptions>,
            maskClosable: true,
            width: "60%",
        })
    }
    getHistoryList(pageNum, pageSize, itemName) {
        let { _pageSize, _current, deviceInfo } = this.state;
        let bodyJson = {
            page_size: pageSize || _pageSize,
            page_no: pageNum || _current,
            fw_ip: deviceInfo.deviceIp || "",
            rule_name: itemName || "",
        }
        http.get(firewall_url + "fw_manage/fwSpHistory", bodyJson, (result) => {
            if (result.code === "00") {
                this.setState({
                    hisData: result.data,
                    _total: result.count,
                    _current: pageNum || 1,
                    itemName: itemName,
                    modalTitle: itemName,
                    hisVis: true
                })
            } else {
                message.error("查询失败");
            }
        });
    }
    getNatForSecurityPolicy(item) {
        let { deviceInfo } = this.state;
        let bodyJson = {
            fw_ip: deviceInfo.deviceIp || "",
            rule_name: item.rule_name || "",
        }
        http.get(firewall_url + "fw_manage/natForSecurityPolicy", bodyJson, (result) => {
            if (result.code === "00") {
                this.setState({
                    dnatData: result.dnat_data,
                    snatData: result.snat_data,
                    modalTitle: item.rule_name,
                    natVis: true
                })
            } else {
                message.error("查询失败");
            }
        });
    }
    onRef = (ref) => {
        this.firewallSNat = ref;
    }
    onRefD = (ref) => {
        this.firewallDNat = ref;
    }
    menuButton(resItem) {
        const { deviceInfo } = this.state
        return (
            <Menu onClick={(e) => this.handleMenuClick(e, resItem)}>
                { deviceInfo.deviceFirm !== 'huawei' ?
                    <Menu.Item key="viewNat">
                        <Icon type="api" />查看相应nat
                    </Menu.Item> : ''}
                {(() => {
                    if (resItem.create_time !== resItem.update_time) {
                        return (
                            <Menu.Item key="history">
                                <Icon type='history' /> 查看历史记录
                            </Menu.Item>
                        )
                    }
                })()}
            </Menu>
        )
    }
    handleMenuClick = (e, item) => {
        const { _current, _pageSize } = this.state
        switch (e.key) {
            case 'history':
                this.getHistoryList(1, _pageSize, item.rule_name);
                break;
            case 'viewNat':
                this.getNatForSecurityPolicy(item)
                break;
            case "tempPolicy":
                this.setState({
                    TemPoliceVis: true,
                    policeItem: item,
                    radioValue: item.temp_policy ? item.temp_policy.is_temp : false
                });
                break;
        }
    }
    settingTemPolice(value) {
        let { policeItem, s_pageSize, s_querys, s_current, userMail } = this.state;
        let temp_policy = {
            is_temp: value.is_temp,
            email: userMail,
        }
        if (value.is_temp) {
            temp_policy = {
                remark: value.remark,
                expiration_time: moment(value.expiration_time).format("YYYY-MM-DD HH:mm:ss"),
                ...temp_policy,
            }
        }
        http.put(firewall_url + "fw_manage/tempPolicy", { _id: policeItem._id, temp_policy }, (result) => {
            if (result.code === "00") {
                message.success("设置成功");
                this.setState({
                    TemPoliceVis: false,
                    policeItem: {}
                })
                this.updata(s_current, s_pageSize, s_querys);
            } else {
                message.error(result.msg);
            }
        });
        // console.log(temp_policy)
    }
    updateList() {
        let { tabsKey, deviceInfo } = this.state;
        this.setState({ btnLoading: true })
        http_axios({
            method: "PUT",
            url: firewall_url + "fw_manage/fwData",
            data: { fw_ip: deviceInfo.deviceIp },
        }).then((result) => {
            if (result.data.code === "00") {
                message.success("防火墙信息更新成功");
                this.setState({ btnLoading: false })
                this.switchTab(tabsKey);
            } else {
                message.error(result.msg);
            }
        })
    }
    switchTab(tabsKey, queryJson) {
        let { s_pageSize } = this.state;
        this.clearTimer();
        console.log()
        switch (tabsKey) {
            case "strategyList": this.updata(1, s_pageSize); break;
            case "srcNAT":
                if (this.firewallSNat) { this.firewallSNat.updata(1, this.firewallSNat.state.s_pageSize); }
                break;
            case "dstNAT":
                if (this.firewallDNat) { this.firewallDNat.updata(1, this.firewallDNat.state.s_pageSize); }
                break;
        }
    }
    render() {
        let { s_current, s_loading, s_pageSize, s_tableData, s_total, s_querys, deviceInfo, dnatData, snatData, TemPoliceVis,
            hisVis, modalTitle, hisData, _current, _total, _pageSize, itemName, natVis, radioValue, policeItem, tabsKey, btnLoading } = this.state;

        const columns = [
            ...FirewallUtils.getPoliceColumns(s_pageSize, s_current),
            {
                title: '操作',
                align: 'center',
                dataIndex: "historyList",
                ellipsis: true,
                width: 200,
                fixed: 'right',
                render: (text, item, index) => {
                    if (item.create_time !== item.update_time) {
                    }
                    return (
                        <Dropdown.Button
                            overlay={this.menuButton(item)} type='primary' size='small' icon={<Icon type="down" />}
                            onClick={() => {
                                this.handleMenuClick({ key: "tempPolicy" }, item)
                            }}
                        >
                            <Icon type="setting" /> 临时策略设置
                        </Dropdown.Button>
                    )
                }
            }
        ];
        const formList = [
            {
                label: '名称',
                type: 'INPUT',
                field: 'rule_name',
                initialValue: '',
                placeholder: '请输入内容'
            },
            {
                label: '源地址',
                type: 'INPUT',
                field: 'src_ip',
                initialValue: '',
                placeholder: '请输入内容'
            },
            {
                label: '目的地址',
                type: 'INPUT',
                field: 'dst_ip',
                initialValue: '',
                placeholder: '请输入内容'
            },
            {
                label: "临时策略",
                type: 'SELECT',
                field: "is_temp",
                initialValue: '',
                placeholder: "请选择内容",
                list: [
                    { id: '', name: '全部' },
                    { id: 'false', name: '否' },
                    { id: 'true', name: '是' },
                ],
            },
        ]
        const hisColumns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: "key",
                ellipsis: true,
                width: 50,
                render: (text, item, index) => {
                    return <span>{index + 1 + (_current - 1) * _pageSize}</span>
                }
            },
            ...FirewallUtils.getPoliceColumns(_pageSize, _current),
            {
                title: '创建时间',
                align: 'center',
                dataIndex: "create_time",
                ellipsis: true,
                width: 120,
            },
        ];
        const params = [
            ["防火墙IP", "deviceIp"],
            ["设备厂商", "deviceFirm"],
            ["SNMP口令 ", "community"],
            ["设备名称", "deviceId"],
            ["设备类型", "deviceType"],
            ["设备型号", "deviceVersion"],
            ["设备OS版本", "deviceOsVersion"],
            ["网点信息", "deviceSite"],
        ];
        const updateBtn = <Button type="primary" icon="sync" style={{ marginRight: 25 }} loading={btnLoading} onClick={() => {
            this.updateList();
        }}>{btnLoading ? '数据更新中...' : '数据更新'}</Button>
        return (
            <div className="home-body">
                <CustomBreadcrumb arr={["运维管理", "防火墙", { title: "防火墙列表", to: "/fireWall" }, "策略详情"]} />
                <Card title={<span style={{ fontWeight: 600 }}>防火墙策略详情</span>}
                    extra={<Button type="primary" icon={'rollback'} onClick={() => { this.props.history.goBack(); }}>返回防火墙列表</Button>}>
                    <Row className="firewallInfo">
                        <Col span={24}>
                            <Text strong style={{ marginLeft: 10 }}>设备基本信息</Text>
                            <Descriptions bordered size="small" column={4}>
                                {params.map((rec, m) => {
                                    return <Descriptions.Item key={m} label={rec[0]}>{deviceInfo[rec[1]]}</Descriptions.Item>
                                })}
                            </Descriptions>
                        </Col>
                    </Row><br />
                    <Tabs type="card" style={{ marginTop: 8 }} tabBarExtraContent={updateBtn} onChange={(activeKey) => {
                        this.setState({ tabsKey: activeKey });
                        this.timerControl();
                        this.switchTab(activeKey)
                    }}>
                        <TabPane tab="策略列表" key="strategyList">
                            <FilterForm formList={formList}
                                filterSubmit={(querys) => {
                                    this.updata(1, s_pageSize, querys)
                                }}
                                resetTab={() => {
                                    this.updata(1, s_pageSize, {})
                                }}
                            />
                            <DyHeadTable
                                type="poloDetail2"
                                style={{ marginTop: 8 }}
                                dataSource={s_tableData}
                                columns={columns}
                                size="small"
                                loading={s_loading}
                                rowKey={(text) => { return text._id }}
                                pagination={{
                                    current: s_current, pageSize: s_pageSize, total: s_total,
                                    showTotal: (total) => { return <span>共 {total} 项</span> },
                                    pageSizeOptions: ["20", "50", "100", "500", "1000"],
                                    showSizeChanger: true,
                                    onShowSizeChange: (page, pageSize) => { this.updata(1, pageSize, s_querys) },
                                    onChange: (page, pageSize) => { this.updata(page, pageSize, s_querys); }
                                }}
                            />
                        </TabPane>
                        <TabPane tab="源NAT" key="srcNAT">
                            <FirewallSNat tabKey="snat" onRef={this.onRef}
                                loadingFalse={() => this.setState({ btnLoading: false })}
                                loadingTrue={() => this.setState({ btnLoading: true })}
                            />
                        </TabPane>
                        <TabPane tab="目的NAT" key="dstNAT">
                            <FirewallDNat tabKey="dnat" onRef={this.onRefD}
                                loadingFalse={() => this.setState({ btnLoading: false })}
                                loadingTrue={() => this.setState({ btnLoading: true })}
                            />
                        </TabPane>
                    </Tabs>
                </Card>
                <Modal
                    title={"防火墙" + deviceInfo.deviceIp + "--安全策略" + modalTitle + "--历史记录"}
                    visible={hisVis}
                    onOk={() => this.setState({ hisVis: false, modalTitle: "", itemName: "" })}
                    onCancel={() => this.setState({ hisVis: false, modalTitle: "", itemName: "" })}
                    width="95%"
                    destroyOnClose
                >
                    <Table
                        dataSource={hisData}
                        columns={hisColumns}
                        size="small"
                        rowKey={(text) => { return text._id }}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: _total,
                            onChange: (page, pageSize) => { this.getHistoryList(page, pageSize, itemName); }
                        }}
                    />
                </Modal>
                <Modal
                    title={"安全策略" + modalTitle + "--nat信息"}
                    visible={natVis}
                    onOk={() => this.setState({ natVis: false, modalTitle: "", snatData: [], dnatData: [] })}
                    onCancel={() => this.setState({ natVis: false, modalTitle: "", snatData: [], dnatData: [] })}
                    width="95%"
                    destroyOnClose
                >
                    <Collapse defaultActiveKey={"snat"}>
                        <Collapse.Panel header="源nat列表" key={"snat"}>
                            <Table
                                dataSource={snatData}
                                columns={[...FirewallUtils.getSNatColumns()]}
                                size="small"
                                rowKey={(text) => { return text._id }}
                                pagination={false}
                            />
                        </Collapse.Panel>
                        <Collapse.Panel header="目的nat列表" key={"dnat"}>
                            <Table
                                dataSource={dnatData}
                                columns={[...FirewallUtils.getDNatColumns()]}
                                size="small"
                                rowKey={(text) => { return text._id }}
                                pagination={false}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </Modal>
                <Modal
                    title="临时策略设置"
                    visible={TemPoliceVis}
                    onCancel={() => this.setState({ TemPoliceVis: false })}
                    width="50%"
                    footer={null}
                    destroyOnClose
                >
                    <Formik
                        onSubmit={(value, actions) => { this.settingTemPolice(value); actions.setSubmitting(false); }}
                        validate={(values) => {
                            let info = {};
                            if (radioValue) {
                                if (!values.expiration_time) {
                                    info.expiration_time = "请选择策略到期时间";
                                }
                                if (!values.remark) {
                                    info.remark = "请输入策略备注";
                                }
                            }
                            return info
                        }}
                        initialValues={{
                            is_temp: policeItem.temp_policy ? policeItem.temp_policy.is_temp : false,
                            expiration_time: policeItem.temp_policy ? (policeItem.temp_policy.is_temp === true ? policeItem.temp_policy.expiration_time : '') : '',
                            remark: policeItem.temp_policy ? (policeItem.temp_policy.is_temp === true ? policeItem.temp_policy.remark : '') : '',
                        }}
                    >
                        <FormikForm labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
                            <FormItem
                                name={"is_temp"}
                                label={"更改临时策略"}
                                key={"is_temp"}
                                required={true}
                            >
                                <Radio.Group name={"is_temp"} onChange={(e) => { this.setState({ radioValue: e.target.value }) }}>
                                    <Radio value={true}>标记为临时策略</Radio>
                                    <Radio value={false}>取消临时策略标记</Radio>
                                </Radio.Group>
                            </FormItem>
                            {(() => {
                                if (radioValue) {
                                    return (
                                        <>
                                            <FormItem
                                                name={"expiration_time"}
                                                label={"到期时间"}
                                                key={"expiration_time"}
                                                required={true}
                                            >
                                                <DatePicker name={"expiration_time"} placeholder={"请选择策略到期时间"} showTime format="YYYY-MM-DD HH:mm:ss" />
                                            </FormItem>
                                            <FormItem
                                                name={"remark"}
                                                label={"备注"}
                                                key={"remark"}
                                                required={true}
                                            >
                                                <FormikInput.TextArea name={"remark"} placeholder={"请输入策略备注"} />
                                            </FormItem>
                                        </>
                                    )
                                }
                            })()}
                            <div style={{ textAlign: "right" }}>
                                <Button onClick={() => { this.setState({ TemPoliceVis: false }) }}>取消</Button>
                                <SubmitButton style={{ marginLeft: "10px" }} disabled={false}>保存</SubmitButton>&nbsp;
                            </div>
                        </FormikForm>
                    </Formik>
                </Modal>
            </div>
        );
    }
} 