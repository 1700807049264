import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Divider, Popconfirm, Modal, Form, Input, Select
} from "antd";
import http from '@/axios/axios_utils';
import { connect } from 'react-redux'
import "./index.scss"
import "animate.css"
const dumbTerminal_Url = "http://" + backup_address + "/ZJNMS_V1/dumb_termial/";

function DumbTerminalType(props) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectItem, setSelectItem] = useState({});
    const [tableData, setTabData] = useState([])
    const { getFieldDecorator } = props.form
    const columns = [
        {
            title: '序号',
            dataIndex: 'key',
            ellipsis: true,
            width: 100,
            render: (text, item, c) => { return c + 1 }
        },
        {
            title: '哑终端类型',
            dataIndex: 'd_t_type',
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            ellipsis: true,
        },
        {
            title: '操作',
            width: 220,
            render: (text, item) => {
                return <div>
                    <Button type="primary" icon="edit" size="small" onClick={() => { setSelectItem(item); setVisible(true); }}>编辑</Button>
                    &nbsp;
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData(item._id) }}
                        okText="是"
                        cancelText="否"
                    >
                        <Button type="danger" size="small" icon="delete" >删除</Button>
                    </Popconfirm>
                </div>
            }
        },
    ]
    // 切换tab，该tab不会刷新，如果需要刷新需要手动点击刷新按钮
    useEffect(() => {//componentDidMount;
        getData();
        // http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    useEffect(() => {
        if(selectItem.d_t_type) { // 编辑初始化表单
            props.form.setFieldsValue({
                d_t_type: selectItem.d_t_type,
                open_ports: selectItem.open_ports,
            })
        }
    }, [selectItem])
    function getData() {
        setLoading(true);
        http.get(dumbTerminal_Url + 'dtType', {}, (r) => {
            if(r.code === '00') {
                setTabData(r.data);
            } else {
                message.error(r.msg);
            }
            setLoading(false);
        })
    }
    function saveDataSource() {
        props.form.validateFieldsAndScroll(async (err, values) => {
            // console.log(err, values, selectItem)
            if(!err) {
                if (selectItem.d_t_type) {//修改
                    http.put(dumbTerminal_Url + 'dtType', {...values, _id: selectItem._id}, (res) => {
                        if(res.code === '00') {
                            getData();
                            message.success("修改成功");
                        } else {
                            message.error(res.msg);
                        }
                    })
                } else {//新增
                    http.post(dumbTerminal_Url + 'dtType', values, (res) => {
                        if(res.code === '00') {
                            getData();
                            message.success("新增成功");
                        } else {
                            message.error(res.msg);
                        }
                    })
                }
                setVisible(false);
            }
        })
    }
    function deleteData(_id) {
        http.delete_body(dumbTerminal_Url + 'dtType', { _id }, (res) => {
            if(res.code === '00') {
                getData();
                message.success("删除成功");
            } else {
                message.error(res.msg);
            }
        })

    }
    return <div className="BackupCmdDiv">
        <Card >
            <div className="head">
                <Button type="primary" icon="file-add" onClick={() => {
                    setSelectItem({})
                    setVisible(true)
                }}>添加</Button>&nbsp;
                <Button icon="reload" onClick={() => {
                    getData();
                }}>刷新</Button>
            </div>
            <Table
                dataSource={tableData}
                loading={loading}
                columns={columns}
                rowKey={(text) => { return text._id }}
                size="small"
                pagination={false}
            />
        </Card>
        <Modal title={selectItem.d_t_type ? "编辑哑终端类型" : "新增哑终端类型"}
            visible={visible}
            onCancel={() => {setVisible(false); setSelectItem({});}}
            footer={null}
            width={"60%"}
            destroyOnClose
        >
            <Form onSubmit={saveDataSource}>
                <Form.Item label={'哑终端类型'} labelCol={{ span: 5 }} wrapperCol={{ span: 15 }} >
                    {
                        getFieldDecorator('d_t_type', {
                            rules: [
                                { required: true, message: '哑终端类型不能为空' },
                            ]
                        })(<Input allowClear placeholder='请输入哑终端类型' />)
                    }
                </Form.Item>
                <Form.Item colon={false} style={{ margin: 0 }}>
                    {<div style={{ textAlign: "center" }}>
                        <Button type="primary" icon="check-circle" htmlType="submit" style={{ marginLeft: "10px" }}>保存</Button>&nbsp;
                        <Button onClick={() => setVisible(false) }>取消</Button>
                    </div>}
                </Form.Item>
            </Form>
        </Modal>
    </div>
}
const DumbTerminalTypeFrom = Form.create()(DumbTerminalType);
export default connect()(DumbTerminalTypeFrom)