import React from 'react'
import {
    Card, Button, Icon, message, notification, Modal, Menu, Dropdown,
    Tag, Progress, Collapse, Tabs, Table, Radio
} from "antd";
import socket from "@/socket/index";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import DyHeadTable from '@/components/DyHeadTable/index';
import DyCard from '@/components/DyHeadTable/cardList.js';
import FlowChar from '@/components/FlowChar/index';
import MonacoEditor from "react-monaco-editor"
import { addTaskType, delTaskType } from './utils/index';
import './index.scss';
const { confirm } = Modal;
class InspectionMan extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _current2: 1,    //当前页码数
        _pageSize: 10,    //当前每页显示条数
        loading: false,
        visible_modal: false,
        visible_module_flag: false, // 显示模版对话弹窗
        querys: { ifTemplate: false },    //查询条件 
        progressObj: {},
        elapsedTimeObj: {},
        taskId: "",
        actType: "all",
        TaskType: [],
        moduleName: "",
        flow_data: {},
        templateObj: {},
        taskStyle: "card"

    };
    componentWillMount() {
        this.setTaskStyle();
        this.initPage();
    }
    initPage() {
        const bodyJson = this.props.location.bodyJson || {};
        const { _current, _pageSize, querys } = this.state;
        this.setState({
            _current: bodyJson.pageNum || _current,
            _pageSize: bodyJson.pageSize || _pageSize,
            querys: bodyJson.querys || querys,
        })
        this.updata(bodyJson.pageSize, bodyJson.pageNum, bodyJson.querys);
        this.onNotice();
        this.getTaskType();
    }
    setTaskStyle() {
        socket.getConfig("taskStyle", (v) => {
            this.setState({ taskStyle: v || "card" });
        })
    }
    onNotice() {
        socket.Notice((resp) => {
            // console.log(resp)
            let { progressObj, elapsedTimeObj, _pageSize, _current, querys } = this.state;
            if (progressObj.hasOwnProperty(resp.taskName)) {
                progressObj[resp.taskName] = resp.taskRate;
                if (resp.taskRate === 100) {
                    this.updata(_pageSize, _current, querys);
                }
            }
            if (elapsedTimeObj.hasOwnProperty(resp.taskName)) {
                elapsedTimeObj[resp.taskName] = resp.elapsedTime;
            }
            this.setState({
                progressObj, elapsedTimeObj
            })
        });
    }
    getTaskType() {
        socket.ExecuteAction('QueryTaskTypes', {}, "", (result) => {
            // console.log("getTaskType", result);
            this.setState({ TaskType: result.data, });
        });
    }
    excuteTask = (taskId, taskName, page, pageSize) => {
        if (page && pageSize) {
            this.setState({
                _current: page,
                _pageSize: pageSize
            })
        }
        const { _pageSize, _current, elapsedTimeObj, progressObj, querys } = this.state;
        this.setState({ loading: true });
        socket.ExecuteAction("OptaskExcute",
            { _id: taskId }, "", () => {
                message.success('执行成功');
                elapsedTimeObj[taskName] = "0:0:0";
                progressObj[taskName] = 0;
                this.setState({ elapsedTimeObj, progressObj });
                this.updata(_pageSize, _current, querys);
            }, (error) => {
                this.setState({ loading: false });
                notification.open({
                    message: '执行失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };
    TerminalTask = (taskId, page, pageSize) => {
        if (page && pageSize) {
            this.setState({
                _current: page,
                _pageSize: pageSize
            })
        }
        const { _pageSize, _current, querys } = this.state;
        this.setState({ loading: true });
        socket.ExecuteAction("TerminalTask",
            { _id: taskId }, "", () => {
                message.success('终止成功');
                this.updata(_pageSize, _current, querys);
            }, (error) => {
                this.setState({ loading: false });
                notification.open({
                    message: '执行失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };
    updata = (pageSize, pageNum, querys2) => {
        this.setState({
            loading: true,
            _current: pageNum,
            _pageSize: pageSize
        });
        let { _pageSize, _current, querys } = this.state;
        querys = querys2 || querys;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys;
        let actType = bodyJson.querys.reportTags || "all";
        if (bodyJson.querys.reportTags === "all") {
            bodyJson.querys.reportTags = undefined;
            actType = "all"
        }
        console.log(querys2, querys)
        socket.ExecuteAction("QueryTasksPage",
            bodyJson, "/taskQueryTask", (result) => {
                this.setState({
                    tableData: Utils.DataAddItem(result.tasks),
                    _current: pageNum || 1,
                    total: result.total,
                    loading: false,
                    actType,
                    querys,
                })
                // console.log("getData", this.state);
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };
    DeleteOperation = (taskId) => {
        const { _pageSize, _current, querys } = this.state;
        let thisTemp = this;
        socket.ExecuteAction("DeleteTask",
            { _id: taskId }, "/delTask", () => {
                message.success('删除成功');
                thisTemp.updata(_pageSize, _current, querys);
            });
        this.setState({ visible: false });
    }
    handleMenuClick = (e, item) => {
        this.setState({ resItem: item });
        const { _current, _pageSize, querys, actType } = this.state
        let _this = this;
        let bodyJson = {
            pageNum: _current,      //当前页码数
            pageSize: _pageSize,    //每页显示条数
            querys,    //查询条件
            taskId: item._id,
            actType
        }
        switch (e.key) {
            case 'log':
                this.setState({ visible_modal: true })
                break;
            case 'edit':
                let path = {
                    pathname: `/customizeTask/newlist`,
                    bodyJson,
                };
                this.props.history.push(path);
                break;
            case 'result':
                sessionStorage.setItem("task", JSON.stringify({ id: item._id, name: item.taskName, tags: item.tags, actType }));
                this.props.history.push({
                    pathname: `/customizeTask/result`,
                    bodyJson,
                });
                break;
            case 'del':
                confirm({
                    title: '消息提示',
                    content: '你确定删除该条记录吗？',
                    maskClosable: true,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        _this.DeleteOperation(item._id);
                    },
                    onCancel() { },
                });
                break;
            case 'mod':
                bodyJson = {
                    pathname: this.props.location.pathname,
                    ...bodyJson
                }
                let modPath = {
                    pathname: `/newTask:${item.template}`,
                    bodyJson
                }
                this.props.history.push(modPath);
                // console.log(item.template)
                // this.setState({ visible_module_flag: true });
                // _this.getModuleGroup(item);
                break;
        }
    }

    getModuleGroup(taskItem) {
        // 获取模版
        socket.ExecuteAction("QueryTasksPage",
            { pageNum: 1, pageSize: 10000, querys: { reportTags: taskItem.reportTags, ifTemplate: true } }, "/taskQueryTask", (result) => { // 获取该任务类型下的所有模版
                const SelectTaskList = result.tasks
                if (taskItem.template) {
                    const SelTemp = SelectTaskList.filter((item) => { return item._id === taskItem.template });
                    if (SelTemp[0] && SelTemp[0].taskName) {
                        this.setState({
                            moduleName: SelTemp[0]
                        })
                        this.getFlowData(SelTemp[0]._id);
                        this.queryTemplate(SelTemp[0].form_template_id);
                    }
                    // console.log(this.state.moduleName, SelTemp[0].form_template_id) // 获取到模版
                }
            });
    }

    getFlowData(_id) {
        // 获取流程图数据
        socket.ExecuteAction('QueryTaskDetail', { _id: _id }, "/taskQueryTask", (resp) => {
            let flow_data = resp.task.taskContent;
            this.setState({
                flow_data: flow_data
            })
        })
    }

    queryTemplate = (_id) => {
        socket.ExecuteAction("QueryInspecTemplate",
            { _id }, "/", (result) => {
                if (result.data && result.data[0]) {
                    this.setState({ templateObj: result.data[0] })
                }
                // console.log(this.state.templateObj.name)
            });
    };


    menuButton(resItem) {
        return <Menu onClick={(e) => this.handleMenuClick(e, resItem)}>

            <Menu.Item key="log">
                <Icon type="file-text" />
                日志
            </Menu.Item>
            <Menu.Item key="edit">
                <Icon type="form" />
                编辑
            </Menu.Item>
            <Menu.Item key="del">
                <Icon type="delete" />
                删除
            </Menu.Item>
        </Menu>
    }
    editTaskType() {
        const { TaskType } = this.state;
        const ModalObj = Modal.info({
            title: "任务类型管理",
            okText: "关闭",
            width: 500,
            maskClosable: true,
            content: <div>
                <Button type="primary" size="small" icon="plus-circle" onClick={() => addTaskType((name) => {
                    this.getTaskType();
                    this.updataModal(ModalObj, [{ _id: new Date().getTime(), name }, ...TaskType])
                })}>新增</Button><p />
                <Table
                    columns={[
                        { title: "序号", width: 40, render: (a, b, c) => (c + 1) },
                        { title: "类型名称", dataIndex: "name" },
                        {
                            title: "序号", render: (a, b, c) => (
                                <Button type="danger" icon="delete" size="small"
                                    onClick={() => delTaskType(b.name, () => {
                                        this.getTaskType();
                                        this.updataModal(ModalObj, TaskType.filter((t) => t.name != b.name))
                                    })}>
                                    删除</Button>
                            )
                        },
                    ]}
                    dataSource={TaskType}
                    size="small"
                    pagination={false}
                />
            </div>
        })

    }
    updataModal(ModalObj, TaskType) {
        ModalObj.update({
            title: "任务类型管理",
            okText: "关闭",
            width: 500,
            maskClosable: true,
            content: <div>
                <Button type="primary" size="small" icon="plus-circle" onClick={() => addTaskType((name) => {
                    this.getTaskType();
                    this.updataModal(ModalObj, [{ _id: new Date().getTime(), name }, ...TaskType])
                })}>新增</Button><p />
                <Table
                    columns={[
                        { title: "序号", width: 40, render: (a, b, c) => (c + 1) },
                        { title: "类型名称", dataIndex: "name" },
                        {
                            title: "序号", render: (a, b, c) => (
                                <Button type="danger" icon="delete" size="small"
                                    onClick={() => delTaskType(b.name, () => {
                                        this.getTaskType();
                                        this.updataModal(ModalObj, TaskType.filter((t) => t.name != b.name))
                                    })}>
                                    删除</Button>
                            )
                        },
                    ]}
                    dataSource={TaskType}
                    size="small"
                    pagination={false}
                />
            </div>
        })
    }

    openReport(data) {
        Modal.info({
            title: "预览报告",
            width: "80%",
            content: <div>
                <p><strong>报告名称:{data.name}</strong></p>
                <div dangerouslySetInnerHTML={{ __html: data.template }}></div>
            </div>
        })
    }


    render() {
        let { _current, actType, _pageSize, elapsedTimeObj, tableData, querys, loading, total, visible_modal, TaskType, visible_module_flag,
            flow_data, moduleName, templateObj, taskStyle } = this.state;
        const columns = [
            {
                title: '任务名称',
                align: 'left',
                width: 150,
                dataIndex: 'taskName',
                ellipsis: true,
                render: (text, itemTask) => {
                    return <a onClick={() => {
                        this.handleMenuClick({ key: "edit" }, itemTask)
                    }}>{text}</a>
                }
            }, {
                title: '状态',
                align: 'center',
                width: 80,
                dataIndex: 'taskStatus',
                render: (text) => {
                    // return (<span style={{color:Utils.ColorShow(text),fontWeight:'600'}}>{Utils.Translation(text)}</span>)
                    return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>)
                }
            },
            {
                title: '任务进度',
                align: 'center',
                width: 150,
                dataIndex: 'detail',
                render: (text, item) => {
                    let taskStatus = item.taskStatus;
                    let status = "success";
                    let content = <Icon type="check-circle" theme="filled" style={{ color: "#52c41a" }} />;
                    if (taskStatus === "run") {
                        status = "active", content = <Icon type="sync" spin style={{ color: "#1890ff" }} />
                    }
                    if (taskStatus === "done") { status = "success" }
                    if (taskStatus === "terminal") {
                        status = "exception"
                        content = <Icon type="close-circle" theme="filled" style={{ color: "#f5222d" }} />
                    }
                    // const elem = taskStatus === "run" ? <div className={"progressRun"}></div> : <Progress
                    //     percent={taskStatus === 'done' ? 100 : 0}
                    //     status={taskStatus === 'done' ? "success" : "normal"}
                    // />;
                    // return elem
                    return <Progress showInfo={true} percent={100} status={status} format={() => (content)} />
                }
            },
            {
                title: '方式',
                align: 'center',
                width: 80,
                dataIndex: 'taskPlan.type',
                render: (text) => {
                    return (<span>{Utils.Translation(text)}</span>)
                }
            }, {
                title: '标签',
                align: 'left',
                width: 120,
                dataIndex: 'tags',
                ellipsis: true,
                render: (text) => {
                    return Utils.hidenTag(text);
                }
            }, {
                title: '开始时间',
                align: 'center',
                width: 150,
                dataIndex: 'taskBeginTime'
            }, {
                title: '下次执行时间',
                align: 'center',
                width: 150,
                dataIndex: 'nextTime'
            }, {
                title: '耗时',
                align: 'center',
                width: 150,
                dataIndex: 'costing',
                render: (text, item) => {
                    let elapsedTime = elapsedTimeObj.hasOwnProperty(item.taskName) ? elapsedTimeObj[item.taskName] : text;
                    // console.log("elapsedTime", text, elapsedTime)
                    return <span>{elapsedTime}</span>
                }
            }, {
                title: '操作',
                align: 'center',
                width: 180,
                dataIndex: 'op',
                fixed: "right",
                render: (text, itemTask) => {
                    return <div>
                        {itemTask.taskStatus === 'run' ?
                            <Button type="danger" size="small"
                                onClick={() => { this.TerminalTask(itemTask._id) }}>
                                <Icon type="pause-circle" />终止</Button>
                            : <Button type="primary" size="small"
                                onClick={() => { this.excuteTask(itemTask._id, itemTask.taskName) }}>
                                <Icon type="play-circle" />执行</Button>}&nbsp;
                        <Dropdown.Button overlay={this.menuButton(itemTask)}
                            type="primary" icon={<Icon type="down" />} size="small"
                            onClick={() => { this.handleMenuClick({ key: "result" }, itemTask) }}
                        ><Icon type="diff" />记录
                        </Dropdown.Button>
                    </div>
                }
            }
        ];
        return (
            <div className="CustomizeListDiv">
                <CustomBreadcrumb arr={["自定义任务", "运维任务"]} />
                <Collapse defaultActiveKey={"key"}>
                    <Collapse.Panel header="筛选条件" key={"key"}>
                        <FilterForm
                            tagClass="task"
                            filterSubmit={(_querys) => {
                                // const reportTags = querys.reportTags;
                                // _querys.reportTags = reportTags;
                                _querys.ifTemplate = false;
                                this.updata(this.state._pageSize, 1, {...querys, ..._querys})
                            }}
                            resetTab={() => {
                                const querys = {
                                    ifTemplate: false,
                                    reportTags: "all"
                                }
                                this.updata(this.state._pageSize, 1, querys)
                            }}
                        />
                    </Collapse.Panel>
                </Collapse>
                <Card
                    // title={<span style={{ fontWeight: 600 }}>自定义运维任务列表</span>}
                    style={{ marginTop: 8 }}
                // extra={
                //     <div>
                //         <Button type="primary"
                //             icon="bars" onClick={() => this.editTaskType()}>任务类型</Button>&nbsp;
                //         <Button type="primary"
                //             icon="edit" onClick={() => {
                //                 let bodyJson = {
                //                     pageNum: _current,      //当前页码数
                //                     pageSize: _pageSize,    //每页显示条数
                //                     querys,    //查询条件
                //                 }
                //                 let path = {
                //                     pathname: `/customizeTask/newlist`,
                //                     bodyJson,
                //                 };
                //                 this.props.history.push(path);
                //             }}>添加任务</Button>
                //     </div>}
                >
                    <Tabs activeKey={actType}
                        type="card"
                        // type="editable-card" 
                        hideAdd
                        tabBarExtraContent={
                            <div>
                                <Radio.Group value={taskStyle} onChange={(e) => {
                                    this.setState({ taskStyle: e.target.value });
                                    socket.setConfig("taskStyle", e.target.value, () => { })
                                }} buttonStyle="solid">
                                    <Radio.Button value="card"><Icon type="credit-card" /></Radio.Button>
                                    <Radio.Button value="list"><Icon type="unordered-list" /></Radio.Button>
                                </Radio.Group>&nbsp;
                                <Button type="primary"
                                    icon="bars" onClick={() => this.editTaskType()}>任务类型</Button>&nbsp;
                                <Button type="primary"
                                    icon="edit" onClick={() => {
                                        let bodyJson = {
                                            pageNum: _current,      //当前页码数
                                            pageSize: _pageSize,    //每页显示条数
                                            querys,    //查询条件
                                        }
                                        let path = {
                                            pathname: `/customizeTask/newlist`,
                                            bodyJson,
                                        };
                                        this.props.history.push(path);
                                    }}>添加任务</Button>
                            </div>
                        }
                        onEdit={(targetKey) => {
                            Modal.confirm({
                                title: "提示",
                                content: "你确定删除类型" + targetKey + "与包含的任务吗？",
                                okType: "danger",
                                onOk() {
                                }
                            })
                        }}
                        onChange={(key) => {
                            this.updata(_pageSize, 1, { ...querys, reportTags: key, ifTemplate: false });
                        }}>
                        <Tabs.TabPane tab={<div><Icon type="setting" />全部</div>} key="all" />
                        {TaskType.map((_type) => {
                            return <Tabs.TabPane closable={true}
                                // tab={<div><Icon type="setting" />{_type}</div>}
                                tab={_type.name}
                                key={_type.name} ></Tabs.TabPane>
                        })}
                    </Tabs>
                    {/* 列表内容 */}
                    {
                        taskStyle === "card" ?
                            <DyCard
                                type="Customize"
                                dataSource={tableData}
                                columns={columns}
                                rowKey={(text) => { return text._id }}
                                size="small"
                                loading={loading}
                                pagination={{
                                    current: _current, pageSize: _pageSize, total: total,
                                    onChange: (page, pageSize) => {
                                        //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                        this.updata(pageSize, page, querys);
                                    }
                                }}
                                handleMenuClick={(e, item) => { this.handleMenuClick(e, item) }}
                                excuteTask={(id, name, page, pageSize) => { this.excuteTask(id, name, page, pageSize) }}
                                TerminalTask={(id, page, pageSize) => { this.TerminalTask(id, page, pageSize) }}
                            /> : <DyHeadTable
                                type="Customize"
                                dataSource={tableData}
                                columns={columns}
                                rowKey={(text) => { return text._id }}
                                size="small"
                                loading={loading}
                                pagination={{
                                    current: _current, pageSize: _pageSize, total: total,
                                    onChange: (page, pageSize) => {
                                        //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                        this.updata(pageSize, page, querys);
                                    }
                                }}
                                handleMenuClick={(e, item) => { this.handleMenuClick(e, item) }}
                                excuteTask={(id, name, page, pageSize) => { this.excuteTask(id, name, page, pageSize) }}
                                TerminalTask={(id, page, pageSize) => { this.TerminalTask(id, page, pageSize) }}
                            />
                    }
                </Card>
                <Modal
                    title="日志"
                    visible={visible_modal}
                    onCancel={() => { this.setState({ visible_modal: false, }); }}
                    onOk={() => { this.setState({ visible_modal: false, }); }}
                    width="95%"
                    style={{ top: 10 }}
                >
                    <MonacoEditor
                        theme="vs-dark"
                        language="java"
                        height="700"

                    />
                </Modal>
                <Modal
                    title={
                        <div>
                            <span>模版：</span>
                            <a onClick={() => {
                                let path = {
                                    pathname: `/newTask:${moduleName._id}`,
                                    bodyJson: {},
                                    actType: this.state.actType
                                };
                                this.props.history.push(path);
                            }}> {moduleName.taskName} </a>
                        </div>
                    }
                    visible={visible_module_flag}
                    onCancel={() => { this.setState({ visible_module_flag: false }); }}
                    onOk={() => { this.setState({ visible_module_flag: false }); }}
                    width="95%"
                    style={{ top: 10, bottom: 10 }}
                >
                    <div>绑定报告：<Button size="small" style={{ border: 0, color: "#1890ff" }} icon="link" onClick={() => { this.openReport(templateObj); }}>{templateObj.name}</Button></div>
                    <FlowChar
                        onRef={(_this) => { this.child = _this }}
                        initValue={flow_data}
                        onFlowDataChange={(data) => {
                            // 导出文件
                            this.setState({ flow_data: data })
                        }}
                    />
                </Modal>
            </div>
        )
    }
}
export default InspectionMan
