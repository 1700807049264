import React from "react";
import {Button, Card, Col, Row, Select} from "antd";
// import 'echarts/lib/chart/line'
import ReactEcharts from 'echarts-for-react'
// import ButtonGroup from "antd/es/button/button-group";
import './index.css'
import Grid from "antd/es/card/Grid";
import moment from "moment";
import {fetchReport1, fetchReport2} from "../../zx-api/report";
import MyButtonGroup from "./button_group";

const ButtonGroup = Button.Group
const {Option} = Select
export default class Report extends React.Component {
    state = {
        btnSelected: 'month'+moment().format("M"),
        btnSelected2: 'month'+moment().format("M"),
        initYear: '2020',
        applyType: 'day',
        applyMonth: moment().format("M"),
        x_data: [],
        fee_data: [],
        bandwidth_data: [],
        counter_data: [],
        min_count: 0,
        max_count: 0,
        min_fee: 0,
        max_fee: 0,
        min_bandwidth: 0,
        max_bandwidth: 0,
        years_total: 0,
        years_index: [],
        months_index: [],
        year2: moment().format('YYYY'), // 第二个报表state
        month2: moment().format('M'),
        type2: 'month',
        x_data2: null,
        fee_data2: null,
        bandwidth_data2: null,
        counter_data2: null,
        months_index2: [],
    }

    componentWillMount() {
        const {initYear, applyType, applyMonth, type2, year2, month2} = this.state
        this.applyReport(applyType, applyMonth, initYear)
        this.applyReport2(type2, month2, year2)
    }

    getOption = () => {
        const colors = ['#2fb7f3', '#d12127', '#2dba05'];

        const option = {
            color: colors,

            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            },
            grid: {
                right: '20%'
            },
            toolbox: {
                feature: {
                    dataView: {show: true, readOnly: false},
                    restore: {show: true},
                    saveAsImage: {show: true}
                }
            },
            legend: {
                data: ['总数量', '总带宽', '总费用']
            },
            xAxis: [
                {
                    type: 'category',
                    axisTick: {
                        alignWithLabel: true
                    },
                    data: this.state.x_data
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: '总数量',
                    min: 0,
                    max: this.state.max_count + 50,
                    position: 'right',
                    axisLine: {
                        lineStyle: {
                            color: colors[0]
                        }
                    },
                    axisLabel: {
                        formatter: '{value} 条'
                    }
                },
                {
                    type: 'value',
                    name: '总带宽',
                    min: 0,
                    max: this.state.max_bandwidth,
                    position: 'right',
                    offset: 80,
                    axisLine: {
                        lineStyle: {
                            color: colors[1]
                        }
                    },
                    axisLabel: {
                        formatter: '{value} Mbps'
                    }
                },
                {
                    type: 'value',
                    name: '总费用',
                    min: 0,
                    max: this.state.max_fee * 1.5,
                    position: 'left',
                    axisLine: {
                        lineStyle: {
                            color: colors[2]
                        }
                    },
                    axisLabel: {
                        formatter: '{value} 万元'
                    }
                }
            ],
            series: [
                {
                    name: '总数量',
                    type: 'line',
                    data: this.state.counter_data
                },
                {
                    name: '总带宽',
                    type: 'line',
                    yAxisIndex: 1,
                    data: this.state.bandwidth_data
                },
                {
                    name: '总费用',
                    type: 'line',
                    yAxisIndex: 2,
                    data: this.state.fee_data
                }
            ]
        };

        return option
    }

    getCharOption1 = () => {
        return {
            title: {
                text: '总数量占比',
                subtext: this.state.month2+'月',
                left: 'center'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                data: this.state.x_data2 || ['电信', '移动', '联通', '盈通', '铁通']
            },
            series: [
                {
                    name: '专线数量占比',
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '60%'],
                    data: this.state.counter_data2 || [
                        {value: 335, name: '联通'},
                        {value: 310, name: '盈通'},
                        {value: 234, name: '铁通'},
                        {value: 1548, name: '电信'}
                    ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(47,183,243,0.52)'
                        }
                    }
                }
            ]
        };
    }

    getCharOption2 = () => {
        return {
            title: {
                text: '总带宽占比',
                subtext: this.state.month2+'月',
                left: 'center'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                data: this.state.x_data2 || ['电信', '移动', '联通', '盈通', '铁通']
            },
            series: [
                {
                    name: '专线带宽占比',
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '60%'],
                    data: this.state.bandwidth_data2 || [
                        {value: 335, name: '联通'},
                        {value: 310, name: '盈通'},
                        {value: 234, name: '铁通'},
                        {value: 1548, name: '电信'}
                    ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
    }

    getCharOption3 = () => {
        return {
            title: {
                text: '总费用占比',
                subtext: this.state.month2+'月',
                left: 'center'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                data: this.state.x_data2 || ['电信', '移动', '联通', '盈通', '铁通']
            },
            series: [
                {
                    name: '专线费用占比',
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '60%'],
                    data: this.state.fee_data2,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
    }

    // 趋势图请求数据
    getData = dataType => {

        switch (dataType) {
            case 'month1': this.setState({btnSelected: 'month1'}); break;
            case 'month2': this.setState({btnSelected: 'month2'}); break;
            case 'month3': this.setState({btnSelected: 'month3'}); break;
            case 'month4': this.setState({btnSelected: 'month4'}); break;
            case 'month5': this.setState({btnSelected: 'month5'}); break;
            case 'month6': this.setState({btnSelected: 'month6'}); break;
            case 'month7': this.setState({btnSelected: 'month7'}); break;
            case 'month8': this.setState({btnSelected: 'month8'}); break;
            case 'month9': this.setState({btnSelected: 'month9'}); break;
            case 'month10': this.setState({btnSelected: 'month10'}); break;
            case 'month11': this.setState({btnSelected: 'month11'}); break;
            case 'month12': this.setState({btnSelected: 'month12'}); break;
            case 'year': this.setState({btnSelected: 'year'}); break;
            case 'month': this.setState({btnSelected: 'month'}); break;

            default: break;
        }


        const {initYear} = this.state
        let applyMonth = parseInt(dataType.replace('month',''))
        if (dataType === 'month' || dataType === 'year') {
            this.setState({applyType: dataType})
            this.applyReport(dataType, 1, initYear )
        } else {
            this.setState({applyMonth, applyType: 'day'});
            this.applyReport('day', applyMonth, initYear);
        }
    }

    handChange = value => {
        this.setState({initYear: value})
        const {applyType, applyMonth, } = this.state
        this.applyReport(applyType, applyMonth, value)
    }

    applyReport = (type, month, year) => {
        let query = {
            'type': type,
            'time': moment(new Date(year+"-"+month)).format("YYYY-M")
        }
        fetchReport1(query, res => {
            res = res.data.data
            this.setState({years_index: [], ...res})

        })
    }

    getData2 = e => {
        if (e === 'year'){
            this.setState({type2: 'year'})
            this.applyReport2('year', 1, this.state.year2)
        }
        else {
            this.setState({month2: e, btnSelected2: e})
            this.applyReport2(this.state.type2, e, this.state.year2)
        }
    }

    setYear2 = e => {
        this.setState({year2: e})
        this.applyReport2(this.state.type2, this.state.month2, e)
    }

    applyReport2 = (type, month, year, times) => {
        let query = {
            'type': type,
            'time': moment(new Date(year+"-"+month)).format("YYYY-M")
        }

        fetchReport2(query, (res)=>{
            res = res.data.data
            this.setState({...res})
            if (!times && times !== 2) {
                const monthArr = res.months_index2.map(item=>{
                    return parseInt(item)
                })
                if (!monthArr.includes(parseInt(this.state.month2))){
                    // alert(1)
                    this.setState({month2: monthArr[0], btnSelected2: monthArr[0].toString()})
                    this.applyReport2(this.state.type2, monthArr[0], this.state.year2, 2)
                } else {
                    // alert(month)
                    this.setState({month2: monthArr[0].toString(), btnSelected2: month.toString()})
                }
            }else {
                this.setState({btnSelected2: month.toString()})
            }
        })

    }

    render() {

        const {years_index, months_index, months_index2, btnSelected2} = this.state
        return (
            <div>
                <Row>
                    <Col span={24}>
                        <Card title="趋势" >
                            <Row>
                                <Col span={24} offset={0} style={{textAlign: 'center',}}>
                                    {/*<span className='btn'>*/}
                                    <ButtonGroup className='btnGroup' hoverable='true'>
                                        <Button disabled={1>months_index.length} style={{backgroundColor: this.state.btnSelected === 'month1' ?  '#81c4ff' : null, color: this.state.btnSelected === 'month1' ? '#fff' : null}} onClick={this.getData.bind(this, 'month1')}>1月</Button>
                                        <Button disabled={2>months_index.length} style={{backgroundColor: this.state.btnSelected === 'month2' ?  '#81c4ff' : null, color: this.state.btnSelected === 'month2' ? '#fff' : null}} onClick={this.getData.bind(this, 'month2')}>2月</Button>
                                        <Button disabled={3>months_index.length} style={{backgroundColor: this.state.btnSelected === 'month3' ?  '#81c4ff' : null, color: this.state.btnSelected === 'month3' ? '#fff' : null}} onClick={this.getData.bind(this, 'month3')}>3月</Button>
                                        <Button disabled={4>months_index.length} style={{backgroundColor: this.state.btnSelected === 'month4' ?  '#81c4ff' : null, color: this.state.btnSelected === 'month4' ? '#fff' : null}} onClick={this.getData.bind(this, 'month4')}>4月</Button>
                                        <Button disabled={5>months_index.length} style={{backgroundColor: this.state.btnSelected === 'month5' ?  '#81c4ff' : null, color: this.state.btnSelected === 'month5' ? '#fff' : null}} onClick={this.getData.bind(this, 'month5')}>5月</Button>
                                        <Button disabled={6>months_index.length} style={{backgroundColor: this.state.btnSelected === 'month6' ?  '#81c4ff' : null, color: this.state.btnSelected === 'month6' ? '#fff' : null}} onClick={this.getData.bind(this, 'month6')}>6月</Button>
                                        <Button disabled={7>months_index.length} style={{backgroundColor: this.state.btnSelected === 'month7' ?  '#81c4ff' : null, color: this.state.btnSelected === 'month7' ? '#fff' : null}} onClick={this.getData.bind(this, 'month7')}>7月</Button>
                                        <Button disabled={8>months_index.length} style={{backgroundColor: this.state.btnSelected === 'month8' ?  '#81c4ff' : null, color: this.state.btnSelected === 'month8' ? '#fff' : null}} onClick={this.getData.bind(this, 'month8')}>8月</Button>
                                        <Button disabled={9>months_index.length} style={{backgroundColor: this.state.btnSelected === 'month9' ?  '#81c4ff' : null, color: this.state.btnSelected === 'month9' ? '#fff' : null}} onClick={this.getData.bind(this, 'month9')}>9月</Button>
                                        <Button disabled={10>months_index.length} style={{backgroundColor: this.state.btnSelected === 'month10' ?  '#81c4ff' : null, color: this.state.btnSelected === 'month10' ? '#fff' : null}} onClick={this.getData.bind(this, 'month10')}>10月</Button>
                                        <Button disabled={11>months_index.length} style={{backgroundColor: this.state.btnSelected === 'month11' ?  '#81c4ff' : null, color: this.state.btnSelected === 'month11' ? '#fff' : null}} onClick={this.getData.bind(this, 'month11')}>11月</Button>
                                        <Button disabled={12>months_index.length} style={{backgroundColor: this.state.btnSelected === 'month12' ?  '#81c4ff' : null, color: this.state.btnSelected === 'month12' ? '#fff' : null}} onClick={this.getData.bind(this, 'month12')}>12月</Button>
                                        <Button style={{backgroundColor: this.state.btnSelected === 'month' ?  '#81c4ff' : null, color: this.state.btnSelected === 'month' ? '#fff' : null}} onClick={this.getData.bind(this, 'month')}>按月</Button>
                                        <Button style={{backgroundColor: this.state.btnSelected === 'year' ?  '#81c4ff' : null, color: this.state.btnSelected === 'year' ? '#fff' : null}} onClick={this.getData.bind(this, 'year')}>按年</Button>
                                        <Select size={"default"} defaultValue={this.state.initYear} onChange={this.handChange} style={{marginLeft: 5, width: '7em'}}>
                                            {
                                                years_index.length && years_index.map((item, index)=>(
                                                    <Select.Option key={index} value={item}>{item}年</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </ButtonGroup>

                                    {/*</span>*/}
                                </Col>
                            </Row>
                            <ReactEcharts style={{height: 400, marginTop: 16}} option={this.getOption()}/>
                        </Card>
                    </Col>
                </Row>
                <Row style={{marginTop: 8}}>
                    <Col span={24}>
                        <Card title="饼图">
                            <Row>
                                <MyButtonGroup setYear2={this.setYear2} getData={this.getData2} months_index={months_index2} years_index={years_index} btnSelected={btnSelected2} />
                            </Row>
                            <Row style={{marginTop: 15}}>
                                <Col span={8}>
                                    <Card  style={{marginLeft: 8}}>
                                        <ReactEcharts option={this.getCharOption1()}/>
                                    </Card>
                                </Col>
                                <Col span={8} >
                                    <Card style={{marginLeft: 8}}>
                                        <ReactEcharts option={this.getCharOption2()}/>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card style={{marginLeft: 8}}>
                                        <ReactEcharts option={this.getCharOption3()}/>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                {/*<Row style={{marginTop: 8}}>*/}
                {/*    <Col span={6}>*/}
                {/*        <Card title="线路资费明细及汇总">*/}
                {/*            <Button type='primary' >导出线路资费明细及汇总表</Button>*/}

                {/*        </Card>*/}
                {/*    </Col>*/}
                {/*    <Col span={6}>*/}
                {/*        <Card title="网点线路维护表" style={{marginLeft: 8}}>*/}
                {/*            <Button type='primary' >导出网点线路维护表</Button>*/}
                {/*        </Card>*/}
                {/*    </Col>*/}
                {/*    <Col span={6}>*/}
                {/*        <Card title="线路新增明细及汇总" style={{marginLeft: 8}}>*/}
                {/*            <Button type='primary' >导出线路新增明细及汇总</Button>*/}

                {/*        </Card>*/}
                {/*    </Col>*/}
                {/*    <Col span={6}>*/}
                {/*        <Card title="线路撤销明细及汇总">*/}
                {/*            <Button type='primary' >导出线路撤销明细及汇总</Button>*/}

                {/*        </Card>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </div>
        );
    }


};