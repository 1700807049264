import React, { useState, useEffect } from 'react'
import {
    // DatePicker,
    SubmitButton,
    ResetButton,
    Form,
    FormItem,
    Select,
} from "formik-antd"
const FormikInput = require("formik-antd").Input;
import { Card, Collapse, } from "antd";
import { Formik } from "formik"
import Socket from '@/socket/index';
import { connect } from 'react-redux'
function SearchCard(props) {
    const [TaskType, setTaskType] = useState([]);
    const [initialValues,] = useState({
        type: "",
        username: "",
        task_name: "",
    });
    useEffect(() => {//componentDidMount
        getTaskType();
        return () => { //componentWillUnmount
        }
    }, []);
    const formItemData = [
        ["task_name", "任务名称",],
        ["username", "创建人",],
        // ["create_time", "创建时间",],

    ]
    function getTaskType() {
        Socket.ExecuteAction('QueryTaskTypes', {}, "", (result) => {
            console.log(result);
            setTaskType(result.data);
        });
    }
    const formItemRender = function (data) {
        return data.map((item, key) => {
            return <FormItem
                key={key}
                name={item[0]}
                label={item[1]}
                required={false}
            >
                <FormikInput name={item[0]} placeholder={item[1]} />
            </FormItem>
        })
    }
    return <Card className="aclSearchCard">
        {/* <Collapse defaultActiveKey={"1"} className="aclSearchCard">
         <Collapse.Panel header="筛选条件" key="1"> */}
        <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                console.log(values)
                props.onSubmit(values);
                actions.setSubmitting(false)
            }}
        >
            {(props2) => {
                return <Form
                    layout="inline"
                >
                    {formItemRender(formItemData)}
                    <FormItem
                        name={"type"}
                        label={"任务类型"}
                    >
                        <Select
                            name={"type"}
                            style={{ width: 180 }} >
                            {TaskType.map((item, key) => {
                                return <Select.Option key={item.name} value={item.name}>{item.name}</Select.Option>
                            })}
                        </Select>
                    </FormItem>
                    <SubmitButton disabled={false} icon="search">查询</SubmitButton>&nbsp;
                            <ResetButton disabled={false} icon="reload" onClick={() => { props.reSet(); }}>重置</ResetButton>
                </Form>

            }}
        </Formik>
    </Card>
    {/* </Collapse.Panel>
    </Collapse> */}
}
export default connect()(React.memo(SearchCard))
