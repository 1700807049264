import React, { useState, useEffect } from 'react'
import {
    Card, Table, Input, Button, Icon, message, notification, Modal,
    Row, Col, Popconfirm, Collapse, Tooltip, Tag, Descriptions, List
} from "antd";
import Socket from "@/socket/index";
import ResUtils from "@/utils/ResUtils";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import axios from '@/axios/axios_utils';
import SearchTree from './seachTree';
import moment from 'moment';
const macAddress_url = "http://" + backup_address + "/ZJNMS_V1/";
import "./index.scss"
const { confirm } = Modal;

function HisLedger(props) {
    const [TableData, setTableData] = useState([]);
    const [DevicesData, setDevicesData] = useState([]);
    const [listData, setListData] = useState([]);
    const [macVis, setMacVis] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [_current, set_current] = useState(1);
    const [_pageSize, set_pageSize] = useState(20);
    const [_total, set_total] = useState(60);
    const [_IP, set_IP] = useState("");
    useEffect(() => {//componentDidMount
        console.log(props)
        let bodyJson = props.location.bodyJson;
        if (bodyJson) {
            updataLedger(_current, _pageSize, { switch_ip: bodyJson.ip });
        } else {
            props.history.go(-1);
        }

        return () => { //componentWillUnmount
            // console.log('componentWillUnmount')
        }
    }, []);
    // 设置搜索默认时间为当天
    function getTimeDistance() {
        const now = new Date();
        const oneDay = 1000 * 60 * 60 * 24;
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        let today = [moment(now), moment(now.getTime() + (oneDay - 1000))];
        return today
    }
    const updataLedger = (page, pageSize, querys) => {
        let bodyJson = {};
        bodyJson.page_no = page || _current;
        bodyJson.page_size = pageSize || _pageSize;
        if (querys) {
            bodyJson = { ...bodyJson, ...querys }
        }
        console.log(bodyJson)
        axios.get(macAddress_url + "hub/macAddrOfSwitch", bodyJson, (res) => {
            if (res.code === "00") {
                setDevicesData(res.data);
                setTableData(res.data[0] ? res.data[0].port : []);
                set_IP(querys.switch_ip);
            } else {
                Utils.showMessage("error", "查询失败", error);
            }
        })
    }
    const getTabData = (_data) => {
        let tabElem = <table id="ledgerTab">
            <tbody>
                <tr><th>端口号</th><th>MAC地址</th><th>对应IP地址</th></tr>
                {_data.map((item, key) => {
                    return <tr key={key}><td>{item[0]}</td><td>{item[1]}</td><td>{item[2]}</td></tr>
                })}
            </tbody>
        </table>
        return tabElem;
    };
    const openModal = (sourceData) => {
        confirm({
            title: _IP + 'MAC地址信息',
            content: <div style={{ maxHeight: 700, overflowY: "auto" }}><SearchTree treeData={sourceData} /></div>,
            okText: '确定',
            cancelText: '取消',
            maskClosable: true,
            width: "50%",
            icon: "file-text",
            style: { top: 10 },
            onOk() {
            },
            onCancel() { },
        });
        setTimeout(function () {
            Utils.mixTable("ledgerTab");
        }, 10)
    }

    const onSubmitButton = (querys) => {
        let bodyJson = {};
        let begin = querys.timeRange ? new Date(querys.timeRange[0]._d).getTime() : '';
        let end = querys.timeRange ? new Date(querys.timeRange[1]._d).getTime() : '';
        let ip = props.location.bodyJson ? props.location.bodyJson.ip : "";
        if (begin && end) {
            bodyJson.begin = begin;
            bodyJson.end = end;
        }
        console.log(bodyJson)
        updataLedger(ip);
    }

    const formList = [
        {
            label: '时间',
            type: 'datePicker',
            field: 'ip',
            // initialValue: getTimeDistance(),
            initialValue: '',
            placeholder: '请选择时间范围'
        },
    ]
    const columns = [
        {
            title: '序号',
            align: 'center',
            dataIndex: 'key',
            ellipsis: true,
            width: 70,
            render: (text, a, b) => { return <span>{b + 1 + (_current - 1) * _pageSize}</span> }
        },
        {
            title: '端口名',
            ellipsis: true,
            dataIndex: 'port_name',
        },
        {
            title: '端口工作模式',
            ellipsis: true,
            dataIndex: 'admin_mode',
        },
        {
            title: '是否是可疑接入',
            ellipsis: true,
            dataIndex: 'is_suspicious',
            render: (text, item, index) => {
                switch (text) {
                    case true: return (<span>是</span>); break;
                    case false: return (<span>否</span>); break;
                }
            }
        },
        {
            title: '所在VLAN',
            ellipsis: true,
            dataIndex: 'vlan',
        },
        {
            title: 'mac地址',
            ellipsis: true,
            dataIndex: 'mac',
            width: 240,
            render: (text, item, index) => {
                if (Array.isArray(text)) {
                    if (text.length <= 1) {
                        return (<span>{text.join('，')}</span>)
                    } else {
                        return (
                            <span>
                                {text.slice(0, 1).join('，')}
                                <Button type="link" size="small" style={{ padding: 0 }} onClick={() => { setMacVis(true); setListData(text); }}>...更多</Button>
                            </span>
                        )
                    }
                }
            }
        },
    ];
    return <div>
        <CustomBreadcrumb
            arr={["运维管理", { title: "MAC地址管理", to: "/Ledger" }, "MAC地址记录"]}
        />
        <Card style={{ padding: "5px 10px" }}>
            <div className="devicesInfoTable">
                <Descriptions title="设备基本信息" column={3} bordered>
                    <Descriptions.Item label={"资源名称"} span={1} >{DevicesData[0] ? DevicesData[0].resource_ip : ""}</Descriptions.Item>
                    <Descriptions.Item label={"设备名称"} span={1} >{DevicesData[0] ? DevicesData[0].resource_params.deviceId : ""}</Descriptions.Item>
                    <Descriptions.Item label={"区域"} span={1} >{DevicesData[0] ? DevicesData[0].resource_params.deviceSite : ""}</Descriptions.Item>
                    <Descriptions.Item label={"设备IP"} span={1} >{DevicesData[0] ? DevicesData[0].resource_params.deviceIp : ""}</Descriptions.Item>
                    <Descriptions.Item label={"设备类型"} span={1} ><div>{DevicesData[0] ? Utils.Translation(DevicesData[0].resource_params.deviceType) : ""}</div></Descriptions.Item>
                    <Descriptions.Item label={"设备OS类型"} span={1} >{DevicesData[0] ? DevicesData[0].resource_params.deviceOsType : ""}</Descriptions.Item>
                    <Descriptions.Item label={"设备厂商"} span={1} >{DevicesData[0] ? DevicesData[0].resource_params.deviceFirm : ""}</Descriptions.Item>
                    <Descriptions.Item label={"设备状态"} span={1} >{DevicesData[0] ? Utils.Translation(DevicesData[0].resource_params.deviceState) : ""}</Descriptions.Item>
                    <Descriptions.Item label={"设备型号"} span={1} >{DevicesData[0] ? DevicesData[0].resource_params.deviceVersion : ""}</Descriptions.Item>
                    <Descriptions.Item label={"创建时间"} span={1} >{DevicesData[0] ? DevicesData[0].create_time : ""}</Descriptions.Item>
                    <Descriptions.Item label={"ssh端口号"} span={1} >{DevicesData[0] ? DevicesData[0].resource_params.sshport : ""}</Descriptions.Item>
                    <Descriptions.Item label={"标签"} span={1} >{DevicesData[0] ?
                        DevicesData[0].resource_tags.map(tags => {
                            return <Tag color="blue" style={{ marginTop: 2, marginBottom: 2 }}>{tags}</Tag>
                        })
                        : []}</Descriptions.Item>
                    {/* <Descriptions.Item label={"所有mac地址"} span={1} >
                        <Button type="primary" size="small" icon="edit" onClick={() => { setMacVis(true); setListData(DevicesData[0].all_mac_address); }}>查看全部mac地址</Button>
                    </Descriptions.Item> */}
                    <Descriptions.Item label={"设备OS版本"} span={3} >{DevicesData[0] ? DevicesData[0].resource_params.deviceOsVersion : ""}</Descriptions.Item>
                </Descriptions> <p />
            </div>
        </Card>
        <Card title={<span style={{ fontWeight: 600 }}>MAC地址记录</span>}
            style={{ marginTop: 8 }}
            extra={<div>
                <Button type="primary" icon={'rollback'}
                    onClick={() => {
                        let path = {
                            pathname: `/Ledger`,
                            bodyJson: props.location.bodyJson,
                        };
                        props.history.push(path);
                    }}>返回设备列表</Button>
            </div>}
        >
            <Table
                dataSource={TableData}
                // rowSelection={rowSelection}
                columns={columns}
                loading={Loading}
                rowKey={(text) => { return text.port_name }}
                size="small"
                pagination={{
                    current: _current, pageSize: _pageSize,
                    showTotal: (total) => { return <span>共 {total} 项</span> },
                    pageSizeOptions: ["20", "50", "100", "500", "1000"],
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) => {
                        set_pageSize(size);
                        set_current(1);
                    },
                    onChange: (page, pageSize) => {
                        //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                        // console.log(page, pageSize);
                        set_current(page);
                    }
                }}
            />

        </Card>
        <Modal
            title="查看mac地址"
            visible={macVis}
            onCancel={() => { setMacVis(false); setListData([]) }}
            onOk={() => { setMacVis(false); setListData([]) }}
            destroyOnClose
        >
            <List
                bordered
                dataSource={listData}
                style={{ maxHeight: "400px", overflow: "auto" }}
                renderItem={item => (
                    <List.Item> {item} </List.Item>
                )}
            />
        </Modal>
    </div>
}
export default HisLedger