import React from 'react';
import { Card, Button, Modal, Tabs, Tag, Upload, Icon, message, Table, Tooltip, Row, Col, } from 'antd';
import AxiosObj from '@/axios/index';
import axios_temp from '@/axios/axios_utils';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import AddTable from '@/components/AddTable/index'
import FormModal from '@/components/EditModal';
import "./index.scss";
import * as XLSX from 'xlsx';
import Utils from "@/utils/utils";
import { SubmitButton, FormItem, InputNumber, Select, AddRowButton, RemoveRowButton, } from "formik-antd";
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
const FormikTable = require("formik-antd").Table;

import { Formik } from "formik";
import moment from "moment";
const smsUrl = "http://" + backup_address + "/ZJNMS_V1/sms_monitor/";
export default class HostPING extends React.Component {
    state = {
        tags: [],
        inputVisible: false,
        conVis: false,
        editHostPingVis: false,
        historyVis: false,
        inputValue: '',
        configData: {},
        current: 1,
        pageSize: 20,
        tableData: [],
        loading: false,
        total: 0,
        selectedRowKeys: [],
    };
    componentWillMount() {
        this.getHistoryList()
    }
    editHostPing(value) {
        console.log(value)
        // value.begin_time = moment(value.begin_time).format("HH:mm:ss")
        // value.end_time = moment(value.end_time).format("HH:mm:ss")
        console.log(value)
        axios_temp.post(smsUrl + "config", value, (res) => {
            if (res.recode === "00") {
                message.success("修改成功");
                this.setState({
                    editHostPingVis: false,
                })
            } else {
                message.error("修改失败");
            }
        })
    }
    getHostConfig() {
        axios_temp.get(smsUrl + "config", {}, (res) => {
            if (res.recode === "00") {
                this.setState({
                    configData: res.config,
                    editHostPingVis: true
                })
            } else {
                message.error("查询失败");
                this.setState({ editHostPingVis: true })
            }
        })
    }
    getHistoryList(page, page_size) {
        this.setState({ loading: true })
        let { current, pageSize } = this.state;
        let bodyJson = {
            page_no: page || current,
            page_size: page_size || pageSize
        }
        axios_temp.get(smsUrl + "send_record", bodyJson, (res) => {
            if (res.code === "00") {
                this.setState({
                    tableData: res.data,
                    total: res.count,
                    current: page || 1,
                    pageSize: page_size || pageSize,
                    loading: false
                })
            } else {
                message.error("查询失败");
                this.setState({ loading: false })
            }
        })
    }
    deleteHistory(ids) {
        let _this = this;
        if (ids.length === 0) {
            message.error("请选择需要删除的记录！")
        } else {
            Modal.confirm({
                title: '消息提示',
                content: '你确定删除该记录吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    axios_temp.delete_body(smsUrl + "send_record", { _ids: ids }, (res) => {
                        console.log(res)
                        if (res.code === "00") {
                            message.success(res.msg);
                            _this.setState({ selectedRowKeys: [] });
                            _this.getHistoryList();
                        } else {
                            message.error("删除失败")
                        }
                    })
                },
            });
        }
    }
    render() {
        const { tags, conVis, editHostPingVis, tableData, loading, current, pageSize, total, configData, selectedRowKeys } = this.state;

        const columns = [
            {
                title: '序号',
                width: 60,
                align: 'left',
                dataIndex: 'key',
                render: (text, item, index) => {
                    return <span>{index + 1 + (current - 1) * pageSize}</span>
                }
            },
            {
                title: '联系人列表',
                // align: 'left',
                dataIndex: 'phones',
                // width: 240,
                ellipsis: true,
                render: (text) => {
                    console.log(text)
                    return <div className="phoneDiv">
                        {text.map((p, i) => {
                            return <Tag>{p}</Tag>
                        })}
                        {/* <Table
                            columns={[{ title: "号码", dataIndex: "phone" }, { title: "区域", dataIndex: "area" }, { title: "类型", dataIndex: "type" }]}
                            dataSource={text}
                            pagination={false}
                            scroll={{ y: 200 }}
                            size="small"
                        /> */}
                    </div>
                }
            },

            // {
            //     title: '丢包率发送阈值',
            //     align: 'center',
            //     dataIndex: 'loss_rate',
            //     width: 120,
            //     ellipsis: true,
            //     render: (text, item, index) => {
            //         return text * 100 + "%"
            //     }
            // },
            {
                title: '发送状态',
                align: 'center',
                dataIndex: 'status',
                // width: 100,
                ellipsis: true,
                render: (text, item) => {
                    switch (text) {
                        case "error": return <Tooltip title={item.tips}><Tag color="red">失败</Tag></Tooltip>;
                        case "ok": return <Tooltip title={item.tips}><Tag color="green">成功</Tag></Tooltip>;
                    }
                }
            },
            {
                title: '发送内容',
                align: 'left',
                dataIndex: 'content',
                // width: 120,
                ellipsis: true,
                render: (text) => {
                    return <div className="msgDiv">{text}</div>
                }
            },
            {
                title: '时间',
                align: 'center',
                dataIndex: 'time',
                // width: 220,
                ellipsis: true,
            },
            {
                title: '操作',
                align: 'center',
                dataIndex: 'op',
                width: 100,
                render: (text, item) => {
                    return <Button type="danger" size="small" icon="delete" onClick={() => this.deleteHistory([item._id])}>删除</Button>
                }
            },
        ]
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };

        return <div className="hostPingDiv">
            <CustomBreadcrumb arr={["通知管理", "短信通知"]} />
            <Card title="短信通知" extra={<div>
                <Button type="primary" icon="edit" onClick={() => { this.getHostConfig(); }}>
                    短信配置</Button>&nbsp;
                <Button type="danger" icon="delete" onClick={() => this.deleteHistory(selectedRowKeys)}>批量删除</Button>

            </div>}>
                <div className="hostpingHistory">
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        loading={loading}
                        size="small"
                        rowKey={(text) => { return text._id }}
                        // expandedRowRender={record => 
                        //     <Table
                        //         dataSource={record.ip_list || []}
                        //         columns={[{ title: "IP地址", dataIndex: "ip" }, { title: "丢包率", dataIndex: "packet_loss" }]}
                        //         size="small"
                        //         pagination={false}
                        //     />
                        // }
                        rowSelection={rowSelection}
                        pagination={{
                            current: current, pageSize: pageSize, total: total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.getHistoryList(1, size)
                            },
                            onChange: (page, pageSize) => {
                                // console.log(page, pageSize);
                                this.getHistoryList(page, pageSize)
                            }
                        }}
                    />
                </div>

            </Card>

            <FormModal
                title="编辑短信配置"
                visible={editHostPingVis}
                onCancel={() => { this.setState({ editHostPingVis: false }) }}
                onSubmit={(val) => { this.editHostPing(val); }}
                footer={null}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 20 }}
                initialValues={configData}
                width="60%"
                style={{ top: 20 }}
                destroyOnClose
                formList={[
                    { type: "input", name: "sms_address", label: "短信猫服务器地址", required: true, placeholder: "短信猫服务器地址", },
                    // {
                    //     type: "expandForm", name: "phones", label: "联系人信息列表",
                    //     labelCol: { span: 3 },
                    //     required: true,
                    //     wrapperCol: { span: 20 },
                    //     formContent: <div style={{ textAlign: "center" }}>
                    //         <FormikTable
                    //             name="phones"
                    //             rowKey={(row, index) => (index)}
                    //             size="small"
                    //             scroll={{ y: 400, }}
                    //             pagination={false}
                    //             columns={[
                    //                 {
                    //                     width: 50,
                    //                     title: "序号",
                    //                     align: "center",
                    //                     key: "序号",
                    //                     render: (text, record, i) => (i + 1),
                    //                 },
                    //                 {
                    //                     title: "电话号码",
                    //                     align: "center",
                    //                     key: "phone",
                    //                     render: (text, record, i) => (<Select style={{ border: "none" }} mode="tags" name={`phones.${i}.phone`} />),
                    //                 },
                    //                 {
                    //                     title: "区域",
                    //                     align: "center",
                    //                     key: "area",
                    //                     render: (text, record, i) => (<FormikInput style={{ border: "none" }} name={`phones.${i}.area`} />),
                    //                 },
                    //                 {
                    //                     title: "类型",
                    //                     key: "type",
                    //                     render: (text, record, i) => (<Select style={{ border: "none" }} name={`phones.${i}.type`} >
                    //                         <Select.Option value="ping">ping</Select.Option>
                    //                         <Select.Option value="card">card</Select.Option>
                    //                         <Select.Option value="none">none</Select.Option>
                    //                     </Select>),
                    //                 },
                    //                 {
                    //                     width: 50,
                    //                     key: "操作",
                    //                     align: "center",
                    //                     render: (text, record, index) => (<RemoveRowButton style={{ border: "none" }} icon="delete" name="phones" index={index} />),
                    //                 },
                    //             ]}
                    //         />
                    //         <AddRowButton
                    //             name="phones"
                    //             style={{ width: "100%" }}
                    //             createNewRow={() => ({
                    //                 key: "key" + new Date().getTime(),
                    //                 phone: "",
                    //                 area: "",
                    //                 type: "none",
                    //             })}
                    //         > +添加参数
                    //   </AddRowButton>
                    //     </div>
                    // },
                ]}
            />
        </div>
    }
}