import React from 'react';
import Exception from '@/components/Exception';

const Exception403 = () => (
  <Exception
    type="403"
    desc={"token过期了"}
    linkElement={null}
    linkTo={"/login"}
    backText={"重新登录"}
  />
);

export default Exception403;
