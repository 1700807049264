import React from 'react'
import {Card, Select, Input, Button, Form, message, notification, Icon, Row, Col, Modal, Tabs} from 'antd';
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import AxiosObj from "../../../axios";
import  EmailForm  from './emailForm';

// monitor_address 配置地址：public/flow/config.js
const backUp_Url = monitor_address;

class addReceiver extends React.Component {
    state = {
        key : 'webhook',   //默认显示webhook配置
        configName : '',
        receiver : {
            name: '',
        },
        route: {
            group_wait: "10s",
            receiver: "",
            // match: {
            //     "id": "1"  //删除时唯一标识
            // }
        }
    };


    //表格提交(webhook)
    handleSubmit = (e) => {
        let { receiver,route } = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                //let body = values;
                receiver.name = values.name;//
                let configName = 'webhook_configs';
                let map ={};
                let list =[];
                map['url'] = values.url;
                map['send_resolved'] = true;
                list[0] = map;
                receiver[configName] = list;
                // console.log(receiver);
                let body ={};
                route["receiver"] = values.name;
                body["route"] = route;
                body['receiver'] = receiver;
                console.log(body);
                //POST 方法第一个参数是与axios中地址的选择
                AxiosObj.HTTP_POST('alarm','/snmpexporter/addReceiver',body,(data)=>{
                    message.success('success');
                    this.props.history.goBack();
                },(error)=>{
                    message.error(error);
                });
                console.log('finish');
            }
        });
    };

    onTabChange = (key, type) => {
        console.log(key, type);
        this.setState({ [type]: key });
    };
    render(){
        const tabList = [
            {
                key: 'webhook',
                tab: 'webhook',
            },
            {
                key: 'email',
                tab: 'email',
            },
        ];
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        const { receiver } = this.state;
        const contentList = {
            webhook: <div>
                <Card title={<span style={{ fontWeight: 600 }}>webhook配置</span>}>
                    <Row>
                        <Col span={16}>
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Item
                                    label="名称"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('name', {
                                        initialValue: receiver.name || "",
                                        rules: [{
                                            type: 'string', message: '请输入正确字符！',
                                        }, {
                                            pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                        }, {
                                            required: true, message: '请输入字符!',
                                        }],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item
                                    label="地址"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('url', {
                                        initialValue:  "",
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label=" "
                                    colon={false}
                                    {...formItemLayout}
                                >
                                    <div >
                                        <Button type="primary" icon="check-circle" htmlType="submit" style={{marginLeft:100,  width: 150 }}>
                                            保存
                                        </Button>
                                        {/*<Button type="danger" icon="close-circle" style={{ width: 150, marginLeft: 10 }}*/}
                                                {/*onClick={() => { this.props.history.goBack(); }}>*/}
                                            {/*取消*/}
                                        {/*</Button>*/}
                                    </div>
                                </Form.Item>

                            </Form>

                        </Col>
                        <Col span={8}>
                        </Col>
                    </Row>
                </Card>
            </div>,
            email:  <EmailForm/>
        };

        return(
            <div>
                <CustomBreadcrumb arr={["监控告警", "告警",{to:'/monitoringAlarm/alarm/setting',title:"告警设置"},"添加接收者"]} />
                <Card
                    style={{ width: '100%', marginTop: 30  }}
                    title={<span style={{ fontWeight: 600 }}>添加接受者</span>}
                    tabList={tabList}
                    activeTabKey={this.state.key}
                    onTabChange={key => {
                        this.onTabChange(key, 'key');
                    }}
                >
                    {contentList[this.state.key]}
                </Card>
            </div>
        )
    }
}

export default Form.create()(addReceiver);