import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Menu, List, Dropdown, Tooltip, Badge, Tag, Modal, Checkbox, Divider, Spin
} from "antd";
import http from '@/axios/axios_utils';
import axios2 from 'axios';
import EditModal from '@/components/EditModal/index';
import { connect } from 'react-redux'
import Utils from "@/utils/utils";
import api from '@/config/api/backup';
import "./index.scss"
import "animate.css"
import FilterForm from '@/components/FilterForm/index';
import DyHeadTable from '@/components/DyHeadTable/index';
const BASEURL = "http://" + backup_address + "/ZJNMS_V1/backup/";
function BackupFtp(props) {
    const [visible, setVisible] = useState(false);
    const [VisFile, setVisFile] = useState(false);
    const [files_spinning, setfiles_spinning] = useState(false);
    const [checkAll, setcheckAll] = useState(false);
    const [indeterminate, setindeterminate] = useState(false);
    const [selectItem, setSelectItem] = useState(null);
    const [tableData, setTabData] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [deviceFiles, setDeviceFiles] = useState([])
    const [checkedList, setCheckedList] = useState([])
    const [_pageSize1, setPageSize] = useState(20)
    const [_current1, setPageNum] = useState(1)
    const [_total1, setTotal] = useState(0)
    const [_querys, setQuery] = useState({})
    const [selectDetail, setSelectDetail] = useState({})
    const [VisSetting, setVisSetting] = useState(false)
    const sysname = sessionStorage.getItem('userName') + "/" + sessionStorage.getItem('nickName')
    let thischiild = null
    const columns = [
        {
            title: '设备IP',
            dataIndex: 'ip',
            ellipsis: true,
            render: (text, item) => {
                let sta = "default";
                let title = "状态未知";
                if (item.deviceState === "online") {
                    sta = "success"; title = "在线";
                } else if (item.deviceState === "offline") {
                    sta = "error"; title = "离线";
                }
                return <div><Tooltip title={title}><Badge status={sta} /></Tooltip>{text}</div>
            }
        },
        // {
        //     title: '自动备份状态',
        //     dataIndex: 'whether_backup',
        //     width: 100,
        //     align: "center",
        //     render: (text) => {
        //         if (text === 0) {
        //             return <Tooltip title="自动备份关闭">
        //                 <Icon type="close-circle" theme="filled" style={{ color: "red" }} />&nbsp;关闭
        //             </Tooltip>
        //         } else if (text === 1) {
        //             return <Tooltip title="自动备份开启">
        //                 <Icon type="check-circle" style={{ color: "#87d068" }} theme="filled" />&nbsp;开启
        //             </Tooltip>
        //         } else {
        //             return <Tooltip title="自动备份暂停">
        //                 <Icon type="close-circle" theme="filled" style={{ color: "red" }} />&nbsp;关闭
        //             </Tooltip>
        //         }
        //     }
        // },
        {
            title: '备份状态',
            dataIndex: 'backup_state',
            render: (a) => {
                let ele = "";
                if (typeof a === "object") {
                    switch (a.code) {
                        case "00": ele = <Tooltip title={a.msg}><Tag color="#87d068">完成</Tag></Tooltip>; break;
                        case "01": ele = <Tooltip title={a.msg}><Tag color="#f50">失败</Tag></Tooltip >; break;
                        case "02": ele = <Tooltip title={a.msg}><Tag color="#108ee9">备份中</Tag></Tooltip >; break;
                        case "03": ele = <Tooltip title={a.msg}><Tag >未开始</Tag></Tooltip >; break;
                    }
                }
                return ele
            }
        },
        {
            title: '备份次数',
            dataIndex: 'backup_count',
            align: "center",
        },
        {
            title: '设备类型',
            dataIndex: 'deviceType',
            align: "center",
            render: (text) => { return Utils.Translation(text); }
        },
        { title: "厂商", dataIndex: "deviceFirm", ellipsis: true, render: (text) => { return Utils.Translation(text); } },
        { title: "型号", dataIndex: "deviceVersion", ellipsis: true, },
        { title: "设备名", dataIndex: "deviceId", ellipsis: true, },
        // {
        //     title: '备份保存时长',
        //     dataIndex: 'total_time',
        //     width: 100,
        //     align: "center",
        //     render: (text) => {
        //         return text + "个月"
        //     }
        // },
        {
            title: '最近备份时间',
            dataIndex: 'latest_backup_time',
            // align: "left",
            ellipsis: true,
        },
        // {
        //     title: '下次自动备份时间',
        //     width: 150,
        //     dataIndex: 'next_run_time',
        //     align: "left",
        //     ellipsis: true,
        // },
        {
            title: '操作',
            align: "center",
            dataIndex: "op",
            render: (text, resItem, index) => {
                return <div key={index}>
                    <Dropdown.Button overlay={() => {
                        return <Menu onClick={(e) => handleMenuClick(e, resItem)}>
                            <Menu.Item key="start">
                                <Icon type="play-circle" />
                                手动开始备份
                            </Menu.Item>
                            {/* <Menu.Item key="stop">
                                <Icon type="pause-circle" />
                                取消自动备份
                            </Menu.Item>
                            <Menu.Item key="restart">
                                <Icon type="reload" />
                                恢复自动备份
                            </Menu.Item> */}
                            <Menu.Item key="setting">
                                <Icon type="setting" />
                                备份设置
                            </Menu.Item>
                            {/* <Menu.Item key="base">
                                <Icon type="carry-out" />
                                基线配置
                            </Menu.Item>
                            <Menu.Item key="baseHis">
                                <Icon type="file-exclamation" />
                                基线记录
                            </Menu.Item> */}
                            <Menu.Item key="del">
                                <Icon type="delete" />
                                删除
                             </Menu.Item>
                        </Menu>
                    }}
                        type="primary" icon={<Icon type="down" />} size="small"
                        onClick={() => { handleMenuClick({ key: "detail" }, resItem) }}
                    ><Icon type="file-text" />备份详情
                    </Dropdown.Button>
                </div>
            }
        },
    ];

    useEffect(() => {//componentDidMount;

        http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    useEffect(() => {//componentDidMount; 
        if (props.tabKey === "ftp") {
            getData();
        }
        return () => { //componentWillUnmount
        }
    }, [props.tabKey]);
    function getData(pageSize, pageNum, querys) {
        console.log("getData")
        let bodyJson = {};
        bodyJson.page_size = pageSize || _pageSize1;
        bodyJson.page_no = pageNum || _current1;
        bodyJson.filter_data = querys || _querys;
        bodyJson.sysname = sysname
        http.post(BASEURL + "findFtpDevices", bodyJson, (r) => {
            setTabData(r.data);
            setTotal(r.device_count || r.count || _total1,);
            setPageNum(bodyJson.page_no);
            setQuery(bodyJson.filter_data);
        })
    }
    function getDataByAttr(querys) {
        let bodyJson = {};
        bodyJson.page_size = 999999;
        bodyJson.page_no = 1;
        bodyJson.filter_data = querys;
        bodyJson.sysname = sysname
        http.post(BASEURL + "findFTPDeviceByAttr", bodyJson, (r) => {
            setTabData(r.data);
            setTotal(r.device_count || r.count || _total1,);
            setPageNum(bodyJson.page_no);
        })
    }
    function saveDataSource(val) {
        // if (firm) {//修改
        val._id = selectItem._id
        http.post(BASEURL + "updateFTPSetting", val, () => {
            getData();
            message.success("修改成功");
        })
        // } else {//新增
        //     http.post(api.addBackupCmd, val, () => {
        //         getData();
        //         message.success("新增成功");
        //     })
        // }
        setVisible(false)
    }
    function deleteData(ip) {
        http.delete_body(BASEURL + "delFtpDevices", { ip, sysname }, () => {
            getData();
            message.success("删除成功");
        })

    }
    function getSettingDetailData(ip) {
        http.post(api.querySetting, { ip, sysname }, (result) => {
            let selectDetail = result.data
            selectDetail.password = Utils.DecryptPwd(selectDetail.password);
            selectDetail.sys_pwd = Utils.DecryptPwd(selectDetail.sys_pwd);
            setSelectDetail(selectDetail)
            setVisSetting(true)
        });
    };
    function startBackup(ips) {
        if (ips.length === 0) {
            message.info("请选择设备！")
            return
        }
        axios2.post(BASEURL + "getBackupByFtp", { ips, sysname })
            .then(res => {
                console.log(res);
                message.success("备份完成！");
            })
            .catch(err => {
                console.log(err);
            })
        getData()
    }
    function openBackupFile(ip) {
        http.post(BASEURL + "getFolderDire", { ip, sysname }, (result) => {
            setDeviceFiles(result.data)
            setVisFile(true)
            setfiles_spinning(false)
        });
    }
    function downloadBackup() {
        const ip = selectItem.ip
        const files = checkedList
        if (files.length === 0) {
            message.info("请选择文件！")
            return
        }
        window.open(BASEURL + "downloadBackup" + "?ip=" + ip + "&" + "sysname=" + sysname + "&files=" + JSON.stringify(files))
    }
    function delFtpBackup() {
        const ip = selectItem.ip
        const files = checkedList
        if (files.length === 0) {
            message.info("请选择文件！")
            return
        }
        setfiles_spinning(true)
        http.delete_body(BASEURL + "delFtpBackup", { ip, sysname, files }, (result) => {
            openBackupFile(ip)
            message.success("删除完成！");
        });
    }

    function handleMenuClick(e, item) {
        setSelectItem(item)
        sessionStorage.setItem("device", JSON.stringify(item));
        switch (e.key) {
            case 'detail':
                // let path = {
                //     pathname: `/configBackup/BackData`,
                //     bodyJson: {
                //         pageNum: _current1,      //当前页码数
                //         pageSize: _pageSize1,    //每页显示条数
                //         querys: _querys,    //查询条件
                //         ip: item.ip,
                //         total: item.backup_count,
                //     }
                // };
                // this.props.history.push(path);
                // window.open("http://10.32.79.155:2211")
                openBackupFile(item.ip)
                break;
            case 'del':
                Modal.confirm({
                    title: '消息提示',
                    content: '你确定删除该条记录吗？',
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        deleteData(item.ip);
                    },
                    onCancel() { },
                });
                break;
            case 'start': startBackup([item.ip]); break;
            case 'stop': this.ActionAutoBackup("pauseAutoBackup", "暂停", [item.ip]); break;
            case 'restart': this.ActionAutoBackup("resumeAutoBackup", "恢复", [item.ip]); break;
            case 'setting': setVisSetting(true); break;
        }
    }
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys1) => {
            setSelectedRowKeys(selectedRowKeys1);
        },
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => {
                    setSelectedRowKeys([]);
                },
            },
        ],
    };
    return <div>
        <div  >
            <Card>
                <FilterForm
                    tagClass="resource"
                    filterSubmit={(querys) => {
                        setPageSize(99999)
                        let n_query = {
                            deviceIp: querys["resourceParams.deviceIp"],
                            deviceFirm: querys["resourceParams.deviceFirm"],
                            deviceId: querys["resourceParams.deviceId"],
                            deviceType: querys["resourceParams.deviceType"],
                        }
                        getDataByAttr(n_query)

                    }}
                    resetTab={() => {
                        setPageSize(20)
                        getData()
                    }}
                />
                <div className="ftpBtn">
                    <Button type="primary" icon="play-circle" onClick={() => { startBackup(selectedRowKeys) }} >开始备份</Button>
                </div>
                <Table
                    dataSource={tableData}
                    rowSelection={rowSelection}
                    columns={columns}
                    rowKey={(item) => { return item.ip }}
                    size="small"
                    pagination={
                        {
                            current: _current1, pageSize: _pageSize1, total: _total1,
                            onChange: (page, pageSize) => {
                                getData(pageSize, page, _querys);
                            }
                        }
                    }
                />
            </Card>
            <EditModal
                title="备份设置"
                visible={VisSetting}
                onCancel={() => setVisSetting(false)}
                onSubmit={(val) => {
                    saveDataSource(val);
                }}
                footer={null}
                width="55%"
                initialValues={selectItem}
                destroyOnClose
                formList={[
                    { type: "input", label: "设备IP", name: "ip", required: true, placeholder: "IP" },
                    { type: "input", label: "厂商", name: "deviceFirm", required: true, placeholder: "厂商" },
                    { type: "input", label: "型号", name: "deviceVersion", required: true, placeholder: "型号" },
                    { type: "input", label: "设备名", name: "deviceId", required: true, placeholder: "设备名" },
                    { type: "input", label: "文件名", name: "config_name", required: true, placeholder: "文件名" },
                    // { type: "input", label: "用户名", name: "username", required: true, placeholder: "用户名" },
                    // { type: "input", label: "密码", name: "password", required: true, placeholder: "密码" },
                ]}
            />
            <Modal
                title="设备备份文件"
                visible={VisFile}
                onOk={() => {
                    setVisFile(false)
                }}
                onCancel={() => {
                    setVisFile(false)
                }}
                afterClose={() => {
                    setCheckedList([])
                    setcheckAll(false)
                    setindeterminate(false)
                }}
                style={{ top: 50 }}
                width="1000px"
                destroyOnClose
            >
                <div className="ftpFilesDiv">
                    <Checkbox
                        className="allCheck"
                        indeterminate={indeterminate}
                        onChange={(e) => {
                            setcheckAll(e.target.checked)
                            setCheckedList(e.target.checked ? deviceFiles : [])
                            setindeterminate(false)
                        }}
                        checked={checkAll}
                    >全选</Checkbox>&nbsp;&nbsp;
                    <Button type="primary" icon="download" onClick={() => downloadBackup()}>下载选中</Button>&nbsp;&nbsp;
                    <Button type="danger" icon="delete" onClick={() => delFtpBackup()}>删除选中</Button>
                    <Divider />

                    <Checkbox.Group onChange={(clist) => {
                        setCheckedList(clist)
                        setindeterminate(!!clist.length && clist.length < deviceFiles.length)
                        setcheckAll(clist.length === deviceFiles.length)
                    }} value={checkedList}>
                        <div className="list">
                            <Spin spinning={files_spinning}>
                                <List
                                    size="small"
                                    // header={<div> </div>}
                                    // footer={<div> </div>}
                                    style={{ width: "100%" }}
                                    bordered
                                    dataSource={deviceFiles}
                                    renderItem={item => <List.Item ><div className="line"><Checkbox value={item}><a>{item}</a></Checkbox></div></List.Item>}
                                />
                            </Spin>
                        </div>
                    </Checkbox.Group>
                </div>
            </Modal>
        </div>
    </div >
}
export default connect()(BackupFtp)








