import React from 'react'
import './index.scss'
import Socket from '@/socket/index'
import {
    Card, Icon, Row, Col, Tag, Button, Radio, DatePicker, message,
    Input, InputNumber, Checkbox, Select, Table, Modal, Typography
} from "antd";
import FilterForm from "@/components/FilterForm/index";
import Utils from "@/utils/utils";
import SelectForm from "@/components/SelectForm/index";
import "./taskElemt.scss"
const Option = Select.Option;
const { Title } = Typography;
export default class oldElemt extends React.Component {
    state = {
        current: 0,                 //步骤条计数
        taskList: [],
        resources: [],
        selectedRowKeys: [],
        selectedRowKeys_Task: [],
        visible_device: false,
        tabloading: false,
        countNum: null,
        dataSouce: [{
            key: new Date().getTime(),
            taskName: "",
            devices: [],
        }],
        Tags: [],
        Groups: [],
        slt_device: [],
        slt_task: null,
        taskCurrent: 1,
        taskPageSize: 20,
        taskTotal: 0,
        taskQuery: null,
        FileIdArr: null,
    };
    componentWillMount() {
        const { defaultValue } = this.props;
        if (defaultValue) {
            this.setState({ dataSouce: defaultValue })
        }
        this.props.refThis(this);
        this.getTagGroupName();
    }
    onDataChange(newData) {
        this.props.onDataChange(newData);
    }
    updataTask = (_pageSize, _page, _taskQuery) => {
        let { taskPageSize, taskCurrent, taskQuery } = this.state;
        this.setState({ loading: true, });
        const bodyJson = {
            pageSize: _pageSize || taskPageSize,
            pageNum: _page || taskCurrent,
            querys: _taskQuery || taskQuery
        };

        Socket.ExecuteAction("QueryTasksPage",
            bodyJson, "/taskQueryTask", (result) => {
                let taskList = result.tasks;
                taskList.map((item, index) => { item.key = index; return item });
                this.setState({
                    taskList,
                    taskCurrent: _page || 1,
                    taskTotal: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };
    getTagGroupName() {
        const thisTemp = this;
        Socket.ExecuteAction('QueryTagsGroups', { tagClass: 'resource' }, "/QueryTagsGroups", (result) => {
            thisTemp.setState({
                Tags: result.tags,
                Groups: result.groupNames,
            });
        });
    }
    updataResouce = (querys) => {
        // this.setState({ loading: true, });
        const bodyJson = {
            pageSize: 1000,
            pageNum: 1,
            querys: querys || {}
        };
        Socket.ExecuteAction("QueryResourcesPage",
            bodyJson, "/queryResource", (result) => {
                let resources = result.resources;
                resources.map((item, index) => { item.key = index; return item });
                this.setState({
                    resources,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };
    onSumitModal() {
        let { slt_task, slt_device } = this.state;
        let { group, } = this.props;
        let FileIdArr = group.filter((item, key) => {
            return item.type === "input";
        });
        FileIdArr = FileIdArr.map((item, key) => { return item.name })
        let backData = slt_device.map((device, key) => {
            let data = {};
            data["key"] = new Date().getTime() + key;
            data[FileIdArr[0]] = slt_task.taskName;
            data[FileIdArr[1]] = device.resourceName;
            return data;
        })
        // console.log(backData)
        // console.log(slt_task.taskName)
        // console.log(slt_device.length)
        this.onDataChange(backData);
        this.setState({ FileIdArr });

    }
    initModal() {
        this.setState({ current: 0, selectedRowKeys: [], visible_device: false })
    }
    sumFun() {
        let { rulesArr } = this.props;
        let { FileIdArr } = this.state;
        if (!FileIdArr) return;
        let taskName = [];
        let sumJosn = {};
        rulesArr.map((item, key) => {
            let _taskName = item[FileIdArr[0]];
            if (!sumJosn[_taskName]) {
                sumJosn[_taskName] = 0;
            }
            sumJosn[_taskName] = sumJosn[_taskName] + 1;
            if (!taskName.includes(_taskName)) {
                taskName.push(_taskName);
            }
        })
        // return <div>
        //     <div>总计：{rulesArr.length}</div>
        //     {taskName.map((item) => { return <div key={item}>{item}：{sumJosn[item]}</div> })}
        // </div>;
        return <table className="sumTab"><tbody>
            <tr><th>任务名称</th><th>资源数量</th></tr>
            {taskName.map((item) => { return <tr key={item}><td>{item}</td><td>{sumJosn[item]}</td></tr> })}
            <tr><td>总计</td><td>{rulesArr.length}</td></tr>
        </tbody></table>
    }
    taskCompent = () => {
        const { taskList, resources, dataSouce, visible_device, tabloading, Tags, Groups, slt_device, taskCurrent, taskQuery, taskTotal,
            taskPageSize, current, slt_task, selectedRowKeys, selectedRowKeys_Task } = this.state;
        const formList = [
            {
                label: "资源名称",
                type: 'INPUT',
                field: "resourceName",
                initialValue: '',
                placeholder: "资源名称"
            },
            {
                label: "标签",
                type: 'SELECT',
                field: "tags",
                isTag: true,
                initialValue: [],
                list: Tags.map((item) => {
                    return { id: item, name: item }
                }),
            },
            {
                label: "分组",
                type: 'SELECT',
                isTag: true,
                field: "groupNames",
                initialValue: [],
                list: Groups.map((item) => {
                    return { id: item, name: item }
                }),
            }
        ]

        const rowDevice = {
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                let new_device = slt_device;
                new_device = selectedRows;
                this.setState({ slt_device: new_device, selectedRowKeys });
            },
            selectedRowKeys: selectedRowKeys
        };
        const rowTask = {
            type: 'radio',
            onSelect: (record, selected, selectedRows) => {
                // console.log(record, selected, selectedRows);
                const current = this.state.current + 1;//设置步骤计数 
                this.setState({ slt_task: record, current });
            },
            onChange: (record, selected, selectedRows) => {
                this.setState({ selectedRowKeys_Task: record })
            },
            selectedRowKeys: selectedRowKeys_Task
        }
        const columnsDevice = [
            {
                title: '序号',
                width: "50px",
                align: 'center',
                render: (text, item, index) => {
                    return index + 1;
                }
            },
            {
                title: '名称',
                align: 'left',
                dataIndex: 'resourceName'
            }, {
                title: '厂商',
                width: "100px",
                align: 'center',
                dataIndex: 'resourceParams.deviceFirm',
            }, {
                title: '型号',
                width: "100px",
                align: 'center',
                dataIndex: 'resourceParams.deviceVersion',
            }, {
                title: '标签',
                align: 'left',
                dataIndex: 'tags',
                width: "200px",
                render: (text) => {
                    return Utils.hidenTag(text);
                }
            }, {
                title: '分组',
                align: "left",
                width: "200px",
                dataIndex: 'groupNames',
                render: (text) => {
                    return Utils.hidenTag(text);
                }
            },
        ];
        const colTask = [
            {
                title: '序号',
                align: 'center',
                width: "50px",
                dataIndex: 'key',
                render: (text) => { return <span>{text + 1}</span> }
            }, {
                title: '任务名称',
                align: 'center',
                dataIndex: 'taskName'
            }, {
                title: '状态',
                align: 'center',
                width: "100px",
                dataIndex: 'taskStatus',
                render: (text) => {
                    return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>)
                }
            }, {
                title: '标签',
                align: 'left',
                width: "200px",
                dataIndex: 'tags',
                render: (text) => {
                    return Utils.hidenTag(text);
                }
            }, {
                title: '分组',
                align: 'left',
                width: "200px",
                dataIndex: 'groupNames',
                render: (text) => {
                    return Utils.hidenTag(text);
                }
            },
        ];
        const condition = [
            { key: 'taskName', value: '任务名称' },
            { key: 'tags', value: '标签' },
            { key: 'groupNames', value: '分组' }
        ];
        const conditionData = {
            taskName: "INPUT",
            tags: { mode: "tags", data: [{ key: '19楼', value: '19楼' }] },
            groupNames: { mode: "tags", data: [{ key: '19楼', value: '19楼' }] },
        };
        const steup1 = <div style={{ textAlign: "center" }}>
            <Title level={3}>选择任务</Title>
            <SelectForm
                condition={condition}
                conditionData={conditionData}
                tagClass="task"
                filterSubmit={(querys) => {
                    this.updataTask(taskPageSize, 1, querys);
                    this.setState({ taskQuery: querys });
                }}
                resetTab={() => {
                    this.updataTask(taskPageSize, 1, {})
                    this.setState({ taskQuery: {}, slt_task: {} });
                }}
            />
            <hr style={{ border: '1px solid #e2e2e2' }} />
            <Table
                rowSelection={rowTask}
                columns={colTask}
                // loading={tabloading}
                dataSource={taskList}
                size='small'
                bordered={false}
                scroll={{ y: 500 }}
                pagination={{
                    current: taskCurrent, pageSize: taskPageSize, total: taskTotal,
                    onChange: (page, pageSize) => {
                        //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                        this.updataTask(pageSize, page, taskQuery | {});
                    }
                }}

            /><hr style={{ border: '1px solid #e2e2e2' }} />
            <Button type="primary" style={{ marginLeft: 8 }} onClick={() => {
                if (!this.state.slt_task || Utils.isEmptyObject(slt_task)) { message.info('请选择任务！'); return; }
                const current = this.state.current + 1;//设置步骤计数
                this.setState({ current });
            }}
            >下一步</Button>
        </div>
        const setup2 = <div style={{ textAlign: "center" }}>
            <Title level={3}>选择设备</Title>
            <div style={{ width: "100%", textAlign: "left" }}>
                <FilterForm
                    formList={formList}
                    filterSubmit={(querys) => {
                        this.setState({ querys })
                        this.updataResouce(querys)
                    }}
                    resetTab={() => {
                        this.setState({ querys: null })
                        this.updataResouce({})
                    }}
                />
            </div>

            <hr style={{ border: '1px solid #e2e2e2' }} />
            <Table
                rowSelection={rowDevice}
                columns={columnsDevice}
                loading={tabloading}
                dataSource={resources}
                size='small'
                bordered={false}
                scroll={{ y: 440 }}
                pagination={false}
            />
            <hr style={{ border: '1px solid #e2e2e2' }} />
            <Button style={{ marginLeft: 8 }} onClick={() => {
                const current = this.state.current - 1;
                this.setState({ current });
            }}>上一步</Button>
            <Button type="primary" style={{ marginLeft: 8 }} onClick={() => {
                this.onSumitModal();
                this.initModal();
            }}>完成</Button>
        </div>
        return <div>{this.sumFun()}
            <Modal
                title="添加子任务信息"
                visible={visible_device}
                footer={null}
                onCancel={(e) => { this.initModal(); }}
                style={{ top: 50 }}
                width={1050}
                destroyOnClose
            >
                {current === 0 ? steup1 : setup2}
            </Modal>
        </div>;
    }

    render() {
        // let { } = this.state;
        return this.taskCompent();
    }
}
