/**
 * Created by YIMINE on 2019/4/25.
 */
import React from 'react'
import { Input, Select, Form, Checkbox, DatePicker, } from 'antd'
import TableForm from './tableForm/TableForm';
import Upload from './upload';
const FormItem = Form.Item;
const Option = Select.Option;

class simpleForm extends React.Component {

    //在组件接收到一个新的 prop (更新后)时被调用。这个方法在初始化render时不会被调用。
    // componentWillReceiveProps() {
    //     const { submitState, } = this.props
    //     if (submitState) {
    //         this.handleSubmit();
    //     }
    // }
    // componentDidMount() {
    //     const { submitState, } = this.props
    //     if (submitState) {
    //         this.handleSubmit();
    //     }
    // }
    componentWillMount() {
        this.props.getThis(this);
    }

    handleSubmit = () => {
        let fieldsValue = this.props.form.getFieldsValue();
        this.props.handleSubmit(fieldsValue);
    };

    reset = () => {
        this.props.form.resetFields();
    };

    initFormList = () => {
        const { getFieldDecorator } = this.props.form;
        const formList = this.props.formList;
        const formItemList = [];
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 19
            },
        }
        if (formList && formList.length > 0) {
            formList.forEach((item) => {
                let label = item.label;
                let field = item.field;
                let initialValue = item.initialValue || '';
                let placeholder = item.placeholder;
                if (item.type == 'time') {
                    const begin_time = <FormItem label="时间" key={field}>
                        {
                            getFieldDecorator('begin_time')(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(begin_time)
                    const end_time = <FormItem label="~" colon={false} key={field}>
                        {
                            getFieldDecorator('end_time')(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(end_time)
                } else if (item.type == 'input') {
                    const INPUT = <FormItem label={label} key={field}
                        {...formItemLayout}
                    >
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue
                            })(
                                <Input type="text" style={{ width: 280 }} placeholder={placeholder} />
                            )
                        }
                    </FormItem>;
                    formItemList.push(INPUT)
                } else if (item.type == 'select') {
                    const SELECT = <FormItem label={label} key={field} {...formItemLayout}>
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue
                            })(
                                <Select
                                    style={{ width: 280 }}
                                    placeholder={placeholder}
                                >
                                    {item.option.map((option) => {
                                        return <Option value={option} key={option}>{option}</Option>
                                    })}
                                </Select>
                            )
                        }
                    </FormItem>;
                    formItemList.push(SELECT)
                } else if (item.type == 'checkbox') {
                    const CHECKBOX = <FormItem label={label} key={field} {...formItemLayout}>
                        {
                            getFieldDecorator(field, {
                                valuePropName: 'checked',
                                initialValue: initialValue //true | false
                            })(
                                <Checkbox>
                                    {label}
                                </Checkbox>
                            )
                        }
                    </FormItem>;
                    formItemList.push(CHECKBOX)
                } else if (item.type == 'upload') {
                    const UPLOAD = <FormItem label={label} key={field} {...formItemLayout}>
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue || {}
                            })(
                                <Upload />
                            )
                        }
                    </FormItem>;
                    formItemList.push(UPLOAD)
                } else if (item.type == 'group') {
                    const GROUPTABLE = <FormItem label={label} key={field}
                        labelCol={{
                            span: 5
                        }}
                        wrapperCol={{
                            span: 24
                        }}
                    >
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue || []
                            })(
                                <TableForm columnsGroup={item.group} size="small" />
                            )
                        }
                    </FormItem >;
                    formItemList.push(GROUPTABLE)
                }
            })
        }
        return formItemList;
    };
    render() {

        return (
            <Form layout="horizontal" className="scriptFormcss">
                {this.initFormList()}
                {/* <FormItem wrapperCol={{ span: 18, offset: 6 }}>
                    <Button type="primary"
                        icon="check-circle"
                        onClick={this.handleSubmit}>{submitName}</Button>
                </FormItem> */}
            </Form>
        );
    }
}
export default Form.create({})(simpleForm);