import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Tag, Descriptions, Icon, Badge, Row, Col, Collapse
} from "antd";
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import Utils from '@/utils/utils';
import http from '@/axios/axios_utils';
import api from '@/config/api/deliveryTask';
import personUtils from './utils/index';
import * as XLSX from 'xlsx';
import "./index.scss"
function ExeRecord(props) {
    const [total, set_total] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [, setRow] = useState([]);
    const [selectedRowKeys, setRowKey] = useState([]);
    const [recordData, setRecordData] = useState([]);
    const [taskObj, setTaskObj] = useState({});
    useEffect(() => {//componentDidMount
        const taskObj2 = JSON.parse(sessionStorage.getItem("acltask"));
        getTaskRecords(taskObj2._id);
        setTaskObj(taskObj2);
        console.log(props)
        return () => { //componentWillUnmount
        }
    }, []);
    async function getTaskRecords(_id, pageSize, pageNum) {
        props.dispatch(isLoading(true));
        let bodyJson = {
            page_size: pageSize || _pageSize,
            page_no: pageNum || _current,
            _id
        };
        const data = await http.get(api.executeRecordsByPage, bodyJson);
        props.dispatch(isLoading(false));
        setRecordData(data.data);
        set_total(data.count);
        set_current(bodyJson.page_no);
    }
    function goBack() {
        const querys = taskObj.querys || {};
        props.history.push({ bodyJson: querys, pathname: "/command/aclSend" })
    }
    const colums = [
        {
            title: "序号", render: (a, b, c) => {
                return c + 1 + (_current - 1) * _pageSize
            }
        },
        // { title: "任务名称", dataIndex: "task_id" },
        { title: "执行时间", dataIndex: "operation_time" },
        { title: "执行人", dataIndex: "operation_user" },
        {
            title: "执行结果", dataIndex: "whether_succeed", render: (a) => {
                let color = "#f50"
                let title = "失败"
                if (a) {
                    color = "#52c41a"; title = "成功";
                }
                return <Tag color={color}>{title}</Tag>
            }
        },
        {
            title: "任务状态", dataIndex: "task_detail.status", render: (a) => {
                let color = "#f50"
                switch (a) {
                    case "执行中": color = "purple"; break;
                    case "完成": color = "#52c41a"; break;
                    case "失败": color = "#f50"; break;
                    case "终止": color = "#f5222d"; break;
                    case "审批中": color = "geekblue"; break;
                    case "审批通过": color = "green"; break;
                    case "暂停": color = "red"; break;
                    case "审批拒绝": color = "magenta"; break;
                    case "待执行": color = "#108ee9"; break;
                }
                return <Badge color={color} text={a} />
            }
        },
        {
            title: "详情", render: (a, b) => {
                return <Button type="primary" icon="file-text" size="small" onClick={() => {
                    personUtils.showTaskInfo(b)
                }}> 详情</Button>
            }
        },
    ]
    return <div className="aclTaskRecordDiv">
        <CustomBreadcrumb arr={["运维管理", "配置下发", { title: "下发任务", to: "/command/aclSend" }, "任务记录"]} />
        <Card title={Utils.BoldFont("任务记录")} extra={<Button type="primary" icon="rollback" onClick={() => goBack()}>返回</Button>}>
            {/* <div><Divider type="vertical" />
                <strong>任务名称: </strong>{taskObj.task_name}<Divider type="vertical" />
                <strong>任务类型: </strong>{taskObj.type}<Divider type="vertical" />
                <strong>创建时间: </strong>{taskObj.create_time}<Divider type="vertical" /><p />
            </div> */}
            {/* <Descriptions column={4} bordered layout="vertical">
                <Descriptions.Item label={"任务名称"} span={1} >{taskObj.task_name}</Descriptions.Item>
                <Descriptions.Item label={"任务名称"} span={1} >{taskObj.task_name}</Descriptions.Item>
                <Descriptions.Item label={"任务类型"} span={1} >{taskObj.type}</Descriptions.Item>
                <Descriptions.Item label={"创建用户"} span={1} ><Icon type="user" />&nbsp;{taskObj.username}</Descriptions.Item>
                <Descriptions.Item label={"创建时间"} span={1} ><Icon type="clock-circle" />&nbsp;{taskObj.create_time}</Descriptions.Item>
            </Descriptions> */}
            <div className="exeRecordDescription" >
                <Descriptions column={3} bordered>
                    <Descriptions.Item label={"任务名称"} span={1} >{taskObj.task_name}</Descriptions.Item>
                    <Descriptions.Item label={"任务状态"} span={1} >{taskObj.status}</Descriptions.Item>
                    <Descriptions.Item label={"任务类型"} span={1} >{taskObj.type}</Descriptions.Item>
                    <Descriptions.Item label={"任务并发"} span={1} >{taskObj.concurrent ? "是" : "否"}</Descriptions.Item>
                    <Descriptions.Item label={"定时执行"} span={1} >{taskObj.automatic ? "是" : "否"}</Descriptions.Item>
                    <Descriptions.Item label={"定时执行时间"} span={1} ><Icon type="clock-circle" />&nbsp;{taskObj.auto_execute_time}</Descriptions.Item>
                    <Descriptions.Item label={"执行过程中遇见错误终止"} span={1} >{taskObj.ignore_error ? "是" : "否"}</Descriptions.Item>
                    <Descriptions.Item label={"运行耗时"} span={1} ><Icon type="hourglass" />&nbsp;{taskObj.consume_time}</Descriptions.Item>
                    <Descriptions.Item label={"创建用户"} span={1} ><Icon type="user" />&nbsp;{taskObj.username}</Descriptions.Item>
                    <Descriptions.Item label={"创建时间"} span={1} ><Icon type="clock-circle" />&nbsp;{taskObj.create_time}</Descriptions.Item>
                    <Descriptions.Item label={"审批人"} span={1} ><Icon type="solution" />&nbsp;{taskObj.reviewer}</Descriptions.Item>
                    <Descriptions.Item label={"审批人邮箱"} span={1} ><Icon type="mail" />&nbsp;{taskObj.mail}</Descriptions.Item>
                </Descriptions> <p />

                <Descriptions column={1} bordered className="tabDescription">
                    <Descriptions.Item label={"下发指令"} span={1} >
                        <Collapse bordered={false} ><Collapse.Panel header="详情" key="cmd">
                            <div className="cmdDiv">{taskObj.commands}</div>
                        </Collapse.Panel></Collapse>
                    </Descriptions.Item>

                    {taskObj.excel_cmd && taskObj.excel_cmd[0] ?
                        <Descriptions.Item label={"指令参数"} span={1} > <Collapse bordered={false} >
                            <Collapse.Panel header="详情" key="detail">
                                <div className="paramDiv" dangerouslySetInnerHTML={{
                                    __html:
                                        XLSX.utils.sheet_to_html(XLSX.utils.json_to_sheet(taskObj.excel_cmd || []), { id: "xlslTab", editable: false, })
                                }} />
                            </Collapse.Panel></Collapse>
                        </Descriptions.Item> : ""}

                </Descriptions>
                <p />
                {/* <Table
                    bordered
                    size="small"
                    columns={colums}
                    dataSource={data.execute_result}
                    pagination={false}
                    scroll={{ y: 350, }}
                    rowKey={(r) => r.ip}
                /> */}
            </div>
            <Table
                rowSelection={{
                    selectedRowKeys: selectedRowKeys,
                    onChange: (selectedRowKeys, selectedRows) => {
                        setRowKey(selectedRowKeys);
                        setRow(selectedRows);
                    },
                }}
                rowKey={(row) => { return row._id }}
                columns={colums}
                dataSource={recordData}
                size="small"
                pagination={{
                    pageSize: _pageSize, current: _current, total: total,
                    showTotal: (total) => { return <span>共 {total} 项</span> },
                    pageSizeOptions: ["20", "50", "100", "500", "1000"],
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) => {
                        set_pageSize(size)
                        getTaskRecords(taskObj._id, size, 1)
                    },
                    onChange: (page, pageSize) => {
                        getTaskRecords(taskObj._id, pageSize, page)
                    }
                }}
            />
        </Card>
    </div >

}
export default connect()(ExeRecord)