import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Popconfirm, Row, Col, Collapse
} from "antd";
import http from '@/axios/axios_utils';
import deviceFirmUtils from '@/utils/deviceFirmUtils';
import FormModal from '@/components/EditModal/index';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import { connect } from 'react-redux'
const checkDevices_url = "http://" + backup_address + "/ZJNMS_V1/";
import "./index.scss"
import "animate.css"
function BaseLineCmd(props) {
    const [visible, setVisible] = useState(false);
    const [selectItem, setSelectItem] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [_pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [query, setQuery] = useState({});
    const [tableData, setTabData] = useState([])
    const [tableLoading, setTableLoading] = useState(false)
    const columns = [
        {
            title: '序号',
            dataIndex: 'ip',
            ellipsis: true,
            width: 50,
            render: (text, item, index) => {
                return <span>{index + 1 + (pageNum - 1) * _pageSize}</span>
            }
        },
        {
            title: '编号',
            dataIndex: 'number',
            ellipsis: true,
        },
        {
            title: '分类',
            dataIndex: 'type',
            ellipsis: true,
        },
        {
            title: '设备厂商',
            dataIndex: 'firm',
            ellipsis: true,
        },
        {
            title: '型号',
            dataIndex: 'deviceVersion',
            ellipsis: true,
        },
        {
            title: '描述',
            dataIndex: 'description',
            ellipsis: true,
        },
        // {
        //     title: '执行指令',
        //     dataIndex: 'cmd',
        //     ellipsis: true,
        // },
        // {
        //     title: '异常分析脚本',
        //     dataIndex: 'check_script',
        //     ellipsis: true,
        // },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            ellipsis: true,
        },
        {
            title: '操作',
            width: 180,
            render: (text, item) => {
                return <div>
                    <Button type="primary" icon="edit" size="small" onClick={() => { initSelectItem(item); setVisible(true) }}>编辑</Button>
                    &nbsp;
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData([item._id]) }}
                        okText="是"
                        cancelText="否"
                    >
                        <Button type="danger" size="small" icon="delete" >删除</Button>
                    </Popconfirm>
                </div>
            }
        },
    ]
    const formList = [
        {
            label: '编号',
            type: 'INPUT',
            field: 'number',
            initialValue: '',
            placeholder: '输入内容'
        },
        {
            label: '分类',
            type: 'INPUT',
            field: 'type',
            initialValue: '',
            placeholder: '输入内容',
        },
        {
            label: '设备厂商',
            type: 'AUTO',
            field: 'firm',
            initialValue: '',
            placeholder: '输入内容',
            list: deviceFirmUtils.deviceFirmsAutoInput()
        },
        {
            label: '型号',
            type: 'INPUT',
            field: 'deviceVersion',
            initialValue: '',
            placeholder: '输入内容'
        },
    ]

    useEffect(() => {//componentDidMount;
        getData();
        // http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(page, page_size, querys) {
        setTableLoading(true);
        let bodyJson = {}
        let query2 = querys || query;
        bodyJson.page_no = page || pageNum;
        bodyJson.page_size = page_size || _pageSize;
        bodyJson.match = { ...query2 }
        // console.log(bodyJson)
        http.get(checkDevices_url + "check/check_cmd", bodyJson, (res) => {
            if (res.code === "00") {
                setTotal(res.count);
                setTabData(res.data);
                setTableLoading(false);
                setPageNum(page || 1);
                setQuery(query2);
            } else {
                message.error("查询失败");
                setTableLoading(false);
            }
        })
    }
    function saveDataSource(val) {
        const { firm } = selectItem;
        let bodyJson = {
            ...val,
            check_script: {
                script: val.check_cmd,
                type: val.check_type
            },
        };
        if (firm) {//修改
            http.put(checkDevices_url + "check/check_cmd", bodyJson, () => {
                getData(pageNum, _pageSize, query);
                message.success("修改成功");
            })
        } else {//新增
            http.post(checkDevices_url + "check/check_cmd", bodyJson, () => {
                getData(1, _pageSize);
                message.success("新增成功");
            })
        }
        setVisible(false)
    }
    function deleteData(ids) {
        console.log(ids)
        http.delete_body(checkDevices_url + "check/check_cmd", { _ids: ids }, () => {
            getData(1, _pageSize, query);
            message.success("删除成功");
        })
    }
    function initSelectItem(item) {
        let initItem = {
            check_cmd: item.check_script.script,
            check_type: item.check_script.type,
            ...item,
        };
        setSelectItem(initItem)
    }
    return <div className="BackupCmdDiv">
        <div className="CustomBreadcrumb"><CustomBreadcrumb arr={["安全基线管理", { title: "设备检查项", to: "/baseLineCmd" }]} /> </div>
        <div className="head" style={{ marginBottom: 8 }}>
            <Collapse defaultActiveKey={"key"}>
                <Collapse.Panel header="筛选条件" key={"key"}>
                    <FilterForm formList={formList}
                        filterSubmit={(querys) => {
                            getData(1, _pageSize, querys)
                        }}
                        resetTab={() => {
                            getData(1, _pageSize, {})
                        }}
                    />
                </Collapse.Panel>
            </Collapse>
        </div>
        <Card title={<span style={{ fontWeight: 600 }}>设备检查项</span>}
            extra={
                <Row>
                    <Button type="primary" icon="file-add" onClick={() => {
                        // setSelectItem({ check_script: { type: "python" }})
                        setSelectItem({ check_type: "python" })
                        setVisible(true)
                    }}>添加</Button>&nbsp;
                    <Button icon="reload" onClick={() => {
                        getData(1, _pageSize, query);
                    }}>刷新</Button>
                </Row>
            }
        >
            <Table
                dataSource={tableData}
                columns={columns}
                loading={tableLoading}
                size="small"
                rowKey={(text) => { return text._id }}
                pagination={{
                    pageSize: _pageSize, current: pageNum, total: total,
                    showTotal: (total) => { return <span>共 {total} 项</span> },
                    pageSizeOptions: ["20", "50", "100", "500", "1000"],
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) => {
                        setPageSize(size);
                        getData(1, size, query)
                    },
                    onChange: (page, pageSize) => {
                        getData(page, pageSize, query)
                    }

                }}
            />
        </Card>
        <FormModal
            title="编辑数据源配置"
            visible={visible}
            onCancel={() => setVisible(false)}
            onSubmit={(val) => { saveDataSource(val); }}
            footer={null}
            style={{ top: 50 }}
            width={"70%"}
            initialValues={selectItem}
            destroyOnClose
            formList={[
                { type: "input", name: "number", label: "编号", required: true, placeholder: "请输入编号" },
                { type: "autoComplete", name: "firm", label: "设备厂商", required: true, placeholder: "请输入设备厂商", selectOptions: [
                    "HUAWEI", "CISCO", "H3C", "MAIPU", "RUIJIE", "JUNIPER", "HILLSTONE", "F5", "DP", "其他"
                ] },
                { type: "input", name: "type", label: "分类", required: true, placeholder: "请输入分类" },
                { type: "input", name: "deviceVersion", label: "型号", required: true, placeholder: "请输入型号" },
                { type: "input", name: "description", label: "描述", required: true, placeholder: "请输入描述" },
                {
                    type: "code", name: "cmd", label: "执行指令", required: true,
                    placeholder: "",
                },
                {
                    // type: "radio", name: "check_script.type", label: "异常分析脚本类型", required: true,
                    type: "radio", name: "check_type", label: "异常分析脚本类型", required: true,
                    options: [
                        { label: "python", value: "python" }, { label: "regex", value: "regex" },
                    ]
                },
                {
                    // type: "code", name: "check_script.script", label: "异常分析脚本", required: true,
                    type: "code", name: "check_cmd", label: "异常分析脚本", required: true,
                    placeholder: "",
                },
            ]}
        />
    </div>
}
export default connect()(BaseLineCmd)