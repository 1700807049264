import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Divider, Popconfirm
} from "antd";
import http from '@/axios/axios_utils';
import FormModal from '@/components/EditModal/index';
import { connect } from 'react-redux'
import api from '@/config/api/backup';
import "./index.scss"
import "animate.css"
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
function BackupCmd(props) {
    const [visible, setVisible] = useState(false);
    const [selectItem, setSelectItem] = useState(null);
    const [tableData, setTabData] = useState([])
    const columns = [
        {
            title: '序号',
            dataIndex: 'ip',
            ellipsis: true,
            width: 50,
            render: (text, item, c) => { return c + 1 }
        },
        {
            title: '设备厂商',
            width: 100,
            dataIndex: 'firm',
            ellipsis: true,
        },
        {
            title: '备份类型',
            dataIndex: 'type',
            ellipsis: true,
        },
        {
            title: '备份命令',
            dataIndex: 'cmd',
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            ellipsis: true,
        },
        {
            title: '操作',
            width: 180,
            render: (text, item) => {
                return <div>
                    <Button type="primary" icon="edit" size="small" onClick={() => { setSelectItem(item); setVisible(true) }}>编辑</Button>
                    &nbsp;
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData(item.firm) }}
                        okText="是"
                        cancelText="否"
                    >
                        <Button type="danger" size="small" icon="delete" >删除</Button>
                    </Popconfirm>
                </div>
            }
        },
    ]
    useEffect(() => {//componentDidMount;
        getData();
        http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    function getData() {
        http.get(api.backupCmd, {}, (r) => {
            setTabData(r.data);
        })
    }
    function saveDataSource(val) {
        const { firm } = selectItem;
        if (firm) {//修改
            http.post(api.updateBackupCmd, val, () => {
                getData();
                message.success("修改成功");
            })
        } else {//新增
            http.post(api.addBackupCmd, val, () => {
                getData();
                message.success("新增成功");
            })
        }
        setVisible(false)
    }
    function deleteData(firm) {
        http.delete_body(api.delBackupCmd, { firm }, () => {
            getData();
            message.success("删除成功");
        })

    }
    return <div>
        {/* <CustomBreadcrumb arr={["运维管理", "配置备份", "备份命令"]} /> */}
        <div  >
            <Card  >
                <div style={{ marginBottom: "10px" }}>
                    <Button type="primary" icon="file-add" onClick={() => {
                        setSelectItem({})
                        setVisible(true)
                    }}>添加</Button>&nbsp;
                <Button icon="reload" onClick={() => {
                        getData();
                    }}>刷新</Button>
                </div>
                <Table
                    dataSource={tableData}
                    columns={columns}
                    size="small"
                    pagination={false}
                />
            </Card>
            <FormModal
                title="编辑备份命令"
                visible={visible}
                onCancel={() => setVisible(false)}
                onSubmit={(val) => {
                    saveDataSource(val);
                }}
                footer={null}
                width={"50%"}
                initialValues={selectItem}
                destroyOnClose
                formList={[
                    { type: "input", name: "firm", label: "设备厂商", required: true, placeholder: "设备厂商" },
                    { type: "select", selectOptions: [{ name: "text", value: "text" }, { name: "ftp", value: "ftp" }], name: "type", label: "备份类型", required: true, placeholder: "备份类型" },
                    {
                        type: "code", name: "cmd", label: "备份命令", required: true,
                        placeholder: "使用{ftp_host}作为FTP服务器地址\n使用{config_name}作为FTP备份配置文件名",
                    },
                ]}
            />
        </div>
    </div >
}
export default connect()(BackupCmd)