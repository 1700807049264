import React from 'react'
import {
    Button, Modal, Spin,
    Card, Table, Tabs, Descriptions, Collapse, Input, Typography, Divider
} from "antd";
import socket from "@/socket/index";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FormikForm from '../../../components/FormikForm/index';
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import Utils from "@/utils/utils";
import moment from "moment";
import './index.scss';
import MonacoEditor from 'react-monaco-editor';
class Result extends React.Component {
    state = {
        TableData: [],
        dataVisible: false,
        cis: false,
        taskInfo: {},
        selectTask: {},
        task: {},
        taskId: "",
        searchIp: "",
        startTime: "",
        endTime: "",
        devicesList: [],
        current: 1,
        pageSize: 20,
        total: 0,
    };
    componentWillMount() {
        const task = JSON.parse(sessionStorage.getItem("task"));
        this.QueryTaskResultsList(task.id);
        this.setState({ task })
    }
    QueryTaskResultsInfo = (taskId, deviceIp, createTime) => {
        this.setState({ cis: true });
        socket.ExecuteAction("QueryTaskResultsInfo",
            { _id: taskId, deviceIp, createTime }, "", (result) => {
                console.log(result);
                this.setState({ taskInfo: result.data, taskId })
                this.setState({ cis: false });
            });
    };
    QueryTaskResultsList = (taskId, pageNum = 1, pageSize = 20, startTime = "", endTime = "") => {
        this.props.dispatch(isLoading(true));
        socket.ExecuteAction("QueryTaskResultsList",
            { _id: taskId, pageNum, pageSize, startTime, endTime }, "", (result) => {
                console.log(result);
                this.setState({
                    TableData: result.data,
                    total: result.total,
                    pageNum, pageSize, startTime, endTime
                });
                this.props.dispatch(isLoading(false));
            });
    };

    getTabs() {
        const { taskInfo } = this.state;
        const result = taskInfo.result || {};
        const resultKey = Object.keys(result);
        const elemt = <Tabs tabPosition="left">
            {resultKey.map((key) => {
                return <Tabs.TabPane tab={key} key={key}>
                    {result[key]}
                </Tabs.TabPane>
            })}
        </Tabs>
        return elemt
    }
    GetDesTable() {
        const { taskInfo, taskId, selectTask, devicesList } = this.state;
        const result = taskInfo.result || {};
        const deviceList = devicesList || [];
        const createTime = selectTask.createTime || "";
        const resultKey = Object.keys(result);

        const elemt = <div><Tabs tabPosition="left" onChange={(ip) => {
            this.QueryTaskResultsInfo(taskId, ip, createTime);
        }}>
            {deviceList.map((ip) => {
                return <Tabs.TabPane tab={ip} key={ip}>
                    <Descriptions column={1} bordered>
                        {resultKey.map((key) => {
                            let textVal = result[key];
                            if (typeof textVal === "object") {
                                textVal = JSON.stringify(result[key]);
                            } else if (typeof textVal === "number") {
                                textVal = textVal + "";
                            }
                            return <Descriptions.Item label={key} key={key}>
                                {((textVal).length < 200) ? textVal :
                                    <Collapse>
                                        <Collapse.Panel header="详情" key={key}>
                                            <MonacoEditor
                                                language="java"
                                                theme="vs-dark"
                                                height="500"
                                                options={{ minimap: { enabled: false } }}
                                                value={textVal + ""}
                                            />
                                        </Collapse.Panel>
                                    </Collapse>
                                }
                            </Descriptions.Item>

                        })}
                    </Descriptions>
                    {/* <Tabs>
                        {longTextList.map((obj, index) => {
                            return <Tabs.TabPane tab={obj.key} key={obj.key}>
                                <MonacoEditor
                                    language="java"
                                    theme="vs-dark"
                                    height="500"
                                    value={obj.value}
                                />
                            </Tabs.TabPane>
                        })}

                    </Tabs> */}
                </Tabs.TabPane>
            })}</Tabs>
        </div>
        return elemt
    }
    searchDeviceByIp() {
        const { searchIp, devicesList, taskInfo, taskId, selectTask, } = this.state;
        const newList = devicesList.filter((item) => {
            return item.includes(searchIp);
        });
        this.QueryTaskResultsInfo(taskId, newList[0] || [], selectTask.createTime || "");
        this.setState({ devicesList: newList })
    }
    reloadDevice() {
        const { searchIp, devicesList, taskInfo, taskId, selectTask, } = this.state;
        this.setState({ devicesList: selectTask.deviceList || [], searchIp: "" })
    }
    downloadData() {
        const { taskInfo, taskId, selectTask, devicesList } = this.state;
        console.log(taskInfo, taskId, selectTask, devicesList)
        const createTime = selectTask.createTime || "";

        let text_data = []
        devicesList.map((d_ip) => {
            socket.ExecuteAction("QueryTaskResultsInfo",
                { _id: taskId, deviceIp: d_ip, createTime }, "", (result) => {

                    // this.setState({ taskInfo: result.data, taskId })
                    // this.setState({ cis: false });
                    // text_data.push(result.data.result) 
                    let b_result = result.data.result
                    let text_content = ""
                    for (let key in b_result) {
                        text_content += "=================================== " + key + " ===================================\r\n";
                        text_content += b_result[key] + "\r\n";
                    }
                    text_data.push({ name: d_ip.replace(/\./g, "_"), data: text_content, })
                    // console.log({ name: result.data.result.text_name, data: result.data.result.text_content, })
                    if (devicesList.length === text_data.length) {
                        console.log(text_data);
                        const time = Utils.formateDate(new Date());
                        Utils.downloadZIP("任务数据" + time, text_data);
                    }
                    // console.log(devicesList.length, text_data.length)
                });
        })



    }
    render() {
        let { TableData, dataVisible, taskId, cis, task, searchIp, pageNum, pageSize, total, startTime, endTime } = this.state;
        return (
            <div className="taskResultDiv">
                <CustomBreadcrumb arr={["自定义任务", { title: "运维任务", to: "/customizeTask/list" }, "运维任务记录"]} />
                <Card title={Utils.BoldFont("运维任务记录")} extra={<Button type="primary"
                    onClick={() => {
                        const path3 = {
                            pathname: "/customizeTask/list",
                            bodyJson: this.props.location.bodyJson
                        }
                        this.props.history.push(path3);
                    }}
                >返回</Button>}>
                    <div className="head">
                        <div className="description">
                            {/* <Descriptions column={1} bordered size="small">
                                <Descriptions.apply label="任务名称">{task.name}</Descriptions.apply>
                                <Descriptions.apply label="任务标签">{task.tags}</Descriptions.apply>
                                <Descriptions.apply label="记录总数">{TableData.length}</Descriptions.apply>
                            </Descriptions> */}
                            <Typography.Title level={3}>{task.name}</Typography.Title>
                        </div>
                        <div>
                            <FormikForm
                                onSubmit={(val) => {
                                    console.log(val)
                                    const rangeTime = val.rangeTime;
                                    if (!rangeTime) return;
                                    const startTime = moment(rangeTime[0]).format("YYYY-MM-DD HH:mm:ss");
                                    const endTime = moment(rangeTime[1]).format("YYYY-MM-DD HH:mm:ss");
                                    this.QueryTaskResultsList(task.id, 1, 20, startTime, endTime);
                                }}
                                onCancel={() => {
                                    this.QueryTaskResultsList(task.id, 1, 20);
                                }}
                                initialValues={{}}
                                formList={[
                                    { type: "rangePicker", name: "rangeTime", label: "起止时间" },
                                ]}
                            />
                        </div>
                    </div>
                    <Table
                        columns={[
                            { title: "序号", render: (a, b, c) => { return <span key={c}>{c + 1}</span> } },
                            // { title: "任务名称", dataIndex: "taskName" },
                            // { title: "状态", dataIndex: "status" },
                            { title: "记录时间", dataIndex: "createTime" },
                            { title: "执行人", dataIndex: "username", },
                            {
                                title: "操作",
                                render: (text, Item, key) => {
                                    return <div key={key}>
                                        {/* <Button icon="diff" type="primary"
                                        onClick={() => {
                                            let _this = this;
                                            this.setState({ modalType: "报告", visible_insp: true })
                                            setTimeout(function () {
                                                _this.selectTime(HisItem["taskBeginTime"], HisItem);
                                            }, 10);

                                        }}
                                    >报告</Button>&nbsp; */}
                                        <Button icon="file-text" type="primary" size="small"
                                            onClick={() => {
                                                this.QueryTaskResultsInfo(taskId, Item.deviceList[0], Item.createTime);
                                                this.setState({ dataVisible: true, selectTask: Item, devicesList: Item.deviceList })
                                            }}
                                        >数据</Button>
                                    </div>
                                }
                            }
                        ]}
                        size="small"
                        dataSource={TableData}
                        rowKey={(r) => r.createTime}
                        pagination={
                            {
                                current: pageNum, pageSize: pageSize, total: total, onChange: (page, size) => {
                                    this.QueryTaskResultsList(task.id, page, size, startTime, endTime);
                                }
                            }
                        }
                    />
                    <Modal
                        title={<div className="customizeDataModal">
                            任务数据<span className="space" />
                            设备IP地址: <Input style={{ width: 200 }} placeholder="请输入设备IP地址搜索" value={searchIp} onChange={(e) => {
                                this.setState({ searchIp: e.target.value });
                            }} onPressEnter={() => this.searchDeviceByIp()}
                            />&nbsp;&nbsp;
                            <Button type="primary" icon="search" onClick={() => this.searchDeviceByIp()}>搜索</Button>&nbsp;&nbsp;
                            <Button icon="reload" onClick={() => this.reloadDevice()}>重置</Button>&nbsp;&nbsp;
                            <Button icon="download" onClick={() => this.downloadData()}>下载数据</Button>
                        </div>}
                        width="95%"
                        style={{ top: 20 }}
                        visible={dataVisible}
                        onCancel={() => { this.setState({ dataVisible: false }) }}
                        onOk={() => { this.setState({ dataVisible: false }) }}
                        destroyOnClose
                        className="dataModal"
                    >
                        <div className="dataDiv"><Spin spinning={cis}>
                            {this.GetDesTable()}
                        </Spin></div>
                    </Modal>
                </Card></div>
        )
    }
}





export default connect()(Result)