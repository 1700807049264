import React from 'react'
import { Card, Table, Button, Icon, Input, message, notification, Tabs, Popconfirm, Typography, Tag, Modal, Spin } from "antd";
import socket from "@/socket/index";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
const { Title } = Typography;
const TabPane = Tabs.TabPane;
export default class DNSserver extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _pageSize: 10,    //当前每页显示条数
        loading: false,
        detailLoading: false,
        detailModal: false,
        detailData: [],
        udspData: [],
        querys: null,    //查询条件
    };
    componentWillMount() {
        this.updata();
    }
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        socket.ExecuteAction("QueryDnsConfigHistory",
            bodyJson, "/QueryDnsConfigHistory", (result) => {
                let tableData = result.data;
                this.setState({
                    tableData,
                    _current: pageNum || 1,
                    _pageSize: pageSize || _pageSize,
                    total: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };
    getPlayDetail(tabItem) {
        this.setState({ detailModal: true, detailData: tabItem.configList, udspData: tabItem.reqBody.data });
        // this.setState({ detailData, detailLoading: false }) 
    }
    render() {
        let { _current, _pageSize, querys, tableData, loading, total, detailModal, detailData, detailLoading, udspData } = this.state;
        const columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: "key",
                render: (text, item, index) => { return <span>{index + 1 + (_current - 1) * _pageSize}</span> }
            },
            {
                title: '开始时间',
                align: 'center',
                dataIndex: 'createTime',
            },
            // {
            //     title: '类型',
            //     align: 'center',
            //     render: (text, item, index) => { return <span>增加</span> }
            // },
            {
                title: ' 单号',
                align: 'center',
                dataIndex: 'serviceId',
            },
            {
                title: '目标DNS服务器地址',
                align: 'center',
                dataIndex: 'reqBody.dns_server_ip',
                render: (text, item, index) => {
                    let arr = text || [];
                    return arr.map((atext, index) => { return <div key={index}>{atext}</div> })
                }
            },
            {
                title: '状态',
                align: 'center',
                dataIndex: 'status',
                render: (text) => {
                    const tst = text === 0 ? "success" : "fail"
                    return (<Tag color={Utils.ColorShow(tst)}>{Utils.Translation(tst)}</Tag>)
                }
            },
            {
                title: '操作',
                align: 'center',
                render: (text, item) => {
                    return <Button type="primary" icon="form" onClick={() => {
                        this.getPlayDetail(item);

                    }}>详情</Button>
                }
            }
        ];
        const FormList = [
            // {
            //     label: "类型",
            //     type: 'SELECT',
            //     field: "type",
            //     initialValue: '',
            //     list: [{ id: 'add', name: '增加' }, { id: 'delete', name: '删除' }]
            // },
            {
                label: "单号",
                type: 'INPUT',
                field: "serviceId",
                initialValue: '',
                placeholder: "单号"
            },

        ]
        return (
            <div>
                <CustomBreadcrumb arr={["DNS记录", "操作记录"]} />
                <Card>
                    <FilterForm
                        formList={FormList}
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(_pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(_pageSize, 1, {})
                        }}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>操作记录列表</span>}
                    style={{ marginTop: 8 }}>

                    <Table
                        dataSource={tableData}
                        columns={columns}
                        size="small"
                        loading={loading}
                        // pagination={false}
                        rowKey={(text) => { return text._id }}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.updata(size, 1, querys)
                            },
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                this.updata(pageSize, page, querys);
                            }
                        }}
                    />
                </Card>
                <Modal
                    title="详情"
                    visible={detailModal}
                    onCancel={() => this.setState({ detailModal: false })}
                    onOk={() => this.setState({ detailModal: false })}
                    width="60%"
                    style={{ top: "20px" }}
                    destroyOnClose
                >
                    <Spin spinning={detailLoading}>
                        <Tabs type="card" >
                            <TabPane tab={"配置信息"} key={"1"}>
                                <div style={{ fontWeight: 600, paddingLeft: 10, paddingBottom: 20 }}>增加的配置信息:</div>
                                {detailData.map((item, key) => {
                                    return <p key={key} style={{ marginLeft: 20 }}>{item}</p>
                                })}
                            </TabPane>
                            <TabPane tab={"udsp数据"} key={"2"}>
                                <Table
                                    dataSource={udspData}
                                    columns={[
                                        { title: "时间", dataIndex: "time", width: 200 },
                                        { title: "domainName", dataIndex: "domainName" },
                                        { title: "ipAddress", dataIndex: "ipAddress" },
                                        { title: "personId", dataIndex: "personId" },
                                    ]}
                                    size="small"
                                    scroll={{ y: 500 }}
                                    pagination={false}
                                />
                            </TabPane>
                            {/* <TabPane tab={"校验返回错误"} key={"3"}>
                                <Table
                                    dataSource={udspData}
                                    columns={[
                                        { title: "时间", dataIndex: "time", width: 200 },
                                        { title: "domainName", dataIndex: "domainName" },
                                        { title: "ipAddress", dataIndex: "ipAddress" },
                                        { title: "personId", dataIndex: "personId" },
                                    ]}
                                    size="small"
                                    scroll={{ y: 500 }}
                                    pagination={false}
                                />
                            </TabPane> */}
                        </Tabs>
                    </Spin>
                </Modal>
            </div>
        )
    }
}
