/**
 * Created by YIMINE on 2019/3/22.
 */
import React from 'react'
import './index.scss'
import { Card, Icon, Row, Col, Progress, } from "antd";
import Socket from "@/socket/index"
import QueueAnim from 'rc-queue-anim';
export default class statiTask extends React.Component {
    state = {
        tableData: [],
        statusTotal: {
            run: 0,
            done: 0,
            new: 0,
            terminal: 0,
        },
        total: 0,
        progressObj: {
            run: 0,
            done: 0,
            new: 0,
            terminal: 0,
        },
    };
    componentWillMount() {
        this.updataTask();
    }
    updataTask = () => {
        var bodyJson = new Object();
        bodyJson.pageSize = 100000;
        bodyJson.pageNum = 1;
        bodyJson.querys = { ifTemplate: false };
        Socket.ExecuteAction("QueryTasksPage",
            bodyJson, "/overViewQueryTask", (result) => {
                let tableData = result.tasks;
                let total = result.total;
                let statusTotal = {
                    run: 0,
                    done: 0,
                    new: 0,
                    terminal: 0,
                }
                tableData.map((item, index) => {
                    item.key = index;
                    switch (item.taskStatus) {
                        case "new": statusTotal.new = statusTotal.new + 1; break;
                        case "done": statusTotal.done = statusTotal.done + 1; break;
                        case "run": statusTotal.run = statusTotal.run + 1; break;
                        case "terminal": statusTotal.terminal = statusTotal.terminal + 1; break;
                    }
                });
                console.log(tableData);
                // console.log(total);
                let progressObj = {}
                for (let key in statusTotal) {
                    const temp = statusTotal[key];

                    const numFloat = (temp / total);
                    // progressObj[key] = (Math.round((temp / total) * 100) / 100) * 100;
                    progressObj[key] = numFloat;
                    // console.log(parseFloat((temp / total).toFixed(2))); 
                }
                // console.log(progressObj);
                this.setState({
                    tableData, statusTotal, total,
                    progressObj
                })
            }, () => { });
    };
    render() {
        let { statusTotal, total, progressObj } = this.state;
        return (
            <Card title={<span style={{ fontWeight: 600 }}>任务概览-任务:({total})</span>} className="Card-task">

                <Row gutter={24} key={"www"} style={{ fontWeight: "bold" }}>
                    <QueueAnim type="bottom" duration={1500} interval={100}>
                        <Col span={6} key={1}>
                            <div className="div-content">
                                <Progress type="circle" percent={parseFloat((progressObj.run * 100).toFixed(2))} className="progressSty" width={100} strokeWidth={10} />
                                <div className="div-info">
                                    进行中任务<Icon type="info-circle" theme="twoTone" />&nbsp;
                                    <strong>{statusTotal.run}</strong>
                                </div>
                            </div>
                        </Col>
                        <Col span={6} key={2}>
                            <div className="div-content" >
                                <Progress type="circle" percent={parseFloat((progressObj.done * 100).toFixed(2))} className="progressSty" width={100} strokeColor="#87d068" strokeWidth={10} />
                                <div className="div-info">
                                    已完成任务<Icon type="info-circle" theme="twoTone" />&nbsp;
                                    <strong>{statusTotal.done}</strong>
                                </div>
                            </div>
                        </Col>
                        <Col span={6} key={3}>
                            <div className="div-content" >
                                <Progress type="circle" percent={parseFloat((progressObj.new * 100).toFixed(2))} className="progressSty" width={100} strokeColor="#87CEFF" strokeWidth={10} />
                                <div className="div-info">
                                    新建任务<Icon type="info-circle" theme="twoTone" />&nbsp;
                                    <strong>{statusTotal.new}</strong>
                                </div>
                            </div>
                        </Col>
                        <Col span={6} key={4}>
                            <div className="div-content" >
                                <Progress type="circle" percent={parseFloat((progressObj.terminal * 100).toFixed(2))} className="progressSty" width={100} strokeColor="red" strokeWidth={10} />
                                <div className="div-info">
                                    终止任务<Icon type="info-circle" theme="twoTone" />&nbsp;
                                    <strong>{statusTotal.terminal}</strong>
                                </div>
                            </div>
                        </Col>
                    </QueueAnim>
                </Row>

            </Card>
        );
    }
}