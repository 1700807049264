import React from 'react'
import {
    Table, Button, Icon, Input, Row, Col, Form,
    Modal, Affix, Alert
} from "antd";
import { connect } from 'react-redux'
import SelectDevice from './checkDev';
import Utils from '@/utils/utils';
import ResUtils from '@/utils/ResUtils';
import MonacaEditor from "react-monaco-editor"
import "./index.scss"
class SimpleForm extends React.Component {
    state = {
        deviceIp: null,
        selectedRowKeys: [],
        selectedRows: [],
        visibleDev: false,
        querys: { "resourceParams.deviceFirm": "CISCO" },
        screenSize: Utils.getScreenSize(),
    }
    handleSubmit = () => {
        let _this = this;
        const { selectedRows } = this.state;
        if (selectedRows.length === 0) {
            Modal.info({
                title: "消息提示",
                content: "请选择下发设备！",
                okText: "确定",
            })
            return
        }
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.ips = selectedRows.map((row) => { return row.resourceParams.deviceIp });
                console.log('Received values of form: ', values);
                Modal.confirm({
                    title: "消息提示",
                    content: "你确定下发指令到所选设备吗?",
                    okText: "确定",
                    okType: "danger",
                    onOk() {
                        _this.props.onSubmit(values);
                    }
                })
                // _this.props.form.resetFields();
            }
        });
    };
    reset = () => {
        this.props.form.resetFields();
        this.setState({ deviceIp: null, selectedRowKeys: [], selectedRows: [] })
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { selectNode, loading } = this.props;
        const { selectedRowKeys, querys, visibleDev, selectedRows, screenSize } = this.state;
        return <div>
            <div className="formTabpane">
                <Alert
                    message={<div><Icon type="info-circle" theme="twoTone" />&nbsp;提示信息</div>}
                    description="简单模式下发，编辑下发指令信息，直接进行指令的下发。"
                    type="info"
                    className="toolInfo" />
                <Form
                    labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}
                    onSubmit={this.handleSubmit}>
                    <Form.Item label={"下发名称"} >
                        {getFieldDecorator("config_delivery_name", {
                            initialValue: selectNode.config_delivery_name || "",
                            rules: [{
                                required: true, message: '请填写下发名称!',
                            }],
                        })(<Input placeholder="本次下发任务名称" />)}
                    </Form.Item>
                    <Form.Item label={"下发描述"} >
                        {getFieldDecorator("details", {
                            initialValue: selectNode.details || "",
                        })(<Input.TextArea placeholder="本次下发任务描述" />)}
                    </Form.Item>
                    <Form.Item label={"下发指令"} >
                        {getFieldDecorator("config_cmd", {
                            initialValue: selectNode.config_cmd || "",
                            rules: [{
                                required: true, message: '请填写下发指令!',
                            }],
                        })(
                            <MonacaEditor
                                theme="vs-dark"
                                language="shell"
                                // options={{ minimap: { enabled: false }, lineNumbers: "off" }} 
                                options={{ scrollBeyondLastColumn: 1, scrollBeyondLastLine: false, lineNumbers: "off" }}
                                height={screenSize.height * 0.35}
                            />
                        )}
                    </Form.Item>
                    {/* <Form.Item label={"下发设备"} >
                        <div className="deviceDiv">
                            <Table
                                columns={[{
                                    title: '序号',
                                    align: 'center',
                                    ellipsis: true,
                                    width: 40,
                                    dataIndex: "key",
                                    render: (text, item, index) => {
                                        return <span>{index + 1}</span>
                                    }
                                },
                                {
                                    title: '设备IP',
                                    align: 'left',
                                    width: 100,
                                    ellipsis: true,
                                    dataIndex: 'resourceParams.deviceIp',
                                },
                                {
                                    title: '厂商',
                                    align: 'left',
                                    ellipsis: true,
                                    dataIndex: 'resourceParams.deviceFirm',
                                },
                                {
                                    title: '设备名称',
                                    ellipsis: true,
                                    align: 'left',
                                    dataIndex: 'resourceParams.deviceId',
                                },
                                {
                                    title: '型号',
                                    ellipsis: true,
                                    align: 'left',
                                    dataIndex: 'resourceParams.deviceVersion',
                                },
                                {
                                    title: '标签',
                                    dataIndex: 'tags',
                                    align: "left",
                                    render: (text) => {
                                        return <div>{Utils.hidenTag(text)}</div>;
                                    }
                                }, {
                                    title: "操作",
                                    render: (a, item) => {
                                        return <Button type="danger" icon="delete" size="small" onClick={() => {
                                            const newData = selectedRows.filter((row) => { return row._id != item._id });
                                            const newData2 = selectedRowKeys.filter((row) => {
                                                return row != JSON.stringify({ resourceName: item.resourceName })
                                            });
                                            this.setState({ selectedRows: newData, selectedRowKeys: newData2 })
                                        }} />
                                    }
                                }]}
                                dataSource={selectedRows}
                                pagination={false}
                                locale={{ emptyText: <div><Icon type="inbox" />暂无数据</div> }}
                                size="small"
                                rowKey={(row) => row._id}
                                scroll={{ y: screenSize.height * 0.25 }}
                            />
                        </div>
                        <Button style={{ width: "100%", marginTop: 5 }} icon="plus-circle" type="dashed"
                            onClick={() => {
                                this.setState({ visibleDev: true })
                            }}>添加设备</Button>
                    </Form.Item> */}
                    <Row>
                        <Col span={24} className="tempsty" >
                            <div className="ant-form-item-label">
                                <label className="ant-form-item-required" >下发设备</label>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="deviceDiv">
                                <Table
                                    columns={[...ResUtils.getColumns(10000, 1), {
                                        title: "操作",
                                        render: (a, item) => {
                                            return <Button type="danger" icon="delete" size="small" onClick={() => {
                                                const newData = selectedRows.filter((row) => { return row._id != item._id });
                                                const newData2 = selectedRowKeys.filter((row) => {
                                                    return row != JSON.stringify({ resourceName: item.resourceName })
                                                });
                                                this.setState({ selectedRows: newData, selectedRowKeys: newData2 })
                                            }} />
                                        }
                                    }]}
                                    dataSource={selectedRows}
                                    pagination={false}
                                    locale={{ emptyText: <div><Icon type="inbox" />暂无数据</div> }}
                                    size="small"
                                    rowKey={(row) => row._id}
                                    scroll={{ y: screenSize.height * 0.25 }}
                                />
                            </div>
                            <Button style={{ width: "100%", marginTop: 5 }} icon="plus-circle" type="dashed"
                                onClick={() => {
                                    this.setState({ visibleDev: true })
                                }}>添加设备</Button>
                        </Col>
                    </Row><br />
                </Form>
            </div>
            <div className="bootmBtn">
                <Button style={{ width: 150 }} type="primary" loading={loading} icon="thunderbolt" onClick={() => {
                    this.handleSubmit()
                }}>{loading ? "发送中" : "开始下发"}
                </Button>&nbsp;
                        <Button style={{ width: 150 }} icon="reload" onClick={() => this.reset()} >清空表单</Button>
            </div>
            <Modal
                title="选择设备"
                visible={visibleDev}
                width={"99%"}
                style={{ top: 10 }}
                onCancel={() => { this.setState({ visibleDev: false, }); }}
                onOk={() => { this.setState({ visibleDev: false, }); }}
            //不能添加destroyOnClose，会导致选择错误
            >
                <SelectDevice
                    onChange={(selectedRowKeys, selectedRows) => {
                        this.setState({ selectedRowKeys, selectedRows, })
                    }}
                    querys={querys}
                    selectedRowKeys={selectedRowKeys}
                />
            </Modal>
        </div>
    }

}
const CommObj = Form.create()(SimpleForm)
export default connect()(CommObj)