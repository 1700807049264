import React from 'react'
import { Card, Table, Button, message, Popconfirm, Icon, Input, Tree, Row, Col, Form, Modal, Select } from "antd";
import Socket from "../../../socket/index";
import iconClass from "./iconList";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import EditMenuForm from './editMenuForm';
import Utils from '@/utils/utils';
import './index.scss';

const { TreeNode } = Tree;
const Search = Input.Search;
const versionName = sys_config.versionName;


class MenuClass extends React.Component {
    state = {
        TreeData: [],
        expandedKeys: [],
        dataList: [],
        searchValue: '',
        autoExpandParent: false,
        addVisible: false,
        selectNode: {},
        RightMenuStyle: {},
        rightClickNodeTreeItem: null
    };
    componentWillMount() {
        this.getMenuData();
    }

    onDragEnter = info => {
        // console.log(info);
        // expandedKeys 需要受控时设置
        // this.setState({
        //   expandedKeys: info.expandedKeys,
        // });
        // let dragNodesKeys = info.dragNodesKeys;
        // let key = dragNodesKeys[dragNodesKeys.length - 1];
        // let index = info.dropPosition + 2;

        // Socket.ExecuteAction("UpdateMenu", { key, index }, "", (result) => {
        //     message.success("修改成功!");
        // });
    };
    getMenuData = () => {
        Socket.ExecuteAction('QueryMenu', {}, "", (result) => {
            let data = result.data.filter(dataItem => {
                if( dataItem.key !== '/maintain' && dataItem.key !== '/Terminal') {
                    return dataItem
                }
            })
            const dataList = [];
            const TreeData = versionName === 'pinganTerminal' ? data : result.data;
            const generateList = data => {
                for (let i = 0; i < data.length; i++) {
                    const node = data[i];
                    const { key, title } = node;
                    dataList.push({ key, title });
                    if (node.children) {
                        generateList(node.children);
                    }
                }
            };
            generateList(TreeData);
            this.setState({
                TreeData,
                dataList
            });
        });
    };
    updataMenuData = (action, body, newTreeData) => {
        console.log(body);
        Socket.ExecuteAction(action, body, "", (result) => {
            message.success("操作成功!");
            this.getMenuData();
            // const dataList = [];
            // const TreeData = newTreeData;
            // const generateList = data => {
            //     for (let i = 0; i < data.length; i++) {
            //         const node = data[i];
            //         const { key, title } = node;
            //         dataList.push({ key, title });
            //         if (node.children) {
            //             generateList(node.children);
            //         }
            //     }
            // };
            // generateList(TreeData);
            this.setState({
                // TreeData,
                // dataList,
                selectNode: body
            });
        });
    };

    onDrop = info => {
        console.log(info);
        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dropPos = info.node.props.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        const source = info.dragNode.props.pos.split("-");
        // console.log(dropKey, dragKey, dropPos, dropPosition, source);
        let _flag = true;

        if (source.length != dropPos.length) {
            _flag = false;
        } else {
            source.map((item, key) => {
                console.log(item, dropPos[key])
                if (item != dropPos[key] && (key + 1) != source.length) {
                    _flag = false;
                }
            })
        }
        if (!_flag) { message.error("暂不支持跨级操作!"); return };
        // const loop = (data, key, callback) => {
        //     data.forEach((item, index, arr) => {
        //         if (item.key === key) {
        //             return callback(item, index, arr);
        //         }
        //         if (item.children) {
        //             return loop(item.children, key, callback);
        //         }
        //     });
        // };
        // const data = [...this.state.TreeData];

        // // Find dragObject
        // let dragObj;
        // loop(data, dragKey, (item, index, arr) => {
        //     arr.splice(index, 1);
        //     dragObj = item;
        // });

        // if (!info.dropToGap) {
        //     // Drop on the content
        //     loop(data, dropKey, item => {
        //         item.children = item.children || [];
        //         // where to insert 示例添加到尾部，可以是随意位置
        //         item.children.push(dragObj);
        //     });
        // } else if (
        //     (info.node.props.children || []).length > 0 && // Has children
        //     info.node.props.expanded && // Is expanded
        //     dropPosition === 1 // On the bottom gap
        // ) {
        //     loop(data, dropKey, item => {
        //         item.children = item.children || [];
        //         // where to insert 示例添加到头部，可以是随意位置
        //         item.children.unshift(dragObj);
        //     });
        // } else {
        //     let ar;
        //     let i;
        //     loop(data, dropKey, (item, index, arr) => {
        //         ar = arr;
        //         i = index;
        //     });
        //     if (dropPosition === -1) {
        //         ar.splice(i, 0, dragObj);
        //     } else {
        //         ar.splice(i + 1, 0, dragObj);
        //     }
        // }

        // this.setState({
        //     TreeData: data,
        // });

        let dragNodes = info.dragNode;
        let body = {
            key: dragNodes.props.eventKey,
            parentKey: dragNodes.props.parentKey,
            index: parseInt(dropPos[dropPos.length - 1]) + 1,
            title: dragNodes.props.titleName,
            display: dragNodes.props.display,
            interface: dragNodes.props.interface,
            interfaceMethod: dragNodes.props.interfaceMethod,
            icon: dragNodes.props.iconName,
        }
        Socket.ExecuteAction("UpdateMenu", body, "", (result) => {
            message.success("修改成功!");
            this.getMenuData();
        });
    };
    getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some(item => item.key === key)) {
                    parentKey = node.key;
                } else if (this.getParentKey(key, node.children)) {
                    parentKey = this.getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };
    onChange = e => {
        const { value } = e.target;
        let expandedKeys = [];
        if (value.trim() != "") {
            expandedKeys = this.state.dataList
                .map(item => {
                    if (item.title.indexOf(value) > -1) {
                        return this.getParentKey(item.key, this.state.TreeData);
                    }
                    return null;
                })
                .filter((item, i, self) => item && self.indexOf(item) === i);
        }
        this.setState({
            expandedKeys,
            searchValue: value,
            autoExpandParent: true,
        });
    };
    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };
    // tree列表上右键事件
    onRightClick = e => {
        let selectNode = {
            title: e.node.props.titleName,
            key: e.node.props.eventKey,
            display: e.node.props.display,
            interface: e.node.props.interface,
            interfaceMethod: e.node.props.interfaceMethod,
            parentKey: e.node.props.parentKey,
            icon: e.node.props.iconName
        }
        this.setState({
            selectNode,
            rightClickNodeTreeItem: {
                pageX: e.event.clientX,
                pageY: e.event.clientY,
                idKey: e.node.props["eventKey"],
                categoryName: e.node.props["titleName"]
            }
        });
    };


    //点击右键菜单选项
    clickRightMenu(key, nodeKey) {
        let { TreeData, selectNode } = this.state;
        let newTreeData = TreeData;
        let actionFlag;
        switch (key) {
            case "del":
                // newTreeData = this.deleteMenu(TreeData, nodeKey);
                // console.log(newTreeData);
                this.updataMenuData("DeleteMenu", selectNode, newTreeData);
                break;
            case "add": actionFlag = "add"; break;
            case "addChild": actionFlag = "addChild"; break;
        }
        let state = true;
        if (key === "del") {
            state = false
        }
        this.setState({ addVisible: state, rightClickNodeTreeItem: null, actionFlag, nodeKey, })
    }
    // 自定义右键菜单内容
    getNodeTreeRightClickMenu = () => {
        const { pageX, pageY, idKey, categoryName } = { ...this.state.rightClickNodeTreeItem };
        const RightMenuStyle = {
            position: "fixed",
            // left: `${pageX - 230}px`,
            // top: `${pageY - 118}px`,
            left: `${pageX}px`,
            top: `${pageY}px`,
        };
        const menu = (
            <div style={RightMenuStyle} className="TreeRightMenu">
                <div onClick={() => this.clickRightMenu("addChild", idKey)} className="add"><Icon type="plus-circle" />  新增子菜单</div>
                <div onClick={() => this.clickRightMenu("add", idKey)} className="add"><Icon type="plus-circle" />  新增同级菜单</div>
                <Popconfirm
                    title="确定删除吗?"
                    onConfirm={() => this.clickRightMenu("del", idKey)}
                    okText="Yes"
                    cancelText="No"
                >
                    <div className="delete"><Icon type="delete" />  删除选中菜单</div>
                </Popconfirm>
            </div>
        );
        return this.state.rightClickNodeTreeItem == null ? "" : menu;
    };

    addChildNode(_treeData, nodeKey, values) {
        let newtreeData = _treeData.map((node) => {
            if (node["key"] === nodeKey) {
                if (node["children"]) {
                    node["children"].push(values);
                } else {
                    node["children"] = [values];
                }
            } else if (node["children"]) {
                node["children"] = this.addChildNode(node["children"], nodeKey, values);
            }
            return node;
        });
        return newtreeData;
    }
    deleteMenu(_treeData, nodeKey) {
        let newtreeData = [];
        _treeData.filter((node) => {
            if (node["key"] === nodeKey) {

            } else if (node["children"]) {
                node["children"] = this.deleteMenu(node["children"], nodeKey);
                newtreeData.push(node);
            } else {
                return newtreeData.push(node);
            }
        });
        return newtreeData;
    }
    upDataSubmit() {
        // e.preventDefault();
        let _this = this;
        let { actionFlag, TreeData, nodeKey, selectNode } = this.state;
        let newTreeData = TreeData;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                switch (actionFlag) {
                    case "add":
                        newTreeData.push(values);
                        values.parentKey = selectNode.parentKey;
                        _this.updataMenuData("AddMenu", values, newTreeData);
                        break;
                    case "addChild":
                        // newTreeData = _this.addChildNode(TreeData, nodeKey, values);
                        values.parentKey = selectNode.key;
                        _this.updataMenuData("AddMenu", values, newTreeData);
                        break;
                    // case "del":
                    //     newTreeData = this.deleteMenu(TreeData, nodeKey);
                    //     _this.updataMenuData("DeleteMenu", { key: selectNode["key"] }, newTreeData);
                    //     break;
                }
                console.log(newTreeData)
                // _this.updataMenuData(newTreeData);

                _this.setState({ addVisible: false });

            }
        });
    }
    changeDataFun(_treeData, nodeObj) {
        let nodeKey = nodeObj["key"];
        let newTreeData = _treeData.map((node) => {
            if (node["key"] === nodeKey) {
                let newNode = nodeObj;
                if (node["children"]) {
                    newNode["children"] = node["children"]
                }
                return newNode;
            } else if (node["children"]) {
                node["children"] = this.changeDataFun(node["children"], nodeObj);
                return node;
            } else {
                return node;
            }
        });
        return newTreeData
    }
    changeTreeData(values) {
        let _this = this;
        let { TreeData, selectNode } = this.state;
        let newTreeData = this.changeDataFun(TreeData, values);
        console.log(newTreeData);
        values.parentKey = selectNode.parentKey;
        _this.updataMenuData("UpdateMenu", values, newTreeData);
    }
    render() {
        let { selectNode, expandedKeys, autoExpandParent, searchValue, addVisible } = this.state
        const { getFieldDecorator } = this.props.form;
        const loop = (data, parentKey) =>
            data.map((item, menuIndex) => {
                const index = item.title.indexOf(searchValue);
                const beforeStr = item.title.substr(0, index);
                const afterStr = item.title.substr(index + searchValue.length);
                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span style={{ color: '#f50' }}>{searchValue}</span>
                            {afterStr}
                        </span>
                    ) : (
                            <span>{item.title}</span>
                        );
                if (item.children) {
                    return (
                        <TreeNode icon={<Icon type={item.icon || "file"} />} key={item.key} title={title} display={item.display}
                            titleName={item.title}
                            parentKey={parentKey}
                            interface={item.interface}
                            interfaceMethod={item.interfaceMethod}
                            index={menuIndex}
                            iconName={item.icon}>
                            {loop(item.children, item.key)}
                        </TreeNode>
                    );
                }
                return <TreeNode icon={<Icon type={item.icon || "file"} />} key={item.key} title={title} display={item.display}
                    titleName={item.title}
                    parentKey={parentKey}
                    interface={item.interface}
                    interfaceMethod={item.interfaceMethod}
                    index={menuIndex}
                    iconName={item.icon} />;
            });
        return (<div><CustomBreadcrumb arr={["系统管理", "菜单管理"]} />
            <Card title="菜单树形图" className="treeCrad" >
                <Row>
                    <Col span={8} >
                        <Search style={{ marginBottom: 8 }} placeholder="Search" onChange={this.onChange} />

                        <Tree
                            showLine
                            showIcon
                            onSelect={(nodeKey, node) => {
                                // console.log(node);
                                let selectNode = {
                                    title: node.node.props.titleName,
                                    key: node.node.props.eventKey,
                                    display: node.node.props.display,
                                    interface: node.node.props.interface,
                                    interfaceMethod: node.node.props.interfaceMethod,
                                    parentKey: node.node.props.parentKey,
                                    icon: node.node.props.iconName,
                                    index: node.node.props.index
                                }
                                this.setState({ selectNode, rightClickNodeTreeItem: null })
                            }}
                            onRightClick={this.onRightClick}
                            onExpand={this.onExpand}
                            expandedKeys={expandedKeys}
                            autoExpandParent={autoExpandParent}
                            draggable
                            blockNode
                            onDragEnter={this.onDragEnter}
                            onDrop={this.onDrop}
                        >
                            {loop(this.state.TreeData, "")}
                        </Tree>
                    </Col>
                    <Col span={16} onClick={() => this.setState({ rightClickNodeTreeItem: null })}>
                        <EditMenuForm selectNode={selectNode} onSubmit={(values) => { this.changeTreeData(values) }} />
                    </Col>
                </Row>
                {this.getNodeTreeRightClickMenu()}
                <Modal
                    title={"新增"}
                    visible={addVisible}
                    onOk={() => { this.upDataSubmit() }}
                    onCancel={() => { this.setState({ addVisible: false }) }}
                    okText="确认"
                    cancelText="取消"
                    // destroyOnClose
                    afterClose={() => {
                        // this.setState({ selectOne: {} })
                        this.props.form.resetFields();//清空表单
                    }}
                >
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onSubmit={this.upDataSubmit}>
                        <Form.Item
                            label="菜单名称"
                        >
                            {getFieldDecorator('title', {
                                // initialValue: selectNode.title || "",
                                rules: [{
                                    required: true, message: '请输入字符!',
                                }],
                                placeholder: "菜单名称"
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label="菜单URL路径"
                        >
                            {getFieldDecorator('key', {
                                // initialValue: selectNode.key || "",
                                rules: [{
                                    required: true, message: '请输入字符!',
                                }],
                                placeholder: "菜单URL路径"
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label="菜单图标"
                        >
                            {getFieldDecorator('icon', {
                                // initialValue: selectNode.icon || "",
                                rules: [{
                                    required: true, message: '请输入字符!',
                                }],
                                placeholder: "菜单图标"
                            })(iconClass.getIconArray())}
                        </Form.Item>
                        <Form.Item
                            label="是否导航菜单"
                        >
                            {getFieldDecorator('display', {
                                // initialValue: selectNode.key || "",
                                rules: [{
                                    required: true, message: '请选择!',
                                }],
                            })(<Select>
                                <Select.Option value={"yes"} key={"yes"}>yes</Select.Option>
                                <Select.Option value={"no"} key={"no"}>no</Select.Option>
                            </Select>)}
                        </Form.Item>
                        <Form.Item
                            label="对应后台接口"
                        >
                            {getFieldDecorator('interface', {
                                // initialValue: selectNode.interface || "",
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="对应后台接口方法"
                        >
                            {getFieldDecorator('interfaceMethod', {})(
                                <Select >
                                    <Select.Option value="GET">GET</Select.Option>
                                    <Select.Option value="POST">POST</Select.Option>
                                    <Select.Option value="PUT">PUT</Select.Option>
                                    <Select.Option value="DELETE">DELETE</Select.Option>
                                    <Select.Option value="PATCH">PATCH</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </Card>
        </div>
        );
    }
}
const MenuClassObj = Form.create()(MenuClass)
export default MenuClassObj
