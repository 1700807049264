// 获取列表
import service from "../zx-utils/request";

export function fetchReport1(query, callback) {
    service({
        url: '/zx/report/',
        method: 'get',
        params: query
    }).then(res => {
        callback(res)
    })
}
export function fetchReport2(query, callback) {
    service({
        url: '/zx/report2/',
        method: 'get',
        params: query
    }).then(res => {
        callback(res)
    })
}