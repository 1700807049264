import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Divider, Popconfirm, Tag, Descriptions, List, Modal, Affix
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import { snmp_modules, } from '../utils/api';
import { connect } from 'react-redux'
import moment from 'moment';
import "./index.scss"
import "animate.css"
import { title } from '@jiaminghi/data-view-react/lib/index-afbd7e09';

function ModuleList(props) {
    const [_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const [selectedRowKeys, setRowKeys] = useState([])
    const [tableData, setTabData] = useState([])
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
            props.onChange(Keys)
        },
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'ip',
            ellipsis: true,
            width: 50,
            render: (text, item, c) => { return c + 1 + (_current - 1) * _pageSize }
        },
        {
            title: '模块名称',
            dataIndex: 'module_name',
            ellipsis: true,
        },
        {
            title: 'SNMP版本',
            dataIndex: 'version',
            ellipsis: true,
        },
        {
            title: '采集频率',
            dataIndex: 'scrape_interval',
            ellipsis: true,
        },
        {
            title: '采集超时',
            dataIndex: 'timeout',
            ellipsis: true,
        },
        {
            title: '重试次数',
            dataIndex: 'retries',
            ellipsis: true,
        },
        {
            title: 'community',
            dataIndex: 'auth',
            ellipsis: true,
            render: (text, item) => {
                return text.community
            }
        },
        {
            title: '描述',
            dataIndex: 'description',
            ellipsis: true,
        },
    ]
    useEffect(() => {//componentDidMount;
        getData();
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(page = 1, size = _pageSize, query = _querys) {
        http.get(snmp_modules(), { page, size, ...query }, (r) => {
            setTabData(r.data.records);
            // setTotal(r.data.total);
            set_querys(query);
            set_current(page);
        })
    }
    function expandedRowRender(record, index) {
        const columns2 = [{ title: "指标名称", dataIndex: "metric_name" }, { title: "OID", dataIndex: "oid" },
            { title: "描述", dataIndex: "description" }, { title: "自定义指标名称", dataIndex: "custom_metric_name" }];
        return <div className="expandedTable">
            <p>{record.module_name}指标信息</p>
            <Table columns={columns2} size="small" dataSource={record.metric_infos} pagination={false} />
        </div>;
    }
    return <div className="snmpModuleList">
        <Table
            rowSelection={rowSelection}
            dataSource={tableData}
            columns={columns}
            rowKey={(r) => r.module_name}
            size="small"
            pagination={false}
            expandedRowRender={(record, index) => expandedRowRender(record, index)}
        />

    </div>
}

export default connect()(ModuleList)