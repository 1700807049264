import React from 'react'
import { Button, Modal, Icon, Table, notification, Tag } from 'antd'
import AxiosObj from '@/axios/index';
import { connect } from 'react-redux'
import Utils from '@/utils/utils';
import MonacaEdit from "react-monaco-editor"
class TempRecords extends React.Component {
    state = {
        Templates: [],
        selectedRowKeys: [],
        selectedRows: [],
        Recordes: [],
        activeTemp: "",
        _pageSize: 20,
        _current: 1,
        _total: 1,
    }
    componentDidMount() {
        this.updataTempRecordes(null, null, this.props.tempName);
    }


    updataTempRecordes = (pageSize, pageNum, template_name) => {
        const { _pageSize, _current } = this.state;
        const props = this.props;
        let bodyJson = {
            page_size: pageSize || _pageSize,
            page_no: pageNum || _current,
            template_name
        };
        props.dispatch({ type: "IS_LOADING", isloading: true });
        AxiosObj.HTTP_POST("acl", "aclTemplateByPage", bodyJson, (res) => {
            this.setState({
                Recordes: res.data,
                _total: res.count,
                _pageSize: bodyJson.pageSize,
                _current: bodyJson.pageNum,
            })

            props.dispatch({ type: "IS_LOADING", isloading: false });
        });
    }
    deleteTempRes = (template_name, insert_time) => {
        const param = {
            template_name,
            insert_time
        }
        AxiosObj.HTTP_POST("acl", "delAclTemplate", param, (result) => {
            console.log(result);
            notification.success({ message: '提示', description: result.msg });
            this.updataTempRecordes(20, 1, template_name);
        });
    }
    render() {
        const { Recordes, _pageSize, _current, _total, selectedRowKeys, selectedRows } = this.state;
        return <div>
            <Table
                columns={[{
                    title: "序号",
                    align: "center",
                    render: (a, b, c) => { return c + 1 }
                }, {
                    title: "名称",
                    dataIndex: "template_name",
                }, {
                    title: "厂商",
                    dataIndex: "manufacturer",
                    // }, {
                    //     title: "区域",
                    //     dataIndex: "template_zone_id",
                    //     render(a, b, c) {
                    //         const ele = a.map((item) => {
                    //             return <Tag color="geekblue">{JSON.parse(item).name}</Tag>
                    //         })
                    //         return ele
                    //     }
                }, {
                    title: "操作用户",
                    dataIndex: "operator",
                }, {
                    title: "是否最新",
                    align: "center",
                    dataIndex: "latest",
                    render: (a) => {
                        return a ? <Icon type="check-circle" style={{ color: "#87d068" }} /> : <Icon type="close-circle" style={{ color: "#f50" }} />
                    }
                }, {
                    title: "操作时间",
                    align: "center",
                    dataIndex: "insert_time",
                }, {
                    title: "操作",
                    align: "center",
                    dataIndex: "template_info",
                    render: (a, b) => {
                        return <div><Button type="primary" icon="form" onClick={() => {
                            Modal.info({
                                title: "详情",
                                style: { top: 20 },
                                width: "70%",
                                content: <MonacaEdit
                                    theme="vs-dark"
                                    language="shell"
                                    height="700"
                                    value={a}
                                />
                            })
                        }} size="small">查看详情</Button>&nbsp;
                            <Button type="danger" icon="delete" onClick={() => {
                                const _this = this;
                                Modal.confirm({
                                    title: "消息提示",
                                    content: "你确定删除所选项吗?",
                                    onOk() {
                                        _this.deleteTempRes(b.template_name, b.insert_time);
                                    }
                                })
                            }} size="small">删除</Button>
                        </div>
                    }
                }]}
                rowSelection={{

                    selectedRowKeys: selectedRowKeys,
                    onChange: (selectedRowKeys, selectedRows) => {
                        this.setState({ selectedRowKeys, selectedRows });
                        this.props.onChange(selectedRowKeys, selectedRows);
                    },
                    type: "checkbox",
                    selections: [
                        {
                            key: 'cencel',
                            text: '清空选择',
                            onSelect: () => {
                                this.setState({ selectedRowKeys: [], selectedRows: [] });
                                this.props.onChange([], []);
                            },
                        },
                    ],
                }}
                dataSource={Recordes}
                rowKey={(row) => { return row.insert_time }}
                size="small"
                pagination={
                    {
                        current: _current, pageSize: _pageSize, total: _total,
                        onChange: (page, pageSize) => {
                            this.updataTempRecordes(pageSize, page, this.props.tempName);
                        }
                    }
                }
            />
            {/* </Card> */}
        </div>
    }
}

export default connect()(TempRecords)