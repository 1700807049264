import { Table, Input, Descriptions, Popconfirm, Form, Modal, AutoComplete, Icon, Divider, Select } from 'antd';
import React, { memo } from "react"
import "./index.scss"
import Utils from "@/utils/utils"

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    state = {
        editing: false,
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title, type } = this.props;
        const { editing } = this.state;
        if (record.key === "total_list" && dataIndex === "type") {
            // return <di className="text">数量统计栏</di>
            return ""
        }
        if (record.key === "dev_alert" && dataIndex === "type") {
            // return <di className="text">设备告警信息列表</di>
            return ""
        }
        if (record.key === "dev_port_traffic" && dataIndex === "type") {
            // return <di className="text">设备端口流量图</di>
            return ""
        }
        if (record.key === "area_device" && dataIndex === "type") {
            // return <di className="text">{record[dataIndex] + "毫秒"}</di>
            return ""
        }
        return (
            <Form.Item style={{ margin: 0 }} className="editable-form">
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} 不能为空！`,
                        },
                    ],
                    initialValue: record[dataIndex],
                })(
                    dataIndex === "type" ?
                        <Select
                            className="selectClass"
                            ref={node => (this.input = node)}
                            onBlur={this.save}
                        >
                            <Select.Option value="column"><Icon type="bar-chart" />&nbsp;柱状图</Select.Option>
                            <Select.Option value="line"><Icon type="area-chart" />&nbsp;线型图</Select.Option>
                            <Select.Option value="pie"><Icon type="pie-chart" />&nbsp;饼状图</Select.Option>
                            <Select.Option value="column2"><Icon type="pie-chart" />&nbsp;横向柱状图</Select.Option>
                        </Select> :
                        (dataIndex === "timer" ?
                            <Select
                                className="selectClass"
                                ref={node => (this.input = node)}
                                onBlur={this.save}
                            >
                                <Select.Option value={10 * 1000}>10s</Select.Option>
                                <Select.Option value={30 * 1000}>30s</Select.Option>
                                <Select.Option value={60 * 1000}>1min</Select.Option>
                                <Select.Option value={5 * 60 * 1000}>5min</Select.Option>
                                <Select.Option value={10 * 60 * 1000}>10min</Select.Option>
                                <Select.Option value={20 * 60 * 1000}>20min</Select.Option>
                                <Select.Option value={30 * 60 * 1000}>30min</Select.Option>
                                <Select.Option value={60 * 60 * 1000}>1h</Select.Option>
                                <Select.Option value={2 * 60 * 60 * 1000}>2h</Select.Option>
                            </Select>
                            : <Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} className="selectClass" />)


                )}
            </Form.Item>
        )
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                        children
                    )}
            </td>
        );
    }
}

class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            ...this.props.columns || [],
            {
                title: '操作顺序',
                dataIndex: 'operation',
                render: (text, record, index) =>
                    this.state.dataSource.length >= 1 ? (
                        <div>
                            {/* <a onClick={() => this.showInfo(record)}>详情</a>
                            <Divider type="vertical" /> */}
                            {index === 0 ? "" : <a onClick={() => {
                                let newData = this.state.dataSource;
                                let index2 = index - 1
                                let temp = newData[index2]
                                newData[index2] = newData[index]
                                newData[index] = temp
                                this.setState({ dataSource: newData })
                                this.props.onChange(newData)
                            }}>上移</a>}
                            <Divider type="vertical" />
                            {index === this.state.dataSource.length - 1 ? "" : <a onClick={() => {
                                let newData = this.state.dataSource;
                                let index2 = index + 1
                                let temp = newData[index2]
                                newData[index2] = newData[index]
                                newData[index] = temp
                                this.setState({ dataSource: newData })
                                this.props.onChange(newData)
                            }}>下移</a>}
                        </div>
                    ) : null,
            },
        ];
        this.state = {
            dataSource: this.props.value || [],
            count: 2,
        };
    }


    componentWillUpdate(props) {
        if (props.value.length != this.state.dataSource.length) {
            this.setState({
                dataSource: props.value || [],
            })
        }
        console.log(props.value)
    }

    showInfo = (record) => {
        Modal.info({
            title: "详细信息",
            width: 500,
            content: <Descriptions bordered size="small" column={1}>
                {
                    Object.keys(record).map((key) => {
                        if (["password", "sys_pwd", "key"].includes(key)) {
                            return null
                        } else {
                            return <Descriptions.Item key={key} label={Utils.Translation(key)}>{record[key]}</Descriptions.Item>
                        }
                    })
                }
            </Descriptions>
        })
    };
    handleDelete = key => {
        const dataSource = [...this.state.dataSource];
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
    };

    handleAdd = () => {
        const { count, dataSource } = this.state;
        const newData = {
            key: count,
            name: `Edward King ${count}`,
            age: 32,
            address: `London, Park Lane no. ${count}`,
        };
        this.setState({
            dataSource: [...dataSource, newData],
            count: count + 1,
        });
    };

    handleSave = row => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData });
        this.props.onChange(newData);
    };

    render() {
        const { dataSource } = this.state;
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map(col => {
            col.ellipsis = true;
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });
        return (
            <div className="BigScreenEditTabClass">
                {/* <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
                    Add a row
        </Button> */}
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    size="small"
                    dataSource={dataSource}
                    columns={columns}
                    // scroll={{ y: 400 }}
                    pagination={false}
                />
            </div>
        );
    }
}

export default memo(EditableTable)