import React, { Component } from "react";
import { Button, Card, Col, Form, Input, Modal, Row, Table } from "antd";
import confirm from "antd/es/modal/confirm";
import { batchDelete, deleteObj, fetchList, fetchObj, getAll, updateObj } from "../../../zx-api/line-source";
import { exportExcel } from "../../../zx-utils/util";
import moment from "moment";
import { editFormItemLayout, newDisabledStyle } from "../../../zx-base/layout";

/**
 * @author linjiawei1996@qq.com
 * @description 作为专线配置模块中，专线资源信息显示
 * @time 2020/2/7 4:45 下午
 */
class LineSource extends Component {


    state = {
        formList: [],
        tableData: [],//表格数据
        loading: false, //加载数据后遮罩层
        tableDataTotal: 0,
        tableDataCurrent: 1,
        tableDataPageSize: 5,
        selectedRowKeys: [],
        detailVisible: false, // 新建、详情弹框可见性
        updateStatus: 0, //0 新建， 1点击了条目，显示为详情状态但是编辑按钮还没点击，2，编辑状态
        detailLoading: false, //编辑框提交的loading
        editObj: {}, //正在编辑的对象
    }

    componentWillMount() {
        this.update();
    }

    update = (tableDataPageSize, tableDataPageNum, query) => {
        let response = {}
        this.setState({
            loading: true,
            selectedRowKeys: []
        })
        query = query || {}
        query['page'] = tableDataPageNum
        query['size'] = tableDataPageSize
        fetchList(query, res => {
            // response = res.data
            res = res.data
            const tableData = res.results
            const tableDataTotal = res.count
            this.setState({ tableData, tableDataTotal, loading: false, tableDataCurrent: tableDataPageNum })
            // alert(JSON.stringify(tableData))
        })
    }

    // 删除操作
    delete() {
        const { selectedRowKeys } = this.state
        if (selectedRowKeys.length < 2) {
            deleteObj(selectedRowKeys, res => {
                this.update(this.state.tableDataPageSize, 1, {})
            })
        } else {
            batchDelete(selectedRowKeys, res => {
                this.update(this.state.tableDataPageSize, 1, {})
            })
        }
    }


    //导出Excel
    exportLineSource = e => {
        getAll(res => {
            let obj = res.data.data
            delete obj.id
            let json = obj.map(item => {
                return {
                    'CI': item.ci,
                    '专线类型': item.lineType,
                    '线路分类': item.lineClassfity,
                    '专线号': item.lineNum,
                    '专线用途': item.linefunction,
                    '专线月租': item.rentFee,
                    '专线开通时间': item.startTime,
                    'B端机构类型': item.ToBType,
                    'A端机构': item.aOrganisation,
                    'a端接入设备ID': item.aEquireID,
                    'A端接入端口类型': item.aPortType,
                    'A端接入端口ID': item.aPortID,
                    'A端接入端口号': item.aToPort,
                    'B端机构': item.bOrganisation,
                    'B端接入设备ID': item.bEquireID,
                    'B端接入设备名称': item.bEquireName,
                    'B端接入端口ID': item.bPortID,
                    'B端接入端口类型': item.bPortType,
                    'B端接入端口号': item.bToPort,
                    '专线属性': item.lineProperty,
                    '带宽': item.bandwidth,
                    '对端公网地址': item.opptNetAddr,
                    '对端隧道监控地址': item.opptMonitorAddr,
                    '专线产权单位': item.lingQuityDepart,
                    '运营商': item.aperator,
                    '科目类型': item.subjectType,
                    '备份线路': item.backupsLine,
                    'CI负责机构': item.ciResponForOrgan,
                    'CI负责部门': item.ciResponForDepart,
                    '创建时间': item.createTime,
                    '更新时间': item.updateTime,
                    '被谁更新': item.updateByPerson,
                    '主要字眼': item.maintainSource,
                    '维护来源': item.configurationStatus,
                    '备注': item.remark,
                }
            })
            //导出Excel
            exportExcel(json, '专线资源表-' + moment().format("YYYYMMDDHHmmss") + '.xlsx')
        })

    }

    // 点击新增按钮
    addObj = e => {
        this.setState({
            updateStatus: 0,
            detailVisible: true,
        })
        this.props.form.resetFields()
    }

    // 点击条目
    tableDataDetail(id) {
        this.setState({
            updateStatus: 1,
            detailVisible: true,
            editObj: {},
        })
        fetchObj(id, res => {
            const editObj = res.data.data
            this.setState({ editObj })
            this.props.form.setFieldsValue({ ...editObj })
        })
    }

    //点击编辑按钮
    handleEdit = e => {
        this.setState({ updateStatus: 2 })
    }

    handleOk = () => {
        this.setState({ detailLoading: true, });
        this.props.form.validateFieldsAndScroll((err, value) => {
            let { editObj } = this.state
            editObj = { ...editObj, ...value } // 用value的值去覆盖
            // alert(JSON.stringify(editObj))
            updateObj(editObj, res => {
                this.setState({ detailLoading: false, updateStatus: 0, });

            })

        })
    }

    //取消按钮
    handleCancel = () => {
        this.setState({ detailVisible: false, updateStatus: 0, detailLoading: false });
    };

    render() {

        const {
            tableDataPageSize, tableData, selectedRowKeys, loading, tableDataCurrent, tableDataTotal, query, detailVisible,
            updateStatus, detailLoading
        } = this.state
        const { getFieldDecorator } = this.props.form;


        const tableDataColumns = [
            {
                title: 'CI',
                dataIndex: 'ci',
                key: 'ci',
                width: 210,
                render: (text, item, index) => {
                    return (
                        // <div >
                        // {
                        <div style={{ color: "blue", cursor: "pointer", width: "300px", }}
                            onClick={this.tableDataDetail.bind(this, item.id)}>{text}</div>
                        // }
                        // </div>
                    )
                },
                // fixed: 'left'
            },
            {
                title: '线路类型',
                dataIndex: 'lineType',
                key: 'lineType',
                width: 210,
            },
            {
                title: '专线类别',
                dataIndex: 'lineClassfity',
                key: 'lineClassfity',
                width: 210
            },
            {
                title: '专线号',
                dataIndex: 'lineNum',
                key: 'lineNum',
                width: 210
            },
            {
                title: '专线用途',
                dataIndex: 'linefunction',
                key: 'linefunction',
                width: 210
            },
            {
                title: 'aEquireID',
                dataIndex: 'aEquireID',
                key: 'aEquireID',
                width: 210
            },
            {
                title: 'aPortType',
                dataIndex: 'aPortType',
                key: 'aPortType',
                width: 210
            },
            {
                title: 'aPortID',
                dataIndex: 'aPortID',
                key: 'aPortID',
                width: 210
            },
            {
                title: 'aToPort',
                dataIndex: 'aToPort',
                key: 'aToPort',
                width: 210
            },
            {
                title: 'bOrganisation',
                dataIndex: 'bOrganisation',
                key: 'bOrganisation',
                width: 360
            },
            {
                title: 'bEquireID',
                dataIndex: 'bEquireID',
                key: 'bEquireID',
                width: 210
            },
            {
                title: 'bEquireName',
                dataIndex: 'bEquireName',
                key: 'bEquireName',
                width: 210
            },
            {
                title: 'bPortID',
                dataIndex: 'bPortID',
                key: 'bPortID',
                width: 210
            },
            {
                title: 'bPortType',
                dataIndex: 'bPortType',
                key: 'bPortType',
                width: 210
            },
            {
                title: 'bToPort',
                dataIndex: 'bToPort',
                key: 'bToPort',
                width: 250
            },
            {
                title: 'bandwidth',
                dataIndex: 'bandwidth',
                key: 'bandwidth',
                width: 210
            },
        ]

        //控制表格勾选
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                this.setState({ selectedRowKeys });
            }
        }

        return (
            <div>
                <Card title='专线资源信息'
                    extra={
                        <div>
                            <Button type='default' icon='redo' onClick={e => {
                                this.setState({ query: {} })
                                this.update(tableDataPageSize, 1, {})
                            }}>刷新</Button>
                            <Button type='default' style={{ color: "#fff", backgroundColor: "green" }}
                                onClick={this.addObj}>新建</Button>
                            <Button type='danger' icon='' onClick={
                                e => {
                                    const _self = this
                                    if (this.state.selectedRowKeys.length > 0) {
                                        confirm({
                                            title: '您确认删除吗?',
                                            content: `您确认要删除这${this.state.selectedRowKeys.length}项吗？`,
                                            okText: '删除',
                                            okType: 'danger',
                                            cancelText: '取消',
                                            onOk() {
                                                _self.delete()
                                            },
                                            onCancel() {

                                            },
                                        });
                                    } else {
                                        alert("请选择要删除的对象！")
                                    }
                                }
                            } style={{}}>
                                删除
                              </Button>
                            <Button type='primary'
                                icon='export'
                                onClick={this.exportLineSource}
                                style={{ marginLeft: 8 }}>导出Excel</Button>

                        </div>
                    }
                >
                    <Table
                        dataSource={tableData}
                        columns={tableDataColumns}
                        rowSelection={rowSelection}
                        rowKey={value => value.id}
                        loading={loading}
                        size="small"
                        scroll={{ x: 3000, }}
                        pagination={{
                            current: tableDataCurrent, pageSize: tableDataPageSize, total: tableDataTotal,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                console.log(page, pageSize);
                                this.update(pageSize, page, query)
                            }
                        }}
                    />
                </Card>

                <Modal
                    visible={detailVisible}
                    title={`${updateStatus === 0 ? '新建' : '详情'}`}
                    width='1680px'
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            取消
                        </Button>,
                        <Button style={{
                            backgroundColor: "green",
                            color: 'white',
                            visibility: `${updateStatus === 1 ? 'visible' : 'collapse'}`
                        }} onClick={this.handleEdit}>
                            编辑
                        </Button>,
                        <Button key="submit" type="primary" loading={detailLoading}
                            style={{ visibility: `${updateStatus === 0 || updateStatus === 2 ? 'visible' : 'collapse'}` }}
                            onClick={this.handleOk}>
                            提交
                        </Button>,
                    ]}
                >
                    <Form onSubmit={this.handSubmit} {...editFormItemLayout}>
                        <Row>
                            <Col span={6}><Form.Item label='CI'>
                                {
                                    getFieldDecorator('ci',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='CI' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='专线类型'>
                                {
                                    getFieldDecorator('lineType',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='专线类型' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='线路分类'>
                                {
                                    getFieldDecorator('lineClassfity',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='线路分类' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='专线号'>
                                {
                                    getFieldDecorator('lineNum',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='专线号' />)
                                }
                            </Form.Item></Col>
                        </Row>
                        <Row>
                            <Col span={6}><Form.Item label='专线用途'>
                                {
                                    getFieldDecorator('linefunction',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='专线用途' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='专线月租'>
                                {
                                    getFieldDecorator('rentFee',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='专线月租' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='专线开通时间'>
                                {
                                    getFieldDecorator('startTime',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='专线开通时间' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='B端机构类型'>
                                {
                                    getFieldDecorator('ToBType',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='B端机构类型' />)
                                }
                            </Form.Item></Col>
                        </Row>
                        <Row>
                            <Col span={6}><Form.Item label='A端机构'>
                                {
                                    getFieldDecorator('aOrganisation',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='A端机构' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='a端接入设备ID'>
                                {
                                    getFieldDecorator('aEquireID',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='a端接入设备ID' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='A端接入端口类型'>
                                {
                                    getFieldDecorator('aPortType',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='A端接入端口类型' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='A端接入端口ID'>
                                {
                                    getFieldDecorator('aPortID',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='A端接入端口ID' />)
                                }
                            </Form.Item></Col>
                        </Row>
                        <Row>
                            <Col span={6}><Form.Item label='A端接入端口号'>
                                {
                                    getFieldDecorator('aToPort',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='A端接入端口号' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='B端机构'>
                                {
                                    getFieldDecorator('bOrganisation',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='B端机构' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='B端接入设备ID'>
                                {
                                    getFieldDecorator('bEquireID',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='B端接入设备ID' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='B端接入设备名称'>
                                {
                                    getFieldDecorator('bEquireName',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='B端接入设备名称' />)
                                }
                            </Form.Item></Col>
                        </Row>
                        <Row>
                            <Col span={6}><Form.Item label='B端接入端口ID'>
                                {
                                    getFieldDecorator('bPortID',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='B端接入端口ID' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='B端接入端口类型'>
                                {
                                    getFieldDecorator('bPortType',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='B端接入端口类型' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='B端接入端口号'>
                                {
                                    getFieldDecorator('bToPort',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='B端接入端口号' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='专线属性'>
                                {
                                    getFieldDecorator('lineProperty',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='专线属性' />)
                                }
                            </Form.Item></Col>
                        </Row>
                        <Row>
                            <Col span={6}><Form.Item label='带宽'>
                                {
                                    getFieldDecorator('bandwidth',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='带宽' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='对端公网地址'>
                                {
                                    getFieldDecorator('opptNetAddr',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='对端公网地址' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='对端隧道监控地址'>
                                {
                                    getFieldDecorator('opptMonitorAddr',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='对端隧道监控地址' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='专线产权单位'>
                                {
                                    getFieldDecorator('lingQuityDepart',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='专线产权单位' />)
                                }
                            </Form.Item></Col>
                        </Row>
                        <Row>
                            <Col span={6}><Form.Item label='运营商'>
                                {
                                    getFieldDecorator('aperator',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='运营商' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='科目类型'>
                                {
                                    getFieldDecorator('subjectType',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='科目类型' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='备份线路'>
                                {
                                    getFieldDecorator('backupsLine',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='备份线路' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='CI负责机构'>
                                {
                                    getFieldDecorator('ciResponForOrgan',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='CI负责机构' />)
                                }
                            </Form.Item></Col>
                        </Row>
                        <Row>
                            <Col span={6}><Form.Item label='CI负责部门'>
                                {
                                    getFieldDecorator('ciResponForDepart',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='CI负责部门' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='创建时间'>
                                {
                                    getFieldDecorator('createTime',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='创建时间' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='更新时间'>
                                {
                                    getFieldDecorator('updateTime',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='更新时间' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='被谁更新'>
                                {
                                    getFieldDecorator('updateByPerson',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='被谁更新' />)
                                }
                            </Form.Item></Col>
                        </Row>
                        <Row>
                            <Col span={6}><Form.Item label='主要字眼'>
                                {
                                    getFieldDecorator('maintainSource',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='主要字眼' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='维护来源'>
                                {
                                    getFieldDecorator('configurationStatus',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='维护来源' />)
                                }
                            </Form.Item></Col>
                            <Col span={6}><Form.Item label='备注'>
                                {
                                    getFieldDecorator('remark',
                                        { rules: [{}] }
                                    )(
                                        <Input style={updateStatus !== 0 ? newDisabledStyle : {}}
                                            disabled={updateStatus === 2 || updateStatus === 0 ? false : true}
                                            placeholder='备注' />)
                                }
                            </Form.Item></Col>

                        </Row>
                    </Form>
                </Modal>
            </div>)
    }
}

const LineSourceForm = Form.create()(LineSource)
export default LineSourceForm