import React, { useState, useEffect } from 'react'
import {
    SubmitButton,
    ResetButton,
    Form,
    FormItem,
} from "formik-antd"
const FormikInput = require("formik-antd").Input;
import { Formik } from "formik"
import { connect } from 'react-redux'
function SearchCard(props) {
    const [initialValues,] = useState({
        name: "",
        creator: "",
    });
    useEffect(() => {//componentDidMount 
        return () => { //componentWillUnmount
        }
    }, []);
    const formItemData = [
        ["name", "策略名称",],
        ["creator", "创建人",],

    ]
    const formItemRender = function (data) {
        return data.map((item, key) => {
            return <FormItem
                key={key}
                name={item[0]}
                label={item[1]}
                required={false}
            >
                <FormikInput name={item[0]} placeholder={item[1]} />
            </FormItem>
        })
    }
    return <div className="SearchCard">
        <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                console.log(values)
                props.onSubmit(values);
                actions.setSubmitting(false)
            }}
        >
            {(props2) => {
                return <Form
                    layout="inline"
                >
                    {formItemRender(formItemData)}
                    <SubmitButton disabled={false} icon="search">查询</SubmitButton>&nbsp;
                    <ResetButton disabled={false} icon="reload" onClick={() => { props.reSet(); }}>重置</ResetButton>
                </Form>

            }}
        </Formik>
    </div>
}
export default connect()(React.memo(SearchCard))
