import React from 'react';
import { Select, Tag, Tooltip, Button, Popover, Icon, Badge } from 'antd';
const versionName = sys_config.versionName;
import Utils from "@/utils/utils";

export default {
    // 列表表头
    getDumbTerminalColumns(_pageSize, _current, is_delete) {
        let bindIndex;
        const columns = [
            is_delete === 'delete' ? {
                title: '终端名称',
                dataIndex: 'name',
                width: 130,
                ellipsis: true,
                align: "left",
            } : {
                title: '终端名称',
                dataIndex: 'name',
                width: 130,
                ellipsis: true,
                align: "left",
                render: (text, item) => {
                    let sta = "default";
                    let title = "状态未知";
                    if (item.is_online === "是") {
                        sta = "success"; title = "在线";
                    } else if (item.is_online === "否") {
                        sta = "error"; title = <div><p>{"离线" + item.offline_days + "天"}</p><p>{"离线时间：" + item.offline_time}</p></div>;
                    }
                    return <div><Tooltip title={title}><Badge status={sta} /></Tooltip>{text}</div>
                }
            },
            {
                title: '哑终端类型',
                dataIndex: 'type',
                width: 130,
                ellipsis: true,
            },
            {
                title: '哑终端型号',
                dataIndex: 'model',
                width: 170,
                ellipsis: true,
            },
            {
                title: '哑终端厂商',
                dataIndex: 'vendor',
                width: 130,
                ellipsis: true,
            },
            {
                title: 'MAC地址',
                dataIndex: 'mac_address',
                width: 150,
                ellipsis: true,
            },
            {
                title: '哑终端IP地址',
                dataIndex: 'ip',
                width: 150,
                ellipsis: true,
            },
            {
                title: '离线告警',
                dataIndex: 'is_monitor',
                width: 100,
                ellipsis: true,
                render: (text, item, index) => {
                    if(text) {
                        switch(text) {
                            case "false": return ( <Tag color="red"><Icon type="close-circle" />&nbsp;关闭</Tag> )
                            case "true": return ( <Tag color="green"><Icon type="check-circle" />&nbsp;开启</Tag> )
                        }
                    }
                }
            },
            {
                title: '接入交换机名称',
                dataIndex: 'switch_name',
                width: 170,
                ellipsis: true,
            },
            {
                title: '接入交换机IP',
                dataIndex: 'switch_ip',
                width: 170,
                ellipsis: true,
                render: (text, item, index) => {
                    if(item.switch_simple_info && JSON.stringify(item.switch_simple_info) !== '{}') {
                        return (
                            <Popover title="网络设备信息" content={<div style={{ margin: '-8px -5px' }}>
                                <div style={{ margin: '8px 5px' }}>设备名称：{item.switch_simple_info.device_name}</div>
                                <div style={{ margin: '8px 5px' }}>设备IP：{item.switch_simple_info.device_ip}</div>
                                <div style={{ margin: '8px 5px', display: 'flex' }}>设备厂商：{Utils.Translation(item.switch_simple_info.device_firm)}</div>
                                <div style={{ margin: '8px 5px' }}>设备状态：{Utils.Translation(item.switch_simple_info.device_state)}</div>
                                <div style={{ margin: '8px 5px' }}>网点信息：{item.switch_simple_info.device_site}</div>
                                <div style={{ margin: '8px 5px', display: 'flex' }}>设备类型：{Utils.Translation(item.switch_simple_info.device_type)}</div>
                                <div style={{ margin: '8px 5px' }}>设备型号：{item.switch_simple_info.device_model}</div>
                            </div>}>
                                <span>{text}</span>
                            </Popover>
                        )
                    } else {
                        return text
                    }
                }
            },
            {
                title: '接入端口',
                dataIndex: 'switch_port',
                width: 130,
                ellipsis: true,
            },
            {
                title: '是否实施了mac准入',
                dataIndex: 'is_allow_mac_in',
                width: 150,
                ellipsis: true,
                render: (text, item, index) => {
                    if(!text) {
                        return <span>否</span>
                    } else {
                        return <span>{text}</span>
                    }
                }
            },
            {
                title: '组织区域',
                dataIndex: 'dep_path',
                width: 160,
                ellipsis: true,
            },
            {
                title: '所属分行',
                dataIndex: 'branch',
                width: 100,
                ellipsis: true,
            },
            {
                title: '哑终端物理地址',
                dataIndex: 'physical_address',
                width: 150,
                ellipsis: true,
            },
            {
                title: '哑终端逻辑位置',
                dataIndex: 'logical_address',
                width: 120,
                ellipsis: true,
            },
            {
                title: '所属业务部门',
                dataIndex: 'department',
                width: 150,
                ellipsis: true,
            },
            {
                title: '哑终端业务负责人',
                dataIndex: 'business_user',
                width: 150,
                ellipsis: true,
            },
            {
                title: '哑终端IT负责人',
                dataIndex: 'it_user',
                width: 150,
                ellipsis: true,
            },
            {
                title: '哑终端状态',
                dataIndex: 'status',
                width: 100,
                ellipsis: true,
            },
            {
                title: '是否在线',
                dataIndex: 'is_online',
                width: 70,
                ellipsis: true,
            },
            {
                title: '离线天数',
                dataIndex: 'offline_days',
                width: 70,
                ellipsis: true,
            },
            {
                title: '描述',
                dataIndex: 'description',
                width: 220,
                ellipsis: true,
            },
            // 最近删除列显示删除时间，哑终端列表显示新增时间
            is_delete === 'delete' ? {
                title: '删除时间',
                dataIndex: 'delete_time',
                width: 150,
                ellipsis: true,
            } : {
                title: '新增时间',
                dataIndex: 'create_time',
                width: 150,
                ellipsis: true,
            },
            {
                title: '离线时间',
                dataIndex: 'offline_time',
                width: 150,
                ellipsis: true,
            },
            {
                title: '接入检查结果',
                dataIndex: 'insert_status',
                width: 100,
                ellipsis: true,
                render: (text, item, index) => {
                    if(text) {
                        switch(text.result) {
                            case false: return (
                                <Tooltip placement="top" title={text.msg}>
                                    <Tag color="#f50"><Icon type="info-circle" />&nbsp;异常</Tag>
                                </Tooltip>
                            )
                            case true: return ( <Tag color="#87d068"><Icon type="check-circle" />&nbsp;正常</Tag> )
                        }
                    }
                }
            },
            {
                title: '绑定状态',
                dataIndex: 'bind_detail',
                width: 120,
                ellipsis: true,
                render: (text, item, index) => {
                    if(text) {
                        switch(text.bind_status) {
                            case "no_check": return (
                                <Tooltip placement="top" title={text.detail}>
                                    <Tag color="volcano"><Icon type="exclamation-circle" />&nbsp;无法检测</Tag>
                                </Tooltip>
                            )
                            case "no_bind": return (
                                <Tag color="red"> 未绑定 </Tag>
                            )
                            case "bound": return (
                                <Tag color="green"> 已绑定 </Tag>
                            )
                            case "abnormal": return (
                                <Tooltip placement="top" title={text.detail}>
                                    <Tag color="orange"><Icon type="exclamation-circle" />&nbsp;异常</Tag>
                                </Tooltip>
                            )
                            case "running": return (
                                <Tag color="blue"><Icon type="loading" /> 执行中 </Tag>
                            )
                            case "updating": return (
                                <Tag color="purple"><Icon type="loading" /> 更新中 </Tag>
                            )
                        }
                    }
                }
            },
            {
                title: '开放端口检查',
                dataIndex: 'port_check',
                width: 100,
                ellipsis: true,
                render: (text, item, index) => {
                    if(text) {
                        if(item.port_check_status) {
                            console.log(item.port_check_status)
                            return ( <Tag color="#2db7f5"><Icon type="loading" />&nbsp;检查中</Tag> )
                        } else {
                            switch(text.status) {
                                case "abnormal": return (
                                    <Tooltip placement="top" title={text.msg}>
                                        <Tag color="#f50"><Icon type="info-circle" />&nbsp;异常</Tag>
                                    </Tooltip>
                                )
                                case "normal": return ( <Tag color="#87d068"><Icon type="check-circle" />&nbsp;正常</Tag> )
                                case "checking": return ( <Tag color="#2db7f5"><Icon type="loading" />&nbsp;检查中</Tag> )
                            }
                        }
                    }
                }
            },
        ];
        if(versionName === 'pinganNanJing') {
            columns.map((i, k) => {
                if(i.dataIndex === 'bind_detail') {
                    bindIndex = k;
                }
            })
            columns.splice(bindIndex,1)
        }
        return columns;
    },
    // 区域划分表格表头
    getRegionColumns() {
        const columns = [
            {
                title: '终端名称',
                dataIndex: 'name',
                width: 130,
                ellipsis: true,
                align: "left",
                render: (text, item) => {
                    let sta = "default";
                    let title = "状态未知";
                    if (item.is_online === "是") {
                        sta = "success"; title = "在线";
                    } else if (item.is_online === "否") {
                        sta = "error"; title = <div><p>{"离线" + item.offline_days + "天"}</p><p>{"离线时间：" + item.offline_time}</p></div>;
                    }
                    return <div><Tooltip title={title}><Badge status={sta} /></Tooltip>{text}</div>
                }
            },
            {
                title: '哑终端类型',
                dataIndex: 'type',
                width: 130,
                ellipsis: true,
            },
            {
                title: 'MAC地址',
                dataIndex: 'mac_address',
                width: 150,
                ellipsis: true,
            },
            {
                title: '哑终端IP地址',
                dataIndex: 'ip',
                width: 150,
                ellipsis: true,
            },
        ];
        return columns;
    },
    // 替换表头
    replaceSheetHeader(name) {
        let headerName = ''
        switch(name) {
            case '终端名称': headerName = 'name'; break;
            case '哑终端类型': headerName = 'type'; break;
            case '哑终端型号': headerName = 'model'; break;
            case '哑终端厂商': headerName = 'vendor'; break;
            case 'MAC地址': headerName = 'mac_address'; break;
            case 'IP地址': headerName = 'ip'; break;
            case '接入交换机名称': headerName = 'switch_name'; break;
            case '接入交换机IP': headerName = 'switch_ip'; break;
            case '接入端口': headerName = 'switch_port'; break;
            case '是否实施了mac准入': headerName = 'is_allow_mac_in'; break;
            case '组织区域': headerName = 'dep_path'; break;
            case '所属分行': headerName = 'branch'; break;
            case '哑终端物理地址': headerName = 'physical_address'; break;
            case '哑终端逻辑位置': headerName = 'logical_address'; break;
            case '所属业务部门': headerName = 'department'; break;
            case '哑终端业务负责人': headerName = 'business_user'; break;
            case '哑终端IT负责人': headerName = 'it_user'; break;
            case '哑终端状态': headerName = 'status'; break;
            case '描述': headerName = 'description'; break;
            case '是否哑终端': headerName = 'is_dumb_tmn'; break;
        }
        return headerName;
    },
    // 下载导入模版
    downloadTemplate() {
        let list = [
            {
                "终端名称": "零售实物发放机：CTM",
                "哑终端类型": "CTM实物发放机",
                "哑终端型号": "零售实物发放机：CTM",
                "哑终端厂商": "恒银",
                "MAC地址": "0018-7dbb-bc69",
                "IP地址": "10.72.96.241",
                "接入交换机名称": "BJ-PT-SW-SanYuanQiao-01",
                "接入交换机IP": "10.72.96.241",
                "接入端口": "E1/0/28",
                "是否实施了mac准入": "是",
                "组织区域": "广州分行-中心机房",
                "所属分行": "北京分行",
                "哑终端物理地址": "三元桥支行",
                "哑终端逻辑位置": "生产",
                "所属业务部门": "运营管理部",
                "哑终端业务负责人": "lixuehao",
                "哑终端IT负责人": "lixuehao",
                "哑终端状态": "在用",
                "描述": "描述信息",
            }
        ]
        Utils.exportExcel(list, "导入哑终端模板.xlsx", '哑终端列表');
    }
}