import React from 'react'
import {
    Table, Button, Icon, Input, Row, Col, Form,
    Modal, Alert
} from "antd";
import { connect } from 'react-redux'
import SelectDevice from './checkDev';
import Utils from '@/utils/utils';
import ResUtils from '@/utils/ResUtils';
import MonacaEditor from "react-monaco-editor"
import "./index.scss"
class CommForm extends React.Component {
    state = {
        deviceIp: null,
        selectedRowKeys: [],
        selectedRows: [],
        visibleDev: false,
        querys: { "resourceParams.deviceFirm": "CISCO" },
        screenSize: Utils.getScreenSize(),
    }
    componentWillMount() {
        // console.log(this.props)
    }

    componentDidMount() {
    }
    handleSubmit = e => {
        e.preventDefault();
        let _this = this;
        const { selectedRows } = this.state;
        if (selectedRows.length === 0) {
            Modal.info({
                title: "消息提示",
                content: "请选择下发设备！",
                okText: "确定",
            })
            return
        }
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.ips = selectedRows.map((row) => { return row.resourceParams.deviceIp });
                console.log('Received values of form: ', values);
                Modal.confirm({
                    title: "消息提示",
                    content: "你确定下发指令到所选设备吗?",
                    okText: "确定",
                    okType: "danger",
                    onOk() {
                        _this.props.onSubmit(values);
                    }
                })
                // _this.props.form.resetFields();
            }
        });
    };
    reset = () => {
        this.props.form.resetFields();
        this.setState({ deviceIp: null, selectedRowKeys: [], selectedRows: [] })
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { selectNode, loading } = this.props;
        const { selectedRowKeys, querys, visibleDev, selectedRows, screenSize } = this.state;
        return <div className="formTabpane">
            <Alert
                message={<div><Icon type="info-circle" theme="twoTone" />&nbsp;提示信息</div>}
                description="比对模式下发，通过查询命令在设备上查询信息，将查询的信息与基线配置进行比对，比对成功后才进行指令的下发。"
                type="info"
                className="toolInfo" />
            <Form
                labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}
                onSubmit={this.handleSubmit}>
                <Form.Item label={"下发名称"} >
                    {getFieldDecorator("config_delivery_name", {
                        initialValue: selectNode.config_delivery_name || "",
                        rules: [{
                            required: true, message: '请填写下发名称!',
                        }],
                    })(<Input placeholder="本次下发任务名称" />)}
                </Form.Item>
                <Form.Item label={"下发描述"} >
                    {getFieldDecorator("details", {
                        initialValue: selectNode.details || "",
                    })(<Input.TextArea placeholder="本次下发任务描述" />)}
                </Form.Item>
                <Form.Item label={"查询命令"} >
                    {getFieldDecorator("query_cmd", {
                        initialValue: selectNode.query_cmd || "",
                        rules: [{
                            required: true, message: '查询命令!',
                        }],
                    })(<Input placeholder="查询命令，如dis acl all" />)}
                </Form.Item>
                <Form.Item label={"基线配置"} >
                    {getFieldDecorator("baseline", {
                        initialValue: selectNode.baseLine || "",
                        rules: [{
                            required: true, message: '请填写基线配置!',
                        }],
                    })(
                        <MonacaEditor
                            theme="vs-dark"
                            language="shell"
                            // options={{ minimap: { enabled: false }, lineNumbers: "off" }} 
                            options={{ scrollBeyondLastColumn: 1, scrollBeyondLastLine: false, lineNumbers: "off" }}
                            height={screenSize.height * 0.3}
                        />
                    )}
                </Form.Item>
                <Form.Item label={"下发指令"} >
                    {getFieldDecorator("config_cmd", {
                        initialValue: selectNode.config_cmd || "",
                        rules: [{
                            required: true, message: '请填写下发指令!',
                        }],
                    })(
                        <MonacaEditor
                            theme="vs-dark"
                            language="shell"
                            // options={{ minimap: { enabled: false }, lineNumbers: "off" }} 
                            options={{ scrollBeyondLastColumn: 1, scrollBeyondLastLine: false, lineNumbers: "off" }}
                            height={screenSize.height * 0.3}
                        />
                    )}
                </Form.Item>
                <Row>
                    <Col span={24} className="tempsty" >
                        <div className="ant-form-item-label">
                            <label className="ant-form-item-required" >下发设备</label>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="deviceDiv">
                            <Table
                                columns={[...ResUtils.getColumns(10000, 1), {
                                    title: "操作",
                                    render: (a, item) => {
                                        return <Button type="danger" icon="delete" size="small" onClick={() => {
                                            const newData = selectedRows.filter((row) => { return row._id != item._id });
                                            const newData2 = selectedRowKeys.filter((row) => {
                                                return row != JSON.stringify({ resourceName: item.resourceName })
                                            });
                                            this.setState({ selectedRows: newData, selectedRowKeys: newData2 })
                                        }} />
                                    }
                                }]}
                                dataSource={selectedRows}
                                pagination={false}
                                locale={{ emptyText: <div><Icon type="inbox" />暂无数据</div> }}
                                size="small"
                                rowKey={(row) => row._id}
                                scroll={{ y: screenSize.height * 0.2 }}
                            />
                        </div>
                        <Button style={{ width: "100%", marginTop: 5 }} icon="plus-circle" type="dashed"
                            onClick={() => {
                                this.setState({ visibleDev: true })
                            }}>添加设备</Button>
                    </Col>
                </Row><br />
                <Form.Item label=" " colon={false}>
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Button style={{ width: 150 }} type="primary" htmlType="submit" loading={loading} icon="thunderbolt">
                            {loading ? "发送中" : "开始下发"}
                        </Button>&nbsp;
                    {/* <Button type="primary" icon="save">存为模板</Button>&nbsp; */}
                        <Button style={{ width: 150 }} icon="reload" onClick={() => this.reset()} >清空表单</Button>
                    </div>
                </Form.Item>
            </Form>
            <Modal
                title="选择设备"
                visible={visibleDev}
                width={"99%"}
                style={{ top: 10 }}
                onCancel={() => { this.setState({ visibleDev: false, }); }}
                onOk={() => { this.setState({ visibleDev: false, }); }}
            //不能添加destroyOnClose，会导致选择错误
            >
                <SelectDevice
                    onChange={(selectedRowKeys, selectedRows) => {
                        this.setState({ selectedRowKeys, selectedRows, })
                    }}
                    querys={querys}
                    selectedRowKeys={selectedRowKeys}
                />
            </Modal>
        </div>
    }

}
const CommObj = Form.create()(CommForm)
export default connect()(CommObj)