import React from 'react';
import Exception from '@/components/Exception';

const Exception401 = () => {
  console.log(55)
  return <Exception
    type="401"
    desc={"抱歉，您的权限不足!"}
    linkElement={null}
    linkTo={"/home"}
    backText={"返回首页"}
  />
}

export default Exception401;
