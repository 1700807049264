import React, { useEffect, useState } from 'react'

// import { ScrollRankingBoard } from '@jiaminghi/data-view-react'
import Charts from "echarts-for-react"
import './Histogram.scss'
import socket from '@/socket/index'
import Utils from "@/utils/utils";
import * as echarts from 'echarts';

function randomExtend(minNum, maxNum) {
  if (arguments.length === 1) {
    return parseInt(Math.random() * minNum + 1, 10)
  } else {
    return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
  }
}

export default () => {
  const [config, setData] = useState({});

  function getData() {
    return {
      title: {
        text: "柱状图",
        textStyle: {
          color: "#e2e2e2",
          fontSize: 22
        },
        x: 'center',
        top: 5
      },
      // color: ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622',],
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        },
      },
      textStyle: {
        color: "#e2e2e2",
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '4%',
        top: "13%",
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        axisTick: {
          alignWithLabel: true
        },
        axisLine: {
          lineStyle: {
            color:  '#e2e2e2' , //坐标线的颜色
          }
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color:  '#e2e2e2' , //坐标线的颜色
          }
        },
      },
      series: [
        {
          name: '直接访问',
          type: 'bar',
          barWidth: '60%',
          itemStyle: {
            normal: {
              // color: function(params) {
              //   let colors = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622'];
              //   return colors[params.dataIndex];
              // }
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  // {offset: 0, color: '#c23531'},d44540
                  {offset: 0, color: '#c65a57'},
                  {offset: 1, color: '#c23531'},
                  {offset: 0.5, color: '#c23531'},
                ]
              )
            }
          },
          data: [randomExtend(10, 400), randomExtend(10, 400), randomExtend(10, 400), randomExtend(10, 400), randomExtend(10, 400), randomExtend(10, 400), randomExtend(10, 400)]
        }
      ]
    }
  }

  useEffect(() => {
    createData();

    let timer = setInterval(createData, 3000);
    // let timer = setInterval(createData, 15000);
    return () => clearInterval(timer)
  }, [])

  function createData() {
    setData(getData())
    // getTaskNumber((taskData) => {
    //   setData(getData(taskData))
    //   // console.log("获取任务记录:", taskData)
    // })
  }


  // 获取任务列表
  // function getTaskNumber(callback) {
  //   let bodyJson = {};
  //   bodyJson.pageSize = 1000;
  //   bodyJson.pageNum = 1;
  //   bodyJson.querys = { ifTemplate: false };
  //   let taskArr = [];
  //   socket.ExecuteAction("QueryTasksPage",
  //     bodyJson, "/queryResource", (result) => {
  //       let taskList = result.tasks;
  //       taskList.map((item, index) => {
  //         let statusTotal = {
  //           name: item.taskName,
  //           // value: 0 
  //         }
  //         if (item.taskStatus === "done") {
  //           statusTotal.value = 100;
  //         } else {
  //           statusTotal.value = 0;
  //         }
  //         taskArr.push(statusTotal);
  //       });
  //       onNotice(taskArr);
  //       // if(taskArr.length <= 9) {
  //       //   callback(taskArr);
  //       // } else {
  //       //   callback(taskArr.splice(0,9));
  //       // }
  //       callback(taskArr);
  //     })
  // }

  return (
    <div className="histogramBoard">
      {/* <div className="ranking-board-title">柱状图</div> */}
      <Charts className="histogram-board" option={config} />
      {/* <ScrollRankingBoard config={config} /> */}
    </div>
  )
}
