import React, { Fragment } from 'react';
import { Row, Col, Button, Dropdown, Icon, Tooltip, Divider, Modal, Input, Layout, Menu, Radio, Drawer, message } from 'antd';
import G6, { Minimap, Grid } from "@antv/g6"
import DetailForm from "./detailFrom/index"
import Utils from '@/utils/utils';
import RightMenu from "./rightMenu/index"
import { openDetail } from "./utils/index"
import SearchInput from "./search/index"
import "./index.scss"
//"@antv/g6": "^3.8.3",
//"@antv/g6": "^3.4.9",
//@antv/g6:  3.5.10
export default class FlowPage extends React.Component {
  state = {
    graph: null,
    selectNode: null,
    MenuElemt: null,
    foldState: true,
    initMenu: [
      {
        title: '选择指针',
        key: 'defaul',
        icon: 'select',
      },
      {
        title: '编辑参数',
        key: 'edit',
        icon: 'edit',
      },
      {
        title: '添加连线',
        key: 'addEdge',
        icon: 'swap-right',
      }
    ],
    MenuData: [],
    toData: {},
    flow_id: this.props.id || "flow",
  }
  componentWillMount() {
    this.registerBehavior();
    this.registerCombo();
    this.registerLabelEdge();
  }
  componentDidMount() {
    this.renderMap();
  }
  registerBehavior() {
    const _this = this;
    let addedCount = 0;
    G6.registerBehavior('click-add-edge', {
      getEvents() {
        return {
          'node:click': 'onClick',
          mousemove: 'onMousemove',
          'edge:click': 'onEdgeClick' // 点击空白处，取消边
        };
      },
      onClick(ev) {
        const { tempSelectNode, graph, selectNode, fullscreen } = _this.state;
        const node = ev.item;
        const point = {
          x: ev.x,
          y: ev.y
        };
        const model = node.getModel();
        // console.log(this.addingEdge, this.edge);
        if (this.addingEdge && this.edge) {
          // graph.updateItem(this.edge, {
          //   target: model.id
          // });

          //端口连线
          // console.log(tempSelectNode);

          if (tempSelectNode) {//端口连线
            const nodeModel = node.getModel();
            const thisEdge = this.edge;
            const RadioData = nodeModel.port_list ? nodeModel.port_list.map((n, pkey) => { return { label: n, value: n } }) : [];
            let inPort = null;
            const outPort = tempSelectNode.outPort;
            if (fullscreen) { _this.imgClick("fullscreenExit", {}); }
            Modal.confirm({
              title: "选择接入端口",
              content: <div style={{ maxHeight: 500, overflow: "auto" }}>
                <p>起点:{tempSelectNode.label}&nbsp;&nbsp;&nbsp;起点端口:{tempSelectNode.outPort}</p>
                <p>终点:{nodeModel.label}&nbsp;&nbsp;&nbsp;</p><Divider />
                <Radio.Group options={RadioData} onChange={(e) => { inPort = e.target.value }} />
              </div>,
              onOk() {
                if (!inPort) {
                  Modal.confirm({
                    title: "消息提示",
                    content: "你未选择端口是否继续提交？",
                    onOk() { graph.updateItem(thisEdge, { start_port: outPort, end_port: "", target: model.id }); }
                  })
                } else { graph.updateItem(thisEdge, { start_port: outPort, end_port: inPort, target: model.id }); }
                _this.setState({ tempSelectNode: null })//取消tempSelectNode
                graph.setMode("default");
              },
              onCancel() {
                graph.setMode("default");
              }
            });
          } else {
            const temp = graph.updateItem(this.edge, { target: model.id });
            // console.log(temp);
          }
          this.edge = null;
          this.addingEdge = false;
        } else {
          this.edge = graph.addItem('edge', {
            id: new Date().getTime() + "",
            color: "#000",
            source: model.id,
            target: point
          });
          this.addingEdge = true;
        }
      },
      onMousemove(ev) {
        const point = {
          x: ev.x,
          y: ev.y
        };
        if (this.addingEdge && this.edge) {
          _this.state.graph.updateItem(this.edge, {
            target: point
          });
        }
      },
      onEdgeClick(ev) {
        const currentEdge = ev.item;
        // 拖拽过程中，点击会点击到新增的边上
        if (this.addingEdge && this.edge == currentEdge) {
          _this.state.graph.removeItem(this.edge);
          this.edge = null;
          this.addingEdge = false;
        }
      }
    });
    // Register a custom behavior to add node
    G6.registerBehavior('click-add-node', {
      getEvents() {
        return {
          'canvas:click': 'onClick'
        };
      },
      onClick(ev) {
        const graph = _this.state.graph;
        const { selectNode } = _this.state;
        const node = graph.addItem('node', {
          shape: "image",
          // x: ev.canvasX,
          // y: ev.canvasY,
          x: ev.x,
          y: ev.y,
          size: 40,
          label: selectNode.label,
          img: selectNode.img,
          id: `node-${addedCount}`, // 生成唯一的 id
        });
        addedCount++;
        graph.setMode("default");
      }
    });
  }
  registerCombo() {
    const collapseIcon = (x, y, r) => {
      return [
        ['M', x - r, y],
        ['a', r, r, 0, 1, 0, r * 2, 0],
        ['a', r, r, 0, 1, 0, -r * 2, 0],
        ['M', x - r + 4, y],
        ['L', x - r + 2 * r - 4, y],
      ];
    };
    const expandIcon = (x, y, r) => {
      return [
        ['M', x - r, y],
        ['a', r, r, 0, 1, 0, r * 2, 0],
        ['a', r, r, 0, 1, 0, -r * 2, 0],
        ['M', x - r + 4, y],
        ['L', x - r + 2 * r - 4, y],
        ['M', x - r + r, y - r + 4],
        ['L', x, y + r - 4],
      ];
    };
    G6.registerCombo('cRect', {
      drawShape: function drawShape(cfg, group) {
        const self = this;
        // Get the padding from the configuration
        cfg.padding = cfg.padding || [50, 20, 20, 20];
        // Get the shape's style, where the style.width and style.height correspond to the width and height in the figure of Illustration of Built-in Rect Combo
        const style = self.getShapeStyle(cfg);
        // Add a rect shape as the keyShape which is the same as the extended rect Combo
        const rect = group.addShape('rect', {
          attrs: {
            ...style,
            x: -style.width / 2 - (cfg.padding[3] - cfg.padding[1]) / 2,
            y: -style.height / 2 - (cfg.padding[0] - cfg.padding[2]) / 2,
            width: style.width,

            height: style.height
          },
          draggable: true,
          name: 'combo-keyShape'
        });
        // Add the circle on the right
        group.addShape('marker', {
          attrs: {
            ...style,
            fill: '#fff',
            opacity: 1,
            // cfg.style.width and cfg.style.heigth correspond to the innerWidth and innerHeight in the figure of Illustration of Built-in Rect Combo
            x: cfg.style.width / 2 + cfg.padding[1],
            y: (cfg.padding[2] - cfg.padding[0]) / 2,
            r: 10,
            cursor: "pointer",
            symbol: collapseIcon
          },
          draggable: true,
          name: 'combo-marker-shape'
        });
        return rect;
      },
      // Define the updating logic of the right circle
      afterUpdate: function afterUpdate(cfg, combo) {
        const group = combo.get('group');
        // Find the circle shape in the graphics group of the Combo by name
        const marker = group.find(ele => ele.get('name') === 'combo-marker-shape');
        // Update the position of the right circle
        marker.attr({
          // cfg.style.width and cfg.style.heigth correspond to the innerWidth and innerHeight in the figure of Illustration of Built-in Rect Combo
          x: cfg.style.width / 2 + cfg.padding[1],
          y: (cfg.padding[2] - cfg.padding[0]) / 2,
          symbol: cfg.collapsed ? expandIcon : collapseIcon
        });
      }
    }, 'rect');
  }
  registerLabelEdge() {
    G6.registerEdge(
      'label-edge',
      {
        afterDraw(cfg, group) {
          // 获取图形组中的第一个图形，在这里就是边的路径图形
          const shape = group.get('children')[0];
          // 获取路径图形的中点坐标
          const flag = parseInt(cfg.id.split("-")[2]) % 2;
          let initRote = 0.35
          if (flag) {
            initRote = 0.45
          }
          const midPoint = shape.getPoint(initRote);
          // 在中点增加
          // console.log(cfg)
          group.addShape('text', {
            attrs: {
              text: cfg.start_port || "",
              fill: 'blue',
              textAlign: 'start',
              textBaseline: 'middle',
              stroke: "#fff",
              x: midPoint.x - 25,
              y: midPoint.y,

            },
            name: 'start-text-shape',
          });
          // 获取路径上的坐标
          const pos = shape.getPoint(0.7);
          group.addShape('text', {
            attrs: {
              text: cfg.end_port || "",
              fill: 'blue',
              textAlign: 'start',
              textBaseline: 'middle',
              shadowColor: '#fff',
              stroke: "#fff",
              x: pos.x - 25,
              y: pos.y

            },
            name: 'end-text-shape',
          });
        },
        update: undefined
      },
      'line',
      // 'polyline',
    );

  }
  renderMap() {
    const { flow_id } = this.state;
    const minimap = new Minimap({
      size: [100, 100],
      className: "minimap",
      type: 'delegate'
    })
    const toolbar = new G6.ToolBar({
      position: { x: 10, y: 90 }
    });
    const grid = new Grid();
    const graph = new G6.Graph({
      container: flow_id,
      fitView: true,//自动适配画布大小
      // fitCenter: true,//图的中心将对齐到画布中心
      // fitViewPadding: [0, 0, 0, 0],
      animate: true, // Boolean，可选，切换布局时是否使用动画过度
      linkCenter: true, // 连接节点中心
      width: this.props.width || document.getElementById(flow_id).clientWidth,
      height: document.getElementById(flow_id).clientHeight,
      plugins: [minimap, toolbar, grid],          // 将 minimap 实例配置到图上
      modes: {
        default: ['drag-canvas', {
          type: 'zoom-canvas',
        }, {
            type: 'edge-tooltip', //边提示框
            formatText(model) {
              // 提示框文本内容
              const text = '起点: ' + model.source + '<br/>端口: ' + (model.start_port || "") + '<hr/>终点: ' + model.target + '<br/>端口: ' + (model.end_port || "");
              // const text = '起点: ' + model.source + '<br/>端口: ' + (model.start_port || "") + '<div style="font-size:20px;text-align:center;">↓</div>终点: ' + model.target + '<br/>端口: ' + (model.end_port || "");
              return text;
            }
          },
          // 'drag-node', "drag-combo", 'click-select', "brush-select",],// 允许拖拽画布、放缩画布、拖拽节点
          // 'drag-node', "drag-combo", 'click-select', "brush-select", 'drag-group', 'drag-node-with-group', 'collapse-expand-group'],// 允许拖拽画布、放缩画布、拖拽节点 
          'drag-node', 'click-select', "drag-combo", "brush-select"],// 允许拖拽画布、放缩画布、拖拽节点
        addNode: ['click-add-node', 'click-select'],
        addEdge: ['click-add-edge', 'click-select']
      },
      // //点击node添加模糊蒙层
      nodeStateStyles: {
        selected: {
          // opacity: 0.8,
          lineWidth: 15,
          lineDash: 10,
          stroke: '#1e90ff',
          shadowColor: "#1e90ff",
          shadowBlur: 15,
          // shadowOffsetX: 2,
          // shadowOffsetY: 2
        },
      },
      defaultNode: {
        type: 'image',
        // size: [30, 30],
        img: '',
        label: '',
        style: {
          cursor: "pointer"
        },
        labelCfg: {
          style: {
            // stroke: "#000",
            fill: "#000",
            // fontSize: 16,
          }
        }
      },
      defaultCombo: {//默认分组样式
        type: "cRect",
        // size: [50, 50], // Combo 的最小大小
        style: {
          lineWidth: 1,
        },
        padding: [35, 30, 25, 30],
        // labelCfg: {
        //   refY: 10,//Y方向偏移量
        //   position: 'top',
        //   style: {
        //     fontSize: 15,
        //   }
        // }
      },
      defaultEdge: {
        type: "label-edge",
        style: {
          lineAppendWidth: 10,
          lineWidth: 2,
          cursor: "pointer",
          stroke: '#F6BD16',
        },
        // style: {
        //   stroke: '#777',
        //   lineWidth: 1,
        //   lineAppendWidth: 5,
        //   cursor: "pointer",
        //   // endArrow: true,
        // },
        // labelCfg: {
        //   autoRotate: true,//标签文字是否跟随边旋转，默认 false
        //   position: "middle",//线文本默认位置
        //   refY: 10,
        //   style: {
        //     stroke: 'white',
        //     lineWidth: 5,
        //   } 
        // },
      },
      // groupByTypes: false,//配置后无法点击连线
      groupType: 'rect',
      groupStyle: {
        default: { lineWidth: 3, cursor: 'pointer' },
        hover: { lineWidth: 10, cursor: 'pointer' },
        collapse: { cursor: 'pointer' },
      },
      // 节点不同状态下的样式集合
      edgeStateStyles: {
        selected: {
          lineWidth: 3,
          stroke: "#000"
        },
        hover: {
          lineWidth: 3,
          stroke: "#5f5e5e"
        }
        // 鼠标点击边，即 click 状态为 true 时的样式
        // click: {
        //   stroke: 'red'
        // }
      },
      comboStateStyles: {
        hover: {
          // lineWidth: 4,
          cursor: "pointer",
        },
        selected: { lineWidth: 4 },
        dragenter: {
          lineWidth: 4,
          stroke: '#FE9797'
        },
      },
      enabledStack: true,//开启 redo & undo 功能
      // maxStep: 3,//redo & undo 最大步数
      // layout: { 
      //   type: 'dagre',
      //   rankdir: 'TB', // 可选，默认为图的中心'TB'：从上至下布局'BT'：从下至上布局；'LR'：从左至右布局；'RL'：从右至左布局。
      //   // align: 'DL', // 可选
      //   nodesep: 20, // 可选
      //   ranksep: 50, // 可选
      //   controlPoints: true, // 可选
      // }
    });
    //设置各个 combo 样式及其他配置，以及在各个状态下节点的 KeyShape 的样式。
    // graph.combo(combo => {
    //   return {
    //     id: combo.id,
    //     type: 'rect',
    //     style: {
    //     },
    //   };
    // });

    this.setState({ graph, toData: Utils.createTopoData(this.props.data) });
    this.props.refThis(this, graph);
    this.listenBehavior(graph);
    this.loadData(graph, this.props.data);
    //把局部渲染关掉:
    graph.get('canvas').set('localRefresh', false);
  }
  //默认布局
  updateLayout(graph) {
    graph.updateLayout({
      type: 'dagre',
      rankdir: 'TB', // 可选，默认为图的中心'TB'：从上至下布局'BT'：从下至上布局；'LR'：从左至右布局；'RL'：从右至左布局。 
      nodesep: 20, // 可选
      ranksep: 50, // 可选
      controlPoints: true, // 可选
      // //同心圆布局
      // type: 'concentric',
      // preventOverlap: true, // 布局参数，是否允许重叠
      // minNodeSpacing: 30,	//	环与环之间最小间距，用于调整半径
      // nodeSize: 50, // 布局参数，节点大小，用于判断节点是否重叠
      //力导向布局
      // type: 'force',
      // preventOverlap: true, // 布局参数，是否允许重叠
      // linkDistance: 200,//边长
      // nodeSize: 50, // 节点大小（直径）。用于碰撞检测
      // nodeSpacing: 40,//preventOverlap 为 true 时生效，防止重叠时节点边缘间距的最小值
      //ruchterman力导向布局
      // type: 'radial',
      // preventOverlap: true, // 布局参数，是否允许重叠
      // linkDistance: 300,
      // // strictRadial: false, // 
      // // sortStrength: 1,//同层节点根据 sortBy 排列的强度，数值越大，sortBy 指定的方式计算出距离越小的越靠近。sortBy 不为 undefined 时生效
      // nodeSize: 150, // 布局参数，节点大小，用于判断节点是否重叠
      // nodeSpacing: 350, // preventOverlap 为 true 时生效，防止重叠时节点边缘间距的最小值
    });
    graph.fitView();
    graph.fitCenter();
  }
  //更改布局格式
  changeChartLayout(type) {
    const { graph, } = this.state;
    if (type === "dagre") {
      graph.updateLayout({
        type: 'dagre',
        rankdir: 'TB', // 可选，默认为图的中心'TB'：从上至下布局'BT'：从下至上布局；'LR'：从左至右布局；'RL'：从右至左布局。 
        nodesep: 20, // 可选
        ranksep: 50, // 可选
        controlPoints: true, // 可选
      });
    } else if (type === "concentric") {
      graph.updateLayout({
        // //同心圆布局
        type: 'concentric',
        preventOverlap: true, // 布局参数，是否允许重叠
        minNodeSpacing: 100,	//	环与环之间最小间距，用于调整半径
        nodeSize: 50, // 布局参数，节点大小，用于判断节点是否重叠
      });
    } else if (type === "force") {
      graph.updateLayout({
        //力导向布局
        type: 'force',
        preventOverlap: true, // 布局参数，是否允许重叠
        linkDistance: 200,//边长
        nodeSize: 50, // 节点大小（直径）。用于碰撞检测
        nodeSpacing: 40,//preventOverlap 为 true 时生效，防止重叠时节点边缘间距的最小值
      });
    } else if (type === "radial") {
      graph.updateLayout({
        // ruchterman力导向布局
        type: 'radial',
        preventOverlap: true, // 布局参数，是否允许重叠
        linkDistance: 300,
        // strictRadial: false, // 
        // sortStrength: 1,//同层节点根据 sortBy 排列的强度，数值越大，sortBy 指定的方式计算出距离越小的越靠近。sortBy 不为 undefined 时生效
        nodeSize: 150, // 布局参数，节点大小，用于判断节点是否重叠
        nodeSpacing: 350, // preventOverlap 为 true 时生效，防止重叠时节点边缘间距的最小值
      });
    }

    setTimeout(() => {//居中延迟
      graph.fitView();
      graph.fitCenter();
    }, 500)
  }
  loadData(graph, _data) {
    //更新数据 
    const data = Utils.createTopoData(_data);
    try {
      if (Array.isArray(_data) && _data.length > 0) {//源数据 
        this.updateLayout(graph);
      } else if (this.props.isLayout) {
        this.updateLayout(graph);
      }
    } catch (e) {
    }
    graph.read(data);
    //数据多的让画布内容适应视口。
    // if (data.nodes.length > 10) { graph.fitView(); }
    // graph.fitView();
    // graph.fitCenter();
  }
  reloadData(graph) {
    //更新数据
    const data = Utils.createTopoData(this.props.data);
    graph.clear();
    graph.read(data);
    //数据多的让画布内容适应视口。
    // if (data.nodes.length > 10) { graph.fitView(); }
    graph.fitView();
  }

  //显示右键菜单
  showContextmenu(evt) {
    // console.log(evt)
    evt.preventDefault();
    evt.stopPropagation();
    this.contextmeun.style.left = `${evt.clientX}px`;
    this.contextmeun.style.top = `${evt.clientY}px`;
  }
  listenBehavior(graph) {
    const { initMenu, } = this.state;
    graph.on('dragend', evt => {
      //修改图标记
      if (this.props.onChange) { this.props.onChange(true) }
    })
    graph.on('canvas:click', evt => {
      //隐藏右键菜单
      if (this.contextmeun) {
        this.contextmeun.style.left = `-300px`;
      }
      //修改图标记
      if (this.props.onChange) { this.props.onChange(true) }
      //点击画布取消其他行为模式，重置默认模式。
      if (graph.getCurrentMode() != "default") {
        graph.setMode("default");
      }
    })
    //node
    graph.on('node:contextmenu', evt => {
      this.showContextmenu(evt);
      const selectNode = evt.item.getModel();
      // const visible = item.isVisible();
      const MenuData = [...initMenu,
      { title: "详情信息", key: "detail", icon: "file-text" },
      { title: "添加分组", key: "addGroup", icon: "usergroup-add" },
      { title: "删除节点", key: "delNode", icon: "delete" },
      // { title: "隐藏节点", key: "foldNode", icon: "shrink" },
      // { title: "显示节点", key: "unFoldNode", icon: "arrows-alt" },
      {
        title: "端口连线", key: "addPortLine", icon: "swap-right", children: selectNode.port_list && selectNode.port_list.map((port) => {
          return { title: port, key: port, }
        })
      },
      ]
      //设置选中状态
      graph.setItemState(evt.item, 'selected', true);
      this.setState({ MenuData, selectNode });
    })
    graph.on('node:click', ev => {
      this.setState({ selectNode: ev.item.getModel() })
    });
    //edge
    graph.on('edge:contextmenu', evt => {
      this.showContextmenu(evt);
      const data = evt.item.getModel().type === "line" ?
        { title: "显示端口", key: "showPort", icon: "eye" } :
        { title: "隐藏端口", key: "hiddenPort", icon: "eye-invisible" }
      const MenuData = [...initMenu, data,
      { title: "删除连线", key: "delEdge", icon: "delete" }]
      this.setState({ MenuData, selectEdge: evt.item.getModel() });
    })
    graph.on('edge:mouseenter', evt => {
      const { item } = evt;
      graph.setItemState(item, 'hover', true);
    })
    graph.on('edge:mouseleave', evt => {
      const { item } = evt;
      graph.setItemState(item, 'hover', false);
    });
    // 点击时选中，
    graph.on('edge:click', ev => {
      //取消已经选中的线
      graph.findAllByState('edge', 'selected').map((line) => {
        graph.setItemState(line, 'selected', false); // 取消选中
      })
      const edge = ev.item;
      graph.setItemState(edge, 'selected', !edge.hasState('selected')); // 切换选中
      this.setState({ selectNode: { ...edge.getModel(), type: "edge" } })
    });
    //combo
    graph.on('combo:contextmenu', evt => {
      // console.log(evt)
      this.showContextmenu(evt);
      const MenuData = [...initMenu,
      { title: "添加分组", key: "addGroup", icon: "usergroup-add" },
      { title: "取消分组", key: "delGroup", icon: "usergroup-delete" },
      { title: "折叠分组", key: "foldGroup", icon: "shrink" },
      { title: "展开分组", key: "unfoldGroup", icon: "arrows-alt" },
      { title: "删除分组元素", key: "delGroupNode", icon: "delete" },
      ]
      this.setState({ MenuData, selectNode: evt.item.getModel() });
    })
    graph.on('combo:click', e => {
      if (e.target.get('name') === 'combo-marker-shape') {
        // graph.collapseExpandCombo(e.item.getModel().id);
        if (e.item.getModel().collapsed) {
          graph.expandCombo(e.item);
        } else {
          graph.collapseCombo(e.item);
        }
        graph.refreshPositions();//刷新所有节点位置，并重计算边的位置。
      }
      this.setState({ selectNode: { ...e.item.getModel(), type: "combo" } })
    });
    // graph.on('combo:dragend', e => {
    //   graph.getCombos().forEach(combo => {
    //     graph.setItemState(e.item, 'dragenter', false);
    //   })
    // });
    graph.on('node:dragend', e => {
      graph.getCombos().forEach(combo => {
        graph.setItemState(combo, 'dragenter', false);
      })
    });
    // graph.on('combo:dragenter', e => {
    //   graph.setItemState(e.item, 'dragenter', true);
    // });
    // graph.on('combo:dragleave', e => {
    //   graph.setItemState(e.item, 'dragenter', false);
    // });
    //布局完成后触发居中
    graph.on("afterlayout", () => {
      console.log("afterlayout")
      graph.fitView();
      graph.fitCenter();
    })
  }
  changeSize() {
    const { flow_id } = this.state;
    this.state.graph.changeSize(document.getElementById(flow_id).clientWidth, document.getElementById(flow_id).clientHeight)
  }
  changeEdgeLabelState(graph, isShow) {
    //必须搭配render()才生效
    graph.edge((edge) => {
      edge.type = isShow ? "label-edge" : "line";
      return edge;
    });
    graph.render();
  }
  fullscreenFun() {//进入全屏
    const _this = this;
    Utils.launchFullScreen(document.getElementsByClassName("flowEditor")[0]);
    //修改网格错位
    const content = document.getElementsByClassName("g6-grid-container")[0];
    if (content) { content.style.top = "40px"; }
    this.setState({ fullscreen: true });
    //修改画布大小
    setTimeout(() => { _this.changeSize() }, 100)
  }
  fullscreenExitFun() {//退出全屏
    const { fullscreen } = this.state;
    const _this = this;
    if (fullscreen) {
      Utils.exitFullScreen(document.getElementsByClassName("flowEditor")[0]);
      //修改画布大小
      setTimeout(() => { _this.changeSize() }, 100);
      //修改网格错位
      const content = document.getElementsByClassName("g6-grid-container")[0];
      if (content) { content.style.top = "81px"; }
      this.setState({ fullscreen: false });
    }
  }
  imgClick(value, selectNode) {
    const { graph } = this.state;
    const _this = this;
    if (graph) {
      switch (value) {
        case "delete": this.contextMenuSumit("delNode"); break;
        case "reload": this.loadData(graph, this.props.data); break;
        case "fill": this.changeSize(); graph.fitView(); break;
        case "autoLay": this.updateLayout(graph); graph.fitView(); break;
        case "save":
          console.log(JSON.stringify(graph.save()));
          this.props.save(graph.save(), true);
          this.props.onChange(false);
          graph.setMode("defaul");
          break;
        case "fullscreen": this.fullscreenFun(); break;
        case "fullscreenExit": this.fullscreenExitFun(); break;
        case "export":
          console.log(JSON.stringify(graph.save()));
          Utils.download("拓扑图.txt", JSON.stringify(graph.save(), null, 5));
          graph.setMode("defaul");
          break;
        case "import":
          let val = "";
          Modal.confirm({
            title: "导入数据",
            width: "70%",
            content: <div>
              <Input.TextArea defaultValue={val} style={{ height: 300 }} onChange={(e) => { val = e.target.value }} />
            </div>,
            onOk() {
              try { graph.read(JSON.parse(val)); } catch (e) { }
            }
          })
          break;
        default: graph.setMode(value); this.setState({ selectNode }); break;
      }
    }
  }
  foldNode(selectNode) {
    const { graph, } = this.state;
    const node = graph.findById(selectNode.id);
    // 获取与 node 关联的所有出边
    const edges = node.getOutEdges();
    //收起一级子节点
    edges.map((edge) => {
      if (edge.getTarget()) {
        graph.hideItem(edge.getTarget());
        this.foldNode(edge.getTarget().getModel());
      }

    })
    //锁定节点
    // node.lock();
    let newNode = node.getModel();
    graph.refreshItem(newNode.id);
  }
  unFoldNode(selectNode) {
    const { graph, } = this.state;
    const node = graph.findById(selectNode.id);
    // 获取与 node 关联的所有出边
    const edges = node.getOutEdges();
    console.log(edges);
    edges.map((edge) => {
      graph.showItem(edge.getTarget());
      this.unFoldNode(edge.getTarget().getModel());
    })
  }
  contextMenuSumit(e) {
    const { graph, selectNode, selectEdge } = this.state;
    switch (e) {
      case 'detail': openDetail(selectNode.dev_ip); break;
      case 'addGroup':
        const nodeIds = graph.findAllByState('node', 'selected').map((item) => {
          return item.getModel().id;
        });
        graph.createCombo({
          id: new Date().getTime() + "",
          type: "cRect",
          label: '新建区域',
        }, nodeIds);
        break;
      case 'unfoldGroup':
        if (selectNode.id) {
          graph.expandCombo(selectNode.id);
          graph.updateItem(selectNode.id, { type: "cRect" })
        }
        break;
      case 'foldGroup':
        if (selectNode.id) {
          graph.collapseCombo(selectNode.id);
          graph.updateItem(selectNode.id, { type: "cRect" })
        }
        break;
      case 'delGroup':
        if (selectNode.id) {
          graph.uncombo(selectNode.id);
        } break;
      case 'delGroupNode':
        if (selectNode.id) {
          graph.removeItem(selectNode.id);
        }
        break;
      case 'defaul': graph.setMode("defaul"); break;
      case 'edit': graph.setMode("defaul"); this.setState({ foldState: false }); break;
      case 'addEdge': graph.setMode("addEdge"); break;
      case 'showPort': this.changeEdgeLabelState(graph, true); break;
      case 'hiddenPort': this.changeEdgeLabelState(graph, false); break;
      case 'delEdge':
        // 通过状态查询节点实例
        console.log(selectEdge);
        if (selectEdge) {
          const items = graph.findById(selectEdge.id);
          console.log(items);
          graph.remove(items);
          console.log(items);
          graph.refresh();
        }
        break;
      case 'foldNode':
        // //更新样式 
        graph.updateItem(selectNode.id, {
          labelCfg: {
            style: {
              fill: 'blue',
            },
          },
        });
        this.foldNode(selectNode); break;
      case 'unFoldNode':
        // //更新样式 
        graph.updateItem(selectNode.id, {
          labelCfg: {
            style: {
              fill: '#000',
            },
          },
        });
        this.unFoldNode(selectNode);
        break;
      case 'delNode':
        // 通过ID查询节点实例
        if (selectNode && selectNode.id) {
          const item = graph.findById(selectNode.id)
          graph.removeItem(item);
        }
        break;
      default:
        //连线模式设置端口 
        selectNode.outPort = e;
        this.setState({ tempSelectNode: selectNode })
        graph.setMode("addEdge");
        break;
    }
    //隐藏右键菜单
    this.contextmeun.style.left = `-300px`;
    // 隐藏g6-tooltip
    const tip = document.getElementsByClassName("g6-tooltip")
    if (tip) {
      for (let i = 0; i < tip.length; i++) {
        tip[i].style.visibility = "hidden";
      }
    }
  }
  onDragEndImg(e, item) {
    const { graph, } = this.state;
    const div_zb = graph.getPointByClient(e.clientX, e.clientY);
    graph.addItem('node', {
      x: div_zb.x,
      y: div_zb.y,
      size: 40,
      label: item[1].label,
      img: item[1].img,
      id: new Date().getTime() + "1", // 生成唯一的 id
    });
  }
  onDragEndText(e) {
    const { graph, } = this.state;
    const div_zb = graph.getPointByClient(e.clientX, e.clientY);
    graph.addItem('node', {
      x: div_zb.x,
      y: div_zb.y,
      type: "rect",
      size: [70, 25],
      label: "新建文本",
      style: {
        opacity: 0,
        fill: "#fff",
        cursor: "move",
        stroke: "#fff"
      },
      id: new Date().getTime() + "1", // 生成唯一的 id
    });

  }
  render() {
    const { selectNode, graph, MenuElemt, foldState, MenuData, flow_id, toData } = this.state;
    return (
      <div className={"flowEditor"}>
        <div className={"headEditor"}><Divider type="vertical" />
          {[
            ["addNode", { img: "/image/l3-switch.png", label: "switch" }],
            ["addNode", { img: "/image/router.png", label: "router" }],
            // ["addNode", { img: "/image/L3-switch.png", label: "switch" }],
            // ["addNode", { img: "/image/router.png", label: "router" }],
            ["addNode", { img: "/image/firewall-2.png", label: "firewall" }],
            ["addNode", { img: "/image/laptop.png", label: "PC" }],
            ["addNode", { img: "/image/P-WAN-trusted.png", label: "cloud" }],

          ].map((item, key) => {
            return <Fragment key={key}><Tooltip
              title={item[1].label}
              placement="bottom"
              overlayClassName={"tooltip"}
            >
              <img draggable="true" src={item[1].img} onClick={() => { this.imgClick(item[0], item[1]) }} className="toolImg"
                onDragEnd={(e) => { this.onDragEndImg(e, item) }}
              />
            </Tooltip><Divider type="vertical" /></Fragment>
          })}
          <Tooltip
            title="文本"
            placement="bottom"
            overlayClassName={"tooltip"}
          >
            <span draggable="true" className="labelImg"
              onDragEnd={(e) => { this.onDragEndText(e) }}
            >文本</span>
          </Tooltip><Divider type="vertical" />
          {[
            ["select", "default", "指针"],
            ["swap-right", "addEdge", "连线"],
            ["delete", "delete", "删除节点"],
            // ["edit", "default", "编辑节点"],
            // ["import", "import", "导入数据"],
            ["export", "export", "导出数据"],
            ["import", "import", "导入数据"],
            ["save", "save", "保存拓扑图"],
            ["fullscreen", "fullscreen", "全屏"],
            ["fullscreen-exit", "fullscreenExit", "退出全屏"],
            ["column-width", "fill", "适应屏幕"],
            ["retweet", "reload", "重新加载拓扑图"],
          ].map((item, key) => {
            return <Fragment key={key}><Tooltip
              title={item[2]}
              placement="top"
              overlayClassName={"tooltip"}
            >
              <Icon className="toolIcon" type={item[0]} onClick={() => { this.imgClick(item[1], selectNode) }} />
            </Tooltip><Divider type="vertical" /></Fragment>
          })}
          <Tooltip
            title="自动布局"
            placement="top"
            overlayClassName={"tooltip"}
          >
            <Dropdown overlay={() => (
              <Menu onClick={(e) => { this.changeChartLayout(e.key) }}>
                <Menu.Item key="concentric">同心圆布局</Menu.Item>
                <Menu.Item key="force">力导向布局1</Menu.Item>
                <Menu.Item key="radial">力导向布局2</Menu.Item>
                <Menu.Item key="dagre">层次布局</Menu.Item>
              </Menu>
            )} trigger={['click']}>
              <Icon className="toolIcon" type={"gateway"} />
            </Dropdown>
          </Tooltip><Divider type="vertical" />
          <Tooltip
            title={foldState ? "展开详情" : "收起详情"}
            placement="bottom"
            overlayClassName={"tooltip"}
          >
            <Icon className="toolIconRight" type={foldState ? "menu-fold" : "menu-unfold"} onClick={() => {
              this.setState({ foldState: !foldState });
              setTimeout(() => {
                graph.changeSize(document.getElementById(flow_id).clientWidth, document.getElementById(flow_id).clientHeight)
              }, 100);
            }} />
          </Tooltip>
          {/* <Divider type="vertical" /> */}
        </div>
        {/* <Row>
          <Col span={foldState ? 24 : 20}> */}
        <div id={flow_id} className="flow" />
        {/* </Col>
          <Col span={foldState ? 0 : 4}>
            {foldState ? null :
              <div className="nodeDetail">
                <SearchInput dataSource={toData.nodes} graph={graph} />
                <DetailForm selectNode={selectNode} graph={graph} />
              </div>
            }
          </Col>
        </Row> */}
        <div ref={ref => { this.contextmeun = ref }} className="contextMenu">
          <RightMenu MenuList={MenuData} onChange={(key) => { this.contextMenuSumit(key) }} />
        </div>

        <Drawer
          title="设备信息"
          placement="right"
          visible={!foldState}
          onClose={() => { this.setState({ foldState: true }) }}
          getContainer={() => (document.getElementById(flow_id))}
          mask={false}
        // style={{ position: 'absolute' }}
        >
          <div className="nodeDetail">
            <SearchInput dataSource={toData.nodes} graph={graph} onChange={(e) => this.setState({ selectNode: e })} />
            <Divider />
            <DetailForm selectNode={selectNode} graph={graph} />
          </div>
        </Drawer>
      </div>
    );
  }
}

