/**
 * Created by YIMINE on 2019/1/24.
 */
import React, { Fragment } from 'react'
import { Typography, Input, Table, Modal, Select, message, Button, Icon, notification, Spin, Tag, Collapse } from 'antd';
import $ from 'jquery'
import './index.scss'
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import FilterForm from '@/components/FilterForm/index';
import GroupElemt from "@/components/ScriptModal/groupElemt";
import MonacoEditor from 'react-monaco-editor';
import ScriptModal from "@/components/ScriptModal/index";
import SelectDevice from '@/components/SelectDevice/index';
import ResUtils from "@/utils/ResUtils";
const TextArea = Input.TextArea;
const { Title } = Typography;
const Search = Input.Search;
let _nodeOper_data = null;//节点数据

class TestOperating extends React.Component {
    state = {
        current: 0,                 //步骤条计数
        deviceCurrent: 0,
        visible_param: false,       //绑定参数模态框
        visible_device: false,      //选择设备模态框
        slt_device: [],
        slt_oper: null,  //选中的操作对象
        deviceData: [],
        selectedRowKeys: [],
        deviceTotal: 0,
        scriptSel: {},
        resultInfo: null,
        isLoading: true
    };
    componentWillMount() {

        let slt_oper = this.props.slt_oper;
        this.setState({ slt_oper });

    }
    updataResources = (pageSize, pageNum, querys) => {
        var bodyJson = new Object();
        bodyJson.pageSize = pageSize || 10;
        bodyJson.pageNum = pageNum || 1;
        bodyJson.querys = querys || {};
        Socket.QueryDataByPage("QueryResourcesPage", bodyJson, (result) => {
            if (result.retcode === '00') {
                let Resources = result.resources;
                Resources.map((item, index) => {
                    item.key = index;
                    item.index = index;
                });
                this.setState({
                    deviceData: Resources,
                    deviceTotal: result.total,
                    deviceCurrent: pageNum,
                })
            }
        }, "/queryResource");
    };
    waitPage = () => {
        // console.log('initModal');
        const current = this.state.current + 1;
        this.setState({ current });
    };
    initModal = () => {
        // console.log('initModal');
        this.setState({
            current: 0,                 //步骤条计数
            deviceCurrent: 1,
            visible_param: false,       //绑定参数模态框
            visible_device: false,      //选择设备模态框
            slt_device: [],
            selectedRowKeys: [],        //清空
        });
    };

    finishModal = () => {
        const { slt_oper, slt_device, } = this.state;
        let _InScript = slt_oper.operationIn.hasOwnProperty("script") ? slt_oper.operationIn.script : [];
        let _OutScript = slt_oper.operationOut.hasOwnProperty("script") ? slt_oper.operationOut.script : [];
        const { scriptSel } = this.fromThis.state;
        _nodeOper_data = {
            operation: slt_oper,
            input: {
                script: scriptSel
            },
            output: _OutScript
        };//初始化节点数据
        // _InScript.map((text, key) => {
        //     if (text.type === 'upload') {
        //         _nodeOper_data['input']['script'][text.name + ''] = reportData;
        //     } else {
        //         _nodeOper_data['input']['script'] = scriptSel;
        //     }
        // });


        //判断是否需要选择设备
        if (slt_oper.operationIn.select && (slt_oper.operationIn.select).includes('resource')) {
            _nodeOper_data.input.resource = slt_device;
        }

        console.log(_nodeOper_data);
        Socket.Action('OperationTest', _nodeOper_data, (result) => {
            if (result.retcode === '00') {
                // notification.open({
                //     message: '信息反馈',
                //     description: JSON.stringify(result,null,5),
                //     icon: <Icon type="smile" style={{ color: '#108ee9' }} />,
                // });
                // let resultInfo = JSON.stringify(result, null, 5);
                let resultInfo = result;

                this.setState({ resultInfo, isLoading: false });
                // this.props.closeWin();
                // this.initModal();
            } else {
                notification.open({
                    message: '操作失败',
                    description: result.error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.initModal();
            }
        }, '');
        this.waitPage();
    };
    getSetup3() {
        let { resultInfo, isLoading } = this.state;
        if (!resultInfo) {
            return <Spin tip="测试中，等待结果..." spinning={isLoading}>
                <div style={{ textAlign: "left", minHeight: 500 }}>
                </div>
            </Spin>;
        }
        let data = resultInfo.data;
        let elem = [];
        for (let lab in data) {
            let valData = data[lab];
            let finData1 = "";
            if (typeof (valData) === "object") {
                if (valData.length < 2) {
                    finData1 = valData[0];
                } else {
                    finData1 = JSON.stringify(valData, null, 5);
                }

            } else {
                finData1 = valData
            }

            elem.push(
                <Collapse.Panel header={lab} key={lab}>
                    <MonacoEditor
                        width="99%"
                        height="600"
                        language="java"
                        // theme="vs-dark"
                        value={finData1}
                        options={{ selectOnLineNumbers: true }}
                    />
                </Collapse.Panel>
            );
        }
        console.log(elem)
        let step33 = <Spin tip="测试中，等待结果..." spinning={isLoading}>
            <div style={{ textAlign: "left", minHeight: 500 }}>
                <Collapse accordion>
                    {elem}
                </Collapse>
            </div>
        </Spin>;
        return step33;
    }
    render() {
        const { deviceCurrent, current, slt_oper, resultInfo, isLoading, selectedRowKeys } = this.state;
        if (Utils.isEmptyObject(slt_oper) || !slt_oper) {
            message.error("请填写操作脚本!"); return null;
        }
        const rowDevice = {
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                let slt_device = [];
                slt_device = selectedRows;
                this.state.selectedRowKeys = selectedRowKeys;
                this.setState({ slt_device, selectedRowKeys: this.state.selectedRowKeys });
            },
            selectedRowKeys: this.state.selectedRowKeys,
        };
        const columnsDevice = [
            {
                title: '名称',
                align: 'left',
                width: "200px",
                dataIndex: 'resourceName'
            }, {
                title: '厂商',
                align: 'left',
                dataIndex: 'resourceParams.deviceFirm',
            }, {
                title: '型号',
                align: 'left',
                dataIndex: 'resourceParams.deviceVersion',
            }, {
                title: '标签',
                align: 'left',
                dataIndex: 'tags',
                width: "200px",
                render: (text, item, index) => {
                    return Utils.hidenTag(text);
                }
            },
        ];
        const selDevice = [...ResUtils.getColumns(10000, 1), {
            title: '操作',
            width: 50,
            fixed: "right",
            align: 'center',
            render: (text, item, index) => {
                return <Button type="danger" icon="delete" size="small"
                    onClick={() => {
                        console.log(text, item, index);
                        const dataSource = [...this.state.slt_device];
                        const selected = [...this.state.selectedRowKeys];
                        this.setState({
                            slt_device: dataSource.filter(item1 => item1._id !== item._id),
                            selectedRowKeys: selected.filter(item1 => item1 !== index)
                        });
                    }} />
            }
        }
        ];
        let step1 = <div className="paramDiv">
            <Title level={3}>选择设备</Title>
            <div style={{ fontWeight: 600, color: 'black', paddingLeft: 12 }}>已选设备</div>
            <Table
                columns={selDevice}
                dataSource={this.state.slt_device}
                size='small'
                scroll={{ x: 1200 }}
                bordered={false} />
            <hr style={{ border: '1px solid #e2e2e2' }} />
            <Button type="primary" onClick={() => {
                this.updataResources(10, 1, {});
                this.setState({ visible_device: true })
            }}>添加设备</Button>
            <Button type="primary" style={{ marginLeft: 8 }} onClick={() => {
                const current = this.state.current + 1;//设置步骤计数
                this.setState({ current });
            }}>下一步</Button>
        </div>;
        console.log(slt_oper);
        let step2 = <div className="paramDiv" >
            <Title level={3}>编辑参数</Title>
            <ScriptModal
                isOut={true}
                InScript={slt_oper.operationIn.script}
                operationExit={slt_oper.operationExit}
                OutScript={slt_oper.operationOut.script}
                getThis={(_this) => { this.fromThis = _this }}
            />
            <hr style={{ border: '1px solid #e2e2e2' }} />
            {current === 0 ? '' : <Button onClick={() => {
                const current = this.state.current - 1;
                this.setState({ current });
            }}>上一步</Button>}
            <Button type="primary" style={{ marginLeft: 8 }}
                onClick={this.finishModal}>完成</Button>
        </div>;
        let step3 = <Spin tip="测试中，等待结果..." spinning={isLoading}>
            <div style={{ textAlign: "left" }}>
                <Collapse accordion>
                    {
                        () => {
                            if (!resultInfo) {
                                return;
                            }
                            let data = resultInfo.data;
                            let elem = [];
                            for (let lab in data) {
                                elem.push(
                                    <Collapse.Panel header={lab}>
                                        <MonacoEditor
                                            width="99%"
                                            height="600"
                                            language="java"
                                            theme="vs-dark"
                                            value={data[lab]}
                                            options={{ selectOnLineNumbers: true }}
                                        />
                                    </Collapse.Panel>
                                );
                            }
                            console.log(elem)
                            return elem
                        }
                    }
                </Collapse>
                {/* <MonacoEditor
                    width="99%"
                    height="500"
                    language="json"
                    theme="vs-dark"
                    value={resultInfo}
                    options={{ selectOnLineNumbers: true }}
                /> */}
            </div>
            {/* <TextArea style={{ minHeight: 400 }} value={resultInfo}>
            </TextArea> */}
        </Spin>;

        return (
            <div >
                <div
                    className="steps-content"
                >
                    {
                        (current === 0 && slt_oper && slt_oper.operationIn.select && (slt_oper.operationIn.select.includes('resource'))) ?
                            step1 : (current === 1 || current === 0 ? step2 : this.getSetup3())
                    }
                </div>

                <Modal
                    title="选择设备"
                    style={{ top: 10 }}
                    visible={this.state.visible_device}
                    onOk={(e) => {
                        this.setState({ visible_device: false, });
                    }}
                    width="90%"
                    onCancel={(e) => { this.setState({ visible_device: false, }); }}
                    okText="确认"
                    cancelText="取消"
                >
                    {/* <Search
                        placeholder="输入IP"
                        onSearch={
                            (value) => {
                                console.log(value);
                                if (value.trim() == '') {
                                    this.updataResources(10, 1, {});
                                    return;
                                }
                                this.updataResources(10, 1, { resourceName: value });
                            }
                        }
                        enterButton={<Button type="primary" icon="search">搜索</Button>}
                    /> */}
                    {/* <FilterForm
                        tagClass="resource"
                        filterSubmit={(querys) => {
                            this.updataResources(10000, 1, querys);
                        }}
                        resetTab={() => {
                            this.updataResources(20, 1, {});
                        }}
                    />
                    <Table
                        rowSelection={rowDevice}
                        rowKey={record => record._id} //防止分页勾选错误
                        columns={columnsDevice}
                        dataSource={this.state.deviceData}
                        size='small'
                        bordered={false}
                        pagination={{
                            current: deviceCurrent, pageSize: 10, total: this.state.deviceTotal,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                this.updataResources(pageSize, page, {});
                            }
                        }}
                    /> */}
                    <SelectDevice
                        onChange={(selectedRowKeys2, selectedRows) => {
                            console.log(selectedRowKeys, selectedRows);
                            // this.setState({ addselectedRowKeys: selectedRowKeys, selectedRows });
                            this.setState({ slt_device: selectedRows, selectedRowKeys: selectedRowKeys2 });
                        }}
                        selectedRowKeys={selectedRowKeys}
                    />
                </Modal>
            </div>
        );
    }
}

export default TestOperating;