/**
 * 系统前后端API配置 模拟器
 */
// import { getSysConfig } from '@/utils/func'
const tempAddress = sys_config.logCollectRule
const Version = "/"
const BASEURL = "http://" + tempAddress + Version
export function getAllRule() { //增加、删除、修改、根据dataSourceId查询数据源配置
    return BASEURL + "getAllRule";
}
export function addRule() {
    return BASEURL + "addRule";
}
export function deleteRule() {
    return BASEURL + "deleteRule";
}
export function updateRule() {
    return BASEURL + "updateRule";
} 