import React from 'react'
import { Button, Card, Col, Divider, Form, Input, message, Modal, Row, Tree } from "antd";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import Axios from "../../axios/axios";
import axios from "axios";
React.$http_ip = axios.create({ baseURL: "http://" + ip_address })
const { TreeNode } = Tree;
const { confirm } = Modal;

/* 表单相关 */
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
const formTailLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8, offset: 4 },
};
/* /表单相关 */

let FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];

class IpFunction extends React.Component {

    state = {
        treeData: [],
        treeArr: [],//
        expandedKeysArr: [],
        createdTreeArr: [],
        oneDetail: {},//点击节点，显示数据
        visible: false,//编辑弹出框可见性
        visible2: false,//删除的提示框
        editId: null,
        editType: '新建',//弹出框是编辑还是新建
        newOne: {},//创建时用
    }

    constructor(props) {
        super(props)
    }

    componentWillMount() {
        // FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
        this.updata();
    }

    editorDidMount(editor, monaco) {
        console.log('editorDidMount', editor);
    }

    onChange(newValue, e) {
    }

    updata() {
        Axios.GET("ip/organizationTree/", {}, (res) => {
            this.setState({
                treeData: res.data.data,
            })
            console.log(this.state.treeData)
        })
        this.props.form.resetFields()
    }

    /* 生成树 */
    renderTree = jsonTree => jsonTree.map(value => {
        if (value.children) {
            return (
                <TreeNode title={value.name} key={value.id}>
                    {this.renderTree(value.children)}
                </TreeNode>
            )
        }
    })

    // 将树变成数组
    createTree = data => {
        let treeArr = [];
        let roots = data.filter(elemt => elemt.pid === 0);
        treeArr.push(...roots);

        const getChildren = (resultArr, data) => {
            resultArr.map((item, index) => {
                item.children = data.filter((element, index) => element.pid === item.id)
                if (item.children.length > 0) {
                    getChildren(item.children)
                }
            })
        }
        getChildren(treeArr, data);
        this.setState({ treeArr })
    }

    /* 点击树节点后的方法*/
    onSelect = async key => {
        console.log(typeof key, key)
        if (key === undefined || key === '' || key.length === 0) {
            this.setState({ oneDetail: {} })
            return
        }
        const { data: res, status } = await React.$http_ip.get('ip/organization/' + key + '/')
        if (status !== 200) return message.error('获取数据失败')
        this.setState({ oneDetail: res, editId: key })
    };

    /* 显示编辑弹出框 */
    showModal = (type) => {
        let editOne = this.state.oneDetail;
        if (type === '编辑') {
            this.props.form.setFieldsValue(
                {
                    name: editOne.name,
                    address: editOne.address,
                    contact: editOne.contact,
                    telephone: editOne.telephone
                }
            )
        }
        this.setState({ visible: true, editType: type })
    }

    handleOk = () => {
        const form = this.props.form
        form.validateFields(['name', 'address', 'contact', 'telephone'], async (err, values) => {
            if (err) return
            let body = { ...values }
            const { editId } = this.state
            this.setState({ newOne: { ...body } })
            let _this = this;
            if (this.state.editType === '新建') {
                body['pid'] = editId ? editId.toString() : ''
                const { data: res, status } = await React.$http_ip.post('ip/organization/', body)
                const res_ = JSON.parse(res)
                if (status !== 200 || res_.statusCode === '404') return message.error('新建失败')
                message.success("新建节点成功")
            } else if (this.state.editType === '编辑') {
                if (!editId) return
                body['id'] = editId.toString()
                const { data: res, status } = await React.$http_ip.put('ip/organization/' + editId + '/', body)
                const res_ = JSON.parse(res)
                if (status !== 200 || res_.statusCode === '404') return message.error('修改失败')
                message.success("修改节点成功")
            } else if (this.state.editType === '新建根节点') {
                // 新建根节点和新建的区别，新建根节点没有pid和id
                console.log(JSON.stringify(body))
                const { data: res, status } = await React.$http_ip.post('ip/organization/', body)
                const res_ = JSON.parse(res)
                if (status !== 200 || res_.statusCode === '404') return message.error('新建根节点失败')
                message.success('新建跟节点成功')
            }
            this.setState({ visible: false });
            this.props.form.resetFields()
            _this.updata()
            _this.onSelect(editId).then(r => { })
        });
    };

    handleCancel = () => {
        this.setState({ visible: false, editOne: {} })
        this.props.form.resetFields()
    };

    /* 删除确认 */
    delConfirm = async () => {
        let _this = this
        const { data: res, status } = await React.$http_ip.delete('ip/organization/' + this.state.editId + '/')
        if (status !== 204) return message.error('删除失败')
        _this.updata()
        this.setState({ oneDetail: {} })
        message.success('删除成功')
    };

    /* 表单提交 */
    handleSubmit = e => {

    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            //ip function is the same as the organizationTree
            <div className={'ip-function'}>
                <CustomBreadcrumb arr={[{ title: '首页', to: '/home' }, "功能类管理", "架构图"]} />
                <Row>
                    {/*功能类树图*/}
                    {/* <Col span={12}> */}
                    <Card title={<span style={{ fontWeight: 600 }}>组织区域</span>} style={{ margin: "8px 10px", height: '50em' }}>
                        {this.state.treeData && this.state.treeData.length ?
                            <Tree
                                style={{ overflow: 'auto', margin: "0 10px" }}
                                defaultExpandAll={true}
                                // onSelect={this.onSelect}
                                showLine={true}>
                                {this.renderTree(this.state.treeData)}
                            </Tree> : null}
                    </Card>
                    {/* </Col> */}

                    {/*详细信息显示*/}
                    {/* <Col span={12}>
                        <Card title={<span style={{ fontWeight: 600 }}>详情</span>} style={{ marginTop: 8, height: '50em' }}>
                            <p>名 称：{this.state.oneDetail.name ? this.state.oneDetail.name :
                                <span style={{ color: 'red', }}>点击左边选择要操作的节点</span>}</p>
                            <Divider />
                            <p>联 系 人：{this.state.oneDetail.contact ? this.state.oneDetail.contact : ''}</p>
                            <Divider />
                            <p>联系电话：{this.state.oneDetail.telephone ? this.state.oneDetail.telephone : ''}</p>
                            <Divider />
                            <p>地 址：{this.state.oneDetail.address ? this.state.oneDetail.address : ''}</p>
                            <Divider />
                            {this.state.oneDetail.name ?
                                (<div>
                                    <Button type={'primary'} style={{marginRight: "10px"}} onClick={this.showModal.bind(this, '新建')}>新建</Button>
                                    <Button type={'primary'} style={{marginRight: "10px"}} onClick={this.showModal.bind(this, '编辑')}>编辑</Button>
                                    <Button type={'primary'} style={{marginRight: "10px"}}
                                        onClick={this.showModal.bind(this, '新建根节点')}>新建根节点</Button>
                                    <Button type='danger' style={{marginRight: "10px"}} onClick={
                                        () => {
                                            const _this = this
                                            confirm({
                                                title: '确定要删除此节点吗？',
                                                content: '此操作将删除该节点下所有子节点以及涉及的网段和IP',
                                                onOk: () => {
                                                    return new Promise((resolve, reject) => {
                                                        _this.delConfirm().then(r => {
                                                        })
                                                        resolve()
                                                    }).catch(() => console.log('Oops errors!'));
                                                },
                                                onCancel() {
                                                },
                                                okText: '确认删除',
                                                okType: 'danger',
                                            })

                                        }
                                    }>删除此节点</Button></div>)
                                :
                                <Button type={'primary'} onClick={this.showModal.bind(this, '新建根节点')}>新建根节点</Button>
                            }
                        </Card>
                    </Col> */}
                </Row>

                <div>
                    <Modal
                        title={this.state.editType}
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item label={'节点名称'} {...formItemLayout}>
                                {
                                    getFieldDecorator('name', {
                                        rules: [
                                            { required: true, message: '组织名称不可为空!' },
                                            { max: 50, message: '组织名称最长为50个字符' }
                                        ]
                                    })(<Input autoFocus={true} allowClear={true} placeholder={'请填写节点名称'} />)

                                }
                            </Form.Item>
                            <Form.Item label={'地址'} {...formItemLayout}>
                                {
                                    getFieldDecorator('address', {
                                        rules: [
                                            { max: 255, message: '最长不得超过255个字符' },
                                            { required: false }
                                        ]
                                    })(<Input allowClear={true} placeholder={'请填写节点地址'} />)

                                }
                            </Form.Item>
                            <Form.Item label={'联系人'} {...formItemLayout}>
                                {
                                    getFieldDecorator('contact', {
                                        rules: [
                                            { max: 50, message: '字符数不得超过50' },
                                            { required: false }
                                        ]
                                    })(<Input allowClear={true} placeholder={'请填写节点联系人'} />)

                                }
                            </Form.Item>
                            <Form.Item label={'联系电话'} {...formItemLayout}>
                                {
                                    getFieldDecorator('telephone', {
                                        rules: [
                                            { max: 40, message: '字符数不得超过40' },
                                            { required: false }
                                        ]
                                    })(<Input allowClear={true} placeholder={'请填写联系人电话'} />)

                                }
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </div>
        )
    }
}

const ipFunction = Form.create()(IpFunction)
export default ipFunction
