import React from 'react'
import './index.scss'
import {
    Card, Table, Button, Icon, message, notification, Row, Col, Modal, Menu, Dropdown, Descriptions,
    Badge, Tooltip, Collapse, Select, Checkbox, Divider,
} from "antd";
import socket from "../../../socket/index";
import Utils from "../../../utils/utils";
import BaseForm from "@/components/BaseForm/index";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import DyHeadTable from '@/components/DyHeadTable/index';
import LineChart from '@/components/G2LineChar/index';
import AuditPage from '../audit/index';
import FileModal from './readFile';
import ExportButton from '@/components/ExportButton/index';
import CascOrgain from '@/components/CascOrgain/index';
import AxiosObj from '@/axios/index';
import FormModal from '@/components/EditModal/index';
import axios from 'axios';
import "animate.css";
const { confirm } = Modal;
const resource_url = "http://" + backup_address + "/ZJNMS_V1/";
class Home extends React.Component {
    state = {
        _current: 1,      //当前页码数
        _pageSize: 20,    //每页显示条数
        querys: {},    //查询条件
        total: 0,
        loading: false,
        visible_conn: false,
        selectedRowKeys: [],
        selectedDevs: [],
        FileVis: false,
        deleteVis: false,
        ImportVis: false,
        reportModal: false,
        detailLoading: false,
        detailData: { reason: [] },
        fileName: "",
        resItem: {},
        visible_time: false,
        fold: false,
        settingVis: false,
        paramsSetting: {
            offlineAlert: false,
            deviceVersion: "",
            deviceFirm: "",
            tags: []
        },
        checkParam: [],
        permission:{},
    };
    componentWillMount() {
        let bodyJson = this.props.location.bodyJson || {};
        this.updata(bodyJson.pageSize, bodyJson.pageNum, bodyJson.querys);

        const permission_btn = JSON.parse(sessionStorage.getItem('FixedBtn'));
        const conn =permission_btn.includes("/connResource")
        const add = permission_btn.includes("/newResource")
        const del = permission_btn.includes("/delResource")
        const monitor = permission_btn.includes("/monitorResource")
        this.setState({ permission_btn,permission:{
            conn,
            del,
            monitor,
            add
        }})
    }
    updata = (pageSize, pageNum, _querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current, querys } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = _querys || querys;
        socket.ExecuteAction('QueryResourcesPage',
            bodyJson, "/queryResource", (result) => {
                let Resources = result.resources;
                Resources.map((item, index) => {
                    item.key = index;
                    item.index = index;
                    return item.id
                });
                this.setState({
                    tableData: Resources,
                    _current: pageNum || 1,
                    _pageSize: pageSize || 20,
                    total: result.total,
                    loading: false,
                    querys: bodyJson.querys
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };
    getDetailData = (resItem) => {
        socket.ExecuteAction('QueryWarnDetail',
            { id: resItem._id }, "/QueryWarnDetail", (result) => {
                this.setState({
                    detailData: result.data,
                    reportModal: true
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };
    delete = (ids) => {
        this.setState({ loading: true, });
        const deleteData1 = this.state.tableData.filter((item) => ids.includes(item._id))
        const deleteData = deleteData1.map((i) => {
            delete i["updateRecord"]
            return i
        })
        let { _pageSize, _current } = this.state;
        socket.ExecuteAction("DeleteResource", { _id: ids, deleteData }, "/delResource", () => {
            this.setState({ visible: false, loading: false, selectedRowKeys: [] });
            this.updata(_pageSize, _current);
            message.success('删除成功');
        }, (error) => {
            message.error('删除失败' + error);
        });
    };
    handleMenuClick = (e, item) => {
        this.setState({ resItem: item });
        let _this = this;
        const { _current, _pageSize, querys } = this.state
        let bodyJson = {
            pageNum: _current,      //当前页码数
            pageSize: _pageSize,    //每页显示条数
            querys,    //查询条件
        }
        switch (e.key) {
            case 'conn':
                // let urlTemp = socket.getDeviceSSHUrl(item.resourceParams.deviceUser,
                //     Utils.StrToBase64(Utils.DecryptPwd(item.resourceParams.devicePwd)), item.resourceParams.deviceIp);
                let urlTemp = socket.getDeviceSSHUrl(item.resourceParams.deviceUser,
                    item.resourceParams.devicePwd, item.resourceParams.deviceIp);
                window.open(urlTemp, '_blank', "toolbar=yes, location=no, directories=no, status=no, menubar=yes, scrollbars=yes,"
                    + "resizable=no, copyhistory=yes, width=700, height=600");
                break;
            case 'edit':
                this.props.history.push({
                    pathname: `/newResource:${item._id}`,
                    bodyJson,
                });
                break;
            case 'view':
                this.props.history.push({
                    pathname: `/resourceDetail:${item._id}`,
                    bodyJson,
                    item
                });
                break;
            case 'del':
                confirm({
                    title: '消息提示',
                    content: '你确定删除该条记录吗？',
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        _this.delete([item._id]);
                    },
                    onCancel() { },
                });
                break;
            case 'report': this.reportDetail(item); break;
            case 'copy':
                this.props.history.push({
                    pathname: `/newResource:${item._id}&copy`,
                    bodyJson,
                });
                break;
            case 'monitor':
                sessionStorage.setItem("monitorDev", JSON.stringify(item))
                this.props.history.push({ pathname: `/resourceMonitor` });
                break;
            case 'config':
                let path2 = {
                    pathname: `/ftpFile`,
                    deviceIp: item.resourceParams.deviceIp,
                };
                this.props.history.push(path2); break;
        }
    }
    addNewResource(e) {
        switch (e.key) {
            case 'manual': this.props.history.push('/newResource'); break;
            case 'scan': this.props.history.push('/resourceAutoFind'); break;
            case 'import': this.setState({ FileVis: true }); break;
        }
    }
    reportDetail(item) {
        this.setState({ reportModal: true, resourceId: item._id })
    }
    menuButton(resItem) {
        const {permission } = this.state;
        const m_monitor = permission.monitor
        const c_monitor = permission.add
        const d_monitor = permission.del
        return <Menu onClick={(e) => this.handleMenuClick(e, resItem)}>
            {/* <Menu.Item key="report">
                <Icon type="file-protect" />
                报告
      </Menu.Item> */}
            {/* <Menu.Item key="config">
                <Icon type="file" />
                配置
      </Menu.Item> */}
            <Menu.Item key="view">
                <Icon type="read" />
                详情
            </Menu.Item>
            <Menu.Item key="edit">
                <Icon type="edit" />
                编辑
            </Menu.Item>
                <Menu.Item key="monitor" disabled = {!m_monitor}>
                    <Icon type="monitor" />
                    监控
            </Menu.Item>
                <Menu.Item key="copy" disabled = {!c_monitor}>
                    <Icon type="copy" />
                    复制
            </Menu.Item>
                <Menu.Item key="del" disabled = {!d_monitor}>
                    <Icon type="delete" />
                    删除
            </Menu.Item>
        </Menu>
    }
    getTaskData() {
        socket.ExecuteAction('QueryTaskDetail', { _id: "5e7af6a7d58ed4000662f0ca" }, "", (resp) => {
            let taskData = resp.task;
            this.setState({ taskData });
        }, () => {
        });
    }
    updataTaskData(plandata) {
        let { selectedDevs, taskData } = this.state;
        let newdata = taskData;
        newdata.taskPlan.content = plandata;
        let gruopData = newdata.taskContent.nodes["1585029213380"].taskdata.input.script.group;
        let newGroData = selectedDevs.map((item, key) => {
            return { key, subtask: gruopData[0]["subtask"], resource: item.resourceName }
        })
        newdata.taskContent.nodes["1585029213380"].taskdata.input.script.group = newGroData;
        socket.ExecuteAction('UpdateTask', newdata, "", () => {
            message.success("操作成功!")
            this.setState({ visible_time: false })
        }, (error) => {
            message.success("操作失败!" + error)
        });

    }
    actionOrgin(deviceIds, areaName) {
        const querys = this.state.querys || {};
        const areaId2 = querys.depId;
        const areaId = this.state.areaId;
        let flag = false;
        if (areaId2 === "1") {
            flag = true;
        }
        socket.ExecuteAction('ResourcesAreaChange', { areaName, deviceIds, areaId, flag }, "", () => {
            message.success("操作成功!");
            this.setState({ selectedRowKeys: [], selectedDevs: [] });
            this.updata(this.state._pageSize, 1, querys);
        }, (error) => {
            message.error("操作失败!" + error)
        });
    }
    editOrgin(columns) {
        const { selectedRowKeys, selectedDevs, } = this.state;
        const _this = this;
        if (Utils.isEmpty(selectedRowKeys)) {
            message.error("请勾选设备！");
            return;
        } else {
            let orgName = null;
            confirm({
                title: "请选择划分的区域",
                width: "50%",
                maskClosable: true,
                icon: "bell",
                onOk() {
                    if (orgName) {
                        _this.actionOrgin(selectedRowKeys, orgName);
                    } else { message.error("请选择区域！") }
                },
                content: <div><p /><p />选择区域
                    <CascOrgain type="add" value={[]} onChange={(val, option) => {
                        orgName = option[option.length - 1]["label"];
                        _this.setState({ areaId: option[option.length - 1]["value"] });
                    }} />已选设备
                    <Table
                        columns={columns.filter((item, key) => { return key < 3 })}
                        dataSource={selectedDevs}
                        size={"small"}
                        scroll={{ y: 500 }}
                        pagination={false}
                    />
                </div>
            })
        }
    }
    getDevInfo() {
        const { selectedRowKeys, selectedDevs, } = this.state;
        if (Utils.isEmpty(selectedRowKeys)) {
            message.error("请勾选需要导出最新配置的设备！");
            return;
        } else {
            this.setState({ loading: true });
            let ips = selectedDevs.map((i) => { return i.resourceParams.deviceIp })
            // window.open(resource_url + "check/export_cfg_zip?data=" + JSON.stringify(ips));
            axios({
                method: "GET",
                url: resource_url + "check/export_cfg_zip",
                params: { data: JSON.stringify(ips) },
                headers: {
                    "Content-Type": 'application/json;charset=UTF-8'
                },
                responseType: 'blob'
            }).then((res) => {
                // console.log(res)
                let blob = new Blob([res.data], { type: "application/zip" })
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob);
                link.download = "device_config"
                document.body.appendChild(link)
                link.click();
                document.body.removeChild(link);
                this.setState({ loading: false });
                message.success("导出成功")
            })
        }
    }
    UpdateResourcePwd(querys) {
        let bodyJson = querys;
        bodyJson["deviceList"] = this.state.selectedRowKeys;
        bodyJson["newPwd"] = Utils.EncryptPwd(querys["newPwd"]);
        socket.ExecuteAction("UpdateResourcePwd", bodyJson, "/UpdateResourcePwd",
            () => { message.success("更新成功！"); this.updata() });
    }
    updateSetting(val) {
        let { selectedRowKeys, checkParam } = this.state
        let bodyJson = val;
        bodyJson["_ids"] = selectedRowKeys;
        if (!checkParam.includes("snmpMonitor")) delete bodyJson.monitor["snmpMonitor"];
        if (!checkParam.includes("offlineAlert")) delete bodyJson.monitor["offlineAlert"];
        if (!checkParam.includes("offlineAlert") && !checkParam.includes("snmpMonitor")) delete bodyJson["monitor"];
        if (!checkParam.includes("tags")) delete bodyJson["tags"];
        if (!checkParam.includes("deviceFirm")) delete bodyJson["deviceFirm"];
        if (!checkParam.includes("deviceVersion")) delete bodyJson["deviceVersion"];
        if (checkParam.includes("passwordChange")) {
            if(!bodyJson.passwordChange.newUserName || !bodyJson.passwordChange.newPwd) {
                return message.error("请填写新用户名和密码");
            } else {
                return this.UpdateResourcePwd(bodyJson.passwordChange)
            }
        } else {
            delete bodyJson["passwordChange"]
        };
        socket.ExecuteAction("BatchUpdateResource", bodyJson, "/BatchUpdateResource",
            () => {
                message.success("更新成功！");
                this.setState({ settingVis: false, checkParam: [] })
                this.updata();
            });
    }
    changePassword() {
        const { selectedRowKeys, } = this.state;
        const _this = this;
        if (Utils.isEmpty(selectedRowKeys)) {
            message.error("请勾选需要更改密码的设备！");
            return;
        } else {
            Modal.info({
                title: "请填写新用户名和密码，点击空白处关闭弹窗",
                width: "50%",
                maskClosable: true,
                icon: "file-text",
                okText: "确定",
                onOk() {
                    _this.baseForm.props.form.validateFields((err, values) => {
                        if (!err) {
                            _this.UpdateResourcePwd(values)
                        } else {
                            for (let key in err) {
                                message.error(err[key]["errors"][0]["message"])
                            }
                        }
                    })
                },
                content: <BaseForm formList={[{
                    label: "用户名",
                    rules: [{ required: true, message: '请输入用户名!', }],
                    field: "newUserName",
                    type: 'INPUT',
                }, {
                    label: "密码",
                    field: "newPwd",
                    rules: [{ required: true, message: '请输入密码!', }],
                    type: 'INPUT',
                },]}
                    refThis={(e) => { _this.baseForm = e }} />,
            })
        }
    }
    updataTemplates = (columns) => {
        AxiosObj.HTTP_POST("acl", "aclTemplates", {}, (result) => {
            this.setState({ Templates: result.data });
            this.setACL(columns, result.data);
        });
    }
    setAclInterface = () => {
        const { selectedDevs, template_name } = this.state;
        AxiosObj.HTTP_POST("acl", "devicesConnAclTemplate", {
            "ips": selectedDevs.map((item) => (item.resourceParams.deviceIp)),
            "template_name": template_name
        }, (result) => { message.success(result.msg) });
    }
    setACL(columns, Templates) {

        const { selectedRowKeys, selectedDevs, } = this.state;
        const _this = this;
        if (Utils.isEmpty(selectedRowKeys)) {
            message.error("请勾选设备！");
            return;
        } else {
            confirm({
                title: "请选择设置的ACL",
                width: "80%",
                maskClosable: true,
                icon: "file-text",
                onOk() { _this.setAclInterface() },
                content: <div><p />选择ACL基线&nbsp;
                    <Select style={{ width: 300 }}
                        // defaultValue={Templates && Templates[0].template_name}
                        onChange={(val) => { this.setState({ template_name: val }) }}
                    >
                        {Templates && Templates.map((temp, index) => (
                            <Select.Option key={index} value={temp.template_name}>{temp.template_name}</Select.Option>
                        ))}
                    </Select><p />已选设备
                    <Table
                        columns={columns.filter((item, key) => { return key < 7 })}
                        dataSource={selectedDevs}
                        size={"small"}
                        scroll={{ y: 500 }}
                        pagination={false}
                    />
                </div>
            })
        }
    }
    mutilDelete() {
        const { selectedRowKeys } = this.state;
        const _this = this;
        if (Utils.isEmpty(selectedRowKeys)) {
            message.error("请勾选设备！");
            return;
        }
        confirm({
            title: "消息提示",
            maskClosable: true,
            onOk() { _this.delete(selectedRowKeys) },
            content: <div>你确定删除选中资源吗？<br />该操作不可恢复</div>
        })
    }
    render() {
        let { _current, _pageSize, querys, FileVis, fold, settingVis, paramsSetting, tableData, checkParam, deleteVis,permission } = this.state;
        const columns = [
            {
                title: '资源名称',
                align: 'left',
                ellipsis: true,
                width: 130,
                dataIndex: 'resourceName'
            },
            {
                title: '设备IP',
                align: 'left',
                ellipsis: true,
                width: 130,
                dataIndex: 'resourceParams.deviceIp',
                render: (text, item) => {
                    let sta = "default";
                    let title = "状态未知";
                    if (item.resourceParams.deviceState === "online") {
                        sta = "success"; title = "在线";
                    } else if (item.resourceParams.deviceState === "offline") {
                        sta = "error"; title = "离线";
                    }
                    return <div><Tooltip title={title}><Badge status={sta} />
                        <Button style={{ padding: 0, fontWeight: 495 }} size="small" type="link" onClick={() => { this.handleMenuClick({ key: "view" }, item) }}>{text}</Button>
                    </Tooltip></div>
                }
            },
        ];
        const params = [
            ["设备厂商", "resourceParams.deviceFirm", 100, "left"],
            ["设备名称", "resourceParams.deviceId", 150],
            ["设备类型", "resourceParams.deviceType"],
            ["设备型号", "resourceParams.deviceVersion", 150],
            ["设备OS版本", "resourceParams.deviceOsVersion", 200],
            ["设备OS类型", "resourceParams.deviceOsType"],
            ["SNMP口令 ", "resourceParams.community"],
            ["设备状态", "resourceParams.deviceState"],
            ["网点信息", "resourceParams.deviceSite", 200],
            ["资源描述", "resourceDes", 200],
        ];
        const company = ["CISCO", "HUAWEI", "H3C", "RUIJIE", "MAIPU", "HILLSTONE", "JUNIPER"];
        params.map((list) => {
            columns.push({
                title: list[0],
                align: list[3] || 'center',
                width: list[2] || 100,
                ellipsis: true,
                dataIndex: list[1],
                render: (text) => {
                    if (list[0] === "设备厂商") {
                        // let one = <Icon type="file-unknown" />;
                        let one = "";
                        if (company.includes(text.toUpperCase())) {
                            one = <img src={"/image/" + text.toLowerCase() + ".jpg"} className={"imgstyle"} />;
                        }
                        text = <div>{one}&nbsp;{text.toUpperCase()}</div>;
                    }
                    let elemt = <div>{text}</div>;
                    if (list[0] === "设备类型") {
                        elemt = <div>{Utils.Translation(text)}</div>;
                    }
                    // let elemt = <div>{Utils.Translation(text)}</div>;
                    if (list[0] === "设备型号" || list[0] === "设备OS版本") {
                        elemt = <Tooltip title={text} placement="topLeft">{text}</Tooltip>
                    }
                    return elemt;
                }
            });
        })
        columns.push({
            title: '标签',
            dataIndex: 'tags',
            width: 200,
            align: "center",
            render: (text) => {
                return Utils.hidenTag(text);
            }
        });
        columns.push({
            title: '离线告警',
            dataIndex: 'monitor',
            align: "center",
            render: (text) => {
                if (text && text.offlineAlert === "true") return <Tooltip title={"离线告警"}><Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /></Tooltip>
                else return <Tooltip title={"离线不告警"}><Icon type="close-circle" /></Tooltip>
            }
        });
        columns.push({
            title: 'SNMP监控',
            dataIndex: 'snmpMonitor',
            width: 100,
            align: "center",
            render: (text,item) => {
                if(item.monitor && item.monitor.snmpMonitor === 'true') return <Tooltip title={"已加入"}><Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /></Tooltip>
                else return <Tooltip title={"未加入"}><Icon type="close-circle" /></Tooltip>
            }
        });
        columns.push({
            title: '创建用户',
            dataIndex: 'createUserId',
            width: 120,
            ellipsis: true,
            align: "center"
        });
        columns.push({
            title: '操作',
            flag: true,
            align: 'center',
            width: 120,
            dataIndex: "op",
            fixed: "right",
            render: (text, item) => {
                let b_data = {
                    text: "详情",
                    icon: "read",
                    key:"view"
                }
                if (this.state.permission.conn) {
                    b_data = {
                        text: "连接",
                        icon: "login",
                        key:"conn"
                    }
                }
                return <div>
                    <Dropdown.Button overlay={this.menuButton(item)}
                        type="primary" icon={<Icon type="down" />} size="small"
                        onClick={() => { this.handleMenuClick({ key: b_data.key }, item) }}
                    ><Icon type={b_data.icon} />{b_data.text}
                    </Dropdown.Button>
                </div>
            }
        });

        const modalParam = {
            title: <div>上传资源列表</div>,
            APIName: "UploadResources",
        }
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys, selectedDevs: selectedRows });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        return (
            <div className="resourceDiv">
                <CustomBreadcrumb arr={["资源管理", "设备列表"]} />
                <Collapse defaultActiveKey={"key"}>
                    <Collapse.Panel header="筛选条件" key={"key"}>
                        <FilterForm
                            tagClass="resource"
                            filterSubmit={(querys) => {
                                this.setState({ querys })
                                this.updata(this.state._pageSize, 1, querys)
                            }}
                            resetTab={() => {
                                this.setState({ querys: {} })
                                this.updata(this.state._pageSize, 1, {})
                            }}
                        />
                    </Collapse.Panel>
                </Collapse>
                <Card title={<span style={{ fontWeight: 600, }}>设备列表</span>}
                    style={{ marginTop: 8, paddingTop: 0 }}
                    extra={<div>
                        {/* <Button type="primary"
                            icon="file-text" onClick={() => { this.updataTemplates(columns); }}>设置ACL基线</Button> */}
                        {/* <Button type="primary" icon="lock" onClick={() => { this.changePassword() }}>密码变更</Button> */}
                        <Button type="primary" icon="snippets" onClick={() => { this.setState({ deleteVis: true }) }}>设备记录</Button>
                        {/* <Button type="primary"
                            icon="export" onClick={() => { this.getDevInfo() }}>导出设备最新配置</Button> */}
                        <Button type="primary" icon="star" onClick={() => { this.editOrgin(columns) }}>区域划分</Button>
                        <Button type="primary"
                            icon="setting" onClick={() => {
                                if (Utils.isEmpty(this.state.selectedRowKeys)) {
                                    message.error("请勾选设备！");
                                    return;
                                }
                                this.setState({ settingVis: true })
                            }}>参数设置</Button>
                        <ExportButton APIName="DownloadResources" selectedRowKeys={this.state.selectedRowKeys} />
                        {/* <Button type="primary"
                            disabled={!permission.add}
                            icon="edit" onClick={() => { this.props.history.push('/newResource') }}>新建资源</Button> */}

                        <Dropdown style={{ marginRight: 8 }} disabled={!permission.add}
                            overlay={
                                <Menu onClick={(e) => { this.addNewResource(e) }}>
                                    <Menu.Item  key="manual"> <Icon type="edit" />手动新增 </Menu.Item>
                                    <Menu.Item  key="scan"> <Icon type="scan" />扫描新增 </Menu.Item>
                                    <Menu.Item  key="import"> <Icon type="import" />批量导入 </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button type="primary">
                                <Icon type="edit" />新建资源 <Icon type="down" />
                            </Button>
                        </Dropdown>
                        <Button type="danger" disabled={!permission.del} ghost icon="delete" onClick={() => { this.mutilDelete() }}>删除</Button>
                    </div>}>
                    <div style={{ border: "1px solid #e2e2e2", borderRadius: 2 }}>

                        <Row>
                            <Col span={fold ? 1 : 4} >
                                <div className="CascOrgainAutoScrll">
                                    <CascOrgain style={{ width: 300 }} onChange={(value) => {

                                        let { _pageSize, querys } = this.state;
                                        const selectID = value[value.length - 1];
                                        if (selectID) {
                                            querys = { depId: selectID };
                                        }
                                        this.updata(_pageSize, 1, querys);
                                        this.setState({ querys });
                                    }} foldChange={(fold) => {
                                        this.setState({ fold });
                                    }}
                                    />
                                </div>
                            </Col>
                            <Col span={fold ? 23 : 20} style={{ borderLeft: "1px solid #e2e2e2", }}>
                                <DyHeadTable
                                    type="resource"
                                    dataSource={tableData}
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    loading={this.state.loading}
                                    rowKey={(text) => { return text._id }}
                                    size="small"
                                    bordered={false}
                                    pagination={{
                                        current: _current, pageSize: _pageSize, total: this.state.total,
                                        onChange: (page, pageSize) => {
                                            this.updata(pageSize, page, querys);
                                        }
                                    }}
                                    // onRow={record => {
                                    //     return {
                                    //         onDoubleClick: () => {
                                    //             confirm({
                                    //                 content: <div className="resInfoDiv"><Descriptions bordered title="设备基本信息" size="small">
                                    //                     {columns.map((rec, m) => {
                                    //                         if (rec.dataIndex === "op") { return } else if (rec.dataIndex === "tags") {
                                    //                             return <Descriptions.Item key="tag" label="标签">{
                                    //                                 Utils.hidenTag(record["tags"])
                                    //                             }</Descriptions.Item>
                                    //                         }
                                    //                         const dataIndex = rec["dataIndex"].split(".");
                                    //                         let val = "";
                                    //                         if (dataIndex.length > 1) {
                                    //                             const temp = record[dataIndex[0]];
                                    //                             val = temp[dataIndex[1]];
                                    //                         } else { val = record[dataIndex]; }
                                    //                         return <Descriptions.Item key={m} label={rec["title"]}>{Utils.Translation(val)}</Descriptions.Item>
                                    //                     })}
                                    //                 </Descriptions>
                                    //                 </div>,
                                    //                 maskClosable: true,
                                    //                 width: "70%",
                                    //             })
                                    //         }, // 点击行
                                    //     };
                                    // }}
                                />
                            </Col>
                        </Row></div>
                </Card>
                <Modal
                    title="连接设备"
                    visible={this.state.visible_conn}
                    onOk={() => { this.setState({ visible_conn: false }) }}
                    onCancel={() => { this.setState({ visible_conn: false }) }}
                    footer={null}
                    style={{ top: 50 }}
                    width="90%"
                >
                    <iframe name="connIframe" style={{ height: '600px', width: '100%' }} />
                </Modal>
                <FileModal FileVis={FileVis}  {...modalParam}
                    onCancel={() => { this.setState({ FileVis: false }); this.updata(this.state._pageSize, 1, {}) }} />
                <FormModal
                    title={<div>参数配置<Divider />
                        <Checkbox.Group style={{ width: '100%' }} onChange={(checkParam) => { this.setState({ checkParam }) }}>
                            <Checkbox value="snmpMonitor">snmp监控</Checkbox>
                            <Checkbox value="offlineAlert">离线告警</Checkbox>
                            <Checkbox value="deviceFirm">设备厂商</Checkbox>
                            <Checkbox value="deviceVersion">设备型号</Checkbox>
                            <Checkbox value="tags">标签</Checkbox>
                            <Checkbox value="passwordChange">密码变更</Checkbox>
                        </Checkbox.Group></div>}
                    visible={settingVis}
                    onCancel={() => this.setState({ settingVis: false, checkParam: [] })}
                    onSubmit={(val) => {
                        this.updateSetting({
                            "monitor": { "offlineAlert": val.offlineAlert + "", "snmpMonitor": val.snmpMonitor + "" },
                            "tags": val.tags,
                            "deviceFirm": val.deviceFirm,
                            "deviceVersion": val.deviceVersion,
                            "passwordChange": { newUserName: val.newUserName, newPwd: val.newPwd },
                        })
                    }}
                    footer={null}
                    width={"50%"}
                    initialValues={paramsSetting}
                    destroyOnClose
                    formList={[
                        { label: "snmp监控", name: "snmpMonitor", type: "switch", disabled: !checkParam.includes("snmpMonitor") },
                        { label: "离线告警", name: "offlineAlert", type: "switch", disabled: !checkParam.includes("offlineAlert") },
                        { label: "设备厂商", name: "deviceFirm", type: "input", disabled: !checkParam.includes("deviceFirm") },
                        { label: "设备型号", name: "deviceVersion", type: "input", disabled: !checkParam.includes("deviceVersion") },
                        { label: "标签", name: "tags", type: "tags", disabled: !checkParam.includes("tags") },
                        { label: "用户名", name: "newUserName", type: "input", disabled: !checkParam.includes("passwordChange") },
                        { label: "密码", name: "newPwd", type: "input", disabled: !checkParam.includes("passwordChange") },
                    ]}
                />
                <Modal
                    title="设备记录"
                    visible={deleteVis}
                    onCancel={() => this.setState({ deleteVis: false })}
                    onOk={() => this.setState({ deleteVis: false })}
                    width="95%"
                    style={{ top: "20px" }}
                    destroyOnClose
                >
                    <AuditPage />
                </Modal>
                {/* <ImportModal visible={ImportVis}
                    title="填写信息"
                    fileName={fileName}
                    tagClass={"resource"}
                    APIName={"UploadResources"}
                    callback={() => this.updata(this.state._pageSize, 1, {})}
                    footer={null}
                    onCancel={() => this.setState({ ImportVis: false })} /> */}
                {/* <Modal visible={visible_time}
                    width="66%"
                    title={"定时巡检设置"}
                    style={{ top: 30 }}
                    footer={null}
                    onCancel={() => this.setState({ visible_time: false })} >
                    <div>
                        <Steps direction="vertical" current={1}>
                            <Steps.Step title="已选设备" description={
                                <Table
                                    dataSource={selectedDevs}
                                    columns={columns.filter((item) => { return item.flag != true })}
                                    size="small"
                                    pagination={false}
                                />
                            } >

                            </Steps.Step>
                            <Steps.Step title="选择定时时间" description={
                                <div style={{ border: "1px solid #e2e2e2", width: "100%", padding: 20, borderRadius: 8 }}>
                                    <TimePlan initValue={null}
                                        onParamChange={(plandata) => {
                                            this.updataTaskData(plandata)
                                        }} />
                                </div>
                            } />
                        </Steps>
                    </div>

                </Modal>
                <Modal
                    title="巡检报告"
                    visible={reportModal}
                    onCancel={() => this.setState({ reportModal: false })}
                    onOk={() => this.setState({ reportModal: false })}
                    width="60%"
                    style={{ top: "20px" }}
                    destroyOnClose
                >
                    <ReportDataMal resourceId={this.state.resourceId || null} />
                </Modal> */}
            </div>
        )
    }
}


export default Home