import { Button, Modal, Form, Input, Select } from 'antd';
import React from 'react';
import axios from 'axios';

class EditNode extends React.Component {


    constructor(props) {
        super(props)
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.node !== this.props.node && nextProps.node !== null) {
            // console.log(nextProps)
            // console.log(this.props)
            const node = nextProps.node.item.getModel();
            nextProps.form.setFieldsValue({
                id: node.id,
                deviceType: node.deviceType,
                ip: node.ip,
                brand: node.brand,
                ips: node.ips.split(','),
                sysDescr: node.sysDescr,
                sysName: node.sysName
            })
        }
    }

    onOk = () => {
        let _this = this;
        _this.props.form.validateFields((err, values) => {
            if (!err) {
                values.ips = values.ips.join(',');
                axios.patch('http://' + topo_address + '/topology/threeDevice/updateNode/', values).then(function (res) {
                    _this.props.editNodeOk(values)
                })
            }
        })
    }

    onCancel = () => {
        console.log(this.props.editNodeCancel)
        this.props.editNodeCancel();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };
        const { TextArea } = Input

        return <Modal
            title="修改设备参数"
            visible={this.props.editNodeFormVisiable}
            onOk={this.onOk}
            onCancel={this.onCancel}
        >
            <Form {...formItemLayout}>
                <div style={{ display: 'none' }}>
                    <Form.Item label="id">
                        {getFieldDecorator('id', {})
                            (<Input />)}
                    </Form.Item>
                </div>
                <Form.Item label="选择设备类型">
                    {getFieldDecorator('deviceType', {
                        rules: [{ required: true, message: 'Please input the title of collection!' },],
                        initialValue: "router"
                    })(<Select>
                        <Option value="router">路由器</Option>
                        <Option value="L3 Switch">交换机</Option>
                    </Select>)}
                </Form.Item>
                <Form.Item label="IP">
                    {getFieldDecorator('ip', {
                        rules: [{ required: true, message: '必须填写一个ip' },
                        { pattern: '\^(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\.(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\.(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\.(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])$', message: '请输入合法的IP地址' },
                        { validator: this.handleInputIp }
                        ],
                        validateFirst: true
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="品牌">
                    {
                        getFieldDecorator('brand', {
                            initialValue: "CISCO"
                        })(
                            <Select>
                                <Option value="CISCO">思科</Option>
                                <Option value="H3C">H3C</Option>
                                <Option value="华为">华为</Option>
                            </Select>)
                    }
                </Form.Item>
                <Form.Item label="端口">
                    {getFieldDecorator('ips', {
                    })(<Select mode="tags" open={false}></Select>)}
                </Form.Item>
                <Form.Item label="sysDescr">
                    {getFieldDecorator('sysDescr', {})(<TextArea rows={4} />)}
                </Form.Item>
                <Form.Item label="sysName">
                    {getFieldDecorator('sysName', {})(<Input />)}
                </Form.Item>
            </Form>
        </Modal>
    }
}

const WarpEditForm = Form.create()(EditNode);
export default WarpEditForm;