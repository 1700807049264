import React from 'react'
import { Button, Card, Col, Form, Icon, Input, message, Modal, Row, Spin, Table, Tag, Upload, Collapse, Descriptions, Dropdown, Menu } from "antd";
import Axios from "../../axios/axios";
import axios from 'axios'
import base_url from '../../axios/BaseURL'
import CustomBreadcrumb from '../../components/CustomBreadcrumb/index';
import ClassificationIp from './classificationIp';
import DyHeadTable from '@/components/DyHeadTable/index';
import FilterForm from '@/components/FilterForm/index';
import Utils from "../../utils/utils";
import * as XLSX from 'xlsx';
import './index.scss';
import { addIpType, delIpType, editIpType } from './utils/index';

import { SubmitButton, FormItem, TreeSelect, Select } from "formik-antd";
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
const { Option } = Select;
import { Formik } from "formik";
import moment from 'moment';
const { Dragger } = Upload;
const versionName = sys_config.versionName;

class IpMonitor extends React.Component {

    state = {
        _current: 1,
        _pageSize: 20,
        total: 0,
        loading: false,
        tableData: null,
        visible: false,
        importVisible: false,
        formData: "",
        excelData: {},
        fileList: [],
        selectedRowKeys: [],
        recycle_fail_list: [],
        recycle_success_list: [],
        ipRecycleVis: false,
        networkVis: false,
        ipSelectOptions: [],
        monitorIpItem: {},
        classifyVis: false,
        classifyData: [],
        ipTypeVis: false,
        querys: {}
    }

    componentWillMount() {
        this.update();
        this.getIpType();
    }
    addMonitor(values) {
        let { monitorIpItem, ipSelectOptions, _current, _pageSize, querys } = this.state;
        if (values.ip_type) {
            ipSelectOptions.map(optionsItem => {
                if (optionsItem.id == values.ip_type) {
                    values.ip_type = optionsItem.name;
                }
            })
        }
        if (monitorIpItem.id) {
            Axios.PUT('ip/ipMonitor/', values, (result) => {
                if (result.status === 201 || result.status === 200 || result.status === 204) {
                    this.update(_current, _pageSize, querys);
                    this.setState({ visible: false, monitorIpItem: {} });
                    message.success("修改成功");
                } else {
                    message.error("修改失败");
                }
            })
        } else {
            Axios.POST('ip/ipMonitor/', values, (result) => {
                if (result.status === 201 || result.status === 200 || result.status === 204) {
                    this.update(1, _pageSize, {});
                    this.setState({ visible: false });
                    message.success("新增成功");
                } else {
                    message.error("新增失败");
                }
                // console.log(result)
            })
        }
    }
    deleteMonitor(item, type) {
        let that = this;
        let { _pageSize } = this.state;
        if (type === 'batch') {
            // 批量删除
            if (Utils.isEmpty(item)) { return message.error('请选择需要删除的IP！'); }
            let ids = item.map(i => { return JSON.parse(i).id })
            let options = {
                url: "ip/ipmBulkDelete/",
                data: { ids }
            };
            Modal.confirm({
                title: "提示",
                content: "你确定删除选中ip吗？",
                okType: "danger",
                onOk() {
                    that.setState({ loading: true });
                    Axios.DELETE_BODY(options, (result) => {
                        if (result.data.code === '00') {
                            that.update(1, _pageSize, {});
                            message.success("删除成功");
                        } else {
                            message.error(result.data.msg);
                        }
                        that.setState({ loading: false });
                    })
                }
            })
        } else {
            // 删除单个监控IP
            this.setState({ loading: true });
            let options = {};
            options.url = "ip/ipMonitor/" + item.id + "/";
            options.data = {};
            Axios.DELETE(options, (result) => {
                if (result.status === 201 || result.status === 200 || result.status === 204) {
                    this.update(1, _pageSize, {});
                    message.success("删除成功");
                } else {
                    message.error("删除失败");
                }
                this.setState({ loading: false });
                // console.log(result)
            })
        }
    }
    getIpType() {
        let queryStr = 'ip/ipType/'
        Axios.GET(queryStr, {}, (result) => {
            if (result.status === 200 || result.status === 201 || result.status === 204) {
                let data = result.data;
                this.setState({ ipSelectOptions: data.results });
            }
            // console.log(result.data, result)
        })
    }
    // 导入 excel 文件
    importExcel(file) {
        if (Utils.isEmpty(file.fileList)) {
            return;
        }
        let _this = this;
        let fileList = [file.file];
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file.file);
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = {};
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    let tempData = [];
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data[sheet] = tempData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    }
                }
                const excelData = data;
                // console.log(excelData);
                _this.setState({ excelData, fileList });
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                console.log(e)
                message.error('上传失败！');
            }
        }

    }
    onSubmit() {
        const { excelData } = this.state;
        const data = Object.values(excelData)[0];
        let bodyJson = [];
        let { _pageSize } = this.state;
        // console.log(excelData, data)
        if (data) {
            data.map((item, index) => {
                bodyJson.push({
                    ip: item["IP Address"],
                    ip_type: item["IP Type"],
                    name: item["Name"],
                    mac_address: item["Mac Address"],
                    comment: item["Comment"],
                });
            })
            Axios.POST('ip/ipFreeMonitorFiles/', bodyJson, (result) => {
                console.log("导入excel：", result);
                let data = result.data;
                if (result.status === 200) {
                    if (data.code === '00') {
                        let fail_list = data.fail_list || [];
                        let success_list = data.success_list || [];
                        // 导入提示信息
                        message.success(<div>
                            <p style={{ margin: 0 }}>操作成功！</p>
                            {success_list.length !== 0 ? <p style={{ margin: 0 }}>导入成功ip：<span style={{ color: 'green' }}>{success_list.join('，')}</span></p> : ''}
                            {fail_list.length !== 0 ? fail_list.map((ipItem, index) => {
                                return <p style={{ margin: 0 }} key={index}>
                                    <span style={{ color: 'red' }}>{ipItem.ip}</span> 导入失败，失败原因：<span style={{ color: 'red' }}>{ipItem.reason}</span>
                                </p>;
                            }) : ''}
                        </div>);

                        this.setState({
                            importVisible: false,
                            fileList: [],
                            excelData: {},
                        });
                        this.update(1, _pageSize, {});
                    } else {
                        message.error(data.msg);
                    }
                } else {
                    message.error(result.statusText)
                }
            })
        } else {
            message.error("请选择需要上传的文件");
        }
    }
    // 导出 excel 文件
    exportExcel(allFlag) {
        let { selectedRowKeys } = this.state;
        let ids = [];
        if (!allFlag) {
            if (selectedRowKeys.length === 0) {
                return message.error("请选择需要导出的ip！")
            }
        }
        ids = selectedRowKeys.map(keysItem => {
            return JSON.parse(keysItem).id
        })
        let bodyJson = {
            is_all: allFlag ? true : false,
            ids: ids
        }
        axios({
            method: "PUT",
            url: base_url + "ip/ipFreeMonitorFiles/",
            responseType: 'blob',
            data: bodyJson,
        }).then((result) => {
            const link = document.createElement('a');
            // let blob = new Blob([result.data], { type: "application/vnd.ms-excel" }); // 转化为 blob 对象 导出.xls文件
            let blob = new Blob([result.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }); // 转化为 blob 对象  导出.xlsx文件
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = "监控ip列表"; // 文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // 取消勾选状态
            this.setState({ selectedRowKeys: [] });
            // console.log(link.href, result);
        })
    }
    /* 用户方法 */
    update = (page, page_size, query) => {
        this.setState({ loading: true })
        let { _pageSize, _current, querys } = this.state
        let bodyJson = {
            page: page || _current,
            page_size: page_size || _pageSize,
            user_id: sessionStorage.getItem("userId") || '',
            querys: query || querys
        };

        let queryStr = 'ip/ipMonitor/?'
        queryStr += bodyJson.page ? '&page=' + bodyJson.page : '';
        queryStr += bodyJson.page_size ? '&page_size=' + bodyJson.page_size : '&page_size=10';
        queryStr += bodyJson.user_id ? '&user_id=' + bodyJson.user_id : '';
        for (let queryItem in bodyJson.querys) {
            queryStr += "&" + queryItem + "=" + bodyJson.querys[queryItem];
        }
        Axios.GET(queryStr, bodyJson, (result) => {
            this.setState({
                tableData: result.data.results,
                _current: page || 1,
                total: result.data.count,
                loading: false,
                _pageSize: page_size || _pageSize,
                querys: query || querys
            })
            // console.log(result.data)
        })
    }
    // 下载导入模版
    downloadTemplate() {
        let list = [
            {
                "IP Address": "10.12.249.4",
                "IP Type": "服务器地址",
                "Name": "BJ-B676",
                "Mac Address": "6c4b-0074-62b7",
                "Comment": "临时共享服务器",
            }
        ]
        Utils.exportExcel(list, "导入监控ip模板.xlsx");
    }
    // 时间格式化
    dealTime(time) {
        let formatTime = "";
        if (time) {
            let timeArr = time.split("T");
            let timeMinutes = timeArr[1].split(".")[0];
            formatTime = [timeArr[0], timeMinutes].join(" ");
        }
        return formatTime
    }
    getOutLineTime(item) {
        let freeDays = "";
        if (item.furthest_offline_time) {
            let furthestOfflineTime = new Date(item.furthest_offline_time);
            let today = new Date();
            freeDays = Math.floor((today.getTime() - furthestOfflineTime.getTime()) / (1000 * 60 * 60 * 24))
        }
        if (freeDays >= 0) {
            return freeDays
        } else {
            return 0
        }
    }
    recycleIp() {
        let { selectedRowKeys, _pageSize } = this.state;
        let ids = [];
        let unRecycleIp = [];
        let _this = this;
        let selectedData = selectedRowKeys.map((item) => {
            return JSON.parse(item)
        })
        selectedData.map((selectItem) => {
            ids.push(selectItem.id)
            if (selectItem.recycle_status !== "可回收") {
                unRecycleIp.push(selectItem.ip)
            }
        })
        if (selectedRowKeys.length === 0) {
            message.error("请选择需要回收的ip!")
        } else {
            if (unRecycleIp.length !== 0) {
                Modal.confirm({
                    title: '消息提示',
                    content: unRecycleIp.join("，") + ' ip不是可回收状态，是否还要继续回收所选ip？',
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        const hide = message.loading("执行中，请稍后......", 0);
                        Axios.POST('ip/recycleIp/', { ids, operator: sessionStorage.getItem("userName") }, (res) => {
                            // console.log(res, res.status)
                            if (res.status === 200) {
                                setTimeout(hide);
                                message.success("执行成功");
                                _this.setState({
                                    recycle_fail_list: res.data.recycle_fail_list,
                                    recycle_success_list: res.data.recycle_success_list,
                                    ipRecycleVis: true,
                                    selectedRowKeys: [],
                                });
                                _this.update(1, _pageSize, {});
                            } else {
                                message.error("执行失败");
                            }
                        })
                    },
                });
            } else {
                const hide = message.loading("执行中，请稍后......", 0);
                Axios.POST('ip/recycleIp/', { ids, operator: sessionStorage.getItem("userName") }, (res) => {
                    if (res.status === 200) {
                        setTimeout(hide);
                        message.success("执行成功");
                        _this.setState({
                            recycle_fail_list: res.data.recycle_fail_list,
                            recycle_success_list: res.data.recycle_success_list,
                            ipRecycleVis: true,
                            selectedRowKeys: [],
                        });
                        _this.update(1, _pageSize, {});
                    } else {
                        message.error("执行失败");
                    }
                })
            }
        }
    }
    addNetMonitor(values) {
        const hide = message.loading("执行中，请稍后......", 0);
        Axios.POST('ip/ipMonitorByNetwork/', values, (result) => {
            let res = result.data;
            let ips = [];
            if (result.status === 201 || result.status === 200 || result.status === 204) {
                setTimeout(hide);
                if (res.code === "00") {
                    ips = res.data.map((ipItem) => {
                        return ipItem.ip;
                    })
                    this.update();
                    this.setState({ loading: false, networkVis: false });
                    message.success("成功新增" + ips.length + "个ip：" + ips.join("，"));
                } else {
                    message.error(res.msg)
                }
            } else {
                message.error("新增失败");
            }
        })
    }
    menuButton() {
        return (
            <Menu onClick={(e) => this.handleMenuClick(e)}>
                {versionName === "pinganNanJing" ? '' : <Menu.Item key="netAdd">
                    <Icon type="plus-square" />网段新增
                </Menu.Item>}
                <Menu.Item key="editAdd">
                    <Icon type="edit" />手动新增
                </Menu.Item>
                <Menu.Item key="importAdd">
                    <Icon type="import" />批量导入
                </Menu.Item>
            </Menu>
        )
    }
    handleMenuClick = (e) => {
        switch (e.key) {
            case 'editAdd': this.setState({ visible: true, monitorIpItem: {} }); break;
            case "netAdd": this.setState({ networkVis: true }); break;
            case "importAdd": this.setState({ importVisible: true }); break;
        }
    }
    handleDropdownMenuClick(e) {
        switch (e.key) {
            case 'is_all': this.exportExcel("is_all"); break;
            case 'selected': this.exportExcel(); break;
        }
    }
    classificationIp() {
        let { selectedRowKeys } = this.state;
        let classifyData = selectedRowKeys.map((item) => {
            return JSON.parse(item)
        })
        console.log(classifyData)
        if (selectedRowKeys.length === 0) {
            return message.error("请选择需要归类的ip!")
        } else {
            this.setState({ classifyVis: true, classifyData })
        }
    }
    ipTypeManage() {
        const { ipSelectOptions } = this.state;
        const ModalObj = Modal.info({
            title: "ip分类管理",
            okText: "关闭",
            width: 600,
            maskClosable: true,
            content: <div>
                <Button type="primary" size="small" icon="plus-circle" onClick={() => addIpType((name, id) => {
                    this.update();
                    this.getIpType();
                    this.updataModal(ModalObj, [...ipSelectOptions, { id, name }])
                })}>新增</Button><p />
                <Table
                    columns={[
                        { title: "序号", dataIndex: "key", width: 40, render: (a, b, c) => (c + 1) },
                        { title: "分类名称", dataIndex: "name" },
                        {
                            title: "操作", render: (a, b, c) => (
                                <div>
                                    <Button type="primary" icon="edit" size="small"
                                        onClick={() => editIpType(b.name, b.id, (ipName, ipId) => {
                                            ipSelectOptions.map((ipItem, index) => {
                                                if (ipItem.id === ipId) { ipSelectOptions.splice(index, 1, { id: ipId, name: ipName }) }
                                                else return ipItem
                                            })
                                            this.update();
                                            this.getIpType();
                                            this.updataModal(ModalObj, [...ipSelectOptions])
                                        })}>编辑</Button>
                                    <Button type="danger" icon="delete" size="small" style={{ marginLeft: 8 }}
                                        onClick={() => delIpType(b.id, b.name, () => {
                                            this.update();
                                            this.getIpType();
                                            this.updataModal(ModalObj, ipSelectOptions.filter((t) => t.id != b.id))
                                        })}>删除</Button>
                                </div>
                            )
                        },
                    ]}
                    rowKey={(text) => text.id}
                    dataSource={ipSelectOptions}
                    size="small"
                    pagination={false}
                />
            </div>
        })
    }
    updataModal(ModalObj, ipSelectOptions) {
        ModalObj.update({
            title: "ip分类管理",
            okText: "关闭",
            width: 600,
            maskClosable: true,
            content: <div>
                <Button type="primary" size="small" icon="plus-circle" onClick={() => addIpType((name, id) => {
                    this.update();
                    this.getIpType();
                    this.updataModal(ModalObj, [...ipSelectOptions, { id, name }])
                })}>新增</Button><p />
                <Table
                    columns={[
                        { title: "序号", dataIndex: "key", width: 40, render: (a, b, c) => (c + 1) },
                        { title: "分类名称", dataIndex: "name" },
                        {
                            title: "操作", render: (a, b, c) => (
                                <div>
                                    <Button type="primary" icon="edit" size="small"
                                        onClick={() => editIpType(b.name, b.id, (ipName, ipId) => {
                                            ipSelectOptions.map((ipItem, index) => {
                                                if (ipItem.id === ipId) { ipSelectOptions.splice(index, 1, { id: ipId, name: ipName }) }
                                                else return ipItem
                                            })
                                            this.update();
                                            this.getIpType();
                                            this.updataModal(ModalObj, [...ipSelectOptions])
                                        })}>编辑</Button>
                                    <Button type="danger" icon="delete" size="small" style={{ marginLeft: 8 }}
                                        onClick={() => delIpType(b.id, b.name, () => {
                                            this.update();
                                            this.getIpType();
                                            this.updataModal(ModalObj, ipSelectOptions.filter((t) => t.id != b.id))
                                        })}>删除</Button>
                                </div>
                            )
                        },
                    ]}
                    rowKey={(text) => text.id}
                    dataSource={ipSelectOptions}
                    size="small"
                    pagination={false}
                />
            </div>
        })
    }

    render() {
        let { _current, _pageSize, querys, selectedRowKeys, ipRecycleVis, recycle_fail_list, recycle_success_list,
            networkVis, ipSelectOptions, monitorIpItem, classifyVis, classifyData } = this.state

        const columns = [
            {
                title: 'IP',
                align: 'center',
                dataIndex: 'ip',
                width: 110,
                ellipsis: true,
            },
            {
                title: '类型',
                align: 'center',
                dataIndex: 'ip_type',
                width: 100,
                ellipsis: true,
            },
            {
                title: '名称',
                align: 'center',
                dataIndex: 'name',
                width: 120,
                ellipsis: true,
            },
            {
                title: 'mac地址',
                align: 'center',
                dataIndex: 'mac_address',
                width: 120,
                ellipsis: true,
            },
            {
                title: '离线时间',
                align: 'center',
                dataIndex: 'furthest_offline_time',
                width: 140,
                ellipsis: true,
                render: (text) => { return text ? (<span>{this.dealTime(text)}</span>) : (<span></span>) }
            },
            {
                title: '最后一次ping结果',
                align: 'center',
                dataIndex: 'last_ping',
                width: 130,
                ellipsis: true,
                render: (text) => {
                    switch (text) {
                        case false:
                            return (<Tag color={Utils.ColorShow("fail")}>失败</Tag>)
                        case true:
                            return (<Tag color={Utils.ColorShow("success")}>成功</Tag>)
                        default:
                            break
                    }
                }
            },
            {
                title: '最后一次ping时间',
                align: 'center',
                dataIndex: 'last_check_time',
                width: 140,
                ellipsis: true,
                render: (text) => { return text ? (<span>{this.dealTime(text)}</span>) : (<span></span>) }
            },
            {
                title: '离线天数',
                align: 'center',
                dataIndex: 'offline_days',
                width: 80,
                ellipsis: true,
            },
            {
                title: '回收状态',
                align: 'center',
                dataIndex: 'recycle_status',
                width: 80,
                ellipsis: true,
            },
            {
                title: '描述',
                align: 'center',
                dataIndex: 'comment',
                width: 240,
                ellipsis: true,
            },
            {
                title: '操作',
                align: 'center',
                dataIndex: 'op',
                width: 180,
                ellipsis: true,
                fixed: 'right',
                render: (text, item) => {
                    return (
                        <div>
                            <Button type='primary' size='small' icon="edit" style={{ marginRight: 8 }} onClick={() => {
                                this.setState({ visible: true, monitorIpItem: item });
                            }}>编辑</Button>
                            <Button type='danger' size='small' icon="delete" onClick={() => {
                                let that = this;
                                Modal.confirm({
                                    title: "提示",
                                    content: "你确定删除选中ip吗？",
                                    okType: "danger",
                                    onOk() {
                                        that.deleteMonitor(item);
                                    }
                                })
                            }}>删除</Button>
                        </div>
                    )
                }
            }
        ]
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        let scrollX = 0;
        columns.map((item, index) => {
            scrollX += item.width;
        })
        scrollX = scrollX + 260;

        return (
            <div>
                <CustomBreadcrumb arr={[{ title: '首页', to: '/home' }, 'IP管理', 'IP监控']} />
                <div className="searchDiv">
                    <Collapse defaultActiveKey={"key"}>
                        <Collapse.Panel header="筛选条件" key={"key"}>
                            <FilterForm tagClass="ipMonitor" selectOptions={ipSelectOptions}
                                filterSubmit={(querys) => {
                                    querys.furthest_offline_time ? querys.fo_start_time = moment(querys.furthest_offline_time[0]).format("YYYY-MM-DD HH:mm:ss") : "";
                                    querys.furthest_offline_time ? querys.fo_end_time = moment(querys.furthest_offline_time[1]).format("YYYY-MM-DD HH:mm:ss") : "";
                                    delete querys.furthest_offline_time; // 删除 querys 中的 furthest_offline_time
                                    this.setState({ querys })
                                    this.update(1, this.state._pageSize, querys)
                                }}
                                resetTab={() => {
                                    this.setState({ querys: {} })
                                    this.update(1, this.state._pageSize, {})
                                }}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </div>
                <Card style={{ marginTop: 8 }} title={<span style={{ fontWeight: 600 }}>监控IP列表</span>}
                    extra={
                        <Row>
                            {versionName === "pinganNanJing" ? '' : <Button type='primary' icon='sync' onClick={() => { this.recycleIp() }}>回收ip</Button>}
                            <Button type='primary' icon='cluster' onClick={() => { this.classificationIp() }}>归类ip</Button>
                            <Button type='primary' icon='profile' onClick={() => { this.ipTypeManage() }}>ip分类管理</Button>
                            {/* <Dropdown.Button className="dropDownBtn"
                                overlay={this.menuButton()} type='primary' icon={<Icon type="down" />}
                                onClick={() => { this.handleMenuClick({ key: "editAdd" }) }}
                            >
                                <Icon type='edit' /> 手动新增监控ip
                            </Dropdown.Button> */}
                            {/* <Dropdown.Button className="dropDownBtn" type='primary' icon={<Icon type="down" />} style={{ marginLeft: 8, marginRight: 8 }}
                                overlay={
                                    <Menu onClick={() => { this.exportExcel("is_all") }}>
                                        <Menu.Item>
                                            <Icon type="export" />导出全部监控ip
                                        </Menu.Item>
                                    </Menu>
                                }
                                onClick={() => { this.exportExcel() }}
                            >
                                <Icon type='export' /> 导出监控ip
                            </Dropdown.Button> */}
                            <Dropdown className="dropDownBtn" style={{ marginRight: 8 }} overlay={this.menuButton()} >
                                <Button type="primary">
                                    <Icon type="edit" />新建 <Icon type="down" />
                                </Button>
                            </Dropdown>
                            <Dropdown className="dropDownBtn" style={{ marginRight: 8 }}
                                overlay={
                                    <Menu onClick={(e) => { this.handleDropdownMenuClick(e, 'export') }}>
                                        <Menu.Item  key="selected"> <Icon type="export" />导出选中 </Menu.Item>
                                        <Menu.Item  key="is_all"> <Icon type="export" />导出全部 </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button type="primary">
                                    <Icon type="export" />导出 <Icon type="down" />
                                </Button>
                            </Dropdown>
                            <Button type='danger' icon='delete' onClick={() => { this.deleteMonitor(selectedRowKeys, 'batch') }}>删除</Button>
                        </Row>
                    }
                >
                    <DyHeadTable
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.loading}
                        size="small"
                        rowKey={(text) => {
                            return JSON.stringify({
                                id: text.id, recycle_status: text.recycle_status, ip: text.ip, name: text.name, mac_address: text.mac_address, comment: text.comment
                            })
                        }}
                        rowSelection={rowSelection}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"], showSizeChanger: true,
                            onChange: (page, pageSize) => {
                                this.update(page, pageSize, querys)
                            },
                            onShowSizeChange: (current, size) => {
                                this.update(1, size, querys)
                            }
                        }}
                    />
                </Card>
                <Modal title={monitorIpItem.ip ? "编辑监控ip" : "新增监控ip"}
                    visible={this.state.visible}
                    onCancel={() => this.setState({ visible: false })}
                    footer={null}
                    width={"60%"}
                    destroyOnClose
                >
                    <Formik
                        onSubmit={(value) => { this.addMonitor(value) }}
                        validate={(values) => {
                            let info = {};
                            if (!values.ip) {
                                info.ip = "请输入IP信息";
                            }
                            if (values.name) {
                                if (values.name.length > 16) {
                                    info.name = "名称不得超过16个字符";
                                }
                            }
                            return info
                        }}
                        initialValues={monitorIpItem || {}}
                    >
                        <FormikForm labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                            <FormItem name={"ip"} label={"IP"} key={"ip"} required={true} >
                                <FormikInput name={"ip"} placeholder={"请输入IP"} allowClear />
                            </FormItem>
                            <FormItem name={"name"} label={"名称"} key={"name"} >
                                <FormikInput name={"name"} placeholder={"请输入监控IP名称"} allowClear />
                            </FormItem>
                            <FormItem name={"ip_type"} label={"分类"} key={"ip_type"} >
                                <Select name={"ip_type"} allowClear placeholder="请选择监控IP分类">
                                    {ipSelectOptions.map(item => (
                                        <Option key={item.id} label={item.name}>{item.name}</Option>
                                    ))}
                                </Select>
                            </FormItem>
                            <FormItem name={"mac_address"} label={"mac地址"} key={"mac_address"} >
                                <FormikInput name={"mac_address"} placeholder={"请输入mac地址"} allowClear />
                            </FormItem>
                            <FormItem name={"comment"} label={"描述"} key={"comment"} >
                                <FormikInput name={"comment"} placeholder={"请输入描述信息"} allowClear />
                            </FormItem>
                            <div style={{ textAlign: "right" }}>
                                <Button onClick={() => { this.setState({ visible: false }) }}>取消</Button>
                                <SubmitButton style={{ marginLeft: "10px" }} disabled={false}>保存</SubmitButton>&nbsp;
                            </div>
                        </FormikForm>
                    </Formik>
                </Modal>
                <Modal
                    title={<div>导入excel文件&nbsp;&nbsp;&nbsp;<Button type="primary" icon="download" onClick={() => { this.downloadTemplate() }}>下载模板</Button></div>}
                    visible={this.state.importVisible}
                    onOk={() => { this.onSubmit() }}
                    onCancel={() => this.setState({ importVisible: false, formData: "" })}
                    destroyOnClose
                >
                    <Dragger
                        beforeUpload={function () { return false; }}
                        onChange={this.importExcel.bind(this)}
                        fileList={this.state.fileList}
                        onRemove={() => {
                            this.setState({ fileList: [], excelData: {}, })
                        }}
                    >
                        <p className="ant-upload-drag-icon"><Icon type="inbox" /></p>
                        <p className="ant-upload-text">单击或将文件拖到该区域上传</p>
                    </Dragger>
                </Modal>
                <Modal title="ip回收返回信息"
                    visible={ipRecycleVis}
                    onOk={() => this.setState({ ipRecycleVis: false })}
                    onCancel={() => this.setState({ ipRecycleVis: false })}
                    width="80%"
                    destroyOnClose
                >
                    <div className="ipRecycleDescriptions">
                        <Descriptions title="回收成功的ip列表" bordered>
                            <Descriptions.Item label="成功IP">{recycle_success_list.join("，")}</Descriptions.Item>
                        </Descriptions>
                        <Descriptions title="回收失败的ip列表" bordered>
                            {recycle_fail_list.map((failItem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Descriptions.Item span={1} label="失败IP">{failItem.ip}</Descriptions.Item>
                                        <Descriptions.Item span={2} label="错误信息">{failItem.error}</Descriptions.Item>
                                    </React.Fragment>
                                )
                            })}
                        </Descriptions>

                    </div>
                </Modal>
                <Modal title="通过网段新增监控ip"
                    visible={networkVis}
                    onCancel={() => this.setState({ networkVis: false })}
                    footer={null}
                    width={"60%"}
                    destroyOnClose
                >
                    <Formik
                        onSubmit={(value, actions) => { this.addNetMonitor(value); actions.setSubmitting(false); }}
                        validate={(values) => {
                            let info = {};
                            if (!values.network) {
                                info.network = "请输入IP信息";
                            }
                            return info
                        }}
                        initialValues={{ network: "", }}
                    >
                        <FormikForm labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                            <FormItem name={"network"} label={"网段"} key={"network"} required={true} >
                                <FormikInput name={"network"} placeholder={"请输入网段"} />
                            </FormItem>
                            <div style={{ textAlign: "right" }}>
                                <Button onClick={() => { this.setState({ networkVis: false }) }}>取消</Button>
                                <SubmitButton style={{ marginLeft: "10px" }} disabled={false}>保存</SubmitButton>&nbsp;
                            </div>
                        </FormikForm>
                    </Formik>
                </Modal>
                <ClassificationIp classifyVis={classifyVis} classifyData={classifyData}
                    cancelVis={() => this.setState({ classifyVis: false })}
                    clearRowKeys={() => this.setState({ selectedRowKeys: [] })}
                    getTableData={() => this.update()}
                />
            </div>
        );
    }

}

const IpMonitorForm = Form.create()(IpMonitor);
export default IpMonitorForm;