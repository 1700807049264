/**
 * Created by YIMINE on 2019/1/24.
 */
import React from 'react'
import {
    Card, Input, Radio, Spin, Select, message,
} from 'antd';
import Socket from "@/socket/index";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FlowChar from '@/components/FlowChar/index';
let FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
class TaskLog extends React.Component {
    state = {
        RadioValue: '',
        cardTitle: "新建任务",
        taskPlanData: { type: 'manual', content: null },
        visibleTime: false,             //时间计划模态框
        timeBtn: false,             //时间计划按钮
        pageSpinning: false,        //页面加载控制
        searchText: '',
        Tags: [],
        Groups: [],
        task: null,
        AutoObject: null,
        commit: false,
        flow_data: null,
    };
    componentDidMount() {
        let thisTemp = this;
        FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
        let IdData = this.props.match.params.Id;
        if (IdData) {
            IdData = (IdData).replace(":", "");
            let strList = IdData.split('&');
            this.setState({ pageSpinning: true });
            Socket.QueryData('QueryTaskDetail', { _id: strList[0] }, (resp) => {
                if (resp.retcode === '00') {
                    let task = resp.task;
                    let AutoObject = task;
                    thisTemp.setState({
                        pageSpinning: false, flow_data: task.taskContent,
                        task, RadioValue: task.taskPlan.type, AutoObject,
                        timeBtn: ((task.taskPlan.type) === 'auto' ? true : false)
                    });
                } else {
                    message.error('数据返回错误:' + JSON.stringify(resp))
                }
            }, "/taskQueryTask");
        }

    }
    onParamChange = (data) => {
        this.setState({ visibleTime: false, taskPlanData: data });
    };

    goBackPage() {
        let bodyJson = this.props.location.bodyJson;
        let path = {
            pathname: '/task',
            bodyJson,
        };
        this.props.history.push(path);
    }

    render() {
        const { flow_data, pageSpinning } = this.state;

        return (
            <div >
                <Spin spinning={pageSpinning}>
                    <CustomBreadcrumb arr={["任务管理", { title: "任务日志", to: '/task' }, "任务日志"]} />
                    <Card title={<span style={{ fontWeight: 600 }}>{"任务日志"}</span>}>
                        <FlowChar
                            onRef={(_this) => { this.child = _this }}
                            initValue={flow_data}
                            onFlowDataChange={(data) => {
                                this.setState({ flow_data: data })
                            }}
                        />
                    </Card>
                </Spin>
            </div>
        );
    }
}
export default TaskLog;