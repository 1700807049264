/**
 * 面包屑导航
 */
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Icon } from 'antd'
import { Link } from 'react-router-dom'
import Utils from "@/utils/utils";

function CustomBreadcrumb(props) {
  // const menuList = JSON.parse(sessionStorage.getItem("menuList"));
  const [sysTime, setSysTime] = useState([]);
  let InteVal = null;
  useEffect(() => {
    getNowDate();
    return () => { //componentWillUnmount
      clearInterval(InteVal);
    }
  }, []);
  function getNowDate() {
    setSysTime(Utils.formateDate(new Date().getTime()));
    InteVal = setInterval(() => {
      let sysTime = Utils.formateDate(new Date().getTime());
      setSysTime(sysTime);
    }, 1000);
  }
  // const [urlList, setUrlList] = useState([]);
  // const dataList = [];
  // const GenerateList = (data) => {
  //   if (!data) return;
  //   for (let i = 0; i < data.length; i++) {
  //     const node = data[i];
  //     dataList.push(node);
  //     if (node.children) {
  //       GenerateList(node.children);
  //     }
  //   }
  // };

  // const getParentKey = (key, tree) => {
  //   let parentKey;
  //   for (let i = 0; i < tree.length; i++) {
  //     const node = tree[i];
  //     if (node.children) {
  //       if (node.children.some(item => item.key === key)) {
  //         parentKey = node.key;
  //       } else if (getParentKey(key, node.children)) {
  //         parentKey = getParentKey(key, node.children);
  //       }
  //     }
  //   }
  //   return parentKey;
  // };

  // const getAllParentKey = (key, TreeData) => {
  //   let parentKey = getParentKey(key, TreeData);
  //   if (parentKey) {
  //     return parentKey = getAllParentKey(parentKey, TreeData) + "@" + parentKey;
  //   } else {
  //     return ""
  //   }
  // }
  // useEffect(() => {
  //   if (!props.url) return;
  //   let urlList = getAllParentKey(props.url, menuList).split("@").filter((item) => { return item != "" });;
  //   urlList.push(props.url);
  //   setUrlList(urlList);
  //   return () => { //componentWillUnmount
  //   }
  // }, []);

  return <div className="BreadcrumbStyle">< Breadcrumb>

    {
      // props.url ?
      //   urlList.map((url, index) => {
      //     let link = url;
      //     if (index === 0) { link = urlList[index + 1] };
      //     // console.log(url)
      //     // console.log(menuItem)
      //     return <Breadcrumb.Item key={index}><Link to={link}><Icon type={"right-circle"} /> {Utils.keyToTitleByMenu(menuList, url)}</Link></Breadcrumb.Item>
      //   })
      //   :
      props.arr && props.arr.map(item => {
        if ((typeof item) === 'object') {
          return <Breadcrumb.Item key={item.title}><Link to={item.to || ""}><Icon type={"right-circle"} /> {item.title}</Link></Breadcrumb.Item>
        }
        else {
          return <Breadcrumb.Item key={item}><Icon type={"right-circle"} /> {item}</Breadcrumb.Item>
        }
      })
    }
  </Breadcrumb >
    <span className="time-clock"><Icon type="clock-circle" />&nbsp;{sysTime}</span>
  </div>
}
export default CustomBreadcrumb