import React from 'react'
import { useState, useEffect } from 'react'

import { ScrollBoard } from '@jiaminghi/data-view-react'

// import './ScrollBoard.scss'

import http from '@/axios/axios_utils';
import { alarmRecords } from '../../monitorAlert/utils/api';
export default (props) => {
  // const [total, setTotal] = useState(10);
  // const [_current, set_current] = useState(1);
  // const [_querys, set_querys] = useState({});

  const [config, setData] = useState({});

  function getData(tableData) {
    return {
      header: ['触发时间', '故障设备IP', '对应规则名称', "告警等级"],
      data: tableData,
      index: true,
      columnWidth: [110, 230, 190, 260, 80],
      align: ['center'],
      rowNum: 7,
      headerBGC: '#1981f6',
      headerHeight: 45,
      oddRowBGC: 'rgba(0, 44, 81, 0.8)',
      evenRowBGC: 'rgba(10, 29, 50, 0.8)',
    }
  }
  useEffect(() => {
    createData();

    // let timer = setInterval(createData, 30000);
    let timer = setInterval(createData, props.timer || 1000 * 60);
    return () => clearInterval(timer)
  }, [])

  function createData() {
    getAlertData(1, 10, {}, (tableData) => {
      setData(getData(tableData))
      console.log("获取告警记录:", tableData)
    })
  }

  function getAlertData(page = 1, size = 10, query = {}, callback) {
    let tableArr = [];
    http.post(alarmRecords(), { page, size, ...query, userId: sessionStorage.getItem("userId") }, (r) => {
      r.data.records.map((i) => {
        let number = i.level
        let alertLabel = (number === 0 ? "信息" : (number === 1 ? "警告" : (number === 2 ? "严重" : "灾难")))
        let dataArr = [i.triggerTime, JSON.parse(i.label).ip || "", i.ruleName || "", alertLabel];
        tableArr.push(dataArr);
      })
      if (tableArr.length <= 10) {
        callback(tableArr)
      } else {
        callback(tableArr.splice(0, 10))
      }
      // setTotal(r.data.total);
      // set_querys(query);
      // set_current(page);
      // console.log("获取告警记录", tableData, r.data.records)
      // console.log("告警面板数据：",config)
    })
    // callback(["20200517", "10.23.65.91" || "", "i.ruleName" || "", "alertLabel"])
  }


  return (
    <div className="item-table animated zoomIn" style={{ textAlign: "center" }}>
      <ScrollBoard config={config} />
    </div>
  )
}
