

import React from 'react'
import {
    Card, Table, Button, Icon, Input, message, notification, Select, Descriptions, Row, Col, Badge,
    Popconfirm, Tag, Modal, Upload, Spin,
} from "antd";
import socket from "@/socket/index";
import Utils from "@/utils/utils";
import "./file.scss";
export default class FileModal extends React.Component {
    state = {
        FileVis: false,
        fileSpin: false,
        _fileName: null,
        InfoBack: [],
    };
    componentWillReceiveProps(props) {
        this.setState({ FileVis: props.FileVis })
    }
    listFile() {
        const _this = this;
        const { APIName } = this.props;
        $("#fileInput").unbind();
        $("#fileInput").change(function () {
            let file = this.files[0];
            if (!file) { return; }
            let _fileName = file.name;
            if (APIName === "SwitchVlanByTags" && _fileName != "批量切换vlan.xlsx") {
                message.error("文件名应为批量切换vlan.xlsx");
                return;
            }
            $('.file_Div').html(_fileName + "上传中...");
            $('.file_Div').css("border", "1px dashed #124ae4");
            $('.file_Div').css("color", "#124ae4");
            _this.onUpload(_fileName);
        })
    }
    onUpload(_fileName) {
        const _this = this;
        $('#fileform').ajaxSubmit({
            url: 'http://' + socket.getAddress() + ':50034/file_upload',
            type: 'post',
            async: true,
            data: $('#fileform').serialize(),
            dataType: "json",
            success: function (data) {
                console.log(data);
                if (data.message === "00") {
                    $('.file_Div').html(_fileName);
                    $('.file_Div').css("border", "2px dashed #87d068");
                    $('.file_Div').css("color", "#87d068");
                    message.success("上传成功");
                    _this.setState({ _fileName })
                } else {
                    message.error("上传失败！");
                }
            },
        });
    }
    onSumit() {
        const { _fileName } = this.state;
        const { APIName } = this.props;
        const _this = this;
        if (_fileName) {
            if (APIName) {
                _this.setState({ fileSpin: true })
                socket.ExecuteAction(APIName, { fileName: _fileName }, "/upfile",
                    (success) => {
                        let InfoBack = "";
                        if (APIName === "SwitchVlanByTags") {
                            if (success.data && success.data.result && success.data.result === "success") {
                                message.success("切换成功！");
                                InfoBack = <Tag color="#87d068"><Icon type="check-circle" />成功切换</Tag>
                            } else {
                                InfoBack = <div style={{ color: "red" }}><Tag color="red">失败</Tag>{JSON.stringify(success)}</div>
                                notification.open({
                                    message: "失败",
                                    description: JSON.stringify(success),
                                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                                });
                            }
                        } else {
                            message.success("导入成功！");
                        }
                        _this.setState({ fileSpin: false, InfoBack, })
                    }, (error) => {
                        message.error("操作失败！" + error);
                        _this.setState({ fileSpin: false, InfoBack: <div style={{ color: "red" }}>{error}</div> })
                    })
            } else {
                _this.setState({ FileVis: false })
            }
        } else {
            message.error("请上传文件");
        }
    }
    InfoBack() {
        let { InfoBack } = this.state;
        return <div>{InfoBack}</div>
    }
    render() {
        let { FileVis, fileSpin } = this.state;
        const { btnName } = this.props;
        return (
            <Modal
                title="上传文件"
                visible={FileVis}
                onCancel={() => this.setState({ FileVis: false, InfoBack: "" })}
                // onOk={() => this.onSumit()}
                afterClose={() => {
                    this.setState({ _fileName: null, fileSpin: false });
                    this.props.onCancel();
                }}
                footer={[
                    // <Button onClick={() => this.setState({ FileVis: false })} key="1">
                    //     取消
                    // </Button>,
                    <Button type="primary" loading={fileSpin} onClick={() => this.onSumit()} key="2">
                        {btnName}
                    </Button>,
                ]}
                destroyOnClose
            >
                <Spin spinning={fileSpin}>
                    <h3>文件上传：</h3>
                    <form method="post" id="fileform" encType="multipart/form-data">
                        <input type="file" name="image" size="50" id="fileInput"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{ display: "none" }} />
                        <div className="file_Div" onClick={() => {
                            $("#fileInput").click();
                            this.listFile();
                        }}>  --------点击上传文件--------
                        </div>
                    </form>
                    <p></p>
                    {this.InfoBack()}
                </Spin>
            </Modal>

        )
    }
}
