import React, { useEffect, useState } from 'react'
import Charts from "echarts-for-react"
import { Row, Col, Progress } from "antd"
import "./index.scss"
function PieMap(props) {
    {/* <PieChar data={[{ name: "成功", value: 40 }, { name: "失败", value: 10 }, { name: "异常", value: 4 }]} /> */ }
    // const [data1, setData1] = useState(props.data || []);
    const [data1, setData1] = useState({});
    useEffect(() => {//componentDidMount 
        fixdata(props.data)
        return () => { //componentWillUnmount
        }
    }, []);

    function getOptionn(data) {
        return {
            // backgroundColor: '#2c343c',
            // title: {
            //     // text: 'Customized Pie',
            //     left: 'center',
            //     top: 20,
            //     textStyle: {
            //         color: '#ccc'
            //     }
            // },
            tooltip: {
                trigger: 'item'
            },
            visualMap: {
                show: false,
                min: 80,
                max: 600,
                inRange: {
                    colorLightness: [0, 1]
                }
            },
            series: [
                {
                    // name: 'Access From',
                    type: 'pie',
                    // radius: ['40%', '70%'],
                    radius: '55%',
                    center: ['50%', '50%'],
                    data: [
                        { value: 335, name: '警告' },
                        { value: 4, name: '严重' },
                        { value: 2, name: '灾难' },
                        { value: 300, name: '信息' },
                    ].sort(function (a, b) {
                        return a.value - b.value;
                    }),
                    roseType: 'radius',
                    label: {
                        color: 'rgba(255, 255, 255, 0.3)'
                    },
                    labelLine: {
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.3)'
                        },
                        smooth: 0.2,
                        length: 10,
                        length2: 20
                    },
                    color: ["#3fb1e3", "#6be6c1", "#a0a7e6", '#1890ff', "#ff5338", "#ff5384", "#4456c7", "#ff80a0", '#5ec9db', '#fdc765', '#f27d51', '#6462cc', '#91c7ae', '#749f83', '#f26659', "#c4ebad",],

                    // itemStyle: {
                    //     // color: '#c23531',
                    //     shadowBlur: 200,
                    //     shadowColor: 'rgba(0, 0, 0, 0.5)',
                    //     normal: {
                    //         //这里是重点
                    //         color: function (params) {
                    //             //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                    //             // var colorList = ["#3fb1e3", "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8", '#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622'];
                    //             var colorList = ["#31e3cb", "#a0a7e6", "#96dee8", '#1890ff', "#ff5338", "#ff5384", "#4456c7", "#ff80a0", '#5ec9db', '#fdc765', '#f27d51', '#6462cc', '#91c7ae', '#749f83', '#f26659', "#c4ebad",];
                    //             return colorList[params.dataIndex]
                    //             // return "#1890ff"
                    //         },

                    //     }
                    // },
                    animationType: 'scale',
                    animationEasing: 'elasticOut',
                    animationDelay: function (idx) {
                        return Math.random() * 200;
                    }
                }
            ]
        };
    }

    function fixdata(data2) {
        let count_val = 0
        // console.log("444444444444444444")
        // console.log(props)
        // console.log(props.data)
        // console.log(data2)
        // props.data.map((i) => {
        //     count_val = count_val + i.value
        //     // if (i.name === "信息") {

        //     // } else if (i.name === "警告") {

        //     // } else if (i.name === "严重") {

        //     // } else if (i.name === "灾难") {
        //     // } else {

        //     // }
        // })
        // let val_se = {}
        // props.data.map((i) => {

        //     console.log(i)
        //     if (i.name === "信息") {
        //         val_se.info = parseInt(i.value / count_val)
        //     } else if (i.name === "警告") {
        //         val_se.jg = parseInt(i.value / count_val)
        //     } else if (i.name === "严重") {
        //         val_se.yz = parseInt(i.value / count_val)
        //     } else if (i.name === "灾难") {
        //         val_se.zn = parseInt(i.value / count_val)
        //     }

        // })
        // setData1(val_se)
        // console.log("0000000000000000000")
        // console.log(val_se)
    }
    //     data
    // : 
    // Array(4)
    // 0
    // : 
    // {name: '信息', value: 5}
    // 1
    // : 
    // {name: '警告', value: 462}
    // 2
    // : 
    // {name: '严重', value: 66}
    // 3
    // : 
    // {name: '灾难', value: 4}
    // length
    // : 
    // 4
    // [[Prototype]]
    // : 
    // Array(0)
    // unit
    // : 
    // "个"
    // console.log("==========================================")
    // console.log(props)
    return <div className="ringsDiv">
        {/* <Row>
            <Col span={12}><Progress type="circle" percent={70} className="ringstyle" width={100} strokeWidth={10} strokeColor="#FB4D19" /></Col>
            <Col span={12}><Progress type="circle" percent={20} className="ringstyle" width={100} strokeWidth={10} strokeColor="#0A8BEB" /></Col>
        </Row><br />
        <Row>
            <Col span={12}><Progress type="circle" percent={5} className="ringstyle" width={100} strokeWidth={10} strokeColor="#332ACD" /></Col>
            <Col span={12}><Progress type="circle" percent={5} className="ringstyle" width={100} strokeWidth={10} strokeColor="#EDD804" /></Col>
        </Row> */}
        <div className="item_ring">
            <Progress type="circle" percent={props.data.info} className="ringstyle" width={130} strokeWidth={10} strokeColor={{ "0%": "#FD8436", "100%": "#FB4D19" }} />
            <div className="ringTitle">信息</div>
        </div>

        <div className="item_ring">
            <Progress type="circle" percent={props.data.jg} className="ringstyle" width={130} strokeWidth={10} strokeColor={{ "0%": "#18BFF7", "100%": "#0A8BEB" }} />
            <div className="ringTitle">警告</div>
        </div>

        <div className="item_ring">
            <Progress type="circle" percent={props.data.yz} className="ringstyle" width={130} strokeWidth={10} strokeColor={{ "0%": "#6254E8", "100%": "#332ACD" }} />
            <div className="ringTitle">严重</div>
        </div>
        <div className="item_ring">
            <Progress type="circle" percent={props.data.zn} className="ringstyle" width={130} strokeWidth={10} strokeColor={{ "0%": "#F7EE0A", "100%": "#EDD804" }} />
            <div className="ringTitle">灾难</div>
        </div>
    </div>
}
// FD8436—FB4D19
// 18BFF7—0A8BEB
// 6254E8—332ACD
// F7EE0A—EDD804
// { from: string; to: string; direction: string }


export default React.memo(PieMap)
