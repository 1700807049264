import React from 'react'
import {Card,Table,Switch,DatePicker,Button,Select,Modal,Icon,Input,Menu,Row,Col,Tabs,} from "antd";
import '../OverView/index.scss'
import Socket from '../../../socket/index'
import Dotcon from './flow'
// import './GooFlow.css'
// import './default.css'
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index'
import SelectForm from '../../../components/SelectForm/index'
const Search = Input.Search;
const TabPane = Tabs.TabPane;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const Option = Select.Option;
const provinceData = ['Zhejiang', 'Jiangsu'];
const cityData = {
    Zhejiang: ['Hangzhou', 'Ningbo', 'Wenzhou'],
    Jiangsu: ['Nanjing', 'Suzhou', 'Zhenjiang'],
};
export default class Task extends React.Component {
    state = {

    };
    componentWillMount() {

        // this.addScript();
    }
    componentDidMount () {
        this.Print();
    }
    addScript = ()=>{
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = './flow/plugin/jquery.min.js';
        document.body.appendChild(s);
    };
    Print = ()=>{
        var property={
	width:950,
	height:470,
//	width:1350,
//	height:667,
//	toolBtns:["start round","end round","task round","node","chat","state","plug","join","fork","complex mix"],
            toolBtns:["start","end","node","fork"],
            haveHead:true,
//	headBtns:["new","open","save","undo","redo","reload","print"],//如果haveHead=true，则定义HEAD区的按钮
            headBtns:["undo","redo","print"],//如果haveHead=true，则定义HEAD区的按钮
            haveTool:true,
            haveGroup:true,
            useOperStack:true
        };
        var remark={
            cursor:"选择指针",
            direct:"结点连线",
            start:"入口结点",
            "end":"结束结点",
            node:"流程结点",
            fork:"分支结点(判断)",
//	"join":"联合结点",
        };
        var demo;
        $(document).ready(function(){
            demo=$.createGooFlow($("#demo"),property);
            demo.setNodeRemarks(remark);
            demo.onItemDel=function(id,type){
                this.blurItem();
                return true;
            }
//	demo.loadData(jsondata);
            demo.onItemFocus=function(id,model){
//        console.log(id);
//        console.log(model);
                var obj;
                if(model=="line"){
                    obj=this.$lineData[id];
                }else if(model=="node"){
                    obj=this.$nodeData[id];
                }
                obj.id = id;
                var sendData = {
                    type:'Focus',
                    data:obj
                };
                top.postMessage(sendData, document.location.origin+'/newTask')//父向子消息
                return true;
            };
            demo.onItemBlur = function (id, model) {
//        document.getElementById("propertyForm").reset();
                return true;
            };
            demo.onPrintClick=function(){demo.exportDiagram("flow");}
            demo.onItemAdd = function (id,type,json,isImport) {
//        console.log(id,type,json);
//        console.log(isImport);
                if(isImport){
                    return true;
                }
                var getPreNode = demo.getItemInfo(json.from,'node');
//        console.log(getPreNode);
                if(!getPreNode||!getPreNode.hasOwnProperty('taskdata')){
                    return true;
                }
                var isExit = getPreNode.taskdata.hasOwnProperty('exit');
                var operationScript = getPreNode.taskdata.operation.operationScript;
                var inUpOutType = operationScript.hasOwnProperty('inUpOutType')?operationScript.inUpOutType:null;
                if(isExit&&inUpOutType==='3'){
                    var exitData = {
                        type:'exit',
                        lineId:id,
                        data:getPreNode.taskdata.exit
                    };
                    top.postMessage(exitData, document.location.origin+'/newTask')//父向子消息
                }
                return true;
            };

        });
        var out;
        function Export(){
            document.getElementById("result").value=JSON.stringify(demo.exportData());
        }
        function btnclick() {
            var age=prompt("input:");
            document.getElementById("ele_btn").innerHTML = age;
        }
    };

    render() {
        const { cities } = this.state;
        return (
            <div>
                <CustomBreadcrumb arr={["任务管理","历史"]} />
                <Card
                    style={{marginTop: 8}}>
                    <Row>
                        <Col span={12} offset={6}>
                            <Search
                                placeholder="输入任务名称"
                                onSearch={ (value) => {}}
                                enterButton={<Button type="primary" icon="search">搜索</Button>}
                                size="large"
                            />
                        </Col>
                    </Row>
                </Card>
                <Card
                    style={{marginTop: 8}}>
                    <div id="ID01">1234</div>
                    <div id="demo"/>
                </Card>

            </div>
        )
    }
}
