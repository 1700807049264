import React, { useState, useEffect } from 'react'

import { DigitalFlop, Decoration10 } from '@jiaminghi/data-view-react'
import { Icon, } from 'antd'

// import './DigitalFlop.scss'
import Socket from '@/socket/index'
import http from '@/axios/axios_utils';
import Utils from '@/utils/utils';
import { alarmRecordTotal } from '../../monitorAlert/utils/api';

import { useRef } from 'react'
import { combineReducers } from 'redux'
const dumbTerminal_Url = "http://" + backup_address + "/ZJNMS_V1/dumb_termial/";


function randomExtend(minNum, maxNum) {
    if (arguments.length === 1) {
        return parseInt(Math.random() * minNum + 1, 10)
    } else {
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
    }
}


export default (props) => {
    const [digitalFlopData, setData] = useState([]);
    const endTime = Utils.formateDate(new Date().getTime());
    let dataList = {
        total: 0,
        switchNumber: 0,
        firewallNumber: 0,
        routerNumber: 0,
        OtherNumber: 0,
        TerminalNumber: 0,
        UnKnowTerminalNumber: 0
    };

    function getData(dataList) {
        return [
            {
                title: '主机总数',
                name: "7",
                number: {
                    number: [dataList.total],
                    content: '{nt}',
                    textAlign: 'right',
                    style: {
                        fill: '#4d99fc',
                        fontWeight: 'bold',
                    },
                },
                unit: '台',
            },
            {
                title: '路由器总数',
                name: "3",
                number: {
                    number: [dataList.routerNumber],
                    content: '{nt}',
                    textAlign: 'right',
                    style: {
                        fill: '#4d99fc',
                        fontWeight: 'bold',
                    },
                },
                unit: '个',
            },
            {
                title: '防火墙总数',
                name: "1",
                number: {
                    number: [dataList.firewallNumber],
                    content: '{nt}',
                    textAlign: 'right',
                    style: {
                        fill: '#4d99fc',
                        fontWeight: 'bold',
                    },
                },
                unit: '个',
            },
            {
                title: '交换机总数',
                name: "2",
                number: {
                    number: [dataList.switchNumber],
                    content: '{nt}',
                    textAlign: 'right',
                    style: {
                        fill: '#4d99fc',
                        fontWeight: 'bold',
                    },
                },
                unit: '个',
            },
            {
                title: '其他总数',
                name: "4",
                number: {
                    number: [dataList.OtherNumber],
                    content: '{nt}',
                    textAlign: 'right',
                    style: {
                        fill: '#4d99fc',
                        fontWeight: 'bold',
                    },
                },
                unit: '个',
            },
            {
                title: '哑终端总数',
                name: "6",
                number: {
                    number: [dataList.TerminalNumber],
                    content: '{nt}',
                    textAlign: 'right',
                    style: {
                        fill: '#40faee',
                        fontWeight: 'bold',
                    },
                },
                unit: '个',
            },
            {
                title: '未知哑终端',
                name: "7",
                number: {
                    number: [dataList.UnKnowTerminalNumber],
                    content: '{nt}',
                    textAlign: 'right',
                    style: {
                        fill: '#f46827',
                        fontWeight: 'bold',
                    },
                },
                unit: '个',
            },
        ]
    }

    useEffect(() => {
        createData()

        // const timer = setInterval(createData, 30000)
        const timer = setInterval(createData, props.timer || 13000)
        return () => clearInterval(timer)
    }, [])

    function createData() {
        getAllPort()
    }


    function getAllPort() {
        getDeviceNumber((total) => {
            dataList.total = total;
            setData(getData(dataList));
        });
        getSwitchNumber((switchNumber, firewallNumber, routerNumber, OtherNumber) => {
            dataList.switchNumber = switchNumber;
            dataList.firewallNumber = firewallNumber;
            dataList.routerNumber = routerNumber;
            dataList.OtherNumber = OtherNumber;
            setData(getData(dataList));
        });
        getDepsId((dep_ids) => {
            getTerminal(dep_ids, (TerminalNumber) => {
                dataList.TerminalNumber = TerminalNumber;
                setData(getData(dataList));
            });
            getUnKnowTerminalNumber(dep_ids, (UnKnowTerminalNumber) => {
                dataList.UnKnowTerminalNumber = UnKnowTerminalNumber;
                setData(getData(dataList));
            });
        })
    }

    // 获取设备总数
    function getDeviceNumber(callback) {
        let bodyJson = {};
        bodyJson.pageSize = 20;
        bodyJson.pageNum = 1;
        bodyJson.querys = {};
        Socket.ExecuteAction('QueryResourcesPage',
            bodyJson, "/queryResource", (result) => {
                callback(result.total)
            });
    }

    // 获取交换机、防火墙、路由器的数量
    function getSwitchNumber(callback) {
        Socket.ExecuteAction('statisDeviceTypeNum',
            {}, "/", (r) => {
                let switchCount = 0;
                let firewallCount = 0;
                let routerCount = 0;
                let OtherCount = 0;
                r.data.map((i) => {
                    let name = i._id;
                    if (i._id === "switch") {
                        name = "交换机";
                        switchCount = i.count;
                    } else if (i._id === "firewall") {
                        name = "防火墙";
                        firewallCount = i.count;
                    } else if (i._id === "router") {
                        name = "路由器";
                        routerCount = i.count;
                    } else if (i._id === "") {
                        name = "其他"
                        OtherCount = i.count;
                    }
                })
                callback(switchCount, firewallCount, routerCount, OtherCount);
            });
    }

    // 获取deps_id
    function getDepsId(callback) {
        Socket.ExecuteAction('QueryArea', {}, "", (result) => {
            const TreeData = result.data;
            let dep_ids = dealTreeData(TreeData);
            callback(dep_ids)
        });
    }
    function dealTreeData(data) {
        let dep_ids = [];
        if(Array.isArray(data)) {
            data.map((item) => {
                let children = item.props ? item.props.children : item.children
                if(children && children.length > 0) {
                    dep_ids = dep_ids.concat(dealTreeData(children));
                } else {
                    dep_ids.push(item.key || item.eventKey)
                }
            })
        }
        return dep_ids
    }

    // 获取任务总数
    function getTerminal(dep_ids, callback) {
        http.get(dumbTerminal_Url + "terminal", {
            page_no: 1,
            page_size: 1,
            dep_ids: dep_ids.join(","),
        }, (result) => { callback(result.count || 0) })
    }

    // 哑终端未知设备
    function getUnKnowTerminalNumber(dep_ids, callback) {
        http.get(dumbTerminal_Url + "unknownDevice", {
            page_no: 1,
            page_size: 1,
            audit_status: 0,
            dep_ids: dep_ids.join(","),
        }, (r) => {
            callback(r.count)
        });
    }

    return (
        <div id="digital-flop" className="animated zoomIn">
            {digitalFlopData.map(item => (
                <div className="digital-flop-item" key={item.title}>
                    <div className="digital-flop-title"><img style={{ width: 50 }} src={"/image/" + item.name + ".png"} />{item.title}</div>
                    <div className="digital-flop">
                        <DigitalFlop config={item.number} style={{ width: '100px', height: '50px' }} />
                        <div className="unit">{item.unit}</div>
                    </div>
                </div>
            ))}
            <Decoration10 />
        </div>
    )
}
