/**
 * 系统前后端API配置
 */
const BASEURL = "http://" + es_address + "/";
const APIConfig = {
    MODENAME: "logMange",//模块名称
    sysIndex: "produce_zjnms*",
    deviceIndex: "zjnms_snmptrap*",
    getIndexs: BASEURL + "_cat/indices",
    BASEURL,
};
export default APIConfig;