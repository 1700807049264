import React, { useState, useEffect } from 'react'
import {
    Table, Button, Icon, message, Divider, Popconfirm
} from "antd";
import http from '@/axios/axios_utils';
import { numberToText } from '../utils/index';
import { strategys, strategy, alarmGroups } from '../utils/api';
import Socket from '@/socket/index';
import SearchCard from './searchCard';
import { connect } from 'react-redux'
import "./index.scss"
import "animate.css"

function Alert(props) {
    const [] = useState(false);
    const [total, setTotal] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const [] = useState(null);
    const [selectedRowKeys, setRowKeys] = useState([])
    const [tableData, setTabData] = useState([]);
    const [GroupData, setGroupData] = useState([]);
    const [UserData, setUserData] = useState([]);
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
        },
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'ip',
            ellipsis: true,
            width: 40,
            render: (text, item, c) => { return c + 1 + (_current - 1) * _pageSize }
        },
        {
            title: '名称',
            dataIndex: 'strategyName',
            ellipsis: true,
        },
        {
            title: '策略描述',
            dataIndex: 'description',
            ellipsis: true
        },
        {
            title: '通知组',
            dataIndex: 'alarmGroupId',
            ellipsis: true,
            render: (text) => {
                const record = GroupData.filter((r) => r.alarmGroupId === text)[0] || {}
                return record.alarmGroupName || text;
            }
        },
        {
            title: '通知人',
            dataIndex: 'alarmReceiverId',
            ellipsis: true,
            render: (text) => {
                const record = UserData.filter((r) => r.id === text)[0] || {}
                return record.name || text;
            }
        },
        {
            title: '通知方式',
            dataIndex: 'notifyWay',
            ellipsis: true,
            render: (text) => {
                if (typeof text === "object") {
                    return text.map((t) => numberToText("notifyWay", t));
                } else {
                    return numberToText("notifyWay", text);
                }
            }
        },
        {
            title: '操作',
            render: (text, item, key) => {
                return <div key={key}>
                    <a onClick={() => { editForm(item); }}><Icon type="edit" />&nbsp;编辑</a>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData([item.strategyId]) }}
                        okText="是"
                        cancelText="否"
                    >
                        <a><Icon type="delete" />&nbsp;删除</a>
                    </Popconfirm>
                </div>
            }
        },
    ]
    useEffect(() => {//componentDidMount; 
        http.setDispatch(props.dispatch);
        getUserData();
        getGroupData();
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(page = 1, size = _pageSize, query = {}) {
        http.get(strategys(), { page, size, ...query }, (r) => {
            setTabData(r.data.records);
            setTotal(r.data.total);
            set_querys(query);
            set_current(page);
            set_pageSize(size);
        })
    }
    function getGroupData(page = 1, size = 9999, query = {}) {
        http.get(alarmGroups(), { page, size, ...query }, (r) => {
            const data = r.data.records;
            setGroupData(data);
            getData();
        })
    }
    function getUserData() {
        Socket.ExecuteAction('QueryUserFromUrl', { url: "" }, "", (result) => {
            const users = result.data;
            setUserData(users);
        });
    }
    function deleteData(strategyIds) {
        http.delete_body(strategy(), { strategyIds }, () => {
            message.success("删除成功!");
            getData();
        })
    }
    function editForm(data) {
        if (data) {
            sessionStorage.setItem("alertItem", JSON.stringify(data));
        }
        props.history.push("/monitorAlert/strategy/editForm")
    }
    return <div className="alertDiv">
        <div className="searchDiv">
            <SearchCard onSubmit={(val) => { getData(1, _pageSize, val) }} reSet={() => { getData(1, _pageSize, {}) }} />
            <div>
                <Button type="primary" icon="file-add" onClick={() => editForm()}>添加</Button>&nbsp;
                <Popconfirm
                    title="你确定删除所选记录吗?"
                    onConfirm={() => { deleteData(selectedRowKeys) }}
                    okText="是"
                    cancelText="否"
                ><Button type="danger" icon="delete" >删除</Button>
                </Popconfirm>

            </div>
        </div>
        <Table
            dataSource={tableData}
            rowSelection={rowSelection}
            columns={columns}
            size="small"
            rowKey={r => r.strategyId}
            pagination={
                {
                    current: _current, pageSize: _pageSize, total,
                    showTotal: (total) => { return <span>共 {total} 项</span> },
                    pageSizeOptions: ["20", "50", "100", "500", "1000"],
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) => {
                        getData(1, size, _querys)
                    },
                    onChange: (page, pageSize) => { getData(page, pageSize, _querys) }
                }
            }
        />
        {/* <FormModal
            title="编辑数据源配置"
            visible={visible}
            onCancel={() => setVisible(false)}
            onSubmit={(val) => {
                saveDataSource(val);
            }}
            footer={null}
            initialValues={selectItem}
            destroyOnClose
            formList={[
                { type: "input", name: "name", label: "名称", required: true, placeholder: "数据源名称" },
                { type: "input", name: "url", label: "URL", required: true, placeholder: "数据源URL" },
                {
                    type: "select", name: "type", label: "类型", placeholder: "类型",
                    selectOptions: [{ name: "prometheus", value: "prometheus" }, { name: "zabbix", value: "zabbix" }]
                },
                { type: "input", name: "username", label: "用户名", placeholder: "用户名" },
                { type: "password", name: "password", label: "密码", placeholder: "密码" },
            ]}
        /> */}
    </div>
}

export default connect()(Alert)