/**
 * Created by YIMINE on 2019/3/22.
 */
import React from 'react'
import './index.scss'
import { Card, } from "antd";
import DataSet from "@antv/data-set";
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Legend
} from "bizcharts";
export default class statiHistogram extends React.Component {
    state = {
    };
    componentWillMount() {

    }
    render() {
        //柱状图
        const data = [
            {
                name: "已完成",
                "01:00:00": 18.9,
                "02:00:00": 28.8,
                "03:00:00": 39.3,
                "04:00:00": 81.4,
                "05:00:00": 47,
                "06:00:00": 20.3,
                "07:00:00": 24,
                "08:00:00": 35.6
            },
            {
                name: "中断",
                "01:00:00": 78.9,
                "02:00:00": 18.8,
                "03:00:00": 69.3,
                "04:00:00": 31.4,
                "05:00:00": 97,
                "06:00:00": 10.3,
                "07:00:00": 44,
                "08:00:00": 45.6

            },
            {
                name: "错误",
                "01:00:00": 28.9,
                "02:00:00": 38.8,
                "03:00:00": 49.3,
                "04:00:00": 61.4,
                "05:00:00": 27,
                "06:00:00": 50.3,
                "07:00:00": 84,
                "08:00:00": 15.6

            }
        ];
        // const data = [
        //     {
        //         '10:05:01': '100000000100',
        // '10:10:40': 1000000000,
        // '10:16:18': 1000000000,
        // '10:21:57': 1000000000,
        // '10:27:33': 1000000000,
        // '10:33:12': 1000000000,
        // '10:38:49': 1000000000,
        // '10:44:21': 1000000000,
        // '10:49:53': 1000000000,
        // '10:55:29': 1000000000,
        // 'name': "Speed-1.3.6.1.2.1.2.2.1.5.1"
        //     },
        //     {
        //         '10:05:01': 4294967295,
        //         '10:10:40': 1000000000,
        //         '10:16:18': 1000000000,
        //         '10:21:57': 4294967295,
        //         '10:27:33': 1000000000,
        //         '10:33:12': 4294967295,
        //         '10:38:49': 1000000000,
        //         '10:44:21': 4294967295,
        //         '10:49:53': 1000000000,
        //         '10:55:29': 4294967295,
        //         'name': "Speed-1.3.6.1.2.1.2.2.1.5.100"
        //     },
        //     {
        //         '10:05:01': '100000000100',
        //         '10:10:40': '100000000100',
        //         '10:16:18': '100000000100',
        //         '10:21:57': '100000000100',
        //         '10:27:33': '100000000100',
        //         '10:33:12': '100000000100',
        //         '10:38:49': '100000000100',
        //         '10:44:21': '100000000100',
        //         '10:49:53': '100000000100',
        //         '10:55:29': '100000000100',
        //         'name': "Speed-1.3.6.1.2.1.2.2.1.5.12222"
        //     }
        // ];


        const ds = new DataSet();
        const dv = ds.createView().source(data);
        dv.transform({
            type: "fold",
            fields: ["01:00:00", "02:00:00", "03:00:00", "04:00:00", "05:00:00", "06:00:00", "07:00:00", "08:00:00"],
            // fields: ["10:55:29", "10:49:53", "10:44:21", "10:38:49", "10:33:12", "10:27:33", "10:21:57", "10:16:18", "10:10:40", "10:05:01"],
            // 展开字段集
            key: "时间",
            // key字段
            value: "总计" // value字段
        });
        return (
            <Card title={<span style={{ fontWeight: 600 }}>任务统计</span>} className="Card-task">
                <Chart height={400} data={dv}
                    forceFit>

                    <Axis name="时间" />
                    <Axis name="总计" />
                    <Legend />
                    <Tooltip
                        crosshairs={{
                            type: "y"
                        }}
                    />
                    <Geom
                        type="interval"
                        position="时间*总计"
                        color={["name", ['#3aa1ff', '#ff9900', '#ff5722']]}

                        adjust={[
                            {
                                type: "dodge",
                                marginRatio: 1 / 32
                            }
                        ]}
                    />
                </Chart>
            </Card>
        );
    }
}