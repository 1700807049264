import service from "../zx-utils/request"

// 获取列表
export function fetchList(query, callback) {
    service({
        url: '/zx/providers/',
        method: 'get',
        params: query
    }).then(res => {
        callback(res)
    })
}


// delete object
export function deleteObj(keys, callback) {
    service({
        url: '/zx/providers/' + keys[0]+ '/',
        method: 'delete',
        params: {},
    }).then(res => callback(res))
}

// 批量删除
export function batchDelete(keys, callback) {
    service({
        url: '/zx/providers/',
        method: 'delete',
        params: {keys: keys.toString()},
    }).then(res => callback(res))
}

// 导出Excel
export function exportProvider(start, limit, callback) {
    service({
        url: '/zx/export-provider/' + start + '/' + limit + '/',
        method: 'get',
        params: {},
    }).then(res => callback(res))
}