import React from 'react'
import FilterForm from '@/components/FilterForm/index';
import { Card, Table, Input, Button, Icon, message, notification, Tag, Row, Col, Form } from "antd";
import CustomBreadcrumb from "@/components/CustomBreadcrumb";
import axios from '@/axios/axios_utils';
import ResUtils from "@/utils/ResUtils";
import socket from "@/socket/index";

import { SubmitButton, FormItem, Radio, Select } from "formik-antd";
const FormikInput = require("formik-antd").Input;
const FormikDatePicker = require("formik-antd").DatePicker;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik";
import moment from 'moment';

const checkDevices_url = "http://" + backup_address + "/ZJNMS_V1/";
class NewCheckDevice extends React.Component {
    state = {
        _current: 1,      //当前页码数
        _pageSize: 10,    //每页显示条数
        _querys: {},    //查询条件
        total: 0,
        loading: false,
        selectedRowKeys: [],
        selectedRows: [],
        selectedData: [],
        radioValue: "manual",

    };
    componentWillMount() {
        this.updata();
    }
    //设备列表
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current, _querys } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || _querys;
        socket.ExecuteAction('QueryResourcesPage',
            bodyJson, "/queryResource", (result) => {
                let Resources = result.resources;
                Resources.map((item, index) => {
                    item.key = index;
                    item.index = index;
                    return item.id
                });
                this.setState({
                    tableData: Resources,
                    _current: bodyJson.pageNum || 1,
                    _querys: bodyJson.pageNum || 1,
                    total: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };


    //添加监控设备
    addDevice = (value) => {
        let bodyJson = {};
        bodyJson.check_mode = value.check_mode;
        if (value.check_mode === "auto") {
            bodyJson.auto_check_config = {
                cycle: value.cycle,
                type: value.type,
                start_date: moment(value.start_date).format("YYYY-MM-DD HH:mm:ss"),
            }
        }
        let { selectedData } = this.state;
        console.log(selectedData)
        if (!selectedData[0]) {
            message.error("请选择设备!");
            return;
        }
        let devices = [];
        selectedData.map((item, index) => {
            devices.push({
                device: item,
            })

        })
        bodyJson.devices = devices;
        // console.log(bodyJson);
        axios.post(checkDevices_url + "check/check_devices", bodyJson, (res) => {
            if (res.code === "00") {
                message.success(res.msg)
                let path = {
                    pathname: `/securityBaseline`,
                };
                this.props.history.push(path);
            }
            // console.log(res)
        })
    }

    radioValueChange = e => {
        this.setState({ radioValue: e.target.value })
    }

    render() {
        let { _current, _pageSize, querys, selectedRowKeys, selectedData } = this.state;
        const columns = ResUtils.getColumns(_pageSize, _current);
        const formItemLayout = {
            labelCol: {
                xs: { span: 18 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 18 },
                sm: { span: 12 },
            },
        };
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys2, selectedRows) => {
                this.setState({
                    selectedRowKeys: selectedRowKeys2,
                    selectedRows: selectedRows,
                });
            },
            onSelect: (record, selected, selectedRows) => {
                if (selected) {
                    if (JSON.stringify(selectedData).indexOf(JSON.stringify(record)) === -1) {
                        selectedData.push(record);
                    }
                } else {
                    selectedData.splice(selectedData.findIndex(item => item._id === record._id), 1)
                }
                console.log("最终的selectedData：", selectedData)
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                // 全选
                let beforeSelectAll = [];
                if (selected) {
                    if (selectedData.length === 0) {
                        selectedData.push(...selectedRows)
                    } else {
                        selectedData.map((item, index) => {
                            if (JSON.stringify(selectedRows).indexOf(JSON.stringify(item)) === -1) {
                                // console.log("selectedData:", item)
                                beforeSelectAll.push(item);
                            }
                        })
                        selectedData.splice(0, selectedData.length, ...selectedRows, ...beforeSelectAll)
                    }
                } else {
                    changeRows.map((item, index) => {
                        selectedData.splice(selectedData.findIndex(selectItem => selectItem._id === item._id), 1)
                    })
                }
                this.setState({ selectedData })
                // console.log("onSelectAll", selected, selectedRows, selectedData)
            },
            selections: [
                {
                    key: 'cancel',
                    text: '清空选择',
                    onSelect: changableRowKeys => {
                        this.setState({ selectedRowKeys: [], selectedData: [] });
                    },
                },
            ],
        };
        return (
            <div>
                <CustomBreadcrumb arr={[{ title: '首页', to: '/home' }, { title: '安全基线管理', to: '/securityBaseline' }, "新增检查任务"]} />
                <Card title={<span style={{ fontWeight: 600 }}>新增检查设备</span>}>
                    <div className="newDeviceBox">
                        <FilterForm
                            tagClass="resource"
                            filterSubmit={(querys) => {
                                this.setState({ querys })
                                this.updata(this.state._pageSize, 1, querys)
                            }}
                            resetTab={() => {
                                this.setState({ querys: {} })
                                this.updata(this.state._pageSize, 1, {})
                            }}
                        />
                        <Table
                            dataSource={this.state.tableData}
                            rowSelection={rowSelection}
                            columns={columns}
                            loading={this.state.loading}
                            rowKey={(text) => { return text._id }}
                            size="small"
                            pagination={{
                                current: _current, pageSize: _pageSize, total: this.state.total,
                                showTotal: (total) => { return <span>共 {total} 项</span> },
                                pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                                showSizeChanger: true,
                                onShowSizeChange: (current, size) => {
                                    this.setState({ _pageSize: size })
                                    this.updata(size, 1, querys)
                                },
                                onChange: (page, pageSize) => {
                                    //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                    // console.log(page, pageSize);
                                    this.updata(pageSize, page, querys);
                                }
                            }}
                        />
                    </div>
                    <Formik
                        onSubmit={(value, actions) => { this.addDevice(value); actions.setSubmitting(false); }}
                        validate={(values) => {
                            let info = {};
                            if (values.check_mode === "auto") {
                                if (!values.cycle) {
                                    info.cycle = "执行周期不能为空";
                                }
                                if (!values.type) {
                                    info.type = "时间周期类型不能为空";
                                }
                                if (!values.start_date) {
                                    info.start_date = "开始自动检测时间不能为空";
                                }
                            }
                            if (!values.check_mode) {
                                info.check_mode = "任务执行方式不能为空";
                            }
                            return info
                        }}
                        initialValues={{
                            check_mode: "manual",
                            cycle: "",
                            type: "",
                            start_date: "",
                        }}
                    >
                        <FormikForm labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                            <Row>
                                <Col span={8}>
                                    <FormItem name={"check_mode"} key={"check_mode"} label={'任务执行方式'} {...formItemLayout} required>
                                        <Radio.Group
                                            name="check_mode"
                                            onChange={this.radioValueChange}
                                            options={[
                                                { label: "手动执行", value: "manual" },
                                                { label: "自动执行", value: "auto" },
                                            ]}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            {this.state.radioValue === "auto" ? <Row>
                                <Col span={8}>
                                    <FormItem name={"cycle"} key={"cycle"} label={'执行周期'} {...formItemLayout} required>
                                        <FormikInput name={"cycle"} style={{ width: 200 }} placeholder="请输入执行周期" />
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem name={"type"} key={"type"} label={'时间周期类型'} {...formItemLayout} required>
                                        <Select name={"type"} style={{ width: 220 }} placeholder="请选择时间周期类型">
                                            <Select.Option value="min">分钟</Select.Option>
                                            <Select.Option value="hour">小时</Select.Option>
                                            <Select.Option value="day">天</Select.Option>
                                            <Select.Option value="week">周</Select.Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem name={"start_date"} key={"start_date"} label={'开始自动检测时间'} {...formItemLayout} required>
                                        <FormikDatePicker name={"start_date"} style={{ width: 220 }} showTime placeholder="请选择开始自动检测时间" />
                                    </FormItem>
                                </Col>
                            </Row> : ""}
                            <div style={{ textAlign: "center" }}>
                                <SubmitButton type="primary" icon="check-circle" style={{ marginRight: "10px" }} disabled={false} >确认</SubmitButton>&nbsp;
                                <Button icon="close-circle" onClick={() => { this.props.history.goBack(); }}>返回</Button>
                            </div>
                        </FormikForm>
                    </Formik>
                </Card>
            </div>

        )
    }
}
const newCheckDeviceFrom = Form.create()(NewCheckDevice);
export default newCheckDeviceFrom