
import React from 'react'
import { Descriptions, Typography, List, Modal, Icon } from "antd";
import { showDetail } from '../../compentUtils/index';
import http from '@/axios/axios_utils';
import Utils from '@/utils/utils';
import api from '@/config/api/autoFind';
export function openDetail(ip) {
    http.get(api.resourceByIp, { ip }, (result) => {
        showDetail(result.data);
    })
}

