import React from 'react'
import './index.scss'
import Socket from '../../../../socket/index'
import Utils from '../../../../utils/utils'
import {
    Card, Icon, Row, Col, Tag, Button, Radio, TimePicker, DatePicker,
    Input, InputNumber, Checkbox, Select, TreeSelect, message
} from "antd";
import QueueAnim from 'rc-queue-anim';
import CustomBreadcrumb from '../../../../components/CustomBreadcrumb/index';
import moment from 'moment';
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;
// const plainOptions = ['Apple', 'Pear', 'Orange'];
// const defaultCheckedList = ['Apple', 'Orange'];


export default class Output extends React.Component {
    state = {
        checkedList: [],
        indeterminate: true,
        checkAll: false,
        inputVisible: false,
        plainOptions: [],
        otherList: [],
        tags: [],
        count: 0,
        mergeOption: []
    };
    componentDidMount() {

        const { checkedList, plainOptions, mergeOption } = this.state;
        const { value } = this.props;
        if (value.length === 0) {//为空
            this.setState({ indeterminate: false });
        }

        // let outputList = ["Cisco_show ip interface brief", , "2", "1", "3", "1", "Cisco_show ip interface brief"]
        // outputList = [...new Set(outputList)]
        // console.log(outputList);
    }
    componentWillReceiveProps(props) {
        // console.log(props)
        const { checkedList, plainOptions, mergeOption } = this.state;
        const { value } = props;
        if (checkedList.length != value.length) {//勾选值发生变化
            this.setState({ checkedList: value, });
        }

        if (plainOptions.length != props.plainOptions.length) {//选项发生变化 
            // console.log(props.plainOptions);
            const mergeOption = [...new Set(Utils.MergeArray(props.plainOptions, value))]
            // const mergeOption = Utils.MergeArray(props.plainOptions, value);
            this.setState({ plainOptions: props.plainOptions, mergeOption });
        } else if (props.plainOptions.length === 0) {
            const mergeOption = [...new Set(Utils.MergeArray(props.plainOptions, value))]
            this.setState({ plainOptions: props.plainOptions, mergeOption });
        }
        if (mergeOption.length === value.length && value.length) {//全选
            this.setState({ checkAll: true });
        }
    }

    onChange = checkedList => {
        // const { plainOptions } = this.props;
        const { mergeOption } = this.state;
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < mergeOption.length,
            checkAll: checkedList.length === mergeOption.length,
        });
        this.props.onChange(checkedList)
    };

    onCheckAllChange = e => {
        // const { plainOptions } = this.props;
        const { mergeOption } = this.state;
        this.setState({
            checkedList: e.target.checked ? mergeOption : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        this.props.onChange(e.target.checked ? mergeOption : [])
    };
    showInput = () => {
        this.setState({ inputVisible: true },
            () => this.input.focus()
        );
    };
    handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
    };

    handleInputConfirm = () => {
        const { inputValue } = this.state;
        let { mergeOption, checkedList } = this.state;
        if (inputValue && mergeOption.indexOf(inputValue) === -1 && inputValue.trim() != "") {
            mergeOption = [...mergeOption, inputValue];
            this.props.onChange([...checkedList, inputValue]);
        }
        console.log(mergeOption);
        this.setState({
            mergeOption,
            inputVisible: false,
            inputValue: '',
        });

    };

    saveInputRef = input => (this.input = input);
    render() {
        const { inputVisible, inputValue, mergeOption } = this.state;
        return (
            <div className="outputDiv">
                <div style={{ borderBottom: '1px solid #E9E9E9' }}>
                    <Checkbox
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                    >
                        全选
              </Checkbox>&nbsp;
                    <Button type="primary" icon="reload" size="small" onClick={() => { this.props.reloadParam() }}>更新参数</Button>&nbsp;

                    {/* <Select mode="tags" style={{ width: "70%" }} value={otherList} onChange={(otherList) => {
                        this.setState({ otherList })
                    }}>
                        {otherList.map((row, key) => { return <Select.Option key={key} value={row}>{row}</Select.Option> })}
                    </Select> */}
                    {/* <Button type="primary" icon="plus-circle" size="small" onClick={() => { this.props.addParam() }}>添加参数</Button> */}
                </div>
                <CheckboxGroup
                    // options={this.props.plainOptions}
                    options={mergeOption}
                    value={this.state.checkedList}
                    onChange={this.onChange}
                />

                {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        style={{ width: 78 }}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                    />
                )}
                {!inputVisible && (
                    <Tag onClick={this.showInput}
                        style={{ background: '#fff', borderStyle: 'dashed', cursor: "pointer" }}>
                        <Icon type="plus" /> New
                    </Tag>
                )}
            </div>
        );
    }
}