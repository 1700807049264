import React from 'react'
import { Card, Table, Button, message, Popconfirm, Icon, Input, Select, Row, Col, Form, Modal, Typography } from "antd";
import Socket from "../../../socket/index";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import Utils from '@/utils/utils';
import iconClass from "./iconList";
class EditMenuClass extends React.Component {
    handleSubmit = e => {
        e.preventDefault();
        let _this = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                _this.props.onSubmit(values);
                _this.props.form.resetFields();
            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { selectNode } = this.props;
        return <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onSubmit={this.handleSubmit}>
            <Form.Item
                label="菜单名称"
            >
                {getFieldDecorator('title', {
                    initialValue: selectNode.title || "",
                    rules: [{
                        required: true, message: '请输入字符!',
                    }],
                    placeholder: "菜单名称"
                })(<Input />)}
            </Form.Item>
            <Form.Item
                label="菜单URL路径"
            >
                {getFieldDecorator('key', {
                    initialValue: selectNode.key || "",
                    rules: [{
                        required: true, message: '请输入字符!',
                    }],
                    placeholder: "菜单URL路径"
                })(<Input disabled />)}
            </Form.Item>
            <Form.Item
                label="菜单图标"
            >
                {getFieldDecorator('icon', {
                    initialValue: selectNode.icon || "",
                    rules: [{
                        required: true, message: '请输入字符!',
                    }],
                    placeholder: "菜单图标"
                })(iconClass.getIconArray())}
            </Form.Item>
            <Form.Item
                label="是否导航菜单"
            >
                {getFieldDecorator('display', {
                    initialValue: selectNode.display || "",
                    rules: [{
                        required: true, message: '请输入字符!',
                    }],
                })(
                    <Select>
                        <Select.Option value={"yes"} key={"yes"}>是</Select.Option>
                        <Select.Option value={"no"} key={"no"}>否</Select.Option>
                    </Select>
                )}
            </Form.Item>
            <Form.Item
                label="对应后台接口"
            >
                {getFieldDecorator('interface', {
                    initialValue: selectNode.interface || "",
                })(
                    // <Select
                    //     mode="tags"
                    //     placeholder="请输入后台接口"
                    // >
                    // </Select>
                    <Input />
                )}
            </Form.Item>
            <Form.Item
                label="对应后台接口方法"
            >
                {getFieldDecorator('interfaceMethod', {
                    initialValue: selectNode.interfaceMethod || "",
                })(
                    <Select >
                        <Select.Option value="GET">GET</Select.Option>
                        <Select.Option value="POST">POST</Select.Option>
                        <Select.Option value="PUT">PUT</Select.Option>
                        <Select.Option value="DELETE">DELETE</Select.Option>
                        <Select.Option value="PATCH">PATCH</Select.Option>
                    </Select>
                )}
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 6 }}>
                <Button type="primary" htmlType="submit" icon="check-circle">提交修改</Button> <br />
                <Typography.Text type="secondary">备注：树形图可以右键点击节点进行编辑~</Typography.Text>
            </Form.Item>
        </Form>
    }

}
const EditMenuObj = Form.create()(EditMenuClass)
export default EditMenuObj