import React, { memo } from 'react';
import { Modal, Button } from 'antd';
import {
    SubmitButton,
    InputNumber,
    FormItem,
    Select,
    ResetButton,
    AddRowButton,
    RemoveRowButton,
    Checkbox,
    DatePicker,
    Transfer,
    Radio,
    Switch,
    AutoComplete,
} from "formik-antd"
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
const FormikTable = require("formik-antd").Table;
import { Formik } from "formik"
import './index.scss';
const formItem = (param, layout) => {
    const _labelCol = layout.labelCol;
    const _wrapperCol = layout.wrapperCol;
    const { type, name, label, required, disabled = false, placeholder = "", selectOptions = [], formContent = null, options,
        width = "100%", labelCol = _labelCol, wrapperCol = _wrapperCol, help = "" } = param;

    let formitem = null;
    switch (type) {
        case "input": formitem = <FormikInput disabled={disabled} name={name} placeholder={placeholder} style={{ width }} />; break;
        case "inputNumber": formitem = <InputNumber disabled={disabled} name={name} placeholder={placeholder} style={{ width }} />; break;
        case "checkbox": formitem = <Checkbox disabled={disabled} name={name} >{label}</Checkbox>;
            return <FormItem
                name={name}
                label={"设置"}
                required={required}
                key={name}
            >
                {formitem}
            </FormItem>
            break;
        case "password": formitem = <FormikInput.Password disabled={disabled} name={name} placeholder={placeholder} />; break;
        case "select": formitem = <Select disabled={disabled} name={name} placeholder={placeholder} >
            {selectOptions.map((item, key) => (<Select.Option key={key} value={item.value}>{item.name}</Select.Option>))}
        </Select>; break;
        case "tags": formitem = <Select mode="tags" disabled={disabled} name={name} placeholder={placeholder}></Select>; break;
        case "textArea": formitem = <FormikInput.TextArea disabled={disabled} name={name} spellCheck="false" placeholder={placeholder} />; break;
        case "code": formitem = <FormikInput.TextArea disabled={disabled} name={name} placeholder={placeholder} spellCheck="false" className="code" />; break;
        case "rangePicker": formitem = <DatePicker.RangePicker showTime disabled={disabled} name={name} style={{ width }} />; break;
        case "transfer": formitem = <Transfer
            name={name}
            titles={['数据列表', '已选择列表']}
            showSearch
            listStyle={{
                height: 300,
            }}
            style={{ width: "100%" }}
            operations={['添加', '移除']}
            filterOption={(inputValue, option) => option.title.indexOf(inputValue) > -1}
            dataSource={param.dataSource}
            render={item => item.title}

        />; break;
        case "expandForm": formitem = formContent; break;
        case "switch": formitem = <Switch disabled={disabled} name={name} checkedChildren="开" unCheckedChildren="关" />; break;
        case "radio": formitem = <Radio.Group disabled={disabled} name={name} options={options}>
            </Radio.Group>; break;
        case "autoComplete": formitem = <AutoComplete disabled={disabled} name={name} placeholder={placeholder}
            dataSource={selectOptions} style={{ width: width }}
            filterOption={(inputValue, option) =>
                option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }/>; break;
    }
    return <FormItem
        name={name}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        label={label}
        required={required}
        key={name}
        help={help}
        hasFeedback
    >
        {formitem}
    </FormItem>
}
const AlertForm = memo(
    (props) => {
        const { onSubmit, initialValues, onCancel, formList = [], labelCol = { span: 4 }, wrapperCol = { span: 18 } } = props;
        // console.log(initialValues)
        const nameArray = [];
        const labelArray = [];
        const formItemList = formList.map((item, key) => {
            if (item.required) {
                nameArray.push(item.name);
                labelArray.push(item.label);
            };
            return formItem(item, { labelCol, wrapperCol })
        })
        return <Modal
            {...props}
        >
            <div className="EditorformModalDiv">
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                        console.log(values);
                        onSubmit(values);
                        actions.setSubmitting(false)
                    }}
                    validate={(values) => {
                        let info = {};
                        nameArray.map((name, key) => {
                            if (values[name] != 0) {
                                if (!values[name]) {
                                    info[name] = labelArray[key] + "不能为空!"
                                }
                            }
                        });
                        return info
                    }}
                >
                    {() => {
                        return <FormikForm labelCol={labelCol} wrapperCol={wrapperCol}>
                            {formItemList}
                            <div style={{ textAlign: "center", borderTop: "1px solid #e2e2e2", paddingTop: 10 }}>
                                <SubmitButton disabled={false} icon="save">保存</SubmitButton>&nbsp;
                                {/* <ResetButton disabled={false} icon="load">清空表单</ResetButton>&nbsp; */}
                                <Button icon="close" onClick={() => { onCancel() }}>关闭</Button>
                            </div>
                        </FormikForm>
                    }}
                </Formik>
            </div>
        </Modal>
    }
);
export default AlertForm;
