import React, { memo } from 'react';
import {
    SubmitButton,
    FormItem,
    Select,
    ResetButton,
    DatePicker,
} from "formik-antd"
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik"
import './index.scss';
const formItem = (param) => {
    const { type, name, label, required, disabled = false, placeholder = "", selectOptions = [], width = 174 } = param;
    let formitem = null;
    switch (type) {
        case "input": formitem = <FormikInput disabled={disabled} name={name} placeholder={placeholder} />; break;
        case "password": formitem = <FormikInput.Password disabled={disabled} name={name} placeholder={placeholder} />; break;
        case "select": formitem = <Select disabled={disabled} name={name} placeholder={placeholder} style={{ width: width }} >
            {selectOptions.map((item, key) => (<Select.Option key={key} value={item.value}>{item.name}</Select.Option>))}
        </Select>; break;
        case "textArea": formitem = <FormikInput.TextArea disabled={disabled} spellCheck="false" name={name} placeholder={placeholder} />; break;
        case "code": formitem = <FormikInput.TextArea disabled={disabled} name={name} spellCheck="false" placeholder={placeholder} className="code" />; break;
        case "rangePicker": formitem = <DatePicker.RangePicker showTime disabled={disabled} name={name} style={{ width: "100%" }} />; break;
    }
    return <FormItem
        name={name}
        label={label}
        required={required}
        key={name}
        hasFeedback
    >
        {formitem}
    </FormItem>
}
function areEqual(prevProps, nextProps) {
    //比较前一次的props跟当前props，返回true表示不需要render
    if (JSON.stringify(prevProps.initialValues || {}) === JSON.stringify(nextProps.initialValues || {})) {
        return true
    } else {
        return false
    }
}
let FromProps = {}
const AlertForm = memo(
    (props) => {
        const { onSubmit, initialValues, onCancel = () => { }, formList = [], labelCol = { span: 3 }, wrapperCol = { span: 20 }, btnName = "查询" } = props;
        const nameArray = [];
        const labelArray = [];
        const formItemList = formList.map((item, key) => {
            if (item.required) {
                nameArray.push(item.name);
                labelArray.push(item.label);
            };
            return formItem(item, key)
        })
        const { setValues } = FromProps;
        // initialValues为 {}时，对筛选有影响，点查询会默认将筛选框全赋值为空
        if (setValues && JSON.stringify(initialValues) !== '{}') {
            setValues(initialValues);
        }
        return <div className="baseFormDiv">
            <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                    console.log(values);
                    onSubmit(values);
                    actions.setSubmitting(false)
                }}
                validate={(values) => {
                    let info = {};
                    nameArray.map((name, key) => {
                        if (!values[name]) {
                            info[name] = labelArray[key] + "不能为空!"
                        }
                    });
                    return info
                }}
            >
                {(fromProps) => {
                    FromProps = fromProps;
                    return <FormikForm
                        layout="inline"
                    >
                        {formItemList}
                        <SubmitButton disabled={false} icon="search">{btnName}</SubmitButton>&nbsp;
                        <ResetButton disabled={false} icon="reload" onClick={() => { onCancel() }}>重置</ResetButton>&nbsp;
                    </FormikForm>
                }}
            </Formik>
        </div>
    }
    // }, areEqual // areEqual对改变页码大小有影响，查询默认会给20
);

export default AlertForm;
