import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Divider, Popconfirm, Tag, Modal
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import { oids, custom_metrics } from '../utils/api';
import { connect } from 'react-redux'
import moment from 'moment';
import "animate.css"
import { SubmitButton, FormItem } from "formik-antd";
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik";


function OidList(props) {
    const [visible, setVisible] = useState(false);
    const [total, setTotal] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [selectItem, setSelectItem] = useState({});
    const [tableData, setTabData] = useState([]);
    const [customMetricId, setCustomMetricId] = useState('');
    const [customMetricName, setCustomMetricName] = useState('');
    const columns = [
        {
            title: '序号',
            dataIndex: 'key',
            ellipsis: true,
            width: 80,
            render: (text, item, c) => { return c + 1 + (_current - 1) * _pageSize }
        },
        {
            title: 'oid',
            dataIndex: 'oid',
            ellipsis: true
        },
        {
            title: '指标名称',
            dataIndex: 'oidname',
            ellipsis: true,
        },
        {
            title: '操作',
            width: 180,
            render: (text, item, i) => {
                return <div key={i}>
                    <a onClick={() => { setSelectItem(item); setVisible(true); }}>
                        <Icon type="edit" />&nbsp;编辑
                    </a>
                    <Divider type="vertical" />
                    <Popconfirm title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteOidData(item.id) }}
                        okText="是"
                        cancelText="否"
                    >
                        <a><Icon type="delete" />&nbsp;删除</a>
                    </Popconfirm>
                </div>
            }
        },
    ]
    useEffect(() => {//componentDidMount;
        let tableData = JSON.parse(sessionStorage.getItem('oidList')).oids;
        let custom_metric_id = JSON.parse(sessionStorage.getItem('oidList')).custom_metric_id;
        let custom_metric_name = JSON.parse(sessionStorage.getItem('oidList')).custom_metric_name;
        setTabData(tableData)
        setCustomMetricId(custom_metric_id)
        setCustomMetricName(custom_metric_name)
        setTotal(tableData.length);
        http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    function getData() {
        http.get(custom_metrics() + '/' + customMetricId, {}, (r) => {
            if (r.code === '00') {
                let oidData = r.data.oids || [];
                setTabData(oidData);
                setTotal(oidData.length);
                sessionStorage.setItem("oidList", JSON.stringify({ oids: oidData, custom_metric_id: r.data.id, custom_metric_name: r.data.name }));
            } else {
                message.error(r.msg)
            }
        })
    }
    function saveOidData(val) {
        const { id } = selectItem;
        if (id) {//修改
            http.put(oids() + '/' + id, { ...val, custom_metric_id: customMetricId }, (res) => {
                if (res.code === '00') {
                    getData();
                    message.success("修改成功");
                } else {
                    message.error(res.msg)
                }
            })
        } else {//新增
            http.post(oids(), { ...val, custom_metric_id: customMetricId }, (res) => {
                if (res.code === '00') {
                    getData();
                    set_current(1);
                    message.success("新增成功");
                } else {
                    message.error(res.msg)
                }
            })
        }
        setVisible(false)
    }
    function deleteOidData(id) {
        http.delete_body(oids() + '/' + id, {}, (r) => {
            if (r.code === '00') {
                getData();
                set_current(1);
                message.success("删除成功");
            } else {
                message.error(r.msg);
            }
        })

    }
    return <div className="monitorOidList">
        <CustomBreadcrumb arr={["监控告警", "数据采集", { title: '自定义指标', to: '/monitorAlert/dataCollection/customIndicator' }, "oid数据列表"]} />
        <Card title={customMetricName + "-oid数据列表"} extra={<div>
            <Button type="primary" icon="file-add" onClick={() => {
                setVisible(true);
                setSelectItem({});
            }}>添加</Button>&nbsp;
            <Button type="primary" icon="rollback" onClick={() => {
                sessionStorage.removeItem("oidList");
                props.history.push("/monitorAlert/dataCollection/customIndicator")
            }}>返回</Button>&nbsp;
        </div>}>
            <Table
                dataSource={tableData}
                columns={columns}
                size="small"
                rowKey={r => r.id}
                pagination={
                    {
                        current: _current, pageSize: _pageSize, total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            set_pageSize(size)
                        },
                        onChange: (page, pageSize) => { set_current(page); }
                    }
                }
            />
        </Card>
        <Modal title={selectItem.id ? "编辑oid数据" : "新增oid数据"}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
            width={"60%"}
            destroyOnClose
        >
            <Formik onSubmit={(value) => { saveOidData(value) }}
                validate={(values) => {
                    let info = {};
                    if (!values.oid) {
                        info.oid = "请输入oid";
                    }
                    return info
                }}
                initialValues={{
                    oid: selectItem.oid || '',
                    oidname: selectItem.oidname || '',
                }}
            >
                <FormikForm labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                    <FormItem name={"oid"} label={"oid"} key={"oid"} required={true} >
                        <FormikInput name={"oid"} placeholder={"请输入oid"} allowClear />
                    </FormItem>
                    <FormItem name={"oidname"} label={"指标名称"} key={"oidname"} >
                        <FormikInput name={"oidname"} placeholder={"请输入指标名称"} allowClear />
                    </FormItem>
                    <div style={{ textAlign: "right" }}>
                        <Button onClick={() => { setVisible(false) }}>取消</Button>
                        <SubmitButton style={{ marginLeft: "10px" }} disabled={false}>保存</SubmitButton>&nbsp;
                    </div>
                </FormikForm>
            </Formik>
        </Modal>
    </div>
}

export default connect()(OidList)