/**
 * Created by YIMINE on 2019/1/24.
 */
import React from 'react'
import { Table, Modal, message, Button, Row, Col, Badge, Card, notification, Icon, Tag } from 'antd';
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import OneHistogram from "@/components/OverlapHistogram/oneIndex";
import OverlapHistogram from "@/components/OverlapHistogram/index";
class WarnPage extends React.Component {
    state = {
        cpuData: {},
        memoryData: {},
        cardData: {},
        onlineData: {},
        interData: {},
        detailData: { reason: [] },
        TrapsPagesData: [],
        detailModal: false,
        Trapsloading: false,
        _current: 1, _pageSize: 10, Trapotal: 10,
    };
    componentWillMount() {
        let deviceIp = this.props.location.deviceIp;
        if (deviceIp) {
            this.warnDetail(deviceIp);
        } else {
            this.props.history.push("/resource");
        }
    }
    charDataMix(resData) {
        if (!resData) {
            return {}
        }

        let newData = resData.data.map((item) => {
            let newObj = {};
            for (let lab in item) {
                newObj["time"] = lab;
                newObj["value"] = item[lab];
            }
            return newObj
        })
        return { data: newData, warn: resData.warn };
    }
    warnDetail(deviceIp) {

        Socket.ExecuteAction('QueryMonitorDetail',
            { deviceIp }, "/QueryMonitorDetail", (result) => {
                let cpuData = this.charDataMix(result.data.cpu);
                let memoryData = this.charDataMix(result.data.memory);
                let cardData = this.charDataMix(result.data.card);
                let onlineData = this.charDataMix(result.data.online);
                let interData = result.data.interface;
                // console.log(cardData, memoryData, cpuData);
                this.setState({
                    cpuData,
                    memoryData,
                    cardData,
                    onlineData,
                    interData,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    }
    getDetailData = () => {
        let deviceIp = this.props.location.deviceIp;
        Socket.ExecuteAction('QueryWarnDetail',
            { deviceIp }, "/QueryWarnDetail", (result) => {
                this.setState({
                    detailData: result.data,
                    detailModal: true
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };
    QueryTrapsPages = (pageSize, pageNum) => {

        let deviceIp = this.props.location.deviceIp;
        this.setState({ Trapsloading: true })
        Socket.ExecuteAction('QueryTrapsPages',
            { pageNum, pageSize, deviceIp }, "/QueryTrapsPages", (result) => {
                console.log(result);
                this.setState({
                    TrapsPagesData: result.data,
                    Trapotal: result.total,
                    _current: pageNum,
                    Trapsloading: false
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };
    DelTrap = (_id) => {
        const { _current, _pageSize } = this.state;
        this.setState({ Trapsloading: true })
        Socket.ExecuteAction('DeleteTrap',
            { _id }, "/DeleteTrap", () => {
                this.QueryTrapsPages(_pageSize, _current);
                message.success("删除成功!");
                this.setState({ Trapsloading: false })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };
    newInterDataFun(data) {
        if (!data) { return {} }
        let oldData = data.data;
        let newData = [];
        let newAllData = data;

        for (let lab in oldData) {
            let vlanData = oldData[lab];
            let newVlanDataArr = [];
            for (let name in vlanData) {
                let tempo = vlanData[name];
                tempo["name"] = name;
                newVlanDataArr.push(tempo);
            }

            newData.push({ title: lab, data: newVlanDataArr });
        }
        newAllData["data"] = newData;
        return newAllData;
    }
    charList(data) {
        const newData_data = data.data;
        const newData_warn = data.warn;
        // const newData_warn = {
        //     inOctet: { rule: ">", value: 70 },
        //     outOctet: { rule: ">", value: 70 },
        //     status: { rule: "=", value: 1 }
        // };
        console.log(data);
        let strHtml = "";
        for (let wLab in newData_warn) {
            let tempObj = newData_warn[wLab];
            strHtml += wLab + tempObj["rule"] + tempObj["value"] + "  ";
        }
        return <div>
            {
                newData_data.map((Item, key) => {
                    return <div key={key} style={{ float: "left", width: "48%", border: "1px solid #e2e2e2", margin: 5, borderRadius: 5 }}><div style={style.chartTile}>{Item["title"]}图表<br />
                        ({strHtml})
                    </div>
                        <OverlapHistogram chartData={Item["data"]} height={400} />
                    </div>
                })

            }</div>
    }
    paramRen() {
        const { cardData, memoryData, cpuData, onlineData, interData, } = this.state;
        const newInterData = this.newInterDataFun(Object.assign({}, interData));//防止改变数据导致源数据被修改

        // console.log(cardData, memoryData, cpuData, onlineData, interData);
        return <div><Row>
            <Col span={6}>
                <p style={style.chartTile}>在线状态</p>
                <Table
                    columns={[{ title: "时间", dataIndex: "time" }, { title: "状态", dataIndex: "value", render: (text) => { return <Badge status={text ? "success" : "default"} /> } }]}
                    dataSource={onlineData.data}
                    size="small"
                    pagination={false}
                />
            </Col>
            <Col span={6}>
                <p style={style.chartTile}>CPU图表</p>
                <OneHistogram chartData={cpuData} /></Col>
            <Col span={6}>
                <p style={style.chartTile}>内存图表</p>
                <OneHistogram chartData={memoryData} /></Col>
            <Col span={6}>
                <p style={style.chartTile}>Card图表</p>
                <OneHistogram chartData={cardData} /></Col>
        </Row>
            <Row>
                {this.charList(newInterData)}
            </Row>

        </div>;
    }

    render() {
        const { detailModal, detailData, Trapsloading, _current, _pageSize, Trapotal, TrapsPagesData } = this.state;
        const trapColum = [
            { title: "时间", dataIndex: "addTime" },
            {
                title: "内容", dataIndex: "data", render: (text) => {
                    return Utils.hidenText(JSON.stringify(text));
                }
            },
            {
                title: "操作", render: (text, item) => {
                    return <Button type="danger" onClick={() => { this.DelTrap(item._id); }}>删除</Button>
                }
            },
        ]
        return (
            <Card
                title={<div>监控详情       (设备IP:{this.props.location.deviceIp})</div>}
                extra={<div><Button type="primary" icon={'rollback'} onClick={() => { history.go(-1) }}>返回</Button>
                    <Button type="primary" onClick={() => { this.getDetailData(); this.QueryTrapsPages(10, 1); }}>告警详情</Button></div>}
            >
                {this.paramRen()}

                <Modal
                    title="告警详情"
                    visible={detailModal}
                    onCancel={() => this.setState({ detailModal: false })}
                    onOk={() => this.setState({ detailModal: false })}
                    width="70%"
                    style={{ top: "20px" }}
                // destroyOnClose
                >
                    <p>设备IP:{this.props.location.deviceIp}</p>
                    <p>告警数量:<Tag color="#ff0000">{detailData.num}</Tag></p>
                    <p>告警详情:{detailData.reason.map((item, key) => {
                        return <Tag key={key} color="#ff0000">{item}</Tag>
                    })}</p>
                    <Table
                        dataSource={TrapsPagesData}
                        columns={trapColum}
                        loading={Trapsloading}
                        expandedRowRender={(record) => {

                            return <table style={{ lineHeight: 2, width: "80%" }}><thead>
                                <tr><th colSpan="2">告警详情</th></tr>
                                {record.data.map((textAll, key00) => {
                                    //tcpMIB:1.3.6.1.4.1
                                    let textArr = textAll.split(":");
                                    return <tr key={key00}><td>{textArr[0]}</td><td>{textArr[1]}</td></tr>
                                })}

                            </thead></table>
                        }}
                        // <p style={{ margin: 0, maxWidth: 1000 }}>{JSON.stringify(record.data)}</p>}
                        size="small"
                        pagination={{
                            current: _current, pageSize: _pageSize, total: Trapotal,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                console.log(page, pageSize);
                                this.QueryTrapsPages(pageSize, page, {});
                            }
                        }}
                    />
                </Modal>
            </Card>
        );
    }
}
const style = {
    chartTile: {
        fontSize: 18,
        color: '#333',
        display: 'block',
        margin: "auto",
        textAlign: "center",
        width: "100%",
        padding: 10
    }
}
export default WarnPage;