
import React, { useState, useEffect } from 'react'
import {
  Card, Table, Button, message, Checkbox
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import BaseForm from '@/components/FormikForm/index';
import { SetDeviceCmdRespDefault, SetModelCmdRespDefault } from '../utils/api';
import http from '@/axios/axios_utils';
import { connect } from 'react-redux'
import "./index.scss"
import "animate.css"

function CommandRespList(props) {
  const [visible, setVisible] = useState(false);
  const [total,] = useState(10);
  const [_pageSize] = useState(20);
  const [_current] = useState(1);
  const [selectItem, setSelectItem] = useState({});
  const [tableData, setTabData] = useState(props.data || [])
  const [defalutId, setDefalutId] = useState(props.default_resp_id);
  const [selectedRowKeys, setRowKeys] = useState([])

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (Keys) => {
      setRowKeys(Keys);
    },
    selections: [
      {
        key: 'cencel',
        text: '清空选择',
        onSelect: () => { setRowKeys([]); },
      },
    ],
  };
  const columns = [
    // {
    //   title: 'resp_id',
    //   dataIndex: 'resp_id',
    //   key: 'resp_id',
    //   render: text => <a>{text}</a>,
    // },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    // {
    //   title: '描述',
    //   dataIndex: 'resp_desc',
    //   key: 'resp_desc',
    // },
    {
      title: '设为默认',
      dataIndex: 'id',
      render: (a, b, c) => (
        <Checkbox checked={defalutId === a} onChange={(e) => {
          if (e.target.checked) {
            setDefalutResp(b.id);
          }
        }} />
      ),
    },
    // {
    //   title: '操作',
    //   render: () => (
    //     <div  >
    //       <Popconfirm
    //         title="你确定删除所选记录吗?"
    //         onConfirm={() => { }}
    //         okText="是"
    //         cancelText="否"
    //       > <a>删除</a>
    //       </Popconfirm>

    //     </div>
    //   ),
    // },
  ];
  useEffect(() => {//componentDidMount
    setSelectItem(tableData[0] || {});
    return () => { //componentWillUnmount
    }
  }, []);
  useEffect(() => {//监听props.data变化
    setTabData(props.data)
    return () => {
    }
  }, [props.data]);
  function setDefalutResp(default_resp_id) {
    const { ip, type, vendor, version } = props.selectItem;
    let httpUrl = SetModelCmdRespDefault();
    let param = {
      class: props.selectItem.class,
      type: type,
      vendor: vendor,
      version: version,
      cmd: props.cmd,
      default_resp_id
    };
    if (ip) { httpUrl = SetDeviceCmdRespDefault(); param.ip = ip }
    http.post(httpUrl, param, (r) => {
      setDefalutId(default_resp_id);
      message.success("操作成功!")
    })
  }
  function setRowClass(r) {
    return r.name === selectItem.name ? 'click-Row-Style' : '';
  }
  function changeSelectItem(r) {
    const { onRowChange } = props
    setSelectItem(r);
    onRowChange(r);
  }
  return <div className="CommandRespList">
    {/* <div className="searchDiv">
      <BaseForm
        onSubmit={() => {
        }}
        initialValues={{}}
        formList={[
          { type: "input", name: "name", label: "名称", placeholder: "名称" },
        ]}
      />
    </div> */}
    <Table
      dataSource={tableData}
      // rowSelection={rowSelection}
      columns={columns}
      size="small"
      rowKey={r => r.name}
      // pagination={
      //   {
      //     current: _current, pageSize: _pageSize, total,
      //     onChange: () => {

      //     }
      //   }
      // }
      pagination={false}
      onRow={record => {
        return { onClick: event => { changeSelectItem(record); } }// 点击行
      }}
      rowClassName={(r) => setRowClass(r)}
    />

  </div>
}

export default connect()(CommandRespList)