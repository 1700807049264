import React from 'react'
import axios from '@/axios/axios_utils';
import DyHeadTable from '@/components/DyHeadTable/index';
import FilterForm from '@/components/FilterForm/index';
import DumbTerminalUtils from "@/utils/DumbTerminalUtils";
import { Table, Collapse, Card, Button, message, Icon, Row, Col, Input, Alert } from "antd";
import moment from 'moment';
const dumbTerminal_Url = "http://" + backup_address + "/ZJNMS_V1/dumb_termial/";

class DumbTerminal extends React.Component {
    state = {
        tableData: [], // 表格数据
        current_page: 1,  // 当前页
        pageSize: 20,
        total: 0,
        loading: false,
        query: {},
        selectedRowKeys: [],
        selectedData: [],
    }
    componentWillMount() {
        this.getTableData();
    }
    componentDidMount() {
        this.props.onRef(this);
    }
    getTableData(page, page_size, querys, loadingFlag) {
        if (loadingFlag !== "noLoading") {
            this.setState({ loading: true, });
        }
        const { current_page, pageSize, query } = this.state;
        let query2 = querys || query;
        let bodyJson = {
            page_no: page || current_page,
            page_size: page_size || pageSize,
            ...query2
        };
        axios.get(dumbTerminal_Url + "recent_del", bodyJson, (result) => {
            if (result.code === "00") {
                console.log(result)
                if (result.count !== 0 && result.data.length === 0) {
                    let pageNum = result.count % pageSize > 0 ? parseInt(result.count / pageSize) + 1 : parseInt(result.count / pageSize);
                    this.getTableData(pageNum, page_size, querys)
                } else {
                    this.setState({
                        tableData: result.data,
                        total: result.count,
                        current_page: page || current_page || 1,
                        loading: false,
                        query: query2,
                    })
                }
            } else {
                message.error("查询失败");
                this.setState({ loading: false })
            }
        })
    }
    revertDumb() {
        let { selectedData } = this.state;
        axios.post(dumbTerminal_Url + "terminal", selectedData, (res) => {
            if (res.code === "00") {
                this.setState({ selectedData: [], selectedRowKeys: [], });
                this.getTableData();
                message.success("还原成功");
            } else {
                message.error("还原失败")
            }
        })
    }

    render() {
        const { current_page, total, pageSize, query, selectedRowKeys, selectedData } = this.state;
        const columns = [...DumbTerminalUtils.getDumbTerminalColumns(current_page, pageSize, 'delete')]
        const formList = [
            {
                label: "月删除",
                type: 'monthSelect',
                field: "month_del",
                initialValue: "",
                placeholder: '月删除'
            },
        ]

        let scrollX = 0;
        columns.map((item, index) => {
            scrollX += item.width;
        })
        scrollX = scrollX + 260;

        // 多选
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            hideDefaultSelections: 'true',
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });
            },
            onSelect: (record, selected, selectedRows) => { // 选择单行
                if (selected) {
                    if (JSON.stringify(selectedData).indexOf(JSON.stringify(record)) === -1) {
                        selectedData.push(record);
                    }
                } else {
                    selectedData.splice(selectedData.findIndex(item => item._id === record._id), 1)
                }
                // console.log("最终的selectedData：", selectedData)
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                let beforeSelectAll = [];
                if (selected) {
                    if (selectedData.length === 0) {
                        selectedData.push(...selectedRows)
                    } else {
                        selectedData.map((item, index) => {
                            if (JSON.stringify(selectedRows).indexOf(JSON.stringify(item)) === -1) {
                                // console.log("selectedData:", item)
                                beforeSelectAll.push(item);
                            }
                        })
                        selectedData.splice(0, selectedData.length, ...selectedRows, ...beforeSelectAll)
                    }
                } else {
                    changeRows.map((item, index) => {
                        selectedData.splice(selectedData.findIndex(selectItem => selectItem._id === item._id), 1)
                    })
                }
                this.setState({ selectedData })
                // console.log("onSelectAll", selected, selectedRows, selectedData)
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [], selectedData: [] });
                    },
                },
            ],
        };


        return (
            <div className="dumbTerminalBody">
                <div className="searchDiv">
                    <Collapse defaultActiveKey={"key"}>
                        <Collapse.Panel header="筛选条件" key={"key"}>
                            {query.month_del ?
                                <Alert message={`${moment(query.month_del).format("YYYY年M月")}删除的数量：${total}`} type="info" closable style={{ marginBottom: 8 }} /> : ''}
                            <FilterForm formList={formList}
                                filterSubmit={(querys) => {
                                    querys.month_del ? querys.month_del = moment(querys.month_del).format("YYYY-MM") : "";
                                    this.getTableData(1, pageSize, querys);
                                }}
                                resetTab={() => {
                                    this.getTableData(1, pageSize, {})
                                }}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </div>
                <Card
                    title={<span style={{ fontWeight: 600 }}>最近删除列表</span>}
                    style={{ marginTop: 8 }}
                    extra={
                        <Row>
                            <Button type="primary" icon="rollback" onClick={() => { this.revertDumb() }}>还原</Button>
                        </Row>
                    }
                >
                    <DyHeadTable
                        dataSource={this.state.tableData || []}
                        columns={columns}
                        size='small'
                        loading={this.state.loading}
                        rowKey={(text) => { return text._id }}
                        rowSelection={rowSelection}
                        pagination={{
                            pageSize: pageSize,
                            current: current_page,
                            total: total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onChange: (page, pageSize) => {
                                this.setState({ current_page: page, pageSize: pageSize });
                                this.getTableData(page, pageSize);
                            },
                            onShowSizeChange: (current, size) => {
                                this.setState({ current_page: current, pageSize: size });
                                this.getTableData(1, size)
                            }
                        }}
                    />
                </Card>
            </div>
        )
    }
}

export default DumbTerminal;
