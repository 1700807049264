import { Table, Input, Cascader } from "antd"
import React, { useEffect, useState } from 'react'
import { Card } from "antd"
import http from '@/axios/axios_utils';
import $ from "jquery";
import ReactECharts from 'echarts-for-react';
import { if_flow_rate_change } from "@/pages/monitorAlert/dataCollection/utils/api"
import DevicePort from "@/pages/BigScreen/devicePort/index";
import SliceShape from '@/components/sliceShape/index';
import * as echarts from 'echarts';
function SampleTable() {
  const [echertData, setEchertData] = useState({})
  useEffect(() => {//componentDidMount

    return () => { //componentWillUnmount

    }
  }, []);
  const CascaderOption = [
    {
      value: 'zhejiang',
      label: 'Zhejiang',
      children: [
        {
          value: 'hangzhou',
          label: 'Hangzhou',
          children: [
            {
              value: 'xihu',
              label: 'West Lake',
            },
          ],
        },
      ],
    },
    {
      value: 'jiangsu',
      label: 'Jiangsu',
      children: [
        {
          value: 'nanjing',
          label: 'Nanjing',
          children: [
            {
              value: 'zhonghuamen',
              label: 'Zhong Hua Men',
            },
          ],
        },
      ],
    },
  ];


  return (
    <Card> <SliceShape />
    </Card>)
}
export default SampleTable