import React from 'react'
import { Input, Form, Button, } from 'antd'
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        span: 8
    },
    wrapperCol: {
        span: 8
    },
};
const formItemLayout2 = {
    labelCol: {
        span: 0
    },
    wrapperCol: {
        span: 24
    },
};
class InputForm extends React.Component {

    handleFilterSubmit = () => {
        let fieldsValue = this.props.form.getFieldsValue();
        this.props.filterSubmit(fieldsValue);
    };

    lastStup = () => {
        this.props.lastStup();
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form >
                <FormItem label={"用户名"} key={"newUserName"} {...formItemLayout}>
                    {
                        getFieldDecorator("newUserName", {
                            initialValue: ""
                        })(
                            <Input type="text" placeholder={"用户名"} />
                        )
                    }
                </FormItem>
                <FormItem label={"密码"} key={"newPwd"} {...formItemLayout}>
                    {
                        getFieldDecorator("newPwd", {
                            initialValue: ""
                        })(
                            <Input type="text" placeholder={"密码"} />
                        )
                    }
                </FormItem><hr style={{ border: "0.5px solid #e2e2e2" }} />
                <FormItem label=" " colon={false} {...formItemLayout2}>
                    <div style={{ textAlign: "center" }}>
                        <Button onClick={this.lastStup} icon="step-backward">上一步</Button>
                        <Button type="primary" style={{ margin: '0 20px' }}
                            icon="check-circle"
                            onClick={this.handleFilterSubmit}>确定</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}
export default Form.create({})(InputForm);