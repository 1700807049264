
import React from "react"
import {
    Card, Form, Input, Radio, Spin, Modal, Icon, Select, message, Table,
    notification, Button, Typography, Tooltip
} from 'antd';
import Socket from "@/socket/index";
import AxiosObj from "@/axios/index";
import Utils from "@/utils/utils";
import FilterForm from '@/components/FilterForm/index';
import ResUtils from "@/utils/ResUtils";
export default class SelectDev extends React.Component {
    state = {
        _current: 1,
        _pageSize: 20,
        total: 0,
        querys: this.props.querys || {},
        // querys: this.props.querys || { "resourceParams.deviceFirm": "cisco" },
        DevTableData: [],
        selectedRows: [],
        selectedRowKeysData: [],
        selectedRowsAll: {},
        selectedData: [],
    }
    componentWillMount() {
        // const firm = Utils.ChineseToenglish(this.props.querys.firm);
        // this.updataDev(null, null, {
        //     "resourceParams.deviceFirm": firm,
        //     "resourceParams.extend_attrs.acl_baseline": this.props.querys.name,
        // });
        this.updataDev(null, null, this.props.querys);
    }
    updataDev = (pageSize, pageNum, querys2) => {
        this.setState({ loading: true, });
        let { _pageSize, _current, querys } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys2 || querys;
        Socket.ExecuteAction('QueryResourcesPage',
            bodyJson, "/queryResource", (result) => {
                let Resources = result.resources;
                Resources.map((item, index) => {
                    item.key = index;
                    item.index = index;
                    return item
                });
                this.setState({
                    DevTableData: Resources,
                    _current: pageNum || 1,
                    total: result.total,
                    loading: false,
                    querys: bodyJson.querys
                })
            });

    };
    aclComparison = () => {
        const _this = this;
        Modal.confirm({
            title: "消息提示",
            content: "可能需要等待，是否需要更新差异？",
            maskClosable: true,
            okText: "更新",
            onOk() {
                _this.setState({ loading: true, });
                AxiosObj.HTTP_POST('acl', "aclComparison", {}, (result) => {
                    message.success(result.msg);
                    _this.updataDev(null, null, _this.props.querys);
                });
            }
        })
    };
    render() {
        const { _current, _pageSize, querys, total, selectedRowsAll, } = this.state;
        let { selectedData } = this.state;
        let { selectedRowKeys, checkType } = this.props;
        let tempCol = ResUtils.getColumns(_pageSize, _current);
        // tempCol.splice(0, 2);
        // tempCol.splice(4, 1);
        const columns = [...tempCol,];
        selectedData = this.props.selectedRows;
        // console.log("render:", this.props, selectedData)
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            hideDefaultSelections: 'true',
            onChange: (selectedRowKeys, selectedRows,) => {
                // selectedRowsAll[_current + ""] = selectedRows;
                // let allrows = [];
                // Object.values(selectedRowsAll).map((rows) => {
                //     allrows = [...allrows, ...rows]
                // });
                this.props.onChange(selectedRowKeys, selectedData);
                this.setState({ selectedData })
                // this.setState({ selectedRowsAll, })
            },
            type: checkType || "checkbox",
            onSelect: (record, selected, selectedRows) => {
                if (selected) {
                    if (JSON.stringify(selectedData).indexOf(JSON.stringify(record)) === -1) {
                        selectedData.push(record);
                    }
                } else {
                    selectedData.splice(selectedData.findIndex(item => item._id === record._id), 1)
                }
                // console.log("最终的selectedData：", selectedData)
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                let beforeSelectAll = [];
                if (selected) {
                    if (selectedData.length === 0) {
                        selectedData.push(...selectedRows)
                    } else {
                        selectedData.map((item, index) => {
                            if (JSON.stringify(selectedRows).indexOf(JSON.stringify(item)) === -1) {
                                // console.log("selectedData:", item)
                                beforeSelectAll.push(item);
                            }
                        })
                        selectedData.splice(0, selectedData.length, ...selectedRows, ...beforeSelectAll)
                    }
                } else {
                    changeRows.map((item, index) => {
                        selectedData.splice(selectedData.findIndex(selectItem => selectItem._id === item._id), 1)
                    })
                }
                // console.log(this.state, selectedData)
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.props.onChange([], []);
                        // this.setState({ selectedRowKeys: [], selectedData: [] });
                    },
                },
            ],
        };
        return <div style={{ border: "1px solid #e2e2e2", padding: 10 }}>
            <FilterForm
                tagClass="resource"
                filterSubmit={(querys) => {
                    this.updataDev(_pageSize, 1, querys)
                    // this.updataDev(_pageSize, 1, {"resourceParams.deviceFirm": "cisco", ...querys})
                }}
                resetTab={() => {
                    // this.updataDev(_pageSize, 1, { "resourceParams.deviceFirm": "cisco" })
                    this.updataDev(_pageSize, 1, {})
                }}
            />
            <div style={{ zoom: 0.9, overflowY: "auto", overflowX: "hidden", maxHeight: "800px" }}>
                <Table
                    dataSource={this.state.DevTableData}
                    rowSelection={rowSelection}
                    columns={columns}
                    loading={this.state.loading}
                    rowKey={(res) => {
                        // return res.resourceParams.deviceIp
                        return JSON.stringify({ name: res.resourceName, ip: res.resourceParams.deviceIp })
                    }}
                    size="small"
                    pagination={{
                        current: _current, pageSize: _pageSize, total: total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            this.setState({ _pageSize: size })
                            this.updataDev(size, 1, querys)
                        },
                        onChange: (page, pageSize) => {
                            this.updataDev(pageSize, page, querys);
                        }
                    }}
                />
            </div>
        </div>
    }

}
