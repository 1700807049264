import React from 'react'
import { Button, Card, Form, Icon, Input, message, notification, Select, Tabs } from 'antd';


import CustomBreadcrumb from '../../components/CustomBreadcrumb/index';

import Axios from "../../axios/axios";

const { Option } = Select;
const TabPane = Tabs.TabPane;
const TextArea = Input.TextArea;

class newIpSearch extends React.Component {

    state = {
        visible: false,
        originState: '', //记录最初始的状态
        ipSegmentId: ''
    }

    componentDidMount() {
        let thisTemp = this;
        let Id = this.props.match.params.Id;

        if (Id) {
            this.setState({
                cardTitle: '编辑IP地址',
                ipSegmentId: Id.replace(":", "")
            });
            Axios.queryData('ip/ipSearch/', { id: Id.replace(":", "") }, (res) => {
                this.setState({ IPSegment: res, originState: res.state.toString() })
                this.props.form.setFieldsValue({
                    ip: res.ip,
                    assign: res.assign,
                    ipSegment_name: res.ipSegment_name,
                    state: res.state.toString()
                })
            });
            this.setState({ visible: true },)

        } else {
            this.setState({ visible: false },)
        }
    }

    /* handleSubmit */
    handleSubmit = (e) => {
        let { IPSegment } = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, value) => {
            let body = {};
            body.id = this.state.ipSegmentId
            body.assign = value.assign
            body.state = value.state
            if (!err) {
                const { data: res, status } = await React.$http_ip.patch('ip/ipSearch/' + body.id + '/', body)
                if (status !== 200) return message.error('修改失败')
                message.success('修改成功');
                this.props.history.goBack();
                return
                Axios.PATCH('ip/ipSearch/', body, (res) => {
                    if (res.data.id.toString() === body.id.toString()) {
                        message.success('修改成功');
                        this.props.history.goBack();
                    } else {
                        notification.open({
                            message: '修改失败',
                            description: res.data.detail,
                            icon: <Icon type="frown" style={{ color: 'red' }} />,
                        });
                    }

                }, (res) => {
                    message.error(JSON.stringify(res.response.data))
                })
            }
        });
    }
    /* /handleSubmit */

    // AssignTo
    assignTo = (e) => {
        const value = e.target.value
        if (value === '' || value === undefined) {
            this.props.form.setFieldsValue({ state: this.state.originState })
            return
        }
        this.props.form.setFieldsValue({ state: '2' })
    }

    ipStatus = value => {
        if (value === '2' || value === '3' || value === null) return
        this.props.form.setFieldsValue({ assign: '' })
    }
    render = () => {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 18 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 18 },
                sm: { span: 12 },
            },
        };

        const { visible, done, Tags, Roles, cardTitle } = this.state;
        return (
            <div>
                <CustomBreadcrumb arr={['权限管理', { title: 'IP分配', to: '/IpAdmin/iprecord' }, cardTitle]} />

                <Card title={<span style={{ fontWeight: 600 }}>{cardTitle}</span>}>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item label={'IP地址'}
                            {...formItemLayout}
                        >
                            {
                                getFieldDecorator('ip', {
                                    rules: [
                                        { type: 'string', message: '请输入正确的字符！' },
                                        // {pattern: new RegExp(/(^((25[0-5]|2[0-4]\d|[0-1]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[0-1]?\d\d?)\/[1-3]{0,1}[0-9]{1}$)/, 'g'),
                                        //     message: '网段格式不正确！'}

                                    ]
                                })(<Input disabled={this.state.visible} />)
                            }
                        </Form.Item>
                        <Form.Item label={'网段'}
                            {...formItemLayout}
                        >
                            {
                                getFieldDecorator('ipSegment_name', {
                                    rules: [
                                        { type: 'string', message: '请输入正确的字符！' },
                                    ]
                                })(<Input disabled={this.state.visible} />)
                            }
                        </Form.Item>
                        <Form.Item label={'分配到'}
                            {...formItemLayout}
                        >
                            {
                                getFieldDecorator('assign', {
                                    rules: [
                                        { type: 'string', message: '请输入正确的字符！' },
                                        { max: 250, message: '不得超过250个字符' }
                                    ]
                                })(<Input onChange={this.assignTo} allowClear placeholder='填写分配给谁用' />)
                            }
                        </Form.Item>
                        <Form.Item label={'状态'}
                            {...formItemLayout}
                        >
                            {
                                getFieldDecorator('state', {
                                    rules: [
                                    ]
                                })(
                                    <Select
                                        style={{ width: 200 }}
                                        placeholder='IP状态'
                                        onChange={this.ipStatus}
                                    >
                                        <Option value="0">保留</Option>
                                        <Option value="1">空闲</Option>
                                        <Option value="2">已分配</Option>
                                        <Option value="3">在线(占用)</Option>
                                    </Select>
                                )
                            }
                        </Form.Item>
                        <Form.Item
                            label=" "
                            colon={false}
                            {...formItemLayout}
                        >
                            <div>
                                <Button type="primary" icon="check-circle" htmlType="submit" style={{ width: 150 }}>
                                    保存
                                </Button>
                                <Button type="danger" icon="close-circle" style={{ width: 150, marginLeft: 10 }}
                                    onClick={() => {
                                        this.props.history.goBack();
                                    }}>
                                    取消
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        )
    }
}

const newAutoObjectFrom = Form.create()(newIpSearch);
export default newAutoObjectFrom;
