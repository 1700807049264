/**
 * Created by YIMINE on 2019/3/22.
 */
import React from 'react'
import {Card, Col, Progress, Row, Table, Tooltip as ToolTip, List, Badge, message} from "antd";
import QueueAnim from 'rc-queue-anim';
import 'echarts/lib/chart/line';
import AxiosObj from "../../../axios";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import {Axis, Chart, Coord, Geom, Label, Tooltip, Legend, View, Guide} from 'bizcharts';
import DataSet from "@antv/data-set";
import './index.scss'
import moment from "moment";

// 监控告警后端服务器地址  monitor_address 配置地址：public/flow/config.js
const backUp_Url = monitor_address;

export default class Overview extends React.Component {
    state = {
        loading: false,
        prometheus: 0,
        alertmanager: 0,
        snmpexporter: 0,
        unhealthy:[
            // "10.1.1.1",
            // "10.1.1.2"
        ],  //异常主机ip
        alertData :[
            // { data: '6天前', value: 50 },
            // { data: '5天前', value: 30 },
            // { data: '4天前', value: 50 },
            // { data: '3天前', value: 20 },
            // { data: '2天前', value: 33 },
            // { data: '1天前', value: 5 },
            // { data: '今天', value: 5 },
        ],
        dataSource : [
            // {
            //     index: '1',
            //     alarmName: 'linux',
            //     ip: "10.32.79.xx" ,
            //     status: '否',
            //     time: '2020-03-23',
            //     user: 'admin',
            // },
            // {
            //     index: '2',
            //     alarmName: 'linux',
            //     ip: "10.32.79.xx" ,
            //     status: '否',
            //     time: '2020-03-23',
            //     user: 'admin',
            // },
        ],
        columns : [
        {
            title: '序号',
            align: 'center',
            dataIndex: 'index',
            render: (text) => { return <span>{text}</span> }
        }, {
            title: '名称',
            align: 'center',
            dataIndex: 'alarmName'
        }, {
            title: 'ip',
            align: 'center',
            dataIndex: 'ip',

        },
        //     {
        //     title: '处理状态',
        //     align: 'center',
        //     dataIndex: 'status',
        // },
            {
            title: '时间',
            align: 'center',
            dataIndex: 'time',
        }, {
            title: '描述',
            align: 'center',
            dataIndex: 'describe',
            },
        //     {
        //     title: '处理人',
        //     align: 'center',
        //     dataIndex: 'user',
        //
        // }
        ],
        tendency : [],
    };
    // 获取各个平台状态
    getServerStatus=()=>{
        AxiosObj.MA_HTTP_GET(backUp_Url+"/snmpexporter/serverStatus",(response)=>{
            //console.log(response)
            //console.log(response.data)
            let data =response.data;
            if (data.prometheus){
                this.state.prometheus=100;
            }
            if (data.alertmanager) {
                this.state.alertmanager=100;
            }
            let snmpTotal = parseInt(data.snmpexporter.healthy)+parseInt(data.snmpexporter.unhealthy);
            if(parseInt(snmpTotal)>0){
                this.state.snmpexporter=parseInt(100*(parseInt(data.snmpexporter.healthy)/parseInt(snmpTotal)));
                // console.log(parseInt(100*(parseInt(data.snmpexporter.healthy)/parseInt(snmpTotal))))
            }else{
                this.state.snmpexporter=0;
            }
            let tmpUnhealthy = [];
            for(let i in data.unhealthy){
                if(data.unhealthy[i].labels.instance){
                    tmpUnhealthy.push(data.unhealthy[i].labels.instance)
                }
            }
            this.setState({
                prometheus : this.state.prometheus,
                alertmanager : this.state.alertmanager,
                snmpexporter : this.state.snmpexporter,
                unhealthy : tmpUnhealthy,
            })
            console.log(this.state.dataSource)

        },(error)=>{
            message.error(error)
        })

    }
    //获取告警列表
    getAlertList=()=>{
        AxiosObj.MA_HTTP_GET(backUp_Url+"/snmpexporter/alertList",(response)=>{
            //console.log(response)
            //console.log(response.data)
            let alertList =response.data.alertList;
            let tendency = response.data.tendency;
            let list = [];
            console.log(alertList);
            for(let i in alertList){
                let map ={}
                console.log(i);
                console.log(alertList[i].labels.ifIndex);
                map['key'] = parseInt(i)+1;
                map['index'] = parseInt(i)+1;
                map['alarmName'] = alertList[i].labels.alertname;
                map['ip'] = alertList[i].labels.instance;
                let statusPassTime = moment(alertList[i].endsAt).format('YYYY-MM-DD HH:mm:ss');
                map['time'] = statusPassTime;
                console.log(statusPassTime);
                map['describe'] = alertList[i].annotations.description;
                list[i] = map;
            };
            this.setState({
                dataSource : list,
                tendency : tendency,
            })
            console.log(tendency);
            this.getOption();

        },(error)=>{
            message.error(error)
        })

    }
    //获取告警趋势折线图数据
    getOption = ()=>{
        let data = [];
        let {tendency} = this.state;
        console.log(tendency)
        for(let i in tendency){
            let map = {};
            map["value"] = tendency[i]
            if(i ==6){
                map["data"] = "今天"
            }else{
                map["data"] = parseInt(6-i)+"天前"
            }
            data[i] = map;
        }
        this.setState({
            alertData :data
        })
        console.log(this.state.alertData)
    }
    componentWillMount(){
        this.getAlertList();
        this.getServerStatus();
    }


    render() {
        let { loading, prometheus, alertmanager,snmpexporter,dataSource, columns, alertData,unhealthy } = this.state;
        // const  data = [
        //     { data: '6天前', value: 50 },
        //     { data: '5天前', value: 30 },
        //     { data: '4天前', value: 50 },
        //     { data: '3天前', value: 20 },
        //     { data: '2天前', value: 33 },
        //     { data: '1天前', value: 5 },
        //     { data: '今天', value: 5 },
        // ]
        //监控图表样式
        const snmp=(errorNum,total,errorDescribe,totalDescribe,module)=>{
            // let errorNum = errorNum || 0;
            // let total = param.total || 0;
            // let errorDescribe = param.errorDescribe || ''
            // let totalDescribe = param.totalDescribe || ""
            // let module = param.module || ""
            return <Card hoverable={false} bordered={true} style={{background:"#fbfbfb"}}>
                <Card.Grid hoverable={false} style={{width:'100%', textAlign:'center', border:0,boxShadow:"0 0 0 0"}}></Card.Grid>
                <Card.Grid hoverable={false} style={{width:'50%', textAlign:'center', border:0, boxShadow:"0 0 0 0"}}><span style={{ fontWeight: 400 }}>{errorNum}</span></Card.Grid>
                <Card.Grid hoverable={false} style={{width:'50%', textAlign:'center', border:0, boxShadow:"0 0 0 0"}}><span style={{ fontWeight: 400 }}>{total}</span></Card.Grid>
                <Card.Grid hoverable={false} style={{width:'50%', textAlign:'center', border:0, boxShadow:"0 0 0 0"}}><span style={{ fontWeight: 400 }}>{errorDescribe}</span></Card.Grid>
                <Card.Grid hoverable={false} style={{width:'50%', textAlign:'center', border:0, boxShadow:"0 0 0 0"}}><span style={{ fontWeight: 400 }}>{totalDescribe}</span></Card.Grid>
                <Card.Grid hoverable={false} style={{width:'100%', textAlign:'center', border:0, boxShadow:"0 0 0 0"}}><span style={{ fontWeight: 600 }}>{module}</span></Card.Grid>
            </Card>
        }
        //告警平台
        const alert = ()=>{
            const  tip =     <List
                size="small"
                bordered
                dataSource={this.state.unhealthy}
                renderItem={item => <List.Item><Badge status="error" />{item}</List.Item>}
            />
            let {unhealthy} = this.state
            if(unhealthy.length>0){
                return   <ToolTip placement="topRight" overlayClassName="top" style={{display:"none"}} title={tip}>
                    <Progress type="circle" percent={snmpexporter}  width={180}  strokeWidth={10} />
                </ToolTip>
            }else{
                return  <Progress type="circle" percent={snmpexporter}  width={180}  strokeWidth={10} />
            }
        }
        // const  alert =     <List
        //     size="small"
        //     bordered
        //     dataSource={unhealthy}
        //     renderItem={item => <List.Item><Badge status="error" />{item}</List.Item>}
        // />
        //告警趋势图
        const chart=()=>{
            const cols = {
                value:{
                    min : 0
                }
            };
            return <Card hoverable={false} bordered={true} style={{background:"#fbfbfb"}}>
                    <Card.Grid hoverable={false} style={{padding:"5 5 5 5",width:'100%',height:"100%", textAlign:'center', border:0,boxShadow:"0 0 0 0"}}>
                        <Chart height={200} width={300} padding={[ 25, 15, 25, 25]} data={this.state.alertData} scale={cols} forceFit>
                            <Axis name="data" />
                            <Axis name="value"/>
                            <Tooltip/>
                            <Geom type="lineStack" position="data*value" size={2} color="rgba(0, 146, 255, 1)"/>
                        </Chart>
                    </Card.Grid>

                    <Card.Grid hoverable={false} style={{width:'100%', textAlign:'center', border:0, boxShadow:"0 0 0 0"}}><h1>告警趋势图</h1></Card.Grid>
                </Card>
        }
        //告警柱状图
        const barGraph=()=>{
            const data=[
                { type: '监控', number: 38 },
                { type: '巡检', number: 52 },
                { type: '备份', number: 61 },
                { type: '第三方', number: 45 },
            ]
            const cols = {
                number: {
                    tickInterval: 20
                }
            };
            return <Card hoverable={false} bordered={true} style={{background:"#fbfbfb"}}>
                <Card.Grid hoverable={false} style={{width:'100%', textAlign:'center', border:0, boxShadow:"0 0 0 0"}}>
                        <Chart height={200} data={data} scale={cols} padding={[ 25, 15, 25, 25]} forceFit>
                            <Axis name="type" />
                            <Axis name="number" />
                            <Tooltip
                                // crosshairs用于设置 tooltip 的辅助线或者辅助框
                                // crosshairs={{
                                //  type: "y"
                                // }}
                            />
                            <Geom type="interval" position="type*number" />
                        </Chart>
                </Card.Grid>
                <Card.Grid hoverable={false} style={{width:'100%', textAlign:'center', border:0, boxShadow:"0 0 0 0"}}><h1>{"问题汇总"}</h1></Card.Grid>
            </Card>
        }
        //饼图
        const pieChart=()=>{
            const { DataView } = DataSet;
            const data = [
                {
                    item: "告警",
                    count: 60
                },
                {
                    item: "次要",
                    count:20
                },
                {
                    item: "重要",
                    count: 10
                },
                {
                    item: "紧急",
                    count: 10
                }
            ];
            const dv = new DataView();
            dv.source(data).transform({
                type: "percent",
                field: "count",
                dimension: "item",
                as: "percent"
            });
            const cols = {
                percent: {
                    formatter: val => {
                        val = val * 100 + "%";
                        return val;
                    }
                }
            };
            return <Card hoverable={false} bordered={true} style={{background:"#fbfbfb"}}>
                <Card.Grid hoverable={false} style={{padding:"5 5 5 5",width:'100%',height:"100&", textAlign:'center', border:0,boxShadow:"0 0 0 0"}}>
                    <Chart
                    height={200}
                    data={dv}
                    scale={cols}
                    padding={[0, 0, 0, 0]}
                    forceFit
                >
                    <Coord type={"theta"} radius={0.75} innerRadius={0.6} />
                    <Axis name="percent" />
                    <Legend position="bottom"/>
                    <Tooltip
                        showTitle={false}
                        itemTpl="<li><span style=&quot;background-color:{color};&quot; class=&quot;g2-tooltip-marker&quot;></span>{name}: {value}</li>"
                    />
                    <Geom
                        type="intervalStack"
                        position="percent"
                        color={['item', (cut)=>{
                            //some code
                            if(cut == "告警")
                                return '#39a0fe';
                            else if(cut == "次要")
                                return "#35caca";
                            else if(cut == "重要")
                                return "#fad353" ;
                            else if(cut == "紧急")
                                return '#ff0000';
                        }]}
                        tooltip={[
                            "item*percent",
                            (item, percent) => {
                                percent = percent * 100 + "%";
                                return {
                                    name: item,
                                    value: percent
                                };
                            }
                        ]}
                        style={{
                            lineWidth: 1,
                            stroke: "#fff"
                        }}
                    >
                        <Label
                            content="percent"
                            formatter={(val, item) => {
                                return item.point.item + ": " + val;
                            }}
                        />
                    </Geom>
                </Chart>
                </Card.Grid>

                <Card.Grid hoverable={false} style={{width:'100%', textAlign:'center', border:0, boxShadow:"0 0 0 0"}}><h1>告警趋势图</h1></Card.Grid>
            </Card>
        }

        return (
            <div>
                <CustomBreadcrumb arr={["监控告警", "概览"]} />
                <Card title={<span style={{ fontWeight: 600 }}>监控状态</span>}>

                    <Row gutter={24} key={"www"} style={{align:"center",height:300}}>
                        <QueueAnim type="bottom" duration={1500} interval={180}>
                            {/*<Col span={6} key={1}>*/}
                                {/*<div >*/}
                                    {/*<Progress type="circle" percent={prometheus}  width={180} strokeWidth={10} />*/}
                                {/*</div>*/}
                                {/*<div >*/}
                                    {/*<h2 style={{marginTop:10,marginLeft:20}}>Prometheus状态  </h2>*/}
                                {/*</div>*/}
                            {/*</Col>*/}
                            {/*<Col span={6} key={2}>*/}
                                {/*<div>*/}
                                    {/*<Progress type="circle" percent={alertmanager}   width={180}  strokeWidth={10} />*/}
                                    {/*<div >*/}
                                        {/*<h2 style={{marginTop:10,marginLeft:20}}>Altermanager状态</h2>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</Col>*/}
                            <Col span={8} key={3}>

                                <div>
                                    {/*<ToolTip placement="topRight" overlayClassName="top" style={{display:"none"}} title={alert}>*/}
                                        {/*<Progress type="circle" percent={snmpexporter}  width={180}  strokeWidth={10} />*/}
                                    {/*</ToolTip>*/}
                                    {/*{alert()}*/}
                                    {/*<div >*/}
                                        {/*/!*<h2 style={{marginTop:10,marginLeft:20}}>SNMPExporter状态</h2>*!/*/}
                                        {/*<h2 style={{marginTop:10,marginLeft:20}}>SNMP监控</h2>*/}
                                    {/*</div>*/}
                                    { snmp(0,0,"异常设备数","监控设备数","SNMP监控")}
                                </div>

                            </Col>
                            <Col span={8} key={4}>
                                { snmp(0,0,"XXXX数","XXXX数","网络监控")}
                            </Col>
                            <Col span={8} key={5}>
                                { snmp(0,0,"异常程序数","监控程序数","程序监控")}
                            </Col>
                        </QueueAnim>
                    </Row>

                </Card>

                <Card title={<span style={{ fontWeight: 600 }}>告警汇总</span>}
                      style={{ marginTop: 8 }}>

                    {/*<Table*/}
                        {/*dataSource={dataSource}*/}
                        {/*columns={columns}*/}
                        {/*size="small"*/}
                        {/*loading={loading}*/}
                        {/*onChange={() => this.handleChange}*/}
                    {/*/>*/}
                    <Row gutter={24} key={"alert"} style={{align:"center",height:300}}>
                        <Col span={8} key={1} >
                            {pieChart()}
                        </Col>
                        <Col span={8} key={1}>
                            {chart()}
                        </Col>
                        <Col span={8} key={1}>
                            {barGraph()}
                        </Col>
                    </Row>
                </Card>
            </div>




        );
    }
}