const formItemLayout = {
    labelCol: {
        xs: {span: 15},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 16},
        sm: {span: 14},
    },
};
export let formList = [
    {
        label: '运营商接口',
        type: 'INPUT',
        field: 'provider_interface',
        initialValue: '',
        placeholder: '运营商接口'
    },
    {
        label: 'VLAN范围',
        type: 'INPUT',
        field: 'vlan_range',
        initialValue: '',
        placeholder: 'VLAN范围'
    },
    {
        label: '运营商名称',
        type: 'INPUT',
        field: 'provider_name',
        initialValue: '',
        placeholder: '运营商名称'
    },
]
