import React from 'react'
import { Button, Card, Form, Icon, Input, message, Select, Tabs, Row, Col, Alert, Modal, notification, AutoComplete } from 'antd';
import CustomBreadcrumb from '../../components/CustomBreadcrumb/index';
import CascOrgain from '@/components/CascOrgain/index';
import axios from '@/axios/axios_utils';
import Utils from "@/utils/utils"
const { Option } = Select;
const { Search } = Input;
const dumbTerminal_Url = "http://" + backup_address + "/ZJNMS_V1/dumb_termial/";

class newTerminal extends React.Component {

    state = {
        cardTitle: '新建哑终端',
        itemInfo: "",
        inputSearchLoading: false,
        typeList: [],
    }

    componentDidMount() {
        // console.log(sessionStorage.getItem("dumbTerminal"))
        this.getTypeList(); // 获取哑终端类型
        if (this.props.location.item || sessionStorage.getItem("dumbTerminal")) {
            // console.log(this.props.location)
            let itemInfo = this.props.location.item || JSON.parse(sessionStorage.getItem("dumbTerminal"));
            this.setState({
                cardTitle: '编辑哑终端',
                itemInfo: itemInfo,
            });
            for (let k in itemInfo) {
                if (itemInfo[k] === "") {
                    itemInfo[k] = undefined;
                }
            }
            if(itemInfo.device_type === "未知设备") {
                this.setState({ cardTitle: '新建哑终端' });
                this.props.form.setFieldsValue({ ip: itemInfo.ip })
                // console.log(this.props.location.item)
                if(itemInfo.ip) {
                    this.getMacPort(itemInfo);
                }    
            } else {
                this.props.form.setFieldsValue({
                    // _id: itemInfo._id,
                    branch: itemInfo.branch,
                    type: itemInfo.type,
                    physical_address: itemInfo.physical_address,
                    name: itemInfo.name,
                    mac_address: itemInfo.mac_address,
                    switch_name: itemInfo.switch_name,
                    switch_port: itemInfo.switch_port,
                    switch_ip: itemInfo.switch_ip,
                    ip: itemInfo.ip,
                    vendor: itemInfo.vendor,
                    model: itemInfo.model,
                    logical_address: itemInfo.logical_address,
                    status: itemInfo.status,
                    department: itemInfo.department,
                    business_user: itemInfo.business_user,
                    it_user: itemInfo.it_user,
                    is_allow_mac_in: itemInfo.is_allow_mac_in,
                    description: itemInfo.description,
                    dep_path: itemInfo.dep_id,
                })
            }
        }

    }
    componentWillUnmount() {
        notification.destroy() // 销毁 notification 消息弹框
    }

    /* handleSubmit */
    handleSubmit = e => {
        let { itemInfo } = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((async (err, value) => {
            // console.log("handleSubmit", err, value, itemInfo)
            if (!err) {
                if (!itemInfo || this.props.location.item.device_type === "未知设备") { // 新增哑终端
                    if(this.props.location.item && this.props.location.item.device_type === "未知设备") {
                        value = {
                            audited: this.props.location.audited,
                            ...value
                        }
                    }
                    let bodyArr = [value];
                    axios.post(dumbTerminal_Url + "terminal", bodyArr, (res) => {
                        if (res.code === "00") {
                            message.success("新增成功");
                            this.goBackPage(res.data);
                        } else {
                            message.error("新增失败")
                        }
                    })
                } else { // 编辑
                    let bodyJson = {
                        _id: itemInfo._id,
                        ...value,
                        dep_path: value.dep_path === itemInfo.dep_id ? itemInfo.dep_path : value.dep_path
                    };
                    for (let k in bodyJson) {
                        if (!bodyJson[k]) {
                            bodyJson[k] = "";
                        }
                    }
                    // console.log(bodyJson)
                    axios.put(dumbTerminal_Url + "terminal", bodyJson, (res) => {
                        if (res.code === "00") {
                            message.success("修改成功");
                            this.goBackPage();
                        } else {
                            message.error("修改失败")
                        }
                    })
                }
            }
        }))
    }
    getTypeList() {
        axios.get(dumbTerminal_Url + 'dtType', {}, (r) => {
            if(r.code === '00') {
                let data = r.data.map(type => {
                    return type.d_t_type
                });
                this.setState({
                    typeList: data
                })
            } else {
                message.error(r.msg);
            }
        }) 
    }
    goBackPage(addData) {
        sessionStorage.removeItem("dumbTerminal");
        let bodyJson = this.props.location.bodyJson;
        let path = {
            pathname: '/dumbTerminal',
            bodyJson,
            addData: addData,
        };
        if(bodyJson && bodyJson.pathname) {
            path.pathname = bodyJson.pathname;
            // console.log(bodyJson.pathname)
        }
        // console.log(path)
        this.props.history.push(path);
    }
    getMacPort(value) {
        let mac_address = value || '';
        this.setState({ inputSearchLoading: true })
        axios.get(dumbTerminal_Url + "terminalKeyData", { mac_address }, (res) => {
            this.setState({ inputSearchLoading: false })
            if(res.code === "00") {
                JSON.stringify(res.data) !== '{}' ? notification.info({
                    message: '哑终端信息查询',
                    description: <div>系统检测到此哑终端：
                        <p style={{ margin: 0, marginLeft: 10 }}>ip为：<span style={{ fontWeight: 'bold' }}>{res.data.ip || 'null'}</span>；</p>
                        <p style={{ margin: 0, marginLeft: 10 }}>接入交换机ip为：<span style={{ fontWeight: 'bold' }}>{res.data.switch_ip || 'null'}</span>；</p>
                        <p style={{ margin: 0, marginLeft: 10 }}>接入交换机名称为：<span style={{ fontWeight: 'bold' }}>{res.data.switch_name || 'null'}</span>；</p>
                        <p style={{ margin: 0, marginLeft: 10 }}>接入端口为：<span style={{ fontWeight: 'bold' }}>{res.data.switch_port || 'null'}</span>；</p>
                    </div>,
                    duration: null
                }) : '';
            }
        }, (error) => {
            this.setState({ inputSearchLoading: false })
            Utils.showMessage("error", "操作失败", error);
        })
    }
    
    render = () => {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 18 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 18 },
                sm: { span: 12 },
            },
        };

        const { cardTitle, typeList } = this.state;

        console.log("render", typeList)
        return (
            <div>
                <CustomBreadcrumb arr={[{ title: '首页', to: '/home' }, { title: '哑终端管理', to: '/dumbTerminal' }, cardTitle]} />
                <Card title={<span style={{ fontWeight: 600 }}>{cardTitle}</span>}>
                    <Alert message="注意：MAC地址，哑终端名称，组织区域不能为空" type="warning" showIcon style={{ marginBottom: 8 }} />
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col span={12}>
                                <Form.Item label={'哑终端名称'} {...formItemLayout} >
                                    {
                                        getFieldDecorator('name', {
                                            rules: [
                                                { required: true, message: '哑终端名称不能为空' },
                                                { type: 'string', message: '请输入正确的字符' },
                                            ]
                                        })(<Input allowClear placeholder='输入哑终端' />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={'哑终端类型'} {...formItemLayout}>
                                    {
                                        getFieldDecorator('type', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },
                                                { max: 200, message: '不能超过200个字符' },
                                            ]
                                        })(<AutoComplete dataSource={typeList} allowClear placeholder="哑终端类型"
                                            filterOption={(inputValue, option) => option.props.children.indexOf(inputValue) !== -1 }
                                        />)
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label={'哑终端型号'} {...formItemLayout} >
                                    {
                                        getFieldDecorator('model', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },

                                            ]
                                        })(<Input allowClear placeholder='哑终端型号' />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={'哑终端厂商'} {...formItemLayout}>
                                    {
                                        getFieldDecorator('vendor', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },
                                            ]
                                        })(<Input allowClear placeholder='哑终端厂商' />)
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label={'MAC地址'} {...formItemLayout}>
                                    {
                                        getFieldDecorator('mac_address', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },
                                                { max: 200, message: '长度不得超过200个字符' },
                                                { required: true, message: 'MAC地址不能为空' }
                                                // this.props.form.getFieldValue('ip') ?  '' : { required: true, message: 'MAC地址不能为空' },
                                            ]
                                        })(<Search onSearch={(e) => this.getMacPort(e)} allowClear placeholder='MAC地址' loading={this.state.inputSearchLoading} />)
                                        // })(<Input onBlur={(e) => this.getMacPort(e)} allowClear placeholder='MAC地址' />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={'哑终端IP地址'} {...formItemLayout}>
                                    {
                                        getFieldDecorator('ip', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },
                                                // this.props.form.getFieldValue('mac_address') ?  '' : { required: true, message: 'IP地址不能为空' },
                                            ]
                                        })(<Input allowClear placeholder='请输入ip地址' />)
                                        // })(<Input onBlur={(e) => this.getMacPort(e)} allowClear placeholder='请输入ip地址' />)
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label={'接入交换机名称'} {...formItemLayout} >
                                    {
                                        getFieldDecorator('switch_name', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },
                                            ]
                                        })(<Input allowClear placeholder='接入交换机名称' />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={'接入交换机IP'} {...formItemLayout} >
                                    {
                                        getFieldDecorator('switch_ip', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },
                                            ]
                                        })(<Input allowClear placeholder='接入交换机IP' />)
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label={'接入端口'} {...formItemLayout}>
                                    {
                                        getFieldDecorator('switch_port', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },
                                                { max: 250, message: '长度不得超过250个字符' },
                                            ]
                                        })(<Input allowClear placeholder='接入端口' />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={'是否实施了mac准入'} {...formItemLayout}>
                                    {
                                        getFieldDecorator('is_allow_mac_in', {
                                            rules: []
                                        })(<Select allowClear placeholder='请选择是否实施了mac准入'>
                                            <Option value="是">是</Option>
                                            <Option value="否">否</Option>
                                        </Select>)
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label={'组织区域'} {...formItemLayout}>
                                    {
                                        getFieldDecorator('dep_path', {
                                            rules: [{
                                                required: true, message: '请选择组织区域!',
                                            }],
                                            getValueFromEvent: (a, data) => {
                                                let dep_name = data.map(item => {
                                                    return item.label
                                                })
                                                console.log("组织区域", a, data)
                                                return dep_name.join('-')
                                                // return a[a.length - 1]
                                            }
                                        })(<CascOrgain type="add" />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={'所属分行'} {...formItemLayout}>
                                    {
                                        getFieldDecorator('branch', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },
                                            ]
                                        })(<Input allowClear placeholder='所属分行' />)
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label={'哑终端物理地址'} {...formItemLayout}>
                                    {
                                        getFieldDecorator('physical_address', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },
                                            ]
                                        })(<Input allowClear placeholder='哑终端物理地址' />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={'哑终端逻辑位置'} {...formItemLayout} >
                                    {
                                        getFieldDecorator('logical_address', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },
                                            ]
                                        })(<Input allowClear placeholder='哑终端逻辑位置' />)
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label={'所属业务部门'} {...formItemLayout}>
                                    {
                                        getFieldDecorator('department', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },
                                                { max: 200, message: '长度不得超过200个字符' },
                                            ]
                                        })(<Input allowClear placeholder='所属业务部门' />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={'哑终端业务负责人'} {...formItemLayout}>
                                    {
                                        getFieldDecorator('business_user', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },
                                                { max: 200, message: '长度不得超过200个字符' },
                                            ]
                                        })(<Input allowClear placeholder='哑终端业务负责人' />)
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label={'哑终端IT负责人'} {...formItemLayout}>
                                    {
                                        getFieldDecorator('it_user', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },
                                                { max: 200, message: '长度不得超过200个字符' },
                                            ]
                                        })(<Input allowClear placeholder='哑终端IT负责人' />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={'哑终端状态'} {...formItemLayout} >
                                    {
                                        getFieldDecorator('status', {
                                            rules: []
                                        })(<Select allowClear placeholder='请选择则哑终端状态'>
                                            <Option value="停用">停用</Option>
                                            <Option value="在用">在用</Option>
                                        </Select>)
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label={'描述信息'} {...formItemLayout}>
                                    {
                                        getFieldDecorator('description', {
                                            rules: [
                                                { type: 'string', message: '请输入正确的字符！' },
                                                { max: 200, message: '长度不得超过200个字符' },
                                            ]
                                        })(<Input.TextArea allowClear placeholder='描述信息' autoSize={{ minRows: 2, maxRows: 4 }} />)
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item colon={false} >
                            {<div style={{ width: "100%", textAlign: "center" }}>
                                <Button type="primary" icon="check-circle" htmlType="submit" style={{ width: 150 }}>保存</Button>&nbsp;
                                    <Button type="danger" icon="close-circle" style={{ width: 150, }}
                                    onClick={() => { this.goBackPage(); }}
                                > 取消</Button>
                            </div>}
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        )
    };
}

const newTerminalFrom = Form.create()(newTerminal);
export default newTerminalFrom;
