import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Divider, Popconfirm
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import { numberToText } from '../utils/index';
import { alarmRules, alarmRule, dataSources, strategys } from '../utils/api';
import { Cascader } from 'formik-antd';
import Socket from "@/socket/index";
import FormModal from '@/components/EditModal';
import BaseForm from '../utils/baseForm';
import { connect } from 'react-redux'
import "./index.scss"
import "animate.css"

function AlertRules(props) {
    const [visible, setVisible] = useState(false);
    const [total, setTotal] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const [selectItem, setSelectItem] = useState({});
    const [selectedRowKeys, setRowKeys] = useState([])
    const [dataLists, setDataSource] = useState([])
    const [strategyList, setStrategys] = useState([])
    const [tableData, setTabData] = useState([])
    const [treeData, setTreeData] = useState([])
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
        },
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'ip',
            ellipsis: true,
            width: 40,
            render: (text, item, c) => { return c + 1 + (_current - 1) * _pageSize }
        },
        {
            title: '规则名称',
            dataIndex: 'ruleName',
            ellipsis: true,
        },
        {
            title: '告警规则表达式',
            dataIndex: 'expression',
            ellipsis: true,
        },
        {
            title: '持续时间',
            dataIndex: 'duration',
            ellipsis: true,
        },
        {
            title: '告警规则描述',
            dataIndex: 'description',
            ellipsis: true,
        },
        {
            title: '分类标签',
            dataIndex: 'sortLabel',
            ellipsis: true,
        },
        {
            title: '告警等级',
            dataIndex: 'alarmLevel',
            ellipsis: true,
            render: (text) => { return numberToText("level", text) }
        },
        {
            title: '告警策略名称',
            dataIndex: 'strategyName',
            ellipsis: true,
        },
        {
            title: '操作',
            render: (text, item) => {
                return <div>
                    {/* <Button type="link" size="small" icon="edit" onClick={() => { setSelectItem(item); setVisible(true) }}>编辑</Button>
                    <Divider type="vertical" />
                    <Button type="link" icon="delete" size="small" onClick={() => deleteData(item)}>删除</Button> */}
                    <a onClick={() => {
                        setSelectItem({ ...item, ruleType: item.ruleType || 0, areaId: (typeof item.areaId === "string" ? [item.areaId] : item.areaId) });
                        setVisible(true);
                    }}><Icon type="edit" />&nbsp;编辑</a>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData([item.ruleId]) }}
                        okText="是"
                        cancelText="否"
                    >
                        <a><Icon type="delete" />&nbsp;删除</a>
                    </Popconfirm>
                </div>
            }
        },
    ]
    useEffect(() => {//componentDidMount;
        getData();
        getMenuData();
        http.setDispatch(props.dispatch);
        getSelectData();
        return () => { //componentWillUnmount
        }
    }, []);

    function greatData(data) {
        const newList = [];
        data.map((item) => {
            let obj = {
                value: item.key,
                label: item.title,
            }
            if (item.children) {
                obj.children = greatData(item.children);
            }
            newList.push(obj);
        })
        return newList
    }

    function getMenuData() {
        Socket.ExecuteAction('QueryArea', {}, "", (result) => {
            const TreeData = result.data;
            const options = greatData(TreeData);
            setTreeData(options)
        });
    };


    function getData(page = 1, size = _pageSize, query = {}) {
        http.get(alarmRules(), { page, size, ...query }, (r) => {
            console.log(r)
            setTabData(r.data.records);
            setTotal(r.data.total);
            set_querys(query);
            set_current(page);
            set_pageSize(size)
        })
    }
    function saveDataSource(val) {
        console.log(val)
        if (selectItem.ruleName) {//修改
            val.ruleId = selectItem.ruleId;
            http.put(alarmRule(), val, () => {
                getData();
                message.success("修改成功");
            })
        } else {//新增 
            http.post(alarmRule(), val, () => {
                getData();
                message.success("新增成功");
            })
        }
        setVisible(false)
    }
    function getSelectData() {
        //数据源
        http.get(dataSources(), { page: 1, size: 9999 }, (r) => {
            setDataSource(r.data.records);
        })
        http.get(strategys(), { page: 1, size: 9999 }, (r) => {
            setStrategys(r.data.records || []);
        })
    }
    function deleteData(ruleIds) {
        http.delete_body(alarmRule(), { ruleIds }, () => {
            getData();
            message.success("删除成功");
        })
    }
    return <div className="monitorAlertRules">
        <CustomBreadcrumb arr={["监控告警", "告警规则"]} />
        <Card title="告警规则" extra={<div>
            <Button type="primary" icon="file-add" onClick={() => {
                setSelectItem({ ruleType: 0 })
                setVisible(true);
            }}>添加</Button>&nbsp;
                    <Popconfirm
                title="你确定删除所选记录吗?"
                onConfirm={() => { deleteData(selectedRowKeys) }}
                okText="是"
                cancelText="否"
            ><Button type="danger" icon="delete" >删除</Button>
            </Popconfirm>
        </div>}>
            <div className="searchDiv">
                <BaseForm
                    onSubmit={(val) => { getData(1, _pageSize, val) }}
                    initialValues={{}}
                    formList={[
                        { type: "input", name: "ruleName", label: "规则名称", placeholder: "规则名称" },
                        {
                            type: "select", name: "level", label: "告警等级",
                            selectOptions: [{ name: "信息", value: 0 }, { name: "警告", value: 1 }, { name: "严重", value: 2 }, { name: "灾难", value: 3 }]
                        },
                        { type: "input", name: "sortLabel", label: "分类标签", placeholder: "分类标签" },
                        // { type: "input", name: "description", label: "告警规则描述", placeholder: "告警规则描述" },

                    ]}
                    onCancel={() => { getData() }}
                />
            </div>
            <Table
                dataSource={tableData}
                rowSelection={rowSelection}
                columns={columns}
                size="small"
                rowKey={r => r.ruleId}
                pagination={
                    {
                        current: _current, pageSize: _pageSize, total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            getData(1, size, _querys)
                        },
                        onChange: (page, pageSize) => { getData(page, pageSize, _querys); }
                    }
                }
            />
        </Card>
        <FormModal
            title="编辑告警规则"
            visible={visible}
            onCancel={() => setVisible(false)}
            onSubmit={(val) => {
                // const areaIds = val.areaId
                // if (areaIds) {
                //     val.areaId = areaIds[areaIds.length - 1]
                // }
                saveDataSource(val);
            }}
            width={"50%"}
            footer={null}
            initialValues={selectItem}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            destroyOnClose
            formList={[
                { type: "input", name: "ruleName", label: "规则名称", required: true, placeholder: "规则名称" },
                { type: "input", name: "expression", label: "告警规则表达式", required: true, placeholder: "告警规则表达式" },
                { type: "inputNumber", name: "duration", label: "持续时间", required: true, placeholder: "持续时间(单位秒)" },
                { type: "tags", name: "tags", label: "标签", placeholder: "设备标签" },
                {
                    type: "expandForm", name: "areaId", label: "设备区域", placeholder: "设备区域", formContent: <Cascader
                        name={"areaId"}
                        label={"设备区域"}
                        required={false}
                        key={"areaId"}
                        options={treeData}
                        placeholder="设备区域"
                    />
                },
                {
                    type: "select", name: "dataSourceId", label: "数据源", required: true,
                    selectOptions: dataLists.map(item => ({ name: item.name, value: item.dataSourceId }))
                },
                {
                    type: "radio", name: "ruleType", label: "告警类型", required: true,
                    options: [{ label: "单终端", value: 0 }, { label: "多终端汇总", value: 1 },]
                },
                {
                    type: "select", name: "strategyId", label: "告警策略", required: true,
                    selectOptions: strategyList.map(item => ({ name: item.strategyName, value: item.strategyId }))
                },
                {
                    type: "select", name: "alarmLevel", label: "告警等级", required: true,
                    selectOptions: [{ name: "信息", value: 0 }, { name: "警告", value: 1 }, { name: "严重", value: 2 }, { name: "灾难", value: 3 }]
                },
                { type: "input", name: "sortLabel", label: "分类标签", placeholder: "分类标签" },
                { type: "textArea", name: "description", label: "告警规则描述", placeholder: "告警规则描述" },
            ]}
        />
    </div>
}

export default connect()(AlertRules)