import React from 'react'
import { Menu, Icon, BackTop } from 'antd';
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { switchMenu, isLoading } from './../../redux/action'
import Utils from '../../utils/utils'
import './index.scss'
import "animate.css";
const SubMenu = Menu.SubMenu;
const versionName = sys_config.versionName;
const terminalMenu = {
    display: "yes",
    icon: "apartment",
    index: 4,
    interface: "",
    key: "/Terminal",
    title: "终端接入",
    children: [
        {
            display: "yes",
            icon: "copy",
            index: 1,
            interface: "",
            key: "/IPAdmin",
            title: "IP地址管理",
            type: 1,
            children: [
                {
                    display: "yes",
                    icon: "desktop",
                    index: 1,
                    interface: "",
                    key: "/ipadmin/ipmonitor",
                    title: "IP监控",
                    type: 1,
                },
                {
                    display: "yes",
                    icon: "sync",
                    index: 2,
                    interface: "",
                    key: "/IpAdmin/ipRecycleRecord",
                    title: "IP回收记录",
                    type: 2,
                }
            ]
        },
        {
            display: "yes",
            icon: "printer",
            index: 2,
            interface: "",
            key: "/dumbTerminalManage",
            title: "哑终端管理",
            type: 2,
            children: [
                {
                    display: "yes",
                    icon: "unordered-list",
                    index: 1,
                    interface: "",
                    key: "/dumbTerminal",
                    title: "哑终端列表",
                    type: 1,
                },
                {
                    display: "yes",
                    icon: "profile",
                    index: 2,
                    interface: "",
                    interfaceMethod: "",
                    key: "/dumbTerminal/allBindDumbRecord",
                    title: "哑终端绑定记录",
                    type: 2,
                },
                {
                    display: "yes",
                    icon: "search",
                    index: 3,
                    interface: "",
                    key: "/dumbTerminal/dumbAutoFind",
                    title: "哑终端自动发现",
                    type: 3,
                },
                {
                    display: "yes",
                    icon: "file-unknown",
                    index: 4,
                    interface: "",
                    key: "/dumbTerminal/unKnownDumbTerminal",
                    title: "哑终端未知设备",
                    type: 4,
                }
            ]
        },
        {
            display: "yes",
            icon: "schedule",
            index: 3,
            interface: "",
            key: "/LedgerManage",
            title: "MAC地址管理",
            type: 3,
            children: [
                {
                    display: "yes",
                    icon: "profile",
                    index: 1,
                    interface: "",
                    key: "/Ledger",
                    title: "交换机列表",
                    type: 1,
                },
                {
                    display: "yes",
                    icon: "unordered-list",
                    index: 2,
                    interface: "",
                    key: "/Ledger/macAddressList",
                    title: "mac地址列表",
                    type: 2,
                },
                {
                    display: "yes",
                    icon: "credit-card",
                    index: 3,
                    interface: "",
                    key: "/Ledger/fixedResources",
                    title: "固定资产",
                    type: 3,
                }
            ]
        }
    ],
}
class NavLeft extends React.Component {
    state = {
        currentKey: '',
        //刷新后展开自动选中菜单
        defaultSelectedKeys: window.location.href.split("#")[1] ? [window.location.href.split("#")[1]] : [],
        openKeys: ['/home', '/user'],
        rootSubmenuKeys: ['/home', '/maintain', '/permission', '/resourceManager', '/taskManger', "/emulator", '/LogMange', "/monitorAlert", "/email",],
    };
    componentWillReceiveProps(props) {
        if (props.collapsed) {
            this.setState({
                openKeys: []
            });
        }
    }
    componentDidMount() {
        const getParentKey = (key, tree) => {
            let parentKey;
            for (let i = 0; i < tree.length; i++) {
                const node = tree[i];
                if (node.children) {
                    if (node.children.some(item => item.key === key)) {
                        parentKey = node.key;
                    } else if (getParentKey(key, node.children)) {
                        parentKey = getParentKey(key, node.children);
                    }
                }
            }
            return parentKey;
        };
        const getAllParentKey = (key, TreeData) => {
            let parentKey = getParentKey(key, TreeData);
            if (parentKey) {
                return parentKey = getAllParentKey(parentKey, TreeData) + "@" + parentKey;
            } else {
                return ""
            }
        }
        const menuList = JSON.parse(sessionStorage.getItem("menuList"));
        //刷新后展开自动选中菜单
        const tempUrl = window.location.href.split("#")[1]
        if (tempUrl) {
            const openKeys = getAllParentKey(tempUrl, menuList).split("@")
            this.setState({ openKeys })
        }
    }
    // 菜单点击
    handleClick = ({ item, key }) => {
        Utils.goTOP();
        if (key === this.state.currentKey) {
            return false;
        }
        // 事件派发，自动调用reducer，通过reducer保存到store对象中
        const { dispatch } = this.props;
        dispatch(switchMenu(item.props.title));
        dispatch(isLoading(false));//关闭加载动画

        this.setState({
            currentKey: key,
            // defaultSelectedKeys: [key]
        });

        let collapsed = this.props.collapsed;
        if (this.props.onToggle) {
            this.props.onToggle(collapsed);
        }
    };
    componentWillMount() {
        let MenuList = [];
        let pinganTerminalMenu = JSON.parse(sessionStorage.getItem('menuList')).filter(menuItem => {
            if (menuItem.key !== '/maintain' && menuItem.key !== '/Terminal') {
                return menuItem
            }
        });
        pinganTerminalMenu.splice(2, 0, terminalMenu)
        // console.log(pinganTerminalMenu)
        let tempMenu = JSON.parse(sessionStorage.getItem('menuList')) || [];
        if (versionName === "pinganTerminal") {
            tempMenu = pinganTerminalMenu
        }
        // let tempMenu2 = Utils.childrenListFun(tempMenu);
        let tempMenu2 = Utils.clearDisplayNo(tempMenu);
        // tempMenu2 = Utils.menuIconFun(tempMenu2);
        if (tempMenu2) {
            MenuList = tempMenu2;
        }
        const menuTreeNode = this.renderMenu(MenuList);
        this.setState({
            menuTreeNode
        })
    }
    onOpenChange(openKeys2) {
        //滚动条置顶
        Utils.goTOP();
        //只展开最后点开的菜单
        let { openKeys, rootSubmenuKeys } = this.state;
        const latestOpenKey = openKeys2.find(key => (!openKeys.includes(key)));
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1 || this.props.mode === "horizontal") {
            this.setState({ openKeys: openKeys2 });
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }

    }
    // 菜单渲染
    renderMenu = (data) => {
        return data.map((item) => {
            if (item.children) {
                return (
                    //<SubMenu title={item.title} key={item.key}>
                    <SubMenu popupClassName="SubMenustyle" title={<span>{item.icon && <Icon type={item.icon} />}<span>{item.title}</span></span>} key={item.key}>
                        {this.renderMenu(item.children)}
                    </SubMenu>

                )
            }
            return <Menu.Item title={item.title} key={item.key}>
                <NavLink to={item.key}>
                    {item.icon && <Icon type={item.icon} />}
                    <span>{item.title}</span>
                </NavLink>
            </Menu.Item>
        })
    }
    homeHandleClick = () => {
        const { dispatch } = this.props;
        dispatch(switchMenu('首页'));
        this.setState({
            currentKey: "",
            // defaultSelectedKeys: []
        });
    };
    getTitle(collapsed, mode) {
        if (collapsed) {
            return ""
        } else {
            if (versionName === "pingan" || versionName === "pinganNanJing" || versionName === "pinganTerminal") {
                if (mode === "inline") {
                    return <img className="pinganlogo" src="/image/pingan-logo.jpg" />
                } else {
                    return <img className="pinganlogoSmall" src="/image/payh_logo.png" />
                    // return <img className="pinganlogoSmall" src="/image/pingan-logo.jpg" />
                }
            } else {
                return <span className="name-title animated flipInX">
                    {mode === "inline" ? '自动化运维平台' : "ZJNMS"}
                </span>
            }

        }
    }
    getLogo() {
        if (versionName === "pingan" || versionName === "pinganNanJing" || versionName === "pinganTerminal") {
            return ""
        } else {
            return <img src="/image/logo.png" alt="网络工具" />
        }
    }
    render() {
        const { defaultSelectedKeys, openKeys, menuTreeNode } = this.state;
        const { collapsed, mode, theme } = this.props;
        return (
            <div className="NavLeftDiv">
                <div className={mode === "inline" ? 'nav-left' : "nav-left2"}>
                    <NavLink to="/home" onClick={() => this.homeHandleClick()}>
                        <div className="logo">
                            <div className="leftLogotitle">
                                {/* <img src="/image/logo.png" alt="网络工具" /> */}
                                {this.getLogo()}
                                {this.getTitle(collapsed, mode)}
                            </div>
                        </div>
                    </NavLink>
                </div>
                <div className={mode === "inline" ? 'menuOver' : "menuOver2"}>
                    <Menu
                        onClick={(e) => this.handleClick(e)}
                        mode={mode}
                        openKeys={openKeys}
                        onOpenChange={(e) => this.onOpenChange(e)}
                        // mode="inline"
                        // mode="horizontal"
                        theme={theme || "dark"}
                        defaultSelectedKeys={defaultSelectedKeys}
                        // defaultOpenKeys={['/portManagement', '/configManger']}
                        // inlineCollapsed={collapsed}
                        className={mode === "inline" ? 'menu-style' : "menu-header2"}
                    // style={{ color: "#fff" }}
                    >
                        {menuTreeNode}
                    </Menu>
                </div>
            </div>
        );
    }
}
export default connect()(NavLeft)