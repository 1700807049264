import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, notification, Modal, Row, Col, Popover, Tag, Descriptions, Divider, Badge, Input, Radio
} from "antd";

import SearchCard from './searchCard';
import Utils from '@/utils/utils';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import { connect } from 'react-redux'
import ResUtils from '@/utils/ResUtils';
import { isLoading } from '@/redux/action'
import "./index.scss"
import http from '@/axios/axios_utils';
import api from '@/config/api/deliveryTask';
import * as XLSX from 'xlsx';
function Approve(props) {
    const [total, set_total] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});

    const [tasks, set_tasks] = useState([]);
    const [detailVis, setDetailVis] = useState(false);
    const [detailNode, setDetailNode] = useState({});
    const [htmlStr, setHTML] = useState("");
    const [contentStr, setContent] = useState("");
    const [approval_status, set_approval_status] = useState("");
    useEffect(() => {//componentDidMount
        getTasks(_pageSize, _current, {});
        return () => { //componentWillUnmount
        }
    }, []);
    const getTasks = async (pageSize, pageNum, querys) => {
        http.setDispatch(null)
        props.dispatch(isLoading(true));
        let bodyJson = {
            page_size: pageSize || _pageSize,
            page_no: pageNum || _current,
            username: Utils.getLoginUser(),
            ...querys
        };
        const data = await http.get(api.approvalRecordsByPage, bodyJson);
        props.dispatch(isLoading(false));
        set_tasks(data.data);
        set_total(data.count);
        set_current(bodyJson.page_no);
        set_querys(querys);
    }
    function statusColor(a, type) {
        let color = "blue";
        switch (a) {
            case "已审批": color = "#e2e2e2"; break;
            case "执行中": color = "purple"; break;
            case "完成": color = "#52c41a"; break;
            case "失败": color = "#f50"; break;
            case "审批中": color = "geekblue"; break;
            case "审批通过": color = "green"; break;
            case "通过": color = "green"; break;
            case "暂停": color = "red"; break;
            case "审批拒绝": color = "magenta"; break;
            case "拒绝": color = "red"; break;
            case "待执行": color = "#108ee9"; break;
        }
        if (type === "des") {
            return <Badge color={color} text={a} />
        } else {
            return <Tag color={color} >{a}</Tag>
        }
    }
    function approveFun(approval_result, _id) {
        Modal.confirm({
            title: "消息提示",
            content: "你确定提交该任务审批结果吗?",
            onOk() {
                props.dispatch(isLoading(true));
                let msgStr = "";
                if (contentStr) {
                    msgStr = contentStr
                } else {
                    if (approval_result === "permit") {
                        msgStr = "默认通过"
                    } else {
                        msgStr = "默认拒绝"
                    }
                }
                let bodyJson = {
                    approval_result, _id,
                    approval_content: msgStr,
                    username: Utils.getLoginUser()
                };
                http.put(api.deliveryTaskApproval, bodyJson, () => {
                    notification.success({ message: "消息提示", description: "操作成功!" });
                    setDetailVis(false);
                    setContent("")
                    getTasks(_pageSize, _current, {});
                });
                props.dispatch(isLoading(false));
            }
        })
    }
    function createTable(b) {
        const sheetData = XLSX.utils.json_to_sheet(b.excel_cmd || []);
        const tabHtmlbyXLSL = XLSX.utils.sheet_to_html(sheetData, { id: "xlslTab", editable: false, });
        setHTML(tabHtmlbyXLSL);
    }
    const colums = [
        {
            title: "序号", width: 40, render: (a, b, c) => {
                return c + 1 + (_current - 1) * _pageSize
            }
        },
        { title: "任务名称", dataIndex: "task_name" },
        { title: "任务类型", dataIndex: "task_type" },
        {
            title: "审批状态", dataIndex: "approval_status", render: (a, b, c) => {
                return statusColor(a, c)
            }
        },
        { title: "提交人", dataIndex: "commit_user" },
        { title: "提交时间", dataIndex: "commit_time" },
        { title: "审批时间", dataIndex: "approval_time" },
        {
            title: "审批结果", dataIndex: "approval_result", render: (a, b) => {
                return <Popover title="审批意见" content={b.approval_content}>
                    <div>{a === "deny" ? statusColor("拒绝", "des") : (a === "permit" ? statusColor("通过", "des") : "")}
                    </div>
                </Popover>
            }
        },
        {
            title: "操作", dataIndex: "task_detail", render: (a, b) => {
                if (b.approval_status === "待审批") {
                    return <Button type="primary" size="small" icon="highlight" onClick={() => {
                        a.app_id = b._id
                        a.approval_status = b.approval_status
                        setDetailNode(a); setDetailVis(true); createTable(a)
                    }}>
                        审批
                        </Button>
                } else {
                    return <Button size="small" icon="file-text" onClick={() => {
                        a.approval_status = b.approval_status
                        a.approval_content = b.approval_content
                        setDetailNode(a); setDetailVis(true); createTable(a)
                    }}>详情</Button>
                }
            }
        },

    ]
    return <div className="aclApproveDiv">
        <CustomBreadcrumb arr={["运维管理", "配置下发", "审批列表"]} />
        <SearchCard onSubmit={(values) => {
            const val = {
                task_name: values.task_name,
                create_user: values.username,
                task_type: values.type,
                approval_status,
            }
            getTasks(_pageSize, 1, val)
        }} reSet={() => {
            getTasks(_pageSize, 1, { approval_status })
        }} />
        <p />
        <Card title={Utils.BoldFont("审批列表")}>
            <Radio.Group className="approveRab" defaultValue="all" buttonStyle="solid"
                onChange={(e) => {
                    set_approval_status((e.target.value === "all") ? "" : e.target.value);
                    getTasks(_pageSize, _current, { ..._querys, approval_status: (e.target.value === "all") ? "" : e.target.value })
                }}>
                <Radio.Button value="all">全部</Radio.Button>
                <Radio.Button value="待审批">待审批</Radio.Button>
                <Radio.Button value="已审批">已审批</Radio.Button>
            </Radio.Group>
            <Table
                // rowSelection={{
                //     selectedRowKeys: selectedRowKeys,
                //     onChange: (selectedRowKeys, selectedRows) => {
                //         setRowKey(selectedRowKeys);
                //         setRow(selectedRows);
                //     },
                // }}
                rowKey={(row) => { return row._id }}
                columns={colums}
                dataSource={tasks}
                size="small"
                pagination={{
                    pageSize: _pageSize, current: _current, total: total,
                    showTotal: (total) => { return <span>共 {total} 项</span> },
                    pageSizeOptions: ["20", "50", "100", "500", "1000"],
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) => {
                        set_pageSize(size)
                        getTasks(size, 1, _querys)
                    },
                    onChange: (page, pageSize) => {
                        getTasks(pageSize, page, _querys)
                    }
                }}
            />
        </Card>
        <Modal
            title="任务详情"
            visible={detailVis}
            onCancel={() => { setDetailVis(false); }}
            onOk={() => { setDetailVis(false); }}
            width="80%"
            okText="关闭"
            style={{ top: 20 }}
            className="approveTaskModal"
        >
            <Row gutter={0}>
                <Col span={24}>
                    <Descriptions column={3} bordered>
                        <Descriptions.Item label={"任务名称"} span={1} >{detailNode.task_name}</Descriptions.Item>
                        {/* <Descriptions.Item label={"任务状态"} span={1} >
                            {statusColor(detailNode.status, "des")}
                        </Descriptions.Item> */}
                        <Descriptions.Item label={"审批状态"} span={1} >
                            {statusColor(detailNode.approval_status, "des")}
                        </Descriptions.Item>
                        <Descriptions.Item label={"类型"} span={1} >{detailNode.type}</Descriptions.Item>
                        <Descriptions.Item label={"定时执行"} span={1} >{detailNode.automatic ? "是" : "否"}</Descriptions.Item>
                        <Descriptions.Item label={"自动执行时间"} span={1} >{detailNode.auto_execute_time}</Descriptions.Item>
                        <Descriptions.Item label={"任务并发"} span={1} >{detailNode.concurrent ? "是" : "否"}</Descriptions.Item>
                        <Descriptions.Item label={"执行过程中遇见错误终止"} span={1} >{detailNode.ignore_error ? "是" : "否"}</Descriptions.Item>
                        <Descriptions.Item label={"创建时间"} span={1} >{detailNode.create_time}</Descriptions.Item>
                        <Descriptions.Item label={"审批人"} span={1} >{detailNode.reviewer}</Descriptions.Item>
                        <Descriptions.Item label={"更新时间"} span={1} >{detailNode.update_time}</Descriptions.Item>
                        <Descriptions.Item label={"创建用户"} span={1} >{detailNode.username}</Descriptions.Item>
                        <Descriptions.Item label={"审批人邮箱"} span={1} >{detailNode.mail}</Descriptions.Item>
                        <Descriptions.Item label={"下发指令"} span={3} >
                            <div className="cmdDiv">{detailNode.commands}</div>
                        </Descriptions.Item>
                        {detailNode.excel_cmd && detailNode.excel_cmd[0] ? <Descriptions.Item label={"指令参数"} span={3} >
                            <div className="paramDiv" dangerouslySetInnerHTML={{ __html: htmlStr }} />
                        </Descriptions.Item> : ""}
                        <Descriptions.Item label={"下发设备"} span={3} >
                            <Table
                                columns={[...ResUtils.getColumns(10000, 1)]}
                                dataSource={detailNode.resources}
                                pagination={false}
                                locale={{ emptyText: <div><Icon type="inbox" />暂无数据</div> }}
                                size="small"
                                rowKey={(row) => row._id}
                                scroll={{ y: 300 }}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label={"审批意见"} span={3} >
                            {detailNode.app_id ? <Input.TextArea
                                value={detailNode.approval_content || contentStr}
                                onChange={(e) => { setContent(e.target.value) }} style={{ height: 100 }} placeholder="审批意见、原因(非必填)" />
                                : <p>{detailNode.approval_content}</p>}<p />
                            {detailNode.app_id ? <div>
                                <Button type="primary" size="small" icon="check-circle" onClick={() => { approveFun("permit", detailNode.app_id) }}>
                                    审批通过</Button><Divider type="vertical" />
                                <Button type="danger" size="small" icon="close-circle" onClick={() => { approveFun("deny", detailNode.app_id) }}>
                                    拒绝 </Button>
                            </div> : ""}
                        </Descriptions.Item>
                    </Descriptions>

                </Col>
            </Row>
        </Modal>
        <p />
    </div >
}
export default connect()(React.memo(Approve))
