import React, { useState, useEffect } from 'react'
import {
    SubmitButton,
    ResetButton,
    Form,
    FormItem,
    Select
} from "formik-antd"
const FormikInput = require("formik-antd").Input;
import { Formik } from "formik"
import { connect } from 'react-redux'
function SearchCard(props) {
    const [initialValues,] = useState({
    });
    useEffect(() => {//componentDidMount 
        return () => { //componentWillUnmount
        }
    }, []);
    const formItemRender = function (data) {
        return data.map((item, key) => {
            return <FormItem
                key={key}
                name={item[0]}
                label={item[1]}
                required={false}
            >
                <FormikInput name={item[0]} placeholder={item[1]} />
            </FormItem>
        })
    }
    return <div className="SearchCard">
        <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                console.log(values)
                props.onSubmit(values);
                actions.setSubmitting(false)
            }}
        >
            {(props2) => {
                return <Form
                    layout="inline"
                >
                    <FormItem
                        name={"tempName"}
                        label={"模板名称"}
                        required={false}
                    >
                        <FormikInput name={"tempName"} placeholder={"模板名称"} />
                    </FormItem>
                    <FormItem
                        name={"tempType"}
                        label={"模板类型"}
                        required={false}
                    >
                        <Select name={"tempType"} placeholder={"模板类型"} style={{ width: 200 }}>
                            <Select.Option value="email">邮件</Select.Option>
                            <Select.Option value="sms">短信</Select.Option>
                        </Select>
                    </FormItem>
                    <SubmitButton disabled={false} icon="search">查询</SubmitButton>&nbsp;
                    <ResetButton disabled={false} icon="reload" onClick={() => { props.reSet(); }}>重置</ResetButton>
                </Form>

            }}
        </Formik>
    </div>
}
export default connect()(React.memo(SearchCard))
