/**
 * Created by YIMINE on 2019/1/24.
 */
import React from 'react'
import { Card, Form, Input, Radio, Modal, Tag, Tooltip, Icon, Select, message, notification, Button, } from 'antd';
import $ from 'jquery'
import './index.scss'
import Socket from "@/socket/index";

import Utils from "@/utils/utils";
// import ArrayTask from "../../newTask/arrayTask";
import TimePlan from "./timePlan";
import CustomBreadcrumb from '../../../../../components/CustomBreadcrumb/index';
const RadioGroup = Radio.Group;
// import './plugin/jquery.min.js'
// import './codebase/GooFunc'
// import './plugin/json2'
// import GooFlow from './codebase/GooFlow'
// import './codebase/GooFlow.color'
const { Option } = Select;
const TextArea = Input.TextArea;
let _flow_data = null;
const newTask_goobal = {

};

class newTask extends React.Component {
    state = {
        RadioValue: '',
        cardTitle: "新建任务",
        typeTags: [{ type: "fixed", name: "文档", },
        { type: "fixed", name: "图表", },
        { type: "fixed", name: "防火墙", },
        { type: "fixed", name: "设备发现", }
        ],
        taskPlanData: { type: 'manual', value: {} },
        visible: false,             //报告类型模态框
        visibleTime: false,             //时间计划模态框
        timeBtn: false,             //时间计划按钮
        searchText: '',
        Tags_Groups: [],
        report_type: [{ type: "fixed", name: "文档", },
        { type: "fixed", name: "图表", },
        { type: "fixed", name: "防火墙", },
        { type: "fixed", name: "设备发现", }],
        Tags: [],
        Groups: [],
        appInfo: null,
        commit: false,
        flow_data: null
    };
    componentDidMount() {
        let thisTemp = this;
        let param = this.props.location.state;
        if (param) {
            console.log(param);
            let reportTagsVal = [];
            param.content.reportTags.map((item, index) => {
                reportTagsVal.push(item.name);
            });
            console.log(reportTagsVal);
            thisTemp.props.form.setFieldsValue({
                reportTags: reportTagsVal,
            });
            //初始化流程图数据
            _flow_data = param.content.taskContent;
            //导入流程图数据
            let data = {
                type: 'import',
                data: param.content.taskContent
            };
            setTimeout(function () {
                thisTemp.child.sendToIframe(data);
            }, 500);

            thisTemp.setState({ appInfo: param, cardTitle: '编辑应用', });
        }
        Socket.QueryData('QueryTagsGroups', { tagClass: 'task' }, (result) => {
            if (result.retcode === '00') {
                let GroupNames = [];
                result.groupNames.map((text) => {
                    GroupNames.push('(分组)' + text)
                });
                thisTemp.setState({
                    Tags: result.tags,
                    Groups: result.groupNames,
                    Tags_Groups: Utils.MergeArray(result.tags, GroupNames)
                });
            }
        });

    }
    onParamChange = (data) => {
        this.setState({ visibleTime: false, taskPlanData: data });
    };
    handleSubmit = (e) => {
        let thisTemp = this;
        let { taskPlanData, appInfo } = this.state;
        // e.preventDefault();
        thisTemp.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
                let reportTags = [];
                reportTags = values.reportTags.map((item) => {
                    return { type: 'fixed', name: item };
                });
                let body = values;
                body.reportTags = reportTags;
                let taskPlan = {
                    type: taskPlanData.type,
                    content: taskPlanData
                };
                if (taskPlanData.type === 'manual') {
                    taskPlan.type = 'manual'
                } else {
                    taskPlan.type = 'auto'
                }
                body.taskPlan = taskPlan;
                body.taskContent = _flow_data;

                let allData = {};
                allData = Object.assign({}, body, appInfo);
                console.log('allData', allData);
                Socket.Action("InstallApp2", allData, (resp) => {
                    if (resp.retcode === '00') {
                        message.success('成功');
                        //thisTemp.props.history.push('/task');
                    } else {
                        notification.open({
                            message: '失败',
                            description: resp.error,
                            icon: <Icon type="frown" style={{ color: 'red' }} />,
                        });
                    }
                }, "/installApp");
            }
        });
    };
    onChangeRadio = (e) => {
        console.log('radio checked', e.target.value);
        if (e.target.value === 'auto') {
            this.setState({
                timeBtn: true,
                RadioValue: e.target.value,
                visibleTime: true,
            });
        } else {
            this.setState({
                timeBtn: false,
                RadioValue: e.target.value,
                visibleTime: false,
            });
        }
    };
    TabCallback = (key) => {
        console.log(key);
    }
    handleTypeClose = (removedTag) => {
        const typeTags = this.state.typeTags.filter(tag => tag !== removedTag);
        console.log(typeTags);
        this.setState({ typeTags });
    }
    checkTag = (Arr) => {
        console.log(Arr);
        Arr.map((text) => {
            if (this.state.Tags_Groups.indexOf(text) === -1) {
                Socket.AddData('AddTag', { tagClass: 'task', tagName: text },
                    (result) => {
                        if (result.retcode === '00') {
                            message.success('标签新增成功')
                        } else {
                            message.success('标签新增失败');
                        }
                    }
                );
            }
        });
    };
    onFlowDataChange = (data) => {
        console.log(data);
        this.setState({
            flow_data: data
        });
        _flow_data = data;
        if (this.state.commit) {
            this.handleSubmit();
        }
    };
    onRef = (ref) => {
        this.child = ref;
    };
    render() {
        const { typeTags, inputValue, Tags_Groups, report_type,
            cardTitle, timeBtn, task, Groups, Tags } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        return (
            <div >
                <CustomBreadcrumb arr={["配置管理", { title: "任务", to: '/task' }, { title: '应用商店', to: '/appStore' }, '安装应用']} />
                <Card title={<span style={{ fontWeight: 600 }}>{cardTitle}</span>}>

                    <Form >
                        <Form.Item
                            label="任务名称"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('taskName', {
                                rules: [{
                                    type: 'string', message: '请输入正确字符！',
                                }, {
                                    pattern: new RegExp(/^[0-9a-zA-Z-\u4E00-\u9FA5]+$/, "g"), message: '含有空格等非法字符！',
                                }, {
                                    required: true, message: '请输入字符!',
                                }],
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label="任务描述"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('taskDes', {
                                rules: [{
                                    type: 'string', message: '请输入正确字符！',
                                }, {
                                    required: true, message: '请输入字符!',
                                }],
                            })(<TextArea style={{ height: 100 }} />)}
                        </Form.Item>
                        <Form.Item
                            label="添加标签"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('tags', {
                            })(
                                <Select
                                    mode="tags"
                                    placeholder="选择标签"
                                    onChange={this.checkTag}
                                >
                                    {Tags.map(owner => (
                                        <Option key={owner} value={owner}>
                                            {owner}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="添加分组"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('groupNames', {
                                rules: [{
                                    required: true, message: '请选择分组!',
                                }],
                            })(
                                <Select
                                    mode="tags"
                                    placeholder="选择分组"
                                >
                                    {Groups.map(owner => (
                                        <Option key={owner} value={owner}>
                                            {owner}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            label="执行方式"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('taskPlan.type', {
                                initialValue: this.state.RadioValue,
                            })(
                                <div>
                                    <RadioGroup onChange={this.onChangeRadio}
                                        value={this.state.RadioValue}
                                    >
                                        <Radio value="auto">自动</Radio>
                                        <Radio value="manual">手动</Radio>
                                    </RadioGroup>
                                    {timeBtn ? <Button icon='setting'
                                        onClick={() => {
                                            this.setState({ visibleTime: true });
                                        }}
                                    >设置</Button> : ''}
                                </div>
                            )}
                        </Form.Item>
                        <Form.Item
                            label="任务级别"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('level', {
                                rules: [{
                                    required: true, message: '请选择级别!',
                                }],
                            })(
                                <Select
                                    showSearch
                                    placeholder="选择级别"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    <Option value="1">级别1</Option>
                                    <Option value="2">级别2</Option>
                                    <Option value="3">级别3</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            label="报告类型"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('reportTags', {})(
                                <Select
                                    mode="multiple"
                                    placeholder="选择报告类型"
                                >
                                    {report_type.map((item, key) => (
                                        <Option key={key} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            label="任务编排"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('taskContent', {})(
                                <ArrayTask onFlowDataChange={(data) => { this.onFlowDataChange(data) }} onRef={this.onRef}></ArrayTask>
                            )}
                            {/*<ArrayTask onFlowDataChange={(data)=>{this.onFlowDataChange(data)}} onRef={this.onRef}></ArrayTask>*/}

                        </Form.Item>
                        <div id="iframeDiv">
                            <Button type="primary"
                                className="smallBtn"
                                icon="fullscreen-exit"
                                onClick={() => {
                                    $('#iframeDiv').removeClass('iframeDiv');
                                    $('#child').removeClass('iframeChild');
                                    $('#iframeDiv .smallBtn').css('display', 'none');
                                    let data = {
                                        type: 'zoomOut'
                                    };
                                    this.child.sendToIframe(data);
                                }}
                            >缩小</Button>
                            <iframe name="myFrame" id="child"
                                src="/flow/demo/demo_ide.html" frameBorder="0" scrolling="auto"
                                className="initChild test-1"></iframe>

                        </div>

                        <Form.Item
                            label=" "
                            colon={false}
                            {...formItemLayout}
                        >
                            <div >
                                <Button type="primary" icon="check-circle" htmlType="submit" style={{ width: 150 }}
                                    onClick={() => {
                                        let data = { type: 'export' };
                                        this.child.sendToIframe(data);
                                        this.setState({
                                            commit: true
                                        });
                                    }}
                                >
                                    保存
                                    </Button>
                                <Button type="danger" icon="close-circle" style={{ width: 150, marginLeft: 10 }}
                                    onClick={() => { this.props.history.goBack(); }}>
                                    取消
                                    </Button>
                            </div>
                        </Form.Item>

                    </Form>

                    <Modal
                        title="报告类型"
                        visible={this.state.visible}
                        onOk={(e) => {
                            let typeInput = document.getElementById('reportType');
                            let nameInput = document.getElementById('reportName');
                            let reportTags = {
                                type: typeInput.value,
                                value: nameInput.value
                            };
                            newTask_goobal.reportTags.push(reportTags);//放入全局变量
                            let str = '类型:' + reportTags.type + '-名称:' + reportTags.value;
                            typeTags.push(str);
                            this.setState({ visible: false, typeTags });
                            typeInput.value = nameInput.value = '';
                        }}
                        onCancel={(e) => { this.setState({ visible: false, }); }}
                        okText="确认"
                        cancelText="取消"
                    >
                        <div style={{ textAlign: 'center' }}>
                            <p><label>类型:</label><Input id="reportType" style={{ width: 300 }} /></p>
                            <label>名称:</label><Input id="reportName" style={{ width: 300 }} />
                        </div>
                    </Modal>

                    <Modal
                        title="时间计划"
                        visible={this.state.visibleTime}
                        footer={null}
                        onCancel={(e) => { this.setState({ visibleTime: false, }); }}
                    >
                        <TimePlan initValue={task && task.taskPlan.type === 'auto' ? task.taskPlan.content : null}
                            onParamChange={(data) => { this.onParamChange(data) }} />
                    </Modal>

                </Card>
            </div>
        );
    }
}
const newTaskFrom = Form.create()(newTask);

export default newTaskFrom;