import React, { useState } from 'react'
import {
    Card, Table, Button, Icon, Modal, message, Descriptions, Upload, Input
} from "antd";
import { AddDeviceCmdResp, AddModelCmdResp, AddsDeviceModel } from './api';
import { connect } from 'react-redux'
import CommandListTable from './CommandListTable';
import http from '@/axios/axios_utils';
import EditModal from '@/components/EditModal/index';
import Utils from '@/utils/utils';
import "./index.scss"
import "animate.css"
let tableThis = null
function RightCard(props) {
    const [visible, setVisible] = useState(false);
    const [addvisible, setAddVisible] = useState(false);
    const [propsSelectItem, setPropsSelectItem] = useState(props.selectItem);
    const [tempParam, setTempParam] = useState("");
    const [tempInput, setTempInput] = useState("");
    const [total,] = useState(10);
    const [_pageSize] = useState(20);
    const [_current] = useState(1);
    const [selectItem, setSelectItem] = useState({});
    const [selectedRowKeys, setRowKeys] = useState([])
    const [tableData, setTabData] = useState([]);
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
        },
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    function saveDataSource(val) {
        const { ip, type, vendor, version } = props.selectItem;
        if (!type || !vendor || !version) {
            message.error("请先点击左侧需要新增命令的节点!")
            return;
        }
        let httpUrl = AddModelCmdResp();
        if (ip) { val.ip = ip; httpUrl = AddDeviceCmdResp(); };
        val.author = sessionStorage.getItem("userName");
        val.class = props.selectItem.class;
        val.type = type;
        val.vendor = vendor;
        val.version = version;
        val.resp_id = new Date().getTime() + "";
        console.log(val);
        http.post(httpUrl, val, (r) => {
            message.success("新增成功！");
            setVisible(false);
            if (ip) {
                tableThis.GetDeviceCmdResp(props.selectItem);
            } else {
                tableThis.getCommList(props.selectItem);
            }
        })
    }
    function deleteData(val) {
        let temp = tableData.filter(item => item.name != val.name);
        setTabData(temp);
    }
    function getDescription() {
        const propsItem = props.selectItem
        console.log(propsItem)
        return [
            propsItem["ip"] ? <Descriptions.Item key="1" label={"设备IP"}>{propsItem["ip"]}</Descriptions.Item> : null,
            propsItem["name"] ? <Descriptions.Item key="12" label={"主机名"}>{propsItem["name"]}</Descriptions.Item> : null,
            <Descriptions.Item key="13" label={"厂商"}>{propsItem["vendor"]}</Descriptions.Item>,
            <Descriptions.Item key="14" label={"类型"}>{propsItem["class"]}</Descriptions.Item>,
            <Descriptions.Item key="15" label={"型号"}>{propsItem["type"]}</Descriptions.Item>,
            <Descriptions.Item key="16" label={"版本"}>{propsItem["version"]}</Descriptions.Item>,
            propsItem["port"] ? <Descriptions.Item key="17" label={"端口"}>{propsItem["port"]}</Descriptions.Item> : null,
        ]
    }
    function importFile() {
        const { ip, type, vendor, version, hostname } = props.selectItem;
        if (!tempParam || !tempInput) { message.error("请补充完整信息!"); return; };
        let val = {
            front_tips: tempInput,
            raw_log: tempParam,
            class: props.selectItem.class,
            type: type,
            vendor: vendor,
            version: version,
        }
        if (ip) {
            val.ip = ip;
            val.hostname = hostname;
        }
        http.post(AddsDeviceModel(), val, (r) => {
            message.success("操作成功!");
            setAddVisible(false)
        })
    }
    function uploadFilesChange(file) {
        if (Utils.isEmpty(file.fileList)) {
            return;
        }
        console.log(file)
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        // 以文本方式打开文件
        fileReader.readAsText(file.file);
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 存储获取到的数据
                console.log(result);
                setTempParam(result);
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                console.log(e);
                message.error('文件类型不正确！');
            }
        }

    }
    return <div className="RightCardDiv">
        <Descriptions bordered column={1} size="small" className="descStyle">
            {getDescription()}
        </Descriptions><p />
        {props.selectItem && props.selectItem.version &&
            <div>
                <Button type="primary" className="btnStyle" icon="file-add" onClick={() => {
                    setVisible(true)
                }}>添加命令</Button>&nbsp;
                <Button type="primary" className="btnStyle" icon="upload" onClick={() => {
                    setAddVisible(true)
                }}>批量添加</Button>
            </div>}
        <p />
        <CommandListTable {...props} refThis={(_this) => { tableThis = _this }} />
        <EditModal
            title="编辑命令"
            visible={visible}
            onCancel={() => setVisible(false)}
            onSubmit={(val) => {
                saveDataSource(val);
            }}
            footer={null}
            initialValues={selectItem}
            width="60%"
            style={{ top: 20 }}
            destroyOnClose
            formList={[
                { type: "input", name: "cmd", label: "命令", required: true, placeholder: "命令" },
                { type: "input", name: "resp_name", label: "名称", required: true, placeholder: "名称" },
                { type: "textArea", name: "desc", label: "描述", required: true, placeholder: "描述" },
                { type: "code", name: "resp", label: "返回", required: true, placeholder: "返回" },
            ]}
        />
        <Modal
            title="导入文件"
            visible={addvisible}
            onCancel={() => setAddVisible(false)}
            onOk={() => { importFile() }}
            destroyOnClose
        >
            <div>
                <p>
                    <label>命令头:</label>
                    <Input value={tempInput} onChange={(e) => { setTempInput(e.target.value) }} />
                </p>
                <Upload.Dragger name="file"
                    accept=".txt"
                    beforeUpload={function () { return false; }}
                    onChange={uploadFilesChange.bind(this)}>
                    <p className="ant-upload-drag-icon"><Icon type="inbox" /></p>
                    <p className="ant-upload-text">单击或将(.txt)文本文件拖到该区域上传</p>
                </Upload.Dragger>
            </div>
        </Modal>
    </div>
}

export default connect()(RightCard)