import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, notification, Modal, Tabs, Tooltip, Popover, Affix
} from "antd";
import AxiosObj from '@/axios/index';
import Utils from '@/utils/utils';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import { MonacoDiffEditor } from "react-monaco-editor"
import MonacaEditor from "react-monaco-editor"
import "./index.scss"
import CommForm from "./commForm"
import SimpleForm from "./simpleForm"
function AclExe() {
    const [loading, set_loading] = useState(false);
    const [] = useState(false);
    const [selectNode] = useState({});
    const [] = useState(false);
    useEffect(() => {//componentDidMount

        return () => { //componentWillUnmount
        }
    }, []);

    const onSubmit = (val, APINAME) => {
        val.operator = sessionStorage.getItem('userName') + "/" + sessionStorage.getItem('nickName');
        set_loading(true)
        AxiosObj.HTTP_POST("delivery", APINAME, val, (result) => {
            console.log(result);
            notification.success({ message: '提示', description: result.msg });
            const comparison_results = result.comparison_results || [];
            Modal.success({
                title: "详情可在下发记录中查询",
                width: "70%",
                okText: "关闭",
                content: <Table
                    columns={[
                        { title: "设备ip", dataIndex: "ip" },
                        {
                            title: "结果", dataIndex: "comparison_result", render: (a, b) => {
                                const elemt = b.code === "00" ? <Icon type="check-circle" style={{ color: "#87d068" }} /> : <Icon type="close-circle" style={{ color: "#f50" }} />
                                return <Tooltip title={a}>{elemt}&nbsp;{b.msg}</Tooltip>;
                            }
                        },
                        {
                            title: "详情", dataIndex: "details", render: (a, b) => {
                                const elemt = <Button type={b.code === "00" ? "primary" : "danger"} size="small"
                                    onClick={() => {
                                        Modal.info({
                                            width: "80%",
                                            title: "反馈信息",
                                            okText: "关闭",
                                            content: <MonacaEditor
                                                theme="vs-dark"
                                                value={a}
                                                language="shell"
                                                options={{ minimap: { enabled: false }, lineNumbers: "off", scrollBeyondLastLine: false, }}
                                                height="500"
                                            />
                                        })
                                    }}>详情</Button>
                                return elemt
                            }
                        },
                    ]}
                    pagination={false}
                    size="small"
                    dataSource={comparison_results}

                />
            })
            set_loading(false);
        }, (msg, data) => {
            set_loading(false)
            const comparison_results = data.comparison_results || [];
            if (data.comparison_results) {
                Modal.error({
                    title: "反馈信息",
                    width: "70%",
                    okText: "关闭",
                    content: <Table
                        columns={[
                            { title: "设备ip", dataIndex: "ip" },
                            {
                                title: "结果", dataIndex: "comparison_result", render: (a, b) => {
                                    const elemt = b.code === "00" ? <Icon type="check-circle" style={{ color: "#87d068" }} /> : <Icon type="close-circle" style={{ color: "#f50" }} />
                                    return <Tooltip title={a}>{elemt}&nbsp;{b.msg}</Tooltip>;
                                }
                            },
                            {
                                title: "详情", dataIndex: "details", render: (a, b) => {
                                    let elemt = <Popover title={"信息"} content={a} trigger="hover">{a}</Popover>;
                                    if ((typeof a === "string")) {
                                        if (a.length > 30) {
                                            elemt = <Button type={b.code === "00" ? "primary" : "danger"} size="small"
                                                onClick={() => {
                                                    Modal.info({
                                                        width: "80%",
                                                        title: "交互信息",
                                                        okText: "关闭",
                                                        maskClosable: true,
                                                        content: <MonacaEditor
                                                            theme="vs-dark"
                                                            value={a}
                                                            language="shell"
                                                            options={{ minimap: { enabled: false }, lineNumbers: "off", scrollBeyondLastLine: false, }}
                                                            height="600"
                                                        />
                                                    })
                                                }}>详情</Button>
                                        }
                                    } else if ((typeof a === "object")) {
                                        elemt = <Button type={b.code === "00" ? "primary" : "danger"} size="small"
                                            onClick={() => {
                                                Modal.info({
                                                    width: "95%",
                                                    title: "交互信息",
                                                    okText: "关闭",
                                                    maskClosable: true,
                                                    content: <div className="alcModal">
                                                        {/* <Row gutter={2}>
                                                            <Col span={12}><strong>基线配置</strong><div className="cmdComp">{a.baseline}</div></Col>
                                                            <Col span={12}><strong>设备配置</strong><div className="cmdComp">{a.device_config}</div></Col>
                                                        </Row> */}
                                                        <strong className="pull-left">设备配置</strong><strong className="pull-right">基线配置</strong>
                                                        <MonacoDiffEditor
                                                            theme="vs"
                                                            value={a.baseline}
                                                            original={a.device_config}
                                                            language="shell"
                                                            options={{ minimap: { enabled: false }, lineNumbers: "off", scrollBeyondLastLine: false, }}
                                                            height="600"
                                                        />

                                                    </div>
                                                })
                                            }}>详情</Button>
                                    }
                                    return elemt
                                }
                            },
                        ]}
                        scroll={{ y: 600 }}
                        pagination={false}
                        size="small"
                        dataSource={comparison_results}
                    />
                })
            }
            Utils.showMessage("error", '下发失败', msg);

        });
    }
    return <div className="aclCommandDivSimple">
        <CustomBreadcrumb
            arr={["运维管理", "配置下发", "简单下发", "下发指令"]}
        />
        <Card
        // title={Utils.BoldFont("下发指令")} className="templateCard" id="templateCard"
        //     extra={<div>
        //         <Button type="primary" icon="profile" onClick={() => { props.history.push("/command/simple/record") }}>下发记录</Button>
        //     </div>}
        >
            <Tabs>
                <Tabs.TabPane tab="简单模式" key="simple"><SimpleForm selectNode={selectNode} onSubmit={(val) => onSubmit(val, "configNoComparison")} loading={loading} /></Tabs.TabPane>
                <Tabs.TabPane tab="比对模式" key="compli"><CommForm selectNode={selectNode} onSubmit={(val) => onSubmit(val, "configDelivery")} loading={loading} /></Tabs.TabPane>
            </Tabs>
        </Card>
        <p />
    </div >
}
export default AclExe