import React from 'react'
import { Card, Table, Input, Typography, Popconfirm, Descriptions, Button, Icon, Tabs, Row, Col, message, Tag, Modal, Progress, List, Tooltip } from "antd";
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import AxiosObj from '@/axios/index';
import CustomBreadcrumb from "@/components/CustomBreadcrumb/index";
import $ from "jquery";
import DyHeadTable from '@/components/DyHeadTable/index';
const { Title, Text } = Typography;
const { confirm } = Modal;
export default class ReduPolicy extends React.Component {
    state = {
        s_current: 1,      //当前页码数
        s_pageSize: 20,    //每页显示条数
        s_querys: {},    //查询条件
        s_total: 10,
        s_loading: false,
        s_tableData: [],
    };
    componentWillMount() {
        this.updata();
    }

    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true })
        let { s_pageSize, s_current, s_total } = this.state;
        let bodyJson = {};
        bodyJson.page_size = pageSize || s_pageSize;
        bodyJson.page_no = pageNum || s_current;
        bodyJson.ip = sessionStorage.getItem("fwIp");
        AxiosObj.HTTP_POST("fw", "noEffectPolicy", bodyJson, (result) => {
            console.log(result);
            this.setState({
                s_tableData: result.data,
                // s_total: result.policy_account,
                loading: false
            })
        });
    };
    modalDetail(titleData, record) {
        confirm({
            content: <Descriptions bordered title="策略详情" size="small"
                layout="vertical"
            >
                {titleData.map((rec) => {
                    const temp = record[rec[0]]
                    return <Descriptions.Item label={rec[1]}>{
                        Array.isArray(temp) ? temp.map((DET, i) => { return <Tag key={i}>{DET}</Tag> }) : temp
                    }</Descriptions.Item>
                })}
            </Descriptions>,
            maskClosable: true,
            width: "60%",
        })
    }
    getContent(titleData, titleData2, record) {
        Modal.info({
            title: <Title level={4}>失效策略结果详情</Title>,
            content: <div>
                <Text strong>基本信息</Text>
                <Descriptions bordered size="small"
                    layout="vertical"
                >
                    {titleData.map((rec) => {
                        return <Descriptions.Item label={rec[1]}>{record[rec[0]]}</Descriptions.Item>
                    })}
                </Descriptions>
                <Text strong>策略组</Text>
                <DyHeadTable
                    type="poloDetail2"
                    dataSource={record["policy_group"]}
                    columns={[...titleData2.map((item) => {
                        return {
                            title: item[1],
                            align: 'center',
                            width: 150,
                            ellipsis: true,
                            dataIndex: item[0],
                            render: (text, rec, key) => {
                                return <Tooltip placement="topLeft" key={key} mouseLeaveDelay={0.07} title={rec[item[0]]}>{text}</Tooltip>
                            }
                        }
                    }), {
                        title: "操作",
                        width: 150,
                        dataIndex: "op",
                        fixed: "right",
                        render: (text, rec, key) => { return <Button type="primary" onClick={() => { this.modalDetail(titleData2, rec) }}>详情</Button> }
                    }]}
                    size="small"
                />
            </div>,
            maskClosable: true,
            width: "90%",
            okText: "关闭"
        })
    }
    render() {
        let {
            s_current, s_loading, s_pageSize, s_tableData, s_total, } = this.state;
        const titleData = [["ip", "防火墙ip",],
        ["policy_analyze_result", "策略分析结果"],
        ["add_time", "策略添加时间"],
            // ["policy_group", "策略组"],
        ];
        const titleData2 = [["ip", "防火墙ip",],
        ["rule_name", "策略名称"],
        ["policy_matched", "策略命中数"],
        ["description", "策略描述"],
        ["source_zone", "源区域"],
        ["destination_zone", "目的区域"],
        ["source_address", "源地址"],
        ["destination_address", "目的地址"],
        ["application", "策略开通应用"],
        ["action", "策略开通权限"],
        ["service", "策略开通的服务名称"],
        ["time_range", "策略开通时间段名称"],
        ["time_range_details", "策略开通时间段详情"],
        ["service_details", "策略开通服务详情"],
        ];
        const columnsDev = [...titleData.map((item) => {
            return {
                title: item[1],
                align: 'center',
                // width: 150,
                ellipsis: true,
                dataIndex: item[0],
                render: (text, rec, key) => {
                    let temp = text;
                    switch (text) {
                        case "冗余策略": temp = <Tag color="cyan">{text}</Tag>; break;
                        case "冲突策略": temp = <Tag color="orange">{text}</Tag>; break;
                        case "失效策略": temp = <Tag color="geekblue">{text}</Tag>; break;
                        case "未命中策略": temp = <Tag color="red">{text}</Tag>; break;
                    };
                    return <Tooltip placement="topLeft" key={key} mouseLeaveDelay={0.07} title={text}>{temp}</Tooltip>
                }
            }
        }), {
            title: "操作",
            dataIndex: "op",
            render: (text, item, index) => {
                return <Button type="primary" key={index}
                    onClick={() => { this.getContent(titleData, titleData2, item) }}>
                    <Icon type="search" />查看详情
            </Button>
            }
        }
        ];
        return (
            <div className="home-body">
                <CustomBreadcrumb arr={["运维管理", "防火墙", { title: "防火墙列表", to: "/fireWall" }, "失效策略"]} />
                <Card title={<span style={{ fontWeight: 600 }}>防火墙失效策略</span>}
                    extra={<Button type="primary" icon={'rollback'}
                        onClick={() => { this.props.history.goBack(); }}>返回防火墙列表</Button>}
                    className="Card-task">
                    <Table
                        dataSource={s_tableData}
                        columns={columnsDev}
                        size="small"
                        loading={s_loading}
                        pagination={{
                            current: s_current, pageSize: s_pageSize, total: s_total,
                            onChange: (page, pageSize) => { this.updata(pageSize, page, s_querys); }
                        }}
                        onRow={record => {
                            return {
                                onDoubleClick: event => {
                                    this.getContent(titleData, titleData2, record)
                                }, // 点击行
                            };
                        }}
                    /><Text type="secondary">备注:双击行可查看详情~</Text>
                </Card>
            </div>
        );
    }
} 