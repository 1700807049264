import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from "antd";
import { connect } from 'react-redux'
import ReactEcharts from "echarts-for-react"
import Socket from '@/socket/index'
import Utils from '@/utils/utils'
const colorArray = ["#626c91", "#979797", "#6be6c1", "#c4ebad", "#96dee8"];
function DataMap(props) {
    const [data2, setData2] = useState({});
    useEffect(() => {//componentDidMount
        statisAreaDeviceTypeNum()
        let _timer = setInterval(() => {
            statisAreaDeviceTypeNum()
        }, props.timer || 60000);
        return () => clearInterval(_timer)
    }, []);
    const getMutilCloums = (_data2) => ({
        legend: {
            orient: 'horizontal',
            top: 30,
            type: "scroll",
            left: 0,
            itemHeight: 8,
            itemWidth: 8,
            textStyle: {
                color: "#FFF"
            },
            data: _data2.type
        },
        color: ["#3fb1e3", "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8"],
        tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#fff"
                }
            },
        },
        xAxis: {
            type: 'category',
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#fff"
                }
            },
            data: _data2.area
        },
        series: _data2.series
    })

    function tranl(text) {
        let name = text;
        if (text === "switch") {
            name = "交换机";
        } else if (text === "firewall") {
            name = "防火墙";
        } else if (text === "router") {
            name = "路由器";
        }
        return name;
    }
    function statisAreaDeviceTypeNum() {
        Socket.ExecuteAction('statisAreaDeviceTypeNum',
            {}, "/", (r) => {
                createData2(r.data);
            });

    }
    function createData2(data) {

        const area = Object.keys(data);
        let typeList = []
        area.map((a) => {
            const arr = Object.keys(data[a])//{} 
            typeList = Utils.MergeArray(arr, typeList);
        })

        let series = typeList.map((_type) => {
            return {
                name: tranl(_type),
                type: 'bar',
                stack: '总量',
                label: {
                    show: true,
                },
                // itemStyle: {
                //     color: "#a0a7e6"
                // },
                data: area.map((aa) => {
                    return data[aa][_type]
                })
            }
        })
        let tempData = {
            type: typeList.map((u) => tranl(u)),
            area,
            series
        }
        setData2(tempData)
    }
    return <div className="card-item animated zoomIn" >
        <div className="card-header">
            <div>
                < img src="/image/arrow.svg" alt="" />
                <span style={{ marginLeft: 10, color: '#61ddff' }}>{props.description}</span>
            </div>
        </div>
        <ReactEcharts
            option={getMutilCloums(data2)}
        // style={style.chart} 
        />
    </div>
}
export default connect()(React.memo(DataMap))
