import React from 'react'
import { Row, Col, Icon, Menu, Dropdown, Badge, Avatar, notification, message, Modal } from "antd"
import './index.scss'
import Util from '../../utils/utils'
import Socket from '../../socket/index'
import axios from '../../axios'
import { connect } from 'react-redux'
//使用withRouter包裹connect,实现跳转的问题；
import { withRouter } from 'react-router';
import WarnModal from "./warnModal"
class Header extends React.Component {
    state = {
        collapsed: false,
        warnVis: false,
        warnNum: 0,
        WarnData: [],
    };
    componentWillMount() {
        this.setState({
            userName: sessionStorage.getItem('userName')
        });
        // setInterval(() => {
        //     let sysTime = Util.formateDate(new Date().getTime());
        //     this.setState({
        //         sysTime
        //     })
        // }, 1000);
        this.loginTimeOut();
        this.ListenWarnInfo();
        if (!window.localStorage) {
            notification.warning({ message: "提示消息", description: "浏览器不支持localstorage,请更新浏览器版本，以获得更好体验！" })
        }
    }

    loginTimeOut = () => {
        Socket.Notice((resp) => {
            if (resp.retcode === '101') {
                notification.open({
                    message: '登录超时通知',
                    description: resp.error,
                    icon: <Icon type="smile" style={{ color: 'red' }} />,
                });
            }
        });
    };
    ListenWarnInfo = () => {
        const _this = this;
        Socket.Notice((resp) => {
            console.log(resp);
            if (resp.type === "noticeBar") {        //显示Spin
                _this.setState({ warnNum: resp.value, })
                notification["info"]({
                    message: '消息通知',
                    description:
                        '您收到了' + resp.value + '条通知，请点击右上角查看详情.',
                });
            } else {
                // console.log(resp);
            }
        });
    };
    openWarn() {
        Socket.ExecuteAction("QueryWarnsAll",
            {}, "/QueryWarnsAll", (result) => {
                this.setState({
                    WarnData: result.data,
                    warnVis: true
                })
            });
    }

    getWeatherAPIData(city) {
        // let city = '深圳';
        axios.jsonp({
            url: 'http://api.map.baidu.com/telematics/v3/weather?location=' + encodeURIComponent(city) + '&output=json&ak=3p49MVra6urFRGOT9s8UBWr2'
        }).then((res) => {
            if (res.status === 'success') {
                let data = res.results[0].weather_data[0];
                this.setState({
                    dayPictureUrl: data.dayPictureUrl,
                    weather: data.weather,
                    city: city
                })
            }
        })
    }

    exit = () => {
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('nickName');
        sessionStorage.removeItem('menuList');
        sessionStorage.removeItem('FixedBtn');
        sessionStorage.clear();
        this.props.history.push('/login');
        // this.props.router.push('/login');
        // window.location.href = '#/login'
        window.location.reload();
    };
    toggle = () => {
        let old = this.props.collapsed;
        let collapsed = true;
        if (old) {
            collapsed = false
        }
        this.props.onToggle(collapsed)
    };
    render() {
        const { menuName, menuType, collapsed, history, mode, setLayout, setStyle } = this.props;
        const { warnNum, warnVis, WarnData } = this.state
        const menu = (
            <Menu style={{ textAlign: 'center' }}>
                <Menu.Item style={{ textAlign: 'center', fontWeight: 600, borderBottom: '1px solid #e2e2e2' }}>
                    <span>个人中心</span>
                </Menu.Item>
                {/* <Menu.Item>
                    <a onClick={() => {
                        this.props.history.push('/userInfo');
                    }}><Icon type="message" />  个人信息{this.state.val}</a>
                </Menu.Item> */}
                {/* <Menu.Item>
                    <a target="_blank" rel="noopener noreferrer" href="">系统设置</a>
                </Menu.Item> */}
                <Menu.Item >
                    <a target="_blank" onClick={() => this.exit()}><Icon type="logout" />  退出登录</a>
                </Menu.Item>
            </Menu>
        );
        const mesgMenu = (
            <Menu className="header-msgMenu">
                <Menu.Item style={{ textAlign: 'center', fontWeight: 600, borderBottom: '1px solid #e2e2e2' }}>
                    <span>消息中心</span>
                </Menu.Item>
                {/* <Menu.Item>
                    <a target="_blank" onClick={() => { this.openWarn() }}><Icon type="exclamation-circle" /> 告警消息
                        <Badge count={warnNum} style={{ backgroundColor: 'red' }} />
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a target="_blank" ><Icon type="message" /> 全部消息
                          <Badge count={100} style={{ backgroundColor: '#2196f3' }} /> 
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a target="_blank"><Icon type="notification" /> 任务提醒
                          <Badge count={75} style={{ backgroundColor: '#faad14' }} />  
                    </a>
                </Menu.Item> */}

                <Menu.Item>
                    <a target="_blank"><Icon type="smile" /> 服务消息
                         {/* <Badge count={25} style={{ backgroundColor: '#52c41a' }} /> */}
                    </a>
                </Menu.Item>
                <Menu.Item onClick={() => { this.setState({ warnNum: 0 }) }}>
                    <a target="_blank"><Icon type="close-circle" /> 清空消息
                    </a>
                </Menu.Item>
                <Menu.Item onClick={() => {
                    localStorage.clear();
                    Modal.confirm({
                        title: "提示信息",
                        content: <div>清空页面存储成功，刷新页面生效<br />是否立即刷新页面？</div>,
                        okText: "刷新",
                        onOk() { location.reload() }
                    })
                }}>
                    <a target="_blank"><Icon type="close" /> 清空存储
                    </a>
                </Menu.Item>
            </Menu>
        );
        const styleMenu = (
            <Menu className="header-msgMenu">
                <Menu.Item style={{ textAlign: 'center', fontWeight: 600, borderBottom: '1px solid #e2e2e2' }}>
                    <span>设置中心</span>
                </Menu.Item>
                <Menu.SubMenu key="g1" title="页面布局">
                    <Menu.Item>
                        <a target="_blank" onClick={() => { setLayout("top") }}><Icon type="caret-up" />顶部布局</a>
                    </Menu.Item>
                    <Menu.Item>
                        <a target="_blank" onClick={() => { setLayout("left") }}><Icon type="caret-left" />侧边布局</a>
                    </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="g2" title="菜单样式">
                    <Menu.Item>
                        <a target="_blank" onClick={() => { setStyle("dark") }}><Icon type="bulb" theme="filled" />深色</a>
                    </Menu.Item>
                    <Menu.Item>
                        <a target="_blank" onClick={() => { setStyle("light") }}><Icon type="bulb" />浅色</a>
                    </Menu.Item>
                </Menu.SubMenu>

            </Menu>
        );

        return (
            <div className={mode === "horizontal" ? 'header2' : "header"} >
                <Row className="header-top" id="header">
                    {mode === "inline" ? <Icon
                        type={collapsed ? 'menu-unfold' : 'menu-fold'}
                        className='trigger'
                        id="icon_top"
                        onClick={this.toggle} /> : ""}

                    <Badge
                        count={warnNum} overflowCount={99}
                        offset={[11, -6]} style={{ marginRight: 30 }}
                        id="Badge"
                    >
                        <Dropdown overlay={mesgMenu} placement="bottomCenter" trigger={["click"]}
                            getPopupContainer={() => document.getElementById('header')}
                        >
                            <Icon type="bell" style={{ marginRight: 30 }} />
                        </Dropdown>

                    </Badge>
                    <Dropdown overlay={styleMenu} placement="bottomCenter" trigger={["click"]}
                        getPopupContainer={() => document.getElementById('header')}
                    >
                        <Icon type="setting" style={{ marginRight: 30 }} />
                    </Dropdown>
                    {/* &nbsp;&nbsp;&nbsp; */}
                    <span id="userDiv">
                        <Dropdown overlay={menu} placement="bottomCenter" trigger={["click"]}
                            getPopupContainer={() => document.getElementById('header')}
                        >
                            <a className="ant-dropdown-link" >
                                {/* <Avatar src="image/top.png" size="small" /> */}
                                <Avatar style={{ backgroundColor: "#1890ff", verticalAlign: 'middle' }} size="default">
                                    {sessionStorage.getItem('userName')}
                                </Avatar>
                                <span style={{ marginRight: 8, marginLeft: 10 }}>
                                    {sessionStorage.getItem('userName')}/
                                    {sessionStorage.getItem('nickName')}
                                </span>
                                <Icon type="down" />
                            </a>
                        </Dropdown>
                    </span>
                    <span> </span>
                </Row>
                {
                    mode === "horizontal" ? '' : ""
                    // <Row className="breadcrumb">
                    //     <Col span={4} className="breadcrumb-title">
                    //         {/* <Icon type="sound" /><marquee>广播消息~</marquee> */}
                    //     </Col>
                    //     <Col span={20} className="weather">

                    //         <span className="date">
                    //             <Icon type="clock-circle" />&nbsp;
                    //             {this.state.sysTime}</span>
                    //         <span className="weather-img">
                    //             <img src={this.state.dayPictureUrl} alt="" />
                    //         </span>
                    //         <span className="weather-detail">
                    //             {this.state.weather}
                    //         </span>
                    //         <span className="weather-detail">
                    //             {this.state.city}
                    //         </span>
                    //     </Col>
                    // </Row>
                }
                <WarnModal
                    title="告警信息详情"
                    visible={warnVis}
                    WarnData={WarnData}
                    history={this.props.history}
                    onCancel={() => this.setState({ warnVis: false })}
                    onOk={() => this.setState({ warnVis: false })}
                    width="70%"
                    style={{ top: "20px" }}
                    destroyOnClose
                />
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        menuName: state.menuName
    }
};

export default withRouter(connect(mapStateToProps)(Header))
