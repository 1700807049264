import React from 'react'
import { Card, Table, Button, Upload, message, Pagination, Tag, Modal, Row, Tabs } from "antd";
import axios from "../../../axios/axios_utils"
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import EditModal from '@/components/EditModal/index';
import MonacoEditor from 'react-monaco-editor';
import ActionCmd from './actionCmd';
import * as XLSX from 'xlsx';
import Utils from "@/utils/utils";
const closureUrl = "http://" + backup_address + "/ZJNMS_V1/";

export default class PortRecord extends React.Component {
    state = {
        _current: 1,      //当前页码数
        _pageSize: 30,    //每页显示条数
        querys: {},    //查询条件
        _current2: 1,      //当前页码数
        _pageSize2: 10,    //每页显示条数
        querys2: {},    //查询条件
        selectItem: {},
        total: 0,
        total2: 0,
        loading: false,
        visibleInfo: false,
        visible: false,
        tableData: [],
        tablePortInfo: [],
        detailInfo: "",
        editTitle: "新增",
        selectedRowKeys1: [],
        selectedRowKeys: []
    };
    componentWillMount() {
        this.getRecord();
        this.getClosePortTable();
    }
    getClosePortTable(page, pageSize, query) {
        const { _current2, _pageSize2, querys2 } = this.state;
        this.setState({ loading: true })
        let bodyJson = {
            page_no: page || _current2,
            page_size: pageSize || _pageSize2,
            match: query || querys2
        };
        axios.get(closureUrl + "port/getClosePortTable", bodyJson, (res) => {
            if (res.code === "00") {
                this.setState({
                    total2: res.count,
                    tablePortInfo: res.data,
                    _current2: page || 1,
                    loading: false
                })
            }
        })
    }
    importDB(data) {
        let _this = this
        Modal.confirm({
            title: '消息提示',
            content: '导入表格将会完全覆盖原来表格的数据，你确定导入吗？',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                axios.post(closureUrl + "port/importClosePortTable", data, (res) => {
                    if (res.code === "00") {
                        message.success("导入成功！");
                        _this.getClosePortTable();
                    }
                })
            },
        });
    }
    getRecord(page, pageSize, query) {
        const { _current, _pageSize, querys } = this.state;
        this.setState({ loading: true })
        let bodyJson = {
            page_no: page || _current,
            page_size: pageSize || _pageSize,
            match: query || querys
        };
        axios.post(closureUrl + "port/getSwPortInfo", bodyJson, (res) => {
            if (res.code === "00") {
                this.setState({
                    total: res.count,
                    tableData: res.data,
                    _current: page || 1,
                    loading: false
                })
            }
        })
    }
    deleteCloseRecord(ids) {
        // console.log(ids)
        let bodyJson = {
            _ids: ids
        }
        if (ids.length === 0) {
            message.error("请选择需要删除的记录！")
        } else {
            // console.log(bodyJson)
            axios.delete_body(closureUrl + "port/delCloseRecord", bodyJson, (res) => {
                if (res.code === "00") {
                    message.success("删除成功");
                    this.setState({ selectedRowKeys: [] });
                    this.getRecord();
                } else {
                    message.error("删除失败")
                }
            })
        }
    }
    deletePortInfo() {
        // console.log(ids)
        const ids = this.state.selectedRowKeys1
        let bodyJson = {
            _ids: ids
        }
        if (ids.length === 0) {
            message.error("请选择需要删除的记录！")
        } else {
            axios.delete_body(closureUrl + "port/delPortInfo", bodyJson, (res) => {
                if (res.code === "00") {
                    message.success("删除成功");
                    this.setState({ selectedRowKeys: [] });
                    this.getClosePortTable();
                } else {
                    message.error("删除失败")
                }
            })
        }
    }
    EditData(bodyJson) {
        let { selectItem } = this.state
        if (!bodyJson["close_time"].match(/^((?:19|20)\d\d)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/)) {
            message.error("时间格式错误，应为2021-12-02")
            return
        }
        if (selectItem._id) {
            bodyJson._id = selectItem._id
            axios.post(closureUrl + "port/updatePortInfo", bodyJson, (res) => {
                if (res.code === "00") {
                    message.success("更新成功");
                    this.getClosePortTable();
                } else {
                    message.error("更新失败")
                }
            })
        } else {

            axios.post(closureUrl + "port/addPortInfo", bodyJson, (res) => {
                if (res.code === "00") {
                    message.success("新增成功");
                    this.getClosePortTable();
                } else {
                    message.error("新增失败")
                }
            })
        }
        this.setState({ visible: false })

    }
    getTableInfo() {
        let { tablePortInfo, _current2, _pageSize2, querys2, total2 } = this.state;
        // 多选
        const rowSelection1 = {
            selectedRowKeys: this.state.selectedRowKeys1,
            onChange: (selectedRowKeys) => {
                this.setState({ selectedRowKeys1: selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys1: [] });
                    },
                },
            ],
        };
        return <div>
            <Table
                dataSource={tablePortInfo}
                rowSelection={rowSelection1}
                size="small"
                pagination={false}
                rowKey={(text) => { return text._id }}
                columns={[
                    { title: "端口名", dataIndex: "port_name", },
                    { title: "所属设备IP", dataIndex: "ip", },
                    { title: "到期操作时间", dataIndex: "close_time", },
                    { title: "设备类型", dataIndex: "device_type", },
                    { title: "操作类型", dataIndex: "action_type", },
                    {
                        title: "状态", dataIndex: "status", render: (text) => {
                            let renHtml = <></>
                            switch (text) {
                                // case 0: renHtml = <Tag color="orange">未启动</Tag>; break;
                                case 1: renHtml = <Tag color="#87d068">未到期</Tag>; break;
                                case 2: renHtml = <Tag >到期已执行</Tag>; break;
                                case 3: renHtml = <Tag color="red">平台未导入设备</Tag>; break;
                                case 4: renHtml = <Tag color="red">出现错误</Tag>; break;
                                default: break;
                            }
                            return renHtml
                        }, width: "12%"
                    },
                    {
                        title: "操作", render: (text, item) => {
                            return <Button type="primary" icon="edit" size="small" onClick={() => this.setState({ visible: true, selectItem: item, editTitle: "编辑" })}>编辑</Button>
                        }
                    },
                ]}
            /><br />
            <Pagination
                showSizeChanger
                pageSize={_pageSize2}
                showTotal={(total) => { return <span>共 {total} 项</span> }}
                onShowSizeChange={(current, pageSize) => {
                    this.setState({ _pageSize2: pageSize });
                    this.getClosePortTable(1, pageSize, querys2);
                }}
                // defaultCurrent={_current2}
                current={_current2}
                total={total2}
                onChange={(page, pageSize) => {
                    //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                    this.getClosePortTable(page, pageSize, querys2);
                }}
            />
            {/* {tablePortInfo.map((one) =>
                <Card title={"设备IP：" + one.ip}>
                    <Table
                        dataSource={one.ports}
                        size="small"
                        pagination={false}
                        columns={[
                            { title: "端口名", dataIndex: "port_name", width: "20%" },
                            { title: "到期操作时间", dataIndex: "close_time", width: "20%" },
                            { title: "设备类型", dataIndex: "device_type", width: "20%" },
                            { title: "操作类型", dataIndex: "action_type", width: "20%" },
                            {
                                title: "状态", dataIndex: "status", render: (text) => {
                                    let renHtml = <></>
                                    switch (text) {
                                        // case 0: renHtml = <Tag color="orange">未启动</Tag>; break;
                                        case 1: renHtml = <Tag color="#87d068">未到期</Tag>; break;
                                        case 2: renHtml = <Tag >到期已执行</Tag>; break;
                                        case 3: renHtml = <Tag color="red">平台未导入设备</Tag>; break;
                                        case 4: renHtml = <Tag color="red">出现错误</Tag>; break;
                                        default: break;
                                    }
                                    return renHtml
                                }
                            },
                            {
                                title: "操作", dataIndex: "status", render: (text, item) => {
                                    return <Button type="primary" icon="edit" size="small" onClick={() => this.EditData(item)}>编辑</Button>
                                }
                            },
                        ]}
                    />
                </Card>
            )}
            <Pagination
                showSizeChanger
                pageSize={_pageSize2}
                onShowSizeChange={(current, pageSize) => {
                    this.setState({ _pageSize2: pageSize });
                    this.getClosePortTable(1, pageSize, querys2);
                }}
                // defaultCurrent={_current2}
                current={_current2}
                total={total2}
                onChange={(page, pageSize) => {
                    //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                    this.getClosePortTable(page, pageSize, querys2);
                }}
            /> */}
        </div>
    }
    uploadFilesChange = (file) => {
        console.log(file)
        let _this = this;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = {};
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {

                    let tempData = [];
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data[sheet] = tempData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    }
                }
                // 最终获取到并且格式化后的 json 数据
                _this.importDB(data)

            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                console.log(e);
                message.error('文件类型不正确！');
            }
        }
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(file.file);
    }
    downloadTemplate = () => {
        axios.get(closureUrl + "port/exportClosePortTable", {}, (res) => {
            if (res.code === "00") {
                let list = res.data || []
                Utils.exportSheetExcel(list, "设备信息", "设备端口信息表.xlsx");
            }
        })


    }
    startAutoTask() {
        axios.post(closureUrl + "port/startAutoClosePort", {}, (res) => {
            if (res.code === "00") {
                message.success("开启成功！");
                this.getClosePortTable();
            }
        })
    }
    render() {
        const { tableData, _current, _pageSize, total, visibleInfo, detailInfo, selectedRowKeys, visible, _pageSize2, editTitle, selectItem, querys } = this.state;
        const columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                width: 70,
                render: (text, item, index) => {
                    return <span>{index + 1 + (_current - 1) * _pageSize}</span>
                }
            },
            {
                title: 'IP地址',
                align: 'center',
                dataIndex: 'switchIp',
            },
            {
                title: '执行结果',
                align: 'center',
                dataIndex: 'code',
                render: (text) => {
                    let renHtml = <></>
                    switch (text) {
                        case "00": renHtml = <Tag color="#87d068">成功</Tag>; break;
                        case "01": renHtml = <Tag color='#ff0000'>失败</Tag>; break;
                        default: break;
                    }
                    return renHtml
                }
            },
            {
                title: '创建时间',
                ellipsis: true,
                align: 'center',
                dataIndex: 'crete_time',
            },
            {
                title: '设备操作信息',
                ellipsis: true,
                align: 'center',
                dataIndex: 'portsInfo',
                render: (text) => {
                    // return <Table
                    //     size="small"
                    //     // showHeader={false}
                    //     dataSource={text}
                    //     columns={[
                    //         { title: "端口", dataIndex: "port_name", width: "33%" },
                    //         { title: "操作", dataIndex: "action_type", width: "33%" },
                    //         { title: "类型", dataIndex: "device_type", width: "33%" },
                    //     ]}
                    //     pagination={false}
                    // />
                    return <table style={{ width: "100%", border: "1px solid #e2e2e2", textAlign: "center" }}>
                        <tbody>
                            <tr><th>端口</th><th>操作</th><th>类型</th></tr>
                            {text.map((item) => <tr><td>{item.port_name}</td><td>{item.action_type}</td><td>{item.device_type}</td></tr>)}
                        </tbody>
                    </table>
                }
            },
            {
                title: '详情',
                align: 'center',
                ellipsis: true,
                dataIndex: 'ssh_info',
                render: (text) => {
                    return (
                        <Button type="primary" size="small" icon="edit" onClick={() => {
                            this.setState({
                                visibleInfo: true,
                                detailInfo: text
                            })
                        }}>详情</Button>
                    )
                }
            },
        ];
        const FormList = [
            {
                label: "IP",
                type: 'INPUT',
                field: "switchIp",
                initialValue: '',
                placeholder: "IP"
            }
        ]
        const addformList = [
            { type: "input", name: "ip", label: "设备IP", placeholder: "设备IP", required: true, },
            { type: "input", name: "port_name", label: "端口名称", placeholder: "端口名称", required: true, },
            { type: "input", name: "device_type", label: "设备类型", placeholder: "设备类型", required: true, },
            { type: "input", name: "action_type", label: "操作类型", placeholder: "操作类型", required: true, },
            { type: "input", name: "close_time", label: "关闭时间", placeholder: "关闭时间(格式：2021-12-12)", required: true, },
        ]

        // 多选
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };


        return (
            <div>
                <CustomBreadcrumb arr={["端口管理", "端口记录"]} />

                <Card title={<span style={{ fontWeight: 600 }}>端口记录</span>}
                    style={{ marginTop: 8 }}
                    extra={
                        <Row>

                        </Row>
                    }
                >
                    <Tabs>
                        <Tabs.TabPane tab="端口信息表" key="table">
                            <Card>
                                <FilterForm
                                    formList={[
                                        {
                                            label: "IP",
                                            type: 'INPUT',
                                            field: "ip",
                                            initialValue: '',
                                            placeholder: "IP"
                                        },
                                        {
                                            label: "端口名称",
                                            type: 'INPUT',
                                            field: "port_name",
                                            initialValue: '',
                                            placeholder: "端口名称"
                                        }
                                    ]}
                                    filterSubmit={(querys) => {
                                        this.setState({ querys2: querys })
                                        this.getClosePortTable(1, _pageSize2, querys)
                                    }}
                                    resetTab={() => {
                                        this.setState({ querys2: {} })
                                        this.getClosePortTable(1, _pageSize2, {})
                                    }}
                                /><br />
                                <Upload name="file" accept=".xls,.xlsx,.csv"
                                    beforeUpload={function () { return false; }}
                                    onChange={this.uploadFilesChange.bind(this)}
                                    showUploadList={false}
                                >
                                    <Button type="primary" icon="import" onClick={() => { }}>导入表格</Button>&nbsp;
                                </Upload>
                                <Button type="primary" icon="download" onClick={() => { this.downloadTemplate() }}>下载表格</Button>&nbsp;
                                <Button type="primary" icon="file-add" onClick={() => { this.setState({ visible: true, selectItem: {}, editTitle: "新增" }) }}>新增数据</Button>&nbsp;
                                <Button type="danger" icon="delete" onClick={() => {
                                    let _this = this;
                                    Modal.confirm({
                                        title: '消息提示',
                                        content: '你确定删除该记录吗？',
                                        okText: '确定',
                                        okType: 'danger',
                                        cancelText: '取消',
                                        onOk() {
                                            _this.deletePortInfo()
                                        },
                                    });
                                }}>删除信息</Button>
                            </Card>
                            {this.getTableInfo()}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="操作命令" key="cmd">
                            <ActionCmd />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="操作记录" key="record">
                            <Card>
                                <FilterForm
                                    formList={FormList}
                                    filterSubmit={(querys) => {
                                        this.setState({ querys })
                                        this.getRecord(1, _pageSize, querys)
                                    }}
                                    resetTab={() => {
                                        this.setState({ querys: {} })
                                        this.getRecord(1, _pageSize, {})
                                    }}
                                /><br />
                                <Button type="danger" icon="delete" onClick={() => {
                                    let _this = this;
                                    Modal.confirm({
                                        title: '消息提示',
                                        content: '你确定删除该记录吗？',
                                        okText: '确定',
                                        okType: 'danger',
                                        cancelText: '取消',
                                        onOk() {
                                            _this.deleteCloseRecord(selectedRowKeys)
                                        },
                                    });
                                }}>删除端口操作记录</Button>
                            </Card>
                            <Table
                                dataSource={tableData}
                                columns={columns}
                                loading={this.state.loading}
                                rowKey={(text) => { return text._id }}
                                rowSelection={rowSelection}
                                size="small"
                                pagination={{
                                    current: _current, pageSize: _pageSize, total: total,
                                    showTotal: (total) => { return <span>共 {total} 项</span> },
                                    pageSizeOptions: ["20", "30", "50", "100"],
                                    showSizeChanger: true,
                                    onShowSizeChange: (current, size) => {
                                        this.setState({ _pageSize: size })
                                        this.getRecord(1, size, querys);
                                    },
                                    onChange: (page, pageSize) => {
                                        //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                        this.getRecord(page, pageSize, querys);
                                    }
                                }}
                            />
                        </Tabs.TabPane>
                    </Tabs>

                </Card>
                <Modal
                    title="端口详情"
                    visible={visibleInfo}
                    onCancel={() => this.setState({ visibleInfo: false, detailInfo: "" })}
                    footer={null}
                    destroyOnClose
                    width="80%"
                >
                    <MonacoEditor
                        language="java"
                        theme={"vs"}
                        width="99%"
                        height="300"
                        value={detailInfo}
                    />
                    <br />
                    <div className="btn-div" style={{ textAlign: 'center', marginTop: 20 }}>
                        <Button type="primary" ghost onClick={() => { this.setState({ visibleInfo: false, detailInfo: "" }) }}>取消</Button>
                        <Button type="primary" style={{ marginLeft: 10 }} onClick={() => { this.setState({ visibleInfo: false, detailInfo: "" }) }}>确定</Button>
                    </div>
                </Modal>
                <EditModal
                    title={editTitle}
                    visible={visible}
                    onCancel={() => this.setState({ visible: false, })}
                    onSubmit={(val) => {
                        this.EditData(val)
                    }}
                    style={{ top: 20 }}
                    footer={null}
                    width="55%"
                    initialValues={selectItem}
                    destroyOnClose
                    formList={addformList}
                />
            </div>
        )
    }
}
