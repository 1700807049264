import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Divider, Popconfirm, Tag, Descriptions, List, Modal, Affix,
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import Socket from '@/socket/index';
import { connect } from 'react-redux'
import { device_snmp, device } from '../utils/api'
import ModuleList from './moduleList';
import Utils from '@/utils/utils';
import "./index.scss"
import "animate.css"
function DetailDevice(props) {
    const [visible, setVisible] = useState(false);
    const [total, setTotal] = useState(10);
    const [_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const [selectItem, setSelectItem] = useState({});
    const [selectedRowKeys, setRowKeys] = useState([])
    const [tableData, setTabData] = useState([])
    const [listData, setListData] = useState([])
    const [Resource, setResource] = useState({})
    const columns = [
        { title: "指标名称", dataIndex: "metric_name", width: "33%" },
        { title: "OID", dataIndex: "oid", width: "33%" },
        { title: "描述", dataIndex: "description" },
        // { title: "自定义指标名称", dataIndex: "custom_metric_name" },
    ];
    useEffect(() => {//componentDidMount;
        const DEVICE_IP = sessionStorage.getItem("device_ip");
        getDeviceInfo(DEVICE_IP);
        queryDeviceInfo(DEVICE_IP);
        return () => { }
    }, []);
    function addModule() {
        const DEVICE_IP = sessionStorage.getItem("device_ip");
        if (!selectedRowKeys[0]) {
            message.error("请选择模块!");
            return;
        }
        http.post(device_snmp(), { device_ip: DEVICE_IP, module_names: selectedRowKeys }, (r) => {
            setVisible(false);
            getDeviceInfo(DEVICE_IP);
            message.success("添加成功!");
        })
    }
    function delModule(modules) {
        const DEVICE_IP = sessionStorage.getItem("device_ip");
        http.delete_body(device_snmp(), { device_ip: DEVICE_IP, module_names: modules }, (r) => {
            getDeviceInfo(DEVICE_IP);
            message.success("删除成功!");
        })
    }
    function getDeviceInfo(device_ip) {
        http.get(device(), { device_ip }, (r) => {
            setSelectItem(r.data.record);
            setListData(r.data.record.module_info);
        })
    }
    function queryDeviceInfo(device_ip) {
        Socket.ExecuteAction('QueryResourcesPage',
            { pageSize: 50, pageNum: 1, querys: { "resourceParams.deviceIp": device_ip } }, "", (result) => {
                const res = result.resources[0] || { resourceParams: {}, tags: [] };
                res.deviceSite = res.resourceParams.deviceSite;
                res.deviceOsVersion = res.resourceParams.deviceOsVersion;
                res.deviceId = res.resourceParams.deviceId;
                res.deviceVersion = res.resourceParams.deviceVersion;
                if (typeof res.tags === "string") {
                    res.tags = [res.tags];
                }
                setResource(res);
            });
    }
    return <div className="monitorDeviceDetail" id="monitorDeviceDetail">
        <CustomBreadcrumb arr={["监控告警", "数据采集", { title: "SNMP概览管理", to: "/monitorAlert/dataCollection/snmpPreview" }, "监控设备详情"]} />
        <Card className="desCard" >
            <Descriptions title="设备基本信息" bordered>
                <Descriptions.Item label="资源名称">{selectItem.device_name}</Descriptions.Item>
                <Descriptions.Item label="设备IP">{selectItem.device_ip}</Descriptions.Item>
                <Descriptions.Item label="厂商">{Utils.Translation(selectItem.business_name)}</Descriptions.Item>
                <Descriptions.Item label="类型">{Utils.Translation(selectItem.type)}</Descriptions.Item>
                <Descriptions.Item label="设备名">{Resource.deviceId}</Descriptions.Item>
                <Descriptions.Item label="设备型号">{Resource.deviceVersion}</Descriptions.Item>
                <Descriptions.Item label="区域">{Resource.deviceSite}</Descriptions.Item>
                <Descriptions.Item label="标签" span={2}>{Resource.tags && Resource.tags.map((t, i) => <Tag key={i} color="blue">{t}</Tag>)}</Descriptions.Item>
                <Descriptions.Item label="设备OS版本" span={3}>{Resource.deviceOsVersion}</Descriptions.Item>
                <Descriptions.Item label="描述" span={3}>{Resource.resourceDes}</Descriptions.Item>
            </Descriptions>
        </Card>
        <Card className="snmpCard" title="SNMP模块" extra={<Button type="primary" icon="file-add" onClick={() => { setVisible(true) }}>添加模块</Button>}>
            <strong>模块总数:{listData.length}</strong>
            <List
                itemLayout="horizontal"
                dataSource={listData}
                renderItem={item => (
                    <List.Item>
                        <div className="tabDiv">
                            <div className="title">
                                <div><Icon type="file-text" />&nbsp;{item.module_name}</div>
                                <div><Popconfirm
                                    title="你确定删除所选记录吗?"
                                    onConfirm={() => { delModule([item.module_name]) }}
                                    okText="是"
                                    cancelText="否"
                                >
                                    <Button ghost type="danger" icon="delete" size="small">删除模块</Button>
                                </Popconfirm>
                                </div>
                            </div>
                            <Table
                                dataSource={item.metric_infos}
                                columns={columns}
                                size="small"
                                pagination={false}
                            />
                        </div>
                    </List.Item>
                )}
            />
        </Card>
        <Affix offsetBottom={0}>
            <div className="bootmBtn">
                <Button type="primary" onClick={() => { props.history.go(-1) }} icon="save">保存</Button>
                        &nbsp;
                    <Button icon="close" onClick={() => { props.history.go(-1) }} >取消</Button>
            </div>
        </Affix>
        <Modal
            title="添加模块"
            visible={visible}
            onCancel={() => { setVisible(false) }}
            onOk={() => { addModule() }}
            width="80%"
            style={{ top: 20 }}
            destroyOnClose
        >
            <ModuleList onChange={(keys) => { setRowKeys(keys) }} />
        </Modal>
    </div>
}

export default connect()(DetailDevice)