import React from 'react'
import { Card, Table, Button, Icon, message, notification, Modal, Upload } from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import Utils from '@/utils/utils';
import http from '@/axios/axios_utils';
import api from '@/config/api/fireWall';
import * as XLSX from 'xlsx';
export default class FwCfg extends React.Component {
    state = {
        loading: false,
        tableData: []
    };
    componentWillMount() {
        this.updata();
    }
    updata = () => {
        this.setState({ loading: true, });
        http.get(api.getConfig, {}, (res) => {
            console.log(res);
            let tableData = res.data || [];
            this.setState({
                tableData,
                loading: false,
            })
        }, (res) => {
            console.log(res);
            notification.open({
                message: '查询失败',
                description: error,
                icon: <Icon type="frown" style={{ color: 'red' }} />,
            });
            this.setState({ loading: false, })
        });
    };
    exportExcel() {
        let list = [
            {
                "防火墙IP": "10.34.11.8",
                "对应网段": "122.21.13.0/24\r\nuntrust",
                "地址": "珠海",
                "用途": "云桌面",
            }
        ]
        Utils.exportSheetExcel(list, "Sheet1", "防火墙网段配置.xlsx");
    }
    openModal() {
        Modal.info({
            title: <div>导入文件<span> </span><Button onClick={() => this.exportExcel()}>下载模板</Button></div>,
            icon: "file-text",
            okText: "关闭",
            content: <Upload name="file" accept=".xls,.xlsx,.csv"
                beforeUpload={function () { return false; }}
                onChange={this.uploadFilesChange.bind(this)}
                showUploadList={false}
            >
                <div>
                    <div style={{ width: "300px", height: "100px", fontSize: 20, lineHeight: "100px", textAlign: "center", border: "1px dashed #807e7e", marginTop: 30 }}>
                        <Icon type="inbox" />点击导入
                    </div>
                </div>
            </Upload>
        })
    }
    uploadFilesChange = (file) => {
        console.log(file)
        let _this = this;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = {};
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {

                    let tempData = [];
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data[sheet] = tempData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    }
                }
                // 最终获取到并且格式化后的 json 数据
                _this.importDB(data)

            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                console.log(e);
                message.error('文件类型不正确！');
            }
        }
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(file.file);
    }
    importDB(data) {
        console.log(data)
        http.post(api.importConfig, data, (res) => {
            console.log(res);
            message.success('导入成功！');
            this.updata();
        }, (res) => {
            console.log(res);
        });
    }
    render() {
        let { tableData, loading } = this.state;

        const columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                render: (text, A, C) => { return <span>{C + 1}</span> }
            }, {
                title: '防火墙IP',
                align: 'left',
                dataIndex: 'ip'
            }, {
                title: '对应网段',
                align: 'left',
                dataIndex: 'network_segment',
                render: (text, A, C) => { return <span>{text + "  " + A.zone}</span> }
            }, {
                title: '地址',
                align: 'left',
                dataIndex: 'address',
            }, {
                title: '类型',
                align: 'left',
                dataIndex: 'network_segment_type',
            }, {
                title: '创建时间',
                align: 'left',
                dataIndex: 'create_time',
            },
        ];

        // address: "珠海"
        // create_time: "2021-08-10 15:30:54"
        // ip: "122.23.4.214"
        // network_segment: "122.21.13.0/24"
        // network_segment_type: "ipv4"
        // zone: "untrust_zh "
        return (
            <div>
                <CustomBreadcrumb arr={["防火墙管理", "网段配置"]} />
                <Card title={<span style={{ fontWeight: 600 }}>防火墙网段配置列表</span>}
                    extra={<div>
                        <Button type="primary" icon="import" onClick={() => {
                            this.openModal();
                        }}>上传开通防火墙配置</Button> </div>}
                    style={{ marginTop: 8 }}>
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        size="small"
                        loading={loading}
                        pagination={false}
                    />
                </Card>

            </div>
        )
    }
}
