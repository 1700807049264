import React, { useState, useEffect } from 'react'
import {
    Card, Table, Input, Button, Icon, message, notification, Modal, Menu, Dropdown, Steps, Form, Anchor, Divider,
    Row, Col, Select, Tabs, Badge, Popconfirm, Typography, Spin, Collapse, Tooltip, Tag, Upload, Descriptions
} from "antd";
import Socket from "@/socket/index";
import AxiosObj from '@/axios/index';
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import Utils from '@/utils/utils';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import { MonacoDiffEditor } from "react-monaco-editor"
import ResUtils from '@/utils/ResUtils';

import "./index.scss"
import AddTemp from "./addTemp"
import RecordTemp from "./tempRecord"
function TempMange(props) {
    const [selectNode, set_selectNode] = useState(null);
    const [Templates, set_Templates] = useState([]);
    const [visibleTemp, set_visibleTemp] = useState(false);
    const [recordvisible, set_recordvisible] = useState(false);
    const [comvisible, setComvisible] = useState(false);
    const [activiREC, set_ActiviREC] = useState(null);
    const [keys, setKeys] = useState([]);
    const [rows, setRows] = useState([]);
    useEffect(() => {//componentDidMount
        updataTemplates();
        return () => { //componentWillUnmount
        }
    }, []);

    const updataTemplates = () => {
        props.dispatch(isLoading(true));
        AxiosObj.HTTP_POST("acl", "aclTemplates", {}, (result) => {
            console.log(result)
            set_Templates(Utils.DataAddItem(result.data));
            props.dispatch(isLoading(false));
        });
    }

    const uploadFilesChange = (file) => {
        if (Utils.isEmpty(file.fileList)) { return; }
        let _this = this;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        // 以二进制方式打开文件
        fileReader.readAsText(file.file);
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                const param = {
                    template_name: file.file.name,
                    template_info: result
                }
                set_selectNode(param);
                set_visibleTemp(true);
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                console.log(e);
                message.error('文件类型不正确！');
            }
        }

    }
    const handleMenuClick = (e, item) => {
        switch (e.key) {
            case 'detail':
                set_visibleTemp(true);
                set_selectNode(item);
                break;
            case 'record':
                // let path = {
                //     pathname: `/configBackup/BackData`,
                //     bodyJson: {
                //         pageNum: _current1,      //当前页码数
                //     }
                // };
                // this.props.history.push(path);
                set_recordvisible(true);
                set_ActiviREC(item.template_name)
                break;
        }
    }
    const deleteTempRes = (item) => {
        AxiosObj.HTTP_POST("acl", "delAclTemplate", {
            "template_name": item.template_name,
            "insert_time": item.insert_time
        }, (result) => { message.success("删除成功!"); updataTemplates() });
    }
    return <div className="aclCommandDiv">
        <CustomBreadcrumb
            arr={["运维管理", "ACL管理", "ACL基线管理"]}
        />

        <Card title={<div >{Utils.BoldFont("设备ACL基线管理")}</div>} extra={<div>
            <Button type="primary" icon="select" onClick={() => { props.history.push("/command/aclSend") }}>ACL下发</Button>
            <Button type="primary" icon="import" onClick={() => {
                Modal.confirm({
                    title: "导入文件",
                    maskClosable: true,
                    className: "aclUploadDiv",
                    icon: <Icon type="file-text" style={{ color: "#1890ff" }} />,
                    content: <div><Upload.Dragger name="file"
                        beforeUpload={function () { return false; }}
                        onChange={uploadFilesChange.bind(this)}>
                        <p className="ant-upload-drag-icon"><Icon type="inbox" /></p>
                        <p className="ant-upload-text">单击或将文件拖到该区域上传</p>
                    </Upload.Dragger></div>,
                    onOk() { }
                })
            }}>导入ACL基线</Button>
            <Button type="primary" icon="edit" onClick={() => { set_visibleTemp(true) }}>新增ACL基线</Button>
        </div>}>

            <Table
                columns={[{
                    title: "序号",
                    align: "center",
                    render: (a, b, c) => { return c + 1 }
                }, {
                    title: "名称",
                    dataIndex: "template_name",
                }, {
                    title: "厂商",
                    dataIndex: "manufacturer",
                    // }, {
                    //     title: "区域",
                    //     dataIndex: "template_zone_id",
                    //     render(a, b, c) {
                    //         const ele = a.map((item) => {
                    //             return <Tag color="geekblue">{JSON.parse(item).name}</Tag>
                    //         })
                    //         return ele
                    //     }
                }, {
                    title: "操作用户",
                    dataIndex: "operator",
                }, {
                    title: "操作时间",
                    align: "center",
                    dataIndex: "insert_time",
                }, {
                    title: "操作",
                    align: "center",
                    dataIndex: "template_info",
                    render: (a, b, c) => {
                        return <div>
                            <Button type="primary" icon="form" onClick={() => {
                                handleMenuClick({ key: "detail" }, b);
                            }} size="small">修改</Button>&nbsp;
                            <Button type="primary" icon="file-text" onClick={() => {
                                handleMenuClick({ key: "record" }, b);
                            }} size="small">变更记录</Button>&nbsp;
                            <Button type="danger" icon="delete" onClick={() => {
                                Modal.confirm({
                                    title: "消息", content: "你确定删除" + b.template_name + "吗？",
                                    onOk() {
                                        deleteTempRes(b);
                                    }
                                })
                            }} size="small">删除</Button>
                        </div>
                        // <Dropdown key={c} overlay={() => {
                        //     return <Menu onClick={(e) => handleMenuClick(e, b)}>
                        //         <Menu.Item key="record">
                        //             <Icon type="file-text" />
                        //             变更记录
                        //         </Menu.Item>
                        //     </Menu>
                        // }} >
                        //     <Button type="primary" icon="form" onClick={() => {
                        //         handleMenuClick({ key: "detail" }, b);
                        //     }} size="small">修改<Icon type="down" /></Button>
                        //     {/* <Button type="danger" icon="delete" onClick={() => {
                        //         Modal.confirm({
                        //             title: "消息提示",
                        //             content: "你确定删除所选项吗?",
                        //             onOk() {
                        //                 deleteTempRes(b.template_name, b.insert_time);
                        //             }
                        //         })
                        //     }} size="small">删除</Button> */}
                        // </Dropdown>
                    }
                }]}
                dataSource={Templates}
                size="small"
            />
        </Card>
        <AddTemp
            visibleTemp={visibleTemp}
            selectNode={selectNode}
            onOk={() => { set_visibleTemp(false); updataTemplates(); }}
            onCancel={() => { set_visibleTemp(false); set_selectNode(null) }}
        />
        <Modal
            title="变更记录"
            width="90%"
            visible={recordvisible}
            onCancel={() => set_recordvisible(false)}
            onOk={() => set_recordvisible(false)}
            style={{ top: 10 }}
            destroyOnClose
        >
            <Button type="primary" size="small" icon="check-square" onClick={() => {
                if (rows.length != 2) {
                    message.error("请选择两条记录!")
                } else {
                    setComvisible(true);
                }
            }}>记录对比</Button>{Utils.getHR()}
            <RecordTemp tempName={activiREC} onChange={(selectedRowKeys, selectedRows) => {
                setRows(selectedRows);
                setKeys(selectedRowKeys);
            }} />
        </Modal>
        <Modal
            title="记录对比"
            width="95%"
            style={{ top: 10 }}
            visible={comvisible}
            onCancel={() => setComvisible(false)}
            onOk={() => setComvisible(false)}
            destroyOnClose
        >
            <Typography.Text className="pull-left"
                strong>{rows[0] && rows[0].template_name + "-" + rows[0].insert_time}</Typography.Text>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Typography.Text className="pull-right"
                strong>{rows[1] && rows[1].template_name + "-" + rows[1].insert_time}</Typography.Text>
            <MonacoDiffEditor
                language="java"
                theme="vs"
                original={rows[0] && rows[0].template_info}
                value={rows[1] && rows[1].template_info}
                height="700px"
                options={{ scrollBeyondLastColumn: 1, }}
            />
        </Modal>
    </div >
}
export default connect()(React.memo(TempMange))