/**
 * 系统前后端API配置
 */
const BASEURL = "http://" + deliverytask_address + "/ZJNMS_V1/configuration/";
const APIConfig = {
    MODENAME: "deliveryTask",//模块名称
    BASEURL,
    //URL:配置 
    cmdItem: BASEURL + "cmdItem",
    showCmdItemByClass: BASEURL + "showCmdItemByClass",
    deliveryTaskByPage: BASEURL + "deliveryTaskByPage",
    deliveryTaskById: BASEURL + "deliveryTaskById",
    commitDeliveryTask: BASEURL + "commitDeliveryTask",
    conditionQueryDeliveryTask: BASEURL + "conditionQueryDeliveryTask",
    saveDeliveryTask: BASEURL + "saveDeliveryTask",
    deliveryTaskApprovalByPage: BASEURL + "deliveryTaskApprovalByPage",
    deliveryTaskApproval: BASEURL + "deliveryTaskApproval",
    deliveryDataStatistics: BASEURL + "deliveryDataStatistics",
    delDeliveryTask: BASEURL + "delDeliveryTask",
    executeDeliveryTask: BASEURL + "executeDeliveryTask",
    conditionQueryExecuteRecord: BASEURL + "conditionQueryExecuteRecord",
    operationRecordsByPage: BASEURL + "operationRecordsByPage",
    executeRecordsByPage: BASEURL + "executeRecordsByPage",
    approvalRecordsByPage: BASEURL + "approvalRecordsByPage",
    approvalRecordByTaskId: BASEURL + "approvalRecordByTaskId",
    conditionQueryOperationRecord: BASEURL + "conditionQueryOperationRecord",
    taskProgress: BASEURL + "taskProgress",
    taskShell: BASEURL + "taskShell",
    websocketUrlBase: "/ZJNMS_V1/socket/sshSocket",
    exportExcel: BASEURL + "exportExcel?_id=",
    websocketUrl: "http://" + deliverytask_address.split(":")[0] + "/ZJNMS_V1/socket/sshSocket",
};
export default APIConfig;