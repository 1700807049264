/**
 * Created by YIMINE on 2019/1/24.
 */
import React, { Fragment } from 'react'
import MonacoEdit from 'react-monaco-editor'
import { Typography, Input, Table, Modal, Select, message, Button, Drawer, Tag, Tabs, Collapse } from 'antd';
import $ from 'jquery'
// import './index.scss'
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import TableShow from "@/components/TableShow/index";
import ScriptModal from "@/components/ScriptModal/index";
import SelectForm from "@/components/SelectForm/index";
import OpScript from "./opScript";
import GroupElemt from "@/components/ScriptModal/groupElemt";
import FilterForm from "@/components/FilterForm/index";
const { Title } = Typography;
const Search = Input.Search;
const Option = Select.Option;
let _focus_node = null;
let _flow_data = null;//流程图数据
let _nodeOper_data = null;//节点数据
const operPageSize = 20;

class FlowChar extends React.Component {
    state = {
        current: 0,                 //步骤条计数
        deviceCurrent: 0,
        operCurrent: 0,
        tabloading: false,       //表格加载
        visible_param: false,       //绑定参数模态框
        visible_drawer: false,       // 抽屉
        visible_device: false,      //选择设备模态框
        visible_import: false,      //导入流程图数据模态框
        visible_exit: false,      //选择出口
        pageFlag: false,      //false为不分页，true为分页
        lineId: '',      //lineId
        select_exit: '',      //默认出口
        exitData: [],     //出口数据
        Json_Area: "",      //流程图模态框数据
        slt_device: [],
        slt_oper: { _id: '', operationIn: { select: [] } },  //选中的操作对象
        OperatingType: [],
        deviceData: [],
        selectedRowKeys: [],
        selectedOperKeys: [],
        reportData: [],
        rulesArr: [{ key: new Date().getTime(), param: '', rule: '', value: '' }],
        OperatingTotal: 0,
        deviceTotal: 0,
        scriptSel: {},
        groupData: {},
        Tags: [],
        Groups: [],
        devicequerys: null,
        propsFlowData: null,
    };
    componentDidMount() {
        // console.log(this.props);
        let tempThis = this;
        tempThis.props.onRef(this);//将this传给父组件
        window.addEventListener('message', function (event) {//父获取子传递的消息 
            if (event.data.type == 'Focus') {
                // document.getElementById('node_name').value = event.data.data.name
                _focus_node = event.data.data;
                let oper = _focus_node.hasOwnProperty("taskdata") ? _focus_node.taskdata.operation : '';
                let scriptSel = _focus_node.hasOwnProperty("taskdata") ? _focus_node.taskdata.input.script : {};
                let resource = _focus_node.hasOwnProperty("taskdata") ?
                    (_focus_node.taskdata.input.hasOwnProperty("resource") ? _focus_node.taskdata.input.resource : []) : [];
                let selectedRowKeys = [];
                resource.map((item) => { selectedRowKeys.push(item.key) });
                // let rulesArr = [{ key: new Date().getTime(), param: '', rule: '', value: '' }];
                // let reportData = [];
                // if (oper.operationIn) {
                //     oper.operationIn.script.map((scitem, kry) => {
                //         if (scitem.type === "group") {
                //             rulesArr = _focus_node.taskdata.input.script[scitem.name]
                //         } else if (scitem.type === "upload") {
                //             reportData = _focus_node.taskdata.input.script[scitem.name]
                //         } else { }
                //     })
                // }
                tempThis.setState({
                    slt_oper: oper, slt_device: resource,
                    selectedRowKeys, scriptSel,
                });
            } else if (event.data.type == 'export') {
                // console.log(event.data.data);
                _flow_data = event.data.data;
                tempThis.props.onFlowDataChange(_flow_data);

                tempThis.setState({ Json_Area: JSON.stringify(_flow_data, null, 8) });
            } else if (event.data.type == 'click') {
                let current = 0;
                if (tempThis.state.slt_oper) { current = 1 }
                tempThis.updataOperating(operPageSize, 1, {});
                // tempThis.updataResources(10000, 1, {});
                tempThis.setState({ visible_param: true, current });
            } else if (event.data.type == 'exit') {
                // console.log(event.data.data);
                tempThis.setState({
                    exitData: event.data.data,
                    lineId: event.data.lineId,
                    visible_exit: true
                });
            }
        }, false);

        var bodyJson = new Object();
        bodyJson.pageSize = 10;
        bodyJson.pageNum = 1;
        bodyJson.querys = {};
        this.fileListener();
        this.getTagGroupName();
    }
    componentWillReceiveProps(props) {
        const initValue = props.initValue;
        const _this = this;
        const iframe = document.getElementById("child");
        const { propsFlowData } = this.state;
        if (initValue && (!Object.is(initValue, propsFlowData))) {
            //导入流程图数据
            const data = { type: 'import', data: initValue };
            _this.sendToIframe(data);
            iframe.onload = function () {
                //导入流程图数据
                const data = { type: 'import', data: initValue };
                _this.sendToIframe(data);
            }
            this.setState({ propsFlowData: initValue })
        }


    }
    sendToIframe = (data) => {
        document.getElementById('child')
            .contentWindow.postMessage(data, document.location.origin + "/flow/demo/demo_ide.html")//父向子传递
    };
    getTagGroupName() {
        const thisTemp = this;
        Socket.ExecuteAction('QueryTagsGroups', { tagClass: 'resource' }, "/QueryTagsGroups", (result) => {
            thisTemp.setState({
                Tags: result.tags,
                Groups: result.groupNames,
            });
        });
    }
    updataOperating = (pageSize, pageNum, querys) => {
        let bodyJson = {};
        bodyJson.pageSize = pageSize || 10;
        bodyJson.pageNum = pageNum || 1;
        bodyJson.querys = querys || {};
        this.setState({ tabloading: true })
        Socket.QueryDataByPage('QueryOperationsPage',
            bodyJson, (result) => {
                if (result.retcode === '00') {
                    let OperatingType = result.operations;
                    OperatingType.map((item, index) => {
                        item.key = index;
                    });
                    this.setState({
                        OperatingType, tabloading: false,
                        OperatingTotal: result.total,
                        operCurrent: pageNum || 1,
                    })
                }
            }, "/queryOperation");
    };
    updataResources = (pageSize, pageNum, querys) => {
        let bodyJson = {};
        bodyJson.pageSize = pageSize || 10;
        bodyJson.pageNum = pageNum || 1;
        bodyJson.querys = querys || {};
        this.setState({ tabloading: true });
        Socket.QueryDataByPage("QueryResourcesPage", bodyJson, (result) => {
            if (result.retcode === '00') {
                let Resources = result.resources;
                Resources.map((item, index) => {
                    item.key = index;
                    item.index = index;
                });
                this.setState({
                    deviceData: Resources,
                    deviceTotal: result.total,
                    deviceCurrent: pageNum,
                    tabloading: false,
                })
            }
        }, "/queryResource");
    }
    fileListener = () => {
        const _this = this;
        $('#file_input').unbind();
        $('#file_input').change(function () {
            let file = this.files[0];
            let _fileData = null;
            if (!file) { return; }
            // $(this).val("");	//清空文件控件的内容
            let _fileName = file.name;
            $('#uploadBtn').text(file.name + " 读取中");
            //开始读取文件数据
            let reader = new FileReader();
            //读取UTF-8文件
            reader.readAsText(file, "UTF-8");
            //文件读取完毕后该函数响应
            reader.onload = function () {
                //读取完成  
                _fileData = this.result;
                try {
                    _fileData = JSON.parse(_fileData);
                } catch (e) {
                    message.error('错误', e);
                    return;
                }
                _this.sendToIframe({ type: "import", data: _fileData });
                _flow_data = _fileData;
                message.success(_fileName + "导入成功！")
            }
        });
    }
    getPreNode = (nowId) => {//获取上个节点的数据信息
        if (!_flow_data) return;
        let allLine = _flow_data.lines;
        let preId = [];
        for (let j in allLine) {
            if (allLine[j].to === nowId) {
                preId.push(allLine[j].from);
            }
        }
        let preNode = [];
        let allDode = _flow_data.nodes;
        for (let i in allDode) {
            if (preId.indexOf(i) > -1) {
                preNode.push(allDode[i]);
            }
        }
        return preNode;
    };
    initModal = () => {
        // console.log('initModal');
        this.setState({
            current: 0,                 //步骤条计数
            deviceCurrent: 1,
            operCurrent: 1,
            visible_param: false,       //绑定参数模态框
            visible_device: false,      //选择设备模态框
            slt_device: [],
            slt_oper: { _id: '' },        //选中的操作对象
            selectedRowKeys: [],        //清空
            selectedOperKeys: [],        //清空
        });
    };
    paramRender = () => {
        const { slt_oper } = this.state;
        const _InScript = slt_oper.hasOwnProperty("operationIn") ?
            (slt_oper.operationIn.hasOwnProperty("script") ? slt_oper.operationIn.script : []) : [];
        const _OutScript = slt_oper.hasOwnProperty("operationOut") ?
            (slt_oper.operationOut.hasOwnProperty("script") ? slt_oper.operationOut.script : []) : [];
        const ExitData = slt_oper.hasOwnProperty("operationExit") ? slt_oper.operationExit : [];
        const initScript = _focus_node ? (_focus_node.hasOwnProperty("taskdata") ? _focus_node.taskdata.input.script : {}) : {};

        return <div className="scriptCont">
            {/* <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="表单" key="1"> */}
            <ScriptModal
                isOut={true}
                isLabel={false}
                InScript={_InScript}
                operationExit={ExitData}
                OutScript={_OutScript}
                initScript={initScript}
                getThis={(_this) => {
                    this.child = _this;
                    // this.setState({ scriptSel: _this.state.scriptSel }) 
                }}
            />
            {/* </Tabs.TabPane>
                <Tabs.TabPane tab="脚本" key="2" style={{ textAlign: "left" }}>
                    <MonacoEdit
                        height={500}
                        theme="vs-dark"
                        options={{ selectOnLineNumbers: true }}
                        language="json"
                        value={JSON.stringify(initScript, null, 5)}
                        onChange={(value) => {
                            // this.setState({ Json_Area: value });
                        }} />
                </Tabs.TabPane>
            </Tabs> */}

        </div>;
    }
    finishModal = () => {
        const { slt_oper, slt_device, scriptSel, } = this.state;
        let _InScript = slt_oper.operationIn.hasOwnProperty("script") ? slt_oper.operationIn.script : [];
        let _OutScript = slt_oper.operationOut.hasOwnProperty("script") ? slt_oper.operationOut.script : [];

        // console.log(scriptSel);
        // console.log(this.child.state.scriptSel);
        const scriptValue = this.child.state.scriptSel;
        _nodeOper_data = {
            operation: slt_oper,
            input: {
                script: scriptValue
            },
            output: _OutScript
        };//初始化节点数据
        // _InScript.map((text, key) => {
        //     if (text.type === 'upload') {
        //         _nodeOper_data['input']['script'][text.name + ''] = reportData;
        //     } else {
        //         // _nodeOper_data['input']['script'] = scriptSel; 
        //         _nodeOper_data['input']['script'] = scriptValue;
        //     }
        // });
        //判断是否需要选择设备
        if (slt_oper.operationIn.select && (slt_oper.operationIn.select).includes('resource')) {
            _nodeOper_data.input.resource = slt_device;
        }
        const data = {
            type: 'setName',
            node: _focus_node,
            newName: slt_oper.operationName
        };
        this.sendToIframe(data);//设置节点名称
        console.log(_nodeOper_data);
        const data2 = {
            type: 'setNodeAttr',
            node: _focus_node,
            key: 'taskdata',
            value: _nodeOper_data
        };
        this.sendToIframe(data2);//设置节点数据属性
        //导出流程图数据传值给表单
        let data3 = { type: 'export' };
        this.sendToIframe(data3);
        // console.log(data2);
        this.initModal();
        message.success('完成!');
    }
    render() {
        const { deviceCurrent, operCurrent, current, slt_oper, slt_device, devicequerys,
            selectedRowKeys, OperatingType, exitData, select_exit, Tags, Groups, tabloading } = this.state;
        const DraWidth = document.body.clientWidth * 0.43;
        const rowDevice = {
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                let slt_device = [];
                slt_device = selectedRows;
                this.state.selectedRowKeys = selectedRowKeys;
                this.setState({ slt_device, selectedRowKeys: this.state.selectedRowKeys });
            },
            selectedRowKeys: this.state.selectedRowKeys,
        };
        const rowbindingOper = {
            type: 'radio',
            onSelect: (record, selected, selectedRows) => {
                console.log(record, selected, selectedRows);
                // const current = this.state.current + 1;//设置步骤计数 
                this.setState({ slt_oper: record, });
            },
            // selectedRowKeys:this.state.selectedOperKeys
            getCheckboxProps(record) {
                return {
                    checked: record._id === slt_oper._id,  // 配置勾选的列
                };
            },
        };
        const columnsDevice = [
            {
                title: '序号',
                width: "50px",
                align: 'center',
                render: (text, item, index) => {
                    return index + 1;
                }
            },
            {
                title: '名称',
                align: 'left',
                dataIndex: 'resourceName'
            }, {
                title: '厂商',
                width: "100px",
                align: 'center',
                dataIndex: 'resourceParams.deviceFirm',
            }, {
                title: '型号',
                width: "100px",
                align: 'center',
                dataIndex: 'resourceParams.deviceVersion',
            }, {
                title: '标签',
                align: 'left',
                dataIndex: 'tags',
                width: "200px",
                render: (text) => {
                    return Utils.hidenTag(text);
                }
            },
            // {
            //     title: '分组',
            //     align: "left",
            //     width: "200px",
            //     dataIndex: 'groupNames',
            //     render: (text) => {
            //         return Utils.hidenTag(text);
            //     }
            // },
        ];
        const selDevice = [
            {
                title: '序号',
                width: "50px",
                align: 'center',
                render: (text, item, index) => {
                    return index + 1;
                }
            },
            {
                title: '名称',
                align: 'center',
                dataIndex: 'resourceName'
            }, {
                title: '厂商',
                width: "100px",
                align: 'left',
                dataIndex: 'resourceParams.deviceFirm',
            }, {
                title: '型号',
                width: "100px",
                align: 'center',
                dataIndex: 'resourceParams.deviceVersion',
            }, {
                title: '标签',
                align: 'left',
                width: "400px",
                dataIndex: 'tags',
                render: (text, item, index) => {
                    return Utils.hidenTag(text);
                }
            },
            {
                title: '操作',
                align: 'center',
                width: "80px",
                render: (text, item, index) => {
                    return <Button type="danger" icon="delete"
                        onClick={() => {
                            const dataSource = [...this.state.slt_device];
                            const selected = [...this.state.selectedRowKeys];
                            this.setState({
                                slt_device: dataSource.filter(item1 => item1._id !== item._id),
                                selectedRowKeys: selected.filter(item1 => item1 !== item._id)
                            });
                        }}
                    ></Button>
                }
            }
        ];
        const colOperatingType = [
            { title: '名称', align: 'left', dataIndex: 'operationName', },
            { title: '类型', align: 'center', dataIndex: 'operationType', },
            { title: '描述', align: 'left', dataIndex: 'operationDes', },
            {
                title: '标签',
                align: 'left',
                dataIndex: 'tags',
                width: "200px",
                render: (text) => {
                    return Utils.hidenTag(text);
                }
            }
        ];
        const condition = [
            { key: 'operationName', value: '操作名称' },
            { key: 'operationType', value: '操作类型' },
            { key: 'tags', value: '标签' },
            // { key: 'groupNames', value: '分组' }
        ];
        const conditionData = {
            operationType: {
                mode: 'alone', data: [
                    { key: 'cli', value: 'cli' }, { key: 'task', value: 'task' }
                    , { key: 'linuxShell', value: 'linuxShell' },
                    { key: 'python', value: 'python' }, { key: 'perl', value: 'perl' }
                    , { key: 'nodejs', value: 'nodejs' }, { key: 'system', value: 'system' }]
            },
            operationName: "INPUT",
            tags: { mode: "tags", data: [{ key: '19楼', value: '19楼' }] },
            // groupNames: { mode: "tags", data: [{ key: '19楼', value: '19楼' }] },
        };
        const _InScript = slt_oper.hasOwnProperty("operationIn") ?
            (slt_oper.operationIn.hasOwnProperty("script") ? slt_oper.operationIn.script : []) : [];
        const _OutScript = slt_oper.hasOwnProperty("operationOut") ?
            (slt_oper.operationOut.hasOwnProperty("script") ? slt_oper.operationOut.script : []) : [];
        const ExitData = slt_oper.hasOwnProperty("operationExit") ? slt_oper.operationExit : [];
        const initScript = _focus_node ? (_focus_node.hasOwnProperty("taskdata") ? _focus_node.taskdata.input.script : {}) : {};
        return (
            <Fragment >
                <div style={{ textAlign: "center" }}>
                    <Button type="primary" icon="file-protect" style={{ marginLeft: 5 }}
                        onClick={() => {
                            this.setState({ visible_import: true, });
                        }}
                    >流程图Json</Button>

                    <Button type="primary" icon="import" style={{ marginLeft: 5 }} onClick={() => {
                        $("#file_input").click();
                    }}>导入文件</Button>
                    <input id="file_input" type="file" style={{ display: "none" }} />
                    <Button type="primary" icon="export" style={{ marginLeft: 5 }} onClick={() => {
                        this.sendToIframe({ type: 'export' });
                        setTimeout(() => {
                            Utils.download("FLOW_DATA" + new Date().getTime() + ".json", this.state.Json_Area);
                        }, 10);
                    }}>导出文件</Button>

                    <Button type="primary" icon="fullscreen" style={{ marginLeft: 5 }}
                        onClick={() => {
                            $('#iframeDiv').addClass('iframeDiv')
                            $('#child').addClass('iframeChild')
                            $('#iframeDiv .smallBtn').css('display', 'block');
                            let data = { type: 'zoom' };
                            this.sendToIframe(data);
                        }}
                    >放大</Button>

                    <Button type="primary" icon="info-circle" className="drawerBtn"
                        onClick={() => {
                            this.setState({ visible_drawer: true });
                            document.getElementById("icon_top") && document.getElementById("icon_top").click();
                        }}
                    />
                </div>
                <div id="iframeDiv"
                    tabIndex="-1"
                >
                    <Button type="primary"
                        className="smallBtn"
                        icon="fullscreen-exit"
                        onClick={() => {
                            $('#iframeDiv').removeClass('iframeDiv');
                            $('#child').removeClass('iframeChild');
                            $('#iframeDiv .smallBtn').css('display', 'none');
                        }}
                    >缩小</Button>

                    {/* <Collapse defaultActiveKey="1" className="Collapse">
                        <Collapse.Panel header="任务编排" key="1"> */}
                    <iframe name="myFrame" id="child" tabIndex="-1"
                        src="/flow/demo/demo_ide.html" frameBorder="0" scrolling="auto"
                        className="initChild test-1" ></iframe>
                    {/* </Collapse.Panel>
                    </Collapse> */}

                </div>
                <Drawer
                    width={DraWidth}
                    title="节点信息"
                    placement="right"
                    mask={false}
                    onClose={() => {
                        this.setState({ visible_drawer: false, });
                        document.getElementById("icon_top") && document.getElementById("icon_top").click();
                    }}
                    visible={this.state.visible_drawer}
                >
                    <div className="DrawerDiv">
                        <OpScript
                            operation={slt_oper}
                            onChangeOper={(operation) => {
                                console.log(_focus_node)
                                console.log(operation);
                                _nodeOper_data = {
                                    operation: operation,
                                    input: {
                                        script: {}
                                    },
                                    output: []
                                };//初始化节点数据
                                const data = {
                                    type: 'setName',
                                    node: _focus_node,
                                    newName: operation.operationName
                                };
                                this.sendToIframe(data);//设置节点名称
                                const data2 = {
                                    type: 'setNodeAttr',
                                    node: _focus_node,
                                    key: 'taskdata',
                                    value: _nodeOper_data
                                };
                                this.sendToIframe(data2);//设置节点数据属性
                            }}
                            checkOper={() => {
                                this.updataOperating(operPageSize, 1, {});
                                this.setState({ visible_param: true })
                            }}
                            onClose={() => { this.setState({ visible_drawer: false, }); document.getElementById("icon_top") && document.getElementById("icon_top").click(); }}
                        />
                    </div>
                </Drawer>
                <Modal
                    title="操作选择"
                    visible={this.state.visible_param}
                    onCancel={(e) => { this.initModal() }}
                    afterClose={() => { this.setState({ pageFlag: false, operQuery: {}, }); }}
                    footer={null}
                    // width='98%'
                    width="70%"
                    style={{ top: 20 }}
                    destroyOnClose
                >
                    <div>
                        <div className="steps-content">
                            {
                                current == 0 ?
                                    <div>
                                        <Title level={3}>选择操作</Title>
                                        <SelectForm
                                            condition={condition}
                                            conditionData={conditionData}
                                            tagClass="operation"
                                            filterSubmit={(querys) => {
                                                this.updataOperating(10000, 1, querys);
                                                this.setState({ pageFlag: true, operQuery: querys });
                                            }}
                                            resetTab={() => {
                                                this.updataOperating(operPageSize, 1, {})
                                                this.setState({ pageFlag: false, operQuery: {}, slt_oper: {} });
                                            }}
                                        />
                                        <hr style={{ border: '1px solid #e2e2e2' }} />
                                        <Table
                                            rowSelection={rowbindingOper}
                                            columns={colOperatingType}
                                            loading={tabloading}
                                            dataSource={OperatingType}
                                            size='small'
                                            bordered={false}
                                            scroll={{ y: 500 }}
                                            pagination={this.state.pageFlag ? false : {
                                                current: operCurrent, pageSize: operPageSize, total: this.state.OperatingTotal,
                                                onChange: (page, pageSize) => {
                                                    //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                                    this.updataOperating(pageSize, page, this.state.operQuery | {});
                                                }
                                            }}

                                        /><hr style={{ border: '1px solid #e2e2e2' }} />
                                        <Button type="primary" style={{ marginLeft: 8 }} onClick={() => {
                                            console.log(slt_oper);
                                            if (!this.state.slt_oper || Utils.isEmptyObject(slt_oper)) { message.info('请选择操作！'); return; }
                                            const current = this.state.current + 1;//设置步骤计数
                                            this.setState({ current });
                                        }}
                                        >下一步</Button>
                                    </div>
                                    // : ((current == 1 && slt_oper && slt_oper.operationIn.select && (slt_oper.operationIn.select.includes('resource'))) ?
                                    //     <div>
                                    //         <Title level={3}>选择设备</Title>
                                    //         <div style={{ fontWeight: 600, color: 'black', paddingLeft: 12 }}>已选设备</div>
                                    //         <Table
                                    //             columns={selDevice}
                                    //             dataSource={this.state.slt_device}
                                    //             size='small'
                                    //             bordered={false}
                                    //             scroll={{ y: 540 }}
                                    //             pagination={false}
                                    //         /><hr style={{ border: '1px solid #e2e2e2' }} />
                                    //         <Button type="primary" onClick={() => {
                                    //             this.setState({ visible_device: true })
                                    //         }}>添加设备</Button>

                                    //         <Button style={{ marginLeft: 8 }} onClick={() => {
                                    //             const current = this.state.current - 1;
                                    //             this.setState({ current });
                                    //         }}>上一步</Button>
                                    //         <Button type="primary" style={{ marginLeft: 8 }} onClick={() => {
                                    //             const current = this.state.current + 1;//设置步骤计数
                                    //             this.setState({ current });
                                    //         }}>下一步</Button>
                                    //     </div>
                                    : <div className="paramDiv">
                                        <Title level={3} >编辑参数</Title>
                                        {this.paramRender()}
                                        <hr style={{ border: '1px solid #e2e2e2' }} />
                                        <Button onClick={() => {
                                            const current = this.state.current - 1;
                                            this.setState({ current });
                                        }}>上一步</Button>
                                        <Button type="primary" style={{ marginLeft: 8 }}
                                            onClick={() => {
                                                this.finishModal();
                                            }}>完成</Button>
                                    </div>
                            }
                        </div>
                    </div>
                </Modal>
                <Modal
                    title="选择设备"
                    visible={this.state.visible_device}
                    onOk={(e) => {
                        this.setState({ visible_device: false, });
                    }}
                    onCancel={(e) => { this.setState({ visible_device: false, }); }}
                    okText="确认"
                    cancelText="取消"
                    style={{ top: 50 }}
                    width={1100}
                    destroyOnClose
                >
                    <FilterForm
                        tagClass="resource"
                        filterSubmit={(devicequerys) => {
                            this.setState({ devicequerys })
                            this.updataResources(10000, 1, devicequerys)
                        }}
                        resetTab={() => {
                            this.setState({ devicequerys: null })
                            this.updataResources(10000, 1, {})
                        }}
                    />
                    <hr />
                    <Table
                        rowSelection={rowDevice}
                        // rowKey={record => { record._id }}
                        columns={columnsDevice}
                        loading={tabloading}
                        dataSource={this.state.deviceData}
                        size='small'
                        bordered={false}
                        scroll={{ y: 440 }}
                        pagination={false}
                    // pagination={{
                    //     current: deviceCurrent, pageSize: 10, total: this.state.deviceTotal,
                    //     onChange: (page, pageSize) => {
                    //         //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                    //         this.updataResources(pageSize, page, devicequerys);
                    //     }
                    // }}
                    />
                </Modal>
                <Modal
                    title={<div>流程图JSON
                        <Button type="primary" style={{ marginLeft: 8 }} onClick={() => {
                            let Json_Area = {};
                            try {
                                Json_Area = JSON.parse(this.state.Json_Area);
                            } catch (e) {
                                message.error('错误', e);
                            }

                            let data = {
                                type: 'import',
                                data: Json_Area
                            };
                            this.sendToIframe(data);
                            this.setState({ visible_import: false, });
                        }}>导入</Button>
                        <Button type="primary" style={{ marginLeft: 8 }} onClick={() => {
                            //导出流程图数据传值给表单
                            let data3 = { type: 'export' };
                            this.sendToIframe(data3);
                        }}>导出</Button>
                        <Button type="primary" style={{ marginLeft: 8 }} onClick={() => {
                            this.setState({ Json_Area: '' })
                        }}>清空</Button>
                    </div>}
                    visible={this.state.visible_import}
                    onCancel={(e) => { this.setState({ visible_import: false, }); }}
                    footer={null}
                    width="95%"
                    style={{ position: 'fixed', top: 25, left: 35, }}
                >
                    <MonacoEdit
                        height={500}
                        theme="vs-dark"
                        options={{ selectOnLineNumbers: true }}
                        language="json"
                        value={this.state.Json_Area}
                        onChange={(value) => {
                            this.setState({ Json_Area: value });
                        }} />
                </Modal>

                <Modal
                    title="选择出口"
                    visible={this.state.visible_exit}
                    onOk={(e) => {
                        let data = {
                            type: 'setName',
                            node: {
                                type: 'line',
                                id: this.state.lineId
                            },
                            newName: select_exit
                        };
                        this.sendToIframe(data);
                        this.setState({ visible_exit: false, });
                    }}
                    onCancel={(e) => { this.setState({ visible_exit: false, }); }}
                    okText="确认"
                    cancelText="取消"
                >
                    <Select
                        onChange={(value) => {
                            this.setState({ select_exit: value, });
                        }}
                        value={select_exit}
                        style={{ width: 100 }}
                    >
                        {exitData.map(owner => (
                            <Option key={owner} value={owner}>
                                {owner}
                            </Option>
                        ))}
                    </Select>
                </Modal>
            </Fragment>
        );
    }
}

export default FlowChar;