import React from 'react'
import { Input, Select, Form, Button, Checkbox, Radio, Divider, DatePicker, message, Icon, Modal, Popconfirm } from 'antd'
import Utils from '@/utils/utils';
import Socket from '@/socket/index';
import RenderSelect from './renderSelect';
const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {
        span: 4
    },
    wrapperCol: {
        span: 18
    },
};
class BaseForm extends React.Component {
    state = {
        vis_modal: false,
        title_modal: "",
        srcAdress: null,
        srcType: "",
    }

    handleFilterSubmit = () => {
        let fieldsValue = this.props.form.getFieldsValue();
        let _fieldsValue = {};
        for (let i in fieldsValue) {
            if (!Utils.isEmpty(fieldsValue[i]) && fieldsValue[i]) {

                if (i.split("-").length == 2) {
                    let str = i.replace(/-/g, ".");
                    _fieldsValue[str] = fieldsValue[i];
                } else {
                    _fieldsValue[i] = fieldsValue[i];
                }
            }
        }
        // _fieldsValue["resourceParams.deviceFirm"] = "cisco"
        this.props.filterSubmit(_fieldsValue);
    };

    reset = () => {
        this.props.form.resetFields();
        // this.props.resetTab();
    };
    openModal(message, srcType) {
        this.setState({ title_modal: message, vis_modal: true, srcType })
    }

    initFormList = () => {
        const { getFieldDecorator } = this.props.form;
        const formList = this.props.formList;
        const formItemList = [];
        const _this = this;
        if (formList && formList.length > 0) {
            formList.forEach((item, i) => {
                let label = item.label;
                let field = item.field;
                let initialValue = item.initialValue || '';
                let placeholder = item.placeholder;
                let width = item.width;
                // console.log(item);
                if (item.type == 'INPUT') {
                    const INPUT = <FormItem label={label} key={field} {...formItemLayout}>
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue
                            })(
                                <Input type="text" placeholder={placeholder} />
                            )
                        }
                    </FormItem>;
                    formItemList.push(INPUT)
                } else if (item.type == 'SELECT') {
                    const SELECT = <FormItem label={label} key={field} {...formItemLayout}>
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue
                            })(
                                // <RenderSelect
                                //     mode={item.isTag ? "tags" : ""}
                                //     field={field}
                                //     SelectList={item.list}
                                //     propsThis={_this}
                                // />
                                <Select
                                    mode={item.isTag ? "tags" : ""}
                                >
                                    {Utils.getOptionList(item.list)}
                                </Select>
                            )
                        }
                    </FormItem>;
                    formItemList.push(SELECT)
                }
            })
        }
        return formItemList;
    }
    ActionSrc() {
        const { srcType, title_modal, srcAdress } = this.state;
        if (!srcAdress) { message.error("请输入范围！"); return };
        let APIName = "";
        let tagClass = "";
        if (title_modal === "新增" && srcType === "srcIP") {
            APIName = "AddFrom"; tagClass = "from";
        } else if (title_modal === "新增" && srcType === "desIP") {
            APIName = "Addto"; tagClass = "to";
        } else if (title_modal === "删除" && srcType === "srcIP") {
            APIName = "DelFrom"; tagClass = "from";
        } else if (title_modal === "删除" && srcType === "desIP") {
            APIName = "Delto"; tagClass = "to";
        }
        Socket.ExecuteAction(APIName,
            { tagClass, tagName: srcAdress }, "/QueryTaskResult", (result) => {
                message.success(title_modal + "成功");
                this.setState({ vis_modal: false, });
            }, () => {
                notification.error({
                    message: title_modal + '失败',
                    description: result.error,
                });
            });
    }
    render() {
        let { vis_modal, srcAdress, title_modal } = this.state;
        return (
            <div>
                <Form >
                    {this.initFormList()}
                    {/* <div style={{ float: "right", paddingTop: 5 }}> */}
                    <FormItem label=" " colon={false} {...formItemLayout}>
                        <div><Button type="primary" style={{ margin: '0 20px' }}
                            icon="check-circle"
                            onClick={this.handleFilterSubmit}>确定</Button>
                            <Button onClick={() => this.props.cancel()} icon="close-circle" type="danger">取消</Button></div>
                    </FormItem>

                    {/* </div> */}
                </Form>
                <Modal
                    visible={vis_modal}
                    title={title_modal}
                    onCancel={(e) => { this.setState({ vis_modal: false, }); }}
                    onOk={(e) => { this.ActionSrc(); }}
                    afterClose={() => {
                        this.props.editThis.getFromList();
                        this.props.editThis.getToList();
                        this.setState({ srcAdress: null })
                    }}
                    destroyOnClose
                >
                    <div>{title_modal === "新增" ?
                        <div><Icon type="plus-circle" theme="twoTone" twoToneColor="#52c41a" />-输入新增的地址范围:</div>
                        : <div><Icon type="close-circle" style={{ color: "red" }} />-输入删除的地址范围: </div>}
                    </div>
                    <Input value={srcAdress} onChange={(e) => { this.setState({ srcAdress: e.target.value }) }} />
                </Modal>
            </div>

        );
    }
}
export default Form.create({})(BaseForm);