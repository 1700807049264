import React from 'react'
import { Button, Card, Col, Form, Icon, Input, message, Modal, Row, Spin, Table, Tag, Upload, Descriptions } from "antd";

import axios from '@/axios/axios_utils';
import Utils from "@/utils/utils";

import { SubmitButton, FormItem, TreeSelect, Cascader } from "formik-antd";
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik";
import moment from 'moment';
const topologyUrl = 'http://' + backup_address + '/ZJNMS_V1/topology/';


class ComparativeData extends React.Component {

    state = {
        deviceInfoVisible: false,
        diffInfoData: {},
        resourceData: {},
    }

    componentWillMount() {

    }
    updateResourceData(item){
        let { diffInfoData, resourceData } = this.props;
        let bodyJson = {
            topo_rs_id: diffInfoData._id || "",
            firm: diffInfoData.firm || "",
            type: diffInfoData.type || "",
            model: diffInfoData.model || "",
            rs_id: resourceData.rs_id|| "",
            rs_firm: item.rs_firm || "",
            rs_type: item.rs_type || "",
            rs_model: item.rs_model || "",
        };
        // console.log(bodyJson)
        axios.put(topologyUrl + "updateRsWithCompare", bodyJson, (res) => {
            if(res.code === "00") {
                message.success("更新成功");
                this.closeModal();
                this.props.updataDev();
            }
        })
    }
    closeModal() {
        this.setState({ deviceInfoVisible: false });
        this.props.onCancel();
    }

    render() {
        let {  deviceInfoVisible, diffInfoData, resourceData, ModalFlag } = this.props

        return (
            <div>
                <Modal
                    title="设备信息差异对比"
                    visible={deviceInfoVisible}
                    width="60%"
                    style={{ top: 50 }}
                    onCancel={() => this.closeModal()}
                    footer={null}
                    destroyOnClose
                >
                    <Row>
                        <Col span={12}>
                            <div className="infoDescriptions">
                                <Descriptions title={<span style={{ fontWeight: 600 }}>{diffInfoData.net_mask}-网络设备</span>} style={{ marginRight: 20 }} bordered column={1}>
                                    <Descriptions.Item label={"设备名称"}>{diffInfoData.hostname}</Descriptions.Item>
                                    <Descriptions.Item label={"设备IP"}>{diffInfoData.interface_ips}</Descriptions.Item>
                                    <Descriptions.Item label={"厂商"}>{diffInfoData.firm}</Descriptions.Item>
                                    <Descriptions.Item label={"设备类型"}>{diffInfoData.type}</Descriptions.Item>
                                    <Descriptions.Item label={"设备型号"}>{diffInfoData.model}</Descriptions.Item>
                                </Descriptions>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="infoDescriptions">
                                {ModalFlag ? 
                                    <Formik
                                        onSubmit={(value, actions) => { this.updateResourceData(value); actions.setSubmitting(false); }}
                                        validate={(values) => {
                                            let info = {};
                                            if (!values.rs_firm) {
                                                info.rs_firm = "请填写厂商";
                                            }
                                            if (!values.rs_type) {
                                                info.rs_type = "请填写设备类型";
                                            }
                                            if (!values.rs_model) {
                                                info.rs_model = "请填写设备型号";
                                            }
                                            return info
                                        }}
                                        initialValues={{
                                            rs_firm: resourceData.rs_firm,
                                            rs_type: resourceData.rs_type,
                                            rs_model: resourceData.rs_model,
                                        }}
                                    >
                                        <FormikForm labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                                            <Descriptions title={<span style={{ fontWeight: 600 }}>系统设备</span>} style={{ marginRight: 20 }} bordered column={1}>
                                                <Descriptions.Item label={"设备名称"}>{resourceData.rs_device_id}</Descriptions.Item>
                                                <Descriptions.Item label={"设备IP"}>{resourceData.rs_ip}</Descriptions.Item>
                                                <Descriptions.Item label={"厂商"} className="noPadding">
                                                    <FormikInput key={"rs_firm"} name={"rs_firm"} placeholder={"请输入厂商" + resourceData.rs_firm} />
                                                </Descriptions.Item>
                                                <Descriptions.Item label={"设备类型"} className="noPadding">
                                                    <FormikInput key={"rs_type"} name={"rs_type"} placeholder={"请输入设备类型" + resourceData.rs_type} />
                                                </Descriptions.Item>
                                                <Descriptions.Item label={"设备型号"} className="noPadding">
                                                    <FormikInput key={"rs_model"} name={"rs_model"} placeholder={"请输入设备型号" + resourceData.rs_model} />
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <div style={{ textAlign: "right", marginTop: 20 }}>
                                                <Button onClick={() => { this.closeModal() }}>取消</Button>
                                                <SubmitButton style={{ marginLeft: "10px" }} disabled={false}>更新信息</SubmitButton>&nbsp;
                                            </div>
                                        </FormikForm>
                                    </Formik>
                                : ""}
                            </div>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }

}

const ComparativeDataForm = Form.create()(ComparativeData);
export default ComparativeDataForm;

