import React from 'react'
import { Button, Card, Form, Icon, Row, Col, Table, DatePicker, InputNumber, Select, Radio, Modal, message } from 'antd';

import axios from '@/axios/axios_utils';
import Utils from "@/utils/utils";
import moment from 'moment';
import TableTransfer from '../monitorAlert/alertGroup/TableTransfer';
const { confirm } = Modal;
const checkDevices_url = "http://" + backup_address + "/ZJNMS_V1/";
import './index.scss'

export default class setRunType extends React.Component {

    state = {
        allTime: moment(new Date(), "YYYY-MM-DD HH:mm:ss "),
        radioValue: "manual",
        setAutoVisible: false,
        loading: false,
        selectedData: [],
        tableList: [],
        targetKeys: [],
        tableData: [],
        current_page: 1, 
        pageSize: 200000000,
    }

    componentWillMount() {
        this.getData();
    }
    componentWillReceiveProps(props) {
        let { targetKeys, radioValue, allTime, current_page, pageSize } = this.state;
        let { selectedData, cmdItem, setAutoVisible } = props;
        let tableData = selectedData;
        if(props.setAutoVisible !== this.props.setAutoVisible) {
            if(cmdItem._id && setAutoVisible) {
                tableData = [cmdItem];
                targetKeys = this.getTargetKeys(cmdItem.check_items);
                radioValue = cmdItem.check_mode ? cmdItem.check_mode : "manual";
                allTime = (cmdItem.auto_check_config && cmdItem.check_mode === "auto") ? moment(cmdItem.auto_check_config.start_date) : moment(new Date(), "YYYY-MM-DD HH:mm:ss ");
            } else if(selectedData.length !== 0 && setAutoVisible) {
                targetKeys = []
                radioValue = "manual";
                allTime = moment(new Date(), "YYYY-MM-DD HH:mm:ss ");
            }
            if(props.setAutoVisible) {
                this.getData(current_page, pageSize, props.devicesFirm);
            }
            this.setState({ tableData, targetKeys, selectedData, radioValue, allTime })
        }
    }
    radioValueChange = e => {
        this.setState({ radioValue: e.target.value })
    }
    getTargetKeys(ids) {
        let { tableList, targetKeys } = this.state;
        targetKeys = [];
        if(ids) {
            if(tableList.length != 0) {
                tableList.map((item, index) => {
                    if(ids.indexOf(item._id) != -1) {
                        targetKeys.push(item._id);
                    }
                })
                // this.setState({ targetKeys })
            }
        }
        return targetKeys;
    }
    getData(page, page_size, firms) {
        let { current_page, pageSize } =  this.state;
        let { devicesFirm } = this.props;
        let bodyJson = {}
        bodyJson.page_no = page || current_page;
        bodyJson.page_size = page_size || pageSize;
        bodyJson.firms = (firms || devicesFirm).toUpperCase()
        // console.log("bodyJson", bodyJson)
        axios.get(checkDevices_url + "check/check_cmd", bodyJson, (res) => {
            if(res.code === "00") {
                this.setState({
                    tableList: res.data,
                })
            }
        })
    }
    update_auto_backup_time_all = (backup_cycle, time_type, selectedRowKeys) => {
        let { targetKeys } = this.state
        let allTime = moment(this.state.allTime).format("YYYY-MM-DD HH:mm:ss");
        let { radioValue } = this.state;
        let bodyJson = {};
        let rowKeysArr = this.props.cmdItem._id ? this.props.cmdItem : selectedRowKeys;
        bodyJson.check_mode = radioValue;
        bodyJson._ids = [];
        bodyJson.check_items = targetKeys;
        if(radioValue === "auto") {
            bodyJson.auto_check_config = {
                cycle: backup_cycle,
                type: time_type,
                start_date: allTime,
            }
        }
        rowKeysArr.map((item, index) => {
            bodyJson._ids.push(item._id);
        })
        // console.log(bodyJson)
        axios.put(checkDevices_url + "check/update_check_info", bodyJson, (result) => {
            if(result.code === "00") {
                message.success(result.msg)
                this.setState({ targetKeys: [] })
                this.props.getTableData();
                this.props.clearSelectedData();
            }
        }, (error) => {
            Utils.showMessage("error", '批量设置时间失败', error);
        });
    };
    checkInfo(content, callback) {
        confirm({
            title: "提示信息",
            content,
            onOk() {
                callback();
            }
        })
    }
    closeModal() {
        this.setState({ setAutoVisible: false, targetKeys: [] });
        this.props.onCancel();
        this.props.clearCmdItem();
    }

    render = () => {
        let { setAutoVisible } = this.props;
        let { time_type, backup_cycle, allTime, tableList, targetKeys, tableData } = this.state;
        const usercolums = [
            {
                title: '序号',
                dataIndex: 'ip',
                ellipsis: true,
                width: 50,
                render: (text, item, c) => { return c + 1 }
            },
            {
                title: '编号',
                dataIndex: 'number',
                ellipsis: true,
            },
            {
                title: '分类',
                dataIndex: 'type',
                width: 80,
                ellipsis: true,
            },
            {
                title: '设备厂商',
                dataIndex: 'firm',
                width: 80,
                ellipsis: true,
            },
            {
                title: '型号',
                dataIndex: 'deviceVersion',
                ellipsis: true,
            },
            {
                title: '描述',
                dataIndex: 'description',
                ellipsis: true,
            },
        ]
    
        let _this = this;
        return (
            <Modal
                title="批量设置时间"
                visible={setAutoVisible}
                onCancel={() => this.closeModal()}
                okButtonProps={{ icon: "check-circle" }}
                cancelButtonProps= {{ icon: "close-circle" }}
                onOk={() => {
                    setTimeout(() => {
                        _this.update_auto_backup_time_all(_this.state.backup_cycle || 1, _this.state.time_type || "day", tableData)
                    }, 100)
                    _this.closeModal();
                }}
                width={"80%"}
                destroyOnClose
            >
                <div >
                    执行方式：&nbsp;
                    <Radio.Group
                        onChange={this.radioValueChange}
                        defaultValue={this.state.radioValue}
                        value={this.state.radioValue} 
                        options={[
                            { label: "手动执行", value: "manual" },
                            { label: "自动执行", value: "auto" },
                        ]}
                    /><p />
                    {this.state.radioValue === "auto" ? 
                        <div>
                            开启时间：&nbsp;
                            <DatePicker showTime style={{ width: 200 }}
                                defaultValue={allTime}
                                onChange={(a) => { this.setState({ allTime: a }) }}
                                placeholder="选择时间" format="YYYY-MM-DD HH:mm:ss" /><p />
                            执行周期：&nbsp;
                            <InputNumber min={1} style={{ width: 80 }} defaultValue={backup_cycle || 1}
                                onChange={(e) => { this.setState({ backup_cycle: e }) }} />
                            <Select style={{ width: 80 }} defaultValue={time_type || "day"} onChange={(e) => { this.setState({ time_type: e }) }}>
                                <Select.Option value="min">分钟</Select.Option>
                                <Select.Option value="hour">小时</Select.Option>
                                <Select.Option value="day">天</Select.Option>
                                <Select.Option value="week">周</Select.Option>
                            </Select>备份一次<p />
                        </div> : ""
                    }
                    <TableTransfer
                        dataSource={tableList.map((r) => { r.key = r._id; r.disabled = false; return r })}
                        titles={[<div><Icon type="unordered-list" twoToneColor="#979797" /> 设备检查项列表</div>,
                        <div><Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> 已选择检查项列表</div>]}
                        operations={['添加', '移除']}
                        targetKeys={targetKeys}
                        onChange={(targetKeys, direction, moveKeys) => { this.setState({ targetKeys }) }}
                        showSearch={true}
                        filterOption={(inputValue, item) =>
                            item.number.indexOf(inputValue) !== -1 || item.type.indexOf(inputValue) !== -1 ||
                            item.firm.indexOf(inputValue) !== -1 || item.deviceVersion.indexOf(inputValue) !== -1 || item.description.indexOf(inputValue) !== -1
                        }
                        leftColumns={usercolums}
                        rightColumns={usercolums}
                    /><p />
                    已选设备：
                    <Table
                        columns={[
                            { title: "设备IP", dataIndex: "device.resourceParams.deviceIp", align: "left", },
                            { title: "设备厂商", align: "left", dataIndex: "device.resourceParams.deviceFirm", render: (t) => Utils.Translation(t) },
                            { title: "设备类型", align: "left", dataIndex: "device.resourceParams.deviceType", render: (t) => Utils.Translation(t) },
                            { title: "设备名", align: "left", dataIndex: "device.resourceParams.deviceId" }
                        ]}
                        dataSource={tableData}
                        rowKey={(text) => { return text.device.resourceParams.deviceIp }}
                        scroll={{ y: 500 }}
                        size="small"
                        pagination={false}
                    />
                </div>
            </Modal>
        )
    };
}