import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from "antd";
import * as echarts from 'echarts';

import { connect } from 'react-redux'
import ReactEcharts from "echarts-for-react"
import Socket from '@/socket/index'
import Utils from '@/utils/utils'
const colorArray = ["#626c91", "#979797", "#6be6c1", "#c4ebad", "#96dee8"];
function DataMap(props) {
    const getOptionCloums = (_data3) => ({
        // title: {
        //     text: '设备品牌分布统计',
        //     x: 'center'
        // },
        // legend: {
        //     orient: 'horizontal',
        //     top: 30,
        //     type: "scroll",
        //     left: 0,
        //     itemHeight: 8,
        //     itemWidth: 8,
        //     data: _data3.map((r) => (r.name))
        // },
        // color: ["#00CCD2", "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8"],
        // tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {               // 坐标轴指示器，坐标轴触发有效
        //         // type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        //     }
        // },
        tooltip: {
            trigger: 'axis',
            // formatter: "{b} : {c} ({d}%)"
            formatter: (param) => {
                const i_param = Array.isArray(param) ? param[0] : param
                const u_parm = i_param.data
                let strArr = '<div>'
                for (let i in u_parm) {
                    strArr += `<div>${i}:${u_parm[i]}</div>`
                }
                return strArr + i_param.marker + "</div>"
            }
        },
        grid: {
            // top: "2%",
            left: '3%',
            right: '3%',
            bottom: '1%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: _data3.map((r) => (r.name)),
                axisTick: {
                    alignWithLabel: true
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#5b5b5b",
                    },
                },
                axisLabel: { interval: 0, rotate: 40, interval: _data3.length > 15 ? "auto" : 0 }
            }
        ],
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    formatter: '{value} ' + (props.unit || "")
                },
                splitLine: { lineStyle: { color: "rgba(91, 91, 91, .35)" } },
                // splitLine: { lineStyle: { color: "#5b5b5b" } },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#5b5b5b",
                        // color: "red",
                        // color: "#fff"
                    }
                }
            }
        ],
        series: [
            {
                name: '',
                type: 'bar',
                label: {
                    show: true, position: _data3.length > 4 ? "top" : "inside", color: "#fff", formatter: (pram) => {
                        return pram.value + props.unit || ""
                    }
                },
                barWidth: '50%',
                itemStyle: {
                    normal: {
                        //这里是重点
                        color: function (params) {
                            //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                            // var colorList = ["#3fb1e3", "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8", '#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622'];
                            // var colorList = ["#31e3cb", "#a0a7e6", "#96dee8", '#1890ff', "#ff5338", "#ff5384", "#4456c7", "#ff80a0", '#5ec9db', '#fdc765', '#f27d51', '#6462cc', '#91c7ae', '#749f83', '#f26659', "#c4ebad",];
                            var colorList = ["#64E9FD", "#4F54FF", "#8A7DEC", '#73A9FF', "#FFDE58", "#FF7B82", "#3367FF", "#76FFBB", '#FFBA6C', '#E68BFF', '#f27d51', '#6462cc', '#91c7ae', '#749f83', '#f26659', "#c4ebad",];

                            return colorList[params.dataIndex]
                            // return "#1890ff"
                        },
                        // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        //     offset: 0,
                        //     color: '#00CCD2'
                        // }, {
                        //     offset: 1,
                        //     color: '#00A2FF'
                        // }])

                    }
                },
                // itemStyle: {
                //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //         { offset: 0, color: '#83bff6' },
                //         { offset: 0.5, color: '#188df0' },
                //         { offset: 1, color: '#188df0' }
                //     ])
                // },
                emphasis: {
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#2378f7' },
                            { offset: 0.7, color: '#2378f7' },
                            { offset: 1, color: '#83bff6' }
                        ])
                    }
                },
                data: _data3
            }
        ]
    });

    return <ReactEcharts
        option={getOptionCloums(props.data)}
    />
}
export default (React.memo(DataMap))
