
import React, { useState, useEffect } from 'react'
import { Card, Table, Input, Button, Icon, message, notification, Menu, Dropdown, Modal, Typography, Select, Badge, } from "antd";
import Utils from "@/utils/utils";
import AxiosObj from '@/axios/index';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import BaseModal from '@/components/BaseModal/index';
import MonacoEditor from 'react-monaco-editor';
export default function BackupRecords(props) {
    const [tabData, setTabData] = useState([]);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_total, set_total] = useState(0);
    const [_detail, set_detail] = useState(null);
    const [content, set_content] = useState(null);
    useEffect(() => {//componentDidMount
        console.log(props)

        updata(_pageSize, _current);
        return () => { //componentWillUnmount
            console.log('componentWillUnmount')
        }
    }, []);
    const updata = (pageSize, pageNum, querys) => {
        set_content(null)
        let bodyJson = {
            "page_size": pageSize,
            "page_no": pageNum
        };
        console.log(bodyJson);
        AxiosObj.HTTP_POST("backUp", "findBatchBackupOperationRecords", bodyJson, (result) => {

            let data = Utils.DataAddItem(result.data);
            console.log(data);
            setTabData(data)
            set_total(result.records_count)
            set_current(pageNum)
        }, (error) => { Utils.showMessage("error", "查询失败", error); });
    };
    return <div>
        <CustomBreadcrumb
            arr={["运维管理", { title: "配置备份", to: "/configBackup/device" }, { title: "手动备份操作记录", to: "/configBackup/BackupRecords" }]}
        />
        <Card title={<Typography.Text strong>手动备份操作记录</Typography.Text>}
            extra={<Button type="primary" icon="rollback" onClick={() => { props.history.go(-1) }}>返回配置备份</Button>}
        >
            <Table
                columns={[
                    { title: "序号", render: (a, b, c) => { return <span key={c}>{c + 1 + (_current - 1) * _pageSize}</span> } },
                    { title: "操作时间", dataIndex: "add_time" },
                    { title: "操作人", dataIndex: "username" },
                    { title: "操作状态", dataIndex: "backup_state" },
                    {
                        title: "详情", render: (a, b, c) => {
                            return <Button type="primary" size="small" icon="form" onClick={() => {
                                let record_details = a.record_details;
                                let ele = <Table
                                    columns={[
                                        { title: "序号", render: (a, b, c) => { return <span key={c}>{c + 1}</span> } },
                                        { title: "时间", dataIndex: "add_time" },
                                        { title: "ip", dataIndex: "ip" },
                                        {
                                            title: "配置", dataIndex: "config", render: (a, b, c) => {
                                                return <Button type="primary" size="small" icon="file-text" onClick={() => {
                                                    Modal.confirm({
                                                        title: "配置信息",
                                                        icon: "file-text",
                                                        maskClosable: true,
                                                        width: "70%",
                                                        content: <MonacoEditor
                                                            language="java"
                                                            height="600"
                                                            theme="vs"
                                                            value={a}
                                                        />
                                                    })
                                                }}>配置</Button>
                                            }
                                        }]}
                                    scroll={{ y: 600 }}
                                    dataSource={record_details}
                                    pagination={false}
                                    size="small"
                                />
                                set_content(<div> {ele} </div>)
                            }}>详情</Button>
                        }
                    },
                ]}
                size="small"
                bordered={false}
                dataSource={tabData}
                pagination={{
                    current: _current, pageSize: _pageSize, total: _total,
                    showTotal: (total) => { return <span>共 {total} 项</span> },
                    pageSizeOptions: ["20", "50", "100", "500", "1000"],
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) => {
                        set_pageSize(size)
                        updata(size, 1, {})
                    },
                    onChange: (page, pageSize) => {
                        updata(pageSize, page, {});
                    }
                }}
            />

        </Card>
        <BaseModal title="操作详情" width="50%" content={content} />
    </div>

}