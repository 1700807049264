import React from 'react'
import http from '@/axios/axios_utils';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import Utils from "@/utils/utils";
import deviceFirmUtils from '@/utils/deviceFirmUtils';
import SetRunType from "./setRunType";
import FilesUtils from "@/utils/FilesUtils";
import { Table, Collapse, Card, Button, message, Modal, Icon, Dropdown, Menu, Row, Col, Tabs, Tag, Tooltip, Select } from "antd";

const checkDevices_url = "http://" + backup_address + "/ZJNMS_V1/";
import './index.scss'

class SecurityBaseLine extends React.Component {
    state = {
        visible: false,
        tableData: [], // 表格数据
        current_page: 1,  // 当前页
        pageSize: 20,
        total: 0,
        loading: false,
        query: {},
        selectedRowKeys: [],
        selectedRows: [],
        selectedData: [],
        flagOnce: false,
        cmdItem: {},
        setAutoVisible: false,
        devicesFirm: '', // 设备厂商

    }

    componentWillMount() {
        this.setState({ loading: true, });
        this.getTableData();
        this.timerControl();
    }
    componentDidUpdate() {
        let { tableData, flagOnce } = this.state;
        let flag = false;
        for (let i = 0; i < tableData.length; i++) {
            if (tableData[i].status === "run") {
                flag = true;
                break;
            }
        }
        if (flag === false && flagOnce === true && this.timer != null) {
            // console.log(flag, flagOnce, this.timer)
            this.setState({ flagOnce: false })
            clearInterval(this.timer);
        }
    }
    componentWillUnmount() {
        this.clearTimer();
    }

    clearTimer() {
        let { flagOnce } = this.state;
        if (this.timer != null) {
            this.setState({ flagOnce: false })
            clearInterval(this.timer);
        }
    }
    timerControl() {
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
            let { current_page, pageSize, query } = this.state;
            this.setState({ flagOnce: true })
            this.getTableData(current_page, pageSize, query)
        }, 5000)
    }
    handleMenuClick = (e, item) => {
        let bodyJson = {
            taskId: item._id,
            ip: item.device ? item.device.resourceParams.deviceIp : ""
        }
        switch (e.key) {
            case "run": this.runCheck([item]); break;
            case 'info':
                let path = {
                    pathname: `/securityBaseline/devicesInfo`,
                    bodyJson,
                };
                this.props.history.push(path);
                break;
            case 'del': this.deleteDevices([item._id]); break;
            case "set": this.setState({setAutoVisible: true, cmdItem: item, devicesFirm: item.device.resourceParams.deviceFirm }); break;
        }
    }
    exportButtonClick(e) {
        let { selectedRowKeys } = this.state;
        let fileName = "设备列表-" + new Date().toLocaleDateString().replace(/\//g, "-")
        if (e.key === "select") {
            if (Utils.isEmpty(selectedRowKeys)) { message.error('请勾选设备！'); return; }
            let exportUrl = checkDevices_url + "check/export_check_result?_ids=" + JSON.stringify(selectedRowKeys);
            FilesUtils.exportFilesGet(exportUrl, fileName)
            // window.open(checkDevices_url + "check/export_check_result?_ids=" + JSON.stringify(selectedRowKeys));
        } else {
            let exportUrl = checkDevices_url + "check/export_check_result?_ids=" + JSON.stringify([]);
            FilesUtils.exportFilesGet(exportUrl, fileName)
            // window.open(checkDevices_url + "check/export_check_result?_ids=" + JSON.stringify([]));
        }
    }
    menuButton(item) {
        let btnFlag = false;
        if (item.status === "run") { btnFlag = true }
        return (
            <Menu onClick={(e) => this.handleMenuClick(e, item)}>
                <Menu.Item key="run" disabled={btnFlag}>
                    <Icon type="tool" />检查
                </Menu.Item>
                <Menu.Item key="del">
                    <Icon type="delete" />删除
                </Menu.Item>
                <Menu.Item key="set">
                    <Icon type="setting" />设置检查项
                </Menu.Item>
            </Menu>
        )
    }
    deleteDevices(id) {
        let bodyJson = {};
        let _this = this;
        bodyJson._ids = id;
        if (id.length === 0) {
            message.error("请选择需要删除的设备！")
        } else {
            // console.log(id)
            Modal.confirm({
                title: '消息提示',
                content: '你确定删除该设备吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    http.delete_body(checkDevices_url + "check/check_devices", bodyJson, (res) => {
                        if (res.code === "00") {
                            message.success(res.msg || "删除成功");
                            _this.setState({ selectedRowKeys: [], loading: true, selectedData: [], selectedRows: [] });
                            _this.getTableData();
                        } else {
                            message.error(res.msg || "删除失败")
                        }
                    })
                },
            });
        }
    }
    // 执行任务
    runCheck(item) {
        let bodyData = [];
        if (item.length === 0) {
            message.error("请选择需要执行的设备！")
        } else {
            item.map((checkDevice, index) => {
                bodyData.push({
                    ip: checkDevice.device ? checkDevice.device.resourceParams.deviceIp : "",
                    check_items: checkDevice.check_items || []
                })
            })
            setTimeout(() => {
                this.setState({ loading: true })
                this.getTableData();
            }, 500);
            http.put(checkDevices_url + "check/check_devices", bodyData, (res) => {
                if (res.code === "00") {
                    message.success("执行成功");
                    this.setState({ flagOnce: true });
                    this.timerControl();
                } else {
                    message.error("执行成功")
                }
            })
            // console.log(item, bodyData);
        }
    }
    getTableData(page, page_size, querys) {
        // this.setState({ loading: true, });
        const { current_page, pageSize, query } = this.state;
        let bodyJson = {
            page_no: page || current_page,
            page_size: page_size || pageSize,
            match: querys || query,
            userId: sessionStorage.getItem("userId")
        };
        // console.log(bodyJson)
        http.get(checkDevices_url + "check/check_devices", bodyJson, (result) => {
            // console.log(result)
            if (result.code === "00") {
                if (result.count !== 0 && result.data.length === 0) {
                    let pageNum = result.count % pageSize > 0 ? parseInt(result.count / pageSize) + 1 : parseInt(result.count / pageSize);
                    this.getTableData(pageNum, page_size, querys)
                } else {
                    this.setState({
                        tableData: result.data,
                        total: result.count,
                        current_page: page || 1,
                        loading: false,
                        query: querys,
                    })
                }
            }
        })
    }
    typeTranslate(type) {
        switch (type) {
            case "min": return "分钟";
            case "hour": return "小时";
            case "day": return "天";
            case "week": return "周";
        }
    }
    render() {
        const { current_page, total, pageSize, query, selectedRowKeys, selectedData, selectedRows, setAutoVisible, cmdItem, devicesFirm } = this.state;
        const columns = [
            {
                title: '序号',
                width: 50,
                dataIndex: 'key',
                render: (text, item, index) => {
                    return <span>{index + 1 + (current_page - 1) * pageSize}</span>
                }
            },
            {
                title: '设备IP',
                dataIndex: 'device.resourceParams.deviceIp',
                width: 100,
                ellipsis: true,
            },
            {
                title: '设备类型',
                dataIndex: 'device.resourceParams.deviceType',
                width: 100,
                ellipsis: true,
                render: (text, item, index) => {
                    return <div>{Utils.Translation(text)}</div>;
                }
            },
            {
                title: '设备名',
                dataIndex: 'device.resourceParams.deviceId',
                width: 130,
                ellipsis: true,
            },
            {
                title: '设备厂商',
                dataIndex: 'device.resourceParams.deviceFirm',
                width: 80,
                ellipsis: true,
            },
            {
                title: '状态',
                dataIndex: 'status',
                width: 70,
                ellipsis: true,
                align: "center",
                render: (text, item) => {
                    switch (text) {
                        case "error": return (<Tooltip title={item.reason}><Tag style={{ margin: 0 }} color="#f50">错误</Tag></Tooltip>)
                        case "abnormal": return (<Tooltip title={item.reason}><Tag style={{ margin: 0 }} color="#ffbe3d">异常</Tag></Tooltip>)
                        case "done": return (<Tooltip title={item.reason}><Tag style={{ margin: 0 }} color={Utils.ColorShow(text)}>合规</Tag></Tooltip>)
                        // case "run" : return (<Tooltip title={item.reason}><Tag style={{ margin: 0 }} color="#58a3f7">执行中</Tag></Tooltip>)
                        // case "new" : return (<Tooltip title={item.reason}><Tag style={{ margin: 0 }} color="#87CEFF">新建</Tag></Tooltip>)
                        default: return (<Tooltip title={item.reason}><Tag style={{ margin: 0 }} color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag></Tooltip>)
                    }
                }
            },
            {
                title: '设备型号',
                dataIndex: 'device.resourceParams.deviceVersion',
                width: 140,
                ellipsis: true,
            },
            {
                title: '合格率',
                dataIndex: 'pass_rate',
                width: 60,
                ellipsis: true,
                render: (text) => {
                    return <span>{text + "%"}</span>
                }
            },
            {
                title: '执行方式',
                dataIndex: 'check_mode',
                width: 80,
                ellipsis: true,
                render: (text, item, index) => {
                    switch (text) {
                        case "manual": return <Tooltip title="手动执行"><span><Icon type="tool" />&nbsp;手动</span></Tooltip>;
                        case "auto": return (
                            <Tooltip title={
                                <div>
                                    <p style={{ marginBottom: 0 }}>执行周期：{item.auto_check_config ? item.auto_check_config.cycle + this.typeTranslate(item.auto_check_config.type) : ""}</p>
                                    <p style={{ marginBottom: 0 }}>开始时间：{item.auto_check_config ? item.auto_check_config.start_date : ""}</p>
                                </div>}>
                                <span><Icon type="sync" />&nbsp;自动</span>
                            </Tooltip>
                        );
                    }
                }
            },
            {
                title: '最近一次检查时间',
                dataIndex: 'checkTime',
                width: 140,
                ellipsis: true,
            },
            {
                title: '操作',
                align: 'center',
                flag: true,
                dataIndex: "op",
                fixed: 'right',
                width: 130,
                render: (text, item) => {
                    return (
                        <div>
                            <Dropdown.Button
                                overlay={this.menuButton(item)} type='primary' size='small' icon={<Icon type="down" />}
                                onClick={() => {
                                    this.handleMenuClick({ key: "info" }, item)
                                }}
                            >
                                <Icon type='edit' /> 详情
                            </Dropdown.Button>
                        </div>
                    )
                }
            }
        ]
        // 多选
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            hideDefaultSelections: 'true',
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys: selectedRowKeys,
                    selectedRows: selectedRows,
                });
            },
            onSelect: (record, selected, selectedRows) => { // 选择单行
                if (selected) {
                    if (JSON.stringify(selectedData).indexOf(JSON.stringify(record)) === -1) {
                        selectedData.push(record);
                    }
                } else {
                    selectedData.splice(selectedData.findIndex(item => item._id === record._id), 1)
                }
                // console.log("最终的selectedData：", selectedData)
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                let beforeSelectAll = [];
                if (selected) {
                    if (selectedData.length === 0) {
                        selectedData.push(...selectedRows)
                    } else {
                        selectedData.map((item, index) => {
                            if (JSON.stringify(selectedRows).indexOf(JSON.stringify(item)) === -1) {
                                // console.log("selectedData:", item)
                                beforeSelectAll.push(item);
                            }
                        })
                        selectedData.splice(0, selectedData.length, ...selectedRows, ...beforeSelectAll)
                    }
                } else {
                    changeRows.map((item, index) => {
                        selectedData.splice(selectedData.findIndex(selectItem => selectItem._id === item._id), 1)
                    })
                }
                this.setState({ selectedData })
                // console.log("onSelectAll", selected, selectedRows, selectedData)
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [], selectedData: [] });
                    },
                },
            ],
        };
        const formList = [
            {
                label: '设备ip',
                type: 'INPUT',
                field: 'device.resourceParams.deviceIp',
                initialValue: '',
                placeholder: '设备ip'
            },
            {
                label: '状态',
                type: 'SELECT',
                field: 'status',
                initialValue: [],
                placeholder: '状态',
                list: [{ id: 'new', name: '新建' }, { id: 'done', name: '合规' }, { id: 'run', name: '执行中' }, { id: "error", name: "错误" }, { id: "abnormal", name: "异常" }]
            },
            {
                label: '设备厂商',
                type: 'AUTO',
                field: 'device.resourceParams.deviceFirm',
                initialValue: '',
                placeholder: '设备厂商',
                list: deviceFirmUtils.deviceFirmsAutoInput()
            },
        ]
        let scrollX = 0;
        columns.map((item, index) => {
            scrollX += item.width;
        })
        scrollX = scrollX + 260;
        // 导出按钮组
        const BtnMenu = (
            <Menu onClick={(e) => this.exportButtonClick(e)}>
                <Menu.Item key="select">
                    <Icon type="export" />导出选中
              </Menu.Item>
                <Menu.Item key="all">
                    <Icon type="export" />全部导出
              </Menu.Item>
            </Menu>
        );
        return (
            <div className="baseLineBody">
                <div className="CustomBreadcrumb"><CustomBreadcrumb arr={["安全基线管理", { title: "基线设备", to: "/securityBaseline" }]} /> </div>
                <div className="searchDiv" >
                    <Collapse defaultActiveKey={"key"}>
                        <Collapse.Panel header="筛选条件" key={"key"}>
                            <FilterForm formList={formList}
                                filterSubmit={(querys) => {
                                    let bodyJson = {};
                                    if (querys.device.resourceParams.deviceIp) {
                                        bodyJson["device.resourceParams.deviceIp"] = querys.device.resourceParams.deviceIp;
                                    }
                                    if (querys.device.resourceParams.deviceFirm) {
                                        bodyJson["device.resourceParams.deviceFirm"] = querys.device.resourceParams.deviceFirm;
                                    }
                                    if (querys.status) {
                                        bodyJson.status = querys.status;
                                    }
                                    if (querys.taskName) {
                                        bodyJson.taskName = querys.taskName;
                                    }
                                    if (querys.result) {
                                        bodyJson.result = querys.result;
                                    }
                                    // console.log(querys, bodyJson)
                                    this.setState({ loading: true, });
                                    this.getTableData(1, pageSize, bodyJson);
                                }}
                                resetTab={() => {
                                    this.setState({ loading: true, });
                                    this.getTableData(1, pageSize, {})
                                }}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </div>
                <div className="baseLineContent" style={{ background: "#fff" }}>
                    <Card
                        title={<span style={{ fontWeight: 600 }}>设备列表</span>}
                        style={{ marginTop: 8 }}
                        extra={
                            <Row>
                                <Button type="primary" icon="clock-circle" onClick={() => {
                                    if (Utils.isEmpty(selectedData)) {
                                        message.info("请选择设备！");
                                        return;
                                    } else {
                                        let firms = selectedData.map(item => item.device.resourceParams.deviceFirm )
                                        firms = Array.from(new Set(firms)) // 数组去重
                                        this.setState({ setAutoVisible: true, devicesFirm: firms.join(',') })
                                    }
                                }}>批量设置</Button>&nbsp;
                                <Dropdown overlay={BtnMenu}>
                                    <Button type="primary">
                                        <Icon type="export" />导出检查结果 <Icon type="down" />
                                    </Button>
                                </Dropdown>
                                <Button type='primary' icon='edit' onClick={() => {
                                    let path = {
                                        pathname: `/securityBaseline/newCheckDevice`,
                                    };
                                    this.props.history.push(path);
                                }}>新增检查任务</Button>
                                <Button type="primary" icon="tool" onClick={() => { this.runCheck(selectedData) }}>检查</Button>
                                <Button type="danger" icon="delete" onClick={() => { this.deleteDevices(selectedRowKeys) }}>删除</Button>
                            </Row>
                        }
                    >
                        <Table
                            dataSource={this.state.tableData || []}
                            columns={columns}
                            size='small'
                            loading={this.state.loading}
                            rowKey={(text) => { return text._id }}
                            rowSelection={rowSelection}
                            scroll={{ x: scrollX }}
                            pagination={{
                                pageSize: pageSize, current: current_page, total: total,
                                showTotal: (total) => { return <span>共 {total} 项</span> },
                                pageSizeOptions: ["20", "50", "100", "500", "1000"],
                                showSizeChanger: true,
                                onShowSizeChange: (current, size) => {
                                    this.setState({ loading: true, pageSize: size })
                                    this.getTableData(1, size, query)
                                },
                                onChange: (page, pageSize) => {
                                    this.setState({ loading: true, });
                                    this.getTableData(page, pageSize, query);
                                }
                            }}
                        />
                    </Card>
                </div>
                <SetRunType
                    getTableData={() => { this.setState({ loading: true }); this.getTableData(current_page, pageSize, query) }}
                    setAutoVisible={setAutoVisible}
                    onCancel={() => this.setState({ setAutoVisible: false })}
                    selectedData={selectedData}
                    clearSelectedData={() => this.setState({ selectedData: [], selectedRows: [], selectedRowKeys: [] })}
                    cmdItem={cmdItem}
                    clearCmdItem={() => this.setState({ cmdItem: {} })}
                    devicesFirm={devicesFirm}
                />
            </div>
        )
    }
}

export default SecurityBaseLine;