import React, { Component } from "react";
import {
    Icon,
    Table,
    Button,
    Card,
    Tree, Dropdown, Menu, Tooltip, Tag, Typography
} from "antd";
import Socket from "@/socket/index";
const { TreeNode } = Tree;

export default class Main extends React.Component {
    constructor(props) {
        super(props);
        // let plainOptions = [
        //     {
        //         title: "表头1",
        //         dataIndex: "bt1"
        //     },
        //     {
        //         title: "表头2",
        //         dataIndex: "bt2"
        //     },
        //     {
        //         title: "表头3",
        //         dataIndex: "bt3"
        //     },
        //     // {
        //     //     title: "表头4",
        //     //     key: "bt4"
        //     // },
        //     // {
        //     //     title: "表头5",
        //     //     key: "bt5"
        //     // }
        // ];//模拟接口请求的字段列表

        // let plainOptions = props.columns;
        // let checkedOptions = props.columns.map((i, key) => { return i.dataIndex });//模拟接口请求的已选择字段


        // let plainOptions = [];
        // let checkedOptions = [];//模拟接口请求的已选择字段
        // // const dataObj = JSON.parse(localStorage.getItem(this.props.type));
        // const dataObj = this.state.currentCfg;
        // if (dataObj) {
        //     plainOptions = dataObj.plainOptions
        //     checkedOptions = dataObj.checkedOptions
        // } else {
        //     plainOptions = props.columns;
        //     checkedOptions = props.columns.map((i, key) => { return i.dataIndex });//模拟接口请求的已选择字段
        // }


        this.state = {
            page_size: 10,
            page: 1,
            totalPage: 1,
            totalCount: 1,
            plainOptions: this.props.columns, //默认的字段列表
            checkedOptions: this.props.columns.map((i, key) => { return i.dataIndex }), //默认的已选择字段
            editPlainOptions: [], //当前选择的字段列表，未保存
            editCheckedOptions: [], //当前已选择字段，未保存
            isClickHandleSearch: '',//设置字段后在未保存的情况下点击空白区域字段重置
        };
    }
    componentWillMount() {
        //获取表单配置信息
        Socket.getConfig("tabConfig", (res) => {
            //表格配置
            const tabConfig = res;
            //当前表格配置
            let currentCfg = res[this.props.type] || this.props.columns;
            if (typeof currentCfg === "string") {
                currentCfg = JSON.parse(currentCfg);
            }
            let plainOptions = [];
            let checkedOptions = [];//模拟接口请求的已选择字段

            const dataObj = currentCfg;
            if (dataObj.plainOptions) {
                plainOptions = dataObj.plainOptions
                checkedOptions = dataObj.checkedOptions
            } else {
                plainOptions = this.props.columns;
                checkedOptions = this.props.columns.map((i, key) => { return i.dataIndex });//模拟接口请求的已选择字段
            }
            this.setState({
                plainOptions, tabConfig, currentCfg, checkedOptions,
                editPlainOptions: plainOptions,
                editCheckedOptions: checkedOptions,
            })
        });
    }
    onFilterDropdownVisibleChange = (visible, type) => {
        if (visible && !this.state.isClickHandleSearch) {
            this.setState({
                isClickHandleSearch: false
            });
        } else {
            console.log(this.state)
            this.setState({
                plainOptions: this.state.editPlainOptions,
                checkedOptions: this.state.editCheckedOptions
            });
        }
    };
    handleSearch = confirm => {
        //确定 保存用户设置的字段排序和需要显示的字段key
        const { plainOptions, checkedOptions, tabConfig } = this.state;
        const { type } = this.props;
        const local = { plainOptions, checkedOptions };
        if (this.props.type) {
            // localStorage.setItem(this.props.type, JSON.stringify(local));
            // 保存配置
            let newdata = tabConfig;
            newdata[type] = JSON.stringify(local);
            Socket.setConfig("tabConfig", newdata, (res) => {
                console.log(res)
            });

        }
        this.setState(
            {
                isClickHandleSearch: true,
                editPlainOptions: plainOptions,
                editCheckedOptions: checkedOptions
            },
            () => {
                confirm();
            }
        );
    };

    handleReset = clearFilters => {
        //用户点击取消按钮，重置字段
        clearFilters();
        this.setState({
            plainOptions: this.state.editPlainOptions,
            checkedOptions: this.state.editCheckedOptions
        });
    };

    onCheck = (checkedKeys) => {
        this.setState({
            checkedOptions: checkedKeys
        });
    };

    onDrop = info => {
        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dropPos = info.node.props.pos.split("-");
        const dropPosition =
            info.dropPosition - Number(dropPos[dropPos.length - 1]);
        if (dropPosition === 1 || dropPosition == -1) {
            const loop = (data, key, callback) => {
                data.forEach((item, index, arr) => {
                    if (item.dataIndex === key) {
                        return callback(item, index, arr);
                    }
                    if (item.children) {
                        return loop(item.children, key, callback);
                    }
                });
            };
            const data = [...this.state.plainOptions];
            let dragObj;
            loop(data, dragKey, (item, index, arr) => {
                arr.splice(index, 1);
                dragObj = item;
            });
            let ar;
            let i;
            loop(data, dropKey, (item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
            this.setState({
                plainOptions: data
            });
        }
    };

    render() {
        const {
            inquiry_list,
            page,
            page_size,
            totalCount,
            plainOptions,
            checkedOptions
        } = this.state;
        const { pagination } = this.props;
        const loop = data =>
            data.map((item, index) => {
                return <TreeNode key={item.dataIndex} title={item.title} />;
            });
        const title_list2 = [
            {
                title: "表头1",
                dataIndex: "bt1",
                key: "bt1",
                width: 170,
                filterType: checkedOptions.some(item => item === "bt1")
            },
            {
                title: "表头2",
                dataIndex: "bt2",
                key: "bt2",
                width: 170,
                filterType: checkedOptions.some(item => item === "bt2")
            },
            {
                title: "表头3",
                dataIndex: "bt3",
                key: "bt3",
                width: 170,
                filterType: checkedOptions.some(item => item === "bt3")
            },
            {
                title: "表头4",
                dataIndex: "bt4",
                key: "bt4",
                width: 170,
                filterType: checkedOptions.some(item => item === "bt4")
            },
            {
                title: "表头5",
                dataIndex: "bt5",
                key: "bt5",
                width: 170,
                filterType: checkedOptions.some(item => item === "bt5")
            }
        ];
        const title_list = this.props.columns.map((i) => {
            i.filterType = checkedOptions.some(item => item === i.dataIndex);
            return i;
        })
        let new_title_list = [];
        let scrollX = 0;
        plainOptions.map(item => {
            title_list.map(titleItem => {
                if (item.title === titleItem.title) {
                    new_title_list.push(titleItem);
                    if (
                        titleItem.width &&
                        checkedOptions.some(optionsItem => optionsItem === item.dataIndex)
                    ) {
                        scrollX += titleItem.width;
                    }
                }
            });
        });
        scrollX = scrollX + 260;
        // let opItem = this.props.columns.slice(-1);
        // opItem["filterDropdown"] = ({ confirm, clearFilters }) => (
        //     <div style={{ padding: 8 }}>
        //         <Tree
        //             checkable
        //             className="draggable-tree"
        //             draggable
        //             blockNode
        //             selectable={false}
        //             onCheck={this.onCheck}
        //             checkedKeys={checkedOptions}
        //             onDrop={this.onDrop.bind(this)}
        //         >
        //             {loop(plainOptions)}
        //         </Tree>
        //         <div>
        //             <Button
        //                 type="primary"
        //                 size="small"
        //                 onClick={() => this.handleSearch(confirm)}
        //                 style={{ width: "60px", marginRight: "10px" }}
        //             >
        //                 确定
        // </Button>
        //             <Button
        //                 size="small"
        //                 onClick={() => this.handleReset(clearFilters)}
        //                 style={{ width: "60px" }}
        //             >
        //                 取消
        // </Button>
        //         </div>
        //     </div>
        // )
        // opItem["filterIcon"] = filtered => <Icon type="setting" theme="filled" />;
        // opItem["onFilterDropdownVisibleChange"] = this.onFilterDropdownVisibleChange.bind(this),
        //     new_title_list.push(opItem);
        new_title_list.unshift({
            title: "设置",
            dataIndex: "key",
            width: 70,
            align: 'center',
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Typography.Text strong>拖拽可排序</Typography.Text>
                    <Tree
                        checkable
                        className="draggable-tree"
                        draggable
                        blockNode
                        selectable={false}
                        onCheck={this.onCheck}
                        checkedKeys={checkedOptions}
                        onDrop={this.onDrop.bind(this)}
                    >
                        {loop(plainOptions)}
                    </Tree>
                    <div>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => this.handleSearch(confirm)}
                            style={{ width: "60px", marginRight: "10px" }}
                        >
                            确定
                        </Button>
                        <Button
                            size="small"
                            onClick={() => this.handleReset(clearFilters)}
                            style={{ width: "60px" }}
                        >
                            取消
                        </Button>
                    </div>
                </div>
            ),
            filterIcon: filtered => <Icon type="setting" theme="filled" />,
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange.bind(this),
            render: (a, b, c) => { return <span>{pagination ? (c + 1 + (pagination.current - 1) * pagination.pageSize) : c + 1}</span> }
        });
        return (
            <div>
                <Table
                    {...this.props}
                    columns={new_title_list.filter(
                        item => item.filterType || item.filterType === undefined
                    )}
                    // dataSource={[
                    //     { key: 0, op: 1, ip: "11111111" },
                    //     { key: 0, op: 1, ip: "22222222" },
                    // ]}
                    // pagination={{
                    //     current: page,
                    //     total: totalCount,
                    //     pageSize: page_size,
                    //     showQuickJumper: true,
                    //     showSizeChanger: true,
                    //     pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                    //     showTotal: this.showTotal
                    // }}
                    scroll={this.props.scroll ? this.props.scroll : { x: scrollX }}
                    pagination={{
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            pagination.onChange(1, size)
                        },
                        ...pagination,
                    }}
                />
            </div>
        );
    }
}