import moment from 'moment';
import React from 'react'
import { Divider, Row, Col, Input, Icon, Modal, message } from "antd";
import Socket from "@/socket/index";
export function addTaskType(callBack) {
    let name = "444";
    Modal.confirm({
        title: "添加任务类型",
        maskClosable: true,
        content: <div><Divider />
            <Row>
                <Col span={5}>类型名称:</Col>
                <Col span={19}><Input onChange={(e) => { name = e.target.value }} placeholder="模板类型" /></Col>
            </Row>
        </div>,
        icon: <Icon type="file-add" />,
        onOk() {
            Socket.ExecuteAction('AddTaskType', { name }, "", () => {
                message.success("新增成功!");
                if (callBack) callBack(name);
            });
        }
    })
}
export function delTaskType(name, callBack) {
    Modal.confirm({
        title: '消息提示',
        content: '你确定删除模板类型' + name + "吗?",
        okType: 'danger',
        onOk() {
            Socket.ExecuteAction('DeleteTaskType', { name }, "", () => {
                message.success("删除成功!");
                if (callBack) callBack(name);
            });
        },
    });
}