/**
 * Created by YIMINE on 2019/1/24.
 */
import React from 'react'
import {
    Card, Form, Input, Modal, Icon, Select, message, Divider,
    notification, Button, Row, Col, Spin
} from 'antd';
import './index.scss'
import Socket from "../../../../socket/index";
import Utils from "../../../../utils/utils";
import TimePlan from "./timePlan";
import CustomBreadcrumb from '../../../../components/CustomBreadcrumb/index';
import FlowChar from '@/components/FlowChar/index';
import TableForm from '@/components/TableForm/index';
import OutPutCheck from './output';
const { Option } = Select;
const TextArea = Input.TextArea;
class newTask extends React.Component {
    state = {
        RadioValue: 'manual',
        cardTitle: "新建模板",
        taskPlanData: { type: 'manual', content: null },
        visibleTime: false,             //时间计划模态框
        timeBtn: false,             //时间计划按钮
        pageSpinning: false,        //页面加载控制
        searchText: '',
        Tags: [],
        outputList: [],
        TaskType: [],
        task: null,
        AutoObject: null,
        commit: false,
        flow_data: null,
        templateData: []
    };
    componentDidMount() {
        let thisTemp = this;
        let IdData = this.props.match.params.Id;
        if (IdData) {
            IdData = (IdData).replace(":", "");
            let strList = IdData.split('&');
            this.setState({ pageSpinning: true });
            // console.log(strList[0])
            Socket.ExecuteAction('QueryTaskDetail', { _id: strList[0] }, "/taskQueryTask", (resp) => {
                let task = resp.task;
                // console.log(task)
                let reportTagsValue = "";
                if (task.reportTags) {
                    if (typeof (task.reportTags) === "string") {
                        reportTagsValue = task.reportTags;
                    }
                }
                let taskName = task.taskName;
                let AutoObject = task;
                let cardTitle = '编辑模板';
                if (strList[1]) {
                    taskName = "";
                    cardTitle = '复制模板';
                    AutoObject = null;
                }
                thisTemp.props.form.setFieldsValue({
                    taskName,
                    taskDes: task.taskDes,
                    tags: task.tags,
                    output: task.output,
                    reportTags: reportTagsValue,
                });
                const flow_data = task.taskContent;
                const nodes = flow_data && flow_data.nodes || [];
                let outputList = [];
                for (let key in nodes) {
                    if (nodes[key].taskdata && nodes[key].taskdata.output) {
                        const output = nodes[key].taskdata.output//[{}]
                        outputList = Utils.MergeArray(outputList, output);
                    }
                }
                outputList = outputList.map((row) => { return row.name })
                thisTemp.setState({
                    pageSpinning: false, flow_data: task.taskContent,
                    task, cardTitle, AutoObject, outputList
                });
            });
        }
        Socket.ExecuteAction('QueryTagsGroups', { tagClass: 'task' }, "", (result) => {
            thisTemp.setState({
                Tags: result.tags,
            });
        });
        this.getTaskType();
        this.loadReport();
    }
    loadReport = () => {
        Socket.ExecuteAction("QueryAllInspecTemplate",
            { option: { name: 1 } }, "/", (result) => {
                this.setState({ templateData: result.data });
            });
    }
    getTaskType() {
        Socket.ExecuteAction('QueryTaskTypes', {}, "", (result) => {
            this.setState({ TaskType: result.data, });
        });
    }
    AddTaskType(name) {
        Socket.ExecuteAction('AddTaskType', { name }, "", () => {
            message.success("新增成功!");
            this.getTaskType();
        });
    }
    delTaskType(name) {
        Socket.ExecuteAction('DeleteTaskType', { name }, "", () => {
            message.success("删除成功!");
            this.getTaskType();
        });
    }
    onParamChange = (data) => {
        this.setState({ visibleTime: false, taskPlanData: data });
    };
    handleSubmit = () => {

        let thisTemp = this;
        let { flow_data } = this.state;
        if (flow_data) {
            if (Utils.isEmptyObject(flow_data.nodes)) {
                message.error("流程图数据为空！");
                return;
            }
        } else {
            message.error("流程图数据为空！");
            return;
        }
        // e.preventDefault();
        thisTemp.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let body = values;
                body.taskPlan = {
                    type: "manual",
                    content: null
                };
                body.taskContent = flow_data;
                body.ifTemplate = true;
                console.log('body', body);
                thisTemp.setState({ pageSpinning: true });
                if (!thisTemp.state.AutoObject) {
                    Socket.ExecuteAction("AddTask", body, "", () => {
                        message.success('新增成功');
                        thisTemp.goBackPage();
                    }, (error) => {
                        notification.open({
                            message: '新增失败',
                            description: error,
                            icon: <Icon type="frown" style={{ color: 'red' }} />,
                        });
                        thisTemp.setState({ pageSpinning: false });
                    });
                } else {
                    body._id = thisTemp.state.task._id;
                    Socket.ExecuteAction("UpdateTask", body, "", () => {
                        message.success('修改成功');
                        thisTemp.goBackPage();
                    }, (error) => {
                        notification.open({
                            message: '修改失败',
                            description: error,
                            icon: <Icon type="frown" style={{ color: 'red' }} />,
                        });
                        thisTemp.setState({ pageSpinning: false });
                    });
                }
            }
        });
    };
    goBackPage() {
        let { state } = this.props.location;
        let bodyJson = this.props.location.bodyJson
        console.log(bodyJson)
        if(bodyJson) {
            if (this.props.location.state) { // 从任务详情页跳转到模版详情页
                bodyJson = {
                    taskId: state.taskId,
                    ...bodyJson
                }
                let path = {
                    pathname: state.pathname,
                    bodyJson,
                };
                this.props.history.push(path);
            } else if (bodyJson.pathname) { // 点击模版按钮跳转到模版详情页
                let backPath = {
                    pathname: bodyJson.pathname,
                    bodyJson,
                }
                this.props.history.push(backPath);
            } else { // 模版列表页转跳到模版详情页
                let path = {
                    pathname: '/task',
                    bodyJson,
                };
                this.props.history.push(path);
                // this.props.history.go(-1);
            }
        } else { // 新建模版返回模版列表
            let path = {
                pathname: '/task',
                bodyJson,
            };
            this.props.history.push(path);
        }
    }
    onChangeRadio = (e) => {
        console.log('radio checked', e.target.value);
        if (e.target.value === 'auto') {
            this.setState({
                timeBtn: true,
                RadioValue: e.target.value,
                visibleTime: true,
            });
        } else {
            this.setState({
                timeBtn: false,
                RadioValue: e.target.value,
                visibleTime: false,
            });
        }
    };
    checkTag = (Arr) => {
        Arr.map((text) => {
            if (this.state.Tags.indexOf(text) === -1) {
                Modal.confirm({
                    title: "消息提示",
                    content: "是否添加标签" + text + "到标签管理?",
                    onOk() {
                        Socket.ExecuteAction('AddTag', { tagClass: 'task', tagName: text }, "/AddTag",
                            () => { message.success('标签新增成功'); }
                        );
                    }
                })
            }
        });
    };
    render() {
        const { TaskType, flow_data, outputList, templateData,
            cardTitle, task, Tags, pageSpinning } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        return (
            <div className="newTaskDiv">
                <Spin spinning={pageSpinning}>
                    <CustomBreadcrumb arr={["自定义任务", { title: "模板管理", to: '/task' }, cardTitle]} />
                    <Card title={<span style={{ fontWeight: 600 }}>{cardTitle}</span>} extra={
                        <Button type="primary" icon="rollback" onClick={() => { this.goBackPage(); }}>返回</Button>
                    }>
                        <Form  >
                            <Form.Item
                                label="模板名称"
                                {...formItemLayout}
                                extra={<span style={{ color: "red" }}>提示：任务名和模板名不能重复。建议命名规范：“模版-cisco-巡检-获取基本信息”</span>}
                            >
                                {getFieldDecorator('taskName', {
                                    initialValue: task ? task.taskName : "",
                                    rules: [{
                                        type: 'string', message: '请输入正确字符！',
                                    }, {
                                        pattern: new RegExp(/^[^\s]+$/, "g"), message: '含有空格等非法字符！',
                                    }, {
                                        required: true, message: '请输入字符!',
                                    }],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item
                                label="模板描述"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('taskDes', {
                                    initialValue: task ? task.taskDes : "",
                                    rules: [{
                                        type: 'string', message: '请输入正确字符！',
                                    }, {
                                        // required: true, message: '请输入字符!',
                                    }],
                                })(<TextArea style={{ height: 100 }} />)}
                            </Form.Item>
                            <Form.Item
                                label="添加标签"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('tags', {
                                    initialValue: task ? task.tags : [],
                                })(
                                    <Select
                                        mode="tags"
                                        placeholder="选择标签"
                                        onChange={this.checkTag}
                                    >
                                        {Tags.map(owner => {
                                            return <Option key={owner} value={owner}>
                                                {owner}
                                            </Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item
                                label="模板类型"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('reportTags', {
                                    initialValue: task ? task.reportTags : "",
                                    rules: [{
                                        required: true, message: '请选择!',
                                    }],
                                })(
                                    <Select
                                        placeholder="模板类型"
                                        dropdownRender={menu => (
                                            <div id="selectDiv">
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div
                                                    style={{ padding: '4px 8px', cursor: 'pointer' }}
                                                    onMouseDown={e => e.preventDefault()}
                                                    onClick={() => {
                                                        const _this = this;
                                                        let name = null;
                                                        Modal.confirm({
                                                            title: Utils.BoldFont("添加类型"),
                                                            maskClosable: true,
                                                            content: <div> <Divider />
                                                                <Row>
                                                                    <Col span={5}>类型名称:</Col>
                                                                    <Col span={19}><Input onChange={(e) => { name = e.target.value }} placeholder="模板类型" /></Col>
                                                                </Row>
                                                            </div>,
                                                            icon: <Icon type="file-add" />,
                                                            onOk() {
                                                                if (name) { _this.AddTaskType(name); } else { message.error("请填写类型") }

                                                            }
                                                        })
                                                    }}
                                                >
                                                    <Icon type="plus-circle" />&nbsp;添加类型
                                              </div>
                                            </div>
                                        )}
                                    >
                                        {TaskType.map((item, key) => {
                                            return <Option key={key} value={item.name} >
                                                <Icon type="minus-circle" onClick={() => {
                                                    const _this = this;
                                                    Modal.confirm({
                                                        title: '消息提示',
                                                        content: '你确定删除模板类型' + item.name + "吗?",
                                                        okType: 'danger',
                                                        onOk() {
                                                            _this.delTaskType(item.name);
                                                        },
                                                    });
                                                }} />&nbsp;{item.name}
                                            </Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item
                                label="报告模板"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('form_template_id', {
                                    initialValue: task ? task.form_template_id : "",
                                })(
                                    <Select
                                        placeholder="模板类型">
                                        {templateData.map((item, key) => (
                                            <Select.Option key={key} value={item._id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>)}
                            </Form.Item>
                            <Form.Item
                                label="输入参数"
                                {...{
                                    labelCol: {
                                        xs: { span: 24 },
                                        sm: { span: 6 },
                                    },
                                    wrapperCol: {
                                        xs: { span: 24 },
                                        sm: { span: 20 },
                                        push: 2
                                    },
                                }}
                            >
                                {getFieldDecorator('input', {
                                    initialValue: task ? task.input : [],
                                    rules: [{ required: true, message: '请输入输入参数!', }],
                                })(<TableForm />)}
                            </Form.Item>
                            <Form.Item
                                label="输出参数"
                                {...{
                                    labelCol: {
                                        xs: { span: 24 },
                                        sm: { span: 6 },
                                    },
                                    wrapperCol: {
                                        xs: { span: 24 },
                                        sm: { span: 20 },
                                        push: 2
                                    },
                                }}
                            >
                                {getFieldDecorator('output', {
                                    initialValue: task ? task.output : [],
                                })(<OutPutCheck plainOptions={outputList} reloadParam={() => {
                                    const { flow_data } = this.state;
                                    const nodes = flow_data && flow_data.nodes || [];

                                    let _outputList = [];
                                    for (let key in nodes) {
                                        if (nodes[key].taskdata && nodes[key].taskdata.output) {
                                            const output = nodes[key].taskdata.output//[{}]
                                            console.log(output)
                                            _outputList = Utils.MergeArray(_outputList, output);
                                        }
                                    }
                                    _outputList = _outputList.map((row) => { return row.name })
                                    _outputList = [...new Set(_outputList)]
                                    console.log(_outputList)
                                    this.setState({ outputList: _outputList })
                                }} addParam={() => {
                                    // let valPar = "";
                                    // const _this = this;
                                    // let outputList = this.state.outputList;
                                    // Modal.confirm({
                                    //     title: "新增输出参数",
                                    //     content: <Input placeholder="输入输出参数" onChange={(e) => { valPar = e.target.value }} />,
                                    //     onOk() {
                                    //         // outputList.push(valPar);
                                    //         _this.setState({ outputList: [...outputList, valPar] })
                                    //     }
                                    // })
                                }} />)}
                            </Form.Item>
                            {/* <Form.Item
                                label="输出参数"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('output', {
                                    initialValue: task ? task.output : [],
                                    // rules: [{ required: true, message: '请选择输出参数!', }],
                                })(<Select placeholder="输出参数"
                                    mode="tags"
                                    onMouseEnter={() => {
                                        const { flow_data } = this.state;
                                        const nodes = flow_data && flow_data.nodes || [];
                                        let outputList = [];
                                        for (let key in nodes) {
                                            if (nodes[key].taskdata && nodes[key].taskdata.output) {
                                                const output = nodes[key].taskdata.output//[{}]
                                                outputList = Utils.MergeArray(outputList, output);
                                            }
                                        }
                                        this.setState({ outputList })
                                    }}
                                    dropdownRender={menu => (
                                        <div id="mselectDiv">
                                            {menu}
                                            <Divider style={{ margin: '0px 0' }} />
                                            <div
                                                style={{ padding: '4px 10px', cursor: 'pointer' }}
                                                onMouseDown={e => e.preventDefault()}
                                                onClick={() => {
                                                    _this.props.form.setFieldsValue({ output: outputList.map((a) => (a.name)) })
                                                }}
                                            >
                                                <Icon type="check-circle" />&nbsp;全选
                                      </div>
                                        </div>
                                    )}
                                >
                                    {outputList.map((item, key) => (
                                        // <Option key={key} value={key}>
                                        <Option key={key} value={item.name}>
                                            {item.display}
                                        </Option>
                                    ))}
                                </Select>)}
                            </Form.Item> */}

                            <FlowChar
                                onRef={(_this) => { this.child = _this }}
                                initValue={flow_data}
                                onFlowDataChange={(data) => {
                                    console.log(data);
                                    this.setState({ flow_data: data })
                                }}
                            />
                            <Form.Item
                                label=" "
                                colon={false}
                                {...formItemLayout}
                            >
                                <div style={{ textAlign: "center" }}>
                                    <Button type="primary" icon="check-circle" htmlType="submit" style={{ width: 150 }}
                                        onClick={() => {
                                            let data = { type: 'export' };
                                            this.child.sendToIframe(data);
                                            const _this = this;
                                            setTimeout(() => {
                                                _this.handleSubmit();
                                            }, 100);
                                        }}
                                    >
                                        保存
                                    </Button>&nbsp;
                                    <Button type="danger" icon="close-circle" style={{ width: 150, }}
                                        onClick={() => { this.goBackPage(); }}>
                                        取消
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                        {/* {task && FixedBtn.includes("/createApp") ? <GenApp taskInfo={{ taskId: task._id, taskName: task.taskName }} /> : ''} */}

                        <Modal
                            title="时间计划"
                            visible={this.state.visibleTime}
                            footer={null}
                            onCancel={() => { this.setState({ visibleTime: false, }); }}
                        >
                            <TimePlan initValue={task && task.taskPlan.type === 'auto' ? task.taskPlan.content : null}
                                onParamChange={(data) => { this.onParamChange(data) }} />
                        </Modal>
                    </Card>
                </Spin>
            </div >
        );
    }
}
const newTaskFrom = Form.create()(newTask);


export default newTaskFrom;
