import React from 'react'
import { Card, Table, Button, message, Popconfirm, Icon, notification, Input, Tag } from "antd";
import Socket from "../../../socket/index";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import Utils from '@/utils/utils';
let FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
export default class Task extends React.Component {
    state = {
        filteredInfo: null,
        sortedInfo: null,
        loading: false,
        _pageSize: 10,
        _current: 1
    };
    componentWillMount() {
        FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
        this.updataRoles();
    }
    updataRoles = (pageSize, pageNum, querys) => {
        let { _pageSize, _current } = this.state;
        this.setState({ loading: true, });
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        Socket.QueryDataByPage('QueryRolesPage',
            bodyJson, (result) => {
                if (result.retcode === '00') {
                    let Roles = result.roles;
                    Roles.map((item, index) => {
                        item.key = index;
                        item.index = index;
                        return item.id
                    });
                    this.setState({
                        Roles, loading: false,
                        _current: pageNum || 1,
                        _pageSize: pageSize || _pageSize,
                        total: result.total,
                    })
                } else {
                    notification.open({
                        message: '查询失败',
                        description: result.error,
                        icon: <Icon type="frown" style={{ color: 'red' }} />,
                    });
                }
            }, "/queryRole");
    };
    setOperations = (operations) => {
        console.log('setOperations--' + operations);
        this.setState({
            filteredInfo: {
                deviceType: operations,
            },
        });
    };
    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };
    confirm = () => {
        this.setState({ visible: false });
        message.success('删除成功');
    };
    DeleteRole = (RoleId) => {
        let thisTemp = this;
        Socket.Action("DeleteRole",
            { _id: RoleId }, (result) => {
                console.log(result);
                if (result.retcode === '00') {
                    message.success('删除成功');
                    thisTemp.updataRoles();
                } else {
                    Utils.showMessage("error", "删除失败", result.error)
                }
            }, "/delRole");
        this.setState({ visible: false });
    };
    cancel = () => {
        this.setState({ visible: false });
        message.error('删除取消');
    };

    handleVisibleChange = (visible) => {
        if (!visible) {
            this.setState({ visible });
            return;
        }
        // Determining condition before show the popconfirm.
        console.log(this.state.condition);
        if (this.state.condition) {
            this.confirm(); // next step
        } else {
            this.setState({ visible }); // show the popconfirm
        }
    }
    render() {
        let { Roles, _current, _pageSize, loading, querys } = this.state;
        const columns = [
            {
                title: '序号',
                align: 'center',
                width: 50,
                dataIndex: 'index',
                render: (text) => { return <span>{text + 1 + (_current - 1) * _pageSize}</span> }
            }, {
                title: '角色名称',
                align: 'center',
                dataIndex: 'roleName'
            }, {
                title: '角色描述',
                align: 'center',
                dataIndex: 'roleDes',

            }, {
                title: '创建时间',
                align: 'center',
                dataIndex: 'createTime',
                ellipsis: true,
            }, {
                title: '创建用户',
                align: 'center',
                dataIndex: 'createUserId',
                ellipsis: true,
            }, {
                title: '操作',
                width: '200px',
                align: 'center',
                render: (text, item) => {
                    return <div>
                        <Button type="primary" size="small" onClick={() => {
                            this.props.history.push(`/newRole:${item._id}`);
                        }}><Icon type="form" />编辑</Button>&nbsp;
                        <Popconfirm
                            title="确定删除吗?"
                            onVisibleChange={this.handleVisibleChange}
                            onConfirm={() => { this.DeleteRole(item._id) }}
                            onCancel={this.cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" size="small"
                                icon='delete'>删除</Button>
                        </Popconfirm>
                    </div>
                }
            }];

        return (
            <div>
                <CustomBreadcrumb arr={["系统管理", "角色管理"]} />
                <Card>
                    <FilterForm
                        tagClass="role"
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updataRoles(this.state._pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updataRoles(this.state._pageSize, 1, {})
                        }}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>角色列表</span>}
                    style={{ marginTop: 8 }}
                    extra={true ?
                        <Button type="primary" icon="edit" style={{ marginLeft: 8 }}
                            onClick={() => { this.props.history.push('/newRole'); }}>
                            新建角色
                          </Button> : ''}>
                    <Table
                        dataSource={Roles}
                        columns={columns}
                        size="small"
                        loading={loading}
                        onChange={() => this.handleChange}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.updataRoles(size, 1, querys)
                            },
                            onChange: (page, pageSize) => {
                                this.updataRoles(pageSize, page, querys);
                            }
                        }}
                    />
                </Card>
            </div>
        )
    }
}
