

import React from 'react'
import {
    Card, Table, Button, Icon, Input, message, notification, Select, Descriptions, Row, Col, Badge, Steps,
    Popconfirm, Tag, Modal, Upload, Spin,
} from "antd";
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import * as XLSX from 'xlsx';
const { Dragger } = Upload;
const { Step } = Steps;
const { confirm } = Modal;
export default class FileModal extends React.Component {
    state = {
        FileVis: false,
        current: 0,
        excelData: [],
    };
    componentWillReceiveProps(props) {
        this.setState({ FileVis: props.FileVis })
    }
    uploadFilesChange = (file) => {
        if (Utils.isEmpty(file.fileList)) {
            return;
        }
        console.log(file)
        let _this = this;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(file.file);
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = {};
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    let tempData = [];
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data[sheet] = tempData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    }
                }
                const excelData = data;
                console.log(excelData);
                _this.setState({ excelData, fileName: file.file.name });
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                console.log(e);
                message.error('文件类型不正确！');
            }
        }

    }
    onSumit() {
        const { excelData, fileName } = this.state
        const data = Object.values(excelData)[0];
        this.props.onOk(data, fileName);

    }
    exportExcel() {
        const { template, } = this.props;
        Utils.exportSheetExcel(template.data, template.sheetName, template.name);
    }
    render() {
        let { FileVis, current } = this.state;
        const { title, loading } = this.props;
        return (
            <Modal
                title={<div>{title}&nbsp;&nbsp;&nbsp;<Button type="primary" icon="download" onClick={() => this.exportExcel()}>下载模板</Button></div>}
                visible={FileVis}
                onCancel={() => this.setState({ FileVis: false, InfoBack: "" })}
                onOk={() => this.onSumit()}
                afterClose={() => {
                    this.props.onCancel();
                    this.setState({ current: 0 })
                }}
                okText="确定"
                destroyOnClose
            ><Spin spinning={loading}>
                    <Dragger name="file"
                        accept=".xlsx,.xls,.csv"
                        beforeUpload={function () {
                            return false;
                        }} onChange={this.uploadFilesChange.bind(this)}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">单击或将文件拖到该区域上传</p>
                    </Dragger>
                </Spin>
            </Modal>

        )
    }
}
