import React, { useEffect, useState, Fragment } from 'react';
import ReactDOM from "react-dom";
import { Input, Row, Col, Button, Form, Icon, message, Spin, Card, Select, Switch } from 'antd';
import AxiosObj from "../../../axios";
import { Redirect } from 'react-router-dom';
// import { hashHistory } from "react-router-dom"
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
// import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
// import "./index.css"
// const rules = [{ required: true }];
// const fields = ["a"];
// function TreeCom() {
//     const [address, setAddress] = useState("http://localhost:50033/ui");
//     const [sendData, setSendData] = useState("{}");
//     const [backData, setBackData] = useState("");
//     const [action, setAction] = useState("");
//     const [fields, setFields] = useState();
//
//     useEffect(() => {//componentDidMount
//         console.log('componentDidMount')
//         return () => { //componentWillUnmount
//             console.log('componentWillUnmount')
//         }
//     }, []);
//     const rules = [{ required: true }];
//
//     const DynamicFieldSet = () => {
//         const onFinish = values => {
//             console.log("Received values of form:", values);
//         };
//
//         return (
//             <Form onFinish={onFinish} className="my-form">
//                 <Form.List name="users">
//                     {(fields, { add, remove }) => {
//                         return (
//                             <div>
//                                 {fields.map((field, index) => (
//                                     <Row key={field.key}>
//                                         <Col>
//                                             <Form.Item
//                                                 name={[field.name, "lastName"]}
//                                                 fieldKey={[field.fieldKey, "lastName"]}
//                                                 rules={rules}
//                                             >
//                                                 <Input placeholder="last name" />
//                                             </Form.Item>
//                                         </Col>
//                                         <Col>
//                                             <Form.Item
//                                                 name={[field.name, "firstName"]}
//                                                 fieldKey={[field.fieldKey, "firstName"]}
//                                                 rules={rules}
//                                             >
//                                                 <Input placeholder="first name" />
//                                             </Form.Item>
//                                         </Col>
//                                         <Col flex="none">
//                                             <Button
//                                                 icon="d"
//                                                 className="dynamic-delete-button"
//                                                 onClick={() => {
//                                                     remove(field.name);
//                                                 }}
//                                             />
//                                         </Col>
//                                     </Row>
//                                 ))}
//                                 <Form.Item>
//                                     <Button
//                                         type="dashed"
//                                         onClick={() => {
//                                             add();
//
//                                         }}
//                                         style={{ width: "100%" }}
//                                     >
//                                         {/* <PlusOutlined /> */}
//                                         Add field
//                                     </Button>
//                                 </Form.Item>
//                             </div>
//                         );
//                     }}
//                 </Form.List>
//
//                 <Form.Item>
//                     <Button type="primary" htmlType="submit">
//                         Submit
//                     </Button>
//                 </Form.Item>
//             </Form>
//         );
//     };
//
//     return <div style={style2.div}>
//         {DynamicFieldSet()}
//     </div>
// }
// const style2 = {
//     textAreaSty: { height: "600px" },
//     input: { width: 350 },
//     div: { padding: 20 }
// }
// export default TreeCom

class editSnmpForm extends React.Component {
    state = {
        // parentKey : '',  //代表设备类型
        // index : '',      //代表监控指标
        display: false,
        describe: '',
        loading: false
    }
    //调用父组件函数来改变页面提交后的显示（使消失）
    change = () => {
        this.props.callback();
    }
    // componentWillMount(){
    //     this.getData();
    // }
    // //获取显示数据  一级目录设备类型不显示2级目录加监控指标
    // getData = ()=>{
    //     let {parentKey,index, display, describe } = this.state;
    //     let selectNode = this.props.selectNode;
    //     console.log("传入数据",selectNode)
    //     if (selectNode.parentKey==""){
    //         parentKey =selectNode.title;
    //     }else{
    //         parentKey = selectNode.parentKey;
    //         index = selectNode.title;
    //         // display = selectNode.  ;
    //         // describe = ;
    //
    //     }
    //     this.setState({
    //         parentKey : parentKey,
    //         index : index,
    //         display : display,
    //         describe : describe,
    //     })
    // }
    //添加保存策略  //this.props.form.validateFieldsAndScroll
    //更新监控指标，向后台发起更新请求
    updateConfigData = (newTreeData) => {
        //console.log("newTreeData",newTreeData);
        //后台数据样式组合
        let data = {};
        data['modules'] = {}
        for (let i in newTreeData) {
            let module = newTreeData[i].title;
            // let modules ={}
            let walk = [];
            for (let j in newTreeData[i].children) {
                let childrenMap = {};
                childrenMap['index'] = newTreeData[i].children[j].title;
                childrenMap['display'] = newTreeData[i].children[j].display;
                childrenMap['describe'] = newTreeData[i].children[j].describe;
                walk.push(childrenMap)
            }
            let tmpWalk = {};
            tmpWalk['walk'] = walk;
            // modules[module] = tmpWalk;
            data["modules"][module] = tmpWalk;
        }
        this.setState({
            TreeData: newTreeData
        })
        //console.log('发送数据',data)
        this.setState({ loading: true });
        AxiosObj.HTTP_POST('alarm', "/snmpexporter/uploadConfig", data, (value) => {
            this.setState({ loading: false });
            this.change();
            // hashHistory.push({pathname : '/monitoringAlarm/snmpMonitor'});
            // this.props.history.push('/monitoringAlarm/snmpMonitor');
            message.success('success');
            //this.forceUpdate();
            //this.getTreeData()
            //this.props.history.goBack();
        }, (error) => {
            this.setState({ loading: false });
            this.change()
            message.error(error);
        })

    };
    handleSubmit = (e) => {
        let treeData = this.props.treeData;
        //console.log("传入值",this.props.selectNode,'treeData',this.props.treeData)
        e.preventDefault(); //阻止表单提交时更新页面
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                //console.log('表单值',values)
                for (let i in treeData) {
                    for (let j in treeData[i].children) {
                        // console.log('name',values.name,'title',treeData[i].title)
                        // console.log(values.name == treeData[i].title ,values.index==treeData[i].children[j].index)
                        // console.log('index',values.index, 'index',treeData[i].children[j].title)
                        if (values.name == treeData[i].title && values.index == treeData[i].children[j].title) {
                            if (values.display) {
                                treeData[i].children[j].display = 'yes';
                            } else {
                                treeData[i].children[j].display = 'no';
                            }
                            treeData[i].children[j].describe = values.describe;
                            break;
                        }
                    }
                }
                this.updateConfigData(treeData)
                // let map ={}
                // map["name"] = values.name;
                // map["hours"] = values.num + values.unit;
                // map["default"] = values.default.toString();
                // console.log(map)
                // AxiosObj.HTTP_POST('alarm',"/snmpexporter/addPolicy",map,(data)=>{
                //     message.success('success');
                //     this.props.history.goBack();
                // },(error)=>{
                //     message.error(error);
                // })
            }
        },
        )
    }
    //动态组件（新增时动态的添加指标组件）

    render() {
        let { loading } = this.state;
        const { getFieldDecorator } = this.props.form;
        const dataMap = () => {
            let selectNode = this.props.selectNode;
            //console.log(selectNode)
            let data = {}
            if (selectNode.parentKey == "") {
                data['parentKey'] = selectNode.title;
                data['index'] = '';
            } else {
                data['parentKey'] = selectNode.parentKey;
                data['index'] = selectNode.title;
                data['describe'] = selectNode.describe;
                if (selectNode.display == "yes") {
                    data['display'] = true;
                }
            }
            console.log('data', data)
            return data

        }
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };

        return (
            <div>
                <Spin spinning={loading}>
                    <Card
                        style={{ width: '100%', marginTop: 0, border: 0 }}
                    // onTabChange={key => {
                    //     this.onTabChange(key, 'key');
                    // }}
                    >
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item
                                label="设备类型"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('name', {

                                    initialValue: dataMap().parentKey || "",
                                    rules: [{
                                        type: 'string', message: '请输入正确字符！',
                                    }, {
                                        pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                    }, {
                                        required: true, message: '请输入字符!',
                                    }],
                                })(<Input disabled={true} />)}
                            </Form.Item>
                            <Form.Item
                                label="监控指标"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('index', {
                                    initialValue: dataMap().index || "",
                                    rules: [{
                                        type: 'string', message: '请输入正确字符！',
                                    }, {
                                        pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                    }, {
                                        required: true, message: '请输入字符!',
                                    }],
                                })(<Input disabled={true} />)}
                            </Form.Item>
                            <Form.Item
                                label="图表默认展示"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('display', {
                                    initialValue: true,
                                })(
                                    <Switch defaultChecked={dataMap().display} style={{ marginLeft: 5 }} checkedChildren="开" unCheckedChildren="关"
                                        onClick={(value) => {
                                            console.log(value)
                                            //setTimeout(console.log(this.state.switchPersistence),1000)
                                        }}
                                    />
                                )}

                            </Form.Item>
                            <Form.Item
                                label="描述"
                                {...formItemLayout}
                            >
                                <div>
                                    {getFieldDecorator('describe', {
                                        initialValue: dataMap().describe || '',
                                    })(
                                        <Input style={{ width: '100%' }} />
                                    )}
                                </div>

                            </Form.Item>

                            <Form.Item
                                label=" "
                                colon={false}
                                {...formItemLayout}
                            >
                                <div >
                                    <Button type="primary" icon="check-circle" htmlType="submit">
                                        提交修改
                                    </Button>&nbsp;
                                    <Button type="danger" icon="close-circle" onClick={this.change}>
                                        关闭窗口
                                    </Button>
                                </div>
                            </Form.Item>

                        </Form>
                    </Card>
                </Spin>
            </div>
        )
    }
}
export default Form.create()(editSnmpForm);