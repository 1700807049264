import React from 'react'
import { Card, Table, Input, Typography, Popconfirm, Descriptions, Button, Icon, Tabs, Row, Col, message, Tag, Modal, Progress, List, Tooltip } from "antd";
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import AxiosObj from '@/axios/index';
import CustomBreadcrumb from "@/components/CustomBreadcrumb/index";
import $ from "jquery";
import DyHeadTable from '@/components/DyHeadTable/index';
const { Title, Text } = Typography;
const { confirm } = Modal;
export default class ReduPolicy extends React.Component {
    state = {
        s_current: 1,      //当前页码数
        s_pageSize: 20,    //每页显示条数
        s_querys: {},    //查询条件
        deviceInfo: {},
        s_total: 10,
        s_loading: false,
        s_tableData: [],
        jsonKey: ["ip", "policy_analyze_result", "add_time",]
    };
    componentWillMount() {
        const deviceInfo = JSON.parse(sessionStorage.getItem("device"));
        this.setState({ deviceInfo });
        this.updata("redundancyPolicy", null, null, null, deviceInfo.deviceIp);
    }

    animateTab() {
        // const oBox = $('#policyTab')[0];
        const oBox = document.getElementById('policyTab');
        if (!oBox) return;
        console.log(oBox);
        oBox.classList.add("animated");
        oBox.classList.add("fadeIn");
        setTimeout(() => {
            oBox.classList.remove("fadeIn");
        }, 1000);
    }
    updata = (API, pageSize, pageNum, querys, ip) => {


        this.setState({ loading: true })
        let { s_pageSize, s_current, s_total, deviceInfo } = this.state;
        let bodyJson = {};
        bodyJson.page_size = pageSize || s_pageSize;
        bodyJson.page_no = pageNum || s_current;
        bodyJson.ip = ip || deviceInfo.deviceIp;
        AxiosObj.HTTP_POST("fw", API, bodyJson, (result) => {
            let jsonKey = Utils.DelArrayItem(Object.keys(result.data[0]), "_id");
            jsonKey = Utils.DelArrayItem(jsonKey, "policy_group");
            this.setState({
                s_tableData: result.data,
                jsonKey,
                // s_total: result.policy_account,
                loading: false
            })
        });
    };
    modalDetail(titleData, record) {
        confirm({
            content: <Descriptions bordered title="策略详情" size="small"
                layout="vertical"
            >
                {titleData.map((rec) => {
                    const temp = record[rec[0]]
                    return <Descriptions.Item label={rec[1]}>{
                        Array.isArray(temp) ? temp.map((DET, i) => { return <Tag key={i}>{DET}</Tag> }) : temp
                    }</Descriptions.Item>
                })}
            </Descriptions>,
            maskClosable: true,
            width: "60%",
        })
    }
    getContent(titleData, titleData2, record) {
        Modal.info({
            title: <Title level={4}>冗余策略结果详情</Title>,
            content: <div>
                <Text strong>基本信息</Text>
                <Descriptions bordered size="small"
                    layout="vertical"
                >
                    {titleData.map((rec) => {
                        let ele = record[rec[0]];
                        if (Array.isArray(ele)) {
                            ele = <div>
                                {ele.map((it, k) => {
                                    const lab = Object.keys(it)[0]
                                    return <Tag>{lab}</Tag>
                                })}
                            </div>
                        }
                        return <Descriptions.Item label={rec[1]}>{ele}</Descriptions.Item>
                    })}
                </Descriptions>
                <Text strong>策略组</Text>
                <DyHeadTable
                    type="policys"
                    dataSource={record["policy_group"]}
                    columns={[...titleData2.map((item) => {
                        return {
                            title: item[1],
                            align: 'center',
                            width: 150,
                            ellipsis: true,
                            dataIndex: item[0],
                            render: (text, rec, key) => {
                                return <Tooltip placement="topLeft" key={key} mouseLeaveDelay={0.07} title={rec[item[0]]}>{text}</Tooltip>
                            }
                        }
                    }), {
                        title: "操作",
                        width: 150,
                        dataIndex: "op",
                        fixed: "right",
                        render: (text, rec, key) => { return <Button type="primary" onClick={() => { this.modalDetail(titleData2, rec) }}>详情</Button> }
                    }]}
                    size="small"
                />
            </div>,
            maskClosable: true,
            width: "90%",
            okText: "关闭"
        })
    }
    render() {
        let { s_current, s_loading, s_pageSize, s_tableData, s_total, jsonKey, deviceInfo } = this.state;
        const APINAME = [
            ["redundancyPolicy", "冗余策略", "file"],
            ["conflictPolicyGroup", "冲突策略", "file-exclamation"],
            ["noEffectPolicy", "失效策略", "file-unknown"],
            ["noMatchedPolicy", "未命中策略", "file-text"],
        ]
        const titleData = [
            ["policy_analyze_result", "策略分析结果"],
            ["add_time", "策略添加时间"],
            // ["policy_group", "策略组"],
        ];
        const titleData2 = [
            ["rule_name", "策略名称"],
            ["policy_matched", "策略命中数"],
            ["description", "策略描述"],
            ["source_zone", "源区域"],
            ["destination_zone", "目的区域"],
            ["source_address", "源地址"],
            ["destination_address", "目的地址"],
            ["application", "策略开通应用"],
            ["action", "策略开通权限"],
            ["service", "策略开通的服务名称"],
            ["time_range", "策略开通时间段名称"],
            ["time_range_details", "策略开通时间段详情"],
            ["service_details", "策略开通服务详情"],
        ];
        const columnsDev = [...jsonKey.map((item) => {
            return {
                title: Utils.Translation(item),
                align: 'center',
                // width: 150,
                ellipsis: true,
                dataIndex: item,
                render: (text, rec, key) => {
                    // let temp = text;
                    // console.log(text)
                    // const policy_analyze_result = text;

                    // if (policy_analyze_result.indexOf("未命中") != -1) {
                    //     temp = <Tag color="red">未命中策略</Tag>
                    // } else if (policy_analyze_result.indexOf("冗余") != -1) {
                    //     temp = <Tag color="cyan">冗余策略</Tag>;;
                    // } else if (policy_analyze_result.indexOf("冲突") != -1) {
                    //     temp = <Tag color="orange">冲突策略</Tag>;;
                    // } else if (policy_analyze_result.indexOf("失效") != -1) {
                    //     temp = <Tag color="geekblue">失效策略</Tag>;
                    // }
                    // return <Tooltip placement="topLeft" key={key} mouseLeaveDelay={0.07} title={text}>{temp}</Tooltip>

                    let resultEle = text;
                    if (item === "policy_analyze_result") {
                        let policy_analyze_result = {};
                        if (Array.isArray(text)) {
                            policy_analyze_result = text;
                        } else {
                            policy_analyze_result[text] = text;
                            policy_analyze_result = [policy_analyze_result];
                        }
                        resultEle = <div>
                            {policy_analyze_result.map((record, k) => {
                                const label = Object.keys(record)[0];
                                let color = "geekblue";
                                if (label.indexOf("未命中") != -1) {
                                    color = "red"
                                } else if (label.indexOf("冗余") != -1) {
                                    color = "#108ee9"
                                } else if (label.indexOf("冲突") != -1) {
                                    color = "#f50"
                                }
                                return <Tooltip placement="topLeft" key={k} mouseLeaveDelay={0.07} title={record[label]}>
                                    <Tag style={{ cursor: "pointer" }} color={color} >{label}</Tag>
                                </Tooltip>
                            })}
                        </div>;
                    }
                    return resultEle
                }
            }
        }), {
            title: "操作",
            dataIndex: "op",
            render: (text, item, index) => {
                return <Button type="primary" key={index}
                    onClick={() => {
                        if (jsonKey.length > 6) {
                            this.modalDetail(titleData2, item)
                        } else {
                            this.getContent(titleData, titleData2, item)
                        }
                    }}>
                    <Icon type="search" />查看详情
            </Button>
            }
        }
        ];
        const params = [
            ["防火墙IP", "deviceIp"],
            ["设备厂商", "deviceFirm"],
            ["SNMP口令 ", "community"],
            ["设备名称", "deviceId"],
            ["设备类型", "deviceType"],
            ["设备型号", "deviceVersion"],
            ["设备OS版本", "deviceOsVersion"],
            ["网点信息", "deviceSite"],
        ];
        return (
            <div className="home-body">
                <CustomBreadcrumb arr={["运维管理", "防火墙", { title: "防火墙列表", to: "/fireWall" }, "策略分析结果"]} />
                <Card title={<span style={{ fontWeight: 600 }}>防火墙策略分析结果</span>}
                    extra={<Button type="primary" icon={'rollback'}
                        onClick={() => { this.props.history.goBack(); }}>返回防火墙列表</Button>}
                    className="Card-task"><Text strong>设备基本信息</Text>
                    <Descriptions bordered size="small">
                        {params.map((rec, m) => {
                            return <Descriptions.Item key={m} label={rec[0]}>{deviceInfo[rec[1]]}</Descriptions.Item>
                        })}
                    </Descriptions><br />
                    <Tabs type="card" onChange={(key) => { this.animateTab(); this.updata(key, s_pageSize, 1, {}); }}>
                        {
                            APINAME.map((apiItem, key) => {
                                console.log(apiItem)
                                return <Tabs.TabPane
                                    tab={<span>
                                        <Icon type={apiItem[2]} />
                                        {apiItem[1]}
                                    </span>}
                                    key={apiItem[0]}
                                >
                                </Tabs.TabPane>
                            })
                        }

                    </Tabs>
                    <Table
                        id="policyTab"
                        // className="animated slideInRight"
                        dataSource={s_tableData}
                        columns={columnsDev}
                        size="small"
                        loading={s_loading}
                        pagination={{
                            current: s_current, pageSize: s_pageSize, total: s_total,
                            onChange: (page, pageSize) => { this.updata(pageSize, page, s_querys); }
                        }}
                        onRow={record => {
                            console.log(record)
                            return {
                                onDoubleClick: event => {
                                    if (jsonKey.length > 6) {
                                        this.modalDetail(titleData2, record)
                                    } else {
                                        this.getContent(titleData, titleData2, record)
                                    }
                                }, // 点击行
                            };
                        }}
                    />
                    {/* <Text type="secondary">备注:双击行可查看详情~</Text> */}
                </Card>
            </div>
        );
    }
} 