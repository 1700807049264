import React from 'react'
import {
    Card, Table, Input, Button, Icon, message, Modal, Menu, Dropdown,
    Spin, Tag, Divider
} from "antd";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import AxiosObj from '@/axios/index';
import Socket from "@/socket/index";
import NewSemForm from '../maintain/autoFind/newSemForm';
import { connect } from 'react-redux'
// import './index.scss';
let IntervalSta = null;
class DumbAutoFind extends React.Component {
    state = {
        s_current: 1,      //当前页码数
        s_pageSize: 20,    //每页显示条数
        s_querys: {},    //查询条件
        s_total: 0,
        s_loading: false,
        newSemVis: false,
        topoVis: false,
        uploadVis: false,
        s_tableData: [],
        selectedRows: [],
        topoData: [],
        dep_ids: [],
    }
    componentWillMount() {
        let bodyJson = this.props.location.bodyJson || {};
        this.getDepIds();
        // this.runGetSem();
    }
    componentWillUnmount() {
        clearInterval(IntervalSta);
    }
    getDepIds = () => {
        Socket.ExecuteAction('QueryArea', {}, "", (result) => {
            const TreeData = result.data;
            let dep_ids = this.dealTreeData(TreeData);
            this.setState({ dep_ids });
            this.getSement();
            this.runGetSem();
        });
    };
    dealTreeData(data) {
        let that = this;
        let dep_ids = [];
        if(Array.isArray(data)) {
            data.map((item) => {
                let children = item.props ? item.props.children : item.children
                if(children && children.length > 0) {
                    dep_ids = dep_ids.concat(that.dealTreeData(children));
                } else {
                    dep_ids.push(item.key || item.eventKey)
                }
            })
        }
        return dep_ids
    }
    getSement(pageSize, pageNum) {
        const { s_pageSize, s_current, dep_ids } = this.state;
        const { dispatch } = this.props;
        let bodyJson = {
            page_size: pageSize || s_pageSize,
            page_no: pageNum || s_current,
            tag: '哑终端',
            dep_ids: dep_ids,
        };
        // dispatch(isLoading(true));
        AxiosObj.HTTP_POST("topology", "pingStatusRecords", bodyJson, (result) => {
            // console.log(result)
            if (result.code === "00") {
                this.setState({
                    s_tableData: result.data,
                    s_total: result.count,
                    s_current: bodyJson.page_no,
                });
            }
            // const flag = result.data.every((item) => { return item.status === "完成" });
            if (result.scan_process_num === 0) {//都完成
                clearInterval(IntervalSta);
            } else {
                this.runGetSem()
            }
            // dispatch(isLoading(false));
        })
    }
    FindDevice(val) {
        const { dispatch } = this.props;
        const _this = this;
        console.log(val)
        // dispatch(isLoading(true));
        this.runGetSem();
        AxiosObj.HTTP_POST("topology", "getAllPingIps", val, (result) => {
            message.success(result.msg)
            this.getSement();
            clearInterval(IntervalSta);
        });
        // 
        setTimeout(() => {
            _this.getSement();
        }, 600)
    }
    addSement(val) {
        AxiosObj.HTTP_POST("topology", "addTopologyNet", val, (result) => {
            message.success(result.msg)
            this.setState({ newSemVis: false });
            this.getSement();
        });
    }
    runGetSem() {
        const _this = this;
        if(IntervalSta) {
            clearInterval(IntervalSta);
        }
        IntervalSta = setInterval(() => {
            _this.getSement();
        }, 5000);
    }
    goDetail(item) {
        let path = {
            pathname: `/autoFind/findDevice`,
            prevPathName: this.props.location.pathname,
            prevName: "哑终端自动发现",
        };
        sessionStorage.setItem("segment", JSON.stringify(item))
        sessionStorage.setItem("prevPathName", this.props.location.pathname)
        sessionStorage.setItem("prevName", path.prevName)
        this.props.history.push(path);
    }
    getMixTopo() {
        const { selectedRows } = this.state;
        AxiosObj.HTTP_POST("topology", "topologyMerger", selectedRows, (result) => {
            this.setState({ topoVis: true, topoData: result.data });
        });
    }
    delMask(val) {
        AxiosObj.HTTP_POST("topology", "delTopologyByNet", val, (result) => {
            message.success(result.msg)
            this.getSement();
        });
    }
    handleMenuClick(menu, resItem) {
        const { _current, _pageSize, querys } = this.state
        let _this = this;
        // let bodyJson = {
        //     pageNum: _current,      //当前页码数
        //     pageSize: _pageSize,    //每页显示条数
        //     querys,    //查询条件
        //     ip: resItem.resourceParams.deviceIp
        // }
        if (menu.key === "detail") {
            this.goDetail(resItem);
        } else if (menu.key === "action") {
            Modal.confirm({
                title: "提示",
                content: "是否开始扫描该网段?",
                onOk() {
                    _this.FindDevice({
                        net_mask: resItem.net_mask,
                        area: resItem.area,
                        community: resItem.community,
                        tags: resItem.tags,
                        dep_id: resItem.dep_id
                    })
                }
            })
        } else if (menu.key === "del") {
            Modal.confirm({
                title: "提示",
                content: "是否删除该网段?",
                onOk() {
                    _this.delMask({
                        net_mask: resItem.net_mask,
                        area: resItem.area,
                    })
                }
            })
        }
    }
    menuButton(resItem) {
        return <Menu onClick={(e) => this.handleMenuClick(e, resItem)}>
            <Menu.Item key="detail">
                <Icon type="edit" />
                详情
      </Menu.Item>
            <Menu.Item key="del">
                <Icon type="delete" />
                删除
      </Menu.Item>
        </Menu>
    }
    scanRecordsByNet(net_mask) {
        let { dep_ids } = this.state;
        AxiosObj.HTTP_POST("topology", "scanRecordsByNet", { net_mask, tag: '哑终端', dep_ids: dep_ids, }, (result) => {
            this.setState({
                s_tableData: result.data,
                s_total: result.data.length,
                s_current: 1,
            });
        });
    }
    render() {
        const { s_tableData, s_current, s_pageSize, s_querys, s_total, s_loading, newSemVis, topoVis, selectedRows, topoData, uploadVis } = this.state;
        const columns = [{
            title: '序号',
            width: 50,
            render: (text, item, index) => {
                return <span>{index + 1 + (s_current - 1) * s_pageSize}</span>
            }
        }, {
            title: '网段',
            align: 'center',
            dataIndex: "net_mask",
            render: (a, b, c) => {
                return <Button type="link" size="small" onClick={() => { this.goDetail(b) }}>{a}</Button>
            }
        }, {
            title: '发现设备数量',
            align: 'center',
            dataIndex: "device_count",
            render: (a, b, c) => {
                return <div className={"linkButn"} >{a}</div>
            }
        }, {
            title: '区域',
            align: 'center',
            dataIndex: "area",
        }, {
            title: '发现时间',
            align: 'center',
            dataIndex: "create_time",
        }, {
            title: '状态',
            dataIndex: "status",
            render: (a, b, c) => {
                return a === "完成" ? <Tag color="green">{a}</Tag> : (
                    a === "未开始" ? <Tag color="blue">{a}</Tag> :
                        (a === "ping数据为空" ? <Tag>{a}</Tag> :
                            <div className="state" ><Spin spinning={true} />&nbsp;{a}</div>)
                )
            }
        }, {
            title: '耗时',
            align: 'center',
            dataIndex: "topology_consume_time",
        }, {
            title: '操作',
            align: 'center',
            width: 110,
            render: (text, item, index) => {
                return <div>
                    <Dropdown.Button overlay={this.menuButton(item)}
                        type="primary" icon={<Icon type="down" />} size="small"
                        onClick={() => { this.handleMenuClick({ key: "action" }, item) }}
                    ><Icon type="search" />扫描
                    </Dropdown.Button>
                </div>
            }
        }];
        return <div className="autoFindDiv">
            <CustomBreadcrumb
                arr={["哑终端管理", { title: "哑终端自动发现", to: "/dumbTerminal/dumbAutoFind" }]}
            />
            <Card className="tableCard" title="哑终端网段管理">
                <div> &nbsp;&nbsp;<strong>请输入网段:&nbsp;</strong>
                    <Input.Search placeholder="根据网段查询" style={{ width: 350 }} allowClear id="search"
                        value={this.state.searchVal || ""}
                        onChange={e => this.setState({ searchVal: e.target.value })}
                        onSearch={value => { this.scanRecordsByNet(value) }} enterButton />&nbsp;
                    <Button icon="reload" style={{ marginRight: "10px" }} onClick={() => {
                        this.setState({ searchVal: "" });
                        this.getSement();
                    }} >重置</Button>&nbsp;
                    <Button type="primary" icon="plus-square" onClick={() => { this.setState({ newSemVis: true }) }}>新建网段</Button>
                </div><p />
                <Table
                    columns={columns}
                    dataSource={s_tableData}
                    rowSelection={{
                        selectedRowKeys: this.state.selectedRowKeys || [],
                        onChange: (selectedRowKeys, selectedRows) => {
                            this.setState({ selectedRowKeys, selectedRows });
                        },
                        selections: [
                            {
                                key: 'cencel',
                                text: '清空选择',
                                onSelect: () => {
                                    this.setState({ selectedRowKeys: [] });
                                },
                            },
                        ],
                    }}
                    rowKey={(row) => row.net_mask}
                    loading={s_loading}
                    pagination={{
                        current: s_current, pageSize: s_pageSize, total: s_total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        onChange: (page, pageSize) => { this.getSement(pageSize, page, s_querys); }
                    }}
                    size="small"
                />
            </Card>
            <Modal
                title="新建网段"
                visible={newSemVis}
                onCancel={(e) => {
                    this.setState({ newSemVis: false });
                }}
                width="70%"
                footer={<div>
                    <Button icon="close" onClick={() => {
                        this.setState({ newSemVis: false })
                    }}>取消</Button>&nbsp;
                    <Button type="primary" icon="plus-square" onClick={() => {
                        this.newForm.props.form.validateFields((err, values) => {
                            if (!err) { this.addSement({...values, dep_id: this.newForm.state.dep_id}) };
                        });
                    }}>确定</Button>&nbsp;
                    <Button type="primary" icon="search" onClick={() => { this.newForm.handleSubmit(); }}>开始扫描</Button>
                </div>}
                className="segmentModal"
                destroyOnClose
            >
                <NewSemForm netTag="哑终端" onRef={(thisForm) => this.newForm = thisForm} onChange={(e) => {
                    this.setState({ newSemVis: false }); this.FindDevice({...e, dep_id: this.newForm.state.dep_id});
                }} />
            </Modal>
        </div>
    }
}
export default connect()(DumbAutoFind)