import React from 'react'
import {
    Card, Table, Button, message, Popconfirm, Icon, Input, Select, Row, Col, Form,
    Modal, Radio, InputNumber, Typography, TimePicker, DatePicker
} from "antd";
import Socket from "../../../socket/index";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import Utils from '@/utils/utils';
import moment from 'moment';
const { Text } = Typography;
const NowTime = new Date();
class EditMenuClass extends React.Component {
    componentWillMount() {
        this.props.onThis(this);
    }
    handleSubmit = e => {
        e.preventDefault();
        let _this = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                _this.props.onSubmit(values);
                _this.props.form.resetFields();
            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { selectNode } = this.props;
        let  backup_type  = this.props.form.getFieldValue('backup_type') || this.props.selectNode.backup_type

        const fromList = [
            { fieId: "ip", label: "设备IP", initialValue: selectNode.ip },
            // { fieId: "os_type", label: "系统类型", initialValue: selectNode.os_type, elemType: "select", list: [{ id: "cisco_ios", name: "cisco_ios" }, { id: "hp_comware", name: "hp_comware" }, { id: "huawei", name: "huawei" }] },
            { fieId: "username", label: "ssh用户名", initialValue: selectNode.username },
            { fieId: "password", label: "ssh登录密码", initialValue: selectNode.password, elemType: "password" },
            // { fieId: "total_time", label: "备份时长(月)", type: "number", initialValue: selectNode.total_time },
            // { fieId: "backup_count", label: "备份次数", type: "number", initialValue: selectNode.backup_count },
            { fieId: "sys_pwd", label: "super密码或者enbale密码", initialValue: selectNode.sys_pwd || selectNode.password, elemType: "password" },
            { fieId: "backup_cycle", label: "自动备份周期", type: "number", initialValue: selectNode.backup_cycle || 1 },
            { fieId: "time_type", label: "周期时间类型", initialValue: selectNode.time_type || 'day', elemType: "select", list: [{ id: "hour", name: "小时" }, { id: "day", name: "天" }, { id: "week", name: "周" }] },
            { fieId: "backup_type", label: "备份类型", initialValue: selectNode.backup_type || 'text', elemType: "select", list: [{ id: "text", name: "text" }, { id: "ftp", name: "ftp" }] },
            // { fieId: "config_name", label: "FTP备份文件名", initialValue: selectNode.config_name || "" },
        ];
        // 只有 backup_type为 ftp的时候才需要填写 config_name
        if(backup_type === 'ftp') {
            fromList.push({ fieId: "config_name", label: "FTP备份文件名", initialValue: selectNode.config_name || "" })
        }
        return <Form labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} onSubmit={this.handleSubmit}>

            {
                fromList.map((Item, key) => {
                    if (Item["elemType"] === "select") {
                        let validateRules = []
                        if (Item["fieId"] === "os_type") {
                            validateRules = [{
                                type: Item["type"] || "string",
                            }]
                        } else {
                            validateRules = [{
                                type: Item["type"] || "string",
                                required: true, message: '请输入字符!',
                            }]
                        }
                        return <Form.Item
                            key={key}
                            label={Item.label}
                            hasFeedback
                        >
                            {getFieldDecorator(Item["fieId"], {
                                initialValue: Item["initialValue"] || "",
                                rules: validateRules,

                            })(<Select style={{ width: 400 }}>
                                {Utils.getOptionList(Item.list)}
                            </Select>)}
                        </Form.Item>
                    } else {
                        return <Form.Item
                            key={key}
                            label={Item.label}
                            hasFeedback
                        >
                            {getFieldDecorator(Item["fieId"], {
                                initialValue: Item["initialValue"] || "",
                                rules: [{
                                    type: Item["type"] || "string",
                                    required: true, message: '请输入字符!',
                                }],
                                getValueFromEvent: (event) => {
                                    // console.log(parseInt(event.target.value))
                                    return Item["type"] === "number" ? parseInt(event.target.value || "0") : event.target.value;
                                },
                            })(
                                // <Input style={{ width: 400 }} type={Item["elemType"] === "password" ? "password" : "text"} />
                                Item["elemType"] === "password" ? <Input.Password style={{ width: 400 }} /> : <Input style={{ width: 400 }} />
                            )}
                        </Form.Item>
                    }


                })
            }
            <Form.Item label={"备份时长(月)"} >
                {getFieldDecorator("total_time", {
                    initialValue: selectNode.total_time || 1,
                    rules: [{
                        // pattern: /^(([1-9])|(1[0-2]))$/,
                        required: true, message: '请选择!',
                    }],
                })(
                    <InputNumber style={{ width: 400 }} max={1000} min={0} />
                )}
            </Form.Item>
            {/* <Form.Item label={"定时备份周期(小时)"} >
                {getFieldDecorator("backup_cycle", {
                    initialValue: selectNode.backup_cycle || 24,
                    rules: [{
                        // pattern: /^(([1-9])|(1[0-2]))$/,
                        required: true, message: '请选择!',
                    }],
                })(
                    <InputNumber min={1} />
                )}
            </Form.Item> */}
            {/* {selectNode.backup_count ? <Form.Item label={"备份次数"}><label >{selectNode.backup_count}</label>
            </Form.Item> : ""} */}
            {/* <Form.Item label={"是否开启定时备份"}>
                {getFieldDecorator("whether_backup", {
                    initialValue: selectNode.whether_backup,
                    rules: [{
                        type: "boolean",

                    }],
                })(<Radio.Group name="radiogroup">
                    <Radio value={true}>是</Radio>
                    <Radio value={false}>否</Radio>
                </Radio.Group>)}
            </Form.Item> */}
            <Form.Item label={"开启时间"} >
                {getFieldDecorator("start_date", {
                    initialValue: moment(selectNode.start_date || NowTime.toLocaleDateString(), "YYYY-MM-DD HH:mm:ss "),
                    rules: [{
                        required: true, message: '请选择!',
                    }],
                })(
                    <DatePicker style={{ width: 400 }} showTime placeholder="选择时间" format="YYYY-MM-DD HH:mm:ss" />
                )}
                {/* &nbsp;&nbsp;&nbsp;<Text disabled>定时备份时间点0~23(默认周一到周五备份)</Text> */}
            </Form.Item>

            <Form.Item label={"备份说明"} hasFeedback>
                {getFieldDecorator("details", {
                    initialValue: selectNode.details || "",
                    rules: [{}],
                })(<Input style={{ height: 50, width: 400 }} />)}
            </Form.Item>
        </Form>
    }

}
const EditMenuObj = Form.create()(EditMenuClass)
export default EditMenuObj