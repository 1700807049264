
import React from 'react';
import { Tree, Input, Icon, Button } from 'antd';
import { Util } from 'bizcharts';
import Utils from "@/utils/utils";
const { TreeNode } = Tree;
const { Search } = Input;
const createToTree = sourData => {
    const portNameList = Object.keys(sourData);
    let treeData = [];
    portNameList.map((port) => {
        let portVal = sourData[port];
        let macList = Object.keys(portVal);
        let mac_ip = [];
        macList.map((mac) => {
            if (mac) {
                let v = mac + "---" + portVal[mac];
                mac_ip.push({ title: v, key: v });
            }
        })
        if (!Utils.isEmpty(mac_ip)) {
            treeData.push({ title: port, key: port, children: mac_ip })
        }
    });
    return treeData;
}
const generateList = data => {
    const dataList = [];
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const { key } = node;
        dataList.push({ key, title: key });
        if (node.children) {
            return generateList(node.children);
        }
    }
    return dataList;
};
const findKeyNewData = (data, val) => {
    let temp = [];
    data.map((obj, key) => {
        if (obj["key"].includes(val)) {
            temp.push(obj);
        } else if (obj["children"]) {
            obj["children"] = findKeyNewData(obj["children"], val);
            if (!Utils.isEmpty(obj["children"])) {
                temp.push(obj);
            }
        }
    })
    return temp;
}
const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some(item => item.key === key)) {
                parentKey = node.key;
            } else if (getParentKey(key, node.children)) {
                parentKey = getParentKey(key, node.children);
            }
        }
    }
    return parentKey;
};

export default class SearchTree extends React.Component {
    state = {
        // sourData: {
        //     "g0/0/1": {
        //         "m2ac41e": "192.12.4.29",
        //         "mw1a3c2": "192.1.41.21",
        //         "mac3eee": "192.14.4.2"
        //     },
        //     "g0/0/2": {
        //         "m4a4c1ee": "192.14.4.20",
        //         "mt5ea3c2": "192.1.4.2",
        //         "mad4ffc3": "191.1.24.22"
        //     },
        // },
        sourData: this.props.treeData,
        expandedKeys: [],
        searchValue: '',
        autoExpandParent: true,
        treeData: [],
        dataList: [],

    };
    componentWillMount() {
        let treeData = createToTree(this.state.sourData);

        let dataList = [];
        const generateList = data => {
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                const { key, title } = node;
                dataList.push({ key, title });
                if (node.children) {
                    generateList(node.children);
                }
            }
        };
        generateList(treeData);
        this.setState({ treeData, dataList });
    }

    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    onChange = value => {
        const { treeData } = this.state
        let expandedKeys = [];
        if (value.trim() != "") {
            expandedKeys = this.state.dataList
                .map(item => {
                    if (item.title.indexOf(value) > -1) {
                        return getParentKey(item.key, treeData);
                    }
                    return null;
                })
                .filter((item, i, self) => item && self.indexOf(item) === i);
        }
        console.log(expandedKeys);
        console.log(this.state.dataList);
        this.setState({
            expandedKeys,
            searchValue: value,
            autoExpandParent: true,
        });
    };
    filterTreeData(value) {
        // const { value } = e.target;

        const { treeData } = this.state;
        let newTree = findKeyNewData(treeData, value);
        console.log(newTree);
        let expandedKeys = [];
        const getAllKey = data => {
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                const { key } = node;
                expandedKeys.push(key);
                if (node.children) {
                    generateList(node.children);
                }
            }
        };
        getAllKey(newTree);

        this.setState({ treeData: newTree, searchValue: value, expandedKeys });
        // this.onChange(value);
    }
    reloadData() {
        let treeData = createToTree(this.state.sourData);
        let dataList = [];
        const generateList = data => {
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                const { key, title } = node;
                dataList.push({ key, title });
                if (node.children) {
                    generateList(node.children);
                }
            }
        };
        generateList(treeData);

        this.setState({ dataList, treeData, expandedKeys: [] });
        console.log("reload");
    }


    render() {
        const { searchValue, expandedKeys, autoExpandParent, treeData, } = this.state;
        const loop = data =>
            data.map(item => {
                const index = item.title.indexOf(searchValue);
                const beforeStr = item.title.substr(0, index);
                const afterStr = item.title.substr(index + searchValue.length);
                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span style={{ color: '#f50' }}>{searchValue}</span>
                            {afterStr}
                        </span>
                    ) : (
                            <span>{item.title}</span>
                        );
                if (item.children) {
                    return (
                        <TreeNode icon={<Icon type="usb" />} key={item.key} title={title} >
                            {loop(item.children)}
                        </TreeNode>
                    );
                }
                return <TreeNode icon={<Icon type="desktop" />}
                    // icon={<Icon type="up-circle" />} 
                    key={item.key} title={title} />;
            });
        return (
            <div style={{ fontWeight: 600 }}>
                <Search style={{ marginBottom: 8 }} placeholder="MAC地址或者IP地址"
                    enterButton={<Button type="primary" icon="search">搜索</Button>}
                    onChange={(e) => { if (e.target.value === "") { this.reloadData() } }}
                    allowClear
                    onSearch={(v) => this.filterTreeData(v)} />
                <Tree
                    // showLine
                    // switcherIcon={<Icon type="down-circle" />}
                    showIcon
                    onExpand={this.onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                >
                    {loop(treeData)}
                </Tree>
            </div>
        );
    }
}
