/**
 * 系统前后端API配置
 */
const BASEURL = "http://" + email_address + "/";
const APIConfig = {
    MODENAME: "email",//模块名称
    BASEURL,
    //URL:配置 
    config: BASEURL + "config",
    email: BASEURL + "email",
};
export default APIConfig;