import React from 'react'
import { Card, Table, Button, Icon, Input, message, Popconfirm, Tag, Modal, Row, Col, Upload } from "antd";
import axios from '@/axios/axios_utils';
import Utils from "../../../utils/utils";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import DyHeadTable from '@/components/DyHeadTable/index';
import FirewallUtils from "@/utils/FirewallUtils";

const { Dragger } = Upload;
const eLineInfo_Url = "http://" + backup_address + "/ZJNMS_V1/fw_manage/";
export default class ELineInformation extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _pageSize: 20,    //当前每页显示条数
        total: 0,
        loading: false,
        detailLoading: false,
        detailModal: false,
        detailData: [],
        querys: null,    //查询条件
        tableData: [],
        importVisible: false,
        fileList: [],
        selectedRowKeys: [],
        e_line_no: '',
        completeTableData: [],
    };
    componentWillMount() {
        this.updata();
    }
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true });
        let { _pageSize, _current } = this.state;
        let bodyJson = {
            page_size: pageSize || _pageSize,
            page_no: pageNum || _current,
            ...querys
        };
        axios.get(eLineInfo_Url + 'eLine', bodyJson, (res) => {
            if (res.code === "00") {
                this.setState({
                    tableData: res.data,
                    loading: false,
                    total: res.count,
                    _current: pageNum || 1,
                    _pageSize: pageSize || _pageSize,
                })
            } else {
                message.error(res.msg);
                this.setState({ loading: false });
            }
        })
    };
    getFireWallData(id) {
        let ext_ids = id.join(',');
        this.setState({ detailLoading: true });
        axios.get(eLineInfo_Url + 'externalConnection', { ext_ids }, (res) => {
            if (res.code === '00') {
                this.setState({
                    detailModal: true,
                    detailLoading: false,
                    detailData: JSON.parse(JSON.stringify(res.data)), // 深拷贝,
                    completeTableData: res.data,
                });
            } else {
                message.error(rea.msg);
                this.setState({ detailLoading: false });
            }
        })
    }
    importELineInfo(file) {
        // console.log("导入专线信息", file.file)
        if (Utils.isEmpty(file.fileList)) {
            return;
        }
        this.setState({ fileList: [file.file] })
    }
    onSubmit() {
        let { fileList } = this.state;
        let file = fileList[0];
        let formData = new FormData();
        formData.append('file', file)
        if (fileList.length > 0) {
            axios.post(eLineInfo_Url + 'eLine', formData, (res) => {
                if (res.code === '00') {
                    message.success("上传成功");
                    this.setState({ importVisible: false, fileList: [] });
                    this.updata();
                }
            })
        }
    }
    downloadTemplate() {
        let list = [
            {
                "CI_CODE": "DDN00001",
                "专线号": "B00000001",
                "主端接口地址": "192.27.1.252",
                "对端接口地址": "192.27.1.253",
            }
        ]
        Utils.exportExcel(list, "监控专线清单.xlsx");
    }
    deleteELineInfo(_ids) {
        let _this = this;
        if (Utils.isEmpty(_ids)) {
            return message.warning("请选择需要删除的专线信息！");
        }
        Modal.confirm({
            title: '消息提示',
            content: '你确定删除该专线信息吗？',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                axios.delete_body(eLineInfo_Url + "eLine", { _ids }, (res) => {
                    if (res.code === "00") {
                        message.success(res.msg || "删除成功");
                        _this.setState({ selectedRowKeys: [] });
                        _this.updata();
                    } else {
                        message.error(res.msg || "删除失败")
                    }
                })
            },
        });
    }
    // 更新列表数据
    updateData(detailData) {
        this.setState({ detailData })
    }

    render() {
        let { _current, _pageSize, querys, tableData, loading, total, detailModal, detailData, detailLoading, selectedRowKeys, e_line_no, completeTableData } = this.state;
        const columns = [
            {
                title: 'CI_CODE',
                align: 'center',
                dataIndex: 'ci_code',
                width: 100,
                ellipsis: true,
            },
            {
                title: '专线号',
                align: 'center',
                dataIndex: 'e_line_no',
                width: 100,
                ellipsis: true,
            },
            {
                title: '外联路由器',
                align: 'center',
                dataIndex: 'router',
                width: 120,
                ellipsis: true,
            },
            {
                title: '主端接口',
                align: 'center',
                dataIndex: 'port',
                width: 120,
                ellipsis: true,
            },
            {
                title: '主端接口地址',
                align: 'center',
                dataIndex: 'port_ip',
                width: 120,
                ellipsis: true,
            },
            {
                title: '对端接口地址',
                align: 'center',
                dataIndex: 'o_port_ip',
                width: 120,
                ellipsis: true,
            },
            {
                title: '防火墙数据',
                align: 'center',
                dataIndex: 'fw_data',
                width: 100,
                render: (text, item, index) => {
                    if (text) {
                        return <Button type="link" size='small' icon='file-text' onClick={() => {
                            this.setState({ detailModal: true, e_line_no: item.e_line_no });
                            this.getFireWallData(text);
                        }}>详情</Button>
                    }
                }
            },
            {
                title: '操作',
                align: 'center',
                dataIndex: 'op',
                fixed: 'right',
                width: 120,
                render: (text, item, index) => {
                    return <Button size='small' type="danger" icon="delete" onClick={() => { this.deleteELineInfo([item._id]) }}>删除</Button>
                }
            },
        ];
        const fwColumns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                width: 100,
                ellipsis: true,
                render: (text, item, index) => {
                    return <span>{index + 1}</span>
                }
            },
            ...FirewallUtils.getExternalConnectionColumns(detailData, completeTableData, this)
        ];
        const FormList = [
            {
                label: "CI_CODE",
                type: 'INPUT',
                field: "ci_code",
                initialValue: '',
                placeholder: "CI_CODE"
            },
            {
                label: "专线号",
                type: 'INPUT',
                field: "e_line_no",
                initialValue: '',
                placeholder: "专线号"
            },

        ]
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        return (
            <div>
                <CustomBreadcrumb arr={["防火墙管理", "专线信息"]} />
                <Card>
                    <FilterForm
                        formList={FormList}
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(_pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(_pageSize, 1, {})
                        }}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>专线信息列表</span>}
                    extra={
                        <Row>
                            <Button type="primary" icon="import" onClick={() => this.setState({ importVisible: true })}>导入专线信息</Button>
                            <Button type="danger" icon="delete" onClick={() => { this.deleteELineInfo(selectedRowKeys) }}>删除</Button>
                        </Row>
                    }
                    style={{ marginTop: 8 }}>
                    <DyHeadTable
                        dataSource={tableData}
                        columns={columns}
                        size='small'
                        loading={loading}
                        rowKey={(text) => { return text._id }}
                        rowSelection={rowSelection}
                        pagination={{
                            pageSize: _pageSize,
                            current: _current,
                            total: total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onChange: (page, pageSize) => {
                                this.updata(pageSize, page, querys);
                            },
                            onShowSizeChange: (current, size) => {
                                this.updata(size, 1, querys)
                            }
                        }}
                    />
                </Card>
                <Modal
                    title={<div>导入专线信息&nbsp;&nbsp;&nbsp;<Button type="primary" icon="download" onClick={() => { this.downloadTemplate() }}>下载模板</Button></div>}
                    visible={this.state.importVisible}
                    onOk={() => { this.onSubmit() }}
                    onCancel={() => this.setState({ importVisible: false })}
                    destroyOnClose
                >
                    <Dragger
                        beforeUpload={function () { return false; }}
                        onChange={this.importELineInfo.bind(this)}
                        fileList={this.state.fileList}
                        onRemove={() => {
                            this.setState({ fileList: [] })
                        }}
                    >
                        <p className="ant-upload-drag-icon"><Icon type="inbox" /></p>
                        <p className="ant-upload-text">单击或将文件拖到该区域上传</p>
                    </Dragger>
                </Modal>
                <Modal
                    title={"专线" + e_line_no + "-防火墙数据"}
                    visible={detailModal}
                    onCancel={() => this.setState({ detailModal: false, e_line_no: '' })}
                    onOk={() => this.setState({ detailModal: false, e_line_no: '' })}
                    width="70%"
                    style={{ top: "20px" }}
                    destroyOnClose
                >
                    <Table
                        dataSource={detailData}
                        columns={fwColumns}
                        size='small'
                        loading={detailLoading}
                        rowKey={(text) => { return text._id }}
                        pagination={false}
                    />
                </Modal>
            </div>
        )
    }
}
