import * as XLSX from 'xlsx'

/**
 * @author linjiawei1996@qq.com
 * @description 导出json为xls
 * @time 2020/2/12 3:23 上午
 */
export function exportExcel(json,fileName){
    const type = 'xlsx'//定义导出文件的格式
    let tmpDown;//导出的内容
    let tmpdata = json[0];
    json.unshift({});
    let keyMap = []; //获取keys
    for (let k in tmpdata) {
        keyMap.push(k);
        json[0][k] = k;
    }
    tmpdata = [];//用来保存转换好的json

    json.map((v, i) => keyMap.map((k, j) => Object.assign({}, {
        v: v[k],
        position: (j > 25 ? getCharCol(j) : String.fromCharCode(65 + j)) + (i + 1)
    }))).reduce((prev, next) => prev.concat(next)).forEach((v, i) => tmpdata[v.position] = {
        v: v.v
    });
    let outputPos = Object.keys(tmpdata); //设置区域,比如表格从A1到D10
    let tmpWB = {
        SheetNames: ['sheet1', ], //保存的表标题
        Sheets: {
            'sheet1': Object.assign({},
                tmpdata, //内容
                {
                    '!ref': outputPos[0] + ':' + outputPos[outputPos.length - 1] //设置填充区域
                })
        }
    };
    tmpDown = new Blob([s2ab(XLSX.write(tmpWB,
        {bookType: (type == undefined ? 'xlsx':type),bookSST: false, type: 'binary'}//这里的数据是用来定义导出的格式类型
    ))], {
        type: ""
    }); //创建二进制对象写入转换好的字节流
    saveAs(tmpDown,fileName);
}

function saveAs(obj, fileName){//导出功能实现
    let tmpa = document.createElement("a");
    tmpa.download = fileName || "下载";
    tmpa.href = URL.createObjectURL(obj); //绑定a标签
    tmpa.click(); //模拟点击实现下载
    setTimeout(function () { //延时释放
        URL.revokeObjectURL(obj); //用URL.revokeObjectURL()来释放这个object URL
    }, 100);
}

function s2ab(s){ //字符串转字符流
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

function getCharCol(n){
    let temCol = '',
        s = '',
        m = 0
    while (n > 0) {
        m = n % 26 + 1
        s = String.fromCharCode(m + 64) + s
        n = (n - m) / 26
    }
    return s
}