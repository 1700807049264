/**
 * 网络通讯类（与后台socket通讯）
 * @type {string}
 */
import { notification, message } from 'antd'
import utils from '../utils/utils';
const address = process.env.ui_address || sys_address;
const Module1 = 'BackStage';
const Module2 = 'Store';
// const io = require('socket.io-client')('http://' + address + ':50033/ui');
const io = require('socket.io-client')('http://' + sys_address + '/ui');
// const io = require('socket.io-client')('http://' + sys_address + '/ui', {
//     extraHeaders: {
//         header: {
//             token: "Bearer authorization_token_here",
//         },
//     }
// });
let _userId = sessionStorage.getItem('userId');
let _username = sessionStorage.getItem('userName') + "/" + sessionStorage.getItem('nickName');
let _token = sessionStorage.getItem('token');
export default class SocketClient {
    static send(action, body, callback, menuUrl) {
        io.emit(Module1, {
            "head": {
                "action": action,
                "auth": {
                    userId: _userId,
                    username: _username,
                    menuUrl: menuUrl,
                    interface: action,
                    token: _token,
                }
            },
            "body": body
        }, function (resp) {
            console.log(resp);
            callback(resp);
        });
    }
    static QueryDataByPage(action, body, callback, menuUrl) {
        console.log(body);
        io.emit(Module1, {
            "head": {
                "action": action,
                "auth": {
                    userId: _userId,
                    username: _username,
                    menuUrl: menuUrl,
                    interface: action,
                    token: _token,
                }
            },
            "body": body
        }, function (resp) {
            console.log(resp);
            callback(resp);
        });
    }
    static QueryData(action, body, callback, menuUrl) {
        io.emit(Module1, {
            "head": {
                "action": action,
                "auth": {
                    userId: _userId,
                    username: _username,
                    menuUrl: menuUrl,
                    interface: action,
                    token: _token,
                }
            },
            "body": body
        }, function (resp) {
            console.log(resp);
            callback(resp);
        });
    }
    static AddData(action, body, callback, menuUrl) {
        this.ExecuteAction(action, body, menuUrl, (success) => {
            callback(success);
            message.success('新增成功');
        }, (error) => {
            callback(error);
            notification.error({
                message: '新增失败',
                description: error,
            });
        })
    }
    static DeleteData(action, body, callback, menuUrl) {
        this.ExecuteAction(action, body, menuUrl, () => {
            callback();
            message.success('删除成功');
        }, (error) => {
            callback();
            notification.error({
                message: '删除失败',
                description: error,
            });
        })
    }
    static UpData(action, body, callback, menuUrl) {
        this.ExecuteAction(action, body, menuUrl, () => {
            callback();
            message.success('更新成功');
        }, (error) => {
            callback();
            notification.error({
                message: '更新失败',
                description: error,
            });
        })
    }
    static Action(action, body, callback, menuUrl) {
        console.log(body);
        io.emit(Module1, {
            "head": {
                "action": action,
                "auth": {
                    userId: _userId,
                    username: _username,
                    menuUrl: menuUrl,
                    interface: action,
                    token: _token,
                }
            },
            "body": body
        }, function (resp) {
            console.log(resp);
            callback(resp);
        });
    }
    static ActionStore(action, body, callback, menuUrl) {
        console.log({
            "head": {
                "action": action,
                "auth": {
                    userId: _userId,
                    username: _username,
                    menuUrl: menuUrl,
                    interface: action,
                    token: _token,
                }
            },
            "body": body
        });
        io.emit(Module2, {
            "head": {
                "action": action,
                "auth": {
                    userId: _userId,
                    menuUrl: menuUrl,
                    username: _username,
                    interface: action,
                    token: _token,
                }
            },
            "body": body
        }, function (resp) {
            console.log(resp);
            callback(resp);
        });
    }
    static Login(action, body, callback, menuUrl) {
        console.log(body);
        io.emit(Module1, {
            "head": {
                "action": action,
                "auth": {
                    userId: _userId,
                    username: _username,
                    menuUrl: menuUrl,
                    interface: action,
                    token: _token,
                }
            },
            "body": body
        }, function (resp) {
            console.log(resp);
            callback(resp);
        });
    }
    static SetData(userId, token, username, nickName) {
        _userId = userId;
        _token = token;
        _username = username + "/" + nickName;
    }
    static QueryTasksPage(pageSize, pageNum, querys, success, menuUrl) {
        let actionName = "QueryTasksPage";
        let body = new Object();
        body.pageSize = pageSize;
        body.pageNum = pageNum;
        body.querys = querys;
        io.emit(Module1, {
            "head": {
                "action": actionName,
                "auth": {
                    userId: _userId,
                    username: _username,
                    interface: actionName,
                    menuUrl: menuUrl,
                    token: _token,
                }
            },
            "body": body
        }, function (result) {
            console.log(result);
            if (result.retcode === '00') {
                success(result);
            } else {
                notification.error({
                    message: "查询失败",
                    description: result.error,
                });
            }
        });
    }
    static QueryResourcesPage(pageSize, pageNum, querys, _this,) {
        let { s_current, s_pageSize, s_querys, } = _this.state;
        let bodyJson = {
            pageSize: pageSize || s_pageSize,
            pageNum: pageNum || s_current,
            querys: querys || s_querys,
        };
        _this.setState({ s_loading: true, });
        this.ExecuteAction("QueryResourcesPage", bodyJson, "/", (success) => {
            let resources = success.resources.map((item, key) => {
                item.key = key;
                return item;
            })
            _this.setState({
                s_tableData: resources,
                s_current: pageNum || s_current,
                s_total: success.total,
                s_loading: false,
            })
        }, (error) => {
            utils.showMessage("error", "查询设备信息失败", error);
        })
    }
    static QueryOperationsPage(pageSize, pageNum, querys, success, menuUrl) {
        let actionName = "QueryOperationsPage";
        let body = new Object();
        body.pageSize = pageSize;
        body.pageNum = pageNum;
        body.querys = querys;
        console.log(body);
        io.emit(Module1, {
            "head": {
                "action": actionName,
                "auth": {
                    userId: _userId,
                    interface: actionName,
                    username: _username,
                    menuUrl: menuUrl,
                    token: _token,
                }
            },
            "body": body
        }, function (result) {
            console.log(result);
            if (result.retcode === '00') {
                success(result);
            } else {
                notification.error({
                    message: "查询失败",
                    description: result.error,
                });
            }
        });
    }
    static QueryTagsGroups(body, success, error) {
        let actionName = "QueryTagsGroups";
        io.emit(Module1, {
            "head": {
                "action": actionName,
                "auth": {
                    userId: _userId,
                    username: _username,
                    interface: actionName,
                    token: _token,
                }
            },
            "body": body
        }, function (result) {
            console.log(result);
            if (result.retcode === '00') {
                success(result);
            } else {
                error(result.error);
            }
        });
    }
    static Notice(callback) {
        io.on("BackStageNotice", function (resp, callback2) {
            // console.log(resp);
            callback(resp, callback2);
        });
    }
    static getAddress() {
        return address.split(":")[0] || "";
    }
    static getAddress50034() {
        if (address === "zjnms.zijin.com") {
            return address
        } else {
            return address.split(":")[0] + ":50034" || "";
        }
    }
    static getDeviceSSHUrl(deviceUser, devicePwd, deviceIp) {
        let _Addr = this.getAddress();
        if (_Addr.includes("zjnms")) {//域名
            _Addr = "10.32.79.60:2222";
        } else {//IP
            _Addr = _Addr + ":2222";
        }
        return "http://" + _Addr + "/sshlogin?name=" + deviceUser + "&pwd=" + devicePwd + "&deviceip=" + deviceIp;
    }
    static getAddress_Port() {
        return address || "";
    }
    static getConfig(fiedId, success) {
        this.ExecuteAction('QueryTableConfig', { config: { name: fiedId } }, "", (result) => {
            console.log(result);
            if (result.data && result.data[0] && result.data[0].value) {
                let data = result.data[0].value;
                if (utils.isJSON(data)) {
                    data = JSON.parse(data);
                }
                success(data);
            } else {
                success({});
            }
        });
    }
    static setConfig(fiedId, newData, success) {
        let _config = {};
        _config["name"] = fiedId;
        _config["value"] = newData;
        this.ExecuteAction('UpdateTableConfig', { config: _config }, "", (result) => {
            this.sys_config = _config;
            success(result);
        });
    }
    static ExecuteAction(action, body, menuUrl, success, error) {
        console.log(action, body, _username);
        io.emit(Module1, {
            "head": {
                "action": action,
                "auth": {
                    userId: _userId,
                    username: _username,
                    menuUrl: menuUrl,
                    interface: action,
                    token: _token,
                    ip: sessionStorage.getItem("userIP") || ""
                }
            },
            "body": body
        }, function (result) {
            console.log("error",result);
            if (result.retcode === '00') {
                success(result);
            } else if (result.retcode === '03') {
                // token过期自动退出登录
                notification.error({
                    message: '操作失败',
                    description: result.error,
                });
                sessionStorage.removeItem('userId');
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('userName');
                sessionStorage.removeItem('nickName');
                sessionStorage.removeItem('menuList');
                sessionStorage.removeItem('FixedBtn');
                sessionStorage.clear();
                window.location.href = "#/login";
            } else if (error) {
                error(result.error);
                // notification.error({
                //     message: '操作失败',
                //     description: result.error,
                // });
            } else {
                let info = (typeof info === "object") ? JSON.stringify(info) : result.error;
                notification.error({
                    message: '操作失败',
                    description: info,
                });
            }
        });
    }
}