import React from 'react'
import {
    Card, Table, Input, Button, Icon, message, Modal, Menu, Dropdown, Spin, Tag, Divider, Tooltip
} from "antd";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import AxiosObj from '@/axios/index';
import axios from 'axios'
import Socket from "@/socket/index";
import NewSemForm from './newSemForm';
import UploadOID from './upload';
import MixTopology from './mixTopology';
import SetTimedExecute from "./setTimedExecute";
import { connect } from 'react-redux'
import './index.scss';
let IntervalSta = null;
const autoFind_Url = "http://" + backup_address + "/ZJNMS_V1/topology/";
class AutoFind extends React.Component {
    state = {
        s_current: 1,      //当前页码数
        s_pageSize: 20,    //每页显示条数
        s_querys: {},    //查询条件
        s_total: 0,
        s_loading: false,
        newSemVis: false,
        topoVis: false,
        uploadVis: false,
        s_tableData: [],
        selectedRows: [],
        selectedRowKeys: [],
        topoData: [],
        setTimedVisible: false,
        networkItem: {},
        dep_ids: [],
    }
    componentWillMount() {
        let bodyJson = this.props.location.bodyJson || {};
        this.getDepIds();
        // this.runGetSem();
        // if(sessionStorage.getItem("prevPathName")) {
        //     sessionStorage.removeItem("prevPathName")
        // }
    }
    componentWillUnmount() {
        clearInterval(IntervalSta);
    }
    getDepIds = () => {
        Socket.ExecuteAction('QueryArea', {}, "", (result) => {
            const TreeData = result.data;
            let dep_ids = this.dealTreeData(TreeData);
            this.setState({ dep_ids });
            this.getSement();
            this.runGetSem();
            if(sessionStorage.getItem("prevPathName")) {
                sessionStorage.removeItem("prevPathName")
            }
        });
    };
    dealTreeData(data) {
        let that = this;
        let dep_ids = [];
        if(Array.isArray(data)) {
            data.map((item) => {
                let children = item.props ? item.props.children : item.children
                if(children && children.length > 0) {
                    dep_ids = dep_ids.concat(that.dealTreeData(children));
                } else {
                    dep_ids.push(item.key || item.eventKey)
                }
            })
        }
        return dep_ids
    }
    getSement(pageSize, pageNum) {
        const { s_pageSize, s_current, dep_ids } = this.state;
        const { dispatch } = this.props;
        let bodyJson = {
            page_size: pageSize || s_pageSize,
            page_no: pageNum || s_current,
            dep_ids: dep_ids,
        };
        // dispatch(isLoading(true));
        AxiosObj.HTTP_POST("topology", "pingStatusRecords", bodyJson, (result) => {
            // console.log(result)
            this.setState({
                s_tableData: result.data,
                s_total: result.count,
                s_current: bodyJson.page_no,
            });
            // const flag = result.data.every((item) => { return item.status === "完成" });
            if (result.scan_process_num === 0) {//都完成
                clearInterval(IntervalSta);
            } else {
                this.runGetSem()
            }
            // dispatch(isLoading(false));
        })
    }
    FindDevice(val) {
        const { dispatch } = this.props;
        const _this = this;
        // dispatch(isLoading(true));
        this.runGetSem();
        AxiosObj.HTTP_POST("topology", "getAllPingIps", val, (result) => {
            message.success(result.msg)
            this.getSement();
            clearInterval(IntervalSta);
        });
        // 
        setTimeout(() => {
            _this.getSement();
        }, 600)
    }
    addSement(val) {
        AxiosObj.HTTP_POST("topology", "addTopologyNet", val, (result) => {
            message.success(result.msg)
            this.setState({ newSemVis: false });
            this.getSement();
        });
    }
    runGetSem() {
        const _this = this;
        if(IntervalSta) {
            clearInterval(IntervalSta);
        }
        IntervalSta = setInterval(() => {
            _this.getSement();
        }, 5000);
    }
    goDetail(item) {
        let path = {
            pathname: `/autoFind/findDevice`,
        };
        sessionStorage.setItem("segment", JSON.stringify(item))
        this.props.history.push(path);
    }
    getMixTopo() {
        const { selectedRows } = this.state;
        AxiosObj.HTTP_POST("topology", "topologyMerger", selectedRows, (result) => {
            this.setState({ topoVis: true, topoData: result.data });
        });
    }
    delMask(val) {
        AxiosObj.HTTP_POST("topology", "delTopologyByNet", val, (result) => {
            message.success(result.msg)
            this.getSement();
        });
    }
    handleMenuClick(menu, resItem) {
        let _this = this;
        switch (menu.key) {
            case "detail": this.goDetail(resItem); break;
            case "action": 
                Modal.confirm({
                    title: "提示",
                    content: "是否开始扫描该网段?",
                    onOk() {
                        _this.FindDevice({
                            net_mask: resItem.net_mask,
                            area: resItem.area,
                            community: resItem.community,
                            tags: resItem.tags,
                            dep_id: resItem.dep_id
                        })
                    }
                }); 
                break;
            case "del":
                Modal.confirm({
                    title: "提示",
                    content: "是否删除该网段?",
                    onOk() {
                        _this.delMask({
                            net_mask: resItem.net_mask,
                            area: resItem.area,
                        })
                    }
                });
                break;
            case "timing": this.setState({ setTimedVisible: true, networkItem: resItem }); break;
        }
    }
    menuButton(resItem) {
        return (
            <Menu onClick={(e) => this.handleMenuClick(e, resItem)}>
                <Menu.Item key="detail">
                    <Icon type="edit" /> 详情
                </Menu.Item>
                <Menu.Item key="del">
                    <Icon type="delete" /> 删除
                </Menu.Item>
                <Menu.Item key="timing">
                    <Icon type="clock-circle" /> 定时设置
                </Menu.Item>
            </Menu>
        )
    }
    scanRecordsByNet(net_mask) {
        let { dep_ids } = this.state;
        AxiosObj.HTTP_POST("topology", "scanRecordsByNet", { net_mask, dep_ids }, (result) => {
            this.setState({
                s_tableData: result.data,
                s_total: result.data.length,
                s_current: 1,
            });
        });
    }
    dealTime(time) {
        let month = time.getMonth() + 1;
        let day = time.getDate();
        let year = time.getFullYear();
        let hours = time.getHours();
        let minutes = time.getMinutes();
        let seconds = time.getSeconds();
        return this.tenNumber(year) + this.tenNumber(month) + this.tenNumber(day) + this.tenNumber(hours) + this.tenNumber(minutes) + this.tenNumber(seconds)
    }
    tenNumber(num) {
        let tenNum = num
        if(tenNum >= 0 && tenNum < 10) {
            tenNum = "0" + tenNum
        } else {
            tenNum = tenNum
        }
        return tenNum;
    }
    typeTranslate(type) {
        switch (type) {
            case "min": return "分钟";
            case "hour": return "小时";
            case "day": return "天";
            case "week": return "周";
        }
    }
    exportReport() {
        const { selectedRowKeys } = this.state;
        let net_ip_ids = selectedRowKeys;
        if(net_ip_ids.length === 0) {
            message.error("请勾选需要导出报表的网段！")
        } else {
            axios({
                method: "POST",
                url: autoFind_Url + "exportNetDeviceData",
                data: { net_ip_ids },
                responseType: 'blob'
            }).then((result) => {
                const link = document.createElement('a');
                let blob = new Blob([result.data], { type: "application/vnd.ms-excel" }); // 转化为 blob 对象 导出.xls文件
                // let blob = new Blob([result.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }); // 转化为 blob 对象  导出.xlsx文件
                link.style.display = "none";
                link.href = URL.createObjectURL(blob);
                link.download = "网络设备报表-" + this.dealTime(new Date()); // 文件名
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
        }
    }
    render() {
        const { s_tableData, s_current, s_pageSize, s_querys, s_total, s_loading, newSemVis, topoVis, selectedRows,
            selectedRowKeys, topoData, uploadVis, setTimedVisible, networkItem } = this.state;
        const columns = [{
            title: '序号',
            width: 50,
            render: (text, item, index) => {
                return <span>{index + 1 + (s_current - 1) * s_pageSize}</span>
            }
        }, {
            title: '网段',
            align: 'center',
            dataIndex: "net_mask",
            render: (a, b, c) => {
                return <Button type="link" size="small" onClick={() => { this.goDetail(b) }}>{a}</Button>
            }
        },
        {
            title: '网段标签',
            align: 'center',
            dataIndex: "tags",
            render: (a, b, c) => {
                return Utils.hidenTag(a)
            }
        },
        {
            title: '发现设备数量',
            align: 'center',
            dataIndex: "device_count",
            render: (a, b, c) => {
                return <div className={"linkButn"} >{a}</div>
            }
        },
        {
            title: '区域',
            align: 'center',
            dataIndex: "area",
        },
        {
            title: '发现时间',
            align: 'center',
            dataIndex: "create_time",
        },
        {
            title: '执行方式',
            dataIndex: "scan_mode",
            render: (text, item, index) => {
                switch (text) {
                    case "auto": return (
                        <Tooltip title={
                            <div>
                                <p style={{ marginBottom: 0 }}>执行周期：{item.auto_scan_config ? item.auto_scan_config.cycle + this.typeTranslate(item.auto_scan_config.type) : ""}</p>
                                <p style={{ marginBottom: 0 }}>开启定时时间：{item.auto_scan_config ? item.auto_scan_config.start_datetime : ""}</p>
                            </div>}>
                            <span><Icon type="sync" />&nbsp;自动</span>
                        </Tooltip>
                    );
                    case "manual": return <Tooltip title="手动执行"><span><Icon type="tool" />&nbsp;手动</span></Tooltip>;
                    default: return <Tooltip title="手动执行"><span><Icon type="tool" />&nbsp;手动</span></Tooltip>;
                }
            }
        },
        {
            title: '状态',
            dataIndex: "status",
            render: (a, b, c) => {
                return a === "完成" ? <Tag color="green">{a}</Tag> : (
                    a === "未开始" ? <Tag color="blue">{a}</Tag> :
                        (a === "ping数据为空" ? <Tag>{a}</Tag> :
                            <div className="state" ><Spin spinning={true} />&nbsp;{a}</div>)
                )
            }
        },
        {
            title: '耗时',
            align: 'center',
            dataIndex: "topology_consume_time",
        },
        {
            title: '操作',
            align: 'center',
            width: 110,
            render: (text, item, index) => {
                return <div>
                    <Dropdown.Button overlay={this.menuButton(item)}
                        type="primary" icon={<Icon type="down" />} size="small"
                        onClick={() => { this.handleMenuClick({ key: "action" }, item) }}
                    ><Icon type="search" />扫描
                    </Dropdown.Button>
                </div>
            }
        }];
        return <div className="autoFindDiv">
            <CustomBreadcrumb
                arr={["运维管理", { title: "网络自动发现", to: "/autoFind" }]}
            />
            <Card className="tableCard" title="网段管理" extra={<div>

            </div>}>
                <div> &nbsp;&nbsp;<strong>请输入网段:&nbsp;</strong>
                    <Input.Search placeholder="根据网段查询" style={{ width: 350 }} allowClear id="search"
                        value={this.state.searchVal || ""}
                        onChange={e => this.setState({ searchVal: e.target.value })}
                        onSearch={value => { this.scanRecordsByNet(value) }} enterButton />&nbsp;
                    <Button icon="reload" onClick={() => {
                        this.setState({ searchVal: "" });
                        this.getSement();
                    }} >重置</Button>&nbsp;
                    <Button type="primary" icon="file-text" onClick={() => { this.setState({ uploadVis: true }) }}>OID类型库信息</Button>&nbsp;
                    <Button type="primary" icon="dot-chart" onClick={() => {
                        if (selectedRows.length === 0) { message.info("请选择网段!"); return; }
                        this.getMixTopo();
                    }}>查看组合拓扑图</Button>&nbsp;
                    <Button type="primary" icon="plus-square" onClick={() => { this.setState({ newSemVis: true }) }}>新建网段</Button>&nbsp;
                    <Button type="primary" icon="export" onClick={() => { this.exportReport() }}>导出网络设备报表</Button>&nbsp;
                    <Button type="primary" icon="clock-circle" onClick={() => {
                        if (Utils.isEmpty(selectedRowKeys)) {
                            message.info("请选择网段！");
                            return;
                        } else {
                            this.setState({ setTimedVisible: true })
                        }
                    }}>批量设置</Button>&nbsp;
                </div><p />
                <Table
                    columns={columns}
                    dataSource={s_tableData}
                    rowSelection={{
                        selectedRowKeys: this.state.selectedRowKeys || [],
                        onChange: (selectedRowKeys, selectedRows) => {
                            this.setState({ selectedRowKeys, selectedRows });
                        },
                        selections: [
                            {
                                key: 'cencel',
                                text: '清空选择',
                                onSelect: () => {
                                    this.setState({ selectedRowKeys: [] });
                                },
                            },
                        ],
                    }}
                    rowKey={(row) => row._id}
                    loading={s_loading}
                    pagination={{
                        current: s_current, pageSize: s_pageSize, total: s_total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        onChange: (page, pageSize) => { this.getSement(pageSize, page, s_querys); }
                    }}
                    size="small"
                />
            </Card>
            <Modal
                title="新建网段"
                visible={newSemVis}
                onCancel={(e) => {
                    this.setState({ newSemVis: false });
                }}
                width="70%"
                footer={<div>
                    <Button icon="close" onClick={() => {
                        this.setState({ newSemVis: false })
                    }}>取消</Button>&nbsp;
                    <Button type="primary" icon="plus-square" onClick={() => {
                        this.newForm.props.form.validateFields((err, values) => {
                            if (!err) { this.addSement({...values, dep_id: this.newForm.state.dep_id}) };
                        });
                    }}>确定</Button>&nbsp;
                    <Button type="primary" icon="search" onClick={() => { this.newForm.handleSubmit(); }}>开始扫描</Button>
                </div>}
                className="segmentModal"
                destroyOnClose
            >
                <NewSemForm onRef={(thisForm) => this.newForm = thisForm} onChange={(e) => {
                    this.setState({ newSemVis: false }); this.FindDevice({...e, dep_id: this.newForm.state.dep_id})
                }} />
            </Modal>
            <Modal
                title="区域组合拓扑图"
                visible={topoVis}
                onCancel={(e) => { this.setState({ topoVis: false }) }}
                onOk={(e) => { }}
                width="98%"
                style={{ top: 10 }}
                className="topoModal"
                destroyOnClose
            >
                <MixTopology data={topoData} />
            </Modal>
            <UploadOID btnName="开始上传" title="设备型号OID信息" visible={uploadVis}
                onCancel={() => { this.setState({ uploadVis: false }) }}
                tempData={[{
                    "oid": "enterprises.9.1.1208",
                    "firm": "CISCO",
                    "type": "SWITCH",
                    "details": "cat-29xxStack"
                }]}
                tempName="上传OID模板.xlsx"
            />
            <SetTimedExecute
                setTimedVisible={setTimedVisible}
                getSement={() => this.getSement()}
                onCancel={() => this.setState({ setTimedVisible: false })}
                selectedData={selectedRows}
                clearSelectedData={() => this.setState({ selectedData:[], selectedRows:[], selectedRowKeys: [] })}
                networkItem={networkItem}
                clearNetworkItem={() => this.setState({ networkItem: {} })}
            />
        </div>
    }
}
export default connect()(AutoFind)