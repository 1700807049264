import React from 'react'
import { Card, Table, Button, message, Popconfirm, Icon, Input, Tree, Row, Col, Form, Modal, Select } from "antd";
import Socket from "../../../socket/index";
import iconClass from "./iconList";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import CascOrgain from '@/components/CascOrgain/index';
import EditMenuForm from './editMenuForm';
import Utils from '@/utils/utils';
import './index.scss';

const { TreeNode } = Tree;
const Search = Input.Search;



class MenuClass extends React.Component {
    state = {
        TreeData: [],
        expandedKeys: [],
        dataList: [],
        searchValue: '',
        autoExpandParent: false,
        addVisible: false,
        selectNode: {},
        RightMenuStyle: {},
        rightClickNodeTreeItem: null
    };
    componentWillMount() {
        this.getMenuData();
    }

    onDragEnter = info => {
        // console.log(info);
        // expandedKeys 需要受控时设置
        // this.setState({
        //   expandedKeys: info.expandedKeys,
        // });
        // let dragNodesKeys = info.dragNodesKeys;
        // let key = dragNodesKeys[dragNodesKeys.length - 1];
        // let index = info.dropPosition + 2;

        // Socket.ExecuteAction("UpdateMenu", { key, index }, "", (result) => {
        //     message.success("修改成功!");
        // });
    };
    getMenuData = () => {
        Socket.ExecuteAction('QueryArea', {}, "", (result) => {
            console.log(result)
            const dataList = [];
            const TreeData = result.data;
            const generateList = data => {
                for (let i = 0; i < data.length; i++) {
                    const node = data[i];
                    const { key, title } = node;
                    dataList.push({ key, title });
                    if (node.children) {
                        generateList(node.children);
                    }
                }
            };
            generateList(TreeData);
            let expandedKeys = [];
            TreeData.map((it, index) => {
                expandedKeys = Utils.ArrayPushRequired(expandedKeys, it.key);
            })
            this.setState({
                TreeData,
                dataList,
                expandedKeys
            });
        });
    };
    updataMenuData = (action, body, newTreeData) => {
        Socket.ExecuteAction(action, body, "", (result) => {
            message.success("操作成功!");
            this.getMenuData();
            // const dataList = [];
            // const TreeData = newTreeData;
            // const generateList = data => {
            //     for (let i = 0; i < data.length; i++) {
            //         const node = data[i];
            //         const { key, title } = node;
            //         dataList.push({ key, title });
            //         if (node.children) {
            //             generateList(node.children);
            //         }
            //     }
            // };
            // generateList(TreeData);
            this.setState({
                // TreeData,
                // dataList,
                selectNode: body
            });
        });
    };

    onDrop = info => {
        console.log(info);
        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dropPos = info.node.props.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        const source = info.dragNode.props.pos.split("-");
        // console.log(dropKey, dragKey, dropPos, dropPosition, source);
        let _flag = true;

        if (source.length != dropPos.length) {
            _flag = false;
        } else {
            source.map((item, key) => {
                console.log(item, dropPos[key])
                if (item != dropPos[key] && (key + 1) != source.length) {
                    _flag = false;
                }
            })
        }
        if (!_flag) { message.error("暂不支持跨级操作!"); return };
        let dragNodes = info.dragNode;
        let body = {
            key: dragNodes.props.eventKey,
            parentKey: dragNodes.props.parentKey,
            index: parseInt(dropPos[dropPos.length - 1]) + 1,
            title: dragNodes.props.titleName,
        }
        Socket.ExecuteAction("UpdateArea", body, "", (result) => {
            message.success("修改成功!");
            this.getMenuData();
        });
    };
    getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some(item => item.key === key)) {
                    parentKey = node.key;
                } else if (this.getParentKey(key, node.children)) {
                    parentKey = this.getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };
    onChange = e => {
        const { value } = e.target;
        let expandedKeys = [];
        if (value.trim() != "") {
            expandedKeys = this.state.dataList
                .map(item => {
                    if (item.title.indexOf(value) > -1) {
                        return this.getParentKey(item.key, this.state.TreeData);
                    }
                    return null;
                })
                .filter((item, i, self) => item && self.indexOf(item) === i);
        }
        this.setState({
            expandedKeys,
            searchValue: value,
            autoExpandParent: true,
        });
    };
    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };
    // tree列表上右键事件
    onRightClick = e => {
        console.log(e)
        let selectNode = {
            title: e.node.props.titleName,
            key: e.node.props.eventKey,
            index: e.node.props.index,
            description: e.node.props.description,
            parentKey: e.node.props.parentKey,
        }
        console.log(selectNode)
        this.setState({
            selectNode,
            rightClickNodeTreeItem: {
                pageX: e.event.clientX,
                pageY: e.event.clientY,
                idKey: e.node.props["eventKey"],
                categoryName: e.node.props["titleName"]
            }
        });
    };


    //点击右键菜单选项
    clickRightMenu(key, nodeKey) {
        let { TreeData, selectNode } = this.state;
        let newTreeData = TreeData;
        let actionFlag;
        switch (key) {
            case "del":
                // newTreeData = this.deleteMenu(TreeData, nodeKey);
                // console.log(newTreeData);
                this.updataMenuData("DeleteArea", selectNode, newTreeData);
                break;
            case "add": actionFlag = "add"; break;
            case "addChild": actionFlag = "addChild"; break;
        }
        let state = true;
        if (key === "del") {
            state = false
        }
        this.setState({ addVisible: state, rightClickNodeTreeItem: null, actionFlag, nodeKey, })
    }
    // 自定义右键菜单内容
    getNodeTreeRightClickMenu = () => {
        const { pageX, pageY, idKey, categoryName } = { ...this.state.rightClickNodeTreeItem };
        const RightMenuStyle = {
            position: "fixed",
            left: `${pageX}px`,
            top: `${pageY}px`,
        };
        const menu = (
            <div style={RightMenuStyle} className="TreeRightMenu">
                <div onClick={() => this.clickRightMenu("addChild", idKey)} className="add"><Icon type="plus-circle" />  新增子节点</div>
                <div onClick={() => this.clickRightMenu("add", idKey)} className="add"><Icon type="plus-circle" />  新增同级节点</div>
                <Popconfirm
                    title="确定删除吗?"
                    onConfirm={() => this.clickRightMenu("del", idKey)}
                    okText="Yes"
                    cancelText="No"
                >
                    <div className="delete"><Icon type="delete" />  删除选中节点</div>
                </Popconfirm>
            </div>
        );
        return this.state.rightClickNodeTreeItem == null ? "" : menu;
    };

    addChildNode(_treeData, nodeKey, values) {
        let newtreeData = _treeData.map((node) => {
            if (node["key"] === nodeKey) {
                if (node["children"]) {
                    node["children"].push(values);
                } else {
                    node["children"] = [values];
                }
            } else if (node["children"]) {
                node["children"] = this.addChildNode(node["children"], nodeKey, values);
            }
            return node;
        });
        return newtreeData;
    }
    deleteMenu(_treeData, nodeKey) {
        let newtreeData = [];
        _treeData.filter((node) => {
            if (node["key"] === nodeKey) {

            } else if (node["children"]) {
                node["children"] = this.deleteMenu(node["children"], nodeKey);
                newtreeData.push(node);
            } else {
                return newtreeData.push(node);
            }
        });
        return newtreeData;
    }
    upDataSubmit() {
        // e.preventDefault();
        let _this = this;
        let { actionFlag, TreeData, nodeKey, selectNode } = this.state;
        let newTreeData = TreeData;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                console.log(selectNode);
                switch (actionFlag) {
                    case "add":
                        newTreeData.push(values);
                        values.parentKey = selectNode.parentKey || "";
                        _this.updataMenuData("AddArea", values, newTreeData);
                        break;
                    case "addChild":
                        // newTreeData = _this.addChildNode(TreeData, nodeKey, values);
                        values.parentKey = selectNode.key;
                        _this.updataMenuData("AddArea", values, newTreeData);
                        break;
                    // case "del":
                    //     newTreeData = this.deleteMenu(TreeData, nodeKey);
                    //     _this.updataMenuData("DeleteMenu", { key: selectNode["key"] }, newTreeData);
                    //     break;
                }
                console.log(newTreeData)
                // _this.updataMenuData(newTreeData);

                _this.setState({ addVisible: false });

            }
        });
    }
    changeDataFun(_treeData, nodeObj) {
        let nodeKey = nodeObj["key"];
        let newTreeData = _treeData.map((node) => {
            if (node["key"] === nodeKey) {
                let newNode = nodeObj;
                if (node["children"]) {
                    newNode["children"] = node["children"]
                }
                return newNode;
            } else if (node["children"]) {
                node["children"] = this.changeDataFun(node["children"], nodeObj);
                return node;
            } else {
                return node;
            }
        });
        return newTreeData
    }
    changeTreeData(values) {
        let _this = this;
        let { TreeData, selectNode } = this.state;
        let newTreeData = this.changeDataFun(TreeData, values);
        console.log(newTreeData);
        _this.updataMenuData("UpdateArea", values, newTreeData);
    }
    render() {
        let { selectNode, expandedKeys, autoExpandParent, searchValue, addVisible } = this.state
        const { getFieldDecorator } = this.props.form;
        const loop = (data, parentKey) =>
            data.map((item, menuIndex) => {
                const index = item.title.indexOf(searchValue);
                const beforeStr = item.title.substr(0, index);
                const afterStr = item.title.substr(index + searchValue.length);
                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span style={{ color: '#f50' }}>{searchValue}</span>
                            {afterStr}
                        </span>
                    ) : (
                            <span>{item.title}</span>
                        );
                if (item.children) {
                    return (
                        <TreeNode
                            // icon={<Icon type="home" />} 
                            key={item.key} title={title}
                            titleName={item.title}
                            parentKey={parentKey}
                            description={item.description}
                            index={item.index}
                            iconName={item.icon}>
                            {loop(item.children, item.key)}
                        </TreeNode>
                    );
                }
                return <TreeNode
                    // icon={<Icon type="home" />}
                    key={item.key} title={title}
                    titleName={item.title}
                    parentKey={parentKey}
                    description={item.description}
                    index={item.index}
                    iconName={item.icon} />;
            });
        return (<div><CustomBreadcrumb arr={["系统管理", "区域管理"]} />
            <Card title="组织区域树形图" className="treeCrad" >
                <Row>
                    <Col span={8} >
                        <Search style={{ marginBottom: 8 }} placeholder="Search" onChange={this.onChange} />

                        <Tree
                            showLine
                            // showIcon
                            onSelect={(nodeKey, node) => {
                                console.log(nodeKey);
                                console.log(node);
                                let selectNode = {
                                    title: node.node.props.titleName,
                                    key: node.node.props.eventKey,
                                    description: node.node.props.description,
                                    parentKey: node.node.props.parentKey,
                                    index: node.node.props.index
                                }
                                console.log(selectNode);
                                this.setState({ selectNode, rightClickNodeTreeItem: null })
                            }}
                            onRightClick={this.onRightClick}
                            onExpand={this.onExpand}
                            expandedKeys={expandedKeys}
                            autoExpandParent={autoExpandParent}
                            draggable
                            blockNode
                            onDragEnter={this.onDragEnter}
                            onDrop={this.onDrop}
                        >
                            {loop(this.state.TreeData, "")}
                        </Tree>
                    </Col>
                    <Col span={16} onClick={() => this.setState({ rightClickNodeTreeItem: null })}>
                        <EditMenuForm selectNode={selectNode} onSubmit={(values) => { this.changeTreeData(values) }} add={() => this.clickRightMenu("add", "")} />
                    </Col>
                </Row>
                {this.getNodeTreeRightClickMenu()}
                <Modal
                    title={"新增"}
                    visible={addVisible}
                    onOk={() => { this.upDataSubmit() }}
                    onCancel={() => { this.setState({ addVisible: false }) }}
                    okText="确认"
                    cancelText="取消"
                    // destroyOnClose
                    afterClose={() => {
                        // this.setState({ selectOne: {} })
                        this.props.form.resetFields();//清空表单
                    }}
                >
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onSubmit={this.upDataSubmit}>
                        <Form.Item
                            label="区域名称"
                        >
                            {getFieldDecorator('title', {
                                rules: [{
                                    required: true, message: '请输入字符!',
                                }],
                                placeholder: "区域名称"
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label="描述"
                        >
                            {getFieldDecorator('description', {
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>

            </Card>
        </div>
        );
    }
}
const MenuClassObj = Form.create()(MenuClass)
export default MenuClassObj
