/**
 * 系统前后端API配置 模拟器
 */
const BASEURL = "http://localhost:5000/";
const APIConfig = {
    MODENAME: "emulator",//模块名称
    BASEURL,
    //URL:配置
    AddDevice: BASEURL + "AddDevice",
    GetDeviceInfo: BASEURL + "GetDeviceInfo",
    GetModelCmdResp: BASEURL + "GetModelCmdResp",

};
export default APIConfig;