import React from 'react'
import { Button, Icon, Popconfirm, message, Card, Collapse, Table, Tag, Modal, Upload, Pagination, DatePicker, Tabs, notification, Select } from "antd";

import Socket from '@/socket/index';
import './index.scss';
import RecordDetail from './recordDetail';
import CompareInfo from './compareInfo';
import $ from "jquery";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
const { Panel } = Collapse;
const { TabPane } = Tabs;

class HistoryRecord extends React.Component {
    state = {
        resuleData: [],
        selectedRows: [],
        vis_detail: false,
        vis_compare: false,
        detailData: [],
        compareData: [],
        taskId: "",
        loading: false,
    }
    componentWillMount() {
        let taskId = this.props.match.params.taskID;
        if (taskId) {
            this.updata({ taskId });
            this.setState({ taskId })
        }
    }
    componentDidMount() {
        // console.log($(".testTable"));

    }
    updata(querys) {
        this.setState({ loading: true });
        Socket.ExecuteAction("QueryTaskResultsInfo", querys, "/QueryTaskResultsInfo", (result) => {
            this.setState({ resuleData: result.data, loading: false });
        }, (result) => {
            this.setState({ loading: false });
            notification.error({
                message: '查询失败',
                description: result.error,
                icon: <Icon type="frown" style={{ color: 'red' }} />,
            })
        })
    }
    creatMacTab(data) {
        return <table>
            <thead>
                {data.map((item, key) => {
                    return <tr key={key}><td>{item}</td></tr>
                })}
            </thead>
        </table>
    }
    creatPortTab(_portArr, mactab) {
        const portArr = _portArr || [];
        const macTabData = mactab || [];
        return <table>
            <thead>
                {portArr.map((portName, key) => {
                    return <tr key={key}><td>{portName}</td><td>{this.creatMacTab(macTabData[portName])}</td></tr>
                })}
            </thead>
        </table>
    }
    createDevMac(data) {
        console.log(data);
        const testTab = data.data;
        const tabTitle = data.title;
        return <table className="testTable">
            <thead>
                <tr><th colSpan={3}>
                    <table className="titleTable"><tbody><tr>
                        {tabTitle.map((item) => {
                            return <th >{item}</th>
                        })}
                    </tr></tbody></table>
                </th></tr>
                {/* <tr><th>{tabTitle[0]}</th><th>{tabTitle[1]}</th></tr> */}
                {testTab.map((item) => {
                    return <tr>
                        <td>{item.deviceIp}</td>
                        <td>{this.creatPortTab(item.portName_link_macTable, item.macTable)}</td>
                    </tr>
                })}

            </thead>
        </table>
    }
    commonTable(title, data) {
        // const colum = title.map((item) => {
        //     return { title: item, dataIndex: item, }
        // })
        console.log(title, data);
        return <Table
            columns={title}
            dataSource={data}
            Pagination={false}
        />
    }

    createDevMacTab(data, keyID) {
        const testTab = data.data;
        const tabTitle = data.title;
        return <table className="testTable" id={"createDevMacTab" + keyID}>
            <thead>
                <tr>
                    {tabTitle.map((item) => {
                        return <th >{item.title}</th>
                    })}
                </tr>
                {/* <tr><th>{tabTitle[0]}</th><th>{tabTitle[1]}</th></tr> */}
                {testTab.map((item) => {
                    return <tr>
                        <td>{item.deviceIp}</td>
                        <td>{item.portName_link_macTable}</td>
                        <td>{item.macTable}</td>
                    </tr>
                })}

            </thead>
        </table>
    }
    creatTabs(dataArr, keyID) {
        return dataArr.map((item, key) => {
            return <TabPane tab={<span><Icon type="profile" />{item.sheetName}</span>} key={key} forceRender={true}

            >
                {item.type === "common" ? this.commonTable(item.title, item.data) : this.createDevMacTab(item, keyID)}
            </TabPane>
        })
    }
    compareResult() {
        let { selectedRows, taskId } = this.state;
        if (selectedRows.length != 2) {
            message.error("请选择两条数据！");
            return;
        }
        let querys = {
            taskId,
        }
        selectedRows.map((item, key) => { querys["date" + (key + 1)] = item.taskEndTime });
        Socket.ExecuteAction("RecordComparison", querys, "/RecordComparison", (result) => {
            let data_all = result.data;
            console.log(data_all);
            let compareData = [data_all.old, data_all.new];
            this.setState({ compareData, vis_compare: true });
        }, (result) => {
            notification.error({
                message: '比较失败',
                description: result,
                icon: <Icon type="frown" style={{ color: 'red' }} />,
            })
        })
    }
    render() {
        let { resuleData, vis_detail, vis_compare, detailData, taskId, loading, selectedRows, compareData } = this.state;
        const colum_page = [
            {
                title: "序号",
                render: (text, item, key) => {
                    return key + 1;
                }
            },
            {
                title: "任务名称",
                dataIndex: "taskName",
            },
            {
                title: "时间",
                dataIndex: "taskEndTime",
            },
            // {
            //     title: "记录条数",
            //     dataIndex: "total",
            // },
            {
                title: "操作",
                render: (text, item, key) => {
                    return <div>
                        <Button type="primary" icon={'project'}
                            onClick={() => { this.setState({ vis_detail: true, detailData: item.taskResult.data }) }}>记录详情</Button>
                    </div>
                }
            },
        ]
        const formList = [
            // {
            //     label: "任务名称",
            //     type: 'INPUT',
            //     field: "taskName",
            //     initialValue: '',
            //     placeholder: "任务名称"
            // },
            {
                label: "关键字",
                type: 'INPUT',
                field: "keyword",
                initialValue: '',
                placeholder: "关键字"
            },
            {
                label: "时间范围",
                type: 'timeSelect',
                field: "time",
                initialValue: '',
            },
        ]
        const selections = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRows });
            },
            hideDefaultSelections: true,
        }
        return <div>
            <CustomBreadcrumb arr={["任务管理", { title: "概览", to: '/overView' }, "历史记录", "任务历史记录"]} />
            <Card>
                <FilterForm
                    formList={formList}
                    filterSubmit={(querys) => {
                        console.log(querys);
                        querys.taskId = taskId;
                        this.updata(querys);
                    }}
                    resetTab={() => {
                        this.updata({ taskId });
                        this.setState({ selectedRows });
                    }}
                />

            </Card>
            <Card title={<span style={{ fontWeight: 600 }}>任务历史记录</span>}
                style={{ marginTop: 8 }} extra={<div><Button type="primary" icon={'diff'}
                    onClick={() => { this.compareResult(); }}>比较</Button>
                    <Button type="primary" icon={'rollback'}
                        onClick={() => { this.props.history.goBack(); }}>返回记录列表</Button></div>}>
                <div>
                    <Table
                        rowSelection={selections}
                        columns={colum_page}
                        dataSource={resuleData}
                        loading={loading}
                        size="small"
                    />
                </div>
            </Card >
            <RecordDetail
                title="任务记录"
                detailData={detailData}
                resuleData={resuleData}
                width="90%"
                onCancel={() => { this.setState({ vis_detail: false }) }}
                onOk={() => { this.setState({ vis_detail: false }) }}
                visible={vis_detail}
                style={{ top: 10 }}
                destroyOnClose
            />
            <CompareInfo
                title="历史分析"
                compareData={compareData}
                width="99%"
                onCancel={() => { this.setState({ vis_compare: false }) }}
                onOk={() => { this.setState({ vis_compare: false }) }}
                visible={vis_compare}
                style={{ top: 10, }}
                destroyOnClose
            />
        </div>
    }
}
export default HistoryRecord