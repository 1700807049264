import React from "react";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import FilterForm from '../../../../components/FilterForm';
import { Button, Card, Col, Divider, Form, Input, Modal, Row, Table } from 'antd'
import formList from "./formList";
import { zx_baseUrl } from '@/pages/NetLine/zx-config'
import { batchDelete, deleteObj, fetchList, fetchObj, patchEdit } from "../../zx-api/device";
import confirm from "antd/es/modal/confirm";
import { exportExcel } from '../../zx-utils/util'
import moment from "moment";
import { editFormItemLayout, newDisabledStyle } from "../../zx-base/layout";


class Device extends React.Component {

    state = {
        formList: [],
        tableData: [],//表格数据
        loading: false, //加载数据后遮罩层
        tableDataTotal: 0,
        tableDataCurrent: 1,
        tableDataPageSize: 10,
        selectedRowKeys: [],
        query: {}, //过滤查询参数
        baseUrl: zx_baseUrl,
        deleteVisible: false,
        detailVisible: false, // 新建、编辑弹窗可见性
        detailLoading: false,
        detailDisable: true, // 控制提交按钮，编辑时方可使用
        editObj: {}, //编辑对象
        isUpdate: false, // false 新建状态，true 详情状态
        isEdit: false, // 还没点击编辑按钮时候，输入框不可编辑，true为不可编辑
        editBtnVisible: true, // 控制编辑按钮是否可见
        submitBtnVisible: false, // 控制编辑按钮是否可见
    }

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        const { tableDataPageSize, tableDataCurrent } = this.state
        this.update(tableDataPageSize, tableDataCurrent);
    }

    componentDidMount() {
        // this.update()
    }

    //更新数据方法
    update = (tableDataPageSize, tableDataPageNum, query) => {
        let response = {}
        this.setState({
            loading: true,
            selectedRowKeys: []
        })
        query = query || {}
        query['page'] = tableDataPageNum
        query['size'] = tableDataPageSize
        fetchList(query, res => {
            response = res.data
            const tableData = response.results
            const tableDataTotal = response.count
            this.setState({ tableData, tableDataTotal, loading: false, tableDataCurrent: tableDataPageNum })
        })
    }


    exportExcel = e => {
        const { tableData } = this.state
        let obj = tableData
        delete obj.id
        let json = obj.map(item => {
            return {
                '设备ID': item.eid,
                '网络设备名称': item.netEName,
                '管理IP地址': item.ip,
                '资源编号': item.resouceNo,
                '序列号': item.serialNum,
                '品牌': item.brand,
                '型号': item.type,
                '小类': item.subclass,
                '机构层级': item.estabLevel,
                '网络区域': item.netArea,
                '功能类别': item.functionType,
                '设备wwn': item.quipWwn,
                '设备运行状态': item.equipStatus,
                '物理位置': item.physicalLocation,
                '供电模式': item.powerSupplyMode,
                'A路电源ID': item.aPowerId,
                'B路电源ID': item.bPowerId,
                '其他IP地址': item.otherIpAddr,
                'MAC地址': item.macAddr,
                '设备用途': item.equipPurpose,
                '电源模块数量': item.NumOfPowerModules,
                '系统版本': item.osVersion,
                '购置时间': item.purchaseTime,
                '安装时间': item.installTime,
                '设备运行时间(天)': item.runDay,
                '备份设备': item.equipBackups,
                '创建时间': item.createTime,
                '最后调整时间': item.lastUpdateTime,
                '最后修改人': item.updateByName,
                '服务商': item.bmc,
                'CI负责机构': item.ciOrganization,
                'CI负责部门': item.ciDepartment,
                '维护来源': item.maintainSource,
                '备注': item.remark,
                '配置项状态': item.configurationItemStatus,
                '所属专业组': item.belongToGroup,
                '标签': item.label,
                '命令集': item.commandSet,
            }
        })
        //导出Excel
        exportExcel(json, '设备表-' + moment().format("YYYYMMDDHHmmss") + '.xlsx')
    }

    //弹出详情界面
    tableDataDetail(id) {
        this.setState({ editObj: {}, isUpdate: true })
        fetchObj(id, res => {
            const editObj = res.data.data
            this.setState({ editObj, detailVisible: true, editBtnVisible: true, isEdit: true, editDisabled: true, })
            this.props.form.setFieldsValue({ ...editObj })
        })
    }

    // 编辑按钮
    handleEdit = () => {
        this.setState({ editDisabled: false, submitBtnVisible: true, editBtnVisible: false })
    }
    // 编辑提交 新建提交
    handSubmit = () => {
        this.handleOk()

        this.setState({ editObj: {}, isUpdate: false })

    }

    // 删除操作
    delete() {
        const { selectedRowKeys } = this.state
        if (selectedRowKeys.length < 2) {
            deleteObj(selectedRowKeys, res => {
                this.update(this.state.tableDataPageSize, 1, {})
            })
        } else {
            batchDelete(selectedRowKeys, res => {
                this.update(this.state.tableDataPageSize, 1, {})
            })
        }
    }

    // 新建操作
    addObj = () => {
        this.props.form.resetFields()
        this.setState({
            isUpdate: false,
            detailVisible: true,
            isEdit: false,
            submitBtnVisible: true,
            editBtnVisible: false
        })
    }

    handleOk = () => {
        this.setState({ detailLoading: true });
        this.props.form.validateFieldsAndScroll((err, value) => {
            let { editObj } = this.state
            editObj = { ...editObj, ...value } // 用value的值去覆盖
            console.log(JSON.stringify(editObj))
            patchEdit(editObj, res => {
                // alert(JSON.stringify(res))
                this.handleCancel()
            })

        })
        // setTimeout(() => {
        //     this.setState({ detailLoading: false, detailVisible: false });
        // }, 3000);
    };

    handleCancel = () => {
        this.setState({
            detailVisible: false, isEdit: false, detailLoading: false, editBtnVisible: false,
            submitBtnVisible: false
        });
    };


    render() {
        const {
            tableDataCurrent, tableDataPageSize, tableDataTotal, query, loading, tableData, selectedRowKeys,
            detailVisible, detailLoading, editDisabled, isEdit, editBtnVisible, submitBtnVisible, isUpdate
        } = this.state

        const { getFieldDecorator } = this.props.form;

        // 表格的列
        const tableDataColumns = [
            {
                title: '设备名称',
                dataIndex: 'netEName',
                key: 'netEName',
                render: (text, item, index) => {
                    return (
                        <div>
                            {
                                <span style={{ color: "blue", cursor: "pointer" }}
                                    onClick={this.tableDataDetail.bind(this, item.id)}>{text}</span>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'IP地址',
                dataIndex: 'ip',
                key: 'ip',
            },
            {
                title: '品牌',
                dataIndex: 'brand',
                key: 'brand',
            },
            {
                title: '型号',
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: '小类',
                dataIndex: 'subclass',
                key: 'subclass',
            },
            {
                title: '机构层级',
                dataIndex: 'estabLevel',
                key: 'estabLevel',
            },
            {
                title: '网络区域',
                dataIndex: 'netArea',
                key: 'netArea',
            },
            {
                title: '功能类别',
                dataIndex: 'functionType',
                key: 'functionType',
            },
            {
                title: '设备运行状态',
                dataIndex: 'equipStatus',
                key: 'equipStatus',
            },
            {
                title: '物理位置',
                dataIndex: 'physicalLocation',
                key: 'physicalLocation',
            },

        ]

        // 上传文件文件参数
        const uploadProps = {
            name: 'file',
            action: zx_baseUrl + '/zx/equip/',
            method: 'post',
            headers: {}
        }

        //控制表格勾选
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                this.setState({ selectedRowKeys });
            }
        }

        return (
            <div>
                <CustomBreadcrumb arr={["专线设备管理", "设备列表"]} />
                <Card>
                    <Row>
                        {/* 筛选 */}
                        <Col span={24}>
                            <FilterForm formList={formList}
                                filterSubmit={(query) => {
                                    this.setState({ query })
                                    this.update(tableDataPageSize, 1, query)
                                }}
                                resetTab={() => {
                                    this.setState({ query: {} })
                                    this.update(tableDataPageSize, 1, {})
                                }}
                            />
                        </Col>
                    </Row>

                </Card><br />

                {/* 设备列表 */}
                <Card title={<span>设备列表</span>}
                    extra={
                        <div>
                            <Button type='default' icon='redo' onClick={e => {
                                this.setState({ query: {} })
                                // this.props
                                this.update(tableDataPageSize, 1, {})
                            }}>刷新</Button>
                            <Button type='default' style={{ color: "#fff", backgroundColor: "green" }}
                                onClick={this.addObj}>新建</Button>
                            <Button type='danger' icon='' onClick={
                                e => {
                                    const _self = this
                                    if (this.state.selectedRowKeys.length > 0) {
                                        confirm({
                                            title: '您确认删除吗?',
                                            content: `您确认要删除这${this.state.selectedRowKeys.length}项吗？`,
                                            okText: '删除',
                                            okType: 'danger',
                                            cancelText: '取消',
                                            onOk() {
                                                _self.delete()
                                            },
                                            onCancel() {

                                            },
                                        });
                                    } else {
                                        alert("请选择要删除的对象！")
                                    }
                                }
                            } style={{}}>
                                删除
                              </Button>
                            <Button type='primary'
                                icon='export'
                                onClick={this.exportExcel}
                                style={{ marginLeft: 8 }}>导出Excel</Button>

                        </div>
                    }
                    sytle={{ marginTop: 18 }}>
                    <Table
                        dataSource={tableData}
                        columns={tableDataColumns}
                        rowSelection={rowSelection}
                        rowKey={value => value.id}
                        loading={loading}
                        size="small"
                        pagination={{
                            current: tableDataCurrent, pageSize: tableDataPageSize, total: tableDataTotal,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                // console.log(page, pageSize, tableDataTotal);
                                this.update(pageSize, page, query)
                            }
                        }}
                    />

                </Card>

                {/* 弹出界面 */}
                <Modal
                    title="确认删除？"
                    visible={this.state.deleteVisible}
                    onOk={this.delete}
                    onCancel={() => {
                        this.setState({ deleteVisible: false })
                    }}
                    okText="确认"
                    cancelText="取消"
                >
                    <p>您确认要删除这<span style={{ 'color': 'red' }}>{this.state.selectedRowKeys.length}</span>项吗？</p>
                </Modal>

                <Modal
                    visible={detailVisible}
                    title={`${this.state.isUpdate ? '详情' : '新建'}`}
                    width='1680px'
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            取消
                        </Button>,
                        <Button style={{
                            backgroundColor: "green",
                            color: 'white',
                            visibility: `${editBtnVisible ? 'visible' : 'collapse'}`
                        }} disabled={!isEdit} onClick={this.handleEdit}>
                            编辑
                        </Button>,
                        <Button key="submit" type="primary" loading={detailLoading}
                            style={{ visibility: `${submitBtnVisible ? 'visible' : 'collapse'}` }}
                            onClick={this.handleOk}>
                            提交
                        </Button>,
                    ]}
                >
                    <Form onSubmit={this.handSubmit} {...editFormItemLayout}>
                        <Row>
                            <Col span={8}>
                                <Form.Item label="设备名称">
                                    {
                                        getFieldDecorator('netEName',
                                            { rules: [{}] }
                                        )(
                                            <Input style={isUpdate ? newDisabledStyle : {}} disabled={editDisabled}
                                                placeholder="设备名称" />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="品牌">
                                    {getFieldDecorator('brand',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="品牌" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="型号">
                                    {getFieldDecorator('type',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="型号" />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label="小类">
                                    {getFieldDecorator('subclass',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="小类" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="设备状态">
                                    {getFieldDecorator('equipStatus',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="设备状态" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="IP地址">
                                    {getFieldDecorator('ip',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="IP地址" />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label="机构层级">
                                    {getFieldDecorator('estabLevel',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="机构层级" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="网络区域">
                                    {getFieldDecorator('netArea',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="网络区域" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="功能类别">
                                    {getFieldDecorator('functionType',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="功能类别" />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label="物理位置">
                                    {getFieldDecorator('physicalLocation',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="设备名称" />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={8}>
                                <Form.Item label="资源编号">
                                    {getFieldDecorator('resouceNo',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="资源编号" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="序列号">
                                    {getFieldDecorator('serialNum',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="序列号" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="系统版本">
                                    {getFieldDecorator('osVersion',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="系统版本" />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label="MAC地址">
                                    {getFieldDecorator('macAddr',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="MAC地址" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="设备用途">
                                    {getFieldDecorator('equipPurpose',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="设备用途" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="最后修改人">
                                    {getFieldDecorator('updateByName',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="最后修改人" />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label="A路电源ID">
                                    {getFieldDecorator('aPowerId',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="A路电源ID" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="B路电源ID">
                                    {getFieldDecorator('bPowerId',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="B路电源ID" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="购置时间">
                                    {getFieldDecorator('purchaseTime',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="购置时间" />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label="安装时间">
                                    {getFieldDecorator('installTime',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="安装时间" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="运行时间(天)">
                                    {getFieldDecorator('runDay',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="设备运行时间(天)" />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="最后调整时间">
                                    {getFieldDecorator('lastUpdateTime',
                                        { rules: [{}] })(<Input style={isUpdate ? newDisabledStyle : {}}
                                            disabled={editDisabled} placeholder="最后调整时间" />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>

        )
    }

}

const DeviceForm = Form.create()(Device)
export default DeviceForm