import React, { useEffect, useState } from 'react'
import Charts from "echarts-for-react"
function PieMap(props) {
    {/* <PieChar data={[{ name: "成功", value: 40 }, { name: "失败", value: 10 }, { name: "异常", value: 4 }]} /> */ }
    // const [data1, setData1] = useState(props.data || []);
    // useEffect(() => {//componentDidMount 
    //     return () => { //componentWillUnmount
    //     }
    // }, []);
    console.log("================================")
    console.log(props)
    function getOptionn(i_data) {
        let data = []
        i_data.map((tt) => {
            if (tt.name === "在线") {
                data.push(tt)
            }
        })
        i_data.map((tt) => {
            if (tt.name === "离线") {
                data.push(tt)
            }
        })
        return {
            legend: {
                orient: "horizontal",
                right: 10,
                textStyle: {
                    color: "#9d9d9d"
                },
                show: data.length < 5
            },
            tooltip: {
                trigger: 'item',
                // formatter: "{b} : {c} ({d}%)"
                formatter: (param) => {
                    const i_param = Array.isArray(param) ? param[0] : param
                    const u_parm = i_param.data
                    let strArr = '<div>'
                    for (let i in u_parm) {
                        strArr += `<div>${i}:${u_parm[i]}</div>`
                    }
                    return strArr + i_param.marker + "</div>"
                }
            },

            // color: ["#3fb1e3", "#6be6c1", "#a0a7e6", '#1890ff', "#ff5338", "#ff5384", "#4456c7", "#ff80a0", '#5ec9db', '#fdc765', '#f27d51', '#6462cc', '#91c7ae', '#749f83', '#f26659', "#c4ebad",],
            // color: ["#4BE1DC", "#FF545B", "#3fb1e3", "#6be6c1", "#a0a7e6", '#1890ff', "#ff5338", "#ff5384", "#4456c7", "#ff80a0", '#5ec9db', '#fdc765', '#f27d51', '#6462cc', '#91c7ae', '#749f83', '#f26659', "#c4ebad",],
            color: ["#31F7FD", "#FF6A72", "#3fb1e3", "#6be6c1", "#a0a7e6", '#1890ff', "#ff5338", "#ff5384", "#4456c7", "#ff80a0", '#5ec9db', '#fdc765', '#f27d51', '#6462cc', '#91c7ae', '#749f83', '#f26659', "#c4ebad",],
            // color: ["#FF6A72", "#31F7FD", "#3fb1e3", "#6be6c1", "#a0a7e6", '#1890ff', "#ff5338", "#ff5384", "#4456c7", "#ff80a0", '#5ec9db', '#fdc765', '#f27d51', '#6462cc', '#91c7ae', '#749f83', '#f26659', "#c4ebad",],
            label: {
                // show: true,
                // position: "inside",
                // position: "outside",
                // formatter: "{b}:{c}\n({d}%)",
                // color: "#fff",

            },

            series: [{
                // name: 'Access From',
                type: 'pie', radius: ['40%', '60%'],
                avoidLabelOverlap: false,
                label: { show: false, position: 'center' },
                emphasis: { label: { show: true, fontSize: '40', fontWeight: 'bold' } },
                // labelLine: { show: false },
                data: data,

                // type: 'pie',
                // radius: ['50%', '70%'],
                // center: ['50%', '60%'],
                avoidLabelOverlap: false,
                label: {
                    show: true,
                    // position: 'inside',
                    formatter: "{b}{c}({d}%)",
                    color: "#9d9d9d"

                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '20',
                        fontWeight: 'bold'
                    }
                },
            }]
        }
    }
    return <Charts option={getOptionn(props.data || [])} />
}
export default React.memo(PieMap)


