/**
 * Created by YIMINE on 2019/1/24.
 */
import React from 'react'
import { Card, Form, Input, message, notification, Icon, Select, Button, Spin, Row, Col, Table, Modal, Descriptions, AutoComplete } from 'antd';
import './index.scss'
import Socket from "../../../socket/index";
import Utils from "../../../utils/utils";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb/index";
import CascOrgain from '@/components/CascOrgain/index';
import MonacoEditor from "react-monaco-editor";
// const RadioGroup = Radio.Group;
const { Option } = Select;
// const TabPane = Tabs.TabPane;
const TextArea = Input.TextArea;
class newResource extends React.Component {
    state = {
        pageSpinning: false,
        cardTitle: "新建资源",
        Tags: [],
        resourceParams: {
            deviceId: "",
            deviceIp: "",
            deviceType: "",
            deviceFirm: "",
            deviceVersion: "",
            deviceOsVersion: "",
            deviceUser: "",
            devicePwd: "",
            deviceSite: "",
            deviceState: "",
            community: "",
            connectionType: "",

        },
        isnew: true,
        AutoObject: null,
        formItemLayout: {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        }
    };
    componentWillMount() {//组件渲染之前调用
        let thisTemp = this;
        let resIdData = this.props.match.params.resId;
        if (resIdData) {
            resIdData = resIdData.replace(":", "");
            let strList = resIdData.split('&');
            this.setState({ pageSpinning: true });
            Socket.ExecuteAction('QueryResourceDetail', { _id: strList[0] }, "/queryResource",
                (resp) => {
                    const resourceData = resp.resource;
                    // console.log(resourceData);
                    let resourceName = '';
                    let resourceParams = resourceData.resourceParams;
                    resourceParams.devicePwd = Utils.DecryptPwd(resourceData.resourceParams.devicePwd);
                    resourceParams.deviceEnablePwd = Utils.DecryptPwd(resourceData.resourceParams.deviceEnablePwd || "");
                    // console.log(resourceParams.devicePwd);
                    // console.log(resourceParams);
                    if (strList[1]) {
                        thisTemp.setState({
                            pageSpinning: false,
                            cardTitle: "复制资源",
                            resourceParams,
                        });
                    } else {
                        resourceName = resp.resource.resourceName;
                        thisTemp.setState({
                            pageSpinning: false,
                            isnew: false,
                            AutoObject: resp.resource, cardTitle: "编辑资源",
                            resourceParams,
                        });
                    }
                    thisTemp.props.form.setFieldsValue({
                        resourceName: resourceName,
                        resourceDes: resp.resource.resourceDes,
                        tags: resp.resource.tags,
                        // groupNames: resp.resource.groupNames,
                        areaId: resp.resource.areaId,
                        resourceType: resp.resource.resourceType,
                        // resourceParams: resp.resource.resourceParams,
                    });
                }, (error) => {
                    message.error('数据返回错误:' + error)
                });

        }
        Socket.ExecuteAction('QueryTagsGroups', { tagClass: 'resource' }, "/QueryTagsGroups", (result) => {
            thisTemp.setState({
                Tags: result.tags,
            });
        });
    }
    translateType(deviceType) {
        switch (deviceType) {
            case "交换机": return "SWITCH";
            case "路由器": return "ROUTER";
            case "防火墙": return "FIREWALL";
            case "负载均衡": return "LOADBALANCE";
            case "其他": return "其他";
        }
    }
    handleSubmit = (e) => {
        let { AutoObject } = this.state;
        let _this = this;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                _this.setState({ pageSpinning: true });
                let body = {
                    "resourceName": values.resourceName,
                    "resourceDes": values.resourceDes || "",
                    "other": values.other,
                    "tags": values.tags || [],
                    "resourceType": values.resourceType,
                    "areaId": values.areaId,
                    "resourceParams": {
                        "deviceId": values.deviceId,
                        "deviceIp": values.deviceIp,
                        "deviceType": this.translateType(values.deviceType),
                        "deviceFirm": values.deviceFirm,
                        "deviceVersion": values.deviceVersion,
                        "deviceOsVersion": values.deviceOsVersion,
                        "deviceUser": values.deviceUser,
                        "devicePwd": Utils.EncryptPwd(values.devicePwd),
                        "deviceEnablePwd": Utils.EncryptPwd(values.deviceEnablePwd),
                        "deviceSite": values.deviceSite,
                        "deviceState": values.deviceState,
                        "community": values.community,
                        "sshport": values.sshport,
                        "deviceOsType": values.deviceOsType,
                        "extend_attrs": AutoObject ? AutoObject.resourceParams.extend_attrs : {},
                    }
                }
                console.log('body', body);
                if (!AutoObject) {//新增
                    Socket.ExecuteAction("AddResource", body, "/newResource", () => {
                        message.success('新增成功');
                        this.props.history.goBack();
                    }, (error) => {
                        notification.open({
                            message: '新增失败',
                            description: error,
                            icon: <Icon type="frown" style={{ color: 'red' }} />,
                        });
                        _this.setState({ pageSpinning: false });
                    });
                } else {//修改
                    body._id = AutoObject._id;
                    Socket.ExecuteAction("UpdateResource", body, "/editResource", () => {
                        message.success('修改成功');
                        this.goBackPage();
                    }, (error) => {
                        notification.open({
                            message: '修改失败',
                            description: error,
                            icon: <Icon type="frown" style={{ color: 'red' }} />,
                        });
                        _this.setState({ pageSpinning: false });
                    });
                }
            }
        });
    };
    goBackPage() {
        let bodyJson = this.props.location.bodyJson;
        let path = {
            pathname: '/resource',
            bodyJson,
        };
        this.props.history.push(path);
    }
    checkTag = (Arr) => {
        Arr.map((text) => {
            if (this.state.Tags.indexOf(text) === -1) {
                Modal.confirm({
                    title: "消息提示",
                    content: "是否添加标签" + text + "到标签管理?",
                    onOk() {
                        Socket.ExecuteAction('AddTag', { tagClass: 'resource', tagName: text }, "/AddTag",
                            () => { message.success('标签新增成功'); }
                        );
                    }
                })
            }
            return text.id
        });
    };
    getDeviceInfo(_this) {
        console.log(_this);
        _this.setState({ pageSpinning: true });
    }
    getFormItem(label, Field, initialValue, type, selectList) {
        const { formItemLayout } = this.state;
        const { getFieldDecorator } = this.props.form;
        let rules = [];
        let elemt = <Input type="text" />;
        if (type === "required") {//字符串，必须
            rules = [{
                type: 'string', message: '请输入正确字符！',
            }, {
                pattern: new RegExp(/^[^\s]+$/, "g"), message: '含有空格等非法字符！',
            }, {
                required: true, message: '请输入字符!',
            }]
        } else if (type === "password") {//字符串密码，不必须
            rules = [{
                type: 'string', message: '请输入正确字符！',
            }, {
                required: true, message: '请输入字符!',
            }];
            elemt = <Input.Password />;
        } else if (type === "password2") {//字符串密码，不必须
            rules = [{
                type: 'string', message: '请输入正确字符！',
            }];
            elemt = <Input.Password />;
        } else if (type === "select") {//字符串，不必须
            return <Form.Item
                label={label}
                {...formItemLayout}
            >
                {getFieldDecorator(Field, {
                    initialValue: initialValue || selectList[0].key,
                    rules: rules,
                })(<Select>
                    {selectList.map((item, key) => {
                        return <Select.Option key={key} value={item.key}>{item.name}</Select.Option>
                    })}
                </Select>)}
            </Form.Item>
        } else if (type === "autoComplete") {
            let selectArr = []
            selectList.map((item, index) => {
                selectArr.push(item.name)
            })
            switch (initialValue.toUpperCase()) {
                case "SWITCH": initialValue = "交换机"; break;
                case "ROUTER": initialValue = "路由器"; break;
                case "FIREWALL": initialValue = "防火墙"; break;
                case "LOADBALANCE": initialValue = "负载均衡"; break;
                default: break;
            }
            return <Form.Item label={label} key={Field} {...formItemLayout}>
                {
                    getFieldDecorator(Field, {
                        initialValue: initialValue || selectArr[0],
                        rules: rules,
                    })(
                        <AutoComplete
                            dataSource={selectArr}
                            allowClear
                            placeholder="输入或选择设备"
                            filterOption={(inputValue, option) =>
                                option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    )
                }
            </Form.Item>;
        } else {//字符串，不必须
            rules = [{
                type: 'string', message: '请输入正确字符！',
            }]
        }

        return <Form.Item
            label={label}
            {...formItemLayout}
        >
            {getFieldDecorator(Field, {
                initialValue: initialValue || "",
                rules: rules,
            })(elemt
                // <Input type={type === "password" ? "password" : "text"} />)} 
                // {type === "text" ? <Button type="primary" onClick={() => this.getDeviceInfo(this)}>获取信息</Button> : ""} 
            )}
        </Form.Item>
    }
    render() {
        const { getFieldDecorator } = this.props.form;

        let { cardTitle, Tags, pageSpinning, resourceParams, AutoObject, formItemLayout, isnew } = this.state;

        return (
            <div >
                <Spin spinning={pageSpinning}>
                    <CustomBreadcrumb arr={["资源管理", { title: "设备列表", to: '/resource' }, cardTitle]} />
                    {/* <Card title={<span style={{ fontWeight: 600 }}>{cardTitle}</span>}> */}
                    <Card title={Utils.BoldFont(cardTitle)} extra={
                        <div>
                            <Button type="primary" icon="rollback" onClick={() => { this.goBackPage(); }}>返回</Button>&nbsp;
                            {isnew ? "" : <Button type="primary" icon="profile" onClick={() => { Utils.scrollToAnchor("#resrecord") }}>查看变更记录</Button>}
                        </div>
                    }>
                        <Form onSubmit={this.handleSubmit}  >
                            <Row>
                                <Col span={12}>{this.getFormItem("资源名称", "resourceName", "", "required")}</Col>
                                <Col span={12}><Form.Item
                                    label="资源类型"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('resourceType', {
                                        initialValue: "netDevice",
                                    })(
                                        <Select
                                            showSearch
                                            placeholder="选择类型"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            <Option value="netDevice">网络设备</Option>
                                            <Option value="physicalServer">物理服务器</Option>
                                            <Option value="virtualSource">虚拟资源</Option>
                                            <Option value="cloudSource">云资源</Option>
                                            <Option value="appSource">应用资源</Option>
                                        </Select>
                                    )}
                                </Form.Item></Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label="组织区域"
                                        {...formItemLayout}
                                    >
                                        {getFieldDecorator('areaId', {
                                            initialValue: AutoObject ? AutoObject.areaId : "",
                                            rules: [{
                                                required: true, message: '请选择组织区域!',
                                            }],
                                            getValueFromEvent: (a) => {
                                                return a[a.length - 1]
                                            }
                                        })(<CascOrgain type="add" />)}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>{this.getFormItem("设备IP地址", "deviceIp", resourceParams.deviceIp, "required")}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>{this.getFormItem("SSH用户名", "deviceUser", resourceParams.deviceUser, "required")}</Col>
                                <Col span={12}>{this.getFormItem("SSH登录密码", "devicePwd", resourceParams.devicePwd, "password")}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>{this.getFormItem("设备厂商", "deviceFirm", resourceParams.deviceFirm, "select",
                                    [...["HUAWEI", "CISCO", "H3C", "MAIPU", "RUIJIE", "JUNIPER", "HILLSTONE", "F5", "DP", "其他"].map((item) => { return { name: item, key: item } })])
                                }</Col>
                                <Col span={12}>{this.getFormItem("特权密码", "deviceEnablePwd", resourceParams.deviceEnablePwd, "password2")}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>{this.getFormItem("设备名称", "deviceId", resourceParams.deviceId)}</Col>
                                <Col span={12}>{this.getFormItem("设备状态", "deviceState", resourceParams.deviceState, "select", [{ name: "未知", key: "none" }, { name: "在线", key: "online" }, { name: "离线", key: "offline" }])}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>{this.getFormItem("SSH连接端口", "sshport", resourceParams.sshport ? resourceParams.sshport + "" : "22",)}</Col>
                                {/* <Col span={12}>{this.getFormItem("设备类型", "deviceType", resourceParams.deviceType, "select", [{ name: "交换机", key: "switch" }, { name: "防火墙", key: "firewall" }, { name: "路由器", key: "router" }, { name: "其他", key: "其他" },])}</Col> */}
                                <Col span={12}>{this.getFormItem("设备类型", "deviceType", resourceParams.deviceType, "autoComplete", [
                                    { name: "交换机", key: "SWITCH" }, { name: "防火墙", key: "FIREWALL" }, { name: "路由器", key: "ROUTER" }, { name: "负载均衡", key: "LOADBALANCE" },
                                    { name: "其他", key: "其他" }
                                ])}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>{this.getFormItem("SNMP口令", "community", resourceParams.community)}</Col>
                                <Col span={12}>{this.getFormItem("设备型号", "deviceVersion", resourceParams.deviceVersion)}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label="添加标签"
                                        {...formItemLayout}
                                    >
                                        {getFieldDecorator('tags', {
                                        })(
                                            <Select
                                                mode="tags"
                                                placeholder="选择标签"
                                                onChange={(arrTag) => this.checkTag(arrTag)}
                                            >
                                                {Tags.map(owner => (
                                                    <Option key={owner} value={owner}>
                                                        {owner}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                {/* <Col span={12}>{this.getFormItem("设备OS类型", "os_type", resourceParams.os_type)}</Col> */}
                                <Col span={12}>{this.getFormItem("设备OS类型", "deviceOsType", resourceParams.deviceOsType || "")}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>{this.getFormItem("网点信息", "deviceSite", resourceParams.deviceSite)}</Col>
                                <Col span={12}>{this.getFormItem("其他信息", "other", resourceParams.other || "")}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label="资源描述"
                                        {...formItemLayout}
                                    >
                                        {getFieldDecorator('resourceDes', {
                                            rules: [{
                                                type: 'string', message: '请输入正确字符！',
                                            }],
                                        })(<TextArea style={{ height: 100 }} />)}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="设备OS版本"
                                        {...formItemLayout}
                                    >
                                        {getFieldDecorator('deviceOsVersion', {
                                            initialValue: resourceParams.deviceOsVersion || "",
                                            rules: [{
                                                type: 'string', message: '请输入正确字符！',
                                            }],
                                        })(<TextArea style={{ height: 100 }} />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                colon={false}
                            >
                                {<div style={{ width: "100%", textAlign: "center" }}>
                                    <Button type="primary" icon="check-circle" htmlType="submit" style={{ width: 150 }}>
                                        保存</Button>&nbsp;
                                            <Button type="danger" icon="close-circle" style={{ width: 150, }}
                                        onClick={() => { this.goBackPage(); }}>
                                        取消</Button>
                                </div>}
                            </Form.Item>

                        </Form>
                    </Card><br />
                    {isnew ? "" :
                        <Card id="resrecord" title={Utils.BoldFont("变更记录")} >
                            <Table
                                columns={[{ title: "序号", render: (a, b, c) => { return c + 1 } },
                                { title: "变更时间", dataIndex: "time", sorter: (a, b) => new Date(a.time) > new Date(b.time), },
                                { title: "类型", dataIndex: "type" },
                                {
                                    title: "操作", dataIndex: "info", render: (a, b) => {
                                        return <Button type="primary" size="small" icon="file-text" onClick={() => {
                                            let content = [];
                                            if (typeof a === "object") {
                                                const keys = Object.keys(a);
                                                let content2 = [];
                                                keys.map((key, index) => {
                                                    if (a[key].constructor === Object) {
                                                        const tempObj = a[key];
                                                        for (let i in tempObj) {
                                                            content2.push(<Descriptions.Item key={index} label={Utils.Translation(i)}>{tempObj[i]}</Descriptions.Item>)
                                                        }
                                                    } else {
                                                        content2.push(<Descriptions.Item key={index} label={Utils.Translation(key)}>{a[key]}</Descriptions.Item>)
                                                    }
                                                })
                                                content = <Descriptions bordered column={1} title={b.time + "记录"} size={"small"} >
                                                    {content2}
                                                </Descriptions>
                                            } else {
                                                content = <MonacoEditor
                                                    value={a}
                                                    language="java"
                                                    theme="vs"
                                                    height="500"
                                                />
                                            }
                                            Modal.info({
                                                title: "信息记录",
                                                width: "70%",
                                                content: content
                                            })
                                        }}>详情</Button>
                                    }
                                }]}
                                dataSource={AutoObject ? AutoObject.updateRecord : []}
                                size="small"
                                pagination={false}
                            />
                        </Card>}

                    {/* </Card> */}
                </Spin>
            </div>
        );
    }
}
const newAutoObjectFrom = Form.create()(newResource);

export default newAutoObjectFrom;