import React from 'react'
import {
    Card, Table, Button, Icon, message, notification, Select, Descriptions, Row, Col, Tag, Modal, Spin,
} from "antd";
import socket from "@/socket/index";
import Utils from "@/utils/utils";
import './index.scss'
export default class ChangeModal extends React.Component {
    state = {
        fileSpin: false,
        modeVlan: "access",
        tagInfoData: { reason: [] },
        resultState: 0,
        vlanData: [],
        changeSingleVlanVis: false,
        changeDeviceInfo: {} // 

    };
    componentWillReceiveProps(props) {
        this.setState({
            changeSingleVlanVis: props.changeSingleVlanVis,
            changeDeviceInfo: props.changeDeviceInfo,
            vlanData: props.vlanData
        })
    }
    SwitchVlanByTagsOne = () => {//单个
        this.setState({ fileSpin: true })
        const { changeDeviceInfo, targetVlan, tagInfoData } = this.state
        let resData = Utils.deepCopy(tagInfoData);
        let bodyJson = {
            vlan: targetVlan,
            ...changeDeviceInfo
        }
        socket.Action("SwitchVlanByTagsOne", bodyJson, (res) => {
            let resSta = 2;
            if(res.retcode === "00") {
                resData = res.data;
                if (resData.result && resData.result === "success") {
                    message.success("切换成功！");
                    resSta = 1;
                } else {
                    message.error("切换失败!");
                }
            } else {
                resData.reason = [res.error];
                message.error("切换失败!");
            }
            this.setState({
                tagInfoData: resData,
                resultState: resSta,
                fileSpin: false,
            })
        }, "SwitchVlanByTagsOne")
    }
    clearResultInfo() {
        this.setState({
            changeSingleVlanVis: false,
            resultState: 0,
            targetVlan: "",
            tagInfoData: { reason: [] },
        })
    }
    render() {
        let { changeSingleVlanVis, fileSpin, vlanData, tagInfoData, resultState, changeDeviceInfo } = this.state;
        return (
            <Modal
                title="切换单个VLAN"
                visible={changeSingleVlanVis}
                onCancel={() => this.clearResultInfo()}
                afterClose={() => {this.props.onCancel();}}
                footer={null}
                destroyOnClose
                width="80%"
            >
                <Row className="changeVlanDiv">
                    <Spin spinning={fileSpin} style={{ zIndex: 9999 }}>
                        <Col>
                        {changeDeviceInfo.device ? <Descriptions title="设备信息" bordered size={"small"} layout="vertical" column={5}>
                            <Descriptions.Item label="设备IP"><span>{changeDeviceInfo.device.deviceIp}</span></Descriptions.Item>
                            <Descriptions.Item label="设备厂商"><span>{changeDeviceInfo.device.deviceFirm}</span></Descriptions.Item>
                            <Descriptions.Item label="端口号"><span>{changeDeviceInfo.device.portName}</span></Descriptions.Item>
                            <Descriptions.Item label="端口类型"><span>{changeDeviceInfo.device.portMode}</span></Descriptions.Item>
                            <Descriptions.Item label="VLAN"><span>{changeDeviceInfo.device.portVlan}</span></Descriptions.Item>
                        </Descriptions> : <div></div>}
                        
                        </Col>
                        <Col>
                            <Descriptions title="参数选择" bordered size={"small"} column={1}>
                                <Descriptions.Item label="端口类型">
                                    <Select defaultValue={this.state.modeVlan}
                                        onChange={(value) => this.setState({ modeVlan: value })}
                                        style={{ width: 300 }}>
                                        <Select.Option key="access">access</Select.Option>
                                        <Select.Option key="trunk">trunk</Select.Option>
                                    </Select>
                                </Descriptions.Item>
                                <Descriptions.Item label="目标VLAN">
                                    <Select style={{ width: 300 }}
                                        onFocus={() => this.props.getAllVlans()}
                                        onChange={(value) => this.setState({ targetVlan: value })}
                                    >
                                        {vlanData.map((itemVlan) => {
                                            return <Select.Option key={itemVlan.vlan}>{itemVlan.vlan}</Select.Option>
                                        })}
                                    </Select>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col>
                            <Descriptions title="结果展示" bordered size={"small"} column={1}>
                                <Descriptions.Item label="结果">
                                    {resultState === 1 ? <Tag color="#87d068"><Icon type="check-circle" />切换成功</Tag> :
                                        (resultState === 2 ? <Tag color="#f50"><Icon type="close-circle" />切换失败</Tag> : '')}
                                </Descriptions.Item>
                                <Descriptions.Item label="说明">
                                    {tagInfoData["reason"] ? tagInfoData["reason"].map((info, key) => {
                                        return <p key={key}>{info}</p>
                                    }) : ''}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Spin>
                </Row>
                <br /><hr />
                <div className="btn-div" style={{ textAlign: 'center' }}>
                    <Button type="primary" ghost onClick={() => this.clearResultInfo() }>取消</Button>
                    <Button type="primary" icon="retweet" style={{ marginLeft: 10 }} onClick={() => { this.SwitchVlanByTagsOne(); }}>切换</Button>
                </div>
            </Modal>
        )
    }
}
