import axios from 'axios'

import base_url from './BaseURL'

// const base_url = "http://localhost:3000/axios-cross/"
axios.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem("token");
        token && (config.headers['X-Access-Token'] = token);
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
export default class Axios {

    /*
    Ajax 请求的数据格式
    const request = {
            options: {
                url: '',
                method: '',
                data: {
                    params: {

                    },
                    isShowLoading: true,
                    status: ''
                },

            }
        }*/
    static state = {
        base_url: base_url,

    }
    static ajax(options) {
        let baseApi = 'https://www.easy-mock.com/mock/5a7278e28d0c633b9c4adbd7/api';
        console.log(base_url + options.url)


        return new Promise((resolve, reject) => {
            axios({
                url: base_url + options.url,
                method: options.method || 'GET',
                baseURL: baseApi,
                params: (options.data && options.data.params) || ''
            }).then((response) => {
                if (response.status === 200) {
                    let data = response.data;
                    resolve(data)
                } else {
                    reject(response.error)
                }
            }).catch((error) => {

                reject(error)
                console.log(error);
            })
        })

    }


    static delete(options, callback) {
        let baseApi = 'https://www.easy-mock.com/mock/5a7278e28d0c633b9c4adbd7/api';
        // console.log(base_url + options.url)
        return new Promise((resolve, reject) => {
            axios.delete(base_url + options.url, options.data).then(
                (response) => {
                    resolve(response)
                    if (typeof callback === "function") {
                        callback(response)
                    }
                }
            ).catch((error) => {
                reject(error)
            })
        })
    }

    static DELETE(options, callback) {
        this.delete(options, callback)
    }

    static DELETE_BODY(options, callback) {
        return new Promise((resolve, reject) => {
            axios({
                url: base_url + options.url,
                method: 'DELETE',
                data: options.data
            }).then((response) => {
                resolve(response)
                if (typeof callback === "function") {
                    console.log(response, options)
                    callback(response)
                }
            }).catch((error) => {
                console.log(error);
            })
        })
    }

    static PUT(action, body, callback, reject) {

        axios.put(base_url + action + "" + body.id + "/", body).then(
            (response) => {
                callback(response);
            }
        ).catch(res => reject(res))
    }

    static GET(action, body, callback) {
        axios.get(base_url + action + "", body).then(
            (response) => {
                callback(response);
            }
        )
    }

    static POST(action, body, callback) {
        axios.post(base_url + action + '', body).then(
            (response) => {
                callback(response)
            }
        )
    }

    static queryData(action, body, callback) {
        axios({
            url: base_url + action + "" + body.id + "/",
            method: 'GET',
            // baseURL: baseApi,
            params: ''
        }).then((response) => {
            if (response.status === 200) {
                let data = response.data;
                callback(data)
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    static PATCH(action, body, callback, reject) {
        axios.patch(base_url + action + '' + body.id.toString() + "/", body, { 'Content-Type': 'application/json' }).then(
            (response) => {
                callback(response);
            }
        ).catch((res) => {
            reject(res)
        })
    }
}
