import React from 'react'
import { Card, Table, Button, message, Popconfirm, Icon, notification, Input, Tag } from "antd";
import Socket from "../../../socket/index";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import Utils from '@/utils/utils';
import AxiosObj from "../../../axios";

// 监控告警后端服务器地址 monitor_address 配置地址：public/flow/config.js
const backUp_Url = monitor_address;
export default class Alarm  extends React.Component{

    state = {
        //filteredInfo: null,
        //sortedInfo: null,
        loading: false,
        _pageSize: 10,
        _current: 1,
        dataSource : [
            // {
            //     index: '1',
            //     status: 'up',
            //     group : 'example',
            //     alarmName: 'CPUHightUsed',
            //     expression: 'a > b',
            //     receiver: "webhook",
            //     describe : "描述"
            // }
        ],
        columns : [
        {
            title: '序号',
            align: 'center',
            dataIndex: 'index',
            render: (text) => { return <span>{text + 1}</span> }
        }, {
            title: '名称',
            align: 'center',
            dataIndex: 'alarmName',

        }, {
            title: '表达式',
            align: 'center',
            dataIndex: 'expression'
        }, {
            title: '接受者',
            align: 'left',
            dataIndex: 'receiver',
        },  {
            title: '描述',
            align: 'center',
            dataIndex: 'describe',
        }, {
                title: '修改',
                width: '15%',
                align: 'center',
                render: (text, item) => {
                    return <div>
                        <Button type="primary" onClick={() => {
                            console.log('text',text,'this.state.json_generator',this.state.json_generator)

                            // this.props.history.push({pathname:'/monitoringAlarm/snmpMonitor/detail',state:{ip: ip, module: module, proms:params}}); /*  要路由修改*/
                        }}>
                            修改
                        </Button>
                    </div>
                }
            },
            ],
        receiverDataSource : [
            // {
            //     index: 0,
            //     status: 'Active',
            //     receiverName: 'admin',
            //     type: '邮件',
            //     describe: '通过邮件接收',
            // },
        ],
        receiverColumns : [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'index',
                render: (text) => { return <span>{text + 1}</span> }
            },
            // {
            //     title: '状态',
            //     align: 'center',
            //     dataIndex: 'status'
            // },
            {
                title: '名称',
                align: 'center',
                dataIndex: 'receiverName',

            },
            {
                title: '接收地址/邮箱',
                align: 'center',
                dataIndex: 'address',

            },{
                title: '类型',
                align: 'center',
                dataIndex: 'type'
            },  {
                title: '描述',
                align: 'center',
                dataIndex: 'describe',
            },{
                title: '修改',
                width: '15%',
                align: 'center',
                render: (text, item) => {
                    return <div>
                        <Button type="primary" onClick={() => {
                            console.log('text',text,'this.state.json_generator',this.state.json_generator)

                            // this.props.history.push({pathname:'/monitoringAlarm/snmpMonitor/detail',state:{ip: ip, module: module, proms:params}}); /*  要路由修改*/
                        }}>
                            修改
                        </Button>
                    </div>
                }
            },],
        ruleSelectedRowKeys : [],
        ruleSelectedRows : [],
        receiverSelectedRows: [],
        receiverSelectedRowKeys : [],
    };
    componentWillMount(){
        this.getRuleList();
        this.getReceiverList();

    }
    //获取告警列表  里面有一处要改，因为只有一个告警规则组
    getRuleList=()=>{
        AxiosObj.MA_HTTP_GET(backUp_Url+"/snmpexporter/ruleList",(response)=>{
            this.setState({ruleSelectedRows : [],ruleSelectedRowKeys:[]}) //当重新获取列表时，把选中的项清空
            //console.log(response)
            //console.log(response.data)
            //

            // let rules =response.data[0].rules;
            // let group = response.data[0].name;
            let rules =response.data;
            let list = [];
            console.log(rules);
            for(let i in rules){
                let map ={}
                console.log(i);
                map['index'] =parseInt(i);
                map['key'] =parseInt(i);
                // map['group'] = group;
                if(rules[i].receiver){
                    map['receiver']=rules[i].receiver
                }
                map['alarmName'] = rules[i].alert;
                map['expression'] = rules[i].expr;
                map['describe'] = rules[i].annotations.description;
                //map['receiver'] = rules[i].endsAt;
                list[i] = map;
            };
            this.setState({
                dataSource : list,
            })
            console.log(list);
        },(error)=>{
            message.error(error);
        })

    }

    //删除告警规则
    deleteRuleList=()=>{
        let {ruleSelectedRows,ruleSelectedRowKeys} = this.state;
        //单选操作
        let rule = {}
        if(ruleSelectedRows[0]){
            rule['name'] = ruleSelectedRows[0].group;
            rule['alert'] = ruleSelectedRows[0].alarmName;
        }else{
            message.error('请先选择要删除的规则')
            return
        }

        console.log(rule);
        AxiosObj.HTTP_DELETE('alarm','/snmpexporter/deleteRule',rule,(data)=>{
            message.success('success');
            this.getRuleList();
        },(error)=>{
            message.error(error);
        })
    }

    //获取接受者列表
    getReceiverList=()=>{
        AxiosObj.MA_HTTP_GET(backUp_Url+"/snmpexporter/receiverList",(response)=>{
            this.setState({receiverSelectedRows:[],receiverSelectedRowKeys:[]})
            //console.log(response)
            //console.log(response.data)
            let receiverList = response.data.receivers.receiver;
            let list = [];
            for(let i in  receiverList){
                let map ={};
                map['index'] = parseInt(i);
                map['key'] = parseInt(i); //table 的唯一ID
                let configName;
                console.log(receiverList[i])

                for(let tmpIndex in receiverList[i]){
                    // console.log('键',tmpIndex);
                    if(tmpIndex == 'webhook_configs'){
                        configName='webhook_config';
                        map['type'] = 'webhook';
                        let address =[];
                        for(let j in receiverList[i].webhook_configs){
                            address.push(receiverList[i].webhook_configs[j].url)
                        }
                        map['address'] = address;
                        break;
                    }else if(tmpIndex == 'email_configs'){
                        configName='email_configs';
                        map['type'] = 'email';
                        let address =[];
                        for(let j in receiverList[i].email_configs){
                            address.push(receiverList[i].email_configs[j].to)
                        }
                        map['address'] = address;
                        break;
                    }
                }

                //接受者状态
                // if(receiverList[i][configName].send_resolved){
                //     map['status'] = 'Active';
                // }else{
                //     map['status'] = 'Firing'
                // }
                map['receiverName'] = receiverList[i].name ;
                //map['type'] = receiverList[i]
                // map['describe'] = '描述待添加';
                list[i] = map;
            }

            this.setState({
                receiverDataSource : list,
            })
            console.log(list);

        },(error)=>{
            message.error(error);
        })

    }

    //删除接受者列表
    deleteReceiverList=()=>{
        let {receiverSelectedRows,receiverSelectedRowKeys} = this.state;
        //单选操作
        let receiver = {}
        if(receiverSelectedRows[0]){
            //receiver['global'] = receiverSelectedRows[0].group;
            receiver['name'] = receiverSelectedRows[0].receiverName;
        }else{
            message.error('请先选择要删除的规则')
            return
        }
        console.log(receiver);
        AxiosObj.HTTP_DELETE('alarm','/snmpexporter/deleteReceiver',receiver,(data)=>{
            message.success('success');
            this.getReceiverList();
        },(error)=>{
            message.error(error);
        })
    }

    render(){

        const ruleRowSelection = {
            selectedRowKeys: this.state.ruleSelectedRowKeys,
            onChange: (ruleSelectedRowKeys, ruleSelectedRows) => {
                console.log(`ruleSelectedRowKeys:`, ruleSelectedRowKeys, 'ruleSelectedRows: ', ruleSelectedRows);
                this.setState({
                    ruleSelectedRowKeys:ruleSelectedRowKeys,
                    ruleSelectedRows : ruleSelectedRows,
                });
            },
            // selections: [
            //     {
            //         key: 'cancel',
            //         text: '清空选择',
            //         onSelect: changableRowKeys  => {
            //
            //             this.setState({ ruleSelectedRowKeys: [] });
            //         },
            //     },
            // ],
        };
        const receiverRowSelection = {
            selectedRowKeys: this.state.receiverSelectedRowKeys,
            onChange: (receiverSelectedRowKeys, receiverSelectedRows) => {
                console.log(`receiverSelectedRowKeys:`, receiverSelectedRowKeys, 'receiverSelectedRows: ', receiverSelectedRows);
                this.setState({
                    receiverSelectedRowKeys : receiverSelectedRowKeys,
                    receiverSelectedRows : receiverSelectedRows,
                });
            },
            // selections: [
            //     {
            //         key: 'cancel',
            //         text: '清空选择',
            //         onSelect: changableRowKeys => {
            //             this.setState({ receiverSelectedRowKeys: [] });
            //         },
            //     },
            // ],
        };
        let { _current, _pageSize, loading,  dataSource, columns , receiverDataSource,receiverColumns,ruleSelectedRowKeys,receiverSelectedRowKeys} = this.state;
        return(
            <div>
                <CustomBreadcrumb arr={["监控告警", "告警"]} />
                <Card title={<span style={{ fontWeight: 600 }}>告警规则</span>}
                      style={{ marginTop: 8 }}
                      extra={true ?
                          <span><Button type="primary" icon="edit" style={{ marginLeft: 8 }}
                                        onClick={() => { this.props.history.push('/monitoringAlarm/alarm/addRule'); }}>{/* 记的修改路由 */}
                                         添加告警规则
                                 </Button>
                                 <Button type="primary" icon="edit" style={{ marginLeft: 8 }}
                                         onClick={()=>this.deleteRuleList()}>{/* 记的修改路由 */}
                                         删除告警规则
                                 </Button>
                          </span>

                          : ''}>

                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        rowSelection={{
                            type: 'radio',
                            ...ruleRowSelection}}
                        size="small"
                        loading={loading}
                        onChange={() => this.handleChange}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>告警接收者</span>}
                      style={{ marginTop: 8 }}
                      extra={true ?
                          <span>
                              <Button type="primary" icon="edit" style={{ marginLeft: 8 }}
                                       onClick={() => { this.props.history.push('/monitoringAlarm/alarm/addReceiver'); }}>{/* 记的修改路由 */}
                                       添加接收者
                              </Button>
                               <Button type="primary" icon="edit" style={{ marginLeft: 8 }}
                                       onClick={() => { this.deleteReceiverList(); }}>{/* 记的修改路由 */}
                                       删除接收者
                              </Button>
                          </span>

                          : ''}>

                    <Table
                        dataSource={receiverDataSource}
                        columns={receiverColumns}
                        rowSelection={{
                            type: 'radio',
                            ...receiverRowSelection}}
                        size="small"
                        loading={loading}
                        onChange={() => this.handleChange}
                    />
                </Card>

            </div>
        )
    }
}

