import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, Divider, Popconfirm, Row, Col, Descriptions, Input, Typography, Drawer, Modal
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import EditModal from '@/components/EditModal/index';
import BaseForm from '@/components/FormikForm/index';
import RightCard from '../utils/RightCard';
import { connect } from 'react-redux'
import http from '@/axios/axios_utils';
import api from '@/config/api/emulator';
import TreeElemt from './tree';
import "animate.css"
//10.32.79.60
//10.32.24.24
function DeviceType(props) {
    const [visible, setVisible] = useState(false);
    const [total,] = useState(10);
    const [_pageSize] = useState(20);
    const [_current] = useState(1);
    const [] = useState({});
    const [selectItem, setSelectItem] = useState({});
    const [selectTree, setSelectTree] = useState({});
    const [selectedRowKeys, setRowKeys] = useState([])
    // const [tableData, setTabData] = useState([
    //     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    // ].map((i, k) => (
    //     { IPAddress: "192.1.1.1" + i, Vendor: "Cisco", Class: "Switch", Type: "ASA0001", Version: "1.0" + i })))
    const [tableData, setTabData] = useState([])
    const columns = [
        {
            title: 'IP',
            dataIndex: 'ip',
            ellipsis: true,
            // render: (text, item) => {
            //     return <a onClick={() => {
            //         setSelectItem(item); setVisible(true)
            //     }}>{text}</a>
            // }
        },
        {
            title: '主机名',
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '厂商',
            dataIndex: 'vendor',
            ellipsis: true,
        },
        {
            title: '类型',
            dataIndex: 'class',
            ellipsis: true,
        },
        {
            title: '型号',
            dataIndex: 'type',
            ellipsis: true,
        },
        {
            title: '版本',
            dataIndex: 'version',
            ellipsis: true,
        },
        // {
        //     title: '操作',
        //     render: (text, item) => {
        //         return <div>
        //             <a onClick={() => { setSelectItem(item); setVisible(true) }}><Icon type="edit" /></a>
        //             <Divider type="vertical" />
        //             <Popconfirm
        //                 title="你确定删除所选记录吗?"
        //                 onConfirm={() => { deleteData(item) }}
        //                 okText="是"
        //                 cancelText="否"
        //             >
        //                 <a><Icon type="delete" /></a>
        //             </Popconfirm>
        //         </div>
        //     }
        // },
    ]
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
        },
        columnWidth: 50,
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    useEffect(() => {//componentDidMount;
        // getDevice()
        return () => { //componentWillUnmount
        }
    }, []);
    function saveDataSource(val) {
        http.get(api.AddDevice, val, (r) => {
            getDevice();
        })
        setVisible(false)
    }
    function deleteData(val) {
        let temp = tableData.filter(item => item.name != val.name);
        setTabData(temp);
    }
    function getDevice() {
        http.get(api.GetDeviceInfo, {}, (r) => {
            const t = r.result;
            setTabData(t);
            setSelectItem(t[0] || {})
        })
    }
    function setRowClass(r) {
        return r.ip === selectItem.ip ? 'click-Row-Style' : '';
    }
    return <div className="emulatorDevice">
        <CustomBreadcrumb arr={["模拟器管理", "类型管理"]} />
        <Row gutter={10}>
            <Col span={8}>
                <Card
                    title="类型树状图"
                    className="tableCard"
                >
                    <TreeElemt onSelect={(nodeKey, node) => {
                        console.log(nodeKey, node);
                        const keys = nodeKey[0] ? nodeKey[0].split("?") : [];
                        if (keys.length === 4) {
                            setSelectItem({
                                vendor: keys[0],
                                class: keys[1],
                                type: keys[2],
                                version: keys[3],
                            })
                        } else if (keys.length === 3) {
                            setSelectItem({
                                vendor: keys[0],
                                class: keys[1],
                                type: keys[2]
                            })
                        } else if (keys.length === 2) {
                            setSelectItem({
                                vendor: keys[0],
                                class: keys[1]
                            })
                        } else if (keys.length === 1) {
                            setSelectItem({ vendor: keys[0] })
                        } else {
                            setSelectItem({})
                        }
                    }} />
                </Card>
            </Col>
            <Col span={16}>
                <Card
                    title="设备类型信息"
                    className="tableCard">
                    <RightCard selectItem={selectItem} {...props} />
                </Card>
            </Col>
        </Row>
        <EditModal
            title="编辑信息"
            visible={visible}
            onCancel={() => setVisible(false)}
            onSubmit={(val) => {
                saveDataSource(val);
            }}
            footer={null}
            width="55%"
            initialValues={selectTree}
            destroyOnClose
            formList={[
                // { type: "input", label: "IP", name: "ip", required: true, placeholder: "IP" },
                // { type: "input", label: "主机名", name: "name", required: true, placeholder: "主机名" },
                { type: "input", label: "厂商", name: "vendor", required: true, placeholder: "厂商" },
                // {
                //     type: "select", name: "type", label: "类型", placeholder: "类型",
                //     selectOptions: [{ name: "prometheus", value: "prometheus" }, { name: "zabbix", value: "zabbix" }]
                // },
                { type: "input", label: "类型", name: "class", required: true, placeholder: "类型" },
                { type: "input", label: "型号", name: "type", required: true, placeholder: "型号" },
                { type: "input", label: "版本", name: "version", required: true, placeholder: "版本" },
            ]}
        />

    </div>
}

export default connect()(DeviceType)