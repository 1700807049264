import React from 'react'

import { Decoration5, Decoration8 } from '@jiaminghi/data-view-react'
import { withRouter } from "react-router-dom";
// import './TopHeader.scss'
import Socket from "@/socket/index";
class BigScreen extends React.Component {

    state = {
        title: "网络运营数据大屏",
    };
    componentWillMount() {
        this.getInterValCfg()
    }
    getInterValCfg = () => {
        Socket.getConfig("ScreenTitle", (r) => {
            if (r && typeof r === "string") {
                this.setState({title:r})
            }
        })
    }
    render() {
        return (
            <div id="top-header">
                <Decoration8 className="header-left-decoration" />
                <Decoration5 className="header-center-decoration" />
                <Decoration8 className="header-right-decoration" reverse={true} />
                <div className="center-title">{this.state.title}</div>
            </div>
        )
    }
}
export default withRouter(BigScreen)