const formItemLayout = {
    labelCol: {
        xs: {span: 15},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 16},
        sm: {span: 14},
    },
};
const formList = [
    {
        label: '网络设备',
        type: 'INPUT',
        field: 'netEName',
        initialValue: '',
        placeholder: '网络设备'
    },
    {
        label: 'IP地址',
        type: 'INPUT',
        field: 'ip',
        initialValue: '',
        placeholder: 'IP地址'
    },
    {
        label: '机构层级',
        type: 'INPUT',
        field: 'estabLevel',
        initialValue: '',
        placeholder: '机构层级'
    },
    {
        label: '网络区域',
        type: 'INPUT',
        field: 'netArea',
        initialValue: '',
        placeholder: '网络区域'
    },
]

export default formList