import {
    Card, Tabs,
} from "antd";
import React, { useState } from 'react'
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import { connect } from 'react-redux'
import Templates from "./template"
import "./index.scss"
import "animate.css"
function AlertTemplate(props) {
    // const [t_type, set_type] = useState("email");
    return <div className="monitorAlertTemplate">
        <CustomBreadcrumb arr={["监控告警", "通知模板"]} />
        <Card title="通知模板">
            {/* <Tabs onChange={(v) => set_type(v)}>
                <Tabs.TabPane tab="邮件模板" key="email">
                </Tabs.TabPane>
                <Tabs.TabPane tab="短信模板" key="sms">
                </Tabs.TabPane>
            </Tabs> */}
            <Templates {...props} />
        </Card>
    </div>
}

export default connect()(AlertTemplate)