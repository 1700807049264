import React from 'react'
import axios from '@/axios/axios_utils';
import Socket from "@/socket/index";
import Utils from "../../utils/utils";
import './index.scss';
import { Table, Collapse, Card, Button, Divider, Modal, Tabs, Tooltip, Row, Col, message, Icon, Tag } from "antd";
import MonacoEditor from 'react-monaco-editor';
const dumbTerminal_Url = "http://" + backup_address + "/ZJNMS_V1/dumb_termial/";

class bindDumbRecord extends React.Component {
    state = {
        tableData: [], // 表格数据
        current_page: 1,  // 当前页
        pageSize: 20,
        total: 0,
        loading: false,
        visible: false,
        detailInfo: "",
        detailIp: "",
        detailIndex: 1,
        listTile: "绑定记录列表",
        dep_ids: [],
    }

    componentWillMount() {
        this.getDepIds()
    }
    initFn() {
        if (this.props.location) {
            if (this.props.location.item) {
                this.getTableData();
            } else {
                this.goBack();
            }
        } else {
            this.setState({ listTile: "哑终端绑定记录" })
            this.getTableData();
        }
    }
    getDepIds = () => {
        Socket.ExecuteAction('QueryArea', {}, "", (result) => {
            const TreeData = result.data;
            let dep_ids = this.dealTreeData(TreeData);
            this.setState({ dep_ids });
            this.initFn();
        });
    };
    dealTreeData(data) {
        let that = this;
        let dep_ids = [];
        if(Array.isArray(data)) {
            data.map((item) => {
                let children = item.props ? item.props.children : item.children
                if(children && children.length > 0) {
                    dep_ids = dep_ids.concat(that.dealTreeData(children));
                } else {
                    dep_ids.push(item.key || item.eventKey)
                }
            })
        }
        return dep_ids
    }
    getTableData(cur_page, page_size) {
        this.setState({ loading: true, });
        const { current_page, pageSize, dep_ids } = this.state;
        let bodyJson = {
            page_no: cur_page || current_page,
            page_size: page_size || pageSize,
            dep_ids: dep_ids.join(","),
        };
        if (this.props.location) {
            const { item } = this.props.location
            bodyJson.mac_address = item.mac_address;
            // bodyJson.ip = item.ip;
        }
        console.log(bodyJson)
        axios.get(dumbTerminal_Url + "bindRecord", bodyJson, (res) => {
            if (res.code === "00") {
                this.setState({
                    tableData: res.data,
                    total: res.count,
                    current_page: cur_page || res.page_no || 1,
                    loading: false,
                })
            } else {
                this.setState({ loading: false })
            }
        })
    }
    goBack() {
        this.props.history.push({
            pathname: `/dumbTerminal`,
            bodyJson: this.props.location.bodyJson,
        });
    }

    render() {
        const { current_page, total, pageSize, visible, tableData, loading, detailInfo, detailIp, detailIndex, listTile } = this.state;
        const columns = [
            {
                title: '序号',
                width: 50,
                dataIndex: 'key',
                render: (text, item, index) => {
                    return <span>{index + 1 + (current_page - 1) * pageSize}</span>
                }
            },
            {
                title: 'mac地址',
                align: 'center',
                dataIndex: 'mac_address',
                width: 100,
                ellipsis: true,
            },
            {
                title: 'IP',
                align: 'center',
                dataIndex: 'ip',
                width: 100,
                ellipsis: true,
            },
            {
                title: '绑定操作',
                align: 'center',
                dataIndex: 'bind_action',
                width: 120,
                ellipsis: true,
                render: (text, item, index) => {
                    switch (text) {
                        case 'bind': return '绑定';
                        case 'unbind': return '解绑';
                    }
                }
            },
            {
                title: '结果',
                align: 'center',
                dataIndex: 'result',
                width: 100,
                ellipsis: true,
                render: (text, item, index) => {
                    switch (text) {
                        case 'success': return (<Tag style={{ marginRight: 0 }} color={Utils.ColorShow("success")}>成功</Tag>)
                        case 'fail': return (<Tag style={{ marginRight: 0 }} color={Utils.ColorShow("fail")}>失败</Tag>)
                    }
                }
            },
            {
                title: '操作人',
                align: 'center',
                dataIndex: 'operator',
                width: 120,
                ellipsis: true,
            },
            {
                title: '操作时间',
                align: 'center',
                dataIndex: 'create_time',
                width: 120,
                ellipsis: true,
            },
            {
                title: '详情',
                align: 'center',
                dataIndex: 'detail',
                width: 120,
                render: (text, item, index) => {
                    return <Button type="primary" style={{ marginLeft: 10 }} size="small" icon="file-text" onClick={() => {
                        this.setState({
                            visible: true,
                            detailInfo: text,
                            detailIp: item.ip,
                            detailIndex: index + 1 + (current_page - 1) * pageSize
                        })
                    }}>查看</Button>
                }
            }
        ]

        return (
            <div className="bindRecordList">
                <Card
                    title={<span style={{ fontWeight: 600 }}>{listTile}</span>} style={{ marginTop: 8 }}
                    extra={(() => {
                        if (this.props.location) {
                            return <Button type="primary" icon="rollback" onClick={() => { this.goBack() }}>返回</Button>
                        }
                    })()
                    }
                >
                    <Table
                        style={{ marginTop: 10 }}
                        dataSource={tableData || []}
                        columns={columns}
                        size='small'
                        loading={loading}
                        rowKey={(text) => { return text._id }}
                        pagination={{
                            pageSize: pageSize, current: current_page, total: total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.setState({ pageSize: size })
                                this.getTableData(1, size)
                            },
                            onChange: (page, pageSize) => {
                                this.getTableData(page, pageSize);
                            }
                        }}
                    />
                </Card>
                <Modal
                    title={`哑终端ip:${detailIp}的绑定记录序号为${detailIndex}的详情`}
                    visible={visible}
                    width="60%"
                    style={{ top: 50 }}
                    onCancel={() => this.setState({ visible: false })}
                    onOk={() => this.setState({ visible: false })}
                >
                    <MonacoEditor
                        language="java"
                        theme={"vs"}
                        width="99%"
                        height="300"
                        value={detailInfo}
                    />
                </Modal>
            </div>
        )
    }
}

export default bindDumbRecord;
