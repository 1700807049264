import React from 'react'
import { Form, Input, Button, notification, Icon, Checkbox, Carousel, Row, Col, Layout } from 'antd'
import Footers from '@/components/Footer'
import TweenOne from 'rc-tween-one';
import Texty from 'rc-texty';
import './index.scss'
import FormModal from '@/components/EditModal/index';
import Socket from '@/socket/index'
import Utils from '@/utils/utils'
import AxiosObj from '@/axios/index'
import $ from "jquery"
import "animate.css"
const { Content, Header, Footer } = Layout
const FormItem = Form.Item;


export default class Login extends React.Component {
    state = {
        isSubmit: false,
        LicenseVis:false
    };
    componentWillMount() {
        this.versionName = sys_config.versionName;
    }


    loginReq = (params) => {
        // console.log(this.state.isRember);
        let data = {
            'userName': params.username,
            'userPwd': Utils.StrToBase64(params.password),
        };
        let thisTemp = this;
        let thisTemp2 = params._this;
        thisTemp2.setState({ isSubmit: true });
        Socket.Login('Login', data, (result) => {
            if (result.retcode === '00') {
                sessionStorage.setItem('userName', params.username);
                sessionStorage.setItem('nickName', result.userName);
                sessionStorage.setItem('userId', result.userId);
                sessionStorage.setItem('token', result.token);
                sessionStorage.setItem('menuList', JSON.stringify(result.menuList));
                // sessionStorage.setItem('menuList', JSON.stringify(menuTemp));
                sessionStorage.setItem('FixedBtn', JSON.stringify(Utils.getChildrenDisplayKey(result.menuList)));
                Socket.SetData(result.userId, result.token, params.username, result.userName);
                AxiosObj.setSysName(params.username, result.userName);
                thisTemp.props.history.push('/home');

            } else {
                notification.open({
                    message: '登录失败',
                    description: result.error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                thisTemp2.setState({ isSubmit: false });
                if (result.retcode === '04') {
                    this.setState({LicenseVis:true})
                }
            }
        });
    };

    geInterval = (e) => {
        switch (e.index) {
            case 0:
                return 0;
            case 1:
                return 150;
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                return 150 + 450 + (e.index - 2) * 10;
            default:
                return 150 + 450 + (e.index - 6) * 150;
        }
    };
    getEnter = (e) => {
        const t = {
            opacity: 0,
            scale: 0.8,
            y: '-100%',
        };
        if (e.index >= 2 && e.index <= 6) {
            return { ...t, y: '-30%', duration: 150 };
        }
        return t;
    }

    getSplit = (e) => {
        const t = e.split(' ');
        const c = [];
        t.forEach((str, i) => {
            c.push((
                <span key={`${str}-${i}`}>
                    {str}
                </span>
            ));
            if (i < t.length - 1) {
                c.push(<span key={` -${i}`}> </span>);
            }
        });
        return c;
    };

    onClickMot = () => {
        this.setState({
            show: false,
        }, () => {
            this.setState({
                show: true
            });
        });
    };

    getHeardTitle() {
        return <div style={{ marginLeft: 20 }} className="animated fadeInDown"><img src="/image/long-logo.gif" /></div>

    }
    getCarousel() {
        return <Carousel
            autoplay
            // dotPosition="right"
            effect="fade"
            className={"CarouselDiv2"}
        >
            {/* <img className={"CarouselImg2"} src="/image/banner05.png" />
            <img className={"CarouselImg2"} src="/image/p1.png" /> */}
            <img className={"CarouselImg2"} src="/image/banner/1.jpg" />
            <img className={"CarouselImg2"} src="/image/banner/2.jpg" />
            <img className={"CarouselImg2"} src="/image/banner/3.jpg" />
            <img className={"CarouselImg2"} src="/image/banner/4.jpg" />
            <img className={"CarouselImg2"} src="/image/banner/5.jpg" />
            <img className={"CarouselImg2"} src="/image/banner/6.jpg" />
        </Carousel>
    }
    getCarousel2() {
        return <Carousel
            autoplay
            // dotPosition="right"
            effect="fade"
            className={"CarouselDiv"}
        >
            <img className={"CarouselImg"} src="/image/banner/1.jpg" />
            <img className={"CarouselImg"} src="/image/banner/2.jpg" />
            <img className={"CarouselImg"} src="/image/banner/3.jpg" />
            <img className={"CarouselImg"} src="/image/banner/4.jpg" />
            <img className={"CarouselImg"} src="/image/banner/5.jpg" />
            <img className={"CarouselImg"} src="/image/banner/6.jpg" />
            <img className={"CarouselImg"} src="/image/banner/7.jpg" />
        </Carousel>
    }
    getVersionLogo() {
        // return <div className="login-img2 animated flipInX">
        //     <img className="login2" src="/image/zjzd-logo.gif" />
        // </div>

        return <div>
            <div className="login-img2 animated flipInX">
                <img className="login2" src="/image/zjzd-logo.gif" />
            </div>
            <Texty
                className={"title2"}
                type="mask-top"
                delay={400}
                enter={this.getEnter}
                interval={this.geInterval}
                component={TweenOne}
                componentProps={{
                    animation: [
                        { x: 130, type: 'set' },
                        { x: 100, delay: 500, duration: 450 },
                        {
                            ease: 'easeOutQuart',
                            duration: 300,
                            x: 0,
                        },
                        {
                            letterSpacing: 0,
                            delay: -300,
                            scale: 0.9,
                            ease: 'easeInOutQuint',
                            duration: 1000,
                        },
                        { scale: 1, delay: -300, duration: 1000, ease: 'easeInOutQuint' },
                    ],
                }}
            >自动化运维平台</Texty>

            <TweenOne
                className="combined-bar2"
                animation={{ delay: 2000, width: 0, x: 158, type: 'from', ease: 'easeInOutExpo' }}
            />
        </div>
    }
    getVersionLogo2() {
        return <div>
            <div className="login-img3 animated flipInX">
                <img className="login2" src="/image/logo.png" />
                <Texty
                    className={"title3"}
                    type="mask-top"
                    delay={400}
                    enter={this.getEnter}
                    interval={this.geInterval}
                    component={TweenOne}
                    componentProps={{
                        animation: [
                            { x: 130, type: 'set' },
                            { x: 100, delay: 500, duration: 450 },
                            {
                                ease: 'easeOutQuart',
                                duration: 300,
                                x: 0,
                            },
                            {
                                letterSpacing: 0,
                                delay: -300,
                                scale: 0.9,
                                ease: 'easeInOutQuint',
                                duration: 1000,
                            },
                            { scale: 1, delay: -300, duration: 1000, ease: 'easeInOutQuint' },
                        ],
                    }}
                >自动化运维平台</Texty>

            </div>
            <TweenOne
                className="combined-bar3"
                animation={{ delay: 2000, width: 0, x: 158, type: 'from', ease: 'easeInOutExpo' }}
            />

        </div>
    }
    updateLicense(val) {
        console.log(val)
        Socket.setConfig("system_license", val["system_license"], (res) => {
            console.log(res)
            this.setState({LicenseVis:false})
        });
    }
    render() {
        return (
            <div className="ZJNMSlogin2_0Div">
                <div className="login-page2" id='backgroundBox'>
                    <Layout>
                        <Header>
                            <div className="login-header2">
                                {this.getHeardTitle()}
                            </div>
                        </Header>
                        <Content>
                            <div className="login-content-wrap2">
                                {/* {this.getCarousel2()} */}
                            </div >
                            <div className="login-content2">

                                <div className="image animated fadeIn">
                                    <div className="infoImages" />
                                    {this.getCarousel()}
                                </div>

                                <div className="login-box2 animated fadeIn">
                                    {/* {this.getVersionLogo()} */}
                                    {this.getVersionLogo2()}
                                    <LoginForm ref="login" loginSubmit={this.loginReq} />
                                </div>
                            </div >

                        </Content>
                        <Footer className="login2_0_footerDiv"> <Footers /></Footer>
                    </Layout>

                    <FormModal
                        title="软件已过期，请重新配置License"
                        visible={this.state.LicenseVis}
                        onCancel={() => this.setState({LicenseVis:false})}
                        onSubmit={(val) => {
                            this.updateLicense(val)
                        }}
                        footer={null}
                        width={"40%"}
                        initialValues={{}}
                        destroyOnClose
                        formList={[
                            { label: "License", name: "system_license", type: "input", },
                        ]}
                    />


                </div>
            </div >

        )
    }
}

class LoginForm extends React.Component {
    state = {
        focusItem: -1,
        isRember: false,
        username: "",
        password: "",
    };
    componentWillMount() {
        this.versionName = sys_config.versionName;
    }
    componentDidMount() {
        this.loadAccountInfo();
    }

    //判断cookie中是否有账号信息，有就可以进行预填写，没有则直接返回
    loadAccountInfo = () => {
        //读取cookie
        let arr, reg = new RegExp("(^| )" + 'accountInfo' + "=([^;]*)(;|$)");
        let accountInfo = ''
        if (arr = document.cookie.match(reg)) {
            accountInfo = unescape(arr[2]);
        } else {
            accountInfo = null;
        }
        if (!accountInfo) {
            this.setState({ isRember: false });
            return false;
        } else {
            let userName = "";
            let passWord = "";
            let typeLogin = "";

            let i = new Array()
            i = accountInfo.split("&");
            userName = i[0],
                passWord = i[1],
                typeLogin = i[2]
            // console.log(userName, passWord);
            this.setState({
                username: userName,
                password: passWord,
                isRember: true
            });

        }
    }
    loginSubmit = (e) => {
        e && e.preventDefault();
        const _this = this;
        this.props.form.validateFieldsAndScroll((err) => {
            if (!err) {
                var formValue = _this.props.form.getFieldsValue();
                _this.props.loginSubmit({
                    username: formValue.username,
                    password: formValue.password,
                    _this: _this,
                });
                //_this.setState({isSubmit:true});
            }
        });
    };

    checkUsername = (rule, value, callback) => {
        var reg = /^\w+$/;
        if (!value) {
            // console.log($(".login-form"));
            $(".login-form").addClass("animated shake")
            callback('请输入用户名!');
        } else if (!reg.test(value)) {
            callback('用户名只允许输入英文字母');
        } else {
            callback();
        }
    };

    checkPassword = (rule, value, callback) => {
        if (!value) {
            callback('请输入密码!');
        } else {
            callback();
        }
    };
    remberPassword(e) {

        if (e.target.checked) {   //是否保存密码
            let username = this.props.form.getFieldValue("username") || "";
            let password = this.props.form.getFieldValue("password") || "";
            let accountInfo = username + '&' + password;

            let Days = 3;  //cookie保存时间
            let exp = new Date();
            exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
            document.cookie = 'accountInfo' + "=" + escape(accountInfo) + ";expires=" + exp.toGMTString();
            // console.log(accountInfo);
        } else {
            let exp = new Date();
            exp.setTime(exp.getTime() - 1);
            let accountInfo = document.cookie
            var cookie_pos = accountInfo.indexOf('accountInfo');
            if (cookie_pos != -1) {
                document.cookie = 'accountInfo' + "=" + ' ' + ";expires=" + exp.toGMTString();
            }
            this.props.form.resetFields();
        }
        this.setState({ isRember: e.target.checked })
    }

    render() {
        const { isSubmit, isRember, username, password } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Form className="login-form2">
                <FormItem>
                    {getFieldDecorator('username', {
                        initialValue: username || "",
                        rules: [{ validator: this.checkUsername }]
                    })(
                        <Input prefix={<Icon type="user" />}
                            // onFocus={() => this.setState({focusItem: 0})}
                            // onBlur={() => this.setState({focusItem: -1})}
                            placeholder="用户名"
                            onPressEnter={this.loginSubmit}
                            className="animated flipInX"
                        // addonBefore={<span className='iconfont icon-User' style={focusItem === 0 ? styles.focus : {}}/>}
                        />
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('password', {
                        initialValue: password || "",
                        rules: [{ validator: this.checkPassword }]
                    })(
                        <Input prefix={<Icon type="lock" />} type="password" placeholder="密码"
                            // wrappedcomponentref={(inst) => this.pwd = inst }/>
                            onPressEnter={this.loginSubmit}
                            className="animated flipInX"
                        />
                    )}
                </FormItem>
                <FormItem>
                    <Checkbox className="animated flipInX" checked={isRember}
                        onChange={(e) => { this.remberPassword(e) }}>记住密码</Checkbox>

                    <a style={{ float: 'right' }} onClick={() => { this.remberPassword({ target: { checked: false } }) }}>忘记密码</a>
                </FormItem>
                <FormItem >
                    <Button type="primary" onClick={this.loginSubmit} className="login-form-button2 animated flipInX">
                        {isSubmit ? <div><Icon type="reload" spin />请求登录中. . .</div> : '登录'}
                    </Button>
                </FormItem>
            </Form>
        )
    }
}
LoginForm = Form.create({})(LoginForm);