import React, { useState, useEffect } from 'react'

import { DigitalFlop, Decoration10 } from '@jiaminghi/data-view-react'

import './DigitalFlop.scss'
import Socket from '@/socket/index'
import http from '@/axios/axios_utils';
import Utils from '@/utils/utils';
import { alarmRecordTotal } from '../../monitorAlert/utils/api';

import { useRef } from 'react'
import { combineReducers } from 'redux'



function randomExtend(minNum, maxNum) {
    if (arguments.length === 1) {
        return parseInt(Math.random() * minNum + 1, 10)
    } else {
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
    }
}


export default () => {
    const [digitalFlopData, setData] = useState([]);
    const endTime = Utils.formateDate(new Date().getTime());
    let dataList = {
        total: 0,
        switchNumber: 0,
        firewallNumber: 0,
        routerNumber: 0,
        OtherNumber: 0,
        TaskNumber: 0,
        AlertNumber: 0
    };

    function getData(dataList) {
        return [
            {
                title: '数字牌1',
                number: {
                    // number: [dataList.total],
                    number: [randomExtend(10,20)],
                    content: '{nt}',
                    textAlign: 'right',
                    style: {
                        fill: '#4d99fc',
                        fontWeight: 'bold',
                    },
                },
                unit: '个',
            },
            {
                title: '数字牌2',
                number: {
                    // number: [dataList.routerNumber],
                    number: [randomExtend(10,20)],
                    content: '{nt}',
                    textAlign: 'right',
                    style: {
                        fill: '#4d99fc',
                        fontWeight: 'bold',
                    },
                },
                unit: '个',
            },
            {
                title: '数字牌3',
                number: {
                    // number: [dataList.firewallNumber],
                    number: [randomExtend(10,20)],
                    content: '{nt}',
                    textAlign: 'right',
                    style: {
                        fill: '#4d99fc',
                        fontWeight: 'bold',
                    },
                },
                unit: '个',
            },
            {
                title: '数字牌4',
                number: {
                    // number: [dataList.switchNumber],
                    number: [randomExtend(10,20)],
                    content: '{nt}',
                    textAlign: 'right',
                    style: {
                        fill: '#4d99fc',
                        fontWeight: 'bold',
                    },
                },
                unit: '个',
            },
        ]
    }

    useEffect(() => {
        createData()

        const timer = setInterval(createData, 3000)
        // const timer = setInterval(createData, 13000)
        return () => clearInterval(timer)
    }, [])

    function createData() {
        // getAllPort()
        setData(getData());
    }


    // function getAllPort() {
    //     getDeviceNumber((total) => {
    //         dataList.total = total;
    //         setData(getData(dataList));
    //     });
    //     getSwitchNumber((switchNumber, firewallNumber, routerNumber, OtherNumber) => {
    //         dataList.switchNumber = switchNumber;
    //         dataList.firewallNumber = firewallNumber;
    //         dataList.routerNumber = routerNumber;
    //         dataList.OtherNumber = OtherNumber;
    //         setData(getData(dataList));
    //     });
    //     getTaskNumber((TaskNumber) => {
    //         dataList.TaskNumber = TaskNumber;
    //         setData(getData(dataList));
    //     });
    //     getAlertNumber(endTime, (AlertNumber) => {
    //         dataList.AlertNumber = AlertNumber;
    //         setData(getData(dataList));
    //     });
    // }

    // 获取设备总数
    // function getDeviceNumber(callback) {
    //     let bodyJson = {};
    //     bodyJson.pageSize = 20;
    //     bodyJson.pageNum = 1;
    //     bodyJson.querys = {};
    //     Socket.ExecuteAction('QueryResourcesPage',
    //         bodyJson, "/queryResource", (result) => {
    //             callback(result.total)
    //         });
    // }

    // 获取交换机、防火墙、路由器的数量
    // function getSwitchNumber(callback) {
    //     Socket.ExecuteAction('statisDeviceTypeNum',
    //         {}, "/", (r) => {
    //             let switchCount = 0;
    //             let firewallCount = 0;
    //             let routerCount = 0;
    //             let OtherCount = 0;
    //             r.data.map((i) => {
    //                 let name = i._id;
    //                 if (i._id === "switch") {
    //                     name = "交换机";
    //                     switchCount = i.count;
    //                 } else if (i._id === "firewall") {
    //                     name = "防火墙";
    //                     firewallCount = i.count;
    //                 } else if (i._id === "router") {
    //                     name = "路由器";
    //                     routerCount = i.count;
    //                 } else if (i._id === "") {
    //                     name = "其他"
    //                     OtherCount = i.count;
    //                 }
    //             })
    //             callback(switchCount, firewallCount, routerCount, OtherCount);
    //         });
    // }

    // 获取任务总数
    // function getTaskNumber(callback) {
    //     let bodyJson = {};
    //     bodyJson.pageSize = 100000;
    //     bodyJson.pageNum = 1;
    //     bodyJson.querys = { ifTemplate: false };
    //     Socket.ExecuteAction("QueryTasksPage",
    //         bodyJson, "/queryResource", (result) => {
    //             callback(result.total)
    //         });
    // }

    // 获取告警总数和告警记录
    // async function getAlertNumber(endTime, callback) {
    //     // 未解决记录数
    //     const alertData0 = await http.get(alarmRecordTotal(), { status: 0, endTime });
    //     // 已解决记录数
    //     const alertData1 = await http.get(alarmRecordTotal(), { status: 1, endTime });
    //     // 警告总数
    //     callback(alertData0.data.total + alertData1.data.total)
    // }

    return (
        <div className="digitalFlop">
            {digitalFlopData.map(item => (
                <div className="digital-flop-item" key={item.title}>
                    <div className="digital-flop-title">{item.title}</div>
                    <div className="digital-flop">
                        <DigitalFlop config={item.number} style={{ width: '100px', height: '50px' }} />
                        <div className="unit">{item.unit}</div>
                    </div>
                </div>
            ))}

            <Decoration10 />
        </div>
    )
}
