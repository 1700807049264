import service from "../zx-utils/request"

// 获取列表
export function fetchList(query, callback) {
    service({
        url: '/zx/zl/',
        method: 'get',
        params: query
    }).then(res => {
        callback(res)
    })
}

// 点击编辑，获取编辑对象
export function fetchObj(id, callback) {
    service({
        url: '/zx/zl/' + id + '/',
        method: 'get',
        params: '',
    }).then(res => callback(res))
}

// delete object
export function deleteObj(keys, callback) {
    service({
        url: '/zx/zl/' + keys[0] + '/',
        method: 'delete',
        params: {},
    }).then(res => callback(res))
}


// 批量删除
export function batchDelete(keys, callback) {
    service({
        url: '/zx/zl/',
        method: 'delete',
        params: {keys: keys.toString()},
    }).then(res => callback(res))
}

//获取全部的资源
export function getAll(callback) {
    service({
        url: '/zx/zl/all/',
        method: 'get',
        params: {},
    }).then(res => callback(res))
}

//编辑
export function updateObj(data, callback) {
    service({
        url: '/zx/zl/' +data.id+ '/',
        method: 'put',
        data: data,
    }).then(res => callback(res))
}