/**
 * Created by YIMINE on 2019/5/22.
 */
import React from 'react'
import DataSet from "@antv/data-set";
import {
    G2,
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Label,
    Legend,
    View,
    Guide,
    Shape,
    Facet,
    Util
} from "bizcharts";
// const chartData= [
//     { name: "CPU", "12:30": 10, "12:35": 30, "12:40": 50, "12:45": 30, "12:50": 20 },
//     { name: "内存", "12:30": 20, "12:35": 30, "12:40": 50, "12:45": 10, "12:50": 20 },
//     { name: "硬盘", "12:30": 30, "12:35": 50, "12:40": 60, "12:45": 20, "12:50": 10 },
//     { name: "流量-vlan1", "12:30": 40, "12:35": 30, "12:40": 50, "12:45": 20, "12:50": 20 },
//     { name: "流量-vlan2", "12:30": 50, "12:35": 70, "12:40": 50, "12:45": 40, "12:50": 20 }
// ]
export default class oneIndex extends React.Component {
    state = {
    };
    componentWillMount() {
        // this.createChar(this.props.chartData);
    }
    componentWillReceiveProps(newData) {
        // this.createChar(newData.chartData);
    }



    render() {
        let { chartData } = this.props;
        // warn:{
        //     rule:">",
        //     value:70
        // }

        const _rule = chartData.warn ? chartData.warn.rule : "";
        const _value = chartData.warn ? chartData.warn.value : 0;
        // console.log(chartData);
        const data = [
            {
                time: "1951",
                value: 38
            },
            {
                time: "1952",
                value: 52
            },
            {
                time: "1956",
                value: 61
            },
            {
                time: "1957",
                value: 145
            },
            {
                time: "1958",
                value: 48
            },
            {
                time: "1959",
                value: 38
            },
            {
                time: "1960",
                value: 38
            },
            {
                time: "1962",
                value: 38
            }
        ];
        const cols = {
            value: {
                tickInterval: 20
            }
        };
        return (
            <div>
                <Chart height={400} data={chartData.data} scale={cols} forceFit>

                    <Axis name="time" />
                    <Axis name="value" />
                    <Tooltip
                        crosshairs={{
                            type: "y"
                        }}
                    />
                    <Geom type="interval"
                        position="time*value" n
                        color={['value', (cut) => {
                            switch (_rule) {
                                case "=":
                                    if (cut === _value) {
                                        return '#ff0000';
                                    } else {
                                        return '#3aa1ff';
                                    }
                                case ">":
                                    if (cut > _value) {
                                        return '#ff0000';
                                    } else {
                                        return '#3aa1ff';
                                    }
                                case "<":
                                    if (cut < _value) {
                                        return '#ff0000';
                                    } else {
                                        return '#3aa1ff';
                                    }
                            }
                        }]}
                    >

                    </Geom>
                </Chart>
            </div>
        );
    }
}