import React from 'react'
import {
    Input, Form, TreeSelect, Row, Col, Table, Button,
    Modal, Typography, Select, Icon
} from "antd";
import "./index.scss"
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import ResUtils from "@/utils/ResUtils";
import AxiosObj from '@/axios/index';
import SelectDevice from './checkDev';

import MonacaEditor from "react-monaco-editor"
import CascOrgain from '@/components/CascOrgain/index';
class AddTemp extends React.Component {
    state = {
        TreeData: [],
        FirmType: ["华为", "思科", "华三"],
        selectedRows: [],
        selectedRowKeys: [],
        visibleDev: false,
    }
    componentWillMount() {
    }

    componentDidMount() {
        // this.getMenuData();
    }
    componentWillReceiveProps(props) {
        if (props.selectNode) {
            this.updataDev(props.selectNode);
        }
    }
    getMenuData = () => {
        Socket.ExecuteAction('QueryArea', {}, "", (result) => {
            console.log(result)
            const TreeData = this.forVal(result.data);
            this.setState({
                TreeData,
            })
        });
    };
    updataDev = (selectNode) => {
        let bodyJson = {
            pageSize: 1000, pageNum: 1, querys: {
                "resourceParams.deviceFirm": Utils.ChineseToenglish(selectNode.manufacturer),
                "resourceParams.extend_attrs.acl_baseline": selectNode.template_name,
            }
        };
        Socket.ExecuteAction('QueryResourcesPage',
            bodyJson, "/queryResource", (result) => {
                console.log(result)
                this.setState({
                    deviceIpList: result.resources.map((row) => { return row.resourceParams.deviceIp }),
                    selectedRows: result.resources,
                    selectedRowKeys: result.resources.map((row) => {
                        return JSON.stringify({
                            resourceName: row.resourceName
                        })
                    })
                })
            });
    };
    forVal(data) {
        const newData = data.map((item) => {
            item.value = JSON.stringify({ key: item.key, name: item.title });
            if (item.children) {
                item.children = this.forVal(item.children);
            }
            return item;
        })
        return newData;
    }
    handleSubmit = (e) => {
        // e.preventDefault();
        let _this = this;
        const { onOk, selectNode, } = this.props;
        const { selectedRows, deviceIpList } = this.state;
        const ID = selectNode && selectNode._id
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.operator = Utils.getLoginUser();
                // const APINAME = ID ? "updateAclTemplate" : "importAclTemplate";
                let APINAME = "updateAclTemplate";
                if (ID) {
                    let add_ips = [];
                    const selectIP = selectedRows.map((row) => {
                        if (!deviceIpList.includes(row.resourceParams.deviceIp)) {//不包含，新增设备
                            add_ips.push(row.resourceParams.deviceIp);
                        }
                        return row.resourceParams.deviceIp
                    });
                    let del_ips = deviceIpList.filter(key => !selectIP.includes(key));//删除
                    values.del_ips = del_ips;
                    values.add_ips = add_ips;
                } else {
                    APINAME = "importAclTemplate";
                    values.ips = selectedRows.map((row) => { return row.resourceParams.deviceIp });
                }
                console.log('Received values of form: ', values);
                AxiosObj.HTTP_POST("acl", APINAME, values, (result) => {
                    Utils.showMessage("success", "消息", "操作成功!")
                    onOk();
                    _this.reset();
                });

            }
        });
    };
    reset = () => {
        this.props.form.resetFields();
        this.props.onCancel()
    };
    render() {
        const { getFieldDecorator, } = this.props.form;
        const { selectNode, onOk, visibleTemp } = this.props;
        const { TreeData, FirmType, visibleDev, selectedRowKeys, selectedRows } = this.state;
        const ID = selectNode && selectNode._id
        console.log(selectNode)
        return <div>
            <Modal
                title={ID ? "修改基线" : "添加基线"}
                visible={visibleTemp}
                okText={ID ? "修改基线" : "添加基线"}
                width={"80%"}
                style={{ top: 10 }}
                onCancel={() => { this.reset(); }}
                onOk={() => { this.handleSubmit(); }}
                afterClose={() => { this.setState({ selectedRowKeys: [], selectedRows: [], }) }}
            >
                <Form
                    labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}
                    onSubmit={this.handleSubmit}>
                    <Form.Item label={"基线名称"} >
                        {getFieldDecorator("template_name", {
                            initialValue: selectNode ? selectNode.template_name : "",
                            rules: [{
                                required: true, message: '请填写基线名称!',
                            }],
                        })(<Input disabled={ID ? true : false} />)}
                    </Form.Item>
                    {/* <Form.Item label="所属区域">
                        {getFieldDecorator("template_zone_id", {
                            initialValue: ID ? selectNode.template_zone_id : [],
                            rules: [{
                                required: true, message: '请填写区域!',
                            }],
                        })(<TreeSelect
                            {...{
                                treeData: TreeData,
                                treeCheckable: true,
                                showCheckedStrategy: TreeSelect.SHOW_PARENT,
                                searchPlaceholder: '请选择区域!',
                                style: {
                                    width: '100%',
                                },
                            }}
                        />)}
                    </Form.Item> */}
                    <Form.Item label="所属厂商">
                        {getFieldDecorator("manufacturer", {
                            initialValue: ID ? selectNode.manufacturer : "华为",
                            rules: [{
                                required: true, message: '请填写所属厂商!',
                            }],
                        })(<Select>
                            {FirmType.map((type) => {
                                return <Select.Option key={type} value={type}>{type}</Select.Option>
                            })}
                        </Select>)}
                    </Form.Item>
                    <Form.Item label="基线内容">
                        {getFieldDecorator("template_info", {
                            initialValue: selectNode ? selectNode.template_info : "",
                            rules: [{
                                required: true, message: '请填写基线内容!',
                            }],
                        })(
                            <MonacaEditor
                                theme="vs-dark"
                                language="shell"
                                options={{ minimap: { enabled: false }, lineNumbers: "off", scrollBeyondLastLine: false, }}
                                height="400"
                            />
                            // <Input.TextArea className="cmdTextArea" />
                        )}
                    </Form.Item>
                    <Row>
                        <Col span={2} className="tempsty" >
                            <div className="ant-form-item-label">
                                <label className="ant-form-item" >绑定设备</label>
                            </div>
                        </Col>
                        <Col span={22}>
                            <Table
                                columns={[...ResUtils.getColumns(10000, 1), {
                                    title: "操作",
                                    render: (a, item, index) => {
                                        return <Button type="danger" icon="delete" size="small" onClick={() => {
                                            const newData = selectedRows.filter((row) => { return row._id != item._id });
                                            const newData2 = selectedRowKeys.filter((row) => {
                                                return row != JSON.stringify({ resourceName: item.resourceName })
                                            });
                                            this.setState({ selectedRows: newData, selectedRowKeys: newData2 })
                                        }} />
                                    }
                                }]}
                                dataSource={selectedRows}
                                pagination={false}
                                locale={{ emptyText: <div><Icon type="inbox" />暂无数据</div> }}
                                size="small"
                                scroll={{ y: 300 }}
                            />
                            <Button style={{ width: "100%", marginTop: 5 }} icon="plus-circle" type="dashed"
                                onClick={() => {
                                    this.setState({ visibleDev: true })
                                }}>添加设备</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title="选择设备"
                visible={visibleDev}
                width={"99%"}
                style={{ top: 10 }}
                onCancel={() => { this.setState({ visibleDev: false, }); }}
                onOk={() => { this.setState({ visibleDev: false, }); }}
                destroyOnClose
            >
                <SelectDevice
                    onChange={(selectedRowKeys, selectedRows) => {
                        this.setState({ selectedRowKeys, selectedRows, })
                    }}
                    querys={{ "resourceParams.deviceFirm": Utils.ChineseToenglish(this.props.form.getFieldValue("manufacturer")) }}
                    selectedRowKeys={selectedRowKeys}
                />
            </Modal>
        </div>
    }

}
const CommObj = Form.create()(AddTemp)
export default CommObj