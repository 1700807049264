import React from 'react'
import { Card, Table, Button, Icon, Input, message, Popconfirm, Tag, Modal, Row, Col, Upload, Dropdown, Menu, Tabs } from "antd";
import axios from '@/axios/axios_utils';
import Axios from 'axios'
import Utils from "../../../utils/utils";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import DyHeadTable from '@/components/DyHeadTable/index';
import FirewallUtils from "@/utils/FirewallUtils";
import SrcIpAddress from './srcIpAddress'
import DstIpAddress from './dstIpAddress'

const { Dragger } = Upload;
const { TabPane } = Tabs;
const eLineInfo_Url = "http://" + backup_address + "/ZJNMS_V1/fw_manage/";
export default class ExternalConnection extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _pageSize: 20,    //当前每页显示条数
        total: 0,
        loading: false,
        querys: null,    //查询条件
        tableData: [],
        selectedRowKeys: [],
        completeTableData: [],
    };
    componentWillMount() {
        this.updata();
    }
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {
            page_size: pageSize || _pageSize,
            page_no: pageNum || _current,
            ...querys
        };
        axios.get(eLineInfo_Url + 'externalConnection', bodyJson, (res) => {
            if (res.code === "00") {
                this.setState({
                    tableData: JSON.parse(JSON.stringify(res.data)), // 深拷贝
                    completeTableData: res.data,
                    loading: false,
                    total: res.count,
                    _current: pageNum || 1,
                    _pageSize: pageSize || _pageSize,
                })
            } else {
                message.error(res.msg);
            }
        })
    };
    // 导出 excel 文件
    exportExcel(allFlag) {
        let { selectedRowKeys } = this.state;
        if (!allFlag) {
            if (selectedRowKeys.length === 0) {
                return message.warning("请选择需要导出的外联信息！")
            }
        }
        let bodyJson = {
            is_all: allFlag ? true : false,
            _ids: selectedRowKeys
        }
        Axios({
            method: "PUT",
            url: eLineInfo_Url + "extConnExcel",
            responseType: 'blob',
            data: bodyJson,
        }).then((result) => {
            const link = document.createElement('a');
            // let blob = new Blob([result.data], { type: "application/vnd.ms-excel" }); // 转化为 blob 对象 导出.xls文件
            let blob = new Blob([result.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }); // 转化为 blob 对象  导出.xlsx文件
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = "外联信息列表"; // 文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // 取消勾选状态
            this.setState({ selectedRowKeys: [] });
            // console.log(link.href, result);
        })
    }
    onRef = (ref) => {
        // console.log(ref.props.refs, 1111)
        if (ref.props.refs === 'srcIp') {
            this.srcIpAddress = ref;
        } else if (ref.props.refs === 'dstIp') {
            this.dstIpAddress = ref;
        }
    }
    switchTab(tabsKey) {
        let { _pageSize } = this.state;
        switch (tabsKey) {
            case "externalConnectionList": this.updata(_pageSize, 1); break;
            case "srcIpAddress":
                if (this.srcIpAddress) { this.srcIpAddress.updata(_pageSize, 1); }
                break;
            case "dstIpAddress":
                if (this.dstIpAddress) { this.dstIpAddress.updata(_pageSize, 1); }
                break;
        }
    }
    // 更新列表数据
    updateData(tableData) {
        this.setState({ tableData })
    }

    render() {
        let { _current, _pageSize, querys, tableData, loading, total, selectedRowKeys, completeTableData } = this.state;
        const columns = [
            ...FirewallUtils.getExternalConnectionColumns(tableData, completeTableData, this),
            {
                title: 'CI_CODE',
                align: 'center',
                dataIndex: 'ci_code',
                width: 100,
                ellipsis: true,
            },
            {
                title: '专线号',
                align: 'center',
                dataIndex: 'e_line_no',
                width: 100,
                ellipsis: true,
            },
        ];
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        return (
            <div>
                <CustomBreadcrumb arr={["防火墙管理", "外联信息"]} />
                <Card>
                    <Tabs type="card" style={{ marginTop: 8 }} onChange={(activeKey) => {
                        this.switchTab(activeKey);
                    }}>
                        <TabPane tab="外联信息列表" key="externalConnectionList">
                            <Card>
                                <FilterForm tagClass="externalConnection"
                                    filterSubmit={(querys) => {
                                        this.setState({ querys })
                                        this.updata(_pageSize, 1, querys)
                                    }}
                                    resetTab={() => {
                                        this.setState({ querys: {} })
                                        this.updata(_pageSize, 1, {})
                                    }}
                                />
                            </Card>
                            <Card title={<span style={{ fontWeight: 600 }}>外联信息列表</span>}
                                extra={
                                    <Dropdown.Button className="dropDownBtn" type='primary' icon={<Icon type="down" />} style={{ marginLeft: 8 }}
                                        overlay={
                                            <Menu onClick={() => { this.exportExcel("is_all") }}>
                                                <Menu.Item>
                                                    <Icon type="export" />导出全部外联信息
                                                </Menu.Item>
                                            </Menu>
                                        }
                                        onClick={() => { this.exportExcel() }}
                                    >
                                        <Icon type='export' /> 导出外联信息
                                    </Dropdown.Button>
                                }
                                style={{ marginTop: 8 }}>
                                <DyHeadTable
                                    dataSource={tableData}
                                    columns={columns}
                                    size='small'
                                    loading={loading}
                                    rowKey={(text) => { return text._id }}
                                    rowSelection={rowSelection}
                                    pagination={{
                                        pageSize: _pageSize,
                                        current: _current,
                                        total: total,
                                        showTotal: (total) => { return <span>共 {total} 项</span> },
                                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                                        showSizeChanger: true,
                                        onChange: (page, pageSize) => {
                                            this.updata(pageSize, page, querys);
                                        },
                                        onShowSizeChange: (current, size) => {
                                            this.updata(size, 1, querys)
                                        }
                                    }}
                                />
                            </Card>
                        </TabPane>
                        <TabPane tab="源ip转换前后的地址" key="srcIpAddress">
                            <SrcIpAddress refs="srcIp" onRef={this.onRef} />
                        </TabPane>
                        <TabPane tab="目的ip转换前后的地址" key="dstIpAddress">
                            <DstIpAddress refs="dstIp" onRef={this.onRef} />
                        </TabPane>
                    </Tabs>
                </Card>
            </div>
        )
    }
}
