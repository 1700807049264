/**
 * 系统前后端API配置
 */
const BASEURL = "http://" + backup_address + "/ZJNMS_V1/firewall/";
const APIConfig = {
    MODENAME: "firewall",//模块名称
    BASEURL,
    //URL:配置
    importConfig: BASEURL + "importConfig",
    getConfig: BASEURL + "getConfig",

};
export default APIConfig;