import React from 'react'
import './index.scss'
import Socket from '@/socket/index'
import Utils from '@/utils/utils'
import {Card,Icon,Row,Col,Tag,Button,Radio,TimePicker,DatePicker,
    Input,InputNumber,Checkbox,Select,TreeSelect} from "antd";
import QueueAnim from 'rc-queue-anim';
import CustomBreadcrumb from '../../../../../components/CustomBreadcrumb/index';
import moment from 'moment';
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;
export default class TimePlan extends React.Component{
    state = {
        current:0,
        timeValue:null,
        timeMoment:null,
        DateValue:null,
        DateMoment:null,
        typeRab:'',
        InputNumber:0,
        month_month:[],
        month_day:[],
        month_weekNum:[],
        month_dayNum:[],
        week_checkbox:[],
        RadioCheck:true,
    };
    componentWillMount() {
        let initValue = this.props.initValue;
        const dateFormat = 'YYYY-MM-DD';
        const timeFormat = 'HH:mm:ss';

        if(initValue){
            let begin = initValue.begin;
            // let DateValue = begin.year+begin.month+begin.day;
            let DateValue = [begin.year,begin.month,begin.day].join("-");
            // let timeValue = begin.hour+begin.minute+begin.second;
            let timeValue = [begin.hour,begin.minute,begin.second].join(":");
            switch (initValue.type){
                case'day':
                    this.setState({
                        typeRab:initValue.type,
                        DateValue,timeValue,
                        InputNumber:initValue.interval
                    }); break;
                case'week':
                    this.setState({
                        typeRab:initValue.type,
                        DateValue,timeValue,
                        InputNumber:initValue.interval,
                        week_checkbox:initValue.weekday
                    });break;
                case'month':
                    this.setState({
                        typeRab:initValue.type,
                        DateValue,timeValue,
                        month_month:initValue.month,
                        month_day:initValue.day,
                        month_weekNum:initValue.week.index,
                        month_dayNum:initValue.week.weekday,
                    });
                    break;
                case'once':this.setState({
                    typeRab:initValue.type,
                    DateValue,timeValue,
                });break;
            }

        }
    }

    chooseTime = () => {
        let {typeRab,month_month,RadioCheck,timeMoment,DateMoment,DateValue,timeValue} = this.state;
        let timeElemt = null;
        const dateFormat = 'YYYY-MM-DD';
        const timeFormat = 'HH:mm:ss';
        const weekList = ['星期一','星期二','星期三','星期四','星期五','星期六','星期日'];
        let TimeDiv = <div className="line-div">开始
            <DatePicker defaultValue={DateValue?moment(DateValue, dateFormat):null}
                        onChange={(date, dateString) => {
                            console.log(date, dateString);
                            this.setState({DateValue: dateString, DateMoment: date});
                        }}
            />
            <TimePicker value={timeValue?moment(timeValue, timeFormat):null} onChange={(time,timeString) => {
                console.log(time);
                this.setState({timeValue:timeString,timeMoment: time});
            }}/></div>;
        switch (typeRab){
            case 'once':
                timeElemt = <div><h2>一次</h2>
                    {TimeDiv}
                </div>;
                break;
            case 'day':
                timeElemt = <div><h2>每天</h2>
                    {TimeDiv}
                    <div className="line-div">每隔
                        <InputNumber min={1} max={10} value={this.state.InputNumber}
                                     onChange={(value) => {
                                         this.setState({InputNumber: value});
                                     }}/>天发生一次
                    </div>
                </div>;
                break;
            case 'week':
                timeElemt = <div><h2>每周</h2>
                    {TimeDiv}
                    <div className="line-div">每隔
                        <InputNumber min={1} max={10} value={this.state.InputNumber}
                                     onChange={(value) => {
                                         this.setState({InputNumber: value});
                                     }}/>周<br/>
                        <CheckboxGroup
                            value={this.state.week_checkbox}
                            onChange={(value)=>{this.setState({week_checkbox: value});}}>
                            <Checkbox value={1}>星期一</Checkbox>
                            <Checkbox value={2}>星期二</Checkbox>
                            <Checkbox value={3}>星期三</Checkbox>
                            <Checkbox value={4}>星期四</Checkbox><br/>
                            <Checkbox value={5}>星期五</Checkbox>
                            <Checkbox value={6}>星期六</Checkbox>
                            <Checkbox value={7}>星期日</Checkbox>
                        </CheckboxGroup>
                    </div>
                </div>;
                break;
            case 'month':
                const OptionList = ['一月','二月','三月','四月','五月','六月','七月',
                    '八月','九月','十月','十一月', '十二月'];
                const numList = ['第一个','第二个','第三个','第四个','最后一个'];
                let arrNum = [...Array.from({length:31}).keys()]; //0-30数组
                let treeData = [];
                const radioStyle = {
                    display: 'block',
                    height: '30px',
                    lineHeight: '30px',
                };
                arrNum.map((num,key)=>{
                    treeData.push({title: num+1, value: num+1, key: key+1,});
                });
                treeData.push({title: '最后一天', value: 33, key: 33,});
                timeElemt = <div><h2>每月</h2>
                     {TimeDiv}
                    <div className="line-div"><span>月份:</span>
                        <Select mode="multiple" placeholder="选择月份" defaultValue={month_month}
                                onChange={(values)=>{this.setState({month_month:values})}}
                                style={{width:'80%'}}>
                            {
                                OptionList.map((item,key)=>{
                                    return <Option key={key} value={key+1}>{item}</Option>
                                })
                            }
                        </Select><br/>
                        <input name="step" type="radio" value={false} onClick={()=>{
                            this.setState({RadioCheck:false})
                        }} />
                            <span style={{paddingLeft:'0px'}}>天:</span>
                            <TreeSelect style={{width:'80%'}}
                                        treeData={treeData}
                                        treeCheckable={true}
                                        value={this.state.month_day}
                                        placeholder="选择日期"
                                        dropdownClassName="dropDown-style"
                                        disabled={RadioCheck}
                                        onChange={(value, node, extra)=>{
                                            this.setState({month_day: value});
                                        }}
                            /><br/>
                        <input name="step" type="radio" className="ant-radio-input2" defaultChecked onClick={()=>{
                            this.setState({RadioCheck:true})
                        }}/>
                            <span style={{paddingLeft:'0px'}}>在:</span>
                            <Select mode="multiple"
                                    disabled={!RadioCheck}
                                    value={this.state.month_weekNum}
                                    onChange={(values)=>{this.setState({month_weekNum:values})}}
                                    style={{width:'40%'}}>
                                {
                                    numList.map((item,key)=>{
                                        return <Option key={key} value={key===4?key+29:key+1}>{item}</Option>
                                    })
                                }
                            </Select>
                            <Select mode="multiple" disabled={!RadioCheck}
                                    value={this.state.month_dayNum}
                                    onChange={(values)=>{this.setState({month_dayNum:values})}}
                                    style={{width:'40%'}}>
                                {
                                    weekList.map((item,key)=>{
                                        return <Option key={key} value={key+1}>{item}</Option>
                                    })
                                }
                            </Select>
                    </div>
                </div>;
                break;
        }

        return timeElemt;
    };
    onChangeRadio=(e)=>{
        console.log(e.target.value);
        this.setState({typeRab: e.target.value});
    };
    submitFun=()=>{
        let {typeRab,InputNumber,DateValue,timeValue,week_checkbox,
            month_month,month_day,month_weekNum,month_dayNum} =this.state;
        let DateStr = DateValue.split('-');
        let TimeStr = timeValue.split(':');
        let param = {
            type: typeRab,
            begin: {
                year: Number.parseInt(DateStr[0]), month: Number.parseInt(DateStr[1]),
                day: Number.parseInt(DateStr[2]), hour: Number.parseInt(TimeStr[0]),
                minute: Number.parseInt(TimeStr[1]), second: Number.parseInt(TimeStr[2])
            },
        };
        switch (typeRab){
            case'day':
                param.interval=InputNumber; break;
            case'week':
                param.interval=InputNumber;
                param.weekday=week_checkbox;break;
            case'month':
                let week={index:month_weekNum,weekday:month_dayNum};
                param.month=month_month;
                param.day=month_day;
                param.week=week;
                break;
            case'once':break;
        }
        console.log(JSON.stringify(param))
        this.props.onParamChange(param);
    };

    render(){
        let {current,typeRab,} =this.state;
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginLeft:'10px',
        };
        return (
            <div>
                <div className="timePlan-steps">
                    {
                        current == 0?
                            <div>
                                <h2>希望该任务何时开始？</h2>
                                <RadioGroup onChange={this.onChangeRadio} value={typeRab}>
                                    <Radio style={radioStyle} value="day">每天</Radio>
                                    <Radio style={radioStyle} value="week">每周</Radio>
                                    <Radio style={radioStyle} value="month">每月</Radio>
                                    <Radio style={radioStyle} value="once">一次</Radio>
                                </RadioGroup>
                                <hr/>
                                <Button type="primary" style={{marginLeft: 8}} className="pull-right"
                                    onClick={() => {
                                    const current = this.state.current + 1;//设置步骤计数
                                    this.setState({ current });
                                }}
                                >下一步</Button>
                            </div>
                            :(
                            <div>
                                {this.chooseTime()}
                                <hr/>
                                <div style={{width:'100%',textAlign:'right'}}>
                                    <Button style={{marginLeft: 8}} onClick={() => {
                                        const current = this.state.current - 1;
                                        this.setState({current});
                                    }}>上一步</Button>
                                    <Button type="primary" style={{marginLeft: 8}}
                                            onClick={()=>this.submitFun()}>完成</Button>
                                </div>
                            </div>)
                    }
                </div>
            </div>
        );
    }
}