import React from 'react'
import './index.scss'
import {
    Card, Table, Input, Typography, Popconfirm, Button, Icon, Tabs, Row,
    notification, Col, message, Tag, Modal, Progress, List, Select
} from "antd";
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import CustomBreadcrumb from "@/components/CustomBreadcrumb/index";
import BaseForm from './BaseForm/index';
import $ from "jquery";
const { Title } = Typography;
const Option = Select.Option;


export default class MangeSrcModal extends React.Component {
    state = {
        initialValue: {},
        fromAddrList: [],
        toList: []
    };
    componentWillMount() {
        const initVal = this.props.initialValue;
        const fromAddrList = this.props.fromAddrList;
        const toList = this.props.toList;
        if (initVal) {
            this.setState({ initialValue: initVal })
        }
        if (fromAddrList) {
            this.setState({ fromAddrList })
        }
        if (toList) {
            this.setState({ toList })
        }
    }

    render() {
        const { initialValue, fromAddrList, toList } = this.state;
        const formList = [
            {
                label: "策略名称",
                type: 'INPUT',
                field: "strategyName",
                initialValue: initialValue.strategyName || "",
                placeholder: "资源名称"
            },
            {
                label: "源头地址范围",
                type: 'SELECT',
                field: "srcIP",
                // isTag: true,
                initialValue: initialValue.srcIP || "",
                list: fromAddrList.map((item) => {
                    return { id: item, name: item }
                }),
            },
            {
                label: "目的地址范围",
                type: 'SELECT',
                field: "desIP",
                // isTag: true,
                initialValue: initialValue.desIP || "",
                list: toList.map((item) => {
                    return { id: item, name: item }
                }),
            },
            {
                label: "应用",
                placeholder: "应用",
                type: 'INPUT',
                field: "app",
                initialValue: initialValue.app || "",
            },
            {
                label: "处理",
                placeholder: "处理",
                type: 'INPUT',
                field: "act",
                initialValue: initialValue.act || "",
            },
        ]

        return (
            <Modal
                {...this.props}
            >
                <div className="modalDiv">
                    <BaseForm
                        formList={formList}
                        filterSubmit={(querys) => {
                        }}
                        resetTab={() => {
                        }}
                    />
                </div>
            </Modal>
        );
    }
} 