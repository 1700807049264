import React from 'react'
import { Button, Icon, Popconfirm, message, Card, Collapse, Table, Tag, Modal, Upload, Pagination, DatePicker, Tabs, notification, Select } from "antd";

import Socket from '@/socket/index';
import Utils from '@/utils/utils';
import './index.scss';
import $ from "jquery";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
const { Panel } = Collapse;
const { TabPane } = Tabs;

class RecordDetail extends React.Component {
    state = {
    }
    componentWillMount() {
    }
    componentDidMount() {
        Utils.mixTable("createDevMacTab");
    }
    commonTable(title, data) {
        const colum = title.map((item) => {
            item["render"] = (_text, _ite, _index) => {
                console.log(_text);
                return Utils.isHightLight(_text);
            };
            return item
        })
        console.log(title, data);

        return <Table
            columns={colum}
            dataSource={data}
            Pagination={false}
        />
    }
    createDevMacTab(data) {
        const testTab = data.data;
        const tabTitle = data.title;
        return <table className="testTable" id={"createDevMacTab"}>
            <thead>
                <tr>
                    {tabTitle.map((item) => {
                        return <th >{item.title}</th>
                    })}
                </tr>
                {/* <tr><th>{tabTitle[0]}</th><th>{tabTitle[1]}</th></tr> */}
                {testTab.map((item) => {
                    let JsonArr = [item.deviceIp, item.portName_link_macTable, item.macTable]
                    let textArr = JsonArr.map((arrItem) => { return Utils.isHightLight(arrItem) })
                    return <tr>
                        {textArr.map((_textItem, textKey) => { return <td key={textKey}>{_textItem}</td> })}
                    </tr>
                })}
            </thead>
        </table>
    }
    creatTabs(dataArr) {
        return dataArr.map((item, key) => {
            console.log(item);
            return <TabPane tab={<span><Icon type="profile" />{item.sheetName}</span>} key={key} forceRender={true}>
                {item.type === "common" ? this.commonTable(item.title, item.data) : this.createDevMacTab(item)}
            </TabPane>
        })
    }
    render() {

        return <Modal
            {...this.props}

        >
            <Tabs defaultActiveKey="0" onChange={(key) => {
                Utils.mixTable("createDevMacTab")
            }}
            // tabBarExtraContent={
            //     <span><Select style={{ width: 200 }} >
            //         {this.props.resuleData.map((data1, key1) => {
            //             return <Select.Option key={key1} value={data1.taskEndTime}>{data1.taskEndTime}</Select.Option>
            //         })}
            //     </Select><Button type="primary" style={{ marginLeft: 5 }} icon="diff">比较</Button></span>
            // }  
            >
                {this.creatTabs(this.props.detailData)}
            </Tabs>
        </Modal>
    }
}
export default RecordDetail