/**
 * 系统前后端API配置 docker
 */
import Socket from "@/socket/index";
const agentAddress = "http://" + Socket.getAddress50034() + "/router"
const tempAddress = sys_config.dockerAddress
const BASEURL = "http://" + tempAddress
export function getAgentAddress() { //获取代理地址
    return agentAddress;
}
export function getContainers() { //获取所有容器信息
    return `${BASEURL}/containers/json?all=true`;
}
export function startContainers(containerID) { //启动容器
    return `${BASEURL}/containers/${containerID}/start`;
}
export function stopContainers(containerID) { //停止容器
    return `${BASEURL}/containers/${containerID}/stop`;
}
export function restartContainers(containerID) { //重启容器
    return `${BASEURL}/containers/${containerID}/restart`;
}
export function getSysAddress() { //获取所有容器信息
    return "http://" + Socket.getAddress();
}
export function getlogs(containerID, since, until) { //获取容器日志信息
    return `${BASEURL}/containers/${containerID}/logs?since=${since}&until=${until}&stdout=true&timestamps=true`;
}