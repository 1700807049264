import axios from 'axios'
import { notification, Icon, Modal } from 'antd';
import { zx_baseUrl } from '@/pages/NetLine/zx-config'
import React, { Component } from "react";

const { confirm } = Modal

/**
 * @author linjiawei1996@qq.com
 * @description 目前仅用于专线管理的API请求
 * @time 2020/1/16 10:37 上午
 */

// create an axios instance
const service = axios.create({
    baseURL: zx_baseUrl, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request 超时时间
})

// request interceptor
service.interceptors.request.use(
    /**
     * @author linjiawei1996@qq.com
     * @description 需要添加权限验证，则在这里写 TODO
     * @time 2020/1/20 4:14 下午
     */
    config => {
        config.headers = {
            'Content-Type': 'application/json; charset=utf-8',
            'X-Requested-With': 'XMLHttpRequest',
        }
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

// response 处理，所有返回的数据都经过这里处理
service.interceptors.response.use(
    /**
     * @author linjiawei1996@qq.com
     * @description 对response进行处理
     * @time 2020/1/20 4:13 下午
     */
    response => {
        const res = response
        const statusCode = response.status
        const code = res.data.code
        // alert(JSON.stringify(res.data.msg))
        if (statusCode === 200) {
            notification.warn({
                message: '返回成功',
                description: res.data.msg || '返回数据成功',
                icon: <Icon type="check-circle" style={{ color: 'green' }} />,
            })
            return res
        } else if (statusCode === 204) {
            notification.warning({
                message: '删除',
                description: res.data.msg || '成功删除记录！',
                icon: <Icon type="check-circle" style={{ color: 'blue' }} />,
            })
            return res
        } else if (statusCode === 206) {
            // 无法删除运营商，提示转到专线管理去撤销
            if (code === 100001) {
                notification.error({
                    message: '删除失败',
                    description: res.data.msg || '出现错误',
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                })
            }

            return false
        } else {
            notification.error({
                message: '请求失败',
                description: res.message || '请求错误，请检查！',
                icon: <Icon type="frown" style={{ color: 'red' }} />,
            })
        }

        // 返回正确数据执行
        // if (res.code !== 200) {
        //     notification.error({
        //         message: '查询失败',
        //         description: res.message || '请求错误，请检查！',
        //         icon: <Icon type="frown" style={{ color: 'red' }} />,
        //     })
        // } else {
        //     return res
        // }
    },
    error => {
        console.log('err' + error) // for debug
        notification.error({
            message: '出现故障',
            description: error.message || '请求错误，请检查！',
            icon: <Icon type="frown" style={{ color: 'red' }} />,
        })

        return Promise.reject(error)
    }
)

export default service
