/**
 * 动态显示表格数据
 */
import React from 'react'
import { Table } from 'antd'
import "./index.scss"
class DynamicTableObj extends React.Component {

    state = {
        dataSource: [],
        columns: [],
    };
    componentWillMount() {
        let { dataSource, columns } = this.props;
        this.setState({ dataSource, columns });
    }
    componentWillReceiveProps(newData) {

    }
    render() {
        let { dataSource, columns } = this.props;
        return (
            <div>
                <Table
                    dataSource={dataSource}
                    columns={columns}

                />
            </div>
        )
    }
}
export default DynamicTableObj 