import React from 'react'
import { Card, Table, message, Popconfirm, Button, notification, Icon, Tag } from "antd";
import Socket from '@/socket/index'
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import FileModal from '@/components/FileModal/index';
import ExportButton from '@/components/ExportButton/index';
import MonacoEditor from 'react-monaco-editor';
import SystemEsInfo from '@/axios/sysGetEs'
import AxiosObj from '@/axios/index'
import Utils from '@/utils/utils';
import moment from 'moment';
import axios from 'axios';
import api from '@/config/api/logMange';
class SysLog extends React.Component {
    state = {
        visible: false,
        _current: 1,       //当前页码数
        _pageSize: 20,    //当前每页显示条数
        querys: {
            "query": {
                "match_all": {}
            }
        },    //查询条件
        loading: false,
        selectedRowKeys: [],
        indexData: {}

    };
    componentWillMount() {
        // let bodyJson = this.props.location.bodyJson || {
        //     "from": 0,
        //     "size": 20,
        //     "query": {
        //         // "match_all": {},
        //         "match": {
        //             "message": '189',
        //         }
        //     }
        // };
        const { _pageSize, _current, querys } = this.state;
        Socket.getConfig("logIndex", (res) => {
            console.log(res)
            this.setState({ indexData: res });
            this.updata(_pageSize, _current, querys, res.sysIndex);
        });
    }

    updata = (pageSize, pageNum, querys, index) => {
        this.setState({ loading: true, });
        querys.from = (pageNum - 1) * pageSize;
        querys.size = pageSize;
        console.log(querys);
        let sysIndex = index || api.sysIndex;
        axios.post(api.BASEURL + sysIndex + "/_search", querys).then((res) => {
            const data = res.data.hits;
            let _groupData = data.hits;
            let _dataSize = data.total.value;
            let tabDatatemp = _groupData.map((item, index) => { return item["_source"] });
            this.setState({
                tableData: tabDatatemp,
                _current: pageNum || 1,
                total: _dataSize,
                loading: false
            })
        }).catch(function (error) {
            message.error(error);
        })
        // SystemEsInfo.PostSysLog(querys, (data) => {
        //     let _groupData = data.hits;
        //     let _dataSize = data.total.value;
        //     // console.log(data);
        //     let tabDatatemp = _groupData.map((item, index) => { return item["_source"] });
        //     this.setState({
        //         tableData: tabDatatemp,
        //         _current: pageNum || 1,
        //         total: _dataSize,
        //         loading: false
        //     })
        // });
        // AxiosObj.ES_HTTP_POST("logMange", "_search", querys, (data) => {
        //     let _groupData = data.hits;
        //     let _dataSize = data.total.value;
        //     // console.log(data);
        //     let tabDatatemp = _groupData.map((item, index) => { return item["_source"] });
        //     this.setState({
        //         tableData: tabDatatemp,
        //         _current: pageNum || 1,
        //         total: _dataSize,
        //         loading: false
        //     })
        // }, (err) => {
        //     message.error(err);
        // });

    };

    render() {
        let { _current, _pageSize, querys, indexData } = this.state;
        const columns = [
            //     {
            //     title: '序号',
            //     align: 'center',
            //     render: (text, item, key) => { return <span>{key + 1}</span> }
            // }, 
            {
                title: '时间',
                align: 'center',
                width: "15%",
                dataIndex: '@timestamp',
                ellipsis: true,
                render: (text, item, index) => {
                    return Utils.utc2beijing(text);
                }
            }, {
                title: '容器名',
                align: 'center',
                width: "8%",
                dataIndex: 'kubernetes.container_name'
            }, {
                title: '日志信息',
                align: 'left',
                // width: "75%",
                dataIndex: 'log',
                ellipsis: true,
                render: (text, item, index) => {
                    return Utils.hidenLongText(text, 180);
                }
            },
            // {
            //     title: '操作',
            //     width: '300px',
            //     align: 'center',
            //     render: (text, item) => {
            //         return <div>
            //             <Button type="primary" onClick={() => {

            //                 let bodyJson = {
            //                     pageNum: _current,      //当前页码数
            //                     pageSize: _pageSize,    //每页显示条数
            //                     querys,    //查询条件
            //                 }
            //                 let path = {
            //                     pathname: `/newOperating:${item._id}`,
            //                     bodyJson,
            //                 };
            //                 this.props.history.push(path);
            //             }}>
            //                 <Icon type="form" />编辑
            //                 </Button>
            //             <Button type="primary" icon="copy" onClick={() => {
            //                 this.props.history.push(`/newOperating:${item._id}&copy`);
            //             }}>复制
            //                 </Button>
            //             <Popconfirm
            //                 title="确定删除吗?"
            //                 onConfirm={() => { this.DeleteOperation(item._id) }}
            //                 okText="Yes"
            //                 cancelText="No"
            //             >
            //                 <Button type="danger" icon='delete'>
            //                     删除
            //                     </Button>
            //             </Popconfirm>
            //         </div>
            //     }
            // }
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys:`, selectedRowKeys, 'selectedRows: ', selectedRows);
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: changableRowKeys => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        const formList = [
            {
                label: "关键字",
                type: 'INPUT',
                field: "log",
                initialValue: '',
            }, {
                label: "时间",
                type: 'timeSelect',
            }
        ]
        return (
            <div>
                <CustomBreadcrumb arr={["日志管理", "系统日志"]} />
                <Card>
                    <FilterForm
                        formList={formList}
                        filterSubmit={(query) => {
                            // var requestData = {
                            //     "from": 0,
                            //     "size": 1000,
                            //     "query": {
                            //         "bool": {
                            //             "must": {
                            //                 // "match": { "User-Name": "username" }
                            //                 "match": { "message": "username" }
                            //             },
                            //             "filter": {
                            //                 "range": {
                            //                     "@timestamp": {
                            //                         "lt": newDate,
                            //                         "gte": Last_query_time
                            //                     }
                            //                 }
                            //             }
                            //         }
                            //     },
                            //     "sort": [
                            //         {
                            //             "@timestamp": {
                            //                 "order": "asc"//desc降序   asc升序
                            //             }
                            //         }
                            //     ]
                            // };

                            let { _pageSize } = this.state;
                            let temp = {
                                "from": 0,
                                "query": {},
                                "size": _pageSize
                            }
                            if ((query.begin_time && query.end_time) && query.log) {//组合查询
                                temp.query = {
                                    "bool": {
                                        "must": {
                                            "match": { "log": query.log }
                                        },
                                        "filter": {
                                            "range": {
                                                "@timestamp": {
                                                    "lt": moment(query.end_time).utc().format(),
                                                    "gte": moment(query.begin_time).utc().format()
                                                }
                                            }
                                        }
                                    }
                                }

                            } else if (query.begin_time && query.end_time) {//时间查询
                                let begin_time = moment(query.begin_time).utc().format();
                                let end_time = moment(query.end_time).utc().format();
                                temp.query = {
                                    range: {
                                        "@timestamp": {
                                            "lt": end_time,
                                            "gte": begin_time
                                        }
                                    }
                                }
                            } else if (query.log) {//关键字查询
                                temp.query = {
                                    match: {
                                        "log": query.log
                                    }
                                }
                            }
                            console.log(query)
                            this.setState({ querys: temp })
                            this.updata(_pageSize, 1, temp, indexData.sysIndex)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(this.state._pageSize, 1, {}, indexData.sysIndex)
                        }}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>系统日志列表</span>}
                    style={{ marginTop: 8 }}
                    extra={<div>
                        {/* <Button type="primary" style={{ marginLeft: 8 }} icon="edit"
                            onClick={() => {
                                this.props.history.push('/newOperating');
                            }}>新建操作</Button> */}
                    </div>}>
                    <Table
                        dataSource={this.state.tableData}
                        columns={columns}
                        // rowSelection={rowSelection}
                        rowKey={(text) => { return text._id }}
                        size="small"
                        loading={this.state.loading}
                        bordered={false}
                        // expandRowByClick={true}
                        expandedRowRender={(record, index, indent, expanded) => {
                            return <div style={{ textAlign: "left" }}><MonacoEditor
                                language="java"
                                theme="vs"
                                width="99%"
                                height="700"
                                value={record.log}
                                options={{ selectOnLineNumbers: false }}
                            /></div>
                        }}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                console.log(page, pageSize);
                                this.updata(pageSize, page, querys, indexData.sysIndex)
                            }
                        }}
                    />

                </Card>
            </div>
        )
    }
}


export default SysLog