import React, { useEffect, useState } from 'react'
// import { ScrollRankingBoard } from '@jiaminghi/data-view-react'
import Charts from "echarts-for-react"
import './LineChart.scss'
import socket from '@/socket/index'
import Utils from "@/utils/utils";

export default () => {
  const [lineCharts, setData] = useState({});
  
  function getData() {
    return {
      // title: {
      //   text: '折线图堆叠',
      //   textStyle: {
      //     color: "#e2e2e2",
      //     fontSize: 22
      //   },
      //   x: 'center',
      //   top: 20
      // },
      tooltip: {
        trigger: 'axis'
      },
      textStyle: {
        color: "#e2e2e2",
      },
      legend: {
        data: ['邮件营销', '联盟广告', '视频广告', '直接访问', '搜索引擎'],
        textStyle: {
          color: "#e2e2e2",
        },
      },
      grid: {
        left: '3%',
        right: '5%',
        bottom: '5%',
        top: "15%",
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        axisLine: {
          lineStyle: {
            color:  '#e2e2e2' , //坐标线的颜色
          }
        },
      },
      yAxis: {
        type: 'value',
        splitLine: { // 是否显示坐标刻度线
          show: false
        },
        axisLine: {
          lineStyle: {
            color:  '#e2e2e2' , //坐标线的颜色
          }
        },
      },
      series: [
        {
          name: '邮件营销',
          type: 'line',
          stack: '总量',
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          name: '联盟广告',
          type: 'line',
          stack: '总量',
          data: [220, 182, 191, 234, 290, 330, 310]
        },
        {
          name: '视频广告',
          type: 'line',
          stack: '总量',
          data: [150, 232, 201, 154, 190, 330, 410]
        },
        {
          name: '直接访问',
          type: 'line',
          stack: '总量',
          data: [320, 332, 301, 334, 390, 330, 320]
        },
        {
          name: '搜索引擎',
          type: 'line',
          stack: '总量',
          data: [820, 932, 901, 934, 1290, 1330, 1320]
        }
      ]
    }
  }

  useEffect(() => {
    createData();

    let timer = setInterval(createData, 3000);
    // let timer = setInterval(createData, 15000);
    return () => clearInterval(timer)
  }, [])

  function createData() {
    setData(getData())
  }

  return (
    <div className="lineChart">
      <Charts className="line-chart" option={lineCharts} />
    </div>
  )
}
