import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Popconfirm, Row, Col, Collapse
} from "antd";
import http from '@/axios/axios_utils';
import api from '@/config/api/deliveryTask';
import deviceFirmUtils from '@/utils/deviceFirmUtils';
import FormModal from '@/components/EditModal/index';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import { connect } from 'react-redux'
function CommandItem(props) {
    const [visible, setVisible] = useState(false);
    const [selectItem, setSelectItem] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [_pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [query, setQuery] = useState({});
    const [tableData, setTabData] = useState([])
    const [tableLoading, setTableLoading] = useState(false)
    const columns = [
        {
            title: '序号',
            dataIndex: 'ip',
            ellipsis: true,
            width: 50,
            render: (text, item, index) => {
                return <span>{index + 1 + (pageNum - 1) * _pageSize}</span>
            }
        },
        {
            title: '名称',
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '厂商',
            dataIndex: 'firm',
            ellipsis: true,
        },
        {
            title: '型号',
            dataIndex: 'model',
            ellipsis: true,
        },
        {
            title: '描述',
            dataIndex: 'desc',
            ellipsis: true,
        },
        {
            title: '指令',
            dataIndex: 'cmd',
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 220,
            ellipsis: true,
        },
        {
            title: '操作',
            width: 180,
            render: (text, item) => {
                return <div>
                    <Button type="primary" icon="edit" size="small" onClick={() => { setSelectItem(item); setVisible(true) }}>编辑</Button>
                    &nbsp;
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData(item._id) }}
                        okText="是"
                        cancelText="否"
                    >
                        <Button type="danger" size="small" icon="delete" >删除</Button>
                    </Popconfirm>
                </div>
            }
        },
    ]
    const formList = [
        {
            label: '名称',
            type: 'INPUT',
            field: 'name',
            initialValue: '',
            placeholder: '输入内容'
        },
        {
            label: '厂商',
            type: 'AUTO',
            field: 'firm',
            initialValue: '',
            placeholder: '输入内容',
            list: deviceFirmUtils.deviceFirmsAutoInput()
        },
        {
            label: '型号',
            type: 'INPUT',
            field: 'model',
            initialValue: '',
            placeholder: '输入内容'
        },
    ]

    useEffect(() => {//componentDidMount;
        getData();
        // http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(page, page_size, querys) {
        setTableLoading(true);
        let query2 = querys || query;
        let bodyJson = {
            page_no: page || pageNum,
            page_size: page_size || _pageSize,
            ...query2,
        }
        // console.log(bodyJson)
        http.get(api.cmdItem, bodyJson, (res) => {
            if (res.code === "00") {
                setTotal(res.count);
                setTabData(res.data);
                setTableLoading(false);
                setPageNum(page || 1);
                setQuery(query2);
            } else {
                message.error("查询失败");
                setTableLoading(false);
            }
        })
    }
    function saveDataSource(val) {
        const { firm } = selectItem;
        let bodyJson = {
            ...val,
        };
        if (firm) {//修改
            delete bodyJson.create_time // 删除默认值中的 create_time参数
            http.put(api.cmdItem, bodyJson, () => {
                getData(pageNum, _pageSize, query);
                message.success("修改成功");
            })
        } else {//新增
            http.post(api.cmdItem, bodyJson, () => {
                getData(1, _pageSize);
                message.success("新增成功");
            })
        }
        setVisible(false)
    }
    function deleteData(id) {
        http.delete_body(api.cmdItem, { _id: id }, () => {
            getData(1, _pageSize, query);
            message.success("删除成功");
        })
    }
    return <div className="CommandItemDiv">
        <CustomBreadcrumb arr={["运维管理", "配置下发", "下发指令项"]} />
        <div className="head" style={{ marginBottom: 8 }}>
            <Collapse defaultActiveKey={"key"}>
                <Collapse.Panel header="筛选条件" key={"key"}>
                    <FilterForm formList={formList}
                        filterSubmit={(querys) => {
                            getData(1, _pageSize, querys)
                        }}
                        resetTab={() => {
                            getData(1, _pageSize, {})
                        }}
                    />
                </Collapse.Panel>
            </Collapse>
        </div>
        <Card title={<span style={{ fontWeight: 600 }}>下发指令项</span>}
            extra={
                <Button type="primary" icon="file-add" onClick={() => {
                    setSelectItem({})
                    setVisible(true)
                }}>添加</Button>
            }
        >
            <Table
                dataSource={tableData}
                columns={columns}
                loading={tableLoading}
                size="small"
                rowKey={(text) => { return text._id }}
                pagination={{
                    pageSize: _pageSize, current: pageNum, total: total,
                    showTotal: (total) => { return <span>共 {total} 项</span> },
                    pageSizeOptions: ["20", "50", "100", "500", "1000"],
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) => {
                        setPageSize(size)
                        getData(1, size, query)
                    },
                    onChange: (page, pageSize) => {
                        getData(page, pageSize, query)
                    }

                }}
            />
        </Card>
        <FormModal
            title="编辑下发指令项"
            visible={visible}
            onCancel={() => { setVisible(false); setSelectItem(null); }}
            onSubmit={(val) => { saveDataSource(val); }}
            footer={null}
            style={{ top: 50 }}
            width={"70%"}
            initialValues={selectItem}
            destroyOnClose
            formList={[
                { type: "input", name: "name", label: "名称", required: true, placeholder: "请输入名称" },
                { type: "autoComplete", name: "firm", label: "厂商", required: true, placeholder: "请输入厂商", selectOptions: [
                    "HUAWEI", "CISCO", "H3C", "MAIPU", "RUIJIE", "JUNIPER", "HILLSTONE", "F5", "DP", "其他"
                ] },
                { type: "input", name: "model", label: "型号", required: true, placeholder: "请输入型号" },
                { type: "input", name: "desc", label: "描述", placeholder: "请输入描述" },
                {
                    type: "code", name: "cmd", label: "执行指令", required: true,
                    placeholder: "",
                },
            ]}
        />
    </div>
}
export default connect()(CommandItem)