import React, { Fragment } from 'react'
import {
    Input, Form, Select, Divider,
    Typography, Card, Button
} from "antd";
const { Item } = Form;
const { Option } = Select;
import { connect } from 'react-redux'
import ColorPicker from 'rc-color-picker';
import 'rc-color-picker/assets/index.css';
import Utils from '@/utils/utils';

const inlineFormItemLayout = {
    labelCol: {
        sm: { span: 8 },
    },
    wrapperCol: {
        sm: { span: 16 },
    },
};
class CommForm extends React.Component {
    state = {
        edit: false,
    }
    componentWillMount() {
        // console.log(this.props)
    }

    componentDidMount() {

    }
    handleSubmit = e => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        const { form, onChange, graph, selectNode } = this.props;

        setTimeout(() => {
            form.validateFieldsAndScroll((err, values) => {
                if (err) {
                    return;
                }

                // const item = getSelected()[0];
                // if (!item) {
                //   return;
                // }
                // console.log(item);
                console.log(values);
                // onChange(values);
                const item = graph.findById(selectNode.id);
                graph.setItemState(selectNode.id, 'selected', false);
                graph.updateItem(item, values);
                form.resetFields();
            });
        }, 0);
        this.setState({ edit: false })
    };

    renderEdgeShapeSelect = () => {
        return (
            <Select onChange={this.handleSubmit}>
                <Option value="flow-smooth">Smooth</Option>
                <Option value="flow-polyline">Polyline</Option>
                <Option value="flow-polyline-round">Polyline Round</Option>
                <Option value="flow-line">flow-line</Option>
            </Select>
        );
    };

    renderNodeDetail = (selectNode) => {
        const { form } = this.props;
        const { edit } = this.state;
        const { label } = selectNode;
        const data = [
            ["显示名", "label"],
            ["设备IP", "dev_ip"],
            ["厂商", "dev_firm"],
            ["类型", "dev_type"],
            ["名称", "dev_hostname"],
            ["型号", "dev_model"],
            ["状态", "dev_status"],
        ].map((item, key) => {
            return (
                <Item key={key} label={item[0]} {...inlineFormItemLayout}>
                    {form.getFieldDecorator(item[1], {
                        initialValue: selectNode[item[1]],
                    })(edit ? <Input /> : <label>{Utils.Translation(selectNode[item[1]])}</label>)}
                </Item>
            );
        })
        return data
    };

    renderEdgeDetail = (selectNode) => {
        // console.log(selectNode)
        const { form } = this.props;
        const { edit } = this.state;
        const { label = '', target, style } = selectNode;
        //防止新建线点击空白处出错
        if (typeof target === "object") { return null; }
        const data = [
            // ["显示名", "label"],
            ["起点IP", "source"],
            ["起点端口", "start_port"],
            ["终点IP", "target"],
            ["终点端口", "end_port"],
        ].map((item, key) => {
            return (
                <Item key={key} label={item[0]} {...inlineFormItemLayout}>
                    {form.getFieldDecorator(item[1], {
                        initialValue: selectNode[item[1]],
                    })(edit ? <Input /> : <label>{selectNode[item[1]]}</label>)}
                </Item>
            );
        })
        data.push(
            <Item key={6} label={"颜色"} {...inlineFormItemLayout}>
                {form.getFieldDecorator("style.stroke", {
                    initialValue: style.stroke,
                    getValueFromEvent: (ev) => {
                        return ev.color
                    }
                })(edit ? <ColorPicker
                    animation="slide-up"
                    className="color-picker"
                    defaultColor={style.stroke} /> : <label>{style.stroke}</label>)}
            </Item>
        )
        return data
        // const { stroke = '#000', } = selectNode.style;
        // const { position } = selectNode.labelCfg;
        // return (
        //     <Fragment>
        //         <Item label="Label" {...inlineFormItemLayout}>
        //             {form.getFieldDecorator('label', {
        //                 initialValue: label,
        //             })(<Input onBlur={this.handleSubmit} />)}
        //         </Item>
        //         <Item label="Shape" {...inlineFormItemLayout}>
        //             {form.getFieldDecorator('shape', {
        //                 initialValue: shape,
        //             })(<Input onBlur={this.handleSubmit} />)}
        //         </Item>
        //         <Item label="color" {...inlineFormItemLayout}>
        //             {form.getFieldDecorator('style.stroke', {
        //                 initialValue: stroke,
        //             })(<Input onBlur={this.handleSubmit} />)}
        //         </Item>
        //         <Item label="position" {...inlineFormItemLayout}>
        //             {form.getFieldDecorator('labelCfg.position', {
        //                 initialValue: position,
        //             })(<Input onBlur={this.handleSubmit} />)}
        //         </Item>
        //     </Fragment>
        // );
    };

    renderTextDetail = (selectNode) => {
        const { form } = this.props;
        const { label } = selectNode;
        const { edit } = this.state;
        return (
            <Item label="Label" {...inlineFormItemLayout}>
                {form.getFieldDecorator('label', {
                    initialValue: label,
                })(edit ? <Input /> : <label>{selectNode["label"]}</label>)}
            </Item>
        );
    };
    renderGroupDetail = (selectNode) => {
        const { form } = this.props;
        const { label = '新建分组' } = selectNode;

        return (
            <Item label="Label" {...inlineFormItemLayout}>
                {form.getFieldDecorator('label', {
                    initialValue: label,
                })(<Input />)}
            </Item>
        );
    };
    renderComboDetail = (selectNode) => {
        const { form } = this.props;
        const { edit } = this.state;
        const { label = '新建区域', } = selectNode;
        const type = "cRect"
        return (
            <Fragment>
                <Item label="Label" {...inlineFormItemLayout}>
                    {form.getFieldDecorator('label', {
                        initialValue: label,
                    })(edit ? <Input /> : <label>{selectNode["label"]}</label>)}
                </Item>
                <Item label="形状" {...inlineFormItemLayout}>
                    {form.getFieldDecorator('type', {
                        initialValue: type,
                    })(<label>{type}</label>)}
                </Item>
            </Fragment>
        );
    };

    render() {
        const { selectNode, } = this.props;
        const { edit, } = this.state;
        if (!selectNode) {
            return null;
        }
        const type = selectNode.type;
        if (!type) {
            return null;
        }
        // console.log(selectNode)
        const { getFieldDecorator } = this.props.form;
        return <Card type="inner" size="small" bordered={false} className="detailForm">
            <Form onSubmit={this.handleSubmit} >
                {type === 'image' && this.renderNodeDetail(selectNode)}
                {type === 'rect' && this.renderTextDetail(selectNode)}
                {type === 'edge' && this.renderEdgeDetail(selectNode)}
                {type === 'group' && this.renderGroupDetail(selectNode)}
                {type === "combo" && this.renderComboDetail(selectNode)}
            </Form>
            <Divider />
            {edit ?
                <div className="detailFormBtn">
                    <Button size="small" type="danger" icon="close-circle" onClick={() => { this.setState({ edit: false }) }}>取消</Button>&nbsp;
                    <Button size="small" type="primary" icon="check-circle" onClick={() => { this.handleSubmit() }}>完成</Button>

                </div> :
                <Button size="small" icon="edit" className={"pull-right"} onClick={() => { this.setState({ edit: true }) }}>编辑</Button>}
        </Card>
    }

}
const CommObj = Form.create()(CommForm)
export default connect()(CommObj)