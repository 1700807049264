import React from 'react'
import { Button, Card, Col, Form, Icon, Input, message, Modal, Row, Spin, Table, Tag, Upload, Collapse, Dropdown, Menu } from "antd";
import Axios from "../../axios/axios";
import './index.scss';

import { SubmitButton, FormItem, TreeSelect, Select } from "formik-antd";
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
const { Option } = Select;
import { Formik } from "formik";

class ClassificationIp extends React.Component {

    state = {
        _current: 1,
        _pageSize: 20,
        total: 0,
        loading: false,
        ipSelectOptions: [],
    }

    componentWillMount() {
        this.getIpType();
    }
    getIpType() {
        let queryStr = 'ip/ipType/'
        Axios.GET(queryStr, {}, (result) => {
            if(result.status === 200 || result.status === 201 || result.status === 204) {
                let data = result.data;
                this.setState({ ipSelectOptions: data.results });
            }
            // console.log(result.data, result)
        })
    }

    // 时间格式化
    dealTime(time) {
        let formatTime = "";
        if (time) {
            let timeArr = time.split("T");
            let timeMinutes = timeArr[1].split(".")[0];
            formatTime = [timeArr[0], timeMinutes].join(" ");
        }
        return formatTime
    }
    getOutLineTime(item) {
        let freeDays = "";
        if(item.furthest_offline_time) {
            let furthestOfflineTime = new Date(item.furthest_offline_time);
            let today = new Date();
            freeDays = Math.floor( (today.getTime() - furthestOfflineTime.getTime()) / (1000 * 60 * 60 * 24) )
        }
        if(freeDays >= 0) {
            return freeDays
        } else {
            return 0
        }
    }
    classificationIp(values) {
        let { ipSelectOptions } = this.state;
        let { classifyData } = this.props
        let ids = [];
        if(values.ip_type) {
            ipSelectOptions.map(optionsItem => {
                if(optionsItem.id == values.ip_type) {
                    values.ip_type = optionsItem.name;
                }
            })
        }
        ids = classifyData.map(dataItem => {
            return dataItem.id
        })
        Axios.POST('ip/classifyIp/', { ids, ...values }, (result) => {
            let data = result.data;
            if(data.code === "00") {
                message.success(data.msg);
                this.cancel();
                
            } else {
                message.error(data.msg);
            }
        })
        // console.log("classificationIp", values, ids);
    }
    cancel() {
        this.props.cancelVis();
        this.props.clearRowKeys();
        this.props.getTableData();
    }

    render() {
        let { _current, _pageSize, ipSelectOptions } = this.state;
        let { classifyVis, classifyData } = this.props;

        const columns = [
            {
                title: '序号',
                width: 50,
                dataIndex: 'key',
                render: (text, item, index) => {
                    return <span>{index+1}</span>
                    // return <span>{index + 1 + (_current - 1) * _pageSize}</span>
                }
            },
            {
                title: 'IP',
                align: 'center',
                dataIndex: 'ip',
                width: 110,
                ellipsis: true,
            },
            {
                title: '名称',
                align: 'center',
                dataIndex: 'name',
                width: 120,
                ellipsis: true,
            },
            {
                title: 'mac地址',
                align: 'center',
                dataIndex: 'mac_address',
                width: 120,
                ellipsis: true,
            },
            {
                title: '描述',
                align: 'center',
                dataIndex: 'comment',
                width: 240,
                ellipsis: true,
            },
        ]

        return (
            <div>
                <Modal title={"归类ip"}
                    visible={classifyVis}
                    onCancel={() => this.props.cancelVis()}
                    footer={null}
                    width={"60%"}
                    destroyOnClose
                >
                    <Formik
                        onSubmit={(value) => { this.classificationIp(value) }}
                        validate={(values) => {
                            let info = {};
                            if (!values.ip_type) {
                                info.ip_type = "请选择ip类型";
                            }
                            return info
                        }}
                        initialValues={{ ip_type: '' }}
                    >
                        <FormikForm labelCol={{ span: 2 }} wrapperCol={{ span: 20 }}>
                            <FormItem name={"ip_type"} label={"分类"} key={"ip_type"} required={true}>
                                <Select name={"ip_type"} allowClear placeholder="请选择监控IP分类" >
                                    {ipSelectOptions.map(item => (
                                        <Option key={item.id} label={item.name}>{item.name}</Option>
                                    ))}
                                </Select>
                            </FormItem>
                            <FormItem name={"tableData"} label={"选中ip"} key={"tableData"} >
                                <Table
                                    dataSource={classifyData}
                                    columns={columns}
                                    loading={this.state.loading}
                                    size="small"
                                    rowKey={(text) => { return text.id }}
                                    pagination={false}
                                />
                            </FormItem>
                            <div style={{ textAlign: "right" }}>
                                <Button onClick={() => { this.props.cancelVis() }}>取消</Button>
                                <SubmitButton style={{ marginLeft: "10px" }} disabled={false}>保存</SubmitButton>&nbsp;
                            </div>
                        </FormikForm>
                    </Formik>
                </Modal>
            </div>
        );
    }

}

const ClassificationIpForm = Form.create()(ClassificationIp);
export default ClassificationIpForm;