import React, { PureComponent, Fragment } from 'react';
import { Table, Button, Input, message, Popconfirm, Divider, Select, Icon } from 'antd';
import isEqual from 'lodash/isEqual';
import styles from './style.scss';
import './style.scss';
import Utils from '@/utils/utils';
const Option = Select.Option;
const inputWidth = '80px';

class TableForm extends PureComponent {
  index = 0;

  cacheOriginData = {};

  constructor(props) {
    super(props);

    this.state = {
      data: props.value,
      loading: false,
      showHeader: props.showHeader,
      size: props.size,
      columnsGroup: props.columnsGroup,
      /* eslint-disable-next-line react/no-unused-state */
      value: props.value,
      columns: this.createColums(props.columnsGroup)
    };
  }

  static getDerivedStateFromProps(nextProps, preState) {
    if (isEqual(nextProps.value, preState.value)) {
      return null;
    }
    return {
      data: nextProps.value,
      value: nextProps.value,
    };
  }

  getRowByKey(key, newData) {
    const { data } = this.state;
    return (newData || data).filter(item => item.key === key)[0];
  }

  toggleEditable = (e, key) => {
    // e.preventDefault();
    const { data } = this.state;
    const newData = data.map(item => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      // 进入编辑状态时保存原始数据
      if (!target.editable) {
        this.cacheOriginData[key] = { ...target };
      }
      target.editable = !target.editable;
      this.setState({ data: newData });
    }
  };

  newMember = () => {
    const { data } = this.state;
    const newData = data.map(item => ({ ...item }));
    newData.push({
      key: `NEW_TEMP_ID_${this.index}`,
      editable: true,
      isNew: true,
    });
    this.index += 1;
    this.setState({ data: newData });
  };

  remove(key) {
    const { data } = this.state;
    const { onChange } = this.props;
    const newData = data.filter(item => item.key !== key);
    this.setState({ data: newData });
    onChange(newData);
  }

  handleKeyPress(e, key) {
    if (e.key === 'Enter') {
      this.saveRow(e, key);
    }
  }

  handleFieldChange(e, fieldName, key) {
    const { data } = this.state;
    const newData = data.map(item => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      if (e.target) {
        target[fieldName] = e.target.value;
      } else {
        target[fieldName] = e;
      }

      this.setState({ data: newData });
      const _this = this;
      setTimeout(() => {
        _this.saveRow(e, key);
      }, 10)
    }
  }

  saveRow(e, key) {
    // e.persist();
    // this.setState({
    //   loading: true,
    // });
    setTimeout(() => {
      if (this.clickedCancel) {
        this.clickedCancel = false;
        return;
      }
      const target = this.getRowByKey(key) || {};
      // if (!target.variable || !target.label || !target.description
      //   || !target.type || !target.required || !target.default
      // ) {
      //   message.error('请填写完整成员信息。');
      //   e.target.focus();
      //   this.setState({
      //     loading: false,
      //   });
      //   return;
      // }
      delete target.isNew;
      this.toggleEditable(e, key);
      const { data } = this.state;
      console.log(data);
      const { onChange } = this.props;
      onChange(data);
      // this.setState({
      //   loading: false,
      // });
    }, 100);
  }

  cancel(e, key) {
    this.clickedCancel = true;
    // e.preventDefault();
    const { data } = this.state;
    const newData = data.map(item => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (this.cacheOriginData[key]) {
      Object.assign(target, this.cacheOriginData[key]);
      delete this.cacheOriginData[key];
    }
    target.editable = false;
    this.setState({ data: newData });
    this.clickedCancel = false;
  }
  createColums(columnsGroup) {
    // const { columnsGroup } = this.state;
    let _this = this;
    let columns = columnsGroup.map((groupItem, key) => {
      switch (groupItem.type) {
        case 'input':
          return {
            title: groupItem.display || "参数",
            dataIndex: groupItem.name,
            key: groupItem.name,
            // width: 50,
            render: (text, record) => {
              // if (record.editable) {
              if (true) {
                // console.log(record);
                // console.log(text);
                return (
                  <Input
                    value={text}
                    key={key}
                    // style={{ width: inputWidth }}
                    onChange={e => _this.handleFieldChange(e, groupItem.name, record.key)}
                    onKeyPress={e => _this.handleKeyPress(e, record.key)}
                    placeholder={groupItem.placeholder}
                  />
                );
              } else {
                return text;
              }
            },
          }
        case 'select':
          return {
            title: groupItem.display || "参数",
            dataIndex: groupItem.name,
            key: groupItem.name,
            // width: 50,
            render: (text, record) => {
              // if (record.editable) {
              if (true) {
                // console.log(record);
                return (
                  <Select
                    value={text}
                    key={key}
                    // style={{ width: inputWidth }}
                    onChange={e => _this.handleFieldChange(e, groupItem.name, record.key)}
                    onKeyPress={e => _this.handleKeyPress(e, record.key)}
                  >
                    {groupItem.option.map((option, key) => {
                      return <Option value={option} key={option}>{option}</Option>
                    })}
                  </Select>
                );
              }
              return text;
            },
          }
        case 'label':
          return {
            title: groupItem.display || "参数",
            // dataIndex: groupItem.name,
            key: groupItem.name || new Date().getTime(),
            // width: 50,
            render: (text, record) => {
              // if (record.editable) {
              // if (true) {
              //   // console.log(record);
              //   return (
              //     <Select
              //       value={text}
              //       key={key}
              //       // style={{ width: inputWidth }}
              //       onChange={e => _this.handleFieldChange(e, groupItem.name, record.key)}
              //       onKeyPress={e => _this.handleKeyPress(e, record.key)}
              //     >
              //       {groupItem.option.map((option, key) => {
              //         return <Option value={option} key={option}>{option}</Option>
              //       })}
              //     </Select>
              //   );
              // }
              return groupItem.display;
            },
          }
      }
    })
    columns.push({
      title: '操作',
      // width: 50,
      key: 'action',
      render: (text, record) => {
        const { loading } = _this.state;
        if (!!record.editable && loading) {
          return null;
        }
        if (record.editable) {
          if (record.isNew) {
            return (
              <span>
                {/* <a onClick={e => _this.saveRow(e, record.key)}>保存 </a>
                <Divider type="vertical" /> */}
                <a onClick={() => _this.remove(record.key)}>删除</a>
                {/* <Button type="primary" icon="save" onClick={e => _this.saveRow(e, record.key)}></Button>
                <Divider type="vertical" />
                <Popconfirm title="是否要删除此行？" onConfirm={() => _this.remove(record.key)}>
                  <Button type="danger" icon="delete"></Button>
                </Popconfirm> */}
              </span>
            );
          }
          return (
            <span>
              {/* <a onClick={e => _this.saveRow(e, record.key)}>保存</a>
              <Divider type="vertical" /> */}
              <a onClick={e => _this.cancel(e, record.key)}>取消</a>
            </span>
          );
        }
        return (
          <span>
            {/* <a onClick={e => _this.toggleEditable(e, record.key)}>编辑</a> */}
            {/* <Divider type="vertical" /> */}
            <a onClick={() => _this.remove(record.key)}>删除</a>
          </span>
        );
      },
    })
    return columns;
  }

  render() {
    const columns222 = [
      {
        title: '键名称',
        dataIndex: 'variable',
        key: 'variable',
        width: '15%',
        render: (text, record) => {
          if (record.editable) {
            // console.log(record);
            return (
              <Input
                value={text}
                autoFocus
                onChange={e => this.handleFieldChange(e, 'variable', record.key)}
                onKeyPress={e => this.handleKeyPress(e, record.key)}
                placeholder="参数键名称"
              />
            );
          }
          return text;
        },
      },
      {
        title: '显示名称',
        dataIndex: 'label',
        key: 'label',
        width: '16%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={e => this.handleFieldChange(e, 'label', record.key)}
                onKeyPress={e => this.handleKeyPress(e, record.key)}
                placeholder="显示名称"
              />
            );
          }
          return text;
        },
      },
      {
        title: '描述',
        dataIndex: 'description',
        key: 'description',
        width: '15%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={e => this.handleFieldChange(e, 'description', record.key)}
                onKeyPress={e => this.handleKeyPress(e, record.key)}
                placeholder="参数描述"
              />
            );
          }
          return text;
        },
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        width: '12%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={e => this.handleFieldChange(e, 'type', record.key)}
                onKeyPress={e => this.handleKeyPress(e, record.key)}
                placeholder="类型"
              />
            );
          }
          return text;
        },
      },
      {
        title: '是否必填',
        dataIndex: 'required',
        key: 'required',
        width: '17%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={e => this.handleFieldChange(e, 'required', record.key)}
                onKeyPress={e => this.handleKeyPress(e, record.key)}
                placeholder="true或者false"
              />
            );
          }
          return text;
        },
      },
      {
        title: '默认值',
        dataIndex: 'default',
        key: 'default',
        width: '14%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={e => this.handleFieldChange(e, 'default', record.key)}
                onKeyPress={e => this.handleKeyPress(e, record.key)}
                placeholder="默认值"
              />
            );
          }
          return text;
        },
      },
      {
        title: '操作',
        key: 'action',
        render: (text, record) => {
          const { loading } = this.state;
          if (!!record.editable && loading) {
            return null;
          }
          if (record.editable) {
            if (record.isNew) {
              return (
                <span>
                  <a onClick={e => this.saveRow(e, record.key)}>添加</a>
                  <Divider type="vertical" />
                  <a onClick={() => this.remove(record.key)}>删除</a>
                </span>
              );
            }
            return (
              <span>
                {/* <a onClick={e => this.saveRow(e, record.key)}>保存</a>
                <Divider type="vertical" /> */}
                <a onClick={e => this.cancel(e, record.key)}>取消</a>
              </span>
            );
          }
          return (
            <span>
              {/* <a onClick={e => this.toggleEditable(e, record.key)}>编辑</a>
              <Divider type="vertical" /> */}
              <a onClick={() => this.remove(record.key)}>删除</a>
            </span>
          );
        },
      },
    ];

    const { loading, data, columns, showHeader, size, } = this.state;

    return (
      <Fragment>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
          // rowClassName={record => (record.editable ? styles.editable : '')}

          showHeader={showHeader}
          size={size}
          className="inputTab"
        />
        <Button
          style={{ width: '100%', }}
          // type="dashed"
          // type="primary"
          // ghost
          onClick={this.newMember}
          icon="plus"
        >
          新增参数
        </Button>
      </Fragment>
    );
  }
}

export default TableForm;
