import React from 'react'
import {
    Button, Icon, Popconfirm, message, Card, Collapse, Table, Tag, Modal, Upload,
    Pagination, DatePicker, Tabs, notification, Select
} from "antd";

import Socket from '@/socket/index';
import './index.scss';
import $ from "jquery";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import Utils from '@/utils/utils';
const { Panel } = Collapse;
const { TabPane } = Tabs;

class CompareInfo extends React.Component {
    state = {
        compareData: []
    }
    componentWillMount() {
    }
    componentWillReceiveProps(props) {
        let compareData = props.compareData;
        this.setState({ compareData });
        if (compareData.length > 1) {
            setTimeout(() => {
                compareData.map((item, index) => {
                    Utils.mixTable("createDevMacTab" + index)
                })
            }, 10);
        }

    }
    componentDidMount() {
    }
    commonTable(title, data, taskEndTime) {
        const colum = title.map((item) => {
            item["render"] = (_text, _ite, _index) => {
                return Utils.isHightLight(_text);
            };
            return item
        })
        // console.log(title, data);

        return <div className="commonTableDiv" ><p>{"时间节点：" + taskEndTime}</p><Table
            columns={title}
            dataSource={data}
            pagination={false}
        /></div>
    }
    createDevMacTab(data, taskEndTime, keyID) {
        const testTab = data.data;
        const tabTitle = data.title;
        return <table className="compareTableModal" id={"createDevMacTab" + keyID}>
            <thead>
                <tr>
                    <th>{"时间节点:"}</th>
                    <th>{taskEndTime}</th>
                    <th>-</th>
                </tr>
                <tr>
                    {tabTitle.map((item) => {
                        return <th >{item.title}</th>
                    })}
                </tr>
                {/* <tr><th>{tabTitle[0]}</th><th>{tabTitle[1]}</th></tr> */}
                {testTab.map((item) => {
                    let JsonArr = [item.deviceIp, item.portName_link_macTable, item.macTable]
                    let textArr = JsonArr.map((arrItem) => { return Utils.isHightLight(arrItem) })
                    return <tr>
                        {textArr.map((_textItem, textKey) => { return <td key={textKey}>{_textItem}</td> })}
                    </tr>
                })}
            </thead>
        </table>
    }

    render() {
        let { compareData } = this.state;
        return <Modal
            {...this.props}
            destroyOnClose
        >
            <div>

                <Tabs>

                    {
                        // compareData.map((itemAll, keyAll) => {

                        compareData[0] && compareData[0].taskResult.data.map((tabItem, tabKey) => {
                            // compareData.taskResult.data.map((tabItem, tabKey) => {
                            console.log(tabItem);
                            return <TabPane tab={<span><Icon type="profile" />{tabItem.sheetName}</span>} key={tabKey} forceRender={true}>
                                {compareData.map((item, keyID) => {

                                    let tabData = item.taskResult.data;
                                    return tabData.map((tabItem2, key2) => {

                                        if (tabItem2) {
                                            if (tabItem2.type === "common" && tabItem.type === "common") {
                                                console.log(tabItem2);
                                                return this.commonTable(tabItem2["title"], tabItem2["data"], item.taskEndTime)
                                            } else if (tabItem2.type === "merge" && tabItem.type === "merge") {
                                                return this.createDevMacTab(tabItem2, item.taskEndTime, keyID)
                                            }

                                        } else {
                                            return null;
                                        }

                                    })

                                })}</TabPane>

                        })


                        // <TabPane tab={<span><Icon type="profile" />设备MAC Table</span>} key={2} forceRender={true}>
                        //     {compareData.map((item, keyID) => {
                        //         console.log(item);
                        //         if (item) {
                        //             return this.createDevMacTab(item.taskResult.data[1], item.taskEndTime, keyID)
                        //         } else {
                        //             return null;
                        //         }
                        //     })}</TabPane>

                        // })
                    }

                </Tabs>
            </div>
        </Modal>
    }
}
export default CompareInfo