import React from "react";
import Axios from "axios";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb';
import Topology from './topology'
import { Button, Card, Cascader, Table, Modal, Form, Input, message } from "antd";
const { confirm } = Modal;


const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
    class extends React.Component {



        render() {
            const { visible, onCancel, onCreate, form } = this.props;
            const { getFieldDecorator } = form;
            const { TextArea } = Input
            return (
                <Modal
                    visible={visible}
                    title="创建一个新的拓扑图"
                    okText="Create"
                    onCancel={onCancel}
                    onOk={onCreate}
                >
                    <Form layout="vertical">
                        <Form.Item label="拓扑图名称">
                            {getFieldDecorator('topologyName', {
                                rules: [{ required: true, message: '请输入为高拓扑图取一个名字' }],
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="位置">
                            {getFieldDecorator('location')(<Input type="textarea" />)}
                        </Form.Item>
                        <Form.Item label="描述">
                            {getFieldDecorator('description')(<TextArea rows={4} />)}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    },
);

class Index extends React.Component {


    state = {
        data: {},
        tableData: null,
        visible: false,
        currentTopology: {},
        topology_id: null
    }

    constructor(props) {
        super(props);
        this.delete.bind(this);
        this.getTopology.bind(this);
        this.getTopologyList.bind(this);
    }

    componentWillMount() {

        let _this = this
        this.getTopologyList();


    }


    getTopologyList() {
        let _this = this;
        Axios.get("http://" + topo_address + "/topologygraph/all")
            .then(function (response) {
                _this.setState({ tableData: response.data })
            })
    }



    getTopology(name) {
        let _this = this;
        Axios.get("http://" + topo_address + "/topology/getChartData/" + name).
            then(function (response) {
                _this.setState({ data: response.data })
            })
    }

    showDeleteConfirm(id) {

        let _this = this;

        confirm({
            title: '你确定删除这个图吗？',
            content: '删除后不可恢复',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.delete(id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    delete(id) {
        let _this = this;

        Axios.delete("http://" + topo_address + "/topologygraph/" + id)
            .then(function () {
                _this.getTopologyList();
                _this.setState({ currentTopology: {}, data: {} })
            })
    }

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    handleCancel = () => {
        this.setState({ visible: false })
    }

    handleCreate = () => {

        let _this = this;
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            Axios.post("http://" + topo_address + "/topologygraph/", values)
                .then(function () {
                    message.info("添加成功")
                    _this.getTopologyList();
                })

            form.resetFields();
            this.setState({ visible: false });
        });
    }

    showModal = () => {
        this.setState({ visible: true })
    }

    render() {

        const columns = [
            { title: '拓扑名称', dataIndex: 'topologyName', key: 'topologyName' },
            { title: '位置', dataIndex: 'location', key: 'location' },
            { title: '创建日期', dataIndex: 'createDate', key: 'createDate' },
            { title: '描述', dataIndex: 'dsescription', key: 'dsescription' },
            {
                title: 'action',
                dataIndex: 'state',
                key: 'x',
                render: (text, record, index) =>
                    <div>
                        <Button onClick={this.showDeleteConfirm.bind(this, record.id)}>删除</Button>
                    </div>
            },
        ];
        return (
            <div>
                <CustomBreadcrumb arr={["网络拓扑", "自动发现设备"]} />
                <Card>
                    <span style={{ fontWeight: "bold", color: "#666" }}>拓扑管理&nbsp;&nbsp;</span>
                    <div style={{ marginBottom: 8, marginTop: 17 }}><Button type="primary" onClick={this.showModal}>新增拓扑图</Button></div>
                    <Table onRow={record => {
                        return {
                            onClick: event => {
                                if (this.state.currentTopology.topologyName !== record.topologyName) {
                                    this.setState({ currentTopology: record })
                                    this.getTopology(record.topologyName)
                                }
                            }
                        };
                    }} dataSource={this.state.tableData} columns={columns} rowKey="id" size="small"></Table>
                </Card>
                <Topology data={this.state.data} topology_id={this.state.currentTopology.id} />
                <CollectionCreateForm
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    onCreate={this.handleCreate}
                />
            </div>
        )
    }
}
export default Form.create()(Index);