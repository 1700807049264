import React from 'react'
import {
    Card, Table, Switch, DatePicker, Button, Modal, notification, Icon, message,
    Input, Menu, Row, Col, Tabs, Tag
} from "antd";
import './index.scss'
import Socket from '@/socket/index'

import Utils from '@/utils/utils'
import QueueAnim from 'rc-queue-anim';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import Visible from './Visible';
import MonacoEditor from 'react-monaco-editor';
const Search = Input.Search;
const TabPane = Tabs.TabPane;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const defaultCode =
    `export default {
  name: 'name',
  code: 'code'
}`;

export default class Task extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _pageSize: 10,    //当前每页显示条数
        filteredInfo: null,
        visible: false,
        sortedInfo: null,
        loading: false,
        total: 0,
        code: defaultCode,
    };

    componentWillMount() {
        this.updata();

    }
    editorDidMount(editor, monaco) {
        console.log('editorDidMount', editor);
        editor.focus();
    }
    onChange(newValue, e) {
        console.log('onChange', newValue, e);
    }

    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        Socket.QueryTasksPage(pageSize, pageNum, querys, (result) => {
            let tableData = result.tasks;
            tableData.map((item, index) => {
                item.key = index;
            });
            this.setState({
                tableData,
                _current: pageNum || 1,
                total: result.total,
                loading: false,
            })

        }, (error) => {
            notification.open({
                message: '查询失败',
                description: error,
                icon: <Icon type="frown" style={{ color: 'red' }} />,
            });
        }, "/system");
    };
    showModal = () => {
        this.setState({
            visible: true,
        });
    }
    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };
    handleOk = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };
    TabCallback = (key) => {
        console.log(key);
    };
    TimeOnChange = (date, dateString) => {
        console.log(date, dateString);
    };
    SetStick = (taskId, isUp) => {
        let action = 'SetStick';
        if (!isUp) {
            action = 'UnSetStick';
        }
        Socket.Action(action, { _id: taskId }, (resp) => {
            if (resp.retcode === '00') {
                message.success('操作成功！')
            } else {
                notification.open({
                    message: '操作失败',
                    description: resp.error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            }
        }, "/" + action)
    };
    render() {
        let { sortedInfo, filteredInfo, tableData, _current, _pageSize } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};
        const menu = (
            <Menu onClick={(item) => { }}>
                <Menu.Item key="1"><Icon type="ordered-list" />指令</Menu.Item>
                <Menu.Item key="2"><Icon type="login" />登录</Menu.Item>
                <Menu.Item key="3"><Icon type="project" />端口</Menu.Item>
                <Menu.Item key="4"><Icon type="edit" />编辑设备</Menu.Item>
                <Menu.Item key="5"><Icon type="delete" />删除设备</Menu.Item>
            </Menu>
        );
        const InsMenu = (
            <Menu onClick={(item) => { }}>
                <Menu.Item key="1"><Icon type="play-circle" />开始巡检</Menu.Item>
                <Menu.Item key="2"><Icon type="stop" />停止巡检</Menu.Item>
                <Menu.Item key="3"><Icon type="schedule" />建立任务</Menu.Item>
                <Menu.Item key="4"><Icon type="project" />查看结果</Menu.Item>
                <Menu.Item key="5"><Icon type="download" />下载报告</Menu.Item>
            </Menu>
        );
        const columns = [{
            title: '序号',
            align: 'center',
            dataIndex: 'key',
            render: (text, item, index) => { return <span>{text + 1}</span> }
        }, {
            title: '任务名称',
            align: 'center',
            dataIndex: 'taskName'
        }, {
            title: '状态',
            align: 'center',
            dataIndex: 'taskStatus',
            render: (text, item, index) => {
                return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>)
            }
        }, {
            title: '方式',
            align: 'center',
            dataIndex: 'taskPlan.type',
            render: (text, item, index) => {
                return (<span>{Utils.Translation(text)}</span>)
            }
        }, {
            title: '创建时间',
            align: 'center',
            dataIndex: 'createTime'
        }, {
            title: '创建用户',
            align: 'center',
            dataIndex: 'createUserId'
        }, {
            title: '预计耗时',
            align: 'center',
            dataIndex: 'predictTime'
        }, {
            title: '开始时间',
            align: 'center',
            dataIndex: 'beginTime'
        }, {
            title: '结束时间',
            align: 'center',
            dataIndex: 'endTime'
        }, {
            title: '首页展示',
            align: 'center',
            render: (text, item, index) => {
                return (
                    <Switch checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                        defaultChecked={item.hasOwnProperty("stick") ? true : false}
                        onChange={(checked, Event) => {
                            this.SetStick(item._id, checked);
                        }}
                    />)
            }
        }];
        const charDataColums = [
            {
                // title: '名称',
                // align:'left',
                dataIndex: 'taskName'
            }
        ];
        const rowSelection = {
            type: 'radio',
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
        };

        const code = this.state.code;
        const options = {
            selectOnLineNumbers: true
        };
        return (
            <div>
                <CustomBreadcrumb arr={["配置管理", "系统"]} />
                <QueueAnim type="left" duration={1500} interval={600} >
                    <Visible key="1" />
                    <Card key="2"
                        style={{ marginTop: 8 }}>
                        <Row>
                            <Col span={12} offset={6}>
                                <Search
                                    placeholder="输入任务名称"
                                    onSearch={(value) => {
                                        let body = { taskName: value };
                                        if (value.replace(/\s+/g, "") === '') {
                                            body = {};
                                        }
                                        this.updata(_pageSize, 1, body);
                                    }}
                                    enterButton={<Button type="primary" icon="search">搜索</Button>}
                                    size="large"
                                />
                            </Col>
                        </Row>
                    </Card>
                    <Card key="3"
                        title={<span style={{ fontWeight: 600 }}>任务列表</span>}
                        style={{ marginTop: 8 }}
                    //      extra={
                    // <Button type="primary" icon="edit" style={{marginLeft: 8}} onClick={() => {this.props.history.push('/newTask');}}>
                    //      新建任务
                    //  </Button>}
                    >
                        <Table
                            dataSource={tableData}
                            columns={columns}
                            size="small"
                            loading={this.state.loading}
                            onChange={() => this.handleChange}
                            pagination={{
                                current: _current, pageSize: _pageSize, total: this.state.total,
                                onChange: (page, pageSize) => {
                                    //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                    this.updata(pageSize, page, {});
                                }
                            }}
                        />

                    </Card>
                    {/*<Card key="4"*/}
                    {/*title={<span style={{fontWeight:600}}>编辑器</span>}*/}
                    {/*style={{marginTop: 8}}*/}
                    {/*>*/}
                    {/*<div>*/}
                    {/*<MonacoEditor*/}
                    {/*width="800"*/}
                    {/*height="500"*/}
                    {/*language="javascript"*/}
                    {/*// theme="vs-dark"*/}
                    {/*value={code}*/}
                    {/*options={options}*/}
                    {/*onChange={this.onChange}*/}
                    {/*editorDidMount={this.editorDidMount}*/}
                    {/*/>*/}
                    {/*</div>*/}

                    {/*</Card>*/}
                </QueueAnim>
            </div>
        )
    }
}
