import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Divider, Popconfirm, Tag, Modal
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import { custom_metrics } from '../utils/api';
import { connect } from 'react-redux'
import moment from 'moment';
import "animate.css"
import { SubmitButton, FormItem } from "formik-antd";
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik";


function CustomMetrics(props) {
    const [visible, setVisible] = useState(false);
    const [total, setTotal] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [selectItem, setSelectItem] = useState({});
    const [tableData, setTabData] = useState([])
    const columns = [
        {
            title: '序号',
            dataIndex: 'key',
            ellipsis: true,
            width: 80,
            render: (text, item, c) => { return c + 1 + (_current - 1) * _pageSize }
        },
        {
            title: '自定义指标名称',
            // width: 180,
            dataIndex: 'name',
            ellipsis: true,
            render: (text, item, index) => {
                return <Button type="link" onClick={() => {
                    sessionStorage.setItem("oidList", JSON.stringify({ oids: item.oids, custom_metric_id: item.id, custom_metric_name: item.name }));
                    props.history.push("/monitorAlert/dataCollection/oidList");
                }}>{text}</Button>
            }
        },
        {
            title: '描述',
            // width: 150,
            dataIndex: 'description',
            ellipsis: true
        },
        {
            title: '操作',
            width: 180,
            render: (text, item, i) => {
                return <div key={i}>
                    <a onClick={() => { setSelectItem(item); setVisible(true); }}>
                        <Icon type="edit" />&nbsp;编辑
                    </a>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteCustomMetrics(item.id) }}
                        okText="是"
                        cancelText="否"
                    >
                        <a><Icon type="delete" />&nbsp;删除</a>
                    </Popconfirm>
                </div>
            }
        },
    ]
    useEffect(() => {//componentDidMount;
        getData();
        http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(page_no = 1, page_size) {
        http.get(custom_metrics(), { page_no, page_size: page_size || _pageSize }, (r) => {
            console.log(r)
            if (r.code === '00') {
                setTabData(r.data.custom_metric_list);
                setTotal(r.data.total_page);
                set_current(page_no);
                set_pageSize(page_size || _pageSize);
            } else {
                message.error(r.msg)
            }
        })
    }
    function saveCustomMetrics(val) {
        const { id } = selectItem;
        if (id) {//修改
            http.put(custom_metrics() + '/' + id, val, (res) => {
                if (res.code === '00') {
                    getData(1, _pageSize, {});
                    message.success("修改成功");
                } else {
                    message.error(res.msg)
                }
            })
        } else {//新增
            http.post(custom_metrics(), val, (res) => {
                if (res.code === '00') {
                    getData(1, _pageSize, {});
                    message.success("新增成功");
                } else {
                    message.error(res.msg)
                }
            })
        }
        setVisible(false)
    }
    function deleteCustomMetrics(id) {
        http.delete_body(custom_metrics() + '/' + id, {}, (r) => {
            if (r.code === '00') {
                getData(1, _pageSize, {});
                message.success("删除成功");
            } else {
                message.error(r.msg);
            }
        })

    }
    return <div className="monitorCustomMetrics">
        <CustomBreadcrumb arr={["监控告警", "数据采集", "自定义指标"]} />
        <Card title="自定义指标列表" extra={<div>
            <Button type="primary" icon="file-add" onClick={() => {
                setVisible(true);
                setSelectItem({});
            }}>添加</Button>&nbsp;
        </div>}>
            <Table
                dataSource={tableData}
                columns={columns}
                size="small"
                rowKey={r => r.id}
                pagination={
                    {
                        current: _current, pageSize: _pageSize, total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            getData(1, size)
                        },
                        onChange: (page, pageSize) => { getData(page, pageSize); }
                    }
                }
            />
        </Card>
        <Modal title={selectItem.id ? "编辑自定义指标" : "新增自定义指标"}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
            width={"60%"}
            destroyOnClose
        >
            <Formik onSubmit={(value) => { saveCustomMetrics(value) }}
                validate={(values) => {
                    let info = {};
                    if (!values.name) {
                        info.name = "请输入自定义指标名称";
                    }
                    return info
                }}
                initialValues={{
                    name: selectItem.name || '',
                    description: selectItem.description || '',
                }}
            >
                <FormikForm labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                    <FormItem name={"name"} label={"自定义指标名称"} key={"name"} required={true} >
                        <FormikInput name={"name"} placeholder={"请输入自定义指标名称"} allowClear />
                    </FormItem>
                    <FormItem name={"description"} label={"描述"} key={"description"} >
                        <FormikInput name={"description"} placeholder={"请输入描述信息"} allowClear />
                    </FormItem>
                    <div style={{ textAlign: "right" }}>
                        <Button onClick={() => { setVisible(false) }}>取消</Button>
                        <SubmitButton style={{ marginLeft: "10px" }} disabled={false}>保存</SubmitButton>&nbsp;
                    </div>
                </FormikForm>
            </Formik>
        </Modal>
    </div>
}

export default connect()(CustomMetrics)