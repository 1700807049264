import React from 'react'
import {
    Card, Table, Button, Icon, message, notification, Modal, Menu, Dropdown, Steps, InputNumber,
    Select, Tabs, Badge, Typography, Radio, Tooltip, Tag, DatePicker, Alert,
} from "antd";
import socket from "@/socket/index";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';

import AxiosUtils from '@/axios/axios_utils';
import Api from '@/config/api/backup';
import DyHeadTable from '@/components/DyHeadTable/index';
import InFoForm from './infoForm';
import AddForm from './addForm';
import ResUtils from "@/utils/ResUtils";
import AxiosObj from '@/axios/index';
import BackupFtp from './backupFtp';
import BackupCMD from './backUpCmd';
import moment from 'moment';
import './index.scss';
import MonacoEditor from 'react-monaco-editor';
const { Step } = Steps;
const { TabPane } = Tabs;
const { confirm } = Modal;
export default class ConfigBackUp extends React.Component {
    state = {
        _current: 1,      //当前页码数
        _pageSize: 20,    //每页显示条数
        querys: null,    //查询条件
        total: 0,
        _current1: 1,      //当前页码数
        _pageSize1: 20,    //每页显示条数
        querys1: null,    //查询条件 
        total1: null,    //查询条件 
        selectObj: {},
        HistoryData: [],
        sysname: sessionStorage.getItem('userName') + "/" + sessionStorage.getItem('nickName'),
        userId: sessionStorage.getItem('userId'),
        loading: false,
        configSpin: false,
        visible_back: false,
        visible_setting: false,
        selectedRowKeys: [],
        addselectedRowKeys: [],
        FileVis: false,
        ImportVis: false,
        configModal: false,
        detailLoading: false,
        configData: "",
        hisConfigData: "",
        fileName: "",
        interData: {},
        selectDetail: {},
        step_count: 0,
        tabKey: "text",
        allTime: moment(new Date(), "YYYY-MM-DD HH:mm:ss "),
        backIpToStop: '',
        isShowPageSize: true,
    };
    componentWillMount() {
        let { pageSize, pageNum, querys } = this.props.location.bodyJson || {}
        this.updata(pageSize, pageNum, querys || {});
        Utils.setProjectThis(this);

    }
    componentWillUnmount() {
        if (this.state.progressInte) {
            clearInterval(this.state.progressInte);//停止
        }
    }
    next() {
        const step_count = this.state.step_count + 1;
        this.setState({ step_count });
    }

    prev() {
        const step_count = this.state.step_count - 1;
        this.setState({ step_count });
    }
    updata = (pageSize, pageNum, querys) => {//更新配置备份列表 
        this.setState({ loading: true, });
        let APINAME = "findDeviceByPage";
        if (querys && JSON.stringify(querys) != "{}") { APINAME = "findDeviceByAttr" };
        let { _pageSize1, _current1, total1, sysname, querys1, userId } = this.state;
        let bodyJson = {};
        bodyJson.page_size = pageSize || _pageSize1;
        bodyJson.page_no = pageNum || _current1;
        bodyJson.filter_data = querys || querys1;
        bodyJson.sysname = sysname;
        bodyJson.userId = userId;
        console.log(bodyJson)
        AxiosObj.HTTP_POST("backUp", APINAME, bodyJson, (result) => {
            let tableData = (result.data || []).map((item, key) => {
                item.key = key;
                return item;
            })
            let selectObj = tableData[0];
            // console.log(tableData, selectObj)
            this.setState({
                tableData,
                selectObj,
                _current1: pageNum || 1,
                total1: result.device_count || result.count || total1,
                loading: false,
                filter_data: null,
                querys1: bodyJson.filter_data,
                _pageSize1: pageSize || _pageSize1,
                isShowPageSize: pageSize === 200000000 ? false : true // 是否显示页码大小

            })
        }, (error) => {
            notification.open({
                message: '查询失败',
                description: error,
                icon: <Icon type="frown" style={{ color: 'red' }} />,
            });
        });

    };
    updataDev = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        socket.ExecuteAction('QueryResourcesPage',
            bodyJson, "/queryResource", (result) => {
                let Resources = result.resources;
                Resources.map((item, index) => {
                    item.key = index;
                    item.index = index;
                    return item
                });
                this.setState({
                    DeviceData: Resources,
                    _current: pageNum || 1,
                    total: result.total,
                    loading: false,
                })
            }, (error) => {
                Utils.showMessage("error", "查询失败", error);
                this.setState({ loading: false })
            });

    };
    bathDelDev = () => {
        let { selectedRowKeys } = this.state;
        if (Utils.isEmpty(selectedRowKeys)) {
            message.error("请选择要删除的设备");
            return;
        }
        Modal.confirm({
            title: '提示',
            content: <div style={{ overflowY: "auto", maxHeight: 500 }}><p>确定删除选择的备份设备吗？</p>{selectedRowKeys.map((ip) => <div>{ip}</div>)}</div>,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.setState({ loading: true, });
                AxiosObj.HTTP_POST("backUp", "batchDelBackupDevice", { ips: selectedRowKeys }, (result) => {
                    notification.success({ message: '提示', description: result.msg });
                    this.updata();
                }, (error) => {
                    Utils.showMessage("error", '操作失败', error);
                });
            }
        });
    }

    getSettingDetailData = (ip) => {
        let { sysname } = this.state;
        // if (Utils.isEmpty(selectedRowKeys)) {
        //     message.info("请选择设备！");
        //     return;
        // } else if (selectedRowKeys.length > 1) {
        //     message.info("请选择一条设备！");
        //     return;
        // }
        this.setState({ loading: true, })
        AxiosUtils.post(Api["querySetting"], { ip, sysname }, (result) => {
            let selectDetail = result.data
            selectDetail.password = Utils.DecryptPwd(selectDetail.password);
            selectDetail.sys_pwd = Utils.DecryptPwd(selectDetail.sys_pwd);
            this.setState({
                selectDetail,
                visible_setting: true,
                loading: false
            })
        }, (error) => {
            Utils.showMessage("error", "查询失败", error);
            this.setState({ loading: false })
        });
    };
    delBackupDevice = (ip) => {
        let { sysname, backIpToStop } = this.state;
        // if (Utils.isEmpty(selectedRowKeys)) {
        //     message.info("请选择设备！");
        //     return;
        // } else if (selectedRowKeys.length > 1) {
        //     message.info("请选择一条设备！");
        //     return;
        // }
        this.setState({ loading: true, });
        AxiosObj.HTTP_POST("backUp", "delBackupDevice", { ip, sysname }, (result) => {
            message.success(result.msg);
            this.updata();
            this.setState({
                loading: false,
            })
            if (backIpToStop === ip) {
                // 如果没有正在备份的设备，停止定时器
                if (this.state.progressInte) {
                    clearInterval(this.state.progressInte);//停止
                }
            }
        }, (error) => {
            Utils.showMessage("error", "删除失败", error);
            this.setState({ loading: false })
        });
    };
    StartBackup = (selIp) => {
        let { selectedRowKeys, sysname, tableData } = this.state;
        let _this = this;
        if (selIp) {
            selectedRowKeys = [selIp];
        } else {
            if (Utils.isEmpty(selectedRowKeys)) {
                message.info("请选择设备！");
                return;
            }
        }
        let progress = -1;
        const progressInte = setInterval(() => {
            _this.getProgress((pro) => {
                if (pro === 100) {
                    // _this.updata();
                    clearInterval(progressInte);//停止
                } else if (progress != pro) {
                    Utils.getProjectThis(this).updata();
                    progress = pro;
                }
            });
        }, 1000);

        this.setState({ loading: true, progressInte })
        const filtrData = [];
        tableData.map((item) => {
            if (selectedRowKeys.includes(item.ip)) {
                filtrData.push({ ip: item.ip, backup_type: item.backup_type || "" })
            }
        })
        this.setState({ backIpToStop: selectedRowKeys.join("，") })
        AxiosObj.HTTP_POST("backUp", "backupBatch", { ips: filtrData, sysname }, (result) => {
            message.success(result.msg);
            clearInterval(progressInte);//停止
            this.setState({ selectedRowKeys: [] }) // 备份成功去除多选勾选状态
            Utils.getProjectThis(this).updata();
        }, (error) => {
            clearInterval(progressInte);//停止
            Utils.showMessage("error", "备份失败", error);
            this.setState({ loading: false });
        });
        // setTimeout(function () {
        //     _this.updata();
        // }, 1000);

    };
    getProgress(callback) {
        AxiosObj.HTTP_POST("backUp", "backupProgress", {}, (result) => {
            callback(result.progress);
        });
    }
    AutoStartBackup = () => {
        this.setState({ loading: true, });
        AxiosObj.HTTP_POST("backUp", "startAutoBackup", {}, (result) => {
            this.updata();
            message.success(result.msg);
            this.setState({
                loading: false,
            })
        }, (error) => {
            Utils.showMessage("error", "开启自动备份失败", error);
            this.setState({ loading: false })
        });
    };
    AutoStopBackup = () => {
        this.setState({ loading: true, });
        AxiosObj.HTTP_POST("backUp", "closeAutoBackup", {}, (result) => {
            notification.success({ message: '提示', description: result.msg });
            this.updata();
            this.setState({
                loading: false,
            })
        }, (error) => {
            Utils.showMessage("error", "关闭自动备份失败", error);
            this.setState({ loading: false })
        });
    };
    HuiFuAllBackup = () => {
        this.setState({ loading: true, });
        AxiosObj.HTTP_POST("backUp", "resumeAllAutoBackup", {}, (result) => {
            notification.success({ message: '提示', description: result.msg });
            this.updata();
            this.setState({
                loading: false,
            })
        }, (error) => {
            Utils.showMessage("error", "恢复备份失败", error);
            this.setState({ loading: false })
        });
    };
    ActionAutoBackup = (API, _info, selectedRowKeys) => {
        if (Utils.isEmpty(selectedRowKeys)) {
            message.info("请选择设备！");
            return;
        }
        this.setState({ loading: true, });
        AxiosObj.HTTP_POST("backUp", API, { ips: selectedRowKeys }, (result) => {
            notification.success({ message: '提示', description: result.msg });
            this.updata();
            this.setState({ loading: false, })
        }, (error) => {
            Utils.showMessage("error", _info + '失败', error);
            this.setState({ loading: false })
        });
    };
    update_auto_backup_time_all = (backup_cycle, time_type, selectedRowKeys) => {
        let allTime = moment(this.state.allTime).format("YYYY-MM-DD HH:mm:ss");
        this.setState({ loading: true, });
        AxiosObj.HTTP_POST("backUp", "update_auto_backup_time",
            { ips: selectedRowKeys, update_time: allTime, backup_cycle, time_type },
            (result) => {
                notification.success({ message: '提示', description: result.msg });
                this.updata();
                this.setState({ loading: false, })
            }, (error) => {
                Utils.showMessage("error", '批量设置时间失败', error);
                this.setState({ loading: false })
            });
    };
    getNewConfigs() {
        let { selectedRowKeys } = this.state;
        if (Utils.isEmpty(selectedRowKeys)) {
            message.error("请选择要导出的设备");
            return;
        }
        let ZipData = [];
        this.setState({ loading: true, });
        AxiosObj.HTTP_POST("backUp", "batchFindLatestConfig", { ips: selectedRowKeys }, (result) => {
            const backData = result.data;
            backData.map((item) => {
                // const devIP = (item.ip).replace(/\./g, "_");
                // let time = (item.create_time.replace(new RegExp(" ", "g"), "_")).replace(new RegExp(":", "g"), "_");
                // let name = devIP + "_" + time;
                let name = item.file_name || item.hostname;
                ZipData.push({ name, data: item.latest_config })
            })
            const time = Utils.formateDate(new Date());
            Utils.downloadZIP("设备最新配置数据" + time, ZipData);
            notification.success({ message: '提示', description: result.msg });
            this.setState({ loading: false, })
        }, (error) => {
            Utils.showMessage("error", '查询失败', error);
            this.setState({ loading: false })
        });
    }
    updateDevice() {
        let { selectedRowKeys } = this.state;
        if (Utils.isEmpty(selectedRowKeys)) {
            message.error("请选择要同步信息的设备");
            return;
        }
        this.setState({ loading: true, });
        AxiosObj.HTTP_POST("backUp", "updateDevice", { ips: selectedRowKeys }, (result) => {
            notification.success({ message: '提示', description: result.msg });
            this.updata();
        }, (error) => {
            Utils.showMessage("error", '操作失败', error);
            this.setState({ loading: false })
        });
    }
    getstepContent() {
        let { _current, _pageSize, querys, total } = this.state;

        const columns = [...ResUtils.getColumns(_pageSize, _current)];
        const rowSelection = {
            selectedRowKeys: this.state.addselectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.setState({ addselectedRowKeys: selectedRowKeys, });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ addselectedRowKeys: [] });
                    },
                },
            ],
        };
        const steps = [
            {
                title: '选择备份设备',
                content: <div>
                    <FilterForm
                        tagClass="resource"
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updataDev(this.state._pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updataDev(this.state._pageSize, 1, {})
                        }}
                    />
                    <div style={{ zoom: 0.9, overflowY: "auto", overflowX: "hidden", maxHeight: "800px" }}>
                        <Table
                            dataSource={this.state.DeviceData || []}
                            rowSelection={rowSelection}
                            columns={columns}
                            loading={this.state.loading}
                            rowKey={(text, key) => {
                                return JSON.stringify({
                                    resourceName: text.resourceName,
                                    ip: text.resourceParams.deviceIp,
                                    username: text.resourceParams.deviceUser,
                                    password: text.resourceParams.devicePwd,
                                    sys_pwd: text.resourceParams.devicePwd,
                                    deviceType: text.resourceParams.deviceType || "",
                                    deviceId: text.resourceParams.deviceId,
                                    deviceVersion: text.resourceParams.deviceVersion,
                                    deviceFirm: text.resourceParams.deviceFirm,
                                    resource_id: text._id,
                                    key
                                })
                            }}
                            size="small"
                            pagination={{
                                current: _current, pageSize: _pageSize, total: total,
                                showTotal: (total) => { return <span>共 {total} 项</span> },
                                pageSizeOptions: ["20", "50", "100", "500", "1000"],
                                showSizeChanger: true,
                                onShowSizeChange: (current, size) => {
                                    this.setState({ _pageSize: size })
                                    this.updataDev(size, 1, querys)
                                },
                                onChange: (page, pageSize) => {
                                    this.updataDev(pageSize, page, querys);
                                }
                            }}
                        />
                    </div>
                </div>,
            },
            {
                title: '填写备份信息',
                content: <AddForm selectNode={this.state.addselectedRowKeys}
                    onSubmit={(value) => { }}
                    onThis={(_this) => { this.chiild = _this }} />,
            },
        ];
        return steps;
    };
    formSumit() {//add
        this.chiild.props.form.validateFields((err, values) => {
            if (!err) {
                const device = values.device;
                let tempData = [];
                device.map((item) => {
                    let Obj = item;
                    // Obj.backup_time = moment(values.backup_time).format("HH:mm:ss");
                    Obj.start_date = moment(values.start_date).format("YYYY-MM-DD HH:mm:ss");
                    Obj.backup_cycle = values.backup_cycle;
                    Obj.details = values.details;
                    Obj.total_time = values.total_time;
                    Obj.time_type = values.time_type;
                    Obj.backup_type = values.backup_type;
                    Obj.deviceType = item.deviceType;
                    Obj.config_name = values.config_name;
                    tempData.push(Obj);
                });
                const values2 = {
                    add_data: tempData
                }
                // console.log(values2)
                AxiosObj.HTTP_POST("backUp", "addDevice", values2, (result) => {
                    this.setState({ visible_back: false, step_count: 0 });
                    this.updata();
                    if (result.code === "02") {
                        confirm({
                            title: "已存在设备列表",
                            content: <div>{result.repeat_ip_list && result.repeat_ip_list.map((i) => { return <p>{i}</p> })}</div>
                        })
                    } else {
                        Utils.showMessage("success", "新增成功", result.msg);
                    }
                }, (error) => {
                    Utils.showMessage("error", "新增失败", error);
                });

            }
        })
    }
    updataSetting() {
        let { _pageSize1, _current1, querys1 } = this.state;
        const _id = this.state.selectDetail._id;
        this.chiild.props.form.validateFields((err, values) => {
            if (!err) {
                values = {
                    update_data: values
                }
                values.update_data._id = _id;
                values.update_data.os_type = "";
                // 只有 backup_type为 ftp的时候才需要填写 config_name
                values.update_data.config_name = values.update_data.backup_type === 'text' ? "" : values.update_data.config_name;
                values.update_data.backup_time = moment(values.update_data.backup_time).format("HH:mm:ss");
                values.update_data.start_date = moment(values.update_data.start_date).format("YYYY-MM-DD HH:mm:ss");
                values.update_data.sys_pwd = Utils.EncryptPwd(values.update_data.sys_pwd);
                values.update_data.password = Utils.EncryptPwd(values.update_data.password);
                AxiosObj.HTTP_PUT("backUp", "updateSetting", values, () => {
                    message.success("更新成功！");
                    this.updata(_pageSize1, _current1, querys1);
                    this.setState({ visible_setting: false });
                }, (error) => {
                    Utils.showMessage("error", "更新失败", error);
                });

            }
        })
    }
    // QueryDevByIp(ip) {
    //     if (!ip.trim()) { return }
    //     this.setState({ loading: true, });
    //     AxiosObj.HTTP_POST("backUp", "fuzzyQueryDeviceByIp", { ip }, (result) => {
    //         this.setState({
    //             tableData: result.data,
    //             loading: false,
    //         })
    //     }, (error) => {
    //         Utils.showMessage("error", "查询失败", error);
    //         this.setState({ loading: false })
    //     });
    // }
    upBaseConfig(item) {
        this.setState({ loading: true, });
        AxiosObj.HTTP_POST("backUp", "updateBaselineByIp",
            { ip: item.ip, baseline: this.state.baseConfig, sysname: this.state.sysname },
            (result) => {
                Utils.showMessage("success", "更新成功", result.msg);
                this.setState({ loading: false, })
            }, (error) => {
                Utils.showMessage("error", "查询失败", error);
                this.setState({ loading: false })
            });
    }
    handleMenuClick = (e, item) => {
        let _this = this;
        const { _current1, _pageSize1, querys1 } = this.state;
        sessionStorage.setItem("device", JSON.stringify(item));
        switch (e.key) {
            case 'detail':
                let path = {
                    pathname: `/configBackup/BackData`,
                    bodyJson: {
                        pageNum: _current1,      //当前页码数
                        pageSize: _pageSize1,    //每页显示条数
                        querys: querys1,    //查询条件
                        ip: item.ip,
                        total: item.backup_count,
                    }
                };
                this.props.history.push(path);
                break;
            case 'baseHis':
                let path2 = {
                    pathname: `/configBackup/BaseLineData`,
                    bodyJson: {
                        pageNum: _current1,      //当前页码数
                        pageSize: _pageSize1,    //每页显示条数
                        ip: item.ip,
                        querys: querys1,    //查询条件
                        // os_type: item.os_type,
                    }
                };
                this.props.history.push(path2);
                break;
            case 'del':
                confirm({
                    title: '消息提示',
                    content: '你确定删除该条记录吗？',
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        _this.delBackupDevice(item.ip);
                    },
                    onCancel() { },
                });
                break;
            case 'base':

                AxiosObj.HTTP_POST("backUp", "findBaselineByIp", { ip: item.ip }, (result) => {
                    let baseConfig = result.baseline || "";
                    _this.setState({ loading: false, baseConfig });
                    confirm({
                        title: <div>{item.ip}基线配置数据</div>,
                        content: <div style={{ paddingTop: 10 }}>
                            <MonacoEditor onChange={(newValue) => {
                                this.setState({ baseConfig: newValue });
                            }}
                                value={baseConfig} height="600" theme="vs" language="java" /></div>,
                        width: "80%",
                        maskClosable: true,
                        okType: 'danger',
                        icon: <Icon type="file-markdown" />,
                        okText: "更新基线",
                        onOk() {
                            confirm({
                                title: "提示信息",
                                content: "确定更新信息吗?",
                                okButtonProps: { type: "danger" },
                                onOk() {
                                    _this.upBaseConfig(item);
                                }
                            });
                        },
                    });
                }, (error) => {
                    Utils.showMessage("error", "查询失败", error);
                    _this.setState({ loading: false })
                });
                break;
            case 'start': this.StartBackup(item.ip); break;
            case 'stop': this.ActionAutoBackup("pauseAutoBackup", "暂停", [item.ip]); break;
            case 'restart': this.ActionAutoBackup("resumeAutoBackup", "恢复", [item.ip]); break;
            case 'setting': this.getSettingDetailData(item.ip); break;
        }
    }

    getmodalCentent() {
        let { time_type, backup_cycle, allTime } = this.state;
        return <div >
            开启时间:&nbsp;
                <DatePicker showTime style={{ width: 200 }}
                defaultValue={allTime}
                onChange={(a) => { this.setState({ allTime: a }) }}
                placeholder="选择时间" format="YYYY-MM-DD HH:mm:ss" /><p />
            备份周期:&nbsp;
                <InputNumber min={1} style={{ width: 80 }} defaultValue={backup_cycle || 1}
                onChange={(e) => { this.setState({ backup_cycle: e }) }} />
            <Select style={{ width: 80 }} defaultValue={time_type || "day"} onChange={(e) => { this.setState({ time_type: e }) }}>
                <Select.Option value="hour">小时</Select.Option>
                <Select.Option value="day">天</Select.Option>
                <Select.Option value="week">周</Select.Option>
            </Select>备份一次<p />
            已选设备:
                <Table
                columns={[
                    { title: "设备IP", dataIndex: "ip", align: "left", },
                    { title: "设备厂商", align: "left", dataIndex: "deviceFirm", render: (t) => Utils.Translation(t) },
                    { title: "设备类型", align: "left", dataIndex: "deviceType", render: (t) => Utils.Translation(t) },
                    { title: "设备名", align: "left", dataIndex: "deviceId" }
                ]}
                dataSource={this.state.selectedRows}
                scroll={{ y: 500 }}
                size="small"
                pagination={false}
            />
        </div>
    }
    render() {
        let { _pageSize, filter_data, step_count, _current1, _pageSize1, selectDetail, selectedRowKeys, querys1, tabKey, isShowPageSize } = this.state;

        const columns = [
            {
                title: '设备IP',
                align: 'left',
                // width: "12%",
                width: 120,
                dataIndex: 'ip',
                ellipsis: true,
                render: (text, item) => {
                    let sta = "default";
                    let title = "状态未知";
                    if (item.deviceState === "online") {
                        sta = "success"; title = "在线";
                    } else if (item.deviceState === "offline") {
                        sta = "error"; title = "离线";
                    }
                    return <div><Tooltip title={title}><Badge status={sta} /></Tooltip>{text}</div>
                }
            }, {
                title: '自动备份状态',
                dataIndex: 'whether_backup',
                width: 100,
                align: "center",
                render: (text) => {
                    if (text === 0) {
                        return <Tooltip title="自动备份关闭">
                            <Icon type="close-circle" theme="filled" style={{ color: "red" }} />&nbsp;关闭
                        </Tooltip>
                    } else if (text === 1) {
                        return <Tooltip title="自动备份开启">
                            <Icon type="check-circle" style={{ color: "#87d068" }} theme="filled" />&nbsp;开启
                        </Tooltip>
                    } else {
                        return <Tooltip title="自动备份暂停">
                            <Icon type="close-circle" theme="filled" style={{ color: "red" }} />&nbsp;关闭
                        </Tooltip>
                    }
                }
            },
            {
                title: '备份状态',
                dataIndex: 'backup_state',
                width: 100,
                render: (a) => {
                    let ele = "";
                    if (typeof a === "object") {
                        switch (a.code) {
                            case "00": ele = <Tooltip title={a.msg}><Tag color="#87d068">完成</Tag></Tooltip>; break;
                            case "01": ele = <Tooltip title={a.msg}><Tag color="#f50">失败</Tag></Tooltip >; break;
                            case "02": ele = <Tooltip title={a.msg}><Tag color="#108ee9">备份中</Tag></Tooltip >; break;
                            case "03": ele = <Tooltip title={a.msg}><Tag >未开始</Tag></Tooltip >; break;
                        }
                    }
                    return ele
                }
            },
            {
                title: '备份次数',
                dataIndex: 'backup_count',
                // width: "8%",
                width: 80,
                align: "center",
            },
            {
                title: '设备类型',
                dataIndex: 'deviceType',
                width: 100,
                align: "center",
                render: (text) => { return Utils.Translation(text); }
            },
            { title: '备份类型', dataIndex: 'backup_type', width: 80, align: "center", },
            { title: "厂商", dataIndex: "deviceFirm", width: 100, ellipsis: true, render: (text) => { return Utils.Translation(text); } },
            { title: "型号", dataIndex: "deviceVersion", width: 120, ellipsis: true, },
            { title: "设备名", dataIndex: "deviceId", width: 120, ellipsis: true, },
            {
                title: '备份保存时长',
                dataIndex: 'total_time',
                width: 100,
                align: "center",
                render: (text) => {
                    return text + "个月"
                }
            },
            {
                title: '最近备份时间',
                width: 150,
                dataIndex: 'latest_backup_time',
                align: "left",
                ellipsis: true,
            }, {
                title: '下次自动备份时间',
                width: 150,
                dataIndex: 'next_run_time',
                align: "left",
                ellipsis: true,
            }, {
                title: '操作',
                width: 150,
                align: "center",
                dataIndex: "op",
                fixed: "right",
                render: (text, resItem, index) => {
                    return <div key={index}>
                        <Dropdown.Button overlay={() => {
                            return <Menu onClick={(e) => this.handleMenuClick(e, resItem)}>
                                <Menu.Item key="start">
                                    <Icon type="play-circle" />
                                    手动开始备份
                                </Menu.Item>
                                <Menu.Item key="stop">
                                    <Icon type="pause-circle" />
                                    取消自动备份
                                </Menu.Item>
                                <Menu.Item key="restart">
                                    <Icon type="reload" />
                                    恢复自动备份
                                </Menu.Item>
                                <Menu.Item key="setting">
                                    <Icon type="setting" />
                                    备份设置
                                </Menu.Item>
                                <Menu.Item key="base">
                                    <Icon type="carry-out" />
                                    基线配置
                                </Menu.Item>
                                <Menu.Item key="baseHis">
                                    <Icon type="file-exclamation" />
                                    基线记录
                                </Menu.Item>
                                <Menu.Item key="del">
                                    <Icon type="delete" />
                                    删除
                                 </Menu.Item>
                            </Menu>
                        }}
                            type="primary" icon={<Icon type="down" />} size="small"
                            onClick={() => { this.handleMenuClick({ key: "detail" }, resItem) }}
                        ><Icon type="file-text" />备份详情
                        </Dropdown.Button>
                    </div>
                }
            },
        ];
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys: selectedRowKeys, selectedRows });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        const steps = this.getstepContent();
        return (
            <div className={"backupDataClass"}>
                <CustomBreadcrumb arr={["运维管理", "配置备份", "备份设备"]} />

                <Tabs type="card" onChange={(tabKey) => this.setState({ tabKey })}>
                    <TabPane tab="配置备份" key="text">
                        <Alert message="设备备份详情、配置比对、基线对比" type="info" closable />
                        <div className="content" >
                            <div className="FilterForm">
                                <FilterForm
                                    tagClass="resource"
                                    filterSubmit={(querys) => {
                                        let filter_data = {};
                                        Object.keys(querys).map((item) => {
                                            const strList = item.split(".");
                                            console.log(strList)
                                            if (strList.length === 2) {
                                                filter_data[strList[1]] = querys[item];
                                            } else {
                                                filter_data[strList[0]] = querys[item];
                                            }
                                        })
                                        console.log(filter_data)
                                        if (filter_data.depId) {
                                            // const reqUrl = "http://localhost:50034/business/getResourceIdBydepId"
                                            const reqUrl = "http://" + socket.getAddress50034() + "/business/getResourceIdBydepId"
                                            const depId = filter_data.depId
                                            const token = sessionStorage.getItem('token')
                                            AxiosUtils.post(reqUrl, { token, depId }, (result) => {
                                                filter_data["resource_ids"] = result.data
                                                console.log(filter_data)
                                                this.updata(200000000, 1, filter_data);
                                            }, (error) => { Utils.showMessage("error", "查询失败", error); });
                                        } else {
                                            this.updata(200000000, 1, filter_data);
                                        }
                                    }}
                                    resetTab={() => { this.updata(20, 1, {}); }}
                                />
                            </div>
                            <div>
                                <Button type="primary"
                                    icon="play-circle" onClick={() => {
                                        let _this = this;
                                        if (Utils.isEmpty(selectedRowKeys)) {
                                            message.info("请选择设备！");
                                            return;
                                        }
                                        confirm({
                                            title: "提示信息",
                                            maskClosable: true,
                                            content: <div>
                                                <Typography.Text strong>你确定批量备份已选设备吗？</Typography.Text>
                                                <div style={{ maxHeight: 500, overflowY: "auto" }}>
                                                    {selectedRowKeys.map((ip) => {
                                                        return <div>{ip}</div>
                                                    })}
                                                </div>
                                            </div>,
                                            onOk() {
                                                _this.StartBackup()
                                            }
                                        })
                                    }}>
                                    批量备份</Button>&nbsp;
                        {/* <Button type="primary"
                            icon="play-circle" onClick={() => { this.AutoStartBackup() }}>
                            开启自动备份</Button> */}
                                {/* <Button type="danger" style={{ background: "#ed4014", color: "#fff" }}
                            icon="close-circle" onClick={() => { this.AutoStopBackup() }}>
                            暂停全部定时任务</Button>
                        <Button type="primary"
                            icon="reload" onClick={() => { this.HuiFuAllBackup() }}>
                            恢复全部任务</Button> */}
                                {/* <Button type="danger" style={{ background: "#ed4014", color: "#fff" }}
                            icon="pause-circle" onClick={() => { this.ActionAutoBackup("pauseAutoBackup", "批量暂停", selectedRowKeys) }}>
                            批量暂停</Button>
                        <Button type="primary"
                            icon="reload" onClick={() => { this.ActionAutoBackup("resumeAutoBackup", "批量恢复", selectedRowKeys) }}>
                            批量恢复</Button> */}
                                <Button type="primary"
                                    icon="clock-circle" onClick={() => {
                                        if (Utils.isEmpty(selectedRowKeys)) {
                                            message.info("请选择设备！");
                                            return;
                                        }
                                        let _this = this;
                                        confirm({
                                            title: "批量设置时间",
                                            content: _this.getmodalCentent(),
                                            style: { top: 50, },
                                            width: "50%",
                                            okText: '确定',
                                            icon: "clock-circle",
                                            maskClosable: true,
                                            okType: 'danger',
                                            okButtonProps: { icon: "check-circle" },
                                            cancelButtonProps: { icon: "close-circle" },
                                            cancelText: '取消',
                                            onOk() {
                                                setTimeout(() => {
                                                    _this.update_auto_backup_time_all(_this.state.backup_cycle || 1, _this.state.time_type || "day", selectedRowKeys)
                                                }, 100)
                                            },
                                            onCancel() {
                                            },

                                        })
                                    }}>
                                    批量设置</Button>&nbsp;
                        <Button type="primary"
                                    icon="export" onClick={() => { this.getNewConfigs() }}>
                                    导出最新配置</Button>&nbsp;
                        <Button type="primary"
                                    icon="sync" onClick={() => { this.updateDevice() }}>
                                    同步信息</Button>&nbsp;
                            <Button type="primary" icon="form" onClick={() => {
                                    this.props.history.push("/configBackup/BackupRecords")
                                }}
                                >手动备份记录</Button>&nbsp;
                        <Button type="primary"
                                    icon="edit" onClick={() => { this.setState({ visible_back: true }); this.updataDev() }}>
                                    添加备份设备</Button>&nbsp;
                        <Button type="danger" icon="delete" onClick={() => { this.bathDelDev() }}>批量删除</Button>
                            </div>{Utils.getHR()}
                            <DyHeadTable
                                type="configBack"
                                dataSource={this.state.tableData}
                                rowSelection={rowSelection}
                                columns={columns}
                                loading={this.state.loading}
                                rowKey={(item) => { return item.ip }}
                                onRow={record => {
                                    return {
                                        onDoubleClick: () => {
                                            let selectedRowKeys = this.state.selectedRowKeys;
                                            selectedRowKeys.push(record.ip);
                                            this.setState({ selectObj: record, selectedRowKeys })
                                        }, // 点击行触发

                                    };
                                }}
                                size="small"
                                pagination={
                                    {
                                        current: _current1, pageSize: _pageSize1, total: this.state.total1,
                                        showSizeChanger: isShowPageSize,
                                        onChange: (page, pageSize) => {
                                            this.updata(pageSize, page, querys1);
                                        }
                                    }
                                }
                            />
                        </div>

                    </TabPane>
                    {/* <TabPane tab="FTP备份" key="ftp">
                        <Alert message="设备FTP文件备份管理" type="info" closable />
                        <BackupFtp tabKey={tabKey} />
                    </TabPane> */}
                    <TabPane tab="备份命令" key="cmd">
                        <Alert message="备份命令" type="info" closable />
                        <BackupCMD />
                    </TabPane>
                </Tabs>

                <Modal
                    title="添加备份设备"
                    visible={this.state.visible_back}
                    onOk={() => { this.setState({ visible_back: false }) }}
                    onCancel={() => { this.setState({ visible_back: false }) }}
                    afterClose={() => {
                        this.setState({ step_count: 0, addselectedRowKeys: [] })
                    }}
                    footer={null}
                    style={{ top: 10 }}
                    width="90%"
                    destroyOnClose
                >
                    <div>
                        <Steps current={step_count}>
                            {steps.map(item => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                        <div style={{ textAlign: "left", marginTop: 20 }}>{steps[step_count].content}</div>
                        <div style={{ textAlign: "center", marginTop: 2 }}>
                            {step_count < steps.length - 1 && (
                                <Button type="primary" onClick={() => this.next()}>
                                    下一步
                                </Button>
                            )}
                            {step_count === steps.length - 1 && (
                                <Button type="primary" onClick={() => { this.formSumit() }}>
                                    完成
                                </Button>
                            )}
                            {step_count > 0 && (
                                <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                                    上一步
                                </Button>
                            )}
                        </div>
                    </div>
                </Modal>
                <Modal
                    title="备份设置"
                    visible={this.state.visible_setting}
                    onOk={() => { this.updataSetting() }}
                    onCancel={() => { this.setState({ visible_setting: false }) }}
                    okText="修改"
                    style={{ top: 50 }}
                    width="65%"
                    destroyOnClose
                >
                    <InFoForm
                        selectNode={selectDetail}
                        onSubmit={(value) => { }}
                        onThis={(_this) => { this.chiild = _this }} />

                </Modal>
            </div>
        )
    }
}

