import React, { memo } from 'react';
import {
    SubmitButton,
    FormItem,
    Select,
    ResetButton,
    AutoComplete,
    DatePicker
} from "formik-antd"
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik"
import "./index.scss"
const formItem = (param) => {
    const { type, name, label, required, disabled = false, placeholder = "", selectOptions = [], width = 174, formContent } = param;
    let formitem = null;
    switch (type) {
        case "input": formitem = <FormikInput disabled={disabled} name={name} placeholder={placeholder} />; break;
        case "password": formitem = <FormikInput.Password disabled={disabled} name={name} placeholder={placeholder} />; break;
        case "autoComplete": formitem = <AutoComplete disabled={disabled} name={name} placeholder={placeholder}
            dataSource={selectOptions} style={{ width: width }}
            filterOption={(inputValue, option) =>
                option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
        />; break;
        case "select": formitem = <Select disabled={disabled} name={name} placeholder={placeholder} style={{ width: width }} >
            {selectOptions.map((item, key) => (<Select.Option key={key} value={item.value}>{item.name}</Select.Option>))}
        </Select>; break;
        case "textArea": formitem = <FormikInput.TextArea disabled={disabled} name={name} placeholder={placeholder} />; break;
        case "rangePicker": formitem = <DatePicker.RangePicker showTime disabled={disabled} name={name} style={{ width: "100%" }} />; break;
        case "expandForm": formitem = formContent; break;
    }
    return <FormItem
        name={name}
        label={label}
        required={required}
        key={name}
    >
        {formitem}
    </FormItem>
}
const AlertForm = memo(
    (props) => {
        const { onSubmit = () => { }, initialValues = {}, onCancel = () => { }, formList = [], btnName = "查询", clearBtn = "重置" } = props;
        const nameArray = [];
        const labelArray = [];
        const formItemList = formList.map((item, key) => {
            if (item.required) {
                nameArray.push(item.name);
                labelArray.push(item.label);
            };
            return formItem(item, key)
        })
        return <div >
            <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                    console.log(values);
                    onSubmit(values, actions);
                    actions.setSubmitting(false)
                }}
                validate={(values) => {
                    let info = {};
                    nameArray.map((name, key) => {
                        if (!values[name]) {
                            info[name] = labelArray[key] + "不能为空!"
                        }
                    });
                    return info
                }}
            >
                {() => {
                    return <FormikForm
                        layout="inline"
                        className="formikDiv"
                    >
                        {formItemList}
                        <SubmitButton disabled={false} icon="search">{btnName}</SubmitButton>&nbsp;
                        <ResetButton disabled={false} icon="reload" onClick={() => { onCancel() }}>{clearBtn}</ResetButton>
                    </FormikForm>
                }}
            </Formik>
        </div>
    }
);
export default AlertForm;
