import React from 'react'

import { Decoration5, Decoration8 } from '@jiaminghi/data-view-react'

import './TopHeader.scss'

export default () => {
    return (
        <div id="top-header">
            <Decoration8 className="header-left-decoration" />
            <Decoration5 className="header-center-decoration" />
            <Decoration8 className="header-right-decoration" reverse={true} />
            <div className="center-title">紫金网络监控告警数据</div>
        </div>
    )
}
