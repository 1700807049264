import React, { useState, useEffect } from 'react'
import {
    Card, Row, Col, Button, Affix, message
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import { Formik } from "formik"
import {
    SubmitButton,
    ResetButton,
    AddRowButton,
    RemoveRowButton,
    FormItem,
    Select,
    Form,
    Table,
    Input,
    InputNumber
} from "formik-antd"
import http from '@/axios/axios_utils';
import { snmp_module } from '../utils/api';
import { getSelectOptionTime, getFormAutoTime } from '../utils/index';
import { connect } from 'react-redux'
import "./index.scss"
import "animate.css"
const nameArray = ["module_name", "version", "scrape_interval", "timeout",]
const labelArray = ["模块名", "SNMP版本", "采集频率", "采集超时"]
function DetailModule(props) {
    const selectItem = JSON.parse(sessionStorage.getItem("snmpModule") || '{"metric_infos":[]}');
    useEffect(() => {//componentDidMount; 

        return () => { sessionStorage.removeItem("snmpModule") }
    }, []);
    function onSubmitting(values) {
        const flag = nameArray.every((name, key) => {
            if (!values[name]) {
                message.error(labelArray[key] + "不能为空!");
                return false;
            } else {
                return true;
            }
        });
        if (!flag) { return }
        values.metric_info_list = values.metric_infos;
        if (selectItem.module_name) {//编辑
            updateModule(values);
        } else {//新增
            addModule(values);
        }
    }
    function addModule(body) {
        http.post(snmp_module(), body, (r) => { message.success("添加成功!"); props.history.go(-1) })
    }
    function updateModule(body) {
        delete body.metric_infos;
        delete body.auth;
        http.put(snmp_module(), body, (r) => { message.success("修改成功!"); props.history.go(-1) })
    }
    return <div className="monitorModuleDetail">
        <CustomBreadcrumb arr={["监控告警", "数据采集", { title: "SNMP模块管理", to: "/monitorAlert/dataCollection/snmpModule" }, selectItem.module_name ? "SNMP模块详情" : "新增SNMP模块"]} />
        <Card className="infoCard" title="编辑SNMP模块信息">
            <div className="formDiv">

                <Formik
                    initialValues={selectItem}
                    onSubmit={(values, actions) => {
                        console.log(values);
                        onSubmitting(values);
                        actions.setSubmitting(false)
                    }}
                // validate={(values) => {
                //     let info = {};
                //     nameArray.map((name, key) => {
                //         if (!values[name]) {
                //             info[name] = labelArray[key] + "不能为空!"
                //         }
                //     });
                //     console.log(info);
                //     return info
                // }}
                >
                    <Form
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                    >
                        <div className="baseDiv">
                            <div className="title">基本信息</div>
                            <Row className="firstRow">
                                <Col span={8}>
                                    <FormItem
                                        name={"module_name"}
                                        label={"模块名"}
                                        required={true}
                                        hasFeedback
                                    >
                                        <Input name={"module_name"} placeholder={"模块名"} />
                                    </FormItem>
                                </Col>
                                {/* <Col span={8}>
                                    <FormItem
                                        name={"auth.community"}
                                        label={"community"}
                                        hasFeedback
                                    >
                                        <Input name={"auth.community"} placeholder={"community"} />
                                    </FormItem>
                                </Col> */}
                                <Col span={8}>
                                    <FormItem
                                        name={"retries"}
                                        label={"重试次数"}
                                    >
                                        <InputNumber min={0} name={"retries"} style={{ width: "100%" }} />
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem
                                        name={"description"}
                                        label={"描述"}
                                        hasFeedback
                                    >
                                        <Input.TextArea name={"description"} placeholder={"描述"} />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <FormItem
                                        name={"version"}
                                        label={"SNMP版本"}
                                        hasFeedback
                                        required={true}
                                    >
                                        {/* <Select name={"version"}>
                                            <Select.Option value={1}>v1</Select.Option>
                                            <Select.Option value={2}>v2</Select.Option>
                                            <Select.Option value={3}>v3</Select.Option>
                                            <Select.Option value={4}>v4</Select.Option>
                                            <Select.Option value={5}>v5</Select.Option>
                                        </Select> */}
                                        <Input name={"version"} placeholder={"SNMP版本"} />
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    {getFormAutoTime("scrape_interval", "采集频率")}
                                </Col>
                                <Col span={8}>
                                    <FormItem
                                        name={"timeout"}
                                        label={"采集超时"}
                                        required={true}
                                        hasFeedback
                                    >
                                        <Select name={"timeout"}>
                                            {getSelectOptionTime()}
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                        </div>
                        <FormItem
                            name={"metric_infos"}
                            wrapperCol={{ span: 24 }}
                            hasFeedback
                        >
                            <div className="tableDiv">
                                <div className="head">
                                    <strong>指标-(总数:{selectItem.metric_infos && selectItem.metric_infos.length})</strong>
                                    <div><AddRowButton
                                        name="metric_infos"
                                        type="primary"
                                        icon="file-add"
                                        createNewRow={() => ({
                                            key: "key" + new Date().getTime(),
                                            metric_name: "",
                                            oid: "",
                                            description: ""
                                        })}
                                    > 添加参数</AddRowButton></div>
                                </div>
                                <Table
                                    name="metric_infos"
                                    rowKey={(row, index) => (index)}
                                    size="small"
                                    pagination={false}
                                    columns={[
                                        {
                                            width: 50,
                                            title: "序号",
                                            align: "center",
                                            key: "序号",
                                            render: (text, record, i) => (i + 1),
                                        },
                                        {
                                            title: "指标名称",
                                            ellipsis: true,
                                            key: "name",
                                            render: (text, record, i) => (
                                                <Input
                                                    style={{ border: "none" }}
                                                    name={`metric_infos.${i}.metric_name`}
                                                />
                                            ),
                                        },
                                        {
                                            title: "OID",
                                            key: "oid",
                                            ellipsis: true,
                                            render: (text, record, i) => (
                                                <Input
                                                    style={{ border: "none" }}
                                                    name={`metric_infos.${i}.oid`}
                                                />
                                            ),
                                        },
                                        {
                                            title: "描述",
                                            key: "description",
                                            ellipsis: true,
                                            render: (text, record, i) => (
                                                <Input
                                                    style={{ border: "none" }}
                                                    name={`metric_infos.${i}.description`}
                                                />
                                            ),
                                        },
                                        // {
                                        //     title: "自定义指标名称",
                                        //     key: "custom_metric_name",
                                        //     ellipsis: true,
                                        //     render: (text, record, i) => (
                                        //         <Input disabled
                                        //             style={{ border: "none" }}
                                        //             name={`metric_infos.${i}.custom_metric_name`}
                                        //         />
                                        //     ),
                                        // },
                                        {
                                            width: 50,
                                            key: "操作",
                                            align: "center",
                                            render: (text, record, index) => (<RemoveRowButton style={{ border: "none" }} icon="delete" name="metric_infos" index={index} />),
                                        },
                                    ]}
                                />
                            </div>
                        </FormItem>
                        <div className="bootmBtn">
                            <SubmitButton disabled={false} icon="save">保存</SubmitButton>&nbsp;
                            <ResetButton disabled={false} icon="reload" >清空</ResetButton>&nbsp;
                            <Button icon="close" onClick={() => { props.history.go(-1) }} >返回</Button>
                        </div>
                    </Form>
                </Formik>

            </div>
        </Card>

    </div>
}

export default connect()(DetailModule)