import React from 'react'
import './index.scss'
import Socket from '@/socket/index'
import {
    Card, Icon, Row, Col, Tag, Button, Radio, DatePicker,
    Input, InputNumber, Checkbox, Select, Table, Modal
} from "antd";
import FilterForm from "@/components/FilterForm/index";
import Utils from "@/utils/utils";
import "./taskElemt.scss"
const Option = Select.Option;
export default class TaskElemt extends React.Component {
    state = {
        taskList: [],
        resources: [],
        visible_device: false,
        tabloading: false,
        countNum: null,
        dataSouce: [{
            key: new Date().getTime(),
            taskName: "",
            devices: [],
        }],
        Tags: [],
        Groups: [],
        slt_device: null,

    };
    componentWillMount() {
        const { defaultValue } = this.props;
        if (defaultValue) {
            this.setState({ dataSouce: defaultValue })
        }
        this.updataResouce();
    }
    onDataChange(newData) {
        this.props.onDataChange(newData);
    }
    updataTask = () => {
        this.setState({ loading: true, });
        const bodyJson = {
            pageSize: 1000,
            pageNum: 1,
            querys: {}
        };

        Socket.ExecuteAction("QueryTasksPage",
            bodyJson, "/taskQueryTask", (result) => {
                let taskList = result.tasks;
                taskList.map((item, index) => { item.key = index; return item });
                this.setState({
                    taskList,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };
    getTagGroupName() {
        const thisTemp = this;
        Socket.ExecuteAction('QueryTagsGroups', { tagClass: 'resource' }, "/QueryTagsGroups", (result) => {
            thisTemp.setState({
                Tags: result.tags,
                Groups: result.groupNames,
            });
        });
    }
    updataResouce = () => {
        // this.setState({ loading: true, });
        const bodyJson = {
            pageSize: 1000,
            pageNum: 1,
            querys: {}
        };
        Socket.ExecuteAction("QueryResourcesPage",
            bodyJson, "/queryResource", (result) => {
                let resources = result.resources;
                resources.map((item, index) => { item.key = index; return item });
                this.setState({
                    resources,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };

    submitFun = () => {
        const { group, addFlag, } = this.props;
        const { rulesArr, } = this.state;
        let data = {};
        data[group.name + ""] = rulesArr;
        console.log(data);
    }
    taskCompent = () => {
        const { taskList, resources, dataSouce, visible_device, tabloading, Tags, Groups, slt_device } = this.state;
        const columns = [
            {
                title: "资源名称",
                dataIndex: "resouceName",
            }
        ]
        const formList = [
            {
                label: "标签",
                type: 'SELECT',
                field: "tags",
                isTag: true,
                initialValue: [],
                list: Tags.map((item) => {
                    return { id: item, name: item }
                }),
            },
            {
                label: "分组",
                type: 'SELECT',
                isTag: true,
                field: "groupNames",
                initialValue: [],
                list: Groups.map((item) => {
                    return { id: item, name: item }
                }),
            },
            {
                label: "资源名称",
                type: 'INPUT',
                field: "resourceName",
                initialValue: '',
                placeholder: "资源名称"
            },
            {
                label: "厂商",
                type: 'INPUT',
                field: "resourceParams-deviceFirm",
                initialValue: '',
                placeholder: "厂商"
            },
            {
                label: "型号",
                type: 'INPUT',
                field: "resourceParams-deviceVersion",
                initialValue: '',
                placeholder: "型号"
            },
        ]
        const rowDevice = {
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                let slt_device = [];
                slt_device = selectedRows;
                this.setState({ slt_device, });
            },
        };
        const columnsDevice = [
            {
                title: '序号',
                width: "50px",
                align: 'center',
                render: (text, item, index) => {
                    return index + 1;
                }
            }, {
                title: '名称',
                align: 'left',
                dataIndex: 'resourceName'
            }, {
                title: '厂商',
                width: "100px",
                align: 'center',
                dataIndex: 'resourceParams.deviceFirm',
            }, {
                title: '型号',
                width: "100px",
                align: 'center',
                dataIndex: 'resourceParams.deviceVersion',
            }, {
                title: '标签',
                align: 'left',
                dataIndex: 'tags',
                width: "200px",
                render: (text) => {
                    return Utils.hidenTag(text);
                }
            }, {
                title: '分组',
                align: "left",
                width: "200px",
                dataIndex: 'groupNames',
                render: (text) => {
                    return Utils.hidenTag(text);
                }
            },
        ];

        return dataSouce.map((taskItem, taskKey) => {
            return <div className="taskElemt" key={taskKey}>
                <div className="TaskStyle">任务名称：
            <Select style={{ width: 300 }}
                        onDropdownVisibleChange={() => this.updataTask()}
                        showSearch
                        value={taskItem.taskName}
                        onChange={((value) => {
                            taskItem["taskName"] = value;
                            this.onDataChange(dataSouce);
                        })}
                    >{taskList.map((item, key) => { return <Option key={key} value={item.taskName}>{item.taskName}</Option> })}</Select><br />
                    选择资源：<Select style={{ width: 450 }}
                        mode="multiple"
                        value={taskItem.devices}
                        onChange={((value) => {
                            taskItem["devices"] = value;
                            this.onDataChange(dataSouce);
                        })}
                    >
                        {resources.map((item, key) => {
                            const resourceParams = item.resourceParams;
                            return <Option key={key} value={resourceParams.deviceIp}>
                                {resourceParams.deviceIp}
                            </Option>
                        })}</Select>
                    <Button type="primary" onClick={() => {
                        this.getTagGroupName();
                        this.setState({ visible_device: true, countNum: taskKey })
                    }}>筛选资源</Button>
                </div>
                <Button type="primary" onClick={() => {
                    let dataSouce = this.state.dataSouce;
                    // arr.push({ key: new Date().getTime(), });
                    //在指定位置添加元素,第一个参数指定位置,第二个参数指定要删除的元素,如果为0,则追加
                    dataSouce.splice(taskKey + 1, 0, { key: new Date().getTime(), taskName: "", devices: [] });
                    this.setState({ dataSouce });
                    this.onDataChange(dataSouce);
                }} >添加任务</Button>
                <Button type="danger" onClick={() => {
                    let dataSouce = this.state.dataSouce;
                    dataSouce = dataSouce.filter(arritem => arritem.key !== taskItem.key);
                    this.setState({ dataSouce });
                    this.onDataChange(dataSouce);
                }} >删除任务</Button>

                <Modal
                    title="添加资源"
                    visible={visible_device}
                    onOk={(e) => {
                        if (slt_device) {
                            let { dataSouce, countNum, slt_device } = this.state;
                            console.log(slt_device);
                            let deviceIps = slt_device.map((item) => { return item["resourceParams"].deviceIp })
                            dataSouce[countNum]["devices"] = deviceIps;
                            console.log(dataSouce);
                            this.setState({ dataSouce });
                        }
                        this.setState({ visible_device: false, });
                    }}
                    onCancel={(e) => { this.setState({ visible_device: false, }); }}
                    okText="确认"
                    cancelText="取消"
                    style={{ top: 50 }}
                    width={1050}
                    destroyOnClose
                >
                    <FilterForm
                        formList={formList}
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updataResouce(1000, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: null })
                            this.updataResouce(1000, 1, {})
                        }}
                    />
                    <hr />
                    <Table
                        rowSelection={rowDevice}
                        columns={columnsDevice}
                        loading={tabloading}
                        dataSource={resources}
                        size='small'
                        bordered={false}
                        scroll={{ y: 440 }}
                        pagination={false}
                    />
                </Modal>
            </div>;
        })
    }
    render() {
        // let { } = this.state;
        return this.taskCompent();
    }
}
