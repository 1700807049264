import React, { useState, useEffect } from 'react'
// import { Charts, DigitalFlop } from '@jiaminghi/data-view-react'
import Charts from "echarts-for-react"
import './Cards.scss'

import Socket from '@/socket/index'


function randomExtend(minNum, maxNum) {
  if (arguments.length === 1) {
    return parseInt(Math.random() * minNum + 1, 10)
  } else {
    return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
  }
}

export default (props) => {
  const [cards, setData] = useState({});
  // const chartData = props.cardData;

  function getData() {
    return {
      title: {
        text: "环形图",
        textStyle: {
          color: "#e2e2e2",
          fontSize: 22
        },
        x: 'center',
        top: 5
      }, 
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      legend: {
        orient: 'vertical',
        left: 'left',
        textStyle: {
          color: "#e2e2e2",
          lineHeight: 20,
        },
        data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'], // 标签
        // data: chartData,
      },
      series: [ // 鼠标滑过显示的提示框
        {
          name: '访问来源',
          type: 'pie',
          // radius: ['50%', '70%'],
          radius: '55%',
          center: ['52%', '50%'],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          data: [ // 图标数据
            {value: randomExtend(300,400), name: '直接访问'},
            {value: randomExtend(300,400), name: '邮件营销'},
            {value: randomExtend(200,300), name: '联盟广告'},
            {value: randomExtend(100,200), name: '视频广告'},
            {value: randomExtend(550,650), name: '搜索引擎'}
          ]
        }
      ],
    }
  }


  useEffect(() => {
    createData()

    let timer = setInterval(createData, 3000);
    // let timer = setInterval(createData, 10000);
    return () => clearInterval(timer)
  }, [])

  function createData() {
    setData(getData())
  }

  return (
    <div className="ringChart">
      <div className="ringChart-item">
        <Charts className="ring-charts" option={cards} />
      </div>
    </div>
  )
}
