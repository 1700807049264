import React from 'react'
import {
    Card, Table, Input, Button, Icon, message, Modal, Steps, List, Divider, Tooltip,
    Row, Col, Tabs, Tag, Descriptions
} from "antd";
import DyHeadTable from '@/components/DyHeadTable/index';
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import ComparativeData from "./comparativeData"
import AxiosObj from '@/axios/index';
import axios from '@/axios/axios_utils';
import PieChar from '@/components/PieChar/small';
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import TolopogyChart from '../Topology/index';
import { showDetail } from '../compentUtils/index';
import './index.scss';
const { Step } = Steps;
const { TabPane } = Tabs;
const { confirm } = Modal;
const topologyUrl = 'http://' + backup_address + '/ZJNMS_V1/topology/';
const ipUrl = 'http://' + ip_address + '/ip';
const versionName = sys_config.versionName;

class AutoFind extends React.Component {
    state = {
        s_current: 1,      //当前页码数
        s_pageSize: 20,    //每页显示条数
        s_querys: {},    //查询条件
        s_total: 0,

        s_current2: 1,      //当前页码数
        s_pageSize2: 20,    //每页显示条数
        s_querys2: {},    //查询条件
        s_total2: 0,

        s_loading: false,
        tcpVisible: false,
        // osVisible: false,
        s_tableData: [],
        s_tableData2: [],
        selectedRows: [],
        selectedRowKeys: [],
        PieData: [],
        topologyData: [],
        segment: {},
        selectOne: {},
        deviceInfoVisible: false,
        diffInfoData: {},
        resourceData: {},
        ModalFlag: false,
        selectedRows2: [],
        selectedRowKeys2: [],
        tabName: "netDevices",
        tagsFlag: false,
    }
    componentWillMount() {
        const segment = JSON.parse(sessionStorage.getItem("segment"));
        this.setState({ segment, })
        this.updataDev("netDevices", null, null, segment.net_mask);
        this.getTotal(segment.net_mask);
        this.getTopology(segment.net_mask);
        this.isWirelessNet();
    }
    componentWillUnmount() {
        if (sessionStorage.getItem("prevPathName")) {
            sessionStorage.removeItem("prevPathName")
        }
        if (sessionStorage.getItem("prevName")) {
            sessionStorage.removeItem("prevName")
        }
    }
    getTotal(net_mask) {
        AxiosObj.HTTP_POST("topology", "devicesPercentage", { net_mask }, (result) => {
            console.log(result);
            const PieData = result.data.map((item) => {
                // return { item: item["device_type"], count: item["device_count"], percent: parseFloat(item["device_percentage"]) };
                return { name: item["device_type"], value: item["device_count"], percent: parseFloat(item["device_percentage"]) };
            })
            this.setState({ PieData })
        }, (err) => { this.showError(err) })
    }
    isWirelessNet() {
        const tags = JSON.parse(sessionStorage.getItem("segment")).tags || '';
        console.log(tags)
        if (Array.isArray(tags)) {
            if (tags.indexOf('无线网段') > -1) {
                this.setState({ tagsFlag: true })
            }
        }
    }
    showError(error) {
        Utils.showMessage("error", "操作失败", error);
        const { dispatch } = this.props;
        dispatch(isLoading(false));
    }
    updataDev = (apiName, pageSize, pageNum, net_mask) => {
        const { s_pageSize, s_current } = this.state;
        const { dispatch } = this.props;
        let bodyJson = {
            page_size: pageSize || s_pageSize,
            page_no: pageNum || s_current,
            net_mask
        };
        dispatch(isLoading(true));
        AxiosObj.HTTP_POST("topology", apiName, bodyJson, (result) => {
            if (apiName === 'netDevices') {
                this.setState({
                    s_tableData: result.data,
                    s_total: result.count,
                    s_current: bodyJson.page_no,
                    s_pageSize: bodyJson.page_size,
                });
            } else if (apiName === 'terminalDevices') {
                this.setState({
                    s_tableData2: result.data,
                    s_total2: result.count,
                    s_current2: bodyJson.page_no,
                    s_pageSize2: bodyJson.page_size,
                });
            }
            dispatch(isLoading(false));
        }, (err) => { this.showError(err) })
    };
    saveResources = () => {
        const { selectedRows, s_pageSize, s_current, segment } = this.state;
        const { dispatch } = this.props;
        const _this = this;
        if (selectedRows.length === 0) { message.info("请选择设备!"); return };
        let param = {};
        Modal.confirm({
            title: "入库操作",
            content: <div><p>填写设备用户名密码</p>
                <p><label>用户名:</label><Input placeholder="用户名" onChange={(e) => { param.username = e.target.value }} /></p>
                <p><label>密码:</label><Input.Password placeholder="密码" onChange={(e) => { param.password = e.target.value }} /></p>
            </div>,
            okText: "入库",
            cancelText: "取消",
            onOk() {
                dispatch(isLoading(true));
                AxiosObj.HTTP_POST("topology", "saveTopologyResources",
                    {
                        resources: selectedRows, username: param.username, token: sessionStorage.getItem('token'),
                        password: param.password, area: segment.area
                        // password: Utils.EncryptPwd(param.password), area: segment.area
                    }, (result) => {
                        message.success("入库成功!");
                        _this.updataDev("netDevices", s_pageSize, s_current, segment.net_mask);
                    }, (err) => { _this.showError(err) })
            }
        })
    };
    getTopology = (net_mask) => {
        const { dispatch } = this.props;
        dispatch(isLoading(true));
        AxiosObj.HTTP_POST("topology", "topologyInfoByNet", { net_mask }, (result) => {
            // console.log(JSON.stringify(result.data.topo_data));
            this.setState({ topologyData: result.data.topo_data, });
            dispatch(isLoading(false));
        }, (err) => { this.showError(err) })
    };
    handleMenuClick(menu, resItem) {
        const { _current, _pageSize, querys } = this.state
        let _this = this;
        // let bodyJson = {
        //     pageNum: _current,      //当前页码数
        //     pageSize: _pageSize,    //每页显示条数
        //     querys,    //查询条件 

        // }
        console.log(menu)
        switch (menu.key) {
            case "detail":
                showDetail(resItem);
                break;
            case "topology": this.props.history.push("/top"); break;
        }
    }
    saveData(topo_data, net_mask, show) {
        AxiosObj.HTTP_POST("topology", "saveTopologyMapInfo", { net_mask, topo_data }, (result) => {
            message.success("保存成功!");
        })
    }
    openTcpModal(b) {
        this.setState({ selectOne: b, tcpVisible: true })
    }
    openOsModal(b) {
        this.setState({ selectOne: b, osVisible: true })
    }
    getTcpModal(objData, name) {
        let temp = null;
        if (objData) {
            const keys = Object.keys(objData);
            temp = keys.map((k, i) => {
                const item = objData[k];
                return <tr key={i + k}><td>{k}</td><td>{typeof item === "object" ? this.getTcpModal(objData[k], "2") : item}</td></tr>
            })
        }
        return <table className={"tcpmodaltab" + name}><tbody>{temp}</tbody></table>;
    }
    getTcpTabModal(objData) {
        const data = Object.keys(objData).map((key) => {
            let newone = objData[key];
            newone.port = key;
            return newone
        });
        return <Table
            columns={[
                { title: "port", dataIndex: "port" },
                ...Object.keys(data[0] || {}).filter((f) => f != "port").map((t) => {
                    return { title: t, dataIndex: t, }
                })
            ]}
            scroll={{ y: 400 }}
            size="small"
            rowKey={r => r.port}
            dataSource={data}
            pagination={false}
        />
    }
    getOsModal(osInfo) {
        if (osInfo) {
            return <Table
                columns={[{ title: "相似操作系统", dataIndex: "os", align: "left" }, { title: "相似度", width: 50, dataIndex: "accuracy", render: (a) => (a + "%") }]}
                size="small"
                scroll={{ y: 400 }}
                rowKey={r => r.os}
                dataSource={osInfo || []}
                pagination={false}
            />
        } else {
            return null;
        }
    }
    lookOverInfo(item) {
        // console.log(item)
        this.setState({ deviceInfoVisible: true, ModalFlag: false })
        let bodyJson = {
            interface_ips: item.interface_ips || [],
            firm: item.firm || "",
            type: item.type || "",
            model: item.model || "",
            hostname: item.hostname || "",
        };
        axios.post(topologyUrl + "diffPlaceWithRs", bodyJson, (res) => {
            if (res.code === "00") {
                this.setState({
                    diffInfoData: item, // 网络设备
                    resourceData: res.data, // 系统设备
                    ModalFlag: true
                })
            }
        })
    }
    addMacMonitor() {
        let { selectedRowKeys, selectedRowKeys2, tabName } = this.state;
        let bodyArr = [];
        if (tabName === 'netDevices') { // 已知设备
            selectedRowKeys.map(keyItem => {
                return bodyArr.push({
                    macs: JSON.parse(keyItem).macs,
                    ips: JSON.parse(keyItem).interface_ips,
                    comment: JSON.parse(keyItem).description,
                    name: JSON.parse(keyItem).hostname,
                })
            })
        } else if (tabName === 'terminalDevices') { // 未知设备
            selectedRowKeys2.map(keyItem => {
                return bodyArr.push({
                    mac: JSON.parse(keyItem).mac,
                    ip: JSON.parse(keyItem).ip,
                })
            })
        }
        axios.post(ipUrl + "/macMonitor/", bodyArr, (res) => {
            if (res.code === "00") {
                message.success("添加成功");
                this.setState({ selectedRowKeys: [], selectedRows: [], selectedRowKeys2: [], selectedRows2: [] });
            } else {
                message.error(res.msg);
            }
        })
    }
    render() {
        let { s_tableData, s_current, s_pageSize, s_querys, s_total, s_loading, segment, PieData, topologyData, tcpVisible, osVisible, tagsFlag,
            selectOne, deviceInfoVisible, diffInfoData, resourceData, ModalFlag, s_current2, s_pageSize2, s_total2, s_tableData2 } = this.state;
            const prevPathName = sessionStorage.getItem("prevPathName") || ''
            let columns = [{
            title: '设备名称',
            dataIndex: "hostname",
            align: "left",
            ellipsis: true,
            width: 150,
            render: (text, item, index) => {
                return <Button type="link" icon="file-text" onClick={() => this.handleMenuClick({ key: "detail" }, item)}>{text}</Button>
            }
        }, {
            title: '关联IP',
            dataIndex: "interface_ips",
            ellipsis: true,
            width: 150,
            render: (text, item, index) => {
                return typeof text === "object" ? text.join(" / ") : text;
            }
        }, {
            title: 'mac地址',
            dataIndex: "macs",
            ellipsis: true,
            width: 120,
            render: (text, item, index) => {
                return typeof text === "object" ? text.join(" / ") : text;
            }
        }, {
            title: '设备型号',
            dataIndex: "model",
            width: 150,
            ellipsis: true,
        }, {
            title: '厂商',
            dataIndex: "firm",
            width: 80,
            ellipsis: true,
        }, {
            title: '设备类型',
            dataIndex: "type",
            width: 80,
            ellipsis: true,
        }, {
            title: '状态',
            dataIndex: "status",
            width: 80,
            render: (text, item, index) => {
                let color = ""
                if (text === "已变更") {
                    color = "orange"
                } else if (text === "未入库") {
                    color = "geekblue"
                }
                return <Tag color={color}>{text}</Tag>
            }
        }, {
            title: '对比系统设备',
            dataIndex: "is_different",
            width: 100,
            align: "left",
            render: (text, item, index) => {
                if (item.status === "已入库") {
                    if (text) {
                        return (<div>
                            <Button type="primary" style={{ marginRight: 5 }} onClick={() => this.lookOverInfo(item)}> <Icon type="edit" />查看 &nbsp;</Button>
                            <Tooltip title='设备信息存在差异'><Tag color="#f50"><Icon type="info-circle" style={{ cursor: "pointer" }} /></Tag></Tooltip>
                        </div>)
                    } else {
                        return <Button type="primary" onClick={() => this.lookOverInfo(item)}> <Icon type="edit" />查看 &nbsp;</Button>
                    }
                }
            }
        }, {
            title: '设备发现时间',
            width: 200,
            dataIndex: "create_time",
            ellipsis: true,
        }];
        if(prevPathName === '/resourceAutoFind') {
            columns.splice(columns.length-2, 1)
        }
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys || [],
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys, selectedRows });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [], selectedRows: [] });
                    },
                },
            ],
        }
        const rowSelectionUnknown = {
            selectedRowKeys: this.state.selectedRowKeys2 || [],
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys2: selectedRowKeys, selectedRows2: selectedRows });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys2: [], selectedRows2: [] });
                    },
                },
            ],
        }
        return <div className="autoFindDeviceDiv">
            <CustomBreadcrumb
                arr={[ prevPathName === '/dumbTerminal/dumbAutoFind' ? "哑终端管理" : (prevPathName === '/resourceAutoFind' ? "资源管理" : "运维管理"), 
                    {
                        title: sessionStorage.getItem("prevName") ? sessionStorage.getItem("prevName") : "网络自动发现",
                        to: prevPathName || "/autoFind"
                    }, 
                "设备信息"]}
            />
            <Tabs type="card" tabBarExtraContent={
                <Button type="primary" icon="rollback" onClick={() => {
                    const _this = this;
                    if (this.flowChange) {
                        Modal.confirm({
                            title: "消息提示",
                            content: "是否保存拓扑图数据？",
                            okText: "保存",
                            cancelText: "不保存",
                            onOk() {
                                console.log(_this.flowThis.state.graph);
                                console.log(_this.flowThis.state);
                                console.log(_this.flowThis.state.graph.save());
                                _this.saveData(_this.flowThis.state.graph.save(), _this.state.segment.net_mask)
                                _this.props.history.go(-1);
                            },
                            onCancel() {
                                _this.props.history.go(-1);
                            }
                        })
                    } else {
                        _this.props.history.go(-1);
                        if (sessionStorage.getItem("prevPathName")) {
                            sessionStorage.removeItem("prevPathName")
                        }
                        if (sessionStorage.getItem("prevName")) {
                            sessionStorage.removeItem("prevName")
                        }
                    }

                }}>返回</Button>
            }>
                <TabPane tab={prevPathName === '/dumbTerminal/dumbAutoFind' ? "哑终端发现设备" : 
                    (prevPathName === '/resourceAutoFind' ? "资源发现设备" : "网段发现设备")} key="device">
                    <div className="tableCard" >
                        <Row className="findCard" gutter={0}>
                            <Col span={12}>
                                <div className="searchCrad" title="基本信息">
                                    <Descriptions bordered size="small" column={1}>
                                        <Descriptions.Item label={"发现网段"}>{segment.net_mask}</Descriptions.Item>
                                        <Descriptions.Item label={"所属区域"}>{segment.area}</Descriptions.Item>
                                        <Descriptions.Item label={"记录时间"}>{segment.create_time}</Descriptions.Item>
                                        { prevPathName !== '/resourceAutoFind' ? 
                                        <Descriptions.Item label={"上次扫描设备数量"}>{segment.ips_length}</Descriptions.Item>: '' }
                                        <Descriptions.Item label={"总耗时"}>{segment.topology_consume_time}</Descriptions.Item>
                                    </Descriptions>
                                </div>
                            </Col>
                            <Col span={12}><div title="设备统计" >
                                <div className="pieDiv"><PieChar data={PieData} /></div>
                            </div></Col>
                        </Row>
                        <div className="deviceRow">
                            <Tabs tabBarExtraContent={
                                <Row>
                                    {tagsFlag ? <Button type="primary" icon="plus-circle" onClick={() => { this.addMacMonitor() }}>添加至mac监控表</Button> : ''}
                                    <Button style={{ marginLeft: 8 }} type="primary" icon="audit" onClick={() => { this.saveResources() }}>入库</Button>
                                </Row>
                            }
                                onChange={(key) => {
                                    this.setState({ selectedRowKeys: [], selectedRows: [], tabName: key });
                                    if (key === "info") {
                                    } else {
                                        this.updataDev(key, null, 1, segment.net_mask);
                                    }
                                    console.log(key)
                                }}>
                                <TabPane tab="设备信息" key="netDevices">
                                    <DyHeadTable
                                        type={prevPathName === '/resourceAutoFind' ? "resourceFindDevice" : "findDevice"}
                                        columns={columns}
                                        rowSelection={rowSelection}
                                        dataSource={s_tableData}
                                        size="small"
                                        loading={s_loading}
                                        rowKey={(r) => JSON.stringify(r)}
                                        pagination={{
                                            current: s_current, pageSize: s_pageSize, total: s_total,
                                            onChange: (page, pageSize) => { this.updataDev("netDevices", pageSize, page, segment.net_mask); }
                                        }}
                                    />
                                </TabPane>
                                {prevPathName !== '/resourceAutoFind' ? <TabPane tab="未知设备" key="terminalDevices">
                                    <Table
                                        // rowKey={r => r._id}
                                        rowKey={(r) => JSON.stringify(r)}
                                        columns={[{
                                            title: '序号',
                                            width: 50,
                                            dataIndex: "key",
                                            render: (text, item, index) => {
                                                return <span>{index + 1 + (s_current - 1) * s_pageSize}</span>
                                            }
                                        }, {
                                            title: 'IP',
                                            dataIndex: "ip",
                                            ellipsis: true,
                                        }, {
                                            title: 'mac地址',
                                            dataIndex: "mac",
                                            ellipsis: true,
                                        }, {
                                            title: '网段',
                                            dataIndex: "net_mask",
                                            ellipsis: true,
                                        }, {
                                            title: '记录时间',
                                            dataIndex: "create_time",
                                            ellipsis: true,
                                        }, {
                                            title: '操作',
                                            render: (a, b, c) => {
                                                const { os_info, tcp_info } = b;
                                                return <div>
                                                    {/* {tcp_info && <Button type="link" icon="file-text" size="small" onClick={() => this.openTcpModal(b)}>端口信息</Button>}
                                                    &nbsp;&nbsp;&nbsp;
                                                   {os_info && <Button type="link" icon="file-text" size="small" onClick={() => this.openOsModal(b)}>操作系统</Button>} */}
                                                    {(tcp_info || os_info) && <Button type="link" icon="file-text" size="small" onClick={() => this.openTcpModal(b)}>设备信息</Button>}
                                                </div>
                                            }
                                        },]}
                                        dataSource={s_tableData2}
                                        size="small"
                                        rowSelection={tagsFlag ? rowSelectionUnknown : null}
                                        loading={s_loading}
                                        pagination={{
                                            current: s_current2, pageSize: s_pageSize2, total: s_total2,
                                            showTotal: (total) => { return <span>共 {total} 项</span> },
                                            pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                                            showSizeChanger: true,
                                            onShowSizeChange: (current, size) => {
                                                this.updataDev("terminalDevices", size, 1, segment.net_mask);
                                            },
                                            onChange: (page, pageSize) => { this.updataDev("terminalDevices", pageSize, page, segment.net_mask); }
                                        }}
                                    />
                                </TabPane> : ''}
                                {/* <TabPane tab="设备拓扑图" key="topology">
                                    <TolopogyChart data={topologyData} save={(data, show) => { this.saveData(data, segment.net_mask, show) }}
                                        onChange={(v) => { this.flowChange = v; }}
                                        refThis={(s, graph) => {
                                            this.flowThis = s;
                                            this.graph = graph;
                                        }} />
                                </TabPane> */}
                            </Tabs>
                        </div>
                    </div><p />
                </TabPane>
                {versionName === "pingan" || versionName === "pinganNanJing" || versionName === "pinganTerminal" ? "" : sessionStorage.getItem("prevPathName") ? "" : <TabPane tab="设备拓扑图" key="topology">
                    <TolopogyChart data={topologyData} save={(data, show) => { this.saveData(data, segment.net_mask, show) }}
                        onChange={(v) => { this.flowChange = v; }}
                        refThis={(s, graph) => {
                            this.flowThis = s;
                            this.graph = graph;
                        }} />
                </TabPane>}
            </Tabs>
            <Modal
                title="设备相关信息详情"
                visible={tcpVisible}
                width="60%"
                style={{ top: 10 }}
                onCancel={() => this.setState({ tcpVisible: false })}
                onOk={() => this.setState({ tcpVisible: false })}
            >
                <Divider orientation="left">端口信息</Divider>
                <div className="portinfoDiv">
                    {/* <table className="tcpmodaltab"><tbody><tr><th>端口号</th><th>端口信息</th></tr></tbody></table>
                    {this.getTcpModal(selectOne.tcp_info, "")} */}
                    {this.getTcpTabModal(selectOne.tcp_info || {})}
                </div>
                <Divider orientation="left">相似操纵系统</Divider>
                <div>{this.getOsModal(selectOne.os_info)}</div>
            </Modal>
            <ComparativeData
                deviceInfoVisible={deviceInfoVisible}
                resourceData={resourceData}
                diffInfoData={diffInfoData}
                ModalFlag={ModalFlag}
                onCancel={() => this.setState({ deviceInfoVisible: false })}
                updataDev={() => this.updataDev("netDevices", null, null, JSON.parse(sessionStorage.getItem("segment")).net_mask)}
            />
        </div>
    }
}
export default connect()(AutoFind);