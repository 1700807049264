import React from 'react'
import {
    Card, Table, Input, Button, Icon, message, Modal, Dropdown, Form, Row, Col, Collapse, Tag
} from "antd";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import DyHeadTable from '@/components/DyHeadTable/index';
import FilterForm from '@/components/FilterForm/index';
import axios from '@/axios/axios_utils';
import moment from 'moment';
const suspiciousUrl = "http://" + backup_address + "/ZJNMS_V1/";

export default class SuspiciousAccess extends React.Component {
    state = {
        current: 1,      //当前页码数
        pageSize: 20,    //每页显示条数
        querys: {},    //查询条件
        total: 0,
        loading: false,
        tableData: [],
        // defaultTime: {},
    }
    componentWillMount() {
        let bodyJson = this.props.location.bodyJson || {};
        const { current, pageSize } = this.state;
        // let defaultTime = {
        //     start_time: this.getTimeDistance()[0].format("YYYY-MM-DD HH:mm:ss"),
        //     end_time: this.getTimeDistance()[1].format("YYYY-MM-DD HH:mm:ss"),
        // }
        // console.log(this.props, defaultTime)
        // this.setState({ defaultTime })
        // this.updataDev(current, pageSize, defaultTime);
        this.updataDev();
    }
    updataDev = (page, _pageSize, query) => {
        this.setState({ loading: true, });
        const { current, pageSize, querys } = this.state;
        let bodyQuery = query || querys;
        let bodyJson = {
            page_no: page || current,
            page_size: _pageSize || pageSize,
            ...bodyQuery,
            // start_time: bodyQuery.start_time,
            // end_time: bodyQuery.end_time,
        };
        axios.get(suspiciousUrl + "hub/check_hub", bodyJson, (res) => {
            if(res.code === "00") {
                res.data.map((item) => {
                    item.suspiciousPort = item.port.filter(portItem => portItem.is_suspicious === true)
                    // return item.port.filter(portItem => portItem.is_suspicious === true)
                })
                this.setState({
                    total: res.count,
                    tableData: res.data,
                    current: page || 1,
                    loading: false,
                    querys: bodyQuery
                })
            }
        })
    };
    // 默认查询当天的数据
    getTimeDistance() {
        const now = new Date();
        const oneDay = 1000 * 60 * 60 * 24;
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        let today = [moment(now), moment(now.getTime() + (oneDay - 1000))];
        return today
    }

    onSubmitButton(querys) {
        const { pageSize } = this.state;
        let bodyJson = {};
        let begin = querys.update_time ? new Date(querys.update_time[0]._d).format("yyyy-MM-dd hh:mm:ss") : '';
        let end = querys.update_time ? new Date(querys.update_time[1]._d).format("yyyy-MM-dd hh:mm:ss") : '';
        if (begin && end) {
            bodyJson.start_time = begin;
            bodyJson.end_time = end;
        }
        this.updataDev(1, pageSize, bodyJson);
    }

    jumpDetail(item) {
        let { current, pageSize, querys } = this.state;
        let bodyJson = {
            page: current,
            pageSize: pageSize,
            querys: querys,
            port: item,
        }
        let path = {
            pathname: "/suspiciousAccess/suspiciousDetails",
            bodyJson,
        };
        // console.log("跳转端口详情",this.props.history)
        this.props.history.push(path);
    }

    render() {
        let { tableData, current, pageSize, querys, total, loading } = this.state;
        
        const formList = [
            {
                label: '时间',
                type: 'datePicker',
                field: 'update_time',
                // initialValue: this.getTimeDistance(),
                initialValue: '',
                placeholder: '请选择时间范围'
            },
        ]

        const columns = [
            {
                title: '设备IP',
                ellipsis: true,
                width: 100,
                dataIndex: 'resource_ip',
                align: 'center',
            },
            {
                title: '设备名称',
                width: 130,
                ellipsis: true,
                dataIndex: 'resource_params.deviceId',
                align: 'center',
            },
            //resource_params 设备参数
            {
                title: '厂商',
                width: 80,
                ellipsis: true,
                dataIndex: 'resource_params.deviceFirm', 
                align: 'center',
            },
            {
                title: 'OS版本',
                width: 250,
                ellipsis: true,
                dataIndex: 'resource_params.deviceOsVersion',
                align: 'center',
            },
            {
                title: '设备状态',
                width: 100,
                ellipsis: true,
                dataIndex: 'resource_params.deviceState',
                align: 'center',
            },
            {
                title: '设备区域',
                width: 180,
                ellipsis: true,
                dataIndex: 'resource_params.deviceSite',
                align: 'center',
            },
            {
                title: '设备类型',
                width: 80,
                ellipsis: true,
                width: 100,
                dataIndex: 'resource_params.deviceType',
                align: 'center',
            },
            {
                title: '设备型号',
                width: 130,
                ellipsis: true,
                dataIndex: 'resource_params.deviceVersion',
                align: 'center',
            },
            {
                title: 'SSH端口号',
                width: 90,
                ellipsis: true,
                dataIndex: 'resource_params.sshport',
                align: 'center',
            },
            {
                title: '更新时间',
                width: 160,
                ellipsis: true,
                dataIndex: 'update_time',
                align: 'center',
            },
            {
                title: '可疑接入端口',
                width: 200,
                ellipsis: true,
                dataIndex: 'suspiciousPort',
                align: 'center',
                render: (text) => {
                    return <div>
                        {text ? text.map((item, index) => {
                            return <Tag color="#f50" key={index}>{item.port_name}</Tag>
                        }) : ""}
                    </div>
                }
            },
            {
                title: '标签',
                dataIndex: 'resource_tags',
                width: 120,
                align: 'center',
                render: (text) => {
                    return <div>{Utils.hidenTag(text)}</div>;
                }
            },
            {
                title: '端口信息',
                width: 170,
                ellipsis: true,
                dataIndex: 'port',
                align: 'center',
                fixed: 'right',
                render: (text) => {
                    return (
                        <div>
                            <Button type="primary" size="small" icon="edit" onClick={() => { this.jumpDetail(text) }}>查看端口信息</Button>
                        </div>
                    )
                }
            },
        ]

        return <div>
            <CustomBreadcrumb arr={["运维管理", { title: "MAC地址管理", to: "/Ledger" }, "查看可疑接入"]} />
            <div className="searchDiv">
                <Collapse defaultActiveKey={"key"}>
                    <Collapse.Panel header="筛选条件" key={"key"}>
                        <FilterForm formList={formList}
                            filterSubmit={(querys) => {
                                this.onSubmitButton(querys)
                            }}
                            resetTab={() => {
                                // this.updataDev(1, pageSize, defaultTime)
                                this.updataDev(1, pageSize, {})
                            }}
                        />
                    </Collapse.Panel>
                </Collapse>
            </div>
            <Card title={<div style={{ fontWeight: 600 }}>可疑接入设备</div>} style={{ marginTop: 8 }} 
                extra={
                    <Row>
                        <Button type='primary' icon='rollback' onClick={() => {
                            let bodyJson = {
                                page_no: current || 1,      //当前页码数
                                page_size: pageSize || 20,    //每页显示条数
                                query: querys || {},    //查询条件
                            }
                            let path = {
                                pathname: `/Ledger`,
                                bodyJson,
                            };
                            this.props.history.push(path);
                        }}>返回mac地址管理</Button>
                    </Row>
                }
            > 
                <DyHeadTable
                    type="suspiciousAccess"
                    dataSource={tableData || []}
                    columns={columns}
                    size='small'
                    loading={loading}
                    rowKey={(text) => { return text._id }}
                    // scroll={{ x: scrollX }}
                    // rowSelection={rowSelection}
                    pagination={{
                        current: current,
                        pageSize: pageSize,
                        total: total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        onChange: (page, pageSize) => {
                            this.setState({ current: page, pageSize: pageSize });
                            this.updataDev(page, pageSize, querys);
                        }
                    }}
                />
            </Card>
        </div>
    }
}