const formItemLayout = {
    labelCol: {
        xs: {span: 15},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 16},
        sm: {span: 14},
    },
};
export let formList = [
    {
        label: '专线名称',
        type: 'INPUT',
        field: 'netEquName',
        initialValue: '',
        placeholder: '专线名称'
    },
    {
        label: '专线号',
        type: 'INPUT',
        field: 'lineNo',
        initialValue: '',
        placeholder: '专线号'
    },
    {
        label: '端口',
        type: 'INPUT',
        field: 'port',
        initialValue: '',
        placeholder: '端口'
    },
    {
        label: 'IP地址',
        type: 'INPUT',
        field: 'ipAddr',
        initialValue: '',
        placeholder: 'IP地址'
    },
    {
        label: 'ACL',
        type: 'INPUT',
        field: 'ACL',
        initialValue: '',
        placeholder: 'ACL'
    },
    {
        label: '部门',
        type: 'INPUT',
        field: 'department',
        initialValue: '',
        placeholder: '部门'
    },
]
