import React from 'react'
import {
    Card, Table, Button, Icon, message, notification, Row, Col, Tag, Modal, Spin, List, Typography
} from "antd";
import socket from "@/socket/index";
import axios from 'axios'
import './index.scss'
const downloadList_Url = "http://" + socket.getAddress50034() + "/business/";

export default class ChangeModal extends React.Component {
    state = {
        downloadVis: false,
        listData: [],
    };
    componentWillReceiveProps(props) {
        this.setState({
            downloadVis: props.downloadVis,
            listData: props.listData
        })
    }
    downloadFile(fileName) {
        axios({
            method: "GET",
            url: downloadList_Url + "downloadFile",
            params: { fileName: fileName },
            responseType: 'blob'
        }).then((result) => {
            const link = document.createElement('a');
            let blob = new Blob([result.data], { type: "text/plain;charset=utf-8" }); // 转化为 blob 对象  导出.xlsx文件
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = fileName; // 文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // console.log(link.href, result);
        })
    }
    clearResultInfo() {
        this.setState({
            downloadVis: false,
            listData: []
        })
        // console.log(this.state, this.props)
    }
    render() {
        let { downloadVis, listData } = this.state;
        return (
            <Modal
                title="下载配置文件"
                visible={downloadVis}
                onCancel={() => this.clearResultInfo()}
                onOk={() => { this.clearResultInfo() }}
                afterClose={() => {this.props.onCancel();}}
                destroyOnClose
            >
                 <List
                    bordered
                    dataSource={listData}
                    style={{ maxHeight: "400px", overflow: "auto" }}
                    renderItem={item => (
                        <List.Item style={{ cursor: "pointer", color: "#1890ff" }} onClick={() => { this.downloadFile(item) }}>
                            {item}
                        </List.Item>
                    )}
                />
            </Modal>
        )
    }
}
