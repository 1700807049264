import React from 'react'
import { Card, Table, Button, Upload, message, Icon, Input, Badge, Progress, Anchor } from "antd";
import YAML from 'json2yaml'
import YAMLJS from 'yamljs'
import fs from 'fs'
import Util from '../../../utils/utils'
import AxiosObj from '../../../axios/index'
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import EditSnmpConfig from './editSnmpConfig'
import utils from '../../../utils/utils';
const { Link } = Anchor;

// 监控告警后端服务器地址 monitor_address 配置地址：public/flow/config.js
const backUp_Url = monitor_address;
// Ajax 请求的数据格式
const options = {
    url: '/snmpexporter/deviceList',
    data: {
        params: {},
        isShowLoading: true,
        status: ''
    },

}

const { TextArea } = Input;
//上传mibs库文件
const props = {
    name: 'config',
    action: backUp_Url + '/snmpexporter/uploadMib',
    headers: {
        authorization: 'authorization-text',
        // 'Content-Type':'multipart/form-data;charse=UTF-8',
    },
    onChange(info) {
        if (info.file.status !== 'uploading') {
            // console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            if (info.file.response.code === 0) {
                message.success(`${info.file.name} file uploaded successfully`);
            } else {
                message.error(info.file.response.msg);
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};

const yamlData = () => {
    try {
        const doc = YAMLJS.safeLoad(fs.readFileSync('/home/ixti/example.yml', 'utf8'));
        // console.log(doc);
    } catch (e) {
        // console.log(e);
    }
}

export default class SnmpMonitor extends React.Component {

    state = {
        //filteredInfo: null,
        //sortedInfo: null,
        loading: false,
        selectedRowKeys: [],
        selectedRows: [],
        _pageSize: 10,
        _current: 1,
        dataSource: [
            // {
            //     index: '1',
            //     name: 'cisco',
            //     ip: '10.32.79.XX',
            //     module: 'cisco',
            //     num: 4,
            // },
            // {
            //     index: '2',
            //     name: 'linux',
            //     ip: "10.32.79.XX" ,
            //     module: 'if_module',
            //     num: 3,
            // },
        ],
        columns: [
            // {
            //     title: '序号',
            //     align: 'center',
            //     dataIndex: 'index',
            //     render: (text) => { return <span>{text}</span> }
            // },
            // {
            //     title: '状态',
            //     align: 'center',
            //     dataIndex: 'index',
            //     render: (text) => { return <Badge status="success" />}
            // },
            {
                title: '名称',
                align: 'center',
                dataIndex: 'name',
                width: '15%'
            }, {
                title: 'ip',
                align: 'center',
                dataIndex: 'ip',
                width: '15%'

            }, {
                title: 'module',
                align: 'center',
                dataIndex: 'module',
                width: '15%'
            }, {
                title: '告警条数',
                align: 'center',
                dataIndex: 'num',
                width: '10%',
                render: (text, item) => {
                    // return <Anchor.Link to={{pathname:"#/monitoringAlarm/alarm",state:{ip:'192.1.1.1'}}}>'hello'</Anchor.Link>
                    return <Button type="link" onClick={() => {
                        // console.log('item',item);
                        let ip = item.ip;
                        this.props.history.push({ pathname: '/monitoringAlarm/alarm', state: { ip: ip } });
                    }
                    }>{text}</Button>
                }
            }, {
                title: 'CPU',
                align: 'center',
                dataIndex: 'cpu',
                width: '15%',
                render: (text) => { return <Progress percent={text} strokeColor="#52c41a" size="small" /> }
            },
            {
                title: '内存',
                align: 'center',
                dataIndex: 'memory',
                width: '15%',
                render: (text) => { return <Progress percent={text} strokeColor="#52c41a" size="small" /> }
            },
            {
                title: '详细信息',
                width: '15%',
                align: 'center',
                render: (text, item) => {
                    return <div>
                        <Button type="primary" onClick={() => {
                            // console.log('text',text,'this.state.json_generator',this.state.json_generator)
                            let ip = text['ip'];
                            let module = text['module']
                            let params = this.state.json_generator.modules[module]["walk"];
                            //console.log(params)
                            this.props.history.push({ pathname: '/monitoringAlarm/snmpMonitor/detail', state: { ip: ip, module: module, proms: params } }); /*  要路由修改*/
                        }}>
                            <Icon type="form" />详情
                        </Button>
                    </div>
                }
            },
            // {
            //     title: '删除设备',
            //     width: '170px',
            //     align: 'center',
            //     render: (text, item) => {
            //         return <div>
            //             <Button type="primary" onClick={() => {
            //                 this.props.history.push(`/monitoringAlarm//snmpMonitor/detail`); /*  要路由修改*/
            //             }}>
            //                 <Icon type="form" />删除设备
            //             </Button>
            //         </div>
            //     }
            // }
        ],
        json_generator: '',
        generator: ''
        // "modules:\n" +
        // "  cisco:   #基于SNMP通过OID获取思科交换机的CPU、内存使用率\n" +
        // "    walk:\n" +
        // "     - busyPer #过去5秒中CPU使用率 \n" +
        // "     - avgBusy1 # 过去1分钟CPU使用率\n" +
        // "     - avgBusy5  \n" +
        // "     - freeMem  \n",
    };


    componentWillMount() {
        this.getConfigYml2();
        // this.getDeviceList();
        this.interval = setInterval(() => this.updateAllCPUMemStatus(), 5000);
        //this.testPromise(); // 测试使用Promise来等待异步返回值
    }
    componentWillUnmount() {
        // 清除定时器
        clearInterval(this.interval);
    }
    //测试Promise 函数使用，获取异步请求的结果
    testPromise = () => {
        let p1 = new Promise((success, error) => {
            AxiosObj.MA_HTTP_GET(backUp_Url + "/snmpexporter/deviceList", success, error)
        });
        let p2 = new Promise((success, error) => {
            AxiosObj.MA_HTTP_GET(backUp_Url + "/snmpexporter/ruleList", success, error)
        });


        Promise.all([p1, p2]).then((success) => {
            // console.log("success",success)
        }, (error) => {
            // console.log('error',error)
        });

    }
    //保存配置文件
    saveConfig = () => {
        let { generator } = this.state;
        Util.download("generator.yml", generator);
    }

    //获取监控设备列表数据
    getDeviceList = (jsonData) => {
        let { json_generator } = this.state;
        if (jsonData) {
            //console.log(jsonData)
        } else {
            jsonData = json_generator;
        }
        AxiosObj.MA_HTTP_GET(backUp_Url + "/snmpexporter/deviceList", (response) => {
            console.log(response)
            //console.log(response.data)
            let data = response.data;
            for (let i in data) {
                data[i].index = parseInt(i) + 1;
                data[i].key = parseInt(i) + 1;
                this.getCPUMemStatus(data[i].ip, data[i].module, jsonData, (response) => {
                    // console.log('cpu',response);
                    data[i]['cpu'] = parseInt(response)
                    if (i == data.length - 1) {
                        this.setState({

                            dataSource: data,

                        })
                    }
                }, (memory) => {
                    // console.log('memory',memory);
                    data[i]['memory'] = parseInt(memory)
                    if (i == data.length - 1) {
                        this.setState({
                            dataSource: data,
                        })
                    }
                });
            }
            // this.setState({
            //
            //     dataSource:data,
            //
            // })
        }, (error) => {
            message.error(error)
        })

    }

    //查询设备CPU内存使用率  通过添加的描述来找到对应CPU 内存字段（如果人为写了多个，暂时取第一个，待优化或者删除）
    getCPUMemStatus = (ip, module, jsonData, cpuPercent, memPercent) => {
        // let {jsonData} = this.state;
        let CPU, CPUUsage, MemoryPoolUsed, MemoryPoolFree, MemoryUsage;
        let end = Date.parse(new Date()) / 1000;
        let start = end - 10;//从最近10秒内取一值做显示数据
        let tmpWalk = jsonData.modules[module].walk;
        let params = { // 这里的参数设置为URL参数（根据URL携带参数）
            ip: ip,
            job: module,
            start: start,
            end: end
        }

        for (let i in tmpWalk) {
            if (tmpWalk[i].describe == 'CPU使用率') {
                CPU = tmpWalk[i].index;
            } else if (tmpWalk[i].describe == '空闲内存') {
                MemoryPoolFree = tmpWalk[i].index;
            } else if (tmpWalk[i].describe == '分配内存') {
                MemoryPoolUsed = tmpWalk[i].index;
            }
        }
        if (CPU) {
            params['prom'] = CPU;
            AxiosObj.MA_HTTP_GET2(backUp_Url + '/snmpexporter/promQuery', params, (response) => {
                // console.log('CPU',response)
                //CPUUsage = response.data[0][1];   //后端返回只有一个数组时
                CPUUsage = response.data[0]["values"][0][1];  //根据返回取值
                cpuPercent(CPUUsage);
            }, (error) => {
                // error.message()
                //message.error(error)
                cpuPercent(0);
            })
        } else {
            CPUUsage = 0
            cpuPercent(CPUUsage)
        }
        if (MemoryPoolUsed && MemoryPoolFree) {
            let tmpParams1 = { ...params };
            tmpParams1['prom'] = MemoryPoolUsed;
            let p1 = new Promise((success, error) => {
                AxiosObj.MA_HTTP_GET2(backUp_Url + '/snmpexporter/promQuery', tmpParams1, success, error)
            });
            let tmpParams2 = { ...params };
            // console.log("params",params,"tmpParams2",tmpParams2)
            tmpParams2['prom'] = MemoryPoolFree;
            let p2 = new Promise((success, error) => {
                AxiosObj.MA_HTTP_GET2(backUp_Url + '/snmpexporter/promQuery', tmpParams2, success, error)
            });
            Promise.all([p1, p2]).then((success) => {
                // console.log("success",success)
                let tmpMemUsedList = success[0].data;
                let tmpMemFreeList = success[1].data;
                let totalMemUsed = 0, totalMemFree = 0;
                //循环获取所有空闲内存和使用内存
                for (let i = 0; i < tmpMemFreeList.length; i++) {
                    // totalMemUsed = tmpMemUsedList[i][1]+totalMemUsed;  //数据接口修改
                    // totalMemFree = tmpMemFreeList[i][1]+totalMemFree;
                    totalMemUsed = tmpMemUsedList[i].values[0][1] + totalMemUsed;
                    totalMemFree = tmpMemFreeList[i].values[0][1] + totalMemFree;
                }
                let totalMem = totalMemUsed + totalMemFree;
                if (totalMem > 0) {
                    MemoryUsage = parseInt(parseInt(totalMemUsed) / parseInt(totalMem));
                    memPercent(MemoryUsage)
                } else {
                    memPercent(0);
                }
            }, (error) => {
                // console.log('error',error)
                memPercent(0);
            });


            // AxiosObj.MA_HTTP_GET2(backUp_Url+'/snmpexporter/promQuery',params,(response)=>{
            //     console.log(response)
            // },(error)=>{})


        } else {
            memPercent(0);
        }
    }

    //动态更新CPU状态
    updateAllCPUMemStatus() {
        let { dataSource, json_generator } = this.state;
        if (dataSource.length == 0 || json_generator == '') {
            return
        }
        for (let i = 0; i < dataSource.length; i++) {
            let tmpData = dataSource[i];
            this.getCPUMemStatus(tmpData.ip, tmpData.module, json_generator, (response) => {
                // console.log('cpu',response);
                dataSource[i]['cpu'] = parseInt(response)
                if (i == dataSource.length - 1) {
                    this.setState({

                        dataSource: dataSource,

                    })
                }
            }, (mem) => {
                // console.log(mem)
            });
        }
    }

    //删除监控设备
    deleteDevice = () => {
        let { selectedRows } = this.state;
        let device = {};
        let ids = [];
        for (let i in selectedRows) {
            ids[i] = selectedRows[i].id;
        }
        device['ids'] = ids;
        if (ids.length == 0) {
            message.error('请先选择要删除的设备！')
            return
        }
        console.log(device);
        AxiosObj.HTTP_DELETE('alarm', '/snmpexporter/deleteDevice', device, (data) => {
            message.success('success');
            this.getDeviceList();
        }, (error) => {
            message.error(error);
        })
    }

    //获取generator.yml配置文件
    getConfigYml = () => {
        AxiosObj.MA_HTTP_GET(backUp_Url + "/snmpexporter/downloadConfig", (response) => {
            let data = YAML.stringify(response.data, null, 4);
            //console.log(data)
            this.setState({
                generator: data,
                json_generator: response.data
            })
        }, (error) => {
            message.error(error)
        })
    }

    //获取generator.yml配置文件，带注释版本
    getConfigYml2 = () => {
        AxiosObj.MA_HTTP_GET(backUp_Url + "/snmpexporter/downloadConfigAndComment", (response) => {
            //将Unicode 解码成中文
            // let data1 =unescape(response.data.replace(/\\u/g, '%u'))
            //  let data = JSON.parse(data1)
            //将数据全部格式化成json
            //  let jsonData = YAMLJS.parse(data);
            //  console.log("jsonData",jsonData);
            //fs.readFileSync('./generator.yml', 'utf8')
            //let testData = YAMLJS.load(fs.readFileSync('./generator.yml', 'utf8'))
            //console.log("testdata", testData)
            //let data =YAML.stringify(YAMLJS.parse(data1),null,4);
            //console.log("jsonData",jsonData)
            let jsonData = YAMLJS.parse(JSON.stringify(response.data));

            let data = YAML.stringify(jsonData, null, 4);
            // console.log("data",data,'jsonData',jsonData)
            this.setState({
                generator: data,
                json_generator: jsonData
            })
            this.getDeviceList(jsonData);
            //this.getCPUMemStatus('192.1.1.1','Cisco',jsonData)
        }, (error) => {
            message.error(error)
        })
    }


    render() {
        let { dataSource, columns, _current, _pageSize, loading } = this.state;
        const _this = this;
        //上传配置文件
        const props2 = {
            name: 'config',
            action: backUp_Url + '/snmpexporter/uploadConfig',
            headers: {
                authorization: 'authorization-text',
                // 'Content-Type':'multipart/form-data;charse=UTF-8',
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    // console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    if (info.file.response.code === 0) {
                        message.success(`${info.file.name} file uploaded successfully`);
                        setTimeout(_this.getConfigYml(), 1000);
                    } else {
                        message.error(info.file.response.msg);
                    }


                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys:`, selectedRowKeys, 'selectedRows: ', selectedRows);
                this.setState({
                    selectedRowKeys: selectedRowKeys,
                    selectedRows: selectedRows
                });
            },
            selections: [
                {
                    key: 'cancel',
                    text: '清空选择',
                    onSelect: changableRowKeys => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        return (
            <div>
                <CustomBreadcrumb arr={["监控告警", "数据采集", "监控设备"]} />
                <Card title={<span style={{ fontWeight: 600 }}>当前监控设备</span>}
                    style={{ marginTop: 8, marginBottom: 20 }}
                    extra={<span><Button type="primary" icon="file-add"
                        onClick={() => { this.props.history.push('/monitoringAlarm/snmpMonitor/addDevice'); }}>{/* 记的修改路由 */}
                                         添加设备
                                 </Button>&nbsp;
                            <Button type="danger" icon="delete"
                            onClick={() => { this.deleteDevice(); }}>{/* 记的修改路由 */}
                                      删除设备
                                 </Button>
                    </span>}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        size="small"
                        rowSelection={rowSelection}
                        loading={loading}
                        onChange={() => console.log('待添加')}
                    />
                </Card>
                {/*<EditSnmpConfig/>*/}
                {/*<Card title={<span style={{ fontWeight: 600 }}>监控指标</span>}*/}
                {/*style={{ marginTop: 8 }}*/}
                {/*extra={true ?*/}
                {/*<span>*/}
                {/*<Button style={{float:"left"}} type="primary" onClick={()=>this.props.history.push('/monitoringAlarm/snmpMonitor/editSnmpConfig')}>*/}
                {/*修改指标*/}
                {/*</Button>*/}
                {/*<Upload {...props} style={{float:"left"} }  >*/}
                {/*<Button  type="primary">*/}
                {/*上传mibs库文件*/}
                {/*</Button>*/}
                {/*</Upload>*/}
                {/*</span> : ''}>*/}

                {/*/!*<TextArea value={this.state.generator}*!/*/}
                {/*// onChange={(value)=>{*/}
                {/*//     this.setState({generator:value.target.value})*/}
                {/*//     console.log(value)*/}
                {/*// }}*/}
                {/*/!*rows={20} />*!/*/}

                {/*<div style={{marginTop:20,width:440}  }>*/}
                {/*/!*<Button type="primary" icon="edit" style={{ float:"left", marginLeft: 8 }}*!/*/}
                {/*/!*onClick={() => { this.props.history.push('/monitoringAlarm/snmpMonitor/addSnmpModule'); }}>/!* 记的修改路由 *!/*!/*/}
                {/*/!*编辑监控指标*!/*/}
                {/*/!*</Button>*!/*/}
                {/*<Button type="primary" style={{float:"left"} }   onClick={this.saveConfig} >下载配置</Button>*/}
                {/*<Upload  {...props2} style={{float:"left"} }  >*/}
                {/*<Button type="primary">*/}
                {/*上传配置*/}
                {/*</Button>*/}
                {/*</Upload>*/}

                {/*</div>*/}
                {/*</Card>*/}


            </div>
        )
    }
}

