import React from 'react'
import './index.scss'
import {
    Card, Table, Input, Typography, Popconfirm, Button, Icon, Tabs, Row, Tooltip,
    notification, Col, message, Tag, Modal, Progress, List, Select
} from "antd";
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import CustomBreadcrumb from "@/components/CustomBreadcrumb/index";
import FilterForm from "@/components/FilterForm/index";
import EditStrategy from "./editStrategy";
import MangeSrcModal from "./MangeSrcModal";
import $ from "jquery";
const { Title } = Typography;
const Option = Select.Option;

export default class Strategy extends React.Component {
    state = {
        tableData: [],
        deviceData: [],
        fromList: [],
        selFrom: "",
        selTo: "",
        toList: [],
        localDevice: {},
        localResouce: {},
        strategyDetil: { title: "", data: "" },
        visible_info: false,
        visible_anal: false,
        // visible_src: false,
        loading: false,
        staloading: false,
        _pageSize: 10,
        fwTotal: 0,
        _current: 1,
        fromZone: "",
        toZone: "",
        taskId: "",
    };
    componentWillMount() {
        let taskId = this.props.match.params.taskId;
        if (taskId) {
            this.updataPageData(taskId);
        }
    }

    updataPageData = (taskId) => {
        this.setState({ loading: true });
        // let body = { tags: ["FW"], taskId };
        let body = { tags: ["FW"] };
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = 10;
        bodyJson.pageNum = 1;
        bodyJson.querys = body;
        Socket.ExecuteAction("QueryResourcesPage",
            bodyJson, "/QueryTaskResult", (result) => {
                let deviceData = result.resources;
                deviceData.map((item, index) => {
                    item.key = index;
                });
                this.setState({
                    deviceData,
                    taskId: taskId,
                    loading: false,
                })
            }, () => {
                notification.error({
                    message: '查询失败',
                    description: result.error,
                });
            });
    };
    QueryStrategyInfo = (resouce, fromZone, toZone, _Size, _Num) => {
        this.setState({ loading: true, localDevice: resouce.resourceParams, localResouce: resouce });
        let body = {
            pageSize: _Size || 10,
            pageNum: _Num || 1,
            deviceId: resouce._id,
            querys: {
                fromZone,
                toZone,
            }
            // taskId: this.state.taskId
        };
        Socket.ExecuteAction("QueryPolicyInfo",
            body, "/QueryTaskResult", (result) => {
                let data = result.data;
                let StrategyInfo = data.list;
                let fromList = data.fromZone;
                let toList = data.toZone;
                let fwTotal = data.total;
                this.setState({
                    StrategyInfo, fwTotal, fromList, toList,
                    loading: false,
                    _current: _Num,
                    visible_info: true,
                })
            }, () => {
                notification.error({
                    message: '查询失败',
                    description: result.error,
                });
            });
    };

    OpenDetail = (Obj) => {
        let strategyDetil = Obj;
        this.setState({ strategyDetil, visible_anal: true });
    };
    // getToList(val) {
    //     Socket.ExecuteAction("QueryTagsGroups",
    //         { tagClass: "to" }, "/QueryTaskResult", (result) => {
    //             let toList = result.tags;
    //             // let toList = result.data;
    //             this.setState({
    //                 toList, selFrom: val,
    //             })
    //         }, () => {
    //             notification.error({
    //                 message: '查询失败',
    //                 description: result.error,
    //             });
    //         });
    // }
    // getFromList() {
    //     const _this = this;
    //     Socket.ExecuteAction("QueryTagsGroups",
    //         { tagClass: "from" }, "/QueryTaskResult", (result) => {
    //             // let fromList = result.data;
    //             let fromList = result.tags;
    //             _this.setState({ fromList, })
    //         }, () => {
    //             notification.error({
    //                 message: '查询失败',
    //                 description: result.error,
    //             });
    //         });
    // }
    ActionStrategy(APIName, param) {
        Socket.ExecuteAction(APIName,
            param, "/QueryTaskResult", (result) => {
                message.success("成功")
            }, () => {
                notification.error({
                    message: '失败',
                    description: result.error,
                });
            });
    }
    moveUp(index) {
        if (index === 0) { return }
        let { StrategyInfo } = this.state;
        let temp = null;
        let tempData = StrategyInfo.map((item, key) => { return item; })
        temp = tempData[index - 1];
        tempData[index - 1] = tempData[index];
        tempData[index] = temp;
        // console.log(tempData);
        this.setState({ StrategyInfo: tempData })
    }
    moveDown(index) {
        let { StrategyInfo } = this.state;
        if (index === (StrategyInfo.length - 1)) { return }
        let temp = null;
        let tempData = StrategyInfo.map((item, key) => { return item; })
        temp = tempData[index];
        tempData[index] = tempData[index + 1];
        tempData[index + 1] = temp;
        // console.log(tempData);
        this.setState({ StrategyInfo: tempData })
    }
    render() {
        let { visible_info, visible_acl, deviceData, StrategyInfo, visible_anal, fromList, toList,
            columnsAnal, strategyDetil, analysisData, localDevice, AclDeatil, localResouce, selTo, selFrom,
            _pageSize, fwTotal, _current, loading } = this.state;
        const columnsDev = [
            {
                title: 'IP',
                align: 'center',
                dataIndex: 'resourceParams.deviceIp',
            }, {
                title: '类型',
                align: 'center',
                dataIndex: 'resourceParams.deviceType',
            }, {
                title: '厂商',
                align: 'center',
                dataIndex: 'resourceParams.deviceFirm',
            }, {
                title: '登录状态',
                align: 'center',
                dataIndex: 'resourceParams.deviceState'
            }, {
                title: '占用状态',
                align: 'center',
                dataIndex: 'resourceParams.deviceOperaState'
            }, {
                title: '网点信息',
                align: 'center',
                dataIndex: 'resourceParams.deviceSite'
            }, {
                title: '操作',
                align: 'center',
                render: (text, item, index) => {
                    return <div key={index}>
                        <Button type="primary" icon="diff"
                            onClick={() => { this.QueryStrategyInfo(item, "", "", 10, 1) }}>策略
                </Button>
                        <Button type="primary" icon="unordered-list"
                            onClick={() => { this.QueryStrategyInfo(item, "", "", 10, 1) }}>管理
                </Button>
                    </div>
                }
            }
        ];
        const columnsStrategy = [
            {
                title: '序号',
                align: 'center',
                width: "8%",
                render: (text, item, index) => {
                    return index + 1;
                }
            }, {
                title: '策略名称',
                align: 'center',
                width: "15%",
                dataIndex: "policyName"
            }, {
                title: '源头地址范围',
                align: 'center',
                width: "15%",
                dataIndex: 'sourceAddress',
                render: (text, item, index) => {
                    return <Select defaultValue={text[0]}>
                        {text.map((option, key) => {
                            return <Select.Option key={key} value={option}>{option}</Select.Option>
                        })}
                    </Select>
                }
            }, {
                title: '目的地址范围',
                align: 'center',
                width: "15%",
                dataIndex: 'destinationAddress',
                render: (text, item, index) => {
                    return <Select defaultValue={text[0]}>
                        {text.map((option, key) => {
                            return <Select.Option key={key} value={option}>{option}</Select.Option>
                        })}
                    </Select>
                }
            }, {
                title: '应用',
                align: 'center',
                dataIndex: "application",
                // render: (text, item, index) => {
                //     return Utils.hidenTag(text);
                // }
                render: (text, item, index) => {
                    return <Select defaultValue={text[0]}>
                        {text.map((option, key) => {
                            return <Select.Option key={key} value={option}>
                                {option}
                            </Select.Option>
                        })}
                    </Select>
                }
            }, {
                title: '处理',
                align: 'center',
                dataIndex: 'action',
            }, {
                title: '操作',
                align: 'center',
                width: "30%",
                render: (text, item, index) => {
                    let style = {
                        margin: { marginLeft: 5 },
                        warn: {
                            marginLeft: 5,
                            // color: '#f50', border: '1px solid #f50' 
                        }
                    };
                    return <div key={index}><Button type="primary" icon="edit" onClick={() => {
                        this.OpenDetail({ title: '编辑策略', data: item })
                    }}>编辑</Button>
                        <Button type="primary" style={style.margin} icon="arrow-up" onClick={() => {
                            this.moveUp(index);
                        }}>上移</Button>
                        <Button type="primary" style={style.warn} icon="arrow-down" onClick={() => {
                            this.moveDown(index);
                        }}>下移</Button>
                        <Popconfirm
                            title="你确定删除当前行吗?"
                            onConfirm={() => this.ActionStrategy("DelStrategy", { _id: item._id })}
                        // onCancel={cancel}
                        // okText="Yes"
                        // cancelText="No"
                        >
                            <Button type="danger" style={style.margin} ghost icon="delete" >删除</Button>
                        </Popconfirm>
                    </div>
                }
            }
        ];
        let filterFormList = [
            {
                label: "from",
                type: 'SELECT',
                field: "fromZone",
                initialValue: [],
                list: fromList.map((item) => {
                    return { id: item, name: item }
                }),
            },
            {
                label: "to",
                type: 'SELECT',
                field: "toZone",
                initialValue: [],
                list: toList.map((item) => {
                    return { id: item, name: item }
                }),
            },
        ]
        return (
            <div className="home-body">
                <CustomBreadcrumb arr={["任务管理", { title: "概览", to: '/overView' }, "安全策略"]} />
                <Card title={<span style={{ fontWeight: 600 }}>安全策略</span>}
                    extra={<Button type="primary" icon={'rollback'}
                        onClick={() => { this.props.history.goBack(); }}>返回任务概览</Button>}
                    className="Card-task">
                    <Table
                        columns={columnsDev}
                        dataSource={deviceData}
                        loading={loading}
                        size={"small"}
                    />

                </Card>
                <Modal
                    title="策略信息"
                    visible={visible_info}
                    onCancel={(e) => { this.setState({ visible_info: false, }); }}
                    footer={<div><Button type="primary" icon="plus-circle" onClick={() => {
                        this.OpenDetail({ title: '新增策略', data: {} })
                    }}>新增</Button>
                        <Button type="primary" onClick={() => { this.ActionStrategy("StrategySend", {}); }}>下发策略</Button></div>}
                    width="95%"
                    style={{ position: 'fixed', top: 25, left: 35, }}
                >
                    <div className="monitorDiv2" >
                        <span className="titleFw2">
                            <span>IP:{localDevice.deviceIp}</span>
                            <span>厂商:{localDevice.deviceFirm}</span>
                            <span>类型:{localDevice.deviceType}</span>
                            <span>登录状态:{localDevice.deviceloginState}</span>
                            <span>占用状态:{localDevice.deviceOperaState}</span>
                            <span>网点信息:{localDevice.deviceSite}</span>
                            {/* <Button type="primary" style={{ position: "absolute", right: 25 }} icon="plus-circle" onClick={() => {
                                this.OpenDetail({ title: '新增策略', data: {}, aclObj: {} })
                            }}>新增</Button> */}
                            <p />
                            <hr />
                            {/* <FilterForm
                                formList={filterFormList}
                                filterSubmit={(querys) => {
                                    // this.setState({ querys })
                                    this.QueryStrategyInfo(localDevice, querys.fromZone, querys.toZone)
                                }}
                                resetTab={() => {
                                    this.QueryStrategyInfo(localDevice)
                                }}
                            /> */}
                            <span>from: </span>
                            <Select
                                // onDropdownVisibleChange={() => this.getFromList()}
                                onSelect={(val) => { this.setState({ selFrom: val }); }}>

                                {fromList.map((item, key) => {
                                    return <Option value={item} key={key}>{item}</Option>
                                })}
                            </Select>
                            <span>to: </span>
                            <Select
                                onSelect={(val) => { this.setState({ selTo: val }); }}>
                                {toList.map((item, key) => {
                                    return <Option value={item} key={key}>{item}</Option>
                                })}
                            </Select>
                            <Button type="primary" style={{ marginLeft: 8 }} icon="search" onClick={() => {
                                this.QueryStrategyInfo(localResouce, selFrom, selTo, 10, 1);
                            }}>搜索</Button>
                            {/* <Button type="primary" style={{ marginLeft: 8 }} icon="edit" onClick={() => {
                                this.setState({ visible_src: true })
                            }}>编辑地址范围</Button> */}
                        </span>
                        <hr />
                        <Table
                            columns={columnsStrategy}
                            dataSource={StrategyInfo}
                            size={"small"}
                            loading={loading}
                            scroll={{ y: 370 }}
                            pagination={{
                                current: _current, pageSize: _pageSize, total: fwTotal,
                                onChange: (page, pageSize) => {
                                    this.QueryStrategyInfo(localResouce, selFrom, selTo, pageSize, page);
                                }
                            }}
                        />
                    </div>
                </Modal>
                <EditStrategy
                    title={strategyDetil.title}
                    visible={visible_anal}
                    onCancel={(e) => { this.setState({ visible_anal: false, }); }}
                    footer={null}
                    width="70%"
                    initialValue={strategyDetil.data}
                    // fromAddrList={fromList}
                    // toList={toList}
                    destroyOnClose
                // style={{ position: 'fixed', top: 25, left: 35, }}
                />
                {/* <MangeSrcModal
                    title={"编辑地址范围"}
                    visible={visible_src}
                    onCancel={(e) => { this.setState({ visible_src: false, }); }}
                    footer={null}
                    width="70%"
                    destroyOnClose
                // style={{ position: 'fixed', top: 25, left: 35, }}
                /> */}
            </div>
        );
    }
}
const style = {
    bgDiv: { textAlign: 'center', border: '1px solid #e2e2e2', width: '80%', margin: 'auto' },
    title: {
        textAlign: 'center', border: '1px solid #e2e2e2',
        background: 'linear-gradient(#4481eb,#04befe)', color: '#FFFFFF'
    }
};