import React from 'react'
import './index.scss'
import { Card, Table, Button, message } from "antd";
import socket from "@/socket/index";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import SelectForm from '@/components/SelectForm/index';
class BasePage extends React.Component {
    state = {
        _current: 1,      //当前页码数
        _pageSize: 10,    //每页显示条数
        querys: null,    //查询条件
        total: 0,
        loading: false,
        visible_conn: false,
    };
    componentWillMount() {
        // FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn'))||[];
        // this.updata();
    }

    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        const { QueryInterface } = this.props;
        let bodyJson = {
            pageSize: pageSize || _pageSize,
            pageNum: pageNum || _current,
            querys: querys || {}
        };
        socket.ExecuteAction(QueryInterface.action, bodyJson, QueryInterface.menuUrl, (result) => {
            let QueryData = Utils.DataAddItem(result.data);
            this.setState({
                tableData: QueryData,
                _current: pageNum || 1,
                total: result.total,
                loading: false,
            })
        });
    };
    delete = (id) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        const { DelInterface } = this.props;
        socket.ExecuteAction(DelInterface.action, { _id: id }, DelInterface.menuUrl,
            (resp) => {
                this.setState({ visible: false, loading: false, });
                this.updata(_pageSize, _current);
                message.success('删除成功');
            }, (error) => {
                message.success(error);
            })

    };

    cancel = () => {
        this.setState({ visible: false });
        message.error('删除取消');
    };
    render() {
        let { _current, _pageSize } = this.state;
        const { columns, condition, conditionData, BreadcrumbArr, tableTitle, cardButton, tableData
        } = this.props;
        return (
            <div>
                <CustomBreadcrumb arr={BreadcrumbArr} />
                <Card>
                    {condition && conditionData ?
                        <SelectForm conditionData={conditionData} condition={condition}
                            tagClass="resource"
                            filterSubmit={(query) => {
                                this.updata(this.state._pageSize, 1, query)
                            }}
                            resetTab={() => { this.updata(this.state._pageSize, 1, {}) }}
                        /> : ''}

                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>{tableTitle}</span>}
                    style={{ marginTop: 8 }}
                    extra={
                        <Button type="primary" style={{ marginLeft: 8 }}
                            icon={cardButton.icon} onClick={() => {
                                if (cardButton.action === 'back')
                                    history.go(-1);
                            }}>{cardButton.name}
                        </Button>}>
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        loading={this.state.loading}
                        size="small"
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                console.log(page, pageSize);
                                this.updata(pageSize, page, {});
                            }
                        }}
                    />
                </Card>
            </div>
        )
    }
}


export default BasePage