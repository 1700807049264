import React, { useEffect, useState } from 'react'
import Charts from "echarts-for-react"
import * as echarts from 'echarts';
function lineMap(props) {
    {/* <lineMap data={[{ name: "成功", value: 40 }, { name: "失败", value: 10 }, { name: "异常", value: 4 }]} /> */ }
    function getOptionn(data) {
        const _values = []
        const _keys = data.map((i) => {
            _values.push(i.value)
            return i.name
        })
        return {
            // tooltip: {
            //     trigger: 'axis'
            // },
            tooltip: {
                trigger: 'axis',
                formatter: (param) => {
                    const i_param = Array.isArray(param) ? param[0] : param
                    const u_parm = i_param.data
                    let strArr = '<div>'
                    for (let i in u_parm) {
                        strArr += `<div>${i}:${u_parm[i]}</div>`
                    }
                    return strArr + i_param.marker + "</div>"
                }
            },
            grid: {
                // top: "2%",
                left: '3%',
                right: '3%',
                bottom: '1%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: _keys,
                axisLine: {
                    show: true,
                    lineStyle: {
                        // color: "#fff"
                        color: "#5b5b5b"
                    }
                },
                axisLabel: { interval: 0, rotate: 40, interval: data.length > 15 ? "auto" : 0 }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value} ' + (props.unit || "")
                },
                splitLine: { lineStyle: { color: "rgba(91, 91, 91, .35)" } },
                axisLine: {
                    show: true,
                    lineStyle: {
                        // color: "#fff"
                        color: "#5b5b5b"
                    }
                }
            },
            series: [
                {
                    data: data,
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                        color: "#FFAB3B"
                    },
                    // symbol: 'none',
                    symbolsize: 10,
                    symbol: "circle",
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: '#FFAB3B'
                        }, {
                            offset: 1,
                            color: '#0b1741'
                        }])
                    },
                    // areaStyle: {
                    //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    //         offset: 0,
                    //         color: '#3fb1e3'
                    //     }, {
                    //         offset: 1,
                    //         color: '#0b1741'
                    //     }])
                    // },
                    itemStyle: {
                        normal: {
                            // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            //     offset: 0,
                            //     color: '#00CCD2'
                            // }, {
                            //     offset: 1,
                            //     color: '#00A2FF'
                            // }])
                            color: "#FFAB3B",
                            // color: "#e2e2e2",

                        }
                    },
                }
            ]
        }
    }
    return <Charts option={getOptionn(props.data)} />
}
export default React.memo(lineMap)
