import React from 'react'
import { Button, Icon, Popconfirm, Modal, Spin, message } from "antd";
import BasePage from '@/components/BasePage';
import DynamicTable from '@/components/DynamicTable';
import OverlapHistogram from '@/components/OverlapHistogram/index';
import ScriptFrom from '@/components/ScriptForm/index'
import ScriptModal from '@/components/ScriptModal/index'
import BaseTree from '@/components/BaseTree/index';
import './index.scss'
import Socket from '@/socket/index'
import Utils from '@/utils/utils'
class Port extends React.Component {
    state = {
        focusBtn: '',
        visible: false,
        action_spin: false,
        visible_config: false,
        visible_temp: false,
        spinning: false,
        CheckParamKey: [],
        expandedKeys: [],
        columns: [],
        dataSource: [],
        columns2: [],
        dataSource2: [],
        chartData: [],
        scriptCont: [],
        deviceIp: null,
        vlanTaskId: null,
        spinInfo: "操作中。。。",
        callbackFun: null,
    }
    componentWillMount() {
        let taskId = (this.props.match.params.taskId).replace(":", "");
        if (!taskId) return;
        this.update({ taskId, reportTags: ["portManager"] }, "/00")
        this.noticeListen();
    }
    update = (body, menuUrl) => {
        Socket.ExecuteAction("QueryTaskResult", body, menuUrl, (resp) => {
            let columns = resp.showField;
            columns.push({
                title: '操作',
                align: 'center',
                width: '380px',
                dataIndex: 'action',
                render: (text, item, index) => {
                    return <div>
                        <Button type="primary" icon="form" style={{ marginLeft: 2 }} onClick={() => {
                            // console.log(text, item, index);
                            this.onClickJump(item, "detail");
                        }}>详情</Button>
                        <Button type="primary" icon="bar-chart" onClick={() => {
                            this.onClickJump(item, "traffic");
                        }}>流量</Button>
                    </div>
                }
            })
            // console.log(resp.data, columns);
            this.setState({
                dataSource: resp.data, columns, taskId: body.taskId
            });
        }, (error) => { })
    }
    noticeListen() {
        const _this = this;
        Socket.Notice((resp, callback) => {
            console.log(resp, callback);
            if (resp.type === "shade") {        //显示Spin
                _this.setState({ action_spin: resp.code, spinInfo: resp.text })
            } else if (resp.type === "interaction") {       //操作内容
                _this.setState({ scriptCont: resp.content, visible_temp: true, callbackFun: callback, })
                // console.log(callback);
            } else {
                // console.log(resp);
            }
        });
    }
    onClickJump(itemObj, _interface) {
        let deviceIp = itemObj.deviceIp;
        let vlanTaskId = itemObj.vlanTaskId;
        let device_id = itemObj.device_id;
        this.updateTableData(deviceIp, _interface);
        let focusBtn = '端口流量';
        if (_interface === "detail") focusBtn = "详情信息";
        setTimeout(() => {
            this.setState({ focusBtn, vlanTaskId, device_id, visible: true })
        }, 10)
    }

    updateTableData(deviceIp, _interface) {
        const { taskId } = this.state;
        Socket.ExecuteAction('QueryTaskResult', {
            taskId,
            reportTags: ["portManager"],
            deviceIp,
            interface: _interface
        }, '/--', (resp) => {
            if (_interface === "detail") {
                this.setState({ columns2: resp.showField, dataSource2: resp.data })
            } else if (_interface === "traffic") {
                let chartData = resp.chartData;
                const sourceTree = resp.treeData;
                let treeData = this.getTreeData(resp.treeData)
                this.setState({ chartData, treeData, deviceIp, sourceTree })
            }
        }, (error) => { })
    }
    getTreeData = (paramList) => {
        let itemTree = {};
        let treeData = [];
        let CheckParamKey = this.state.CheckParamKey;
        paramList.map((item) => {
            if (item.selected === 1) {
                CheckParamKey.push(item.name);
            }
            itemTree = {
                title: item.display,
                key: item.name
            };
            if (item.data && item.data != '') {
                itemTree['children'] = this.getTreeData(item.data)
            }
            treeData.push(itemTree)
        });
        this.setState({ CheckParamKey });
        return treeData;
    };
    CreateTreeData = (paramList) => {

        let treeData = [];
        let CheckParamKey = this.state.CheckParamKey;
        treeData = paramList.map((item) => {
            item.selected = 0;

            if (CheckParamKey.includes(item.name)) {
                item.selected = 1;
            }

            if (item.data && item.data != '') {
                item['data'] = this.CreateTreeData(item.data)
            }
            return item;
        });
        return treeData;
    };
    ActionTask() {
        const { vlanTaskId } = this.state;
        Socket.ExecuteAction('ExcuteTask', { _id: vlanTaskId }, '/taskMangerExe', (resp) => {
            // message.success("操作成功！");

        }, (error) => { })
    }
    TerminalTask() {
        const { vlanTaskId } = this.state;
        const _this = this;
        Socket.ExecuteAction('TerminalTask', { _id: vlanTaskId }, '/taskMangerTer', (resp) => {
            // message.success("操作成功！");
            _this.setState({ action_spin: false, })
        }, (error) => { })
    }
    ModalContent() {
        const { focusBtn, columns2, dataSource2, chartData, deviceIp } = this.state;

        const _this = this;
        if (focusBtn === '详情信息') {
            let columns3 = columns2.map((item, key) => { return item });
            columns3.push({
                title: '操作',
                render: (text, item, index) => {
                    return <div>
                        <Button type="primary" onClick={() => {
                            _this.ActionTask();
                            let data = {}
                            columns2.map((itemTitle, key) => {
                                data[itemTitle.title] = item[itemTitle.dataIndex]
                            })
                            _this.setState({ tableParam: data })
                            // _this.setState({ action_spin: true })
                            // setTimeout(() => {
                            //     _this.setState({ visible_temp: true, })
                            // }, 2000)

                        }}>切换vlan</Button>
                        <Button type="primary" style={{ marginLeft: 2 }} onClick={() => {
                            // _this.updateTableData(deviceIp, "detail")
                            // _this.setState({ visible_temp: true })
                        }}>端口up/down</Button>
                        <Button type="primary" style={{ marginLeft: 2 }} onClick={() => {
                            // _this.updateTableData(deviceIp, "detail")
                            // _this.setState({ visible_temp: true })
                        }}>策略下发</Button>
                    </div>
                }
            })
            let param = { columns: columns3, dataSource: dataSource2 }
            return <DynamicTable {...param} />
        } else if (focusBtn === '端口流量') {
            return <OverlapHistogram chartData={chartData} height={550} />
        }
    }

    setParam() {
        const { taskId, deviceIp, CheckParamKey, sourceTree } = this.state;
        let treeData = this.CreateTreeData(sourceTree);
        Socket.ExecuteAction('QueryTaskResult', {
            taskId, reportTags: ["portManager"], deviceIp, treeData,
            interface: "setTree",
        }, '/--', (resp) => {
            let chartData = resp.chartData;
            let treeData = this.getTreeData(resp.treeData)
            // console.log(chartData)
            // console.log(resp.treeData)
            this.setState({ visible_config: false, chartData, treeData })
        }, (error) => { })
    }
    ConfigContent() {
        let { treeData, CheckParamKey } = this.state;

        return (
            <BaseTree
                checkable={true}
                checkedKeys={CheckParamKey}
                // expandedKeys={this.state.expandedKeys}
                onCheck={(CheckParamKey) => {
                    // console.log('port', CheckParamKey);
                    this.setState({ CheckParamKey })
                }}
                treeData={treeData}
            />
        );
    }
    TempContent() {
        const { spinning, scriptCont } = this.state;
        const _this = this;
        // let formList = scriptCont.map((item) => {
        //     let fromItem = {
        //         label: item.display,
        //         field: item.name,
        //         // initialValue: scriptVal[item.name],
        //         type: item.type,
        //         option: item.option,
        //         group: item.group,
        //     };
        //     return fromItem
        // })
        return (
            <Spin spinning={spinning}>
                {/* <ScriptFrom formList={formList}
                    handleSubmit={(value) => {
                        console.log(value);
                        // console.log(this);
                        _this.setState({ spinning: true });
                        setTimeout(function () {
                            _this.setState({ spinning: false, visible_temp: false });
                            message.success('成功!');
                        }, 2000)
                    }}
                    getThis={this.refThis} /> */}
                <ScriptModal
                    InScript={scriptCont}
                    isOut={false}
                    getThis={(_this) => { this.fromThis = _this }}
                />
            </Spin>
        );
    }
    handleSubmit() {
        let scriptVal = this.fromThis.state.scriptSel;
        let { callbackFun, device_id, tableParam } = this.state;
        let resData = {
            input: scriptVal,
            table: tableParam,
            device_id,
        }
        console.log(resData);
        if (typeof callbackFun === "function") {

            callbackFun(resData);
        }

    }
    render() {
        let { visible, visible_config, visible_temp, focusBtn,
            columns, dataSource, action_spin, spinInfo } = this.state;
        const BreadcrumbArr = ["任务管理", { title: "概览", to: '/overView' }, "端口管理"];
        const tableTitle = "设备列表";
        const QueryInterface = { action: "QueryTaskResult", menuUrl: '/QueryTaskResult' };
        const cardButton = { name: "返回任务概览", icon: 'rollback', action: 'back' };
        const paramAPI = {
            columns, BreadcrumbArr, tableTitle, cardButton, QueryInterface, tableData: dataSource
        };
        return <div>
            <BasePage {...paramAPI} />
            <Modal
                destroyOnClose
                title={<div>{focusBtn}
                    {focusBtn === '详情信息' ? '' :
                        <Button type="primary" icon="setting" style={{ marginLeft: 15 }} onClick={
                            () => this.setState({ visible_config: true, })
                        }>参数配置</Button>
                    }
                </div>}
                visible={visible}
                onCancel={() => { this.setState({ visible: false }) }}
                footer={null}
                width="95%"
                style={{ position: 'fixed', top: 25, left: 35, }}
            >
                {this.ModalContent()}
            </Modal>
            <Modal
                destroyOnClose
                title={"参数配置"}
                visible={visible_config}
                onCancel={() => { this.setState({ visible_config: false }) }}
                onOk={() => this.setParam()}
                // footer={null}
                width="40%"
                style={{ position: 'fixed', top: 50, left: "30%", }}
            ><div className="configDiv">
                    {this.ConfigContent()}
                </div>
            </Modal>
            <Modal
                destroyOnClose
                zIndex={99999}
                title={"指令"}
                visible={visible_temp}
                onCancel={() => { this.TerminalTask(); this.setState({ visible_temp: false }) }}
                onOk={() => { this.handleSubmit(); this.setState({ visible_temp: false }) }}
                // footer={null}
                maskClosable={false}
                width="40%"
                style={{ position: 'fixed', top: 50, left: "30%", }}
            ><div className="TempContent">
                    {this.TempContent()}
                </div>
            </Modal>
            <Spin tip={spinInfo} spinning={action_spin} size="large" className="loading" />
        </div>
    }
}


export default Port