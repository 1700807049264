/**
 * 系统配置
 */
import React from 'react'
import { Card, Table, Button, message, Popconfirm, Icon, Input, Modal, Form, Tabs, Checkbox, Radio, Divider, } from "antd";
import Socket from "../../../socket/index";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import Utils from '@/utils/utils';
import EditTable from '@/components/AddTable/index'
import MonacaEditor from 'react-monaco-editor';
import {
    SubmitButton,
    FormItem,
    InputNumber,
} from "formik-antd"
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
const FormikRadio = require("formik-antd").Radio;
import { Formik } from "formik"

class SYSconfig extends React.Component {
    state = {
        apiVisible: false,
        loading: false,
        modeData: {},
        activeKey: "command",
        configStr: "",
        configObj: [],
        selectNode: null
    };
    componentWillMount() {
        this.getConfig("command");
        this.getAllConfig()
    }
    getAllConfig = () => {
        Socket.ExecuteAction("QueryTableConfig", {}, "", (res) => {
            this.setState({
                configStr: JSON.stringify(res.data, null, 5),
                configObj: res.data.map((item, index) => { item.key = "ID" + index; return item })
            })
        });
    };
    getConfig = (mode) => {
        Socket.getConfig(mode, (res) => {
            console.log(res)
            this.setState({ modeData: res });
        });
    };
    handleSubmit() {
        const { activeKey } = this.state;
        const _this = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                Socket.setConfig(activeKey, values, () => { message.success("保存成功!") });
            }
        });

    };
    tabOnChange(e) {
        this.setState({ activeKey: e })
        if (e === "code") {
            this.getAllConfig();
        }
    }
    addConfigParam = (val) => {
        Socket.ExecuteAction("UpdateTableConfig", { config: val }, "", (res) => {
            message.success("编辑成功");
            this.getAllConfig();
            this.setState({ apiVisible: false });
        });
    };
    delConfigParam = (val) => {
        const _this = this;
        Modal.confirm({
            title: "消息提示",
            content: "你确定删除该参数信息吗？",
            onOk() {
                Socket.ExecuteAction("DeleteTableConfig", { config: val }, "", (res) => {
                    message.success("删除成功");
                    _this.getAllConfig();
                });
            }
        })
    };
    updateConfigParam(r) {
        this.setState({ selectNode: r, apiVisible: true })
    }
    showInfo = (text) => {
        const val = typeof text === "object" ? JSON.stringify(text, null, 5) : text;
        Modal.info({
            title: "详情",
            width: "65%",
            content: <MonacaEditor
                theme="vs-dark"
                value={val}
                language="python"
                options={{ minimap: { enabled: false }, lineNumbers: "off", scrollBeyondLastLine: false, }}
                height="600"
            />
        })
    };
    setOffTime(value) {
        let bodyJson = {
            name: "maxOfflineDays",
            value: value.maxOfflineDays
        }
        Socket.ExecuteAction("UpdateTableConfig", { config: bodyJson }, "", (res) => {
            // console.log(res, value)
            message.success("设置成功");
            this.getAllConfig();
        });
    }
    setAdAuth(value) {
        let bodyJson = {
            name: "ad_auth",
            value: value,
        }
        Socket.ExecuteAction("UpdateTableConfig", { config: bodyJson }, "", (res) => {
            // console.log(res, value)
            message.success("设置成功");
            this.getAllConfig();
        });
    }
    render() {
        let { modeData, activeKey, configStr, configObj, apiVisible, selectNode } = this.state;
        const { getFieldDecorator } = this.props.form;
        const columns = [
            {
                title: "序号", width: 50, render: (text, record, key) => {
                    return <span key={key}>{key + 1}</span>
                }
            },
            { title: "参数名", dataIndex: "name", align: "left", width: 150, ellipsis: true, },
            {
                title: "参数值", dataIndex: "value", align: "left", ellipsis: true, render: (text, r, key) => {
                    const val = typeof text === "object" ? JSON.stringify(text) : text;
                    return <a onClick={() => { this.showInfo(text) }}>{val}</a>
                }
            },
            {
                title: "描述", dataIndex: "description", align: "left", ellipsis: true, render: (text, r, key) => {
                    return <a onClick={() => { this.showInfo(text) }}>{text}</a>
                }
            },
            {
                title: "操作", width: 100, render: (text, r, key) => {
                    return <div key={key}><a onClick={() => { this.updateConfigParam(r) }}>编辑</a>
                        <Divider type="vertical" /><a onClick={() => { this.delConfigParam({ name: r.name, value: r.value }) }}>删除</a></div>
                }
            },
        ];
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };
        let maxOffTime = configObj.filter((item) => {
            return item.name === "maxOfflineDays"
        })
        let ad_auth = configObj.filter(item => {
            return item.name === "ad_auth";
        })
        let ad_auth_data = ad_auth[0] ? ad_auth[0].value : null
        return (
            <div>
                <CustomBreadcrumb arr={["系统管理", "配置管理"]} />
                <Card title={<span style={{ fontWeight: 600 }}>系统配置信息</span>}>
                    <Tabs tabPosition="left" onChange={(e) => { this.tabOnChange(e); }} activeKey={activeKey}>
                        <Tabs.TabPane tab="配置下发" key="command">
                            <Form   {...formItemLayout}>
                                <Form.Item
                                    label="配置下发是否需要审核"
                                >
                                    {getFieldDecorator('approve', {
                                        initialValue: modeData.approve,
                                    })(<Radio.Group>
                                        <Radio value={"01"}>配置下发不需要审核</Radio>
                                        <Radio value={"02"}>配置下发强制需要审核</Radio>
                                        <Radio value={"03"}>配置下发是否需要审核由下发人员决定</Radio>
                                    </Radio.Group>)}
                                </Form.Item>
                                <Form.Item
                                    label="任务日志滚动条自动置底"
                                >
                                    {getFieldDecorator('logAutoScrllo', {
                                        valuePropName: 'checked',
                                        initialValue: modeData.logAutoScrllo,
                                    })(<Checkbox />)}
                                </Form.Item>
                                <Form.Item
                                    label="下发acl任务，先undo deny，末尾补充deny"
                                >
                                    {getFieldDecorator('need_handle_deny', {
                                        valuePropName: 'checked',
                                        initialValue: modeData.need_handle_deny,
                                    })(<Checkbox />)}
                                </Form.Item>
                            </Form>
                            <div style={{ marginTop: 10, width: "100%", textAlign: "center", paddingTop: 10 }}>
                                <Button type="primary" icon="save" onClick={() => { this.handleSubmit() }}>保存</Button>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="参数管理" key="code">
                            <Table
                                columns={columns}
                                dataSource={configObj}
                                size="small"
                                rowKey={(r) => { return r.key }}
                                pagination={false}
                            /><p />
                            <p><Button type="primary" icon="file-add" onClick={() => {
                                this.setState({ apiVisible: true })
                            }}>新增</Button></p>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="IP地址管理" key="off_time">
                            <Formik
                                onSubmit={(value, actions) => { this.setOffTime(value); actions.setSubmitting(false) }}
                                validate={(values) => {
                                    let info = {};
                                    if (!values.maxOfflineDays) {
                                        info.maxOfflineDays = "请输入最大离线天数";
                                    }
                                    return info
                                }}
                                initialValues={{
                                    maxOfflineDays: maxOffTime[0] ? maxOffTime[0].value : "",
                                }}
                            >
                                <FormikForm labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                                    <FormItem
                                        name={"maxOfflineDays"}
                                        label={"最大离线天数"}
                                        key={"maxOfflineDays"}
                                    >
                                        <InputNumber style={{ width: "170px" }} min={0} name={"maxOfflineDays"} placeholder={"请输入最大离线天数"} />
                                    </FormItem>
                                    <div style={{ textAlign: "center" }}>
                                        <SubmitButton style={{ marginLeft: "10px" }} icon="save" disabled={false}>保存</SubmitButton>&nbsp;
                                    </div>
                                </FormikForm>
                            </Formik>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="AD认证" key="ad_auth">
                            <Formik
                                onSubmit={(value, actions) => { this.setAdAuth(value); actions.setSubmitting(false) }}
                                validate={(values) => {
                                    let info = {};
                                    if (values.open_ad_auth !== true && values.open_ad_auth !== false) {
                                        info.open_ad_auth = "是否开启ad认证";
                                    }
                                    return info
                                }}
                                initialValues={{
                                    open_ad_auth: ad_auth_data ? ad_auth_data.open_ad_auth : false,
                                    ldap_url: ad_auth_data ? ad_auth_data.ldap_url : '',
                                    base_dn: ad_auth_data ? ad_auth_data.base_dn : '',
                                    domain: ad_auth_data ? ad_auth_data.domain : '',
                                }}
                            >
                                <FormikForm labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                                    <FormItem
                                        name={"open_ad_auth"}
                                        label={"是否开启ad认证"}
                                        key={"open_ad_auth"}
                                    >
                                        <FormikRadio.Group name="open_ad_auth" options={[
                                            { label: "开启", value: true },
                                            { label: "不开启", value: false },
                                        ]} />
                                    </FormItem>
                                    <FormItem
                                        name={"ldap_url"}
                                        label={"ldap地址"}
                                        key={"ldap_url"}
                                    >
                                        <FormikInput style={{ width: "370px" }} name={"ldap_url"} placeholder={"请输入ldap地址"} />
                                    </FormItem>
                                    <FormItem
                                        name={"base_dn"}
                                        label={"base_dn"}
                                        key={"base_dn"}
                                    >
                                        <FormikInput style={{ width: "370px" }} name={"base_dn"} placeholder={"请输入base_dn"} />
                                    </FormItem>
                                    <FormItem
                                        name={"domain"}
                                        label={"domain"}
                                        key={"domain"}
                                    >
                                        <FormikInput style={{ width: "370px" }} name={"domain"} placeholder={"请输入domain"} />
                                    </FormItem>
                                    <div style={{ textAlign: "center" }}>
                                        <SubmitButton style={{ marginLeft: "10px" }} icon="save" disabled={false}>保存</SubmitButton>&nbsp;
                                    </div>
                                </FormikForm>
                            </Formik>
                        </Tabs.TabPane>
                    </Tabs><Divider />
                </Card>
                <Modal
                    title={"编辑参数"}
                    visible={apiVisible}
                    width={"50%"}
                    onCancel={() => { this.setState({ apiVisible: false }) }}
                    footer={null}
                    destroyOnClose
                    afterClose={() => {
                        this.setState({ selectNode: null })
                    }}
                >
                    <div>
                        <Formik
                            initialValues={{
                                name: selectNode ? selectNode.name : "",
                                description: selectNode ? selectNode.description : "",
                                value: selectNode ?
                                    (typeof selectNode.value === "object" ? JSON.stringify(selectNode.value, null, 5) : selectNode.value) : "",
                            }}
                            onSubmit={(values, actions) => {
                                this.addConfigParam(values)
                                actions.setSubmitting(false)
                            }}
                            validate={(values) => {
                                if (!values.name) {
                                    return { name: "参数名不能为空！" }
                                }
                                if (!values.value) {
                                    return { value: "参数值不能为空！" }
                                }
                                return {}
                            }}
                        >
                            {(props2) => {
                                return <FormikForm labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                                    <FormItem
                                        name={"name"}
                                        label={"参数名"}
                                        required={true}
                                    >
                                        <FormikInput disabled={selectNode ? true : false} name={"name"} placeholder={"key"} />
                                    </FormItem>
                                    <FormItem
                                        name={"value"}
                                        label={"参数值"}
                                        required={true}
                                    >
                                        <FormikInput.TextArea style={{ height: 200 }} name={"value"} placeholder={"value"} />
                                    </FormItem>
                                    <FormItem
                                        name={"description"}
                                        label={"描述"}
                                        required={false}
                                    >
                                        <FormikInput.TextArea style={{ height: 200 }} name={"description"} placeholder={"description"} />
                                    </FormItem>
                                    <div style={{ textAlign: "center", borderTop: "1px solid #e2e2e2", paddingTop: 10 }}>
                                        <SubmitButton disabled={false} icon="file-text">完成</SubmitButton>&nbsp;
                                    <Button icon="close" onClick={() => { this.setState({ apiVisible: false }) }}>关闭</Button>
                                    </div>

                                </FormikForm>
                            }}
                        </Formik>
                    </div>
                </Modal>
            </div>
        )
    }
}
const SYSconfigForm = Form.create()(SYSconfig);
export default SYSconfigForm