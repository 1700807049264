/**
 * Created by YIMINE on 2019/1/24.
 */
import React from 'react'
import Socket from '../../../socket/index'
import { Card, Form, Input, Tree, Modal, Tabs, message, Select, Button, } from 'antd';
const { Option } = Select;
const TabPane = Tabs.TabPane;
const TextArea = Input.TextArea;
const { TreeNode } = Tree;

export default class PermissionTree extends React.Component {
    state = {
        autoExpandParent: true,                 //是否自动展开父节点
        selectedKeys: [],
        systemData: [],
        systemPerentKey: [],
    };

    componentDidMount() {
        this.getRulesData();
    }
    onExpand = (expandedKeys) => {  //展开/收起节点时触发 
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };
    getRulesData = () => {
        let _this = this;
        Socket.ExecuteAction('QueryArea', {}, "", (result) => {
            let systemPerentKey = _this.getParentKey(result.data);
            setTimeout(function () {
                let checkedKeys = _this.props.value;
                console.log(checkedKeys);
                _this.setState({
                    systemData: result.data,
                    checkedKeys: checkedKeys,
                    expandedKeys: [],
                    systemPerentKey,
                });
            }, 10);

        });

    };
    getParentKey = (ArrData) => {
        let arrNodeKey = [];
        ArrData.map((node, key) => {
            arrNodeKey.push(node.key);
        });
        return arrNodeKey;
    };

    onCheck = (checkedKeys, info) => {  //点击复选框触发
        console.log(checkedKeys, info);
        let checkedKeysResult = [...checkedKeys];
        // let checkedKeysResult = [...checkedKeys, ...info.halfCheckedKeys];
        // console.log(checkedKeys, checkedKeysResult);
        this.props.onChange(checkedKeysResult);
        this.setState({ checkedKeys, checkedKeysResult, });
    };
    onSelect = (selectedKeys, info) => { //点击树节点触发 
        this.setState({ selectedKeys });
    }
    renderTreeNodes = data => data.map((item) => {
        if (item.children) {
            return (
                <TreeNode title={item.title} key={item.key} dataRef={item}>
                    {this.renderTreeNodes(item.children)}
                </TreeNode>
            );
        }
        return <TreeNode {...item} />;
    })

    render() {

        let { systemData, } = this.state;
        return (
            <div style={{ border: "1px solid #e2e2e2" }}>
                <Tree
                    checkable
                    onExpand={this.onExpand}
                    expandedKeys={this.state.expandedKeys}
                    autoExpandParent={true}
                    onCheck={this.onCheck}
                    checkedKeys={this.state.checkedKeys}
                    onSelect={this.onSelect}
                    selectedKeys={this.state.selectedKeys}
                >
                    {this.renderTreeNodes(systemData)}
                </Tree>
            </div>
        );
    }
}
