import React from "react";
import { FullScreenContainer } from '@jiaminghi/data-view-react'
import './index.scss'
import { fullExit, fullScreen, judgeIsSupportFull } from '../utils'
import { withRouter } from "react-router-dom";
import Socket from "@/socket/index";
import http from '@/axios/axios_utils';
import { interfaces } from "@/pages/monitorAlert/dataCollection/utils/api"
import { message } from "antd";
const GRAFANA_IP_PORT = sys_config.grafana_address || "localhost:30300";
class BigScreen extends React.Component {

    state = {
        isSupportFull: false,

        isFull: true,
        isHider: true,
        MONITOR_URL: `http://${Socket.getAddress()}:50033/exception404`
    };
    componentWillMount() {
        this.getCfgVal()
    }
    componentDidMount() {
        window.addEventListener("resize", this.changeFullStatus);
        this.judgeIsSupportFull();
        fullScreen()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.changeFullStatus);
    }
    // 判断当前浏览器是否支持全屏API
    judgeIsSupportFull = () => {
        let isSupportFull = judgeIsSupportFull();
        this.setState({ isSupportFull });
    };
    // 计算当前是否处于全屏
    changeFullStatus = () => {
        // 判断网页的高度或者宽度是否等于屏幕对应大小
        // true: 当前处于全屏状态
        // false: 当前不处于全屏状态
        let scalRatio = window.devicePixelRatio;
        let screenRatio = document.body.scrollHeight / Math.round((window.screen.height / scalRatio));
        if (
            // document.body.scrollHeight === window.screen.height &&
            // document.body.scrollWidth === window.screen.width
            // 网页的高度或者宽度进行缩放之后，是否等于屏幕对应的大小
            (document.body.scrollHeight === Math.round(window.screen.height / scalRatio.toFixed(2)) &&
                document.body.scrollWidth === Math.round(window.screen.width / scalRatio.toFixed(2))) ||
            (document.body.scrollHeight === Math.round(window.screen.height / (scalRatio / screenRatio).toFixed(2)) &&
                document.body.scrollWidth === Math.round(window.screen.width / (scalRatio / screenRatio).toFixed(2)))
        ) {
            this.setState({ isFull: true });
        } else {
            this.setState({ isFull: false });
        }
        if (!this.state.isFull) {
            this.props.history.push("/home")
        }

    };
    // click button
    handClick = () => {
        this.toFullExit()
        // this.state.isFull ? this.toFullExit() : fullScreen();
    };

    handleMouseOver = () => {
        this.setState({ isHider: !this.state.isHider })
    }

    tick = e => {
        fullScreen()
    }

    toFullExit = e => {
        fullExit()
        this.props.history.push("/home")
    }
    getCfgVal = () => {
        Socket.getConfig("deviceScreenCfg", (r1) => {
            if (r1 && JSON.stringify(r1) != "{}") {
                const userName = sessionStorage.getItem('userName');
                let r_user = r1[userName];
                if (r_user && JSON.stringify(r1) != "{}") {
                    this.setData(r_user)
                } else {
                    this.initData()
                }
            }else {
                this.initData()
            }
        })
    }
    setData = (r_user) => {
        console.log(r_user)
        let r = r_user.info;
        let theme = r_user.theme || "dark";
        if (r.length === 3) {
            let monitor_url = `http://${GRAFANA_IP_PORT}/d/kqAltF33z/wang-luo-she-bei?orgId=1&var-job=${r[0]}&var-ip=${r[1]}&var-interface=${r[2]}&kiosk=tv&theme=${theme}`
            this.setState({ MONITOR_URL: monitor_url })
        }
    }
    initData = () => {
        http.get(interfaces(), { user_id: sessionStorage.getItem("userId") }, (r) => {
            let info = []
            const b_data = r.data
            const firms = Object.keys(b_data) || [];

            if (firms.length === 0) {
                message.error("数据为空,请设置")
            } else {
                const ip_obj = b_data[firms[0]];
                const ips = Object.keys(ip_obj) || [];
                if (ips.length === 0) {
                    message.error("ip数据为空,请设置")
                } else {
                    const ports = ip_obj[ips[0]];
                    if (ports.length === 0) {
                        message.error("端口数据为空,请设置")
                    } else {
                        info.push(firms[0]);
                        info.push(ips[0]);
                        info.push(ports[0]);
                    }
                }
            }
            this.setData({info,theme:"dark"})
        })
    }

    render() {
        let { isSupportFull, MONITOR_URL } = this.state;

        if (!isSupportFull) {
            return null;
        }
        return (<div id='data-view-screen'>
            <FullScreenContainer>
                <iframe id="deviceScreenIframe" src={MONITOR_URL} />
            </FullScreenContainer>

        </div>)
    }
}

export default withRouter(BigScreen)