/**
 * Created by YIMINE on 2019/5/17.
 */
import React, { Component } from 'react';
import './error.scss';
export default class ErrorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return <div style={styles.exceptionContent} className="exception-content">
            <img
                src="/image/error.png"
                style={styles.image}
                className="imgException"
                alt="页面出现异常"
            />
            <div className="prompt">
                <h3 style={styles.title} className="title">
                    抱歉，你访问的页面出现异常！
                </h3>
                <p style={styles.description} className="description">

                </p>
            </div>
        </div>
    }
}
const styles = {
    exceptionContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        color: '#333',
    },
    description: {
        color: '#666',
    },
};
