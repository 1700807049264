import React from 'react'
import { Card, Table, Button, Icon, Input, message, notification, Popconfirm, Tag, Modal } from "antd";
import socket from "../../../socket/index";
import Utils from "../../../utils/utils";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
export default class PlayRecord extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _pageSize: 10,    //当前每页显示条数
        loading: false,
        detailLoading: false,
        detailModal: false,
        detailData: [],
        querys: null,    //查询条件
    };
    componentWillMount() {
        this.updata();
    }
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        socket.ExecuteAction("QueryAuditsPage",
            bodyJson, "/QueryAuditsPage", (result) => {
                let tableData = result.data;
                tableData.map((item, index) => { item.key = index; return item });
                this.setState({
                    tableData,
                    _current: pageNum || 1,
                    _pageSize: pageSize || _pageSize,
                    total: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };
    getPlayDetail(_ID) {
        this.setState({ detailLoading: true });
        socket.ExecuteAction("QueryAuditDetail",
            { _id: _ID }, "/QueryAuditDetail", (result) => {
                let detailData = result.data;
                detailData.map((item, index) => { item.key = index; return item });
                this.setState({ detailData, detailLoading: false })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ detailLoading: false, })
            });
    }
    render() {
        let { _current, _pageSize, querys, tableData, loading, total, detailModal, detailData, detailLoading } = this.state;
        const columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                render: (text) => { return <span>{text + 1}</span> }
            }, {
                title: '名称',
                align: 'center',
                dataIndex: 'name'
            }, {
                title: '开始时间',
                align: 'center',
                dataIndex: 'beginTime',
            }, {
                title: '时长',
                align: 'center',
                dataIndex: 'runTime',
            }, {
                title: '状态',
                align: 'center',
                dataIndex: 'status',
                render: (text) => {
                    // return (<span style={{color:Utils.ColorShow(text),fontWeight:'600'}}>{Utils.Translation(text)}</span>)
                    return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>)
                }
            }, {
                title: '执行人',
                align: 'center',
                dataIndex: 'userId'
            }, {
                title: '用户IP',
                align: 'center',
                dataIndex: 'userIP',
                render: () => {
                    return (<span>{sessionStorage.getItem("userIP") || ""}</span>)
                }
            }, {
                title: '操作',
                align: 'center',
                render: (text, item) => {
                    return <Button type="primary" icon="form" size="small" onClick={() => {
                        this.getPlayDetail(item._id);
                        this.setState({ detailModal: true });
                    }}>详情</Button>
                }
            }
        ];
        const detailColums = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                render: (text) => { return <span>{text + 1}</span> }
            }, {
                title: '标签名称',
                align: 'center',
                dataIndex: 'portTag'
            }, {
                title: '端口名称',
                align: 'center',
                dataIndex: 'portName',
            }, {
                title: '设备IP',
                align: 'center',
                dataIndex: 'deviceIp',
            }, {
                title: '输入信息',
                align: 'center',
                dataIndex: 'input',
            }, {
                title: '切换前信息',
                align: 'center',
                dataIndex: 'old',
            }, {
                title: '切换后信息',
                align: 'center',
                dataIndex: 'new',
            }, {
                title: '切换结果',
                align: 'center',
                dataIndex: 'result',
                render: (text) => {
                    return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>)
                }
            }, {
                title: '添加时间',
                align: 'center',
                dataIndex: 'addTime'
            }
        ];
        const FormList = [
            {
                label: "名称",
                type: 'INPUT',
                field: "name",
                initialValue: '',
                placeholder: "名称"
            },

        ]
        return (
            <div>
                <CustomBreadcrumb arr={["端口管理", "操作记录"]} />
                <Card>
                    <FilterForm
                        formList={FormList}
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(_pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(_pageSize, 1, {})
                        }}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>操作记录列表</span>}
                    style={{ marginTop: 8 }}>

                    <Table
                        dataSource={tableData}
                        columns={columns}
                        size="small"
                        loading={loading}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.updata(size, 1, querys);
                            },
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                this.updata(pageSize, page, querys);
                            }
                        }}
                    />
                </Card>
                <Modal
                    title="详情"
                    visible={detailModal}
                    onCancel={() => this.setState({ detailModal: false })}
                    onOk={() => this.setState({ detailModal: false })}
                    width="90%"
                    style={{ top: "20px" }}
                    destroyOnClose
                >
                    <Table
                        columns={detailColums}
                        dataSource={detailData}
                        loading={detailLoading}
                        size="small"
                    />
                </Modal>
            </div>
        )
    }
}
