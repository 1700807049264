import React from 'react'
import BindDumbRecord from './bindDumbRecord'

class allBindDumbRecord extends React.Component {
    state = {
    }

    componentWillMount() {
    }
    render() {
        return (
            <div className="bindRecordList">
                <BindDumbRecord />
            </div>
        )
    }
}

export default allBindDumbRecord;
