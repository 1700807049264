import React from 'react'
import { Table, Button, Icon, Row, Col, message, Tag, Modal, Dropdown, Menu } from "antd";
import http from '@/axios/axios_utils';
import FilterForm from '@/components/FilterForm/index';
import FirewallUtils from "@/utils/FirewallUtils";
const firewall_url = "http://" + backup_address + "/ZJNMS_V1/";
export default class FireWallNat extends React.Component {
    state = {
        s_current: 1,      //当前页码数
        s_pageSize: 20,    //每页显示条数
        s_total: 0,
        s_querys: {},    //查询条件
        s_loading: false,
        s_tableData: [],
        deviceInfo: {},
        hisVis: false,
        hisData: [],
        hisItem: {},
        modalTitle: "",
        _current: 1,      //当前页码数
        _pageSize: 20,    //每页显示条数
        _total: 0,
        policeVis: false,
        policeData: [],
    };
    componentWillMount() {
        const deviceInfo = JSON.parse(sessionStorage.getItem("device"));
        this.setState({ deviceInfo });
        this.updata(null, null, null, deviceInfo.deviceIp);
    }
    componentDidMount() {
        this.props.onRef(this);
    }
    componentWillUnmount() {
        this.clearTimer();
    }

    updata = (pageNum, pageSize, querys, ip, loadingFlag) => {
        if (loadingFlag !== "noLoading") {
            this.setState({ s_loading: true, });
        }
        let { s_pageSize, s_current, s_querys, deviceInfo } = this.state;
        let query = querys || s_querys;
        let bodyJson = {
            page_size: pageSize || s_pageSize,
            page_no: pageNum || s_current,
            fw_ip: ip || deviceInfo.deviceIp,
            nat_type: "dnat",
            ...query,
        };
        http.get(firewall_url + "fw_manage/fwNat", bodyJson, (result) => {
            if (result.code === "00") {
                this.setState({
                    s_tableData: result.data,
                    s_total: result.count,
                    s_loading: false,
                    s_querys: query,
                    s_current: pageNum || 1,
                    s_pageSize: pageSize || s_pageSize,
                })
                if (result.is_updating) {
                    this.timerControl();
                    this.props.loadingTrue();
                } else {
                    this.clearTimer();
                    this.props.loadingFalse();
                }
            } else {
                message.error("查询失败");
            }
        });
    };
    clearTimer() {
        if (this.timer != null) {
            clearInterval(this.timer);
        }
    }
    timerControl() {
        let { s_current, s_pageSize, s_querys, deviceInfo, tabsKey } = this.state;
        let _this = this;
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
            // _this.switchTab(tabsKey, {s_current, s_pageSize, s_querys})
            _this.updata(s_current, s_pageSize, s_querys, deviceInfo.deviceIp, 'noLoading')
        }, 5000)
    }
    getHistoryList(pageNum, pageSize, item) {
        let { _pageSize, _current, deviceInfo } = this.state;
        let nat_id = item.dnat_id;
        let bodyJson = {
            page_size: pageSize || _pageSize,
            page_no: pageNum || _current,
            fw_ip: deviceInfo.deviceIp || "",
            ip_vrouter: item.ip_vrouter || "",
            nat_type: "dnat",
            nat_id: nat_id || "",
        }
        http.get(firewall_url + "fw_manage/fwNatHistory", bodyJson, (result) => {
            if (result.code === "00") {
                this.setState({
                    hisData: result.data,
                    _total: result.count,
                    _current: pageNum || 1,
                    modalTitle: nat_id,
                    hisItem: item,
                    hisVis: true,
                })
            } else {
                message.error("查询失败");
            }
        });
    }
    getSecurityPolicyForNat(item) {
        let { deviceInfo } = this.state;
        let bodyJson = {
            fw_ip: deviceInfo.deviceIp || "",
            trans_to_addr_group: item.trans_to_addr.addr_group || "",
        }
        http.get(firewall_url + "fw_manage/securityPolicyForNat", bodyJson, (result) => {
            if (result.code === "00") {
                // console.log(result)
                this.setState({
                    policeData: result.data,
                    modalTitle: item.dnat_id,
                    policeVis: true,
                })
            } else {
                message.error("查询失败");
            }
        });
    }
    menuButton(resItem) {
        return (
            <Menu onClick={(e) => this.handleMenuClick(e, resItem)}>
                {(() => {
                    if (resItem.create_time !== resItem.update_time) {
                        return (
                            <Menu.Item key="history">
                                <Icon type='history' /> 查看历史记录
                            </Menu.Item>
                        )
                    }
                })()}
            </Menu>
        )
    }
    handleMenuClick = (e, item) => {
        const { _current, _pageSize } = this.state
        switch (e.key) {
            case 'history':
                this.getHistoryList(1, _pageSize, item);
                break;
            case 'viewPolice':
                this.getSecurityPolicyForNat(item)
                break;
        }
    }

    render() {
        let { s_current, s_loading, s_pageSize, s_tableData, s_total, s_querys, deviceInfo, hisVis,
            modalTitle, hisData, _current, _total, _pageSize, hisItem, policeVis, policeData } = this.state;
        const columns = [
            ...FirewallUtils.getDNatColumns(s_current, s_pageSize),
            {
                title: '操作',
                align: 'center',
                dataIndex: "historyList",
                ellipsis: true,
                width: 200,
                fixed: 'right',
                render: (text, item, index) => {
                    return (
                        <Dropdown.Button
                            overlay={this.menuButton(item)} type='primary' size='small' icon={<Icon type="down" />}
                            onClick={() => {
                                this.handleMenuClick({ key: "viewPolice" }, item)
                            }}
                        >
                            <Icon type="api" /> 查看相应策略
                        </Dropdown.Button>
                    )
                }
            }
        ];
        const formList = [
            {
                label: '虚拟路由器',
                type: 'INPUT',
                field: 'ip_vrouter',
                initialValue: '',
                placeholder: '请输入内容'
            },
            {
                label: '源地址',
                type: 'INPUT',
                field: 'src_ip',
                initialValue: '',
                placeholder: '请输入内容'
            },
            {
                label: '目的地址',
                type: 'INPUT',
                field: 'dst_ip',
                initialValue: '',
                placeholder: '请输入内容'
            },
            {
                label: '转换为',
                type: 'INPUT',
                field: 'trans_to_addr',
                initialValue: '',
                placeholder: '请输入内容'
            },
        ]
        const hisColumns = [...FirewallUtils.getDNatColumns(s_current, s_pageSize),
        {
            title: '创建时间',
            align: 'center',
            dataIndex: "create_time",
            ellipsis: true,
            width: 120,
        },
        ];
        const policeColumns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: "key",
                ellipsis: true,
                width: 50,
                render: (text, item, index) => {
                    return <span>{index + 1}</span>
                }
            },
            ...FirewallUtils.getPoliceColumns(20000000, 1),
        ];
        return (
            <div className="home-body">
                <FilterForm formList={formList}
                    filterSubmit={(querys) => {
                        this.updata(1, s_pageSize, querys)
                    }}
                    resetTab={() => {
                        this.updata(1, s_pageSize, {})
                    }}
                />
                <Table
                    style={{ marginTop: 8 }}
                    dataSource={s_tableData}
                    columns={columns}
                    size="small"
                    loading={s_loading}
                    rowKey={(text) => { return text._id }}
                    pagination={{
                        current: s_current, pageSize: s_pageSize, total: s_total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (page, pageSize) => { this.updata(1, pageSize, s_querys) },
                        onChange: (page, pageSize) => { this.updata(page, pageSize, s_querys); }
                    }}
                />
                <Modal
                    title={`防火墙${deviceInfo.deviceIp}--目的nat_id:${modalTitle}--历史记录`}
                    visible={hisVis}
                    onOk={() => this.setState({ hisVis: false, modalTitle: "", hisItem: {} })}
                    onCancel={() => this.setState({ hisVis: false, modalTitle: "", hisItem: {} })}
                    width="95%"
                    destroyOnClose
                >
                    <Table
                        dataSource={hisData}
                        columns={hisColumns}
                        size="small"
                        rowKey={(text) => { return text._id }}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: _total,
                            onChange: (page, pageSize) => { this.getHistoryList(page, pageSize, hisItem); }
                        }}
                    />
                </Modal>
                <Modal
                    title={`目的nat_id:${modalTitle}--策略信息`}
                    visible={policeVis}
                    onOk={() => this.setState({ policeVis: false, modalTitle: "" })}
                    onCancel={() => this.setState({ policeVis: false, modalTitle: "" })}
                    width="95%"
                    destroyOnClose
                >
                    <Table
                        dataSource={policeData}
                        columns={policeColumns}
                        size="small"
                        rowKey={(text) => { return text._id }}
                        pagination={false}
                    />
                </Modal>
            </div>
        );
    }
} 