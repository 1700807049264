import React from 'react'
import { Button, Icon, Popconfirm, message, Card, Collapse, Table, Tag, Modal, Upload, Pagination, DatePicker, Tabs, notification, Select } from "antd";

import Socket from '@/socket/index';
import './index.scss';
import $ from "jquery";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';

class HistoryList extends React.Component {
    state = {
        resuleData: [],
        taskName: "",
        loading: false,
        taskID: "",
    }
    componentWillMount() {
        let taskID = this.props.match.params.taskID;
        if (taskID) {
            this.updata({ _id: taskID });
            this.setState({ taskID });
        }
    }
    updata(querys) {
        this.setState({ loading: true });
        Socket.ExecuteAction("QueryTaskResultsList", querys, "/QueryTaskResultsList", (result) => {
            this.setState({ resuleData: result.data, loading: false });
        }, (result) => {
            this.setState({ loading: false });
            notification.error({
                message: '查询失败',
                description: result.error,
                icon: <Icon type="frown" style={{ color: 'red' }} />,
            })
        })
    }
    render() {
        let { resuleData, taskName, loading, taskID } = this.state;
        const colum_page = [
            {
                title: "序号",
                render: (text, item, key) => {
                    return key + 1;
                }
            },
            {
                title: "任务名称",
                dataIndex: "taskName",
            },
            {
                title: "时间",
                dataIndex: "taskEndTime",
            },
            {
                title: "记录条数",
                dataIndex: "recordNum",
            },
            {
                title: "操作",
                render: (text, item, key) => {
                    return <div>
                        <Button type="primary" icon={'project'}
                            onClick={() => { this.props.history.push(`taskHistory${item.taskId}`) }}>查看记录</Button>
                    </div>
                }
            },
        ]
        const formList = [
            {
                label: "任务名称",
                type: 'INPUT',
                field: "taskName",
                initialValue: '',
                placeholder: "任务名称"
            },
            {
                label: "时间范围",
                type: 'timeSelect',
                field: "time",
                initialValue: '',
            },
        ]
        return <div>
            <CustomBreadcrumb arr={["任务管理", { title: "概览", to: '/overView' }, "历史记录"]} />
            <Card>
                <FilterForm
                    formList={formList}
                    filterSubmit={(querys) => {
                        console.log(querys);
                        querys.taskName = taskName;
                        this.updata(querys);
                    }}
                    resetTab={() => {
                        this.updata({});
                    }}
                />

            </Card>
            <Card title={<span style={{ fontWeight: 600 }}>历史纪录</span>}
                style={{ marginTop: 8 }} extra={<div>
                    <Button type="primary" icon={'rollback'}
                        onClick={() => { this.props.history.goBack(); }}>返回任务概览</Button></div>}>
                <div>
                    <Table
                        columns={colum_page}
                        dataSource={resuleData}
                        loading={loading}
                        size="small"
                    />
                </div>
            </Card >
        </div>
    }
}
export default HistoryList