import React from 'react'
import {Card, Select, Input, Button, Form, message, notification, Icon, Row, Col, Modal, Tabs, Switch} from 'antd';
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import AxiosObj from "../../../axios";
const { Option } = Select;
// 监控告警后端服务器地址 monitor_address 配置地址：public/flow/config.js
const backUp_Url = monitor_address;

class addPolicy extends React.Component{
    state = {

    }
    //添加保存策略  //this.props.form.validateFieldsAndScroll
    handleSubmit=(e)=>{
        e.preventDefault(); //阻止表单提交时更新页面
        this.props.form.validateFieldsAndScroll((err,values)=>{
            if(!err){
                //console.log('表单值',values)
                let map ={}
                map["name"] = values.name;
                map["hours"] = values.num + values.unit;
                map["default"] = values.default.toString();
                console.log(map)
                AxiosObj.HTTP_POST('alarm',"/snmpexporter/addPolicy",map,(data)=>{
                    message.success('success');
                    this.props.history.goBack();
                },(error)=>{
                    message.error(error);
                })
            }
        })
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        return(
            <div>
                <CustomBreadcrumb arr={["监控告警", "数据存储","添加存储策略"]} />
                <Card
                    style={{ width: '100%', marginTop: 30  }}
                    title={<span style={{ fontWeight: 600 }}>添加存储策略</span>}
                    onTabChange={key => {
                        this.onTabChange(key, 'key');
                    }}
                >
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item
                            label="策略名称"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('name', {
                                initialValue:  "",
                                rules: [{
                                    type: 'string', message: '请输入正确字符！',
                                }, {
                                    pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                }, {
                                    required: true, message: '请输入字符!',
                                }],
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label="保存时长"
                            {...formItemLayout}
                        >
                            <div>
                                 {getFieldDecorator('num', {
                                     rules:[{
                                         required:true,
                                         pattern: new RegExp(/^[1-9]\d*$/, "g"),
                                         message: '请输入正确的时长'
                                     }]
                                 })(
                                      <Input style={{ width : '50%'}} placeholder="" />
                                  )}
                                 {getFieldDecorator('unit', {
                                      initialValue: "d"
                                      })(
                                      <Select   style={{ marginLeft : 20, width: 80 }} onChange={console.log('')}>
                                          <Option value="h">小时</Option>
                                          <Option value="d">天</Option>
                                      </Select>
                                 )}
                            </div>

                        </Form.Item>
                        <Form.Item
                            label="是否选为默认策略"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('default',{
                                initialValue : false
                            })(
                                <Switch style={{marginLeft:5}}checkedChildren="开" unCheckedChildren="关"
                                        onClick={(value)=>{
                                            console.log(value)
                                            //setTimeout(console.log(this.state.switchPersistence),1000)
                                        }}
                                />
                            )}

                        </Form.Item>
                        <Form.Item
                            label=" "
                            colon={false}
                            {...formItemLayout}
                        >
                            <div >
                                <Button type="primary" icon="check-circle" htmlType="submit" style={{ width: 150 }}>
                                    保存
                                </Button>
                                <Button type="danger" icon="close-circle" style={{ width: 150, marginLeft: 10 }}
                                        onClick={() => { this.props.history.goBack(); }}>
                                    取消
                                </Button>
                            </div>
                        </Form.Item>

                    </Form>
                </Card>
            </div>
        )
    }
}
export default Form.create()(addPolicy);