import React, { createElement } from 'react';
import classNames from 'classnames';
import { Button, Icon } from 'antd';
import { Link } from 'react-router-dom'
import config from './typeConfig';
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import './index.scss';

class Exception extends React.PureComponent {
  static defaultProps = {
    backText: 'back to home',
    redirect: '/',
  };

  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.props.dispatch(isLoading(false));
  }

  render() {
    const {
      className,
      backText,
      linkElement = 'a',
      linkTo,
      type,
      title,
      desc,
      img,
      actions,
      redirect,
      ...rest
    } = this.props;
    const pageType = type in config ? type : '404';
    console.log(this.props)
    return (
      <div className="exception" >
        <div className={"imgBlock"}>
          <div
            className={"imgEle"}
            style={{ backgroundImage: `url(${img || config[pageType].img})` }}
          />
        </div>
        <div className={"content"}>
          <h1>{title || config[pageType].title}</h1>
          <div className={"desc"}>
            {/* <img src={"/image/" + pageType + ".png"} /> */}
            <p>
              <Icon type="frown" theme="twoTone" /> {desc || config[pageType].desc}
            </p>
            <p>
              <Button type="primary" icon="home"><Link to={linkTo} style={{ color: "white" }}> {backText}</Link></Button>
            </p>

          </div>

          {/* <div className={"actions"}>
            {actions ||
              createElement(
                linkElement,
                {
                  to: redirect,
                  href: redirect,
                },
                <Button type="primary">{backText}</Button>
              )}
          </div> */}
        </div>
      </div>
    );
  }
}

export default connect()(Exception)
