import React, { useState, useEffect } from 'react'
import { Card, Collapse, Row, Col } from "antd";
import Utils from '@/utils/utils';
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import ReactEcharts from "echarts-for-react"
import http from '@/axios/axios_utils';
import api from '@/config/api/deliveryTask';
import Socket from '@/socket/index';
function DataMap(props) {
    const [mapData, setMapData] = useState({});
    const [treeDepIds, setTreeDepIds] = useState([]);

    useEffect(() => {//componentDidMount
        // getData();
        getAreaData();
        return () => { //componentWillUnmount
        }
    }, []);
    const getData = (depIds) => {
        http.post(api.deliveryDataStatistics, { dep_ids: depIds || treeDepIds }, (data) => {
            setMapData(data.data)
        })
    }
    function getAreaData() {
        Socket.ExecuteAction('QueryArea', {}, "", (result) => {
            const TreeData = result.data;
            const depIds = dealTreeData(TreeData);
            setTreeDepIds(depIds)
            getData(depIds); // 获取列表数据
            // console.log("getAreaData", TreeData, depIds)
        });
    };
    function dealTreeData(data) {
        let dep_ids = [];
        if(Array.isArray(data)) {
            data.map((item) => {
                let children = item.props ? item.props.children : item.children
                if(children && children.length > 0) {
                    dep_ids = dep_ids.concat(dealTreeData(children));
                } else {
                    dep_ids.push(item.key || item.eventKey)
                }
            })
        }
        return dep_ids
    }
    const getOptionPie = () => {
        const text = "下发分类统计";
        const type = [];
        const data = (mapData[text] || []).map((item,) => {
            type.push(item.type);
            return { value: item.count, name: item.type }
        })
        return {
            title: {
                text: text,
                x: 'center'
            },
            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            // legend: {
            //     orient: 'horizontal',
            //     top: 30,
            //     type: "scroll",
            //     left: 0,
            //     itemHeight: 8,
            //     itemWidth: 8,
            //     data: type
            // },
            color: ["#3fb1e3", "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8"],
            series: [
                {
                    name: text,
                    type: 'pie',
                    radius: '55%',
                    label: { show: true, position: "outside", formatter: "{b}:{c}\n({d}%)", },
                    center: ['50%', '60%'],
                    data: data,
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        }
    };

    const getOptionCloums = () => {
        const text = "下发分类成功率";
        const type = [];
        const data = (mapData[text] || []).map((item,) => {
            type.push(item.type);
            return item.success_rate
        })
        return {
            title: {
                text: '下发分类成功率',
                x: 'center'
            },
            // legend: {
            //     orient: 'horizontal',
            //     top: 30,
            //     type: "scroll",
            //     left: 0,
            //     itemHeight: 8,
            //     itemWidth: 8,
            //     data: type
            // },
            tooltip: {
                trigger: 'axis',
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    // type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: type,
                    axisTick: {
                        alignWithLabel: true
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            series: [
                {
                    name: '成功率',
                    type: 'bar',
                    label: { show: true, position: "top" },
                    barWidth: '60%',
                    itemStyle: {
                        normal: {
                            //这里是重点
                            color: function (params) {
                                //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                                var colorList = ["#3fb1e3", "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8", '#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622'];
                                return colorList[params.dataIndex]
                            }
                        }
                    },
                    data: data
                }
            ]
        }
    };
    const getOptionLines = () => {
        const text = "每月下发数量分类统计";
        const time = [];
        let AllData = {}
        let type = [];
        (mapData[text] || []).map((item, key) => {
            time.push(Object.keys(item)[0]);
            Object.values(item)[0].map((item2, key2) => {
                type = [...type, item2.type,]

                let temp = [];
                if (AllData[item2.type]) {
                    temp = AllData[item2.type];
                }
                temp.push(item2.count);
                AllData[item2.type] = temp;
            })
        })
        const series = Object.keys(AllData).map((type, key) => {
            return {
                data: AllData[type],
                type: 'line',
                name: type,
            }
        })
        console.log(series)
        return {
            title: {
                text: text,
                x: 'center'
            },
            tooltip: {
                trigger: 'axis',
            },
            color: ["#3fb1e3", "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8"],
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: time
            },
            yAxis: {
                type: 'value'
            },
            series: series
        }
    }
    const getMutilCloums = () => {
        const text = "按月分类成功率";
        const time = [];
        let AllData = {}
        let type = [];
        (mapData[text] || []).map((item, key) => {
            time.push(Object.keys(item)[0]);
            Object.values(item)[0].map((item2, key2) => {
                type = [...type, item2.type,]

                let temp = [];
                if (AllData[item2.type]) {
                    temp = AllData[item2.type];
                }
                temp.push(item2.success_rate);
                AllData[item2.type] = temp;
            })
        })
        const color = ["#3fb1e3", "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8", "#3fb1e3",
            "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8",
            "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8",
            "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8",
            "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8",
            "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8"];
        const series = Object.keys(AllData).map((type, key) => {
            return {
                name: type,
                type: 'bar',
                label: {
                    show: true,
                },
                itemStyle: {
                    color: color[key] || "#000"
                },
                data: AllData[type]
            }
        })
        return {
            title: {
                text: text,
                x: 'center'
            },
            // legend: {
            //     orient: 'horizontal',
            //     top: 30,
            //     type: "scroll",
            //     left: 0,
            //     itemHeight: 8,
            //     itemWidth: 8,
            //     data: ['ACL', '防火墙策略', '密码变更',]
            // },
            tooltip: {
                trigger: 'axis',
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            yAxis: {
                type: 'value'
            },
            xAxis: {
                type: 'category',
                data: time
            },
            series: series
        }
    }

    return <div className={props.className}>
        <Row gutter={10}>
            <Col span={6}>
                <Card>
                    {/* <PieChar data={[{ item: "成功", count: 40 }, { item: "失败", count: 10 }, { item: "异常", count: 4 }]} /> */}
                    <ReactEcharts
                        option={getOptionPie()}
                        style={{ height: 200 }}
                    // onChartReady={this.onChartReady}
                    // onEvents={onEvents} 
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <ReactEcharts
                        option={getMutilCloums()}
                        style={{ height: 200 }}
                    // onChartReady={this.onChartReady}
                    // onEvents={onEvents} 
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <ReactEcharts
                        option={getOptionCloums()}
                        style={{ height: 200 }}
                    // onChartReady={this.onChartReady}
                    // onEvents={onEvents} 
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <ReactEcharts
                        option={getOptionLines()}
                        style={{ height: 200 }}
                    // onChartReady={this.onChartReady}
                    // onEvents={onEvents} 
                    />
                </Card>
            </Col>
        </Row>
    </div>
}
export default connect()(React.memo(DataMap))
