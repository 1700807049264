import React from "react";
import {
    Icon, Card, Dropdown, Menu, Tag, Popover, Spin, Empty, Pagination, Tooltip,
} from "antd";
import "./index.scss"
import socket from "@/socket/index";
import Utils from "@/utils/utils";
const { Meta } = Card;
export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page_size: 10,
            page: 1,
            totalPage: 1,
            totalCount: 1,
            loading: this.props.loading,
            moduleName: this.props.moduleName,
            moduleGroup: [],

            tableData: this.props.dataSource || [],
            type: this.props.type,  // 表单来源（从哪个页面进入组件）
        };
    }
    componentWillMount() {
        const pagination = this.props.pagination;
        const { page, page_size } = this.state;
        // this.props.pagination.onChange(pagination.current || page, pagination.pageSize || page_size);
        this.currentPageChange(pagination.current || page, pagination.pageSize || page_size)
        this.getModuleGroup();
        // console.log(this.state.type) // Inspect 巡检   Customize   自定义任务
    }

    currentPageChange(current, pageSize) {
        this.setState({
            page: current,
            page_size: pageSize
        })
        this.props.pagination.onChange(current, pageSize);
    }

    // 按钮菜单
    menuButton(item) {
        if (this.state.type === "Customize") { // 自定义任务列表
            return (
                <Menu onClick={(e) => { e.domEvent.stopPropagation(); this.props.handleMenuClick(e, item); }}>
                    <Menu.Item key="mod">
                        <Icon type="database" />
                        模版
                    </Menu.Item>
                    <Menu.Item key="log">
                        <Icon type="file-text" />
                        日志
                    </Menu.Item>
                    <Menu.Item key="edit">
                        <Icon type="form" />
                        编辑
                    </Menu.Item>
                    <Menu.Item key="del">
                        <Icon type="delete" />
                        删除
                    </Menu.Item>
                </Menu>
            )
        } else if (this.state.type === "Inspect") { // 巡检列表
            return (
                <Menu onClick={(e) => { e.domEvent.stopPropagation(); this.props.handleMenuClick(e, item); }}>
                    <Menu.Item key="mod">
                        <Icon type="database" />
                        模版
                    </Menu.Item>
                    <Menu.Item key="edit">
                        <Icon type="form" />
                        编辑
                    </Menu.Item>
                    <Menu.Item key="del">
                        <Icon type="delete" />
                        删除
                    </Menu.Item>
                </Menu>
            )
        }
    }

    chooseButtonGroup(item) {
        let page = this.state.page;
        let pageSize = this.state.page_size;
        if (item.taskStatus === "run") {
            return (
                <span onClick={(e) => { e.stopPropagation(); this.props.TerminalTask(item._id, page, pageSize) }} style={{ color: "#ff4d4f" }}>
                    <Icon type="pause-circle" style={{ marginRight: 5 + "px" }} />
                    终止
                </span>
            )
        } else {
            return (
                <span onClick={(e) => { e.stopPropagation(); this.props.excuteTask(item._id, item.taskName, page, pageSize) }} style={{ color: "#1890FE" }}>
                    <Icon type="play-circle" style={{ marginRight: 5 + "px" }} />
                    执行
                </span>
            )
        }
    }

    buttonAction(item) {
        return ([
            <span onClick={(e) => {
                e.stopPropagation();
                if (this.state.type === "Customize") {
                    this.props.handleMenuClick({ key: "result" }, item);
                } else if (this.state.type === "Inspect") {
                    this.props.handleMenuClick({ key: "report" }, item);
                }
            }}>
                <Icon type="diff" style={{ marginRight: 5 + "px" }} />
                记录
            </span>,
            this.chooseButtonGroup(item),
            <Dropdown overlay={this.menuButton(item)} onClick={(e) => { e.stopPropagation(); }} className="pd-12">
                <Icon type="ellipsis" />
            </Dropdown>
        ])
    }

    pickImgUrl(reportTags, taskName) {
        switch (reportTags) {
            case "普通任务": return (<span className="taskIcon" style={{ background: "linear-gradient(135deg,#00c6ff 1%,#0072ff 100%)", opacity: "0.9" }} >{reportTags}</span>);
            case "巡检": return (<span className="taskIcon" style={{ background: "linear-gradient(135deg,#4e54c8 1%,#8f94fb 100%)", opacity: "0.9" }} >{reportTags}</span>);
            case "acl": return (<span className="taskIcon" style={{ background: "linear-gradient(135deg,#ED213A 1%,#93291E 100%)", opacity: "0.9" }} >{reportTags}</span>);
            default: return (<span className="taskIcon" style={{ background: "linear-gradient(135deg,#1cd8d2 1%,#93edc7 100%)", opacity: "0.9" }} >{reportTags}</span>)
        }
    }
    popContent(item) {
        return (
            <div style={{ maxWidth: "230px" }} onClick={(e) => { e.stopPropagation(); }}>
                <p>方式：{Utils.Translation(item.taskPlan.type)}</p>
                <p>标签：{Utils.hidenTag(item.tags)}</p>
                <p>耗时：{item.costing}</p>
                { this.getSimpleModule(item.template)}
                <p>下次执行时间：{item.nextTime}</p>
            </div>
        )
    }
    getModuleGroup() {
        // 获取所有模版信息
        socket.ExecuteAction("QueryTasksPage",
            { pageNum: 1, pageSize: 10000, querys: { ifTemplate: true } }, "/taskQueryTask", (result) => { // 获取该任务类型下的所有模版
                const taskList = result.tasks;
                this.setState({
                    moduleGroup: taskList
                })
            });
    }
    getSimpleModule(template) {
        const { moduleGroup } = this.state;
        let elem = null;
        moduleGroup.map((item, index) => {
            if (item._id === template) {
                elem = (
                    <Tooltip title={item.taskName}>
                        <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>模版名称：{item.taskName}</p>
                    </Tooltip>
                )
            }
        })
        return elem;
    }
    getTaskStatus(taskStatus) {
        const statusColor = Utils.ColorShow(taskStatus);
        const statusText = Utils.Translation(taskStatus);
        let iconType = <Icon type="check-circle" />;
        if (statusText === "执行中") {
            iconType = <Icon type="sync" spin />
        } else if (statusText === "终止") {
            iconType = <Icon type="warning" />
        } else if (statusText === "新建") {
            iconType = <Icon type="plus-circle" />
        }
        return <Tag color={statusColor}>{iconType}&nbsp;{statusText}</Tag>
    }

    render() {
        let { page, page_size } = this.state;
        const { pagination } = this.props;
        let tableData = this.props.dataSource || [];

        if (!this.props.dataSource) {
            return (
                <div className="loadingTip">
                    <Spin tip="正在加载" />
                </div>
            )
        } else if (this.props.dataSource.length === 0) {
            return (
                <div className="loadingTip">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            )
        } else {
            return (
                <div>
                    <Pagination
                        current={page}
                        pageSize={page_size}
                        total={pagination.total}
                        showTotal={total => `共 ${total} 项`}
                        showSizeChanger={true}
                        defaultPageSize={pagination.pageSize || page_size}
                        pageSizeOptions={["10", "20", "30"]}
                        defaultCurrent={pagination.current || page}
                        size="small"
                        onChange={(page, page_size) => { this.currentPageChange(page, page_size); }}
                        onShowSizeChange={(page, page_size) => { this.currentPageChange(1, page_size); }}
                    />
                    <div className="cardList">
                        {tableData.map((item, index) => (
                            <Card key={index}
                                bordered={true}
                                cover={this.pickImgUrl(item.reportTags, item.taskName)}
                                hoverable={true}
                                actions={this.buttonAction(item)}
                                onClick={(e) => {
                                    if (e.target.className === "ant-popover-inner-content") {
                                        e.stopPropagation();
                                    } else {
                                        this.props.handleMenuClick({ key: "edit" }, item)
                                    }
                                }}
                            >
                                <Popover
                                    placement="top"
                                    title={<span style={{ fontWeight: "bold", fontSize: "16px", cursor: "pointer", display: "block" }}>{item.taskName}</span>}
                                    trigger="hover"
                                    content={this.popContent(item)}
                                >
                                    <Meta title={item.taskName} />
                                </Popover>
                                <span>任务状态：{this.getTaskStatus(item.taskStatus)}</span><br />
                                <div className="cardBottom">
                                    <span className="taskBeginTime">{item.taskBeginTime || "未执行"}</span>
                                </div>
                            </Card>
                        ))}
                    </div>

                </div>
            )
        }
    }
}