import React from 'react'
import { Card, Table, Form, Avatar, Tabs, Button, message, Popconfirm, Icon, Input, Menu, Row, Col, } from "antd";
import Socket from "../../../socket/index";
import "./userInfo.scss";
const Search = Input.Search;
const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
export default class userInfo extends React.Component {
    state = {
        filteredInfo: null,
        users: {
            userId: sessionStorage.getItem("userId"),
            userMail: "test00@zjft.com",
            userPwd: "admin",
            username: sessionStorage.getItem("userName"),
            nickName: sessionStorage.getItem("nickName")
        },

        visible: false,
        _current: 1,      //当前页码数
        _pageSize: 10,    //每页显示条数
    }
    componentWillMount() {
        this.setState({
            userName: sessionStorage.getItem('userName')
        });
    }
    updata = (user_id) => {
        let { _pageSize, } = this.state;
        Socket.ExecuteAction('QueryUserDetail',
            { _id: user_id }, "/QueryUserDetail", (result) => {
                let users = result.data;
                console.log(users);
                this.setState({ users, })
            }, (error) => { message.error(error) });
    };

    TabCallback = (key) => {
        console.log(key);
    };
    infoPage = () => {
        let { userName, users } = this.state;
        return <div className="userInfo">
            <Row>
                <Col span={6}>
                    <div className="left">
                        {/* <Avatar src="image/top.png" size={128} /> */}
                        <Avatar style={{ backgroundColor: "#1890ff", verticalAlign: 'middle' }} size={128}>
                            {sessionStorage.getItem('nickName')}
                        </Avatar>
                        {/* <div className="userName">{userName}</div> */}
                        {/* <div>海纳百川，有容乃大</div> */}
                    </div>
                </Col>
                <Col span={18}><InfoForm ref="change" users={users} updataFrom={() => { }} /></Col>
            </Row>
        </div>
    };
    render() {
        return (
            <div>
                <Card title="个人信息">
                    <Tabs defaultActiveKey="1" onChange={this.TabCallback}>
                        <TabPane tab="基本信息" key="1">{this.infoPage()}</TabPane>
                        {/* <TabPane tab="更改密码" key="2">Content of Tab Pane 3</TabPane> */}
                    </Tabs>
                </Card>

            </div>
        )
    }
}
class InfoForm extends React.Component {
    state = {
        focusItem: -1,
        isChange: true,
    };
    updataFrom = (e) => {
        e && e.preventDefault();
        const _this = this;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var formValue = _this.props.form.getFieldsValue();
                _this.props.updataFrom({
                    username: formValue.username,
                    password: formValue.password
                });
            }
        });
    };

    // checkUsername = (rule, value, callback) => {
    //     var reg = /^\w+$/;
    //     if (!value) {
    //         callback('请输入用户名!');
    //     } else if (!reg.test(value)) {
    //         callback('用户名只允许输入英文字母与数字');
    //     } else {
    //         callback();
    //     }
    // };

    checkPassword = (rule, value, callback) => {
        if (!value) {
            callback('请输入密码!');
        } else {
            callback();
        }
    };

    //新密码一致校验
    handleCheckPwd(rules, value, callback) {
        let cfmPwd = this.props.form.getFieldValue('cfmloginpass');
        if (cfmPwd && cfmPwd !== value) {
            callback(new Error('两次密码输入不一致'))
        } else {

            // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
            callback();
        }
    }

    //确认密码校验一致
    handleCfmPwd(rules, value, callback) {
        let loginpass = this.props.form.getFieldValue('loginpass');
        if (loginpass && loginpass !== value) {
            callback(new Error('两次密码输入不一致'))
        } else {

            // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
            callback();
        }
    }

    render() {
        let { focusItem, userName, isChange } = this.state;
        const { users } = this.props;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 14 },
            },
        };
        return (

            <Form {...formItemLayout} onSubmit={this.updataFrom}>
                <FormItem label='用户登录名'>
                    {getFieldDecorator('username', {
                        initialValue: users.username,
                        // rules: [{ required: true, message: '用户名称!' }],

                    })(
                        <Input prefix={<Icon type="user" />}
                            // disabled
                            placeholder="用户登录名"
                        />
                    )}
                </FormItem>
                <FormItem label='用户名称'>
                    {getFieldDecorator('nickName', {
                        initialValue: users.nickName,
                        // rules: [{ required: true, message: '用户名称!' }],
                    })(
                        <Input prefix={<Icon type="user" />}
                            // disabled={isChange}
                            placeholder="用户名称"
                        />
                    )}
                </FormItem>

                {/* <FormItem label='用户邮箱'>
                    {getFieldDecorator('email', {
                        initialValue: users.userMail,
                    })(
                        <Input prefix={<Icon type="mail" />} placeholder="用户邮箱"
                        // disabled={isChange}
                        />
                    )}
                </FormItem>
                <FormItem label="旧密码">
                    {getFieldDecorator('oldPassword', {
                        rules: [{ required: true, message: '请输入旧密码!' }],
                    })(<Input prefix={<Icon type="lock" />} placeholder="旧密码" />)}
                </FormItem>
                <FormItem label="新密码">
                    {getFieldDecorator('loginpass', {
                        rules: [{ required: true, message: '请输入新密码!' }, {
                            validator: (rules, value, callback) => { this.handleCheckPwd(rules, value, callback) }
                        }],
                        validateFirst: true
                    })(<Input prefix={<Icon type="lock" />} placeholder="新密码" />)}
                </FormItem>
                <FormItem label="确认密码">
                    {getFieldDecorator('cfmloginpass', {
                        rules: [{ required: true, message: '请再次确认新密码!' }, {
                            validator: (rules, value, callback) => { this.handleCfmPwd(rules, value, callback) }
                        }],
                        validateFirst: true
                    })(<Input prefix={<Icon type="lock" />} placeholder="确认密码" />)}
                </FormItem> */}

                {/*  <FormItem label="">
                     {isChange ? <Button type="primary" icon="rollback" onClick={() => this.setState({ isChange: false })} >
                        修改信息
                    </Button> : <Button type="primary" icon="check-circle" htmlType="submit" >
                            确定修改
                    </Button>} 
                    <Button type="primary" icon="check-circle" htmlType="submit" >
                        修改信息
                    </Button>
                </FormItem>*/}
            </Form >
        )
    }
}

InfoForm = Form.create({})(InfoForm);