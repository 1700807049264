import React, { Component } from 'react'
import { Button, Card, Select, Table } from "antd";
import confirm from "antd/es/modal/confirm";
import { fetchList } from "../../../zx-api/version";

export default class SourceConfigSetting extends Component {

    state = {
        selectedRowKeys: [],
        allLoading: true,
        tableDataPageSize: 5,
        tableData: [],
        rowSelection: [],
        loading: false,
        tableDataCurrent: 1,
        tableDataTotal: 0,
        query: {},
    }

    componentWillMount() {
        this.update()
    }

    //更新数据方法
    update = (query) => {
        let response = {}

        this.setState({
            loading: true,
            selectedRowKeys: []
        })

        query = query || {}

        fetchList(query, res => {
            res = res.data
            const tableData = res.version
            this.setState({ tableData, loading: false, })
            // alert(JSON.stringify(tableData.name))

        })

    }

    // 删除操作
    delete() {
        const { selectedRowKeys } = this.state
        if (selectedRowKeys.length < 2) {

        } else {

        }
    }

    // // 下拉选择框变化
    handleSelectChange = (name, value) => {
        if (name === '设备文件') {
            this.setState({
                query: {
                    equip: value,
                    line: this.state.query.line,
                    config: this.state.query.config,
                }
            })
        } else if (name === '专线文件') {
            this.setState({
                query: {
                    line: value,
                    config: this.state.query.config,
                    equip: this.state.query.equip,
                }
            })
        } else if (name === '配置文件') {
            this.setState({
                query: {
                    config: value,
                    line: this.state.query.line,
                    equip: this.state.query.equip,

                }
            })
        }
    }

    backTo = key => {
        // alert(JSON.stringify(this.state.query));
        let query = this.state.query
        query.put('aaa', 11)

        if (key === 'backTo') {
            alert(JSON.stringify(query))
        } else if (key === 'latest') {

        }
    }

    render() {
        const { tableDataPageSize, tableData, rowSelection, loading, query, } = this.state

        const handleSpinning = this.props.handleSpinning
        const tableDataColumns = [
            {
                title: '类别',
                dataIndex: 'name',
                key: 'name',
                // fixed: 'left',
                width: 150,
            },
            {
                title: '版本',
                dataIndex: 'data',
                key: 'data',
                width: 150,
                render: (text, item, index) => {

                    return <div>
                        {
                            <Select style={{ width: 100 }}
                                showSearch
                                onChange={this.handleSelectChange.bind(this, item.name)}
                                defaultValue={
                                    text.length && text.map((item, index) => {
                                        if (item.is_selected) {
                                            return item.id
                                        }
                                    })
                                }
                            >
                                {
                                    text.length && text.map((item, index) => (
                                        <Select.Option key={index} value={item.id}>{item.id}</Select.Option>)
                                    )
                                }

                            </Select>
                        }
                    </div>
                }
            },

        ]
        return (
            <div>
                <Card title='资源及配置设定'
                    style={{ marginTop: 8, height: 300 }}
                    extra={
                        <div>
                            <Button type='default' icon='redo' onClick={e => {
                                this.update(tableDataPageSize, 1, query)
                            }}>刷新</Button>
                            <Button type='primary' onClick={() => {
                                this.props.handleClick()
                            }}>基本配置</Button>
                            {/*<Button type='default' style={{color: "#fff", backgroundColor: "green"}} onClick={this.addObj}>新建</Button>*/}
                            <Button type='danger' icon='' onClick={
                                e => {
                                    const _self = this
                                    confirm({
                                        title: '您确认回退吗?',
                                        content: `您确认要回退到上一个版本吗？`,
                                        okText: '回退',
                                        okType: 'danger',
                                        cancelText: '取消',
                                        onOk() {
                                            _self.backTo('backTo')
                                        },
                                        onCancel() {

                                        },
                                    });
                                }
                            } style={{}}>
                                回退
                              </Button>
                            <Button style={{ backgroundColor: "green", color: "white" }} onClick={
                                e => {
                                    const _self = this
                                    confirm({
                                        title: '您确认要恢复吗？?',
                                        content: `您确认要恢复到最新版本吗？`,
                                        okText: '恢复',
                                        okType: 'warning',
                                        cancelText: '取消',
                                        onOk() {
                                            _self.backTo('latest')
                                        },
                                        onCancel() {

                                        },
                                    });
                                }
                            }>恢复</Button>

                        </div>
                    }
                >
                    <Table
                        dataSource={tableData}
                        columns={tableDataColumns}
                        loading={loading}
                        size="small"
                        pagination={false}
                    />
                </Card>
            </div>
        );
    }
}