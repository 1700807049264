import React from 'react'
import { Button, Card, Col, Icon, Input, message, Row, Table, Tag, Upload, Collapse } from "antd";
import Axios from "../../axios/axios";
import CustomBreadcrumb from '../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import Utils from "../../utils/utils";
import moment from 'moment';

class IpRecycleRecord extends React.Component {

    state = {
        _current: 1,
        _pageSize: 20,
        total: 0,
        loading: false,
        tableData: null,
        query: null,
    }

    componentWillMount() {
        this.update();
    }

    /* 用户方法 */
    update = (page, page_size, querys) => {
        this.setState({ loading: true })
        let { _pageSize, _current, query } = this.state
        let bodyJson = {
            page: page || _current,
            page_size: page_size || _pageSize,
            user_id: sessionStorage.getItem("userId") || '',
            querys: querys || query
        };
        let queryStr = 'ip/ipRecycleRecord/?'
        queryStr += bodyJson.page ? '&page=' + bodyJson.page : '';
        queryStr += bodyJson.user_id ? '&user_id=' + bodyJson.user_id : '';
        queryStr += bodyJson.page_size ? '&page_size=' + bodyJson.page_size : '&page_size=10';
        for (let queryItem in bodyJson.querys) {
            queryStr += "&" + queryItem + "=" + bodyJson.querys[queryItem];
        }

        Axios.GET(queryStr, {}, (res) => {
            if (res.status === 200) {
                this.setState({
                    tableData: res.data.results,
                    total: res.data.count,
                    _current: page || 1,
                    loading: false,
                    query: querys || {},
                })
            } else {
                message.error("查询失败");
                this.setState({ loading: false })
            }
            // console.log(res)
        })

    }
    // 时间格式化
    dealTime(time) {
        let formatTime = "";
        if (time) {
            let timeArr = time.split("T");
            let timeMinutes = timeArr[1].split(".")[0];
            formatTime = [timeArr[0], timeMinutes].join(" ");
        }
        return formatTime
    }
    render() {
        let { _current, _pageSize, total, loading, tableData, query } = this.state

        const columns = [
            {
                title: '序号',
                width: 50,
                dataIndex: 'key',
                render: (text, item, index) => {
                    return <span>{index + 1 + (_current - 1) * _pageSize}</span>
                }
            },
            {
                title: 'IP',
                align: 'center',
                dataIndex: 'ip',
                width: 110,
                ellipsis: true,
            },
            {
                title: '名称',
                align: 'center',
                dataIndex: 'name',
                width: 90,
                ellipsis: true,
            },
            {
                title: 'mac地址',
                align: 'center',
                dataIndex: 'mac_address',
                width: 130,
                ellipsis: true,
            },
            {
                title: '最近在线时间',
                align: 'center',
                dataIndex: 'furthest_offline_time',
                width: 150,
                ellipsis: true,
                render: (text) => { return text ? (<span>{this.dealTime(text)}</span>) : (<span></span>) }
            },
            {
                title: '最后一次ping结果',
                align: 'center',
                dataIndex: 'last_ping',
                width: 150,
                ellipsis: true,
                render: (text) => {
                    switch (text) {
                        case false:
                            return (<Tag color={Utils.ColorShow("fail")}>失败</Tag>)
                        case true:
                            return (<Tag color={Utils.ColorShow("success")}>成功</Tag>)
                        default:
                            break
                    }
                }
            },
            {
                title: '最后一次ping时间',
                align: 'center',
                dataIndex: 'last_check_time',
                width: 150,
                ellipsis: true,
                render: (text) => { return text ? (<span>{this.dealTime(text)}</span>) : (<span></span>) }
            },
            {
                title: '离线天数',
                align: 'center',
                dataIndex: 'offline_days',
                width: 90,
                ellipsis: true,
            },
            {
                title: '回收状态',
                align: 'center',
                dataIndex: 'recycle_status',
                width: 100,
                ellipsis: true,
            },
            {
                title: '描述',
                align: 'center',
                dataIndex: 'comment',
                width: 200,
                ellipsis: true,
            },
            {
                title: '回收时间',
                align: 'center',
                dataIndex: 'create_time',
                width: 150,
                ellipsis: true,
                render: (text) => { return text ? (<span>{this.dealTime(text)}</span>) : (<span></span>) }
            },
            {
                title: '操作人',
                align: 'center',
                dataIndex: 'operator',
                width: 100,
                ellipsis: true,
            },
        ]

        let scrollX = 0;
        columns.map((item, index) => {
            scrollX += item.width;
        })
        scrollX = scrollX + 260;

        return (
            <div>
                <CustomBreadcrumb arr={[{ title: '首页', to: '/home' }, 'IP管理', 'IP回收记录']} />
                <div className="searchDiv">
                    <Collapse defaultActiveKey={"key"}>
                        <Collapse.Panel header="筛选条件" key={"key"}>
                            <FilterForm tagClass="ipRecycleRecord"
                                filterSubmit={(querys) => {
                                    querys.furthest_offline_time ? querys.fo_start_time = moment(querys.furthest_offline_time[0]).format("YYYY-MM-DD HH:mm:ss") : "";
                                    querys.furthest_offline_time ? querys.fo_end_time = moment(querys.furthest_offline_time[1]).format("YYYY-MM-DD HH:mm:ss") : "";
                                    querys.create_time ? querys.start_time = moment(querys.create_time[0]).format("YYYY-MM-DD HH:mm:ss") : "";
                                    querys.create_time ? querys.end_time = moment(querys.create_time[1]).format("YYYY-MM-DD HH:mm:ss") : "";
                                    delete querys.furthest_offline_time; // 删除 querys 中的 furthest_offline_time
                                    delete querys.create_time; // 删除 querys 中的 create_time
                                    this.update(1, _pageSize, querys)
                                }}
                                resetTab={() => {
                                    this.update(1, _pageSize, {})
                                }}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </div>
                <Card title={<span style={{ fontWeight: 600 }}>IP回收记录</span>} style={{ marginTop: 8 }} >
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        loading={loading}
                        size="small"
                        rowKey={(text) => { return text.id }}
                        scroll={{ x: scrollX }}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.setState({ _pageSize: size })
                                this.update(current, size, query)
                            },
                            onChange: (page, pageSize) => {
                                this.update(page, pageSize, query)
                            },
                        }}
                    />
                </Card>
            </div>
        );
    }

}

export default IpRecycleRecord;

