/**
 * Created by YIMINE on 2019/1/24.
 */
import React from 'react'
import { Card, Form, Input, Modal, Tabs, Select, Button, message, notification, Icon, Row, Col, Tree, TreeSelect } from 'antd';
const { Option } = Select;
const TabPane = Tabs.TabPane;
const TextArea = Input.TextArea;
const Search = Input.Search;
const { TreeNode } = Tree;
import Socket from '../../../socket/index'
import Utils from '../../../utils/utils'
import BaseForm from "@/components/BaseForm/index";
import AreaTree from "./areaTree";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import "./userInfo.scss";
class newUser extends React.Component {
    state = {
        RadioValue: 1,
        visible: false,
        cardTitle: "新建用户",
        visible2: false,
        Tags: [],
        userParams: {
            userTel: "10086",
            userMail: "test@zjft.com",
            department: "网络",
            userPwd: "admin"
        },
        User: {},
        Roles: [],
        expandedKeys: [],
        selectRole: [],
        searchValue: '',
        autoExpandParent: false,
    };

    componentWillMount() {
        let thisTemp = this;
        let Id = this.props.match.params.Id;
        // console.log(Id);
        if (Id) {
            Id = Id.replace(":", "");
            Socket.QueryData('QueryUserDetail', { _id: Id }, (resp) => {
                if (resp.retcode === '00') {
                    let User = resp.user;
                    // let userParams = User.userParams;
                    let selectRole = User.role.map((item) => { return item._id })
                    User.password = Utils.Base64ToStr(User.password);
                    this.setState({ User, selectRole, cardTitle: "编辑用户" });
                } else {
                    message.error('数据返回错误:' + JSON.stringify(resp))
                }
            }, "/queryUser");

        }
        this.updataTags();
        this.updataRoles();
    }
    updataRoles = () => {
        let bodyJson = {};
        bodyJson.pageSize = 10000;
        bodyJson.pageNum = 1;
        bodyJson.querys = {};
        Socket.QueryDataByPage('GetUsableRoles',
            bodyJson, (result) => {
                if (result.retcode === '00') {
                    let Roles = result.roles;
                    Roles.map((item, index) => {
                        item.key = index;
                        item.index = index;
                        return item.id
                    });
                    this.setState({ Roles, })
                }
            }, "/queryRole");
    };
    updataTags = () => {
        Socket.QueryData('QueryTagsGroups', { tagClass: 'user' }, (result) => {
            if (result.retcode === '00') {
                this.setState({
                    Tags: result.tags,
                });
            }
        });
    };
    handleSubmit = (e) => {
        let { User } = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                let body = values;
                // body.userName = values.userName;
                // body.userDes = values.userDes;
                // body.roles = values.roles;
                // body.tags = values.tags;
                // body.userId = values.userId;
                // let userParams = {};
                // userParams.userTel = values.userTel;
                // userParams.userMail = values.userMail;
                // userParams.department = values.department;
                body.password = Utils.StrToBase64(values.password);
                // body.userParams = userParams;

                console.log('body', body);
                if (Utils.isEmptyObject(User)) {//新增
                    Socket.ExecuteAction("AddUser", body, "", (resp) => {
                        message.success('新增成功');
                        this.props.history.goBack();
                    }, (resp) => {
                        notification.open({
                            message: '新增失败',
                            description: resp.error,
                            icon: <Icon type="frown" style={{ color: 'red' }} />,
                        });
                    });
                } else {//修改
                    body._id = User._id;
                    Socket.Action("UpdateUser", body, (resp) => {
                        if (resp.retcode === '00') {
                            message.success('修改成功');
                            this.props.history.goBack();
                        } else {
                            notification.open({
                                message: '修改失败',
                                description: resp.error,
                                icon: <Icon type="frown" style={{ color: 'red' }} />,
                            });
                        }
                    }, "/editUser");
                }
            }
        });
    };
    onChangeRadio = (e) => {
        console.log('radio checked', e.target.value);
        this.setState({
            value: e.target.value,
        });
    };
    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };
    TabCallback = (key) => {
        console.log(key);
    };
    createTab = () => {
        let userParams = this.state.userParams;
        let label = [];
        for (let i in userParams) {
            label.push(i);
        }
        return <table className="resoParamsTab"><tbody>
            <tr><th colSpan={2}>用户基本信息</th></tr>
            {label.map((item, key) => {
                return <tr key={key}><td>{Utils.Translation(item)}</td><td><Input id={item + 'flag'} defaultValue={userParams[item]} /></td></tr>;
            })}
        </tbody></table>
    };
    checkTag = (Arr) => {
        console.log(Arr);
        Arr.map((text) => {
            if (this.state.Tags.indexOf(text) === -1) {
                Socket.AddData('AddTag', { tagClass: 'user', tagName: text },
                    (result) => {
                        if (result.retcode === '00') {
                            message.success('标签新增成功');
                        } else {
                            message.success('标签新增失败');
                        }
                    }
                );
            }
            return text.id
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { expandedKeys, searchValue, autoExpandParent, } = this.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const { visible, done, Tags, Roles, cardTitle, User, selectRole } = this.state;
        const formList = [
            {
                label: "用户名称",
                type: 'INPUT',
                field: "nickName",
                initialValue: User.nickName,
                rules: [{
                    type: 'string', message: '请输入正确字符！',
                }, {
                    pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                }, {
                    required: true, message: '请输入字符!',
                }],
            },
            {
                label: "用户登录名",
                type: 'INPUT',
                field: "username",
                initialValue: User.username,
                rules: [{
                    type: 'string', message: '请输入正确字符！',
                }, {
                    pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                }, {
                    required: true, message: '请输入字符!',
                }],
            },
            {
                label: "用户密码",
                type: 'INPUT',
                field: "password",
                initialValue: User.password,
                inputType: "password",
                rules: [{
                    type: 'string', message: '请输入正确字符！',
                }, {
                    pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                }, {
                    required: true, message: '请输入字符!',
                }],
            },
        ];
        const loop = data =>
            data.map(item => {
                const title = item.title;
                if (item.children) {
                    return (
                        <TreeNode icon={<Icon type={item.icon || "file"} />} key={item.key} title={title} display={item.display}
                            titleName={item.title}
                            interface={item.interface}
                            iconName={item.icon}>
                            {loop(item.children)}
                        </TreeNode>
                    );
                }
                return <TreeNode icon={<Icon type={item.icon || "file"} />} key={item.key} title={title} display={item.display}
                    titleName={item.title}
                    interface={item.interface}
                    iconName={item.icon} />;
            });
        return (
            <div className="newUserDiv">
                <CustomBreadcrumb arr={["系统管理", { title: "用户管理", to: '/user' }, cardTitle]} />
                <Card title={<span style={{ fontWeight: 600 }}>{cardTitle}</span>}>


                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="用户名称"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('nickName', {
                                        initialValue: User.nickName || "",
                                        rules: [{
                                            type: 'string', message: '请输入正确字符！',
                                        }, {
                                            pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入特殊字符！',
                                        }, {
                                            required: true, message: '请输入字符!',
                                        }],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item
                                    label="用户登录名"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('username', {
                                        initialValue: User.username || "",
                                        rules: [{
                                            required: true, message: '请输入字符!',
                                        }, {
                                            pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入特殊字符！',
                                        },],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item
                                    label="用户密码"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('password', {
                                        initialValue: User.password || "",
                                        rules: [{
                                            required: true, message: '不能为空!',
                                        }],
                                    })(
                                        <Input.Password />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="绑定角色"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('roleId', {
                                        initialValue: selectRole || "",
                                        rules: [{
                                            required: true, message: '请绑定角色!',
                                        }],
                                    })(
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            placeholder="绑定角色"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {Roles.map((item, index) => {
                                                return <Option value={item._id} key={index}>{item.roleName}</Option>
                                            })}

                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="邮箱"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('mail', {
                                        initialValue: User.mail || "",
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="电话号码"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('phone', {
                                        initialValue: User.phone || "",
                                        rules: [{
                                            pattern: new RegExp(/^((\+86))\d{11}$/, "g"),
                                            message: '请输入正确格式的号码!'
                                        }]
                                    })(
                                        <Input placeholder="带区号电话,中国大陆+86" />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="用户描述"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('userDesc', {
                                        initialValue: User.userDesc || "",
                                    })(<TextArea />)}
                                </Form.Item>
                                <Form.Item
                                    label=" "
                                    colon={false}
                                    {...formItemLayout}
                                >
                                    <div className="btnDiv">
                                        <Button type="primary" icon="check-circle" htmlType="submit" style={{ width: 140 }}>
                                            保存
                                    </Button>
                                        <Button type="danger" icon="close-circle" style={{ width: 140, marginLeft: 5 }}
                                            onClick={() => { this.props.history.goBack(); }}>
                                            取消
                                    </Button>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="管理设备区域"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('areaIds', {
                                        initialValue: User.areaIds || [],
                                        rules: [{
                                            required: true, message: '请绑定区域!',
                                        }],
                                    })(<AreaTree
                                    // onChange={(ruleData) => { console.log(ruleData) }}
                                    />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {/* <Col span={4}>
                            <h1>对应权限菜单：</h1>
                            <Tree
                                showLine
                                showIcon
                                blockNode
                            // expandedKeys={expandedKeys}
                            // autoExpandParent={autoExpandParent}
                            >
                                {loop(TreeData)}
                            </Tree>
                        </Col> */}

                    <Modal
                        title="报告类型"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        okText="确认"
                        cancelText="取消"
                    >
                        <Tabs defaultActiveKey="1"
                            tabPosition="left"
                            onChange={this.TabCallback}>
                            <TabPane tab="通知策略" key="1">Content of Tab Pane 1</TabPane>
                            <TabPane tab="报告模板" key="2">Content of Tab Pane 2</TabPane>
                            <TabPane tab="邮件通知" key="3">

                            </TabPane>
                            <TabPane tab="指标设置" key="4">Content of Tab Pane 3</TabPane>
                        </Tabs>
                    </Modal>

                </Card>
            </div >
        );
    }
}

const newAutoObjectFrom = Form.create()(newUser);

export default newAutoObjectFrom;

