import React, { useEffect, useState } from 'react'
import Charts from "echarts-for-react"
function PieMap(props) {
    {/* <PieChar data={[{ name: "成功", value: 40 }, { name: "失败", value: 10 }, { name: "异常", value: 4 }]} /> */ }
    // const [data1, setData1] = useState(props.data || []);
    // useEffect(() => {//componentDidMount 
    //     return () => { //componentWillUnmount
    //     }
    // }, []);
    function getOptionn(data) {
        return {
            // backgroundColor: '#2c343c',
            // title: {
            //     // text: 'Customized Pie',
            //     left: 'center',
            //     top: 20,
            //     textStyle: {
            //         color: '#ccc'
            //     }
            // },
            tooltip: {
                trigger: 'item'
            },
            visualMap: {
                show: false,
                min: 80,
                max: 600,
                inRange: {
                    colorLightness: [0, 1]
                }
            },
            series: [
                {
                    // name: 'Access From',
                    type: 'pie',
                    // radius: ['40%', '70%'],
                    radius: '55%',
                    center: ['50%', '50%'],
                    data: [
                        { value: 335, name: '警告' },
                        { value: 4, name: '严重' },
                        { value: 2, name: '灾难' },
                        { value: 300, name: '信息' },
                    ].sort(function (a, b) {
                        return a.value - b.value;
                    }),
                    roseType: 'radius',
                    label: {
                        color: 'rgba(255, 255, 255, 0.3)'
                    },
                    labelLine: {
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.3)'
                        },
                        smooth: 0.2,
                        length: 10,
                        length2: 20
                    },
                    color: ["#6be6c1", "#3fb1e3", "#a0a7e6", '#1890ff', "#ff5338", "#ff5384", "#4456c7", "#ff80a0", '#5ec9db', '#fdc765', '#f27d51', '#6462cc', '#91c7ae', '#749f83', '#f26659', "#c4ebad",],

                    // itemStyle: {
                    //     // color: '#c23531',
                    //     shadowBlur: 200,
                    //     shadowColor: 'rgba(0, 0, 0, 0.5)',
                    //     normal: {
                    //         //这里是重点
                    //         color: function (params) {
                    //             //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                    //             // var colorList = ["#3fb1e3", "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8", '#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622'];
                    //             var colorList = ["#31e3cb", "#a0a7e6", "#96dee8", '#1890ff', "#ff5338", "#ff5384", "#4456c7", "#ff80a0", '#5ec9db', '#fdc765', '#f27d51', '#6462cc', '#91c7ae', '#749f83', '#f26659', "#c4ebad",];
                    //             return colorList[params.dataIndex]
                    //             // return "#1890ff"
                    //         },

                    //     }
                    // },
                    animationType: 'scale',
                    animationEasing: 'elasticOut',
                    animationDelay: function (idx) {
                        return Math.random() * 200;
                    }
                }
            ]
        };
    }
    return <Charts option={getOptionn(props.data || [])} />
}
export default React.memo(PieMap)
