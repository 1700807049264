import React from 'react'
import {
    Card, Table, Input, Button, Icon, message, notification, Modal, Menu, Dropdown, Steps, Form,
    Row, Col, Select, Tabs, Badge, Popconfirm, Typography, Spin, Collapse, Tooltip, Tag, Popover
} from "antd";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import http from '@/axios/axios_utils';
import Axios from 'axios'
import moment from 'moment';

const hub_Url = "http://" + backup_address + "/ZJNMS_V1/hub/";


export default class MacAddressList extends React.Component {
    state = {
        current: 1,      //当前页码数
        page_size: 20,    //每页显示条数
        querys: {},    //查询条件
        total: 0,
        loading: false,
        tableData: [],
        selectedRowKeys: [],
    }
    componentWillMount() {
        this.getData()
    }
    getData(pageNum, pageSize, querys2) {
        this.setState({ loading: true })
        const { current, page_size, querys } = this.state
        let query = querys2 || querys;
        let bodyJson = {
            page_size: pageSize || page_size,
            page_no: pageNum || current,
            user_id: sessionStorage.getItem("userId") || '',
            ...query
        };
        // console.log(bodyJson)
        http.get(hub_Url + "macAddress", bodyJson, (res) => {
            if (res.code === "00") {
                this.setState({
                    tableData: res.data,
                    total: res.count,
                    current: pageNum || 1,
                    loading: false,
                    querys: querys2
                })
            } else {
                message.error("查询失败");
                this.setState({ loading: false })
            }
        })
    };
    dealTime(time) {
        let month = time.getMonth() + 1;
        let day = time.getDate();
        let year = time.getFullYear();
        let hours = time.getHours();
        let minutes = time.getMinutes();
        let seconds = time.getSeconds();
        return this.tenNumber(year) + this.tenNumber(month) + this.tenNumber(day) + this.tenNumber(hours) + this.tenNumber(minutes) + this.tenNumber(seconds)
    }
    tenNumber(num) {
        let tenNum = num
        if (tenNum >= 0 && tenNum < 10) {
            tenNum = "0" + tenNum
        } else {
            tenNum = tenNum
        }
        return tenNum;
    }
    handleMenuClick(e) {
        switch (e.key) {
            case 'is_all': this.exportExcel("is_all"); break;
            case 'selected': this.exportExcel(); break;
        }
    }
    exportExcel(allFlag) {
        const { selectedRowKeys } = this.state;
        if (!allFlag) {
            if (selectedRowKeys.length === 0) {
                return message.error("请勾选需要导出的mac地址！")
            }
        }
        let bodyJson = {
            is_all: allFlag ? true : false,
            _ids: selectedRowKeys
        }
        Axios({
            method: "POST",
            url: hub_Url + "exportMacAddress",
            data: bodyJson,
            responseType: 'blob'
        }).then((result) => {
            const link = document.createElement('a');
            let blob = new Blob([result.data], { type: "application/vnd.ms-excel" }); // 转化为 blob 对象 导出.xls文件
            // let blob = new Blob([result.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }); // 转化为 blob 对象  导出.xlsx文件
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = "MAC地址表-" + this.dealTime(new Date()); // 文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }

    render() {
        let { tableData, current, page_size, querys, total, loading, selectedRowKeys } = this.state;
        let columns = [
            {
                title: '序号',
                width: 50,
                dataIndex: 'key',
                render: (text, item, index) => {
                    return <span>{index + 1 + (current - 1) * page_size}</span>
                }
            },
            {
                title: 'mac地址',
                align: 'center',
                dataIndex: 'mac_address',
            },
            {
                title: 'ip',
                align: 'center',
                dataIndex: 'ip',
            },
            {
                title: '接入端口名称',
                align: 'center',
                dataIndex: 'port',
            },
            {
                title: '端口工作模式',
                align: 'center',
                dataIndex: 'admin_mode',
            },
            {
                title: '交换机IP',
                align: 'center',
                dataIndex: 'resource_ip',
                render: (text, item, index) => {
                    if(item.net_device_simple_info && JSON.stringify(item.net_device_simple_info) !== '{}') {
                        return (
                            <Popover title="网络设备信息" content={<div style={{ margin: '-8px -5px' }}>
                                <div style={{ margin: '8px 5px' }}>设备名称：{item.net_device_simple_info.device_name}</div>
                                <div style={{ margin: '8px 5px' }}>设备IP：{item.net_device_simple_info.device_ip}</div>
                                <div style={{ margin: '8px 5px', display: 'flex' }}>设备厂商：{Utils.Translation(item.net_device_simple_info.device_firm)}</div>
                                <div style={{ margin: '8px 5px' }}>设备状态：{Utils.Translation(item.net_device_simple_info.device_state)}</div>
                                <div style={{ margin: '8px 5px' }}>网点信息：{item.net_device_simple_info.device_site}</div>
                                <div style={{ margin: '8px 5px', display: 'flex' }}>设备类型：{Utils.Translation(item.net_device_simple_info.device_type)}</div>
                                <div style={{ margin: '8px 5px' }}>设备型号：{item.net_device_simple_info.device_model}</div>
                            </div>}>
                                <span>{text}</span>
                            </Popover>
                        )
                    } else {
                        return text
                    }
                }
            },
            {
                title: '最近接入时间',
                align: 'center',
                dataIndex: 'create_time',
            },
        ];
        const formList = [
            {
                label: 'mac地址',
                type: 'INPUT',
                field: 'mac_address',
                initialValue: '',
                placeholder: 'mac地址'
            },
            {
                label: 'ip',
                type: 'INPUT',
                field: 'ip',
                initialValue: '',
                placeholder: 'ip'
            },
            {
                label: '接入端口名称',
                type: 'INPUT',
                field: 'port_name',
                initialValue: '',
                placeholder: '接入端口名称'
            },
            {
                label: '接入交换机IP',
                type: 'INPUT',
                field: 'resource_ip',
                initialValue: '',
                placeholder: '接入交换机IP'
            },
        ]
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };

        return <div>
            <CustomBreadcrumb arr={["运维管理", "MAC地址管理", { title: "mac地址列表", to: "/Ledger/macAddressList" }]} />
            <div className="searchDiv">
                <Collapse defaultActiveKey={"key"}>
                    <Collapse.Panel header="筛选条件" key={"key"}>
                        <FilterForm formList={formList}
                            filterSubmit={(querys) => {
                                this.getData(1, page_size, querys);
                            }}
                            resetTab={() => {
                                this.getData(1, page_size, {})
                            }}
                        />
                    </Collapse.Panel>
                </Collapse>
            </div>
            <Card
                style={{ marginTop: 8 }}
                title={<div style={{ fontWeight: 600 }}>MAC地址列表</div>}
                extra={
                    // <Button type="primary" icon="export" onClick={() => this.exportExcel()}>导出mac地址表</Button>
                    <Dropdown className="dropDownBtn" style={{ marginRight: 8 }}
                        overlay={
                            <Menu onClick={(e) => { this.handleMenuClick(e) }}>
                                <Menu.Item  key="selected"> <Icon type="export" />导出选中 </Menu.Item>
                                <Menu.Item  key="is_all"> <Icon type="export" />导出全部 </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button type="primary">
                            <Icon type="export" />导出 <Icon type="down" />
                        </Button>
                    </Dropdown>
                }
            >
                <Table
                    columns={columns}
                    dataSource={tableData}
                    size="small"
                    loading={loading}
                    rowKey={(text) => { return text._id }}
                    rowSelection={rowSelection}
                    pagination={{
                        current: current, pageSize: page_size, total: total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            this.setState({ page_size: size })
                            this.getData(1, size, querys)
                        },
                        onChange: (page, pageSize) => { this.getData(page, pageSize, querys); }
                    }}
                />
            </Card>
        </div>
    }
}