import React from 'react'
import { Card, Table, Button, message, Popconfirm, Icon, Input, Modal, Form } from "antd";
import Socket from "../../../socket/index";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import Utils from '@/utils/utils';

class APICLASS extends React.Component {
    state = {
        modalTitle: "新建",
        apiVisible: false,
        _current: 1,      //当前页码数
        _pageSize: 10,    //每页显示条数
        loading: false,
        selectOne: {}
    };
    componentWillMount() {
        this.updata();
    }
    updata = (pageSize, pageNum, querys) => {
        let { _pageSize, _current } = this.state;
        this.setState({ loading: true, });
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        Socket.ExecuteAction('QueryAPIsPage',
            bodyJson, "/queryUser", (result) => {
                console.log(result);
                let data = result.data;
                data.map((item, index) => { item.key = index; return item.id });
                this.setState({
                    tableData: data,
                    _current: pageNum || 1,
                    loading: false,
                    total: result.total,
                })
            });
    };

    delete = (_id) => {
        Socket.DeleteData('QueryAPIsPage', { _id }, () => {
            this.setState({ apiVisible: false })
        }, "/QueryAPIsPage");
    };
    commitInfo() {
        const { modalTitle } = this.state;
        const _this = this;
        _this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                if (modalTitle === "新建接口") {
                    Socket.AddData('addApi', values, () => {
                        _this.setState({ apiVisible: false })
                    });
                } else {
                    Socket.UpData('upApi', values, () => {
                        _this.setState({ apiVisible: false })
                    });
                }

            }
        });

    };

    render() {
        let { _current, _pageSize, loading, modalTitle, apiVisible, selectOne } = this.state;
        const { getFieldDecorator } = this.props.form;
        const columns = [
            {
                title: '序号',
                align: 'center',
                render: (text, item, key) => { return <span>{key + 1}</span> }
            }, {
                title: '接口名称',
                align: 'center',
                dataIndex: 'name'
            }, {
                title: '请求接口名',
                align: 'center',
                dataIndex: 'apiName',

            }, {
                title: '创建时间',
                align: 'center',
                dataIndex: 'createTime'
            }, {
                title: '操作',
                width: '270px',
                align: 'center',
                render: (text, item) => {
                    return <div>
                        <Button type="primary" onClick={() => {
                            this.setState({ modalTitle: "编辑接口", selectOne: item, apiVisible: true, });
                        }}>
                            <Icon type="form" />编辑
                    </Button>
                        <Popconfirm
                            title="确定删除吗?"
                            onConfirm={() => this.delete(item._id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger"
                                style={{ marginLeft: 8 }}
                                icon='delete'>
                                删除
                            </Button>
                        </Popconfirm>
                    </div>
                }
            }];
        const formList = [
            {
                label: "接口名称",
                type: 'INPUT',
                field: "name",
                initialValue: '',
                placeholder: "接口名称"
            },
            {
                label: "请求接口名",
                type: 'INPUT',
                field: "apiName",
                initialValue: '',
                placeholder: "请求接口名"
            },
        ];
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };
        return (
            <div>
                <CustomBreadcrumb arr={["权限管理", "接口列表"]} />
                <Card>
                    <FilterForm
                        formList={formList}
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(this.state._pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(this.state._pageSize, 1, {})
                        }}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>接口列表</span>}
                    style={{ marginTop: 8 }}
                    extra={
                        <Button type="primary" icon="edit" style={{ marginLeft: 8 }} onClick={() => { this.setState({ modalTitle: "新建接口", apiVisible: true }); }}>
                            新建接口
                    </Button>}>
                    <Table
                        dataSource={this.state.tableData}
                        columns={columns}
                        size="small"
                        loading={loading}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                console.log(page, pageSize);
                                this.updata(pageSize, page, {});
                            }
                        }}
                    />

                </Card>
                <Modal
                    title={modalTitle}
                    visible={apiVisible}
                    onOk={() => { this.commitInfo(); }}
                    onCancel={() => { this.setState({ apiVisible: false }) }}
                    okText="确认"
                    cancelText="取消"
                    destroyOnClose
                    afterClose={() => {
                        this.setState({ selectOne: {} })
                    }}
                >
                    <Form   {...formItemLayout}>
                        <Form.Item
                            label="接口名称"
                        >
                            {getFieldDecorator('name', {
                                initialValue: selectOne.name || "",
                                rules: [{
                                    required: true, message: '请输入字符!',
                                }],
                                placeholder: "接口名称"
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label="请求接口名"
                        >
                            {getFieldDecorator('apiName', {
                                initialValue: selectOne.apiName || "",
                                rules: [{
                                    required: true, message: '请输入字符!',
                                }],
                                placeholder: "请求接口名"
                            })(<Input />)}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}
const APICLASSObjectFrom = Form.create()(APICLASS);
export default APICLASSObjectFrom