import React, { memo } from 'react';
import { Row, Col, Card, Tabs, DatePicker } from 'antd';
import './index.scss';
import { Bar } from 'ant-design-pro/lib/Charts';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const rankingListData1 = [];
for (let i = 0; i < 7; i += 1) {
    rankingListData1.push({
        type: "设备故障" + i,
        total: 323234,
    });
}

const AlertBar = memo(
    ({ rangePickerValue, salesData, isActive, handleRangePickerChange, loading, selectDate, rankingListData = rankingListData1 }) => (
        <Card loading={loading} bordered={false} bodyStyle={{ padding: 0 }}>
            <div className={"salesCard"}>
                <Tabs
                    tabBarExtraContent={
                        <div className={"salesExtraWrap"}>
                            <div className={"salesExtra"}>
                                {/* <a className={isActive('today')} onClick={() => selectDate('today')}>
                                    今日
                                </a>
                                <a className={isActive('week')} onClick={() => selectDate('week')}>
                                    本周
                                </a>
                                <a className={isActive('month')} onClick={() => selectDate('month')}>
                                    本月
                                </a>
                                <a className={isActive('year')} onClick={() => selectDate('year')}>
                                    全年
                                </a> */}
                                <a className={isActive('today')} onClick={() => selectDate('today')}>
                                    过去24小时
                                </a>
                                <a className={isActive('week')} onClick={() => selectDate('week')}>
                                    最近一周
                                </a>
                                <a className={isActive('month')} onClick={() => selectDate('month')}>
                                    最近一月
                                </a>
                                <a className={isActive('HalfYear')} onClick={() => selectDate('HalfYear')}>
                                    最近半年
                                </a>
                                <a className={isActive('year')} onClick={() => selectDate('year')}>
                                    最近一年
                                </a>
                            </div>
                            <RangePicker
                                showTime
                                value={rangePickerValue}
                                onChange={handleRangePickerChange}
                                style={{ width: 350 }}
                            />
                        </div>
                    }
                    size="large"
                    tabBarStyle={{ marginBottom: 24 }}
                >
                    <TabPane
                        tab={"告警额"}
                        key="sales"
                    >
                        <Row>
                            <Col xl={24} lg={12} md={12} sm={24} xs={24}>
                                <div className={"salesBar"}>
                                    <Bar
                                        height={295}
                                        title={"告警趋势"}
                                        data={salesData}
                                    />
                                </div>
                            </Col>
                            {/* <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                                <div className={"salesRank"}>
                                    <h4 className={"rankingTitle"}>告警分类排名</h4>
                                    <ul className={"rankingList"}>
                                        {rankingListData.map((item, i) => (
                                            <li key={item.type}>
                                                <span
                                                    className={`${"rankingItemNumber"} ${i < 3 ? "active" : ''}`}
                                                >
                                                    {i + 1}
                                                </span>
                                                <span className={"rankingItemTitle"} title={item.type}>
                                                    {item.type}
                                                </span>
                                                <span className={"rankingItemValue"}>
                                                    {item.total}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Col> */}
                        </Row>
                    </TabPane>
                </Tabs>
            </div>
        </Card>
    )
);

export default AlertBar;
