import React from "react";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import { Button, Card, Col, Modal, Row, Table, Upload } from "antd";
import { formList } from "./formList";
import confirm from "antd/es/modal/confirm";
import FilterForm from '@/components/FilterForm/index';
import { fetchList, } from "../../zx-api/operator";
import { batchDelete, deleteObj, exportProvider } from "../../zx-api/operator";
import moment from "moment";
import { exportExcel } from "../../zx-utils/util";
import { zx_baseUrl } from "../../zx-config";

export default class Operator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tableData: [],//表格数据
            loading: false, //加载数据后遮罩层
            tableDataTotal: 0,
            tableDataCurrent: 1,
            tableDataPageSize: 15,
            selectedRowKeys: [],
            importFileVisible: false,
            importFileLoading: false,
        }
    }

    componentWillMount() {
        const { tableDataPageSize, tableDataCurrent } = this.state
        this.update(tableDataPageSize, tableDataCurrent);
    }

    tableDataDetail(id) {

    }

    //更新数据方法
    update = (tableDataPageSize, tableDataPageNum, query) => {
        let response = {}
        this.setState({
            loading: true,
            selectedRowKeys: []
        })
        query = query || {}
        query['page'] = tableDataPageNum
        query['size'] = tableDataPageSize
        fetchList(query, res => {
            response = res.data
            const tableData = response.results
            const tableDataTotal = response.count
            this.setState({ tableData, tableDataTotal, loading: false, tableDataCurrent: tableDataPageNum })
        })
    }

    // 删除操作
    delete() {
        const { selectedRowKeys } = this.state
        if (selectedRowKeys.length < 2) {
            deleteObj(selectedRowKeys, res => {
                this.update(this.state.tableDataPageSize, 1, {})
            })
        } else {
            batchDelete(selectedRowKeys, res => {
                if (res != false) {
                    this.update(this.state.tableDataPageSize, 1, {})
                }
            })
        }
    }


    exportExcel = e => {
        exportProvider(0, 0, res => {
            let data = res.data.data.body
            // alert(JSON.stringify(res.data))
            let json = data.map(item => {
                // let index=0
                // for (index; index < item.length; index++) {
                //     alert(item[0])
                //     if (item[index] === null) {
                //         item[index] = ''
                //     }
                // }
                for (let key in item) {
                    item[key] = item[key] === null ? '' : item[key]
                }
                return {
                    "专线号": item.line_num,
                    "运营商名称": item.provider_name,
                    "运营商接口": item.provider_interface,
                    "费用": item.fee,
                    "光端机名称": item.equip_name,
                    "接入路由器名称": item.router_in,
                    "接入路由器端口": "Serial3/0:5",
                    "VLAN范围": item.vlan_range,
                    "带宽": item.bandwidth,
                    "月租": item.fee,
                }
            })
            //导出Excel
            exportExcel(json, '运营商信息列表-' + moment().format("YYYYMMDDHHmmss") + '.xlsx')
        })
    }


    // 批量导入
    importFiles = () => {

    }

    // 弹窗弹出和关闭方法
    handleOk = () => {

        // this.setState({ detailLoading: true });
        /*this.props.form.validateFieldsAndScroll((err, value) => {
            let {editObj} = this.state
            editObj = {...editObj, ...value} // 用value的值去覆盖
            console.log(JSON.stringify(editObj))
            patchEdit(editObj, res => {
                alert(JSON.stringify(res))
            })

        })*/
    };
    handleCancel = () => {
        this.setState({ importFileVisible: false });
    };

    render() {
        const {
            tableDataCurrent, tableDataPageSize, tableDataTotal, query, loading, tableData, selectedRowKeys,
            importFileVisible, importFileLoading, isEdit
        } = this.state

        const tableDataColumns = [
            {
                title: '专线号',
                dataIndex: 'line_num',
                key: 'line_num',
            },
            {
                title: '运营商名称',
                dataIndex: 'provider_name',
                key: 'provider_name',
            },
            {
                title: '运营商接口',
                dataIndex: 'provider_interface',
                key: 'provider_interface',
                render: (text, item, index) => {
                    return (
                        <div>
                            {
                                <span style={{ color: "blue", cursor: "pointer" }}
                                    onClick={this.tableDataDetail.bind(this, item.id)}>{text}</span>
                            }
                        </div>
                    )
                },
            },
            {
                title: '费用',
                dataIndex: 'fee',
                key: 'fee',
            },
            {
                title: '光端机设备名称',
                dataIndex: 'equip_name',
                key: 'equip_name',
            },
            {
                title: '接入路由器',
                dataIndex: 'router_in',
                key: 'router_in',
            },
            {
                title: '接入路由器端口',
                dataIndex: 'router_port',
                key: 'router_port',
            },
            {
                title: 'VLAN范围',
                dataIndex: 'vlan_range',
                key: 'vlan_range',
            },

            {
                title: '是否上线',
                dataIndex: 'is_online',
                key: 'is_online',
                render: (text, item, index) => {
                    return (
                        <div>

                        </div>
                    )
                }
            },
        ]

        //控制表格勾选
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                this.setState({ selectedRowKeys });
            }
        }

        //导入文件配置
        const UploadProps = {
            name: 'file',
            action: zx_baseUrl + '/zx/provider/import/',
            method: 'post',
            headers: {}
        }

        //页面渲染
        return (
            <div>
                <CustomBreadcrumb arr={["运营商管理", "运营商列表"]} />
                <Card>
                    <Row>
                        {/* 筛选 */}
                        <Col span={24}>
                            <FilterForm formList={formList}
                                filterSubmit={(query) => {
                                    this.setState({ query })
                                    this.update(tableDataPageSize, 1, query)
                                }}
                                resetTab={() => {
                                    this.setState({ query: {} })
                                    this.update(tableDataPageSize, 1, {})
                                }}
                            />
                        </Col>
                    </Row>

                </Card>

                {/* 设备列表 */}
                <Card title={<span>运营商列表</span>}
                    extra={
                        <div>
                            <Button type='default' icon='redo' onClick={e => {
                                this.update(tableDataPageSize, 1, query)
                            }}>刷新</Button>
                            <Button type='primary' style={{ color: "#fff", backgroundColor: "green" }}
                                onClick={() => {
                                    this.setState({ importFileVisible: true })
                                }}>导入</Button>
                            <Button type='default' style={{ color: "#fff", backgroundColor: "green" }}
                                onClick={this.addObj}>新建</Button>
                            <Button type='danger' icon='' onClick={
                                e => {
                                    const _self = this
                                    if (this.state.selectedRowKeys.length > 0) {
                                        confirm({
                                            title: '您确认删除吗?',
                                            content: `您确认要删除这${this.state.selectedRowKeys.length}项吗？`,
                                            okText: '删除',
                                            okType: 'danger',
                                            cancelText: '取消',
                                            onOk() {
                                                _self.delete()
                                            },
                                            onCancel() {

                                            },
                                        });
                                    } else {
                                        //   alert("请选择要删除的对象！")
                                        message.error("请选择要删除的对象！")
                                    }
                                }
                            }>
                                删除
                              </Button>
                            <Button type='primary'
                                icon='export'
                                onClick={this.exportExcel}
                                style={{ marginLeft: 8 }}>导出Excel</Button>

                        </div>
                    }
                    sytle={{ marginTop: 18 }}>
                    <Table
                        dataSource={tableData}
                        columns={tableDataColumns}
                        rowSelection={rowSelection}
                        rowKey={value => value.id}
                        loading={loading}
                        size="small"
                        pagination={{
                            current: tableDataCurrent, pageSize: tableDataPageSize, total: tableDataTotal,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                console.log(page, pageSize);
                                this.update(pageSize, page, query)
                            }
                        }}
                    />

                </Card>


                {/*弹出窗口*/}
                <Modal
                    visible={importFileVisible}
                    title='导入文件'
                    width='1000px'
                    height='600px'
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            取消
                        </Button>,
                        <Button style={{}} disabled={!isEdit} onClick={this.handleEdit}>
                            编辑
                        </Button>,
                        <Button key="submit" type="primary" loading={importFileLoading} style={{}}
                            onClick={this.handleOk}>
                            提交
                        </Button>,
                    ]}
                >
                    <div style={{ height: 600 }}>
                        <Row>
                            <Col offset={10} span={10} style={{ textAlign: 'center' }}>
                                <Upload {...UploadProps}>
                                    <Button type='primary' icon='upload' >上传</Button>
                                </Upload>
                                <span style={{ marginLeft: 8 }}>请选择填写好的模板进行上传，<a
                                    href='javascript:0;' onClick={() => {
                                        alert("下载模板")
                                    }}>点击</a>下载模板</span>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        )
    }


}