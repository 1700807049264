import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Divider, Popconfirm
} from "antd";
import http from '@/axios/axios_utils';
import FormModal from '@/components/EditModal/index';
import { connect } from 'react-redux'
const portCmdUrl = "http://" + backup_address + "/ZJNMS_V1/port/";
function ActionCmd(props) {
    const [visible, setVisible] = useState(false);
    const [selectItem, setSelectItem] = useState(null);
    const [tableData, setTabData] = useState([])
    const columns = [
        {
            title: '序号',
            dataIndex: 'ip',
            ellipsis: true,
            width: 50,
            render: (text, item, c) => { return c + 1 }
        },
        {
            title: '设备类型',
            width: 100,
            dataIndex: 'device_type',
            ellipsis: true,
        },
        {
            title: '操作类型',
            width: 100,
            dataIndex: 'action_type',
            ellipsis: true,
        },
        {
            title: '命令',
            dataIndex: 'cmd',
            ellipsis: true,
        },
        // {
        //     title: '创建时间',
        //     dataIndex: 'create_time',
        //     ellipsis: true,
        // },
        {
            title: '操作',
            width: 180,
            render: (text, item) => {
                return <div>
                    <Button type="primary" icon="edit" size="small" onClick={() => { setSelectItem(item); setVisible(true) }}>编辑</Button>
                    &nbsp;
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData(item._id) }}
                        okText="是"
                        cancelText="否"
                    >
                        <Button type="danger" size="small" icon="delete" >删除</Button>
                    </Popconfirm>
                </div>
            }
        },
    ]
    useEffect(() => {//componentDidMount;
        getData();
        http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    function getData() {
        http.get(portCmdUrl + "getCmdByPage", { page_size: 9999 }, (r) => {
            setTabData(r.data);
        })
    }
    function saveDataSource(val) {
        const { device_type } = selectItem;
        if (device_type) {//修改
            http.post(portCmdUrl + "updateCmd", val, () => {
                getData();
                message.success("修改成功");
            })
        } else {//新增
            http.post(portCmdUrl + "addCmd", val, () => {
                getData();
                message.success("新增成功");
            })
        }
        setVisible(false)
    }
    function deleteData(_id) {
        http.delete_body(portCmdUrl + "delCmd", { _ids: [_id] }, () => {
            getData();
            message.success("删除成功");
        })

    }
    return <div className="ActionCmdDiv">
        <Card >
            <div className="head">
                <Button type="primary" icon="file-add" onClick={() => {
                    setSelectItem({})
                    setVisible(true)
                }}>添加</Button>&nbsp;
                <Button icon="reload" onClick={() => {
                    getData();
                }}>刷新</Button>
            </div>
            <Table
                dataSource={tableData}
                columns={columns}
                size="small"
                pagination={false}
            />
        </Card>
        <FormModal
            title="编辑数据源配置"
            visible={visible}
            onCancel={() => setVisible(false)}
            onSubmit={(val) => {
                saveDataSource(val);
            }}
            footer={null}
            width={"50%"}
            initialValues={selectItem}
            destroyOnClose
            formList={[
                { type: "input", name: "device_type", label: "设备类型", required: true, placeholder: "设备类型" },
                { type: "input", name: "action_type", label: "操作类型", required: true, placeholder: "操作类型" },
                {
                    type: "code", name: "cmd", label: "命令", required: true,
                    placeholder: "",
                },
            ]}
        />
    </div>
}
export default connect()(ActionCmd)