import React from 'react'
import { Input, Select, Form, Button, Checkbox, Radio, DatePicker} from 'antd'
import Utils from '../../../../utils/utils';
const FormItem = Form.Item;
const Option = Select.Option;
let FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn'))||[];
class SettingForm extends React.Component{
    componentWillMount () {
        FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
    }

    handleFilterSubmit = ()=>{
        let fieldsValue = this.props.form.getFieldsValue();
        this.props.filterSubmit(fieldsValue);
    };

    reset = ()=>{
        this.props.form.resetFields();
        this.props.resetTab();
    };

    initFormList = ()=>{
        const { getFieldDecorator } = this.props.form;
        const formList = this.props.formList;
        const formItemList = [];
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        if (formList && formList.length>0){
            formList.forEach((item,i)=>{
                let label = item.label;
                let field = item.field;
                let initialValue = item.initialValue || '';
                let placeholder = item.placeholder;
                let style = item.style;
                let width = item.width;
                let rules = item.rules;
                if (item.type == '时间查询'){
                    const begin_time = <FormItem label="时间" key={field}
                                                 {...formItemLayout}>
                        {
                            getFieldDecorator('begin_time')(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss"/>
                            )
                        }
                    </FormItem>;
                    formItemList.push(begin_time)
                    const end_time = <FormItem label="~" colon={false} key={field}
                                               {...formItemLayout}>
                        {
                            getFieldDecorator('end_time')(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(end_time)
                }else if(item.type == 'INPUT'){
                    const INPUT = <FormItem label={label} key={field}
                                            {...formItemLayout}>
                        {
                            getFieldDecorator([field],{
                                rules:rules,
                                initialValue: initialValue
                            })(
                                <span>
                                    <Input type="text" placeholder={placeholder} defaultValue={initialValue} style={style} />
                                    <div style={{color:'#78c9cf',lineHeight:'20px',margin:'0px 0px 20px 0px'}}>
                                        {placeholder}</div>
                                </span>

                            )
                        }
                    </FormItem>;
                    formItemList.push(INPUT)
                } else if (item.type == 'SELECT') {
                    const SELECT = <FormItem label={label} key={field}
                                             {...formItemLayout}>
                        {
                            getFieldDecorator([field], {
                                initialValue: initialValue
                            })(
                                <Select
                                    style={{ width: width }}
                                    placeholder={placeholder}
                                >
                                    {Utils.getOptionList(item.list)}
                                </Select>
                            )
                        }
                    </FormItem>;
                    formItemList.push(SELECT)
                } else if (item.type == 'CHECKBOX') {
                    const CHECKBOX = <FormItem label={label} key={field}
                                               {...formItemLayout}>
                        {
                            getFieldDecorator([field], {
                                valuePropName: 'checked',
                                initialValue: initialValue //true | false
                            })(
                                <Checkbox>
                                    {label}
                                </Checkbox>
                            )
                        }
                    </FormItem>;
                    formItemList.push(CHECKBOX)
                }
            })
        }
        return formItemList;
    }
    render(){
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        return (
        <div>
            <Form layout="inline" {...formItemLayout}>
                { this.initFormList() }

            </Form>
            <br/>
            <div style={{textAlign:'center',width:'100%',paddingLeft:'40%'}}>
                {
                    FixedBtn.includes('/installApp')?
                        <Button type="primary" icon="play-circle" style={{float:'left'}}
                                onClick={this.handleFilterSubmit}>安装应用</Button>:''
                }
                <Button style={{marginLeft:8,float:'left'}} type="danger"
                        icon="close-circle"
                        onClick={()=>{history.go(-1)}}>取消</Button>
            </div>
        </div>

        );
    }
}
export default Form.create({})(SettingForm);