import React from 'react'
import { Input, Select, Form, Button, Checkbox, Radio, Divider, DatePicker, message, Icon, Modal, Popconfirm } from 'antd'
import Utils from '@/utils/utils';
import "../index.scss";
const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {
        span: 4
    },
    wrapperCol: {
        span: 18
    },
};
export default class renderSelect extends React.Component {
    state = {
        vis_modal: false,
        title_modal: "",
        srcAdress: "",
    }

    render() {
        let { vis_modal, srcAdress, title_modal } = this.state;
        const { onChange, SelectList, value, propsThis, field, mode } = this.props;
        return (
            <div
                onMouseDown={(e) => {
                    e.preventDefault();
                    return false;
                }}>
                <Select
                    showSearch
                    mode={mode}
                    onChange={(val) => { onChange(val); }}
                    value={value}
                    dropdownRender={menu => (
                        <div>
                            {menu}
                            <Divider style={{ margin: '0px 0' }} />
                            <div style={{ padding: '5px', cursor: 'pointer' }}
                                onClick={() => { propsThis.openModal("新增", field); }}>
                                <Icon type="plus-circle" theme="twoTone" twoToneColor="#52c41a" /> Add item
                    </div>

                            <Divider style={{ margin: '0px 0' }} />
                            <div style={{ padding: '5px', cursor: 'pointer' }}
                                onClick={() => { propsThis.openModal("删除", field); }}>
                                <Icon type="close-circle" style={{ color: "red" }} /> Del item
                    </div>
                        </div>
                    )}
                >
                    {/* {Utils.getOptionList(item.list)} */}
                    {SelectList.map((itemOp, keyOp) => {
                        return <Option key={keyOp} value={itemOp.id}>{itemOp.name}</Option>
                    })}
                </Select>

            </div>


        );
    }
} 