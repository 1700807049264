import React from 'react'
import { Button, Select, Input, message } from "antd";
import $ from 'jquery'
import Socket from '@/socket/index'
const Option = Select.Option;
// const condition = [
//     { key: 'operationName', value: '操作名称' },
//     { key: 'operationType', value: '操作类型' },
//     { key: 'level', value: '操作级别' },
//     { key: 'tags', value: '标签' },
//     { key: 'groupNames', value: '分组' }
// ];
// const conditionData = {
//     operationType: {
//         mode: 'alone', data: [
//             { key: 'cli', value: 'cli' }, { key: 'linuxShell', value: 'linuxShell' },
//             { key: 'python', value: 'python' }, { key: 'perl', value: 'perl' }
//             , { key: 'nodejs', value: 'nodejs' }, { key: 'system', value: 'system' }]
//     },
//     operationName: "INPUT",
//     level: "INPUT",
//     tags: { mode: "tags", data: [{ key: '19楼', value: '19楼' }] },
//     groupNames: { mode: "tags", data: [{ key: '19楼', value: '19楼' }] },
// };
export default class SelectForm2 extends React.Component {

    componentWillMount() {
        this.initData();
    }

    handleFilterSubmit = () => {
        let { defaultCondition, OptionValue, isInput } = this.state;
        let { tagClass } = this.props;
        let fieldsValue = {};
        if (isInput) {
            fieldsValue[defaultCondition] = $('#inputVal')[0].value;
        } else {
            fieldsValue[defaultCondition] = OptionValue;
        }
        this.props.filterSubmit(fieldsValue);
    };
    initData = () => {
        let { conditionData, condition, tagClass } = this.props;
        let dataKey = condition[0].key;
        let dataObj = conditionData[dataKey];

        let dataList = [];
        let selectMode = '';
        let OptionLab = [];
        let OptionValue = '';
        if (!(dataObj === 'INPUT')) {
            dataList = dataObj["data"];
            selectMode = dataObj["mode"];
            OptionLab = dataList;
            OptionValue = dataList[0].key;
        }

        if (tagClass) {
            this.updataTagsGroupNames(conditionData, tagClass);
        }
        this.setState({
            conditionData,
            condition, selectMode,
            OptionLab: OptionLab,
            OptionValue: OptionValue,
            defaultCondition: dataKey,
            isInput: true,
        });
        // this.setState({
        //     conditionData,
        //     condition,
        //     OptionLab: conditionData[condition[0].key][0],
        //     OptionValue: conditionData[condition[0].key][0].key,
        //     defaultCondition:condition[0].key,
        //     isInput: false,
        // });
    };
    updataTagsGroupNames = (Data, tagClass) => {
        let conditionData = Data;
        Socket.QueryTagsGroups({ tagClass: tagClass }, (success) => {
            if (conditionData.tags) {
                success.tags.map((tag, key) => {
                    let item = { key: tag, value: tag };
                    conditionData.tags.data[key] = item;
                    return conditionData;
                });
            }

            if (conditionData.groupNames) {
                success.groupNames.map((groupName, key) => {
                    let item = { key: groupName, value: groupName };
                    conditionData.groupNames.data[key] = item;
                });
            }
        }, (error) => {
            message.error(error);
        })
    };

    reset = () => {
        this.initData();
        this.props.resetTab();
    };
    handleProvinceChange = (value) => {
        let { conditionData, } = this.state;
        let isInput = false;
        if (conditionData[value] === 'INPUT') {
            isInput = true;
            this.setState({ isInput, defaultCondition: value });
            return;
        }
        // this.setState({
        //     OptionLab: conditionData[value],
        //     OptionValue: conditionData[value][0].key,
        //     isInput,
        //     defaultCondition:value
        // });

        let dataObj = conditionData[value];
        let dataList = dataObj["data"];
        let selectMode = dataObj["mode"];
        let OptionValue = dataList[0].key;
        if (selectMode === 'tags') {
            OptionValue = [dataList[0].key];
        }
        this.setState({
            OptionLab: dataList,
            OptionValue, isInput, selectMode,
            defaultCondition: value
        });
    };

    onOptionValueChange = (value) => {
        this.setState({
            OptionValue: value,
        });
    };

    render() {
        let { OptionLab, isInput, defaultCondition, condition, selectMode } = this.state;
        let { className, } = this.props;
        return (
            <div className={className}>
                <span>筛选条件:</span>
                <Select
                    value={defaultCondition}
                    style={{ width: 120, marginLeft: 10, marginRight: 5 }}
                    onChange={this.handleProvinceChange}
                >
                    {condition.map(province =>
                        <Option key={province.key}>{province.value}</Option>
                    )}

                </Select>
                {
                    isInput ? <Input style={{ width: 180 }} id="inputVal" allowClear /> : <Select
                        mode={selectMode}
                        style={{ width: 180 }}
                        value={this.state.OptionValue}
                        onChange={this.onOptionValueChange}
                    >
                        {OptionLab.map(item => <Option key={item.key}>{item.value}</Option>)}
                    </Select>
                }
                <Button type="primary" style={{ margin: '0 20px' }}
                    icon="search"
                    onClick={this.handleFilterSubmit}>查询</Button>
                <Button onClick={this.reset} icon="reload">重置</Button>
            </div>
        )
    }
}
