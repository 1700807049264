import React from 'react'
import {
    Card, Table, Button, message, Icon, Input, Select, Row, Col, Form, Upload, Divider, Modal, DatePicker, Checkbox, Affix
} from "antd";
import Socket from "@/socket/index";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import SelectDevice from '../checkDev';
import Utils from '@/utils/utils';
import moment from 'moment';
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import ResUtils from '@/utils/ResUtils';
import http from '@/axios/axios_utils';
import api from '@/config/api/deliveryTask';
import * as XLSX from 'xlsx';
import "./index.scss"
import CmdTable from './utils/cmdTable'
class NewTaskForm extends React.Component {
    state = {
        selectedRowKeys: [],
        selectedRows: [],
        TaskType: [],
        userList: [],
        visibleDev: false,
        querys: {},
        // querys: { "resourceParams.deviceFirm": "cisco" },
        screenSize: Utils.getScreenSize(),
        tabJson: null,
        tabHtmlbyXLSL: null,
        modeData: {},
        detailNode: {},
        commitStatus: false,
        saveStatus: false,
        isApprove: false,
        devicesFirm: '', // 设备厂商
    }
    componentWillMount() {
        // console.log(this.props)
        const detailNode = JSON.parse(sessionStorage.getItem("acltask") || "{}");
        let commitStatus = false;
        let saveStatus = false;
        let isApprove = detailNode.reviewer ? true : false;
        if (detailNode.status === "完成") {
            saveStatus = true;
        } else if (detailNode.status === "审批中" || detailNode.status === "待执行") {
            commitStatus = true;
            saveStatus = true;
        }
        
        let firms = (detailNode.resources || []).map(item => item.resourceParams.deviceFirm );
        firms = Array.from(new Set(firms)) // 数组去重
        this.setState({
            selectedRows: detailNode.resources || [],
            selectedRowKeys: detailNode.resources && detailNode.resources.map((res) => { return JSON.stringify({ name: res.resourceName, ip: res.resourceParams.deviceIp }) }),
            tabJson: detailNode.excel_cmd || null,
            devicesFirm: firms.join(','),
            detailNode, saveStatus, commitStatus, isApprove,
        })
        this.getConfig("command");
        this.getUsers();
        this.getTaskType();
    }
    componentWillUnmount() { sessionStorage.removeItem("acltask"); }
    componentDidMount() {
        const { detailNode } = this.state;
        const excel_cmd = detailNode.excel_cmd;
        if (!excel_cmd) { return }
        if (!excel_cmd[0]) { return }
        const sheetData = XLSX.utils.json_to_sheet(excel_cmd || []);
        const tabHtmlbyXLSL = XLSX.utils.sheet_to_html(sheetData, { id: "xlslTab", editable: false, });
        this.setState({ tabHtmlbyXLSL });
    }
    getUsers() {
        Socket.ExecuteAction('QueryUserFromUrl', { url: "/command/approve" }, "", (result) => {
            console.log(result);
            this.setState({ userList: result.data || [] });
        });
    }
    delTaskType(name) {
        const _this = this;
        Modal.confirm({
            title: '消息提示',
            content: '你确定删除模板类型' + name + "吗?",
            okType: 'danger',
            onOk() {
                Socket.ExecuteAction('DeleteTaskType', { name }, "", () => {
                    message.success("删除成功!");
                    _this.getTaskType();
                });
            },
        });
    }
    addTaskType() {
        const _this = this;
        let name = "444";
        Modal.confirm({
            title: Utils.BoldFont("添加类型"),
            maskClosable: true,
            content: <div><Divider />
                <Row>
                    <Col span={5}>类型名称:</Col>
                    <Col span={19}><Input onChange={(e) => { name = e.target.value }} placeholder="模板类型" /></Col>
                </Row>
            </div>,
            icon: <Icon type="file-add" />,
            onOk() {
                Socket.ExecuteAction('AddTaskType', { name }, "", () => {
                    message.success("新增成功!");
                    _this.getTaskType();
                });
            }
        })
    }
    getTaskType() {
        Socket.ExecuteAction('QueryTaskTypes', {}, "", (result) => {
            this.setState({ TaskType: result.data, });
        });
    }
    getConfig = (mode) => {
        Socket.getConfig(mode, (res) => {
            if (res.approve === "02") {//必须审核
                this.setState({ isApprove: true });
            }
            this.setState({ modeData: res });
        });
    };
    newOnSubmit(val, type, isNew) {
        const { detailNode } = this.state;
        const { dispatch } = this.props;
        val.username = Utils.getLoginUser();
        const _this = this;
        dispatch(isLoading(true));
        if (type === "commit") {//提交
            Modal.confirm({
                title: '消息提示',
                content: '你确定提交任务 ' + val.task_name + ' 吗？',
                onOk() {
                    val._id = detailNode._id;
                    val.status = detailNode.status;
                    // console.log(api.commitDeliveryTask, val)
                    http.put(api.commitDeliveryTask, val, () => {
                        dispatch(isLoading(false));
                        message.success("提交任务成功!");
                        _this.goBack();
                    });
                },
                onCancel() {
                    dispatch(isLoading(false));
                }
            });
        } else {//保存
            if (isNew) {//新建
                http.post(api.saveDeliveryTask, val, () => {
                    message.success("新建成功!");
                    dispatch(isLoading(false));
                    _this.goBack();
                });
            } else {//修改
                val._id = detailNode._id;
                val.status = detailNode.status;
                http.post(api.saveDeliveryTask, val, () => {
                    message.success("修改成功!");
                    dispatch(isLoading(false));
                    _this.goBack();
                });
            }
        }

    }
    handleSubmit = (type) => {
        let _this = this;
        let ips = [];
        const { selectedRows } = this.state;
        if (selectedRows.length === 0) {
            Modal.info({
                title: "消息提示",
                content: "请选择下发设备！",
                okText: "确定",
            })
            return
        } else {
            selectedRows.map((item, index) => {
                if(item.resourceParams.deviceState === "offline") {
                    ips.push(item.resourceParams.deviceIp);
                }
            })
            if(ips.length > 0) {
                Modal.confirm({
                    title: "消息提示",
                    maskClosable: true,
                    content: ips.join("，") + " 设备处于离线状态，是否继续使用该设备执行下发任务？",
                    onOk() {
                        _this.formValidate(type);
                    }
                })
            } else {
                this.formValidate(type);
            }
        }
    };
    formValidate(type) {
        const { selectedRows, tabJson, detailNode, isApprove } = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.resources = selectedRows;
                values.excel_cmd = tabJson;
                if (values.auto_execute_time) {
                    values.auto_execute_time = moment(values.auto_execute_time).format("YYYY-MM-DD HH:mm:ss");
                }
                if (!isApprove) {//如果不需要审批，清空接收人与邮箱
                    values.reviewer = "";
                    values.mail = "";
                }
                console.log('Received values of form: ', values);
                const isNew = detailNode._id ? false : true
                this.newOnSubmit(values, type, isNew);
            }
        });
    };
    reset = () => {
        this.props.form.setFieldsValue({
            auto_execute_time: "",
            automatic: false,
            commands: "",
            concurrent: false,
            ignore_error: false,
            mail: "",
            reviewer: "",
            type: "acl",
            task_name: "",
        });
        this.setState({ selectedRowKeys: [], selectedRows: [], tabHtmlbyXLSL: null, isApprove: false, })
    };
    setHtml(id, html) {
        document.getElementById(id).innerHTML = html;
    }
    onPreview = (tabHtmlbyXLSL) => {
        Modal.info({
            title: "参数列表",
            width: "80%",
            maskClosable: true,
            content: <div dangerouslySetInnerHTML={{ __html: tabHtmlbyXLSL }} />,
        })
    }
    uploadFilesChange = (file) => {
        console.log(file)
        let tabHtmlbyXLSL = "";
        let tabJson = null;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        tabHtmlbyXLSL = XLSX.utils.sheet_to_html(workbook.Sheets[sheet], { id: "xlslTab", editable: false, });
                        tabJson = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
                    }
                }
                message.success('导入成功！');
                console.log(tabJson);
                this.setState({ tabJson, tabHtmlbyXLSL, })
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                console.log(e);
                message.error('文件类型不正确！');
            }
        }
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(file.file);
    }
    goBack() {
        const querys = this.state.detailNode.querys || {};
        this.props.history.push({ bodyJson: querys, pathname: "/command/aclSend" })
    }
    submitDevices() {
        let { selectedRows } = this.state;
        let firms = selectedRows.map(item => item.resourceParams.deviceFirm );
        firms = Array.from(new Set(firms)) // 数组去重
        this.setState({ visibleDev: false, devicesFirm: firms.join(',') });
    }
    render() {
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
        const { selectedRowKeys, querys, visibleDev, selectedRows, screenSize, modeData, isApprove, devicesFirm,
            tabHtmlbyXLSL, userList, TaskType, detailNode, saveStatus, commitStatus } = this.state;
        const columns = [...ResUtils.getColumns(10000, 1), {
            title: "操作",
            render: (a, item) => {
                return <Button type="danger" icon="delete" size="small" onClick={() => {
                    const newData = selectedRows.filter((row) => { return row._id != item._id });
                    const newData2 = selectedRowKeys ? selectedRowKeys.filter((row) => {
                        return row != JSON.stringify({ name: item.resourceName, ip: item.resourceParams.deviceIp })
                    }) : "";
                    let firms = (newData || []).map(item => item.resourceParams.deviceFirm );
                    firms = Array.from(new Set(firms)) // 数组去重            
                    this.setState({ selectedRows: newData, selectedRowKeys: newData2, devicesFirm: firms.join(',') })
                }} />
            }
        }]
        return <div className="newTaskAclDiv">
            <CustomBreadcrumb arr={["运维管理", "配置下发", { title: "下发任务", to: "/command/aclSend" }, "编辑下发任务"]} />
            <Card title={"编辑下发任务"} extra={<Button type="primary" icon="rollback" onClick={() => { this.goBack() }}>返回</Button>}>
                <Form
                    labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}
                    onSubmit={this.handleSubmit}>
                    <Row>
                        <Col span={12}> <Form.Item label={"任务名称"} >
                            {getFieldDecorator("task_name", {
                                initialValue: detailNode.task_name || "",
                                rules: [{
                                    required: true, message: '请填写任务名称!',
                                }],
                            })(<Input placeholder="本次下发任务名称" />)}
                        </Form.Item></Col>
                        <Col span={12}>
                            <Form.Item label="任务类型" >
                                {getFieldDecorator('type', {
                                    initialValue: detailNode.type || "acl",
                                })(<Select
                                        placeholder="任务类型"
                                        dropdownRender={menu => (
                                            <div id="selectDiv">
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div
                                                    style={{ padding: '4px 8px', cursor: 'pointer' }}
                                                    onMouseDown={e => e.preventDefault()}
                                                    onClick={() => { this.addTaskType() }}
                                                >
                                                    <Icon type="plus-circle" />&nbsp;添加类型
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {TaskType.map((item, key) => (
                                            <Option key={key} value={item.name}>
                                                {item.name}
                                            </Option>
                                        ))}
                                </Select>)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}><Form.Item label={" "} colon={false} >
                            {getFieldDecorator("ignore_error", {
                                valuePropName: 'checked',
                                initialValue: detailNode.ignore_error || false,
                            })(<Checkbox>执行过程中遇见错误终止</Checkbox>)}
                        </Form.Item></Col>
                        <Col span={12}> <Form.Item label={" "} colon={false}  >
                            {getFieldDecorator("concurrent", {
                                valuePropName: 'checked',
                                initialValue: detailNode.concurrent || false,
                            })(<Checkbox>任务并发</Checkbox>)}
                        </Form.Item></Col>
                    </Row>
                    <Row>
                        <Col span={12}><Form.Item label={" "} colon={false}>
                            {getFieldDecorator("automatic", {
                                valuePropName: 'checked',
                                initialValue: detailNode.automatic || false,
                            })(<Checkbox>定时执行</Checkbox>)}
                        </Form.Item></Col>
                        <Col span={12}>
                            {getFieldValue("automatic") ?
                                <Form.Item label={"时间"} help="设置定时执行的时间" >
                                    {getFieldDecorator("auto_execute_time", {
                                        initialValue: moment(detailNode.auto_execute_time || Utils.formateDate(new Date()), "YYYY-MM-DD HH:mm:ss"),
                                        // initialValue: moment(detailNode.auto_execute_time) || null,
                                    })(<DatePicker style={{ width: "100%" }} showTime placeholder="定时执行时间" format="YYYY-MM-DD HH:mm:ss"
                                    // disabled={getFieldValue("automatic") ? false : true}
                                    />)}
                                </Form.Item> : null}
                        </Col>
                    </Row>
                    {modeData.approve == "03" ? <Form.Item label=" " colon={false} labelCol={{ span: 2 }}>
                        <Checkbox checked={isApprove} onChange={(e) => {
                            this.setState({ isApprove: e.target.checked })
                        }}>任务审批</Checkbox>
                    </Form.Item> : ""}
                    {isApprove ? <Row>
                        <Col span={12}><Form.Item label={"审批人"} >
                            {getFieldDecorator("reviewer", {
                                initialValue: detailNode.reviewer || "",
                                getValueFromEvent: (val) => {
                                    userList.some((user) => {
                                        if (user.name + "/" + user.nickName === val) {
                                            setFieldsValue({ mail: user.mail });
                                            if (!user.mail.trim()) {
                                                message.info("该审批人邮箱为空！请前往系统管理-用户管理完善邮箱信息！")
                                            }
                                        }
                                        return user.name + "/" + user.nickName === val;
                                    })
                                    return val
                                },
                                rules: [{ required: modeData.approve == "02" ? true : false, message: '请填写审批人!', }],
                            })(<Select placeholder="审批人" allowClear>
                                {userList.map((user, key) => {
                                    const name = user.name + "/" + user.nickName;
                                    return <Select.Option key={name + key} value={name}>{name}</Select.Option>
                                })}
                            </Select>)}
                        </Form.Item></Col>
                        <Col span={12}> <Form.Item label={"邮箱"} >
                            {getFieldDecorator("mail", {
                                initialValue: detailNode.mail || "",
                                rules: [{ required: modeData.approve == "02" ? true : false, message: '请填写审批人邮箱地址!', }],
                            })(<Input placeholder="审批人邮箱地址" />)}
                        </Form.Item></Col>
                    </Row> : ""}
                    <Row>
                        <Col span={2} className="tempsty" >
                            <div className="ant-form-item-label">
                                <label className="ant-form-item-required" >下发设备</label>
                            </div>
                        </Col>
                        <Col span={22}>
                            <div className="deviceDiv">
                                <Table
                                    columns={columns}
                                    dataSource={selectedRows}
                                    pagination={false}
                                    locale={{ emptyText: <div><Icon type="inbox" />暂无数据</div> }}
                                    size="small"
                                    rowKey={(row) => row._id}
                                    scroll={{ y: screenSize.height * 0.2 }}
                                />
                            </div>
                            <Button style={{ width: "100%", marginTop: 5 }} icon="plus-circle" type="dashed"
                                onClick={() => {
                                    this.setState({ visibleDev: true })
                                }}>添加设备</Button>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={24} className="alcTaskModal"><Form.Item label={"下发指令"} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                            {getFieldDecorator("commands", {
                                initialValue: detailNode.commands || "",
                                rules: [{
                                    required: true, message: '请填写下发指令!',
                                }],
                            })(
                                // <MonacaEditor
                                //     theme="vs-dark"
                                //     language="shell"
                                //     options={{ minimap: { enabled: false }, scrollBeyondLastColumn: 1, scrollBeyondLastLine: false, lineNumbers: "off" }}
                                //     height={screenSize.height * 0.3}
                                // />
                                <Input.TextArea className="cmdDiv" style={{ height: screenSize.height * 0.3 }} />
                            )}
                        </Form.Item></Col>
                        <Col span={24}> <Form.Item label={"下发指令项"} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                            <CmdTable commandsFormValue={this.props.form.getFieldValue("commands")} devicesFirm={devicesFirm}
                                setFormValue={(value) => { this.props.form.setFieldsValue({ commands: value }) }} />
                        </Form.Item></Col>  
                        <Col span={24}><Form.Item label="指令参数" labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                            <div className="centent">
                                <Upload name="file" accept=".xls,.xlsx,.csv"
                                    beforeUpload={function () { return false; }}
                                    onChange={this.uploadFilesChange.bind(this)}
                                    showUploadList={false}
                                    onPreview={() => { this.onPreview(tabHtmlbyXLSL) }}
                                >
                                    <div className="import"><Icon type="inbox" />点击导入指令参数</div>
                                </Upload>
                                <div
                                    style={{ maxHeight: screenSize.height * 0.4 - 0 }}
                                    id="excel"
                                    dangerouslySetInnerHTML={{ __html: tabHtmlbyXLSL }}
                                />
                            </div>
                        </Form.Item></Col>
                    </Row>
                </Form>
            </Card>
            <Affix offsetBottom={0}>
                <div className="bootmBtn">
                    <Button type="primary" disabled={commitStatus} onClick={() => { this.handleSubmit("commit") }} icon="rocket">提交</Button>&nbsp;
                    <Button type="primary" disabled={saveStatus} onClick={() => { this.handleSubmit("save") }} icon="save">保存</Button>&nbsp;
                    <Button icon="reload" onClick={() => this.reset()} >清空</Button>&nbsp;
                    <Button icon="close" onClick={() => { this.goBack() }} >取消</Button>
                </div>
            </Affix>
            <Modal
                title="选择设备"
                visible={visibleDev}
                width={"99%"}
                style={{ top: 10 }}
                onCancel={() => { this.setState({ visibleDev: false, }); }}
                onOk={() => { this.submitDevices(); }}
            //不能添加destroyOnClose，会导致选择错误
            >
                <SelectDevice
                    onChange={(selectedRowKeys, selectedRows) => {
                        this.setState({ selectedRowKeys, selectedRows, })
                    }}
                    querys={querys}
                    selectedRowKeys={selectedRowKeys}
                    selectedRows={selectedRows}
                />
            </Modal>
        </div>
    }
}
const CommObj = Form.create()(NewTaskForm)
export default connect()(CommObj)