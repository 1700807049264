import React from "react";
import {Button, Col, Row, Select} from "antd";
import './index.css'
import moment from "moment";

const ButtonGroup = Button.Group
class MyButtonGroup extends React.Component {
    
    state = {
        btnSelected: moment().format('M'),
        initYear: moment().format("YYYY"),
    }

    handleChange = e => {
        this.setState({btnSelected: e})
        this.props.getData(e)
    }

    handleYearChange = e => {
        this.setState({initYear: e})
        this.props.setYear2(e)
    }
    render() {
        const {years_index, months_index, btnSelected, getData2, _this} = this.props
        // const btnSelected = btnSelected2
        // const {, } = this.state


        return (<div>
            <Row>
                <Col span={24} offset={0} style={{textAlign: 'center',}}>
                    <ButtonGroup className='btnGroup'>
                        <Button disabled={months_index.indexOf('01')<0} style={{backgroundColor: btnSelected === '1' ?  '#81c4ff' : null, color: btnSelected === '1' ? '#fff' : null}} onClick={this.handleChange.bind(this, '1')}>1月</Button>
                        <Button disabled={months_index.indexOf('02')<0} style={{backgroundColor: btnSelected === '2' ?  '#81c4ff' : null, color: btnSelected === '2' ? '#fff' : null}} onClick={this.handleChange.bind(this, '2')}>2月</Button>
                        <Button disabled={months_index.indexOf('03')<0} style={{backgroundColor: btnSelected === '3' ?  '#81c4ff' : null, color: btnSelected === '3' ? '#fff' : null}} onClick={this.handleChange.bind(this, '3')}>3月</Button>
                        <Button disabled={months_index.indexOf('04')<0} style={{backgroundColor: btnSelected === '4' ?  '#81c4ff' : null, color: btnSelected === '4' ? '#fff' : null}} onClick={this.handleChange.bind(this, '4')}>4月</Button>
                        <Button disabled={months_index.indexOf('05')<0} style={{backgroundColor: btnSelected === '5' ?  '#81c4ff' : null, color: btnSelected === '5' ? '#fff' : null}} onClick={this.handleChange.bind(this, '5')}>5月</Button>
                        <Button disabled={months_index.indexOf('06')<0} style={{backgroundColor: btnSelected === '6' ?  '#81c4ff' : null, color: btnSelected === '6' ? '#fff' : null}} onClick={this.handleChange.bind(this, '6')}>6月</Button>
                        <Button disabled={months_index.indexOf('07')<0} style={{backgroundColor: btnSelected === '7' ?  '#81c4ff' : null, color: btnSelected === '7' ? '#fff' : null}} onClick={this.handleChange.bind(this, '7')}>7月</Button>
                        <Button disabled={months_index.indexOf('08')<0} style={{backgroundColor: btnSelected === '8' ?  '#81c4ff' : null, color: btnSelected === '8' ? '#fff' : null}} onClick={this.handleChange.bind(this, '8')}>8月</Button>
                        <Button disabled={months_index.indexOf('09')<0} style={{backgroundColor: btnSelected === '9' ?  '#81c4ff' : null, color: btnSelected === '9' ? '#fff' : null}} onClick={this.handleChange.bind(this, '9')}>9月</Button>
                        <Button disabled={months_index.indexOf('10')<0} style={{backgroundColor: btnSelected === '10' ?  '#81c4ff' : null, color: btnSelected === '10' ? '#fff' : null}} onClick={this.handleChange.bind(this, '10')}>10月</Button>
                        <Button disabled={months_index.indexOf('11')<0} style={{backgroundColor: btnSelected === '11' ?  '#81c4ff' : null, color: btnSelected === '11' ? '#fff' : null}} onClick={this.handleChange.bind(this, '11')}>11月</Button>
                        <Button disabled={months_index.indexOf('12')<0} style={{backgroundColor: btnSelected === '12' ?  '#81c4ff' : null, color: btnSelected === '12' ? '#fff' : null}} onClick={this.handleChange.bind(this, '12')}>12月</Button>
                        {/*<Button style={{backgroundColor: btnSelected === 'month' ?  '#81c4ff' : null, color: btnSelected === 'month' ? '#fff' : null}} onClick={getData2.bind(this, 'month')}>按月</Button>*/}
                        <Button style={{backgroundColor: btnSelected === 'year' ?  '#81c4ff' : null, color: btnSelected === 'year' ? '#fff' : null}} onClick={this.handleChange.bind(this, 'year')}>按年</Button>
                        <Select size={"default"} defaultValue={this.state.initYear} onChange={this.handleYearChange} style={{marginLeft: 5, width: '7em'}}>
                            {
                                years_index.length && years_index.map((item, index)=>(
                                    <Select.Option key={index} value={item}>{item}年</Select.Option>
                                ))
                            }
                        </Select>
                        {/*<span>{btnSelected}</span>*/}
                    </ButtonGroup>
                </Col>
            </Row>
        </div>);
    }

};

export default MyButtonGroup