
import React from 'react'
import { Card, Table, Button, Icon, notification, Tag, Modal, Spin } from "antd";
import socket from "@/socket/index";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm';
import PwdModal from './PwdModal/index';
export default class PwdAudits extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _pageSize: 10,    //当前每页显示条数
        loading: false,
        detailLoading: false,
        detailModal: false,
        pwd_Vis: false,
        detailData: [],
        querys: null,    //查询条件
    };
    componentWillMount() {
        this.updata();
    }
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        socket.ExecuteAction("QueryPwdAuditsPage",
            bodyJson, "/QueryPwdAuditsPage", (result) => {
                let tableData = result.data;
                tableData.map((item, index) => { item.key = index; return item });
                this.setState({
                    tableData,
                    _current: bodyJson.pageNum || 1,
                    total: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };
    getPlayDetail(_ID) {
        this.setState({ detailLoading: true });
        socket.ExecuteAction("QueryPwdAuditDetail",
            { _id: _ID }, "/QueryPwdAuditDetail", (result) => {
                let detailData = result.data;
                this.setState({ detailData, detailLoading: false })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ detailLoading: false, })
            });
    }
    render() {
        let { _current, _pageSize, querys, tableData, loading, total, detailModal, detailData, detailLoading, pwd_Vis } = this.state;
        const columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                render: (text) => { return <span>{text + 1}</span> }
            }, {
                title: '添加时间',
                align: 'center',
                dataIndex: 'addTime',
            }, {
                title: '执行人',
                align: 'center',
                dataIndex: 'createUserId',
            }, {
                title: '状态',
                align: 'center',
                dataIndex: 'status',
                render: (text) => {
                    return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>)
                }
            }, {
                title: '操作',
                align: 'center',
                render: (text, item) => {
                    return <Button type="primary" icon="form" onClick={() => {
                        this.getPlayDetail(item._id);
                        this.setState({ detailModal: true });
                    }}>详情</Button>
                }
            }
        ];
        const FormList = [
            {
                label: "执行人",
                type: 'INPUT',
                field: "createUserId",
                initialValue: '',
                placeholder: "执行人"
            },

        ]
        return (
            <div>
                <CustomBreadcrumb arr={["密码变更管理", "操作记录"]} />
                <Card>
                    <FilterForm
                        formList={FormList}
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(_pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(_pageSize, 1, {})
                        }}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>操作记录列表</span>} extra={<Button type="primary" icon="lock" onClick={() => this.setState({ pwd_Vis: true })}>密码变更</Button>}
                    style={{ marginTop: 8 }}>
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        size="small"
                        loading={loading}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: total,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                this.updata(pageSize, page, querys);
                            }
                        }}
                    />
                </Card>
                <Modal
                    title="详情"
                    visible={detailModal}
                    onCancel={() => this.setState({ detailModal: false })}
                    onOk={() => this.setState({ detailModal: false })}
                    width="60%"
                    style={{ verticalAlign: "middle" }}
                    destroyOnClose
                >
                    <Table
                        columns={[
                            { title: '序号', align: 'center', dataIndex: 'key', render: (text, item, index) => { return <span>{index + 1}</span> } },
                            { title: "资源名称", dataIndex: "resourceName" },
                            { title: "设备IP", dataIndex: "deviceIp" },
                            { title: "时间", dataIndex: "addTime" },
                            { title: "输入", dataIndex: "input" },
                            { title: "旧账户", dataIndex: "old" },
                            { title: "新账户", dataIndex: "new" },
                            { title: "结果", dataIndex: "result", render: (text) => { return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>) } },
                        ]}
                        size="small"
                        dataSource={detailData}
                        loading={detailLoading}
                        pagination={false}
                    />
                </Modal>
                <PwdModal
                    title="密码变更"
                    visible={pwd_Vis}
                    onCancel={() => this.setState({ pwd_Vis: false })}
                    onSumit={() => { this.setState({ pwd_Vis: false }); this.updata(); }}
                    width="60%"
                    footer={null}
                    style={{ verticalAlign: "middle" }}
                    destroyOnClose
                />
            </div>
        )
    }
}
