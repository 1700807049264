import React from 'react';
import { Select, Tag, Tooltip, notification, message, Divider } from 'antd'
import FileSaver from 'file-saver';
import moment from 'moment';
import _Socket from "@/socket/index"
import * as XLSX from 'xlsx'
import JSEncrypt from 'jsencrypt';
const Option = Select.Option;
let projectThis = {};
export default {
    formateDate(time) {
        if (!time) return '';
        let date = new Date(time);
        return date.getFullYear() + '-' + this.addZero((date.getMonth() + 1)) + '-' + this.addZero(date.getDate()) + ' ' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date.getSeconds());
    },
    addZero(_data) {
        let data = _data
        if (data < 10) {
            data = "0" + data;
        }
        return data;
    },
    pagination(data, callback) {
        return {
            onChange: (current) => {
                callback(current)
            },
            current: data.result.page,
            pageSize: data.result.page_size,
            total: data.result.total_count,
            showTotal: () => {
                return `共${data.result.total_count}条`
            },
            showQuickJumper: true
        }
    },
    // 格式化金额,单位:分(eg:430分=4.30元)
    formatFee(fee, suffix = '') {
        if (!fee) {
            return 0;
        }
        return Number(fee).toFixed(2) + suffix;
    },
    // 格式化公里（eg:3000 = 3公里）
    formatMileage(mileage, text) {
        if (!mileage) {
            return 0;
        }
        if (mileage >= 1000) {
            text = text || " km";
            return Math.floor(mileage / 100) / 10 + text;
        } else {
            text = text || " m";
            return mileage + text;
        }
    },
    // 隐藏手机号中间4位
    formatPhone(phone) {
        phone += '';
        return phone.replace(/(\d{3})\d*(\d{4})/g, '$1***$2')
    },
    // 隐藏身份证号中11位
    formatIdentity(number) {
        number += '';
        return number.replace(/(\d{3})\d*(\d{4})/g, '$1***********$2')
    },
    getOptionList(data) {
        if (!data) {
            return [];
        }
        let options = [] //[<Option value="0" key="all_key">全部</Option>];
        data.map((item) => {
            options.push(<Option value={item.id} key={item.id}>{item.name}</Option>)
            return item.id
        });
        return options;
    },
    /**
     * ETable 行点击通用函数
     * @param {*选中行的索引} selectedRowKeys
     * @param {*选中行对象} selectedItem
     */
    updateSelectedItem(selectedRowKeys, selectedRows, selectedIds) {
        if (selectedIds) {
            this.setState({
                selectedRowKeys,
                selectedIds: selectedIds,
                selectedItem: selectedRows
            })
        } else {
            this.setState({
                selectedRowKeys,
                selectedItem: selectedRows
            })
        }
    },
    /*格式化json数据 给key加上双引号*/
    formatJsonKey(JsonStr) {
        // var json_string = "{   keyName : 34, 'keyCode' : '5554'}";
        // console.log(json_string);//{   keyName : 34, 'keyCode' : '5554'}
        // console.log(/(?:\s*['"]*)?([a-zA-Z0-9]+)(?:['"]*\s*)?:/g.test(json_string))//true
        // console.log(json_string.match(/(?:\s*['"]*)?([a-zA-Z0-9]+)(?:['"]*\s*)?:/g));//["   keyName :", " 'keyCode' :"]
        // json_string = json_string.replace(/(?:\s*['"]*)?([a-zA-Z0-9]+)(?:['"]*\s*)?:/g, "'$1':");
        // console.log(json_string);//{'keyName': 34,'keyCode': '5554'}
        // var json = eval('('+ json_string + ')');
        // console.log(json);//Object {keyName: 34, keyCode: "5554"}
        let formatData = '';
        formatData = JsonStr.replace(/(?:\s*['"]*)?([a-zA-Z0-9]+)(?:['"]*\s*)?:/g, "'$1':");
        return formatData;
    },
    /*两个数组合并并去重*/
    MergeArray(arr1, arr2) {

        if (!arr1) {
            arr1 = [];
        }
        if (!arr2) {
            arr2 = [];
        }
        let _arr = [];
        for (let i = 0; i < arr1.length; i++) {
            _arr.push(arr1[i]);
        }
        for (let i = 0; i < arr2.length; i++) {
            let flag = true;
            for (let j = 0; j < arr1.length; j++) {
                if (arr2[i] === arr1[j]) {
                    flag = false;
                    break;
                }
            }
            if (flag) {
                _arr.push(arr2[i]);
            }
        }
        return _arr;
    },
    /*字符串数组删除指定元素*/
    DelArrayItem(arr1, text) {
        let _arr = arr1;
        let index = arr1.indexOf(text);
        if (index > -1) {
            _arr.splice(index, 1);
        }
        return _arr;
    },
    /*字符串数组去重添加元素*/
    ArrayPushRequired(arr1, text) {
        let _arr = arr1;
        if (arr1.includes(text)) {
            return _arr;
        } else {
            _arr.push(text);
            return _arr;
        }
    },
    /*字典翻译*/
    Translation(text) {
        // let str = text.trim().toLowerCase();
        let str = text ? text.trim().toLowerCase() : text;
        switch (str) {
            case 'auto': str = '自动'; break;
            case 'manual': str = '手动'; break;
            case 'run': str = '执行中'; break;
            case 'done': str = '完成'; break;
            case 'new': str = '新建'; break;
            case 'terminal': str = '终止'; break;
            case 'resourceName': str = '资源名称'; break;
            case 'resourceDes': str = '资源描述'; break;
            case 'tags': str = '标签'; break;
            case 'resourceType': str = '资源类型'; break;
            case 'sshport': str = 'SSH登录端口'; break;
            case 'createTime': str = '创建时间'; break;
            case 'createUserId': str = '创建用户'; break;
            case 'updateTime': str = '更新时间'; break;
            case 'deviceId': str = '设备名称'; break;
            case 'deviceIp': str = '设备IP'; break;
            case 'deviceType': str = '设备类型'; break;
            case 'deviceOsType': str = '设备OS类型'; break;
            case 'deviceFirm': str = '厂商'; break;
            case 'deviceVersion': str = '型号'; break;
            case 'deviceOsVersion': str = 'OS版本'; break;
            case 'deviceUser': str = '登录用户名'; break;
            case 'devicePwd': str = '登录密码'; break;
            case 'deviceSite': str = '网点信息'; break;
            case 'deviceState': str = '设备状态'; break;
            case 'os_type': str = 'OS类型'; break;
            case 'community': str = '设备community'; break;
            case 'connectionType': str = '连接类型'; break;
            case 'userTel': str = '电话'; break;
            case 'userMail': str = '邮箱'; break;
            case 'department': str = '部门'; break;
            case 'userPwd': str = '用户密码'; break;
            case 'netDevice': str = '网络设备'; break;
            case 'physicalServer': str = '物理服务器'; break;
            case 'virtualSource': str = '虚拟资源'; break;
            case 'cloudSource': str = '云资源'; break;
            case 'appSource': str = '应用资源'; break;
            case 'success': str = '成功'; break;
            case 'fail': str = '失败'; break;
            case 'online': str = '在线'; break;
            case 'offline': str = '离线'; break;
            case 'none': str = '未知'; break;
            case 'SWITCH': str = <div><img src={"/image/L3-switch.svg"} className={"imgstyle"} />&nbsp;交换机</div>; break;
            case 'FIREWALL': str = <div><img src={"/image/firewall.png"} className={"imgstyle"} />&nbsp;防火墙</div>; break;
            case 'ROUTER': str = <div><img src={"/image/router.svg"} className={"imgstyle"} />&nbsp;路由器</div>; break;

            case 'switch': str = <div><img src={"/image/L3-switch.svg"} className={"imgstyle"} />&nbsp;交换机</div>; break;
            case 'firewall': str = <div><img src={"/image/firewall.png"} className={"imgstyle"} />&nbsp;防火墙</div>; break;
            case 'router': str = <div><img src={"/image/router.svg"} className={"imgstyle"} />&nbsp;路由器</div>; break;

            case 'CISCO': str = <div><img src={"/image/" + str.toLowerCase() + ".jpg"} className={"imgstyle"} />&nbsp;CISCO</div>; break;
            case "HUAWEI": str = <div><img src={"/image/" + str.toLowerCase() + ".jpg"} className={"imgstyle"} />&nbsp;HUAWEI</div>; break;
            case "H3C": str = <div><img src={"/image/" + str.toLowerCase() + ".jpg"} className={"imgstyle"} />&nbsp;H3C</div>; break;
            case "MAIPU": str = <div><img src={"/image/" + str.toLowerCase() + ".jpg"} className={"imgstyle"} />&nbsp;MAIPU</div>; break;
            case "RUIJIE": str = <div><img src={"/image/" + str.toLowerCase() + ".jpg"} className={"imgstyle"} />&nbsp;RUIJIE</div>; break;
            case "HILLSTONE": str = <div><img src={"/image/" + str.toLowerCase() + ".jpg"} className={"imgstyle"} />&nbsp;HILLSTONE</div>; break;

            case 'cisco': str = <div><img src={"/image/" + str.toLowerCase() + ".jpg"} className={"imgstyle"} />&nbsp;CISCO</div>; break;
            case "huawei": str = <div><img src={"/image/" + str.toLowerCase() + ".jpg"} className={"imgstyle"} />&nbsp;HUAWEI</div>; break;
            case "h3c": str = <div><img src={"/image/" + str.toLowerCase() + ".jpg"} className={"imgstyle"} />&nbsp;H3C</div>; break;
            case "maipu": str = <div><img src={"/image/" + str.toLowerCase() + ".jpg"} className={"imgstyle"} />&nbsp;MAIPU</div>; break;
            case "ruijie": str = <div><img src={"/image/" + str.toLowerCase() + ".jpg"} className={"imgstyle"} />&nbsp;RUIJIE</div>; break;
            case "hillstone": str = <div><img src={"/image/" + str.toLowerCase() + ".jpg"} className={"imgstyle"} />&nbsp;HILLSTONE</div>; break;
            case "dp": str = "迪普"; break;
            case "loadBalance": str = "负载均衡"; break;

            case 'rule_name': str = '策略名称'; break;
            case 'add_time': str = '策略添加时间'; break;
            case 'policy_matched': str = '策略命中数'; break;
            case 'description': str = '策略描述'; break;
            case 'source_zone': str = '源区域'; break;
            case 'destination_zone': str = '目的区域'; break;
            case 'source_address': str = '源地址'; break;
            case 'destination_address': str = '目的地址'; break;
            case 'application': str = '策略开通应用'; break;
            case 'action': str = '策略开通权限'; break;
            case 'service': str = '策略开通的服务名称'; break;
            case 'time_range': str = '策略开通时间段名称'; break;
            case 'time_range_details': str = '策略开通时间段详情'; break;
            case 'service_details': str = '策略开通服务详情'; break;
            case 'policy_analyze_result': str = '策略分析结果'; break;
            case 'auto_execute_time': str = '自动执行时间'; break;
            case 'automatic': str = '是否自动执行'; break;
            case 'concurrent': str = '任务并发'; break;
            case 'create_time': str = '创建时间'; break;
            case 'ignore_error': str = '执行是否忽略错误'; break;
            case 'mail': str = '邮箱'; break;
            case 'reviewer': str = '审批人'; break;
            case 'status': str = '状态'; break;
            case 'task_name': str = '任务名称'; break;
            case 'type': str = '类型'; break;
            case 'update_time': str = '更新时间'; break;
            case 'username': str = '用户'; break;
            default:
                break;
        }
        return str;
    },
    /*任务状态颜色*/
    ColorShow(text) {
        let str = '#58a3f7';
        switch (text) {
            case 'run':
                str = '#58a3f7';
                break;
            case 'done':
                str = '#87d068';
                break;
            case 'success':
                str = '#87d068';
                break;
            case 'new':
                str = '#87CEFF';
                break;
            case 'terminal' || 'fail':
                str = '#ff0000';
                break;
            case 'fail':
                str = '#ff0000';
                break;
            default:
                break;
        }
        return str;
    },
    /**
     * 导出EXCEL文件
     */
    exportExcel(json, fileName, SheetNames = '资源列表') {
        const type = 'xlsx'//定义导出文件的格式
        var tmpDown;//导出的内容
        var tmpdata = json[0];
        json.unshift({});
        var keyMap = []; //获取keys
        for (var k in tmpdata) {
            keyMap.push(k);
            json[0][k] = k;
        }
        var tmpdata = [];//用来保存转换好的json

        json.map((v, i) => keyMap.map((k, j) => Object.assign({}, {
            v: v[k],
            position: (j > 25 ? this.getCharCol(j) : String.fromCharCode(65 + j)) + (i + 1)
        }))).reduce((prev, next) => prev.concat(next)).forEach((v, i) => tmpdata[v.position] = {
            v: v.v
        });
        var outputPos = Object.keys(tmpdata); //设置区域,比如表格从A1到D10
        var tmpWB = {
            SheetNames: [SheetNames], //保存的表标题
            Sheets: {
                [SheetNames]: Object.assign({},
                    tmpdata, //内容
                    {
                        '!ref': outputPos[0] + ':' + outputPos[outputPos.length - 1] //设置填充区域
                    })
            }
        };
        tmpDown = new Blob([this.s2ab(XLSX.write(tmpWB,
            { bookType: (type == undefined ? 'xlsx' : type), bookSST: false, type: 'binary' }//这里的数据是用来定义导出的格式类型
        ))], {
            type: ""
        }); //创建二进制对象写入转换好的字节流
        this.saveAs(tmpDown, fileName);
    },
    /**
 * 导出完整EXCEL文件
 */
    exportSheetExcel(json, SheetNames, fileName) {
        const type = 'xlsx'//定义导出文件的格式
        var tmpDown;//导出的内容
        var tmpdata = json[0];
        json.unshift({});
        var keyMap = []; //获取keys
        for (var k in tmpdata) {
            keyMap.push(k);
            json[0][k] = k;
        }
        var tmpdata = [];//用来保存转换好的json

        json.map((v, i) => keyMap.map((k, j) => Object.assign({}, {
            v: v[k],
            position: (j > 25 ? this.getCharCol(j) : String.fromCharCode(65 + j)) + (i + 1)
        }))).reduce((prev, next) => prev.concat(next)).forEach((v, i) => tmpdata[v.position] = {
            v: v.v
        });
        var outputPos = Object.keys(tmpdata); //设置区域,比如表格从A1到D10
        var tmpWB = {
            SheetNames: [SheetNames], //保存的表标题 
            Sheets: {}
        };
        tmpWB.Sheets[SheetNames] = Object.assign({},
            tmpdata, //内容
            {
                '!ref': outputPos[0] + ':' + outputPos[outputPos.length - 1] //设置填充区域
            });
        tmpDown = new Blob([this.s2ab(XLSX.write(tmpWB,
            { bookType: (type == undefined ? 'xlsx' : type), bookSST: false, type: 'binary' }//这里的数据是用来定义导出的格式类型
        ))], {
            type: ""
        }); //创建二进制对象写入转换好的字节流
        this.saveAs(tmpDown, fileName);
    },
    saveAs(obj, fileName) {//导出功能实现
        var tmpa = document.createElement("a");
        tmpa.download = fileName || "下载";
        tmpa.href = URL.createObjectURL(obj); //绑定a标签
        tmpa.click(); //模拟点击实现下载
        setTimeout(function () { //延时释放
            URL.revokeObjectURL(obj); //用URL.revokeObjectURL()来释放这个object URL
        }, 100);
    },
    s2ab(s) { //字符串转字符流
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    },
    getCharCol(n) {
        let temCol = '',
            s = '',
            m = 0
        while (n > 0) {
            m = n % 26 + 1
            s = String.fromCharCode(m + 64) + s
            n = (n - m) / 26
        }
        return s
    },
    isEmpty(obj) {
        // 检验 undefined 和 null和 空json{}
        if (!obj && obj !== 0 && obj !== '') {
            return true;
        }
        if (Array.prototype.isPrototypeOf(obj) && obj.length === 0) {
            return true;
        }
        if (Object.prototype.isPrototypeOf(obj) && Object.keys(obj).length === 0) {
            return true;
        }
        return false;
    },
    //深拷贝对象
    deepCopy(source) {
        let result = {};
        for (let key in source) {
            if (typeof source[key] === 'object') {
                result[key] = this.deepCopy(source[key])
            } else {
                result[key] = source[key]
            }
        }
        return result;
    },
    getHR() {
        return <Divider style={{ margin: "10px 0" }} />;
    },
    getSystemTiltle(versionName) {
        if (versionName === "pingan") {
            return "网络运维管理平台"
        } else if (versionName === "pinganTerminal") {
            return "终端接入管理平台"
        } else {
            return "自动化运维平台"
        }
    },
    childrenListFun(data) {
        let childrenList = [];
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            const key = node.key;
            if (node.children && node.children[0].display === "yes") {
                // if(node.type==="fixed"){
                childrenList.push({ key, title: node.title, icon: node.icon, children: this.childrenListFun(node.children) });
                // }
            } else {
                childrenList.push({ key, title: node.title, icon: node.icon });
            }
        }
        return childrenList;
    },
    clearDisplayNo(data) {
        let newData = [];
        data.map((item, index) => {
            if (item.display === "yes") {
                let newNode = { key: item.key, title: item.title, icon: item.icon, }
                if (item.children) {
                    newNode.children = this.clearDisplayNo(item.children);
                }
                newData.push(newNode);
            }
        })
        return this.isEmpty(newData) ? null : newData;
    },
    //菜单图标
    menuIconFun(data) {
        let childrenList = [];
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            const key = node.key;
            let _icon = 'desktop';
            switch (key) {
                case '/user': _icon = 'user'; break;
                case '/task': _icon = 'table'; break;
                case '/system': _icon = 'gold'; break;
                case '/role': _icon = 'global'; break;
                case '/home': _icon = 'desktop'; break;
                case '/history': _icon = 'fund'; break;
                case '/operating': _icon = 'tool'; break;
                case '/resource': _icon = 'global'; break;
                case '/fireWallMan': _icon = 'fire'; break;
                case '/configManger': _icon = 'diff'; break;
                case '/taskManger': _icon = 'project'; break;
                case '/overView': _icon = 'bar-chart'; break;
                case '/portManagement': _icon = 'apartment'; break;
                case '/portManagement/switch': _icon = 'hdd'; break;
                case '/fireWallMan/config': _icon = 'control'; break;
                case '/fireWallMan/IPconfig': _icon = 'control'; break;
                case '/portManagement/task': _icon = 'profile'; break;
                case '/permission': _icon = 'safety-certificate'; break;
                case '/fireWallMan/PlayRecord': _icon = 'file-text'; break;
                case '/fireWallMan/IPPlayRecord': _icon = 'file-text'; break;
                case '/portManagement/PortRecord': _icon = 'cluster'; break;
                case '/portManagement/PlayRecord': _icon = 'file-text'; break;
                case '/passwordMan': _icon = 'lock'; break;
                case '/passwordMan/PwdAudits': _icon = 'file-exclamation'; break;
                case '/passwordMan/PwdHistory': _icon = 'file-text'; break;
                case '/tagManagement': _icon = 'tags'; break;
                case '/tagManagement/tagList': _icon = 'tag'; break;
                case '/tagManagement/groupList': _icon = 'appstore'; break;
                case '/InspectionMan': _icon = 'file-done'; break;
                case '/InspectionMan/taskList': _icon = 'bars'; break;
                case '/IPAdmin': _icon = 'snippets'; break;
                case '/IpAdmin/ipfunction': _icon = 'global'; break;
                case '/IpAdmin/Segment': _icon = 'deployment-unit'; break;
                case '/IpAdmin/IPRecord': _icon = 'gateway'; break;
                case '/LogMange': _icon = 'schedule'; break;
                case '/LogMange/sysFileLog': _icon = 'file-text'; break;
                case '/LogMange/deviceLog': _icon = 'file-text'; break;
                case '/dns': _icon = 'cloud-server'; break;
                case '/dns/dnsServer': _icon = 'file-text'; break;
            }
            if (node.children) {
                childrenList.push({ key, title: node.title, icon: _icon, children: this.menuIconFun(node.children) });
            } else {
                childrenList.push({ key, title: node.title, icon: _icon });
            }

        }
        return childrenList;
    },
    getChildrenDisplayKey(data) {
        let ChildrenDisplayKey = [];
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            const key = node.key;
            if (node.display === 'no') {
                ChildrenDisplayKey.push(key);
            }
            if (node.children) {
                ChildrenDisplayKey = this.MergeArray(ChildrenDisplayKey, this.getChildrenDisplayKey(node.children));
            }
        }
        return ChildrenDisplayKey;
    },
    /**
     * 树形图是否包含某个key
     * @param {*} data 
     * @param {*} key 
     */
    includsKey(data, key) {
        let flag = data.some((node) => {
            if (node.key === key) {
                console.log(node.key, key);
                return true;
            } else if (node.children) {
                return this.includsKey(node.children, key);
            }
        })
        return flag;
    },
    keyToTitleByMenu(menu, key) {
        // console.log(menu, key);
        // let val;
        return menu.map((item) => {
            if (item.key === key) {
                // return { title: item.title, icon: item.icon };
                return item.title;
            } else if (item.children) {
                return this.keyToTitleByMenu(item.children, key);
            }
        });
        // for (let i = 0; i < menu.length; i++) {
        //     let item = menu[i];
        //     if (item.key === key) {
        //         // return { title: item.title, icon: item.icon };
        //         val = { title: item.title, icon: item.icon };
        //         return;
        //         // break;
        //     } else if (item.children) {
        //         val = this.keyToTitleByMenu(item.children, key);
        //     }
        // }

        // menu.map((item) => {
        //     console.log(item.key, key);
        //     if (item.key === key) {
        //         val = item;
        //     }
        // })
        // return val;
    },

    DataAddItem(list) {
        let data = list.map((item, index) => {
            item.key = index;
            item.index = index;
            return item;
        });
        return data;
    },
    // 字符串转base64
    StrToBase64(str) {
        // 对字符串进行编码
        let encode = encodeURI(str);
        // 对编码的字符串转化base64
        let base64 = btoa(encode);
        return base64;
    },
    //加密
    EncryptPwd(str) {
        // const encrypt = new JSEncrypt();
        // encrypt.setPublicKey('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQD1CsQ4FX/ez1kj7PKWC8jOnUFPfbWGpCXCpqz7rQmDXLSyaelsb/lxMGnG21dBoRQ1MLBFfk3ej+dtk9w7WtNi92bHJMptA2qXyVpWczIcb8EBpPG11Xf5SrwcZZK5qXbIsVuXlbyVxCTNVvieFZEkIpOWU+R7Wv1s/clywAHR6QIDAQAB');
        // const password = encrypt.encrypt(str);// 加密后的字符串
        // return password;

        // 对字符串进行编码
        const encode = encodeURI(str);
        // 对编码的字符串转化base64
        const base64 = btoa(encode);
        return base64;
    },
    //解密
    DecryptPwd(str) {
        // const decrypt = new JSEncrypt();
        // decrypt.setPrivateKey("MIICXQIBAAKBgQD1CsQ4FX/ez1kj7PKWC8jOnUFPfbWGpCXCpqz7rQmDXLSyaels"
        //     + "b/lxMGnG21dBoRQ1MLBFfk3ej+dtk9w7WtNi92bHJMptA2qXyVpWczIcb8EBpPG1"
        //     + "1Xf5SrwcZZK5qXbIsVuXlbyVxCTNVvieFZEkIpOWU+R7Wv1s/clywAHR6QIDAQAB"
        //     + "AoGAE9VyLOpGk5RbuM3xaQYyqSXu1vOL9BuIspDsSvcGSwOVB6TiynlkwA3lCJNm"
        //     + "jw7DPzaTP0i6Hk6Dsv7xvOcnhABe3ezAHr+gwxogw6C8Fe11we2/TTvvLSrL0Anp"
        //     + "9y1L8aa83mf6ThhjpRY1s8YnyBrTg1xGJHX62D2WxPJlBAUCQQD23HLLRCB/SIF/"
        //     + "gjyiasD7hOffZE/yyZ1ZwHTCcV80nulAvNeK5cmYbF1UtwSmuruN6q13dwW+lfmv"
        //     + "9YKSNJwlAkEA/h0UEMc+utwSEiyqFt3GOCl8VKw7rLLJQH0TkGvVoLfMZHxZjaA9"
        //     + "bIjJKx1YPYh3xwaQxby0DLcBMjLO0bgRdQJAOsexGc9mAYjNNQRY1b+fpgQ2hg3H"
        //     + "r6LsMbPO2YHPTqNMHRsaI7ulbgLXklVl6SFriVZI6bpQhtoFMy29YnzedQJBALje"
        //     + "8N0wB2wsdR0xz2CpYe80B1cWoqeGvrGjDVuxiA7QCOxGJ2TI4kcW0LwEMlE5+mIa"
        //     + "XbLuEVN6xGz/18Vtk40CQQC5Hd2WTMQbqPLzkM/qXqUEwy1NpmNFPALpmfCR//nr"
        //     + "twh3i4Eny1Px/CqChwZOPPByy07L5BlCgXwGnYcs4Nc2")
        // const decrypted = decrypt.decrypt(str);
        // if (!decrypted) { console.log("解密为空!"), decrypted }
        // return decrypted;

        // // 对base64转编码
        const decode = atob(str);
        // 编码转字符串
        const password = decodeURI(decode);
        return password;
    },
    // base64转字符串
    Base64ToStr(base64) {
        // // 对base64转编码
        let decode = atob(base64);
        // 编码转字符串
        let str = decodeURI(decode);
        // let str = Buffer.from(base64, 'base64').toString('ascii');
        return str;
    },
    /**
     * 下载文件
     * @param {*文件名} fileName 
     * @param {*文件内容} particleData 
     */
    download(fileName, particleData) {

        // 将指定数据转换为 JSON 字符串
        let content = particleData;
        if (typeof particleData === "object") {
            content = JSON.stringify(particleData, null, 5);
        }

        // 数组内容的MIME类型为json
        let type = "data:application/json;charset=utf-8";

        // 实例化Blob对象，并传入参数        
        let blob = new Blob([content], { type: type });

        let isFileSaverSupported = false;
        try {
            isFileSaverSupported = !!new Blob();
        } catch (e) {
            console.log(e);
        }

        if (isFileSaverSupported) {
            FileSaver.saveAs(blob, fileName);
        } else {
            FileSaver.open(encodeURI(type + "," + content));
        }
    },
    //ZIP包下载
    downloadZIP(fileName, jsonData) {
        // var imgData = "R0lGODdhBQAFAIACAAAAAP/eACwAAAAABQAFAAACCIwPkWerClIBADs=";
        // var img = zip.folder("images");
        // img.file("smile.gif", imgData, { base64: true });
        // const zip = new JSZip();
        const zip = require('jszip')();
        jsonData.map((item) => {
            zip.file(item.name + ".txt", item.data);
        })
        zip.generateAsync({ type: "blob" }).then(function (content) {
            FileSaver.saveAs(content, fileName + ".zip");
        });
    },
    //ZIP包下载文件夹
    downloadFolderZIP(zipName, jsonData) {
        // let data = {
        //     "10.32.79.19": { //文件夹名称
        //         "2020_25_99.log":"datatext"  //文件名-文件数据
        //     }
        // }
        const zip = require('jszip')();
        for (let folder in jsonData) {
            let folder_obj = zip.folder(folder)
            for (let fileName in jsonData[folder]) {
                folder_obj.file(fileName, jsonData[folder][fileName]);
            }
        }
        zip.generateAsync({ type: "blob" }).then(function (content) {
            FileSaver.saveAs(content, zipName + ".zip");
        });
    },
    //判断是否是空对象
    isEmptyObject(obj) {
        for (let key in obj) {
            return false
        };
        return true
    },
    //tag数据过长处理
    hidenTag(tagArr) {
        if (!tagArr) { return "" }
        if (typeof (tagArr) === "string") { return <Tag closable={false} color="blue">{tagArr}</Tag> }
        return tagArr.map((tag, index) => {
            const longFlag = 5;
            const isLongTag = tag.length > longFlag;
            const tagElem = (
                <Tag key={tag} closable={false} color="blue">
                    {isLongTag ? `${tag.slice(0, longFlag)}...` : tag}
                </Tag>
            );
            // return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
            return <Tooltip title={tag} key={tag}>{tagElem}</Tooltip>;
        })
    },
    hidenText(text) {
        if (!text) { return "" }
        // return tagArr.map((tag, index) => {
        const longFlag = 100;
        const isLongTag = text.length > longFlag;
        const tagElem = isLongTag ? `${text.slice(0, longFlag)}...` : text;


        // return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
        return <Tooltip title={text} >{tagElem}</Tooltip>;
        // })
    },
    //隐藏字符串
    hidenLongText(text, num) {
        if (!text) { return "" }
        // return tagArr.map((tag, index) => {
        const longFlag = num;
        const isLongTag = text.length > longFlag;
        const tagElem = isLongTag ? `${text.slice(0, longFlag)}...` : text;
        return <Tooltip placement="topLeft" title={text} >{tagElem}</Tooltip>;
    },
    //显示信息
    showMessage(type, message, description) {
        notification[type]({
            message,
            description,
        });
    },
    //表格相同单元格合并
    mixTable(tabId) {//表格相同单元格合并
        var tab = document.getElementById(tabId);
        // console.log(tab.innerHTML)
        if (!tab) return;
        var maxCol = 3, val, count, start;
        let regex1 = /\>(.+?)\</g;  // >< 
        try {
            for (var col = maxCol - 1; col >= 0; col--) {
                count = 1;
                val = "";
                for (var i = 0; i < tab.rows.length; i++) {
                    let mactVal = val.match(regex1) || ["0"];
                    // if (val == tab.rows[i].cells[col].innerHTML && mactVal[0] != "0" && col != 2) {//col != 最后一列不合并
                    if (val == tab.rows[i].cells[col].innerHTML && val.trim() != "") {//col != 最后一列不合并
                        count++;
                    } else {
                        if (count > 1) { //合并
                            start = i - count;
                            tab.rows[start].cells[col].rowSpan = count;
                            for (var j = start + 1; j < i; j++) {
                                // tab.rows[j].cells[col].style.display = "none";
                                tab.rows[j].removeChild(tab.rows[j].cells[col]);
                            }
                            count = 1;
                        }
                        val = tab.rows[i].cells[col].innerHTML;
                    }
                }
                if (count > 1) { //合并，最后几行相同的情况下
                    start = i - count;
                    tab.rows[start].cells[col].rowSpan = count;
                    for (var j = start + 1; j < i; j++) {
                        // tab.rows[j].cells[col].style.display = "none";
                        tab.rows[j].removeChild(tab.rows[j].cells[col]);
                    }
                }
            }
        } catch (e) {
            message.error("错误");
            console.error(e);
        }
    },
    //数据高亮
    isHightLight(_text1) {//数据高亮
        // console.log(_text);
        // if (!_text) return <sapn></sapn>;
        let _text = JSON.stringify(_text1) + "";
        let fonstEle = <sapn >{_text}</sapn>;
        const textAdd = _text.split("<ADD>");
        const textDel = _text.split("<DEL>");
        const textArr = _text.split("<HL>");
        if (textAdd.length > 1) {
            fonstEle = <sapn style={{ background: "#87d068", color: "white", padding: 5, borderRadius: 1 }}>{textArr[1]}</sapn>;
        }
        if (textDel.length > 1) {
            fonstEle = <sapn style={{ background: "#ff6666", color: "white", padding: 5, borderRadius: 1 }}>{textArr[1]}</sapn>;
        }
        if (textArr.length > 1) {
            fonstEle = <sapn style={{ background: "#ff9558", color: "white", padding: 5, borderRadius: 1 }}>{textArr[1]}</sapn>;
        }
        return fonstEle;
    },
    //获取标签和分组
    getTagGroupName(tagClass, callback) {
        _Socket.ExecuteAction('QueryTagsGroups', { tagClass }, "/QueryTagsGroups", (result) => {
            callback(result.tags, result.groupNames);
        });
    },

    utc2beijing(utc_datetime) {
        let beijing_datetime = utc_datetime;
        if (typeof utc_datetime === "string") {
            // 转为正常的时间格式 年-月-日 时:分:秒
            let T_pos = utc_datetime.indexOf('T');
            let Z_pos = utc_datetime.indexOf('Z');
            let year_month_day = utc_datetime.substr(0, T_pos);
            let hour_minute_second = utc_datetime.substr(T_pos + 1, Z_pos - T_pos - 1);
            let new_datetime = year_month_day + " " + hour_minute_second; // 2017-03-31 08:02:06

            // 处理成为时间戳
            let timestamp = new Date(Date.parse(new_datetime));
            timestamp = timestamp.getTime();
            timestamp = timestamp / 1000;

            // 增加8个小时，北京时间比utc时间多八个时区
            timestamp = timestamp + 8 * 60 * 60;

            // 时间戳转为时间
            beijing_datetime = moment(new Date(parseInt(timestamp) * 1000)).format("YYYY-MM-DD HH:mm:ss")
            // beijing_datetime = new Date(parseInt(timestamp) * 1000).toLocaleString().replace(/年|月/g, "-").replace(/日/g, " ");
        } else if (typeof utc_datetime === "number") {
            // beijing_datetime = new Date(utc_datetime).toLocaleString().replace(/年|月/g, "-").replace(/日/g, " ");
            beijing_datetime = moment(new Date(utc_datetime)).format("YYYY-MM-DD HH:mm:ss")
        }
        return beijing_datetime; // 2017-03-31 16:02:06
    },
    logtimestr2beijing(utc_datetime) {
        let beijing_datetime = utc_datetime;
        if (typeof utc_datetime === "string") {
            // 转为正常的时间格式 年-月-日 时:分:秒
            let T_pos = utc_datetime.indexOf('T');
            let Z_pos = utc_datetime.indexOf('Z');
            let year_month_day = utc_datetime.substr(0, T_pos);
            let hour_minute_second = utc_datetime.substr(T_pos + 1, Z_pos - T_pos - 1);
            let new_datetime = year_month_day + " " + hour_minute_second; // 2017-03-31 08:02:06

            // 处理成为时间戳
            let timestamp = new Date(Date.parse(new_datetime));
            timestamp = timestamp.getTime();
            timestamp = timestamp / 1000;
            // 无需增加8个小时，log采集已经处理
            // 时间戳转为时间
            beijing_datetime = moment(new Date(parseInt(timestamp) * 1000)).format("YYYY-MM-DD HH:mm:ss")
        } else if (typeof utc_datetime === "number") {
            beijing_datetime = moment(new Date(utc_datetime)).format("YYYY-MM-DD HH:mm:ss")
        }
        return beijing_datetime; // 2017-03-31 16:02:06
    },
    //设置配置备份this
    setProjectThis(_this) {
        projectThis = _this;
    },
    //获取配置备份this
    getProjectThis() {
        return projectThis;
    },
    //滚动条置顶
    goTOP() {
        //滚动条置顶
        const BackTopElem = document.getElementsByClassName("BackTopClass")[0];
        if (BackTopElem) {
            BackTopElem.click();
        }
    },
    //字体加粗
    BoldFont(title) {//字体加粗
        return <span style={{ fontWeight: 600 }}>{title}</span>
    },
    //锚链接跳转函数
    scrollToAnchor(anchorName) {
        if (anchorName) {
            anchorName = anchorName.replace("#", "");
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) { anchorElement.scrollIntoView(); }
        }
    },
    //获取当前用户
    getLoginUser() {
        return sessionStorage.getItem('userName') + "/" + sessionStorage.getItem('nickName')
    },
    getLoginUserName() {
        return sessionStorage.getItem('userName');
    },
    ChineseToenglish(str) {
        let newStr = str;
        switch (str) {
            case "华为": newStr = "HUAWEI"; break;
            case "华三": newStr = "H3C"; break;
            case "思科": newStr = "CISCO"; break;
            case "迈普": newStr = "MAIPU"; break;
            case "锐捷": newStr = "RUIJIE"; break;
        }
        return newStr;
    },
    getScreenSize() {
        return { height: window.screen.height, width: window.screen.width };
    },
    // 进入全屏
    launchFullScreen(element1) {
        const element = element1 || document.documentElement
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    },
    //// 退出全屏
    exitFullScreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
        } else if (document.mozExitFullScreen) {
            document.mozExitFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    },
    createTopoData(initDat) {
        if (initDat.nodes) { return initDat };//已保存的拓扑图数据
        const deviceList = initDat;
        const flowData = { "nodes": [], "edges": [], }
        const nodeArr2 = [];
        const edgesArr = [];
        // console.log(initDat)
        deviceList.map((dev, index) => {
            let img = "/image/other.png";
            // let img = "image/L3-switch.svg";
            switch (dev.type) {
                case "switch": img = "/image/l3-switch.png"; break;
                case "router": img = "/image/router.png"; break;
                // case "router": img = "/image/router.svg"; break;
                case "firewall": img = "/image/firewall-2.png"; break;
                default: img = "/image/other.png";
            }
            nodeArr2.push({
                "size": [40, 40],
                "type": "image",
                "label": dev.ip,
                "img": img,
                "style": {
                    "selected": {
                        "opacity": 0.6
                    },
                    "cursor": "pointer"
                },
                "id": dev.ip,
                dev_ip: dev.ip,
                dev_firm: dev.firm,
                dev_hostname: dev.hostname,
                dev_status: dev.status,
                dev_type: dev.type,
                dev_model: dev.model,
                port_list: dev.info
            });
            dev["link"].map((item, eindex) => {
                let tempEdges = {
                    "source": item.start_ip, "target": item.end_ip, "id": new Date().getTime() + "-" + index + "-" + eindex,
                    "start_port": item.start_interface, "end_port": item.end_interface,
                };
                if (item.type) {
                    // tempEdges.type = 'cubic-vertical';
                    // tempEdges.type = 'quadratic';
                    // tempEdges.curveOffset = 5;
                    // tempEdges.curvePosition = 1;
                    // tempEdges.controlPoints = [{ x: 20, y: 30 }];
                    tempEdges.style = {
                        stroke: "#40a9ff",
                        lineWidth: 2,
                        lineAppendWidth: 5,
                        cursor: "pointer",
                    }
                }
                edgesArr.push(tempEdges);
            })
        })
        // console.log(nodeArr2.map((cc) => { return cc.x }))
        // console.log(nodeArr2.map((cc) => { return cc }))
        flowData.nodes = nodeArr2;
        flowData.edges = edgesArr;
        return flowData;
    },
    /*判断字符串是否是JSON字符串格式*/
    isJSON(str) {
        if (typeof str == 'string') {
            try {
                const obj = JSON.parse(str);
                if (typeof obj == 'object' && obj) {
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                console.log('error：' + str + '!!!' + e);
                return false;
            }
        } else {
            return false;
        }
    },
    getAllDate(day1, day2) {
        //计算两个日期之间的所有日期
        var getDate = function (str) {
            var tempDate = new Date();
            var list = str.split("-");
            tempDate.setFullYear(list[0]);
            tempDate.setMonth(list[1] - 1);
            tempDate.setDate(list[2]);
            return tempDate;
        }
        var date1 = getDate(day1);
        var date2 = getDate(day2);
        if (date1 > date2) {
            var tempDate = date1;
            date1 = date2;
            date2 = tempDate;
        }
        date1.setDate(date1.getDate() + 1);
        var dateArr = [];
        var i = 0;
        while (!(date1.getFullYear() == date2.getFullYear()
            && date1.getMonth() == date2.getMonth() && date1.getDate() == date2
                .getDate())) {
            var dayStr = date1.getDate().toString();
            var monthStr = (date1.getMonth() + 1).toString();
            if (dayStr.length == 1) {
                dayStr = "0" + dayStr;
            }
            if (monthStr.length == 1) {
                monthStr = "0" + monthStr;
            }
            dateArr[i] = date1.getFullYear() + "-" + (monthStr) + "-"
                + dayStr;
            i++;
            date1.setDate(date1.getDate() + 1);
        }
        dateArr.splice(0, 0, day1)
        dateArr.push(day2);
        return dateArr;
    }
}