import React from 'react';
import { Card, Button, Modal, Tag, message, Table, Tooltip, } from 'antd';
import axios_temp from '@/axios/axios_utils';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import "./index.scss";
import * as XLSX from 'xlsx';
import Utils from "@/utils/utils";
const smsUrl = "http://" + backup_address + "/ZJNMS_V1/sms_monitor/";
export default class DeviceCheck extends React.Component {
    state = {
        tags: [],
        inputVisible: false,
        conVis: false,
        editCheckVis: false,
        historyVis: false,
        inputValue: '',
        configData: {},
        current: 1,
        pageSize: 20,
        tableData: [],
        loading: false,
        total: 0,
        selectedRowKeys: [],
    };
    componentWillMount() {
        this.getHistoryList()
    }

    openMonitorConfig() {
        const temp_url = smsUrl + "device"
        axios_temp.get(temp_url, {}, (result) => {
            console.log(result.ips)
            this.setState({ conVis: true, tags: result.ips.map((ip, key) => { ip.key = key; return ip }) })
        });
    }



    getHistoryList(page, page_size) {
        this.setState({ loading: true })
        let { current, pageSize } = this.state;
        let bodyJson = {
            page_no: page || current,
            page_size: page_size || pageSize
        }
        axios_temp.get(smsUrl + "record", bodyJson, (res) => {
            if (res.code === "00") {
                this.setState({
                    tableData: res.data,
                    total: res.count,
                    current: page || 1,
                    loading: false
                })
            } else {
                message.error("查询失败");
                this.setState({ loading: false })
            }
        })
    }
    deleteHistory(ids) {
        let _this = this;
        if (ids.length === 0) {
            message.error("请选择需要删除的记录！")
        } else {
            Modal.confirm({
                title: '消息提示',
                content: '你确定删除该记录吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    axios_temp.delete_body(smsUrl + "record", { _ids: ids }, (res) => {
                        console.log(res)
                        if (res.code === "00") {
                            message.success(res.msg);
                            _this.setState({ selectedRowKeys: [] });
                            _this.getHistoryList();
                        } else {
                            message.error("删除失败")
                        }
                    })
                },
            });
        }
    }



    render() {
        const { tableData, loading, current, pageSize, total, selectedRowKeys } = this.state;

        const columns = [
            {
                title: '序号',
                width: 60,
                align: 'left',
                dataIndex: 'key',
                render: (text, item, index) => {
                    return <span>{index + 1 + (current - 1) * pageSize}</span>
                }
            },
            {
                title: '记录信息',
                align: 'left',
                dataIndex: 'ip_list',
                width: 300,
                ellipsis: true,
                render: (text, item) => {
                    if (item.type === "ping") {
                        return <Table
                            dataSource={text || []}
                            scroll={{ y: 170 }}
                            columns={[{ title: "IP地址", dataIndex: "ip" }, { title: "丢包率", dataIndex: "packet_loss" }, { title: "备注", dataIndex: "remarks" }]}
                            size="small"
                            pagination={false}
                        />
                    } else {
                        return ""
                    }

                    // return <div className="ipLossDiv">{text.map((one) => {
                    //     return <div>{one.ip} / {one.packet_loss * 100}% / {one.remarks}</div>
                    // })}</div>
                }
            },
            {
                title: '发送内容',
                align: 'left',
                dataIndex: 'content',
                // width: 120,
                ellipsis: true,
                render: (text) => {
                    return <div className="msgDiv">{text}</div>
                }
            },
            {
                title: '联系人列表',
                align: 'left',
                dataIndex: 'phones',
                // width: 240,
                ellipsis: true,
                render: (text) => {
                    return <div className="phoneDiv">{text.map((t) => t + "\r\n")}</div>
                }
            },
            {
                title: '发送计数',
                align: 'center',
                dataIndex: 'count',
                // width: 120,
                ellipsis: true,
            },
            {
                title: '记录类型',
                align: 'center',
                dataIndex: 'type',
                ellipsis: true
            },
            {
                title: '发送状态',
                align: 'center',
                dataIndex: 'sms_success',
                // width: 100,
                ellipsis: true,
                render: (text, item) => {
                    switch (text) {
                        case false: return <Tooltip title={item.tips}><Tag color="red">失败</Tag></Tooltip>;
                        case true: return <Tooltip title={item.tips}><Tag color="green">成功</Tag></Tooltip>;
                    }
                }
            },
            {
                title: '时间',
                align: 'center',
                dataIndex: 'time',
                // width: 220,
                ellipsis: true,
            },
            {
                title: '操作',
                align: 'center',
                dataIndex: 'op',
                width: 100,
                render: (text, item) => {
                    return <Button type="danger" size="small" icon="delete" onClick={() => this.deleteHistory([item._id])}>删除</Button>
                }
            },
        ]
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };

        return <div className="hostPingDiv">
            <CustomBreadcrumb arr={["监控告警", "检测记录"]} />
            <Card title="检测记录" extra={<div>
                <Button type="danger" icon="delete" onClick={() => this.deleteHistory(selectedRowKeys)}>批量删除</Button>
            </div>}>
                <div className="hostpingHistory">
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        loading={loading}
                        size="small"
                        rowKey={(text) => { return text._id }}
                        // expandedRowRender={record => 
                        //     <Table
                        //         dataSource={record.ip_list || []}
                        //         columns={[{ title: "IP地址", dataIndex: "ip" }, { title: "丢包率", dataIndex: "packet_loss" }]}
                        //         size="small"
                        //         pagination={false}
                        //     />
                        // }
                        rowSelection={rowSelection}
                        pagination={{
                            current: current, pageSize: pageSize, total: total,
                            onChange: (page, pageSize) => {
                                // console.log(page, pageSize);
                                this.getHistoryList(page, pageSize)
                            }
                        }}
                    />
                </div>

            </Card>
        </div>
    }
}