/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
import { notification } from 'antd';
import { isLoading } from '@/redux/action'
import qs from 'qs';
let Dispatch = null;
let http = {
    post: "",
    delete: "",
    delete_params: "",
    delete_body: "",
    get: "",
    setDispatch: (callback) => {
        Dispatch = callback;
    },
}
import Utils from "@/utils/utils"
// 环境的切换
// if (process.env.NODE_ENV == 'development') {
//     axios.defaults.baseURL = '/api';
// } else if (process.env.NODE_ENV == 'debug') {
//     axios.defaults.baseURL = '';
// } else if (process.env.NODE_ENV == 'production') {
//     axios.defaults.baseURL = 'http://zjnms.zijin.com/';
// }

// create an axios instance
const serviceReq = axios.create({
    baseURL: "", // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000 // request 超时时间
})
// 请求超时时间
serviceReq.defaults.timeout = 10000;

// post请求头
serviceReq.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
/**
 * 错误处理函数，显示提示信息。
 */
function errorShow(status) {
    if (Dispatch) { Dispatch(isLoading(false)) }
    switch (status) {
        // 401: 权限不足，跳转页面              
        case 401:
            notification.error({
                message: '权限不足',
                description: "抱歉，您的访问权限不足",
            });
            window.location.href = "#/exception401"
            break;
        // 403 token过期                
        // 登录过期对用户进行提示                
        // 清除本地token            
        // 跳转登录页面                
        case 403:
            notification.error({
                message: '登录过期',
                description: "登录过期，请重新登录",
            });
            // 清除token                    
            sessionStorage.removeItem('token');
            window.location.href = "#/exception403"

            break;
        // 404请求不存在                
        case 404:
            notification.error({
                message: '网络请求不存在',
                description: "网络请求不存在",
            });
            window.location.href = "#/exception404"
            break;
        // 405请求方法不被允许                
        case 405:
            notification.error({
                message: '网络请求错误',
                description: "405 请求方法不被允许",
            });
            break;
        // 500 服务器错误                
        case 500:
            notification.error({
                message: '服务器错误',
                description: "服务器错误",
            });
            window.location.href = "#/exception500"
            break;
        // 其他错误，直接抛出错误提示                
        default:
            notification.error({
                message: "错误提示",
                description: "请求错误!",
            });
    }
}
// 请求拦截器
serviceReq.interceptors.request.use(

    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        
        // 配置备份 "查询"按钮不添加请求头token
        if(config.url.indexOf('/business/getResourceIdBydepId') !== -1 && config.method === 'post') {
        } else {
            const token = sessionStorage.getItem("token");
            token && (config.headers['X-Access-Token'] = token);
        }
        // const userId = sessionStorage.getItem("userId");
        // userId && (config.headers.userid = userId); 
        return config;
    },
    error => {
        return Promise.error(error);
    }
),

    // 响应拦截器
    serviceReq.interceptors.response.use(
        response => {
            if (response.status === 200) {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        },
        // 服务器状态码不是200的情况    
        error => {
            console.log(JSON.stringify(error))
            if (error.config.url.split("ZJNMS_V1")[1] !== "/configuration/executeDeliveryTask") { // 执行下发任务超时时间大于10秒，但是不用显示弹窗报错信息
                if (Dispatch) { Dispatch(isLoading(false)) }
                notification.error({
                    message: "错误提示",
                    description: JSON.stringify(error).split(",")[0] || "",
                });
                if (typeof error === "string") {
                    notification.error({
                        message: "错误提示",
                        description: error,
                    });
                } else if (error.response && error.response.status) {
                    errorShow(error.response.status);

                } else if (typeof error === "object") {
                    notification.error({
                        message: "错误提示",
                        description: JSON.stringify(error),
                    });
                }
            }
            return Promise.reject({});
        }
    );
/**
 * 服务器响应处理函数
 * @param {Object} response [响应数据]
 * @param {function} success [成功返回回调]
 * @param {function} error [失败返回回调]
 */
function resHandle(response, success, error) {
    if (Dispatch) { Dispatch(isLoading(false)) }
    if (!response || !response.data) return;
    if ((response.data.code === "00" || response.data.recode === "00" || response.data.code === 200 ||
        response.data.retcode === "00" || response.data.code === 0)) {
        console.log(response.data);
        if (success) success(response.data)
    } else if (response.data.code === "02") {
        if (success) success(response.data)
    } else if (response.data.code == 0) {
        if (success) success(response.data)
    } else {
        const msg = response.data.msg || response.data.message || response.data.errmsg || JSON.stringify(response.data);
        if (error) error(msg, response.data)
        else Utils.showMessage("error", "操作失败", msg);
    }
}
/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 * @param {function} success [成功返回回调]
 * @param {function} error [失败返回回调]
 */
http.get = function get(url, params, success, error) {
    if (Dispatch) { Dispatch(isLoading(true)) }
    return new Promise((resolve, reject) => {
        serviceReq.get(url, {
            params,
        })
            .then(res => {
                resHandle(res, success, error);
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data || err)
            })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 * @param {function} success [成功返回回调]
 * @param {function} error [失败返回回调]
 */
http.post = function post(url, params, success, error) {
    if (Dispatch) { Dispatch(isLoading(true)) }
    return new Promise((resolve, reject) => {
        serviceReq.post(url, params)
            .then(response => {
                resHandle(response, success, error);
                resolve(response.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });

}
/** 
 * put方法，对应put请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 * @param {function} success [成功返回回调]
 * @param {function} error [失败返回回调]
 */
http.put = function put(url, params, success, error) {
    if (Dispatch) { Dispatch(isLoading(true)) }
    return new Promise((resolve, reject) => {
        serviceReq.put(url, params)
            .then(response => {
                resHandle(response, success, error);
                resolve(response.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });

}
/** 
 * delete方法，对应delete请求 ,参数拼接在url中
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 * @param {function} success [成功返回回调]
 * @param {function} error [失败返回回调]
 */
http.delete_params = function del(url, params, success, error) {
    if (Dispatch) { Dispatch(isLoading(true)) }
    console.log(url, params)
    return new Promise((resolve, reject) => {
        serviceReq({
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            method: 'delete',
            url,
            params
        })
    });

}
/** 
 * delete方法，对应delete请求 ,参数在body中
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 * @param {function} success [成功返回回调]
 * @param {function} error [失败返回回调]
 */
http.delete_body = function del(url, params, success, error) {
    if (Dispatch) { Dispatch(isLoading(true)) }
    return new Promise((resolve, reject) => {
        // serviceReq.delete("http://localhost:50034/ZJNMS_V1/configuration/delDeliveryTask", { params, paramsSerializer: params => qs.stringify(params, { indices: false }) })
        // serviceReq.delete(url, { params, paramsSerializer: params => qs.stringify(params, { indices: false }) })
        //     .then(response => {
        //         resHandle(response, success, error);
        //         resolve(response.data); 
        //     })
        //     .catch(err => {
        //         reject(err.data)
        //     });
        serviceReq({
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            method: 'delete',
            url,
            data: params
        }).then(response => {
            resHandle(response, success, error);
            resolve(response.data);
        }).catch(err => {
            reject(err.data)
        });
    });

}

export default http