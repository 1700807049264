import React from 'react'
import './index.scss'
import { Card, Table, Button, message, Row, Col, Modal } from "antd";
import socket from "@/socket/index";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
class NewBasePage extends React.Component {
    state = {
        _current: 1,      //当前页码数
        _pageSize: 10,    //每页显示条数
        querys: null,    //查询条件
        total: 0,
        loading: false,
        visible_conn: false,
        selectedRowKeys: [],
        columns: this.props.columns,
    };

    componentWillMount() {
        this.updata();
        let { _current, _pageSize } = this.state;
        let tableColumns = this.state.columns;
        tableColumns.unshift({
            title: '序号',
            align: 'center',
            dataIndex: 'key',
            render: (text, item, index) => {
                return <span>{index + 1 + (_current - 1) * _pageSize}</span>
            }
        })
        this.setState({ columns: tableColumns })
    }

    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        const { QueryInterface } = this.props;
        let bodyJson = {
            pageSize: pageSize || _pageSize,
            pageNum: pageNum || _current,
            querys: querys || {}
        };
        socket.ExecuteAction(QueryInterface.action, bodyJson, QueryInterface.menuUrl, (result) => {
            let QueryData = Utils.DataAddItem(result.data);
            this.setState({
                tableData: QueryData,
                _current: pageNum || 1,
                _pageSize: pageSize || _pageSize,
                total: result.total,
                loading: false,
            })
            console.log(this.state)
        });
    };

    deletePortRecord(ids) {
        let bodyJson = {
            _ids: ids
        }
        if (ids.length === 0) {
            message.error("请选择需要删除的记录！")
        } else {
            socket.ExecuteAction("DeletePortAudits", bodyJson, "DeletePortAudits", (result) => {
                // console.log(result, bodyJson)
                if (result.retcode === "00") {
                    message.success("删除成功");
                    this.setState({ selectedRowKeys: [] });
                    this.updata()
                } else {
                    message.error("删除失败")
                }
            });
        }
    }

    render() {
        let { _current, _pageSize, tableData, selectedRowKeys, columns, querys } = this.state;
        const { BreadcrumbArr, tableTitle, FormList } = this.props;
        // 多选
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };

        columns[0] = {
            title: '序号',
            align: 'center',
            dataIndex: 'key',
            render: (text, item, index) => {
                return <span>{index + 1 + (_current - 1) * _pageSize}</span>
            }
        }

        return (
            <div>
                <CustomBreadcrumb arr={BreadcrumbArr} />
                <Card>
                    <FilterForm
                        formList={FormList}
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(_pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(_pageSize, 1, {})
                        }}
                    />

                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>{tableTitle}</span>}
                    style={{ marginTop: 8 }}
                    extra={
                        <Row>
                            <Button type="danger" icon="delete" onClick={() => {
                                let _this = this;
                                Modal.confirm({
                                    title: '消息提示',
                                    content: '你确定删除该记录吗？',
                                    okText: '确定',
                                    okType: 'danger',
                                    cancelText: '取消',
                                    onOk() {
                                        _this.deletePortRecord(selectedRowKeys)
                                    },
                                });
                            }}>删除端口记录</Button>
                        </Row>
                    }
                >
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        loading={this.state.loading}
                        rowSelection={rowSelection}
                        rowKey={(text) => { return text._id }}
                        size="small"
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.updata(size, 1, querys);
                            },
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                console.log(page, pageSize);
                                this.updata(pageSize, page, querys);
                            }
                        }}
                    />
                </Card>
            </div>
        )
    }
}


export default NewBasePage