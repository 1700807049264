/**
 * 系统前后端API配置 模拟器
 */
const tempAddress = sys_config.emulatorAddress;
const BASEURL = "http://" + tempAddress;
export function AddDevice() {
    return BASEURL + "/sshsim/AddDevice";
}
export function GetDeviceInfo() {
    return BASEURL + "/sshsim/GetDeviceInfo";
}
export function GetModelCmdResp() {
    return BASEURL + "/sshsim/GetModelCmdResp";
}
export function GetDeviceCmdResp() {
    return BASEURL + "/sshsim/GetDeviceCmdResp";
}
export function GetModelTree() {
    return BASEURL + "/sshsim/GetModelTree";
}
export function SetModelCmdRespDefault() {
    return BASEURL + "/sshsim/SetModelCmdRespDefault";
}
export function SetDeviceCmdRespDefault() {
    return BASEURL + "/sshsim/SetDeviceCmdRespDefault";
}
export function AddModelCmdResp() {
    return BASEURL + "/sshsim/AddModelCmdResp";
}
export function AddDeviceCmdResp() {
    return BASEURL + "/sshsim/AddDeviceCmdResp";
}
export function AddsDeviceModel() {
    return BASEURL + "/sshsim/AddsDeviceModel";
}
export function modelcmdresp() {//更新模板命令返回
    return BASEURL + "/sshsim/modelcmdresp";
}
export function devicecmdresp() {//更新设备命令返回
    return BASEURL + "/sshsim/devicecmdresp";
}
export function deleteDevice() {//删除设备
    return BASEURL + "/sshsim/device";
}
export function AddDeviceModel() {
    return BASEURL + "/sshsim/AddDeviceModel";
}
export function deviceModel() {// 删除设备类型
    return BASEURL + "/sshsim/devicemodel";
} 