
import React from 'react'
import { Descriptions, Typography, List, Modal, Icon } from "antd";
import "./index.scss"

export function showDetail(rec) {
    if (!rec) {
        message.info("未查询到相关信息")
        return;
    }
    Modal.info({
        title: <Typography.Title level={4}>详细信息</Typography.Title>,
        content: <div className="InfoDiv">
            <strong>&nbsp;设备信息</strong>
            <Descriptions bordered size="small">
                <Descriptions.Item label={"关联IP"}>{rec["interface_ips"].join(",")}</Descriptions.Item>
                <Descriptions.Item label={"设备名称"}>{rec["hostname"]}</Descriptions.Item>
                <Descriptions.Item label={"设备型号"}>{rec["model"]}</Descriptions.Item>
                <Descriptions.Item label={"设备序列号"}>{rec["sn"]}</Descriptions.Item>
                <Descriptions.Item label={"设备型号oid"}>{rec["device_type_oid"]}</Descriptions.Item>
                <Descriptions.Item label={"设备类型"}>{rec["type"]}</Descriptions.Item>
                <Descriptions.Item label={"厂商"}>{rec["firm"]}</Descriptions.Item>
                <Descriptions.Item label={"状态"}>{rec["status"]}</Descriptions.Item>
                <Descriptions.Item label={"设备发现时间"}>{rec["create_time"]}</Descriptions.Item>
                <Descriptions.Item label={"运行时长"} span={3}>{rec["up_time"]}</Descriptions.Item>
                <Descriptions.Item label={"描述"} span={3}>{rec["description"]}</Descriptions.Item>
            </Descriptions><p /> <strong>&nbsp;端口信息</strong>
            <Descriptions bordered title="" size="small" column={1}>
                <Descriptions.Item label={<div style={{ minWidth: 100 }}>
                    {rec["interface_ips"].map((ip, kk) => {
                        return <p key={kk}>{ip}</p>
                    })}
                    <Icon type="check-circle" style={{ color: "green" }} />&nbsp;端口up<br />
                    <Icon type="close-circle" style={{ color: "red" }} />&nbsp;端口down
                </div>}>
                    <div className="portDiv">
                        <List
                            grid={{ column: 4 }}
                            dataSource={Object.keys(rec["interface"] || {})}
                            renderItem={item => {
                                let temp = null;
                                if (rec["interface"][item] === "up") {
                                    temp = <Icon type="check-circle" style={{ color: "green" }} />
                                } else {
                                    temp = <Icon type="close-circle" style={{ color: "red" }} />
                                }
                                return <List.Item>
                                    {temp}<span className="falsgSpan" title={item}>{" " + item}</span>
                                </List.Item>
                            }}
                        />
                    </div>
                </Descriptions.Item>
            </Descriptions>
        </div>,
        okText: "确定",
        maskClosable: true,
        width: "80%",
        style: { top: 10 }
    })
}

