import React from 'react'
import { Input, Select, Form, Button, Checkbox, Radio, DatePicker, Modal, Tree, Dropdown, Menu, Icon, AutoComplete } from 'antd'
import Utils from '@/utils/utils';
import deviceFirmUtils from '@/utils/deviceFirmUtils';
import Socket from '@/socket/index';
import AxiosObj from '@/axios/index';
import CascOrgain from '@/components/CascOrgain/index';
const FormItem = Form.Item;
const Option = Select.Option;
const { RangePicker, MonthPicker } = DatePicker;
import axios from 'axios'
import moment from 'moment';
const versionName = sys_config.versionName;
class FilterForm extends React.Component {
    state = {
        formList: [],
        allFormList: [],
        checkedKeys: [],
        Templates: [],
        visible: false,
        selectOptions: [],
    }

    componentWillMount() {
        // Socket.ExecuteAction("QueryTableConfig", {}, "", (success) => {
        //     console.log(success)
        // });
        this.getTagGroupName();
    }
    componentWillReceiveProps(netProps) {
        if (netProps.tagClass === 'ipMonitor') { // 解决ip类型拿不到数据的问题
            this.setState({ selectOptions: netProps.selectOptions })
            this.getTagGroupName();
            // console.log("componentWillReceiveProps", netProps)
        }
    }
    getFormList(tagClass, Tags, Groups, Templates) {
        let formList = [];
        let { selectOptions } = this.state;
        switch (tagClass) {
            case "task":
                formList = [
                    {
                        label: "任务名称",              //描述
                        type: 'INPUT',                 //组件类型
                        field: "taskName",             //
                        initialValue: '',              //初始值
                        placeholder: "任务名称"        //组件提示信息
                    },
                    {
                        label: "任务状态",
                        type: 'SELECT',
                        field: "taskStatus",
                        initialValue: '',
                        list: [
                            { id: 'run', name: '执行中' }, { id: 'done', name: '完成' },
                            { id: 'new', name: '新建' }, { id: 'terminal', name: '终止' }
                        ]
                    }, {
                        label: "标签",
                        type: 'SELECT',
                        field: "tags",
                        isTag: true,
                        initialValue: [],
                        list: Tags.map((item) => {
                            return { id: item, name: item }
                        }),
                    }
                ];
                break;
            case "temp":
                formList = [
                    {
                        label: "模板名称",              //描述
                        type: 'INPUT',                 //组件类型
                        field: "taskName",             //
                        initialValue: '',              //初始值
                        placeholder: "模板名称"        //组件提示信息
                    }, {
                        label: "标签",
                        type: 'SELECT',
                        field: "tags",
                        isTag: true,
                        initialValue: [],
                        list: Tags.map((item) => {
                            return { id: item, name: item }
                        }),
                    }
                ];
                break;
            case "resource":
                formList = [
                    {
                        label: "名称",
                        type: 'INPUT',
                        field: "resourceName",
                        initialValue: '',
                        placeholder: "资源名称"
                    },
                    {
                        label: "IP",
                        type: 'INPUT',
                        field: "resourceParams-deviceIp",
                        initialValue: '',
                        placeholder: "设备IP"
                    },
                    {
                        // label: "厂商",
                        // type: 'INPUT',
                        // field: "resourceParams-deviceFirm",
                        // initialValue: '',
                        // placeholder: "厂商"
                        label: "厂商",
                        type: 'AUTO',
                        field: "resourceParams-deviceFirm",
                        initialValue: '',
                        placeholder: "厂商",
                        list: deviceFirmUtils.deviceFirmsAutoInput()
                        // list: ["huawei", "cisco", "h3c", "maipu", "ruijie", "hillstone"]

                    },
                    {
                        label: "区域",
                        type: 'CASC',
                        field: "depId",
                        // initialValue: [],
                        initialValue: '',
                        placeholder: "区域"
                    },
                    {
                        label: "型号",
                        type: 'INPUT',
                        field: "resourceParams-deviceVersion",
                        initialValue: '',
                        placeholder: "型号"
                    },
                    {
                        label: "设备名称",
                        type: 'INPUT',
                        field: "resourceParams-deviceId",
                        initialValue: '',
                        placeholder: "设备名称"
                    },
                    {
                        label: "设备OS版本",
                        type: 'INPUT',
                        field: "resourceParams-deviceOsVersion",
                        initialValue: '',
                        placeholder: "设备OS版本"
                    },
                    {
                        label: "设备OS类型",
                        type: 'INPUT',
                        field: "resourceParams-os_type",
                        initialValue: '',
                        placeholder: "设备OS类型"
                    },
                    {
                        label: "设备类型",
                        type: 'SELECT',
                        field: "resourceParams-deviceType",
                        initialValue: [],
                        placeholder: "设备类型",
                        list: [{ id: 'switch', name: '交换机' }, { id: 'firewall', name: '防火墙' }, { id: 'router', name: '路由器' }]
                    },
                    {
                        label: "设备状态",
                        type: 'SELECT',
                        field: "resourceParams-deviceState",
                        initialValue: [],
                        placeholder: "设备状态",
                        list: [{ id: 'online', name: '在线' }, { id: 'offline', name: '离线' }, { id: 'none', name: '未知' }]
                    }, {
                        label: "标签",
                        type: 'SELECT',
                        field: "tags",
                        isTag: true,
                        initialValue: [],
                        list: Tags.map((item) => {
                            return { id: item, name: item }
                        }),
                    },
                    {
                        label: "离线告警",
                        type: 'SELECT',
                        field: "monitor-offlineAlert",
                        list: [{ id: "true", name: '开启' }, { id: "false", name: '关闭' }],
                    },
                    {
                        label: "snmp告警",
                        type: 'SELECT',
                        field: "monitor-snmpMonitor",
                        list: [{ id: "true", name: '开启' }, { id: "false", name: '关闭' }],
                    }
                ];

                break;
            case "dumbTerminal":
                let bindIndex;
                formList = [
                    {
                        label: 'IP地址',
                        type: 'INPUT',
                        field: 'ip',
                        initialValue: '',
                        placeholder: 'IP地址'
                    },
                    {
                        label: '哑终端厂商',
                        type: 'INPUT',
                        field: 'vendor',
                        initialValue: '',
                        placeholder: '哑终端厂商'
                    },
                    {
                        label: '终端名称',
                        type: 'INPUT',
                        field: 'name',
                        initialValue: '',
                        placeholder: '终端名称'
                    },
                    {
                        label: "区域",
                        type: 'CASC',
                        field: "dep_ids",
                        // initialValue: [],
                        initialValue: '',
                        placeholder: "区域"
                    },
                    {
                        label: '哑终端类型',
                        type: 'INPUT',
                        field: 'type',
                        initialValue: '',
                        placeholder: '哑终端类型'
                    },
                    {
                        label: '哑终端物理地址',
                        type: 'INPUT',
                        field: 'physical_address',
                        initialValue: '',
                        placeholder: '哑终端物理地址'
                    },
                    {
                        label: 'MAC地址',
                        type: 'INPUT',
                        field: 'mac_address',
                        initialValue: '',
                        placeholder: 'MAC地址'
                    },
                    {
                        label: '接入交换机名称',
                        type: 'INPUT',
                        field: 'switch_name',
                        initialValue: '',
                        placeholder: '接入交换机名称'
                    },
                    {
                        label: '接入交换机IP',
                        type: 'INPUT',
                        field: 'switch_ip',
                        initialValue: '',
                        placeholder: '接入交换机IP'
                    },
                    {
                        label: '哑终端业务负责人',
                        type: 'INPUT',
                        field: 'business_user',
                        initialValue: '',
                        placeholder: '哑终端业务负责人'
                    },
                    {
                        label: '哑终端IT负责人',
                        type: 'INPUT',
                        field: 'it_user',
                        initialValue: '',
                        placeholder: '哑终端IT负责人'
                    },
                    // {
                    //     label: '哑终端型号',
                    //     type: 'INPUT',
                    //     field: 'model',
                    //     initialValue: '',
                    //     placeholder: '哑终端型号' 
                    // },
                    {
                        label: '哑终端状态',
                        type: 'SELECT',
                        field: 'status',
                        initialValue: '',
                        list: [
                            { id: '', name: '全部' },
                            { id: '停用', name: '停用' },
                            { id: '在用', name: '在用' },
                        ],
                        placeholder: '哑终端状态'
                    },
                    {
                        label: '是否实施了mac准入',
                        type: 'SELECT',
                        field: 'is_allow_mac_in',
                        initialValue: '',
                        list: [
                            { id: '', name: '全部' },
                            { id: '否', name: '否' },
                            { id: '是', name: '是' },
                        ],
                        placeholder: '是否实施了mac准入'
                    },
                    {
                        label: '是否在线',
                        type: 'SELECT',
                        field: 'is_online',
                        initialValue: '',
                        list: [
                            { id: '', name: '全部' },
                            { id: '否', name: '否' },
                            { id: '是', name: '是' },
                        ],
                        placeholder: '是否在线'
                    },
                    {
                        label: '绑定状态',
                        type: 'SELECT',
                        field: 'bind_status',
                        initialValue: '',
                        list: [
                            { id: '', name: '全部' },
                            { id: 'no_check', name: '无法检测' },
                            { id: 'no_bind', name: '未绑定' },
                            { id: 'bound', name: '已绑定' },
                            { id: 'abnormal', name: '异常' },
                        ],
                        placeholder: '绑定状态'
                    },
                    {
                        label: '开放端口检查状态',
                        type: 'SELECT',
                        field: 'port_check',
                        initialValue: '',
                        list: [
                            { id: '', name: '全部' },
                            { id: 'normal', name: '正常' },
                            { id: 'abnormal', name: '异常' },
                        ],
                        placeholder: '开放端口检查状态'
                    },
                    {
                        label: '接入检查状态',
                        type: 'SELECT',
                        field: 'insert_status',
                        initialValue: '',
                        list: [
                            { id: '', name: '全部' },
                            { id: 'true', name: '正常' },
                            { id: 'false', name: '异常' },
                        ],
                        placeholder: '接入检查状态'
                    },
                    {
                        label: "月新增",
                        type: 'monthSelect',
                        field: "month_add",
                        initialValue: "",
                        placeholder: '月新增'
                    },
                    {
                        label: '离线告警',
                        type: 'SELECT',
                        field: 'is_monitor',
                        initialValue: '',
                        list: [
                            { id: '', name: '全部' },
                            { id: 'true', name: '开启' },
                            { id: 'false', name: '关闭' },
                        ],
                        placeholder: '离线告警'
                    },
                ];
                if(versionName === 'pinganNanJing') {
                    formList.map((i, k) => {
                        if(i.field === 'bind_status') {
                            bindIndex = k;
                        }
                    })
                    formList.splice(bindIndex,1)
                }
                break;
            case "operation":
                formList = [
                    {
                        label: "操作名称",
                        type: 'INPUT',
                        field: "operationName",
                        initialValue: '',
                        placeholder: "操作名称"
                    }, {
                        label: "操作类型",
                        type: 'SELECT',
                        field: "operationType",
                        initialValue: '',
                        list: [{ id: 'cli', name: 'cli' }, { id: 'linuxShell', name: 'linuxShell' },
                        { id: 'python', name: 'python' }, { id: 'nodejs', name: 'nodejs' }, { id: 'task', name: 'task' }
                            , { id: 'perl', name: 'perl' }, { id: 'system', name: 'system' }]
                    }, {
                        label: "标签",
                        type: 'SELECT',
                        field: "tags",
                        isTag: true,
                        initialValue: [],
                        list: Tags.map((item) => {
                            return { id: item, name: item }
                        }),
                    }
                ]; break;
            case "role":
                formList = [
                    {
                        label: "角色名称",
                        type: 'INPUT',
                        field: "roleName",
                        initialValue: '',
                        placeholder: "角色名称"
                    }
                ]; break;
            case "user":
                formList = [
                    {
                        label: "用户名称",
                        type: 'INPUT',
                        field: "nickName",
                        initialValue: '',
                        placeholder: "用户名称"
                    },
                    {
                        label: "用户登录名",
                        type: 'INPUT',
                        field: "username",
                        initialValue: '',
                        placeholder: "用户登录名"
                    }
                ];
                break;
            case "audit":
                formList = [
                    {
                        label: "用户名",              //描述
                        type: 'INPUT',                 //组件类型
                        field: "username",             //
                        initialValue: '',              //初始值
                        placeholder: "用户名"        //组件提示信息
                    },
                    {
                        label: "请求方法",
                        type: 'SELECT',
                        field: "method",
                        initialValue: '',
                        list: [
                            { id: 'POST', name: 'POST' }, { id: 'PUT', name: 'PUT' },
                            { id: 'PATCH', name: 'PATCH' }, { id: 'DELETE', name: 'DELETE' },
                            { id: 'GET', name: 'GET' }
                        ]
                    },
                    {
                        label: "起止时间",
                        type: 'datePicker',
                        field: "timeRange",
                        initialValue: "",
                    },
                ];
                break;
            case "ipMonitor":
                formList = [
                    {
                        label: 'IP地址',
                        type: 'INPUT',
                        field: 'ip',
                        initialValue: '',
                        placeholder: 'IP地址'
                    },
                    {
                        label: '名称',
                        type: 'INPUT',
                        field: 'name',
                        initialValue: '',
                        placeholder: '请输入名称'
                    },
                    {
                        label: '类型',
                        type: 'SELECT',
                        field: 'ip_type',
                        initialValue: [],
                        placeholder: '类型',
                        list: Array.isArray(selectOptions) ? selectOptions.map((item) => {
                            return { id: item.name, name: item.name }
                        }) : [],
                    },
                    {
                        label: 'mac地址',
                        type: 'INPUT',
                        field: 'mac_address',
                        initialValue: '',
                        placeholder: 'mac地址'
                    },
                    {
                        label: '离线天数',
                        type: 'INPUT',
                        field: 'offline_days',
                        initialValue: '',
                        placeholder: '离线天数'
                    },
                    {
                        label: '最后一次ping结果',
                        type: 'SELECT',
                        field: 'last_ping',
                        initialValue: '',
                        list: [
                            { id: '', name: '全部' },
                            { id: 'false', name: '失败' },
                            { id: 'true', name: '成功' },
                        ],
                        placeholder: '最后一次ping结果'
                    },
                    {
                        label: '最近在线时间',
                        type: 'datePicker',
                        field: 'furthest_offline_time',
                        initialValue: '',
                    },
                ];
                break;
            case "ipRecycleRecord":
                formList = [
                    {
                        label: 'IP地址',
                        type: 'INPUT',
                        field: 'ip',
                        initialValue: '',
                        placeholder: 'IP地址'
                    },
                    {
                        label: '名称',
                        type: 'INPUT',
                        field: 'name',
                        initialValue: '',
                        placeholder: '请输入名称'
                    },
                    {
                        label: 'mac地址',
                        type: 'INPUT',
                        field: 'mac_address',
                        initialValue: '',
                        placeholder: 'mac地址'
                    },
                    {
                        label: '操作人',
                        type: 'INPUT',
                        field: 'operator',
                        initialValue: '',
                        placeholder: '操作人'
                    },
                    {
                        label: '回收时间',
                        type: 'datePicker',
                        field: 'create_time',
                        initialValue: '',
                    },
                    {
                        label: '最后一次ping结果',
                        type: 'SELECT',
                        field: 'last_ping',
                        initialValue: '',
                        list: [
                            { id: '', name: '全部' },
                            { id: 'false', name: '失败' },
                            { id: 'true', name: '成功' },
                        ],
                        placeholder: 'IP状态'
                    },
                    {
                        label: '最近在线时间',
                        type: 'datePicker',
                        field: 'furthest_offline_time',
                        initialValue: '',
                    },
                ];
                break;
            case "unKnownDumbTerminal":
                formList = [
                    {
                        label: '设备ip',
                        type: 'INPUT',
                        field: 'ip',
                        initialValue: '',
                        placeholder: '设备ip'
                    },
                    {
                        label: '名称（dhcp）',
                        type: 'INPUT',
                        field: 'dhcp_name',
                        initialValue: '',
                        placeholder: 'dhcp名称'
                    },
                    {
                        label: '交换机ip',
                        type: 'INPUT',
                        field: 'switch_ip',
                        initialValue: '',
                        placeholder: '交换机ip'
                    },
                    {
                        label: '交换机名称',
                        type: 'INPUT',
                        field: 'switch_name',
                        initialValue: '',
                        placeholder: '交换机名称'
                    },
                    {
                        label: '输入端口',
                        type: 'INPUT',
                        field: 'switch_port',
                        initialValue: '',
                        placeholder: '输入端口'
                    },
                    {
                        label: 'mac地址',
                        type: 'INPUT',
                        field: 'mac_address',
                        initialValue: '',
                        placeholder: 'mac地址'
                    },
                ]
                break;
            case "noPassedDevices":
                formList = [
                    {
                        label: '设备ip',
                        type: 'INPUT',
                        field: 'ip',
                        initialValue: '',
                        placeholder: '设备ip'
                    },
                    {
                        label: '名称（dhcp）',
                        type: 'INPUT',
                        field: 'dhcp_name',
                        initialValue: '',
                        placeholder: 'dhcp名称'
                    },
                    {
                        label: '交换机ip',
                        type: 'INPUT',
                        field: 'switch_ip',
                        initialValue: '',
                        placeholder: '交换机ip'
                    },
                    {
                        label: '交换机名称',
                        type: 'INPUT',
                        field: 'switch_name',
                        initialValue: '',
                        placeholder: '交换机名称'
                    },
                    {
                        label: '输入端口',
                        type: 'INPUT',
                        field: 'switch_port',
                        initialValue: '',
                        placeholder: '输入端口'
                    },
                ]
                break;
            case "macMonitor":
                formList = [
                    {
                        label: 'mac地址',
                        type: 'INPUT',
                        field: 'mac_address',
                        initialValue: '',
                        placeholder: 'mac地址'
                    },
                    {
                        label: 'IP地址',
                        type: 'INPUT',
                        field: 'ip',
                        initialValue: '',
                        placeholder: 'IP地址'
                    },
                    {
                        label: '名称',
                        type: 'INPUT',
                        field: 'name',
                        initialValue: '',
                        placeholder: '请输入名称'
                    },
                    {
                        label: '最后一次ping结果',
                        type: 'SELECT',
                        field: 'last_ping',
                        initialValue: '',
                        list: [
                            { id: '', name: '全部' },
                            { id: 'false', name: '失败' },
                            { id: 'true', name: '成功' },
                        ],
                        placeholder: '最后一次ping结果',
                    },
                    {
                        label: '离线天数',
                        type: 'INPUT',
                        field: 'offline_days',
                        initialValue: '',
                        placeholder: '离线天数'
                    },
                    {
                        label: '最近在线时间',
                        type: 'datePicker',
                        field: 'lately_online_time',
                        initialValue: '',
                        placeholder: '最近在线时间',
                    },
                ]
                break;
            case "externalConnection":
                formList = [
                    {
                        label: "CI_CODE",
                        type: 'INPUT',
                        field: "ci_code",
                        initialValue: '',
                        placeholder: "CI_CODE"
                    },
                    {
                        label: "专线号",
                        type: 'INPUT',
                        field: "e_line_no",
                        initialValue: '',
                        placeholder: "专线号"
                    },
                    {
                        label: "源ip",
                        type: 'INPUT',
                        field: "src_ip",
                        initialValue: '',
                        placeholder: "源ip"
                    },
                    {
                        label: "源ip转换后地址",
                        type: 'INPUT',
                        field: "srcIp_trans_to",
                        initialValue: '',
                        placeholder: "源ip转换后地址"
                    },
                    {
                        label: "目的ip",
                        type: 'INPUT',
                        field: "dst_ip",
                        initialValue: '',
                        placeholder: "目的ip"
                    },
                    {
                        label: "目的ip转换后地址",
                        type: 'INPUT',
                        field: "dstIp_trans_to",
                        initialValue: '',
                        placeholder: "目的ip转换后地址"
                    },
                    {
                        label: "安全策略名称",
                        type: 'INPUT',
                        field: "rule_name",
                        initialValue: '',
                        placeholder: "安全策略名称"
                    },
                ]
                break;
            case 'alertRecord': 
                formList = [
                    { type: "INPUT", field: "ruleName", label: "规则名称", placeholder: "规则名称", initialValue: '' },
                    { type: "INPUT", field: "sort", label: "告警类别", placeholder: "告警类别", initialValue: '', },
                    {
                        type: "SELECT", field: "status", label: "告警状态", initialValue: [], placeholder: "告警状态",
                        list: [{ name: "未解决", id: '0' }, { name: "已解决", id: '1' }]
                    },
                    {
                        type: "SELECT", field: "level", label: "告警等级", initialValue: [], placeholder: "告警等级",
                        list: [{ name: "信息", id: '0' }, { name: "警告", id: '1' }, { name: "严重", id: '2' }, { name: "灾难", id: '3' }]
                    },
                    { type: "datePicker", field: "rangeTime", label: "起止时间", initialValue: '' },
                    { type: "INPUT", field: "ip", label: "IP", placeholder: "IP", initialValue: '', },
                    { type: "CASC", field: "areaId", label: "设备区域", placeholder: "设备区域", initialValue: '', },
                ]
                break;
        };
        // formList.push({
        //     label: "标签",
        //     type: 'SELECT',
        //     field: "tags",
        //     isTag: true,
        //     initialValue: [],
        //     list: Tags.map((item) => {
        //         return { id: item, name: item }
        //     }),
        // });
        // formList.push({
        //     label: "分组",
        //     type: 'SELECT',
        //     isTag: true,
        //     field: "groupNames",
        //     initialValue: [],
        //     list: Groups.map((item) => {
        //         return { id: item, name: item }
        //     }),
        // });
        return formList;
    }
    getTagGroupName() {
        let { tagClass, formList, type } = this.props;
        if (tagClass) {
            Utils.getTagGroupName(tagClass, async (Tags, Groups) => {
                let data = { data: { data: [] } };
                // try {
                //     const url = AxiosObj.getBaseUrl("acl") + 'aclTemplates';
                //     data = await axios.post(url, {});
                //     console.log(data);
                // } catch (e) {
                //     data = { data: { data: [] } };
                // }
                const formList = this.getFormList(tagClass, Tags, Groups, data.data.data);
                let filter = formList;
                let localData = JSON.parse(localStorage.getItem(tagClass + "filter"));
                let checkedKeys = [];
                if (localData) {
                    checkedKeys = localData;
                    filter = formList.filter((item) => { return checkedKeys.includes(item["field"]) })
                } else if (tagClass === "resource" || tagClass === "dumbTerminal" || tagClass === "ipMonitor" || tagClass === "macMonitor" || tagClass === "externalConnection" ||
                    tagClass === "ipRecycleRecord" || tagClass === "unKnownDumbTerminal" || tagClass === "noPassedDevices" || tagClass === "alertRecord") {
                    checkedKeys = [];
                    filter = [];
                    formList.some((item, key) => {
                        if (key > 3) {
                            return true;//结束循环
                        } else {
                            checkedKeys.push(item["field"]);
                            filter.push(item);
                        }
                    });
                }
                this.setState({ formList: filter, allFormList: formList, checkedKeys })
            });
        } else {
            this.setState({ formList, allFormList: formList, checkedKeys: [] })
        }

        // const flag = tagClass || type;
        // let localData = JSON.parse(localStorage.getItem(flag + "filter"));
        // let checkedKeys = [];
        // if (tagClass) {
        //     Utils.getTagGroupName(tagClass, (Tags, Groups) => {
        //         const formList = this.getFormList(tagClass, Tags, Groups);
        //         let filter = formList;
        //         if (localData) {
        //             checkedKeys = localData;
        //             filter = formList.filter((item) => { return checkedKeys.includes(item["field"]) })
        //         } else {
        //             checkedKeys = formList.map((item) => { return item["field"] });
        //         }
        //         this.setState({ formList: filter, allFormList: formList, checkedKeys })
        //     });
        // } else {
        //     if (localData) {
        //         checkedKeys = localData;
        //         formList = formList.filter((item) => { return checkedKeys.includes(item["field"]) })
        //     } else {
        //         checkedKeys = formList.map((item) => { return item["field"] });
        //     }
        //     this.setState({ formList, allFormList: formList, checkedKeys })
        // }
    }
    onCheck = (checkedKeys) => {
        this.setState({ checkedKeys });
    };

    handleFilterSubmit = () => {
        let fieldsValue = this.props.form.getFieldsValue();
        let _fieldsValue = {};
        for (let i in fieldsValue) {
            if (!Utils.isEmpty(fieldsValue[i]) && fieldsValue[i]) {

                if (i.split("-").length >= 2) {
                    let str = i.replace(/-/g, ".");
                    _fieldsValue[str] = fieldsValue[i];
                } else {
                    _fieldsValue[i] = fieldsValue[i];
                }
            }
        }
        // _fieldsValue["resourceParams.deviceFirm"] = "cisco" 
        this.props.filterSubmit(_fieldsValue);
    };

    reset = () => {
        this.props.form.resetFields();
        this.props.resetTab();
    };

    initFormList = () => {
        const { getFieldDecorator } = this.props.form;
        const formList = this.state.formList;
        const formItemList = [];
        const _this = this;
        if (formList && formList.length > 0) {
            formList.forEach((item, i) => {
                let label = item.label;
                let field = item.field;
                let initialValue = item.initialValue || '';
                let placeholder = item.placeholder;
                let width = item.width;
                // console.log(item);
                if (item.type == 'timeSelect') {
                    const begin_time = <FormItem label="时间" key={field + "0"}>
                        {
                            getFieldDecorator('begin_time')(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(begin_time)
                    const end_time = <FormItem label="~" colon={false} key={field + "1"}>
                        {
                            getFieldDecorator('end_time')(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(end_time)
                } else if (item.type == 'datePicker') {
                    const timeRange = <FormItem label={label} key={field}>
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue
                            })(
                                <RangePicker
                                    showTime={true}
                                    placeholder={['开始时间', '结束时间']}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    ranges={{ "此刻": [moment(), moment()], '本月': [moment().startOf('month'), moment().endOf('month')], }}
                                />
                            )
                        }
                    </FormItem>;
                    formItemList.push(timeRange)
                } else if (item.type == 'INPUT') {
                    const INPUT = <FormItem label={label} key={field}>
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue
                            })(
                                <Input type="text" style={{ width: 200 }} placeholder={placeholder} allowClear />
                            )
                        }
                    </FormItem>;
                    formItemList.push(INPUT)
                } else if (item.type == 'SELECT') {
                    const SELECT = <FormItem label={label} key={field}>
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue
                            })(
                                <Select
                                    // {item.isTag?mode="tags":""}
                                    mode={item.isTag ? "tags" : ""}
                                    style={{ width: 200 }}
                                    placeholder={placeholder}
                                    allowClear
                                >
                                    {Utils.getOptionList(item.list)}
                                </Select>
                            )
                        }
                    </FormItem>;
                    formItemList.push(SELECT)
                } else if (item.type == 'CHECKBOX') {
                    const CHECKBOX = <FormItem label={label} key={field}>
                        {
                            getFieldDecorator(field, {
                                valuePropName: 'checked',
                                initialValue: initialValue //true | false
                            })(
                                <Checkbox>
                                    {label}
                                </Checkbox>
                            )
                        }
                    </FormItem>;
                    formItemList.push(CHECKBOX)
                } else if (item.type == 'AUTO') {
                    const AUTO = <FormItem label={label} key={field}>
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue
                            })(<AutoComplete
                                style={{ width: 200 }}
                                dataSource={item.list}
                                allowClear
                                placeholder="输入或选择厂商"
                                filterOption={(inputValue, option) =>
                                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                            )
                        }
                    </FormItem>;
                    formItemList.push(AUTO)
                } else if (item.type == 'CASC' || item.type === 'CASC_ARR') {
                    const CASC = <FormItem label={label} key={field}>
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue,
                                getValueFromEvent: (a, b) => {
                                    if(item.type === 'CASC_ARR') { 
                                        // 返回数组格式的数据
                                        return a
                                    } else if(item.type == 'CASC'){
                                        // 返回字符串格式的数据
                                        return a[a.length - 1]
                                    }
                                }
                            })(<CascOrgain type="add" onChange={() => { setTimeout(() => { _this.handleFilterSubmit() }, 100) }} allowClear />)
                        }
                    </FormItem>;
                    formItemList.push(CASC)
                } else if (item.type == "dateSelect") {
                    const dateSelect = <FormItem label={label} key={field}>
                        {
                            getFieldDecorator(field)(
                                <DatePicker placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" showTime />
                            )
                        }
                    </FormItem>;
                    formItemList.push(dateSelect)
                } else if (item.type == "monthSelect") {
                    const monthSelect = <FormItem label={label} key={field}>
                        {
                            getFieldDecorator(field)(
                                <MonthPicker placeholder={placeholder} format="YYYY-MM" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(monthSelect)
                }
            })
        }
        return formItemList;
    }
    handleSearch = confirm => {
        //确定 保存用户设置的字段排序和需要显示的字段key
        const { checkedKeys, allFormList } = this.state;
        const { tagClass, type } = this.props;
        const newList = allFormList.filter((item) => { return checkedKeys.includes(item["field"]) });
        const flag = tagClass || type;
        if (tagClass === "resource" || tagClass === "dumbTerminal" || tagClass === "ipMonitor" || tagClass === "macMonitor" || tagClass === "externalConnection" ||
            tagClass === "ipRecycleRecord" || tagClass === "unKnownDumbTerminal" || tagClass === "noPassedDevices" || tagClass === "alertRecord") {
            localStorage.setItem(tagClass + "filter", JSON.stringify(checkedKeys));
            Socket.ExecuteAction("QueryTableConfig", {}, "", (success) => {
                console.log(success)
            });
        }
        this.setState({
            visible: false,
            formList: newList,
        });
    };
    handleReset() {
        this.setState({ visible: false })
    }
    render() {
        let { checkedKeys, visible, allFormList } = this.state;
        const { tagClass } = this.props;
        return (<div id="filterDiv"><Form layout="inline" onKeyDown={(e) => { // 回车查询
            if (e.keyCode === 13) {
                // console.log(e.keyCode, )
                // e.stopPropagation();
                this.handleFilterSubmit();
            }
        }}>
            {this.initFormList()}
            <FormItem><span>
                <Button type="primary"
                    icon="search"
                    onClick={() => this.handleFilterSubmit()}>查询</Button>
                <Button onClick={() => this.reset()} icon="reload" style={{ margin: '0 5px' }}>重置</Button>
                {tagClass === "resource" || tagClass === "dumbTerminal" || tagClass === "ipMonitor" || tagClass === "macMonitor" || tagClass === "externalConnection" ||
                    tagClass === "ipRecycleRecord" || tagClass === "unKnownDumbTerminal" || tagClass === "noPassedDevices" || tagClass === "alertRecord" ?
                    <Dropdown
                        // visible={visible}
                        getPopupContainer={(triggerNode) => {
                            return document.getElementById("filterDiv")
                        }}
                        overlay={<Menu>
                            <Tree
                                checkable
                                blockNode
                                selectable={false}
                                onCheck={this.onCheck}
                                checkedKeys={checkedKeys}
                            >
                                {allFormList.map((item) => {
                                    return <Tree.TreeNode key={item.field} title={item.label} />
                                })}
                            </Tree>
                            <div style={{ padding: 5, }}>
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => this.handleSearch()}
                                    style={{ width: "60px", marginRight: "5px" }}
                                >
                                    确定</Button>
                                <Button
                                    size="small"
                                    onClick={() => this.handleReset()}
                                    style={{ width: "60px" }}
                                >取消 </Button>
                            </div>
                        </Menu>}
                        trigger={['hover']}
                    >
                        <Button type="primary"
                            icon="setting" onClick={() => { this.setState({ visible: true }) }}>配置 <Icon type="down" /></Button>
                    </Dropdown> : ""}
            </span></FormItem>
        </Form> </div>
        );
    }
}
export default Form.create({})(FilterForm);