import React from 'react'
import { Card, Table, Button, Icon, message, Modal, List, Row, Col, Collapse, Tag, Tooltip } from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import axios from '@/axios/axios_utils';

export default class SuspiciousDetails extends React.Component {

    state = {
        current: 1,      //当前页码数
        pageSize: 20,    //每页显示条数
        querys: {},    //查询条件
        total: 0,
        loading: false,
        tableData: [],
        macVis: false,
        listData: [],
    }

    componentWillMount() {
        this.setState({ loading: true })
        let bodyJson = this.props.location.bodyJson || {};
        console.log(bodyJson)
        let tableDataArr = []
        if (Array.isArray(bodyJson.port)) {
            bodyJson.port.map((item, index) => {
                let tableObj = {
                    _id: index,
                    ...item
                }
                tableDataArr.push(tableObj)
            })
        }
        this.setState({
            // tableData: bodyJson.port
            tableData: tableDataArr,
            loading: false,
            total: tableDataArr.length || 0
        })
        // this.updata();
    }

    updata(pageNum, page_size, querys) {
        // this.setState({ loading: true, });
        let { pageSize, current } = this.state;
        let bodyJson = {
            pageNum: pageNum || current,
            pageSize: page_size || pageSize,
            ...querys
        };
        // axios.get(fileWallUrl + "/firewall/whitelist", bodyJson , (res) => {
        //     if(res.retcode === "00") {
        //         this.setState({
        //             tableData: res.data,
        //             loading: false,
        //             total: res.total,
        //             _current: pageNum || 1,
        //         })
        //     } else {
        //         message.error("查询失败")
        //     }
        // })
    }

    render() {
        let { tableData, current, pageSize, querys, total, loading, macVis, listData } = this.state;
        const columns = [
            {
                title: '',
                align: 'center',
                dataIndex: 'tag',
                width: 20,
                render: (text, item, index) => {
                    if (item.is_suspicious === true) {
                        return (
                            <div className="failTag" style={{ position: "absolute", left: "5px" }}><Icon type="warning" /></div>
                            // <div style={{ position: "relative" }}>
                            // </div>
                        )
                    } else {
                        return (<span></span>)
                    }
                }
            },
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                ellipsis: true,
                width: 70,
                render: (text, a, b) => { return <span>{(b + 1 + (current - 1) * pageSize)}</span> }
            },
            {
                title: '端口名称',
                align: 'center',
                dataIndex: 'port_name',
                // width: 100,
                ellipsis: true,
            },
            {
                title: '所在VLAN',
                align: 'center',
                dataIndex: 'vlan',
                // width: 110,
                ellipsis: true,
            },
            {
                title: '端口工作模式',
                align: 'center',
                dataIndex: 'admin_mode',
                // width: 130,
                ellipsis: true,
            },
            {
                title: '是否为可疑接入端口', // 是/否
                align: 'center',
                dataIndex: 'is_suspicious',
                // width: 160,
                ellipsis: true,
                render: (text) => {
                    switch (text) {
                        case true: return (<span>是</span>); break;
                        case false: return (<span>否</span>); break;
                    }
                }
            },
            {
                title: 'mac地址',
                align: 'center',
                dataIndex: 'mac',
                width: 220,
                ellipsis: true,
                render: (text, item, key) => {
                    // return (
                    //     <div>
                    //         <Button type="primary" size="small" icon="edit" onClick={() => { this.setState({ listData: text, macVis: true }) }}>查看mac地址</Button>
                    //     </div>
                    // )
                    if (Array.isArray(text)) {
                        if (text.length <= 1) {
                            return (<span>{text.join('，')}</span>)
                        } else {
                            return (
                                <span>
                                    {text.slice(0, 1).join('，')}
                                    <Button type="link" size="small" style={{ padding: 0 }} onClick={() => this.setState({ listData: text, macVis: true })}>...更多</Button>
                                </span>
                            )
                        }
                    }
                }
            },
        ];
        return (
            <div>
                <CustomBreadcrumb arr={["运维管理", { title: "MAC地址管理", to: "/Ledger" }, { title: "查看可疑接入", to: "/suspiciousAccess" }, "端口详情"]} />
                <Card title={<span style={{ fontWeight: 600 }}>端口详情</span>}
                    style={{ marginTop: 8 }}
                    extra={
                        <div>
                            <Button type="primary" icon={'rollback'}
                                onClick={() => {
                                    let path = {
                                        pathname: `/suspiciousAccess`,
                                        bodyJson: this.props.location.bodyJson,
                                    };
                                    this.props.history.push(path);
                                }}>返回可疑接入列表</Button>
                        </div>
                    }
                >
                    <Table
                        dataSource={tableData}
                        // rowSelection={rowSelection}
                        columns={columns}
                        loading={loading}
                        rowKey={(text) => { return text._id }}
                        size="small"
                        scroll={{ y: 500 }}
                        pagination={{
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            current: current, pageSize: pageSize,
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.setState({ pageSize: size, current: 1 })
                            },
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                // console.log(page, pageSize);
                                this.setState({ current: page })
                            }
                        }}
                    />
                </Card>
                <Modal
                    title="查看mac地址"
                    visible={macVis}
                    onCancel={() => { this.setState({ listData: [], macVis: false }) }}
                    onOk={() => { this.setState({ listData: [], macVis: false }) }}
                    destroyOnClose
                >
                    <List
                        bordered
                        dataSource={listData}
                        style={{ maxHeight: "400px", overflow: "auto" }}
                        renderItem={item => (
                            <List.Item>
                                {item}
                            </List.Item>
                        )}
                    />
                </Modal>
            </div>
        )
    }
}