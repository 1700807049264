/**
 * 系统前后端API配置 模拟器
 */
// import { getSysConfig } from '@/utils/func'
const tempAddress = sys_config.dataScrape || "localhost:38209";
const Version = "/dataScrape/v1/"
const BASEURL = "http://" + tempAddress + Version
export function devices() {
    return BASEURL + "devices";
}
export function device() {
    return BASEURL + "device";
}
export function device_snmp() {
    return BASEURL + "device_snmp";
}
export function snmp_module() {
    return BASEURL + "snmp_module";
}
export function snmp_modules() {
    return BASEURL + "snmp_modules";
}
export function scrape_job() {
    return BASEURL + "scrape_job";
}
export function scrape_jobs() {
    return BASEURL + "scrape_jobs";
}
export function global_config() {
    return BASEURL + "global_config";
}
export function custom_metrics() {
    return BASEURL + "custom_metrics";
}
export function oids() {
    return BASEURL + "oids";
}
export function if_flow_rate_change() {
    return BASEURL + "if_flow_rate_change";
}
export function interfaces() {
    return BASEURL + "interfaces";
}