import React from 'react'
import { Button, Card, Icon, Row, Col, Table, DatePicker, InputNumber, Select, Radio, Modal, message } from 'antd';
import axios from '@/axios/axios_utils';
import Utils from "@/utils/utils";
import moment from 'moment';
const { confirm } = Modal;
const autoFind_Url = "http://" + backup_address + "/ZJNMS_V1/topology/";

export default class setTimedExecute extends React.Component {

    state = {
        allTime: moment(new Date(), "YYYY-MM-DD HH:mm:ss "),
        radioValue: "manual",
        setTimedVisible: false,
        selectedData: [],
        tableData: [],
    }

    componentWillMount() { }
    componentWillReceiveProps(props) {
        let { selectedData, networkItem, setTimedVisible } = props;
        let { radioValue, allTime } = this.state;
        let tableData = selectedData;
        if(props.setTimedVisible !== this.props.setTimedVisible) {
            // console.log(props, this.props)
            if(networkItem._id && setTimedVisible) {
                tableData = [networkItem];
                radioValue = networkItem.scan_mode ? networkItem.scan_mode : "manual";
                allTime = networkItem.auto_scan_config ? moment(networkItem.auto_scan_config.start_datetime) : moment(new Date(), "YYYY-MM-DD HH:mm:ss ");
            } else if(selectedData.length !== 0 && setTimedVisible) {
                radioValue = "manual";
                allTime = moment(new Date(), "YYYY-MM-DD HH:mm:ss ");
            }
            // console.log(setTimedVisible, radioValue)
            this.setState({ tableData, selectedData, radioValue, allTime })
        }
    }
    radioValueChange = e => {
        this.setState({ radioValue: e.target.value })
    }
    update_auto_network_scan_time = (backup_cycle, time_type, selectedRowKeys) => {
        let allTime = moment(this.state.allTime).format("YYYY-MM-DD HH:mm:ss");
        let { radioValue } = this.state;
        let bodyJson = {};
        let rowKeysArr = this.props.networkItem._id ? this.props.networkItem : selectedRowKeys;
        bodyJson.scan_mode = radioValue;
        bodyJson._ids = [];
        bodyJson.auto_scan_config = {};
        if(radioValue === "auto") {
            bodyJson.auto_scan_config = {
                cycle: backup_cycle,
                type: time_type,
                start_datetime: allTime,
            }
        }
        rowKeysArr.map((item, index) => {
            bodyJson._ids.push(item._id);
        })
        // console.log(bodyJson)
        axios.put(autoFind_Url + "updateAutoScanNetworkTime", bodyJson, (result) => {
            if(result.code === "00") {
                message.success(result.msg)
                this.props.getSement();
                this.props.clearSelectedData();
            }
        }, (error) => {
            Utils.showMessage("error", '批量设置时间失败', error);
        });
    };
    checkInfo(content, callback) {
        confirm({
            title: "提示信息",
            content,
            onOk() {
                callback();
            }
        })
    }
    closeModal() {
        this.setState({ setTimedVisible: false });
        this.props.onCancel();
        this.props.clearNetworkItem();
    }

    render = () => {
        let { setTimedVisible, networkItem } = this.props;
        let { time_type, backup_cycle, allTime, tableData } = this.state;    
        let _this = this;
        return (
            <Modal
                title={networkItem._id ? "定时扫描设置" : "批量设置扫描方式"}
                visible={setTimedVisible}
                onCancel={() => this.closeModal()}
                okButtonProps={{ icon: "check-circle" }}
                cancelButtonProps= {{ icon: "close-circle" }}
                onOk={() => {
                    _this.checkInfo("你确定更新该网段扫描方式吗？", () => {
                        _this.update_auto_network_scan_time(_this.state.backup_cycle || 1, _this.state.time_type || "day", tableData)
                    });
                    _this.closeModal();
                }}
                width={"60%"}
                destroyOnClose
            >
                <div >
                    扫描方式：&nbsp;
                    <Radio.Group
                        onChange={this.radioValueChange}
                        defaultValue={this.state.radioValue}
                        value={this.state.radioValue} 
                        options={[
                            { label: "手动扫描", value: "manual" },
                            { label: "自动扫描", value: "auto" },
                        ]}
                    /><p />
                    {this.state.radioValue === "auto" ? 
                        <div>
                            开启时间：&nbsp;
                            <DatePicker showTime style={{ width: 200 }}
                                defaultValue={allTime}
                                onChange={(a) => { this.setState({ allTime: a }) }}
                                placeholder="选择时间" format="YYYY-MM-DD HH:mm:ss" /><p />
                            扫描周期：&nbsp;
                            <InputNumber min={1} style={{ width: 80 }} defaultValue={backup_cycle || 1}
                                onChange={(e) => { this.setState({ backup_cycle: e }) }} />
                            <Select style={{ width: 80 }} defaultValue={time_type || "day"} onChange={(e) => { this.setState({ time_type: e }) }}>
                                <Select.Option value="min">分钟</Select.Option>
                                <Select.Option value="hour">小时</Select.Option>
                                <Select.Option value="day">天</Select.Option>
                                <Select.Option value="week">周</Select.Option>
                            </Select>备份一次<p />
                        </div> : ""
                    }
                    已选网段：
                    <Table
                        columns={[
                            { title: "网段", dataIndex: "net_mask", align: "left", },
                            { title: "网段标签", align: "left", dataIndex: "tags", render: (t) => Utils.hidenTag(t) },
                            { title: "区域", align: "left", dataIndex: "area" }
                        ]}
                        dataSource={tableData}
                        rowKey={(text) => { return text._id }}
                        scroll={{ y: 500 }}
                        size="small"
                        pagination={false}
                    />
                </div>
            </Modal>
        )
    };
}