// 编辑框表单布局
export const editFormItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 9 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
    },
};



//更改disabled样式
export const newDisabledStyle = {
    color: '#333',
    '-webkit-text-fill-color': '#333',
    '-webkit-opacity': 1,
    'opacity': 1,
}