import React from 'react'
import {
    Card, Table, Input, Button, Icon, message, Modal, Form, Row, Col, Collapse, Tooltip, Tag, Upload, Dropdown, Menu, Radio
} from "antd";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import http from '@/axios/axios_utils';
import NewFixedResources from './newFixedResources';
import Axios from 'axios';
import moment from 'moment';
import * as XLSX from 'xlsx';
const { Dragger } = Upload;
const hub_Url = "http://" + backup_address + "/ZJNMS_V1/hub/";

export default class MacAddressList extends React.Component {
    state = {
        current: 1,      //当前页码数
        page_size: 20,    //每页显示条数
        querys: {},    //查询条件
        total: 0,
        loading: false,
        tableData: [],
        selectedRowKeys: [],
        importVisible: false,
        fileList: [],
        formData: "",
        excelData: {},
        newVisible: false,
        resItem: null,
    }
    componentWillMount() {
        this.getData()
    }
    getData(pageNum, pageSize, querys2) {
        this.setState({ loading: true })
        const { current, page_size, querys } = this.state
        let query = querys2 || querys;
        let bodyJson = {
            page_size: pageSize || page_size,
            page_no: pageNum || current,
            ...query
        };
        // console.log(bodyJson, this.state)
        http.get(hub_Url + "fixedResources", bodyJson, (res) => {
            if (res.code === "00") {
                this.setState({
                    tableData: res.data,
                    total: res.count,
                    current: pageNum || 1,
                    loading: false,
                    querys: querys2,
                })
            } else {
                message.error("查询失败");
                this.setState({ loading: false })
            }
        })
    };
    dealTime(time) {
        let month = time.getMonth() + 1;
        let day = time.getDate();
        let year = time.getFullYear();
        let hours = time.getHours();
        let minutes = time.getMinutes();
        let seconds = time.getSeconds();
        return this.tenNumber(year) + this.tenNumber(month) + this.tenNumber(day) + this.tenNumber(hours) + this.tenNumber(minutes) + this.tenNumber(seconds)
    }
    tenNumber(num) {
        let tenNum = num
        if (tenNum >= 0 && tenNum < 10) {
            tenNum = "0" + tenNum
        } else {
            tenNum = tenNum
        }
        return tenNum;
    }
    exportExcel(allFlag) {
        const { selectedRowKeys } = this.state;
        if (!allFlag) {
            if (selectedRowKeys.length === 0) {
                return message.error("请勾选需要导出的设备！")
            }
        }
        let bodyJson = {
            is_all: allFlag ? true : false,
            _ids: selectedRowKeys
        }
        Axios({
            method: "POST",
            url: hub_Url + "exportFixedResources",
            data: bodyJson,
            responseType: 'blob'
        }).then((result) => {
            const link = document.createElement('a');
            let blob = new Blob([result.data], { type: "application/vnd.ms-excel" }); // 转化为 blob 对象 导出.xls文件
            // let blob = new Blob([result.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }); // 转化为 blob 对象  导出.xlsx文件
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = "固定资产表-" + this.dealTime(new Date()); // 文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }
    // 下载导入模版
    downloadTemplate() {
        let list = [
            {
                "Name": "打卡机1",
                "Mac Address": "001a-a9bf-3bf1",
            }
        ]
        Utils.exportExcel(list, "导入固定资产表模板.xlsx");
    }
    // 导入 excel 文件
    importExcel(file) {
        if (Utils.isEmpty(file.fileList)) {
            return;
        }
        let _this = this;
        let fileList = [file.file];
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file.file);
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = {};
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    let tempData = [];
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data[sheet] = tempData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    }
                }
                const excelData = data;
                // console.log(excelData);
                _this.setState({ excelData, fileList });
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                console.log(e)
                message.error('上传失败！');
            }
        }
    }
    // 点击确定导入文件
    onSubmit() {
        const { excelData } = this.state;
        const data = Object.values(excelData)[0];
        let bodyJson = [];
        const { page_size } = this.state;
        // console.log(excelData, data)
        if (data) {
            data.map((item, index) => {
                bodyJson.push({
                    name: item["Name"],
                    mac_address: item["Mac Address"],
                    // port: item["Port"],
                    // switch_ip: item["Switch IP"],
                });
            })
            http.post(hub_Url + "fixedResources", bodyJson, (result) => {
                if (result.code === "00") {
                    message.success("导入成功")
                    this.setState({
                        importVisible: false,
                        fileList: [],
                        excelData: {},
                    });
                    this.getData(1, page_size, {});
                } else {
                    message.error("导入失败")
                }
            })
        } else {
            message.error("请选择需要上传的文件");
        }
    }
    deleteResources(ids) {
        let _this = this;
        const { page_size } = this.state
        if (ids.length === 0) {
            message.error("请选择需要删除的设备");
        } else {
            Modal.confirm({
                title: '消息提示',
                content: '你确定删除该设备吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    http.delete_body(hub_Url + "fixedResources", { ids }, (res) => {
                        if (res.code === "00") {
                            message.success("删除成功");
                            _this.setState({ selectedRowKeys: [] });
                            _this.getData(1, page_size, {});
                        } else {
                            message.error("删除失败")
                        }
                    })
                },
            });
        }
    }
    menuButton(resItem) {
        return (
            <Menu onClick={(e) => this.handleMenuClick(e, resItem)}>
                <Menu.Item key="check">
                    <Icon type="file-search" />盘点
                </Menu.Item>
                <Menu.Item key="del">
                    <Icon type="delete" />删除
                </Menu.Item>
            </Menu>
        )
    }
    handleMenuClick = (e, item) => {
        switch (e.key) {
            case 'edit':
                this.setState({ resItem: item, newVisible: true });
                break;
            case 'del':
                this.deleteResources([item._id]);
                break;
            case "check":
                this.checkFixedResources(item);
                break;
        }
    }
    checkFixedResources(item) {
        let { selectedRowKeys, tableData, page_size } = this.state;
        let macs = [];
        if (item) {
            macs.push(item.mac_address)
        } else {
            tableData.map((tableItem, index) => {
                selectedRowKeys.map((rowItem, key) => {
                    if (tableItem._id === rowItem) {
                        macs.push(tableItem.mac_address)
                        // console.log(rowItem, tableItem._id)
                    }
                })
            })
        }
        if (macs.length === 0) {
            message.error("请选择需要执行的设备");
        } else {
            http.put(hub_Url + "checkFixedResources", { macs }, (res) => {
                if (res.code === "00") {
                    message.success("执行成功");
                    this.getData(1, page_size, {});
                } else {
                    message.error("执行失败")
                }
                // console.log(res, macs)
            })
        }
    }

    render() {
        let { tableData, current, page_size, querys, total, loading, selectedRowKeys, newVisible, resItem } = this.state;
        let columns = [
            {
                title: '序号',
                width: 50,
                dataIndex: 'key',
                render: (text, item, index) => {
                    return <span>{index + 1 + (current - 1) * page_size}</span>
                }
            },
            {
                title: '名称',
                align: 'center',
                width: 140,
                dataIndex: 'name',
            },
            {
                title: 'mac地址',
                align: 'center',
                width: 140,
                dataIndex: 'mac_address',
            },
            {
                title: '接入交换机IP',
                align: 'center',
                width: 130,
                dataIndex: 'switch_ip',
            },
            {
                title: '接入端口',
                align: 'center',
                width: 110,
                dataIndex: 'port',
            },
            {
                title: '最近接入时间',
                align: 'center',
                width: 150,
                dataIndex: 'insert_time',
            },
            {
                title: '新增时间',
                align: 'center',
                width: 150,
                dataIndex: 'create_time',
            },
            {
                title: '盘点状态',
                align: 'center',
                width: 100,
                dataIndex: 'check_status',
                render: (text, item, index) => {
                    switch (text) {
                        case true: return <span>√</span>
                        // case false: return <span>X</span>
                    }
                }
            },
            {
                title: '操作',
                align: 'center',
                dataIndex: 'op',
                width: 120,
                fixed: 'right',
                render: (text, item, index) => {
                    return (
                        <div>
                            <Dropdown.Button
                                overlay={this.menuButton(item)} type='primary' size='small' icon={<Icon type="down" />}
                                onClick={() => { this.handleMenuClick({ key: "edit" }, item) }}
                            >
                                <Icon type='edit' /> 编辑
                            </Dropdown.Button>
                        </div>
                    )
                }
            },
        ];
        const formList = [
            {
                label: '名称',
                type: 'INPUT',
                field: 'name',
                initialValue: '',
                placeholder: '名称'
            },
            {
                label: 'mac地址',
                type: 'INPUT',
                field: 'mac_address',
                initialValue: '',
                placeholder: 'mac地址'
            },
            {
                label: '接入交换机IP',
                type: 'INPUT',
                field: 'switch_ip',
                initialValue: '',
                placeholder: '接入交换机'
            },
            {
                label: '接入端口',
                type: 'INPUT',
                field: 'port',
                initialValue: '',
                placeholder: '接入端口'
            },
            {
                label: '盘点状态',
                type: 'SELECT',
                field: 'check_status',
                initialValue: [],
                placeholder: '盘点状态',
                list: [{ id: 'true', name: '盘点到' }, { id: 'false', name: '未盘点到' },]
            },
        ]
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        let scrollX = 0;
        columns.map((item, index) => {
            scrollX += item.width;
        })
        scrollX = scrollX + 260;

        return <div>
            <CustomBreadcrumb arr={["运维管理", "MAC地址管理", { title: "固定资产", to: "/Ledger/fixedResources" }]} />
            <div className="searchDiv">
                <Collapse defaultActiveKey={"key"}>
                    <Collapse.Panel header="筛选条件" key={"key"}>
                        <FilterForm formList={formList}
                            filterSubmit={(querys) => {
                                this.getData(1, page_size, querys);
                            }}
                            resetTab={() => {
                                this.getData(1, page_size, {})
                            }}
                        />
                    </Collapse.Panel>
                </Collapse>
            </div>
            <Card
                style={{ marginTop: 8 }}
                title={<div style={{ fontWeight: 600 }}>固定资产列表</div>}
                extra={
                    <Row>
                        <Button type="primary" icon="file-search" onClick={() => this.checkFixedResources()}>盘点</Button>
                        <Button type="primary" icon="plus-circle" onClick={() => this.setState({ newVisible: true })}>新增</Button>
                        <Button type="primary" icon="import" onClick={() => this.setState({ importVisible: true })}>导入固定资产表</Button>
                        {/* <Button type="primary" icon="export" onClick={() => this.exportExcel()}>导出固定资产表</Button> */}
                        <Dropdown.Button className="dropDownBtn" type='primary' icon={<Icon type="down" />} style={{ marginRight: 8 }}
                            overlay={
                                <Menu onClick={() => { this.exportExcel("is_all") }}>
                                    <Menu.Item>
                                        <Icon type="export" />导出全部固定资产表
                                    </Menu.Item>
                                </Menu>
                            }
                            onClick={() => { this.exportExcel() }}
                        >
                            <Icon type='export' /> 导出固定资产表
                        </Dropdown.Button>
                        <Button type="danger" icon="delete" onClick={() => this.deleteResources(selectedRowKeys)}>删除</Button>
                    </Row>
                }
            >
                <Table
                    columns={columns}
                    dataSource={tableData}
                    size="small"
                    loading={loading}
                    rowKey={(text) => { return text._id }}
                    scroll={{ x: scrollX, y: 630 }}
                    rowSelection={rowSelection}
                    pagination={{
                        current: current, pageSize: page_size, total: total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            this.setState({ page_size: size })
                            this.getData(1, size, querys)
                        },
                        onChange: (page, pageSize) => { this.getData(page, pageSize, querys); }
                    }}
                />
            </Card>
            <Modal
                title={<div>导入excel文件&nbsp;&nbsp;&nbsp;<Button type="primary" icon="download" onClick={() => { this.downloadTemplate() }}>下载模板</Button></div>}
                visible={this.state.importVisible}
                onOk={() => { this.onSubmit() }}
                onCancel={() => this.setState({ importVisible: false, formData: "" })}
                destroyOnClose
            >
                <Dragger
                    beforeUpload={function () { return false; }}
                    onChange={this.importExcel.bind(this)}
                    fileList={this.state.fileList}
                    onRemove={() => {
                        this.setState({
                            fileList: [],
                            excelData: {},
                        })
                    }}
                >
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">单击或将文件拖到该区域上传</p>
                </Dragger>
            </Modal>
            <NewFixedResources
                newVisible={newVisible}
                onCancel={() => { this.setState({ newVisible: false, resItem: null }) }}
                getData={(page, pageSize, query) => this.getData(page || current, pageSize || page_size, query || querys)}
                resItem={resItem}
            />
        </div>
    }
}