import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Modal, Icon, message, Tag, Dropdown, Menu, Progress, List, Divider, Checkbox, Tooltip
} from "antd";
import SearchCard from './searchCard';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import DataMap from './dataMap';
import http from '@/axios/axios_utils';
import Utils from '@/utils/utils';
import Socket from '@/socket/index';
import api from '@/config/api/deliveryTask';
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import MonacaEditor from "react-monaco-editor"
import "./index.scss"
import "animate.css"
let MonaceEditorObj = null;
let logFlag = false;
let logTimeRun = null;
let progressRun = null;
function AclTask(props) {
    const [total, set_total] = useState(0);
    const [_pageSize, set_pageSize] = useState(10);
    const [_current, set_current] = useState(1);
    const [tasks, set_tasks] = useState([]);
    const [selectedRowKeys, setRowKey] = useState([]);
    const [] = useState([]);
    const [] = useState([]);
    const [operRecords, setOperRecord] = useState([]);
    const [] = useState({});
    const [] = useState(false);
    const [recSta, set_recSta] = useState(false);
    const [logSta, setLogSta] = useState(false);
    const [autoCheched, setAutoCheched] = useState(false);
    const [configData, setConfigData] = useState({});
    const [_querys, set_querys] = useState({});
    const [treeDepIds, setTreeDepIds] = useState([]);

    // const tempquerys = props.history.location.bodyJson || _querys;
    // const [initialValues, setInitialValues] = useState({
    //     type: tempquerys.type || "",
    //     username: tempquerys.username || "",
    //     task_name: tempquerys.task_name || "",
    // });
    const [SellData, setSellData] = useState("");
    useEffect(() => {//  
        props.dispatch(isLoading(true));
        http.setDispatch(null)
        getAreaData();
        return () => {
            clearInterval(logTimeRun);
            clearInterval(progressRun);
        }
    }, []);

    const getTasks = async (pageSize, pageNum, querys) => {
        let bodyJson = querys || _querys;
        bodyJson.page_size = pageSize || _pageSize;
        bodyJson.page_no = pageNum || _current;
        bodyJson.dep_ids = querys ? (bodyJson.dep_ids ? [...bodyJson.dep_ids] : [...treeDepIds]) : [...treeDepIds];
        const data = await http.post(api.conditionQueryDeliveryTask, bodyJson);
        set_tasks(data.data);
        set_total(data.count);
        set_current(bodyJson.page_no);
        set_querys(bodyJson);
        if (!data.execute_task) {//任务执行完毕，暂停进度查询
            clearInterval(progressRun)
        }
        props.dispatch(isLoading(false));
    }
   const getAreaData = () => {
        Socket.ExecuteAction('QueryArea', {}, "", (result) => {
            const TreeData = result.data;
            const depIds = dealTreeData(TreeData);
            // console.log("getAreaData", TreeData, depIds)
            setTreeDepIds(depIds);
            initData(depIds);
        });
    };
    function dealTreeData(data) {
        let dep_ids = [];
        if(Array.isArray(data)) {
            data.map((item) => {
                let children = item.props ? item.props.children : item.children
                if(children && children.length > 0) {
                    dep_ids = dep_ids.concat(dealTreeData(children));
                } else {
                    dep_ids.push(item.key || item.eventKey)
                }
            })
        }
        return dep_ids
    }
    function initData(depIds) {
        const querys = props.history.location.bodyJson || _querys;
        querys.dep_ids = depIds || [...treeDepIds] || []
        getTasks(querys.page_size, querys.page_no, querys);
        runTimeProgress();//启动定时器
        getConfig();
    }
    function taskSubmit(val) {
        Modal.confirm({
            title: '消息提示',
            content: '你确定提交任务' + val.task_name + '吗？',
            onOk() {
                props.dispatch(isLoading(true));
                http.put(api.commitDeliveryTask, val, (data) => {
                    message.success(data.msg);
                    getTasks();
                });
            },
        });
    }
    function runTimeProgress() {
        clearInterval(progressRun);
        progressRun = setInterval(() => {
            getTasks(null, null, _querys); // 更新列表
        }, 3000);
    };
    function getConfig() {
        Socket.getConfig("command", (res) => {
            setAutoCheched(res.logAutoScrllo);
            logFlag = res.logAutoScrllo;
            setConfigData(res);
        });
    };
    function setConfig(value) {
        Socket.setConfig("command", value, () => {
            setAutoCheched(value.logAutoScrllo);
            logFlag = value.logAutoScrllo;
            setConfigData(value);
        });
    };
    function approvalRecordByTaskId(_id) {
        props.dispatch(isLoading(true));
        http.get(api.approvalRecordByTaskId, { _id }, (data) => {
            setOperRecord(data.data)
            set_recSta(true);
            props.dispatch(isLoading(false));
        });
    }
    function executeDeliveryTask(val) {
        Modal.confirm({
            title: '消息提示',
            content: '你确定执行任务' + val.task_name + '吗？',
            onOk() {
                props.dispatch(isLoading(true));
                http.post(api.executeDeliveryTask, val, (data) => {
                    message.success(data.msg);
                    getTasks();
                });
                setTimeout(() => {//及时更新状态
                    getTasks();
                    runTimeProgress();//启动定时器
                }, 500)
            },
        });
    }
    function delTask(val) {
        if (val.length === 0) { message.info("请选择任务"); return; }
        Modal.confirm({
            title: '消息提示',
            content: '你确定删除所选记录吗？',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                http.delete_body(api.delDeliveryTask, { task_id_list: val, username: Utils.getLoginUser() },
                    (data) => {
                        message.success(data.msg);
                        getTasks();
                    });
            },
        });
    }
    function detailTask(b) {
        b.querys = _querys;
        sessionStorage.setItem("acltask", JSON.stringify(b))
        window.location.href = "#/command/newAclTask"
    }
    function taskRecord(b) {
        b.querys = _querys;
        sessionStorage.setItem("acltask", JSON.stringify(b));
        props.history.push("/command/exeRecord");
    }
    function getTaskLogs(_id, flag) {
        try {
            http.get(api.taskShell, { _id }, (data) => {
                setSellData(data.data)
                if (data.status != "执行中") {
                    clearInterval(logTimeRun);
                }
                const num = data.data.split("\n").length;
                setTimeout(() => {
                    if (MonaceEditorObj && num && flag) {
                        MonaceEditorObj.revealLineInCenter(num, num + 1);
                        MonaceEditorObj.setPosition({
                            lineNumber: num,
                            column: 1
                        })
                        MonaceEditorObj.focus();
                    }
                }, 1000)
            }, (msg) => {
                clearInterval(logTimeRun);
                message.error(msg);
                setLogSta(false);
            });
        } catch (e) {
            clearInterval(logTimeRun);
        }
    }
    function logTask(b) {
        setOperRecord(b)
        getTaskLogs(b._id, logFlag);
        logTimeRun = setInterval(() => {
            getTaskLogs(b._id, logFlag);
        }, 2000);
        setLogSta(true);
    }
    function handleMenuClick(e, item) {
        console.log(e, item);
        switch (e.key) {
            case 'detail': detailTask(item); break;
            case 'flowRecord': approvalRecordByTaskId(item._id); break;
            case 'taskRecord': taskRecord(item); break;
            case 'del': delTask([item._id]); break;
            case 'start': executeDeliveryTask(item); break;
            case 'stop': break;
            case 'commit': taskSubmit(item); break;
            case 'log': logTask(item); break;
            case 'setting': break;
        }
    }
    const colums = [
        {
            title: "序号", render: (a, b, c) => {
                return c + 1 + (_current - 1) * _pageSize
            }
        },
        {
            title: "任务名称", dataIndex: "task_name", align: "left", render: (a, b) => {
                return <a onClick={() => { handleMenuClick({ key: "taskRecord" }, b); }}><Icon type="file-text" />&nbsp;{a}</a>
            }
        },
        { title: "任务类型", dataIndex: "type" },
        {
            title: "任务状态", dataIndex: "status", align: "left", render: (a) => {
                let color = "blue";
                switch (a) {
                    case "执行中": color = "purple"; break;
                    case "完成": color = "#52c41a"; break;
                    case "失败": color = "#f50"; break;
                    case "终止": color = "#f5222d"; break;
                    case "审批中": color = "geekblue"; break;
                    case "审批通过": color = "green"; break;
                    case "暂停": color = "red"; break;
                    case "审批拒绝": color = "magenta"; break;
                    case "待执行": color = "#108ee9"; break;
                }
                return <Tag color={color}>{a}</Tag>
            }
        },
        {
            title: "进度", dataIndex: "Progress", width: 200, render: (a, b) => {
                let status = "success";
                if (b.status === "执行中") { status = "active" }
                if (b.status === "终止") { status = "exception" }
                return <div><Progress showInfo={true} percent={b["execute_progress"]} status={status} /></div>
            }
        },
        {
            title: "执行方式", dataIndex: "automatic", render: (a, b) => {
                const text = a ? <div><Icon type="hourglass" />&nbsp;定时</div> : <div><Icon type="tool" />&nbsp;手动</div>;
                const title = b.auto_execute_time ? "定时时间:" + b.auto_execute_time : "手动执行"
                return <Tooltip title={title}>{text}</Tooltip>
            }
        },
        { title: "创建人", dataIndex: "username" },
        { title: "创建时间", dataIndex: "create_time" },
        {
            title: "操作", render: (a, b) => {
                let menuStatus = [false, true, true, false,];
                if (
                    b.status === "审批拒绝" ||
                    b.status === "审批中" ||
                    b.status === "保存"
                ) { menuStatus[0] = true; }
                if (b.status === "执行中") { menuStatus[1] = false; }
                if (
                    b.status === "保存" ||
                    b.status === "完成" ||
                    b.status === "审批拒绝" ||
                    b.status === "暂停" ||
                    b.status === "失败"
                ) { menuStatus[2] = false; }


                return <Dropdown.Button size="small" type="primary" onClick={() => handleMenuClick({ key: "log" }, b)}
                    overlay={() => {
                        return <Menu onClick={(e) => handleMenuClick(e, b)}>
                            <Menu.Item key="start" disabled={menuStatus[0]}>
                                <Icon type="play-circle" />执行任务
                            </Menu.Item>
                            {/* <Menu.Item key="stop" disabled={menuStatus[1]}>
                                <Icon type="pause-circle" />暂停任务
                            </Menu.Item> */}
                            <Menu.Item key="commit" disabled={menuStatus[2]}>
                                <Icon type="check-circle" />提交任务
                            </Menu.Item>
                            <Menu.Item key="taskRecord" disabled={false}>
                                <Icon type="file-text" />执行记录
                            </Menu.Item>
                            <Menu.Item key="detail" disabled={false}>
                                <Icon type="setting" />任务设置
                            </Menu.Item>
                            <Menu.Item key="flowRecord" disabled={menuStatus[3]}>
                                <Icon type="file-exclamation" />审批记录
                            </Menu.Item>
                            <Menu.Item key="del">
                                <Icon type="delete" />删除任务
                             </Menu.Item>
                        </Menu>
                    }}
                    icon={<Icon type="down" />}>
                    <Icon type="file-sync" />任务日志
              </Dropdown.Button>
            }
        },

    ]
    function showStatusImage() {
        Modal.info({
            style: { top: 20 },
            maskClosable: true,
            title: "任务流程状态图",
            content: <div style={{ textAlign: "center" }}><img src="/image/aclStatus2.png"></img></div>,
            width: "85%",
        })
    }
    return <div className="sendAclTaskDiv">
        <CustomBreadcrumb arr={["运维管理", "配置下发", "下发配置"]} />
        <DataMap className="animated fadeInUp" />
        <p />
        <SearchCard
            // initialValues={initialValues}
            onSubmit={(values) => {
                values.dep_ids = treeDepIds || [];
                props.dispatch(isLoading(true)); getTasks(_pageSize, 1, values)
            }} reSet={() => { props.dispatch(isLoading(true)); getTasks(_pageSize, 1, {}); }} />
        <p />
        <Card title={Utils.BoldFont("下发配置")} extra={<div>
            <Button type="primary" icon="cluster" onClick={() => { showStatusImage(); }}>流程状态图</Button>
            <Button type="primary" icon="profile" onClick={() => {
                sessionStorage.setItem("acltask", "{}")
                window.location.href = "#/command/newAclTask"
            }}>新建</Button>
            <Button type="danger" icon="delete" onClick={() => { delTask(selectedRowKeys) }}>删除</Button>
            {/* <Button icon="reload" onClick={() => { getTasks(_pageSize, _current) }}>重置</Button> */}
        </div>}>

            {/* <Divider /> */}
            <Table
                rowSelection={{
                    selectedRowKeys: selectedRowKeys,
                    onChange: (selectedRowKeys) => {
                        // selectedRowsAll[_current + ""] = selectedRows;
                        // let allrows = [];
                        // Object.values(selectedRowsAll).map((rows) => {
                        //     allrows = [...allrows, ...rows]
                        // });
                        // setSelectedRowsAll(selectedRowsAll);
                        // setRow(allrows);
                        setRowKey(selectedRowKeys);
                    },
                }}
                rowKey={(row) => { return row._id }}
                columns={colums}
                dataSource={tasks}
                size="small"
                pagination={{
                    pageSize: _pageSize, current: _current, total: total,
                    showTotal: (total) => { return <span>共 {total} 项</span> },
                    pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) => {
                        set_pageSize(size)
                        getTasks(size, 1)
                    },
                    onChange: (page, pageSize) => {
                        getTasks(pageSize, page)
                    }
                }}
            />
        </Card>
        <Modal
            title={"审批记录"}
            width="70%"
            visible={recSta}
            onCancel={() => { set_recSta(false) }}
            style={{ top: 20 }}
            footer={null}
            destroyOnClose
        >
            <div><Icon type="star" theme="twoTone" />——展示任务相关审批记录</div><br />
            <div className="aclRecSteps">
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={operRecords}
                    renderItem={(item, key) => {
                        let icon = "audit";
                        let color = "#1890ff";
                        let flag = item.approval_status;
                        if (item.approval_result === "permit") {
                            flag = "通过";
                            icon = "check-circle";
                            color = "#52c41a";
                        } else if (item.approval_result === "deny") {
                            icon = "close-circle";
                            color = "red";
                            flag = "拒绝";
                        }
                        return <List.Item key={flag + key}>
                            <div className="aclflow">
                                <div className="title"><Icon style={{ color: color }} type={icon} /><Divider type="vertical" /><span>【{flag}】{item.approval_content}</span></div>
                                {/* <div className="content">【{flag}】{item.operation_content}</div> */}
                                <div className="foot">提交人: {item.commit_user} <Divider type="vertical" />提交时间: {item.commit_time}</div>
                                <div className="foot">审批人: {item.approval_user} <Divider type="vertical" />审批时间: {item.approval_time || "待审批"}</div>
                            </div>
                        </List.Item>
                    }}
                />
            </div>
        </Modal>
        <Modal
            title={"任务日志"}
            width="85%"
            visible={logSta}
            onCancel={() => {
                setLogSta(false); clearInterval(logTimeRun); setSellData("");
            }}
            afterClose={() => {
                clearInterval(logTimeRun);
            }}
            style={{ top: 20 }}
            footer={null}
            destroyOnClose
        >
            <div id="aa">
                <p>
                    <strong>任务名称:{operRecords.task_name}</strong>
                    <strong style={{ float: "right", marginRight: 10 }}>滚动条自动置底:<Checkbox checked={autoCheched} onChange={(e) => {
                        const temp = configData;
                        temp.logAutoScrllo = e.target.checked;
                        setConfig(temp);
                    }} /></strong>
                </p>
                <MonacaEditor
                    theme="vs-dark"
                    value={SellData}
                    editorDidMount={(editor) => {
                        console.log(editor)
                        MonaceEditorObj = editor
                    }}
                    language="python"
                    options={{ minimap: { enabled: false }, lineNumbers: "off", scrollBeyondLastLine: false, }}
                    height="700"
                />
            </div>
        </Modal>
        <p />
    </div >
}

export default connect()(AclTask)