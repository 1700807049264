import React from 'react'
import {
    Table, Button, Row, Col, Icon, Modal, Collapse, Input, Tooltip
} from "antd";
import MonacaEditor from "react-monaco-editor"
import http from '@/axios/axios_utils';
import api from '@/config/api/deliveryTask';

class CmdTable extends React.Component {
    state = {
        commandItem: []
    }
    componentWillMount() {
        let { devicesFirm } = this.props;
        this.getCmdItem(devicesFirm);
    }
    componentWillReceiveProps(props) {
        if(props.devicesFirm !== this.props.devicesFirm) {
            this.getCmdItem(props.devicesFirm)
        }
    }
    casCommandItem(type, cmd) {
        // e.stopPropagation();
        let cmdValue = this.props.commandsFormValue
        if (type === 'view') {
            Modal.info({
                title: "查看下发指令：" + cmd.name,
                width: "65%",
                style: { top: 30 },
                okText: "关闭",
                maskClosable: true,
                content: <MonacaEditor
                    theme="vs-dark"
                    value={cmd.cmd}
                    language="python"
                    options={{ minimap: { enabled: false }, lineNumbers: "off", scrollBeyondLastLine: false, }}
                    height="600"
                />
            })
        } else if (type === 'add') {
            this.props.setFormValue(cmdValue ? cmdValue + '\n' + cmd : cmd)
        }
    }
    getCmdItem(firms) {
        let bodyJson = {
            firms: firms ? firms.toUpperCase() : firms,
        }
        http.get(api.cmdItem, bodyJson, (res) => {
            if (res.code === '00') {
                let data = res.data
                this.setState({ commandItem: data })
                console.log("getCmdItem", data)
            } else {
                message.error(res.msg)
            }
        })
    }
    dealCmdData(data, popVis) {
        let cmdData = data.map(i => {
            let childrenItem = i.children
            if (childrenItem) {
                return {
                    ...i,
                    label: i.value,
                    children: this.dealCmdData(i.children, popVis)
                }
            } else {
                return {
                    value: i.value,
                    label: <>
                        <span style={{ marginRight: 15 }}>{i.value}</span>
                        <span onClick={(e) => this.casCommandItem('view', e, i.cmd)}><Icon type="eye" theme="twoTone" />&nbsp;查看</span> &nbsp;
                        <span onClick={(e) => this.casCommandItem('add', e, i.cmd)}><Icon type="plus-circle" theme="twoTone" />&nbsp;补充</span>
                    </>
                }
            }
        })
        return cmdData
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            (record[dataIndex] || "")
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        }
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    render() {
        let { commandItem } = this.state
        const columns = [
            { dataIndex: "number", title: "序号", align: "center", ellipsis: true, width: 50, render: (text, item, index) => index + 1 },
            { dataIndex: "name", title: "名称", align: "center", ellipsis: true, ...this.getColumnSearchProps("name") },
            { dataIndex: "firm", title: "厂商", align: "center", ellipsis: true, ...this.getColumnSearchProps("firm") },
            { dataIndex: "model", title: "型号", align: "center", ellipsis: true, ...this.getColumnSearchProps("model") },
            {
                dataIndex: "cmd", title: "命令", align: "center", ellipsis: true, ...this.getColumnSearchProps("cmd"), render: (text, item, index) => <Tooltip title={text}>
                    <div>{text}</div>
                </Tooltip>
            },
            { dataIndex: "desc", title: "描述", align: "center", ellipsis: true, ...this.getColumnSearchProps("desc") },
            {
                dataIndex: "op", title: "操作", width: 220, align: "center", ellipsis: true, render: (text, item, index) => {
                    return <div>
                        <Button type="primary" icon="eye" size="small" onClick={() => { this.casCommandItem('view', item) }}>查看</Button>
                    &nbsp;
                    <Button type="primary" size="small" icon="plus-circle" onClick={(e) => this.casCommandItem('add', item.cmd)}>补充</Button>
                    </div>
                }
            }
        ]

        return (
            <Collapse bordered={false}>
                <Collapse.Panel header="展开" style={{ textAlign: 'center' }}>
                    <Table
                        rowKey={(text) => { return text._id }}
                        size="small"
                        pagination={false}
                        dataSource={commandItem}
                        columns={columns}
                    />
                </Collapse.Panel>
            </Collapse>
            // <Cascader popupClassName="popSelect" options={commandItem} onChange={(value) => {

            // }} >
            //     <div style={{ width: '100%', background: '#f4f4f4', textAlign: 'center', cursor: 'pointer' }}>展开</div>
            // </Cascader>
        )
    }
}
export default CmdTable