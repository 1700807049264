/**
 * Created by YIMINE on 2019/5/22.
 */
import React from "react";
import {
    G2,
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Label,
    Legend,
    View,
    Guide,
    Shape,
    Facet,
    Util
} from "bizcharts";
import DataSet from "@antv/data-set";
const data = [
    {
        item: "事例一",
        count: 40
    },
    {
        item: "事例二",
        count: 21
    },
    {
        item: "事例三",
        count: 17
    },
    {
        item: "事例四",
        count: 13
    },
    {
        item: "事例五",
        count: 9
    }
];
export default class Pie extends React.Component {
    componentWillMount() {
        this.createChar();
    }

    createChar = () => {
        const ds = new DataSet();
        const dvSet = ds.createView().source(data);
        // let newArr = [];
        // for (let filed in data[0]) {
        //     // console.log(filed);
        //     if (filed != 'name') {
        //         newArr.push(filed);
        //     }
        // }
        dvSet.transform({
            type: "percent",
            field: "count",
            dimension: "item",
            as: "percent"
        });
        this.setState({ dvSet });
    };
    render() {
        const cols = {
            percent: {
                formatter: val => {
                    val = val * 100 + "%";
                    return val;
                }
            }
        };
        let { dvSet } = this.state
        return (
            <Chart
                height={350}
                data={dvSet}
                scale={cols}
                padding={[10, 80, 10, 50]}
                forceFit
            // style={{border:'1px solid red'}}
            >
                <Coord type="theta" radius={0.75} />
                <Axis name="percent" />
                <Legend
                    position="right"
                    offsetY={-350 / 2}
                    offsetX={-50}
                    height={200}
                />
                <Tooltip
                    showTitle={false}
                    itemTpl="<li><span style=&quot;background-color:{color};&quot; class=&quot;g2-tooltip-marker&quot;></span>{name}: {value}</li>"
                />
                <Geom
                    type="intervalStack"
                    position="percent"
                    color="item"
                    tooltip={[
                        "item*percent",
                        (item, percent) => {
                            percent = percent * 100 + "%";
                            return {
                                name: item,
                                value: percent
                            };
                        }
                    ]}
                    style={{
                        lineWidth: 1,
                        stroke: "#fff"
                    }}
                >
                    <Label
                        content="percent"
                        formatter={(val, item) => {
                            return item.point.item + ": " + val;
                        }}
                    />
                </Geom>
            </Chart>
        );
    }
}
