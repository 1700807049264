import React from 'react'
import './index.scss'
import { Card, Table, message, Popconfirm, Button, notification, Icon, Tag, Dropdown, Menu, Modal, Tabs, Typography, Collapse } from "antd";
import Socket from '../../../socket/index'
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import FileModal from '@/components/FileModal/index';
// import ImportModal from './impInfoModal';
import ExportButton from '@/components/ExportButton/index';
import Utils from '@/utils/utils';
const { confirm } = Modal;
const { TabPane } = Tabs;
const { ButtonGroup } = Button.Group;
let FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
class Operating extends React.Component {
    state = {
        visible: false,
        _current: 1,       //当前页码数
        _pageSize: 20,    //当前每页显示条数
        querys: null,    //查询条件
        loading: false,
        FileVis: false,
        ImportVis: false,
        MIBVis: false,
        fileName: "",
        selectedRowKeys: [],
        firmList: ["HUAWEI", "CISCO", "H3C", "MAIPU", "RUIJIE",]
    };
    componentWillMount() {
        FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
        let bodyJson = this.props.location.bodyJson || {};
        this.updata(bodyJson.pageSize, bodyJson.pageNum, bodyJson.querys);
    }
    DeleteOperation = (ids) => {
        let { _pageSize, _current, querys, } = this.state;
        Socket.ExecuteAction("DeleteOperation", { _id: ids }, "/delOperation", (result) => {
            message.success('删除成功');
            this.updata(_pageSize, _current, querys);
            this.setState({ selectedRowKeys: [] })
        });
    };
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        Socket.QueryOperationsPage(pageSize || 20, pageNum || 1, querys || {}, (result) => {
            console.log(result);
            let Operating = result.operations;
            Operating.map((item, index) => { item.key = index; item.index = index; });
            this.setState({
                tableData: Operating,
                _current: pageNum || 1,
                _pageSize: pageSize || this.state._pageSize,
                total: result.total,
                querys,
                loading: false,
                FileVis: false
            })
        }, "/queryOperation");
    };
    handleMenuClick = (e, item) => {
        console.log(e);
        let _this = this;
        switch (e.key) {
            case 'edit':
                const { _current, _pageSize, querys } = this.state
                let bodyJson = {
                    pageNum: _current,      //当前页码数
                    pageSize: _pageSize,    //每页显示条数
                    querys,    //查询条件
                }
                let path = {
                    pathname: `/newOperating:${item._id}`,
                    bodyJson,
                };
                this.props.history.push(path);
                break;
            case 'delete':
                confirm({
                    title: '消息提示',
                    content: '你确定删除' + item.operationName + '吗？',
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        _this.DeleteOperation([item._id]);
                    },
                    onCancel() { },
                });

                break;
            case 'copy': this.props.history.push(`/newOperating:${item._id}&copy`); break;
        }
    }
    mutilDelete() {
        const { selectedRowKeys } = this.state;
        const _this = this;
        if (Utils.isEmpty(selectedRowKeys)) {
            message.error("请勾选设备！");
            return;
        }
        confirm({
            title: "消息提示",
            maskClosable: true,
            onOk() { _this.DeleteOperation(selectedRowKeys) },
            content: <div>你确定删除选中操作吗？<br />该操作不可恢复</div>
        })
    }
    selectButton(operate, index) {
        let buttonArr = document.getElementsByClassName("vendorBtn");
        for (let i = 0; i < buttonArr.length; i++) {
            if (buttonArr[i].className.split(" ").indexOf("selectBtn") != -1) {
                buttonArr[i].classList.remove("selectBtn");
            }
        }
        if (operate === "select") {
            buttonArr[index].classList.add("selectBtn");
        }
    }
    render() {
        let { _current, _pageSize, querys, FileVis, ImportVis, MIBVis, fileName, firmList } = this.state;
        const columns = [{
            title: '序号',
            align: 'center',
            dataIndex: 'key',
            render: (text) => { return <span>{text + 1 + (_current - 1) * _pageSize}</span> }
        }, {
            title: '操作名称',
            align: 'left',
            dataIndex: 'operationName'
        }, {
            title: '类型',
            align: 'center',
            dataIndex: 'operationType'
        }, {
            title: '创建者ID',
            align: 'center',
            dataIndex: 'createUserId'
        }, {
            title: '创建时间',
            align: 'center',
            dataIndex: 'createTime'
        }, {
            title: '标签',
            align: 'left',
            dataIndex: 'tags',
            render: (text) => {
                return Utils.hidenTag(text);
            }
        },
        // {
        //     title: '分组',
        //     align: 'left',
        //     dataIndex: 'groupNames',
        //     render: (text) => {
        //         return Utils.hidenTag(text);
        //     }
        // }, 
        {
            title: '操作',
            align: 'center',
            render: (text, item) => {
                return <div>
                    <Dropdown.Button overlay={<Menu onClick={(e) => this.handleMenuClick(e, item)}>
                        <Menu.Item key="copy"><Icon type="copy" />复制</Menu.Item>
                        <Menu.Item key="delete"><Icon type="delete" />删除</Menu.Item>
                    </Menu>}
                        type="primary" icon={<Icon type="down" />} size="small"
                        onClick={() => { this.handleMenuClick({ key: "edit" }, item) }}
                    ><Icon type="edit" />编辑
                    </Dropdown.Button>
                </div>
            }
        }];

        const modalParam = {
            title: "导入操作",
            APIName: "UploadOperations",
        }
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys:`, selectedRowKeys, 'selectedRows: ', selectedRows);
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: changableRowKeys => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        return (
            <div>
                <CustomBreadcrumb arr={["自定义任务", "操作管理"]} />
                <Collapse defaultActiveKey={"key"}>
                    <Collapse.Panel header="筛选条件" key={"key"}>
                        <FilterForm
                            tagClass="operation"
                            filterSubmit={(querys) => {
                                let bodyJson = {};
                                bodyJson = querys;
                                if (this.state.querys) {
                                    bodyJson.firm = this.state.querys.firm ? this.state.querys.firm : undefined;
                                }
                                this.setState({ querys: bodyJson })
                                this.updata(this.state._pageSize, 1, bodyJson)
                            }}
                            resetTab={() => {
                                this.selectButton();
                                this.setState({ querys: {} })
                                this.updata(this.state._pageSize, 1, {})
                            }}
                        />
                    </Collapse.Panel>
                </Collapse>
                <Card title={<div style={{ fontWeight: 600 }}>操作列表&nbsp;&nbsp;&nbsp;
                    </div>}
                    style={{ marginTop: 8 }}
                    extra={<div>

                        {/* <Button type="primary" icon="upload"
                            onClick={() => { this.setState({ MIBVis: true }) }}>上传MIB</Button> */}
                        <ExportButton APIName="DownloadOperations" selectedRowKeys={this.state.selectedRowKeys} />
                        <Button type="primary" icon="import"
                            onClick={() => { this.setState({ FileVis: true }) }}>导入操作</Button>
                        <Button type="primary" style={{ marginLeft: 8 }} icon="edit"
                            onClick={() => {
                                this.props.history.push('/newOperating');
                            }}>新建操作</Button>
                        <Button type="danger" ghost icon="delete" onClick={() => { this.mutilDelete() }}>删除</Button>
                    </div>}><Typography.Text strong>厂商筛选:&nbsp;</Typography.Text>
                    <Button.Group>
                        {firmList.map((firm, index) => {
                            return <Button key={index} className="vendorBtn" style={{ margin: 0 }} onClick={() => {
                                let bodyJson = { ...this.state.querys };
                                bodyJson.firm = { deviceFirm: firm }
                                this.selectButton("select", index);
                                this.setState({ querys: bodyJson });
                                this.updata(_pageSize, 1, bodyJson)
                                // this.setState({ querys: { firm: { deviceFirm: firm } } });
                                // this.updata(_pageSize, 1, { firm: { deviceFirm: firm } })
                            }}><img src={"/image/" + firm + ".jpg"} className={"imgstyle"} />&nbsp;{firm}</Button>
                        })}
                        <Button icon="reload" style={{ position: "absolute", marginLeft: "0" }} onClick={() => {
                            let bodyJson = this.state.querys;
                            if (bodyJson) {
                                bodyJson.firm = this.state.querys.firm ? undefined : undefined;
                            }
                            this.selectButton()
                            this.setState({ querys: bodyJson })
                            this.updata(this.state._pageSize, 1, bodyJson)
                        }}>取消筛选</Button>
                    </Button.Group>
                    <hr style={{ border: "1px solid #e2e2e2" }} />
                    <Table
                        dataSource={this.state.tableData}
                        columns={columns}
                        rowSelection={rowSelection}
                        rowKey={(text) => { return text._id }}
                        size="small"
                        loading={this.state.loading}
                        bordered={false}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.updata(size, 1, querys)
                            },
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize) 
                                this.updata(pageSize, page, querys)
                            }
                        }}
                    />

                </Card>
                <FileModal FileVis={MIBVis} APIName="UploadMIB" title="上传MIB文件"
                    uploadCallBack={(fileName) => {
                        Socket.ExecuteAction('UploadMIB',
                            { fileName }, "/UploadMIB", (result) => {
                                this.setState({ MIBVis: true, });
                            }, (error) => {
                                notification.open({
                                    message: '调用UploadMIB失败',
                                    description: error,
                                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                                });
                            });
                    }}
                    onCancel={() => this.setState({ MIBVis: false })} />
                <FileModal FileVis={FileVis}  {...modalParam}
                    btnName="开始导入"
                    uploadCallBack={(fileName) => this.setState({ fileName })}
                    callback={() => this.updata()}
                    onCancel={() => this.setState({ FileVis: false })} />
                {/* <ImportModal visible={ImportVis}
                    title="填写信息"
                    fileName={fileName}
                    tagClass={"operation"}
                    APIName={"UploadOperations"}
                    callback={() => this.updata(this.state._pageSize, 1, {})}
                    footer={null}
                    onCancel={() => this.setState({ ImportVis: false })} /> */}
            </div>
        )
    }
}


export default Operating