import service from "../zx-utils/request"

// 获取列表
export function fetchList(query, callback) {
    service({
        url: '/zx/version/?t=' + Date.parse(new Date()).toString() ,
        method: 'get',
        params: query
    }).then(res => {
        callback(res)
    })
}

// 回退
export function backTo(query, callback) {
    service({
        url: '/zx/version/',
        method: 'post',
        data: query
    }).then(res => {
        callback(res)
    })
}

//恢复到最新数据
// export function toLatest(query, callback) {
//     service({
//         url: '/zx/version/',
//         method: 'get',
//         data: query
//     }).then(res => {
//         callback(res)
//     })
// }