import moment from 'moment';
import React from 'react'
import { Descriptions, Tag, Icon, Table, Tooltip } from "antd";
export function fixedZero(val) {
    return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
    const now = new Date();
    const oneDay = 1000 * 60 * 60 * 24;

    if (type === 'today') {
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        return [moment(now), moment(now.getTime() + (oneDay - 1000))];
    }

    if (type === 'week') {
        let day = now.getDay();
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);

        if (day === 0) {
            day = 6;
        } else {
            day -= 1;
        }

        const beginTime = now.getTime() - day * oneDay;

        return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
    }

    if (type === 'month') {
        const year = now.getFullYear();
        const month = now.getMonth();
        const nextDate = moment(now).add(1, 'months');
        const nextYear = nextDate.year();
        const nextMonth = nextDate.month();

        return [
            moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
            moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
        ];
    }

    const year = now.getFullYear();
    return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

export function showDetail(data) {
    return <Descriptions column={1} bordered>
        {data.map((item, key) => {
            if (item.clickIcon) {
                return <Descriptions.Item key={key} label={item.label} span={1} >
                    <Table
                        dataSource={item.value || []}
                        scroll={{ y: 165 }}
                        columns={[
                            {
                                title: '序号',
                                dataIndex: 'key',
                                ellipsis: true,
                                width: 40,
                                render: (text, b, c) => { return c + 1 }
                            },
                            {
                                title: '处理时间',
                                dataIndex: 'dealTime',
                                ellipsis: true,
                            },
                            {
                                title: '发送状态',
                                dataIndex: 'sendStatus',
                                ellipsis: true,
                                render: (text, item, index) => {
                                    return (
                                        <>
                                            {item.emailSendRecordId ? <p style={{ margin: 0, marginBottom: 3 }}>邮件发送：{item.emailSendContent ? switchState(item.emailSendContent.state) : ''}</p> : '' }
                                            {item.smsSendRecordId ? <p style={{ margin: 0 }}>短信发送：{
                                                item.smsSendContent ? (item.smsSendContent.status === 'ok' ? <Tag color="green">成功</Tag> : <Tag color="red">失败</Tag>) : ''}</p> : ''}
                                        </>
                                    )
                                }
                            },
                            {
                                title: '接收人',
                                dataIndex: 'servers',
                                ellipsis: true,
                                width: 300,
                                render: (text, item, index) => {
                                    return (
                                        <>
                                            {item.emailSendRecordId ?
                                                <Tooltip title={item.emailSendContent ? item.emailSendContent.server : ''} overlayStyle={{ maxWidth: 265 }}>
                                                    <p style={{ marginBottom: 3 }} className="ellipsisP">邮件接收人：{item.emailSendContent ? item.emailSendContent.server : ''}</p>
                                                </Tooltip> : '' }
                                            {item.smsSendRecordId ?
                                                <Tooltip title={item.smsSendConten ? item.smsSendContent.phones.join('，') : ''} overlayStyle={{ maxWidth: 265 }}>
                                                    <p className="ellipsisP">短信接收人：{item.smsSendConten ? item.smsSendContent.phones.join('，') : ''}</p>
                                                </Tooltip> : '' }
                                        </>
                                    )
                                }
                            },
                            {
                                title: '通知方式',
                                dataIndex: 'notifyWay',
                                ellipsis: true,
                                render: (text, itemsData) => {
                                    if (typeof text === "object") {
                                        return text.map((t, i) => {
                                            return <span key={i} style={{ cursor: 'pointer' }} onClick={() => item.clickIcon(t, itemsData)}>
                                                {numberToText("notifyWay", t)}
                                            </span>
                                        });
                                    } else {
                                        return numberToText("notifyWay", text);
                                    }
                                }
                            },
                        ]}
                        size="small"
                        pagination={false}
                    />
                </Descriptions.Item>
            } else {
                return <Descriptions.Item key={key} label={item.label} span={1} >{item.value}</Descriptions.Item>
            }
        })}
    </Descriptions>
}
export function numberToText(type, number) {
    let text = number
    switch (type) {
        case "status": text = (number === 0 ? <Tag color="#f50">未解决</Tag> : <Tag>已解决</Tag>); break;
        case "level": text = (number === 0 ? <div className="defaultTag"><Icon type="message" />&nbsp;信息</div> :
            (number === 1 ? <div className="warnTag"><Icon type="exclamation-circle" />&nbsp;警告</div> :
                (number === 2 ? <div className="failTag"><Icon type="frown" />&nbsp;严重</div> :
                    <div className="errorTag"><Icon type="warning" />&nbsp;灾难</div>))); break;
        case "notifyWay": text = (number === 0 ? <span>&nbsp;<Icon type="mail" />&nbsp;邮件</span> :
            (number === 1 ? <span>&nbsp;<Icon type="message" />&nbsp;短信</span> : <span>&nbsp;<Icon type="global" />&nbsp;webhook</span>)); break;
        case "language": text = (number === 0 ? "python" :
            (number === 1 ? "javsscript" : (number === 2 ? "java" : "bat"))); break;

    }
    return text;
}
export function switchState(state) {
    switch(state) {
        case 0: return <Tag color="green">成功</Tag>;
        case 1: return <Tag color="red">失败</Tag>;
        case 2: return <Tag color="blue">未发送</Tag>;
    }
}