import React, { useEffect, useState } from 'react'
// import { ScrollRankingBoard } from '@jiaminghi/data-view-react'
import Charts from "echarts-for-react"
import './DashBoard.scss'
import socket from '@/socket/index'
import Utils from "@/utils/utils";

export default () => {
  const [dashCards, setData] = useState([]);
  
  function getData() {
    return [
      {
        tooltip: {
          formatter: '{a} <br/>{b} : {c}%'
        },
        textStyle: {
          color: "#e2e2e2",
        }, 
        series: [
          {
            name: '仪表盘1',
            type: 'gauge',
            detail: {formatter: '{value}%'},
            title : {               //设置仪表盘中间显示文字样式
              textStyle: {       // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                  color:"#e2e2e2"
              }
            },
            axisLine: {            // 坐标轴线
              lineStyle: {       // 属性lineStyle控制线条样式
                  width: 10
              }
            }, 
            axisTick: {            // 坐标轴小标记
              length: 15,        // 属性length控制线长
              lineStyle: {       // 属性lineStyle控制线条样式
                  color: 'auto'
              }
            },
            splitLine: {           // 分隔线
              length: 20,         // 属性length控制线长
              lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                  color: 'auto'
              }
            },
            data: [{value: (Math.random()*100).toFixed(2) - 0, name: '仪表盘1'}],
          }
        ]
      },
      {
        tooltip: {
          formatter: '{a} <br/>{b} : {c}%'
        },
        textStyle: {
          color: "#e2e2e2",
        },  
        series: [
          {
            name: '仪表盘2',
            type: 'gauge',
            detail: {formatter: '{value}%'},
            axisLine: {            // 坐标轴线
              lineStyle: {       // 属性lineStyle控制线条样式
                  width: 10
              }
            },
            title: {
              textStyle: {
                color: "#e2e2e2"
              }
            },
            axisLine: {            // 坐标轴线
              lineStyle: {       // 属性lineStyle控制线条样式
                  width: 10
              }
            }, 
            axisTick: {            // 坐标轴小标记
              length: 15,        // 属性length控制线长
              lineStyle: {       // 属性lineStyle控制线条样式
                  color: 'auto'
              }
            },
            splitLine: {           // 分隔线
              length: 20,         // 属性length控制线长
              lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                  color: 'auto'
              }
            },
            data: [{value: (Math.random()*100).toFixed(2) - 0, name: '仪表盘2'}]
          }
        ]
      },
      {
        tooltip: {
          formatter: '{a} <br/>{b} : {c}%'
        },
        textStyle: {
          color: "#e2e2e2",
        },  
        series: [
          {
            name: '仪表盘3',
            type: 'gauge',
            detail: {formatter: '{value}%'},
            title: {
              textStyle: {
                color: "#e2e2e2"
              }
            },
            axisLine: {            // 坐标轴线
              lineStyle: {       // 属性lineStyle控制线条样式
                  width: 10
              }
            }, 
            axisTick: {            // 坐标轴小标记
              length: 15,        // 属性length控制线长
              lineStyle: {       // 属性lineStyle控制线条样式
                  color: 'auto'
              }
            },
            splitLine: {           // 分隔线
              length: 20,         // 属性length控制线长
              lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                  color: 'auto'
              }
            },
            data: [{value: (Math.random()*100).toFixed(2) - 0, name: '仪表盘3'}]
          }
        ]
      },
      {
        tooltip: {
          formatter: '{a} <br/>{b} : {c}%'
        },
        textStyle: {
          color: "#e2e2e2",
        },  
        series: [
          {
            name: '仪表盘4',
            type: 'gauge',
            detail: {formatter: '{value}%'},
            title: {
              textStyle: {
                color: "#e2e2e2"
              }
            },
            axisLine: {            // 坐标轴线
              lineStyle: {       // 属性lineStyle控制线条样式
                  width: 10
              }
            }, 
            axisTick: {            // 坐标轴小标记
              length: 15,        // 属性length控制线长
              lineStyle: {       // 属性lineStyle控制线条样式
                  color: 'auto'
              }
            },
            splitLine: {           // 分隔线
              length: 20,         // 属性length控制线长
              lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                  color: 'auto'
              }
            },
            data: [{value: (Math.random()*100).toFixed(2) - 0, name: '仪表盘4'}]
          }
        ]
      },
    ]
  }

  useEffect(() => {
    createData();

    let timer = setInterval(createData, 3000);
    // let timer = setInterval(createData, 15000);
    return () => clearInterval(timer)
  }, [])

  function createData() {
    setData(getData())
  }

  return (
    <div className="dashBoard">
      {dashCards.map((item, index) => (
        <div className="dashBoardItem" key={index}>
          <Charts className="dash-board" option={item} />
        </div>
      ))}
    </div>
  )
}
