import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Divider, Popconfirm
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import { addRule, updateRule, deleteRule, getAllRule } from './api';
import FormModal from '@/components/EditModal';
import { connect } from 'react-redux'
import BaseForm from '@/components/FormikForm/index';
import { isLoading } from '@/redux/action'
import moment from 'moment';
import {
    AddRowButton,
    Select,
    RemoveRowButton,
} from "formik-antd"
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
const FormikTable = require("formik-antd").Table;
import { Formik } from "formik"
import "./index.scss"

function LogRuleColl(props) {
    const [visible, setVisible] = useState(false);
    const [total, setTotal] = useState(10);
    const [_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const [selectItem, setSelectItem] = useState(null);
    const [selectedRowKeys, setRowKeys] = useState([])
    const [tableData, setTabData] = useState([])
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
        },
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'ip',
            ellipsis: true,
            width: 80,
            render: (text, item, c) => { return c + 1 + (_current - 1) * _pageSize }
        },
        {
            title: '日志规则名称',
            dataIndex: 'ruleName',
            ellipsis: true,
        },
        {
            title: '间隔时间',
            dataIndex: 'time',
            ellipsis: true,
        },
        {
            title: '日志规则出现次数',
            dataIndex: 'rate',
            ellipsis: true,
        },
        {
            title: '告警邮件地址',
            dataIndex: 'mailAddress',
            ellipsis: true,
        },
        {
            title: '操作',
            render: (text, item) => {
                return <div>
                    <a onClick={() => { setSelectItem(item); setVisible(true) }}><Icon type="edit" />&nbsp;编辑</a>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData(item.id) }}
                        okText="是"
                        cancelText="否"
                    >
                        <a><Icon type="delete" />&nbsp;删除</a>
                    </Popconfirm>
                </div>
            }
        },
    ]
    useEffect(() => {//componentDidMount;
        http.setDispatch(props.dispatch)
        getData();
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(page = 1, size = 20, query = {}) {
        http.get(getAllRule(), { from: page, size, ...query }, (r) => {
            setTabData(r.data.content || []);
            setTotal(r.data.total) || 10;
            set_querys(query);
            set_current(page);
        })
    }
    function saveDataSource(val) {
        const { id, mailAddress } = selectItem;
        console.log(val);
        if (mailAddress) {//修改
            val.id = id;
            http.post(updateRule(), val, () => {
                getData(1, _pageSize, {});
                message.success("修改成功");
            })
        } else {//新增
            http.post(addRule(), [val], () => {
                getData(1, _pageSize, {});
                message.success("新增成功");
            })
        }
        setVisible(false)
    }
    function deleteData(id) {
        http.get(deleteRule() + "/" + id, {}, () => {
            getData(1, _pageSize, {});
            message.success("删除成功");
        })
    }
    return <div className="monitorDataSource">
        <CustomBreadcrumb arr={["日志管理", "日志规则"]} />
        <Card title="规则列表" extra={<div>
            <Button type="primary" icon="file-add" onClick={() => {
                setSelectItem({ type: 1 })
                setVisible(true)
            }}>添加</Button>&nbsp;

            {/* <Popconfirm
                title="你确定删除所选记录吗?"
                onConfirm={() => { }}
                okText="是"
                cancelText="否"
            ><Button type="danger" icon="delete" onClick={() => deleteData(selectedRowKeys)}>删除</Button>
            </Popconfirm> */}
        </div>}>
            {/* <BaseForm
                onSubmit={(val) => {
                    const rangeTime = val.rangeTime;
                    let filter = {
                        name: val.name,
                        startTime: rangeTime && moment(rangeTime[0]).format("YYYY-MM-DD HH:mm:ss"),
                        endTime: rangeTime && rangeTime[1] && moment(rangeTime[1]).format("YYYY-MM-DD HH:mm:ss")
                    }
                    getData(1, _pageSize, filter)
                }}
                onCancel={() => { getData(1, _pageSize, {}) }}
                initialValues={{}}
                formList={[
                    { type: "input", name: "name", label: "数据源名称", placeholder: "数据源名称" },
                    { type: "rangePicker", name: "rangeTime", label: "起止时间" },
                ]}
            /> */}
            <Table
                dataSource={tableData}
                // rowSelection={rowSelection}
                columns={columns}
                size="small"
                rowKey={r => r.dataSourceId}
                pagination={
                    {
                        current: _current, pageSize: _pageSize, total,
                        onChange: (page, pageSize) => { getData(page, pageSize, _querys); }
                    }
                }
            />
        </Card>
        <FormModal
            title="编辑日志规则配置"
            visible={visible}
            onCancel={() => setVisible(false)}
            onSubmit={(val) => { saveDataSource(val); }}
            className="logRuleConfigDiv"
            footer={null}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            initialValues={selectItem}
            width="70%"
            style={{ top: 20 }}
            destroyOnClose
            formList={[
                { type: "input", name: "ruleName", label: "日志规则名称", required: true, placeholder: "日志规则名称", width: 300 },
                { type: "input", name: "mailAddress", label: "告警邮件地址", required: true, placeholder: "告警邮件地址", width: 300 },
                { type: "inputNumber", name: "rate", label: "日志规则出现次数", required: true, placeholder: "日志规则出现次数", width: 300 },
                { type: "inputNumber", name: "time", label: "日志规则间隔时间", required: true, width: 300 },
                {
                    type: "expandForm", name: "ruleProp", label: "参数列表",
                    labelCol: { span: 24 },
                    wrapperCol: { span: 24 },
                    formContent: <div>
                        <FormikTable
                            name="ruleProp"
                            rowKey={(row, index) => (index)}
                            size="small"
                            scroll={{ y: 400 }}
                            pagination={false}
                            columns={[
                                {
                                    width: 50,
                                    title: "序号",
                                    align: "center",
                                    key: "序号",
                                    render: (text, record, i) => (i + 1),
                                },
                                {
                                    // width: 100,
                                    title: "key",
                                    align: "center",
                                    key: "key",
                                    render: (text, record, i) => (<FormikInput style={{ border: "none" }} name={`ruleProp.${i}.key`} />),
                                },
                                {
                                    title: "value",
                                    align: "center",
                                    key: "value",
                                    render: (text, record, i) => (<FormikInput style={{ border: "none" }} name={`ruleProp.${i}.value`} />),
                                },
                                {
                                    title: "参数类型",
                                    key: "type",
                                    render: (text, record, i) => (<Select style={{ border: "none" }} name={`ruleProp.${i}.type`} >
                                        <Select.Option value="josn">json</Select.Option>
                                        <Select.Option value="text">text</Select.Option>
                                    </Select>),
                                },
                                {
                                    title: "key-value比较方式",
                                    key: "eqType",
                                    render: (text, record, i) => (<Select style={{ border: "none" }} name={`ruleProp.${i}.eqType`} >
                                        <Select.Option value="eq">eq</Select.Option>
                                        <Select.Option value="like">like</Select.Option>
                                    </Select>),
                                },
                                {
                                    title: "描述",
                                    align: "center",
                                    key: "desc",
                                    render: (text, record, i) => (<FormikInput style={{ border: "none" }} name={`ruleProp.${i}.desc`} />),
                                },
                                {
                                    width: 32,
                                    key: "操作",
                                    align: "center",
                                    render: (text, record, index) => (<RemoveRowButton style={{ border: "none" }} icon="delete" name="ruleProp" index={index} />),
                                },
                            ]}
                        />
                        <AddRowButton
                            name="ruleProp"
                            style={{ width: "100%" }}
                            createNewRow={() => ({
                                key: "key" + new Date().getTime(),
                                value: "value",
                                type: "json",
                                eqType: "eq",
                                desc: "desc"
                            })}
                        > +添加参数
                  </AddRowButton>
                    </div>
                },
            ]}


        />
    </div>
}

export default connect()(LogRuleColl)