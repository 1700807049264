import React, { useEffect, useState } from 'react'
import { Spin, Row, Col } from "antd";
import Socket from '@/socket/index'
import Utils from '@/utils/utils'
import http from '@/axios/axios_utils';
import PieChar from '@/components/PieChar/index2';
import ColumnChart from '@/components/ColumnChart/index';
import ColumnChart2 from '@/components/ColumnChart/index2';
import LineChar from '@/components/LineChar/echart';
import Flower from '@/components/PieChar/index3';
import Rings from '@/components/PieChar/index4';
import { alarmTrend, alarmSortRank, alarmRecordTotal } from "@/pages/monitorAlert/utils/api";
const tempAddress = sys_config.dataScrape || "localhost:38209";
const Version = "/dataScrape/v1/"
const MONITOR_URL = "http://" + tempAddress + Version
function DataMap(props) {
    const [data1, setData1] = useState([]);
    const [level_data, setflag_toal] = useState({});
    useEffect(() => {//componentDidMount
        createData()
        let _timer = setInterval(createData, props.timer || 60000);
        return () => clearInterval(_timer)
    }, []);
    async function createData() {
        let httData = {}
        let templist = [];
        if (props.key1 === "cpu_top10") {
            httData = await http.get(MONITOR_URL + "cpu_usage_rate_top10", { user_id: sessionStorage.getItem("userId") },)
        } else if (props.key1 === "mem_top10") {
            httData = await http.get(MONITOR_URL + "mem_usage_rate_top10", { user_id: sessionStorage.getItem("userId") },)
        } else if (props.key1 === "temperature_top10") {
            httData = await http.get(MONITOR_URL + "temperature_top10", { user_id: sessionStorage.getItem("userId") },)
        } else if (props.key1 === "alert_time") {
            const temp_d = await http.get(alarmTrend(), { timeRange: 2 })
            httData = temp_d.data.records || []
            templist = httData.map((i) => {
                return { name: i.time, value: i.total }
            })
        } else if (props.key1 === "alert_level") {
            const endTime = Utils.formateDate(new Date().getTime())
            const data0 = await http.get(alarmRecordTotal(), { level: 0, endTime, userId: sessionStorage.getItem("userId") });
            const data1 = await http.get(alarmRecordTotal(), { level: 1, endTime, userId: sessionStorage.getItem("userId") });
            const data2 = await http.get(alarmRecordTotal(), { level: 2, endTime, userId: sessionStorage.getItem("userId") });
            const data3 = await http.get(alarmRecordTotal(), { level: 3, endTime, userId: sessionStorage.getItem("userId") });

            templist.push({ name: "信息", value: data0.data.total });
            templist.push({ name: "警告", value: data1.data.total });
            templist.push({ name: "严重", value: data2.data.total });
            templist.push({ name: "灾难", value: data3.data.total });

            let a_total = data0.data.total + data1.data.total + data2.data.total + data3.data.total
            let flag_toal = {
                info: ((data0.data.total / a_total).toFixed(4) * 100).toFixed(2),
                jg: ((data1.data.total / a_total).toFixed(4) * 100).toFixed(2),
                yz: ((data2.data.total / a_total).toFixed(4) * 100).toFixed(2),
                zn: ((data3.data.total / a_total).toFixed(4) * 100).toFixed(2),
            }
            // let flag_toal = {
            //     info: parseInt((data0.data.total / a_total) * 100),
            //     jg: parseInt((data1.data.total / a_total) * 100),
            //     yz: parseInt((data2.data.total / a_total) * 100),
            //     zn: parseInt((data3.data.total / a_total) * 100),
            // }
            console.log("9999999999999999999999999999999")
            console.log(data0)
            console.log(data1)
            console.log(data2)
            console.log(data3)
            console.log(templist)
            setflag_toal(flag_toal)

        }
        // let top10Data = 
        // let alarmData = await http.get(alarmTrend(), { timeRange: 2 })
        // // let typeData = await http.get(alarmSortRank(), { timeRange: 2 })

        // const alarm_data = alarmData.data.records.map((i) => {
        //   return { x: i.time, y: i.total }
        // })

        // const endTime = Utils.formateDate(new Date().getTime())
        // const data0 = await http.get(alarmRecordTotal(), { level: 0, endTime });
        // const data1 = await http.get(alarmRecordTotal(), { level: 1, endTime });
        // const data2 = await http.get(alarmRecordTotal(), { level: 2, endTime });
        // const data3 = await http.get(alarmRecordTotal(), { level: 3, endTime });
        // let templist = [];
        // templist.push({ name: "信息", value: data0.data.total });
        // templist.push({ name: "警告", value: data1.data.total });
        // templist.push({ name: "严重", value: data2.data.total });
        // templist.push({ name: "灾难", value: data3.data.total });


        let type_data = (httData.data || []).map((i) => {
            return { ...i, name: i.ip }
        })
        if (props.key1 === "alert_time" || props.key1 === "alert_level") {
            type_data = templist
        }
        setData1(type_data)
    }
    function getChar() {
        if (props.type === "pie") {
            return <PieChar data={data1} unit={props.unit} />
        } else if (props.type === "line") {
            return <LineChar data={data1} unit={props.unit} />
        } else if (props.type === "flower") {
            return <Flower data={data1} unit={props.unit} />
        } else if (props.type === "rings") {
            return <Rings data={level_data} unit={props.unit} />
        } else if (props.type === "column2") {
            return <ColumnChart2 data={data1} unit={props.unit} />
        } else {
            return <ColumnChart data={data1} unit={props.unit} />
        }
    }
    return <div className="card-item animated zoomIn" >
        <div className="card-header">
            <div>
                < img src="/image/arrow.svg" alt="" />
                <span style={{ marginLeft: 10, color: '#61ddff' }}>{props.description}</span>
            </div>
        </div>
        {getChar()}
    </div>
}
export default (React.memo(DataMap))
