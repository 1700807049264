import React, { useState, useEffect, memo } from 'react';
import { Line } from '@ant-design/plots';

const DemoLine = (props) => {
    console.log(props)
    const [data, setData] = useState(props.data || []);

    useEffect(() => {

    }, []);
    const config = {
        data,
        padding: 'auto',
        xField: 'x',
        yField: 'y',
        xAxis: {
            label: {
                autoHide: false,
                autoRotate: true,
                // 配置样式
                style: {
                    fill: '#FFF',
                    cursor: 'pointer'
                },
            }
        },
        yAxis: {
            label: {
                // 配置样式
                style: {
                    fill: '#FFF',
                },
            }
        },
        axis: {
            title: {
                text: props.title || "折线图",
            }
        },
        // annotations: [
        //     // 低于标线颜色变化
        //     {
        //         type: 'regionFilter',
        //         start: ['min', 'median'],
        //         end: ['max', '0'],
        //         color: '#F4664A',
        //     },
        //     {
        //         type: 'text',
        //         position: ['min', 'median'],
        //         content: '阈值',
        //         offsetY: -4,
        //         style: {
        //             textBaseline: 'bottom',
        //         },
        //     },
        //     {
        //         type: 'line',
        //         start: ['min', 'median'],
        //         end: ['max', 'median'],
        //         style: {
        //             stroke: '#F4664A',
        //             lineDash: [2, 2],
        //         },
        //     },
        // ],
    };

    return <Line {...config} />;
};

export default memo(DemoLine)




