import React, { memo } from 'react';
import { Card, Button, Modal, List, Tag, Input, Icon, message, Table, Tooltip, Row, Col, } from 'antd';
import AxiosObj from '@/axios/index';
import axios from '@/axios/axios_utils';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import AddTable from '@/components/AddTable/index'
import "./index.scss";
import Utils from "@/utils/utils";
import { SubmitButton, FormItem, InputNumber, Select, TimePicker } from "formik-antd";
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik";
import moment from "moment";
const hostpingUrl = "http://" + hostping_address + "/v1/hostping/";
export default class HostPING extends React.Component {
    state = {
        tags: [],
        inputVisible: false,
        conVis: false,
        editHostPingVis: false,
        historyVis: false,
        inputValue: '',
        configData: {},
        current: 1,
        pageSize: 20,
        tableData: [],
        loading: false,
        total: 0,
        selectedRowKeys: [],
    };
    componentWillMount() {
    }
    showError(error) {
        Utils.showMessage("error", "操作失败", error);
    }
    openMonitorConfig() {
        const temp_url = hostping_address + "/v1/hostping/device"
        AxiosObj.MA_HTTP_GET(temp_url, (result) => {
            // console.log(result.ips)
            this.setState({ conVis: true, tags: result.ips.map((ip, key) => { ip.key = key; return ip }) })
        }, (err) => { this.showError(err) });
    }
    onSumit() {
        AxiosObj.HTTP_POST("hostping", "device", { ips: this.state.tags }, (result) => {
            // console.log(result);
            // document.getElementById('hostping').contentWindow.location.reload(true);
            this.setState({ conVis: false });
            message.success("修改成功");
        }, (err) => { this.showError(err) });
    }
    editHostPing(value) {
        console.log(value)
        value.begin_time = moment(value.begin_time).format("HH:mm:ss")
        value.end_time = moment(value.end_time).format("HH:mm:ss")
        console.log(value)
        axios.post(hostpingUrl + "config", value, (res) => {
            if (res.recode === "00") {
                message.success("修改成功");
                this.setState({
                    editHostPingVis: false,
                })
            } else {
                message.error("修改失败");
            }
        })
    }
    getHostConfig() {
        axios.get(hostpingUrl + "config", {}, (res) => {
            if (res.recode === "00") {
                this.setState({
                    configData: res.config,
                    editHostPingVis: true
                })
            } else {
                message.error("查询失败");
                this.setState({ editHostPingVis: true })
            }
        })
    }
    getHistoryList(page, page_size) {
        this.setState({ loading: true, historyVis: true })
        let { current, pageSize } = this.state;
        let bodyJson = {
            page_no: page || current,
            page_size: page_size || pageSize
        }
        axios.get(hostpingUrl + "record", bodyJson, (res) => {
            if (res.code === "00") {
                this.setState({
                    tableData: res.data,
                    total: res.count,
                    current: page || 1,
                    loading: false
                })
            } else {
                message.error("查询失败");
                this.setState({ loading: false })
            }
        })
    }
    deleteHistory(ids) {
        let _this = this;
        if (ids.length === 0) {
            message.error("请选择需要删除的记录！")
        } else {
            Modal.confirm({
                title: '消息提示',
                content: '你确定删除该设备吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    axios.delete_body(hostpingUrl + "record", { _ids: ids }, (res) => {
                        console.log(res)
                        if (res.code === "00") {
                            message.success(res.msg);
                            _this.setState({ selectedRowKeys: [] });
                            _this.getHistoryList();
                        } else {
                            message.error("删除失败")
                        }
                    })
                },
            });
        }
    }
    handleClose = removedTag => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        console.log(tags);
        this.setState({ tags });
    };

    showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());

    };
    handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
    };
    handleInputConfirm = () => {
        const { inputValue } = this.state;
        let { tags } = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        console.log(tags);
        this.setState({
            tags,
            inputVisible: false,
            inputValue: '',
        });
    };
    saveInputRef = input => (this.input = input);
    render() {
        const { tags, inputVisible, inputValue, conVis, editHostPingVis, historyVis, tableData, loading, current, pageSize, total, configData, selectedRowKeys } = this.state;

        const columns = [
            {
                title: '序号',
                width: 60,
                align: 'left',
                dataIndex: 'key',
                render: (text, item, index) => {
                    return <span>{index + 1 + (current - 1) * pageSize}</span>
                }
            },
            {
                title: 'IP地址/丢包率',
                align: 'left',
                dataIndex: 'ip_list',
                // width: 240,
                ellipsis: true,
                render: (text, item, index) => {
                    // return <Table
                    //     dataSource={text || []}
                    //     columns={[{ title: "IP地址", dataIndex: "ip" }, { title: "丢包率", dataIndex: "packet_loss" }]}
                    //     size="small"
                    //     showHeader={false}
                    //     pagination={false}
                    // />
                    return <div className="ipLossDiv">{text.map((one) => {
                        return <div>{one.ip}/{one.packet_loss * 100}%</div>
                    })}</div>
                }
            },
            {
                title: '联系人列表',
                align: 'left',
                dataIndex: 'phones',
                // width: 240,
                ellipsis: true,
                render: (text, item, index) => {
                    return text.join("，")
                }
            },
            {
                title: '每次发送包数量',
                align: 'center',
                dataIndex: 'ping_count',
                width: 120,
                ellipsis: true,
            },
            {
                title: '丢包率发送阈值',
                align: 'center',
                dataIndex: 'loss_rate',
                width: 120,
                ellipsis: true,
                render: (text, item, index) => {
                    return text * 100 + "%"
                }
            },
            {
                title: '发送状态',
                align: 'center',
                dataIndex: 'sms_success',
                width: 100,
                ellipsis: true,
                render: (text, item, index) => {
                    switch (text) {
                        case false: return <Tooltip title={item.tips_success}><Tag color="red">失败</Tag></Tooltip>;
                        case true: return <Tooltip title={item.tips_success}><Tag color="green">成功</Tag></Tooltip>;
                    }
                }
            },
            {
                title: '时间',
                align: 'center',
                dataIndex: 'time',
                width: 220,
                ellipsis: true,
            },
            {
                title: '操作',
                align: 'center',
                dataIndex: 'op',
                width: 100,
                render: (text, item, index) => {
                    return <Button type="danger" size="small" icon="delete" onClick={() => this.deleteHistory([item._id])}>删除</Button>
                }
            },
        ]
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };

        return <div className="hostPingDiv">
            <CustomBreadcrumb arr={["监控告警", "主机网络检测"]} />
            <Card title="主机网络检测" extra={<div>
                {/* <Button type="primary" icon="edit" onClick={() => { this.getHostConfig(); }}>
                    修改hostping配置</Button>&nbsp;
                <Button type="primary" icon="unordered-list" onClick={() => { this.getHistoryList(); }}>
                    查看历史记录</Button>&nbsp; */}
                <Button type="primary" icon="plus-circle" onClick={() => { this.openMonitorConfig() }}>
                    监控设备IP</Button>&nbsp;
            </div>}>
                <iframe
                    id="hostping"
                    src={hostping_url}
                />
            </Card>
            <Modal
                title="监控设备IP"
                visible={conVis}
                onCancel={() => { this.setState({ conVis: false }) }}
                onOk={() => { this.onSumit() }}
                width="70%"
                destroyOnClose
            >
                <div className="hostpingModal">
                    {/* <List
                        grid={{ column: 5 }}
                        dataSource={tags}
                        renderItem={item => (
                            <List.Item>
                                <span>{item}</span>
                                <Icon type="close" onClick={() => { this.handleClose(item) }} />
                            </List.Item>
                        )}
                    /><p />
                    {inputVisible && (
                        <Input
                            ref={this.saveInputRef}
                            type="text"
                            size="small"
                            style={{ width: 150 }}
                            value={inputValue}
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputConfirm}
                            onPressEnter={this.handleInputConfirm}
                        />
                    )}
                    {!inputVisible && (
                        <Tag onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
                            <Icon type="plus" /> 添加设备IP
                        </Tag>
                    )} */}
                    <AddTable
                        columns={[
                            { title: "主机IP地址", dataIndex: "ip", type: "INPUT", },
                            { title: "备注", dataIndex: "name", type: "INPUT", },
                            { title: "丢包发送短信", dataIndex: "isSim", type: "SWITCH" },
                        ]}
                        value={tags}
                        onChange={(a) => { this.setState({ tags: a }) }}
                    />
                </div>
            </Modal>
            <Modal
                title="编辑hostping配置"
                visible={editHostPingVis}
                onCancel={() => { this.setState({ editHostPingVis: false }) }}
                onOk={() => { this.setState({ editHostPingVis: false }) }}
                width="60%"
                destroyOnClose
                footer={null}
            >
                <Formik
                    onSubmit={(value, actions) => { this.editHostPing(value); actions.setSubmitting(false) }}
                    validate={(values) => {
                        let info = {};
                        values.phones.map((phoneItem) => {
                            if (phoneItem.indexOf("+86") === -1) {
                                info.phones = phoneItem + " 格式不正确，请在号码前加上'+86'"
                            }
                        })
                        return info
                    }}
                    initialValues={{
                        ping_count: configData.ping_count || 0,
                        ping_timeout: configData.ping_timeout || 0,
                        loss_rate: configData.loss_rate || 0,
                        phones: configData.phones || [],
                        begin_time: moment(configData.begin_time || "18:00:00", 'HH:mm:ss'),
                        end_time: moment(configData.end_time || "08:30:00", 'HH:mm:ss'),
                    }}
                >
                    {(props) => {
                        // const { getFieldProps } = props
                        // console.log(getFieldProps("begin_time"))
                        // console.log(props)
                        return <FormikForm labelCol={{ span: 4 }} wrapperCol={{ span: 19 }} >
                            <FormItem
                                name={"ping_count"}
                                label={"每次发送包数量(个)"}
                                key={"ping_count"}
                            >
                                <InputNumber style={{ width: "100%" }} min={0} name={"ping_count"} placeholder={"请输入每次发送包数量"} />
                            </FormItem>
                            <FormItem
                                name={"ping_timeout"}
                                label={"响应超时(秒/S)"}
                                key={"ping_timeout"}
                            >
                                <InputNumber style={{ width: "100%" }} min={0} name={"ping_timeout"} placeholder={"请输入响应超时"} />
                            </FormItem>
                            <FormItem
                                name={"loss_rate"}
                                label={"丢包率发送阈值(0~1)"}
                                key={"loss_rate"}
                            >
                                <InputNumber style={{ width: "100%" }} min={0} max={1} step={0.1} name={"loss_rate"} placeholder={"请输入丢包率"} />
                            </FormItem>
                            <FormItem
                                name={"phones"}
                                label={"联系人(+86中国)"}
                                key={"phones"}
                            >
                                <Select mode="tags" open={false} tokenSeparators={[',', '，', ' ']} name={"phones"} placeholder={"请输入联系人"}>
                                </Select>
                            </FormItem>
                            {/* <FormItem
                                name={"BACKUP_ADDRESS"}
                                label={"backup地址端口"}
                                key={"BACKUP_ADDRESS"}
                            >
                                <FormikInput name={"BACKUP_ADDRESS"} placeholder={"请输入BACKUP_ADDRESS"} />
                            </FormItem>
                            <FormItem
                                name={"SMS_ADDRESS"}
                                label={"短信发送端地址端口"}
                                key={"SMS_ADDRESS"}
                            >
                                <FormikInput name={"SMS_ADDRESS"} placeholder={"请输入短信发送端地址端口"} />
                            </FormItem> */}
                            <Row>
                                <Col span={6}>
                                    <FormItem
                                        name={"begin_time"}
                                        label={"勿扰时间"}
                                        key={"begin_time"}
                                        labelCol={{ span: 16 }}
                                        wrapperCol={{ span: 3 }}
                                    >
                                        <TimePicker name={"begin_time"} />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem
                                        name={"end_time"}
                                        label={"~"}
                                        key={"end_time"}
                                        colon={false}
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <TimePicker name={"end_time"} />
                                    </FormItem>
                                </Col>
                            </Row>
                            <div style={{ textAlign: "right" }}>
                                <Button onClick={() => { this.setState({ editHostPingVis: false }) }}>取消</Button>
                                <SubmitButton style={{ marginLeft: "10px" }} disabled={false}>保存</SubmitButton>&nbsp;
                        </div>
                        </FormikForm>
                    }}
                </Formik>
            </Modal>
            <Modal
                title="查看历史记录"
                visible={historyVis}
                onCancel={() => { this.setState({ historyVis: false }) }}
                onOk={() => { this.setState({ historyVis: false }) }}
                width="70%"
                destroyOnClose
            >
                <div className="hostpingHistory">
                    <Row style={{ marginTop: -14 }}>
                        <Button type="danger" style={{ marginBottom: 10 }} icon="delete" onClick={() => this.deleteHistory(selectedRowKeys)}>批量删除</Button>
                    </Row>
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        loading={loading}
                        size="small"
                        rowKey={(text) => { return text._id }}
                        // expandedRowRender={record => 
                        //     <Table
                        //         dataSource={record.ip_list || []}
                        //         columns={[{ title: "IP地址", dataIndex: "ip" }, { title: "丢包率", dataIndex: "packet_loss" }]}
                        //         size="small"
                        //         pagination={false}
                        //     />
                        // }
                        rowSelection={rowSelection}
                        pagination={{
                            current: current, pageSize: pageSize, total: total,
                            onChange: (page, pageSize) => {
                                // console.log(page, pageSize);
                                this.getHistoryList(page, pageSize)
                            }
                        }}
                    />
                </div>
            </Modal>
        </div>
    }
}