import React from 'react'
import { Form, Input, Button, notification, Icon, Checkbox, Carousel } from 'antd'
import Footer from '../../components/Footer'
import TweenOne from 'rc-tween-one';
import Texty from 'rc-texty';
import './index.scss'
import Socket from '../../socket/index'
import Utils from '../../utils/utils'
import AxiosObj from '../../axios/index'
import $ from "jquery"
import "animate.css"
// import history from '../../history'
const FormItem = Form.Item;



export default class Login extends React.Component {
    state = {
        isSubmit: false,
    };
    componentWillMount() {
        this.versionName = sys_config.versionName;
    }
    loginReq = (params) => {
        // console.log(this.state.isRember);
        let data = {
            'userName': params.username,
            'userPwd': Utils.StrToBase64(params.password),
        };
        let thisTemp = this;
        let thisTemp2 = params._this;
        thisTemp2.setState({ isSubmit: true });
        Socket.Login('Login', data, (result) => {
            if (result.retcode === '00') {
                sessionStorage.setItem('userName', params.username);
                sessionStorage.setItem('nickName', result.userName);
                sessionStorage.setItem('userId', result.userId);
                sessionStorage.setItem('token', result.token);
                sessionStorage.setItem('menuList', JSON.stringify(result.menuList));
                // sessionStorage.setItem('menuList', JSON.stringify(menuTemp));
                sessionStorage.setItem('FixedBtn', JSON.stringify(Utils.getChildrenDisplayKey(result.menuList)));
                Socket.SetData(result.userId, result.token, params.username, result.userName);
                AxiosObj.setSysName(params.username, result.userName);
                thisTemp.props.history.push('/home');

            } else {
                notification.open({
                    message: '登录失败',
                    description: result.error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                thisTemp2.setState({ isSubmit: false });
            }
        });
    };

    geInterval = (e) => {
        switch (e.index) {
            case 0:
                return 0;
            case 1:
                return 150;
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                return 150 + 450 + (e.index - 2) * 10;
            default:
                return 150 + 450 + (e.index - 6) * 150;
        }
    };
    getEnter = (e) => {
        const t = {
            opacity: 0,
            scale: 0.8,
            y: '-100%',
        };
        if (e.index >= 2 && e.index <= 6) {
            return { ...t, y: '-30%', duration: 150 };
        }
        return t;
    }
    getHeardTitle() {
        let title = <div className="logo animated flipInX">
            <img src="/image/logo.png" className="imgStyle" alt="ZJNMS自动化运维平台" />
                ZJNMS自动化运维平台
            </div>
        if (this.versionName === "pingan") {
            title = <div style={{ marginLeft: 20 }}><img src="/image/pingan-logo.jpg" /></div>
        }
        return title
    }
    getCarousel() {
        return <Carousel autoplay
            dotPosition="right"
            effect="fade"
            className={"CarouselDiv"}
        >
            <img className={"CarouselImg"} src="/image/backgroud.png" />
            <img className={"CarouselImg"} src="/image/bg01.jpg" />
            <img className={"CarouselImg"} src="/image/banner3.png" />
        </Carousel>
    }
    getVersionLogo() {
        if (this.versionName === "pingan") {
            return <div className="login-img">
                <img className="login" src="/image/payh_logo.png" />
            </div>
        } else {
            // return <div className="login-img2">
            //     <img className="login" src="/image/zjzd-logo.gif" />
            // </div>
            return ""
        }
    }
    render() {
        return (
            <div>

                <div className="login-page">
                    {this.getCarousel()}
                    <div className="login-header" id="login-title">
                        {this.getHeardTitle()}
                    </div>
                    <Footer />
                </div>
                <div className="login-content-wrap">
                    <div className="login-content">
                        {this.getVersionLogo()}
                        <div className="login-box animated fadeIn">
                            <div className="title" >
                                <Texty
                                    className={"title"}
                                    type="mask-top"
                                    delay={400}
                                    enter={this.getEnter}
                                    interval={this.geInterval}
                                    component={TweenOne}
                                    componentProps={{
                                        animation: [
                                            { x: 130, type: 'set' },
                                            { x: 100, delay: 500, duration: 450 },
                                            {
                                                ease: 'easeOutQuart',
                                                duration: 300,
                                                x: 0,
                                            },
                                            {
                                                letterSpacing: 0,
                                                delay: -300,
                                                scale: 0.9,
                                                ease: 'easeInOutQuint',
                                                duration: 1000,
                                            },
                                            { scale: 1, width: '100%', delay: -300, duration: 1000, ease: 'easeInOutQuint' },
                                        ],
                                    }}
                                >{Utils.getSystemTiltle(this.versionName)}</Texty>
                                <TweenOne
                                    className="combined-bar2"
                                    animation={{ delay: 2000, width: 0, x: 158, type: 'from', ease: 'easeInOutExpo' }}
                                    style={styles.lineColor}
                                />
                            </div>
                            <LoginForm ref="login" loginSubmit={this.loginReq} />
                        </div>
                    </div >
                </div >

            </div >

        )
    }
}

class LoginForm extends React.Component {
    state = {
        focusItem: -1,
        isRember: false,
        username: "",
        password: "",
    };
    componentWillMount() {
        this.versionName = sys_config.versionName;
    }
    componentDidMount() {
        this.loadAccountInfo();
    }

    //判断cookie中是否有账号信息，有就可以进行预填写，没有则直接返回
    loadAccountInfo = () => {
        //读取cookie
        let arr, reg = new RegExp("(^| )" + 'accountInfo' + "=([^;]*)(;|$)");
        let accountInfo = ''
        if (arr = document.cookie.match(reg)) {
            accountInfo = unescape(arr[2]);
        } else {
            accountInfo = null;
        }
        if (!accountInfo) {
            this.setState({ isRember: false });
            return false;
        } else {
            let userName = "";
            let passWord = "";
            let typeLogin = "";

            let i = new Array()
            i = accountInfo.split("&");
            userName = i[0],
                passWord = i[1],
                typeLogin = i[2]
            // console.log(userName, passWord);
            this.setState({
                username: userName,
                password: passWord,
                isRember: true
            });

        }
    }
    loginSubmit = (e) => {
        e && e.preventDefault();
        const _this = this;
        this.props.form.validateFieldsAndScroll((err) => {
            if (!err) {
                var formValue = _this.props.form.getFieldsValue();
                _this.props.loginSubmit({
                    username: formValue.username,
                    password: formValue.password,
                    _this: _this,
                });
            }
        });
    };

    checkUsername = (rule, value, callback) => {
        var reg = /^\w+$/;
        if (!value) {
            // console.log($(".login-form"));
            $(".login-form").addClass("animated shake")
            callback('请输入用户名!');
        } else if (!reg.test(value)) {
            callback('用户名只允许输入英文字母');
        } else {
            callback();
        }
    };

    checkPassword = (rule, value, callback) => {
        if (!value) {
            callback('请输入密码!');
        } else {
            callback();
        }
    };
    remberPassword(e) {

        if (e.target.checked) {   //是否保存密码
            let username = this.props.form.getFieldValue("username") || "";
            let password = this.props.form.getFieldValue("password") || "";
            let accountInfo = username + '&' + password;

            let Days = 3;  //cookie保存时间
            let exp = new Date();
            exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
            document.cookie = 'accountInfo' + "=" + escape(accountInfo) + ";expires=" + exp.toGMTString();
            // console.log(accountInfo);
        } else {
            let exp = new Date();
            exp.setTime(exp.getTime() - 1);
            let accountInfo = document.cookie
            var cookie_pos = accountInfo.indexOf('accountInfo');
            if (cookie_pos != -1) {
                document.cookie = 'accountInfo' + "=" + ' ' + ";expires=" + exp.toGMTString();
            }
            this.props.form.resetFields();
        }
        this.setState({ isRember: e.target.checked })
    }

    render() {
        const { isSubmit, isRember, username, password } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Form className="login-form">
                <FormItem>
                    {getFieldDecorator('username', {
                        initialValue: username || "",
                        rules: [{ validator: this.checkUsername }]
                    })(
                        <Input prefix={<Icon type="user" />}
                            placeholder="用户名"
                            onPressEnter={this.loginSubmit}
                            className="animated flipInX"
                        />
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('password', {
                        initialValue: password || "",
                        rules: [{ validator: this.checkPassword }]
                    })(
                        <Input prefix={<Icon type="lock" />} type="password" placeholder="密码"
                            onPressEnter={this.loginSubmit}
                            className="animated flipInX"
                        />
                    )}
                </FormItem>
                <FormItem>
                    <Checkbox className="animated flipInX" checked={isRember}
                        onChange={(e) => { this.remberPassword(e) }}>记住密码</Checkbox>
                    <a style={{ float: 'right' }} onClick={() => { this.remberPassword({ target: { checked: false } }) }}>忘记密码</a>
                </FormItem>
                <FormItem style={{ width: 300 }}>
                    <Button style={styles.SumitBtn} type="primary" onClick={this.loginSubmit} className="login-form-button animated flipInX">
                        {isSubmit ? <div><Icon type="reload" spin />请求登录中. . .</div> : '登录'}
                    </Button>
                </FormItem>
            </Form>
        )
    }
}
const styles = {
    SumitBtn: { width: 300 },
    lineColor: { background: "linear-gradient(to right,#04befe,#4481eb)", }
}
LoginForm = Form.create({})(LoginForm);
