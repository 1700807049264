import React from 'react'
import './index.scss'
const versionName = sys_config.versionName;
export default class Footer extends React.Component {
    state = {
        title: "版权所有：©深圳市紫金支点技术股份有限公司"
    }
    componentWillMount() {
        if (versionName === "pingan" || versionName === "pinganNanJing" || versionName === "pinganTerminal") {
            this.setState({ title: "版权所有：©中国平安 金融·科技" });
        }
    }
    render() {
        return (
            <div className="footer">
                { this.state.title}
                { versionName === "pingan" || versionName === "pinganNanJing" || versionName === "pinganTerminal" ? "" :
                    <p className="about"><a href="http://www.zjft.com" target="_blank">关于紫金</a></p>}
            </div>
        );
    }
}