import React from 'react'
import './index.scss'
import { Card, Table, Input, Typography, Popconfirm, Button, Icon, Tabs, Row, Col, message, Tag, Modal, Progress, List, } from "antd";
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import $ from "jquery";
const { Title } = Typography;

export default class findDevice extends React.Component {
    state = {
        deviceData: [],
        loading: false,
        _pageSize: 10,
        total: 0,
        _current: 1,
        taskId: this.props.taskId
    };
    componentWillMount() {
        let { _pageSize, _current } = this.state;
        this.QueryNetFindPage(_pageSize, _current);
    }
    QueryNetFindPage = (pageSize, pageNum) => {
        let { _pageSize, _current, taskId } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.taskId = taskId;
        Socket.QueryDataByPage('QueryNetFindPage',
            bodyJson, (result) => {
                if (result.retcode === '00') {
                    let deviceData = result.data;
                    let total = result.total;
                    deviceData.map((item, index) => {
                        item.key = index;
                    });
                    this.setState({
                        deviceData, total,
                        loading: false,
                        _current: pageNum,
                        _pageSize: pageSize,
                    })
                }
            });
    };

    render() {
        let { deviceData, _pageSize, total, _current, } = this.state;
        const columnsDev = [
            {
                title: 'IP',
                align: 'center',
                dataIndex: 'deviceIp',
            }, {
                title: 'MAC',
                align: 'center',
                dataIndex: 'mac',
            }, {
                title: '时间',
                align: 'center',
                dataIndex: 'cycleBeginTime',
            }, {
                title: '类型',
                align: 'center',
                dataIndex: 'deviceType',
            }, {
                title: '厂商',
                align: 'center',
                dataIndex: 'deviceFirm',
            }, {
                title: '型号',
                align: 'center',
                dataIndex: 'deviceVersion'
            }, {
                title: 'OS版本',
                align: 'center',
                dataIndex: 'deviceOsVersion'
            }, {
                title: '网点信息',
                align: 'center',
                dataIndex: 'deviceSite'
            }
        ];
        return (
            <div className="home-body">
                <Table
                    columns={columnsDev}
                    dataSource={deviceData}
                    size={"small"}
                    pagination={{
                        current: _current, pageSize: _pageSize, total: total,
                        onChange: (page, pageSize) => {
                            //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                            // console.log(page, pageSize);
                            this.QueryNetFindPage(pageSize, page);
                        }
                    }}
                />
            </div>
        );
    }
}
