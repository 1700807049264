import React, { useState, useEffect } from 'react'
import {
    Card, Row, Col, Button, Affix, message, List, Collapse
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import { Formik } from "formik"
import {
    SubmitButton,
    ResetButton,
    AddRowButton,
    RemoveRowButton,
    FormItem,
    Select,
    Form,
    Table,
    Input,
    InputNumber
} from "formik-antd"
import http from '@/axios/axios_utils';
import { scrape_job } from '../utils/api';
import { getFormAutoTime, getSelectOptionTime } from '../utils/index';
import Utils from '@/utils/utils';
import ListCompent from './tabelemt';
import InputTime from './inputelemt';
import { connect } from 'react-redux'
import "./index.scss"
import "animate.css"
const nameArray = ["job_name"]
const labelArray = ["采集任务名"]
let FromProps = {};
let labelFlag = true;
function DetaiJob(props) {
    const JOB_NAME = sessionStorage.getItem("job_name");
    // const [JobObject, setJobObject] = useState({});
    // const [staticConfigs, setStatiConfigs] = useState([]);
    useEffect(() => {//componentDidMount;
        if (JOB_NAME) { getJobDetial({ job_name: JOB_NAME }) }
        return () => {
            // sessionStorage.removeItem("job_name")
        }
    }, []);
    function onSubmitting(values) {
        const flag = nameArray.every((name, key) => {
            if (!values[name]) {
                message.error(labelArray[key] + "不能为空!");
                return false;
            } else {
                return true;
            }
        });
        if (!flag) { return }
        if (!labelFlag) { message.error("参数有误!"); return }
        // 重组params
        let params = {};
        (values.params || []).map((item) => {
            params[item["key"]] = item["value"];
        })
        //深拷贝
        let tempVal = JSON.parse(JSON.stringify(values));
        tempVal.params = params;
        //重组static_configs
        tempVal.static_configs = tempVal.static_configs.map((item) => {
            const labels = item.labels;
            let tempLab = {};
            labels.map((lab) => {
                tempLab[lab.key] = lab.value;
            })
            item.labels = tempLab;
            return item
        });
        console.log(tempVal);
        if (JOB_NAME) {//编辑
            updateModule(tempVal);
        } else {//新增
            addModule(tempVal);
        }
    }
    function getJobDetial(body) {
        http.get(scrape_job(), body, (r) => {
            //深拷贝
            let backData = JSON.parse(JSON.stringify(r.data.record || {}));

            //重组static_configs
            const static_cfg = (backData.static_configs || []).map((item) => {
                const labels = item.labels;
                const newlabels = Object.keys(labels).map((key) => {
                    return { key, value: labels[key] };
                });
                item.labels = newlabels;
                return item
            })
            // 重组params
            const params = backData.params || {};
            const paramList = [];
            for (const key in params) {
                paramList.push({ key, value: params[key] });
            }
            backData.params = paramList;
            backData.static_configs = static_cfg;
            console.log(backData);
            const { setValues } = FromProps;
            if (setValues) {
                setValues(backData);
            }
        })
    }
    function addModule(body) {
        http.post(scrape_job(), body, (r) => { message.success("添加成功!"); props.history.go(-1) })
    }
    function updateModule(body) {

        http.put(scrape_job(), body, (r) => { message.success("修改成功!"); props.history.go(-1) })
    }
    function checkJson(id) {
        const dom = document.getElementById(id);
        labelFlag = true;
        dom.style.border = "0px"
        try {
            const t = JSON.parse(dom.value);
        } catch (e) {
            labelFlag = false;
            dom.style.border = "1px solid red";
            message.error("格式错误!必须输入JSON格式字符!")
        }
    }
    function createColum(lists, itemId, fromProps) {
        const content = lists.map((item, key) => {
            if (item.title === "序号") {
                return {
                    width: 50,
                    title: "序号",
                    align: "center",
                    key: "序号",
                    render: (text, record, i) => (i + 1),
                }
            } else if (item.title === "操作") {
                return {
                    width: 50,
                    key: "操作",
                    align: "center",
                    render: (text, record, index) => (<RemoveRowButton style={{ border: "none" }} icon="delete" name={itemId} index={index} />),
                }
            } else if (item.type === "extList") {
                return {
                    title: item.title,
                    align: "center",
                    ellipsis: true,
                    key: item.key,
                    // render: (text, record, i) => {
                    //     const keys = Object.keys(text.labels);
                    //     console.log(text)
                    //     console.log(keys)
                    //     return <div>
                    //         {
                    //             keys.map((jsonKey, kk) => (
                    //                 <Row>
                    //                     <Col span={12}>
                    //                         {jsonKey}
                    //                         <Input
                    //                             name={`${itemId}.${i}.${item.key}.${kk}.jsonkey`}
                    //                         />
                    //                     </Col>
                    //                     <Col span={12}>
                    //                         <Input
                    //                             name={`${itemId}.${i}.${item.key}.${kk}.jsonval`}
                    //                         />
                    //                     </Col>
                    //                 </Row>
                    //             ))
                    //         }

                    //     </div>
                    // },
                    render: (text, record, i) => {

                        return <div className="configConent">
                            <Collapse bordered={false}>
                                <Collapse.Panel header="详情" className="panel">
                                    <Table
                                        name={`${itemId}.${i}.${item.key}`}
                                        rowKey={(row, index) => (index)}
                                        size="small"
                                        pagination={false}
                                        columns={[
                                            {

                                                key: "key",
                                                title: "参数",
                                                align: "center",
                                                render: (text, record, index) => (<Input
                                                    name={`${itemId}.${i}.${item.key}.${index}.key`}
                                                />),
                                            },
                                            {

                                                key: "value",
                                                title: "值",
                                                align: "center",
                                                render: (text, record, index) => (<Input
                                                    name={`${itemId}.${i}.${item.key}.${index}.value`}
                                                />),
                                            }, ,
                                            {

                                                key: "操作",
                                                width: 50,
                                                align: "center",
                                                render: (text, record, index) => (<RemoveRowButton style={{ border: "none" }} icon="delete"
                                                    name={`${itemId}.${i}.${item.key}`}
                                                    index={index}
                                                />),
                                            }
                                        ]}
                                    />
                                    <AddRowButton
                                        name={`${itemId}.${i}.${item.key}`}
                                        type="link"
                                        className="labelTableBtn"
                                        icon="file-add"
                                        createNewRow={() => ({
                                            key: "",
                                            value: ""
                                        })}
                                    > 添加</AddRowButton>

                                </Collapse.Panel>
                            </Collapse>

                        </div>
                    },
                    // render: (text, record, i) => (
                    //     //     <Input.TextArea
                    //     //     className="JsonTextArea"
                    //     //     id={`label${i}`}
                    //     //     name={`${itemId}.${i}.${item.key}`}
                    //     //     onBlur={() => { checkJson(`label${i}`) }}
                    //     // />
                    //     <ListCompent

                    //         name={`${itemId}.${i}.${item.key}`}
                    //         fromProps={fromProps}
                    //         elemtProps={fromProps.getFieldProps(`${itemId}.${i}.${item.key}`)}
                    //     />

                    // ),
                }
            } else {
                return {
                    title: item.title,
                    align: "center",
                    ellipsis: true,
                    key: item.key,
                    render: (text, record, i) => (
                        item.type === "tag" ? <Select
                            name={`${itemId}.${i}.${item.key}`}
                            className="selectTag"
                            mode="tags" /> : <Input
                                name={`${itemId}.${i}.${item.key}`}
                            />
                    ),
                }
            }

        })
        return content
    }
    return <div className="monitorDetaiJob">
        <CustomBreadcrumb arr={["监控告警", "数据采集", { title: "采集任务", to: "/monitorAlert/dataCollection/scrapeJob" }, JOB_NAME ? "采集任务详情" : "新增采集任务"]} />
        <Card className="infoCard" title="编辑采集任务信息">
            <div className="formDiv">
                <Formik
                    initialValues={{}}
                    onSubmit={(values, actions) => {
                        onSubmitting(values);
                        actions.setSubmitting(false)
                    }}
                >
                    {(fromProps) => {
                        FromProps = fromProps;
                        return <Form
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 15 }}
                            layout="horizontal"
                        >
                            <div className="baseDiv">
                                <Row className="firstRow">
                                    <Col span={6}>
                                        <FormItem
                                            name={"job_name"}
                                            label={"任务名"}
                                            required={true}
                                            hasFeedback
                                        >
                                            <Input name={"job_name"} disabled={JOB_NAME ? true : false} placeholder={"采集任务名"} />
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                            name={"metrics_path"}
                                            label={"路径"}
                                            hasFeedback
                                        >
                                            <Input name={"metrics_path"} placeholder={"路径"} />
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                            name={"scheme"}
                                            label={"scheme"}
                                            hasFeedback
                                        >
                                            <Input name={"scheme"} placeholder={"scheme"} />
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        {/* <FormItem
                                            name={"scrape_interval"}
                                            label={"采集频率"}
                                            hasFeedback
                                        >
                                            <InputTime
                                                name={"scrape_interval"}
                                                fromProps={fromProps}
                                                inputTimeProps={fromProps.getFieldProps("scrape_interval")}
                                            />
                                        </FormItem> */}
                                        {getFormAutoTime("scrape_interval", "采集频率")}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        <FormItem
                                            name={"scrape_timeout"}
                                            label={"采集超时"}
                                            hasFeedback
                                        >
                                            <Select name={"scrape_timeout"}>
                                                {getSelectOptionTime()}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </div>
                            <FormItem
                                name={"static_configs"}
                                label="静态配置"
                                wrapperCol={{ span: 22 }}
                                labelCol={{ span: 2 }}
                                hasFeedback

                            >
                                <div className="static_config_div">
                                    <Table
                                        name="static_configs"
                                        rowKey={(row, index) => (index)}
                                        size="small"
                                        pagination={false}
                                        columns={createColum([
                                            { key: "序号", title: "序号", },
                                            { key: "targets", title: "targets", type: "tag" },
                                            {
                                                key: "labels", title: "labels", type: "extList"
                                            },
                                            { key: "操作", title: "操作" }
                                        ], "static_configs", fromProps)}
                                    />
                                    <AddRowButton
                                        name="static_configs"
                                        type="primary"
                                        icon="file-add"
                                        createNewRow={() => ({
                                            labels: [],
                                            targets: []
                                        })}
                                    > 添加</AddRowButton>
                                </div>
                            </FormItem>
                            <Collapse >
                                <Collapse.Panel header="标签重命名">
                                    <FormItem
                                        name={"relabel_configs"}
                                        label="标签重命名"
                                        wrapperCol={{ span: 22 }}
                                        labelCol={{ span: 2 }}
                                        hasFeedback
                                    >
                                        <div className="tableDiv">
                                            <Table
                                                name="relabel_configs"
                                                rowKey={(row, index) => (index)}
                                                size="small"
                                                pagination={false}
                                                columns={createColum(
                                                    [
                                                        { key: "序号", title: "序号" },
                                                        { key: "source_labels", title: "source_labels" },
                                                        { key: "target_label", title: "target_label" },
                                                        { key: "separator", title: "separator" },
                                                        { key: "regex", title: "regex" },
                                                        { key: "replacement", title: "replacement" },
                                                        { key: "action", title: "action" },
                                                        { key: "操作", title: "操作" },
                                                    ], "relabel_configs", fromProps
                                                )}
                                            />
                                            <AddRowButton
                                                name="relabel_configs"
                                                type="primary"
                                                icon="file-add"
                                                createNewRow={() => ({
                                                    source_labels: "",
                                                    target_label: ""
                                                })}
                                            > 添加</AddRowButton>
                                        </div>
                                    </FormItem>

                                </Collapse.Panel>
                                <Collapse.Panel header="参数">
                                    <FormItem
                                        name={"params"}
                                        label="参数"
                                        wrapperCol={{ span: 22 }}
                                        labelCol={{ span: 2 }}
                                        hasFeedback
                                    >
                                        <div>
                                            <Table
                                                name="params"
                                                rowKey={(row, index) => (index)}
                                                size="small"
                                                pagination={false}
                                                columns={createColum([
                                                    { key: "序号", title: "序号", },
                                                    { title: "参数", key: "key", },
                                                    { title: "值", key: "value", type: "tag" },
                                                    { key: "操作", title: "操作" }
                                                ], "params", fromProps)}
                                            />
                                            <AddRowButton
                                                name="params"
                                                type="primary"
                                                icon="file-add"
                                                createNewRow={() => ({
                                                    key: "",
                                                    value: []
                                                })}
                                            > 添加</AddRowButton>
                                        </div>
                                    </FormItem>
                                </Collapse.Panel>
                            </Collapse>
                            <div className="bootmBtn">
                                <SubmitButton disabled={false} icon="save">保存</SubmitButton>&nbsp;
                            <ResetButton disabled={false} icon="reload" >清空</ResetButton>&nbsp;
                            <Button icon="close" onClick={() => { props.history.go(-1) }} >返回</Button>
                            </div>
                        </Form>
                    }}
                </Formik>

            </div>
        </Card>

    </div>
}

export default connect()(DetaiJob)