/**
 * 配置下发操作审计
 */
import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Tabs, Tag, Modal
} from "antd";
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import personUtils from './utils/index';
import http from '@/axios/axios_utils';
import api from '@/config/api/deliveryTask';
import MonacoEditor from 'react-monaco-editor';
import Socket from '@/socket/index';
import {
    SubmitButton,
    ResetButton,
    Form,
    FormItem,
    Select
} from "formik-antd"
const FormikInput = require("formik-antd").Input;
import { Formik } from "formik"
import "./index.scss"
function OperationAudit(props) {
    const [total, set_total] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const [recordData, setRecordData] = useState([]);

    const [taskTypeList, setTaskType] = useState([]);
    const [IPrecordData, setIPRecordData] = useState([]);
    const [IPtotal, set_IPtotal] = useState(10);
    const [IP_pageSize, set_IP_pageSize] = useState(20);
    const [IP_current, set_IPcurrent] = useState(1);
    const [IP_querys, set_IPquerys] = useState({});
    const [treeDepIds, setTreeDepIds] = useState([]);

    useEffect(() => {//componentDidMount
        http.setDispatch(null)
        // getOperationAudit(_pageSize, _current, {});
        // conditionQueryExecuteRecord(IP_pageSize, IP_current, {})
        getTaskType();
        getAreaData();
        return () => { //componentWillUnmount
        }
    }, []);
    function getAreaData() {
        Socket.ExecuteAction('QueryArea', {}, "", (result) => {
            const TreeData = result.data;
            const depIds = dealTreeData(TreeData);
            setTreeDepIds(depIds);

            getOperationAudit(_pageSize, _current, {dep_ids: depIds});
            conditionQueryExecuteRecord(IP_pageSize, IP_current, {dep_ids: depIds})    
            // console.log("getAreaData", TreeData, depIds)
        });
    };
    function dealTreeData(data) {
        let dep_ids = [];
        if(Array.isArray(data)) {
            data.map((item) => {
                let children = item.props ? item.props.children : item.children
                if(children && children.length > 0) {
                    dep_ids = dep_ids.concat(dealTreeData(children));
                } else {
                    dep_ids.push(item.key || item.eventKey)
                }
            })
        }
        return dep_ids
    }
    function getOperationAudit(pageSize, pageNum, querys) {
        props.dispatch(isLoading(true));
        let bodyJson = {
            page_size: pageSize || _pageSize,
            page_no: pageNum || _current,
            dep_ids: treeDepIds || [],
            ...querys
        };
        http.post(api.conditionQueryOperationRecord, bodyJson, (data) => {
            props.dispatch(isLoading(false));
            setRecordData(data.data);
            set_total(data.count);
            set_querys(querys);
            set_current(bodyJson.page_no);
        });
    }
    function getTaskType() {
        Socket.ExecuteAction('QueryTaskTypes', {}, "", (result) => {
            setTaskType(result.data);
        });
    }
    function conditionQueryExecuteRecord(pageSize, pageNum, querys) {
        props.dispatch(isLoading(true));
        let bodyJson = {
            page_size: pageSize || IP_pageSize,
            page_no: pageNum || IP_current,
            dep_ids: treeDepIds || [],
            ...querys
        };
        http.post(api.conditionQueryExecuteRecord, bodyJson, (data) => {
            props.dispatch(isLoading(false));
            setIPRecordData(data.data);
            set_IPtotal(data.count);
            set_IPquerys(querys);
            set_IPcurrent(bodyJson.page_no);
        });
    }
    function showInfo(a) {
        Modal.info({
            title: "交互信息",
            width: "65%",
            style: { top: 10 },
            okText: "关闭",
            content: <MonacoEditor
                theme="vs-dark"
                value={a}
                language="python"
                options={{ minimap: { enabled: false }, lineNumbers: "off", scrollBeyondLastLine: false, }}
                height="600"
            />
        })
    }
    const colums = [
        {
            title: "序号", dataIndex: "key", render: (a, b, c) => {
                return c + 1 + (_current - 1) * _pageSize
            }
        },
        { title: "操作类型", align: "left", dataIndex: "operation_type", },
        { title: "任务名称", align: "left", dataIndex: "task_id" },
        { title: "操作用户", align: "left", dataIndex: "operation_user" },
        { title: "操作时间", align: "left", dataIndex: "operation_time" },
        { title: "内容", align: "left", dataIndex: "operation_content" },
        {
            title: "详情", render: (a, b) => {
                if (b.operation_type === "执行") {
                    return <Button type="primary" icon="file-text" size="small" onClick={() => {
                        personUtils.showTaskInfo(b)
                    }}> 详情</Button>
                } else {
                    return ""
                }

            }
        },
    ]
    const colums2 = [
        {
            title: "序号", dataIndex: "key", render: (a, b, c) => {
                return c + 1 + (IP_current - 1) * IP_pageSize
            }
        },
        { title: "设备IP", align: "left", dataIndex: "ip" },
        { title: "任务名称", align: "left", dataIndex: "task_name" },
        { title: "任务类型", align: "left", dataIndex: "task_type" },
        { title: "任务状态", align: "left", dataIndex: "task_status" },
        { title: "操作用户", align: "left", dataIndex: "username" },
        { title: "执行时间", align: "left", dataIndex: "operation_time" },
        {
            title: "设备执行结果", dataIndex: "execute_code", render: (a) => {
                if (a === "00") {
                    return <Tag color="#87d068">成功</Tag>
                } else {
                    return <Tag color="#f50">失败</Tag>
                }
            }
        },
        { title: "创建时间", align: "left", dataIndex: "create_time" },
        {
            title: "详情", render: (a, b) => {
                return <Button type="primary" icon="file-text" size="small" onClick={() => {
                    showInfo(b.details);
                }}> 详情</Button>
            }
        },
    ]
    return <div className="TaskAuditDiv">
        <CustomBreadcrumb arr={["运维管理", "配置下发", "下发查询"]} />
        <Card
        // title={Utils.BoldFont("审计记录")}
        >
            <Tabs onChange={() => {
                // if(key==="device"){conditionQueryExecuteRecord(IP_pageSize, IP_current, {});}   
            }}>
                <Tabs.TabPane tab="操作记录" key="opertion">
                    <div className="ipSearch">
                        <Formik
                            initialValues={{ task_name: "", username: "", operation_type: "" }}
                            onSubmit={(values, actions) => {
                                console.log(values)
                                getOperationAudit(_pageSize, 1, values)
                                actions.setSubmitting(false)
                            }}
                        >
                            {() => {
                                return <Form layout="inline" >
                                    <FormItem name={"task_name"} label={"任务名称"}>
                                        <FormikInput name={"task_name"} placeholder="任务名称" />
                                    </FormItem>
                                    <FormItem name={"username"} label={"操作用户"}>
                                        <FormikInput name={"username"} placeholder="操作用户" />
                                    </FormItem>
                                    <FormItem name={"operation_type"} label={"操作类型"}>
                                        <Select name={"operation_type"} placeholder="操作类型" style={{ width: 172 }} >
                                            <Select.Option value="执行">执行</Select.Option>
                                            <Select.Option value="提交">提交</Select.Option>
                                            <Select.Option value="删除">删除</Select.Option>
                                        </Select>
                                    </FormItem>
                                    <SubmitButton disabled={false} icon="search">查询</SubmitButton>&nbsp;
                                    <ResetButton disabled={false} icon="reload" onClick={() => { getOperationAudit(_pageSize, 1, {}) }}>重置</ResetButton>
                                </Form>

                            }}
                        </Formik>
                    </div>
                    <Table
                        rowKey={(row) => { return row._id }}
                        columns={colums}
                        dataSource={recordData}
                        size="small"
                        pagination={{
                            pageSize: _pageSize, current: _current, total: total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                set_pageSize(size)
                                getOperationAudit(size, 1, _querys)
                            },
                            onChange: (page, pageSize) => {
                                getOperationAudit(pageSize, page, _querys)
                            }
                        }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="设备下发记录" key="device">
                    <div className="ipSearch">
                        <Formik
                            initialValues={{ ip: "", }}
                            onSubmit={(values, actions) => {
                                console.log(values)
                                conditionQueryExecuteRecord(IP_pageSize, 1, values)
                                actions.setSubmitting(false)
                            }}
                        >
                            {(props2) => {
                                return <Form layout="inline" >
                                    <FormItem name={"ip"} label={"设备IP"}>
                                        <FormikInput name={"ip"} placeholder="设备IP" />
                                    </FormItem>
                                    <FormItem name={"task_name"} label={"任务名称"}>
                                        <FormikInput name={"task_name"} placeholder="任务名称" />
                                    </FormItem>
                                    <FormItem name={"operation_user"} label={"操作用户"}>
                                        <FormikInput name={"operation_user"} placeholder="操作用户" />
                                    </FormItem>
                                    <FormItem name={"task_type"} label={"任务类型"}>
                                        <Select name={"task_type"} style={{ width: 172 }} >
                                            {taskTypeList.map((item, kk) => {
                                                return <Select.Option key={kk} value={item.name}>{item.name}</Select.Option>
                                            })}
                                        </Select>
                                    </FormItem>
                                    <SubmitButton disabled={false} icon="search">查询</SubmitButton>&nbsp;
                                    <ResetButton disabled={false} icon="reload" onClick={() => { }}>重置</ResetButton>
                                </Form>

                            }}
                        </Formik>
                    </div>
                    <Table
                        rowKey={(row) => { return row._id }}
                        columns={colums2}
                        dataSource={IPrecordData}
                        size="small"
                        pagination={{
                            pageSize: IP_pageSize, current: IP_current, total: IPtotal,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                set_IP_pageSize(size)
                                conditionQueryExecuteRecord(size, 1, IP_querys)
                            },
                            onChange: (page, pageSize) => {
                                conditionQueryExecuteRecord(pageSize, page, IP_querys)
                            }
                        }}
                    />
                </Tabs.TabPane>
            </Tabs>

        </Card>
    </div >

}
export default connect()(OperationAudit)