import React from 'react'
import { Tree } from "antd";
const TreeNode = Tree.TreeNode;
class BaseTree extends React.Component {
    state = {
        // checkedKeys: this.props.checkedKeys,
        // expandedKeys: [],
    }
    componentWillMount() {

    }
    renderTreeNodes = data =>
        data.map(item => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={item.key} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode {...item} />;
        });

    render() {
        const { treeData, checkable = true, onCheck, checkedKeys, checkStrictly = false } = this.props
        // const { checkedKeys } = this.state;
        return (
            <Tree
                checkable={checkable}
                checkedKeys={checkedKeys}
                checkStrictly={checkStrictly}
                onCheck={(checkedKeys, info) => {
                    // this.setState({ checkedKeys });
                    onCheck(checkedKeys, info);
                }}

            >
                {this.renderTreeNodes(treeData)}
            </Tree>
        )
    }
}


export default BaseTree