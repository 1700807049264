import React from 'react'
import {Card, Select, Input, Button, Form, message, notification, Icon, Row, Col, Modal, Tabs} from 'antd';
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import AxiosObj from "../../../axios";
const { Option } = Select;

// monitor_address 配置地址：public/flow/config.js
const backUp_Url = monitor_address;

class addRule extends React.Component {
    state = {
        receiverDataSource:[],  //接受者选项，前端展示格式
        receiverData:[],   //接受者选项，前端展示格式
        rule : {
            name : 'example',
            rule : {
                alert : '',
                expr : '',
                for : '1m',
                annotations :{
                    description :'',
                }
            }
        },
    }
    componentWillMount(){
        this.getReceiverList();
    }

    //获取接受者列表
    getReceiverList=()=>{
        AxiosObj.MA_HTTP_GET(backUp_Url+"/snmpexporter/receiverList",(response)=>{
            //console.log(response.data)
            let receiverList = response.data.receivers.receiver;
            let list = [],tmpData=[];
            for(let i in  receiverList){
                list.push(<Option key={receiverList[i].name}>{receiverList[i].name }</Option>)
                tmpData.push(receiverList[i].name)
            }
            this.setState({
                receiverDataSource : list,
                receiverData:tmpData
            })
            console.log(list);

        },(error)=>{
            message.error(error);
        })

    }
    //表格提交
    handleSubmit = (e) => {
        let { rule } = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                //let body = values;
                rule['receivers'] = values.receivers;
                rule.name = 'example';//  这个暂时没有使用到，先写死在这里
                rule.rule.alert = values.alertName;
                rule.rule.expr = values.expr;
                rule.rule.for = values.for;
                rule.rule.annotations.description = values.description;
                console.log(rule);
                //POST 方法第一个参数是与axios中地址的选择
                AxiosObj.HTTP_POST('alarm','/snmpexporter/addRule',rule,(data)=>{
                    message.success('success');
                    this.props.history.goBack();
                },(error)=>{
                    message.error(error);
                });

            }
        });
    };
    onTabChange = (key, type) => {
        console.log(key, type);
        this.setState({ [type]: key });
    };
    render(){
        const { rule, receiverDataSource ,receiverData} =this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };

        return(
            <div>
                <CustomBreadcrumb arr={["监控告警", "告警",{to:'/monitoringAlarm/alarm/setting',title:"告警设置"},"添加告警规则"]} />
                <Card
                    style={{ width: '100%', marginTop: 30  }}
                    title={<span style={{ fontWeight: 600 }}>添加告警规则</span>}
                    onTabChange={key => {
                        this.onTabChange(key, 'key');
                    }}
                >
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item
                            label="告警名称"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('alertName', {
                                initialValue:  "",
                                rules: [{
                                    type: 'string', message: '请输入正确字符！',
                                }, {
                                    pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                }, {
                                    required: true, message: '请输入字符!',
                                }],
                            })(<Input />)}
                        </Form.Item>
                        {/*<Form.Item*/}
                            {/*label="告警规则组"*/}
                            {/*{...formItemLayout}*/}
                        {/*>*/}
                            {/*{getFieldDecorator('group', {*/}
                                {/*initialValue:  rule.name || "example",*/}
                            {/*})(*/}
                                {/*<Input />*/}
                            {/*)}*/}
                        {/*</Form.Item>*/}
                        <Form.Item
                            label="告警表达式"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('expr', {
                                initialValue:  rule.rule.expr,
                                rules: [{required: true,message : '请输入告警表达式'}]
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="触发时长"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('for', {
                                initialValue:  "1m",
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="描述"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('description', {
                                initialValue:  "",
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="接受者"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('receivers', {
                                    initialValue:receiverData.slice(0,1) ||''
                            })(
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    onChange={(value)=>{
                                        // console.log(value)
                                    }}
                                >
                                    {receiverDataSource}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            label=" "
                            colon={false}
                            {...formItemLayout}
                        >
                            <div >
                                <Button type="primary" icon="check-circle" htmlType="submit" style={{ width: 150 }}>
                                    保存
                                </Button>
                                <Button type="danger" icon="close-circle" style={{ width: 150, marginLeft: 10 }}
                                        onClick={() => { this.props.history.goBack(); }}>
                                    取消
                                </Button>
                            </div>
                        </Form.Item>

                    </Form>
                </Card>
            </div>
        )
    }
}

export default Form.create()(addRule);