import React from 'react';
import { Select, Tag, Tooltip, notification, Card } from 'antd'
import Socket from "@/socket/index"
import FilterForm from '@/components/FilterForm/index';
import Utils from "@/utils/utils"
const Option = Select.Option;
export default {
    getColumns(_pageSize, _current) {
        let columns = [
            {
                title: '序号',
                align: 'center',
                ellipsis: true,
                width: 50,
                dataIndex: "key",
                render: (text, item, index) => {
                    return <span>{index + 1 + (_current - 1) * _pageSize}</span>
                }
            },
            {
                title: '资源名称',
                align: 'left',
                width: 110,
                ellipsis: true,
                dataIndex: 'resourceName'
            },
            {
                title: '设备IP',
                align: 'left',
                ellipsis: true,
                width: 110,
                dataIndex: 'resourceParams.deviceIp',
            },
            {
                title: '类型',
                align: 'left',
                width: 100,
                ellipsis: true,
                dataIndex: 'resourceParams.deviceType',
                render: (text, item, index) => {
                    return Utils.Translation(text);
                }
            },
            {
                title: '厂商',
                align: 'left',
                width: 70,
                ellipsis: true,
                dataIndex: 'resourceParams.deviceFirm',
            },
            {
                title: 'OS版本',
                align: 'left',
                ellipsis: true,
                dataIndex: 'resourceParams.deviceOsVersion',
            },
            {
                title: 'OS类型',
                ellipsis: true,
                width: 100,
                align: 'left',
                dataIndex: 'resourceParams.deviceOsType',
            },
            {
                title: '设备名称',
                ellipsis: true,
                align: 'left',
                dataIndex: 'resourceParams.deviceId',
            },
            {
                title: '型号',
                ellipsis: true,
                align: 'left',
                dataIndex: 'resourceParams.deviceVersion',
            },
            {
                title: '标签',
                dataIndex: 'tags',
                // width: "100px",
                align: "left",
                render: (text) => {
                    return <div>{Utils.hidenTag(text)}</div>;
                }
            }
        ];
        return columns;
    },
    getFilterForm(_this) {
        let { s_pageSize } = _this.state;
        return <Card>
            <FilterForm
                tagClass="resource"
                filterSubmit={(s_querys) => {
                    _this.setState({ s_querys })
                    _this.updataDev(s_pageSize, 1, s_querys)
                }}
                resetTab={() => {
                    _this.setState({ s_querys: {} })
                    _this.updataDev(s_pageSize, 1, {})
                }}
            />
        </Card>
    },
    getDeviceData(pageSize, pageNum, querys, callback) {
        Socket.ExecuteAction("QueryResourcesPage", { pageSize, pageNum, querys }, "/QueryResourcesPage", (data) => {
            callback(data);
        });
    }
}