import React from 'react';
import { Card, Button, Modal, Tabs, Tag, Upload, Icon, message, Table, Tooltip, Row, Col, Divider, Input, Switch } from 'antd';
import FormModal from '@/components/EditModal/index';
import axios_temp from '@/axios/axios_utils';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import AddTable from '@/components/AddTable/index'
import "./index.scss";
import * as XLSX from 'xlsx';
import Utils from "@/utils/utils";
import { SubmitButton, FormItem, InputNumber, Select, TimePicker, } from "formik-antd";
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik";
import moment from "moment";
const smsUrl = "http://" + backup_address + "/ZJNMS_V1/sms_monitor/";
export default class DeviceCheck2 extends React.Component {
    state = {
        tags: [],
        inputVisible: false,
        editCheckVis: false,
        editVisible: false,
        SettingVis: false,
        inputValue: '',
        configData: {},
        settingParam: {},
        current: 1,
        pageSize: 20,
        tableData: [],
        loading: false,
        total: 0,
        selectedRowKeys: [],
    };
    componentWillMount() {
        this.getDevices()
    }
    showError(error) {
        Utils.showMessage("error", "操作失败", error);
    }
    getDevices() {
        const temp_url = smsUrl + "device"
        this.setState({ loading: true })
        axios_temp.get(temp_url, {}, (result) => {
            console.log(result.ips)
            this.setState({ tags: result.ips.map((ip, key) => { ip.key = key; return ip }), loading: false, editVisible: false })
        });
    }
    addData(data) {
        const temp_url = smsUrl + "device"
        axios_temp.post(temp_url, { data }, () => {
            message.success("新增成功");
            this.getDevices()
        }, () => { this.getDevices() });
    }
    updateData(data) {
        console.log(data)
        const temp_url = smsUrl + "device"
        axios_temp.put(temp_url, { data }, () => {
            message.success("更新成功");
            this.getDevices()
        }, () => { this.getDevices() });
    }

    editHostPing(value) {
        console.log(value)
        // value.begin_time = moment(value.begin_time).format("HH:mm:ss")
        // value.end_time = moment(value.end_time).format("HH:mm:ss")
        console.log(value)
        axios_temp.post(smsUrl + "config", value, (res) => {
            if (res.recode === "00") {
                message.success("修改成功");
                this.setState({
                    editCheckVis: false,
                })
            } else {
                message.error("修改失败");
            }
        })
    }
    getHostConfig() {
        axios_temp.get(smsUrl + "config", {}, (res) => {
            if (res.recode === "00") {
                this.setState({
                    configData: res.config,
                    editCheckVis: true
                })
            } else {
                message.error("查询失败");
                this.setState({ editCheckVis: true })
            }
        })
    }
    getHistoryList(page, page_size) {
        this.setState({ loading: true })
        let { current, pageSize } = this.state;
        let bodyJson = {
            page_no: page || current,
            page_size: page_size || pageSize
        }
        axios_temp.get(smsUrl + "record", bodyJson, (res) => {
            if (res.code === "00") {
                this.setState({
                    tableData: res.data,
                    total: res.count,
                    current: page || 1,
                    loading: false
                })
            } else {
                message.error("查询失败");
                this.setState({ loading: false })
            }
        })
    }
    delData(ids) {
        let _this = this;
        if (ids.length === 0) {
            message.error("请选择需要删除的记录！")
        } else {
            Modal.confirm({
                title: '消息提示',
                content: '你确定删除该设备吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    axios_temp.delete_body(smsUrl + "device", { _ids: ids }, (res) => {
                        console.log(res)
                        if (res.code === "00") {
                            message.success(res.msg);
                            _this.setState({ selectedRowKeys: [] });
                            _this.getDevices();
                        } else {
                            message.error("删除失败")
                        }
                    })
                },
            });
        }
    }
    handleClose = removedTag => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        console.log(tags);
        this.setState({ tags });
    };

    showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());

    };
    handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
    };
    handleInputConfirm = () => {
        const { inputValue } = this.state;
        let { tags } = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        console.log(tags);
        this.setState({
            tags,
            inputVisible: false,
            inputValue: '',
        });
    };
    saveInputRef = input => (this.input = input);
    importDB(data) {
        console.log(data)
        let _this = this
        axios_temp.post(smsUrl + "import", { data }, (res) => {
            console.log(res)
            if (res.code === "00") {
                message.success(res.msg);
                _this.getDevices()
            } else {
                message.error("导入失败")
            }
        })
    }
    uploadFilesChange = (file) => {
        console.log(file)
        let _this = this;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = {};
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {

                    let tempData = [];
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data[sheet] = tempData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    }
                }
                // 最终获取到并且格式化后的 json 数据
                Modal.confirm({
                    title: '消息提示',
                    content: '导入表格将会完全覆盖系统表格信息，确认导入吗？',
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        _this.importDB(data)
                    },
                });


            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                console.log(e);
                message.error('文件类型不正确！');
            }
        }
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(file.file);
    }
    downloadTemplate = () => {
        let { tags } = this.state
        let list = []

        tags.map((data) => {
            list.push({
                "设备IP": data.ip,
                "设备类型": data.deviceType,
                "设备厂商": data.deviceFirm,
                "设备区域": data.deviceArea,
                "用户名": data.username,
                "密码": data.password,
                "备注": data.remarks,
            })
        })

        Utils.exportSheetExcel(list, "设备信息", "监控设备.xlsx");
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        // render: text =>
        //     this.state.searchedColumn === dataIndex ? (
        //         <Highlighter
        //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        //             searchWords={[this.state.searchText]}
        //             autoEscape
        //             textToHighlight={text.toString()}
        //         />
        //     ) : (
        //             text
        //         ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    editSetting = () => {
        const temp_url = smsUrl + "sms_setting"
        axios_temp.get(temp_url, {}, (result) => {
            this.setState({ SettingVis: true, settingParam: result.param })
        });
    };
    submitStting = (val) => {
        const temp_url = smsUrl + "sms_setting"
        val.begin_time = moment(val.begin_time).format("HH:mm:ss")
        val.end_time = moment(val.end_time).format("HH:mm:ss")
        axios_temp.post(temp_url, val, (result) => {
            this.setState({ SettingVis: false })
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    render() {
        const { tags, SettingVis, loading, current, pageSize, settingParam, selectItem, selectedRowKeys, editVisible } = this.state;

        const columns = [
            {
                title: '序号',
                width: 60,
                align: 'left',
                dataIndex: 'key',
                render: (text, item, index) => {
                    return <span>{index + 1 + (current - 1) * pageSize}</span>
                }
            },
            {
                title: 'IP地址/丢包率/备注',
                align: 'left',
                dataIndex: 'ip_list',
                // width: 240,
                ellipsis: true,
                render: (text) => {
                    // return <Table
                    //     dataSource={text || []}
                    //     columns={[{ title: "IP地址", dataIndex: "ip" }, { title: "丢包率", dataIndex: "packet_loss" }]}
                    //     size="small"
                    //     showHeader={false}
                    //     pagination={false}
                    // />
                    return <div className="ipLossDiv">{text.map((one) => {
                        return <div>{one.ip} / {one.packet_loss * 100}% / {one.name}</div>
                    })}</div>
                }
            },
            {
                title: '联系人列表',
                align: 'left',
                dataIndex: 'phones',
                // width: 240,
                ellipsis: true,
                render: (text) => {
                    return <div className="phoneDiv">{text.map((t) => t + "\r\n")}</div>
                }
            },
            {
                title: '发送内容',
                align: 'left',
                dataIndex: 'content',
                // width: 120,
                ellipsis: true,
                render: (text) => {
                    return <div className="msgDiv">{text}</div>
                }
            },
            {
                title: '发送计数',
                align: 'center',
                dataIndex: 'count',
                // width: 120,
                ellipsis: true,
            },
            // {
            //     title: '丢包率发送阈值',
            //     align: 'center',
            //     dataIndex: 'loss_rate',
            //     width: 120,
            //     ellipsis: true,
            //     render: (text, item, index) => {
            //         return text * 100 + "%"
            //     }
            // },
            {
                title: '发送状态',
                align: 'center',
                dataIndex: 'sms_success',
                // width: 100,
                ellipsis: true,
                render: (text, item) => {
                    switch (text) {
                        case false: return <Tooltip title={item.tips_success}><Tag color="red">失败</Tag></Tooltip>;
                        case true: return <Tooltip title={item.tips_success}><Tag color="green">成功</Tag></Tooltip>;
                    }
                }
            },
            {
                title: '时间',
                align: 'center',
                dataIndex: 'time',
                // width: 220,
                ellipsis: true,
            },
            {
                title: '操作',
                align: 'center',
                dataIndex: 'op',
                width: 100,
                render: (text, item) => {
                    return <Button type="danger" size="small" icon="delete" onClick={() => this.delData([item._id])}>删除</Button>
                }
            },
        ]
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys) => {
                console.log(selectedRowKeys)
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };

        return <div className="hostPingDiv">
            <CustomBreadcrumb arr={["监控告警", "检测设备"]} />
            <Card title="检测设备" extra={<div>
                <Button icon="setting" type="primary" onClick={() => { this.editSetting() }}>编辑配置</Button>&nbsp;
                <Upload name="file" accept=".xls,.xlsx,.csv"
                    beforeUpload={function () { return false; }}
                    onChange={this.uploadFilesChange.bind(this)}
                    showUploadList={false}
                >
                    <Button type="primary" icon="import" onClick={() => { }}>导入设备</Button>&nbsp;
                </Upload>
                <Button icon="reload" onClick={() => { this.getDevices() }}>刷新列表</Button>&nbsp;
                <Button type="primary" icon="download" onClick={() => { this.downloadTemplate() }}>下载表格</Button>&nbsp;

                <Button type="danger" icon="delete" onClick={() => this.delData(selectedRowKeys)}>批量删除</Button>

            </div>}>
                <div>
                    <Table
                        rowSelection={rowSelection}
                        columns={[
                            { title: "序号", render: (t, i, k) => k + 1, width: 80 },
                            { title: "设备IP", dataIndex: "ip", ...this.getColumnSearchProps("ip") },
                            { title: "设备厂商", dataIndex: "deviceFirm", ...this.getColumnSearchProps("deviceFirm") },
                            { title: "设备类型", dataIndex: "deviceType", ...this.getColumnSearchProps("deviceType") },
                            { title: "设备区域", dataIndex: "deviceArea", ...this.getColumnSearchProps("deviceArea") },
                            { title: "备注", dataIndex: "remarks", ...this.getColumnSearchProps("remarks") },
                            {
                                title: "发送短信", dataIndex: "isSim", render: (t, i, k) => {
                                    return <Switch checkedChildren="发送" unCheckedChildren="不发送"
                                        checked={i.isSim}
                                        onChange={(checked) => { i.isSim = checked; this.updateData(i) }} />
                                }
                            },
                            { title: "用户名", dataIndex: "username", },
                            { title: "密码", dataIndex: "password", },
                            {
                                title: "操作", render: (t, item, k) => {
                                    return <div>
                                        <Button type="primary" size="small" icon="edit" onClick={() => { this.setState({ selectItem: item, editVisible: true }) }}>编辑</Button>
                                        <Divider type="vertical" />
                                        <Button type="danger" size="small" icon="delete" onClick={() => this.delData([item._id])}>删除</Button>
                                    </div>
                                }
                            }
                        ]}
                        dataSource={tags}
                        scroll={{ y: 800 }}
                        rowKey={(text) => { return text._id }}
                        size="small"
                        pagination={false}
                        loading={loading}
                    />
                    {/* <AddTable
                        columns={[
                            { title: "设备IP", dataIndex: "ip", type: "INPUT", ...this.getColumnSearchProps("ip") },
                            { title: "设备厂商", dataIndex: "deviceFirm", type: "INPUT", ...this.getColumnSearchProps("deviceFirm") },
                            { title: "设备类型", dataIndex: "deviceType", type: "INPUT", ...this.getColumnSearchProps("deviceType") },
                            { title: "设备区域", dataIndex: "deviceArea", type: "INPUT", ...this.getColumnSearchProps("deviceArea") },
                            { title: "备注", dataIndex: "remarks", type: "INPUT", ...this.getColumnSearchProps("remarks") },
                            { title: "发送短信", dataIndex: "isSim", type: "SWITCH" },
                            { title: "用户名", dataIndex: "username", type: "PASSWORD" },
                            { title: "密码", dataIndex: "password", type: "PASSWORD" },
                        ]}
                        value={tags}
                        onChange={(a, target, type) => {
                            this.setState({ tags: a })
                            console.log(target)
                            delete target["key"]
                            delete target["editable"]
                            delete target["required"]
                            delete target["type"]
                            if (type === "add") {
                                this.addData([target])
                            } else if (type === "del") {
                                if (target["_id"]) {
                                    this.delData([target["_id"]])
                                }
                            } else if (type === "update") {
                                this.updateData(target)
                            }
                        }}
                        scroll={{ y: 800 }}
                    /> */}
                    <Modal
                        title="编辑配置"
                        visible={SettingVis}
                        onCancel={() => { this.setState({ SettingVis: false }) }}
                        onOk={() => { this.setState({ SettingVis: false }) }}
                        width="60%"
                        destroyOnClose
                        footer={null}
                    >
                        <Formik
                            onSubmit={(value, actions) => { this.submitStting(value); actions.setSubmitting(false) }}
                            validate={(values) => {
                                let info = {};
                                return info
                            }}
                            initialValues={{
                                ping_count: settingParam.ping_count || 0,
                                ping_timeout: settingParam.ping_timeout || 0,
                                loss_rate: settingParam.loss_rate || 0,
                                interval_time1: settingParam.interval_time1 || 0,
                                interval_time2: settingParam.interval_time2 || 0,
                                begin_time: moment(settingParam.begin_time || "18:00:00", 'HH:mm:ss'),
                                end_time: moment(settingParam.end_time || "08:30:00", 'HH:mm:ss'),
                            }}
                        >
                            {(props) => {
                                return <FormikForm labelCol={{ span: 4 }} wrapperCol={{ span: 19 }} >
                                    <FormItem
                                        name={"ping_count"}
                                        label={"每次发送包数量(个)"}
                                        key={"ping_count"}
                                    >
                                        <InputNumber style={{ width: "100%" }} min={0} name={"ping_count"} placeholder={"请输入每次发送包数量"} />
                                    </FormItem>
                                    <FormItem
                                        name={"ping_timeout"}
                                        label={"响应超时(秒/S)"}
                                        key={"ping_timeout"}
                                    >
                                        <InputNumber style={{ width: "100%" }} min={0} name={"ping_timeout"} placeholder={"请输入响应超时"} />
                                    </FormItem>
                                    <FormItem
                                        name={"loss_rate"}
                                        label={"丢包率发送阈值(0~1)"}
                                        key={"loss_rate"}
                                    >
                                        <InputNumber style={{ width: "100%" }} min={0} max={1} step={0.1} name={"loss_rate"} placeholder={"请输入丢包率"} />
                                    </FormItem>
                                    <FormItem
                                        name={"interval_time1"}
                                        label={"前1-3次故障发送时间间隔"}
                                        key={"interval_time1"}
                                    >
                                        <Select name={"interval_time1"}>
                                            <Select.Option value={60}>1分钟</Select.Option>
                                            <Select.Option value={300}>5分钟</Select.Option>
                                            <Select.Option value={600}>10分钟</Select.Option>
                                            <Select.Option value={1200}>20分钟</Select.Option>
                                            <Select.Option value={1800}>30分钟</Select.Option>
                                            <Select.Option value={3600}>60分钟</Select.Option>
                                        </Select>
                                    </FormItem>
                                    <FormItem
                                        name={"interval_time2"}
                                        label={"3次以后发送时间间隔"}
                                        key={"interval_time2"}
                                    >
                                        <Select name={"interval_time2"}>
                                            <Select.Option value={3600}>1小时</Select.Option>
                                            <Select.Option value={7200}>2小时</Select.Option>
                                            <Select.Option value={10800}>3小时</Select.Option>
                                            <Select.Option value={14400}>4小时</Select.Option>
                                            <Select.Option value={18000}>5小时</Select.Option>
                                        </Select>
                                    </FormItem>
                                    <Row>
                                        <Col span={6}>
                                            <FormItem
                                                name={"begin_time"}
                                                label={"勿扰时间"}
                                                key={"begin_time"}
                                                labelCol={{ span: 16 }}
                                                wrapperCol={{ span: 3 }}
                                            >
                                                <TimePicker name={"begin_time"} />
                                            </FormItem>
                                        </Col>
                                        <Col span={6}>
                                            <FormItem
                                                name={"end_time"}
                                                label={"~"}
                                                key={"end_time"}
                                                colon={false}
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 16 }}
                                            >
                                                <TimePicker name={"end_time"} />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <div style={{ textAlign: "right" }}>
                                        <Button onClick={() => { this.setState({ SettingVis: false }) }}>取消</Button>
                                        <SubmitButton style={{ marginLeft: "10px" }} disabled={false}>保存</SubmitButton>&nbsp;
                        </div>
                                </FormikForm>
                            }}
                        </Formik>
                    </Modal>
                    <FormModal
                        title="编辑设备"
                        visible={editVisible}
                        onCancel={() => this.setState({ editVisible: false })}
                        onSubmit={(val) => {
                            this.updateData(val)
                        }}
                        footer={null}
                        width={"50%"}
                        initialValues={selectItem}
                        destroyOnClose
                        formList={[
                            { label: "设备IP", name: "ip", type: "input", },
                            { label: "设备厂商", name: "deviceFirm", type: "input", },
                            { label: "设备类型", name: "deviceType", type: "input", },
                            { label: "设备区域", name: "deviceArea", type: "input", },
                            { label: "备注", name: "remarks", type: "input", },
                            { label: "是否发送短信", name: "isSim", type: "radio", options: [{ label: "发送", value: true }, { label: "不发送", value: false }] },
                            { label: "用户名", name: "username", type: "input" },
                            { label: "密码", name: "password", type: "password" },
                        ]}
                    />
                </div>
                <Divider />
            </Card>
        </div>
    }
}