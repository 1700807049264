import React, { Fragment } from 'react'
import {
    Input, Form, Select, Menu, Icon,
    Typography, Card, Button
} from "antd";
const SubMenu = Menu.SubMenu;
export default class RightMenu extends React.Component {
    state = {
        edit: false,
    }
    componentWillMount() {
    }
    // 菜单渲染
    renderMenu = (data) => {
        return data.map((item) => {
            if (item.children) {
                return (
                    <SubMenu title={<span>{item.icon && <Icon type={item.icon} />}<span>{item.title}</span></span>} key={item.key}>
                        {this.renderMenu(item.children)}
                    </SubMenu>

                )
            }
            return <Menu.Item title={item.title} key={item.key}>
                <div>
                    {item.icon && <Icon type={item.icon} />}
                    <span>{item.title}</span>
                </div>
            </Menu.Item>
        })
    }
    // 菜单点击
    handleClick = ({ item, key }) => {
        console.log(item, key);
        this.props.onChange(key);
    }
    // onOpenChange(openKeys2) { 
    //     //只展开最后点开的菜单
    //     let { openKeys, rootSubmenuKeys } = this.state;
    //     const latestOpenKey = openKeys2.find(key => (!openKeys.includes(key)));
    //     if (rootSubmenuKeys.indexOf(latestOpenKey) === -1 || this.props.mode === "horizontal") {
    //         this.setState({ openKeys: openKeys2 });
    //     } else {
    //         this.setState({
    //             openKeys: latestOpenKey ? [latestOpenKey] : [],
    //         });
    //     }

    // }
    render() {
        const { MenuList = [] } = this.props;
        const menuTreeNode = this.renderMenu(MenuList);
        return <div className="rightMenu">
            <Menu
                onClick={(e) => this.handleClick(e)}
                // onOpenChange={(e) => this.onOpenChange(e)}
                mode="inline"
                // mode="horizontal"
                // defaultOpenKeys={['/taskManger','/configManger','/permission']} 
                // inlineCollapsed={collapsed}
                className={'menu-style'}
            >
                {menuTreeNode}
            </Menu>
        </div>
    }
}
