import React from 'react'
import {Card, Select, Input, Button, Form, message, notification, Icon, Row, Col, Modal, Tabs} from 'antd';
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import AxiosObj from "../../../axios";
// monitor_address 配置地址：public/flow/config.js
const backUp_Url = monitor_address;

class EmailForm extends React.Component {
    state={
        route : {                       //这些参数后续进行修改
            // "group_by": ["group_name"],
            "group_wait": "30s",                     //# 当收到告警的时候，等待三十秒看是否还有告警，如果有就一起发出去
            "group_interval": "10s",               // #当第一个报警发送后，等待'group_interval'时间来发送新的一组报警信息
            "repeat_interval": "1m",                //# 如果一个报警信息已经发送成功了，等待'repeat_interval'时间来重新发送他们
            "receiver": ""             //# 和receiver中的name对应
        },
        receiver:{},
    }
    //email表单提交
    emailHandleSubmit=(e) =>{
        let {route} = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let body ={},global={},receiver={email_configs:[{}]};
                console.log('Received values of form: ', values);
                //let body = values;
                global['stmp_smarthost'] = values.emailServiceAddress;//
                global['smtp_from'] = values.emailSender;
                global['smtp_auth_username'] = values.emailSender;
                global['smtp_auth_password'] = values.emailSenderPassword;
                body['global'] = global;
                let tmpList = [],tmpMap = {};//拼接数据
                // let tmpEmailConfig = values.emailName +'_configs';
                // tmpMap['to'] = values.emailReceiverAddress;
                // receiver[tmpEmailConfig] = tmpMap;
                receiver['name'] = values.emailName;
                receiver['email_configs'][0]['to'] = values.emailReceiverAddress;
                body['receiver'] = receiver;
                route["receiver"] = values.emailName;
                body['route'] = route;
                console.log('body',body)
                //POST 方法第一个参数是与axios中地址的选择
                AxiosObj.HTTP_POST('alarm','/snmpexporter/addReceiver',body,(data)=>{
                    message.success('success');
                    //this.props.history.goBack();
                },(error)=>{
                    message.error(error);
                });
                console.log('finish');
            }
        });
    }
    onTabChange = (key, type) => {
        console.log(key, type);
        this.setState({ [type]: key });
    };
    //邮件测试
    emailTest=(e)=>{
        let {route} = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let body ={};
                console.log('Received values of form: ', values);
                //let body = values;
                body['stmp'] = values.emailServiceAddress;//
                body['port'] = values.emailServiceAddressPort;
                body['from_address'] = values.emailSender;
                //body['smtp_auth_username'] = values.emailSenderUsername;
                body['password'] = values.emailSenderPassword;
                let receivers =[];
                receivers.push(values.emailReceiverAddress);
                body['receivers'] = receivers;
                console.log('body',body)
                //POST 方法第一个参数是与axios中地址的选择
                AxiosObj.HTTP_POST('alarm','/snmpexporter/verifyEmail',body,(data)=>{
                    message.success('success');
                    //this.props.history.goBack();
                },(error)=>{
                    message.error(error);
                });
            }
        });
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        const { receiver } = this.state;

        return(
            <Card title={<span style={{ fontWeight: 600 }}>邮件配置</span>}>
                <Row>
                    <Col span={16}>
                        <Form  onSubmit={this.emailHandleSubmit}>
                            <Form.Item
                                label="名称"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('emailName', {
                                    initialValue: "",
                                    rules: [{
                                        type: 'string', message: '请输入正确字符！',
                                    }, {
                                        pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                    }, {
                                        required: true, message: '请输入字符!',
                                    }],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item
                                label="SMTP服务器地址"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('emailServiceAddress', {
                                    initialValue:  "",
                                    // rules: [{
                                    //     type: 'string', message: '请输入正确字符！',
                                    // }, {
                                    //     pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                    // }, {
                                    //     required: true, message: '请输入字符!',
                                    // }],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item
                                label="SMTP服务器端口"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('emailServiceAddressPort', {
                                    initialValue:  "",
                                    // rules: [{
                                    //     type: 'string', message: '请输入正确字符！',
                                    // }, {
                                    //     pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                    // }, {
                                    //     required: true, message: '请输入字符!',
                                    // }],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item
                                label="发件人邮箱"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('emailSender', {
                                    initialValue:  "",
                                    rules:[{type: 'email',required: true}]
                                })(<Input />)}
                            </Form.Item>
                            {/*<Form.Item*/}
                                {/*label="发件人用户名"*/}
                                {/*{...formItemLayout}*/}
                            {/*>*/}
                                {/*{getFieldDecorator('emailSenderUsername', {*/}
                                    {/*initialValue:  "",*/}
                                    {/*// rules: [{*/}
                                    {/*//     type: 'string', message: '请输入正确字符！',*/}
                                    {/*// }, {*/}
                                    {/*//     pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',*/}
                                    {/*// }, {*/}
                                    {/*//     required: true, message: '请输入字符!',*/}
                                    {/*// }],*/}
                                {/*})(<Input />)}*/}
                            {/*</Form.Item>*/}
                            <Form.Item
                                label="发件人密码"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('emailSenderPassword', {
                                    initialValue:  "",
                                    // rules: [{
                                    //     type: 'string', message: '请输入正确字符！',
                                    // }, {
                                    //     pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                    // }, {
                                    //     required: true, message: '请输入字符!',
                                    // }],
                                })(<Input.Password  />)}
                            </Form.Item>
                            <Form.Item
                                label="收件人邮箱"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('emailReceiverAddress', {
                                    initialValue:  "",
                                    rules:[{type: 'email',required: true}]
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item
                                label=" "
                                colon={false}
                                {...formItemLayout}
                            >
                                <div >
                                    <Button  size="small" style={{marginLeft:112,width: 80}} onClick={this.emailTest}>
                                        测试
                                    </Button>
                                </div>
                            </Form.Item>
                            <Form.Item
                                label=" "
                                colon={false}
                                {...formItemLayout}
                            >
                                <div >
                                    {/*<div>*/}
                                        {/*<Button type="primary" style={{ marginLeft:75 }}>邮件发送测试</Button>*/}
                                    {/*</div>*/}
                                    <Button type="primary" icon="check-circle" htmlType="submit" style={{ marginLeft:75, width: 150 }}>
                                        保存
                                    </Button>
                                    {/*<Button type="danger" icon="close-circle" style={{ width: 150, marginLeft: 10 }}*/}
                                            {/*onClick={() => { this.props.history.goBack(); }}>*/}
                                        {/*取消*/}
                                    {/*</Button>*/}
                                </div>
                            </Form.Item>

                        </Form>

                    </Col>
                    <Col span={8}>
                    </Col>
                </Row>
            </Card>
        )
    }
}

export default Form.create()(EmailForm);