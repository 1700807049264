import React, { memo } from 'react';
import { Modal, Button } from 'antd';
import {
    SubmitButton,
    FormItem,
    Select,
    ResetButton,
    DatePicker,
    Transfer,
    Radio,
    Checkbox,
    TimePicker 
} from "formik-antd"
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik"
import './index.scss';
const formItem = (param) => {
    const { type, name, label, required, disabled = false, placeholder = "", selectOptions = [], selectMode = "", formContent,
        radioOptions, checkboxOptions, changeRadio, endName } = param;
    let formitem = null;
    switch (type) {
        case "input": formitem = <FormikInput disabled={disabled} name={name} placeholder={placeholder} />; break;
        case "password": formitem = <FormikInput.Password disabled={disabled} name={name} placeholder={placeholder} />; break;
        case "select": formitem = <Select disabled={disabled} name={name} placeholder={placeholder} mode={selectMode} >
            {selectOptions.map((item, key) => (<Select.Option key={key} value={item.value}>{item.name}</Select.Option>))}
        </Select>; break;
        case "textArea": formitem = <FormikInput.TextArea disabled={disabled} name={name} spellCheck="false" placeholder={placeholder} />; break;
        case "code": formitem = <FormikInput.TextArea disabled={disabled} name={name} placeholder={placeholder} spellCheck="false" className="code" />; break;
        case "rangePicker": formitem = <DatePicker.RangePicker showTime disabled={disabled} name={name} style={{ width: "100%" }} />; break;
        case "timePicker": formitem = <TimePicker disabled={disabled} name={name} style={{ width: "40%" }} placeholder={placeholder} />; break;
        case "radio": formitem = <Radio.Group disabled={disabled} name={name} options={radioOptions} onChange={(e) => changeRadio(e.target.value)} />; break;
        case "checkbox": formitem = <Checkbox.Group disabled={disabled} name={name} options={checkboxOptions} />; break;
        case "expandForm": formitem = formContent; break;
        case "transfer": formitem = <Transfer
            name={name}
            titles={['数据列表', '已选择列表']}
            showSearch
            listStyle={{
                height: 300,
            }}
            style={{ width: "100%" }}
            operations={['添加', '移除']}
            filterOption={(inputValue, option) => option.title.indexOf(inputValue) > -1}
            dataSource={param.dataSource}
            render={item => item.title}

        />; break;
    }
    return <FormItem
        hasFeedback
        name={name}
        label={label}
        required={required}
        key={name}
    >
        {formitem}
    </FormItem>
}
const AlertForm = memo(
    (props) => {
        const { onSubmit, initialValues, onCancel, formList = [], labelCol = { span: 3 }, wrapperCol = { span: 20 } } = props;
        const nameArray = [];
        const labelArray = [];
        const formItemList = formList.map((item, key) => {
            if(item) {
                if (item.required) {
                    nameArray.push(item.name);
                    labelArray.push(item.label);
                };
                return formItem(item, key)
            }
        })
        return <Modal
            {...props}
        >
            <div className="formModalDiv">
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                        onSubmit(values);
                        actions.setSubmitting(false)
                    }}
                    validateOnBlur={false} // 关闭失去焦点校验，checkbox会同时触发blue和change校验，且blur校验在onchange后，校验结果有问题
                    validate={(values) => {
                        let info = {};
                        nameArray.map((name, key) => {
                            if(values[name] !== 0) {
                                if (!values[name] || values[name].length <= 0) {
                                    info[name] = labelArray[key] + "不能为空!"
                                }
                            }
                        });
                        return info
                    }}
                >
                    {() => {
                        return <FormikForm labelCol={labelCol} wrapperCol={wrapperCol}>
                            {formItemList}
                            <div style={{ textAlign: "center", borderTop: "1px solid #e2e2e2", paddingTop: 10 }}>
                                <SubmitButton disabled={false} icon="save">保存</SubmitButton>&nbsp;
                                {/* <ResetButton disabled={false} icon="load">清空表单</ResetButton>&nbsp; */}
                                <Button icon="close" onClick={() => { onCancel() }}>关闭</Button>
                            </div>
                        </FormikForm>
                    }}
                </Formik>
            </div>
        </Modal>
    }
);
export default AlertForm;
