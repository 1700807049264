import React, { useState, useEffect } from 'react'
import { Card, Collapse, Row, Col } from "antd";
import Utils from '@/utils/utils';
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import ReactEcharts from "echarts-for-react"
import http from '@/axios/axios_utils';
function DataMap(props) {
    useEffect(() => {//componentDidMount

        return () => { //componentWillUnmount
        }
    }, []);


    const getOptionLines = () => {
        // console.log(props.data)
        const text = props.city + props.title + "流量图";
        let time = [];
        let AllData = [];
        if (props.data) {
            time = props.data.time;
            AllData = props.title === "流入" ? props.data.indata : props.data.outdata;
        }
        // const time = [
        //     "16:12:11", "16:12:12", "16:12:13", "16:12:14", "16:12:15", "16:12:16", "16:12:17", "16:12:18", "16:12:19", "16:12:20",
        //     "17:12:11", "17:12:12", "17:12:13", "17:12:14", "17:12:15", "17:12:17", "17:12:17", "17:12:18", "17:12:19", "17:12:20",
        // ];
        // let AllData = [
        //     ["北京", [
        //         120, 132, 101, 134, 90, 230, 210, 11, 22, 33,
        //         120, 132, 101, 134, 90, 230, 210, 11, 22, 33,
        //     ]],
        //     ["西安", [
        //         220, 182, 191, 234, 290, 330, 310, 110, 220, 330,
        //         220, 182, 191, 234, 290, 330, 310, 110, 220, 330,
        //     ]],
        //     ["成都", [
        //         150, 232, 201, 154, 190, 330, 410, 119, 259, 359,
        //         150, 232, 201, 154, 190, 330, 410, 119, 259, 359,
        //     ]],
        //     ["杭州", [
        //         320, 332, 301, 334, 390, 330, 320, 211, 322, 133,
        //         320, 332, 301, 334, 390, 330, 320, 211, 322, 133,
        //     ]],
        //     ["广州", [
        //         820, 932, 901, 934, 1290, 1330, 1320, 1111, 1222, 1333,
        //         820, 932, 901, 934, 1290, 1330, 1320, 1111, 1222, 1333,
        //     ]],
        //     ["上海", [
        //         700, 640, 750, 740, 740, 750, 711, 11, 722, 533,
        //         700, 640, 750, 740, 740, 750, 711, 11, 722, 533,
        //     ]],
        // ]

        const series = AllData.map((item, key) => {
            return {
                data: item[1],
                type: 'line',
                // areaStyle: {
                //     opacity: 0.3
                // },
                name: item[0],
                lineStyle: {
                    width: 1.5
                },
                // smooth: true,//平滑
                showSymbol: false,
                // symbol: 'circle',     //设定为实心点
                // symbolSize: 3,   //设定实心点的大小
                markPoint: {
                    data: [{
                        type: "max"
                    }],
                    symbolSize: 30,
                    // symbol: "circle",
                    // itemStyle: {
                    //     color: "#fff",
                    //     borderColor: "#00FFFF",
                    //     borderWidth: 2
                    // },
                    label: {
                        formatter: function (params, a, b, c) {
                            return parseFloat((params.value / 1048576)).toFixed(2)
                        },
                        textBorderColor: "#000",
                        textBorderWidth: 2,
                    }
                }
            }
        })
        // console.log(series)
        return {
            title: {
                text: text,
                textStyle: {
                    color: '#eee'
                },
                subtext: "(标识为最高值)",
                x: 'center'
            },
            textStyle: {
                color: "#eee"
            },
            tooltip: {
                trigger: 'axis',
                formatter: function (params, a, b, c) {
                    console.log(params)
                    let html = " <div>" + params[0].name;
                    params.map((item) => {
                        html += "<div><div style='background-color: " + item.color
                            + ";width: 15px;height: 15px;border-radius: 50%;float: left;margin-top: 4px;'></div>"
                            + item.seriesName + ":" + parseFloat((item.value / 1048576)).toFixed(2) + "Mbps</div>"
                    })
                    html += "</div>"
                    return html
                }
            },

            legend: {
                data: ["北京", "西安", "成都", "杭州", "广州", "上海",],
                //align: 'left',
                right: 10,
                top: 30,
                textStyle: {
                    color: "#eee",
                },

            },

            // color: [ "#3fb1e3", "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8"],

            //    c4ebad 96dee8

            // color: ["#00BFFF", '#a6c84c', '#ffa022', '#46bee9', '#FF8C00', "#FF6347", "#00FFFF",],
            color: ["#00BFFF", "#FF8C00", '#a6c84c', '#6be6c1', '#a0a7e6', '#96dee8', "#FF6347", "#00FFFF",],
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: time,
                axisLine: { //坐标轴线
                    show: true,
                    lineStyle: {
                        color: "#eee"
                    }
                },
            },
            yAxis: {
                type: 'value',
                name: '流量(Mbps)',
                axisLine: { //坐标轴线
                    show: true,
                    lineStyle: {
                        color: "#eee"
                    },
                },
                axisLabel: {
                    margin: 2,
                    formatter: function (value, index) {
                        // if (value >= 10000 && value < 10000000) {
                        value = parseInt(value / 1048576);
                        // }
                        // else if (value >= 10000000) {
                        //     value = value / 10000000 + "千万";
                        // }
                        return value;
                    }
                },
                axisTick: {   //坐标轴刻度线
                    show: true,
                    lineStyle: {
                        color: "#eee"
                    }
                },
                splitLine: {//坐标轴横线
                    show: false
                }
            },
            series: series
        }
    }

    return <div className={props.className}>

        <ReactEcharts
            option={getOptionLines()}
            style={{ height: 300 }}
        // onChartReady={this.onChartReady}
        // onEvents={onEvents} 
        />
    </div>
}
export default connect()(React.memo(DataMap))
