import React, { useState, useEffect } from 'react'
import {
    Icon, Tooltip, Row, Col,
} from "antd";
import { Chart, Interval, Axis, Coordinate, Legend } from 'bizcharts';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import { ChartCard, MiniArea } from 'ant-design-pro/lib/Charts';
import http from '@/axios/axios_utils';
import { getTimeDistance } from '../utils/index';
import { DigitalFlop } from '@jiaminghi/data-view-react';
import { alarmSortRank, alarmTrend, alarmRecordTotal } from '../utils/api';
import Utils from '@/utils/utils';
import { connect } from 'react-redux'
import moment from 'moment';
import AlertBar from "./alertBar"
import "./index.scss"
import "./ant-design-pro.min.css"
import "animate.css"
// const visitData22 = [];
// const beginDay = new Date().getTime();
// for (let i = 0; i < 20; i += 1) {
//     visitData22.push({
//         x: moment(new Date(beginDay + 1000 * 50 * 50 * 24 * i)).format('YYYY-MM-DD'),
//         y: Math.floor(Math.random() * 100) + 10,
//     });
// }
function Overview(props) {
    const [rangePickerValue, setRPV] = useState(getTimeDistance('week'))
    const [solved, setSolved] = useState(0)
    const [unsolved, setUnsolved] = useState(0)
    const [selctType, setTimeType] = useState('week')
    const [rankingListData, setRankList] = useState([])
    const [visitData, setVisitData] = useState([])
    const [levelTotal, setLevelTotal] = useState([])

    useEffect(() => {//componentDidMount  
        http.setDispatch(null)
        const query = { timeRange: 1 }
        getAllData(query);
        const endTime = Utils.formateDate(new Date().getTime());

        getlevelTotal(endTime);
        getStatusTotal(endTime);
        return () => { //componentWillUnmount
        }
    }, []);
    const isActive = type => {
        // const value = getTimeDistance(type);
        // if (!rangePickerValue[0] || !rangePickerValue[1]) {
        //     return '';
        // }
        // if (//isSame判断日期是否相同
        //     rangePickerValue[0].isSame(value[0], 'day') &&
        //     rangePickerValue[1].isSame(value[1], 'day')
        // ) {
        //     return "currentDate";
        // }
        if (selctType === type) return "currentDate";
        return '';
    };
    const handleRangePickerChange = val => {
        setRPV(val)
        const query = {
            startTime: moment(val[0]).format("YYYY-MM-DD HH:mm:ss"),
            endTime: moment(val[1]).format("YYYY-MM-DD HH:mm:ss")
        }
        getAllData(query);
    };
    const selectDate = type => {
        // const val = getTimeDistance(type)
        // setRPV(val);
        // console.log(val)
        // const query = {
        //     startTime: moment(val[0]).format("YYYY-MM-DD HH:mm:ss"),
        //     endTime: moment(val[1]).format("YYYY-MM-DD HH:mm:ss")
        // }
        let timeRange = 0
        switch (type) {
            case "today": timeRange = 0; break;
            case "week": timeRange = 1; break;
            case "month": timeRange = 2; break;
            case "HalfYear": timeRange = 3; break;
            case "year": timeRange = 4; break;
        }
        setTimeType(type);
        const query = {
            timeRange,
        }
        getAllData(query);
    };
    const alarmSortRankFun = val => {
        http.get(alarmSortRank(), val, (r) => {
            console.log(r)
            setRankList(r.data.records)
        })
    };
    const alarmTrendFun = val => {
        http.get(alarmTrend(), val, (r) => {

            const newdata = r.data.records.map((i) => {
                return { x: i.time, y: i.total }
            })
            setVisitData(newdata)
        })
    };
    async function getStatusTotal(endTime) {
        //未解决记录数
        const data0 = await http.get(alarmRecordTotal(), { status: 0, endTime, userId: sessionStorage.getItem("userId") });
        //已解决记录数
        const data1 = await http.get(alarmRecordTotal(), { status: 1, endTime, userId: sessionStorage.getItem("userId") });
        setUnsolved(data0.data.total)
        setSolved(data1.data.total)
    }
    async function getlevelTotal(endTime) {
        const data0 = await http.get(alarmRecordTotal(), { level: 0, endTime, userId: sessionStorage.getItem("userId") });
        const data1 = await http.get(alarmRecordTotal(), { level: 1, endTime, userId: sessionStorage.getItem("userId") });
        const data2 = await http.get(alarmRecordTotal(), { level: 2, endTime, userId: sessionStorage.getItem("userId") });
        const data3 = await http.get(alarmRecordTotal(), { level: 3, endTime, userId: sessionStorage.getItem("userId") });
        let templist = [];
        templist.push({ x: "信息", y: data0.data.total });
        templist.push({ x: "警告", y: data1.data.total });
        templist.push({ x: "严重", y: data2.data.total });
        templist.push({ x: "灾难", y: data3.data.total });
        console.log(templist)
        setLevelTotal(templist)
    };
    const getAllData = (val) => {
        alarmSortRankFun(val);
        alarmTrendFun(val);

    };
    return <div className="monitorOverview">
        <CustomBreadcrumb arr={["监控告警", "概览"]} />
        <Row gutter={24} className="chartCard animated fadeInUp">
            <Col span={6}>
                <ChartCard title="告警数统计"
                    contentHeight={150}
                    action={
                        <Tooltip title="指标说明">
                            <Icon type="info-circle-o" />
                        </Tooltip>
                    }
                >
                    {/* <MiniArea line height={40} data={salesPieData} /> */}
                    <div className="totalContent" onClick={() => props.history.push("/monitorAlert/alertRecord")}>
                        <div className="totalDiv">总告警数:<span><Icon type="rise" />&nbsp;{unsolved + solved}</span></div>
                        <div className="totalDiv">未解决告警数:<span className="unsolve"><Icon type="warning" />&nbsp;{unsolved}</span></div>
                        <div className="totalDiv">已解决告警数:<span className="solve"><Icon type="check-circle" />&nbsp;{solved}</span></div>
                    </div>
                </ChartCard>
            </Col>
            <Col span={6}>
                <ChartCard title="历史告警形势"
                    action={
                        <Tooltip title="指标说明">
                            <Icon type="info-circle-o" />
                        </Tooltip>
                    }
                    contentHeight={150} >
                    <MiniArea color="#1890ff" data={visitData} />
                </ChartCard>
            </Col>
            <Col span={6} >
                <ChartCard
                    title="告警记录等级统计"
                    action={
                        <Tooltip title="指标说明">
                            <Icon type="info-circle-o" />
                        </Tooltip>
                    }
                    // total={unsolved + ""}
                    // footer={<Field label="数据源统计" value={"6352"} />}
                    contentHeight={150}
                >
                    {/* <MiniBar height={40} data={levelTotal} /> */}
                    < Chart height={150} forceUpdate={true} notCompareData={true} autoFit data={levelTotal}
                        padding={[20, 0, 20, 22]}
                    // interactions = { ['active-region']}
                    ><Legend visible={false} />
                        <Interval position="x*y"
                            color="x"
                            label={['y', (val) => {
                                return {
                                    content: val
                                };
                            }]}
                        />
                    </Chart>
                </ChartCard>
            </Col>
            <Col span={6}>
                <ChartCard
                    className="pieChart"
                    title="告警分类统计图"
                    action={
                        <Tooltip title="指标说明">
                            <Icon type="info-circle-o" />
                        </Tooltip>
                    }
                    total={"  "}
                    // footer={"各告警分类统计"}
                    contentHeight={116}
                >
                    {/* <Pie
                        // hasLegend
                        // title="销售额"
                        // subTitle="销售额"
                        total={() => (getRankListTotal())}
                        data={rankingListData.map((r) => { return { x: r.type, y: r.total } })}
                        // valueFormat={val => <span dangerouslySetInnerHTML={{ __html: yuan(val) }} />}
                        height={110}
                        style={{ padding: '0' }}
                    /> */}

                    < Chart height={150} data={rankingListData.map((r) => { return { item: r.type, percent: r.total } })}
                        // scale={{
                        //     percent: {
                        //         formatter: (val, b, c) => {
                        //             val = val * 100 + '%';
                        //             return val;
                        //         },
                        //     },
                        // }}
                        autoFit interactions={['element-single-selected']}
                        padding={[10, 30, 0, 30]}>
                        <Legend visible={false} />
                        <Coordinate type="theta" radius={0.8} />
                        <Tooltip showTitle={false} />
                        < Axis visible={false} />
                        <Interval
                            position="percent"
                            adjust="stack"
                            color="item"
                            style={{
                                lineWidth: 1,
                                stroke: '#fff',
                                fontSize: 10,
                            }}
                            label={
                                ['*', {
                                    content: (data) => {
                                        // return `${data.item}: ${data.percent * 100}%`;
                                        return `${data.item}: ${data.percent}`;
                                    },
                                }]}
                        />
                    </Chart>

                </ChartCard>
            </Col>
        </Row>
        <p />
        <div
            className="animated fadeInUp"
        >
            <AlertBar
                rangePickerValue={rangePickerValue}
                rankingListData={rankingListData}
                salesData={visitData}
                isActive={isActive}
                handleRangePickerChange={handleRangePickerChange}
                loading={false}
                selectDate={selectDate}
            />
        </div>

    </div>
}

export default connect()(Overview)