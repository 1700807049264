import React, { PureComponent, Fragment } from 'react';
import { Table, Button, Input, message, Popconfirm, Divider, Select } from 'antd';
import isEqual from 'lodash/isEqual';
import styles from './style.scss';

class TableForm extends PureComponent {
  // index = 0;

  cacheOriginData = {};

  constructor(props) {
    super(props);

    this.state = {
      data: props.value,
      loading: false,
      /* eslint-disable-next-line react/no-unused-state */
      value: props.value,
    };
  }

  static getDerivedStateFromProps(nextProps, preState) {
    if (isEqual(nextProps.value, preState.value)) {
      return null;
    }
    return {
      data: nextProps.value,
      value: nextProps.value,
    };
  }

  getRowByKey(key, newData) {
    const { data } = this.state;
    return (newData || data).filter(item => item.key === key)[0];
  }

  toggleEditable = (e, key) => {
    e.preventDefault();
    const { data } = this.state;
    const newData = data.map(item => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      // 进入编辑状态时保存原始数据
      if (!target.editable) {
        this.cacheOriginData[key] = { ...target };
      }
      target.editable = !target.editable;
      this.setState({ data: newData });
    }
  };

  newMember = () => {
    const { data } = this.state;
    const newData = data.map(item => ({ ...item }));
    const dateStr = new Date().getTime();
    newData.push({
      // key: `NEW_ID_${this.index}`,
      key: `NEW_ID_${dateStr}`,
      editable: true,
      required: "true",
      type: "string",
      isNew: true,
    });
    // this.index += 1;
    this.setState({ data: newData });
  };

  remove(key) {
    const { data } = this.state;
    const { onChange } = this.props;
    const newData = data.filter(item => item.key !== key);
    this.setState({ data: newData });
    onChange(newData);
  }

  handleKeyPress(e, key) {
    if (e.key === 'Enter') {
      this.saveRow(e, key);
    }
  }

  handleFieldChange(e, fieldName, key) {
    const { data } = this.state;
    const newData = data.map(item => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      target[fieldName] = e.target.value;
      this.setState({ data: newData });
    }
  }

  saveRow(e, key) {
    e.persist();
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      if (this.clickedCancel) {
        this.clickedCancel = false;
        return;
      }
      const target = this.getRowByKey(key) || {};
      console.log(target);
      if (!target.variable || !target.label
        || !target.type || !target.required
      ) {
        message.error('请填写完整成员信息。');
        e.target.focus();
        this.setState({
          loading: false,
        });
        return;
      }
      delete target.isNew;
      this.toggleEditable(e, key);
      const { data } = this.state;
      const { onChange } = this.props;
      onChange(data);
      this.setState({
        loading: false,
      });
    }, 100);
  }

  cancel(e, key) {
    this.clickedCancel = true;
    e.preventDefault();
    const { data } = this.state;
    const newData = data.map(item => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (this.cacheOriginData[key]) {
      Object.assign(target, this.cacheOriginData[key]);
      delete this.cacheOriginData[key];
    }
    target.editable = false;
    this.setState({ data: newData });
    this.clickedCancel = false;
  }

  render() {
    const columns = [
      {
        title: '参数名称',
        dataIndex: 'label',
        key: 'label',
        ellipsis: true,
        width: '16%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={e => this.handleFieldChange(e, 'label', record.key)}
                onKeyPress={e => this.handleKeyPress(e, record.key)}
                placeholder="参数名称"
              />
            );
          }
          return text;
        },
      },
      {
        title: '参数ID',
        dataIndex: 'variable',
        ellipsis: true,
        key: 'variable',
        width: '15%',
        render: (text, record) => {
          if (record.editable) {
            console.log(record);
            return (
              <Input
                value={text}
                autoFocus
                onChange={e => this.handleFieldChange(e, 'variable', record.key)}
                onKeyPress={e => this.handleKeyPress(e, record.key)}
                placeholder="参数键名称"
              />
            );
          }
          return text;
        },
      },
      {
        title: '描述',
        dataIndex: 'description',
        ellipsis: true,
        key: 'description',
        width: '15%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={e => this.handleFieldChange(e, 'description', record.key)}
                onKeyPress={e => this.handleKeyPress(e, record.key)}
                placeholder="参数描述"
              />
            );
          }
          return text;
        },
      },
      {
        title: '类型',
        dataIndex: 'type',
        ellipsis: true,
        key: 'type',
        width: '16%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Select
                value={text}
                onChange={e => this.handleFieldChange({ target: { value: e } }, 'type', record.key)}
              >
                <Select.Option value={"string"}>string</Select.Option>
                <Select.Option value={"int"}>int</Select.Option>
                <Select.Option value={"json"}>json</Select.Option>
                <Select.Option value={"object"}>object</Select.Option>
                <Select.Option value={"arrary"}>arrary</Select.Option>
                <Select.Option value={"float"}>float</Select.Option>
                <Select.Option value={"double"}>double</Select.Option>
                <Select.Option value={"boolean"}>boolean</Select.Option>
              </Select>
            );
          }
          return text;
        },
      },
      {
        title: '是否必填',
        dataIndex: 'required',
        key: 'required',
        ellipsis: true,
        width: '10%',
        render: (text, record) => {
          if (record.editable) {
            return (
              // <Input
              //   value={text}
              //   onChange={e => this.handleFieldChange(e, 'required', record.key)}
              //   onKeyPress={e => this.handleKeyPress(e, record.key)}
              //   placeholder="true或者false"
              // />
              <Select
                value={text}
                onChange={e => this.handleFieldChange({ target: { value: e } }, 'required', record.key)}
              >
                <Select.Option value={"true"}>是</Select.Option>
                <Select.Option value={"false"}>否</Select.Option>
              </Select>
            );
          }
          return text === "true" ? "是" : "否";
        },
      },
      {
        title: '默认值',
        dataIndex: 'value',
        ellipsis: true,
        key: 'value',
        width: '14%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={e => this.handleFieldChange(e, 'value', record.key)}
                onKeyPress={e => this.handleKeyPress(e, record.key)}
                placeholder="默认值"
              />
            );
          }
          return text;
        },
      },
      {
        title: '操作',
        key: 'action',
        width: 120,
        render: (text, record) => {
          const { loading } = this.state;
          if (!!record.editable && loading) {
            return null;
          }
          if (record.editable) {
            if (record.isNew) {
              return (
                <span>
                  <a onClick={e => this.saveRow(e, record.key)}>添加</a>
                  <Divider type="vertical" />
                  <Popconfirm title="是否要删除此行？" onConfirm={() => this.remove(record.key)}>
                    <a>删除</a>
                  </Popconfirm>
                </span>
              );
            }
            return (
              <span>
                <a onClick={e => this.saveRow(e, record.key)}>保存</a>
                <Divider type="vertical" />
                <a onClick={e => this.cancel(e, record.key)}>取消</a>
              </span>
            );
          }
          return (
            <span>
              <a onClick={e => this.toggleEditable(e, record.key)}>编辑</a>
              <Divider type="vertical" />
              <Popconfirm title="是否要删除此行？" onConfirm={() => this.remove(record.key)}>
                <a>删除</a>
              </Popconfirm>
            </span>
          );
        },
      },
    ];

    const { loading, data } = this.state;

    return (
      <Fragment >
        <div className="tableformDiv">
          <Table
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={false}
            rowClassName={record => (record.editable ? styles.editable : '')}
            size="small"
          />
          <Button
            style={{ width: '100%', marginTop: 10, marginBottom: 0 }}
            type="dashed"
            onClick={this.newMember}
            icon="plus"
          >
            新增参数
        </Button>
        </div>

      </Fragment>
    );
  }
}

export default TableForm;
