import React from 'react'
import './index.scss'
import { Card, Table, Input, Typography, Tree, notification, Button, Icon, message, Modal, List, } from "antd";
import DataSet from "@antv/data-set";
import Socket from "@/socket/index";
import OverlapHistogram from "@/components/OverlapHistogram/index";
// import FireWall from "./fireWall";
import Port from "./port";
import FindDevice from "./findDevice";
const { Title } = Typography;
const { TreeNode } = Tree;
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Legend
} from "bizcharts";
export default class OverView extends React.Component {
    state = {
        tableData: [],
        treeData: [],
        Resources: [],
        selectList: [],
        CheckParamKey: [],
        visible_report: false,
        visible_port: false,
        visible_monitor: false,
        visible_param: false,
        visible_find: false,
        visible_IpConfig: false,
        progressObj: {},
        deviceIp: '',
        dataList: [],
        httpSrc: '',
        _current: 1,    //当前页码数
        _pageSize: 10,    //当前每页显示条数
        interfaceData: [],    //端口数据
        taskTotal: 0,
        resTotal: 0,
        loading: false,
        oidLoading: false,
    };
    componentWillMount() {
        this.props.onRef(this);//将this传给父组件
        let { taskId, } = this.props;
        this.setState({
            taskId
        });
    }
    updataCharData = (taskId) => {
        this.setState({ oidLoading: true });
        Socket.Action('OidFirstPage',
            { "taskId": taskId }, (result) => {
                if (result.retcode === '00') {
                    let data = result.data;
                    let dataList = [];
                    data.ipData.map((item) => {
                        // console.log(item.chartData);
                        dataList.push({
                            title: item.deviceIp + '-' + item.deviceFirm + '-' + item.deviceType + '-' + item.deviceVersion + '-' + item.deviceOsVersion,
                            deviceIp: item.deviceIp,
                            data: item.chartData
                        })
                    });
                    let temp = data.ipList;
                    let selectList = temp.map((item) => {
                        if (item.selected === 1) {
                            return item;
                        }
                    });
                    this.setState({ dataList, OidFirstPage: data, selectList, oidLoading: false })
                } else {
                    notification.open({
                        message: '失败',
                        description: result.error,
                        icon: <Icon type="frown" style={{ color: 'red' }} />,
                    });
                }
            });
    };

    createChar = (data) => {
        // console.log(data);
        const ds3 = new DataSet();
        const dv3 = ds3.createView().source(data);
        let newArr = [];
        for (let filed in data[0]) {
            if (filed != 'name') {
                newArr.push(filed);
            }
        }
        dv3.transform({
            type: "fold",
            fields: newArr,
            // 展开字段集
            key: "时间",
            // key字段
            value: "百分比" // value字段
        });
        return <Chart height={250} data={dv3}
            forceFit>

            <Axis name="时间" />
            <Axis name="百分比" />
            <Legend />
            <Tooltip
                crosshairs={{
                    type: "y"
                }}
            />
            <Geom
                type="interval"
                position="时间*百分比"
                // color={["name",['#3aa1ff','#ff9900','#ff5722']]}
                color={["name", ['#3aa1ff', '#ff9900', '#ff5722']]}
                adjust={[
                    {
                        type: "dodge",
                        marginRatio: 1 / 32
                    }
                ]}
            />
        </Chart>
    };
    expandedParamRender = (dataSource) => {
        let { selectportKey } = this.state;
        return <div style={{ textAlign: 'center', border: '1px solid #e2e2e2' }}>
            <Title level={3} style={{ borderBottom: '1px solid #e2e2e2', }}>列表</Title>
            <Table
                rowSelection={{
                    onChange: (selectedRowKeys, selectedRows) => {
                        this.setState({ selectport: selectedRows, selectportKey: selectedRowKeys })
                    },
                    selectedRowKeys: selectportKey
                }}
                columns={[{ title: '名称', dataIndex: 'name' }]}
                dataSource={dataSource}
                pagination={false}
            />
        </div>
    };
    SetDeviceIp = () => {
        let { selectList } = this.state;
        let { taskId } = this.props;
        let body = { taskId: taskId, ipLists: selectList };
        Socket.Action("SetDeviceIp",
            body, (result) => {
                if (result.retcode === '00') {
                    message.success('设置成功');
                    this.updataCharData(taskId);
                }
            });
        this.setState({ visible_IpConfig: false, });
    };
    SetChartParams = () => {
        let { deviceIp, paramList, } = this.state;
        let { taskId, } = this.props;
        let body = { taskId: taskId, deviceIp: deviceIp, paramList: this.getRowFun(paramList) };
        Socket.Action("SetChartParams",
            body, (result) => {
                if (result.retcode === '00') {
                    message.success('设置成功');
                    this.updataCharData(taskId);
                    this.setState({ visible_param: false, });
                }
            });
    };
    getRowFun = (List) => {
        let { CheckParamKey } = this.state;
        let tempArr = [];
        List.map((item) => {
            if (CheckParamKey.includes(item.name)) {
                tempArr.push(item);
            } else {
                let dataChild = item.data;
                let dataItem = item;
                if (dataChild && dataChild != '') {
                    dataItem.data = this.getRowFun(dataChild);
                    tempArr.push(dataItem);
                }
            }
        });
        return tempArr;
    };
    renderTreeNodes = data => data.map((item) => {
        if (item.children) {
            return (
                <TreeNode title={item.title} key={item.key} dataRef={item}>
                    {this.renderTreeNodes(item.children)}
                </TreeNode>
            );
        }
        return <TreeNode {...item} />;
    })
    getTreeData = (paramList) => {
        let itemTree = {};
        let treeData = [];
        let CheckParamKey = this.state.CheckParamKey;
        paramList.map((item) => {
            if (item.selected === 1) {
                CheckParamKey.push(item.name);
            }
            itemTree = {
                title: item.display,
                key: item.name
            };
            if (item.data && item.data != '') {
                itemTree['children'] = this.getTreeData(item.data)
            }
            treeData.push(itemTree)
        });
        this.setState({ CheckParamKey });
        return treeData;
    };
    render() {
        let { dataList, visible_IpConfig, visible_param, visible_port,
            deviceIp
        } = this.state;
        let { visible_monitor, visible_report,
            visible_find, taskId } = this.props;
        return (
            <div>
                <Modal
                    title="预览报告"
                    visible={visible_report}
                    onCancel={() => { this.props.setVisible('visible_report'); }}
                    footer={null}
                    width="98%"
                    style={{ position: 'fixed', top: 15, left: 15 }}
                >
                    <iframe name="iframe_report"
                        style={{ width: '100%', height: 'calc(100vh - 120px)' }}
                    ></iframe>
                </Modal>
                <Modal
                    title={<div>监控数据
                        <Button type="primary" icon="setting" style={{ marginLeft: 15 }} onClick={
                            () => this.setState({ visible_IpConfig: true, })
                        }>配置IP</Button>
                    </div>}
                    visible={visible_monitor}
                    onCancel={() => { this.props.setVisible('visible_monitor'); }}
                    footer={null}
                    width="95%"
                    style={{ position: 'fixed', top: 25, left: 35, }}
                >

                    <div className="monitorDiv" >
                        <List
                            grid={{ gutter: 10, column: 2 }}
                            dataSource={dataList}
                            loading={this.state.oidLoading}
                            renderItem={(item, key) => {
                                // console.log(item)
                                return (<List.Item>
                                    <Card title={item.title} extra={<div><Button icon="cluster" onClick={() => {
                                        this.setState({
                                            deviceIp: item.deviceIp,
                                            visible_port: true,
                                        });
                                    }} >查看端口</Button>
                                        <Button icon="switcher" onClick={() => {
                                            let obj = this.state.OidFirstPage;
                                            let paramList = (obj.ipData)[key]['paramList'];

                                            let treeData = this.getTreeData(paramList);

                                            this.setState({
                                                paramList, deviceIp: (obj.ipData)[key].deviceIp,
                                                treeData, visible_param: true,
                                            });
                                        }}>参数配置</Button></div>
                                    }>
                                        {/*{this.createChar(item.data)}*/}
                                        <OverlapHistogram chartData={item.data} height={250} />
                                    </Card>
                                </List.Item>
                                )
                            }}
                        />
                    </div>
                </Modal>
                <Modal
                    title="端口信息"
                    visible={visible_port}
                    onCancel={() => { this.setState({ visible_port: false, }); }}
                    footer={null}
                    width="95%"
                    zIndex={1900}
                    destroyOnClose={true}
                    style={{ position: 'fixed', top: 25, left: 35, }}
                >
                    <div className="monitorDiv" >
                        <Port taskId={taskId} deviceIp={deviceIp} />
                    </div>
                </Modal>
                <Modal
                    title="配置IP"
                    visible={visible_IpConfig}
                    onCancel={() => { this.setState({ visible_IpConfig: false, }); }}
                    onOk={() => { this.SetDeviceIp() }}
                >
                    <Table
                        columns={[{ title: '设备IP', dataIndex: 'deviceIp' }]}
                        rowSelection={{
                            onChange: (selectedRowKeys, selectedRows) => {
                                this.setState({ selectList: selectedRows })
                            },
                            getCheckboxProps(record) {
                                return {
                                    defaultChecked: record.selected === 1,  // 配置勾选的列
                                };
                            },
                        }}
                        dataSource={this.state.OidFirstPage ? this.state.OidFirstPage.ipList : []}
                        size="small"
                        pagination={false}
                    />
                </Modal>
                <Modal
                    title="配置参数"
                    visible={visible_param}
                    onCancel={() => { this.setState({ visible_param: false, }); }}
                    onOk={() => { this.SetChartParams() }}

                >
                    <div style={{ maxHeight: 400, overflowY: 'auto' }}>
                        {/*<Table*/}
                        {/*columns={[{title:'参数名称',dataIndex:'display'}]}*/}
                        {/*rowSelection={{*/}
                        {/*onChange: (selectedRowKeys, selectedRows) => {*/}
                        {/*this.setState({*/}
                        {/*CheckParamKey:selectedRowKeys,*/}
                        {/*CheckParam:selectedRows,*/}
                        {/*})*/}
                        {/*},*/}
                        {/*selectedRowKeys:this.state.CheckParamKey,*/}
                        {/*}}*/}
                        {/*dataSource={this.state.paramList?this.state.paramList:[]}*/}
                        {/*size="small"*/}
                        {/*expandedRowRender={(record, index, indent, expanded)=>{*/}
                        {/*if(record.name==='speed'){*/}
                        {/*return this.expandedParamRender(record.data);*/}
                        {/*}*/}
                        {/*}}*/}
                        {/*pagination={false}*/}
                        {/*/>*/}
                        <Tree
                            checkable
                            checkedKeys={this.state.CheckParamKey}
                            onCheck={(CheckParamKey) => {
                                // console.log('onSelect', info);
                                this.setState({ CheckParamKey });
                            }}
                        >
                            {this.renderTreeNodes(this.state.treeData)}
                        </Tree>

                    </div>
                </Modal>
                <Modal
                    title="设备发现"
                    visible={visible_find}
                    onCancel={() => { this.props.setVisible('visible_find') }}
                    footer={null}
                    width="95%"
                    style={{ position: 'fixed', top: 25, left: 35, }}
                >
                    <FindDevice taskId={taskId} />
                </Modal>
            </div>
        );
    }
}