import React from 'react'
import { List, Button } from "antd";

export default class CollapseList extends React.Component{
    state = {
        listData: [],
        listItem: {}
    };
    componentWillMount() {
        this.dealListData();
    }
    lookMore(key, keyName) {
        let { tableData, completeTableData } = this.props;
        let { listItem, listData } = this.state;
        tableData[key].omitFlag[keyName] = false;
        tableData[key][keyName] = completeTableData[key][keyName];
        listItem = tableData[key];
        listData = tableData[key][keyName];
        this.props.updateData(tableData);
        this.setState({ listItem, listData })
        // console.log("lookMore", tableData[key][keyName], listData, completeTableData[key])
    }
    abbreviationData(key, keyName) {
        let { tableData, completeTableData } = this.props;
        let { listItem, listData } = this.state;
        tableData[key].omitFlag[keyName] = true;
        tableData[key][keyName] = completeTableData[key][keyName].slice(0,3);
        listItem = tableData[key];
        listData = tableData[key][keyName];
        this.props.updateData(tableData);
        this.setState({ listItem, listData })
        // console.log("abbreviationData", tableData[key][keyName], listData, completeTableData[key])
    }
    dealListData() {
        let { listData, listItem, keyName, tableData, listIndex } = this.props;
        if(listData) {
            listItem = {
                ...listItem,
                omitFlag: { [keyName]: listData.length <= 3 ? false : true }
            }
            listData = listData.length <= 3 ? listData : listData.slice(0, 3);
            tableData[listIndex] = listItem;
            this.props.updateData(tableData);
            this.setState({ listData, listItem })
        }
    }

    render(){
        let { listIndex, keyName } = this.props;
        let { listData, listItem } = this.state;
        return (
            <List
                size="small"
                dataSource={listData}
                renderItem={item => <List.Item style={{ textAlign: 'center', border: 'none', padding: 0, display: 'block' }}>{item}</List.Item>}
                loadMore={listItem.omitFlag[keyName] ? <Button type='link' size='small' onClick={() => {
                    this.lookMore(listIndex, keyName);
                }}>查看更多</Button> : (listData.length > 3 ? <Button type='link' size='small' onClick={() => {
                    this.abbreviationData(listIndex, keyName);
                }}>收起全部</Button> : '')}
            />
        );
    }
}