import React from 'react'
import { Card, Form, Input, message, Icon, Select, Button, Spin, Row, Col, Table, Modal, Descriptions, Divider } from 'antd';
import './index.scss'
import Utils from "../../../utils/utils";
import axios from '@/axios/axios_utils';
import CustomBreadcrumb from "../../../components/CustomBreadcrumb/index";
const resource_Url = "http://" + backup_address + "/ZJNMS_V1/resource/resource";
let timer = null;
class resourceDetail extends React.Component {
    state = {
        pageSpinning: false,
        cardTitle: "设备名",
        resourceIp: '',
        resourceInfo: {},
        sshPwdShow: false,
        sshEnablePwdShow: false,
        communityShow: false,
        isUpdating: false,
    };
    componentWillMount() {//组件渲染之前调用
        let resourceItem = this.props.location.item || {};
        let deviceIp = resourceItem.resourceParams ? resourceItem.resourceParams.deviceIp : ''
        this.setState({
            cardTitle: resourceItem.resourceName,
            resourceIp: deviceIp
        })
        if(deviceIp) {
            this.getDevicesDetails(deviceIp);
        } else {
            this.goBackPage();
        }
    }
    componentWillUnmount() {
        this.clearTimer();
        message.destroy();
    }
    clearTimer() {
        if (timer != null) {
            clearInterval(timer);
        }
    }
    timerControl() {
        let { resourceIp } = this.state;
        let _this = this;
        if (timer) {
            clearInterval(timer);
        }
        timer = setInterval(() => {
            _this.getDevicesDetails(resourceIp, 'noLoading')
        }, 4000)
    }
    translateType(deviceType) {
        switch (deviceType) {
            case "交换机": return "switch";
            case "路由器": return "router";
            case "防火墙": return "firewall";
            case "负载均衡": return "loadBalance";
            case "其他": return "其他";
        }
    }
    goBackPage() {
        let bodyJson = this.props.location.bodyJson;
        let path = {
            pathname: '/resource',
            bodyJson,
        };
        this.props.history.push(path);
    }
    getDevicesDetails(deviceIp, loadingFlag) {
        if (loadingFlag !== "noLoading") {
            this.setState({ pageSpinning: true, });
        }
        axios.get(resource_Url, { resource_ip: deviceIp }, (result) => {
            if (result.code === "00") {
                let data = result.data.resource_data;
                let ports = data.ports ? data.ports.map(i => {
                    return {
                        ...i,
                        deviceFirm: data.resourceParams.deviceFirm,
                        deviceVersion: data.resourceParams.deviceVersion,
                        deviceIp: data.resourceParams.deviceIp
                    }
                }) : []
                data.ports = [...ports]
                // 隐藏密码
                data.resourceParams.hidePwd = new Array(data.resourceParams.devicePwd.length).join('*');
                data.resourceParams.hideEnablePwd = new Array(data.resourceParams.deviceEnablePwd.length).join('*');
                data.resourceParams.hideCommunity = new Array(data.resourceParams.community.length).join('*');
                this.setState({ resourceInfo: data, pageSpinning: false })

                if (result.data.is_updating) {
                    this.timerControl();
                } else {
                    if(this.state.isUpdating) {
                        message.destroy();
                        message.success("更新成功");
                    }
                    this.clearTimer();
                }
            } else {
                message.error("查询失败");
                this.setState({ pageSpinning: false });
            }
        })
    }
    updatePortList() {
        let { resourceInfo, resourceIp } = this.state;
        axios.post(resource_Url, { resources: [resourceInfo] }, (result) => {
            if (result.code === "00") {
                console.log("updatePortList", result)
                // message.success("更新成功");
                message.loading("更新中...", 0)
                this.setState({ isUpdating: true });
                setTimeout(() => {
                    this.getDevicesDetails(resourceIp, 'noLoading');
                }, 2000)
            } else {
                message.error("更新失败");
            }
        })
    }
    showPwd(type) {
        let { sshPwdShow, sshEnablePwdShow, communityShow } = this.state;
        switch(type) {
            case "pwd": this.setState({ sshPwdShow: !sshPwdShow }); break;
            case "enablePwd": this.setState({ sshEnablePwdShow: !sshEnablePwdShow }); break;
            case "community": this.setState({ communityShow: !communityShow }); break;
        }
    }
    render() {
        let { cardTitle, pageSpinning, resourceInfo, sshPwdShow, sshEnablePwdShow, communityShow } = this.state;
        let columns =[
            { title: "序号", width: 50, render: (a, b, c) => { return c + 1 } },
            { title: "端口名称", width: 100, ellipsis: true, dataIndex: "ifDesc" },
            { title: "端口状态", width: 80, ellipsis: true, dataIndex: "status" },
            { title: "端口索引", width: 80, ellipsis: true, dataIndex: "ifIndex" },
            { title: "工作模式", width: 80, ellipsis: true, dataIndex: "admin_mode" },
            { title: "端口MAC地址", width: 120, ellipsis: true, dataIndex: "mac" },
            { title: "vlan", width: 80, ellipsis: true, dataIndex: "vlan" },
            { title: "设备厂商", width: 120, ellipsis: true, dataIndex: "deviceFirm" },
            { title: "设备型号", width: 120, ellipsis: true, dataIndex: "deviceVersion" },
            { title: "所属设备IP", width: 120, ellipsis: true, dataIndex: "deviceIp" },
            { title: "端口配置", width: 120, ellipsis: true, dataIndex: "config" },
            // { title: "变更时间", dataIndex: "time", sorter: (a, b) => new Date(a.time) > new Date(b.time), },
        ]
        return (
            <div className='resourceDetailContainer'>
                <Spin spinning={pageSpinning}>
                    <CustomBreadcrumb arr={["资源管理", { title: "设备列表", to: '/resource' }, "资源详情"]} />
                    {resourceInfo.resourceParams?<Card title={Utils.BoldFont(cardTitle + "-设备信息")} extra={
                        <div>
                            <Button type="primary" icon="rollback" onClick={() => { this.goBackPage(); }}>返回</Button>&nbsp;
                        </div>
                    }>
                        <div className='deviceInfoDescriptions'>
                            <Descriptions title="设备属性">
                                <Descriptions.Item label="设备名称">{resourceInfo.resourceParams.deviceId}</Descriptions.Item>
                                <Descriptions.Item label="设备IP地址">{resourceInfo.resourceParams.deviceIp}</Descriptions.Item>
                                <Descriptions.Item label="设备状态">{resourceInfo.resourceParams.deviceState === 'online' ? '在线' : '离线'}</Descriptions.Item>
                                <Descriptions.Item label="设备厂商">{Utils.Translation(resourceInfo.resourceParams.deviceFirm.toUpperCase())}</Descriptions.Item>
                                <Descriptions.Item label="设备类型">{Utils.Translation(resourceInfo.resourceParams.deviceType.toUpperCase())}</Descriptions.Item>
                                <Descriptions.Item label="设备型号">{resourceInfo.resourceParams.deviceVersion}</Descriptions.Item>
                                <Descriptions.Item label="设备OS版本" className='overEllipsis'>
                                    {Utils.hidenLongText(resourceInfo.resourceParams.deviceOsVersion, 45)}
                                </Descriptions.Item>
                                <Descriptions.Item label="设备序列号">{resourceInfo.resourceParams.deviceIp}</Descriptions.Item>
                                <Descriptions.Item label="设备OID">{resourceInfo.resourceParams.deviceIp}</Descriptions.Item>
                            </Descriptions>
                        </div>
                        <Divider />
                        <div className='deviceInfoDescriptions'>
                            <Descriptions title="管理属性">
                                <Descriptions.Item label="资源名称">{resourceInfo.resourceName}</Descriptions.Item>
                                <Descriptions.Item label="资源类型">{Utils.Translation(resourceInfo.resourceType.toUpperCase())}</Descriptions.Item>
                                <Descriptions.Item label="组织区域">{resourceInfo.resourceParams.deviceSite}</Descriptions.Item>
                                <Descriptions.Item label="标签" className='devicesTagsDes'>{Utils.hidenTag(resourceInfo.tags)}</Descriptions.Item>
                                <Descriptions.Item label="资源描述" className='overEllipsis'>
                                    {Utils.hidenLongText(resourceInfo.resourceDes, 45)}
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                        <Divider />
                        <div className='deviceInfoDescriptions'>
                            <Descriptions title="认证属性">
                                <Descriptions.Item label="SSH用户名">{resourceInfo.resourceParams.deviceUser}</Descriptions.Item>
                                <Descriptions.Item label="SSH密码">
                                    <span style={{ marginRight: 10 }}>{sshPwdShow ? resourceInfo.resourceParams.devicePwd : resourceInfo.resourceParams.hidePwd }</span>
                                    {resourceInfo.resourceParams.devicePwd.length > 0 ?
                                        sshPwdShow ? <Icon onClick={() => this.showPwd('pwd')} type="eye" /> :
                                        <Icon onClick={() => this.showPwd('pwd')} type="eye-invisible" />
                                    : ''}
                                </Descriptions.Item>
                                <Descriptions.Item label="特权密码">
                                    <span style={{ marginRight: 10 }}>{sshEnablePwdShow ? resourceInfo.resourceParams.deviceEnablePwd : resourceInfo.resourceParams.hideEnablePwd}</span>
                                    {resourceInfo.resourceParams.deviceEnablePwd.length > 0 ? 
                                        sshEnablePwdShow ? <Icon onClick={() => this.showPwd('enablePwd')} type="eye" /> :
                                        <Icon onClick={() => this.showPwd('enablePwd')} type="eye-invisible" />
                                    : ''}
                                </Descriptions.Item>
                                <Descriptions.Item label="SSH连接端口">{resourceInfo.resourceParams.sshport}</Descriptions.Item>
                                <Descriptions.Item label="SNMP口令">
                                    <span style={{ marginRight: 10 }}>{communityShow ? resourceInfo.resourceParams.community : resourceInfo.resourceParams.hideCommunity}</span>
                                    {resourceInfo.resourceParams.community.length > 0 ? 
                                        communityShow ? <Icon onClick={() => this.showPwd('community')} type="eye" /> :
                                        <Icon onClick={() => this.showPwd('community')} type="eye-invisible" />
                                    : ''}
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    </Card>:null}<br />
                    <Card id="resrecord" title={
                        <>
                            <span>{Utils.BoldFont(`端口列表(${resourceInfo.ports? resourceInfo.ports.length : 0})`)}</span>
                            <Button icon="sync" size='small' style={{ marginLeft: 8 }} onClick={() => this.updatePortList()}></Button>
                        </>
                    } >
                            <Table
                                columns={columns}
                                rowKey={r => r.ifIndex}
                                dataSource={resourceInfo.ports? resourceInfo.ports : []}
                                size="small"
                                pagination={false}
                                scroll={{ y: 600 }}
                            />
                        </Card>
                </Spin>
            </div>
        );
    }
}

export default resourceDetail;