import React, { useEffect, useState } from 'react'
import ReactECharts from "echarts-for-react"
import { Cascader, Row, Col, message } from "antd"
import * as echarts from 'echarts';
import http from '@/axios/axios_utils';
import { if_flow_rate_change, interfaces } from "@/pages/monitorAlert/dataCollection/utils/api"
import './index.scss'
function lineMap(props) {
    {/* <lineMap data={[{ name: "成功", value: 40 }, { name: "失败", value: 10 }, { name: "异常", value: 4 }]} /> */ }
    const [echertData, setEchertData] = useState({})
    const [CascaderData, setCascaderData] = useState([])
    const [CascaderVale, setCascaderVale] = useState([])
    const [tempInter, setTempInter] = useState(null)
    useEffect(() => {//componentDidMount
        getFirmIpPortData()
        return () => { //componentWillUnmount
            if (tempInter) {
                clearInterval(tempInter)
            }
        }
    }, []);

    function runInterval(ip, port) {
        console.log(ip, port,)
        console.log(tempInter)
        if (tempInter) {
            clearInterval(tempInter)
        }
        setTempInter(setInterval(() => {
            gethttpData(ip, port)
        }, props.timer || 60000))
    }

    function gethttpData(ip, port, last_time = 60 * 30, step = 15) {
        /**
         * // 参数名称	是否必须	示例	备注
          // ip 设备ip
          // port 接口
          // last_time 300 最近时间的，300指的是300秒，默认5min
          // step	 15 间隔时间，15指的是15秒
         */
        http.get(if_flow_rate_change(), { ip, port, last_time, step }, (r) => {
            handleData(r.data)
        })
    }
    function getFirmIpPortData() {
        http.get(interfaces(), { user_id: sessionStorage.getItem("userId") }, (r) => {
            handleFrimIpPortData(r.data || {})
        })
    }
    function handleFrimIpPortData(h_Data) {
        if (!h_Data || JSON.stringify(h_Data) === "{}") {
            message.error("数据为空！")
            return;
        }
        let t_CascaderData = []
        //获取第一个数据
        for (let frim in h_Data) {
            let temp_c_data = []
            const ip_inter = h_Data[frim]
            for (let ip in ip_inter) {
                const inter_list = ip_inter[ip]
                const c_inter_data = inter_list.map((i) => ({ "label": i, "value": i }))
                let f_data_child = {
                    "label": ip,
                    "value": ip,
                    children: c_inter_data
                }
                temp_c_data.push(f_data_child)
            }
            let f_data = {
                "label": frim,
                "value": frim,
                children: temp_c_data
            }
            t_CascaderData.push(f_data)
        }
        setCascaderData(t_CascaderData)
        //获取设置第一个数据
        let firms = Object.keys(h_Data)
        let ip_inters = Object.values(h_Data)
        let ips = Object.keys(ip_inters[0])
        let inters = Object.values(ip_inters[0])
        setCascaderVale([firms[0], ips[0], inters[0][0]])
        //更新图形数据
        gethttpData(ips[0], inters[0][0])
        //启动定时器
        runInterval(ips[0], inters[0][0])
    }
    function handleData(init_data) {
        const tempIn = init_data.in || {}
        const tempOut = init_data.out || {}
        const tempInValue = tempIn.values || []
        const tempOutValue = tempOut.values || []
        const i_Indata = []
        const i_xData = tempInValue.map((i) => {
            i_Indata.push(i[1])
            return i[0]
        })
        const i_Outdata = tempOutValue.map((i) => {
            return 0 - parseFloat(i[1])
        })
        //最大最小参数
        const min_max_data = {
            in: {
                "min": tempIn.min,
                "max": tempIn.max,
                "avg": tempIn.avg,
                "current": tempIn.current
            },
            out: {
                "min": tempOut.min,
                "max": tempOut.max,
                "avg": tempOut.avg,
                "current": tempOut.current
            },
        }
        setEchertData({
            min_max_data,
            xData: i_xData,
            inData: i_Indata,
            outData: i_Outdata,
        })
    }
    function getOption(charData) {
        const xData = charData.xData || []
        const inData = charData.inData || []
        const outData = charData.outData || []
        const min_max_data = charData.min_max_data || { in: {}, out: {} }
        const option = {
            // title: {
            //     text: '设备端口流量图'
            // },
            tooltip: {
                trigger: 'axis'
            },
            color: ["#3fb1e3", "#6be6c1", "#a0a7e6", "#626c91", "#F4664A", "#c4ebad", "#96dee8"],
            legend: {
                data: ['入口流量', '出口流量'],
                textStyle: {
                    color: "#7b7b7b",
                },
                formatter: function (name) {
                    if (name === "入口流量") {
                        return `入口流量B/S max:${min_max_data.in.max} min:${min_max_data.in.min} avg:${min_max_data.in.avg} current:${min_max_data.in.current}`
                    } else {
                        return `出口流量B/S max:${min_max_data.out.max} min:${min_max_data.out.min} avg:${min_max_data.out.avg} current:${min_max_data.out.current}`
                    }
                },
                orient: "vertical",
                right: 0
            },
            // toolbox: {
            //     feature: {
            //         saveAsImage: {}
            //     }
            // },
            grid: {
                // top: '3%',
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    data: xData,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#7b7b7b",
                        }
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#7b7b7b",
                        }
                    },
                    splitLine: { lineStyle: { color: "rgba(91, 91, 91, .35)" } },
                    axisLabel: {
                        formatter: '{value} B/S'
                    },
                }
            ],
            series: [
                {
                    name: '入口流量',
                    type: 'line',
                    stack: 'in',
                    symbol: 'none',
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: '#3fb1e3'
                            // color: '#8ec6ad'
                        }, {
                            offset: 1,
                            // color: '#6be6c1'
                            color: '#0b1741'
                        }])
                    },
                    itemStyle: {
                        color: '#3fb1e3'
                    },
                    data: inData
                },
                {
                    name: '出口流量',
                    type: 'line',
                    stack: 'out',
                    symbol: 'none',
                    data: outData,
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 1,
                            color: '#6be6c1'
                        }, {
                            offset: 0,
                            color: '#0b1741'
                        }])
                    },
                    itemStyle: {
                        color: '#6be6c1'
                    },
                }
            ]
        };
        return option
    }
    function onChange(data) {
        if (data.length == 0) {
            setCascaderVale([])
            setEchertData({})
            return
        }
        if (data.length != 3) {
            message.info("请选择完整的数据！")
            return
        }
        setCascaderVale(data)
        gethttpData(data[1], data[2])
        //重新启动定时器
        runInterval(data[1], data[2])
    }

    function filter(inputValue, path) {
        return path.some(CascaderData => CascaderData.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }

    return <div className="item-table animated zoomIn" >
        <div className="port-scroll-head">
            <div className="card-header">
                <div>
                    < img src="/image/arrow.svg" alt="" />
                    <span style={{ marginLeft: 10, color: '#61ddff' }}>{"设备端口流量图"}</span>
                </div>
            </div>
            <div className="rigth-header">
                <Cascader options={CascaderData}
                    allowClear={true}
                    value={CascaderVale}
                    onChange={(e) => { onChange(e) }}
                    showSearch={{ filter }}
                    placeholder="Please select"
                />
            </div>
        </div>

        <ReactECharts option={getOption(echertData)} />

    </div>

}
export default React.memo(lineMap)
