import React, { memo } from 'react';
import { Modal, Button, Form, Input, Checkbox, } from 'antd';
class EditMenuClass extends React.Component {
    handleSubmit = e => {
        e.preventDefault();
        let _this = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                _this.props.onSubmit(values);
            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { initialValues } = this.props;
        return <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onSubmit={this.handleSubmit}>
            <Form.Item
                label="名称"
            >
                {getFieldDecorator('name', {
                    initialValue: initialValues.name || "",
                    rules: [{
                        required: true, message: '请输入字符!',
                    }],
                    placeholder: "名称"
                })(<Input />)}
            </Form.Item>
            <Form.Item
                label="描述"
            >
                {getFieldDecorator('desc', {
                    initialValue: initialValues.desc || "",
                    rules: [{ required: true, message: '请输入字符!', }],
                    placeholder: "描述"
                })(<Input.TextArea />)}
            </Form.Item>
            <Form.Item
                label="返回信息"
            >
                {getFieldDecorator('resp', {
                    initialValue: initialValues.resp || "",
                    rules: [{
                        required: true, message: '请输入字符!',
                    }],
                    placeholder: "返回信息"
                })(<Input.TextArea spellCheck={false} style={{ height: 500, fontWeight: 600, background: "#000", color: "#fafafa" }} />)}
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 12 }}>
                <Button type="primary" htmlType="submit" icon="save">保存</Button>
            </Form.Item>
        </Form>
    }

}
const EditMenuObj = Form.create()(EditMenuClass)
export default EditMenuObj