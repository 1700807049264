import React from 'react'
import { Input, Form, Select } from "antd";
import { connect } from 'react-redux'
import "./index.scss"
import Socket from "@/socket/index";
import CascOrgain from '@/components/CascOrgain/index';
const versionName = sys_config.versionName;
// const treeData = [
//     {
//         title: 'Node1',
//         value: '0-0',
//         key: '0-0',
//         children: [
//             {
//                 title: 'Child Node1',
//                 value: '0-0-1',
//                 key: '0-0-1',
//             },
//             {
//                 title: 'Child Node2',
//                 value: '0-0-2',
//                 key: '0-0-2',
//             },
//         ],
//     },
//     {
//         title: 'Node2',
//         value: '0-1',
//         key: '0-1',
//     },
// ];
class CommForm extends React.Component {
    state = {
        TreeData: [],
        dep_id: ''
    }
    componentWillMount() {
        this.getMenuData();
    }
    componentDidMount() {
        this.props.onRef(this)
        if (this.props.netTag) {
            this.props.form.setFieldsValue({
                tags: ["哑终端"]
            })
        }
    }

    getMenuData = () => {
        Socket.ExecuteAction('QueryArea', {}, "", (result) => {
            const TreeData = this.greatData(result.data);
            this.setState({ TreeData, })
        });
    };
    greatData = (data) => {
        const newList = [];
        data.map((item) => {
            let obj = item;
            obj.value = item.title;
            if (item.children) {
                obj.children = this.greatData(item.children);
            }
            newList.push(obj);
        })
        return newList
    }
    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.props.onChange(values);
            }
        });
    };
    reset = () => {
        this.props.form.resetFields();
        this.setState({ deviceIp: null, selectedRowKeys: [], selectedRows: [] })
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { } = this.props;
        return <div>

            <Form
                labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
                onSubmit={this.handleSubmit}>
                <Form.Item label={"扫描网段"} >
                    {getFieldDecorator("net_mask", {
                        rules: [
                            { type: 'string', message: '请输入正确的网段' },
                            { required: true, message: '请输入正确的网段' },
                            { max: 18, message: '最大长度为18个字符' },
                            {
                                pattern: new RegExp(/(^((25[0-5]|2[0-4]\d|[0-1]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[0-1]?\d\d?)\/[1-3]{0,1}[0-9]{1}$)/, 'g'),
                                message: '网段格式不正确！'
                            }
                        ]
                    })(<Input placeholder="扫描网段" />)}
                </Form.Item>
                <Form.Item label={"community"} >
                    {getFieldDecorator("community", {
                        initialValue: versionName === "pingan" || versionName === "pinganNanJing" || versionName === "pinganTerminal" ? "linux" : "public",
                        rules: [{

                            required: true, message: '请填写community！',
                        }],
                    })(<Input placeholder="默认public" />)}
                </Form.Item>
                <Form.Item label="网段标签" >
                    {getFieldDecorator('tags', {
                        // rules: [{
                        //     required: true, message: '请选择组织区域!',
                        // }],
                    })(<Select
                        mode="multiple"
                        placeholder={"请选择网络标签"}
                        allowClear
                    >
                        <Select.Option value="哑终端">哑终端</Select.Option>
                        <Select.Option value="无线网段">无线网段</Select.Option>
                    </Select>)}
                </Form.Item>
                {/* <Form.Item label={"区域"} >
                    {getFieldDecorator("area", {
                        rules: [{
                            required: true, message: '请填写区域！',
                        }],
                    })(<TreeSelect
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={TreeData}
                        placeholder="请选择区域"
                        treeDefaultExpandAll
                        onChange={(key) => { console.log(key) }}
                    />)}
                </Form.Item> */}
                <Form.Item
                    label="组织区域"

                >
                    {getFieldDecorator('area', {
                        rules: [{
                            required: true, message: '请选择组织区域!',
                        }],
                        getValueFromEvent: (a, b) => {
                            console.log(a, b)
                            this.setState({ dep_id: a[a.length - 1] })
                            return b.map((i) => { return i.label }).join("-");
                        }
                    })(<CascOrgain type="add" />)}
                </Form.Item>
            </Form>
        </div>
    }

}
const CommObj = Form.create()(CommForm)
export default connect()(CommObj)