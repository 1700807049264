import React from 'react'
import {
    Card, Table, Input, Button, Icon, message, notification, Modal, Menu, Dropdown, Steps, Form,
    Row, Col, Select, Tabs, Badge, Popconfirm, Typography, Spin, Collapse, Tooltip, Tag
} from "antd";
import Socket from "@/socket/index";
import ResUtils from "@/utils/ResUtils";
import deviceFirmUtils from '@/utils/deviceFirmUtils';
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import moment from 'moment';
const { confirm } = Modal;

export default class Ledger extends React.Component {
    state = {
        s_current: 1,      //当前页码数
        s_pageSize: 20,    //每页显示条数
        s_querys: { "resourceParams.deviceType": "switch", "resourceParams.deviceState": "online" },    //查询条件  默认展示在线的交换机设备
        s_total: 0,
        s_loading: false,
        s_tableData: [],
    }
    componentWillMount() {
        // console.log(this.props)
        let bodyJson = this.props.location.bodyJson || {};
        this.updataDev(bodyJson.pageSize, bodyJson.pageNum, { "resourceParams.deviceType": "switch", "resourceParams.deviceState": "online" });
        // this.updataDev(bodyJson.pageSize, bodyJson.pageNum, bodyJson.querys);
    }
    updataDev = (pageSize, pageNum, querys) => {
        Socket.QueryResourcesPage(pageSize, pageNum, querys, this);
    };
    QueryMactableDetail = (mac) => {
        Socket.ExecuteAction("QueryMactableDetail",
            { mac }, "/", (result) => {
                // console.log(result);
                Modal.info({
                    title: <Typography.Title level={4}>MAC信息记录</Typography.Title>,
                    content: <div>
                        <Table
                            type="MAC"
                            dataSource={result.data}
                            columns={[...[["host", "主机IP",], ["portName", "端口名"], ["mac", "MAC地址"], ["ip", "设备IP",],
                            ].map((item) => {
                                return {
                                    title: item[1],
                                    align: 'center',
                                    // width: 150,
                                    ellipsis: true,
                                    dataIndex: item[0],
                                    render: (text, rec, key) => {
                                        return <Tooltip placement="topLeft" key={key} mouseLeaveDelay={0.07} title={rec[item[0]]}>{text}</Tooltip>
                                    }
                                }
                            })]}
                            pagination={false}
                            size="small"
                        />
                    </div>,
                    maskClosable: true,
                    width: "90%",
                    okText: "关闭"
                })
            });
    };
    handleMenuClick(menu, resItem) {
        const { s_current, s_pageSize, s_querys } = this.state
        let _this = this;
        let bodyJson = {
            pageNum: s_current,      //当前页码数
            pageSize: s_pageSize,    //每页显示条数
            s_querys,    //查询条件
            ip: resItem ? resItem.resourceParams.deviceIp : "",
        }
        if (menu.key === "record") {
            let path = {
                pathname: `/Ledger/history`,
                bodyJson,
            };
            this.props.history.push(path);
        } else if (menu.key === "skip") {
            let path = {
                pathname: `/suspiciousAccess`,
                bodyJson,
            };
            this.props.history.push(path);
        }
    }
    render() {
        let { s_tableData, s_current, s_pageSize, s_querys, s_total, s_loading, } = this.state;
        let columns = [...ResUtils.getColumns(s_pageSize, s_current),
        {
            title: '操作',
            align: 'center',
            width: 140,
            render: (text, item, index) => {
                return <div>
                    {/* <Dropdown overlay={this.menuButton(item)}> */}
                    <Button type="primary" size="small" icon="edit" onClick={() => this.handleMenuClick({ key: "record" }, item)}>
                        {/* MAC地址记录 <Icon type="down" /> */}
                        MAC地址
                    </Button>
                    {/* </Dropdown> */}
                </div>
            }
        }];
        const formList = [
            {
                label: "资源名称",
                type: 'INPUT',
                field: "resourceName",
                initialValue: '',
                placeholder: "资源名称"
            },
            {
                label: "设备IP",
                type: 'INPUT',
                field: "resourceParams-deviceIp",
                initialValue: '',
                placeholder: "设备IP"
            },
            {
                label: "厂商",
                type: 'AUTO',
                field: "resourceParams-deviceFirm",
                initialValue: '',
                placeholder: "厂商",
                list: deviceFirmUtils.deviceFirmsAutoInput()
            },
            {
                label: "型号",
                type: 'INPUT',
                field: "resourceParams-deviceVersion",
                initialValue: '',
                placeholder: "型号"
            },
        ]
        return <div>
            <CustomBreadcrumb arr={["运维管理", "MAC地址管理", { title: "交换机列表", to: "/Ledger" }]} />
            <div className="searchDiv">
                <Collapse defaultActiveKey={"key"}>
                    <Collapse.Panel header="筛选条件" key={"key"}>
                        <FilterForm
                            formList={formList}
                            filterSubmit={(querys) => {
                                let searchQuery = {
                                    ...s_querys,
                                    ...querys,
                                }
                                this.updataDev(s_pageSize, 1, searchQuery);
                                this.setState({ s_querys: searchQuery })
                            }}
                            resetTab={() => {
                                this.updataDev(s_pageSize, 1, { "resourceParams.deviceType": "switch", "resourceParams.deviceState": "online" })
                                this.setState({ s_querys: { "resourceParams.deviceType": "switch", "resourceParams.deviceState": "online" } })
                            }}
                        />
                    </Collapse.Panel>
                </Collapse>
            </div>
            <Card title={<div style={{ fontWeight: 600 }}>交换机列表</div>} style={{ marginTop: 8 }}
                extra={
                    <Button type="danger" icon="bug" size="small" style={{ height: 32, marginRight: 20 }}
                        onClick={() => this.handleMenuClick({ key: "skip" })}>
                        查看可疑接入
                    </Button>
                }
            >
                <Table
                    columns={columns}
                    dataSource={s_tableData}
                    size="small"
                    loading={s_loading}
                    pagination={{
                        current: s_current, pageSize: s_pageSize, total: s_total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            this.setState({ s_pageSize: size })
                            this.updataDev(size, 1, s_querys)
                        },
                        onChange: (page, pageSize) => { this.updataDev(pageSize, page, s_querys); }
                    }}
                />
            </Card>
        </div>
    }
}