import React from 'react'
import { Card, Table, message, Popconfirm, Button, notification, Icon, Tag, Modal } from "antd";
import Socket from '@/socket/index'
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import FileModal from '@/components/FileModal/index';
import ExportButton from '@/components/ExportButton/index';
import SystemEsInfo from '@/axios/sysGetEs'
import Utils from '@/utils/utils';
class ADLog extends React.Component {
    state = {
        visible: false,
        warnVis: false,
        _current: 1,       //当前页码数
        _pageSize: 20,    //当前每页显示条数
        // querys: {
        //     "query": {
        //         "match_all": {}
        //     }
        // },    //查询条件querys.query.bool.filter.range["@timestamp"]
        querys: {
            "from": 0,
            "size": 1000,
            // "query": {
            //     "bool": {
            //         "must": {
            //             "match": { "User-Name": "" }
            //         },
            //         "filter": {
            //             "range": {
            //                 "@timestamp": {
            //                     "lt": new Date(),
            //                     "gte": new Date(new Date().toLocaleDateString())
            //                 }
            //             }
            //         }
            //     }
            // },
            "query": {
                "match_all": {}
            },
            "sort": [
                {
                    "@timestamp": {
                        "order": "asc"//倒序
                    }
                }
            ]
        },    //查询条件
        loading: false,
        selectedRowKeys: []

    };
    componentWillMount() {
        // let bodyJson = this.props.location.bodyJson || {
        //     "from": 0,
        //     "size": 20,
        //     "query": {
        //         // "match_all": {},
        //         "match": {
        //             "message": '189',
        //         }
        //     }
        // };
        // {
        //     "from": 0,
        //     "size": 1000,
        //     "query": {
        //         "bool": {
        //             "must": {
        //                 "match": { "User-Name": "username" }
        //             },
        //             "filter": {
        //                 "range": {
        //                     "@timestamp": {
        //                         "lt": newDate,
        //                         "gte": Last_query_time
        //                     }
        //                 }
        //             }
        //         }
        //     },
        //     "sort": [
        //         {
        //             "@timestamp": {
        //                 "order": "asc"//倒序
        //             }
        //         }
        //     ]
        // }
        let { _pageSize, _current, querys } = this.state;
        this.updata(_pageSize, _current, querys);
    }

    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        querys.from = (pageNum - 1) * pageSize;
        querys.size = pageSize;
        console.log(querys);
        SystemEsInfo.PostSysLog(querys, (data) => {
            let _groupData = data.hits;
            let _dataSize = data.total.value;
            // console.log(data);
            let tabDatatemp = _groupData.map((item, index) => { return item["_source"] });
            this.setState({
                tableData: tabDatatemp,
                _current: pageNum || 1,
                total: _dataSize,
                loading: false
            })
        })

    };

    render() {
        let { _current, _pageSize, querys, warnVis } = this.state;
        const columns = [{
            title: '序号',
            align: 'center',
            fixed: 'left',
            width: 50,
            render: (text, item, key) => { return <span>{key + 1}</span> }
        }, {
            title: '时间',
            align: 'left',
            fixed: 'left',
            dataIndex: '@timestamp',
            render: (text, item, index) => {
                return Utils.utc2beijing(text);
            }
        },
        // {
        //     title: '日志信息',
        //     align: 'left',
        //     dataIndex: 'log'
        // }, 
        {
            title: 'Date',
            align: 'left',
            dataIndex: 'Date'
        }, {
            title: 'Time',
            align: 'left',
            dataIndex: 'Time'
        }, {
            title: 'Message-Type',
            align: 'left',
            dataIndex: 'Message-Type'
        }, {
            title: 'User-Name',
            align: 'left',
            dataIndex: 'User-Name'
        }, {
            title: 'Group-Name',
            align: 'left',
            dataIndex: 'Group-Name'
        }, {
            title: 'Caller-ID',
            align: 'left',
            dataIndex: 'Caller-ID'
        }, {
            title: 'Authen-Failure-Code',
            align: 'left',
            dataIndex: 'Authen-Failure-Code'
        }, {
            title: 'Author-Failure-Code',
            align: 'left',
            dataIndex: 'Author-Failure-Code'
        },
            // {
            //     title: '操作',
            //     width: '300px',
            //     align: 'center',
            //     render: (text, item) => {
            //         return <div>
            //             <Button type="primary" onClick={() => {

            //                 let bodyJson = {
            //                     pageNum: _current,      //当前页码数
            //                     pageSize: _pageSize,    //每页显示条数
            //                     querys,    //查询条件
            //                 }
            //                 let path = {
            //                     pathname: `/newOperating:${item._id}`,
            //                     bodyJson,
            //                 };
            //                 this.props.history.push(path);
            //             }}>
            //                 <Icon type="form" />编辑
            //                 </Button>
            //             <Button type="primary" icon="copy" onClick={() => {
            //                 this.props.history.push(`/newOperating:${item._id}&copy`);
            //             }}>复制
            //                 </Button>
            //             <Popconfirm
            //                 title="确定删除吗?"
            //                 onConfirm={() => { this.DeleteOperation(item._id) }}
            //                 okText="Yes"
            //                 cancelText="No"
            //             >
            //                 <Button type="danger" icon='delete'>
            //                     删除
            //                     </Button>
            //             </Popconfirm>
            //         </div>
            //     }
            // }
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys:`, selectedRowKeys, 'selectedRows: ', selectedRows);
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: changableRowKeys => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        const formList = [
            {
                label: "User-Name",
                type: 'INPUT',
                field: "UserName",
                initialValue: '',
            },
            {
                label: "时间",
                type: 'timeSelect',
                field: "time",
                initialValue: '',
            },
        ]
        return (
            <div>
                <CustomBreadcrumb arr={["日志管理", "AD日志"]} />
                <Card>
                    <FilterForm
                        formList={formList}
                        filterSubmit={(query) => {
                            let { querys, _pageSize } = this.state;
                            console.log(querys);
                            console.log(query);
                            if (Utils.isEmptyObject(query)) {
                                querys = {}
                            } else {
                                querys.query = {
                                    "bool": {}
                                }
                                if (query["UserName"]) {
                                    querys.query.bool.must = {
                                        "match": { "User-Name": query["UserName"] }
                                    }
                                }
                                if (query.begin_time && query.end_time) {
                                    querys.query.bool.filter = {
                                        "range": {
                                            "@timestamp": {
                                                "lt": new Date(query.end_time).toISOString(),
                                                "gte": new Date(query.begin_time).toISOString()
                                            }
                                        }
                                    }
                                }
                            }

                            // let temp = {
                            //     "from": 0,
                            //     "query": {
                            //         "match": query
                            //     },
                            //     "size": _pageSize
                            // }
                            this.setState({ querys })
                            this.updata(_pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(this.state._pageSize, 1, {})
                        }}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>系统日志列表</span>}
                    style={{ marginTop: 8 }}
                    extra={<div>
                        {/* <Button type="primary" icon="file-text"
                            onClick={() => {
                                this.setState({ warnVis: true })
                            }}>历史告警信息</Button> */}
                        <Button type="primary" style={{ marginLeft: 5 }} icon="rollback"
                            onClick={() => { history.go(-1) }}>返回</Button>
                    </div>}>
                    <Table
                        dataSource={this.state.tableData}
                        columns={columns}
                        // rowSelection={rowSelection}
                        rowKey={(text) => { return text._id }}
                        size="small"
                        loading={this.state.loading}
                        bordered={false}
                        scroll={{ x: 1920 }}
                        // pagination={false}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                console.log(page, pageSize);
                                this.updata(pageSize, page, querys)
                            }
                        }}
                    />

                </Card>
                {/* <Modal
                    title="告警信息"
                    visible={warnVis}
                    onCancel={() => { this.setState({ warnVis: false }) }}
                    onOk={() => this.setState({ warnVis: false })}
                    // width="70%"
                    // style={{ top: "20px" }}
                    destroyOnClose
                >

                </Modal> */}
            </div>
        )
    }
}


export default ADLog