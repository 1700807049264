import React from 'react'
import { Button, Card, DatePicker, Divider, Icon, Input, message, Modal, Row, Table, Tabs, Tag } from "antd";
import CustomBreadcrumb from '../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';

import Axios from "../../axios/axios";
import Popconfirm from "antd/es/popconfirm";
import GetTreeDataUtil from "../../utils/GetTreeDataUtil";
import Col from "antd/es/grid/col";


const Search = Input.Search;
const TabPane = Tabs.TabPane;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const defaultCode =
    `export default {
  name: 'name',
  code: 'code'
}`;

class Segment extends React.Component {

    state = {
        _current: 1,
        _pageSize: 20,
        filteredInfo: null,
        loading: false,
        total: 0,
        code: defaultCode,
        pingResult: [],//ping后的结果
        visible3: false,//Ping后的弹出框控制可见
        organizationTable: {},//用于将组织ID转换为文字
        treeData: {},
        organizationData: [],
    }

    componentWillMount() {
        Axios.GET("ip/organizationTree/", {}, (res) => { // 获取组织树数据
            this.getTreeData(res.data.data)
            this.updata();
        })
        GetTreeDataUtil.getData(treeData => {
            this.setState({ treeData })
        })
    }

    editorDidMount(editor, monaco) {
        console.log('editorDidMount', editor);
        editor.foucus;
    }

    onChange(newValue, e) {
        console.log('onChange', newValue, e);
    }

    // 将组织树数据放到一个数组中
    getTreeData(arr) {
        arr.map((item, index) => {
            let dataObj = {};
            dataObj.name = item.name;
            dataObj.id = item.id;
            dataObj.key = item.id;
            dataObj.pid = item.pid;
            if (item.children.length != 0) {
                this.getTreeData(item.children)
            }
            this.state.organizationData.push(dataObj)
        })
        // console.log(1, this.state.organizationData)
    }

    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let { organizationData } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};

        let queryStr = 'ip/ipSegment/?';
        queryStr += bodyJson.pageNum ? '&page=' + bodyJson.pageNum : '';
        queryStr += bodyJson.pageSize ? '&page_size=' + bodyJson.pageSize : '&page_size=20';
        bodyJson.querys.vlannum ? queryStr += '&vlannum=' + bodyJson.querys.vlannum : '';
        bodyJson.querys.ipsegment_start ? queryStr += '&ipsegment_start=' + bodyJson.querys.ipsegment_start : '';
        bodyJson.querys.organization_id ? queryStr += '&organization_id=' + bodyJson.querys.organization_id : '';

        let resolve = (result) => {
            let Resources = result.data.results;
            const newRes = [];
            if (Resources.length !== 0) {
                Resources.map((item, index) => {
                    let id = item.organization
                    // Axios.GET("ip/organization/" + id, {}, res => {
                    //     item.organization = res.data.name
                    //     // this.state.organizationTable.push(item.id, item.organization)
                    //     this.setState({ Resources })

                    // }) 

                    // 调用接口获取所属节点
                    // const { data: res, status } = await React.$http_ip.get("ip/organization/" + id); // 列表内容错乱，每次打开页面列表展示顺序不一致
                    // if (status !== 200) {
                    //     message.error('查询失败');
                    //     item.organization = "未知"
                    // } else {
                    //     item.organization = res.name
                    // }

                    let organizationName = organizationData.filter(elemt => elemt.id === id)
                    if (organizationName.length != 0) {
                        item.organization = organizationName[0].name;
                    } else {
                        message.error('查询失败');
                        item.organization = "未知"
                    }
                    newRes.push(item);
                    // if (Resources.length === index + 1) {
                    if (Resources.length === newRes.length) {
                        this.setState({
                            tableData: newRes,
                            _current: pageNum || 1,
                            total: result.data.count,
                            loading: false,
                        })
                    }
                });
            } else {
                this.setState({ loading: false })
            }
        }
        Axios.GET(queryStr, {}, resolve)
    }

    /* 删除方法*/
    delete = (id) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;

        let options = {
            url: 'ip/ipSegment/' + id + '/',
            method: 'DELETE',
            data: {
                params: {
                    // id: id,
                },
            },
        }

        Axios.delete(options).then(
            () => {
                this.setState({ visible: false, loading: false, });
                this.updata(_pageSize, _current);
                message.success('删除成功');
            },
            () => {
                this.setState({ visible: false, loading: false, });
                message.error('删除失败');
            }
        )

    }

    /* 弹出框取消操作 */
    cancel = (optionType) => {
        this.setState({ visible: false })
        message.error('取消' + optionType || '' + '操作')
    }

    /* Ping方法 */
    ping = async (id) => {
        // message.info('开始Ping' + '...')
        this.setState({ loading: true })
        const { data: res, status } = await React.$http_ip.get('ip/testPing/' + id + '/')
        const res_ = JSON.parse(res)
        if (status !== 200 || res_.statusCode.toString() !== '200') {
            this.setState({ loading: false })
            return message.error('Ping失败')
        }
        let json = res_.data
        let arr = []
        for (let i = 0; i < json.length; i++) {
            arr[i] = { ip: json[i][0], result: json[i][1] }
        }
        this.setState({ pingResult: arr, visible3: true, loading: false })
    }

    generatePingResult = () => {
        let row_arr = []
        let row_arr_branch = []
        let pingResultRenderArr = []
        const { pingResult } = this.state
        pingResult.forEach((item, index) => {
            let render_html = []
            if (index % 2 === 0) {
                render_html.push(<Col span={12} key={index}>
                    <Row>
                        <Col span={10}>{item.ip}</Col>
                        <Col span={14}>
                            <Col span={23}>
                                {item.result ? <Tag color='green'>连通</Tag> : <Tag color='red'>断开</Tag>}
                            </Col>
                            <Col span={1}>
                                <Divider type='vertical' />
                            </Col>
                        </Col>
                    </Row>
                </Col>)
            } else {
                render_html.push(<Col span={12} key={index}>
                    <Row >
                        <Col offset={10} span={10}>{item.ip}</Col>
                        <Col span={4}>
                            {item.result ? <Tag color='green'>连通</Tag> : <Tag color='red'>断开</Tag>}
                        </Col>
                    </Row>
                </Col>)
            }
            row_arr_branch.push(render_html)
            // 若是第二个，则要添加到数组中row_qrr
            if (index % 2 === 0) {
                row_arr.push(row_arr_branch)
            }
            // 当result数组为奇数时，把最后一个row_arr加上
            if (pingResultRenderArr.length % 2 === 0 && index === pingResultRenderArr.length - 1) {
                row_arr.push(row_arr_branch)
            }
        })
        if (row_arr === []) return message.error('异常！')
        row_arr.forEach((item, i) => {
            // 取消最后一行还加分割线
            if (i !== row_arr.length - 1) {
                pingResultRenderArr.push((<Row gutter='20px' key={i}>
                    {item.shift()} {item.shift()} <Divider />
                </Row>))
            } else {
                pingResultRenderArr.push((<Row gutter='20px' key={i}>
                    {item.shift()} {item.shift()}
                </Row>))
            }
        })
        // console.log('*********************', pingResultRenderArr.map((item) => { return item }))
        return pingResultRenderArr
    }

    render() {
        let { _current, _pageSize, querys, } = this.state;

        const columns = [
            {
                title: '序号',
                dataIndex: 'key',
                width: 50,
                render: (text, item, index) => {
                    return <span>{index + 1 + (_current - 1) * _pageSize}</span>
                }
            },
            {
                title: '网段',
                align: 'center',
                dataIndex: 'name',
            },
            {
                title: '网关',
                align: 'center',
                dataIndex: 'gateway',
            },
            {
                title: 'DNS',
                align: 'center',
                dataIndex: 'dns',
            },
            {
                title: 'Vlan号',
                align: 'center',
                dataIndex: 'vlannum',
            },
            {
                title: 'Vlan名称',
                align: 'center',
                dataIndex: 'vlanname',
            },
            {
                title: '所属节点',
                align: 'center',
                dataIndex: 'organization',
            },
            {
                title: '操作',
                align: 'center',
                render: (text, item, index) => {
                    return (
                        <div>
                            {
                                <Button type={"primary"} size="small"
                                    onClick={() => {
                                        this.props.history.push(`/newSegment:${item.id}`);
                                    }}><Icon type={"form"} />
                                    编辑
                                </Button>
                            }&nbsp;
                            {/* PING功能按钮 */}
                            <Popconfirm
                                title={'Ping操作可能会造成网络拥堵，请谨慎操作！\n您确定要Ping此网段吗？'}
                                onConfirm={() => {
                                    this.ping(item.id)
                                }}
                                // onCancel={this.cancel.bind(this, "Ping")}
                                okText={'确定Ping'}
                                cancelText={'取消'}
                            >
                                <Button
                                    type={'primary'} style={{ backgroundColor: "green" }} size="small"
                                ><Icon type="thunderbolt" />Ping</Button>
                            </Popconfirm>&nbsp;
                            {
                                <Popconfirm
                                    title={'确定要删除吗？'}
                                    onConfirm={() => {
                                        this.delete(item.id)
                                    }}
                                    // onCancel={this.cancel.bind(this, '删除')}
                                    okText={"确定删除"}
                                    cancelText={'取消'}
                                >
                                    <Button type={'danger'} icon={'delete'} size="small">删除</Button>
                                </Popconfirm>
                            }

                        </div>
                    );
                }
            }
        ]
        const formList = [
            {
                label: '组织',
                type: '组织查询',
                field: 'organization_id',
                initialValue: '',
                tree_value: this.state.value,
                treeData: this.state.treeData,
                placeholder: '组织名称'
            }, {
                label: 'IP',
                type: 'INPUT',
                field: 'ipsegment_start',
                initialValue: '',
                placeholder: 'IP'
            },
            {
                label: 'VLAN号',
                type: 'INPUT',
                field: 'vlannum',
                initialValue: '',
                placeholder: 'VLAN号'
            },


        ]

        return (
            <div>
                <CustomBreadcrumb arr={[{ title: '首页', to: '/home' }, 'IP管理', 'IP网段']} />
                <Card>
                    <FilterForm
                        formList={formList}
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(this.state._pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(this.state._pageSize, 1, {})
                        }}
                    />
                </Card>

                <Card title={<span style={{ fontWeight: 600 }}>网段列表</span>}
                    style={{ marginTop: 8 }}
                    extra={
                        <div>
                            <Button typeof='default' icon='reload' onClick={() => {
                                this.updata()
                            }}>刷新</Button>
                            <Button type={'primary'} style={{ marginLeft: 8 }}
                                icon={"edit"} onClick={() => {
                                    this.props.history.push('/newSegment');
                                }}>新建网段</Button>
                        </div>
                    }>
                    <Table
                        dataSource={this.state.tableData}
                        columns={columns}
                        rowKey={columns => columns.id}
                        loading={this.state.loading}
                        size="small"
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.setState({ _pageSize: size });
                                this.updata(size, 1, querys)
                            },
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                console.log(page, pageSize);
                                this.updata(pageSize, page, querys);
                            }
                        }}
                    />
                </Card>

                <div>
                    {/*显示ping以后的结果*/}
                    <Modal
                        title="Ping的结果"
                        visible={this.state.visible3}
                        onOk={() => {
                            this.setState({ visible3: false, })
                        }}
                        onCancel={() => {
                            this.setState({ visible3: false, loading: false })
                        }}
                        width='45em'
                    >
                        <div style={{ height: '400px', overflow: 'auto' }}>
                            {
                                this.generatePingResult()
                            }
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default Segment;
