import JsonP from 'jsonp'
import axios from 'axios'
import { Modal } from 'antd'
import Utils from "@/utils/utils"
const backUp_Url = "http://" + backup_address + "/ZJNMS_V1/backup/";
const esLog_Url = "http://" + es_address + "/produce_zjnms*/";
const device_Log_Url = "http://" + es_address + "/zjnms_snmptrap*/";
const alarm_Url = 'http://' + monitor_address
const fireWall_Url = 'http://' + fireWall_address + '/ZJNMS_V1/fw_policy/'
const acl_Url = 'http://' + backup_address + '/ZJNMS_V1/acl/'
const delivery_Url = 'http://' + backup_address + '/ZJNMS_V1/delivery/'
const hostping_Url = 'http://' + hostping_address + '/v1/hostping/'
const email_Url = 'http://' + email_address;
import { notification } from 'antd';
let sysname = sessionStorage.getItem('userName') + "/" + sessionStorage.getItem('nickName');
axios.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem("token");
        token && (config.headers['X-Access-Token'] = token);
        // const userId = sessionStorage.getItem("userId");
        // userId && (config.headers.userid = userId);
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况    
    error => {
        console.log(JSON.stringify(error))
        console.log(error.response)
        if (typeof error === "string") {
            notification.error({
                message: "错误提示",
                description: error,
            });
        } else if (error.response && error.response.status) {
            if (error.response.data && error.response.data.code && error.response.data.code != 0) {
                notification.error({
                    message: "错误提示",
                    description: error.response.data.msg,
                });
            } else {
                notification.error({
                    message: "错误提示",
                    description: error.response.status + "错误",
                });
            }

        } else if (typeof error === "object") {
            notification.error({
                message: "错误提示",
                description: JSON.stringify(error),
            });
        }
        return Promise.reject({});
    }
);
export default class Axios {
    static jsonp(options) {
        return new Promise((resolve, reject) => {
            JsonP(options.url, {
                param: 'callback'
            }, function (err, response) {
                if (response.status === 'success') {
                    resolve(response);
                } else {
                    reject(response.messsage);
                }
            })
        })
    }
    static setSysName(userName, nickName) {
        sysname = userName + "/" + nickName
    }
    static getBaseUrl(mode) {
        switch (mode) {
            case "backUp": return backUp_Url;
            case "logMange": return esLog_Url;
            case "deviceLog": return device_Log_Url;
            case "alarm": return alarm_Url;
            case "fw": return fireWall_Url;
            case "acl": return acl_Url;
            case "delivery": return delivery_Url;
            case "hostping": return hostping_Url;
            case "email": return email_Url;
            default: return 'http://' + backup_address + '/ZJNMS_V1/' + mode + '/';
        }
    }
    static HTTP_POST(mode, URL, body, success, error) {
        if (mode === "backUp") { body["sysname"] = sysname };
        console.log(this.getBaseUrl(mode) + URL);
        console.log(body);
        axios.post(this.getBaseUrl(mode) + URL, body).then(
            (response) => {
                console.log(response);
                if (response.data.code === "00" || response.data.recode === "00") {
                    success(response.data)
                } else if (response.data.code === "02") {
                    success(response.data)
                } else if (response.data.code == 0) {
                    success(response.data)
                } else if (error) {
                    error(response.data.msg, response.data)
                } else {
                    Utils.showMessage("error", "操作失败", response.data.msg);
                }
            }
        )
    }
    static SIMPLE_POST(URL, body, success, error) {
        console.log(URL);
        console.log(body);
        axios.post(URL, body).then(
            (response) => {
                console.log(response);
                if (response.data.code === "00" || response.data.recode === "00" || response.data.retcode === "00") {
                    success(response.data)
                } else if (response.data.code === "02") {
                    success(response.data)
                } else if (response.data.code == 0) {
                    success(response.data)
                } else if (error) {
                    error(response.data.msg, response.data)
                } else {
                    Utils.showMessage("error", "操作失败", response.data.msg);
                }
            }
        )
    }
    static async ASYNC_POST(mode, URL, body) {
        if (mode === "backUp") { body["sysname"] = sysname };
        console.log(URL);
        console.log(body);
        const data = await axios.post(this.getBaseUrl(mode) + URL, body);
        console.log(data);
        if (data.data.code === "00") {
            return (data.data);
        } else {
            Utils.showMessage("error", "操作失败", data.data.msg);
        }
    }

    // 安全审计 查询接口
    static auditQuery(URL, Params, success, error) {
        // console.log(URL);
        axios.get('http://' + URL, { params: Params }).then(
            (res) => {
                let response = JSON.parse(res.data)
                if (res.status === 200) {
                    if (response.code === "0") {
                        // console.log(response.data)
                        success(response)
                    } else {
                        // error(response.data.msg)
                        error(res.statusText)
                    }
                } else {
                    error(response.data.msg || response.data)
                }
            }
        )
    }

    static ES_HTTP_POST(mode, URL, body, success, error) {
        console.log(URL);
        console.log(body);
        axios.post(this.getBaseUrl(mode) + URL, body).then(
            (response) => {
                console.log(response);
                if (response.status === 200) {
                    success(response.data.hits)
                } else {
                    error(response.data.msg || response.data)
                }
            }
        )
    }
    //监控告警模块get 带参数
    static MA_HTTP_GET2(URL, Params, success, error) {
        console.log(URL);
        axios.get('http://' + URL, { params: Params }).then(
            (response) => {
                if (response.status === 200) {
                    if (response.data.code === 0) {
                        console.log(response.data)
                        success(response.data)
                    } else {
                        error(response.data.msg)
                    }
                } else {
                    error(response.data.msg || response.data)
                }
            }
        )
    }

    static HTTP_DELETE(mode, URL, body, success, error) {
        console.log(this.getBaseUrl(mode) + URL);
        console.log(body);
        axios.delete(this.getBaseUrl(mode) + URL, { data: body }).then(
            (response) => {
                console.log(response);
                if (response.data.code === "00") {
                    success(response.data)
                } if (response.data.code == 0) {
                    success(response.data)
                } else {
                    error(response.data.msg)
                }
            }
        )
    }
    static HTTP_PUT(mode, URL, body, success, error) {
        console.log(body);
        axios.put(this.getBaseUrl(mode) + URL, body).then(
            (response) => {
                console.log(response);
                if (response.data.code === "00") {
                    success(response.data)
                } else {
                    error(response.data.msg)
                }
            }
        )
    }
    // static HTTP_DELETE(mode, URL, body, callback) {
    //     axios.delete(this.getBaseUrl(mode) + URL, body).then(
    //         (response) => {
    //             callback(response)
    //         }
    //     )
    // }
    //监控告警模块get方法
    static MA_HTTP_GET(URL, success, error) {
        console.log(URL);
        axios.get('http://' + URL).then(
            (response) => {
                console.log(response)
                if (response.status === 200) {
                    if (response.data.code === 0) {
                        console.log(response.data)
                        success(response.data)
                    } else if (response.data.recode === "00") {
                        success(response.data)
                    } else {
                        error(response.data.msg)
                    }
                } else {
                    error(response.data.msg || response.data)
                }
            }
        )
    }
    static MA_HTTP_PUT(mode, URL, body, success, error) {
        console.log(this.getBaseUrl(mode) + URL);
        console.log(URL);
        axios.put(this.getBaseUrl(mode) + URL, body).then(
            (response) => {
                console.log(response)
                if (response.status === 200) {
                    if (response.data.code === "00" || response.data.recode === "00") {
                        success(response.data)
                    } else if (response.data.code === "02") {
                        success(response.data)
                    } else if (response.data.code == 0) {
                        success(response.data)
                    } else if (error) {
                        error(response.data.msg, response.data)
                    } else {
                        Utils.showMessage("error", "操作失败", response.data.msg);
                    }
                } else {
                    error(response.data.msg || response.data)
                }
            }
        )
    }
    static HTTP_GET(URL, callback) {
        axios.get(URL).then(
            (response) => {
                callback(response)
            }
        )
    }
    static ajax(options) {
        let loading;
        if (options.data && options.data.isShowLoading !== false) {
            loading = document.getElementById('ajaxLoading');
            loading.style.display = 'block';
        }
        let baseApi = 'https://www.easy-mock.com/mock/5a7278e28d0c633b9c4adbd7/api';
        // let baseApi = 'http://10.32.2.48:8088/';

        return new Promise((resolve, reject) => {
            axios({
                url: options.url,
                method: options.method || 'get',
                baseURL: baseApi,
                timeout: 5000,
                params: (options.data && options.data.params) || ''
            }).then((response) => {
                if (options.data && options.data.isShowLoading !== false) {
                    loading = document.getElementById('ajaxLoading');
                    loading.style.display = 'none';
                }
                if (response.status === '200') {
                    let res = response.data;
                    if (res.code === '0') {
                        resolve(res);
                    } else {
                        Modal.info({
                            title: "提示",
                            content: res.msg
                        })
                    }
                } else {
                    reject(response.data);
                }
            }).catch(() => {
                loading = document.getElementById('ajaxLoading');
                loading.style.display = 'block';
            })
        });
    }
    static NO_Delay_POST(URL, body, success, error) {
        axios.post(URL, body).then(
            (response) => {
                console.log(response);
                if (response.data.code === "00" || response.data.recode === "00") {
                    success(response.data)
                } else if (response.data.code === "02") {
                    success(response.data)
                } else if (response.data.code == 0) {
                    success(response.data)
                } else if (error) {
                    error(response.data.msg, response.data)
                } else {
                    Utils.showMessage("error", "操作失败", response.data.msg);
                }
            }
        )
    }
    static NO_Delay_DELETE(URL, body, success) {
        return new Promise((resolve, reject) => {
            axios({
                method: "delete",
                url: URL,
                data: body,
            }).then((response) => {
                console.log(response);
                if(response.status === 200) {
                    success(response.data)
                } else {
                    reject(response.data)
                }
            })
        })
    }

}