import React from 'react';
import { Select, Tag, Tooltip, Button, Popover, Icon, List } from 'antd';
import CollapseList from '@/components/CollapseList/index'

export default {
    getPoliceColumns(_pageSize, _current) {
        const columns = [
            {
                title: '策略名称',
                align: 'center',
                dataIndex: "rule_name",
                ellipsis: true,
                width: 80,
                render: (text, item, index) => {
                    let temp_policy = item.temp_policy
                    if(temp_policy) {
                        if(temp_policy.is_temp) {
                            let title = <div>
                                <p style={{ marginBottom: 0 }}>{'过期时间：' + temp_policy.expiration_time}</p>
                                <p style={{ marginBottom: 0 }}>{'备注信息：' + temp_policy.remark}</p>
                            </div>
                            return <div>
                                <Tooltip title={title}><Icon theme="twoTone" type="clock-circle" /></Tooltip>
                                <span style={{ marginLeft: 5 }}>{text}</span>
                            </div>
                        } else {
                            return text
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '动作',
                align: 'center',
                dataIndex: "action",
                ellipsis: true,
                width: 70,
            },
            {
                title: '源安全域',
                align: 'center',
                dataIndex: "src_zone",
                ellipsis: true,
                width: 80,
                render: (text, item, index) => {
                    if(Array.isArray(text)) {
                        return(
                            <>
                                <Tooltip title={text.join("， ")}> <Icon type="eye" /> </Tooltip>
                                {text.join("， ")}
                            </>
                        )
                    }
                }
            },
            {
                title: '目的安全域',
                align: 'center',
                dataIndex: "dst_zone",
                ellipsis: true,
                width: 90,
                render: (text, item, index) => {
                    if(Array.isArray(text)) {
                        return(
                            <>
                                <Tooltip title={text.join("， ")}> <Icon type="eye" /> </Tooltip>
                                {text.join("， ")}
                            </>
                        )
                    }
                }
            },
            {
                title: '源地址',
                align: 'center',
                dataIndex: "src_addr",
                ellipsis: true,
                width: 180,
                render: (text, item, key) => {
                    if(Array.isArray(text)) {
                        let titleRender = text.map((addrItem, index) => {
                            if(typeof addrItem === "string") {
                                return <>{index === text.length-1 ? addrItem : addrItem + "，"}</>
                            } else if(typeof addrItem === "object") {
                                return ( addrItem.ips.length !== 0 ?
                                    <Popover content={
                                        addrItem.ips.length !== 0 ? addrItem.ips.map((ipItem, ipIndex) => {
                                            return <p style={{ marginBottom: 0 }} key={ipIndex}>{ipItem}</p>
                                        }) : "无"
                                    }>{index === text.length-1 ? addrItem.addr_group  : addrItem.addr_group+ '，'}</Popover> :
                                    <>{index === text.length-1 ? addrItem.addr_group  : addrItem.addr_group+ '，'}</>
                                )
                            }
                        })
                        return(
                            <>
                                <Tooltip title={titleRender}> <Icon type="eye" /> </Tooltip>
                                {titleRender}
                            </>
                        )
                    }
                }
            },
            {
                title: '目的地址',
                align: 'center',
                dataIndex: "dst_addr",
                ellipsis: true,
                width: 180,
                render: (text, item, key) => {
                    if(Array.isArray(text)) {
                        let titleRender = text.map((addrItem, index) => {
                            if(typeof addrItem === "string") {
                                return <>{index === text.length-1 ? addrItem : addrItem + "，"}</>
                            } else if(typeof addrItem === "object") {
                                return ( addrItem.ips.length !== 0 ?
                                    <Popover content={
                                        addrItem.ips.length !== 0 ? addrItem.ips.map((ipItem, ipIndex) => {
                                            return <p style={{ marginBottom: 0 }} key={ipIndex}>{ipItem}</p>
                                        }) : "无"
                                    }>{index === text.length-1 ? addrItem.addr_group  : addrItem.addr_group+ '，'}</Popover> : 
                                    <>{index === text.length-1 ? addrItem.addr_group  : addrItem.addr_group+ '，'}</>
                                )
                            }
                        })
                        return(
                            <>
                                <Tooltip title={titleRender}> <Icon type="eye" /> </Tooltip>
                                {titleRender}
                            </>
                        )
                    }
                }
            },
            {
                title: '服务',
                align: 'center',
                dataIndex: "service",
                ellipsis: true,
                width: 180,
                render: (text, item, key) => {
                    if(Array.isArray(text)) {
                        let titleRender = text.map((mapItem, index) => {
                            return mapItem.protocol_port.length !== 0 ? <Popover key={index} content={
                                mapItem.protocol_port.length !== 0 ? mapItem.protocol_port.map((portItem, portIndex) => {
                                    return <p style={{ marginBottom: 0 }} key={portIndex}>{portItem.protocol + portItem.d_port}</p>
                                }) : "无"
                            }>{index === text.length-1 ? mapItem.service_name : mapItem.service_name + "，"}</Popover> :
                            <>{index === text.length-1 ? mapItem.service_name : mapItem.service_name + "，"}</>
                        })
                        return(
                            <>
                                <Tooltip title={titleRender}> <Icon type="eye" /> </Tooltip>
                                {titleRender}
                            </>
                        )
                    }
                }
            },
            {
                title: '描述',
                align: 'center',
                dataIndex: "description",
                ellipsis: true,
                width: 150,
            },
        ];
        return columns;
    },
    getDNatColumns (_current, _pageSize) {
        const columns = [
            {
                title: '序号',
                width: 50,
                dataIndex: 'key',
                render: (text, item, index) => {
                    if(_current && _pageSize) {
                        return <span>{index + 1 + (_current - 1) * _pageSize}</span>
                    } else {
                        return <span>{index + 1}</span>
                    }
                }
            },
            {
                title: '虚拟路由器',
                align: 'center',
                dataIndex: "ip_vrouter",
                ellipsis: true,
                width: 80,
            },
            {
                title: '目的nat_id',
                align: 'center',
                dataIndex: 'dnat_id',
                ellipsis: true,
                width: 70,
            },
            {
                title: '源地址',
                align: 'center',
                dataIndex: "src_addr",
                ellipsis: true,
                width: 120,
                render: (text, item, key) => {
                    if(text) {
                        if(text.addr_group) {
                            let textIps = text.ips;
                            return <Popover content={
                                Array.isArray(textIps) ? textIps.map((ipsItem, index) => {
                                    return <p style={{ marginBottom: 0 }} key={index}>{ipsItem}</p>
                                }) : ''
                            }>
                                <span>{text.addr_group}</span>
                            </Popover>
                        }
                    }
                }
            },
            {
                title: '目的地址',
                align: 'center',
                dataIndex: "dst_addr",
                ellipsis: true,
                width: 120,
                render: (text, item, key) => {
                    if(text) {
                        if(text.addr_group) {
                            let textIps = text.ips;
                            return <Popover content={
                                Array.isArray(textIps) ? textIps.map((ipsItem, index) => {
                                    return <p style={{ marginBottom: 0 }} key={index}>{ipsItem}</p>
                                }) : ''
                            }>
                                <span>{text.addr_group}</span>
                            </Popover>
                        }
                    }
                }
            },
            {
                title: '转换为',
                align: 'center',
                dataIndex: "trans_to_addr",
                ellipsis: true,
                width: 120,
                render: (text, item, key) => {
                    if(text) {
                        if(text.addr_group) {
                            let textIps = text.ips;
                            return <Popover content={
                                Array.isArray(textIps) ? textIps.map((ipsItem, index) => {
                                    return <p style={{ marginBottom: 0 }} key={index}>{ipsItem}</p>
                                }) : ''
                            }>
                                <span>{text.addr_group}</span>
                            </Popover>
                        }
                    }
                }
            },
            {
                title: '描述',
                align: 'center',
                dataIndex: "description",
                ellipsis: true,
                width: 150,
            },
        ];
        return columns;
    },
    getSNatColumns (_current, _pageSize) {
        const columns = [
            {
                title: '序号',
                width: 50,
                dataIndex: 'key',
                render: (text, item, index) => {
                    if(_current && _pageSize) {
                        return <span>{index + 1 + (_current - 1) * _pageSize}</span>
                    } else {
                        return <span>{index + 1}</span>
                    }
                }
            },
            {
                title: '虚拟路由器',
                align: 'center',
                dataIndex: "ip_vrouter",
                ellipsis: true,
                width: 80,
            },
            {
                title: '源nat_id',
                align: 'center',
                dataIndex: "snat_id",
                ellipsis: true,
                width: 70,
            },
            {
                title: '源地址',
                align: 'center',
                dataIndex: "src_addr",
                ellipsis: true,
                width: 120,
                render: (text, item, key) => {
                    if(text) {
                        if(text.addr_group) {
                            let textIps = text.ips;
                            return <Popover content={
                                Array.isArray(textIps) ? textIps.map((ipsItem, index) => {
                                    return <p style={{ marginBottom: 0 }} key={index}>{ipsItem}</p>
                                }) : ''
                            }>
                                <span>{text.addr_group}</span>
                            </Popover>
                        }
                    }
                }
            },
            {
                title: '目的地址',
                align: 'center',
                dataIndex: "dst_addr",
                ellipsis: true,
                width: 120,
                render: (text, item, key) => {
                    if(text) {
                        if(text.addr_group) {
                            let textIps = text.ips;
                            return <Popover content={
                                Array.isArray(textIps) ? textIps.map((ipsItem, index) => {
                                    return <p style={{ marginBottom: 0 }} key={index}>{ipsItem}</p>
                                }) : ''
                            }>
                                <span>{text.addr_group}</span>
                            </Popover>
                        }
                    }
                }
            },
            {
                title: '转换为',
                align: 'center',
                dataIndex: "trans_to_addr",
                ellipsis: true,
                width: 120,
                render: (text, item, key) => {
                    if(text) {
                        if(text.addr_group) {
                            let textIps = text.ips;
                            return <Popover content={
                                Array.isArray(textIps) ? textIps.map((ipsItem, index) => {
                                    return <p style={{ marginBottom: 0 }} key={index}>{ipsItem}</p>
                                }) : ''
                            }>
                                <span>{text.addr_group}</span>
                            </Popover>
                        }
                    }
                }
            },
            {
                title: '描述',
                align: 'center',
                dataIndex: "description",
                ellipsis: true,
                width: 150,
            },
        ];
        return columns;
    },
    getExternalConnectionColumns(tableData, completeTableData, parentThis) {
        const columns = [
            {
                title: '源ip',
                align: 'center',
                dataIndex: 'src_ips',
                width: 120,
                ellipsis: true,
                render: (text, item, index) => {
                    if(Array.isArray(text)) {
                        return <CollapseList
                            listData={text}
                            listItem={item}
                            listIndex={index}
                            tableData={tableData}
                            keyName={"src_ips"}
                            completeTableData={completeTableData}
                            updateData={parentThis.updateData.bind(parentThis)}
                        />
                    }
                }
            },
            {
                title: '源ip转换后地址',
                align: 'center',
                dataIndex: 'srcIp_trans_to',
                width: 120,
                ellipsis: true,
            },
            {
                title: '目的ip',
                align: 'center',
                dataIndex: 'dst_ips',
                width: 120,
                ellipsis: true,
                render: (text, item, index) => {
                    if(Array.isArray(text)) {
                        return <CollapseList
                            listData={text}
                            listItem={item}
                            listIndex={index}
                            tableData={tableData}
                            keyName={"dst_ips"}
                            completeTableData={completeTableData}
                            updateData={parentThis.updateData.bind(parentThis)}
                        />
                    }
                }
            },
            {
                title: '目的ip转换后地址',
                align: 'center',
                dataIndex: 'dstIp_trans_to',
                width: 120,
                ellipsis: true,
            },
            {
                title: '服务',
                align: 'center',
                dataIndex: 'services',
                width: 120,
                ellipsis: true,
                render: (text, item, index) => {
                    if(Array.isArray(text)) {
                        return <CollapseList
                            listData={text}
                            listItem={item}
                            listIndex={index}
                            tableData={tableData}
                            keyName={"services"}
                            completeTableData={completeTableData}
                            updateData={parentThis.updateData.bind(parentThis)}
                        />
                    }
                }
            },
            {
                title: '描述',
                align: 'center',
                dataIndex: 'desc',
                width: 120,
                ellipsis: true,
            },
            {
                title: '防火墙ip',
                align: 'center',
                dataIndex: 'fw_ip',
                width: 120,
                ellipsis: true,
            },
            {
                title: '安全策略名称',
                align: 'center',
                dataIndex: 'rule_name',
                width: 120,
                ellipsis: true,
            },
        ];
        return columns;
    }
}