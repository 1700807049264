/**
 * postman页面
 */
import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Tabs, Tag, message, Select, Input
} from "antd";
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
// import http from '@/axios/axios_utils';
import MonacoEditor from 'react-monaco-editor';
import axios_obj from 'axios';

import "./index.scss"
function OperationAudit(props) {
    const [method, set_method] = useState("GET");
    const [strData, set_StrData] = useState("");
    const [backData, set_BackData] = useState("");
    const [loading, set_loading] = useState(false);
    const [url_srt, set_url] = useState("http://10.32.79.60:50034/firewall");
    useEffect(() => {//componentDidMount
        // http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    const resHandleFun = (response) => {
        set_loading(false)
        if (response.status === 200) {
            console.log(response.data);
            set_BackData(JSON.stringify(response.data, null, 4))
        } else {
            set_BackData(JSON.stringify(response, 4, null))
        }
    }
    const onSend = () => {
        try {
            set_loading(true)
            let jsonData = strData.trim() == "" ? {} : JSON.parse(strData)
            if (method === "GET") {
                axios_obj({
                    method: method,
                    url: url_srt,
                    params: jsonData,
                    timeout: 10000,
                }).then(response => {
                    resHandleFun(response);
                }).catch(err => {
                    set_loading(false)
                    console.error(err)
                });
            } else {
                axios_obj({
                    method: method,
                    url: url_srt,
                    data: jsonData,
                    timeout: 10000,
                }).then(response => {

                    resHandleFun(response);
                }).catch(err => {
                    console.error(err)
                    set_loading(false)
                });
            }

        } catch (e) {
            set_loading(false)
            message.error("报文错误或者发送错误！")
        }

    }

    return <div className="postmanDiv">
        <CustomBreadcrumb arr={["http请求测试页面"]} />
        <Card>
            <div className="content">
                <Select value={method} onChange={(v) => { set_method(v) }} className="method">
                    {["GET", "POST", "DELETE", "PUT", "PATCH", "COPY", "OPTION"].map((item, key) => <Select.Option key={key} value={item}>{item}</Select.Option>)}

                </Select>
                <Input
                    value={url_srt}
                    onChange={(e) => { set_url(e.target.value) }}
                    className="url_input"
                />
                <Button type="primary" className="btm_send" icon="thunderbolt" onClick={() => { onSend() }} loading={loading}>发送</Button>
                <MonacoEditor
                    // theme="vs-dark"
                    value={strData}
                    onChange={(e) => { set_StrData(e) }}
                    language="json"
                    options={{ minimap: { enabled: false }, lineNumbers: "off", scrollBeyondLastLine: false, }}
                    height="400"
                    width="832"
                />
                <MonacoEditor
                    // theme="vs-dark"
                    value={backData}
                    language="json"
                    options={{ minimap: { enabled: false }, lineNumbers: "off", scrollBeyondLastLine: false, }}
                    height="400"
                    width="832"
                />
            </div>
        </Card>
    </div >

}
export default connect()(OperationAudit)