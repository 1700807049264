import React from 'react';
import Exception from '@/components/Exception';

const Exception500 = () => (
  <Exception
    type="500"
    desc={"服务器开小差了"}
    linkElement={null}
    linkTo="/home"
    backText={"返回首页"}
  />
);

export default Exception500;
