
import React from "react"
import {
    Card, Form, Input, Radio, Spin, Modal, Icon, Select, message, Table,
    notification, Button,
} from 'antd';
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import FilterForm from '@/components/FilterForm/index';
import ResUtils from "@/utils/ResUtils";
export default class SelectDev extends React.Component {
    state = {
        _current: 1,
        _pageSize: 20,
        total: 10,
        querys: this.props.querys || {},
        DevTableData: [],
        selectedRows: [],
        selectedRowsAll: {},
    }
    componentWillMount() {
        this.updataDev();
    }
    updataDev = (pageSize, pageNum, querys2) => {
        this.setState({ loading: true, });
        let { _pageSize, _current, querys } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys2 || querys;
        Socket.ExecuteAction('QueryResourcesPage',
            bodyJson, "/queryResource", (result) => {
                let Resources = result.resources;
                Resources.map((item, index) => {
                    item.key = index;
                    item.index = index;
                    return item
                });
                this.setState({
                    DevTableData: Resources,
                    _current: pageNum || 1,
                    total: result.total,
                    loading: false,
                    querys: bodyJson.querys
                })
            });

    };
    render() {
        const { _current, _pageSize, querys, total, selectedRowsAll } = this.state;
        const { selectedRowKeys, checkType } = this.props;
        const columns = [...ResUtils.getColumns(_pageSize, _current)];
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                selectedRowsAll[_current + ""] = selectedRows;
                let allrows = [];
                Object.values(selectedRowsAll).map((rows) => {
                    allrows = [...allrows, ...rows]
                });
                console.log(allrows)
                this.props.onChange(selectedRowKeys, allrows);
                this.setState({ selectedRowsAll, })
            },
            type: checkType || "checkbox",
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.props.onChange([], []);
                    },
                },
            ],
        };
        return <div style={{ border: "1px solid #e2e2e2", padding: 10, }}>
            <FilterForm
                tagClass="resource"
                filterSubmit={(querys) => {
                    // this.setState({ querys })
                    this.updataDev(_pageSize, 1, querys)
                }}
                resetTab={() => {
                    // this.setState({ querys: {} })
                    this.updataDev(_pageSize, 1, {})
                }}
            />
            <Table
                style={{ zoom: 0.9 }}
                dataSource={this.state.DevTableData}
                rowSelection={rowSelection}
                columns={columns}
                loading={this.state.loading}
                rowKey={(text) => {
                    return JSON.stringify({
                        // deviceIp: text.resourceParams.deviceIp,
                        resourceName: text.resourceName,
                    })
                }}
                size="small"
                pagination={{
                    current: _current, pageSize: _pageSize, total: total,
                    onChange: (page, pageSize) => {
                        this.updataDev(pageSize, page, querys);
                    }
                }}
            />
        </div>
    }

}














