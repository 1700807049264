import React from 'react'
import './index.scss'
import {
    Card, Table, Input, Typography, Popconfirm, notification, Button,
    Icon, Tabs, Row, Col, message, Tag, Modal, Progress, List,
} from "antd";
import Socket from "../../../socket/index";
import Utils from "../../../utils/utils";
import StatiTask from "./statiTask";
import StatiHistogram from "./statiHistogram";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import TaskResult from '@/components/taskResult/index';
const Search = Input.Search;
import "animate.css";
let FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
export default class OverView extends React.Component {
    state = {
        tableData: [],
        Resources: [],
        selectList: [],
        visible_report: false,
        visible_port: false,
        visible_monitor: false,
        visible_param: false,
        visible_find: false,
        visible_IpConfig: false,
        visible_managePort: false,
        visible_topology: false,
        progressObj: {},
        elapsedTimeObj: {},
        dataList: [],
        httpSrc: '',
        taskId: null,
        _current: 1,    //当前页码数
        _pageSize: 10,    //当前每页显示条数
        interfaceData: [],    //端口数据
        taskTotal: 0,
        resTotal: 0,
        loading: false,
        oidLoading: false,
    };
    componentWillMount() {
        FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
        this.updataTask();
        // this.updataResource();
        Socket.Notice((resp) => {
            let { progressObj, elapsedTimeObj, _pageSize, _current } = this.state;
            if (progressObj.hasOwnProperty(resp.taskName)) {
                progressObj[resp.taskName] = resp.taskRate;
                if (resp.taskRate === 100) {
                    this.updataTask(_pageSize, _current, {});
                }
            }
            if (elapsedTimeObj.hasOwnProperty(resp.taskName)) {
                elapsedTimeObj[resp.taskName] = resp.elapsedTime;
            }
            this.setState({
                progressObj, elapsedTimeObj
            })
        });
    }
    excuteTask = (taskId, taskName) => {
        const { _pageSize, _current, elapsedTimeObj, progressObj } = this.state;
        this.setState({ loading: true });
        Socket.Action("ExcuteTask",
            { _id: taskId }, (result) => {
                if (result.retcode === '00') {
                    message.success('执行成功');
                    elapsedTimeObj[taskName] = "0:0:0";
                    progressObj[taskName] = 0;
                    this.setState({ elapsedTimeObj, progressObj });
                    this.updataTask(_pageSize, _current, {});
                } else {
                    this.setState({ loading: false });
                    notification.open({
                        message: '执行失败',
                        description: result.error,
                        icon: <Icon type="frown" style={{ color: 'red' }} />,
                    });
                }
            }, "/taskMangerExe");
    };
    TerminalTask = (taskId) => {
        const { _pageSize, _current } = this.state;
        this.setState({ loading: true });
        Socket.Action("TerminalTask",
            { _id: taskId }, (result) => {
                if (result.retcode === '00') {
                    message.success('终止成功');
                    this.updataTask(_pageSize, _current, {});
                } else {
                    message.error('操作失败');
                    this.setState({ loading: false });
                }
            }, "/taskMangerTer");
    };
    updataTask = (pageSize, pageNum, querys) => {
        this.setState({ loading: true });
        let { _pageSize, _current } = this.state;
        var bodyJson = new Object();
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        Socket.QueryDataByPage("QueryTasksPage",
            bodyJson, (result) => {
                if (result.retcode === '00') {
                    let tableData = result.tasks;
                    let { progressObj, elapsedTimeObj } = this.state;

                    if (Utils.isEmptyObject(progressObj)) {
                        tableData.map((item, index) => {
                            item.key = index;
                            progressObj[item.taskName] = 0;
                        });
                    }

                    if (Utils.isEmptyObject(elapsedTimeObj)) {
                        tableData.map((item, index) => {
                            item.key = index;
                            elapsedTimeObj[item.taskName] = item.taskEndTime;
                        });
                    }
                    this.setState({
                        tableData,
                        progressObj,
                        elapsedTimeObj,
                        _current: pageNum || 1,
                        taskTotal: result.total,
                        loading: false,
                    })
                }
            }, "/overViewQueryTask");
    };
    openChar = (taskId) => {
        this.child.updataCharData(taskId);
        this.setState({ visible_monitor: true })
    };
    render() {
        let { tableData, progressObj, elapsedTimeObj, _current,
            visible_monitor, visible_port, visible_IpConfig, visible_param, visible_topology,
            visible_find, dataList, visible_report, taskId, visible_managePort } = this.state;
        let paramResult = {
            visible_report, visible_port, visible_monitor, visible_param,
            visible_find, visible_IpConfig, visible_topology, taskId,
        }
        const columns = [
            {
                title: '任务名称',
                align: 'center',
                dataIndex: 'taskName',
                render: (text, itemTask, index) => {
                    // let httpSrc = 'http://' + Socket.getAddress() + ':50034/download?taskName=' + text + '&reportName=report_finished.pdf';
                    let httpSrc = 'http://' + Socket.getAddress() + ':50034/download?taskName=' + itemTask["taskName"] + '&reportName=report_finished.docx';
                    let httpSrcExcel = 'http://' + Socket.getAddress() + ':50034/download?taskName=' + itemTask["taskName"] + '&reportName=report.xlsx';
                    let reportTags = itemTask.reportTags;
                    let type_falg = {
                        pdf: false,
                        excel: false,
                        char: false,
                        fire: false,
                        device: false,
                        portManager: false,
                        taskHistory: false,
                        adhistory: false,
                    };
                    let element = null;
                    reportTags.map((item) => {
                        item.name === '文档' && (type_falg.pdf = true);
                        item.name === 'EXCEL' && (type_falg.excel = true);
                        item.name === '图表' && (type_falg.char = true);
                        item.name === '防火墙' && (type_falg.fire = true);
                        item.name === '设备发现' && (type_falg.device = true);
                        item.name === '端口管理' && (type_falg.portManager = true);
                        item.name === '安全策略' && (type_falg.strategy = true);
                        item.name === '历史分析' && (type_falg.taskHistory = true);
                        item.name === 'AD日志分析' && (type_falg.adhistory = true);
                        if (item.name === '文档') {
                            element = <a style={{ fontWeight: '600', color: 'black' }}
                                href={httpSrc}
                                target="_blank"
                            // target="iframe_report"
                            // onClick={() => {
                            //     this.setState({ visible_report: true, httpSrc, })
                            // }}
                            >{text}</a>;
                        } else if (item.name === 'EXCEL') {
                            element = <a style={{ fontWeight: '600', color: 'black' }}
                                href={httpSrcExcel}
                                target="_blank"
                            >{text}</a>;
                        } else {
                            element = <a style={{ fontWeight: '600', color: 'black' }}
                                onClick={() => {
                                    this.setState({ taskId: itemTask._id });
                                    type_falg.char && this.openChar(itemTask._id);
                                    type_falg.device && (this.setState({ visible_find: true }))
                                    type_falg.fire && (this.props.history.push(`/fireWall:${itemTask._id}`));
                                    type_falg.portManager && (this.props.history.push(`/port${itemTask._id}`));
                                    type_falg.strategy && (this.props.history.push(`/strategy${itemTask._id}`));
                                    type_falg.taskHistory && (this.props.history.push(`/listHistory${itemTask._id}`));
                                    type_falg.adhistory && (this.props.history.push(`/adhistory${itemTask._id}`));

                                }}>{text}</a>;
                        }
                    });
                    return element
                }
            }, {
                title: '状态',
                align: 'center',
                width: '100px',
                dataIndex: 'taskStatus',
                render: (text, item, index) => {
                    return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>)
                }
            }, {
                title: '方式',
                align: 'center',
                dataIndex: 'taskPlan.type',
                render: (text, item, index) => {
                    return (<span>{Utils.Translation(text)}</span>)
                }
            }, {
                title: '开始时间',
                align: 'center',
                dataIndex: 'taskBeginTime'
            }, {
                title: '等级',
                align: 'center',
                dataIndex: 'level'
            }, {
                title: '耗时',
                align: 'center',
                dataIndex: 'taskEndTime',
                render: (text, item, index) => {
                    let elapsedTime = elapsedTimeObj.hasOwnProperty(item.taskName) ? elapsedTimeObj[item.taskName] : text;
                    return <span>{elapsedTime}</span>
                }
            }, {
                title: '任务进度',
                align: 'center',
                width: '200px',
                dataIndex: 'detail',
                render: (text, item, index) => {
                    let progress = progressObj.hasOwnProperty(item.taskName) ? progressObj[item.taskName] : 0;
                    // let progress = parseInt(Math.random()*99+1);
                    // return <Progress percent={progress} status="active" strokeColor={progress<80?(progress>30?"#0079fe":"#fb6260"):"#00cc00"} />
                    let taskStatus = item.taskStatus;
                    return <Progress percent={taskStatus === 'done' ? 100 : progress}
                        status={taskStatus === 'run' ? "active" : (taskStatus === 'done' ? "success" : "normal")}

                    />
                }
            }, {
                title: '操作',
                align: 'center',
                // width: '250px',
                render: (text, itemTask, index) => {
                    return <div>{itemTask.taskStatus === 'run' ?
                        <Button type="danger"
                            onClick={() => { this.TerminalTask(itemTask._id) }}>
                            <Icon type="pause-circle" />终止
                </Button>
                        : <Button type="primary"
                            onClick={() => { this.excuteTask(itemTask._id, itemTask.taskName) }}>
                            <Icon type="play-circle" />执行
                </Button>}
                        <Button type="primary"
                            onClick={() => {
                                let httpSrc = 'http://' + Socket.getAddress() + ':50034/download?taskName=' + itemTask.taskName + '&reportName=report_finished.docx';
                                let httpSrcExcel = 'http://' + Socket.getAddress() + ':50034/download?taskName=' + itemTask.taskName + '&reportName=report.xlsx';
                                let reportTags = itemTask.reportTags;
                                this.setState({ taskId: itemTask._id });
                                reportTags.map((item) => {
                                    switch (item.name) {
                                        case "文档": window.open(httpSrc); break;
                                        case "EXCEL": window.open(httpSrcExcel); break;
                                        case "图表": this.openChar(itemTask._id); break;
                                        case "防火墙": this.props.history.push(`/fireWall:${itemTask._id}`); break;
                                        case "设备发现": this.setState({ visible_find: true }); break;
                                        case "端口管理": this.props.history.push(`/port${itemTask._id}`); break;
                                        case "安全策略": this.props.history.push(`/strategy${itemTask._id}`); break;
                                        case "历史分析": this.props.history.push(`/listHistory${itemTask._id}`); break;
                                        case "AD日志分析": this.props.history.push(`/adhistory`);; break;
                                    }
                                });
                            }}>
                            <Icon type="diff" />结果
                </Button>
                    </div>
                }
            }
        ];

        return (
            <div className="home-body">
                <CustomBreadcrumb arr={["任务管理", "概览"]} />
                <StatiTask />
                <Card className="Card-task">
                    <Row>
                        <Col span={12} offset={6}>
                            <div className="animated bounceInRight">
                                <Search
                                    placeholder="输入任务名称"
                                    onSearch={(value) => {
                                        // console.log(value);
                                        let { _pageSize } = this.state;
                                        this.updataTask(_pageSize, 1, { taskName: value });
                                    }}
                                    enterButton={<Button type="primary" icon="search">搜索</Button>}
                                    size="large"
                                />
                            </div>

                        </Col>
                    </Row>
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>任务列表</span>}
                    extra={
                        <Button type="primary" icon="redo"
                            onClick={() => {
                                this.updataTask(this.state._pageSize, 1, {});
                            }}>重置列表
                </Button>}
                    className="Card-task">
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        size="small"
                        pagination={{
                            current: _current, pageSize: 10, total: this.state.taskTotal,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                // console.log(page, pageSize);
                                this.updataTask(pageSize, page, {});
                            }
                        }}
                        loading={this.state.loading}
                    />

                </Card>
                <StatiHistogram />

                <TaskResult
                    {...paramResult}
                    setVisible={(name) => {
                        let Obj = {};
                        Obj[name] = false;
                        this.setState(Obj);
                    }}
                    onRef={(ref) => { this.child = ref; }}
                />

            </div>
        );
    }
}