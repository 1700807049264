import React from 'react'
import { Button, Icon, Popconfirm, Modal, Spin, message, Card, Table, Tabs, Input, Select } from "antd";
import BasePage from '@/components/BasePage';
import Socket from '@/socket/index'
import Utils from '@/utils/utils'
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
const TabPane = Tabs.TabPane;
const Option = Select.Option;
class GroupList extends React.Component {
    state = {
        dataSource: [],
        loading: false,
        visible_info: false,
        selTagName: "",
        _querys: {}
    }
    componentWillMount() {

        this.updateTableData(10, 1, {});
    }

    updateTableData(pageSize, pageNum, querys) {
        let { _pageSize, _current, _querys } = this.state;
        let bodyJson = {
            pageSize: pageSize || _pageSize,
            pageNum: pageNum || _current,
            querys: querys || _querys
        };
        this.setState({ loading: true, });
        Socket.ExecuteAction('QueryGroupsPage', bodyJson, '/QueryGroupsPage', (resp) => {
            console.log(resp)
            let dataList = resp.data;
            this.setState({ dataSource: dataList, _pageSize: bodyJson.pageSize, _current: bodyJson.pageNum, _querys: bodyJson.querys, loading: false, _total: resp.total || 10 })
        }, (error) => { message.error(error); this.setState({ loading: false }) })
    }
    ActionGroup(bodyJson, APIName, showInfo) {
        let { _pageSize, _current, _querys } = this.state;
        Socket.ExecuteAction(APIName, bodyJson, '/' + APIName, (resp) => {
            console.log(resp)
            this.updateTableData(_pageSize, _current, {});
            message.success(showInfo);
        }, (error) => { message.error(error); })
    }
    render() {
        let { dataSource, loading, _current, _pageSize, _total, visible_info, selTagName, } = this.state;
        const BreadcrumbArr = ["权限管理", "分组列表"];
        const tableTitle = "分组列表";
        const columns = [
            {
                title: "名称",
                dataIndex: "groupName"

            },
            {
                title: "创建用户",
                dataIndex: "createUserId"
            },
            {
                title: "创建时间",
                dataIndex: "createTime"
            },
            {
                title: "操作",
                render: (text, item, index) => {
                    return <Popconfirm title="是否删除该条数据?" onConfirm={() => { this.ActionGroup({ _id: item._id }, "DeleteGroup", "删除成功!"); }}><Button icon="delete" type="danger">删除</Button></Popconfirm>
                }
            },

        ]
        const formList = [
            {
                label: "分组名称",
                type: 'INPUT',
                field: "groupName",
                initialValue: '',
                placeholder: "分组名称"
            },
        ]
        return <div>
            <CustomBreadcrumb arr={BreadcrumbArr} />
            {/* <Card>
                <FilterForm
                    formList={formList}
                    filterSubmit={(querys) => {
                        this.updateTableData(this.state._pageSize, 1, querys)
                    }}
                    resetTab={() => {
                        this.setState({ querys: {} })
                        this.updateTableData(this.state._pageSize, 1, {})
                    }}
                />
            </Card> */}
            <Card title={<span style={{ fontWeight: 600 }}>{tableTitle}</span>}
                style={{ marginTop: 8 }}
                extra={
                    <Button type="primary" style={{ marginLeft: 8 }}
                        icon="edit" onClick={() => this.setState({ visible_info: true })}>新增分组
                    </Button>}>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    loading={loading}
                    size="small"
                    pagination={{
                        current: _current, pageSize: _pageSize, total: _total,
                        onChange: (page, pageSize) => {
                            this.updateTableData(pageSize, page, this.state._querys)
                        }
                    }}
                />
            </Card>
            <Modal
                title="新增分组"
                visible={visible_info}
                onCancel={(e) => { this.setState({ visible_info: false, }); }}
                okText={"新增分组"}
                onOk={() => { this.ActionGroup({ groupName: selTagName }, "AddGroup", "新增成功！"); this.setState({ visible_info: false, }); }}
            >
                <div>
                    <p>分组名称：<Input onChange={(e) => { this.setState({ selTagName: e.target.value }) }} value={selTagName} style={{ width: 300, }} placeholder="标签名称" /></p>
                </div>
            </Modal>
        </div >
    }
}


export default GroupList