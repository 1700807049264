/**
 * Created by YIMINE on 2019/1/24.
 */
import React from 'react'
import { Card, Form, Input, Tabs, Checkbox, Select, Button, message, Row, Col, Spin } from 'antd';
import './newRole.scss'
import Utils from '../../../utils/utils'
import Socket from '../../../socket/index'
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import BaseTree from '@/components/BaseTree/index';
const { Option } = Select;
const TextArea = Input.TextArea;
class newRole extends React.Component {
    state = {
        cardTitle: "新建角色",
        SpinVis: false,
        Tags: [],
        Role: null,
        menuList: [],
        roleCheckedKeys: [],
        checkedKeysResult: [],
    };

    componentWillMount() {
        this.QueryMenu();
        let thisTemp = this;
        let Id = this.props.match.params.Id;
        if (Id) {
            Id = Id.replace(":", "");
            Socket.ExecuteAction('QueryRoleDetail', { _id: Id }, "", (resp) => {
                console.log(resp)
                let Role = resp.role;
                let sysCheckedKeys = Role.rules.selectedKeys;
                thisTemp.setState({
                    roleCheckedKeys: sysCheckedKeys,
                    Role,
                    rule: sysCheckedKeys,
                    cardTitle: "编辑角色",
                });
                thisTemp.props.form.setFieldsValue({
                    roleName: Role.roleName,
                    roleDes: Role.roleDes,
                    tags: Role.tags,
                });
            });

        }
    }
    QueryMenu = () => {
        Socket.ExecuteAction('QueryMenu', {}, "", (result) => {
            this.setState({ menuList: result.data });
        });

    };
    handleSubmit = (e) => {
        let { Role } = this.state;
        e.preventDefault();
        let { roleCheckedKeys } = this.state;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let body = values;
                body.rules = {
                    selectedKeys: roleCheckedKeys,
                };
                console.log(body);
                this.setState({ SpinVis: true });
                if (!Role) {//新增
                    Socket.ExecuteAction("AddRole", body, "", () => {
                        message.success('新增成功');
                        this.props.history.goBack();
                    }, (error) => { this.setState({ SpinVis: false }); Utils.showMessage("error", "信息提示", error) })
                } else {//修改
                    body._id = Role._id;
                    Socket.ExecuteAction("UpdateRole", body, "", () => {
                        message.success('修改成功');
                        this.props.history.goBack();
                    }, (error) => { this.setState({ SpinVis: false }); Utils.showMessage("error", "信息提示", error) })
                }
            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        let { AutoObject = {}, SpinVis,
            cardTitle, menuList, roleCheckedKeys } = this.state;
        return (
            <div className="newRoleDiv">
                <CustomBreadcrumb arr={["系统管理", { title: "角色管理", to: '/role' }, cardTitle]} />
                <Card title={<span style={{ fontWeight: 600 }}>{cardTitle}</span>}>
                    <Spin spinning={SpinVis}>
                        <Row>
                            <Col span={12} className="formDiv">
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Item
                                        label="角色名称"
                                        {...formItemLayout}
                                    >
                                        {getFieldDecorator('roleName', {
                                            rules: [{
                                                type: 'string', message: '请输入正确字符！',
                                            }, {
                                                pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                            }, {
                                                required: true, message: '请输入字符!',
                                            }],
                                            initialValue: AutoObject.name || null
                                        })(<Input />)}
                                    </Form.Item>
                                    <Form.Item
                                        label="角色描述"
                                        {...formItemLayout}
                                    >
                                        {getFieldDecorator('roleDes', {
                                            rules: [{
                                                type: 'string', message: '请输入正确字符！',
                                            },],
                                        })(<TextArea className="TextArea" />)}
                                    </Form.Item>
                                    <Form.Item
                                        label=" "
                                        colon={false}
                                        {...formItemLayout}
                                    >
                                        <div className="btnDiv">
                                            <Button type="primary" icon="check-circle" htmlType="submit"  >
                                                保存
                                            </Button>&nbsp;
                                            <Button type="danger" icon="close-circle"
                                                onClick={() => { this.props.history.goBack(); }}>
                                                取消
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span={12} className="menuTreeDiv">
                                菜单权限：
                            <BaseTree
                                    treeData={menuList}
                                    onCheck={(checkedKeys, info) => {
                                        let checkedKeysResult = [...checkedKeys, ...info.halfCheckedKeys];
                                        console.log(checkedKeys, info);
                                        this.setState({ roleCheckedKeys: checkedKeys, checkedKeysResult })
                                    }}
                                    checkedKeys={roleCheckedKeys}
                                />
                            </Col>
                        </Row>
                    </Spin>
                </Card>
            </div>
        );
    }
}
const newAutoObjectFrom = Form.create()(newRole);

export default newAutoObjectFrom;