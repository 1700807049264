import React, { useState, useEffect } from 'react';
import { Input, Select } from "antd"
const { Option } = Select;
function Tabelemt(props) {
    const [inputVal, setInputVal] = useState("");
    const [UnitVal, setUnit] = useState("");
    useEffect(() => {
        console.log(props, "-----------------------11111");
        return () => { }
    }, []);
    useEffect(() => {
        console.log(props, "-----------------------");
        if (props.fromProps) {
            // const fatherProps = props.inputTimeProps;
            // let fatherVal = fatherProps.value;
            // let _unit = "s";
            // if (fatherVal) {
            //     if (fatherVal.includes("s")) {
            //         fatherVal = fatherVal.replace("s", "");
            //     } else {
            //         fatherVal = fatherVal.replace("m", "");
            //         _unit = "m";
            //     }
            // }
            // setInputVal(fatherVal);
            // setUnit(_unit);
        }
        return () => { }
    }, [props.elemtProps.value]);

    function setFromValue(num = inputVal, unitVal = UnitVal) {
        const value = num + unitVal;
        props.fromProps.setFieldValue(props.name, value);
    }
    return <div >
        <Input
            value={inputVal}
            onChange={(e) => {
                // setInputVal(e.target.value);
                // setFromValue(e.target.value);
            }}
            addonAfter={
                <Select value={UnitVal} style={{ width: 60 }} onChange={(e) => {
                    // setUnit(e);
                    // setFromValue(undefined, e);
                }}>
                    <Option value="s">s</Option>
                    <Option value="m">m</Option>
                </Select>
            }
        />
    </div>
}

export default Tabelemt;
