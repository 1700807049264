import React from 'react'
import { Card, Modal, Input, Button, Form, Progress, Tag, Row, Switch, Table, Tooltip as ToolTip, List, Badge, message, Col, InputNumber, } from "antd";
import AxiosObj from "../../axios";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import http from "../../axios/axios_utils";
import api from "../../config/api/email";
const FormikInput = require("formik-antd").Input;
import {
    SubmitButton,
    FormItem,
    Select
} from "formik-antd"
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik"
// 监控告警后端服务器地址  monitor_address 配置地址：public/flow/config.js
// const backUp_Url = monitor_address;
const email_Url = email_address;
class emailConfig extends React.Component {
    state = {
        dataSource: [],
        _current: 1,
        _pageSize: 20,
        total: 0,
        emailConfig: {},//修改配置当前行的呃数据值
        loading: false,
        visible: false, // 修改弹出框显示判断标志
        visibleTest: false,
        columns: [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'index',
                render: (text, item, index) => {
                    return <span>{index + 1 + (this.state._current - 1) * this.state._pageSize}</span>
                }
            },
            {
                title: '名称',
                align: 'center',
                dataIndex: 'name'
            },
            {
                title: 'SMTP服务器地址',
                align: 'center',
                dataIndex: 'server'
            },
            {
                title: 'SMTP服务器端口',
                align: 'center',
                dataIndex: 'port'
            },
            {
                title: '使用TLS',
                align: 'center',
                dataIndex: 'tls',
                render: (text) => {
                    return text ? <Tag color="#87d068">启用</Tag> : <Tag>关闭</Tag>
                }
            },
            {
                title: '发件人邮箱',
                align: 'center',
                dataIndex: 'username',

            },
            {
                title: '默认配置',
                align: 'center',
                dataIndex: 'is_default',
                render: (text) => {
                    if (text) {
                        return "是"
                    } else {
                        return "否"
                    }
                }

            },
            // {
            //     title: '时间',
            //     align: 'center',
            //     dataIndex: 'time',
            //     sorter : (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime(),
            //
            // },
            // {
            //     title: '描述',
            //     align: 'center',
            //     dataIndex: 'describe',
            // },
            {
                title: '操作',
                width: '15%',
                align: 'center',
                render: (text, item) => {
                    return <div>
                        <Button type="primary" size="small" icon="edit" onClick={() => {
                            console.log("item", item)
                            this.setState({
                                visible: true,
                                emailConfig: item
                            })
                        }}>修改
                        </Button>&nbsp;
                        <Button type="primary" size="small" icon="bug" onClick={() => {
                            this.setState({
                                visibleTest: true,
                                emailConfig: item
                            })
                        }}>测试
                        </Button>
                    </div>
                }
            },
        ],
        selectedRowKeys: [],
        selectedRows: []

    };
    componentWillMount() {
        this.getEmailConfigList();
    }
    //获取邮件发件配置列表，默认获取所有，不分页
    getEmailConfigList = (page, pageSize) => {
        let { _current, _pageSize } = this.state
        let params = {
            page: page || _current,
            size: pageSize || _pageSize,
        }

        let url = email_Url + "/configs";
        AxiosObj.MA_HTTP_GET2(url, params, (response) => {
            //console.log(response)
            //console.log(response.data)
            let data = response.data;

            let list = [];
            console.log(data);
            let total = response.total;
            for (let i in data) {
                let map = {}
                // console.log(i);
                map['key'] = parseInt(i) + 1;
                map['index'] = +parseInt(i) + 1;
                map['server'] = data[i].server;
                map["port"] = data[i].port;
                map['name'] = data[i].name;
                map['id'] = data[i].id
                map['username'] = data[i].username;
                map['tls'] = data[i].tls;
                map['password'] = data[i].password;
                map['is_default'] = data[i].is_default;
                // let statusPassTime = moment(alertList[i].endsAt).format('YYYY-MM-DD HH:mm:ss');
                // map['time'] = statusPassTime;
                // map['describe'] = alertList[i].annotations.description;
                list[i] = map;
            };
            this.setState({
                dataSource: list,
                selectedRowKeys: [],//清空选择
                selectedRows: [],
                total: total,
                _current: page || 1,
                _pageSize: pageSize || _pageSize,
                // state:state

            })
            // console.log(tendency);

        }, (error) => {
            message.error(error)
        })

    }
    //修改发件人配置
    updateEmailConfig = (e) => {
        let { emailConfig } = this.state;
        // e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                let body = values;
                values["id"] = emailConfig.id;//id填充
                //POST 方法第一个参数是与axios中地址的选择
                AxiosObj.MA_HTTP_PUT('email', '/config', body, (data) => {
                    message.success('success');
                    this.getEmailConfigList()
                }, (error) => {
                    message.error(error);
                });
                console.log('finish');
            }
        });
    }
    //设为默认发件人
    editDefaultConfig = () => {
        let { selectedRows } = this.state;
        let id;
        if ((selectedRows.length > 0) && (selectedRows[0].id)) {
            id = selectedRows[0].id
        } else {
            message.error("请先选择发件人");
            return;
        }

        let body = {
            id: id
        }
        AxiosObj.MA_HTTP_PUT('email', '/default', body, (data) => {
            message.success('success');
            this.getEmailConfigList();
        }, (error) => {
            message.error(error);
        });
    }
    //删除发件人配置
    deleteEmailConfig = () => {
        let { selectedRows } = this.state;
        let body = {}
        if ((selectedRows.length > 0) && (selectedRows[0].id)) {
            body["id"] = selectedRows[0].id
        } else {
            message.error("请先选择发件人");
            return;
        }

        http.delete_body(api.config, body, (data) => {
            message.success('success');
            this.getEmailConfigList();
        })
    }
    //测试发送邮件
    testSendEmail = (body) => {
        http.post(api.email, body, (data) => {
            message.success('发送成功');
            this.setState({ visibleTest: false, });
        })
    }
    render() {
        let { dataSource, loading, columns, visible, emailConfig, visibleTest, _current, _pageSize, total } = this.state;
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys:`, selectedRowKeys, 'selectedRows: ', selectedRows);
                this.setState({
                    selectedRowKeys: selectedRowKeys,
                    selectedRows: selectedRows,
                });
            },
            // selections: [
            //     {
            //         key: 'cancel',
            //         text: '清空选择',
            //         onSelect: changableRowKeys => {
            //             this.setState({ selectedRowKeys: [] });
            //         },
            //     },
            // ],
        };
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        return (<div>
            <CustomBreadcrumb arr={["邮件管理", "邮件配置"]} />
            <Card title={<span style={{ fontWeight: 600 }}>发件人配置</span>}
                style={{ marginTop: 8 }}
                extra={true ?
                    <span>
                        <Button type="primary" icon="setting" style={{ marginLeft: 8 }}
                            onClick={() => { this.editDefaultConfig() }}>{/* 记的修改路由 */}
                              设为默认发件人
                             </Button>
                        <Button type="primary" icon="file-add" style={{ marginLeft: 8 }}
                            onClick={() => { this.props.history.push('/email/emailForm'); }}>{/* 记的修改路由 */}
                                    添加邮件配置
                             </Button>
                        <Button type="danger" icon="delete" style={{ marginLeft: 8 }}
                            onClick={() => this.deleteEmailConfig()}>{/* 记的修改路由 */}
                                     删除邮件配置
                             </Button>
                    </span>

                    : ''}
            >
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    rowSelection={{
                        type: 'radio',
                        ...rowSelection
                    }}
                    size="small"
                    loading={loading}
                    rowKey={(text) => { return text.id }}
                    pagination={{
                        current: _current, pageSize: _pageSize, total: total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            this.getEmailConfigList(1, size);
                        },
                        onChange: (page, pageSize) => {
                            //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                            this.getEmailConfigList(page, pageSize);
                        }
                    }}
                />
            </Card>
            <Modal
                width={765}
                title={"测试邮件发送"}
                visible={visibleTest}
                onCancel={() => { this.setState({ visibleTest: false }) }}
                okText="确认"
                footer={null}
                destroyOnClose
            >
                <div>
                    <Formik
                        initialValues={{
                            title: "测试发送主题",
                            content: "测试发送内容",
                            type: "text",
                            receivers: [],
                            name: emailConfig.name || "test"
                        }}
                        onSubmit={(values, actions) => {
                            console.log(values);
                            this.testSendEmail(values);
                            actions.setSubmitting(true)
                        }}
                        validate={(values) => {
                            let info = {};
                            if (!values["title"]) { info["title"] = "主题不能为空!"; }
                            else if (!values["content"]) { info["content"] = "邮件内容不能为空!"; }
                            else if (values["receivers"].length === 0) {
                                info["receivers"] = "接收人邮箱地址不能为空!";
                            };
                            console.log(info);
                            return info
                        }}
                    >
                        {() => {
                            return <FormikForm labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
                                <FormItem
                                    name={"name"}
                                    label={"邮件配置名称"}
                                    required={true}
                                    key={"title"}
                                >
                                    <FormikInput name={"name"} disabled={true} placeholder={"邮件配置名称"} />
                                </FormItem>
                                <FormItem
                                    name={"title"}
                                    label={"邮件主题"}
                                    required={true}
                                    key={"title"}
                                >
                                    <FormikInput name={"title"} placeholder={"邮件主题"} />
                                </FormItem>
                                <FormItem
                                    name={"content"}
                                    label={"邮件内容"}
                                    required={true}
                                    key={"content"}
                                >
                                    <FormikInput name={"content"} placeholder={"邮件内容"} />
                                </FormItem>
                                <FormItem
                                    name={"type"}
                                    label={"邮件类型"}
                                    required={true}
                                    key={"type"}
                                >
                                    <Select name={"type"}>
                                        <Select.Option value={"text"}>text</Select.Option>
                                        <Select.Option value={"html"}>html</Select.Option>
                                        <Select.Option value={"text_appendix"}>text_appendix</Select.Option>
                                        <Select.Option value={"html_appendix"}>html_appendix</Select.Option>
                                    </Select>
                                </FormItem>
                                <FormItem
                                    name={"receivers"}
                                    label={"接收人邮箱地址"}
                                    required={true}
                                    key={"receivers"}
                                >
                                    <Select name={"receivers"} mode="tags">
                                        {/* <Select.Option key={key} value={item.value}></Select.Option> */}
                                    </Select>
                                </FormItem>
                                <div style={{ textAlign: "center", borderTop: "1px solid #e2e2e2", paddingTop: 10 }}>
                                    <SubmitButton disabled={false} icon="rocket">发送</SubmitButton>
                                </div>
                            </FormikForm>
                        }}
                    </Formik>
                </div>
            </Modal>
            <Modal
                width={765}
                title={"修改配置"}
                visible={visible}
                onOk={() => { this.updateEmailConfig(); this.setState({ visible: false }) }}
                onCancel={() => { this.setState({ visible: false }) }}
                okText="确认"
                cancelText="取消"
                // destroyOnClose
                afterClose={() => {
                    // this.setState({ selectOne: {} })
                    this.props.form.resetFields();//清空表单
                }}
            >
                <Row>
                    <Col span={24}>
                        <Form >
                            <Form.Item
                                label="名称"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('name', {
                                    initialValue: emailConfig.name,
                                })(<Input disabled />)}
                            </Form.Item>
                            <Form.Item
                                label="SMTP服务器地址"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('server', {
                                    initialValue: emailConfig.server,
                                    rules: [
                                        // {
                                        //     type: 'string', message: '请输入正确字符！',
                                        // }, {
                                        //     pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                        // },
                                        {
                                            required: true, message: '请输入字符!',
                                        }],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item
                                label="SMTP服务器端口"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('port', {
                                    initialValue: emailConfig.port || 0,
                                    rules: [
                                        // {
                                        //     type: 'string', message: '请输入正确字符！',
                                        // }, {
                                        //     pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                        // },
                                        {
                                            required: true, message: '请输入端口号!',
                                        }],
                                })(<InputNumber style={{ width: "100%" }} />)}
                            </Form.Item>
                            <Form.Item
                                label="使用TLS"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('tls', {
                                    initialValue: emailConfig.tls || false,
                                    valuePropName: 'checked'
                                })(<Switch
                                    checkedChildren="启用" unCheckedChildren="关闭"
                                />)}
                            </Form.Item>
                            <Form.Item
                                label="发件人邮箱"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('username', {
                                    initialValue: emailConfig.username,
                                    rules: [{ type: 'email', required: true }]
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item
                                label="发件人密码"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('password', {
                                    initialValue: emailConfig.password || "",
                                    rules: [{
                                        type: 'string', message: '请输入正确字符！',
                                    },
                                        // {
                                        //     pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                        // }, {
                                        //     required: true, message: '请输入字符!',
                                        // }
                                    ],
                                })(<Input.Password />)}
                            </Form.Item>
                            {/*<Form.Item*/}
                            {/*label="默认发件配置"*/}
                            {/*{...formItemLayout}*/}
                            {/*>*/}
                            {/*{getFieldDecorator('is_default', {*/}
                            {/*initialValue: false,*/}
                            {/*// rules: [{*/}
                            {/*//     type: 'string', message: '请输入正确字符！',*/}
                            {/*// }, {*/}
                            {/*//     pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',*/}
                            {/*// }, {*/}
                            {/*//     required: true, message: '请输入字符!',*/}
                            {/*// }],*/}
                            {/*})(<Switch disabled={this.state.disabled}  />)}*/}
                            {/*</Form.Item>*/}

                        </Form>

                    </Col>
                    <Col span={8}>
                    </Col>
                </Row>

            </Modal>
        </div>)
    }
}
export default Form.create()(emailConfig)