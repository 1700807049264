/**
 * Created by YIMINE on 2019/1/24.
 * 资源、操作、任务数据导出按钮组件
 */
import React from 'react'
import { Typography, Table, Modal, message, Button, Tag, Form, Select, Menu, Dropdown, Icon } from 'antd';
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import FilterForm from "@/components/FilterForm/index";
import BaseForm from "@/components/BaseForm/index";
const { Title } = Typography;
const Option = Select.Option;
class ExportButton extends React.Component {
    state = {
        Tags: [],
        Groups: [],
    };
    componentWillMount() {
    }
    handleSubmit = (_bodyJson) => {
        let { APIName, } = this.props;
        Socket.ExecuteAction(APIName, _bodyJson, "/" + APIName, (result) => {
            message.success("导出成功!");
            console.log(result);
            if (APIName === "DownloadResources") {
                Utils.exportExcel(result.data, "资源列表-" + new Date().toLocaleDateString() + ".xlsx")
            } else {
                // window.open("http://" + Socket.getAddress() + ":50034/download2?fileName=" + result.data);
                const url = "http://" + Socket.getAddress() + ":50034/download2?fileName=" + result.data
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute(
                    'download',
                    "ffff"
                )
                document.body.appendChild(link)
                link.click();
            }
        });
    }
    handleMenuClick(e) {
        let { selectedRowKeys } = this.props;
        console.log(selectedRowKeys);
        if (e.key === "select") {
            if (Utils.isEmpty(selectedRowKeys)) { message.error('请勾选设备！'); return; }
            this.handleSubmit({ _ids: selectedRowKeys });
        } else {
            this.handleSubmit({ _ids: [] });
        }
    }
    render() {
        const BtnMenu = (
            <Menu onClick={(e) => this.handleMenuClick(e)}>
                <Menu.Item key="select">
                    <Icon type="export" />
                    导出选中
              </Menu.Item>
                <Menu.Item key="all">
                    <Icon type="export" />
                    全部导出
              </Menu.Item>
            </Menu>
        );
        return (
            <Dropdown overlay={BtnMenu}>
                <Button type="primary">
                    <Icon type="export" />导出 <Icon type="down" />
                </Button>
            </Dropdown>
        );
    }
}

export default ExportButton;