import axios from "axios";
// var request = require("request");
//get请求
// getAllSysLog = function (options, callback) {
//     request({
//         url: "http://10.34.10.210:9200/_search",
//         method: "get",//如果是post就涉及到跨域的问题了
//         json: true,
//         headers: {
//             "content-type": "application/json",
//         },
// body: {
//     "query": {
//         "match_all": {}
//     }
// }
//         // body:options
//     }, function (error, response, body) {
//         console.log('error-' + error);
//         console.log('response.statusCode-' + response.statusCode);
//         if (!error && response.statusCode == 200) {
//             // console.log(JSON.stringify(body,null,8));
//             callback(body);
//         }
//     });
// };
// getAllSysLog({}, function (body) {
//     console.log(body);
// })
const base_url = "http://10.34.10.210:9200/_search";
const device_url = "http://10.34.10.210:9200/_search";
// const sysLog_url = "http://10.32.79.54:9200/logflag/_search";
const sysLog_url = "http://10.32.79.54:9200/produce_zjnms*/_search";
axios.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem("token");
        token && (config.headers['X-Access-Token'] = token);
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
export default class Axios {
    static GET(options, callback) {
        axios.get(base_url, options).then(
            (response) => {
                // console.log(response);
                if (response.status == 200) {
                    // console.log(JSON.stringify(body,null,8));
                    callback(response.data.hits);
                }
            }
        ).catch((error) => {
            console.log(error);
        })
    }
    static GETSysLog(options, callback) {
        axios.get(sysLog_url, options).then(
            (response) => {
                // console.log(response);
                if (response.status == 200) {
                    // console.log(JSON.stringify(body,null,8));
                    callback(response.data.hits);
                }
            }
        ).catch((error) => {
            console.log(error);
        })
    }
    static PostSysLog(options, callback) {
        console.log(options);
        axios.post(sysLog_url, options).then(
            (response) => {
                // console.log(response);
                if (response.status == 200) {
                    // console.log(JSON.stringify(body,null,8));
                    callback(response.data.hits);
                }
            }
        ).catch((error) => {
            console.log(error);
        })
    }
    static POSTDevice(options, callback) {
        axios.post(device_url, options).then(
            (response) => {
                // console.log(response);
                if (response.status == 200) {
                    // console.log(JSON.stringify(body,null,8));
                    callback(response.data.hits);
                }
            }
        ).catch((error) => {
            console.log(error);
        })
    }
}


