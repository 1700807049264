import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, Divider, Popconfirm, Row, Col, Descriptions, Input, Typography, Drawer, Modal, message,
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import EditModal from '@/components/EditModal/index';
import BaseForm from '@/components/FormikForm/index';
import TreeCompoent from '../type/tree';
import RightCard from '../utils/RightCard';
import { connect } from 'react-redux'
import http from '@/axios/axios_utils';
import { AddDevice, GetDeviceInfo, GetModelTree, deleteDevice } from '../utils/api';
import {
    SubmitButton,
    FormItem,
    TreeSelect,
    Cascader
} from "formik-antd"
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik"
import "./index.scss"
import "animate.css"
import { Label } from 'bizcharts';
function DeviceMange(props) {
    const [visible, setVisible] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [total, setTotal] = useState(10);
    const [_pageSize, setPageSize] = useState(20);
    const [_current, setCurrent] = useState(1);
    const [selectItem, setSelectItem] = useState({});
    const [selectedRowKeys, setRowKeys] = useState([])
    const [filterList, setFiltermList] = useState({
        vendor: [],
        class: [],
        type: [],
        version: [],
    })
    const [treeData, setTreeData] = useState([])
    const [tableData, setTabData] = useState([])
    const columns = [
        {
            title: '序号',
            width: 40,
            dataIndex: 'index',
            width: 50,
            ellipsis: true,
            render: (text, item, key) => <span>{_pageSize ? (key + 1 + (_current - 1) * _pageSize) : key + 1}</span>
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            // width: 100,
            ellipsis: true,
            render: (text, item) => {
                return <a>{text}</a>
            }
        },
        {
            title: '主机名',
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '厂商',
            dataIndex: 'vendor',
            ellipsis: true,
            filters: filterList.vendor,
            onFilter: (value, record) => record.vendor.includes(value),
        },
        {
            title: '类型',
            dataIndex: 'class',
            ellipsis: true,
            filters: filterList.class,
            onFilter: (value, record) => record.class.includes(value),
        },
        {
            title: '型号',
            dataIndex: 'type',
            ellipsis: true,
            filters: filterList.type,
            onFilter: (value, record) => record.type.includes(value),
        },
        {
            title: '版本',
            dataIndex: 'version',
            ellipsis: true,
            filters: filterList.version,
            onFilter: (value, record) => record.version.includes(value),
        },
        {
            title: '端口',
            dataIndex: 'port',
            ellipsis: true,
        },
        // {
        //     title: '操作',
        //     render: (text, item) => {
        //         return <div>
        //             <a onClick={() => { setSelectItem(item); setVisible(true) }}><Icon type="edit" /></a>
        //             <Divider type="vertical" />
        //             <Popconfirm
        //                 title="你确定删除所选记录吗?"
        //                 onConfirm={() => { deleteData(item) }}
        //                 okText="是"
        //                 cancelText="否"
        //             >
        //                 <a><Icon type="delete" /></a>
        //             </Popconfirm>
        //         </div>
        //     }
        // },
    ]
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
        },
        columnWidth: 50,
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    useEffect(() => {//componentDidMount;
        http.setDispatch(props.dispatch)
        getDevice();
        getMenuData();
        return () => { //componentWillUnmount
        }
    }, []);
    function saveDataSource(val) {
        http.post(AddDevice(), val, (r) => {
            getDevice();
        })
        setVisible(false)
    }
    function deleteData() {
        let deleteDevices = {};
        deleteDevices.ips = selectedRowKeys;
        if (deleteDevices.ips.length === 0) {
            message.error('未选中设备');
        } else {
            http.delete_body(deleteDevice(), deleteDevices, (r) => {
                getDevice({ cur_page: _current, page_size: _pageSize });
            })
            message.success('删除成功');
            setRowKeys([]);
        }
        // let temp = tableData.filter(item => item.name != val.name);
        // setTabData(temp);
    }
    function getMenuData() {
        http.get(GetModelTree(), {}, (r) => {
            let TreeData = r.data;
            const newFirmList = [];
            const newClassList = [];
            const newTypeList = [];
            const newVersionList = [];
            const generateList = (data, parentKey) => {
                const temp = [];
                for (let i = 0; i < data.length; i++) {
                    const node = data[i];
                    let { title } = node;
                    let value = title;
                    let label = title;
                    let newNode = { label, value, key: parentKey + "-" + i }
                    if (node.children) {
                        newNode.children = generateList(node.children, parentKey + "-" + i)
                    }
                    temp.push(newNode);

                    const flag = newNode.key.split("-").length;
                    // 设备厂商筛选条件
                    if (flag === 2 && !newFirmList.includes(title)) {
                        newFirmList.push(title);
                    }
                    //设备类型筛选条件
                    if (flag === 3 && !newClassList.includes(title)) {
                        newClassList.push(title);
                    }
                    //设备型号筛选条件
                    if (flag === 4 && !newTypeList.includes(title)) {
                        newTypeList.push(title);
                    }
                    //设备版本筛选条件
                    if (flag === 5 && !newVersionList.includes(title)) {
                        newVersionList.push(title);
                    }

                }
                return temp;
            };
            TreeData = generateList(TreeData, 0);
            // console.log(TreeData)
            setTreeData(TreeData);
            setFiltermList({
                vendor: newFirmList.map((r) => ({ text: r, value: r })),
                class: newClassList.map((r) => ({ text: r, value: r })),
                type: newTypeList.map((r) => ({ text: r, value: r })),
                version: newVersionList.map((r) => ({ text: r, value: r })),
            });
        })
    };
    function getDevice(bodyJson) {
        let param = bodyJson || {
            cur_page: _current,
            page_size: _pageSize,
        };
        http.get(GetDeviceInfo(), param, (r) => {
            const t = r.result;
            t.map((item, index) => {
                item.index = index + 1;
            })
            setTabData(t);
            setSelectItem(t[0] || {});
            setTotal(r.total);
        })
    }
    function changePage(page, pageSize) {
        let bodyJson = {}
        bodyJson.cur_page = page;
        bodyJson.page_size = pageSize;
        setCurrent(page);
        setPageSize(pageSize)
        getDevice(bodyJson);
    }
    function searchDevice(querys) {
        let bodyJson = {};
        // console.log(querys)
        bodyJson.ip = querys.ip || "";
        bodyJson.hostname = querys.hostname || "";
        bodyJson.param = querys.param || "";
        getDevice(bodyJson);
        // const { ip, param, name } = val;
        // let newData = tableData;
        // if (ip) {
        //     newData = tableData.filter((i) => i.ip.indexOf(ip) != -1);
        // }
        // if (name) {
        //     newData = tableData.filter((i) => i.name.indexOf(name) != -1);
        // }
        // if (param && param.length > 0) {
        //     newData = tableData.filter((i) =>
        //         i.vendor === param[0] &&
        //         i.class === param[1] &&
        //         i.type === param[2] &&
        //         i.version === param[3]
        //     );
        // }
        // setTabData(newData)
    }
    function setRowClass(r) {
        return r.ip === selectItem.ip ? 'click-Row-Style' : '';
    }
    function clickMenu(flag) {
        if (drawerVisible) {

        } else {
            document.getElementById("icon_top") && document.getElementById("icon_top").click();
        }
        if (flag) {
            document.getElementById("icon_top") && document.getElementById("icon_top").click();
        }
    }
    return <div className="emulatorDevice">
        <CustomBreadcrumb arr={["模拟器管理", "设备管理"]} />
        <Row gutter={10}>
            <Col span={24}>
                <Card
                    title="设备列表"
                    className="tableCard"
                    extra={<div>
                        <Button type="primary" icon="file-add" onClick={() => {
                            setSelectItem({}); setVisible(true);
                        }}>添加</Button>&nbsp;
                        <Button type="danger" icon="delete" onClick={() => {
                            Modal.confirm({
                                title: "提示",
                                content: "你确定删除选中设备吗？",
                                okType: "danger",
                                onOk() {
                                    deleteData();
                                }
                            })
                        }}>删除</Button>
                        {/* <Button icon="reload" onClick={() => {
                            getDevice()
                        }}>刷新</Button> */}
                    </div>}
                >
                    <div className="formikDiv">
                        <BaseForm
                            onSubmit={(val) => {
                                setCurrent(1);
                                searchDevice(val);
                            }}
                            onCancel={() => { setCurrent(1); getDevice() }}
                            initialValues={{}}
                            formList={[
                                { type: "input", name: "ip", label: "设备IP", placeholder: "设备IP地址", },
                                { type: "input", name: "name", label: "主机名", placeholder: "主机名", },
                                {
                                    type: "expandForm", name: "param", label: "类型信息",
                                    formContent: <div>
                                        <Cascader
                                            name={"param"}
                                            label={"类型信息"}
                                            required={true}
                                            key={"param"}
                                            options={treeData}
                                            placeholder="请选择类型信息"
                                        />
                                    </div>
                                },
                            ]}
                        />
                    </div>
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        size="small"
                        rowKey={r => r.ip}
                        pagination={{
                            current: _current,
                            total: total,
                            defaultPageSize: _pageSize,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["10", "20", "30", "40", "50"],
                            showSizeChanger: true,
                            onChange: (page, pageSize) => {
                                changePage(page, pageSize)
                            },
                            onShowSizeChange: (page, pageSize) => {
                                changePage(1, pageSize)
                            }
                        }}
                        onRow={record => {
                            return {
                                onClick: event => {
                                    setSelectItem(record);
                                    setDrawerVisible(true);
                                    clickMenu();
                                }
                            }// 点击行
                        }}
                        scroll={{ y: 650 }}
                        rowClassName={(r) => setRowClass(r)}
                        rowSelection={rowSelection}
                    />
                </Card>
            </Col>
            {/* <Col span={12}>
                <Card
                    title="设备信息"
                    className="tableCard">
                    <RightCard selectItem={selectItem} {...props} />
                </Card>
            </Col> */}
        </Row>
        <Drawer
            title="设备信息"
            placement="right"
            width={400}
            mask={false}
            onClose={() => { setDrawerVisible(false); clickMenu(true) }}
            visible={drawerVisible}
        >
            <RightCard selectItem={selectItem} {...props} />
        </Drawer>
        <Modal
            title="编辑设备类型信息"
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
            width="55%"
            destroyOnClose
        >
            <div className="EditorformModalDiv">
                <Formik
                    onSubmit={(values, actions) => {
                        console.log(values);
                        const typeList = values["typeSel"];
                        const param = {
                            ip: values.ip,
                            hostname: values.name,
                            port: values.port,
                            vendor: typeList[0] || "",
                            class: typeList[1] || "",
                            type: typeList[2] || "",
                            version: typeList[3] || "",
                        }
                        saveDataSource(param);
                        actions.setSubmitting(false)
                    }}
                    validate={(values) => {
                        let info = {};
                        if (!values.ip) {
                            info.ip = "请填写完整IP信息";
                        } else if (!values.name) {
                            info.name = "请填写完整主机名信息";
                        } else if (!values.port) {
                            info.port = "请填写完整端口信息";
                        } else if (values.typeSel.length === 0) {
                            info.typeSel = "请选择完整信息";
                        }
                        return info
                    }}
                    initialValues={{
                        ip: "",
                        name: "",
                        port: "",
                        typeSel: []
                    }}
                >
                    {() => {
                        return <FormikForm labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                            <FormItem
                                name={"ip"}
                                label={"IP"}
                                required={true}
                                key={"ip"}
                            >
                                <FormikInput name={"ip"} placeholder={"设备IP"} />
                            </FormItem>
                            <FormItem
                                name={"name"}
                                label={"主机名"}
                                required={true}
                                key={"name"}
                            >
                                <FormikInput name={"name"} placeholder={"主机名"} />
                            </FormItem>
                            <FormItem
                                name={"port"}
                                label={"端口"}
                                required={true}
                                key={"port"}
                            >
                                <FormikInput name={"port"} placeholder={"端口"} />
                            </FormItem>
                            <Row>
                                <Col span={3} className="ant-form-item-label"><label className="ant-form-item-required">类型信息</label></Col>
                                <Col span={20} className="ant-form-item-control">
                                    <Cascader
                                        name={"typeSel"}
                                        label={"类型信息"}
                                        required={true}
                                        key={"typeSel"}
                                        // dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        options={treeData}
                                        placeholder="请选择"
                                    // treeDefaultExpandAll
                                    />
                                </Col>
                            </Row>
                            <div style={{ textAlign: "center", borderTop: "1px solid #e2e2e2", paddingTop: 10, margin: 10 }}>
                                <SubmitButton disabled={false} icon="save">保存</SubmitButton>&nbsp;
                                <Button icon="close" onClick={() => { setVisible(false) }}>关闭</Button>
                            </div>
                        </FormikForm>
                    }}
                </Formik>
            </div>
        </Modal>

    </div>
}

export default connect()(DeviceMange)
