import axios from "axios";
import React from 'react';
import G6 from '@antv/g6';
import '../css/index.scss';
import { message, Button, Cascader, Modal, Form, Input, Select, notification, Card, Drawer, Table } from 'antd';
import { Route } from "react-router";
import qs from 'qs';
import WarpEditForm from "./editNode";

const { confirm } = Modal;

const options = [
    {
        value: "default",
        label: "默认模式",
    },
    {
        value: "addNode",
        label: "添加节点",
    },
    {
        value: "addEdge",
        label: "添加边"
    }
]


function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}


class FindTopologyForm extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        console.log(this.props.topology_id)

    }

    render() {

        const { form } = this.props;
        const { getFieldDecorator, getFieldsError } = form;

        return <div style={{ marginTop: 13 }}>
            <Form layout='inline' onSubmit={this.props.submitForm}>
                <div style={{ display: 'none' }}>
                    <Form.Item label="topology_id">
                        {getFieldDecorator('topology_id', {
                            initialValue: this.props.topology_id
                        })
                            (<Input />)}
                    </Form.Item>
                </div>
                <Form.Item label="根据指定IP发现设备">
                    {getFieldDecorator('initIP', {
                        rules: [{ required: true, message: '必须填写一个ip' },
                        { pattern: '\^(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\.(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\.(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\.(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])$', message: '请输入合法的IP地址' },
                        ],
                    })(<Input />)}
                </Form.Item>
                <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                    提交
            </Button>
            </Form>
        </div>
    }
}

const WarpTopologyForm = Form.create()(FindTopologyForm);




class Topology1 extends React.Component {
    state = {
        loading: false,
        graph: null,
        mode: null,
        deviceTye: null,
        visible: false,
        canvasX: null,
        canvasY: null,
        currentNode: null,
        editNodeFormVisiable: false,
        drawerVisible: false,
        nodeDetail: null,
        dataSource: null

    }
    constructor(props) {
        super(props);
    }

    //保存数据
    save = () => {
        let _this = this;
        _this.setState({ loading: true })
        const data = this.state.graph.save();
        console.log();
        axios.post("http://" + topo_address + "/topology/saveData/", {
            "aa": data
        }).then(function (response) {
            console.log(response)
            _this.setState({ loading: false })
        })
    }

    editNodeOk = (values) => {
        const { id } = values
        const item = this.state.graph.findById(id)
        this.state.graph.updateItem(item, values);
        this.setState({ currentNode: null })
        this.setState({ editNodeFormVisiable: false })
    }

    editNodeCancel = () => {
        this.setState({ editNodeFormVisiable: false })
    }

    //表格行属性
    rowClassName = (record, index) => {
        console.log(record);
    }

    //选择设备类型
    modalOk = e => {
        let _this = this;

        this.props.form.validateFields((err, values) => {
            console.log(_this.props.topology_id);

            if (!err) {
                values.x = _this.state.canvasX
                values.y = _this.state.canvasY
                values.ips = values.ips.join(',');
                values.topology_id = _this.props.topology_id;
                axios.post("http://" + topo_address + "/topology/addNode", values).then(function (response) {
                    notification['success']({
                        message: '设备已入库',
                        description:
                            "设备信息已存入数据库中",
                    });
                    console.log(response.data)
                    let res = response.data
                    res.id = res.id + ""
                    res.label = res.deviceType
                    if (res.deviceType == "router") {
                        res.img = "/image/router.png"
                    } else if (res.deviceType == "L3 Switch") {
                        res.img = "/image/l3-switch.png"
                    }

                    _this.state.graph.addItem('node', res);
                    _this.setState({ visible: false })

                    _this.props.form.resetFields();
                    _this.setState({ visible: false })

                })
            }
        })
    }

    modalCancel = () => {
        this.setState({ visible: false })
        this.props.form.resetFields();
    }


    //选择模式
    modesChange = (value) => {
        console.log(value);
        const mode = value[0];
        console.log(mode)
        this.state.graph.setMode(mode)
    }

    //编辑节点
    editNode = (node) => {
        console.log("success")
        this.setState({ editNodeFormVisiable: true })
    }

    // 删除节点
    deleteNode = (node) => {
        const { id } = this.state.currentNode.item.getModel();
        let ids = this.state.currentNode.item.getEdges().map(i => { return i.getModel().id });
        confirm({
            title: '你确定删除这个节点吗？删除后不可恢复',
            okType: 'danger',
            okText: 'yes',
            cancelText: 'no',
            onOk: () => {
                let _this = this;
                function deleteNode(id) {
                    axios.delete("http://" + topo_address + "/topology/threeDevice/deleteNode/" + id + "/");
                }

                function deleteEdges(eids) {
                    axios.delete("http://" + topo_address + "/topology/edge/deleteEdge", {
                        params: {
                            ids: eids
                        },
                        paramsSerializer: function (params) {
                            return qs.stringify(params, { indices: false })
                        }
                    })
                }
                axios.all([deleteNode(id), deleteEdges(ids)]).then(axios.spread(function (res, res2) {
                    message.success("删除成功");
                    //_this.state.currentNode.item.getEdges().map(i=>{return i}).forEach(v=>_this.state.graph.removeItem(v));
                    _this.state.graph.removeItem(_this.state.currentNode.item)
                    console.log(_this.state.graph)
                }))
            },
            onCancel: () => {

            }

        })
    }

    //抽屉相关
    showDrawer = () => {
        this.setState({
            drawerVisible: true,
        });
    };

    onClose = () => {
        this.setState({
            drawerVisible: false,
        });
    };


    componentWillReceiveProps(nextProps) {

        if (JSON.stringify(nextProps.data) !== JSON.stringify({}) && JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
            let data = nextProps.data;
            data.edges.map(edge => {
                edge.source = edge.source + ""
                edge.target = edge.target + ""
            })
            data.nodes.map(node => {
                node.id = node.id + ""
                node.label = node.deviceType
                if (node.deviceType === "router") {
                    node.img = "/image/router.png"
                } else if (node.deviceType === "L3 Switch") {
                    node.img = "/image/l3-switch.png"
                }
            })

            console.log(data)
            this.state.graph.data(data);


            this.state.graph.on('node:dblclick', ev => {
                this.setState({ nodeDetail: ev.item.getModel() })
                const nodeDetail = ev.item.getModel();

                const dataSource = [
                    {
                        key: '1',
                        prop: 'ip',
                        value: nodeDetail.ip,
                    },
                    {
                        key: '2',
                        prop: '设备类型',
                        value: nodeDetail.deviceType,
                    },
                    {
                        key: '3',
                        prop: '品牌',
                        value: nodeDetail.brand
                    },
                    {
                        key: '4',
                        prop: '设备描述',
                        value: nodeDetail.sysDescr
                    },
                    {
                        key: '5',
                        prop: '设备名称',
                        value: nodeDetail.sysName
                    }
                ];
                const columns = [
                    {
                        title: '属性',
                        dataIndex: 'prop',
                        key: 'prop',
                        width: 100,
                        align: 'center',
                        className: 'columnStyle'
                    },
                    {
                        title: '值',
                        dataIndex: 'value',
                        key: 'value',
                    },
                ];
                this.setState({ dataSource: dataSource, columns: columns })
                this.showDrawer()
            })
            this.state.graph.on('node:dragend', ev => {
            })

            //右键菜单
            this.state.graph.on('node:contextmenu', evt => {
                console.log(evt.item.getModel())
                evt.preventDefault();
                evt.stopPropagation();
                this.contextmeun.style.left = `${evt.x}px`;
                this.contextmeun.style.top = `${evt.y}px`;
                console.log(this.contextmeun)
                this.setState({ currentNode: evt });

            })

            this.state.graph.on('node:mouseleave', evt => {

            });
            this.state.graph.render();
        }
    }
    componentDidMount() {
        this.getChart();
        let this1 = this;
        G6.registerBehavior("add-node", {
            getEvents() {
                return {
                    'canvas:click': 'onClick'
                };
            },
            onClick(ev) {
                this1.setState({ visible: true, canvasX: ev.canvasX, canvasY: ev.canvasY })
            }
        });
        G6.registerBehavior('add-edge', {
            getEvents() {
                return {
                    'node:click': 'onClick',
                    mousemove: 'onMousemove',
                    'edge:click': 'onEdgeClick'
                };
            },
            onClick(ev) {
                const node = ev.item;
                const graph = this.graph;
                const point = {
                    x: ev.x,
                    y: ev.y
                };
                const model = node.getModel();
                let _this = this;
                if (this.addingEdge && this.edge) {
                    axios.post("http://" + topo_address + "/topology/addEdge", { source: parseInt(this.edge.getSource().getModel().id), target: parseInt(model.id), topology_id: this1.props.topology_id })
                        .then(function (response) {
                            console.log(response.data)
                            graph.updateItem(_this.edge, {
                                id: response.data,
                                target: model.id
                            });
                            console.log(graph)
                            _this.edge = null
                            _this.addingEdge = false;
                        })

                } else {
                    this.edge = graph.addItem('edge', {
                        id: 9999,
                        source: model.id,
                        target: model.id
                    });
                    this.addingEdge = true;
                }
            },
            onMousemove(ev) {
                const point = {
                    x: ev.x,
                    y: ev.y
                };
                if (this.addingEdge && this.edge) {
                    this.graph.updateItem(this.edge, {
                        target: point
                    })
                }
            },
            onEdgeClick(ev) {
                console.log("hello")
                const currentEdge = ev.item;
                if (this.addingEdge && this.edge == currentEdge) {
                    this.graph.removeItem(this.edge);
                    this.edge = null;
                    this.addingEdge = false
                }
            }

        });
        this.contextmeun.addEventListener('mouseleave', e => {
            this.contextmeun.style.left = "-300px";
        })
    };
    handleInputIp = (rule, value, callback) => {
        //const {getFieldValue} = this.props.form;
        axios.get("http://" + topo_address + "/topology/checkIPAvailability/" + value + "/").then(function (response) {
            if (response.data === true) {
                callback("ip已被使用")
            }
            callback();
        })
    }
    getChart() {
        let graph = null;
        var div = document.getElementById("container")
        var width = div.style.width || div.clientWidth || div.offsetWidth || div.scrollWidth;
        // console.log(div.clientWidth,div.offsetWidth,div.scrollWidth);

        graph = new G6.Graph({
            container: "container",
            height: 800,//ref.current.clientHeight||800,
            width: width - 200,//ref.current.clientWidth,
            layout: {
                //type: 'force',
                //linkDistance: 150, // 设置边长为 100
                preventOverlap: true, // 设置防止重叠
            },
            modes: {
                default: [
                    'drag-canvas',
                    {
                        type: 'zoom-canvas',
                        enableDelegate: true
                    },
                    'drag-node',
                    'click-select',
                    {
                        type: 'tooltip',
                        formatText(model) {
                            var text = "";
                            text += "ip: " + model.ip + "</br>" + "设备品牌: " + model.brand + "</br>" + "设备类型: " + model.deviceType;
                            return text;
                        }
                    },
                    'node-detail'
                ], // 允许拖拽画布、放缩画布、拖拽节点
                addNode: ['click-select', 'add-node'],
                addEdge: ['click-select', 'add-edge'],
            },
            nodeStateStyles: {
                selected: {
                    opacity: 0.7
                }
            },
            //plugins: [grid,minimap], // 将 grid 实例配置到图上
            defaultNode: {
                type: 'image',
                size: [70, 50],
                img: '',
                label: '',
                labelCfg: {
                    position: "bottom",
                    offset: -8
                }

            },
            defaultEdge: {
                style: {
                    stroke: '#777',
                    lineWidth: 3,
                    lineAppendWidth: 5,
                    cursor: "pointer"
                },
                labelCfg: {
                    autoRotate: true,
                    position: "start",

                },
            },
        });

        this.setState({ graph: graph })
    }

    submitForm = () => {
        const { form } = this.topologyForm.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            const { topology_id, initIP } = values
            notification.open({
                message: '设备发现开始',
                description: '正在探测设备，请勿进行其它操作'
            })
            axios.get('http://' + topo_address + '/topology/topologyByIp/' + topology_id + '/' + initIP + '/').then(function () {
                notification.open({
                    message: '设备探测完成',
                    description: '请重新点击列表！！！'
                })
            })
        })
    }
    render() {
        const { TextArea } = Input
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };
        return <div>
            <Card style={{ marginTop: 8, overflow: 'hidden', position: 'relative' }}>
                <span style={{ fontWeight: "bold", color: "#666" }}>模式切换&nbsp;&nbsp;</span>
                <Cascader options={options} defaultValue={["default"]} allowClear={false} onChange={this.modesChange}></Cascader>
                <Button style={{ float: "right" }} type="primary" loading={this.state.loading} onClick={this.save}>
                    保存图
                </Button>

                <WarpTopologyForm topology_id={this.props.topology_id} wrappedComponentRef={(form) => this.topologyForm = form} submitForm={this.submitForm} />

                <div id="container" style={{ position: 'relative', overflow: 'hidden' }}>
                    <div ref={ref => { this.contextmeun = ref }} className="contextMenu">
                        <div className="contextMenu--option" onClick={(e) => this.editNode(this.state.currentNode, e)}>编辑节点</div>
                        <div className="contextMenu--option" onClick={e => this.deleteNode(this.state.currentNode)}>删除节点</div>
                    </div>
                    {this.state.nodeDetail && <Drawer
                        title={`${this.state.nodeDetail.deviceType}设备详情`}
                        placement="right"
                        closable={true}
                        onClose={this.onClose}
                        visible={this.state.drawerVisible}
                        getContainer={false}
                        style={{ position: 'absolute' }}
                        destroyOnClose={true}
                        width={384}
                        keyboard={true}
                    >
                        <Table dataSource={this.state.dataSource} pagination={false} showHeader={false} columns={this.state.columns}
                            rowClassName={this.rowClassName}
                        ></Table>
                    </Drawer>}
                </div>
            </Card>
            <Modal
                title="配置设备参数"
                visible={this.state.visible}
                onOk={this.modalOk}
                onCancel={this.modalCancel}
            >
                <Form {...formItemLayout}>
                    <Form.Item label="选择设备类型">
                        {getFieldDecorator('deviceType', {
                            rules: [{ required: true, message: 'Please input the title of collection!' },],
                            initialValue: "router"
                        })(<Select>
                            <Option value="router">路由器</Option>
                            <Option value="L3 Switch">交换机</Option>
                        </Select>)}
                    </Form.Item>
                    <Form.Item label="IP">
                        {getFieldDecorator('ip', {
                            rules: [{ required: true, message: '必须填写一个ip' },
                            { pattern: '\^(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\.(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\.(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\.(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])$', message: '请输入合法的IP地址' },
                            { validator: this.handleInputIp }
                            ],
                            validateFirst: true
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="品牌">
                        {
                            getFieldDecorator('brand', {
                                initialValue: "CISCO"
                            })(
                                <Select>
                                    <Option value="CISCO">思科</Option>
                                    <Option value="H3C">H3C</Option>
                                    <Option value="华为">华为</Option>
                                </Select>)
                        }
                    </Form.Item>
                    <Form.Item label="端口">
                        {getFieldDecorator('ips', {
                        })(<Select mode="tags" open={false}></Select>)}
                    </Form.Item>
                    <Form.Item label="sysDescr">
                        {getFieldDecorator('sysDescr', {})(<TextArea rows={4} />)}
                    </Form.Item>
                    <Form.Item label="sysName">
                        {getFieldDecorator('sysName', {})(<Input />)}
                    </Form.Item>
                </Form>
            </Modal>
            <WarpEditForm editNodeFormVisiable={this.state.editNodeFormVisiable} node={this.state.currentNode} editNodeOk={this.editNodeOk} editNodeCancel={this.editNodeCancel}></WarpEditForm>
        </div>
    }
}

const WrapTopology1 = Form.create()(Topology1);
export default WrapTopology1;