import React from 'react'
import DeletePage from "./delete";
import AddPage from "./add";
import {
    Tabs
} from "antd";

class AuditPage extends React.Component {
    state = {

    }
    componentWillMount() {
    }
    componentDidMount() {
        // this.props.onRef(this);
    }


    render() {
        const { } = this.state;



        return (
            <div className="auditPage">
                <Tabs>
                    <Tabs.TabPane key="add" tab="最近新增"><AddPage /></Tabs.TabPane>
                    <Tabs.TabPane key="delete" tab="最近删除"><DeletePage /></Tabs.TabPane>
                </Tabs>
            </div>
        )
    }
}

export default AuditPage;
