import React from 'react'
import { Input, Button, Icon, message, Modal, Form, Row, Col, } from "antd";
import Utils from "@/utils/utils";
import axios from '@/axios/axios_utils';
import moment from 'moment';
import { SubmitButton, FormItem, DatePicker, Select } from "formik-antd";
import { Formik } from "formik";
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
const { Option } = Select;
const hub_Url = "http://" + backup_address + "/ZJNMS_V1/hub/";

export default class MacAddressList extends React.Component {
    state = {
        newVisible: false      
    }
    componentWillMount() {

    }
    closeModal() {
        this.setState({ newVisible: false });
        this.props.onCancel();
    }
    addFixedResources(value) {
        let { resItem } = this.props;
        if(resItem) { // 编辑
            let bodyJson = {
                _id: resItem._id,
                ...value
            }
            axios.put(hub_Url + "fixedResources", bodyJson, (result) => {
                if (result.code === "00") {
                    message.success("修改成功")
                    this.setState({ importVisible: false });
                    this.props.getData();
                    this.closeModal();
                } else {
                    message.error("修改失败")
                }
            })
        } else { // 新增
            axios.post(hub_Url + "fixedResources", [value], (result) => {
                if (result.code === "00") {
                    message.success("新增成功")
                    this.setState({
                        importVisible: false,
                        fileList: [],
                        excelData: {},
                    });
                    this.props.getData(1, 20, {});
                    this.closeModal();
                } else {
                    message.error("导入失败")
                }
            })
        }
    }

    render() {
        let { newVisible, resItem } = this.props;

        return <div>
            <Modal
                title={ resItem ? "编辑固定资产" : "新增固定资产"}
                visible={newVisible}
                onCancel={() => this.closeModal()}
                footer={null}
                width={"60%"}
                destroyOnClose
            >
                <Formik
                    onSubmit={(value, actions) => { this.addFixedResources(value); actions.setSubmitting(false); }}
                    validate={(values) => {
                        let info = {};
                        if (!values.name) {
                            info.name = "请输入设备名称";
                        }
                        if (!values.mac_address) {
                            info.mac_address = "请输入mac地址";
                        }
                        return info
                    }}
                    initialValues={{
                        name: resItem ? resItem.name : "",
                        mac_address: resItem ? resItem.mac_address : "",
                        switch_ip: resItem ? resItem.switch_ip : "",
                        port: resItem ? resItem.port : "",
                        insert_time: resItem ? resItem.insert_time : "",
                        check_status: resItem ? resItem.check_status.toString() : [],
                    }}
                >
                    <FormikForm labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                        <FormItem
                            name={"name"}
                            label={"名称"}
                            key={"name"}
                            required={true}
                        >
                            <FormikInput name={"name"} placeholder={"请输入设备名称"} />
                        </FormItem>
                        <FormItem
                            name={"mac_address"}
                            label={"mac地址"}
                            key={"mac_address"}
                            required={true}
                        >
                            <FormikInput name={"mac_address"} placeholder={"请输入mac地址"} />
                        </FormItem>
                        { resItem ? <div>
                            <FormItem
                                name={"switch_ip"}
                                label={"接入交换机IP"}
                                key={"switch_ip"}
                            >
                                <FormikInput name={"switch_ip"} placeholder={"请输入交换机IP信息"} />
                            </FormItem>
                            <FormItem
                                name={"port"}
                                label={"接入端口"}
                                key={"port"}
                            >
                                <FormikInput name={"port"} placeholder={"请输入端口信息"} />
                            </FormItem>
                            <FormItem
                                name={"insert_time"}
                                label={"最近接入时间"}
                                key={"insert_time"}
                            >
                                <DatePicker style={{ width: "100%" }} name={"insert_time"} showTime placeholder="请选择最近接入时间" format="YYYY-MM-DD HH:mm:ss" />
                            </FormItem>
                            <FormItem
                                name={"check_status"}
                                label={"盘点状态"}
                                key={"check_status"}
                            >
                                <Select allowClear name={"check_status"} placeholder="请选择盘点状态">
                                    <Option value="true">盘点到</Option>
                                    <Option value="false">未盘点到</Option>
                                </Select>
                            </FormItem>
                        </div> : "" }
                        <div style={{ textAlign: "right" }}>
                            <Button onClick={() => { this.closeModal() }}>取消</Button>
                            <SubmitButton style={{ marginLeft: "10px" }} disabled={false}>保存</SubmitButton>&nbsp;
                        </div>
                    </FormikForm>
                </Formik>
            </Modal>
        </div>
    }
}