import React, { useState, useEffect } from 'react'
import {
    Icon, Tooltip, Row, Col, Table
} from "antd";
import http from '@/axios/axios_utils';
import AxiosObj from '@/axios/index';
import Utils from '@/utils/utils';
import { connect } from 'react-redux'
import moment from 'moment';
import "./index.scss"
import "animate.css"
function OidMangen(props) {
    const [tableData, setTableData] = useState([])
    const [total, setTotal] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const columns = [
        {
            title: '序号',
            dataIndex: 'ip',
            ellipsis: true,
            width: 80,
            render: (text, item, c) => { return c + 1 + (_current - 1) * _pageSize }
        },
        {
            title: 'oid',
            dataIndex: 'oid',
            ellipsis: true,
        },
        {
            title: '厂商',
            dataIndex: 'firm',
            ellipsis: true,
        },
        {
            title: '类型',
            dataIndex: 'type',
            ellipsis: true,
        },
        {
            title: 'details',
            dataIndex: 'details',
            ellipsis: true,
        },
        {
            title: '更新时间',
            dataIndex: 'update_time',
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            ellipsis: true,
        },
    ]
    useEffect(() => {//componentDidMount  
        getData();
        return () => { //componentWillUnmount
        }
    }, []);
    useEffect(() => {//componentDidMount  
        getData();
        return () => { }
    }, [props.uptime]);
    function getData(page = 1, size = _pageSize, query = {}) {
        http.get(AxiosObj.getBaseUrl("topology") + "snmpOidInfo", { page_no: page, page_size: size, ...query }, (r) => {
            setTableData(r.data);
            setTotal(r.count);
            set_querys(query);
            set_current(page);
            set_pageSize(size)
        })
    }

    return <div className="OidMangenOverview">
        <Table
            dataSource={tableData}
            columns={columns}
            size="small"
            pagination={
                {
                    current: _current, pageSize: _pageSize, total,
                    showTotal: (total) => { return <span>共 {total} 项</span> },
                    pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) => {
                        getData(1, size, _querys);
                    },
                    onChange: (page, pageSize) => { getData(page, pageSize, _querys); }
                }
            }
        />
    </div>
}

export default connect()(OidMangen)