import React from 'react';
import { Select, Tag, Tooltip, Button, Popover, Icon, Badge } from 'antd';

export default {
    deviceFirmsOptions() {
        const deviceFirms = [
            { value: 'HUAWEI', name: 'HUAWEI' }, 
            { value: 'CISCO', name: 'CISCO' },
            { value: 'H3C', name: 'H3C' },
            { value: 'MAIPU', name: 'MAIPU' },
            { value: 'RUIJIE', name: 'RUIJIE' },
            { value: 'HILLSTONE', name: 'HILLSTONE' },
            { value: 'JUNIPER', name: 'JUNIPER' },
        ]
        return deviceFirms;
    },
    deviceFirmsAutoInput() {
        const deviceFirms = [
            "HUAWEI", "CISCO", "H3C", "MAIPU", "RUIJIE", "HILLSTONE", "JUNIPER"
        ]
        return deviceFirms;
    },
}