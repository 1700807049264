import React from 'react'
import FilterForm from '@/components/FilterForm/index';
import { Card, Table, Input, Button, Icon, message, Select, notification, Steps } from "antd";
import CustomBreadcrumb from "@/components/CustomBreadcrumb";
import Utils from "@/utils/utils";
import http from '@/axios/axios_utils';
import { devices, snmp_modules } from "../utils/api";
import ResUtils from "@/utils/ResUtils";
import socket from "@/socket";
export default class AddDevice extends React.Component {
    state = {
        stepCurrent: 0,      //步骤计数
        _current: 1,      //当前页码数
        _pageSize: 10,    //每页显示条数
        _querys: {},    //查询条件
        total: 0,
        _current2: 1,      //当前页码数
        _pageSize2: 10,    //每页显示条数
        _querys2: {},    //查询条件
        total2: 0,
        loading: false,
        selectedRowKeys: [],
        selectedRows: [],
        MRowKeys: [],
        selectedModuleRows: [],
        ModuleList: [],
    };
    componentWillMount() {
        this.updata();
        this.getModuleList();
    }
    //设备列表
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current, _querys } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || _querys;
        socket.ExecuteAction('QueryResourcesPage',
            bodyJson, "/queryResource", (result) => {
                let Resources = result.resources;
                Resources.map((item, index) => {
                    item.key = index;
                    item.index = index;
                    return item.id
                });
                this.setState({
                    tableData: Resources,
                    _current: bodyJson.pageNum || 1,
                    _querys: bodyJson.pageNum || 1,
                    total: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };

    //获取mibs模块列表
    getModuleList = (page = 1, size = 20, query = {}) => {
        http.get(snmp_modules(), { page, size, ...query }, (r) => {
            let data = r.data.records.map((item, index) => {
                return { ...item, key: index + 1 }
            })
            this.setState({
                ModuleList: data,
                // ModuleList: r.data.records,
                total2: r.data.total,
                _querys2: query,
                _current2: page
            })
        })
    }

    //添加监控设备
    addDevice = () => {
        let { selectedRows, MRowKeys, selectedRowKeys } = this.state;
        if (!selectedRowKeys[0]) {
            message.error("请选择设备!");
            return;
        } else if (!MRowKeys[0]) {
            message.error("请选择监控模块!");
            return;
        }

        console.log(selectedRowKeys, MRowKeys)
        const param = {
            devices: selectedRowKeys.map((item, i) => {
                let r = JSON.parse(item);
                const n = {
                    device_ip: r.resourceParams.deviceIp,
                    device_name: r.resourceName,
                    business_name: r.resourceParams.deviceFirm,
                    type: r.resourceParams.deviceType,
                    deviceOsVersion: r.resourceParams.deviceOsVersion,
                    description: r.resourceDes,
                    deviceSite: r.resourceParams.deviceSite
                }
                return n
            }),
            modules: MRowKeys
        }
        http.post(devices(), param, (r) => {
            message.success("添加成功!");
            this.props.history.go(-1);
        })
    }


    render() {
        let { _current, _pageSize, querys, ModuleList, stepCurrent, selectedRowKeys, MRowKeys } = this.state;
        const columns = ResUtils.getColumns(_pageSize, _current);
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    stepCurrent: 1,
                    selectedRowKeys: selectedRowKeys,
                    selectedRows: selectedRows,
                });
            },
            selections: [
                {
                    key: 'cancel',
                    text: '清空选择',
                    onSelect: changableRowKeys => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        const columnsModule = [
            {
                title: '序号',
                dataIndex: 'key',
                ellipsis: true,
                width: 50,
                // render: (text, item, c) => { return c + 1 + (_current2 - 1) * _pageSize2 }
            },
            {
                title: '模块名称',
                dataIndex: 'module_name',
                ellipsis: true,
            },
            {
                title: 'SNMP版本',
                dataIndex: 'version',
                ellipsis: true,
            },
            {
                title: '采集频率',
                dataIndex: 'scrape_interval',
                ellipsis: true,
            },
            {
                title: '采集超时',
                dataIndex: 'timeout',
                ellipsis: true,
            },
            {
                title: '重试次数',
                dataIndex: 'retries',
                ellipsis: true,
            },
            {
                title: 'community',
                dataIndex: 'auth',
                ellipsis: true,
                render: (text, item) => {
                    return text ? text.community : "";
                }
            },
            {
                title: '描述',
                dataIndex: 'description',
                ellipsis: true,
            },
        ]
        return (
            <div>
                <CustomBreadcrumb arr={["监控告警", "数据采集", { title: "SNMP概览", to: "/monitorAlert/dataCollection/snmpPreview" }, "添加监控设备"]} />
                <Card title={<span style={{ fontWeight: 600 }}>新增监控设备</span>}>
                    <Steps direction="vertical" current={stepCurrent}>
                        <Steps.Step title="选择监控设备"
                            description={
                                <div>
                                    <FilterForm
                                        tagClass="resource"
                                        filterSubmit={(querys) => {
                                            this.setState({ querys })
                                            this.updata(this.state._pageSize, 1, querys)
                                        }}
                                        resetTab={() => {
                                            this.setState({ querys: {} })
                                            this.updata(this.state._pageSize, 1, {})
                                        }}
                                    />
                                    <Table
                                        dataSource={this.state.tableData}
                                        rowSelection={rowSelection}
                                        columns={columns}
                                        loading={this.state.loading}
                                        rowKey={(text) => { return JSON.stringify(text) }}
                                        // rowKey={(text) => { return text._id }}
                                        size="small"
                                        pagination={{
                                            current: _current, pageSize: _pageSize, total: this.state.total,
                                            showTotal: (total) => { return <span>共 {total} 项</span> },
                                            pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                                            showSizeChanger: true,
                                            onShowSizeChange: (current, size) => {
                                                this.setState({ _pageSize: size })
                                                this.updata(size, 1, querys)
                                            },
                                            onChange: (page, pageSize) => {
                                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                                console.log(page, pageSize);
                                                this.updata(pageSize, page, querys);
                                            }
                                        }}
                                    />
                                </div>
                            }
                        />
                        <Steps.Step title="选择监控模块"
                            description={
                                <div>
                                    <Table
                                        dataSource={ModuleList}
                                        rowSelection={{
                                            selectedRowKeys: MRowKeys,
                                            onChange: (MRowKeys2, selectedModuleRows2) => {
                                                this.setState({
                                                    stepCurrent: 2,
                                                    MRowKeys: MRowKeys2,
                                                    selectedModuleRows: selectedModuleRows2,
                                                });
                                            },
                                            selections: [
                                                {
                                                    key: 'cancel',
                                                    text: '清空选择',
                                                    onSelect: changableRowKeys => {
                                                        this.setState({ MRowKeys: [] });
                                                    },
                                                },
                                            ],
                                        }}
                                        columns={columnsModule}
                                        rowKey={(text) => text.module_name}
                                        size="small"
                                    // pagination={{
                                    //     current: _current, pageSize: _pageSize, total: this.state.total,
                                    //     onChange: (page, pageSize) => {
                                    //         //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                    //         console.log(page, pageSize);
                                    //         this.updata(pageSize, page, querys);
                                    //     }
                                    // }}
                                    />
                                </div>
                            }
                        />
                        <Steps.Step title="确认完成" description={
                            <div>
                                <Button type="primary" icon="check-circle"
                                    onClick={() => { this.addDevice(); }}>
                                    确认
                                </Button>&nbsp;
                                <Button icon="close-circle"
                                    onClick={() => { this.props.history.goBack(); }}>
                                    返回
                                 </Button>
                            </div>
                        } />
                    </Steps>
                </Card>
            </div>

        )
    }
}