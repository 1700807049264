/**
 * 系统前后端API配置
 */
const BASEURL = "http://" + backup_address + "/ZJNMS_V1/topology/";
const APIConfig = {
    BASEURL,
    //URL:配置
    MODENAME: "autoFind",//模块名称 
    pingStatusRecords: BASEURL + "pingStatusRecords",
    getAllPingIps: BASEURL + "getAllPingIps",
    addTopologyNet: BASEURL + "addTopologyNet",
    topologyMerger: BASEURL + "topologyMerger",
    delTopologyByNet: BASEURL + "delTopologyByNet",
    addSysOidInfo: BASEURL + "addSysOidInfo",
    scanRecordsByNet: BASEURL + "scanRecordsByNet",
    devicesPercentage: BASEURL + "devicesPercentage",
    saveTopologyResources: BASEURL + "saveTopologyResources",
    topologyInfoByNet: BASEURL + "topologyInfoByNet",
    netDevices: BASEURL + "netDevices",
    terminalDevices: BASEURL + "terminalDevices",
    resourceByIp: BASEURL + "resourceByIp",

};

export default APIConfig;