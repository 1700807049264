import React from 'react'
import { Button, Icon, Popconfirm, Modal, Spin, message, Card, Table, Tabs, Input, Select } from "antd";
import BasePage from '@/components/BasePage';
import Socket from '@/socket/index'
import Utils from '@/utils/utils'
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
const TabPane = Tabs.TabPane;
const Option = Select.Option
class TagList extends React.Component {
    state = {
        dataSource: [],
        loading: false,
        visible_info: false,
        tagClasss: [{ id: "all", name: "全部" },
        // { id: "user", name: "用户" },
        // { id: "role", name: "角色" },
        { id: "resource", name: "资源" },
        { id: "operation", name: "操作" },
        { id: "task", name: "任务" },
        ],
        selTagClass: "resource",
        selTagName: "",
        actTagClass: "",
        _total: 0,
        _pageSize: 10,
    }
    componentWillMount() {

        this.updateTableData(10, 1, { tagClass: "all" });
    }

    updateTableData(pageSize, pageNum, querys) {
        let { _pageSize, _current, _querys, } = this.state;
        this.setState({ loading: true, actTagClass: querys["tagClass"] });
        if (querys["tagClass"] === "all") {
            delete querys["tagClass"];
        }
        let bodyJson = {
            pageSize: pageSize || _pageSize,
            pageNum: pageNum || _current,
            querys: querys || _querys
        };
        if (Utils.isEmptyObject(bodyJson["querys"])) {
            delete bodyJson["querys"];
        }
        Socket.ExecuteAction('QueryTagsPage', bodyJson, '/QueryTagsPage', (resp) => {
            console.log(resp)
            let dataList = resp.data;
            this.setState({ dataSource: dataList, _pageSize: bodyJson.pageSize, _current: bodyJson.pageNum, _querys: bodyJson.querys, loading: false, _total: resp.total || 10 })
        }, (error) => { message.error(error); this.setState({ loading: false }) })
    }
    ActionTag(_bodyJson, APIName, showInfo) {
        let { actTagClass } = this.state;
        Socket.ExecuteAction(APIName, _bodyJson, '/' + APIName, (resp) => {
            console.log(resp)
            this.updateTableData(10, 1, { tagClass: actTagClass });
            message.success(showInfo);
        }, (error) => { message.error(error); })
    }
    render() {
        let { dataSource, loading, _current, _pageSize, _total, tagClasss, visible_info, selTagClass, selTagName, actTagClass, _querys } = this.state;
        const BreadcrumbArr = ["系统管理", "标签列表"];
        const tableTitle = "标签列表";
        const columns = [
            {
                title: "名称",
                dataIndex: "tagName"
            },
            {
                title: "类型",
                dataIndex: "tagClass"
            },
            {
                title: "创建用户",
                dataIndex: "createUserId"
            },
            {
                title: "创建时间",
                dataIndex: "createTime"
            },
            {
                title: "操作",
                render: (text, item, index) => {
                    return <Popconfirm title="是否删除该条数据?"
                        onConfirm={() => { this.ActionTag({ _id: item._id }, "DeleteTag", "删除成功!"); }}>
                        <Button icon="delete" size="small" type="danger">删除</Button>
                    </Popconfirm>
                }
            },
        ]
        const formList = [
            {
                label: "标签名称",
                type: 'INPUT',
                field: "tagName",
                initialValue: '',
                placeholder: "标签名称"
            },
        ]
        return <div>
            <CustomBreadcrumb arr={BreadcrumbArr} />
            <Card>
                <FilterForm
                    formList={formList}
                    filterSubmit={(querys) => {
                        querys["tagClass"] = actTagClass || "all";
                        this.updateTableData(this.state._pageSize, 1, querys)
                    }}
                    resetTab={() => {
                        this.updateTableData(this.state._pageSize, 1, { tagClass: "all" })
                    }}
                />
            </Card>
            <Card title={<span style={{ fontWeight: 600 }}>{tableTitle}</span>}
                style={{ marginTop: 8 }}
                extra={
                    <Button type="primary" style={{ marginLeft: 8 }}
                        icon="edit" onClick={() => this.setState({ visible_info: true })}>新增标签
                    </Button>}>
                <Tabs type="card"
                    activeKey={actTagClass}
                    onChange={(activeKey) => {
                        this.updateTableData(_pageSize, 1, { ..._querys, tagClass: activeKey });
                    }}>
                    {tagClasss.map((item, key) => {
                        return <TabPane tab={item.name} key={item.id}>
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                loading={loading}
                                size="small"
                                pagination={{
                                    current: _current, pageSize: _pageSize, total: _total,
                                    showTotal: (total) => { return <span>共 {total} 项</span> },
                                    pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                                    showSizeChanger: true,
                                    onShowSizeChange: (current, size) => {
                                        this.updateTableData(size, 1, { ..._querys, tagClass: actTagClass })
                                    },
                                    onChange: (page, pageSize) => {
                                        this.updateTableData(pageSize, page, { ..._querys, tagClass: actTagClass })
                                    }
                                }}
                            />
                        </TabPane>
                    })}
                </Tabs>
            </Card>
            <Modal
                title="新增标签"
                visible={visible_info}
                onCancel={(e) => { this.setState({ visible_info: false, }); }}
                okText={"新增标签"}
                onOk={() => { this.ActionTag({ tagClass: selTagClass, tagName: selTagName }, "AddTag", "新增成功！"); this.setState({ visible_info: false, }); }}
            // width="50%"
            // style={{ position: 'fixed', top: 25, left: 35, }}
            >
                <div>
                    <p>标签名称：<Input onChange={(e) => { this.setState({ selTagName: e.target.value }) }} value={selTagName} style={{ width: 300, }} placeholder="标签名称" /></p>
                    标签类型：
                    <Select value={selTagClass} onChange={(val) => { this.setState({ selTagClass: val }) }} style={{ width: 300 }}>
                        {tagClasss.map((item, key) => {
                            return <Option key={key} value={item.id}>{item.name}</Option>
                        })}
                    </Select>
                </div>
            </Modal>
        </div>
    }
}


export default TagList