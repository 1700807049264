import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Card, Row, Col, Button, Typography, message } from 'antd';
import allConfig from "./config.json"

require("echarts/map/js/china.js");

export default class Airport extends PureComponent {
    getOption = () => {
        const { data } = this.props;
        const cityConfig = allConfig.flow
        // const inoutData = data[cityConfig.city]
        const inoutData = data;
        // console.log(data)
        const geoCoordMap = cityConfig.geoCoordMap;

        // const cityData = Object.keys(data).map((city) => {
        // const cityData = Object.keys(data).map((city) => {
        //     return [city, [
        //         [{ name: city }, { name: '北京', value: [1000, 200] }],
        //         [{ name: city }, { name: '上海', value: [95, 210] }],
        //         [{ name: city }, { name: '广州', value: [90, 2020] }],
        //         [{ name: city }, { name: '西安', value: [80, 2300] }],
        //         [{ name: city }, { name: '成都', value: [70, 2500] }],
        //         [{ name: city }, { name: '珠海', value: [60, 2070] }],
        //         [{ name: city }, { name: '杭州', value: [50, 133] }],
        //         [{ name: city }, { name: '成都', value: [40, 2090] }]
        //     ].filter((item) => (item[1].name != city))
        //     ]
        // })

        const cityData = [[cityConfig.city, Object.keys(geoCoordMap).map((city) => {
            const value1 = inoutData["out"] ? inoutData["out"][city] : 0;
            const value2 = inoutData["in"] ? inoutData["in"][city] : 0;
            return [{ name: cityConfig.city }, { name: city, value: [value1, value2] }]
        })]]
        // }).filter((item) => { return item[1].name != cityConfig.city })]]




        const planePath = 'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z';

        const convertData = function (data) {
            var res = [];
            for (var i = 0; i < data.length; i++) {
                var dataItem = data[i];
                var fromCoord = geoCoordMap[dataItem[0].name];
                var toCoord = geoCoordMap[dataItem[1].name];
                if (fromCoord && toCoord) {
                    res.push({
                        fromName: dataItem[0].name,
                        toName: dataItem[1].name,
                        value: dataItem[1].value,
                        coords: [fromCoord, toCoord]
                    });
                }
            }
            return res;
        };

        const color = ["#00BFFF", '#a6c84c', '#ffa022', '#46bee9', '#FF8C00', "#FF6347", "#00FFFF",];
        const series = [];
        // [ 
        //     ['北京', BJData], ['上海', SHData], ['广州', GZData],
        // ].forEach(function (item, i) {
        cityData.forEach(function (item, i) {
            series.push(
                {
                    name: item[0] + '',
                    type: 'lines',
                    zlevel: 1,
                    effect: {
                        show: true,
                        period: 6,
                        trailLength: 0.7,
                        color: '#fff',
                        symbolSize: 3
                    },
                    lineStyle: {
                        normal: {
                            color: color[i],
                            width: 0,
                            curveness: 0.2
                        }
                    },
                    data: convertData(item[1])
                },
                {
                    name: item[0] + '',
                    type: 'lines',
                    zlevel: 2,
                    symbol: ['none', 'arrow'],
                    symbolSize: 10,
                    effect: {
                        show: true,
                        period: 6,
                        trailLength: 0,
                        symbol: "arrow",
                        symbolSize: 10
                    },
                    lineStyle: {
                        normal: {
                            color: color[i],
                            width: 2,
                            opacity: 0.8,
                            curveness: 0.2
                        }
                    },
                    data: convertData(item[1])
                },
                {
                    name: item[0] + '',
                    type: 'effectScatter',
                    coordinateSystem: 'geo',
                    zlevel: 2,
                    rippleEffect: {
                        brushType: 'stroke'
                    },
                    label: {
                        normal: {
                            show: true,
                            position: 'right',
                            formatter: '{b}'
                        }
                    },
                    symbolSize: function (val) {
                        // return val[2] / 8;//大小
                        return 10;
                    },
                    itemStyle: {
                        normal: {
                            color: color[i]
                        }
                    },
                    data: item[1].map(function (dataItem) {
                        return {
                            name: dataItem[1].name,
                            value: geoCoordMap[dataItem[1].name].concat([dataItem[1].value])
                        };
                    })
                },
                {
                    name: 'show value',
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    symbol: 'pin',
                    symbolSize: [5, 5],
                    label: {
                        normal: {
                            show: true,
                            textStyle: {
                                color: '#fff',
                                fontSize: 12,
                                fontWeight: 300,
                            },
                            formatter(params) {
                                if (params.name === "珠海") {
                                    return ""
                                }
                                const data = params.value[2];
                                const inData = "↪" + parseFloat(data[0] / 1048576).toFixed(2) + "Mbps";
                                const outData = "↩" + parseFloat(data[1] / 1048576).toFixed(2) + "Mbps";
                                return inData + "\n" + outData;
                            }
                        }
                    },
                    itemStyle: {
                        normal: {
                            color: color[i], //标志颜色
                        }
                    },
                    data: item[1].map(function (dataItem) {
                        const name = dataItem[1].name;
                        let label = {
                            position: "top",
                            distance: 10
                        };
                        if (name === "杭州") {
                            label = {
                                position: "right",
                                distance: 40
                            }
                        } else if (name === "广州") {
                            label = {
                                position: "left",
                                distance: 35
                            }
                        }
                        return {
                            name: name,
                            value: geoCoordMap[dataItem[1].name].concat([dataItem[1].value]),
                            label
                        };
                    }),
                    // showEffectOn: 'render',
                    // rippleEffect: {
                    //     brushType: 'stroke'
                    // },
                    // hoverAnimation: true,
                    // zlevel: 1
                },
            );
        });

        const option = {
            // backgroundColor: '#404a59',
            // color:""
            title: {
                text: '流量示意图',
                // subtext: '数据纯属虚构',
                left: 'center',
                textStyle: {
                    color: '#fff',
                    fontSize: 30
                }
            },
            tooltip: {
                trigger: 'item',
                formatter: function (params, a, b, c) {
                    // console.log(params)
                    let htmlContent = null
                    if (!params.name) {
                        htmlContent = "<div>" + params.data.fromName + "->" + params.data.toName + ":" + parseFloat(params.data.value[0] / 1048576).toFixed(2) + "Mbps</div>"//out
                            + "<div>" + params.data.toName + "->" + params.data.fromName + ":" + parseFloat(params.data.value[1] / 1048576).toFixed(2) + "Mbps</div>"//in
                    } else if (params.name === cityConfig.city) {
                        htmlContent = "";
                    } else {
                        htmlContent = "<div>" + params.seriesName + "->" + params.name + ":" + parseFloat((params.value[2])[0] / 1048576).toFixed(2) + "Mbps</div>"
                            + "<div>" + params.name + "->" + params.seriesName + ":" + parseFloat((params.value[2])[1] / 1048576).toFixed(2) + "Mbps</div>"//in
                    }
                    return htmlContent
                }
            },
            legend: {
                orient: 'vertical',
                top: 'bottom',
                left: 'right',
                data: Object.keys(geoCoordMap).map((ii) => { return { name: ii } }),
                textStyle: {
                    color: '#fff'
                },
                selectedMode: 'single'
            },
            geo: {
                map: 'china',
                label: {
                    emphasis: {
                        show: false
                    }
                },
                roam: true,
                itemStyle: {
                    normal: {
                        areaColor: '#323c48',
                        borderColor: '#404a59'
                    },
                    emphasis: {
                        areaColor: '#2a333d'
                    }
                }
            },
            series: series
        };
        return option;
    };
    onChartReady(echarts) {
        console.log(echarts);
        echarts.getZr().on('click', function (params) {
            console.log(params);

        })
        echarts.getZr().on('click', "legend", function (params) {
            console.log(params);
        });
    }
    onChartClick = (param, echarts) => {
        // console.log(param, echarts);
    };

    onChartLegendselectchanged = (param, echart) => {
        console.log(param);
        this.props.legendChanged({ name: param.name })
    };
    render() {
        let onEvents = {
            'click': this.onChartClick,
            'legendselectchanged': this.onChartLegendselectchanged
        };
        return <div className='mapFlow'>
            <ReactEcharts
                option={this.getOption()}
                // onChartReady={this.onChartReady}
                onEvents={onEvents}
                className='react_for_echarts'
            />
        </div>
    }
}
