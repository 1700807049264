import React from "react";
import { FullScreenContainer } from '@jiaminghi/data-view-react'
import './index.scss'
import { fullExit, fullScreen, judgeIsSupportFull } from '../utils'
import { withRouter } from "react-router-dom";
import TopHeader from "./TopHeader";
import DigitalFlop from "./DigitalFlop";
import ScrollBoard from "./ScrollBoard";
import Socket_Char from "../chartSwitch/socket_Char";
import Http_Char from "../chartSwitch/http_Char";
import SliceShape from '@/components/sliceShape/index';
import DevicePort from "../devicePort/index";
import Socket from "@/socket/index";
import "animate.css";
class BigScreen extends React.Component {

    state = {
        isSupportFull: false,
        isFull: true,
        isHider: true,
        chartParam: [],
        bigScreenCfg: []
    };

    componentWillMount() {
        this.getInterValCfg()
    }

    componentDidMount() {
        window.addEventListener("resize", this.changeFullStatus);
        this.judgeIsSupportFull();
        fullScreen()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.changeFullStatus);
    }

    getInterValCfg = () => {
        Socket.getConfig("ScreenInterval", (r) => {
            if (r && JSON.stringify(r) != "{}") {
                let timer = r.statis
                const newData = [
                    {
                        "key": "total_list",
                        "description": "数量统计栏",
                        "type": "title",
                        "timer": 300000
                    },
                    {
                        "key": "dev_line",
                        "description": "设备在线/离线图",
                        "type": "ring",
                        "timer": 300000
                    },
                    {
                        "key": "dev_port_traffic",
                        "description": "设备端口流量图",
                        "type": "column",
                        "timer": 300000
                    },
                    {
                        "key": "alert_level",
                        "description": "告警等级统计图",
                        "type": "rings",
                        "timer": 300000
                    },
                    {
                        "key": "cpu_top10",
                        "description": "设备CPU使用率前十台",
                        "type": "column2",
                        "timer": 300000
                    },
                    {
                        "key": "dev_alert",
                        "description": "设备告警信息列表",
                        "type": "column",
                        "timer": 300000
                    },
                    {
                        "key": "temperature_top10",
                        "description": "设备温度前十台",
                        "type": "column",
                        "timer": 300000
                    }
                ].map((i) => {
                    i.timer = timer
                    return i;
                })
                this.setState({ bigScreenCfg: newData })
            }
        })
    }
    // 判断当前浏览器是否支持全屏API
    judgeIsSupportFull = () => {
        let isSupportFull = judgeIsSupportFull();
        this.setState({ isSupportFull });
    };
    // 计算当前是否处于全屏
    changeFullStatus = () => {
        // 判断网页的高度或者宽度是否等于屏幕对应大小
        // true: 当前处于全屏状态
        // false: 当前不处于全屏状态
        let scalRatio = window.devicePixelRatio;
        let screenRatio = document.body.scrollHeight / Math.round((window.screen.height / scalRatio));
        if (
            // document.body.scrollHeight === window.screen.height &&
            // document.body.scrollWidth === window.screen.width
            // 网页的高度或者宽度进行缩放之后，是否等于屏幕对应的大小
            (document.body.scrollHeight === Math.round(window.screen.height / scalRatio.toFixed(2)) &&
                document.body.scrollWidth === Math.round(window.screen.width / scalRatio.toFixed(2))) ||
            (document.body.scrollHeight === Math.round(window.screen.height / (scalRatio / screenRatio).toFixed(2)) &&
                document.body.scrollWidth === Math.round(window.screen.width / (scalRatio / screenRatio).toFixed(2)))
        ) {
            this.setState({ isFull: true });
        } else {
            this.setState({ isFull: false });
        }
        if (!this.state.isFull) {
            this.props.history.push("/home")
        }

    };
    // click button
    handClick = () => {
        this.toFullExit()
        // this.state.isFull ? this.toFullExit() : fullScreen();
    };

    handleMouseOver = () => {
        this.setState({ isHider: !this.state.isHider })
    }

    tick = e => {
        fullScreen()
    }

    toFullExit = e => {
        fullExit()
        this.props.history.push("/home")
    }
    getScreenContent = () => {
        const { bigScreenCfg } = this.state
        return bigScreenCfg.map((item, i) => {
            switch (item.key) {
                case "total_list": return <DigitalFlop {...item} key1={item.key} />;
                case "dev_firm": return <Socket_Char {...item} key1={item.key} unit="台" />
                case "dev_type": return <Socket_Char {...item} key1={item.key} unit="台" />
                case "dev_alert": return <ScrollBoard timer={item.timer} />
                case "dev_line": return <Socket_Char {...item} key1={item.key} unit="台" />
                case "dev_port_traffic": return <DevicePort {...item} key1={item.key} />
                case "alert_level": return <Http_Char  {...item} key1={item.key} unit="个" />
                case "cpu_top10": return <Http_Char  {...item} key1={item.key} unit="%" />
                case "mem_top10": return <Http_Char  {...item} key1={item.key} unit="%" />
                case "temperature_top10": return <Http_Char  {...item} key1={item.key} unit="℃" />
                case "alert_time": return <Http_Char  {...item} key1={item.key} unit="个" />
                case "area_device": return <Area_device  {...item} key1={item.key} />
                case "add_del_char": return <Add_del_char  {...item} key1={item.key} unit="台" />
                default: return <div className="card-item"> </div>
            }
        })
    }


    render() {
        let { isSupportFull } = this.state;
        if (!isSupportFull) {
            return null;
        }
        return (<div id='data-view-screen'>
            <FullScreenContainer>
                <TopHeader />
                <div className="main-content">
                    {this.getScreenContent()}
                </div>
            </FullScreenContainer>

        </div>)
    }
}

export default withRouter(BigScreen)