import React, { useState, useEffect } from 'react';
import { Card, Descriptions, Button, Tag, message, Input, Divider, Row, Col, Switch, Radio } from 'antd';
import Utils from '@/utils/utils';
import FormModal from '@/components/EditModal/index';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import Socket from "@/socket/index";
import http from '@/axios/axios_utils';
import { devices, interfaces } from '@/pages/monitorAlert/dataCollection/utils/api';
import "../index.scss"
const GRAFANA_IP_PORT = sys_config.grafana_address || "localhost:30300";
const MonitorInfo = (props) => {
    const [PORT_DATA, setPORT_DATA] = useState([]);
    const [portData, setPortData] = useState([]);
    const [editVisible, setVisEd] = useState(false);
    const [device, setDevice] = useState(JSON.parse(sessionStorage.getItem("monitorDev") || {}));
    const DEVICEIP = device.resourceParams.deviceIp
    const [monitorSetting, setMonitorSetting] = useState({});

    const [snmp_module_name, setSnmp_module_name] = useState(device.resourceParams.deviceFirm.toLowerCase());
    const [theme, setTheme] = useState("light");
    const [selectPort, setSelectPort] = useState("");
    const [promethes_url, setPromethes_url] = useState(`http://${GRAFANA_IP_PORT}/d/kqAltF33z/wang-luo-she-bei?orgId=1&var-job=${snmp_module_name}&var-ip=${DEVICEIP}&theme=${theme}&kiosk=tv`);

    useEffect(() => {
        setMonitorSetting({
            ...device.monitor,
            "offlineAlert": (device.monitor && device.monitor.offlineAlert === "true") ? true : false
        })
        getSnmpData(1, 100, {
            device_ip: DEVICEIP, business_name: device.resourceParams.deviceFirm.toLowerCase(),
            type: device.resourceParams.deviceType.toLowerCase()
        })
        getFirmIpPortData()
    }, []);
    async function getSnmpData(page = 1, size, query = {}) {
        let res = await http.get(devices(), { page, size, ...query, userId: sessionStorage.getItem("userId") })
        let snmp_module_name2 = device.resourceParams.deviceFirm.toLowerCase()
        if (res.data && res.data.records) {
            res.data.records.every((i) => {
                if (i.device_ip === DEVICEIP) {
                    snmp_module_name2 = i.module_info && i.module_info[0] && i.module_info[0]["module_name"]
                    let promethes_url1 = `http://${GRAFANA_IP_PORT}/d/kqAltF33z/wang-luo-she-bei?orgId=1&var-job=${snmp_module_name}&var-ip=${DEVICEIP}&theme=${theme}&kiosk=tv`
                    document.getElementById("bigScreenIframe").src = promethes_url1
                    console.log(promethes_url)
                    setSnmp_module_name(snmp_module_name2)
                    return false;
                }
                return true;
            })
        }
    }
    function getFirmIpPortData() {
        http.get(interfaces(), { user_id: sessionStorage.getItem("userId") }, (r) => {
            let tempData = r.data || {}
            let tempData2 = tempData[device.resourceParams.deviceFirm.toLowerCase()] || {}
            let port_data = tempData2[device.resourceParams.deviceIp] || []
            console.log(r.data)
            console.log(portData)
            setPortData(port_data)
            setPORT_DATA(port_data)
        })
    }
    function onClickPort(port) {
        let promethes_url2 = `http://${GRAFANA_IP_PORT}/d/kqAltF33z/wang-luo-she-bei?orgId=1&var-job=${snmp_module_name}&var-ip=${DEVICEIP}&var-interface=${port}&theme=${theme}&kiosk=tv`
        console.log(promethes_url2)
        document.getElementById("bigScreenIframe").src = promethes_url2
        setSelectPort(port)
    }
    const updateData = (val) => {
        device.monitor = val;
        device.monitor.offlineAlert = val.offlineAlert + ""
        Socket.ExecuteAction("UpdateResource", device, "/editResource", () => {
            message.success('修改成功');
            setVisEd(false)
            setMonitorSetting({ ...val, offlineAlert: Boolean(val.offlineAlert === "true") })
        }, (error) => {
            message.error(JSON.stringify(error));
            setVisEd(false)
        });
    }
    const seachPort = (value) => {
        if (value.trim() === "") {
            setPortData(PORT_DATA)
            return;
        }
        let newData = PORT_DATA.filter((r) => r.toLowerCase().includes(value.toLowerCase()))
        setPortData(newData)
    }
    const params = [
        ["资源名称", "resourceName",],
        ["设备IP", "resourceParams.deviceIp",],
        ["设备厂商", "resourceParams.deviceFirm",],
        ["设备名称", "resourceParams.deviceId"],
        ["设备类型", "resourceParams.deviceType"],
        ["设备型号", "resourceParams.deviceVersion",],
        ["设备OS版本", "resourceParams.deviceOsVersion",],
        ["设备OS类型", "resourceParams.deviceOsType"],
        ["SNMP口令 ", "resourceParams.community"],
        ["设备状态", "resourceParams.deviceState"],
        ["网点信息", "resourceParams.deviceSite"],
        ["标签", "tags"],
        ["资源描述", "resourceDes"],
    ];
    return <div className="monitorInfo">
        <CustomBreadcrumb arr={["资源管理", "设备列表", "设备监控信息"]} />
        <Card title="设备监控信息" extra={<div>
            <Button type="primary" icon="setting" onClick={() => { setVisEd(true) }}>监控设置</Button>&nbsp;
            <Button type="primary" icon="rollback" onClick={() => { props.history.push({ pathname: `/resource`, }); }}>返回</Button>
        </div>}>
            <div className="moitorInfoDiv">
                <Descriptions bordered size="small">
                    {
                        params.map((item) => {
                            let dataIndexs = item[1].split(".")
                            let val = device[dataIndexs]
                            if (dataIndexs.length > 1) {
                                let temp = device[dataIndexs[0]]
                                val = temp[dataIndexs[1]]
                            }
                            return <Descriptions.Item key={item[1]} label={item[0]}>{val}</Descriptions.Item>
                        })

                    }
                    <Descriptions.Item label='SNMP监控' span={2}>
                        <Button type="link" target="_blank"
                            onClick={() => { props.history.push({ pathname: `/monitorAlert/dataCollection/snmpPreview`, }); }}>
                            加入SNMP监控
                            </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label='端口信息' span={3}>
                        <Input.Search
                            allowClear
                            placeholder="输入关键词"
                            onChange={(e) => { seachPort(e.target.value) }}
                            style={{ width: 204, marginBottom: 2, marginLeft: 2 }}
                        />
                        <div className="portDiv">
                            {portData.map((port, index) => <div className="port" tabIndex={index} onClick={() => onClickPort(port)}>{port}</div>)}
                        </div>
                    </Descriptions.Item>
                </Descriptions>
            </div><p />
            <Radio.Group value={theme} onChange={(e) => {
                let promethes_url3 = `http://${GRAFANA_IP_PORT}/d/kqAltF33z/wang-luo-she-bei?orgId=1&var-job=${snmp_module_name}&var-ip=${DEVICEIP}&var-interface=${selectPort}&theme=${e.target.value}&kiosk=tv`
                console.log(promethes_url3)
                document.getElementById("bigScreenIframe").src = promethes_url3
                setTheme(e.target.value)
            }} >
                <Radio.Button value={"dark"}>深色</Radio.Button>
                <Radio.Button value={"light"}>浅色</Radio.Button>
            </Radio.Group><p />
            <iframe id="bigScreenIframe" src={promethes_url} />
        </Card>
        <FormModal
            title="监控配置"
            visible={editVisible}
            onCancel={() => setVisEd(false)}
            onSubmit={(val) => {
                updateData(val)
            }}
            footer={null}
            width={"50%"}
            initialValues={monitorSetting}
            destroyOnClose
            formList={[
                { label: "离线告警", name: "offlineAlert", type: "switch", },
            ]}
        />
    </div>;
};


export default MonitorInfo
