import React from 'react'
import axios from '@/axios/axios_utils';
import DyHeadTable from '@/components/DyHeadTable';
import FilterForm from '@/components/FilterForm';
import DumbTerminalUtils from "@/utils/DumbTerminalUtils";
import socket from "@/socket";
import Utils from "@/utils/utils";
import {
    Card, Table, Button, Icon, message, notification, Row, Col, Modal, Menu, Dropdown, Descriptions,
    Badge, Tooltip, Collapse, Select, Checkbox, Divider, Alert, Input
} from "antd";
import moment from 'moment';
const dumbTerminal_Url = "http://" + backup_address + "/ZJNMS_V1/dumb_termial/";

class DumbTerminal extends React.Component {
    state = {
        tableData: [], // 表格数据
        current_page: 1,  // 当前页
        pageSize: 20,
        total: 0,
        loading: false,
        query: {},
        selectedRowKeys: [],
        selectedData: [],
    }
    componentWillMount() {
        this.getTableData();
    }
    componentDidMount() {
        // this.props.onRef(this);
    }
    getTableData(page, page_size, querys, loadingFlag) {
        if (loadingFlag !== "noLoading") {
            this.setState({ loading: true, });
        }
        const { current_page, pageSize, query } = this.state;
        let bodyJson = {};
        let query2 = querys || query;
        bodyJson.pageSize = page_size || pageSize;
        bodyJson.pageNum = page || current_page;
        bodyJson.querys = query2
        socket.ExecuteAction('QueryResourcesAddPage',
            bodyJson, "/QueryResourcesAddPage", (result) => {
                this.setState({
                    tableData: result.data,
                    total: result.total,
                    current_page: page || current_page || 1,
                    loading: false,
                    query: query2,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });

    }

    exportTab() {
        let { selectedData, query } = this.state;
        // console.log(selectedData)
        this.setState({ loading: true, });
        let bodyJson = {};
        bodyJson.pageSize = 10000;
        bodyJson.pageNum = 1;
        bodyJson.querys = query
        socket.ExecuteAction('QueryResourcesAddPage',
            bodyJson, "/QueryResourcesAddPage", (result) => {
                this.setState({ loading: false, })
                let newData = result.data.map((i) => {
                    return {
                        "资源名称": i.resourceName,
                        "设备IP": i.resourceParams.deviceIp,
                        "新增时间": i.createTime,
                        "设备厂商": i.resourceParams.deviceFirm,
                        "设备名称": i.resourceParams.deviceId,
                        "设备类型": i.resourceParams.deviceType,
                        "设备型号": i.resourceParams.deviceVersion,
                        "设备OS版本": i.resourceParams.deviceOsVersion,
                        "SNMP口令": i.resourceParams.community,
                        "标签": (i.tags || []).join("-"),
                    }
                })

                Utils.exportSheetExcel(newData, "最近新增设备", "最近新增设备.xlsx");
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    }

    render() {
        const { current_page, total, pageSize, query, selectedRowKeys, selectedData } = this.state;
        const columns = [
            {
                title: '资源名称',
                align: 'left',
                ellipsis: true,
                width: 130,
                dataIndex: 'resourceName'
            },
            {
                title: '设备IP',
                align: 'left',
                ellipsis: true,
                width: 130,
                dataIndex: 'resourceParams.deviceIp',
            },
            {
                title: '新增时间',
                align: 'left',
                ellipsis: true,
                width: 200,
                dataIndex: 'createTime',
            },
        ];
        const params = [
            ["设备厂商", "resourceParams.deviceFirm", 100, "left"],
            ["设备名称", "resourceParams.deviceId", 120],
            ["设备类型", "resourceParams.deviceType"],
            ["设备型号", "resourceParams.deviceVersion", 150],
            ["设备OS版本", "resourceParams.deviceOsVersion", 200],
            ["设备OS类型", "resourceParams.deviceOsType"],
            ["SNMP口令 ", "resourceParams.community"],
            ["设备状态", "resourceParams.deviceState"],
            // ["网点信息", "resourceParams.deviceSite"],
        ];
        const company = ["CISCO", "HUAWEI", "H3C", "RUIJIE", "MAIPU", "HILLSTONE"];
        params.map((list) => {
            columns.push({
                title: list[0],
                align: list[3] || 'center',
                width: list[2] || 100,
                ellipsis: true,
                dataIndex: list[1],
                render: (text) => {
                    if (list[0] === "设备厂商") {
                        let one = "";
                        if (company.includes(text.toLowerCase())) {
                            one = <img src={"/image/" + text.toLowerCase() + ".jpg"} className={"imgstyle"} />;
                        }
                        text = <div>{one}&nbsp;{text}</div>;
                    }
                    let elemt = <div>{text}</div>;
                    if (list[0] === "设备类型") {
                        elemt = <div>{Utils.Translation(text)}</div>;
                    }
                    if (list[0] === "设备型号" || list[0] === "设备OS版本") {
                        elemt = <Tooltip title={text} placement="topLeft">{text}</Tooltip>
                    }
                    return elemt;
                }
            });
        })
        columns.push({
            title: '标签',
            dataIndex: 'tags',
            width: 200,
            align: "center",
            render: (text) => {
                return Utils.hidenTag(text);
            }
        });
        const formList = [
            {
                label: "月新增",
                type: 'monthSelect',
                field: "month_add",
                initialValue: "",
                placeholder: '月新增'
            },
        ]

        let scrollX = 0;
        columns.map((item, index) => {
            scrollX += item.width;
        })
        scrollX = scrollX + 260;

        // 多选
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            hideDefaultSelections: 'true',
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });
            },
            onSelect: (record, selected, selectedRows) => { // 选择单行
                if (selected) {
                    if (JSON.stringify(selectedData).indexOf(JSON.stringify(record)) === -1) {
                        selectedData.push(record);
                    }
                } else {
                    selectedData.splice(selectedData.findIndex(item => item._id === record._id), 1)
                }
                // console.log("最终的selectedData：", selectedData)
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                let beforeSelectAll = [];
                if (selected) {
                    if (selectedData.length === 0) {
                        selectedData.push(...selectedRows)
                    } else {
                        selectedData.map((item, index) => {
                            if (JSON.stringify(selectedRows).indexOf(JSON.stringify(item)) === -1) {
                                // console.log("selectedData:", item)
                                beforeSelectAll.push(item);
                            }
                        })
                        selectedData.splice(0, selectedData.length, ...selectedRows, ...beforeSelectAll)
                    }
                } else {
                    changeRows.map((item, index) => {
                        selectedData.splice(selectedData.findIndex(selectItem => selectItem._id === item._id), 1)
                    })
                }
                this.setState({ selectedData })
                // console.log("onSelectAll", selected, selectedRows, selectedData)
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [], selectedData: [] });
                    },
                },
            ],
        };


        return (
            <div className="dumbTerminalBody">
                <div className="searchDiv">
                    {query.month_add ?
                        <Alert message={`${moment(query.month_add).format("YYYY年M月")}新增的数量：${total}`} type="info" closable style={{ marginBottom: 8 }} /> : ''}
                    <FilterForm formList={formList}
                        filterSubmit={(querys) => {
                            querys.month_add ? querys.month_add = moment(querys.month_add).format("YYYY-MM") : "";
                            this.getTableData(1, pageSize, querys);
                        }}
                        resetTab={() => {
                            this.getTableData(1, pageSize, {})
                        }}
                    />
                </div>
                <Card
                    title={<span style={{ fontWeight: 600 }}>最近新增列表</span>}
                    style={{ marginTop: 8, maxHeight: 800 }}
                    extra={
                        <Row>
                            <Button type="primary" icon="export" onClick={() => { this.exportTab() }}>导出列表</Button>
                        </Row>
                    }
                >
                    <DyHeadTable
                        dataSource={this.state.tableData || []}
                        columns={columns}
                        size='small'
                        loading={this.state.loading}
                        rowKey={(text) => { return text._id }}
                        rowSelection={rowSelection}
                        pagination={{
                            pageSize: pageSize,
                            current: current_page,
                            total: total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onChange: (page, pageSize) => {
                                this.setState({ current_page: page, pageSize: pageSize });
                                this.getTableData(page, pageSize);
                            },
                            onShowSizeChange: (current, size) => {
                                this.setState({ current_page: current, pageSize: size });
                                this.getTableData(1, size)
                            }
                        }}
                    />
                </Card>
            </div>
        )
    }
}

export default DumbTerminal;
