import React from 'react'
import { Card, Button, message, Row, Icon, Form, Tabs } from "antd";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import http from '@/axios/index';
import { SubmitButton, FormItem, Radio, DatePicker, Select } from "formik-antd";
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik";
import moment from 'moment';
import './index.scss';
const { TabPane } = Tabs;
const fileWallPolicyUrl =  "http://" + backup_address + "/ZJNMS_V1/";
class FireWallPolicy extends React.Component {
    state = {
        addressTabs: ["地址1"],
        ips: []
    };
    componentWillMount() {

    }
    onSubmit(values) {
        let beginTime = "";
        let endTime = "";
        let ips = [];
        let { addressTabs } = this.state;
        if(values.validTime.length !== 0) {
            beginTime = moment(values.validTime[0]).format("YYYY-MM-DD HH:mm:ss");
            endTime = moment(values.validTime[1]).format("YYYY-MM-DD HH:mm:ss");
        }
        addressTabs.map((addressItem, index) => {
            if(values[addressItem]) {
                ips.push(values[addressItem]);
            }
        })
        let bodyJson = {
            head: { action: values.action },
            body: {
                serviceId: values.serviceId,
                beginTime: beginTime,
                endTime: endTime,
                area: values.area,
                ips: ips
            }
        };
        const hide = message.loading("执行中，请稍后......", 0);
        // if(values.action === "addByIps") {
        //     http.NO_Delay_POST(fileWallPolicyUrl + "firewall/firewallPolicy", bodyJson, (res) => {
        //         setTimeout(hide);
        //         if(res.code === "00") {
        //             message.success(res.msg);
        //         } else {
        //             message.error(res.msg);
        //         }
        //     })
        // } else if(values.action === "deleteByIps") {
        //     http.NO_Delay_DELETE(fileWallPolicyUrl + "firewall/firewallPolicy", bodyJson, (res) => {
        //         setTimeout(hide);
        //         if(res.code === "00") {
        //             message.success(res.msg);
        //         } else {
        //             message.error(res.msg);
        //         }
        //     })
        // }
        console.log(values, bodyJson)
    }
    addTabs() {
        let { addressTabs } = this.state;
        addressTabs.push("地址" + (addressTabs.length + 1));
        this.setState({ addressTabs })
    }
    render() {
        let  { addressTabs } = this.state;
        return (
            <div>
                <CustomBreadcrumb arr={["防火墙管理", "策略下发"]} />
                <Card title={<span style={{ fontWeight: 600 }}>策略下发</span>} style={{ marginTop: 8 }}>
                    <Formik
                        onSubmit={(value, actions) => { this.onSubmit(value); actions.setSubmitting(false); }}
                        validate={(values) => {
                            let info = {};
                            return info
                        }}
                        initialValues={{
                            serviceId: "",
                            area: "",
                            validTime: [],
                            action: "addByIps",
                        }}
                    >
                        <FormikForm labelCol={{ span: 5 }} wrapperCol={{ span: 13 }}>
                            <FormItem
                                name={"serviceId"}
                                label={"策略单号"}
                                key={"serviceId"}
                            >
                                <FormikInput name={"serviceId"} placeholder={"请输入策略单号"} />
                            </FormItem>
                            <FormItem
                                name={"area"}
                                label={"区域备注"}
                                key={"area"}
                            >
                                <FormikInput name={"area"} placeholder={"请输入区域备注"} />
                            </FormItem>
                            <FormItem
                                name={"validTime"}
                                label={"策略有效期"}
                                key={"validTime"}
                                hasFeedback={false}
                            >
                                <DatePicker.RangePicker name={"validTime"} showTime={true} placeholder={['开始时间', '结束时间']} format="YYYY-MM-DD HH:mm:ss" />
                            </FormItem>
                            <FormItem
                                name={"action"}
                                label={"操作类型"}
                                key={"action"}
                            >
                                <Radio.Group name={"action"} >
                                    <Radio name={"action"} value={"addByIps"}>增加</Radio>
                                    <Radio name={"action"} value={"deleteByIps"}>删除</Radio>
                                </Radio.Group>
                            </FormItem>
                            <FormItem
                                name={"ips"}
                                label={"IP地址信息"}
                                key={"ips"}
                                className="fireWallPolicyIpsTabs"
                            >
                                <Tabs name={"ips"}>
                                    { addressTabs.map((tabItem, index) => {
                                        return <TabPane className="tabsBox" tab={tabItem} key={index}>
                                            <FormItem
                                                name={`${tabItem}.sourceIps`}
                                                label={"源地址"}
                                                key={`${tabItem}.sourceIps`}
                                            >
                                                <Select mode="tags" tokenSeparators={[',','，',' ']} name={`${tabItem}.sourceIps`} placeholder={"请输入源地址"}></Select>
                                            </FormItem>
                                            <FormItem
                                                name={`${tabItem}.destinationIps`}
                                                label={"目的地址"}
                                                key={`${tabItem}.destinationIps`}
                                            >
                                                <Select mode="tags" tokenSeparators={[',','，',' ']} name={`${tabItem}.destinationIps`} placeholder={"请输入目的地址"}></Select>
                                            </FormItem>
                                            <FormItem
                                                name={`${tabItem}.service`}
                                                label={"端口号"}
                                                key={`${tabItem}.service`}
                                            >
                                                <Select mode="tags" tokenSeparators={[',','，',' ']} name={`${tabItem}.service`} placeholder={"请输入端口号"}></Select>
                                            </FormItem>
                                            <FormItem
                                                name={`${tabItem}.serviceType`}
                                                label={"端口类型"}
                                                key={`${tabItem}.serviceType`}
                                            >
                                                <Radio.Group name={`${tabItem}.serviceType`} defaultValue={"TCP"}>
                                                    <Radio name={`${tabItem}.serviceType`} value={"TCP"}>TCP</Radio>
                                                    <Radio name={`${tabItem}.serviceType`} value={"UDP"}>UDP</Radio>
                                                </Radio.Group>
                                            </FormItem>
                                            <Button className="addButton" type="primary" onClick={() => this.addTabs()}>增加地址单</Button>
                                        </TabPane>
                                    }) }
                                </Tabs>
                            </FormItem>

                            <div style={{ textAlign: "center" }}>
                                <SubmitButton style={{ marginLeft: "10px" }} disabled={false}>发送</SubmitButton>&nbsp;
                            </div>
                        </FormikForm>
                    </Formik>
                </Card>
            </div>
        )
    }
}

const FireWallPolicyForm = Form.create()(FireWallPolicy);
export default FireWallPolicyForm;