import React from 'react'
import './index.scss'
import {
    Card, Table, Input, Typography, Popconfirm, Button, Icon, Tabs, Row,
    notification, Col, message, Tag, Modal, Progress, List, Select
} from "antd";
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import CustomBreadcrumb from "@/components/CustomBreadcrumb/index";
import BaseForm from './BaseForm/index';
import $ from "jquery";
const { Title } = Typography;
const Option = Select.Option;


export default class Strategy extends React.Component {
    state = {
        // initialValue: {},
        fromAddrList: [],
        toList: []
    };
    componentWillMount() {
        // const initVal = this.props.initialValue;
        // const fromAddrList = this.props.fromAddrList;
        // const toList = this.props.toList;
        // if (initVal) {
        //     this.setState({ initialValue: initVal })
        // }
        // if (fromAddrList) {
        //     this.setState({ fromAddrList })
        // }
        // if (toList) {
        //     this.setState({ toList })
        // }
        // this.getFromList();
        // this.getToList();
    }
    onSubmit(body) {
        const { title } = this.props;

        let APIName = "AddStrategy";
        let InfoMessage = "新增";
        if (title === "编辑策略") {
            APIName = "UpdateStrategy";
            InfoMessage = "编辑";
        }
        Socket.ExecuteAction(APIName,
            body, "/QueryTaskResult", (result) => {
                message.success(InfoMessage + "成功");
                this.props.onCancel()
            }, () => {
                notification.error({
                    message: InfoMessage + '失败',
                    description: result.error,
                });
            });
    }
    getToList(val) {
        Socket.ExecuteAction("QueryTagsGroups",
            { tagClass: "to" }, "/QueryTaskResult", (result) => {
                let toList = result.tags;
                // let toList = result.data;
                this.setState({
                    toList, selFrom: val,
                })
            }, () => {
                notification.error({
                    message: '查询失败',
                    description: result.error,
                });
            });
    }
    getFromList() {
        const _this = this;
        Socket.ExecuteAction("QueryTagsGroups",
            { tagClass: "from" }, "/QueryTaskResult", (result) => {
                // let fromList = result.data;
                let fromAddrList = result.tags;
                _this.setState({ fromAddrList, })
            }, () => {
                notification.error({
                    message: '查询失败',
                    description: result.error,
                });
            });
    }
    render() {
        const { initialValue, } = this.props;
        const { fromAddrList, toList } = this.state;
        const formList = [
            {
                label: "策略名称",
                type: 'INPUT',
                field: "policyName",
                initialValue: initialValue.policyName || "",
                placeholder: "策略名称"
            },
            {
                label: "源头地址范围",
                type: 'SELECT',
                field: "sourceAddress",
                isTag: true,
                initialValue: initialValue.sourceAddress || [],
                list: fromAddrList.map((item) => {
                    return { id: item, name: item }
                }),
            },
            {
                label: "目的地址范围",
                type: 'SELECT',
                field: "destinationAddress",
                isTag: true,
                initialValue: initialValue.destinationAddress || [],
                list: toList.map((item) => {
                    return { id: item, name: item }
                }),
            },
            {
                label: "应用",
                placeholder: "应用",
                type: 'SELECT',
                field: "application",
                isTag: true,
                initialValue: initialValue.application || [],
                list: []
            },
            {
                label: "处理",
                placeholder: "处理",
                type: 'SELECT',
                field: "action",
                initialValue: initialValue.action || [],
                list: [{ id: "Permit", name: "Permit" }, { id: "Deny", name: "Deny" }, { id: "Tunel", name: "Tunel" }]
            },
        ]

        return (
            <Modal
                {...this.props}
            >
                <div className="modalDiv">
                    <BaseForm
                        formList={formList}
                        filterSubmit={(param) => {
                            this.onSubmit(param);
                        }}
                        editThis={this}
                        cancel={() => { this.props.onCancel() }}
                    />
                </div>
            </Modal>
        );
    }
} 