import 'babel-polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Router from './router'
import { Provider } from 'react-redux'
import configureStore from './redux/store/configureStore';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn')
// import "./mock/aclcmand.js"
// Redux Store对象，管理所有的Redux状态
const store = configureStore();
ReactDOM.render(
    <ConfigProvider locale={zhCN}>
        <Provider store={store}>
            <Router />
        </Provider>
    </ConfigProvider>,
    document.getElementById('root')
);


/* axios封装 */
// axios.defaults.withCredentials = true
// axios.defaults.timeout = 100000
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
// axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
// axios.interceptors.request.use(config => {
//     config.headers = {
//         'Content-Type': 'application/json;charset=UTF-8',
//         // 在这里设置请求头与携带token信息
//     }
//     return config
// })

/*IP管理使用*/
// eslint-disable-next-line no-undef
// axios.defaults.baseURL = ip_address
// React.$http_ip = axios.create({
//     // eslint-disable-next-line no-undef
//     baseURL: "http://" + ip_address,
// })

// /*专线管理使用*/
// // eslint-disable-next-line no-undef
// // axios.defaults.baseURL = zx_address
// React.$http_line = axios.create({
//     // eslint-disable-next-line no-undef
//     baseURL: zx_address,
// })



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
// serviceWorker.unregister();