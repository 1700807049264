import React from 'react'
import './index.scss'
import Socket from '../../../../socket/index'
import Utils from '../../../../utils/utils'
import {Card,Icon,Row,Col,Select,Tag,Button,Input,notification,message,Popconfirm} from "antd";
import QueueAnim from 'rc-queue-anim';
import CustomBreadcrumb from '../../../../components/CustomBreadcrumb/index';
import SettingForm from './settingForm';
const Option = Select.Option;
export default class appDetail extends React.Component{
    state = {
        paramData:null,
        param:null,
        version:'',
    };
    componentWillMount() {
        let thisTemp = this;
        let param = this.props.match.params.Id;
        console.log(param);
        if(param) {
            param = JSON.parse(param.replace(":", ""));
        }
        this.updata(param);
    }
    updata = (param) => {
        let thisTemp = this;
        let param2 = param;
        // console.log(1111);
        Socket.ActionStore('QueryAppDetail',
            param, (result) =>{
                console.log(result);
                if(result.retcode === '00'){
                    let paramData = result.data;
                    thisTemp.setState({
                        paramData,
                        param:param2
                    })
                }else {
                    notification.open({
                        message: '查询失败',
                        description: result.error,
                        icon: <Icon type="frown" style={{ color: 'red' }} />,
                    });
                }
            },"/queryApp");
    };
    InstallApp1 = (param) => {
        Socket.ActionStore('InstallApp1',
            param, (result) =>{
                console.log(result);
                if(result.retcode === '00'){
                    let Data = result.data;
                    let path = {
                        pathname:'/newAppStore',
                        state:Data,
                    };
                    this.props.history.push(path);
                }else {
                    notification.open({
                        message: '安装失败',
                        description: result.error,
                        icon: <Icon type="frown" style={{ color: 'red' }} />,
                    });
                }
            },"/installApp");
    };
    deleteApp = () => {
        let {paramData} = this.state;
        let param = {
            appStore: paramData.appStore,
            name: paramData.name,
            version: paramData.selectedVersion
        };
        Socket.ActionStore('DeleteApp',
            param, (result) =>{
                console.log(result);
                if(result.retcode === '00'){
                    message.error('删除成功');
                    // history.go(-1);
                    this.props.history.push('/appStore');
                }else {
                    notification.open({
                        message: '删除失败',
                        description: result.error,
                        icon: <Icon type="frown" style={{ color: 'red' }} />,
                    });
                }
            },"/DeleteApp");
    };
    paramRender = () => {
        let {paramData,} = this.state;

        // if(paramData){
        //     console.log(paramData);
            // let paramElemt = <table className="appParamTab"><tbody>
            //     <tr><td className="titleTD">执行方式</td><td>{Utils.Translation(paramData.taskPlan.typeType)}</td></tr>
            //     <tr><td className="titleTD">时间计划</td><td>{JSON.stringify(paramData.taskPlan.content)}</td></tr>
            //     <tr><td className="titleTD">任务预计耗时</td><td>{paramData.predictTime}</td></tr>
            //     <tr><td className="titleTD">标签</td><td>{paramData.tags.map((item,key)=>{
            //         return <Tag color="#108ee9">{item}</Tag> })}
            //         </td></tr>
            //     <tr><td className="titleTD">报告</td><td>{paramData.reportTags.map((item,key)=>{
            //         return <Tag color="#108ee9">{'类型:'+item.type+'|值:'+item.name}</Tag>})}
            //         </td></tr>
            //     <tr><td className="titleTD">输入参数</td><td>{paramData.input.map((item,key)=>{
            //         return <Tag color="#108ee9">{'参数名称:'+item.title+'|键名称:'+item.name}</Tag>})}
            //         </td></tr>
            // </tbody></table>;
            // return paramElemt;
            // return null;
        // }
    };
    render(){
        let {paramData,version,param} = this.state;
        let formList = [];
        if (!paramData) {
            paramData = {
                title: '',
                description: '',
                versions: []
            };
        }else {
            let tempArr = paramData.settings;
            if(tempArr){
                // let tempArr = JSON.parse(paramData.settings);
                tempArr.map((item, index) => {
                    formList.push({
                        type: 'INPUT',
                        label: item.label,
                        field: item.variable,
                        placeholder: item.description,
                        initialValue: item.default,
                        style: {
                            width:500
                        },
                        rules: [{
                            required:item.required, message: '必填!',
                        }],
                    })
                });
            }
        }
        return (
            <div className="detailPage">
                <CustomBreadcrumb arr={["配置管理",{title:"任务",to:'/task'},{title:'应用商店',to:'/appStore'},'查看详情']}/>
                <Card>
                    <Row>
                        <Col span={5} style={{textAlign: 'left',paddingLeft:'4%'}}>
                            <img className="imgLogo" src="http://www.tietuku.com/static/image/icon2.png" alt=""/>
                            {/*<img className="imgLogo" src={paramData.icon} alt=""/>*/}
                            <div><label className="labelTitle">应用商店：</label><label>{paramData.appStore}</label></div>
                            <div><label className="labelTitle">类别：</label><label>{paramData.category}</label></div>
                            <div><label className="labelTitle">维护者：</label><label>{paramData.maintainer}</label></div>
                            {/*<div><label className="labelTitle">项目URL：</label><label >{paramData.icon}</label></div>*/}
                        </Col>
                        <Col span={19}>
                            <Button className="pull-right" icon="rollback"
                                    type="primary"
                                    onClick={()=>{history.go(-1)}}>返回</Button>
                            <Popconfirm
                                title="确定删除吗?"
                                onConfirm={()=>{this.deleteApp()}}
                                onCancel={()=>{}}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button className="pull-right" icon="delete" type="danger">
                                    删除
                                </Button>
                            </Popconfirm>


                            {/*<Button className="pull-right" icon="download"*/}
                                    {/*type="primary"*/}
                                    {/*onClick={()=>{}}>下载</Button>*/}
                            <p style={{fontWeight: 700,marginTop:30}}>描述：</p>
                            <div>
                                {paramData.description}
                            </div>

                        </Col>
                    </Row>
                </Card>

                <Card title="模板版本" style={{margin:'10px 0'}}>
                    <Select placeholder="选择版本" value={version||paramData.selectedVersion}
                            onChange={(value)=>{this.setState({version:value})}}
                            style={{width:'80%'}}>
                        {
                            paramData.versions.map((item,key)=>{
                                return <Option key={key} value={item}>{item}</Option>
                            })
                        }
                    </Select>
                </Card>
                <Card title="配置选项">

                    <SettingForm  formList={formList} filterSubmit={(value)=>{
                        this.InstallApp1(param);
                    }}/>
                </Card>
            </div>
        );
    }
}