import React from 'react'
import { Button, Tree, Card, Form, Icon, Input, AutoComplete, Table, Tabs, Spin, message, Popconfirm, Row, Switch, Col, Modal, Select, Upload } from 'antd';
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import Socket from "../../../socket";
import iconClass from "../../permission/menu/iconList";
import './editSnmpConfig.scss'
import AxiosObj from "../../../axios";
// import GroupElemt from '@/components/ScriptModal/groupElemt';
import EditForm from "./editSnmpForm"

import YAML from "json2yaml";
// import {EyeOutlined } from '@ant-design/icons'

// monitor_address 配置地址：public/flow/config.js
const { TabPane } = Tabs;
const backUp_Url = monitor_address;
const { TreeNode } = Tree;

// const TreeData22 = [
//     {
//         "title": "首页",
//         "key": "/home",
//         "describe": "描述",
//         "children": [
//             {
//                 "title": "查询任务",
//                 "key": "/homeQueryTask",
//                 "describe": "describe",
//             }
//         ]
//     }
// ]
// let gg =[
//     {
//       "display": "监控指标 ",
//       "name": "test2",
//       "type": "label",
//       "option": "",
//       "addFlag": false
//     },
//     {
//         "display": "",
//         "name": "subtask",
//         "type": "input",
//         "width": 235,
//         "option": ""
//     },
//     {
//       "display": "默认图表 ",
//       "name": "test3",
//       "type": "label",
//       "option": "",
//       "addFlag": false
//     },
//     {
//         "display": "",
//         "name": "display",
//         "type": "select",
//         "width": 230,
//         "option": ['yes',"no"]
//     },
//     {
//         "display": "指标描述 ",
//         "name": "test4",
//         "type": "label",
//         "option": "",
//         "addFlag": false
//     },
//     {
//         "display": "",
//         "name": "describe",
//         "type": "input",
//         "width": 235,
//         "option": ""
//     }
// ]
class EditSnmpConfig extends React.Component {
    state = {
        loading: false,    //加载中组件显示与否
        json_generator: {},
        TreeData: [],
        catalog: [],  //两种显示方式，提取目录，不要children
        expandedKeys: [],
        dataList: [],
        searchValue: '',
        autoExpandParent: false,
        addVisible: false,
        editTableVisible: false,//设备添加的弹出框
        addTableVisible: false,   //表格修改弹出框显示
        editNode: {}, //点击修改时传递给表单的数据
        selectKey: [],
        tabSelect: '', //选中的标签页
        addDeviceTye: '', //标签页添加的设备类型（已有/新增）
        selectNode: {},
        RightMenuStyle: {},
        rightClickNodeTreeItem: null,
        dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        groupList: [
            {
                key: '',
                display: '',
                describe: ''
            }
        ]
    };
    componentWillMount() {
        this.getTreeData();
    }
    // 获取监控指标和初始表格数据
    getTreeData = () => {
        // let { TreeData} = this.state;
        let tmpTreeData = [];
        AxiosObj.MA_HTTP_GET(backUp_Url + "/snmpexporter/downloadConfigAndComment", (response) => {
            console.log(response)
            let data = response.data.modules;
            let tmpCatalog = [];
            for (let i in data) {
                // 获取一级目录
                let catalogMap = {};
                catalogMap["title"] = i;
                catalogMap["key"] = i
                tmpCatalog.push(catalogMap)

                //获取整个tree
                let map = {};
                map["title"] = i;
                map["key"] = i
                console.log(i);
                let walk = data[i]["walk"];
                let children = [];
                for (let j in walk) {
                    let tmpMap = {};
                    tmpMap["title"] = walk[j].index;
                    tmpMap["key"] = walk[j].index;
                    tmpMap["describe"] = walk[j].describe;
                    tmpMap['display'] = walk[j].display;
                    children[j] = tmpMap;
                }
                map["children"] = children;
                tmpTreeData.push(map)
            }
            console.log("treeData", tmpTreeData, 'catalog', tmpCatalog)
            let tmpKey = [];
            tmpKey.push(tmpCatalog[0].title)
            this.setState({
                TreeData: tmpTreeData,
                json_generator: response.data,
                catalog: tmpCatalog,
                selectKey: tmpKey
            })
            //获取默认标签页数据
            let selectNode = {
                title: tmpCatalog[0].title,
                key: tmpCatalog[0].key,
            }
            this.getDataSource(selectNode);
        }, (error) => {
            message.error(error)
        })
    };
    //获取设备类型的图表数据
    getDataSource(node) {
        //标签页方式 参数为index
        // if(! index){
        //     index = 0
        // }
        // let selectNode = {
        //     title: this.state.catalog[index].title,
        //     key: this.state.catalog[index].key,
        //     display: '',
        //     describe: '',
        //     parentKey: '',
        //     //icon: node.node.props.iconName,
        //     //index: node.node.props.index
        // }
        let selectNode = node;
        console.log('selectNode', selectNode)
        let deviceType = selectNode.title;
        let { TreeData } = this.state;
        let children = []
        for (let i in TreeData) {
            if (TreeData[i].title == deviceType) {
                children = TreeData[i].children;
                break
            }
        }
        let editNode = this.state.editNode;
        editNode['parentKey'] = selectNode.title;
        this.setState({
            selectNode,
            editNode: editNode,
            dataSource: children,
            // tabSelect:deviceType  //标签页时的选中
        })
    }

    //更新监控指标，向后台发起更新请求
    updateConfigData = (newTreeData) => {
        console.log("newTreeData", newTreeData);
        //后台数据样式组合
        let data = {};
        data['modules'] = {}
        for (let i in newTreeData) {
            let module = newTreeData[i].title;
            // let modules ={}
            let walk = [];
            for (let j in newTreeData[i].children) {
                let childrenMap = {};
                childrenMap['index'] = newTreeData[i].children[j].title;
                childrenMap['display'] = newTreeData[i].children[j].display;
                childrenMap['describe'] = newTreeData[i].children[j].describe;
                walk.push(childrenMap)
            }
            let tmpWalk = {};
            tmpWalk['walk'] = walk;
            // modules[module] = tmpWalk;
            data["modules"][module] = tmpWalk;
        }
        console.log('发送数据', data)
        this.setState({ loading: true })
        AxiosObj.HTTP_POST('alarm', "/snmpexporter/uploadConfig", data, (value) => {
            //关闭弹出框和加载中显示
            this.setState({ loading: false, addVisible: false });
            message.success('success');
            this.getTreeData()
            //this.props.history.goBack();
        }, (error) => {
            //关闭弹出框和加载中显示
            this.setState({ loading: false, addVisible: false });
            message.error(error);
            this.getTreeData()
        })
        // this.setState({
        //     TreeData : newTreeData
        // })

    };
    getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some(item => item.key === key)) {
                    parentKey = node.key;
                } else if (this.getParentKey(key, node.children)) {
                    parentKey = this.getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };
    //待修改
    onDrop = info => {
        console.log(info);
        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dropPos = info.node.props.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        // const loop = (data, key, callback) => {
        //     data.forEach((item, index, arr) => {
        //         if (item.key === key) {
        //             return callback(item, index, arr);
        //         }
        //         if (item.children) {
        //             return loop(item.children, key, callback);
        //         }
        //     });
        // };
        // const data = [...this.state.TreeData];

        // // Find dragObject
        // let dragObj;
        // loop(data, dragKey, (item, index, arr) => {
        //     arr.splice(index, 1);
        //     dragObj = item;
        // });

        // if (!info.dropToGap) {
        //     // Drop on the content
        //     loop(data, dropKey, item => {
        //         item.children = item.children || [];
        //         // where to insert 示例添加到尾部，可以是随意位置
        //         item.children.push(dragObj);
        //     });
        // } else if (
        //     (info.node.props.children || []).length > 0 && // Has children
        //     info.node.props.expanded && // Is expanded
        //     dropPosition === 1 // On the bottom gap
        // ) {
        //     loop(data, dropKey, item => {
        //         item.children = item.children || [];
        //         // where to insert 示例添加到头部，可以是随意位置
        //         item.children.unshift(dragObj);
        //     });
        // } else {
        //     let ar;
        //     let i;
        //     loop(data, dropKey, (item, index, arr) => {
        //         ar = arr;
        //         i = index;
        //     });
        //     if (dropPosition === -1) {
        //         ar.splice(i, 0, dragObj);
        //     } else {
        //         ar.splice(i + 1, 0, dragObj);
        //     }
        // }

        // this.setState({
        //     TreeData: data,
        // });

        let dragNodes = info.dragNode;
        let body = {
            key: dragNodes.props.eventKey,
            parentKey: dragNodes.props.parentKey,
            index: parseInt(dropPos[dropPos.length - 1]) + 1,
            title: dragNodes.props.titleName,
            display: dragNodes.props.display,
            describe: dragNodes.props.describe,
            icon: dragNodes.props.iconName,
        }
        Socket.ExecuteAction("UpdateMenu", body, "", (result) => {
            message.success("修改成功!");
            this.getMenuData();
        });
    };
    onChange = e => {
        const { value } = e.target;
        const expandedKeys = this.state.dataList
            .map(item => {
                if (item.title.indexOf(value) > -1) {
                    return this.getParentKey(item.key, this.state.TreeData);
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);
        this.setState({
            expandedKeys,
            searchValue: value,
            autoExpandParent: true,
        });
    };
    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };
    // tree列表上右键事件
    onRightClick = e => {
        let selectNode = {
            title: e.node.props.titleName,
            key: e.node.props.eventKey,
            parentKey: e.node.props.parentKey,
            icon: e.node.props.iconName
        }
        this.setState({
            selectNode,
            rightClickNodeTreeItem: {
                pageX: e.event.pageX,
                pageY: e.event.pageY,
                idKey: e.node.props["eventKey"],
                categoryName: e.node.props["titleName"]
            }
        });
    };

    //tree 列表点击右键菜单选项
    clickRightMenu(key, nodeKey) {
        let { TreeData, selectNode } = this.state;
        let newTreeData = TreeData;
        let actionFlag;
        switch (key) {
            case "del":
                this.deleteMenu(newTreeData, selectNode);
                //newTreeData = this.deleteMenu(newTreeData, selectNode);
                console.log(newTreeData);
                //this.updateMenuData("DeleteMenu", selectNode, newTreeData);
                break;
            case "add": actionFlag = "add"; break;
            case "addChild": actionFlag = "addChild"; break;
        }
        let state = true;
        if (key === "del") {
            state = false
        }
        this.setState({ addVisible: state, rightClickNodeTreeItem: null, actionFlag, nodeKey, })
    }
    //tree列表 自定义右键菜单内容
    getNodeTreeRightClickMenu = () => {
        let { selectNode } = this.state;
        //判断是否是根目录，根据级别不同来显示不同的页面内容
        let flag1, flag2;
        console.log(selectNode.parentKey);
        if (selectNode.parentKey == "") {
            flag1 = ""
            flag2 = "none"
        } else {
            flag1 = "none"
            flag2 = ""
        }
        const { pageX, pageY, idKey, categoryName } = { ...this.state.rightClickNodeTreeItem };
        const RightMenuStyle = {
            position: "absolute",
            left: `${pageX - 200}px`,
            top: `${pageY - 118}px`,
        };
        const menu = (
            <div style={RightMenuStyle} className="TreeRightMenu">
                <div style={{ display: flag1 }} onClick={() => { this.setState({ addDeviceTye: '' }), this.clickRightMenu("add", idKey) }} className="add"><Icon type="plus-circle" />  新增设备类型</div>
                <div style={{ display: flag2 }} onClick={() => this.clickRightMenu("addChild", idKey)} className="add"><Icon type="plus-circle" />  新增监控指标</div>
                <Popconfirm
                    title="确定删除吗?"
                    onConfirm={() => this.clickRightMenu("del", idKey)}
                    okText="Yes"
                    cancelText="No"
                >
                    <div className="delete"><Icon type="delete" />  删除选中</div>
                </Popconfirm>
            </div>
        );
        return this.state.rightClickNodeTreeItem == null ? "" : menu;
    };
    //待修改
    //     addChildNode(_treeData, nodeKey, values) {
    //         let newtreeData = _treeData.map((node) => {
    //             console.log(node)
    //             if (node["key"] === nodeKey) {
    //                 if (node["children"]) {
    //                     node["children"].push(values);
    //                 } else {
    //                     node["children"] = [values];
    //                 }
    //             } else if (node["children"]) {
    //                 node["children"] = this.addChildNode(node["children"], nodeKey, values);
    //             }
    //             return node;
    //         });
    //         return newtreeData;
    //     }
    //删除树状图节点
    deleteMenu(_treeData, selectNode) {
        let newtreeData = [];
        for (let i in _treeData) {
            if (selectNode.parentKey == "" && _treeData[i].title == selectNode.title) {
                _treeData.splice(i, 1)
            } else if (_treeData[i].title == selectNode.parentKey) {
                for (let j in _treeData[i].children) {
                    if (_treeData[i].children[j].title == selectNode.title) {
                        _treeData[i].children.splice(j, 1)
                        break
                    }
                }
            }
        }
        newtreeData = _treeData;
        console.log(newtreeData)
        // _treeData.filter((node) => {
        //     console.log(node)
        //     if (node["key"] === selectNode.parentKey) {
        //
        //     } else if (node["children"]) {
        //         //node["children"] = this.deleteMenu(node["children"], nodeKey);
        //         newtreeData.push(node);
        //     } else {
        //         return newtreeData.push(node);
        //     }
        // });
        this.updateConfigData(newtreeData);
        //return newtreeData;
    }
    //删除表格选中的指标
    deleteDeviceIndex = () => {
        let { selectedRows, selectNode, TreeData } = this.state;
        if (selectedRows.length > 0) {
            //
        } else {
            message.error('请先选择要删除的指标')
            return
        }
        let newTreData = []
        for (let i in TreeData) {
            if (TreeData[i].title != selectNode.title) {
                newTreData.push(TreeData[i])
            } else {
                let map = {}
                map['title'] = TreeData[i].title;
                map['key'] = TreeData[i].key;
                map['children'] = []
                for (let j in TreeData[i].children) {
                    let map1 = {}  //接收children中未被删除的map
                    let judge = false //判断子树中的节点是否删除
                    for (let k in selectedRows) {
                        if (TreeData[i].children[j].title == selectedRows[k].title) {
                            judge = true
                            break
                        }
                    }
                    if (!judge) {
                        map1['title'] = TreeData[i].children[j].title;
                        map1['key'] = TreeData[i].children[j].key;
                        map1['display'] = TreeData[i].children[j].display;
                        map1['describe'] = TreeData[i].children[j].describe;
                    }
                    if (map1.title) {
                        map['children'].push(map1)
                    }
                }
                newTreData.push(map)
            }
        }
        // console.log(newTreData)
        this.updateConfigData(newTreData);

    }
    //表单提交方法
    upDataSubmit() {
        // e.preventDefault();
        let _this = this;
        let { actionFlag, TreeData, nodeKey, selectNode, groupList } = this.state;
        let newTreeData = TreeData;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                console.log('groupList', groupList);
                values.key = groupList;   //使用自己写的组件的获取值
                switch (actionFlag) {
                    case "add":
                        let children = [];
                        let addMap = {};
                        for (let i in values.key) {
                            let map = {}
                            console.log(values.key[i].key)
                            map["title"] = values.key[i].key;
                            map["key"] = values.key[i].key;
                            map['display'] = values.key[i].display;
                            map["describe"] = values.key[i].describe;
                            children.push(map)
                        }
                        addMap["title"] = values.title;
                        addMap["key"] = values.title;;
                        addMap["children"] = children;
                        newTreeData.push(addMap);
                        //values.parentKey = selectNode.parentKey;
                        // _this.updateMenuData("AddMenu", values, newTreeData);
                        break;
                    case "addChild":
                        for (let i in values.key) {
                            console.log("i", i)
                            let map = {}
                            console.log(values.key[i].key)
                            map["title"] = values.key[i].key;
                            map["key"] = values.key[i].key;
                            map['display'] = values.key[i].display;
                            map["describe"] = values.key[i].describe;
                            for (let j in newTreeData) {
                                if (values.title == newTreeData[j].title) {
                                    newTreeData[j].children.push(map)
                                    break
                                }
                            }
                        }
                        //console.log("newTreeData",newTreeData)
                        //newTreeData = _this.addChildNode(TreeData, selectNode.parentKey, values);
                        //values.parentKey = selectNode.key;
                        //_this.updataMenuData("AddMenu", values, newTreeData);
                        break;
                    // case "del":
                    //     newTreeData = this.deleteMenu(TreeData, nodeKey);
                    //     _this.updateMenuData("DeleteMenu", { key: selectNode["key"] }, newTreeData);
                    //     break;
                }
                //console.log("newTreeData",newTreeData);
                //console.log("treeData",TreeData)
                // _this.updateMenuData(newTreeData);
                console.log('newTreeData', newTreeData)
                _this.updateConfigData(newTreeData)
                // _this.setState({
                //     TreeData : newTreeData,
                //     addVisible: false
                // });

            }
        });
    }
    changeDataFun(_treeData, nodeObj) {
        let nodeKey = nodeObj["key"];
        let newTreeData = _treeData.map((node) => {
            if (node["key"] === nodeKey) {
                let newNode = nodeObj;
                if (node["children"]) {
                    newNode["children"] = node["children"]
                }
                return newNode;
            } else if (node["children"]) {
                node["children"] = this.changeDataFun(node["children"], nodeObj);
                return node;
            } else {
                return node;
            }
        });
        return newTreeData
    }
    changeTreeData(values) {
        let _this = this;
        let { TreeData, selectNode } = this.state;
        let newTreeData = this.changeDataFun(TreeData, values);
        console.log(newTreeData);
        values.parentKey = selectNode.parentKey;
        _this.updateMenuData("UpdateMenu", values, newTreeData);
    }
    //修改 修改页面的显示与否
    changeEditTableVisible = () => {
        this.setState({
            editTableVisible: false,
        })
    }

    handleOk = e => {
        console.log(e);
        this.setState({
            editTableVisible: false,
        });
    };
    render() {
        let { groupList, selectKey, loading, actionFlag, selectNode, expandedKeys, autoExpandParent, searchValue, addVisible, tabSelect, addDeviceTye, editTableVisible, addTableVisible, TreeData, dataSource, editNode, catalog } = this.state
        //上传mibs库文件
        const props = {
            name: 'config',
            action: backUp_Url + '/snmpexporter/uploadMib',
            headers: {
                authorization: 'authorization-text',
                // 'Content-Type':'multipart/form-data;charse=UTF-8',
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    if (info.file.response.code === 0) {
                        message.success(`${info.file.name} file uploaded successfully`);
                    } else {
                        message.error(info.file.response.msg);
                    }
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };
        //动态组件
        const groupElem = (value) =>
            value.map((item) => {
                const dataSource = ['CPU使用率', '空闲内存', '分配内存'];
                return <div style={{ borderBottom: "1px solid #e2e2e2", padding: 8 }}>
                    <span>
                        监控指标
                        <Input style={{ width: 235, marginLeft: 15, marginRight: 15 }}
                            onChange={(value) => { item['key'] = value.target.value; }} />
                        图表展示
                        <Switch style={{ marginLeft: 15 }} checkedChildren="是" unCheckedChildren="否"
                            onClick={(value) => {
                                if (value) {
                                    item['display'] = "yes";
                                } else {
                                    item['display'] = "no";
                                }
                                //setTimeout(console.log(this.state.switchPersistence),1000)
                            }}
                        />
                        {/*<Select style={{width:80,marginLeft:15 }}  onChange={(value)=>{item['display'] = value;}}>*/}
                        {/*<Option value="yes">yes</Option>*/}
                        {/*<Option value="no">no</Option>*/}
                        {/*</Select>*/}
                        <br />
                    </span>
                    <span>
                        指标描述
                        <AutoComplete onChange={(value) => { item['describe'] = value }} dataSource={dataSource}
                            placeholder="选则或者自己添加" style={{ width: 235, marginLeft: 15, marginRight: 15 }} />
                        <Button type="primary" size="small" icon="plus-circle" onClick={() => {
                            let tmpMap = {
                                index: '',
                                display: '',
                                describe: ''
                            };
                            value.push(tmpMap)
                            this.setState({
                                groupList: value
                            })
                        }} />&nbsp;
                        <Button type="danger" size="small" icon="minus-circle" onClick={() => {
                            value.pop();
                            this.setState({ groupList: value })
                        }} />
                    </span>
                </div>
            })


        // let {form} = this.props;
        // const [editingKey, setEditingKey] = useState('');
        // const isEditing = record => record.key === editingKey;
        //
        // const edit = record => {
        //     form.setFieldsValue({
        //         name: '',
        //         age: '',
        //         address: '',
        //         ...record,
        //     });
        //     setEditingKey(record.key);
        // };
        //
        // const cancel = () => {
        //     setEditingKey('');
        // };
        //
        // const save = async key => {
        //     try {
        //         const row = await form.validateFields();
        //         const newData = [...data];
        //         const index = newData.findIndex(item => key === item.key);
        //
        //         if (index > -1) {
        //             const item = newData[index];
        //             newData.splice(index, 1, { ...item, ...row });
        //             setData(newData);
        //             setEditingKey('');
        //         } else {
        //             newData.push(row);
        //             setData(newData);
        //             setEditingKey('');
        //         }
        //     } catch (errInfo) {
        //         console.log('Validate Failed:', errInfo);
        //     }
        // };

        const columns = [
            {
                title: '设备指标',
                dataIndex: 'title',
                key: 'title',
                width: '35%'
            },
            {
                title: '默认图表',
                dataIndex: 'display',
                key: 'display',
                width: '10%',
                // render : (text)=>{
                //     return <div>
                //     <Switch size="small" defaultChecked />
                // </div>
                // }
            },
            {
                title: '描述',
                dataIndex: 'describe',
                key: 'describe',
                width: '35%'
            },
            //   {
            //       title: 'operation',
            //       dataIndex: 'operation',
            //       render: (_, record) => {
            //           const editable = isEditing(record);
            //           return editable ? (
            //               <span>
            //   <a
            //       href="javascript:;"
            //       onClick={() => save(record.key)}
            //       style={{
            //           marginRight: 8,
            //       }}
            //   >
            //     Save
            //   </a>
            //   <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
            //     <a>Cancel</a>
            //   </Popconfirm>
            // </span>
            //           ) : (
            //               <a disabled={editingKey !== ''} onClick={() => edit(record)}>
            //                   Edit
            //               </a>
            //           );
            //       },
            //   },
            {
                title: '修改',
                width: '20%',
                align: 'center',
                render: (text, item) => {
                    return <div>
                        <Button type="primary" onClick={() => {
                            let parentKey = this.state.editNode.parentKey;
                            text['parentKey'] = parentKey;
                            this.setState({
                                editTableVisible: true,
                                editNode: text
                            })
                        }}>
                            <Icon type="form" />修改
                        </Button>
                    </div>
                }
            },
        ];
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys:`, selectedRowKeys, 'selectedRows: ', selectedRows);
                this.setState({
                    selectedRowKeys: selectedRowKeys,
                    selectedRows: selectedRows
                });
            },
            selections: [
                {
                    key: 'cancel',
                    text: '清空选择',
                    onSelect: changableRowKeys => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        const { getFieldDecorator } = this.props.form;
        const loop = (data, parentKey) =>
            data.map((item, menuIndex) => {
                const index = item.title.indexOf(searchValue);
                const beforeStr = item.title.substr(0, index);
                const afterStr = item.title.substr(index + searchValue.length);
                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span style={{ color: '#f50' }}>{searchValue}</span>
                            {afterStr}
                        </span>
                    ) : (
                            <span>{item.title}</span>
                        );
                if (item.children) {
                    return (
                        <TreeNode icon={<Icon type={item.icon || "compass"} />} key={item.key} title={title} display={item.display}
                            titleName={item.title}
                            parentKey={parentKey}
                            describe={item.describe}
                            index={menuIndex}
                            iconName={item.icon}>
                            {loop(item.children, item.key)}
                        </TreeNode>
                    );
                }
                return <TreeNode icon={<Icon type={item.icon || "compass"} />} key={item.key} title={title} display={item.display}
                    titleName={item.title}
                    parentKey={parentKey}
                    describe={item.describe}
                    index={menuIndex}
                    iconName={item.icon} />;
            });
        return (
            <div>
                <Spin spinning={loading}>
                    <CustomBreadcrumb arr={["监控告警", "数据采集", "snmp指标"]} />
                    {/*<Card title="监控指标" className="treeCrad" >*/}
                    {/*<Row>*/}
                    {/*<Col span={11} >*/}
                    {/*<Tree*/}
                    {/*showLine*/}

                    {/*onSelect={(nodeKey, node) => {*/}
                    {/*console.log(node);*/}
                    {/*let selectNode = {*/}
                    {/*title: node.node.props.titleName,*/}
                    {/*key: node.node.props.eventKey,*/}
                    {/*display: node.node.props.display,*/}
                    {/*describe: node.node.props.describe,*/}
                    {/*parentKey: node.node.props.parentKey,*/}
                    {/*icon: node.node.props.iconName,*/}
                    {/*index: node.node.props.index*/}
                    {/*}*/}
                    {/*this.setState({ selectNode, rightClickNodeTreeItem: null })*/}
                    {/*}}*/}
                    {/*onRightClick={this.onRightClick}*/}
                    {/*onExpand={this.onExpand}*/}
                    {/*defaultExpandAll={true}*/}
                    {/*autoExpandParent={autoExpandParent}*/}
                    {/*blockNode*/}
                    {/*// onDragEnter={this.onDragEnter}*/}
                    {/*// onDrop={this.onDrop}*/}
                    {/*>*/}
                    {/*{loop(this.state.TreeData, "")}*/}
                    {/*</Tree>*/}
                    {/*<span >*/}
                    {/*<Button type="primary" icon="check-circle" htmlType="submit" style={{marginTop:20, marginLeft:15}}*/}
                    {/*onClick={()=>this.updateConfigData(TreeData)}>*/}
                    {/*确认修改*/}
                    {/*</Button>*/}
                    {/*<Button type="danger" icon="close-circle" style={{  marginLeft: 10 }}*/}
                    {/*onClick={() => { this.props.history.goBack(); }}>*/}
                    {/*取消*/}
                    {/*</Button>*/}
                    {/*</span>*/}
                    {/*</Col>*/}
                    {/*<Col span={11}>*/}
                    {/*<EditForm  selectNode={selectNode}/>*/}
                    {/*</Col>*/}
                    {/*/!*<Col span={11} >*!/*/}
                    {/*/!*设备类型 <input style={{marginBottom:10,width:150}  }/>*!/*/}
                    {/*/!*<GroupElemt  onDataChange={(e) => { console.log(e) }} group={gg} addFlag={true} />*!/*/}
                    {/*/!*<Button style={{marginLeft:150,marginTop:30}  } type="primary">*!/*/}
                    {/*/!*添加监控指标*!/*/}
                    {/*/!*</Button>*!/*/}
                    {/*/!*</Col>*!/*/}
                    {/*</Row>*/}
                    {/*{this.getNodeTreeRightClickMenu()}*/}
                    {/*<Modal*/}
                    {/*width = {940}*/}
                    {/*title={"新增"}*/}
                    {/*visible={addVisible}*/}
                    {/*onOk={() => { this.upDataSubmit() }}*/}
                    {/*onCancel={() => { this.setState({ addVisible: false }) }}*/}
                    {/*okText="确认"*/}
                    {/*cancelText="取消"*/}
                    {/*// destroyOnClose*/}
                    {/*afterClose={() => {*/}
                    {/*// this.setState({ selectOne: {} })*/}
                    {/*this.props.form.resetFields();//清空表单*/}
                    {/*}}*/}
                    {/*>*/}
                    {/*<Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} onSubmit={this.upDataSubmit}>*/}
                    {/*<Form.Item*/}
                    {/*label="设备类型"*/}
                    {/*>*/}
                    {/*{getFieldDecorator('title', {*/}
                    {/*initialValue: selectNode.parentKey,*/}
                    {/*rules: [{*/}
                    {/*required: true, message: '请输入字符!',*/}
                    {/*}],*/}
                    {/*placeholder: "设备类型"*/}
                    {/*})(<Input />)}*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item*/}
                    {/*label="添加指标">*/}
                    {/*{getFieldDecorator('key', {*/}
                    {/*// initialValue: selectNode.key || "",*/}
                    {/*// rules: [{*/}
                    {/*//     required: true, message: '请输入字符!',*/}
                    {/*// }],*/}
                    {/*placeholder: "菜单URL路径"*/}
                    {/*})(<GroupElemt  onDataChange={(e) => {*/}
                    {/*console.log("数组值",e)*/}
                    {/*let addList=[];*/}
                    {/*for(let  i in e){*/}
                    {/*//获取输入指标数据*/}
                    {/*let map = {}*/}
                    {/*map['key'] = e[i].subtask;*/}
                    {/*map['describe'] = e[i].resource;*/}
                    {/*addList[i] = map*/}

                    {/*}*/}
                    {/*console.log("addList",addList)*/}
                    {/*//console.log("获取值",addList)*/}
                    {/*this.props.form.setFieldsValue({*/}
                    {/*key : addList*/}
                    {/*})*/}

                    {/*}}*/}
                    {/*group={gg} addFlag={true} />)}*/}
                    {/*</Form.Item>*/}
                    {/*/!*<Form.Item*!/*/}
                    {/*/!*label="菜单图标"*!/*/}
                    {/*/!*>*!/*/}
                    {/*/!*{getFieldDecorator('icon', {*!/*/}
                    {/*// initialValue: selectNode.icon || "",*/}
                    {/*/!*rules: [{*!/*/}
                    {/*/!*required: true, message: '请输入字符!',*!/*/}
                    {/*/!*}],*!/*/}
                    {/*/!*placeholder: "菜单图标"*!/*/}
                    {/*/!*})(iconClass.getIconArray())}*!/*/}
                    {/*/!*</Form.Item>*!/*/}
                    {/*/!*<Form.Item*!/*/}
                    {/*/!*label="是否导航菜单"*!/*/}
                    {/*/!*>*!/*/}
                    {/*/!*{getFieldDecorator('display', {*!/*/}
                    {/*// initialValue: selectNode.key || "",*/}
                    {/*/!*rules: [{*!/*/}
                    {/*/!*required: true, message: '请选择!',*!/*/}
                    {/*/!*}],*!/*/}
                    {/*/!*})(<Select>*!/*/}
                    {/*/!*<Select.Option value={"yes"} key={"yes"}>yes</Select.Option>*!/*/}
                    {/*/!*<Select.Option value={"no"} key={"no"}>no</Select.Option>*!/*/}
                    {/*/!*</Select>)}*!/*/}
                    {/*/!*</Form.Item>*!/*/}
                    {/*</Form>*/}
                    {/*</Modal>*/}
                    {/*</Card>*/}


                    {/*监控指标样式2*/}
                    <Card title={<span style={{ fontWeight: 600 }}>监控指标</span>} className="snmpMonitorTreeCrad"
                        extra={<span>
                            <Button type="danger" icon="delete" onClick={() => { this.deleteDeviceIndex(); }}>
                                删除指标
                            </Button>
                            <Button type="primary" icon="file-add"
                                onClick={() => {
                                    this.setState({ addDeviceTye: this.state.selectNode.title })
                                    this.clickRightMenu("addChild")
                                }}>
                                新增指标
                            </Button>
                            <Upload {...props}   >
                                <Button type="primary" icon="upload">
                                    上传mibs库文件
                                </Button>
                            </Upload>
                        </span>}
                    >
                        <div style={{ border: "1px solid #e2e2e2" }}>
                            <Row >
                                {/*以标签页的形式展示*/}
                                {/*<Tabs defaultActiveKey={0} tabPosition={"left"}*/}
                                {/*onChange={(index)=> {*/}
                                {/*this.getDataSource(index)*/}

                                {/*}}*/}
                                {/*>*/}
                                {/*<Button type="primary" icon="edit" style={{ marginLeft: 8,marginBottom: 15 }}*/}
                                {/*onClick={() => {this.setState({addDeviceTye : tabSelect})*/}
                                {/*this.clickRightMenu("addChild")} }>*/}
                                {/*添加指标*/}
                                {/*</Button>*/}
                                {/*<Button type="primary" icon="edit" style={{ marginLeft: 8 }}*/}
                                {/*onClick={() => {  }}>/!* 记的修改路由 *!/*/}
                                {/*删除指标*/}
                                {/*</Button>*/}
                                {/*{this.state.catalog.map((i,index) => (*/}
                                {/*<TabPane tab={i.title} key={index}>*/}
                                {/*<Table  rowSelection={rowSelection} size="middle"*/}
                                {/*dataSource={dataSource} columns={columns} />*/}
                                {/*</TabPane>*/}
                                {/*))}*/}
                                {/*</Tabs>*/}

                                {/*树状图+form展示*/}
                                <Col span={4} >
                                    <Tree
                                        // style={{ marginLeft: 0, paddingRight: 25 }}
                                        size='small'
                                        // showLine
                                        showIcon
                                        selectedKeys={selectKey}
                                        onSelect={(nodeKey, node) => {
                                            console.log(node);
                                            let selectNode = {
                                                title: node.node.props.titleName,
                                                key: node.node.props.eventKey,
                                                display: node.node.props.display,
                                                describe: node.node.props.describe,
                                                parentKey: node.node.props.parentKey,
                                                icon: node.node.props.iconName,
                                                index: node.node.props.index
                                            }
                                            this.getDataSource(selectNode)
                                            //this.getDataSource(selectNode.title)  //获取标签页方式数据
                                            let editNode = this.state.editNode;
                                            editNode['parentKey'] = selectNode.title;
                                            this.setState({ selectNode, rightClickNodeTreeItem: null, editNode: editNode, selectKey: [node.node.props.eventKey] })
                                        }}
                                        onRightClick={this.onRightClick}
                                        onExpand={this.onExpand}
                                        defaultExpandAll={true}
                                        autoExpandParent={autoExpandParent}
                                        blockNode
                                    // onDragEnter={this.onDragEnter}
                                    // onDrop={this.onDrop}
                                    >
                                        {loop(this.state.catalog, "")}
                                    </Tree>
                                </Col>
                                <Col span={20}>
                                    {/*<Button type="primary" icon="edit" style={{  float:'right', marginLeft: 8 }}*/}
                                    {/*onClick={() => {  }}>/!* 记的修改路由 *!/*/}
                                    {/*删除指标*/}
                                    {/*</Button>*/}
                                    {/*<Button type="primary" icon="edit" style={{ float:'right',  marginLeft: 8,marginBottom: 15 }}*/}
                                    {/*onClick={() => {*/}
                                    {/*//this.setState({addDeviceTye : tabSelect})*/}
                                    {/*this.clickRightMenu("addChild")} }>*/}
                                    {/*添加指标*/}
                                    {/*</Button>*/}
                                    <Table
                                        style={{ background: '#ffffff', borderLeft: "1px solid #e2e2e2" }}
                                        size="middle"
                                        rowSelection={rowSelection}
                                        dataSource={dataSource}
                                        columns={columns}
                                    />
                                </Col>
                            </Row>
                        </div>
                        {this.getNodeTreeRightClickMenu()}
                        <Modal
                            width={"50%"}
                            destroyOnClose={true}
                            footer={null}
                            title={"修改"}
                            visible={this.state.editTableVisible}
                            onOk={(e) => {

                                console.log('modal', e)
                                //this.upDataSubmit()
                            }}
                            onCancel={() => { this.setState({ editTableVisible: false }) }}
                            okText="确认"
                            cancelText="取消"
                            // destroyOnClose
                            afterClose={() => {
                                // this.setState({ selectOne: {} })
                                this.props.form.resetFields();//清空表单
                            }}
                        >
                            <EditForm callback={this.changeEditTableVisible} treeData={TreeData} selectNode={editNode} />
                        </Modal>
                        <Modal
                            width={800}
                            title={"新增"}
                            destroyOnClose={true}
                            visible={addVisible}
                            onOk={() => { this.upDataSubmit() }}
                            onCancel={() => { this.setState({ addVisible: false }) }}
                            okText="确认"
                            cancelText="取消"
                            destroyOnClose
                            afterClose={() => {
                                // this.setState({ selectOne: {} })
                                this.props.form.resetFields();//清空表单
                            }}
                        >
                            <Spin spinning={loading}>
                                <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} onSubmit={this.upDataSubmit}>
                                    <Form.Item
                                        label="设备类型"
                                    >
                                        {getFieldDecorator('title', {
                                            initialValue: addDeviceTye || '',
                                            rules: [{
                                                required: true, message: '请输入字符!',
                                            }],
                                            placeholder: "设备类型"
                                        })(<Input disabled={actionFlag == "addChild" || false} />)}
                                    </Form.Item>
                                    <Form.Item
                                        label="添加指标">
                                        {getFieldDecorator('key', {
                                            initialValue: groupList || '',
                                            // initialValue: selectNode.key || "",
                                            // rules: [{
                                            //     required: true, message: '请输入字符!',
                                            // }],
                                            placeholder: "菜单URL路径"
                                        })(<div style={{ border: "1px solid #e2e2e2", padding: 10, maxHeight: 500, overflowY: "auto" }}>
                                            {groupElem(groupList)}
                                        </div>)}
                                    </Form.Item>
                                </Form>
                            </Spin>
                        </Modal>
                    </Card>
                </Spin>
            </div>



        );
    }
}
const EditSnmpConfigObj = Form.create()(EditSnmpConfig)
export default EditSnmpConfigObj
