import React from 'react'
import axios from '@/axios/axios_utils';
import DyHeadTable from '@/components/DyHeadTable/index';
import FilterForm from '@/components/FilterForm/index';
import './index.scss';
import { Table, Collapse, Card, Button, Divider, Modal, Tabs, Tooltip, Row, Col, message, Icon, Tag } from "antd";

const dumbTerminal_Url = "http://" + backup_address + "/ZJNMS_V1/dumb_termial/";

class auditNotPassed extends React.Component {
    state = {
        tableData: [], // 表格数据
        current_page: 1,  // 当前页
        pageSize: 20,
        total: 0,
        loading: false,
        query: {},
        selectOne: {},
        tcpVisible: false,
    }

    componentWillMount() {
        this.getTableData();
    }
    componentDidMount() {
        this.props.onRef(this);
    }
    openTcpModal(b) {
        this.setState({ selectOne: b, tcpVisible: true })
    }
    getTcpTabModal(objData) {
        const data = Object.keys(objData).map((key) => {
            let newone = objData[key];
            newone.port = key;
            return newone
        });
        return <Table
            columns={[
                { title: "port", dataIndex: "port" },
                ...Object.keys(data[0] || {}).filter((f) => f != "port").map((t) => {
                    return { title: t, dataIndex: t, }
                })
            ]}
            scroll={{ y: 400 }}
            size="small"
            rowKey={r => r.port}
            dataSource={data}
            pagination={false}
        />
    }
    getOsModal(osInfo) {
        if (osInfo) {
            return <Table
                columns={[{ title: "相似操作系统", dataIndex: "os", align: "left" }, { title: "相似度", width: 50, dataIndex: "accuracy", render: (a) => (a + "%") }]}
                size="small"
                scroll={{ y: 400 }}
                rowKey={r => r.os}
                dataSource={osInfo || []}
                pagination={false}
            />
        } else {
            return null;
        }
    }
    getTableData(cur_page, page_size, querys) {
        this.setState({ loading: true, });
        const { current_page, pageSize, query } = this.state;
        let bodyQuery = querys || query;
        let bodyJson = {
            page_no: cur_page || current_page,
            page_size: page_size || pageSize,
            audit_status: 2,
            dep_ids: this.props.depIds.join(","),
            ...bodyQuery,
        };
        axios.get(dumbTerminal_Url + "unknownDevice", bodyJson, (res) => {
            if(res.code === "00") {
                this.setState({
                    tableData: res.data,
                    total: res.count,
                    current_page: cur_page || res.page_no || 1,
                    pageSize: page_size || res.page_size || 20,
                    loading: false,
                    query: bodyQuery,
                })
            }
            // console.log(res)
        })
    }
    deleteNoPassDevices(deleteItem) {
        let _this = this;
        let { pageSize } = this.state;
        let bodyJson = {
            id: deleteItem._id,
            ip: deleteItem.ip,
            mac_address: deleteItem.mac_address,

        }
        // console.log(bodyJson)
        Modal.confirm({
            title: '消息提示',
            content: '你确定删除该设备吗？',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                axios.delete_body(dumbTerminal_Url + "noPassDevice", bodyJson, (res) => {
                    // console.log(res)
                    if (res.code === "00") {
                        message.success("删除成功");
                        _this.getTableData(1, pageSize, {});
                    } else {
                        message.error(res.msg)
                    }
                })
            },
        });
    }

    render() {
        const { current_page, total, pageSize, tcpVisible, selectOne, tableData, loading } = this.state;
        const columns = [
            {
                title: 'IP',
                align: 'center',
                dataIndex: 'ip',
                width: 100,
                ellipsis: true,
            },
            {
                title: 'mac地址',
                align: 'center',
                dataIndex: 'mac_address',
                width: 120,
                ellipsis: true,
            },
            {
                title: '名称（dhcp）',
                align: 'center',
                dataIndex: 'dhcp_name',
                width: 100,
                ellipsis: true,
            },
            {
                title: 'mac地址（dhcp）',
                align: 'center',
                dataIndex: 'dhcp_mac',
                width: 120,
                ellipsis: true,
            },
            {
                title: '描述（dhcp）',
                align: 'center',
                dataIndex: 'dhcp_desc',
                width: 120,
                ellipsis: true,
            },
            {
                title: '描述（snmp）',
                align: 'center',
                dataIndex: 'snmp_desc',
                width: 220,
                ellipsis: true,
            },
            {
                title: '设备信息',
                align: 'center',
                dataIndex: 'device_info',
                width: 120,
                ellipsis: true,
                render: (a, b, c) => {
                    const { os_info, tcp_info } = b;
                    if(Object.keys(tcp_info).length !== 0 || os_info .length !== 0) {
                        return <Button type="link" size="small" onClick={() => this.openTcpModal(b)}>查看设备信息</Button>
                    }
                }
            },
            {
                title: '原因',
                align: 'center',
                dataIndex: 'reason',
                width: 120,
                ellipsis: true,
            },
            {
                title: '接入交换机名称',
                align: 'center',
                dataIndex: 'switch_name',
                width: 120,
                ellipsis: true,
            },
            {
                title: '接入交换机IP',
                align: 'center',
                dataIndex: 'switch_ip',
                width: 100,
                ellipsis: true,
            },
            {
                title: '接入端口',
                align: 'center',
                dataIndex: 'switch_port',
                width: 100,
                ellipsis: true,
            },
            {
                title: '创建时间',
                align: 'center',
                dataIndex: 'create_time',
                width: 150,
                ellipsis: true,
            },
            {
                title: '操作',
                align: 'center',
                dataIndex: 'op',
                fixed: 'right',
                width: 100,
                render: (a, b, c) => {
                    return <Button type="danger" style={{ marginLeft: 10 }} size="small" icon="delete" onClick={() => { this.deleteNoPassDevices(b) }}>删除</Button>
                }
            }
        ]

        let scrollX = 0;
        columns.map((item, index) => {
            scrollX += item.width;
        })
        scrollX = scrollX + 260;

        return (
            <div className="noPassedDiv">
                <div className="searchDiv">
                    <Collapse defaultActiveKey={"key"}>
                        <Collapse.Panel header="筛选条件" key={"key"}>
                            <FilterForm tagClass="noPassedDevices"
                                filterSubmit={(querys) => {
                                    this.getTableData(1, pageSize, querys);
                                }}
                                resetTab={() => {
                                    this.getTableData(1, pageSize, {})
                                }}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </div>
                <Card title={<span style={{ fontWeight: 600 }}>审核不通过列表</span>} style={{ marginTop: 8 }} >
                    <DyHeadTable
                        style={{ marginTop: 10 }}
                        dataSource={tableData || []}
                        columns={columns}
                        size='small'
                        loading={loading}
                        rowKey={(text) => { return text._id }}
                        scroll={{ x: scrollX, y: 630 }}
                        pagination={{
                            pageSize: pageSize, current: current_page, total: total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            onChange: (page, pageSize) => {
                                this.getTableData(page, pageSize);
                            }
                        }}
                    />
                </Card>
                <Modal
                    title="设备相关信息详情"
                    visible={tcpVisible}
                    width="60%"
                    style={{ top: 50 }}
                    onCancel={() => this.setState({ tcpVisible: false })}
                    onOk={() => this.setState({ tcpVisible: false })}
                >
                    <Divider orientation="left">端口信息</Divider>
                    <div className="portinfoDiv">
                        {/* <table className="tcpmodaltab"><tbody><tr><th>端口号</th><th>端口信息</th></tr></tbody></table>
                        {this.getTcpModal(selectOne.tcp_info, "")} */}
                        {this.getTcpTabModal(selectOne.tcp_info || {})}
                    </div>
                    <Divider orientation="left">相似操纵系统</Divider>
                    <div>{this.getOsModal(selectOne.os_info)}</div>
                </Modal>
            </div>
        )
    }
}

export default auditNotPassed;
