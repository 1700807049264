import React from 'react'
import { useState, useEffect } from 'react'

import { ScrollBoard } from '@jiaminghi/data-view-react'

import './ScrollBoard.scss'

import http from '@/axios/axios_utils';
import { alarmRecords } from '../../monitorAlert/utils/api';

export default () => {
  // const [config, setData] = useState({});

  const config = {
    header: ['时间', '规则名称', '设备IP', '告警标志'],
    // data: tableData,
    data: [
      ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
      ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
      ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
      ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
      ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
      ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
      ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
      ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
      ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
      ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"]
    ],
    index: true,
    columnWidth: [50, 230, 150, 230, 150],
    align: ['center'],
    rowNum: 7,
    headerBGC: '#1981f6',
    headerHeight: 45,
    oddRowBGC: 'rgba(0, 44, 81, 0.8)',
    evenRowBGC: 'rgba(10, 29, 50, 0.8)',
  }
  // function getData(tableData) {
  //   return {
  //     header: ['时间', '规则名称', '设备IP', '告警标志'],
  //     // data: tableData,
  //     data: [
  //       ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
  //       ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
  //       ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
  //       ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
  //       ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
  //       ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
  //       ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
  //       ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
  //       ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"],
  //       ["2021-01-13 09:55:14", "网络延迟监控", "10.32.79.34", "√"]
  //     ],
  //     index: true,
  //     columnWidth: [50, 230, 150, 230, 150],
  //     align: ['center'],
  //     rowNum: 7,
  //     headerBGC: '#1981f6',
  //     headerHeight: 45,
  //     oddRowBGC: 'rgba(0, 44, 81, 0.8)',
  //     evenRowBGC: 'rgba(10, 29, 50, 0.8)',
  //   }
  // }
  useEffect(() => {
    // createData();

    // // let timer = setInterval(createData, 30000);
    // let timer = setInterval(createData, 20800);
    // return () => clearInterval(timer)
  }, [])

  function createData() {
    // setData(getData());
    // getAlertData(1, 10, {}, (tableData) => {
    //   setData(getData(tableData))
    //   // console.log("获取告警记录:", tableData)
    // })
  }

    // function getAlertData(page = 1, size = 10, query = {}, callback) {
    // let tableArr = [];
    // http.get(alarmRecords(), { page, size, ...query }, (r) => {
    //   r.data.records.map((i) => {
    //     let alertLabel = "";
    //     if (i.status === 0) {
    //       alertLabel = "✖";
    //     } else if (i.status === 1) {
    //       alertLabel = "√";
    //     }
    //     let dataArr = [i.triggerTime, i.ruleName || "", JSON.parse(i.label).ip || "", alertLabel];
    //     tableArr.push(dataArr);
    //   })
    //   if (tableArr.length <= 10) {
    //     callback(tableArr)
    //   } else {
    //     callback(tableArr.splice(0, 10))
    //   }
    // })
    // }


  return (
    <div className="network-scroll-board">
      <ScrollBoard config={config} />
    </div>
  )
}
