import React, { useEffect, useState } from 'react'
import {
    Card, Table, Button, Icon, Input, message, notification, Upload, Tabs,
    Popconfirm, Tag, Modal, Menu, Dropdown, Timeline, Progress, Collapse, Typography, Descriptions, Row, Col, Divider
} from "antd";
import Socket from "../../../socket/index";
import Utils from "../../../utils/utils";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import MonacoEditor from 'react-monaco-editor';
import $ from "jquery";
import * as XLSX from 'xlsx';
import "./index.scss"
const { Text } = Typography;
const Dragger = Upload.Dragger;
function Report() {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reportVis, setReportVis] = useState(false);
    const [record, setRecord] = useState({});
    const [tempHtml, setTempHtml] = useState("");
    const [tempName, setTempName] = useState("");
    useEffect(() => {//componentDidMount
        console.log('componentDidMount')
        loadReport();
        return () => { //componentWillUnmount
            console.log('componentWillUnmount');
        }
    }, []);
    const loadReport = () => {
        setLoading(true);
        Socket.ExecuteAction("QueryAllInspecTemplate",
            { option: {} }, "/", (result) => {
                console.log(result);
                setTableData(result.data);
                setRecord(result.data[0] || {})
                setLoading(false);
            }, (error) => {
                Utils.showMessage("error", "查询失败", error)
            });
    }
    const getTable = (str, id) => {
        document.getElementById(id).innerHTML = str;
    }
    const updataReport = () => {
        const param = record
        param.template = document.getElementById(param.name + "tab").innerHTML;
        Socket.ExecuteAction("UpdateInspecTemplate", param, "/", (result) => {
            console.log(result);
            Utils.showMessage("success", "更新成功", "");
            loadReport();
        });
    }
    const addReport = () => {
        if (!tempHtml) {
            message.info("请导入模板文件！");
            return;
        } else if (!tempName) {
            message.info("请填写模板名称！");
            return;
        }
        Socket.ExecuteAction("AddInspecTemplate",
            { template: tempHtml, name: tempName }, "/", (result) => {
                console.log(result);
                Utils.showMessage("success", "新增成功", "");
                setReportVis(false);
                setTempHtml("")
                setTempName("");
                loadReport();
            });
    }
    const uploadFilesChange = (file) => {
        console.log(file);
        let tabHtmlbyXLSL = "";
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = {};
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {

                    let tempData = [];
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        // data[sheet] = tempData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        console.log(XLSX.utils.sheet_to_html(workbook.Sheets[sheet], { id: "xlslTab", editable: true, }));
                        tabHtmlbyXLSL = XLSX.utils.sheet_to_html(workbook.Sheets[sheet], { id: "xlslTab", editable: true, });
                    }
                }
                // 最终获取到并且格式化后的 json 数据
                message.success('导入成功！');
                let id = record.name + "tab";
                if (reportVis) {//新增
                    id = "newTable"
                    setTempHtml(tabHtmlbyXLSL);
                } else {//修改
                    const temp = record;
                    temp.template = tabHtmlbyXLSL;
                    setRecord(temp);
                }
                getTable(tabHtmlbyXLSL, id);
                // createTab(excelData);
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                console.log(e);
                message.error('文件类型不正确！');
            }
        }
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(file.file);
    }

    // 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
    function sheet2blob(sheet, sheetName) {
        sheetName = sheetName || 'sheet1';
        var workbook = {
            SheetNames: [sheetName],
            Sheets: {}
        };
        workbook.Sheets[sheetName] = sheet; // 生成excel的配置项

        var wopts = {
            bookType: 'xlsx', // 要生成的文件类型
            bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
            type: 'binary'
        };
        var wbout = XLSX.write(workbook, wopts);
        var blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream"
        }); // 字符串转ArrayBuffer
        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        return blob;
    }

    function openDownloadDialog(url, saveName) {
        if (typeof url == 'object' && url instanceof Blob) {
            url = URL.createObjectURL(url); // 创建blob地址
        }
        var aLink = document.createElement('a');
        aLink.href = url;
        aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
        var event;
        if (window.MouseEvent) event = new MouseEvent('click');
        else {
            event = document.createEvent('MouseEvents');
            event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        }
        aLink.dispatchEvent(event);
    }
    function exportReport() {
        const div = document.createElement("div");//创建一个div
        div.innerHTML = document.getElementById(record.name + "tab").innerHTML;
        const sheet = XLSX.utils.table_to_sheet(div);
        const blob = sheet2blob(sheet);
        openDownloadDialog(blob, record.name + ".xlsx");
    }
    function deleteReport(_id) {
        Modal.confirm({
            title: "消息提示",
            content: "你确定删除当前报告模板吗？",
            onOk() {
                Socket.ExecuteAction("DeleteInspecTemplate",
                    { _id }, "/", (result) => {
                        Utils.showMessage("success", "删除成功", "");
                        loadReport();
                    });
            }
        })

    }
    return <div className="reportTempDiv">
        <CustomBreadcrumb arr={["自定义任务", { title: "报告模板", to: "/reportTemplate" }]} />
        <Card title={<span style={{ fontWeight: 600 }}>报告模板列表</span>}
            extra={
                <div>
                    <Button type="primary" icon="edit"
                        onClick={() => { setReportVis(true) }}>
                        新增报告模板
                        </Button>
                </div>}>

            <Tabs tabPosition="left" onChange={(e) => {
                const record = tableData.filter((item) => { return item.name === e });
                if (record[0]) { setRecord(record[0]) }
            }} >
                {tableData.map((item, key) => {
                    return <Tabs.TabPane tab={item.name} key={item.name}>
                        <Upload name="file"
                            beforeUpload={function () { return false; }}
                            onChange={uploadFilesChange.bind(this)}
                            showUploadList={false}>
                            <Button icon="file-excel" type="primary">重新导入</Button>
                        </Upload>&nbsp;
                    <Button icon="export" type="primary" onClick={() => { exportReport() }}>导出模板</Button>&nbsp;
                    <Button icon="save" type="primary" onClick={() => { updataReport() }}>保存模板</Button>&nbsp;
                    <Button icon="delete" type="danger" onClick={() => { deleteReport(item._id) }}>删除模板</Button>&nbsp;
                    <Divider />
                        <div className="reportTemplate" id={item.name + "tab"} dangerouslySetInnerHTML={{ __html: item.template }}></div><p />
                    </Tabs.TabPane>
                })}

            </Tabs>

        </Card>
        <Modal visible={reportVis}
            title="报告模板"
            style={{ top: 10 }}
            width={"80%"}
            okText="新增模板"
            forceRender
            onCancel={() => setReportVis(false)}
            onOk={() => { addReport() }}
        >
            <div style={{ maxHeight: 800, overflowY: "auto" }}>
                <Button icon="export" onClick={() => {
                    exportWord({
                        mhtml: $("#xlslTab").prop("outerHTML") || "",
                        data: { title: "exportword" },
                        filename: "巡检报告模板",
                        // style: "table{width:100%;border:1px; cellspacing:0; cellpadding:0} table td{border:1px solid #000;width:200px}"
                        style: "table,table tr th, table tr td { border:1px solid #000;width:200px;font-weight:600} table { width: 80%;  text-align: left; border-collapse: collapse;}"
                    })
                }}>生成预览报告</Button>

                <Upload name="file"
                    beforeUpload={function () { return false; }}
                    onChange={uploadFilesChange.bind(this)}
                    showUploadList={false}>
                    <Button icon="file-excel" >导入报告模板</Button>

                </Upload>
                <Button icon="file-excel" onClick={() => {
                    const html = ['<table  width="100%" border="1" cellspacing="0" cellpadding="0">',
                        '    <tbody>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">设备名称</td>',
                        '            <td style="border:1px solid #000">{sysname}</td>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">设备型号</td>',
                        '            <td style="border:1px solid #000">{model}</td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">设备序列号</td>',
                        '            <td style="border:1px solid #000">{SN}</td>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">管理地址</td>',
                        '            <td style="border:1px solid #000">{deviceIp}</td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">项目</td>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">子项</td>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">结果</td>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">备注</td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000" rowspan="3">硬件状态</td>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">电源模块</td>',
                        '            <td style="border:1px solid #000">{power}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">机箱温度</td>',
                        '            <td style="border:1px solid #000">{systemper}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">风扇工作状态</td>',
                        '            <td style="border:1px solid #000">{fantemper}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000" rowspan="2">软件状态</td>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">软件版本</td>',
                        '            <td style="border:1px solid #000" colspan="2">{software}</td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">运行时间</td>',
                        '            <td style="border:1px solid #000" colspan="2">{uptime}</td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000" rowspan="3">资源利用率</td>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000" >CPU使用率</td>',
                        '            <td style="border:1px solid #000" >{cpuRate}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000" >内存使用率</td>',
                        '            <td style="border:1px solid #000">{memoryRate}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">接口错误包</td>',
                        '            <td style="border:1px solid #000">{interfaceError}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000" rowspan="2">时间</td>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">系统时间(clock)</td>',
                        '            <td style="border:1px solid #000">{clock}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">NTP状态</td>',
                        '            <td style="border:1px solid #000">{ntp}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000" rowspan="3">路由协议</td>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">静态路由总数</td>',
                        '            <td style="border:1px solid #000">{static}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">OSPF路由总数</td>',
                        '            <td style="border:1px solid #000">{ospf}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">BGP路由总数</td>',
                        '            <td style="border:1px solid #000">{bgp}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000" rowspan="3">Log配置</td>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">服务器地址</td>',
                        '            <td style="border:1px solid #000">{logServer}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">源地址或端口</td>',
                        '            <td style="border:1px solid #000">{logSource}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">LogBuffer大小</td>',
                        '            <td style="border:1px solid #000">{logBuffer}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000" rowspan="3">SNMP配置</td>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">状态</td>',
                        '            <td style="border:1px solid #000">{snmpStatus}</td>',
                        '            <td style="border:1px solid #000"> </td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">服务器地址</td>',
                        '            <td style="border:1px solid #000">{snmpServer}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '        <tr>',
                        '            <td style="font-weight:600;width:200px;border:1px solid #000">源地址或端口</td>',
                        '            <td style="border:1px solid #000">{snmpSource}</td>',
                        '            <td style="border:1px solid #000"></td>',
                        '        </tr>',
                        '    </tbody>',
                        '</table>'].join("");
                    const div = document.createElement("div");//创建一个div
                    div.innerHTML = html;
                    console.log(div)
                    const sheet = XLSX.utils.table_to_sheet(div);
                    const blob = sheet2blob(sheet);
                    openDownloadDialog(blob, "报告导入模板.xlsx");
                }}
                >下载导入模板</Button>
                <hr style={{ border: "1px solid #e2e2e2" }} />
                <p><Text strong className="ant-form-item-required">报告模板名称: </Text>
                    <Input style={{ width: 300 }} value={tempName} onChange={(e) => { setTempName(e.target.value) }} />
                </p>
                <p><Text strong className="ant-form-item-required">报告表格模板(预览): </Text></p>
                <div className="reportTemplate" id="newTable"></div>
            </div>
        </Modal>
    </div >
}
export default Report