import React from 'react'
import { Button, Card, Form, Icon, Input, message, notification, Select, Tabs } from 'antd';


import CustomBreadcrumb from '../../components/CustomBreadcrumb/index';

import Axios from "../../axios/axios";
import TreeSelect from "antd/es/tree-select";
import GetTreeDataUtil from "../../utils/GetTreeDataUtil";
import moment from "moment";
const { Option } = Select;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

class newSegment extends React.Component {

    state = {
        RadioValue: 1,
        visible: false,
        cardTitle: '新建网段',
        IPSegment: null,
        res: null,
        ipDisabled: false,//编辑时，IP输入框不可编辑,
        ipSegmentId: '',
        treeData: [],//
        value: undefined,
        defvalue: "",//编辑时选择的组织
    }

    //转换树形数据格式

    convertTree = e => {
        // alert(JSON.stringify(e))
        e.map(item => {
            let child = item.children
            if (child !== undefined && item.children.length > 0) {
                this.convertTree(child)
            }
            item.key = item.id
            item.value = item.id
            item.title = item.name
            delete item["id"]
            delete item["name"]
            delete item["pid"]
        })
        return e
    }

    onChange = value => {
        console.log(value);
        this.setState({ value });
    };

    componentWillMount() {

    }

    componentDidMount() {
        let thisTemp = this;
        let Id = this.props.match.params.Id;
        GetTreeDataUtil.getData(treeData => {
            this.setState({ treeData })
        })

        if (Id) {
            this.setState({
                cardTitle: '编辑网段',
                ipSegmentId: Id.replace(":", ""),
                ipDisabled: true
            });

            Axios.queryData('ip/ipSegment/', { id: Id.replace(":", "") }, (res) => {
                this.props.form.setFieldsValue({
                    id: res.id,
                    name: res.name,
                    gateway: res.gateway,
                    dns: res.dns,
                    vlannum: res.vlannum,
                    vlanname: res.vlanname,
                    num: res.num,
                    description: res.description,
                    created_time: moment(res.created_time).format("YYYY-MM-DD HH:mm:ss"),
                    created_by_person: res.created_by_person,
                    last_update_time: moment(res.last_update_time).format("YYYY-MM-DD HH:mm:ss"),
                    last_updated_by_name: res.last_updated_by_name,
                    organization: res.organization,
                    // tag: res.tag,
                })
                this.setState({ IPSegment: res })
                this.setState({ res: res })
            });
            this.setState({ ipDisabled: true, })
        } else {
            this.setState({ ipDisabled: false },)
        }
    }

    /* handleSubmit */
    handleSubmit = (e) => {

        let { IPSegment } = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, value) => {
            let tag = [];
            let body = {};
            body.id = this.state.ipSegmentId
            body.name = value.name
            body.gateway = value.gateway
            body.dns = value.dns
            body.vlannum = value.vlannum
            body.vlanname = value.vlanname
            body.num = value.num
            let str = value.name.split('/')[1]
            let num = Math.pow(2, 32 - parseInt(str))
            if (!value.num) { body.num = num }
            body.description = value.description
            body.created_by_person = value.created_by_person
            body.last_updated_by_name = value.last_updated_by_name
            body.organization = value.organization
            body.tag = []

            if (!err) {
                if (!IPSegment) {//新增
                    Axios.POST('ip/ipSegment/', body, (res) => {
                        if (JSON.parse(res.data).statusCode !== '404' && res.status === 200) {
                            message.success('新建网段成功');
                            this.props.history.goBack();
                        } else {
                            notification.open({
                                message: '新增失败',
                                description: JSON.parse(res.data).data,
                                icon: <Icon type="frown" style={{ color: 'red' }} />,
                            });
                        }
                    })
                } else {//编辑
                    const { data: res, status } = await React.$http_ip.put('ip/ipSegment/' + body.id + '/', body)
                    if (status !== 200 || !res.id) return message.error('修改失败')
                    this.props.history.goBack()
                    return message.success('修改成功')
                }
            }
        });
    }

    /* handleInputClick */
    handleInputClick = e => {
        this.setState({ visible2: true })
    }

    handleCancel = e => {
        this.setState({ visible2: false })
    }

    render = () => {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 18 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 18 },
                sm: { span: 12 },
            },
        };

        const { cardTitle } = this.state;

        return (
            <div>
                <CustomBreadcrumb arr={[{ title: '首页', to: '/home' }, 'IP管理', { title: 'IP网段', to: '/IpAdmin/segment' }, cardTitle]} />
                <Card title={<span style={{ fontWeight: 600 }}>{cardTitle}</span>}>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item label={'网段'} {...formItemLayout} >
                            {
                                getFieldDecorator('name', {
                                    rules: [
                                        { type: 'string', message: '请输入正确的网段' },
                                        { required: true, message: '请输入正确的网段' },
                                        { max: 18, message: '最大长度为18个字符' },
                                        {
                                            pattern: new RegExp(/(^((25[0-5]|2[0-4]\d|[0-1]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[0-1]?\d\d?)\/[1-3]{0,1}[0-9]{1}$)/, 'g'),
                                            message: '网段格式不正确！'
                                        }
                                    ]
                                })(<Input allowClear={!this.state.ipDisabled} placeholder='输入网段，形如：10.1.1.0/24' disabled={this.state.ipDisabled} />)
                            }
                        </Form.Item>
                        <Form.Item label={'网关'} {...formItemLayout}>
                            {
                                getFieldDecorator('gateway', {
                                    rules: [
                                        { type: 'string', message: '请输入正确的字符！' },
                                        { required: false, message: "网关不能为空" },
                                        { max: 18, message: '长度不得超过18' }
                                    ]
                                })(<Input allowClear placeholder='网关' />)
                            }
                        </Form.Item>
                        <Form.Item label={'DNS'} {...formItemLayout}>
                            {
                                getFieldDecorator('dns', {
                                    rules: [
                                        { type: 'string', message: '请输入正确的字符！' },
                                        { max: 200, message: '不能超过200个字符' }
                                    ]
                                })(<Input allowClear placeholder='DNS' />)
                            }
                        </Form.Item>
                        <Form.Item label={'Vlan号'} {...formItemLayout}>
                            {
                                getFieldDecorator('vlannum', {
                                    rules: [
                                        { type: 'string', message: '请输入正确的字符！' },
                                        { max: 30, message: '长度不得超过30个字符' }
                                    ]
                                })(<Input allowClear placeholder='Vlan号' />)
                            }
                        </Form.Item>
                        <Form.Item label={'Vlan名称'} {...formItemLayout}>
                            {
                                getFieldDecorator('vlanname', {
                                    rules: [
                                        { type: 'string', message: '请输入正确的字符！' },
                                        { max: 200, message: '长度不得超过200个字符' }
                                    ]
                                })(<Input allowClear placeholder='Vlan名称' />)
                            }
                        </Form.Item>
                        <Form.Item label={'网段下IP数量'} {...formItemLayout}
                            style={!this.state.IPSegment ? { display: 'none' } : { display: 'block' }}>
                            {
                                getFieldDecorator('num', {
                                    rules: []
                                })(<Input disabled={true} />)
                            }
                        </Form.Item>
                        <Form.Item label={'描述'} {...formItemLayout}>
                            {
                                getFieldDecorator('description', {
                                    rules: [
                                        { type: 'string', message: '请输入正确的字符！' },
                                        { max: 250, message: '长度不得超过250个字符' }
                                    ]
                                })(<TextArea allowClear placeholder='描述' />)
                            }
                        </Form.Item>
                        <Form.Item label={'创建时间'} {...formItemLayout} style={!this.state.IPSegment ? { display: 'none' } : { display: 'block' }}>
                            {
                                getFieldDecorator('created_time', {
                                    rules: []
                                })(<Input disabled />)
                            }
                        </Form.Item>
                        {/* 由权限读入此处 */}
                        {/*<Form.Item label={'创建人'} {...formItemLayout} style={!this.state.IPSegment ? {display: 'none'}:{display: 'block'}}>
                            {
                                getFieldDecorator('created_by_person', {
                                    rules: []
                                })(<Input disabled={true} />)
                            }
                        </Form.Item>*/}
                        <Form.Item label={'修改时间'} {...formItemLayout} style={!this.state.IPSegment ? { display: 'none' } : { display: 'block' }}>
                            {
                                getFieldDecorator('last_update_time', {
                                    rules: []
                                })(<Input disabled />)
                            }
                        </Form.Item>

                        {/*<Form.Item label={'修改人'}
                                   {...formItemLayout}
                            style={!this.state.IPSegment ? {display: 'none'}:{display: 'block'}}
                        >
                            {
                                getFieldDecorator('last_updated_by_name', {
                                    rules: [
                                        {type: 'string', message: '请输入正确的字符！'},
                                        {}

                                    ]
                                })(<Input disabled={true} />)
                            }
                        </Form.Item>*/}

                        <Form.Item label={'组织'}
                            {...formItemLayout}
                        >
                            {
                                getFieldDecorator('organization', {
                                    rules: [{ required: true, message: '请选择组织机构' }]
                                })(<TreeSelect
                                    defaultValue={this.state.defvalue}
                                    // style={{width: 300}}
                                    value={this.state.value}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={this.state.treeData}
                                    placeholder="选择组织"
                                    treeDefaultExpandAll
                                    onChange={this.onChange}
                                />)
                            }
                        </Form.Item>
                        {/*<Form.Item label={'标签'}
                                   {...formItemLayout}
                        >
                            {
                                getFieldDecorator('tag', {
                                    rules: []
                                })(<Input/>)
                            }
                        </Form.Item>*/}
                        <Form.Item label={' '} colon={false} {...formItemLayout}
                        >
                            <div>
                                <Button type="primary" icon="check-circle" htmlType="submit">保存</Button>
                                <Button type="danger" icon="close-circle" style={{ marginLeft: 10 }}
                                    onClick={() => { this.props.history.goBack(); }}>取消</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        )
    };
}

const newAutoObjectFrom = Form.create()(newSegment);
export default newAutoObjectFrom;
