

import React from 'react'
import {
    Card, Table, Button, Icon, Input, message, notification, Select, Descriptions, Row, Col, Badge, Steps,
    Popconfirm, Tag, Modal, Upload, Spin, Tabs
} from "antd";
import AxiosObj from '@/axios/index';
import Utils from "@/utils/utils";
import OIDMangen from "./oidMangen";
import * as XLSX from 'xlsx';
const { Dragger } = Upload;
const { Step } = Steps;
const { TabPane } = Tabs;
const { confirm } = Modal;
export default class FileModal extends React.Component {
    state = {
        current: 0,
        uptime: 0,
        excelData: [],
    };
    uploadFilesChange = (file) => {
        if (Utils.isEmpty(file.fileList)) {
            return;
        }
        console.log(file)
        let _this = this;
        try {
            // 通过FileReader对象读取文件
            const fileReader = new FileReader();
            // 以二进制方式打开文件
            fileReader.readAsBinaryString(file.file);
            fileReader.onload = event => {
                try {
                    const { result } = event.target;
                    // 以二进制流方式读取得到整份excel表格对象
                    const workbook = XLSX.read(result, { type: 'binary' });
                    // 存储获取到的数据
                    let data = {};
                    // 遍历每张工作表进行读取（这里默认只读取第一张表）
                    for (const sheet in workbook.Sheets) {
                        let tempData = [];
                        // esline-disable-next-line
                        if (workbook.Sheets.hasOwnProperty(sheet)) {
                            // 利用 sheet_to_json 方法将 excel 转成 json 数据
                            data[sheet] = tempData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        }
                    }
                    const excelData = data;
                    _this.setState({ excelData });
                    //上传
                    const _data = Object.values(excelData)[0];
                    _this.UpLoadOID(_data);
                } catch (e) {
                    // 这里可以抛出文件类型错误不正确的相关提示
                    console.log(e);
                    message.error('上传错误!');
                }
            }
        } catch (e) {

        }
    }
    UpLoadOID(e) {
        try {
            AxiosObj.HTTP_POST("topology", "addSysOidInfo", e, (result) => {
                message.success(result.msg);
                this.setState({ uptime: new Date().getTime() })
            });
        } catch (err) {
            console.log(err)
            message.error("上传错误!");
        }
    }
    onSumit() {
        const { excelData } = this.state
        const data = Object.values(excelData)[0];
        console.log(excelData);
        console.log(data);
        this.onUpLoad(data);
    }
    exportExcel() {
        let list = this.props.tempData;
        Utils.exportSheetExcel(list, "设备型号OID列表", this.props.tempName);
    }
    render() {
        const { title, visible, onCancel } = this.props;
        return (
            <Modal
                title={<div>{title}</div>}
                visible={visible}
                width={"80%"}
                onCancel={() => { onCancel() }}
                onOk={() => onCancel()}
                style={{ top: 10 }}
                afterClose={() => { onCancel(); }}
                className="deviceOIDinfoDiv"
                destroyOnClose
            >
                {/* <Tabs defaultActiveKey="oidlist">
                    <TabPane key="oidlist" tab="OID信息列表"><OIDMangen /></TabPane>
                    <TabPane key="upoid" tab="上传OID表">
                        <div className="upfile">
                            <Dragger name="file"
                                beforeUpload={function () {
                                    return false;
                                }} onChange={this.uploadFilesChange.bind(this)}>
                                <p className="ant-upload-drag-icon">
                                    <Icon type="inbox" />
                                </p>
                                <p className="ant-upload-text">单击或将文件拖到该区域上传</p>
                            </Dragger><p />
                        </div>
                    </TabPane>
                </Tabs> */}
                <div className="upfile">
                    <div className="head">
                        <Upload name="file"
                            showUploadList={false}
                            beforeUpload={function () { return false; }}
                            onChange={this.uploadFilesChange.bind(this)}>
                            <Button type="primary"><Icon type="upload" />上传OID表</Button>
                        </Upload>
                        &nbsp;
                        <Button icon="download" onClick={() => this.exportExcel()}>下载模板</Button>
                    </div>
                    <OIDMangen uptime={this.state.uptime} />
                </div>
            </Modal>

        )
    }
}
