import React, { Fragment } from 'react'
import './index.scss'
import OldElemt from './oldElemt'
import Utils from '@/utils/utils'
import {
    Card, Icon, Row, Col, Tag, Button, Radio, DatePicker,
    Input, InputNumber, Checkbox, Select, Tooltip,
} from "antd";
export default class GroupElemt extends React.Component {
    state = {
        rulesArr: [{ key: new Date().getTime(), init: true }],
    };
    componentWillMount() {
        const { defaultValue, } = this.props;
        if (defaultValue) {
            this.setState({ rulesArr: defaultValue })
        }
    }
    onDataChange(newData) {
        this.props.onDataChange(newData);
    }
    submitFun = () => {
        const { group, addFlag, taskFlag } = this.props;
        const { rulesArr, } = this.state;
        let data = {};
        data[group.name + ""] = rulesArr;
        console.log(data);
    }
    groupCompent = () => {
        const { rulesArr, } = this.state;
        const { group, addFlag, taskFlag } = this.props;
        if (!rulesArr) return;
        return <table className="compareTab" id="compareTab02"><tbody>
            {rulesArr.map((item, key) => {
                return <tr key={key} >
                    <td style={{ textAlign: 'left' }}>
                        {group.map((groupItem, groupKey) => {
                            return <span key={groupKey}>
                                {groupItem.type === 'input' && <Tooltip title={item[groupItem.name + '']}><Input id={groupItem.name}
                                    value={item[groupItem.name + '']}
                                    onChange={(e) => {
                                        let _rulesArr = rulesArr;
                                        _rulesArr[key][groupItem.name + ''] = e.target.value
                                        this.onDataChange(_rulesArr);
                                        this.setState({ rulesArr: _rulesArr });
                                    }}
                                    style={{ width: groupItem["width"] || 200 }}
                                    className="width100"
                                    placeholder={groupItem.display || ''}
                                /></Tooltip>}
                                {groupItem.type === 'label' && <label>{groupItem.display}</label>}
                                {groupItem.type === 'select' &&
                                    <Select
                                        id={groupItem.name}
                                        value={item[groupItem.name + '']}
                                        className="width100"
                                        getPopupContainer={() => document.getElementById('compareTab02')}
                                        onChange={(value) => {
                                            let _rulesArr = rulesArr;
                                            _rulesArr[key][groupItem.name + ''] = value
                                            this.onDataChange(_rulesArr)
                                            this.setState({ rulesArr: _rulesArr })
                                        }}>
                                        {groupItem.option.map((item2, groupKey2) => {
                                            return <Select.Option key={item2} value={item2}>
                                                {item2}
                                            </Select.Option>
                                        })}
                                    </Select>}
                            </span>
                        })}
                        {addFlag ? (<span>
                            <Button style={{ marginLeft: 3 }} icon="plus-circle" type="primary" onClick={() => {
                                let arr = rulesArr;
                                // arr.push({ key: new Date().getTime(), });
                                //在指定位置添加元素,第一个参数指定位置,第二个参数指定要删除的元素,如果为0,则追加
                                arr.splice(key + 1, 0, { key: new Date().getTime(), });
                                this.setState({ rulesArr: arr });
                            }}>
                            </Button><Button style={{ marginLeft: 1 }} icon="close-circle" type="danger" onClick={() => {
                                let arr = rulesArr;
                                arr = arr.filter(arritem => arritem.key !== item.key);
                                this.setState({ rulesArr: arr });
                                this.onDataChange(arr)
                            }}>
                            </Button></span>) : ''}
                        {(taskFlag && key === 0) ? (<span>
                            <Button style={{ marginLeft: 3 }} icon="plus-circle" type="primary" onClick={() => {
                                this.oldElemtThis.setState({ visible_device: true })
                                this.oldElemtThis.updataResouce(10000, 1, {});
                                this.oldElemtThis.updataTask(20, 1, {});
                                this.oldElemtThis.getTagGroupName();
                            }}>添加任务</Button></span>) : ''}
                    </td>
                </tr>
            })}
        </tbody></table>
    }
    render() {
        let { rulesArr } = this.state;
        return <Fragment>{this.groupCompent()}<OldElemt refThis={(_this) => { this.oldElemtThis = _this }} group={this.props.group} rulesArr={rulesArr}
            onDataChange={(data) => {
                console.log(data);
                let allData = Utils.MergeArray(data, rulesArr);
                allData = allData.filter((item) => { return item["init"] != true })
                this.setState({ rulesArr: allData });
                this.onDataChange(allData)
            }} /></Fragment>;
    }
}