import React from 'react'
import { Input, Select, Form, Button, Checkbox, Radio, DatePicker } from 'antd'
import Utils from '../../utils/utils';
const FormItem = Form.Item;
const Option = Select.Option;
class BaseForm extends React.Component {
    state = {
        formItemLayout: {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 18
            },
        }
    }
    componentDidMount() {
        let formItemLayout = this.props.formItemLayout;
        if (formItemLayout) {
            this.setState({ formItemLayout })
        }
        if (this.props.refThis) {
            this.props.refThis(this);
        }
    }
    handleFilterSubmit = (e) => {
        e.preventDefault();
        let tempThis = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let fieldsValue = values;
                let _fieldsValue = {};
                for (let i in fieldsValue) {
                    if (!Utils.isEmpty(fieldsValue[i]) && fieldsValue[i]) {
                        if (i.split("-").length == 2) {
                            let str = i.replace(/-/g, ".");
                            _fieldsValue[str] = fieldsValue[i];
                        } else {
                            _fieldsValue[i] = fieldsValue[i];
                        }
                    }
                }
                tempThis.props.filterSubmit(_fieldsValue);
            }
        });


        // let fieldsValue = this.props.form.getFieldsValue();
        // let _fieldsValue = {};
        // for (let i in fieldsValue) {
        //     if (!Utils.isEmpty(fieldsValue[i]) && fieldsValue[i]) {

        //         if (i.split("-").length == 2) {
        //             let str = i.replace(/-/g, ".");
        //             _fieldsValue[str] = fieldsValue[i];
        //         } else {
        //             _fieldsValue[i] = fieldsValue[i];
        //         }
        //     }
        // }
        // // _fieldsValue["resourceParams.deviceFirm"] = "cisco"
        // this.props.filterSubmit(_fieldsValue);
    };

    reset = () => {
        this.props.form.resetFields();
        this.props.resetTab();
    };

    initFormList = () => {
        const { getFieldDecorator } = this.props.form;
        const formList = this.props.formList;
        const formItemList = [];
        const { formItemLayout } = this.state;

        if (formList && formList.length > 0) {
            formList.forEach((item, i) => {
                let label = item.label;
                let field = item.field;
                let initialValue = item.initialValue || '';
                let placeholder = item.placeholder;
                let width = item.width;
                // console.log(item);
                if (item.type == 'timeSelect') {
                    const begin_time = <FormItem label="时间" key={field}>
                        {
                            getFieldDecorator('begin_time')(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(begin_time)
                    const end_time = <FormItem label="~" colon={false} key={field}>
                        {
                            getFieldDecorator('end_time')(
                                <DatePicker showTime={true} placeholder={placeholder} format="YYYY-MM-DD HH:mm:ss" />
                            )
                        }
                    </FormItem>;
                    formItemList.push(end_time)
                } else if (item.type == 'INPUT') {
                    const INPUT = <FormItem label={label} key={field} {...formItemLayout}>
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue,
                                rules: item.rules || []
                            })(
                                <Input type={item.inputType == 'password' ? "password" : "text"} placeholder={placeholder} />
                            )
                        }
                    </FormItem>;
                    formItemList.push(INPUT)
                } else if (item.type == 'TEXTAREA') {
                    const INPUT = <FormItem label={label} key={field} {...formItemLayout}>
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue
                            })(
                                <Input.TextArea style={{ height: 200 }} type="text" placeholder={placeholder} />
                            )
                        }
                    </FormItem>;
                    formItemList.push(INPUT)
                } else if (item.type == 'SELECT') {
                    const SELECT = <FormItem label={label} key={field} {...formItemLayout}>
                        {
                            getFieldDecorator(field, {
                                initialValue: initialValue,
                                rules: item.rules || []
                            })(
                                <Select

                                    // {item.isTag?mode="tags":""}
                                    mode={item.isTag ? "tags" : ""}
                                    placeholder={placeholder}
                                >
                                    {Utils.getOptionList(item.list)}
                                </Select>
                            )
                        }
                    </FormItem>;
                    formItemList.push(SELECT)
                } else if (item.type == 'CHECKBOX') {
                    const CHECKBOX = <FormItem label={label} key={field}>
                        {
                            getFieldDecorator(field, {
                                valuePropName: 'checked',
                                initialValue: initialValue //true | false
                            })(
                                <Checkbox>
                                    {label}
                                </Checkbox>
                            )
                        }
                    </FormItem>;
                    formItemList.push(CHECKBOX)
                }
            })
        }
        return formItemList;
    }
    render() {
        const { formItemLayout } = this.state;
        const { cancelText, btnStyle, refThis } = this.props;
        return (
            <Form onSubmit={this.handleFilterSubmit}>
                {this.initFormList()}
                {/* <div style={{ float: "right", paddingTop: 5 }}> */}
                {refThis ? null : <FormItem label=" " colon={false} {...formItemLayout} >
                    <div><Button type="primary" style={btnStyle ? btnStyle : { margin: '0 20px' }}
                        icon="check-circle"
                        // onClick={this.handleFilterSubmit}
                        htmlType="submit"
                    >确定</Button>
                        <Button
                            style={btnStyle ? btnStyle : { margin: '0 20px' }}
                            onClick={this.reset} icon="reload" type="danger">{cancelText ? cancelText : "清空"}</Button>
                    </div>
                </FormItem>}

                {/* </div> */}
            </Form>
        );
    }
}
export default Form.create({})(BaseForm);