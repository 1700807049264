import React from 'react'
import './index.scss'
import Axios from "../../axios/index"
import Utils from "../../utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';

import { Table, Collapse, Card } from "antd";

const audit_url = sys_config.audit_address || "localhost:39010";

class SecurityAudit extends React.Component {
    state = {
        tableData: [], // 表格数据
        current_page: 1,  // 当前页
        pageSize: 20,
        total: 0,
        loading: false,
        query: {},
    }

    componentWillMount() {
        this.getTableData();
    }

    getTableData(cur_page, page_size, querys) {
        this.setState({ loading: true, });
        const { current_page, pageSize, query } = this.state;
        let url = audit_url + "/v1/audit/log/";
        let querys2 = querys || query
        let param = {
            cur_page: cur_page || current_page,
            page_size: page_size || pageSize,
            ...querys2
        };
        Axios.auditQuery(url, param, (res) => {
            let dataArr = res.data;
            dataArr.map((item, index) => {
                item.key = index + 1
            })
            this.setState({
                tableData: dataArr,
                total: res.total,
                current_page: cur_page || 1,
                loading: false,
                query: querys2
            })
        }, (error) => {
            // console.log(error)
            message.error(error)
        })
    }

    onSubmitButton(querys) {
        this.setState({ loading: true, });
        const { pageSize } = this.state;
        let bodyJson = {};
        let begin = querys.timeRange ? new Date(querys.timeRange[0]._d).getTime() : '';
        let end = querys.timeRange ? new Date(querys.timeRange[1]._d).getTime() : '';
        bodyJson.username = querys.username ? querys.username : '';
        bodyJson.method = querys.method ? querys.method : '';
        if (begin && end) {
            bodyJson.begin = begin;
            bodyJson.end = end;
        }
        this.getTableData(1, pageSize, bodyJson);
    }

    render() {
        const { current_page, total, pageSize } = this.state;
        const columns = [
            {
                title: '序号',
                dataIndex: 'key',
                width: 50,
                ellipsis: true,
                render: (text, item, index) => {
                    return <span>{index + 1 + (current_page - 1) * pageSize}</span>
                }
            },
            {
                title: '用户名',
                align: 'left',
                dataIndex: 'username',
                width: 70,
                ellipsis: true,
            },
            {
                title: '请求路径',
                align: 'left',
                dataIndex: 'request_uri',
                width: 300,
                ellipsis: true,
            },
            {
                title: '请求方法',
                align: 'left',
                dataIndex: 'request_method',
                width: 80,
                ellipsis: true,
            },
            {
                title: '状态码',
                align: 'left',
                dataIndex: 'response_status',
                width: 70,
                ellipsis: true,
            },
            {
                title: '描述',
                align: 'left',
                dataIndex: 'request_description',
                width: 200,
                ellipsis: true,
            },
            {
                title: '时间',
                align: 'left',
                dataIndex: 'record_time',
                render: text => <span>{Utils.utc2beijing(text)}</span>,
                width: 150,
                ellipsis: true,
            },
        ]

        return (
            <div className="securityBody">
                <div className="CustomBreadcrumb"><CustomBreadcrumb arr={["安全审计"]} /> </div>
                <div className="searchDiv">
                    <Collapse defaultActiveKey={"key"}>
                        <Collapse.Panel header="筛选条件" key={"key"}>
                            <FilterForm
                                tagClass="audit"
                                filterSubmit={(querys) => {
                                    this.onSubmitButton(querys)
                                }}
                                resetTab={() => {
                                    this.getTableData(1, this.state.pageSize, {})
                                }}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </div>
                <Card
                    title={<span style={{ fontWeight: 600 }}>审计列表</span>}
                    style={{ marginTop: 8 }}
                >
                    <Table
                        dataSource={this.state.tableData || []}
                        columns={columns}
                        size='small'
                        loading={this.state.loading}
                        pagination={{
                            pageSize: 20,
                            current: current_page,
                            total: total,
                            // showTotal: (total) => { return <span>共 {total} 项</span> },
                            onChange: (page, pageSize) => {
                                // console.log(this.state.query)
                                this.setState({ current_page: page, pageSize: pageSize });
                                this.getTableData(page, pageSize);
                            }
                        }}
                    >
                    </Table>
                </Card>
            </div>
        )
    }
}

export default SecurityAudit;
