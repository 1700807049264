import React from 'react'
import {
    Card, Table, Button, Input, message, Tag, Modal, Row, DatePicker, Upload, Icon, Collapse
} from "antd";
import socket from "../../../socket/index";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import axios from '@/axios/axios_utils';
import * as XLSX from 'xlsx';
import Utils from "@/utils/utils";
import { SubmitButton, FormItem, } from "formik-antd";
const FormikInput = require("formik-antd").Input;
const FormikDatePicker = require("formik-antd").DatePicker;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik";
import moment from 'moment';
const { Dragger } = Upload;
// const fileWallUrl =  "http://" + socket.getAddress() + ":50034";
const fileWallUrl =  "http://" + socket.getAddress50034();
export default class PlayRecord extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _pageSize: 20,    //当前每页显示条数
        total: 0,
        loading: false,
        querys: null,    //查询条件
        tableData: [],
        visible: false,
        importVisible: false,
        selectedRowKeys: [],
        fileList: [],
        excelData: {},
    };
    componentWillMount() {
        this.updata();
    }
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {
            pageNum: pageNum || _current,
            pageSize: pageSize || _pageSize,
            ...querys
        };
        axios.get(fileWallUrl + "/firewall/whitelist", bodyJson , (res) => {
            if(res.retcode === "00") {
                this.setState({
                    tableData: res.data,
                    loading: false,
                    total: res.total,
                    _current: pageNum || 1,
                })
            } else {
                message.error("查询失败")
            }
        })
    };
    addWhiteList(value) {
        let bodyJson = {};
        if(Array.isArray(value)){ // 导入文件
            bodyJson.segments = value;
        } else { // 单独新增
            let time = value.createtime ?  moment(value.createtime).format("YYYY-MM-DD HH:mm:ss") : "";
            bodyJson.segments = [
                {
                    segment: value.segment,
                    des: value.des,
                    createtime: time,
                }
            ]
        }
        axios.post(fileWallUrl + "/firewall/whitelist", bodyJson , (res) => {
            console.log(res)
            if(res.retcode === "00") {
                this.updata()
                this.setState({ visible: false })
                message.success("新增成功")
            } else {
                message.error(res.error);
            }
        })
    }
    deleteWhiteList(ids) {
        let deleteList = {};
        deleteList.ids = ids;
        if(ids.length === 0) {
            message.error("请选择需要删除的白名单");
        } else {
            axios.delete_body(fileWallUrl + "/firewall/whitelist", deleteList, (res) => {
                console.log(res);
                if(res.retcode === "00") {
                    this.updata()
                    message.success(res.msg)
                } else {
                    message.error(res.error);
                }
            })
        }
        
    }
    importFiles(file) {
        if (Utils.isEmpty(file.fileList)) {
            return;
        }
        let _this = this;
        let fileList = [file.file];
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file.file);
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = {};
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    let tempData = [];
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 修改表头
                        workbook.Sheets.Sheet1.A1.w = 'segment';
                        workbook.Sheets.Sheet1.B1.w = 'createtime';
                        workbook.Sheets.Sheet1.C1.w = 'des';
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data[sheet] = tempData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        if(data[sheet].length != 0) { // 把 excel 时间代码转换成时间格式
                            data[sheet].map((item, index) => {
                                item.createtime = this.formatTime(item.createtime);
                            })
                        }
                    }
                }
                const excelData = data;
                // console.log(excelData);
                _this.setState({ excelData, fileList });
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                // console.log(e)
                message.error('上传失败！');
            }
        }
    }
    formatTime(num, format = "-") {
        const time = new Date((num - 1) * 24 * 3600000 + 1);
        time.setYear(time.getFullYear() - 70);
        time.setHours(time.getHours() - 8);
        const year = time.getFullYear() + '';
        const month = time.getMonth() + 1 + '';
        const date = time.getDate() - 1 + '';
        const hours = time.getHours().toLocaleString();
        const minutes = time.getMinutes();
        const second = time.getSeconds();
        if (format && format.length === 1) {
            return year + format + this.addZero(month) + format + this.addZero(date) + ' ' + this.addZero(hours) + ':' + this.addZero(minutes) + ":" + this.addZero(second);
        }
    }
    addZero(time) {
        return time < 10 ? '0' + time : time;
    }
    onSubmit() {
        const { excelData } = this.state;
        const data = Object.values(excelData)[0];
        // console.log(excelData, data)
        if (data) {
            this.setState({
                importVisible: false,
                fileList: [],
                excelData: {},
            })
            this.addWhiteList(data);
        } else {
            message.error("请选择文件")
        }
    }

    render() {
        let { _current, _pageSize, querys, tableData, loading, total, visible, selectedRowKeys } = this.state;
        const columns = [
            {
                title: '序号',
                align: 'center',
                width: 70,
                dataIndex: 'key',
                render: (text, item, index) => {
                    return <span>{index + 1 + (_current - 1) * _pageSize}</span>
                }
            }, 
            {
                title: '网段',
                align: 'center',
                width: 180,
                dataIndex: 'segment',
                ellipsis: true,
            }, 
            {
                title: '创建时间',
                align: 'center',
                width: 180,
                dataIndex: 'createtime',
                ellipsis: true,
            },
            {
                title: '描述',
                align: 'center',
                dataIndex: 'des',
                ellipsis: true,
            },
            {
                title: '操作',
                align: 'center',
                width: 200,
                render: (text, item) => {
                    return (
                        <div>
                            <Button size="small" type="danger" icon="delete" onClick={() => {
                                let _this = this;
                                Modal.confirm({
                                    title: '消息提示',
                                    content: '你确定删除该设备吗？',
                                    okText: '确定',
                                    okType: 'danger',
                                    cancelText: '取消',
                                    onOk() {
                                        _this.deleteWhiteList([item._id]);
                                    },
                                });
                            }}>删除</Button>
                        </div>
                    )
                }
            }
        ];
        const FormList = [
            {
                label: "网段",
                type: 'INPUT',
                field: "segment",
                initialValue: '',
            },
        ]

        // 多选
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        return (
            <div>
                <CustomBreadcrumb arr={["防火墙管理", "白名单管理"]} />
                <div className="searchDiv">
                    <Collapse defaultActiveKey={"key"}>
                        <Collapse.Panel header="筛选条件" key={"key"}>
                            <FilterForm formList={FormList}
                                filterSubmit={(querys) => {
                                    this.setState({ querys })
                                    this.updata(_pageSize, 1, querys)
                                        }}
                                resetTab={() => {
                                    this.setState({ querys: {} })
                                    this.updata(_pageSize, 1, {})
                                }}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </div>
                <Card title={<span style={{ fontWeight: 600 }}>白名单列表</span>}
                    style={{ marginTop: 8 }}
                    extra={
                        <Row>
                            <Button type='primary' icon='edit' onClick={() => { this.setState({ visible: true }) }}>新增白名单</Button>
                            <Button type="primary" icon="import" onClick={() => { this.setState({ importVisible: true }) }}>导入文件</Button>
                            <Button type='danger' icon='delete' onClick={() => {
                                let _this = this;
                                Modal.confirm({
                                    title: '消息提示',
                                    content: '你确定删除该设备吗？',
                                    okText: '确定',
                                    okType: 'danger',
                                    cancelText: '取消',
                                    onOk() {
                                        _this.deleteWhiteList(selectedRowKeys);
                                    },
                                });
                            }}>删除白名单</Button>
                        </Row>
                    }
                >
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        size="small"
                        rowKey={(text) => { return text._id }}
                        loading={loading}
                        rowSelection={rowSelection}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: total,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                this.updata(pageSize, page, querys);
                            }
                        }}
                    />
                </Card>
                <Modal
                    title="新增白名单"
                    visible={visible}
                    onCancel={() => this.setState({ visible: false })}
                    width="60%"
                    style={{ top: "20px" }}
                    footer={null}
                    destroyOnClose
                >
                    <Formik
                        onSubmit={(value) => { this.addWhiteList(value) }}
                        validate={(values) => {
                            // let info = {};
                            // if (!values.segment) {
                            //     info.segment = "请填写完整网段信息";
                            // } else if(!values.createtime) {
                            //     info.createtime = "请选择时间";
                            // } else if(!values.des) {
                            //     info.des = "请填写完整描述信息";
                            // }
                            // return info
                        }}
                        initialValues={{
                            segment: "",
                            createtime: "",
                            des: "",
                        }}
                    >
                        <FormikForm labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                            <FormItem
                                name={"segment"}
                                label={"网段"}
                                key={"segment"}
                            >
                                <FormikInput name={"segment"} placeholder={"请输入网段"} />
                            </FormItem>
                            <FormItem
                                name={"createtime"}
                                label={"创建时间"}
                                key={"createtime"}
                            >
                                <FormikDatePicker showTime name={"createtime"} placeholder={"请选择时间"} style={{ width: "100%" }} />
                            </FormItem>
                            <FormItem
                                name={"des"}
                                label={"描述"}
                                key={"des"}
                            >
                                <FormikInput name={"des"} placeholder={"请输入描述信息"} />
                            </FormItem>
                            <div style={{ textAlign: "right" }}>
                                <Button onClick={() => { this.setState({ visible: false }) }}>取消</Button>
                                <SubmitButton style={{ marginLeft: "10px" }} disabled={false}>保存</SubmitButton>&nbsp;
                            </div>
                        </FormikForm>
                    </Formik>
                </Modal>
                <Modal
                    title="导入excel文件"
                    visible={this.state.importVisible}
                    onOk={() => { this.onSubmit() }}
                    onCancel={() => this.setState({ importVisible: false })}
                    destroyOnClose
                >
                    <Dragger
                        beforeUpload={function () { return false; }}
                        onChange={this.importFiles.bind(this)}
                        fileList={this.state.fileList}
                        onRemove={() => {
                            this.setState({
                                fileList: [],
                                excelData: {},
                            })
                        }
                        }
                    >
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">单击或将文件拖到该区域上传</p>
                    </Dragger>
                </Modal>
            </div>
        )
    }
}
