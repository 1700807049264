import React from 'react'
import { Row, Layout, notification, Menu, Breadcrumb, Icon, Col, BackTop, Tooltip, Spin, Button } from 'antd';

const { SubMenu } = Menu;

import HeaderBar from './components/Header'
import FooterBar from './components/Footer'
import { connect } from 'react-redux'
import NavLeft from './components/NavLeft'
import Socket from './socket/index'
import axios from './axios/axios_utils';
import { setSysConfig, getSysConfig } from './utils/func'
import './style/common.scss'
import Visible from './pages/config/system/Visible';
// import Home from './pages/home'
const { Sider, Header, Content, Footer } = Layout;
const versionName = sys_config.versionName;

class Admin extends React.Component {
    state = {
        collapsed: false,
        isloading: false,
        pageLayout: "left",
        pageStyle: "dark",
    };
    componentWillMount() {
        // this.getConfig()
        // userIp
        // axios.get("http://" + Socket.getAddress50034() + "/ZJNMS_v1/getRequestAddress", {} , (res) => {
        //     if(res.retcode === "00") {
        //         sessionStorage.setItem("userIP", res.data)
        //     }
        //     // console.log("getIp", res)
        // })
    }
    componentDidMount() {
        const pageLayout = localStorage.getItem("pageLayout") || "left";
        const pageStyle = versionName === 'pinganTerminal' ? 'light' : "dark";
        // const pageStyle = versionName === 'pinganTerminal' ? 'light' : localStorage.getItem("pageStyle") || "dark";
        this.setColor(pageStyle);
        notification.info({
            message: "欢迎回来 " + sessionStorage.getItem("userName"),
            description: <div>Hi~ 欢迎回来，右上角可以修改布局哦!<br />可以按住Ctrl,滚动鼠标来缩放页面大小</div>,
            placement: "bottomRight",
            icon: <Icon type="smile" theme="twoTone" />
        });
        this.setState({ pageLayout });
    }
    getConfig() {
        Socket.getConfig("sysConfig", (r) => {
            if (JSON.stringify(r) === "{}") {
                setSysConfig(sys_config)
            } else {
                setSysConfig(r)
            }
        });
    };
    toggle = (collapsed) => {
        this.setState({
            collapsed: collapsed
        })
    };
    setColor(pageStyle) {
        const element = document.getElementsByClassName("ant-layout-sider")[0];
        const logo = document.getElementsByClassName("leftLogotitle")[0];
        const topHeader = document.getElementsByClassName("topHeader")[0];
        const headerTop = document.getElementsByClassName("header-top")[0];
        if (element && logo) {
            if (pageStyle === "dark") { //深色
                element.style.setProperty('--bgcolor', "#001529");
                logo.style.setProperty('--logocolor', "#fff");
            } else {//浅色
                element.style.setProperty('--bgcolor', "#fff");
                logo.style.setProperty('--logocolor', "#1890ff");
            }
            this.setState({ pageStyle });
            localStorage.setItem("pageStyle", pageStyle);
        } else if (topHeader) {
            if (pageStyle === "dark") { //深色
                topHeader.style.setProperty('--bgcolor', "#001529");
                logo.style.setProperty('--logocolor', "#fff");
                headerTop.style.setProperty('--headcolor', "#fff");
            } else {//浅色
                topHeader.style.setProperty('--bgcolor', "#fff");
                logo.style.setProperty('--logocolor', "#1890ff");
                headerTop.style.setProperty('--headcolor', "#000");
            }
            this.setState({ pageStyle });
            localStorage.setItem("pageStyle", pageStyle);
        }
    }
    setLayout = (pageLayout) => {
        const { pageStyle } = this.state
        const _this = this;
        this.setState({ pageLayout });
        setTimeout(() => {
            _this.setColor(pageStyle);
        }, 100)
        localStorage.setItem("pageLayout", pageLayout);
    };
    setStyle = (pageStyle) => {
        this.setColor(pageStyle);
    };
    render() {
        let { pageStyle } = this.state;
        let { isloading } = this.props;
        return (
            <div className="bodyDiv">
                {this.state.pageLayout === "left" ?
                    <Layout style={{ height: 'calc(100vh - 0px)' }}>
                        <Sider collapsible
                            breakpoint="lg"
                            trigger={null}
                            collapsed={this.state.collapsed}
                            className="leftMneuSider"
                        >
                            <NavLeft theme={pageStyle} collapsed={this.state.collapsed} onToggle={(a) => this.toggle(a)} mode="inline" />
                        </Sider>
                        <Layout className="rightLayout">
                            <Header className="leftHeader">
                                <HeaderBar mode="inline" collapsed={this.state.collapsed}
                                    setLayout={(a) => this.setLayout(a)}
                                    setStyle={(a) => this.setStyle(a)}
                                    onToggle={(a) => this.toggle(a)} />
                            </Header>
                            <Row ><Spin spinning={isloading}>
                                <Content className="leftContentDiv">
                                    {this.props.children}
                                </Content></Spin>
                                <p>
                                </p>
                            </Row>
                            <FooterBar />
                        </Layout>
                    </Layout>
                    : <Layout>
                        <Header className={"topHeader"} >
                            <Row>
                                <Col span={18}><NavLeft theme={pageStyle} collapsed={this.state.collapsed} mode="horizontal" style={{ height: 200 }} /></Col>
                                <Col span={6}><HeaderBar
                                    setLayout={(a) => this.setLayout(a)} setStyle={(a) => this.setStyle(a)}
                                    collapsed={this.state.collapsed} onToggle={(a) => this.toggle(a)} mode="horizontal" /></Col>
                            </Row>
                        </Header>
                        <Layout className="midLayout">
                            <Layout style={{ padding: '10px 24px', }} className="mid2Layout">
                                <Spin spinning={isloading}>
                                    <Content style={{ padding: 10, marginTop: 0, minHeight: "888px", }}>
                                        {this.props.children}<FooterBar />
                                    </Content>
                                </Spin>
                            </Layout>
                        </Layout>
                    </Layout>}
                <Tooltip title="回到顶部">
                    <BackTop
                        visibilityHeight={10}
                        target={() => {
                            const doc = document.getElementsByClassName("rightLayout")[0] || document.getElementsByClassName("mid2Layout")[0]
                            return doc
                        }}
                        className="BackTopClass"
                    />
                </Tooltip>
            </div>
        );
    }
}
export default connect((state) => {
    return {
        isloading: state.isloading
    }
})(Admin) 