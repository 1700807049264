import React from 'react'
import {Card, Icon, Row, Col, Progress, Button, Table, Switch, message, Radio, Divider,Spin} from "antd";
import  'echarts/lib/chart/line';
import AxiosObj from "../../../axios";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
// 监控告警后端服务器地址 monitor_address 配置地址：public/flow/config.js
const backUp_Url = monitor_address;
export default class Storage extends React.Component{
    state = {
        switchPersistence : false,
        selectedRowKeys : [],
        selectedRows : [],
        dataSource :[
            // {
            //     index: '1',
            //     name: 'cisco',
            //     duration : '0s',
            //     shardGroupDuration : 'cisco',
            //     default : "否"
            // },
            // {
            //     index: '2',
            //     name: 'cisco',
            //     duration : '0s',
            //     shardGroupDuration : 'cisco',
            //     default : "否"
            // },
        ],
        columns : [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'index',
                render: (text) => { return <span>{text}</span> }
            }, {
                title: '名称',
                align: 'center',
                dataIndex: 'name'
            }, {
                title: '存储时长',
                align: 'center',
                dataIndex: 'duration',

            }, {
                title: '压缩周期',
                align: 'center',
                dataIndex: 'shardGroupDuration'
            }, {
                title: '默认策略',
                align: 'left',
                dataIndex: 'default',
            }
        ],
        _pageSize: 10,
        _current: 1,
        loading: true,
    }

    componentWillMount(){
        this.getPersistenceStatus();
        this.getPolicyList();
    }
//选择是否开启数据持久化存储
    switchPersistence=(value)=>{
        let {dataSource} = this.state;
        console.log(value);
        let persistence = {};
        //当存在默认存储策略时才允许开启
        if(value){
            let exit = false;
            for(let i in dataSource){
                if(dataSource[i].default == "是"){
                    exit = true;
                    break;
                }
            }
            if(exit){
                persistence['switch'] = value.toString();
                //console.log(persistence['switch'])
                AxiosObj.HTTP_POST('alarm','/snmpexporter/switchPersistence',persistence,(data)=>{
                    console.log(data)
                    this.setState({
                        switchPersistence : value
                    })
                    message.success('success');

                },(error)=>{
                    message.error(error)
                })
            }else{
                message.error("请先创建或设置默认策略！")
            }

        }else{
            persistence['switch'] = value.toString();
            //console.log(persistence['switch'])
            AxiosObj.HTTP_POST('alarm','/snmpexporter/switchPersistence',persistence,(data)=>{
                console.log(data)
                this.setState({
                    switchPersistence : value
                })
                message.success('success');

            },(error)=>{
                message.error(error)
            })

        }

}
//获取存储开启状态
    getPersistenceStatus=()=>{
        AxiosObj.MA_HTTP_GET(backUp_Url+"/snmpexporter/persistenceStatus",(success)=>{
            this.setState({
                switchPersistence : success.data.switch
            })
            console.log(this.state.switchPersistence)
        },(error)=>{
            message.error(error);
        })
    }
//获取存储策略列表
    getPolicyList=()=>{
        //修改默认策略后，表的值发生改变后，对选中的行要重新刷新，否则值会没有更新
        this.setState({
            selectedRowKeys :[],
        })
        AxiosObj.MA_HTTP_GET(backUp_Url+"/snmpexporter/policyList",(data)=>{
            console.log(data)
            //根据数据返回格式现指定只取数组第一个，后看数据返回做调整
            let values = data.data.results[0]["series"][0]["values"];
            let list = [];
            for(let i in values){
                let map = {};
                map["key"] = parseInt(i)+1;
                map["index"] = parseInt(i)+1;
                map["name"] = values[i][0];
                map["duration"] = values[i][1];
                map["shardGroupDuration"] = values[i][2];
                if(values[i][4]){
                    map["default"] = "是";
                }else{
                    map["default"] = "否";
                }

                list[i] = map;
            }
            //console.log(list)
            this.setState({
                dataSource : list,
                loading:false
            })
        },(error)=>{
            message.error(error)
        })
    }
//删除默认策略
    deletePolicy=()=>{
        let {selectedRows} = this.state;
        let policy ={};
        //排除默认策略，默认策略先更改后删除
        if( selectedRows[0].default == "是"){
            message.error("请先修改默认策略，再进行删除操作！")
            return
        }
        policy["name"] =  selectedRows[0].name;
        console.log(policy);
        AxiosObj.HTTP_DELETE('alarm','/snmpexporter/deletePolicy',policy,(data)=>{
            message.success('success');
            this.getPolicyList();
        },(error)=>{
            message.error(error);
        })
    }

//设为默认策略
    changeDefaultPolicy=()=>{
        let {selectedRows} = this.state;
        let policy ={};
        policy["name"] =  selectedRows[0].name;
        AxiosObj.HTTP_POST("alarm","/snmpexporter/setDefaultPolicy",policy,(success)=>{
            message.success('success');
            this.getPolicyList()
        },(error)=>{
            message.error(error);
        })
    }
    render(){
        let {switchPersistence, dataSource, _current, _pageSize, columns, loading} =this.state;
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys:`, selectedRowKeys, 'selectedRows: ', selectedRows);
                this.setState({
                    selectedRowKeys : selectedRowKeys,
                    selectedRows : selectedRows
                });
            }
        };
        return(
            <div>
                <CustomBreadcrumb arr={["监控告警", "数据存储"]} />
                <Spin spinning={loading}>
                    <Card title={<span style={{ fontWeight: 600 }}>数据存储</span>}>
                    <span>
                        数据远程存储状态
                         <Switch style={{marginLeft:50}}checkedChildren="开" unCheckedChildren="关" checked={switchPersistence}
                                 onClick={(value)=>{
                                     console.log(value)
                                     //setTimeout(console.log(this.state.switchPersistence),1000)
                                     this.switchPersistence(value)
                                 }}
                         />
                    </span>
                        <div style={{marginTop:50}} >
                            <Card title={<span style={{ fontWeight: 600 }}>存储策略</span>}
                                  extra={true ?
                                      <span>
                                    <Button type="primary" icon="edit" style={{ marginLeft: 8 }}
                                            onClick={() => { this.changeDefaultPolicy(); }}>{/* 记的修改路由 */}
                                        设为默认策略
                                     </Button>
                                    <Button type="primary" icon="edit" style={{ marginLeft: 8 }}
                                            onClick={() => { this.props.history.push('/monitoringAlarm/storage/addPolicy'); }}>{/* 记的修改路由 */}
                                        添加策略
                                     </Button>
                                     <Button type="primary" icon="edit" style={{ marginLeft: 8 }}
                                             onClick={() => { this.deletePolicy(); }}>{/* 记的修改路由 */}
                                         删除策略
                                     </Button>
                                 </span>

                                      : ''}>
                                <Table
                                    dataSource={dataSource}
                                    columns={columns}
                                    size="small"
                                    rowSelection={{
                                        type: 'radio',
                                        ...rowSelection}}
                                    // loading={loading}
                                    //onChange={(a,b) => console.log('select',a,b)}
                                />
                            </Card>

                        </div>
                    </Card>
                </Spin>
            </div>
            )
    }

}