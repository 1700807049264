import React from 'react'
import './index.scss'
import {Card,Table,Input,Popconfirm,Button,Icon,Tabs,Row,Col,message,Tag,Modal,Progress,List,} from "antd";
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import $ from "jquery";
export default class Port extends React.Component{
    state = {
        interfaceData:[],
        _pageSize:10,
        loading:false,
        _current:1
    };
    componentWillMount() {
        let taskId=this.props.taskId;
        let deviceIp=this.props.deviceIp;
        this.QueryOidPort(taskId,deviceIp);
    }
    QueryOidPort=(taskId,deviceIp)=>{
        this.setState({loading:true,});
        let bodyJson = {};
        bodyJson.taskId = taskId;
        bodyJson.deviceIp = deviceIp;
        Socket.QueryDataByPage('QueryOidPort',
            bodyJson, (result) =>{
                if(result.retcode === '00'){
                    let interfaceData = result.data;
                    let total = result.total;
                    interfaceData.map((item, index) => {
                        item.key = index;
                    });
                    this.setState({
                        interfaceData,total,
                        loading:false,
                    })
                }
            });
    };

    render(){
        let {interfaceData,loading}=this.state;
        const columnsPort = [
            {
                title: 'SWITCH NAME',
                align: 'center',
                dataIndex: 'switchname',
                render: (text, item, index) => {
                    return (text ? text : '')
                }
            }, {
                title: 'SWITCH PORT',
                align: 'center',
                dataIndex: 'switchport',
                render: (text, item, index) => {
                    return (text ? text : '')
                }
            }, {
                title: 'PORT DESC',
                align: 'center',
                dataIndex: 'portdesc',
                render: (text, item, index) => {
                    return (text ? text : '')
                }
            }, {
                title: 'VLAN',
                align: 'center',
                dataIndex: 'vlan',
                render: (text, item, index) => {
                    return (text ? text : '')
                }
            }, {
                title: 'MAC',
                align: 'center',
                dataIndex: 'mac',
                render: (text, item, index) => {
                    return (text ? text : '')
                }
            }, {
                title: 'IP ADDRESS',
                align: 'center',
                dataIndex: 'ipaddress',
                render: (text, item, index) => {
                    return (text ? text : '')
                }
            }, {
                title: 'SPEED',
                align: 'center',
                dataIndex: 'speed',
                render: (text, item, index) => {
                    return (text ? text+'/Kbit' : '')
                }
            }, {
                title: 'DUPLEX',
                align: 'center',
                dataIndex: 'duplex',
                render: (text, item, index) => {
                    return (text ? text : '')
                }
            }, {
                title: 'ADMIN STATUS',
                align: 'center',
                dataIndex: 'adminstatus',
                render: (text, item, index) => {
                    return (text ? text : '')
                }
            }, {
                title: 'OPER STATUS',
                align: 'center',
                dataIndex: 'operstatus',
                render: (text, item, index) => {
                    return (text ? text : '')
                }
            }, {
                title: 'ACTION',
                align: 'center',
                dataIndex: 'action',
                render: (text, item, index) => {
                    return (text ? text : '')
                }
            }
        ];
        // const interfaceData = [
        //     {key:0,"ipaddress":"2","mac":"2","vlan":"2","switchport":"2","portdesc":"Vlan2","speed":"1000000000","adminstatus":"1","operstatus":"1"},
        //     {key:1,"ipaddress":"2","mac":"2","vlan":"2","switchport":"2","portdesc":"Vlan3","speed":"1000000000","adminstatus":"1","operstatus":"1"},
        //     {key:2,"ipaddress":"2","mac":"2","vlan":"2","switchport":"2","portdesc":"GigabitEthernet0/1","speed":"1000000000","adminstatus":"1","operstatus":"1"},
        //     {key:3,"ipaddress":"2","mac":"2","vlan":"2","switchport":"2","portdesc":"GigabitEthernet0/2","speed":"10000000","adminstatus":"1","operstatus":"2"}
        // ];
        return (
            <Table
                columns={columnsPort}
                dataSource={interfaceData}
                size="small"
                pagination={false}
                loading={loading}
            />
        );
    }
}
