import React, { PureComponent, Fragment } from 'react';
import { Table, Button, Input, message, Popconfirm, Divider, Select, Switch } from 'antd';
import isEqual from 'lodash/isEqual';
import styles from './style.scss';

class TableForm extends PureComponent {
  // index = 0;

  cacheOriginData = {};

  constructor(props) {
    super(props);

    this.state = {
      data: props.value,
      loading: false,
      /* eslint-disable-next-line react/no-unused-state */
      value: props.value,
    };
  }

  static getDerivedStateFromProps(nextProps, preState) {
    if (isEqual(nextProps.value, preState.value)) {
      return null;
    }
    return {
      data: nextProps.value,
      value: nextProps.value,
    };
  }

  getRowByKey(key, newData) {
    const { data } = this.state;
    return (newData || data).filter(item => item.key === key)[0];
  }

  toggleEditable = (e, key) => {
    e.preventDefault();
    const { data } = this.state;
    const newData = data.map(item => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      // 进入编辑状态时保存原始数据
      if (!target.editable) {
        this.cacheOriginData[key] = { ...target };
      }
      target.editable = !target.editable;
      this.setState({ data: newData });
    }
  };

  newMember = () => {
    const { data } = this.state;
    const newData = data.map(item => ({ ...item }));
    const dateStr = new Date().getTime();
    newData.push({
      // key: `NEW_ID_${this.index}`,
      key: `NEW_ID_${dateStr}`,
      editable: true,
      required: "true",
      type: "string",
      isNew: true,
    });
    // this.index += 1;
    this.setState({ data: newData });
  };

  remove(key) {
    const { data } = this.state;
    const target = this.getRowByKey(key) || {};
    const { onChange } = this.props;
    const newData = data.filter(item => item.key !== key);
    this.setState({ data: newData });
    onChange(newData, target, "del");
  }

  handleKeyPress(e, key) {
    if (e.key === 'Enter') {
      this.saveRow(e, key);
    }
  }

  handleFieldChange(e, fieldName, key) {
    const { data } = this.state;
    const newData = data.map(item => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      target[fieldName] = e.target.value;
      this.setState({ data: newData });
    }
    if (fieldName === "isSim") {
      this.props.onChange(newData, target, "update");
    }
  }

  saveRow(e, key, type) {
    e.persist();
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      if (this.clickedCancel) {
        this.clickedCancel = false;
        return;
      }
      const target = this.getRowByKey(key) || {};
      // if (!target.variable || !target.label
      //   || !target.type || !target.required
      // ) {
      //   message.error('请填写完整成员信息。');
      //   e.target.focus();
      //   this.setState({
      //     loading: false,
      //   });
      //   return;
      // }
      delete target.isNew;
      this.toggleEditable(e, key);
      const { data } = this.state;
      const { onChange } = this.props;
      onChange(data, target, type);
      this.setState({
        loading: false,
      });
    }, 100);
  }

  cancel(e, key) {
    this.clickedCancel = true;
    e.preventDefault();
    const { data } = this.state;
    const newData = data.map(item => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (this.cacheOriginData[key]) {
      Object.assign(target, this.cacheOriginData[key]);
      delete this.cacheOriginData[key];
    }
    target.editable = false;
    this.setState({ data: newData });
    this.clickedCancel = false;
  }

  render() {
    const { columns, scroll } = this.props;
    const tempcolumns = columns.map((item, key) => {
      return {
        ...item,
        key: item.dataIndex,
        render: (text, record) => {
          const textNew = (typeof text === "object") ? JSON.stringify(text) : text;
          if (record.editable) {
            switch (item.type) {
              case "INPUT": return (
                <Input
                  value={textNew}
                  onChange={e => this.handleFieldChange(e, item.dataIndex, record.key)}
                  onKeyPress={e => this.handleKeyPress(e, record.key)}
                  placeholder={item.placeholder || ""}
                />
              )
              case "PASSWORD": return (
                <Input.Password
                  value={textNew}
                  onChange={e => this.handleFieldChange(e, item.dataIndex, record.key)}
                  onKeyPress={e => this.handleKeyPress(e, record.key)}

                />
              )
              case "SWITCH": return (
                <Switch defaultChecked={text || false} />
              )
              default: return (
                <Select
                  value={textNew}
                  onChange={e => this.handleFieldChange({ target: { value: e } }, item.dataIndex, record.key)}
                >
                  {item.option && item.option.map((op) => {
                    return (<Select.Option value={op.value}>{op.label}</Select.Option>)
                  })}
                </Select>
              )
            }
          }
          if (item.type === "SWITCH") {
            return (
              <Switch onChange={e => this.handleFieldChange({ target: { value: e } }, item.dataIndex, record.key)} defaultChecked={text || false} />
            )
          } else {
            if (item.type === "PASSWORD") return "***";
            return textNew;
          }
        },
      }
    })
    const newcolumns = [{
      title: '序号',
      key: 'key',
      render: (text, record, key) => {
        return key + 1
      }
    }, ...tempcolumns]
    newcolumns.push({
      title: '操作',
      key: 'action',
      render: (text, record, index) => {
        const { loading } = this.state;
        if (!!record.editable && loading) {
          return null;
        }
        if (record.editable) {
          if (record.isNew) {
            return (
              <span>
                <a onClick={e => this.saveRow(e, record.key || index, "add")}>添加</a>
                <Divider type="vertical" />
                <Popconfirm title="是否要删除此行？" onConfirm={() => this.remove(record.key || index)}>
                  <a>删除</a>
                </Popconfirm>
              </span>
            );
          }
          return (
            <span>
              <a onClick={e => this.saveRow(e, record.key || index, "update")}>保存</a>
              <Divider type="vertical" />
              <a onClick={e => this.cancel(e, record.key || index)}>取消</a>
            </span>
          );
        }
        return (
          <span>
            <a onClick={e => this.toggleEditable(e, record.key || index)}>编辑</a>
            <Divider type="vertical" />
            <Popconfirm title="是否要删除此行？" onConfirm={() => this.remove(record.key || index)}>
              <a>删除</a>
            </Popconfirm>
          </span>
        );
      },
    })

    const { loading, data } = this.state;

    return (
      <Fragment >
        <div className="AddtableformDiv">
          <Table
            loading={loading}
            columns={newcolumns}
            dataSource={data}
            pagination={false}
            scroll={scroll || { y: 400 }}
            rowClassName={record => (record.editable ? styles.editable : '')}
            size="small"
          />
          <Button
            style={{ width: '100%', marginTop: 10, marginBottom: 0 }}
            type="dashed"
            onClick={this.newMember}
            icon="plus"
          >
            新增参数
        </Button>
        </div>

      </Fragment>
    );
  }
}

export default TableForm;
