import React from 'react'
import './index.scss'
import Socket from '../../../../socket/index'
import Utils from '../../../../utils/utils'
import { Card, Icon, Row, Col, Tag, Button } from "antd";
import QueueAnim from 'rc-queue-anim';
import CustomBreadcrumb from '../../../../components/CustomBreadcrumb/index';
import SelectForm from '../../../../components/SelectForm/index';
export default class AppStore extends React.Component {
    state = {
        showData: [],
        tableData: [],
        _pageSize: 10,
    };
    componentWillMount() {
        this.updata();
    }
    updata = () => {
        let bodyJson = {};
        bodyJson.pageSize = 10;
        bodyJson.pageNum = 1;
        bodyJson.querys = {};
        Socket.ActionStore("QueryAllApps",
            bodyJson, (result) => {
                if (result.retcode === '00') {
                    let tableData = result.data;
                    let showData = [];
                    let chunk = 4; //每5个分一组

                    for (let i = 0, j = tableData.length; i < j; i += chunk) {
                        showData.push(tableData.slice(i, i + chunk));
                    }
                    console.log(showData);
                    tableData.map((item, index) => {
                        item.key = index;
                        return item.id
                    });
                    this.setState({
                        tableData,
                        showData,
                        total: result.total,
                    })
                }
            }, "/queryApp");

    };
    fastLink = (link) => {
        this.props.history.push(link);
    };
    TaskCardRow = () => {
        return <QueueAnim type="right" duration={1500} interval={600}>
            {this.state.showData.map((itemArr, keyArr) => {
                return <Row style={{ height: '320px', marginTop: 15 }} gutter={10} key={keyArr}>
                    {itemArr.map((item, key) => {
                        return <Col span={key === 4 ? 6 : 6} key={key}>
                            <Card
                                className="cardStyle"
                                onClick={() => { this.props.history.push('/appDetail'); }}>
                                <img className="imgTitle" src={item.icon} />
                                {/*<img className="imgTitle" src="http://www.tietuku.com/static/image/icon2.png"/>*/}
                                <div className="titleDiv">{item.taskName}</div>
                                <div className="midDiv">
                                    {item.taskDes}
                                </div>
                                <div className="footDiv">
                                    <Button type="primary" onClick={() => { this.props.history.push('/appDetail') }}>查看详情</Button>
                                </div>
                            </Card>
                        </Col>
                    })}
                </Row>
            })}
        </QueueAnim>
    };
    TaskCard = () => {
        return <QueueAnim type="right" >
            {this.state.tableData.map((item, key) => {
                const param = JSON.stringify({ appStore: item.appStore, name: item.name, version: item.version });
                return <Card key={key}
                    className="cardStyle">
                    {/*<img className="imgTitle" src="http://10.34.10.183/bobo/demo_project/raw/master/firewall/icon.png"/>*/}
                    <img className="imgTitle" src="/image/appStore/icon2.png" />
                    {/*<img className="imgTitle" src={''+item.icon}/>*/}
                    <div className="titleDiv">{item.title}</div>
                    <div className="midDiv">
                        {item.description}
                    </div>
                    <div className="footDiv">
                        <Button type="primary" onClick={() => {
                            this.props.history.push(`/appDetail:${param}`)
                        }}>查看详情</Button>
                    </div>
                </Card>
            })}
        </QueueAnim>
    };

    render() {
        const condition = [{ key: 'taskName', value: '应用名称' }, { key: 'maintainer', value: '应用分类' },];
        const conditionData = {
            taskName: "INPUT",
            maintainer: { mode: 'alone', data: [{ key: 'zijin', value: '紫金' }] },
        };
        return (
            <div className="appStoreDiv">
                <CustomBreadcrumb arr={["配置管理", { title: "任务", to: '/task' }, '应用商店']} />
                <Card className="card3D">
                    <SelectForm conditionData={conditionData} condition={condition}
                        filterSubmit={(query) => {
                            this.updata(this.state._pageSize, 1, query)
                        }}
                        resetTab={() => { this.updata(this.state._pageSize, 1, {}) }}
                        className="pull-left"
                    />
                    <Button className="pull-right" icon="rollback"
                        type="primary"
                        onClick={() => { history.go(-1) }}>返回</Button>
                    {/*<Button className="pull-right" icon="edit"*/}
                    {/*type="primary"*/}
                    {/*onClick={()=>{this.props.history.push('/newAppStore')}}>上传</Button>*/}
                </Card>

                {this.TaskCard()}
            </div>
        );
    }
}