import React from 'react'
import { Card, Table, Button, Icon, Input, message, notification, Popconfirm, Tag, Modal, Spin, Tabs, Col, Row, Descriptions, Collapse, DatePicker } from "antd";
import socket from "../../../socket/index";
import AxiosObj from "../../../axios/index";
import http from '@/axios/axios_utils';
import Utils from "../../../utils/utils";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import FileModal from '@/components/ReadFlieModal/index';
import MonacoEditor from 'react-monaco-editor';
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import "./index.scss"
const TabPane = Tabs.TabPane;
class IPPlayRecord extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _current2: 1,    //当前页码数
        _pageSize: 20,    //当前每页显示条数
        _pageSize2: 10,    //当前每页显示条数
        loading: false,
        detailLoading: false,
        fileLoading: false,
        FileVis: false,
        detailModal: false,
        detailData: [],
        ipsData: [],
        querys: null,    //查询条件
        querys2: null,    //查询条件
        selectItem: null,
        deleteVis: false,
        deleteDate: "",
    };
    componentWillMount() {
        this.updata();

    }
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        socket.ExecuteAction("QueryFwIpAuditsPage",
            bodyJson, "/QueryFwIpAuditsPage", (result) => {
                console.log(result)
                let tableData = result.data;
                tableData.map((item, index) => { item.key = index; return item });
                this.setState({
                    tableData,
                    _current: pageNum || 1,
                    _pageSize: pageSize || _pageSize,
                    total: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };
    getPlayDetail(_ID) {
        this.setState({ detailLoading: true });
        socket.ExecuteAction("QueryFwIpAuditDetail",
            { _id: _ID }, "/QueryFwIpAuditDetail", (result) => {
                let detailData = result.data;
                this.setState({ detailData, detailLoading: false })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ detailLoading: false, })
            });
    }
    modalSumit(data, fileName) {
        const reg = /([^0-9.*-|;])/g;
        const errorList = [];
        const ips = [];
        data.map((item, index) => {
            const sourceIps = item["源地址"].trim();
            const destinationIps = item["目的地址"].trim();
            const service = item["目的端口"].trim();
            const serviceType = item["服务类型"].trim();
            if (!sourceIps && !destinationIps && !service && !serviceType) {
                message.error("请检查源地址、目的地址、目的端口、服务类型是否填写完整！");
                return;
            }
            if (sourceIps.match(reg)) {
                errorList.push({ index, name: "源地址", error: sourceIps.match(reg).toString() })
            }
            if (destinationIps.match(reg)) {
                errorList.push({ index, name: "目的地址", error: destinationIps.match(reg).toString() })
            }
            if (service.match(reg)) {
                errorList.push({ index, name: "目的端口", error: service.match(reg).toString() })
            }
            console.log(item);
            ips.push({ sourceIps, destinationIps, service, serviceType });
        });
        if (errorList.length > 0) {
            Modal.error({
                title: "错误提示",
                content: <div>
                    {errorList.map((error) => {
                        return <p>第{error.index + 2}行，{error.name}，错误字符"{error.error}"</p>
                    })}

                </div>
            });
            this.setState({ FileVis: false })
        } else {
            let bodyJson = {};
            let temp;
            let time;
            try {
                temp = fileName.split("_");
                time = data[0]["时间范围"].split("~");
            } catch (e) {
                message.error("文件错误！")
            }
            if (temp.length < 2) {
                message.error("文件名错误，格式参考: XXXX_单号_区域.xls");
                return;
            } else if (time.length != 2) {
                message.error("时间范围错误！");
                return;
            }
            bodyJson = {
                head: { action: "addByIps" },
                body: {
                    ips,
                    serviceId: temp[temp.length - 2],
                    beginTime: time[0],
                    endTime: time[1],
                    area: temp[temp.length - 1].split(".")[0]
                }
            }

            console.log(bodyJson)

            this.setState({ fileLoading: true });

            let temp_url = "http://" + socket.getAddress() + ":50034/firewall"
            console.log(temp_url)
            AxiosObj.SIMPLE_POST(temp_url,
                bodyJson, (result) => {
                    this.updata();
                    message.success("操作成功!");
                    this.setState({ FileVis: false, fileLoading: false, })
                }, (err, b_data) => {
                    let errorInfo = b_data["error"] || []
                    Modal.error({
                        title: "错误提示",
                        content: <div>
                            {errorInfo.map((error) => {
                                return <p>{error}</p>
                            })}
                        </div>
                    });
                    this.updata();
                    this.setState({ FileVis: false, fileLoading: false, })
                });
        }
    }
    getFWtable(item, text, color) {
        return item.map((success, key) => {
            return <tr key={key}>
                <td>{success["sourceIps"].map((ip) => <p>{ip}</p>)}</td>
                <td>{success["destinationIps"].map((ip) => <p>{ip}</p>)}</td>
                <td>
                    <p />
                    <table className="table"><tbody>
                        <tr><th>IP</th><th>源区域</th><th>目的区域</th></tr>
                        {/* {success["firewall_ip"].map((fw) => <p>{`${fw["firewall_ip"]}  ${fw["source_zone"]}  ${fw["destin_zone"]}`}</p>)} */}
                        {success["firewall_ip"].map((fw) => <tr><td>{fw["firewall_ip"]}</td><td>{fw["source_zone"]}</td><td>{fw["destin_zone"]}</td></tr>)}
                    </tbody></table>
                    <p />
                </td>
                <td>
                    <Tag color={color}>{text}</Tag>
                </td>
                <td>{(success["rule_name"] || []).map((n) => <p>{n}</p>)}</td>
            </tr>
        })
    }
    getErrorInfo(error) {
        if (typeof error === "object") {
            return <div className="errorInfoDiv">
                {error.map((item, k) => <p key={k}>{item}</p>)}
            </div>
        } else {
            return error
        }
    }
    getDetailDiv(data) {
        // console.log(data);
        let { selectItem } = this.state;
        let JsonData = data;
        let elemt = null;
        try {
            JsonData = JSON.parse(data);
        } catch (e) {

        }

        if (typeof JsonData === "string") {
            elemt = JsonData
        } else if (typeof JsonData === "object") {
            const flag = Object.prototype.toString.call(JsonData)
            if (flag === '[object Array]') {
                // console.log(JsonData)
                elemt = <div className="FileWallerror" >
                    {JsonData.map((item, key) => {
                        return <div key={key} className="hang">
                            <p><strong>状态码:</strong>{item.errorCode}</p>
                            <p><strong>反馈信息:</strong>{this.getErrorInfo(item.error)}</p>
                            <p><div className="pull-left2"><strong>请求信息:</strong></div>
                                <div className="infoDiv">
                                    {/* <p>源地址:{item.ips.ips.sourceIps.toString().replace(new RegExp(",", "g"), ",  ")}</p>
                                    <p>目的地址:{item.ips.ips.destinationIps.toString().replace(new RegExp(",", "g"), ",  ")}</p> */}
                                    <p>目标端口:{item.ips.ips.service.toString().replace(new RegExp(",", "g"), ",  ")}</p>
                                    <p>端口类型:{item.ips.ips.serviceType}</p>
                                    <p>
                                        {
                                            item.ips.check["success"] ?
                                                <table className="table"><tbody>
                                                    <tr><th>源地址</th><th>目的地址</th><th>防火墙信息</th><th>下发结果</th><th>策略名称</th></tr>
                                                    {this.getFWtable(item.ips.check["success"], "成功", "green")}
                                                    {this.getFWtable(item.ips.check["fail"], "失败", "red")}
                                                </tbody></table>
                                                : <Row gutter={2}>
                                                    <Col span={12}><table className="pull-left2 table"><tbody>
                                                        <tr><th>源地址</th><th>防火墙IP</th><th>区域</th></tr>
                                                        {item.ips.ips.sourceIps.map((souradd, key) => {
                                                            let s_data = item.ips.check[souradd] || {}
                                                            return <tr key={key}><td>{souradd}</td><td>{s_data.firewallIp}</td><td>{s_data.zone}</td></tr>
                                                        })}
                                                    </tbody></table></Col>
                                                    <Col span={12}><table className="pull-left2 table"><tbody>
                                                        <tr><th>目的地址</th><th>防火墙IP</th><th>区域</th></tr>
                                                        {item.ips.ips.destinationIps.map((desadd, key) => {
                                                            let d_data = item.ips.check[desadd] || {}
                                                            return <tr key={key}><td>{desadd}</td><td>{d_data.firewallIp}</td><td>{d_data.zone}</td></tr>
                                                        })}
                                                    </tbody></table></Col>
                                                </Row>}
                                    </p>
                                </div>
                            </p>
                        </div>
                    })}
                </div>
            } else if (flag === '[object Object]') {

            }
            // elemt = <div>
            //     <p>错误码:{JsonData}</p>
            // </div>

        }
        // const data = [{ "ips": { "ips": { "sourceIps": ["192.1.2.0", "192.1.2.3", "192.1.2.4", "192.1.2.5", "192.1.2.3", "192.1.2.3", ""], "destinationIps": ["193.2.34.2", "193.2.34.3", ""], "service": ["8080", "80"], "serviceType": "TCP" }, "check": { "192.1.2.0": { "firewallIp": "", "zone": "" }, "192.1.2.3": { "firewallIp": "", "zone": "" }, "192.1.2.4": { "firewallIp": "", "zone": "" }, "192.1.2.5": { "firewallIp": "", "zone": "" }, "": { "firewallIp": "", "zone": "" }, "193.2.34.2": { "firewallIp": "", "zone": "" }, "193.2.34.3": { "firewallIp": "", "zone": "" } } }, "errorCode": "100001", "error": "ips[0],sourceIps(192.1.2.0)没有源区域，网段信息中未匹配到源区域，请及时添加配置" }]
        return (
            <div>
                <p>状态：{selectItem ?
                    selectItem.status === "error" ? <Tag color="#ffbe3d">异常</Tag> : <Tag color={Utils.ColorShow(selectItem.status)}>{Utils.Translation(selectItem.status)}</Tag>
                    : ""}</p>
                <div>提示信息:{elemt}</div>
            </div>
        )
    }
    getDescriptionsData() {
        let { ipsData } = this.state;
        if (ipsData.length !== 0) {
            let editData
            if (typeof ipsData === "string") {
                editData = JSON.parse(ipsData.replace(/'/g, '"'))
            } else if (typeof ipsData === "object") {
                editData = ipsData
            } else {
                console.log(ipsData)
            }
            let elem = <MonacoEditor
                language="java"
                theme="vs-dark"
                height="500"
                options={{ selectOnLineNumbers: true }}
                value={JSON.stringify(editData, null, 4)}
            // value={ipsData}
            />
            return elem;
        }
    }
    deleteRecord() {
        let { deleteDate } = this.state;
        let _this = this;
        if (deleteDate.length === 0) {
            message.error("请选择删除时间!");
        } else {
            Modal.confirm({
                title: '消息提示',
                content: '你确定删除该时间前的所有记录吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    http.delete_body("http://10.32.79.60:50034/business/firewall/deleteIpAudits", { date: deleteDate }, (res) => {
                        if (res.retcode === "00") {
                            message.success(res.msg || "删除成功");
                            _this.setState({ deleteVis: false, deleteDate: "" });
                            _this.updata();
                        } else {
                            message.error(res.msg || "删除失败")
                        }
                    })
                },
            });
        }
    }
    render() {
        let { _current, _pageSize, querys, tableData, loading, total, FileVis, fileLoading, deleteVis,
            detailModal, detailData, detailLoading } = this.state;
        const columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                render: (text) => { return <span>{text + 1}</span> }
            }, {
                title: '开始时间',
                align: 'center',
                dataIndex: 'beginTime',
            }, {
                title: '类型',
                align: 'center',
                dataIndex: 'type',
            },

            {
                title: '单号',
                align: 'center',
                dataIndex: 'serviceId',
            }, {
                title: '策略有效时间',
                align: 'center',
                dataIndex: 'period',
            }, {
                title: '状态',
                align: 'center',
                dataIndex: 'status',
                render: (text) => {
                    // return (<span style={{color:Utils.ColorShow(text),fontWeight:'600'}}>{Utils.Translation(text)}</span>)
                    if (text === "error") {
                        return (<Tag color="#ffbe3d">异常</Tag>)
                    } else {
                        return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>)
                    }
                }
            }, {
                title: '操作',
                align: 'center',
                render: (text, item) => {
                    return <Button type="primary" size="small" icon="form" onClick={() => {
                        this.getPlayDetail(item._id);
                        this.setState({ detailModal: true, ipsData: item.ips, selectItem: item });
                    }}>详情</Button>
                }
            }
        ];
        const FormList = [
            {
                label: "类型",
                type: 'SELECT',
                field: "type",
                initialValue: '',
                list: [{ id: 'add', name: '增加' }, { id: 'delete', name: '删除' }]
            },
            {
                label: "单号",
                type: 'INPUT',
                field: "serviceId",
                initialValue: '',
                placeholder: "单号"
            },

        ]
        const modalParam = {
            title: "上传策略文件",
            template: {
                name: "XXXX_单号_区域.xlsx",
                sheetName: "防火墙变更",
                data: [{ "源地址": "192.1.2.*;192.1.2.3|4|5;192.1.2.3;192.1.2.3", "目的地址": "193.2.34.2;193.2.34.3", "服务类型": "TCP", "目的端口": "8080;80", "时间范围": "2020-04-02 09:00:00~2021-04-02 09:00:00" }]
            }
        }
        return (
            <div>
                <CustomBreadcrumb arr={["防火墙管理", "IP操作记录"]} />
                <Card>
                    <FilterForm
                        formList={FormList}
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(_pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(_pageSize, 1, {})
                        }}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>IP操作记录列表</span>}
                    style={{ marginTop: 8 }}
                    extra={
                        <Row>
                            <Button type="primary" icon="file-text" onClick={() => { this.setState({ FileVis: true }) }}>策略变更</Button>
                            <Button type="danger" icon="delete" onClick={() => { this.setState({ deleteVis: true }) }}>删除操作记录</Button>
                        </Row>
                    }
                >
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        size="small"
                        loading={loading}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.updata(size, 1, querys)
                            },
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                this.updata(pageSize, page, querys);
                            }
                        }}
                    />

                </Card>
                <Modal
                    title="详情"
                    visible={detailModal}
                    onCancel={() => this.setState({ detailModal: false, selectItem: null })}
                    onOk={() => this.setState({ detailModal: false, selectItem: null })}
                    width="70%"
                    style={{ top: "20px" }}
                    destroyOnClose
                >
                    <Spin spinning={detailLoading}>
                        <Tabs type="card" animated={true}>
                            <TabPane tab={"详情"} key={"1"}>
                                {detailData.map((item, key) => {
                                    if (key === 3) {
                                        return null;
                                    }
                                    return <p key={key}>{typeof (item) === "string" ? item : JSON.stringify(item)}</p>
                                })}

                                {this.getDetailDiv(detailData[3] && detailData[3].split("错误原因:")[1])}
                            </TabPane>
                            <TabPane tab={"请求数据"} key={"2"}>
                                {/* <div>{this.state.ipsData}</div>getDescriptionsData */}
                                <div>{this.getDescriptionsData()}</div>
                            </TabPane>
                        </Tabs>
                    </Spin>
                </Modal>
                <FileModal FileVis={FileVis}  {...modalParam} loading={fileLoading}
                    onCancel={() => { this.setState({ FileVis: false }); }}
                    onOk={(data, fileName) => { this.modalSumit(data, fileName) }}
                />
                <Modal
                    title="删除操作记录"
                    visible={deleteVis}
                    onCancel={() => this.setState({ deleteVis: false, deleteDate: "" })}
                    onOk={() => this.deleteRecord()}
                    width="50%"
                    destroyOnClose
                >
                    <span>选择删除时间：</span>
                    <DatePicker showTime placeholder="选择删除时间"
                        onChange={(date, dateString) => this.setState({ deleteDate: dateString })}
                        onOk={(date) => this.setState({ deleteDate: date.format("YYYY-MM-DD HH:mm:ss") })}
                    />
                </Modal>
            </div>
        )
    }
}


export default connect()(IPPlayRecord)