import React from 'react'
import axios from '@/axios/axios_utils';
import Socket from "@/socket/index";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import DyHeadTable from '@/components/DyHeadTable/index';
import FilterForm from '@/components/FilterForm/index';
import AuditNotPassed from './auditNotPassed';
import './index.scss';
import { Table, Collapse, Card, Button, Divider, Modal, Tabs, Tooltip, Row, Col, message, Radio, Icon, Tag, Input } from "antd";
const { TabPane } = Tabs;
const dumbTerminal_Url = "http://" + backup_address + "/ZJNMS_V1/dumb_termial/";

class UnknownDumbTerminal extends React.Component {
    state = {
        tableData: [], // 表格数据
        current_page: 1,  // 当前页
        pageSize: 20,
        total: 0,
        loading: false,
        query: {},
        selectedRowKeys: [],
        selectedData: [],
        selectOne: {},
        tcpVisible: false,
        noPassedVisible: false,
        radioValue: "非哑终端",
        dumbItem: null,
        inputValue: "",
        dep_ids: [],
    }

    componentWillMount() {
        this.getDepIds();
    }
    getDepIds = () => {
        Socket.ExecuteAction('QueryArea', {}, "", (result) => {
            const TreeData = result.data;
            let dep_ids = this.dealTreeData(TreeData);
            this.setState({ dep_ids });
            this.getTableData();
        });
    };
    dealTreeData(data) {
        let that = this;
        let dep_ids = [];
        if(Array.isArray(data)) {
            data.map((item) => {
                let children = item.props ? item.props.children : item.children
                if(children && children.length > 0) {
                    dep_ids = dep_ids.concat(that.dealTreeData(children));
                } else {
                    dep_ids.push(item.key || item.eventKey)
                }
            })
        }
        return dep_ids
    }
    getTableData(cur_page, page_size, querys) {
        this.setState({ loading: true, });
        const { current_page, pageSize, query, dep_ids } = this.state;
        let bodyQuery = querys || query;
        let bodyJson = {
            page_no: cur_page || current_page,
            page_size: page_size || pageSize,
            audit_status: 0,
            dep_ids: dep_ids.join(","),
            ...bodyQuery
        };
        axios.get(dumbTerminal_Url + "unknownDevice", bodyJson, (res) => {
            if(res.code === "00") {
                this.setState({
                    tableData: res.data,
                    total: res.count,
                    current_page: cur_page || res.page_no || 1,
                    pageSize: page_size || res.page_size || 20,
                    loading: false,
                    query: bodyQuery
                })
            }
            // console.log(res)
        })
    }
    openTcpModal(b) {
        this.setState({ selectOne: b, tcpVisible: true })
    }
    getTcpTabModal(objData) {
        const data = Object.keys(objData).map((key) => {
            let newone = objData[key];
            newone.port = key;
            return newone
        });
        return <Table
            columns={[
                { title: "port", dataIndex: "port" },
                ...Object.keys(data[0] || {}).filter((f) => f != "port").map((t) => {
                    return { title: t, dataIndex: t, }
                })
            ]}
            scroll={{ y: 400 }}
            size="small"
            rowKey={r => r.port}
            dataSource={data}
            pagination={false}
        />
    }
    getOsModal(osInfo) {
        if (osInfo) {
            return <Table
                columns={[{ title: "相似操作系统", dataIndex: "os", align: "left" }, { title: "相似度", width: 50, dataIndex: "accuracy", render: (a) => (a + "%") }]}
                size="small"
                scroll={{ y: 400 }}
                rowKey={r => r.os}
                dataSource={osInfo || []}
                pagination={false}
            />
        } else {
            return null;
        }
    }
    auditTerminal(type) {
        let { radioValue, selectedData, dumbItem, inputValue, pageSize } = this.state;
        let _this = this;
        let bodyJson = {};
        let audit_data = dumbItem ? [dumbItem] : selectedData;
        let reason = radioValue === "其他" ? inputValue : radioValue;
        if(type === "noPass") {
            bodyJson = {
                audit_data: audit_data,
                audit_status: 2,
                reason: reason,
            }
        } else if(type === "pass") {
            bodyJson = {
                audit_data: audit_data,
                audit_status: 1
            }
        }
        // console.log(bodyJson)
        Modal.confirm({
            title: '消息提示',
            content: `你确定审核${type === "pass" ? "通过" : "不通过"}该设备吗？`,
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                axios.put(dumbTerminal_Url + "unknownDeviceAudit", bodyJson, (res) => {
                    if(res.code === "00") {
                        message.success("执行成功");
                        _this.getTableData(1, pageSize, {});
                        _this.setState({
                            dumbItem: null,
                            selectedData: [],
                            radioValue: "非哑终端",
                            inputValue: "",
                            noPassedVisible: false
                        })
                    } else {
                        message.success(res.msg);
                    }
                    // console.log(res)
                })
            },
        });
    }
    onRef = (ref) => {
        this.auditNotPassed = ref;
    }

    render() {
        const { current_page, total, pageSize, tcpVisible, selectOne, selectedRowKeys, selectedData, noPassedVisible, radioValue } = this.state;
        const columns = [
            {
                title: 'IP',
                align: 'center',
                dataIndex: 'ip',
                width: 100,
                ellipsis: true,
            },
            {
                title: 'mac地址',
                align: 'center',
                dataIndex: 'mac_address',
                width: 120,
                ellipsis: true,
            },
            {
                title: '名称（dhcp）',
                align: 'center',
                dataIndex: 'dhcp_name',
                width: 100,
                ellipsis: true,
            },
            {
                title: 'mac地址（dhcp）',
                align: 'center',
                dataIndex: 'dhcp_mac',
                width: 120,
                ellipsis: true,
            },
            {
                title: '描述（dhcp）',
                align: 'center',
                dataIndex: 'dhcp_desc',
                width: 120,
                ellipsis: true,
            },
            {
                title: '描述（snmp）',
                align: 'center',
                dataIndex: 'snmp_desc',
                width: 220,
                ellipsis: true,
            },
            {
                title: '设备信息',
                align: 'center',
                dataIndex: 'device_info',
                width: 120,
                ellipsis: true,
                render: (a, b, c) => {
                    const { os_info, tcp_info } = b;
                    if(Object.keys(tcp_info).length !== 0 || os_info .length !== 0) {
                        return <Button type="link" size="small" onClick={() => this.openTcpModal(b)}>查看设备信息</Button>
                    }
                }
            },
            {
                title: '接入交换机名称',
                align: 'center',
                dataIndex: 'switch_name',
                width: 120,
                ellipsis: true,
            },
            {
                title: '接入交换机IP',
                align: 'center',
                dataIndex: 'switch_ip',
                width: 100,
                ellipsis: true,
            },
            {
                title: '接入端口',
                align: 'center',
                dataIndex: 'switch_port',
                width: 100,
                ellipsis: true,
            },
            {
                title: '创建时间',
                align: 'center',
                dataIndex: 'create_time',
                width: 150,
                ellipsis: true,
            },
            {
                title: '更新时间',
                align: 'center',
                dataIndex: 'update_time',
                width: 150,
                ellipsis: true,
            },
            
            {
                title: '操作',
                align: 'center',
                dataIndex: 'op',
                fixed: 'right',
                width: 200,
                render: (a, b, c) => {
                    return (
                        <div>
                            <Button type="primary" style={{ marginLeft: 10 }} size="small" icon="check-circle" onClick={() => {
                                this.setState({ dumbItem: b }, () => {
                                    this.auditTerminal("pass", b);
                                });
                            }}>通过</Button>
                            <Button type="danger" style={{ marginLeft: 10 }} size="small" icon="close-circle" onClick={() => { this.setState({ noPassedVisible: true, dumbItem: b }) }}>不通过</Button>
                        </div>
                    )
                }
            }
        ]

        let scrollX = 0;
        columns.map((item, index) => {
            scrollX += item.width;
        })
        scrollX = scrollX + 260;

        //多选
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            hideDefaultSelections: 'true',
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys });
            },
            onSelect: (record, selected, selectedRows) => { // 选择单行
                if(selected) {
                    if(JSON.stringify(selectedData).indexOf(JSON.stringify(record)) === -1) {
                        selectedData.push(record);
                    }
                } else {
                    selectedData.splice(selectedData.findIndex(item => item._id === record._id), 1)
                }
                // console.log("最终的selectedData：", selectedData)
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                let beforeSelectAll = [];
                if(selected) {
                    if(selectedData.length === 0) {
                        selectedData.push(...selectedRows)
                    } else {
                        selectedData.map((item, index) => {
                            if(JSON.stringify(selectedRows).indexOf(JSON.stringify(item)) === -1) {
                                // console.log("selectedData:", item)
                                beforeSelectAll.push(item);
                            }
                        })
                        selectedData.splice(0, selectedData.length, ...selectedRows, ...beforeSelectAll)
                    }
                } else {
                    changeRows.map((item, index) => {
                        selectedData.splice(selectedData.findIndex(selectItem => selectItem._id === item._id), 1)
                    })
                }
                this.setState({ selectedData })
                // console.log("onSelectAll", selected, selectedRows, selectedData)
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [], selectedData: [] });
                    },
                },
            ],
        };

        const unknownDiv = document.getElementById("unknownDiv");

        return (
            <div className="unknownDumbTerminalDiv">
                <CustomBreadcrumb arr={["哑终端管理", { title: "哑终端未知设备审核", to: "/dumbTerminal/unKnownDumbTerminal" }]} />
                <Tabs type="card" onChange={(activeKey) => {
                    switch(activeKey){
                        case "unKnown": this.getTableData(1, pageSize); $(".auditList").append(unknownDiv); break;
                        case "noPass": if(this.auditNotPassed) { this.auditNotPassed.getTableData(1, pageSize); }; document.getElementById("unknownDiv").remove(); break; // $("#unknownDiv").remove();
                    }
                }}>
                    <TabPane tab="未知设备审核列表" key="unKnown" className="auditList">
                        <div id="unknownDiv">
                            <div className="searchDiv">
                                <Collapse defaultActiveKey={"key"}>
                                    <Collapse.Panel header="筛选条件" key={"key"}>
                                        <FilterForm tagClass="unKnownDumbTerminal"
                                            filterSubmit={(querys) => {
                                                this.getTableData(1, pageSize, querys);
                                            }}
                                            resetTab={() => {
                                                this.getTableData(1, pageSize, {})
                                            }}
                                        />
                                    </Collapse.Panel>
                                </Collapse>
                            </div>
                            <Card
                                title={<span style={{ fontWeight: 600 }}>未知设备审核列表</span>}
                                style={{ marginTop: 8 }}
                                extra={
                                    <Row>
                                        <Button type="primary" icon="check-circle" onClick={() => {
                                            if(selectedRowKeys.length === 0) {
                                                message.error("请选择哑终端设备");
                                            } else {
                                                this.auditTerminal("pass")
                                            }
                                        }}>通过</Button>
                                        <Button type="danger" icon="close-circle" onClick={() => {
                                            if(selectedRowKeys.length === 0) {
                                                message.error("请选择哑终端设备");
                                            } else {
                                                this.setState({ noPassedVisible: true })
                                            }
                                        }}>不通过</Button>
                                    </Row>
                                }
                            >
                                <DyHeadTable
                                    style={{ marginTop: 10 }}
                                    dataSource={this.state.tableData || []}
                                    columns={columns}
                                    size='small'
                                    loading={this.state.loading}
                                    rowKey={(text) => { return text._id }}
                                    rowSelection={rowSelection}
                                    scroll={{ x: scrollX, y: 630 }}
                                    pagination={{
                                        pageSize: pageSize, current: current_page, total: total,
                                        showTotal: (total) => { return <span>共 {total} 项</span> },
                                        onChange: (page, pageSize) => {
                                            this.getTableData(page, pageSize);
                                        }
                                    }}
                                />
                            </Card>
                        </div>
                    </TabPane>
                    <TabPane tab="审核不通过列表" key="noPass" className="noPassList">
                        <AuditNotPassed getDataList={() => this.getTableData()} onRef={this.onRef} depIds={this.state.dep_ids} />
                    </TabPane>
                </Tabs>
                <Modal
                    title="设备相关信息详情"
                    visible={tcpVisible}
                    width="60%"
                    style={{ top: 50 }}
                    onCancel={() => this.setState({ tcpVisible: false })}
                    onOk={() => this.setState({ tcpVisible: false })}
                >
                    <Divider orientation="left">端口信息</Divider>
                    <div className="portinfoDiv">
                        {/* <table className="tcpmodaltab"><tbody><tr><th>端口号</th><th>端口信息</th></tr></tbody></table>
                        {this.getTcpModal(selectOne.tcp_info, "")} */}
                        {this.getTcpTabModal(selectOne.tcp_info || {})}
                    </div>
                    <Divider orientation="left">相似操纵系统</Divider>
                    <div>{this.getOsModal(selectOne.os_info)}</div>
                </Modal>
                <Modal
                    title="审核不通过理由"
                    visible={noPassedVisible}
                    width="60%"
                    onCancel={() => this.setState({ noPassedVisible: false, dumbItem: null })}
                    onOk={() => this.auditTerminal("noPass") }
                >
                    <div style={{ padding: "0 30px" }}>
                        <label>不通过理由：</label>
                        <Radio.Group
                            onChange={(e) => { this.setState({ radioValue: e.target.value }) }}
                            style={{ marginBottom: 20 }}
                            defaultValue={radioValue}
                            value={radioValue} 
                            options={[
                                { label: "非哑终端", value: "非哑终端" },
                                { label: "属于PC", value: "属于PC" },
                                { label: "属于网络设备", value: "属于网络设备" },
                                { label: "其他", value: "其他" },
                            ]}
                        />
                        { radioValue === "其他" ? <div>
                            <label required>&emsp;其他理由：</label>
                            <Input style={{ width: "45%" }} onBlur={(e) => this.setState({ inputValue: e.target.value })} placeholder="请输入审核不通过理由" />
                        </div> : ''}
                    </div>
                </Modal>
            </div>
        )
    }
}

export default UnknownDumbTerminal;
