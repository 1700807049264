

import React from 'react'
import {
    Button, Icon, message, Modal, Upload,
} from "antd";
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import * as XLSX from 'xlsx';
const { Dragger } = Upload;
export default class FileModal extends React.Component {
    state = {
        FileVis: false,
        current: 0,
        excelData: [],
    };
    componentWillReceiveProps(props) {
        this.setState({ FileVis: props.FileVis })
    }
    uploadFilesChange = (file) => {
        if (Utils.isEmpty(file.fileList)) {
            return;
        }
        console.log(file)
        let _this = this;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(file.file);
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = {};
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    let tempData = [];
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data[sheet] = tempData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    }
                }
                const excelData = data;
                console.log(excelData);
                _this.setState({ excelData });
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                console.log(e);
                message.error('文件类型不正确！');
            }
        }

    }
    onSumit() {
        const { excelData } = this.state
        const data = Object.values(excelData)[0];
        console.log(excelData);
        console.log(data);
        Socket.ExecuteAction('UploadResources',
            { data }, "/queryResource", (result) => {
                if (result.error && result.error.length != 0) {
                    Modal.info({
                        title: "提示消息",
                        content: <div>
                            {result.error.map((e) => { return <p>{e}</p> })}
                        </div>
                    })
                } else {
                    message.success("导入成功!")
                }
                this.props.onCancel();
            }, (error) => {
                Utils.showMessage("error", "导入失败", error);
            });

    }
    exportExcel() {
        let list = [
            {
                "设备IP": "10.34.11.8",
                "SSH用户名": "cisco",
                "SSH密码": "cisco",
                "SNMP口令": "zijin",
                "资源描述": "sw-cisco-10.34.11.8",
                "设备厂商": "CISCO",
                "设备区域": "南山",
                "设备OS版本": "",
                "标签": "",
                "设备名称": "",
                "设备型号": "",
            }
        ]
        Utils.exportExcel(list, "导入资源模板.xlsx");
    }
    render() {
        let { FileVis } = this.state;
        return (
            <Modal
                title={<div>上传资源&nbsp;&nbsp;&nbsp;<Button type="primary" icon="download" onClick={() => this.exportExcel()}>下载模板</Button></div>}
                visible={FileVis}
                onCancel={() => this.setState({ FileVis: false, InfoBack: "" })}
                onOk={() => this.onSumit()}
                afterClose={() => {
                    this.props.onCancel();
                    this.setState({ current: 0 })
                }}
                okText="确认导入"
                destroyOnClose
            >
                {/* 
                <Steps current={current}>
                    <Step title="导入文件" icon={<Icon type="upload" />} >
                    </Step>
                    <Step title="填写信息" icon={<Icon type="solution" />} >
                    </Step>
                </Steps> */}
                <Dragger name="file"
                    beforeUpload={function () {
                        return false;
                    }} onChange={this.uploadFilesChange.bind(this)}>
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">单击或将文件拖到该区域上传</p>
                    {/* <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                        band files
    </p> */}
                </Dragger>
                {/* <hr style={{ border: "1px solid #e2e2e2" }} /> */}
                {/* <div style={{ position: "absolute", right: 20 }}> */}
                {/* {current === 0 ? <Button type="primary"
                        onClick={() => this.onSumit()}
                    >下一步</Button> :
                        <div>
                            <Button type="primary"
                                onClick={() => this.setState({ current: 0 })}
                            >上一步</Button>&nbsp;
                            <Button type="primary"
                                onClick={() => this.onSumit()}
                            >完成</Button>
                        </div>
                    } */}
                {/* </div> */}
            </Modal>

        )
    }
}
