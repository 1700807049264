import React from 'react'
import { Button, Card, Form, Icon, Input, message, Select, Tabs, Row, Col, Descriptions, Tag, Collapse, Table, Tooltip, Modal } from 'antd';
import CustomBreadcrumb from '../../components/CustomBreadcrumb/index';

import axios from '@/axios/axios_utils';
import Utils from "@/utils/utils";
import MonacoEditor from 'react-monaco-editor';

const checkDevices_url = "http://" + backup_address + "/ZJNMS_V1/";
import './index.scss'

class DevicesInfo extends React.Component {

    state = {
        itemInfo: {},
        taskData: [],
        modalData: {},
        loading: false,
        pageNum: 1,
        pageSize: 20,
        modalVisible: false,
    }

    componentWillMount() {
        let bodyJson = this.props.location.bodyJson;
        if(bodyJson) {
            this.getData(bodyJson.taskId)
        }
    }
    getData(id) {
        axios.get(checkDevices_url + "check/check_devices_detail", { id }, (res) => {
            if(res.code === "00") {
                this.setState({
                    taskData: res.data[0].taskData ? res.data[0].taskData : [],
                    itemInfo: res.data[0]
                })
            }
            // console.log(res, this.state)
        })
    }
    openModal(item) {
        this.setState({
            modalVisible: true,
            modalData: item.data
        });
    }
    getDescriptionsData() {
        let { modalData } = this.state;
        let taskDataKey = Object.keys(modalData);
        let elem = taskDataKey.map((item, index) => {
            let taskText = modalData[item];
            if(typeof taskText === "object"){
                taskText = JSON.stringify(modalData[item]);
            }else if(typeof taskText === "number"){
                taskText = taskText + "";
            }
            return <Descriptions.Item key={item} label={item} >
                <Collapse>
                    <Collapse.Panel header={item}>
                        <MonacoEditor
                            language="java"
                            theme="vs-dark"
                            height="500"
                            options={{ minimap: { enabled: false } }}
                            value={taskText+""}
                        /> 
                    </Collapse.Panel>
                </Collapse>
            </Descriptions.Item>
        })
        return elem;
    }
    goBackPage() {
        let path = {
            pathname: '/securityBaseline',
        };
        this.props.history.push(path);
    }
    getHistoryList() {
        let path = {
            pathname: `/securityBaseline/historyDeviceDetail`,
            bodyJson: this.props.location.bodyJson,
        };
        this.props.history.push(path);
    }

    render = () => {
        let { taskData, itemInfo, loading, pageNum, pageSize, modalVisible } = this.state;
        const columns = [
            {
                title: '序号',
                dataIndex: 'ip',
                ellipsis: true,
                width: 50,
                render: (text, item, c) => { return c + 1 }
            },
            {
                title: '编号',
                dataIndex: 'number',
                ellipsis: true,
            },
            {
                title: '分类',
                dataIndex: 'type',
                ellipsis: true,
            },
            {
                title: '描述',
                dataIndex: 'description',
                ellipsis: true,
            },
            {
                title: '检查结果',
                dataIndex: 'result',
                ellipsis: true,
                render: (text, item, index) => {
                    let elemt = []
                    text.map((tag, ind) => {
                        switch (tag.code) {
                            case "0" : elemt.push(<Tag key={tag.id} color="blue">{tag.value}</Tag>); break;
                            case "1" : elemt.push(<Tag key={tag.id} color="red">{tag.value}</Tag>); break;
                            case "2" : elemt.push(<Tag key={tag.id}>{tag.value}</Tag>); break;
                        }
                    })
                    return elemt
                }
            },
            {
                title: '操作',
                width: 180,
                render: (text, item) => {
                    return <div>
                        <Button type="primary" icon="edit" size="small" onClick={() => { this.openModal(item) }}>详情</Button>
                    </div>
                }
            },
        ]

        return (
            <div>
                <CustomBreadcrumb arr={[{ title: '首页', to: '/home' }, { title: '安全基线管理', to: '/securityBaseline' }, "设备详细信息"]} />
                <Card style={{ padding: "5px 10px" }}>
                    <div className="devicesInfoTable">
                        <Descriptions
                            title={
                                <Row>
                                    <Col span={22}>设备基本信息</Col>
                                    <Col><Button span={2} type="primary" icon="rollback" onClick={() => { this.goBackPage() }}>返回</Button></Col>
                                </Row>
                            }
                            column={3}
                            bordered
                        >
                            <Descriptions.Item label={"资源名称"} span={1} >{itemInfo.device ? itemInfo.device.resourceName : ""}</Descriptions.Item>
                            <Descriptions.Item label={"设备名称"} span={1} >{itemInfo.device ? itemInfo.device.resourceParams.deviceId : ""}</Descriptions.Item>
                            <Descriptions.Item label={"区域"} span={1} >{itemInfo.device ? itemInfo.device.resourceParams.deviceSite : ""}</Descriptions.Item>
                            <Descriptions.Item label={"设备IP"} span={1} >{itemInfo.device ? itemInfo.device.resourceParams.deviceIp : ""}</Descriptions.Item>
                            <Descriptions.Item label={"设备类型"} span={1} ><div>{itemInfo.device ? Utils.Translation(itemInfo.device.resourceParams.deviceType) : ""}</div></Descriptions.Item>
                            <Descriptions.Item label={"检查时间"} span={1} >{itemInfo.checkTime}</Descriptions.Item>
                            <Descriptions.Item label={"设备厂商"} span={1} >{itemInfo.device ? itemInfo.device.resourceParams.deviceFirm : ""}</Descriptions.Item>
                            <Descriptions.Item label={"设备状态"} span={1} >{itemInfo.device ? Utils.Translation(itemInfo.device.resourceParams.deviceState) : ""}</Descriptions.Item>
                            <Descriptions.Item label={"创建时间"} span={1} >{itemInfo.create_time}</Descriptions.Item>
                            <Descriptions.Item label={"设备型号"} span={1} >{itemInfo.device ? itemInfo.device.resourceParams.deviceVersion : ""}</Descriptions.Item>
                            <Descriptions.Item label={"标签"} span={1} >{itemInfo.device ? itemInfo.device.tag : ""}</Descriptions.Item>
                            <Descriptions.Item label={"资源描述"} span={1} >{itemInfo.device ? itemInfo.device.resourceDes : ""}</Descriptions.Item>
                            <Descriptions.Item label={"设备OS版本"} span={3} >{itemInfo.device ? itemInfo.device.resourceParams.deviceOsVersion : ""}</Descriptions.Item>
                            <Descriptions.Item label={"检查结果"} span={3} >
                                {itemInfo.result ? itemInfo.result.map((item, index) => {
                                    switch (item.code) {
                                        case "0" : return(<Tag key={item.key} color="blue">{item.value}</Tag>);
                                        case "1" : return(<Tag key={item.key} color="red">{item.value}</Tag>);
                                        case "2" : return(<Tag key={item.key}>{item.value}</Tag>);
                                    }
                                }) : ""}
                            </Descriptions.Item>
                        </Descriptions> <p />
                    </div>
                </Card>
                <Card
                    style={{ marginTop: 5 }}
                    title={<span style={{ fontWeight: 600 }}>设备检查数据</span>}
                    extra={ <Button type="primary" icon="diff" onClick={() => { this.getHistoryList() }}>查看历史记录</Button> }
                >
                    <Table
                        dataSource={Array.isArray(taskData) ? taskData : []}
                        columns={columns}
                        loading={loading}
                        size="small"
                        rowKey={(text) => { return text.number }}
                        pagination={false}
                    />
                </Card>
                <Modal
                    visible={modalVisible}
                    onOk={() => { this.setState({ modalVisible: false, modalData: {} }) }}
                    onCancel={() => this.setState({ modalVisible: false, modalData: {} })}
                    width="80%"
                    destroyOnClose
                >
                    { taskData ? 
                        <div className="checkData">
                            <Card title={<span style={{ fontWeight: 600 }}>设备检查数据</span>} style={{ marginTop: 5 }}>
                                {this.getDescriptionsData()}
                            </Card>
                        </div>
                    : "" }
                </Modal>
            </div>
        )
    };
}

const DevicesInfoFrom = Form.create()(DevicesInfo);
export default DevicesInfoFrom;
