import React from "react";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import {BackTop, Button, Card, Col, Icon, Modal, Row, Spin, Upload} from "antd";
import {zx_baseUrl} from '@/pages/NetLine/zx-config'
import LineSource from "./line-source";
import SourceConfigSetting from "./source-config";
import {generateLine} from '../../zx-api/config-settings'

export default class Settings extends React.Component {

    state = {
        isGenerateSuccess: false,
        configModalVisible: false,
        submitLoading: false,
        allLoading: false

    }

    constructor(props) {
        super(props);

    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    //合成专线信息
    generateLine = () => {
        generateLine(res => {
            if (res.msg === 'success') {
                this.setState({isGenerateSuccess: true})
            }
        })
    }

    handleClick = () => {
        this.setState({configModalVisible: true})
    }

    handleLoading = (flag) => {
        alert("settings handleLoading")
        if (flag) {
            this.setState({allLoading: true})
        } else {
            this.setState({allLoading: false})
        }
    }

    handleOk = () => {
        this.setState({submitLoading: true});
        this.props.form.validateFieldsAndScroll((err, value) => {


        })
        // setTimeout(() => {
        //     this.setState({ detailLoading: false, detailVisible: false });
        // }, 3000);
    };

    handleCancel = () => {
        this.setState({configModalVisible: false});
    };

    render() {

        const {configModalVisible, submitLoading} = this.state

        // const allLoading = this.props.allLoading || false

        //设备上传文件配置
        const EquipUploadProps = {
            name: 'file',
            action: zx_baseUrl + '/zx/equip/',
            method: 'post',
            headers: {}
        }

        // 专线上传文件配置
        const LineUploadProps = {
            name: 'file',
            action: zx_baseUrl + '/zx/zl/',
            method: 'post',
            headers: {}
        }

        // 配置文件上传配置
        const ConfigUploadProps = {
            name: 'file',
            action: zx_baseUrl + '/zx/uploadConfigFile/',
            method: 'post',
            multiple: true,
            headers: {}
        }

        return (
            <div>
                <BackTop/>
                <CustomBreadcrumb arr={["专线配置管理", "配置导入"]}/>
                <Spin spinning={this.state.allLoading} tip="这可能需要花费比较长的时间，请耐心等待……" size={"large"} delay={500}>
                    <Row>
                        <Col span={12}>
                            <SourceConfigSetting handleClick={this.handleClick} handleSpinning={this.handleLoading}/>
                        </Col>
                        <Col span={12}>
                            <Card title='异常信息' style={{marginTop: 8, marginLeft: 8, height: 300}}>
                                <p>暂无异常信息</p>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 8}}>
                        <LineSource/>
                    </Row>
                </Spin>


                {/* 弹出层 */}
                <Modal
                    visible={configModalVisible}
                    title={`基本配置`}
                    width='90%'
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" type='primary' onClick={this.handleCancel}>
                            关闭
                        </Button>,
                    ]}
                >
                    <Row>
                        <Col span={8}>
                            <Card title='步骤一 设备资源导入' style={{marginRight: 8}}>
                                <Upload {...EquipUploadProps}>
                                    <Button type='primary'>
                                        <Icon type="upload"/> 上传设备资源
                                    </Button>
                                </Upload>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title='步骤二 专线资源导入' style={{marginRight: 8}}>
                                <Upload {...LineUploadProps}>
                                    <Button type='primary'>
                                        <Icon type="upload"/> 上传专线资源
                                    </Button>
                                </Upload>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title='步骤三 网络资源导入（交换机等的配置文件）'>
                                <Upload {...ConfigUploadProps}>
                                    <Button type='primary'>
                                        <Icon type="upload"/> 上传网络资源
                                    </Button>
                                </Upload>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 8}}>
                        <Card title='步骤四 匹配设备和专线'>
                            <Row>
                                <Col span={4}>
                                    <Card style={{height: 200}}>
                                        <Button type='primary' onClick={this.generateLine}>匹配合成</Button>
                                    </Card>
                                </Col>
                                <Col span={20}>
                                    <Card title='反馈结果' style={{height: 200}}>
                                        <h1>{`${this.state.isGenerateSuccess === true ? '合成成功！' : ''}`}</h1>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Row>
                </Modal>
            </div>
        )
    }

}