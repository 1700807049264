import React from 'react'
import { Card, Select, Input, Button, Form, message, notification, Icon, Row, Col, Modal, Tabs, Switch, InputNumber } from 'antd';
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import AxiosObj from "../../axios";
// monitor_address 配置地址：public/flow/config.js
const backUp_Url = monitor_address;

class EmailForm extends React.Component {
    state = {
    }
    //email表单提交
    emailHandleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                let body = values;
                //POST 方法第一个参数是与axios中地址的选择
                AxiosObj.HTTP_POST('email', '/config', body, (data) => {
                    message.success('success');
                    this.props.history.goBack();
                }, (error) => {
                    message.error(error);
                });
                console.log('finish');
            }
        });
    }
    onTabChange = (key, type) => {
        console.log(key, type);
        this.setState({ [type]: key });
    };
    //邮件测试
    emailTest = (e) => {
        let { route } = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let body = {};
                console.log('Received values of form: ', values);
                console.log('body', body)
                //POST 方法第一个参数是与axios中地址的选择
                // AxiosObj.HTTP_POST('alarm','/snmpexporter/verifyEmail',body,(data)=>{
                //     message.success('success');
                //     //this.props.history.goBack();
                // },(error)=>{
                //     message.error(error);
                // });
            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        const { receiver } = this.state;

        return (
            <div>
                <CustomBreadcrumb arr={["邮件管理", { to: '/email/emailConfig', title: "邮件配置" }, "添加发件人"]} />
                <Card title={<span style={{ fontWeight: 600 }}>发件人配置</span>}>
                    <Row>
                        <Col span={16}>
                            <Form onSubmit={this.emailHandleSubmit}>
                                <Form.Item
                                    label="名称"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('name', {
                                        initialValue: "",
                                        rules: [{
                                            type: 'string', message: '请输入正确字符！',
                                        }, {
                                            pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                        }, {
                                            required: true, message: '请输入字符!',
                                        }],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item
                                    label="SMTP服务器地址"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('server', {
                                        initialValue: "",
                                        rules: [
                                            // {
                                            //     type: 'string', message: '请输入正确字符！',
                                            // }, {
                                            //     pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                            // },
                                            {
                                                required: true, message: '请输入字符!',
                                            }],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item
                                    label="SMTP服务器端口"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('port', {
                                        rules: [
                                            // {
                                            //     type: 'string', message: '请输入正确字符！',
                                            // }, {
                                            //     pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                            // },
                                            {
                                                required: true, message: '请输入端口数字!',
                                            }],
                                    })(<InputNumber style={{ width: "100%" }} />)}
                                </Form.Item>
                                <Form.Item
                                    label="发件人邮箱"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('username', {
                                        initialValue: "",
                                        rules: [{ type: 'email', required: true, message: '请输入正确邮箱！' }]
                                    })(<Input />)}
                                </Form.Item>
                                {/*<Form.Item*/}
                                {/*label="发件人用户名"*/}
                                {/*{...formItemLayout}*/}
                                {/*>*/}
                                {/*{getFieldDecorator('emailSenderUsername', {*/}
                                {/*initialValue:  "",*/}
                                {/*// rules: [{*/}
                                {/*//     type: 'string', message: '请输入正确字符！',*/}
                                {/*// }, {*/}
                                {/*//     pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',*/}
                                {/*// }, {*/}
                                {/*//     required: true, message: '请输入字符!',*/}
                                {/*// }],*/}
                                {/*})(<Input />)}*/}
                                {/*</Form.Item>*/}
                                <Form.Item
                                    label="发件人密码"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('password', {
                                        initialValue: "",
                                        // rules: [{
                                        //     type: 'string', message: '请输入正确字符！',
                                        // }, {
                                        //     pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',
                                        // }, {
                                        //     required: true, message: '请输入字符!',
                                        // }],
                                    })(<Input.Password />)}
                                </Form.Item>
                                {/*<Form.Item*/}
                                {/*label="默认发件配置"*/}
                                {/*{...formItemLayout}*/}
                                {/*>*/}
                                {/*{getFieldDecorator('is_default', {*/}
                                {/*initialValue: false,*/}
                                {/*// rules: [{*/}
                                {/*//     type: 'string', message: '请输入正确字符！',*/}
                                {/*// }, {*/}
                                {/*//     pattern: new RegExp(/^[0-9a-zA-Z\u4E00-\u9FA5]+$/, "g"), message: '不能输入空格！',*/}
                                {/*// }, {*/}
                                {/*//     required: true, message: '请输入字符!',*/}
                                {/*// }],*/}
                                {/*})(<Switch disabled={this.state.disabled}  />)}*/}
                                {/*</Form.Item>*/}
                                {/*<Form.Item*/}
                                {/*label="收件人邮箱"*/}
                                {/*{...formItemLayout}*/}
                                {/*>*/}
                                {/*{getFieldDecorator('emailReceiverAddress', {*/}
                                {/*initialValue:  "",*/}
                                {/*rules:[{type: 'email',required: true}]*/}
                                {/*})(<Input />)}*/}
                                {/*</Form.Item>*/}
                                {/*<Form.Item*/}
                                {/*label=" "*/}
                                {/*colon={false}*/}
                                {/*{...formItemLayout}*/}
                                {/*>*/}
                                {/*<div >*/}
                                {/*<Button  size="small" style={{marginLeft:112,width: 80}} onClick={this.emailTest}>*/}
                                {/*测试*/}
                                {/*</Button>*/}
                                {/*</div>*/}
                                {/*</Form.Item>*/}
                                <Form.Item
                                    label=" "
                                    colon={false}
                                    {...formItemLayout}
                                >
                                    <div style={{ textAlign: "center" }}>
                                        {/*<div>*/}
                                        {/*<Button type="primary" style={{ marginLeft:75 }}>邮件发送测试</Button>*/}
                                        {/*</div>*/}
                                        <Button type="primary" icon="check-circle" htmlType="submit" >
                                            保存
                                        </Button>&nbsp;
                                        <Button type="danger" icon="close-circle"
                                            onClick={() => { this.props.history.goBack(); }}>
                                            返回
                                        </Button>
                                    </div>
                                </Form.Item>

                            </Form>

                        </Col>
                        <Col span={8}>
                        </Col>
                    </Row>
                </Card>
            </div>
        )
    }
}

export default Form.create()(EmailForm);