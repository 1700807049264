import React from 'react'
import { Button, Card, Form, Icon, Input, message, Select, Tabs, Row, Col, Descriptions, Tag, Collapse, Table, Tooltip, Modal } from 'antd';
import CustomBreadcrumb from '../../components/CustomBreadcrumb/index';

import axios from '@/axios/axios_utils';
import Utils from "@/utils/utils";
import MonacoEditor from 'react-monaco-editor';

const checkDevices_url = "http://" + backup_address + "/ZJNMS_V1/";
import './index.scss'

class HistoryDevicesDetail extends React.Component {

    state = {
        taskData: [],
        modalData: {},
        loading: false,
        pageNum: 1,
        pageSize: 20,
        total: 0,
        modalVisible: false,
        historyVisible: false,
        historyTableData: [],
        hisDetailVisible: false,
        hisDetailData: {}

    }

    componentWillMount() {
        let bodyJson = this.props.location.bodyJson;
        if (bodyJson) {
            this.getData()
        }
    }
    getData(page_num, _pageSize) {
        this.setState({ loading: true })
        let { pageNum, pageSize } = this.state;
        let bodyJson = {
            ip: this.props.location.bodyJson.ip,
            page_no: page_num || pageNum,
            page_size: _pageSize || pageSize,
        }
        axios.post(checkDevices_url + "check/get_check_record", bodyJson, (res) => {
            // console.log(res)
            if (res.code === "00") {
                this.setState({
                    historyTableData: res.data,
                    total: res.count,
                    pageNum: page_num || 1,
                    loading: false,
                })
            }
        })
    }
    openModal(item, modalName) {
        // console.log(item)
        if (modalName) {
            this.setState({
                hisDetailVisible: true,
                hisDetailData: item
            });
        } else {
            this.setState({
                modalVisible: true,
                modalData: item.data
            });
        }
    }
    getDescriptionsData() {
        let { modalData } = this.state;
        let taskDataKey = Object.keys(modalData);
        let elem = taskDataKey.map((item, index) => {
            let taskText = modalData[item];
            if (typeof taskText === "object") {
                taskText = JSON.stringify(modalData[item]);
            } else if (typeof taskText === "number") {
                taskText = taskText + "";
            }
            return <Descriptions.Item key={item} label={item} >
                <Collapse>
                    <Collapse.Panel header={item}>
                        <MonacoEditor
                            language="java"
                            theme="vs-dark"
                            height="500"
                            options={{ minimap: { enabled: false } }}
                            value={taskText + ""}
                        />
                    </Collapse.Panel>
                </Collapse>
            </Descriptions.Item>
        })
        return elem;
    }
    goBackPage() {
        let path = {
            pathname: '/securityBaseline/devicesInfo',
            bodyJson: this.props.location.bodyJson
        };
        this.props.history.push(path);
    }
    render = () => {
        let { taskData, loading, pageNum, pageSize, modalVisible, total, historyTableData, hisDetailVisible, hisDetailData } = this.state;
        const columns = [
            {
                title: '序号',
                dataIndex: 'ip',
                ellipsis: true,
                width: 50,
                render: (text, item, c) => { return c + 1 }
            },
            {
                title: '编号',
                dataIndex: 'number',
                ellipsis: true,
            },
            {
                title: '检查结果',
                dataIndex: 'result',
                ellipsis: true,
                render: (text, item, index) => {
                    let elemt = []
                    text.map((tag, ind) => {
                        switch (tag.code) {
                            case "0": elemt.push(<Tag key={tag.id} color="blue">{tag.value}</Tag>); break;
                            case "1": elemt.push(<Tag key={tag.id} color="red">{tag.value}</Tag>); break;
                            case "2": elemt.push(<Tag key={tag.id}>{tag.value}</Tag>); break;
                        }
                    })
                    return elemt
                }

            },
            {
                title: '分类',
                dataIndex: 'type',
                ellipsis: true,
            },
            {
                title: '描述',
                dataIndex: 'description',
                ellipsis: true,
            },
            {
                title: '操作',
                width: 180,
                render: (text, item) => {
                    return <div>
                        <Button type="primary" icon="edit" size="small" onClick={() => { this.openModal(item) }}>详情</Button>
                    </div>
                }
            },
        ]

        const historyColumns = [
            {
                title: '序号',
                dataIndex: 'key',
                ellipsis: true,
                width: 50,
                render: (text, item, c) => { return <span>{c + 1 + (pageNum - 1) * pageSize}</span> }
            },
            {
                title: '设备IP',
                dataIndex: 'd_ip',
                ellipsis: true,
            },
            {
                title: '执行方式',
                dataIndex: 'check_mode',
                ellipsis: true,
                render: (text) => {
                    switch (text) {
                        case "manual": return <span>手动</span>;
                        case "auto": return <span>自动</span>;
                    }
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                ellipsis: true,
                render: (text, item) => {
                    switch (text) {
                        case "error": return (<Tooltip title={item.reason}><Tag style={{ margin: 0 }} color="#f50">错误</Tag></Tooltip>)
                        case "abnormal": return (<Tooltip title={item.reason}><Tag style={{ margin: 0 }} color="#ffbe3d">异常</Tag></Tooltip>)
                        case "done": return (<Tooltip title={item.reason}><Tag style={{ margin: 0 }} color={Utils.ColorShow(text)}>合规</Tag></Tooltip>)
                        // case "run" : return (<Tooltip title={item.reason}><Tag style={{ margin: 0 }} color="#58a3f7">执行中</Tag></Tooltip>)
                        // case "new" : return (<Tooltip title={item.reason}><Tag style={{ margin: 0 }} color="#87CEFF">新建</Tag></Tooltip>)
                        default: return (<Tooltip title={item.reason}><Tag style={{ margin: 0 }} color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag></Tooltip>)
                    }
                }
            },
            {
                title: '检查时间',
                dataIndex: 'checkTime',
                ellipsis: true,
            },
            {
                title: '操作',
                width: 180,
                render: (text, item) => {
                    return <div>
                        <Button type="primary" icon="edit" size="small" onClick={() => { this.openModal(item, "historyDetail") }}>详情</Button>
                    </div>
                }
            },
        ]
        return (
            <div>
                <CustomBreadcrumb arr={[{ title: '首页', to: '/home' }, { title: '安全基线管理', to: '/securityBaseline' }, "设备详细信息"]} />
                <Card
                    style={{ padding: "5px 10px" }}
                    title={<span style={{ fontWeight: 600 }}>合规性检查历史记录</span>}
                    extra={<Button type="primary" icon="rollback" onClick={() => { this.goBackPage() }}>返回</Button>}
                >
                    <Table
                        dataSource={historyTableData}
                        columns={historyColumns}
                        loading={loading}
                        size="small"
                        rowKey={(text) => { return text._id }}
                        pagination={{
                            current: pageNum, pageSize: pageSize, total: total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.setState({ pageSize: size })
                                this.getData(1, size)
                            },
                            onChange: (page, pageSize) => {
                                this.getData(page, pageSize)
                            }

                        }}
                    />
                </Card>
                <Modal
                    visible={modalVisible}
                    onOk={() => { this.setState({ modalVisible: false, modalData: {} }) }}
                    onCancel={() => this.setState({ modalVisible: false, modalData: {} })}
                    width="80%"
                    destroyOnClose
                >
                    {taskData ?
                        <div className="checkData">
                            <Card title={<span style={{ fontWeight: 600 }}>设备检查数据</span>} style={{ marginTop: 5 }}>
                                {this.getDescriptionsData()}
                            </Card>
                        </div>
                        : ""}
                </Modal>
                <Modal
                    title={"设备检查历史数据"}
                    visible={hisDetailVisible}
                    onOk={() => { this.setState({ hisDetailVisible: false }) }}
                    onCancel={() => this.setState({ hisDetailVisible: false })}
                    width="80%"
                    destroyOnClose
                >
                    <Card style={{ marginTop: 5 }} title={<span style={{ fontWeight: 600 }}>设备检查数据</span>}>
                        <Table
                            dataSource={Array.isArray(hisDetailData.taskData) ? hisDetailData.taskData : []}
                            columns={columns}
                            size="small"
                            rowKey={(text) => { return text.number }}
                            pagination={false}
                        />
                    </Card>
                </Modal>
            </div>
        )
    };
}

const HistoryDevicesDetailFrom = Form.create()(HistoryDevicesDetail);
export default HistoryDevicesDetailFrom;
