

import React from 'react';
import {
    Chart,
    Interval,
    Tooltip,
    Axis,
    Coordinate,
    Interaction,
    Legend,
} from 'bizcharts';
const cols = {
    percent: {
        formatter: val => {
            val = Math.round((val * 100) * 1000) / 1000 + '%';
            return val;
        },
    },
};
export default class Pie extends React.Component {
    render() {
        const { data } = this.props;
        let keyVal = {};
        data.map((item, key) => {
            keyVal[item.name] = item.value;
        })
        return (
            <Chart forceFit={true} data={data} scale={cols} autoFit>
                <Coordinate type="theta" radius={0.85} />
                <Tooltip showTitle={false} />
                <Axis visible={false} />
                <Legend
                    position="right"
                    offsetX={-30}
                    itemName={{
                        style: {
                            shadowBlur: 1,
                            shadowColor: "rgba(0, 0, 0, .85)",
                        },
                        formatter: (val, item, c) => {
                            return val + ":" + keyVal[val];
                        }
                    }}
                />
                <Interval
                    position="percent"
                    adjust="stack"
                    color="name"
                    style={{
                        lineWidth: 1,
                        stroke: '#fff',
                    }}
                    label={['name', {
                        style: {
                            shadowBlur: 1,
                            shadowColor: "rgba(0, 0, 0, .85)",
                        },
                        content: (data) => {
                            if (data.percent === 0) {
                                return ""
                            }
                            return `${data.name}: ${Math.round((data.percent * 100) * 1000) / 1000}%`;
                        },
                    }]}
                />
                <Interaction type='element-single-selected' />
            </Chart>
        );
    }
}