import React, { Fragment } from 'react'
import { Typography, Input, Table, Modal, Select, message, Button, Drawer } from 'antd';


export default class TableShow extends React.Component {
    state = {
        tableData: this.props.tableData
    }
    componentWillReceiveProps(newProps) {
        this.setState({ tableData: newProps.tableData });
    }
    renderElemt() {
        let { tableData } = this.state;
        if (!tableData) return;
        let showData = [];
        // console.log(tableData);
        let chunk = 4; //每4个分一组
        for (let i = 0, j = tableData.length; i < j; i += chunk) {
            showData.push(tableData.slice(i, i + chunk));
        }
        // console.log(showData);
        return <table>
            <tbody>
                {/* {showData.map((itemArr, key) => {
                    <tr>
                        {itemArr.map((item, index) => {
                            return <td>{item.display}</td>
                        })}
                    </tr>
                })} */}
                {tableData.map((item, key) => {
                    return <tr key={key}>
                        <td>&nbsp;&nbsp;&nbsp;{item.display}</td>
                    </tr>
                })}
            </tbody>
        </table>
    }
    render() {
        return <div>{this.renderElemt()}</div>
    }

}
