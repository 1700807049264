/*
 * action 类型
 */

export const type = {
    SWITCH_MENU: 'SWITCH_MENU',
    NODE_DATA: 'NODE_DATA',
    NODE_FOCUS: 'NODE_FOCUS',
    OPEN_PARAM: 'OPEN_PARAM',
    IS_LOADING: 'IS_LOADING',
}

// 切换加载动画
export function isLoading(isloading) {
    return {
        type: type.IS_LOADING,
        isloading
    }
}
// 菜单点击切换，修改面包屑名称
export function switchMenu(menuName) {
    return {
        type: type.SWITCH_MENU,
        menuName
    }
}
// 导出节点数据
export function exportNodeData(NodeData) {
    return {
        type: type.NODE_DATA,
        NodeData
    }
}
// 流程图当前点击节点数据
export function focusNodeData(focusNodeData) {
    return {
        type: type.NODE_FOCUS,
        focusNodeData
    }
}