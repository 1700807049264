/**
 * reducer
 */

// import { combineReducers } from 'redux'
import { type } from '../action';
const ebikeData = (state, action) => {
    // console.log(state);
    switch (action.type) {
        case type.IS_LOADING:
            return {
                ...state,
                isloading: action.isloading
            };
        case type.SWITCH_MENU:
            return {
                ...state,
                menuName: action.menuName
            };
        case 'open_param':
            return {
                ...state,
                open_param: action.value
            };
        case type.NODE_DATA:
            return {
                ...state,
                NodeData: action.NodeData
            };
        case type.NODE_FOCUS:
            return {
                ...state,
                focusNodeData: action.focusNodeData
            };
        default:
            return { ...state };
    }
};

export default ebikeData;