import React from 'react'
import { Card, Table, Button, Icon, message, Menu, Modal, Typography, Select, Row, Col, Descriptions, } from "antd";
import socket from "@/socket/index";
import Utils from "@/utils/utils";
import AxiosObj from '@/axios/index';
import ReactDOM from 'react-dom';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import { MonacoDiffEditor, } from 'react-monaco-editor';
import MonacoEditor from 'react-monaco-editor';
import { exportWord } from "mhtml-to-word"
import $ from "jquery"
const { Text } = Typography;
const { confirm } = Modal;
import './index.scss';
let perDiv = null;
class BackData extends React.Component {
    state = {
        _current: 1,      //当前页码数
        _pageSize: 20,    //每页显示条数
        querys: {},    //查询条件
        total: 20,
        loading: false,
        visible_conn: false,
        selectedRowKeys: [],
        selectedRows: [],
        tableData: [],
        FileVis: false,
        ImportVis: false,
        detailModal: false,
        diffModal: false,
        detailLoading: false,
        detailData: "",
        fileName: "",
        cpuData: {},
        memoryData: {},
        cardData: {},
        onlineData: {},
        interData: {},
        resItem: {},
        firstConfig: "",
        secondConfig: "",
        WarnVis: false,
        deviceIp: "",
        baseConfig: "",
        itemData: {},
        deviceInfo: {},

    };
    componentWillMount() {
        const deviceInfo = JSON.parse(sessionStorage.getItem("device"));
        this.updata(null, null, { ip: deviceInfo.ip });
        this.getBaseConfig(deviceInfo.ip);
        this.setState({ deviceIp: deviceInfo.ip, deviceInfo })
    }
    updata = (pageSize, pageNum, querys) => {
        if (!querys.ip) {
            // message.error("IP错误！");
            let path = {
                pathname: `/configBackup/device`,
                bodyJson: this.props.location.bodyJson || {}
            }
            this.props.history.push(path);
            return;
        }
        this.setState({ loading: true, });
        let { _pageSize, _current, total } = this.state;
        let bodyJson = {
            "ip": querys.ip || "",
            "page_size": pageSize || _pageSize,
            "page_no": pageNum || _current
        };
        AxiosObj.HTTP_POST("backUp", "findConfigByIp", bodyJson, (result) => {
            // let tableData = Utils.DataAddItem(result.data);
            let tableData = result.data;
            let itemData = Utils.deepCopy(tableData[0]);
            this.setState({
                tableData, itemData,
                _current: pageNum || 1,
                total: result.count || total,
                loading: false,
            })
        });

    };
    getDetailData = (resItem) => {
        this.setState({
            detailData: resItem.config,
            detailModal: true
        })
    };
    getBaseConfig(ip) {
        if (!ip) {
            // message.error("IP错误！");
            return;
        }
        AxiosObj.HTTP_POST("backUp", "findBaselineByIp", { ip }, (result) => {
            this.setState({ baseConfig: result.baseline, baseConfig2: result.baseline });
        }, (error) => {
            Utils.showMessage("error", "查询失败", error);
        });
    }
    delete = (id) => {
        this.setState({ loading: true, });
        let { _pageSize, _current, deviceIp } = this.state;
        socket.ExecuteAction("DeleteResource", { _id: id }, "/delResource", () => {
            this.setState({ visible: false, loading: false, });
            this.updata(_pageSize, _current, { ip: deviceIp });
            message.success('删除成功');
        }, (error) => {
            message.error('删除失败' + error);
        });
    };
    handleMenuClick = (e, item) => {
        this.setState({ resItem: item });
        switch (e.key) {
            case 'conn': break;
            case 'edit':
                const { _current, _pageSize, querys } = this.state
                let bodyJson = {
                    pageNum: _current,      //当前页码数
                    pageSize: _pageSize,    //每页显示条数
                    querys,    //查询条件
                }
                let path = {
                    pathname: `/newResource:${item._id}`,
                    bodyJson,
                };
                this.props.history.push(path);
                break;
            case 'del': this.delete(item._id); break;
            case 'monitor': this.warnDetail(item); break;
            case 'copy': this.props.history.push(`/newResource:${item._id}&copy`); break;
            case 'config':
                let path2 = {
                    pathname: `/ftpFile`,
                    deviceIp: item.resourceParams.deviceIp,
                };
                this.props.history.push(path2); break;
        }
    }
    warnDetail(item) {
        let path = {
            pathname: '/resMonitor',
            deviceIp: item.resourceParams.deviceIp,
        };
        this.props.history.push(path);
    }
    menuButton(resItem) {
        return <Menu onClick={(e) => this.handleMenuClick(e, resItem)}>
            {/* <Menu.Item key="monitor">
                <Icon type="eye" />
                监控
      </Menu.Item> */}
            <Menu.Item key="config">
                <Icon type="file" />
                配置
      </Menu.Item>
            <Menu.Item key="copy">
                <Icon type="copy" />
                复制
      </Menu.Item>
            <Menu.Item key="edit">
                <Icon type="form" />
                编辑
      </Menu.Item>
            <Menu.Item key="del">
                <Icon type="delete" />
                删除
      </Menu.Item>
        </Menu>
    }
    compareText() {
        let { selectedRowKeys, selectedRows } = this.state;
        if (selectedRowKeys.length === 2) {

            let first = selectedRows[0];
            let second = selectedRows[1];
            this.configCompareByback(first.config, second.config);
            this.setState({
                firstTime: first.create_time,
                secondTime: second.create_time,
                firstConfig: first.config,
                secondConfig: second.config,
                diffModal: true
            });
        } else {
            message.error("请选择两条记录");
        }

    }
    getConfigs() {
        let { selectedRowKeys, selectedRows, deviceInfo } = this.state;
        if (Utils.isEmpty(selectedRowKeys)) {
            message.error("请选择要导出的记录");
            return;
        }
        let ZipData = [];
        const devIP = deviceInfo.ip;
        // const devIP = (deviceInfo.ip).replace(/\./g, "_");
        selectedRows.map((str) => {
            let data = str;
            let time = (data.create_time.replace(new RegExp(" ", "g"), "_")).replace(new RegExp(":", "g"), "_");
            // let name = devIP + "_" + time;
            let name = (str.file_name || deviceInfo.deviceId || devIP || "") + "-" + time;
            ZipData.push({ name, data: data.config })
        })
        Utils.downloadZIP(devIP + "配置数据", ZipData);
    }
    baseConfigCompare(item) {
        let { baseConfig } = this.state;
        this.configCompareByback(item.config, baseConfig);
        this.setState({
            diffModal: true,
            firstTime: item.create_time, secondTime: "基线配置",
            firstConfig: item.config, secondConfig: baseConfig
        })
    }
    async configCompareByback(config_one, config_two, callback) {
        // AxiosObj.HTTP_POST("backUp", "configCompare", {
        //     config_one: config_one || "", config_two: config_two || ""
        // }, (result) => {
        //     const diff_result = result.diff_result || {};
        //     this.setState({
        //         config_one_diff: diff_result.config_one_diff || "",
        //         config_two_diff: diff_result.config_two_diff || "",
        //         diff_lines: diff_result.diff_lines || []
        //     });
        // });
        const data = await AxiosObj.ASYNC_POST("backUp", "configCompare", {
            config_one: config_one || "", config_two: config_two || ""
        });
        const diff_result = data.diff_result || {};
        this.setState({
            config_one_diff: diff_result.config_one_diff || "",
            config_two_diff: diff_result.config_two_diff || "",
            diff_lines: diff_result.diff_lines || []
        });
        if (callback) {
            callback(diff_result);
        }
    }
    setBaseLine(flag) {
        let { detailData, selectedRowKeys, selectedRows, deviceInfo } = this.state;
        if (flag) {
            if (selectedRowKeys.length > 1 || selectedRowKeys.length === 0) {
                message.error("请选择一条设备！");
                return;
            } else {
                detailData = selectedRows[0].config;
            }
        }
        AxiosObj.HTTP_POST("backUp", "configToBaseline", { ip: deviceInfo.ip || "", config: detailData }, (result) => {
            this.setState({ detailModal: false, baseConfig: detailData });
            Utils.showMessage("success", "设置成功", result.msg);
        }, (error) => {
            Utils.showMessage("error", "设置失败", error);
            this.setState({ detailModal: false });
        });
    }
    ExportDiff() {
        const { config_one_diff, config_two_diff } = this.state;
        const one_diff_List = config_one_diff.split("\n");
        const two_diff_List = config_two_diff.split("\n");
        let conHTML = '<table  width="100%" border="1" cellspacing="0" cellpadding="0"><tbody>';
        one_diff_List.map((item, key) => {
            conHTML += '<tr><td style="background-color:rgb(255, 166, 166); font-weight:600;width:50%;border:1px solid #000">'
                + item + '</td><td style="border:1px solid #000">' + two_diff_List[key] + '</td></tr>'
        })
        conHTML += '</tbody></table>';
        exportWord({
            mhtml: conHTML,
            data: { title: "exportword" },
            filename: "差异报告",
            style: ''
        })
    }
    getOverViews(diff_lines = [], text, _editor) {
        let lineNumber = diff_lines;
        const editor = _editor || this.editor;
        const { firstConfig } = this.state;
        if (editor) {
            const temp = editor.getLineChanges();
            // console.log(temp);
            // if (temp && temp[0]["modifiedStartLineNumber"] != 1) {
            if (temp) {
                lineNumber = temp.map((item) => {
                    return item["originalStartLineNumber"];
                });
            }
        } else {
            return
        }
        // const list = text.split("\n");
        const sourceData = firstConfig.split("\n");
        // console.log(sourceData);
        // console.log(lineNumber);
        // console.log(diff_lines);


        // const plist = list.map((item, key) => {
        //     if (item === "" && key === list.length - 1) {
        //         return <div key={key} />
        //     }
        //     let e;
        //     if (!Utils.isEmpty(lineNumber) && lineNumber[key]) {
        //         e = lineNumber[key];
        //         // e = diff_lines[key];
        //     }
        //     if (!e) { return null }
        //     return <div key={key} id={"id" + key} className={"overDiv"}
        //         onClick={() => this.gotoLine(e, "id" + key, editor)}>
        //         {e + "----" + item}</div>;
        // });
        const plist = lineNumber.map((e, key) => {
            return <div key={key} id={"id" + key} className={"overDiv"}
                onClick={() => this.gotoLine(e, "id" + key, editor)}>
                {e + "----" + sourceData[e - 1]}</div>;
        })

        if (document.getElementById('overView')) {
            ReactDOM.render(<div className={"conentDiv"}>{plist}</div>, document.getElementById('overView'));
        }

        return <div className={"conentDiv"}>{plist}</div>;
    }
    gotoLine(num, divId, editor) {
        if (!num) { messages.error("行数错误！"); return; };
        let clickdiv = document.getElementById(divId);
        if (perDiv) { perDiv.style.backgroundColor = ''; perDiv.style.border = ''; }
        clickdiv.style.backgroundColor = 'rgb(255, 204, 203)';
        clickdiv.style.border = '1px solid #000';
        perDiv = clickdiv;
        let pos = {
            lineNumber: num,
            column: 1
        }
        // this.editor.revealLine(num);
        editor.revealLineInCenter(num, num + 1);
        editor.setPosition(pos)
        editor.focus();

        // let rang = {
        //     startLineNumber: num,
        //     startColumn: 1,
        //     endLineNumber: num + 1,
        //     endColumn: 1
        // }

        // this.editor.revealRangeInCenter(rang)

        // console.log(this.editor.getLineChanges());
    }
    render() {
        let { _current, _pageSize, detailData, detailModal, diffModal, tableData, baseConfig, itemData, deviceInfo, diff_lines, config_one_diff, editor } = this.state;

        const _this = this;
        const columns = [
            {
                title: '序号',
                align: 'center',
                render: (a, b, text) => {
                    return <span>{text + 1 + (_current - 1) * _pageSize}</span>
                }
            },
            {
                title: '时间',
                align: 'left',
                dataIndex: 'create_time',
            },
            {
                title: '设备IP',
                align: 'left',
                dataIndex: 'ip',
            },
            {
                title: '备份账户',
                dataIndex: 'sysname',
                align: "left",
            },
            {
                title: '最近一次是否有变更',
                dataIndex: 'changed',
                align: "center",
                render: (text, item) => {
                    return text ? <Button type="danger" size="small" style={{ background: "#ed4014", color: "#fff" }}
                        icon="issues-close"
                        title="查看差异信息"
                        onClick={() => {
                            _this.configCompareByback(item.config, item.changed_config, (diff_result) => {
                                console.log(diff_result);
                                _this.setState({ firstConfig: item.config })
                                confirm({
                                    title: "与" + item.changed_time + "备份对比差异",
                                    icon: "file-text",
                                    style: { top: 50 },
                                    maskClosable: true,
                                    content: <div className="baseConfigRow">
                                        <Row >
                                            <Col span={6}>
                                                <div style={{ marginBottom: "8px", textAlign: "center", width: "100%", }}><Text strong >变更概览</Text></div><hr />
                                                {/* {_this.getOverViews(diff_result.diff_lines, diff_result.config_one_diff || "")} */}
                                                <div id="overView" />
                                            </Col>
                                            <Col span={18}>
                                                <div style={{ marginBottom: "8px", textAlign: "center", width: "100%" }}>
                                                    <Text strong >时间:{item.create_time}</Text>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Text strong >上次备份时间:{item.changed_time}</Text>
                                                </div>
                                                <MonacoDiffEditor
                                                    height="600"
                                                    language="java"
                                                    theme={"vs"}
                                                    options={{ readOnly: true }}
                                                    original={item.config}
                                                    editorDidMount={(editor) => {
                                                        _this.changeEdit = editor;
                                                        setTimeout(() => {
                                                            _this.getOverViews(diff_result.diff_lines, diff_result.config_one_diff || "", editor)
                                                        }, 500)
                                                    }}
                                                    value={item.changed_config} />
                                            </Col>
                                        </Row>
                                    </div>,
                                    width: "100%"

                                })
                            });
                        }}
                    ></Button> : ""
                }
            },
            {
                title: '备份数据操作',
                align: 'center',
                // width: "80px",
                render: (text, item) => {


                    return <div>
                        {/* <Dropdown overlay={this.menuButton(item)}> */}
                        <Button type="primary" size="small" icon="form" onClick={() => { this.getDetailData(item) }}>
                            详情
                        </Button>&nbsp;
                        <Button type="primary" size="small" icon="check-square" onClick={() => { this.baseConfigCompare(item) }}>
                            基线对比
                        </Button>
                        {/* </Dropdown>*/}
                    </div>
                }
            }
        ];
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys, selectedRows });
            },
        };
        const params = [
            ["设备IP", "ip"],
            ["设备厂商", "deviceFirm"],
            ["设备名称", "deviceId"],
            ["设备类型", "deviceType"],
            ["设备型号", "deviceVersion"],
            ["设备OS版本", "deviceOsVersion"],
            ["设备OS类型", "deviceOsVersion"],
            ["网点信息", "deviceSite"],
        ];
        return (
            <div>
                <CustomBreadcrumb arr={["运维管理", { title: "配置备份", to: "/configBackup/device" }, { title: "备份数据", to: "/configBackup/BackData" }]} />
                {/* <Card>
                    <FilterForm
                        tagClass="resource"
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(this.state._pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(this.state._pageSize, 1, {})
                        }}             
                    />
                </Card> */}
                <Card title={<span style={{ fontWeight: 600 }}>备份数据</span>}
                    style={{ marginTop: 8 }}
                    extra={<div>
                        <Button type="primary" icon="export" onClick={() => {
                            // let time = (item.create_time.split(" "))[0];
                            // Utils.download("配置数据" + time + ".txt", item.config);
                            this.getConfigs();
                        }}>
                            导出配置
                        </Button>
                        <Button type="primary" icon={'setting'}
                            onClick={() => {
                                const _this = this;
                                confirm({
                                    title: "提示信息",
                                    content: "确定设置基线吗？",
                                    onOk() {
                                        _this.setBaseLine(true)
                                    }
                                })
                            }}>设为基线</Button>
                        <Button type="primary" icon={'setting'}
                            onClick={() => {
                                const _this = this;
                                const sysname = sessionStorage.getItem('userName') + "/" + sessionStorage.getItem('nickName');
                                let tempBaseConfig = "";
                                const modalObj = Modal.confirm({
                                    title: <div>{itemData.ip}基线配置数据&nbsp;&nbsp;<Button type="primary" icon="file-text" onClick={() => {
                                        _this.props.history.push("/configBackup/BaseLineData");
                                        modalObj.destroy();
                                    }}>基线更改记录</Button></div>,
                                    content: <div style={{ paddingTop: 10 }}>
                                        <MonacoEditor onChange={(newValue) => {
                                            tempBaseConfig = newValue;
                                        }}
                                            value={baseConfig || ""} height="600" theme="vs" language="java" /></div>,
                                    width: "80%",
                                    maskClosable: true,
                                    okText: "更新基线",
                                    okType: 'danger',
                                    icon: <Icon type="file-markdown" />,
                                    onOk() {
                                        Modal.confirm({
                                            title: "消息提醒",
                                            content: "你确定更新基线吗？",
                                            okType: 'danger',
                                            onOk() {
                                                AxiosObj.HTTP_POST("backUp", "updateBaselineByIp", { ip: itemData.ip, baseline: tempBaseConfig, sysname }, (result) => {
                                                    Utils.showMessage("success", "更新成功", result.msg);
                                                    _this.setState({ baseConfig: tempBaseConfig });
                                                });
                                            },
                                        });
                                    },
                                });

                            }}>基线配置</Button>
                        <Button type="primary" icon={'swap'}
                            onClick={() => {
                                this.compareText()
                            }}>差异对比</Button>
                        <Button type="primary" icon={'rollback'}
                            onClick={() => {
                                let path = {
                                    pathname: `/configBackup/device`,
                                    bodyJson: this.props.location.bodyJson,
                                };
                                this.props.history.push(path);
                            }}>返回</Button>
                    </div>}>
                    <Descriptions bordered size="small">
                        {params.map((rec, m) => {
                            return <Descriptions.Item key={m} label={rec[0]}>{Utils.hidenLongText(deviceInfo[rec[1]], 30)}</Descriptions.Item>
                        })}
                    </Descriptions><br />
                    <Table
                        dataSource={this.state.tableData}
                        rowSelection={rowSelection}
                        columns={columns}
                        loading={this.state.loading}
                        rowKey={(text) => text._id}
                        // rowKey={(text) => { return JSON.stringify({ flag: new Date().getTime(), time: text.create_time, config: text.config, }) }}
                        size="small"
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.setState({ _pageSize: size });
                                this.updata(size, 1, { ip: this.state.deviceIp })
                            },
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                this.updata(pageSize, page, { ip: this.state.deviceIp });
                            }
                        }}
                    />

                </Card>
                <Modal
                    width="80%"
                    title={<div>配置信息&nbsp;&nbsp;<Button onClick={() => {
                        const _this = this;
                        confirm({
                            title: "提示信息",
                            content: "确定设置基线吗？",
                            onOk() {
                                _this.setBaseLine()
                            }
                        })
                    }}
                        type="primary" icon="setting">设置为基线配置</Button>&nbsp;&nbsp;
                        {itemData ? <span><Text strong>(设备IP:{itemData.ip}</Text>&nbsp;&nbsp;
                            <Text strong>设备厂商:{itemData.company})</Text></span> : ""}
                    </div>}
                    visible={detailModal}
                    onCancel={() => this.setState({ detailModal: false })}
                    onOk={() => this.setState({ detailModal: false })}
                    style={{ top: "20px" }}
                    destroyOnClose
                >
                    <MonacoEditor
                        style={{ margin: 10 }}
                        width="99%"
                        height="700"
                        language="java"
                        theme={"vs"}
                        value={detailData}
                        options={{ selectOnLineNumbers: false, readOnly: true }}
                    />
                </Modal>
                <Modal
                    width="100%"
                    title={<div>差异对比
                        {/* &nbsp;&nbsp;&nbsp;<Button type="primary" icon="export" onClick={() => this.ExportDiff()}>导出差异</Button> */}
                    &nbsp;&nbsp;&nbsp;<Button type="primary" icon="setting" onClick={() => {
                            const _this = this;
                            Modal.confirm({
                                title: "提示信息",
                                content: "是否确定设置基线配置？",
                                okType: "danger",
                                onOk() {
                                    AxiosObj.HTTP_POST("backUp", "configToBaseline",
                                        { ip: deviceInfo.ip || "", config: _this.state.firstConfig }, (result) => {
                                            _this.setState({ baseConfig: _this.state.firstConfig });
                                            Utils.showMessage("success", "设置成功", result.msg);
                                        });
                                }
                            })
                        }} ghost>设置{this.state.firstTime}为基线配置</Button>
                    </div>}
                    visible={diffModal}
                    onCancel={() => this.setState({ diffModal: false })}
                    onOk={() => this.setState({ diffModal: false })}
                    style={{ top: "20px" }}
                    // destroyOnClose
                    forceRender
                >
                    <div className="baseConfigRow">
                        <Row >
                            <Col span={6}>
                                <div style={{ marginBottom: "8px", textAlign: "center", width: "100%", marginTop: 11 }}><Text strong >变更概览</Text></div><hr />
                                {/* <div id="overView" /> */}
                                {this.getOverViews(diff_lines, this.state.config_one_diff || "")}
                            </Col>
                            <Col span={18}>
                                <div>
                                    <div style={{ marginBottom: "8px", textAlign: "center", width: "100%" }}>

                                        <Text strong >
                                            {/* {this.state.firstTime}&nbsp;&nbsp; {this.state.secondTime} */}

                                对比时间:
                            <Select className="timeSelect" value={this.state.firstTime} showSearch onChange={(val) => {
                                                let tempItem = JSON.parse(val);
                                                this.configCompareByback(tempItem.config, this.state.secondConfig || "");
                                                this.setState({ firstTime: tempItem.create_time, firstConfig: tempItem.config });
                                            }}>
                                                {tableData.map((item, key) => {
                                                    return <Select.Option key={key} value={JSON.stringify(item)}>{item.create_time}</Select.Option>
                                                })}
                                                <Select.Option key={"z0"} value={JSON.stringify({ create_time: "基线配置", config: baseConfig })}>基线配置</Select.Option>

                                            </Select>
                            对比时间:
                            <Select className="timeSelect" value={this.state.secondTime} showSearch onChange={(val) => {
                                                let tempItem = JSON.parse(val);
                                                this.configCompareByback(this.state.firstConfig || "", tempItem.config);
                                                this.setState({ secondTime: tempItem.create_time, secondConfig: tempItem.config });
                                            }}>
                                                {tableData.map((item, key) => {
                                                    return <Select.Option key={key} value={JSON.stringify(item)}>{item.create_time}</Select.Option>
                                                })}
                                                <Select.Option key={"z1"} value={JSON.stringify({ create_time: "基线配置", config: baseConfig })}>基线配置</Select.Option>
                                            </Select>
                                        </Text>
                                    </div><hr />
                                    <MonacoDiffEditor
                                        height="600"
                                        language="java"
                                        theme={"vs"}
                                        options={{ readOnly: true }}
                                        original={this.state.firstConfig}
                                        editorDidMount={(editor) => {
                                            this.editor = editor;

                                            // setTimeout(() => {
                                            //     _this.getOverViews(diff_lines, this.state.config_one_diff || "", editor);
                                            // }, 1000)
                                            // this.setState({ editor })
                                            // let rang = {
                                            //     startLineNumber: 280,
                                            //     startColumn: 1,
                                            //     endLineNumber: 281,
                                            //     endColumn: 1
                                            // }

                                            // editor.revealRangeInCenter(rang)
                                            // editor.revealLine
                                            // console.log(editor.getLineChanges());
                                            // console.log(editor.getDiffLineInformationForModified());
                                            // console.log(editor.getDiffLineInformationForModified());
                                            // let pos = {
                                            //     lineNumber: 282,
                                            //     column: 1
                                            // }
                                            // editor.revealLine(282);
                                            // editor.setPosition(pos)
                                            // editor.focus();
                                        }}
                                        value={this.state.secondConfig} />
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        )
    }
}


export default BackData