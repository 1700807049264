
import React from "react"
import { Cascader, Typography, Tree, Icon } from "antd"
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
const { TreeNode } = Tree
// const options2 = [
//     {
//         title: "深圳", key: "sz", children: [
//             { title: "南山", key: "ns" },
//             { title: "宝安", key: "ba" },
//         ]
//     },
//     { title: "广州", key: "gz" },
// ]
export default class CascOrgin extends React.Component {
    state = {
        options: [],
        TreeData: [],
        defaultValue: [],
        expandedKeys: [],
        fold: false,
    }
    componentDidMount() {
        this.getMenuData();
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.treeData) {
            let expandedKeys = nextProps.treeData.map((i) => i.key)
            this.setState({ expandedKeys })
        }
    }
    getMenuData = () => {
        Socket.ExecuteAction('QueryArea', {}, "", (result) => {
            const TreeData = result.data;
            const options = this.greatData(TreeData);
            let defList = [];
            if (this.props.value) {
                defList = this.gets(this.props.value, TreeData);
            } else {
                // defList = [];
                // const getDefaule = (List) => {
                //     defList.push(List[0].value);
                //     if (List[0].children) {
                //         getDefaule(List[0].children);
                //     }
                // }
                // getDefaule(options);

                // options.map((it, index) => {
                //     defList = Utils.ArrayPushRequired(defList, it.value);
                // });
            }
            let expandedKeys = TreeData.map((i) => i.key)
            this.props.getTreeData ? this.props.getTreeData(TreeData) : ''
            this.setState({
                TreeData,
                options,
                expandedKeys,
                defaultValue: defList
            })
        });
    };

    greatData = (data) => {
        const newList = [];
        data.map((item) => {
            let obj = {
                value: item.key,
                label: item.title,
            }
            if (item.children) {
                obj.children = this.greatData(item.children);
            }
            newList.push(obj);
        })
        return newList
    }
    onChange = (value, selectedOptions) => {
        this.setState({ defaultValue: value })
        this.props.onChange(value, selectedOptions);
    }
    filter = (inputValue, path) => {
        return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }
    getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some(item => item.key === key)) {
                    parentKey = node.key;
                } else if (this.getParentKey(key, node.children)) {
                    parentKey = this.getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    getAllParentKey = (key, TreeData) => {
        let parentKey = this.getParentKey(key, TreeData);
        if (parentKey) {
            return parentKey = this.getAllParentKey(parentKey, TreeData) + "@" + parentKey;
        } else {
            return ""
        }
    }
    gets = (value, TreeData) => {
        let list = this.getAllParentKey(value, TreeData);
        let newList = [];
        if (list) {
            newList = list.split("@");
        }
        newList = newList.filter((item) => { return item != "" });
        newList.push(value);
        return newList;
    }
    onSelect = (selectedKeys, info) => { //点击树节点触发  
        this.setState({ selectedKeys });
        this.props.onChange(selectedKeys, info.node.props.title, info.node.props);
    }
    renderTreeNodes = data => data.map((item) => {
        if (item.children) {
            return (
                <TreeNode title={item.title} key={item.key} dataRef={item}>
                    {this.renderTreeNodes(item.children)}
                </TreeNode>
            );
        }
        return <TreeNode {...item} />;
    })
    onExpand = (expandedKeys) => {  //展开/收起节点时触发 
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };
    menuFold() {
        const fold = !this.state.fold;
        this.setState({ fold });
        this.props.foldChange(fold);
    }
    render() {
        const { options, defaultValue, TreeData, fold } = this.state;
        let { treeData } = this.props;
        return <div style={{ paddingBottom: 5, width: "100%" }}>
            {this.props.type === "add" ?
                <Cascader changeOnSelect
                    style={this.props.style}
                    options={options}
                    value={defaultValue}
                    onChange={this.onChange}
                    placeholder="请选择"
                    showSearch={this.filter}
                /> :
                <div style={{ padding: 8, }}>
                    {fold ? <Icon type="menu-unfold" onClick={() => { this.menuFold() }} /> :
                        <Typography.Text strong><Icon type="menu-fold" onClick={() => { this.menuFold() }} />&nbsp;组织区域:</Typography.Text>}
                    {fold ? null : <Tree
                        onExpand={this.onExpand}
                        showLine
                        expandedKeys={this.state.expandedKeys || defaultValue}
                        autoExpandParent={true}
                        defaultExpandAll={true}
                        onSelect={this.onSelect}
                        selectedKeys={this.state.selectedKeys || defaultValue}
                    >
                        {this.renderTreeNodes(treeData ? treeData : TreeData)}
                    </Tree>}
                </div>

            }
        </div>
    }

}