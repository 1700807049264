import React from 'react'
import { Card, Table, Button, Icon, Input, message, notification, Popconfirm, Tag, Modal } from "antd";
import socket from "@/socket/index";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import FileModal from '@/components/FileModal/index';
import http from '@/axios/axios_utils';
import api from '@/config/api/fireWall';
export default class FireWallMan extends React.Component {
    state = {
        loading: false,
        FileVis: false,
        modalParam: null,
        tableData: []
    };
    componentWillMount() {
        this.updata();
    }
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        socket.ExecuteAction("QueryFwConfigs",
            bodyJson, "/QueryFwConfigs", (result) => {
                let tableData = result.data || [];
                tableData.map((item, index) => { item.key = index; return item });
                this.setState({
                    tableData,
                    _current: pageNum || 1,
                    total: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };
    openModal(title, APIName, btnName) {
        const modalParam = {
            title,
            APIName,
            btnName,
        }
        this.setState({ modalParam, FileVis: true })
    }
    readFilesChange(data) {
        console.log(data)
        this.updata()
    }
    render() {
        let { tableData, loading, FileVis, modalParam } = this.state;

        const columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                render: (text, A, C) => { return <span>{C + 1}</span> }
            }, {
                title: '防火墙IP',
                align: 'center',
                dataIndex: 'firewallIp'
            }, {
                title: '对应网段',
                align: 'center',
                dataIndex: 'network',
            }, {
                title: '地址',
                align: 'center',
                dataIndex: 'area',
            }, {
                title: '用途',
                align: 'center',
                dataIndex: 'use',
            },
        ];
        return (
            <div>
                <CustomBreadcrumb arr={["防火墙管理", "网段配置"]} />
                <Card title={<span style={{ fontWeight: 600 }}>防火墙网段配置列表</span>}
                    extra={<div>
                        <Button type="primary" icon="import" onClick={() => {
                            this.openModal("上传网段配置", "", "确定");
                        }}>上传防火墙配置</Button> </div>}
                    style={{ marginTop: 8 }}>
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        size="small"
                        loading={loading}
                        pagination={false}
                    />
                </Card>
                <FileModal FileVis={FileVis}  {...modalParam}
                    onCancel={() => this.setState({ FileVis: false })}
                    readFilesChange={(data) => this.readFilesChange(data)}
                />
            </div>
        )
    }
}
