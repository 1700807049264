const config = {
  401: {
    img: '/image/401.svg',
    title: '401',
    desc: '抱歉，你无权访问该页面',
  },
  403: {
    img: '/image/403.svg',
    title: '403',
    desc: '抱歉，你无权访问该页面',
  },
  404: {
    img: '/image/404.png',
    // img: 'https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg',
    title: '404',
    desc: '抱歉，你访问的页面不存在',
  },
  500: {
    img: '/image/500.svg',
    title: '500',
    desc: '抱歉，服务器出错了',
  },
};

export default config;
