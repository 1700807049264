import React, { useState, useEffect } from 'react'
import {
    Card, Table, Modal, Icon, Button, message, Badge, Dropdown, Menu, DatePicker, List, Avatar, Divider, Drawer, Descriptions, Spin
} from "antd";
const { RangePicker } = DatePicker;
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import axios_req from 'axios';
import MonacoEditor from 'react-monaco-editor';
import { getAgentAddress, getContainers, getSysAddress, getlogs, startContainers, stopContainers, restartContainers } from "../utils/api";
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import Utils from '@/utils/utils'
import moment from 'moment';
import "./index.scss"
import "animate.css"
import { typeOf } from '@jiaminghi/data-view-react/lib/style-inject.es-55d78dad';
//告警记录
function Containers(props) {
    const [infoVisible, setInfoVisible] = useState(false);
    const [textload, setTextload] = useState(false);
    const [notifyWayVis, setNotifyWayVis] = useState(false);
    const [recordStatus, setRecordStatus] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalData, setModalData] = useState({});
    const [total, setTotal] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const [selectItem, setSelectItem] = useState({});
    const [selectedRowKeys, setRowKeys] = useState([])
    const [tableData, setTabData] = useState([])
    const [logtext, setLogtext] = useState("")
    const [logVis, setLogVis] = useState(false);

    useEffect(() => {//componentDidMount;
        getData();
        return () => { //componentWillUnmount
        }
    }, []);
    function agentFunction(param, callback) {
        console.log(getAgentAddress())
        console.log(param)
        props.dispatch(isLoading(true));
        axios_req.post(getAgentAddress(), param,).then((r) => {
            console.log(r)
            props.dispatch(isLoading(false));
            if (r.status === 200) {
                callback(r.data);
            } else {
                message.error(r.status)
                message.error(r.data)
            }

        })
    }
    function getData() {

        const param = {
            method: "get",
            url: getContainers(),
            headers: null,
            data: {}
        }
        agentFunction(param, (data) => {
            setTabData(data)
        })
    }
    function getInfoDiv() {

        return <div className="containerInfoDiv">
            <Descriptions bordered title="基本信息" size="small" column={1}>
                <Descriptions.Item label="容器名称">{selectItem["Names"]}</Descriptions.Item>
                <Descriptions.Item label="容器ID">{selectItem["Id"]}</Descriptions.Item>
                <Descriptions.Item label="容器镜像">{selectItem["Image"]}</Descriptions.Item>
                <Descriptions.Item label="创建时间">{moment(selectItem["Created"] * 1000).format("YYYY-MM-DD HH:mm:ss")}</Descriptions.Item>
                <Descriptions.Item label="状态">{selectItem["State"] == "running" ?
                    <Badge status="success" text={selectItem["State"]} /> :
                    <Badge status="error" text={selectItem["State"]} />}
                </Descriptions.Item>
                <Descriptions.Item label="状态时长">{selectItem["Status"]}</Descriptions.Item>
                <Descriptions.Item label="端口映射">

                    {selectItem["Ports"] && selectItem["Ports"]["length"] > 0 ? <Table
                        columns={[{ title: "容器端口", dataIndex: "PrivatePort" }, { title: "主机端口", dataIndex: "PublicPort" }, { title: "类型", dataIndex: "Type", width: 50 }]}
                        dataSource={selectItem["Ports"]}
                        size="small"
                        pagination={false}
                    /> : ""}
                </Descriptions.Item>
                <Descriptions.Item label="数据卷(挂载目录)">
                    {selectItem["Mounts"] && selectItem["Mounts"]["length"] > 0 ? <Table
                        columns={[{ title: "容器路径", dataIndex: "Source" }, { title: "主机路径", dataIndex: "Destination" }, { title: "模式", dataIndex: "Mode", width: 50 }]}
                        dataSource={selectItem["Mounts"]}
                        size="small"
                        pagination={false}
                    /> : ""}
                </Descriptions.Item>
            </Descriptions>
        </div>
    }
    function http_get_log(item, since = (parseInt(moment().valueOf() / 1000) - 86400), until = parseInt(moment().valueOf() / 1000)) {
        setLogtext("")
        setTextload(true)
        const param = {
            method: "get",
            url: getlogs(item["Id"], since, until),
            headers: null,
            data: {}
        }
        agentFunction(param, (data) => {
            console.log(data)
            if (typeof data === "object" && data.message) {
                message.error(data.message)
                setTextload(false)
            } else {
                const r_data = data.replaceAll("\u001b[1m", "").replaceAll("\u001b[31m", "").replaceAll("\u001b[32m", "")
                    .replaceAll("\u001b[36m", "").replaceAll("\u001b[0m", "")
                    .replace(/\u0000/g, '').replace(/\u0001/g, '')
                setLogtext(r_data)
                setTextload(false)
            }
        })

    }
    function http_action(h_url) {
        const param = {
            method: "post",
            url: h_url,
            headers: null,
            data: {}
        }
        agentFunction(param, (data) => {
            console.log(data)
            message.success("操作成功!")
            getData()
        })
    }
    function downloadLog() {
        console.log(selectItem)
        let name_str = selectItem["Names"][0].replaceAll("/", "")
        let ZipData = [{ name: name_str, data: logtext }]
        Utils.downloadZIP(name_str, ZipData);
    }
    function getMenu(item) {
        const menu = (
            <Menu onClick={({ key }) => {
                // message.info(`Click on item ${key}`);
                switch (key) {
                    case "edit": setSelectItem(item); setInfoVisible(true); break;
                    case "start":
                        Modal.confirm({
                            title: `提示`,
                            content: `确定启动${item["Names"]}容器吗?`,
                            onOk() { http_action(startContainers(item["Id"])) },
                            onCancel() { },
                        });
                        break;
                    case "reload":
                        Modal.confirm({
                            title: `该操作危险`,
                            content: `该操作危险，确定重启${item["Names"]}容器吗?`,
                            onOk() { http_action(restartContainers(item["Id"])) },
                            onCancel() { },
                        });
                        break;
                    case "pause-circle":
                        Modal.confirm({
                            title: `该操作危险`,
                            content: `该操作危险，确定停止${item["Names"]}容器吗?`,
                            onOk() { http_action(stopContainers(item["Id"])) },
                            onCancel() { },
                        }); break;
                }
            }}>
                <Menu.Item key="edit" ><Icon type="edit" />详情</Menu.Item>
                {item["State"] === "running" ? <Menu.Item key="reload"><Icon type="reload" />重启</Menu.Item>
                    : <Menu.Item key="start"><Icon type="play-circle" />启动</Menu.Item>}
                {item["State"] === "running" ? <Menu.Item key="pause-circle"><Icon type="pause-circle" />停止</Menu.Item> : null}

            </Menu>
        );
        return menu
    }
    return <div className="dockerContainers">
        <CustomBreadcrumb arr={["容器管理", "容器列表"]} />
        <Card className="dockerContainersCard" title="容器列表" extra={<div>
            <Button type="primary" icon="reload" onClick={() => { getData() }}>刷新</Button>
        </div>}>
            <div className="containersDiv">
                <List
                    header={<div className="listTile">docker容器信息<Divider type="vertical" />总计:{tableData.length}个容器</div>}
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={tableData}
                    renderItem={item => (
                        <List.Item
                            actions={[
                                <Dropdown.Button className="dropDownBtn" type='primary' size="small" icon={<Icon type="down" />} style={{ marginRight: 8 }}
                                    onClick={() => { http_get_log(item); setSelectItem(item); setLogVis(true) }}
                                    overlay={getMenu(item)}
                                > <Icon type='file-text' />日志
                                </Dropdown.Button>
                            ]}
                        >
                            <List.Item.Meta
                                avatar={
                                    item.State == "running" ?
                                        <Avatar style={{ backgroundColor: '#87d068' }} size="default" icon="check" alt={item.State} /> :
                                        <Avatar style={{ backgroundColor: "#f50" }} size="default" icon="close" alt={item.State} />
                                }
                                title={<a onClick={() => { setSelectItem(item); setInfoVisible(true) }}>{item.Names}</a>}
                                description={(item.Ports || []).map((port) => {
                                    if (port.PublicPort) {
                                        const url = `${getSysAddress()}:${port.PublicPort}`
                                        return <a href={url} target="_blank">{`${port.PublicPort}/${port.Type},  `}</a>
                                    } else {
                                        return null
                                    }
                                })}
                            />
                            <div className="descriptionDiv">
                                <div>{item.Image}</div>
                                <div className="createTimeDiv">
                                    创建时间:{moment(item.Created * 1000).format('YYYY-MM-DD HH:mm:ss')}<Divider type="vertical" />
                                    状态:{item.Status}
                                </div>
                            </div>
                        </List.Item>
                    )}
                />
            </div>
        </Card>
        <Drawer
            title={<div>容器信息</div>}
            placement="right"
            maskClosable={true}
            onClose={() => setInfoVisible(false)}
            visible={infoVisible}
            width={"55%"}
        >
            {getInfoDiv()}
        </Drawer>
        <Modal
            title={<div>{selectItem["Names"]} 容器日志<Divider type="vertical" />查看时间范围:&nbsp;
                <RangePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder={['开始时间', '结束时间']}
                    onChange={() => { }}
                    onOk={(timeRange) => {
                        const start = parseInt(moment(timeRange[0]).valueOf() / 1000)
                        const end = parseInt(moment(timeRange[1]).valueOf() / 1000)
                        console.log(start, end)
                        http_get_log(selectItem, start, end)
                    }}
                /><Divider type="vertical" />
                <Button type="primary" icon="download" onClick={() => { downloadLog() }}>下载日志</Button>
            </div>}
            visible={logVis}
            onCancel={() => setLogVis(false)}
            onOk={() => setLogVis(false)}
            width={"99%"}
            destroyOnClose
            className="ContainersModal"
        >
            <Spin spinning={textload}>
                <MonacoEditor
                    key={"logtext"}
                    language="powershell"
                    theme="vs-dark"
                    width="99%"
                    value={logtext}
                    options={{ selectOnLineNumbers: false }}
                />
            </Spin>

        </Modal>
    </div>
}

export default connect()(Containers)