import React from 'react'
import {Card, Input, Button, Col, Form, Progress, Row, Table, Tooltip as ToolTip, List, Badge, message} from "antd";
import QueueAnim from 'rc-queue-anim';
// import 'echarts/lib/chart/line';
import AxiosObj from "../../../axios";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
// import {Axis, Chart, Geom, Tooltip} from 'bizcharts';
// import './index.scss'
import moment from "moment";

// 监控告警后端服务器地址  monitor_address 配置地址：public/flow/config.js
const backUp_Url = monitor_address;

 class Overview extends React.Component {
    state = {
        loading: false,
        dataSource : [
            // {
            //     index: '1',
            //     alarmName: 'linux',
            //     ip: "10.32.79.xx" ,
            //     status: '否',
            //     time: '2020-03-23',
            //     user: 'admin',
            // },
            // {
            //     index: '2',
            //     alarmName: 'linux',
            //     ip: "10.32.79.xx" ,
            //     status: '否',
            //     time: '2020-03-23',
            //     user: 'admin',
            // },
        ],
        columns : [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'index',
                render: (text) => { return <span>{text}</span> }
            },
            {
                title: '告警级别',
                align: 'center',
                dataIndex: 'alarmLevel'
            },
            {
                title: '名称',
                align: 'center',
                dataIndex: 'alarmName'
            },
            {
                title: 'ip',
                align: 'center',
                dataIndex: 'ip',

            },
            // {
            //     title: '处理状态',
            //     align: 'center',
            //     dataIndex: 'status',
            // },
            {
                title: '时间',
                align: 'center',
                dataIndex: 'time',
                sorter : (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime(),

            }, {
                title: '描述',
                align: 'center',
                dataIndex: 'describe',
            },
            // {
            //     title: '处理人',
            //     align: 'center',
            //     dataIndex: 'user',
            //
            // }
            ],
        tendency : [],
    };
    getAlertList=(avg)=>{
        let ip ;
        if(avg) {
            ip =avg;
        }else {
            try{
                ip = this.props.location.state.ip
            }
            catch (e) {

                // console.log(e)
            }
        }

        let params={},url;
        if(ip){
            params['ip'] = ip;
            url = backUp_Url+"/snmpexporter/singleAlerts";
        }else{
            url = backUp_Url+"/snmpexporter/alertList";
        }
        AxiosObj.MA_HTTP_GET2(url,params,(response)=>{
            //console.log(response)
            //console.log(response.data)
            let alertList ;
            if(ip){
                alertList =response.data;
            }else{
                alertList =response.data.alertList;
            }

            let list = [];
            console.log(alertList);
            for(let i in alertList){
                let map ={}
                // console.log(i);
                console.log(alertList[i].labels.ifIndex);
                map['key'] = parseInt(i)+1;
                map['index'] = parseInt(i)+1;
                map['alarmName'] = alertList[i].labels.alertname;
                map['ip'] = alertList[i].labels.instance;
                let statusPassTime = moment(alertList[i].endsAt).format('YYYY-MM-DD HH:mm:ss');
                map['time'] = statusPassTime;
                map['describe'] = alertList[i].annotations.description;
                list[i] = map;
            };
            this.setState({
                dataSource : list,
            })
            // console.log(tendency);

        },(error)=>{
            message.error(error)
        })

    }

    componentWillMount(){
        this.getAlertList();
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        let { loading,dataSource, columns, alertData } = this.state;
        const cols = {
            value:{
                min : 0
            }
        };
        const search = (form)=>{

            const onFinish = e => {
                //console.log(e.target.ip.value)
                e.preventDefault();
                this.props.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                        let ip = values.ip;
                        this.getAlertList(ip);
                    }
                });
            };

            const onFinishFailed = errorInfo => {
                console.log('Failed:', errorInfo);
            };
         return(

             <Form
                 layout={'inline'}
                 initialValues={{ ip:'' }}
                 onSubmit={onFinish}
                 onFinishFailed={onFinishFailed}
             >
                 <Form.Item label="IP">
                     {getFieldDecorator('ip', {
                         rules: [{ required: true, message: 'Please input IP!' }],
                     })(
                         <Input/>,
                     )}
                 </Form.Item>

                 {/*<Form.Item*/}
                     {/*label="ip"*/}
                     {/*name="ip"*/}
                     {/*rules={[{ required: true, message: 'Please input your username!' }]}*/}
                 {/*>*/}
                     {/*<Input />*/}
                 {/*</Form.Item>*/}


                 <Form.Item >
                     <Button type="primary" htmlType="submit" >
                         查询
                     </Button>
                 </Form.Item>
             </Form>
         )
        }
        return (
            <div>
                <CustomBreadcrumb arr={["监控告警", "告警","告警列表"]} />
                <Card title={<span style={{ fontWeight: 600 }}>告警列表</span>}
                      style={{ marginTop: 8 }}
                extra={search()}>

                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        size="small"
                        loading={loading}
                        onChange={() => this.handleChange}
                    />
                </Card>
            </div>




        );
    }
}
const queryForm = Form.create()(Overview);
export default queryForm;