import React from 'react'
import { Button, Card, Col, Form, Icon, Input, message, Modal, Row, Spin, Table, Tag, Upload, Collapse, Descriptions, Dropdown, Menu } from "antd";
import Axios from "../../axios/axios";
import CustomBreadcrumb from '../../components/CustomBreadcrumb/index';
import DyHeadTable from '@/components/DyHeadTable/index';
import FilterForm from '@/components/FilterForm/index';
import Utils from '@/utils/utils';
import './index.scss';

import { SubmitButton, FormItem, TreeSelect, Select, Radio } from "formik-antd";
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
const { Option } = Select;
import { Formik } from "formik";
import moment from 'moment';

class MacMonitor extends React.Component {

    state = {
        _current: 1,
        _pageSize: 20,
        total: 0,
        loading: false,
        tableData: null,
        visible: false,
        selectedRowKeys: [],
        monitorMacItem: {},
        historyVis: false,
        historyData: [],
    }

    componentWillMount() {
        this.update();
    }
    addMonitor(values) {
        let { monitorMacItem } = this.state;
        if (monitorMacItem.id) {
            let bodyJson = {
                ...values,
                mac_address: values.mac,
                id: monitorMacItem.id
            }
            Axios.PUT('ip/macMonitor/', bodyJson, (result) => {
                if (result.status === 201 || result.status === 200 || result.status === 204) {
                    this.update();
                    this.setState({ visible: false, monitorMacItem: {} });
                    message.success("修改成功");
                } else {
                    message.error("修改失败");
                }
            })
        } else {
            Axios.POST('ip/macMonitor/', [values], (result) => {
                if (result.status === 201 || result.status === 200 || result.status === 204) {
                    this.update();
                    this.setState({ visible: false });
                    message.success("新增成功");
                } else {
                    message.error("新增失败");
                }
                // console.log(result)
            })
        }
    }
    deleteMonitor(item) {
        this.setState({ loading: true });
        let { _pageSize } = this.state;
        let options = {};
        options.url = "ip/macMonitor/" + item.id + "/";
        options.data = {};
        Axios.DELETE(options, (result) => {
            if (result.status === 201 || result.status === 200 || result.status === 204) {
                this.update(1, _pageSize, {});
                this.setState({ loading: false });
                message.success("删除成功");
            } else {
                message.error("删除失败");
            }
            // console.log(result)
        })
    }
    /* 查询列表数据 */
    update = (page, page_size, querys) => {
        this.setState({ loading: true })
        let { _pageSize, _current } = this.state
        let bodyJson = {
            page: page || _current,
            page_size: page_size || _pageSize,
            querys: querys || {}
        };

        let queryStr = 'ip/macMonitor/?'
        queryStr += bodyJson.page ? '&page=' + bodyJson.page : '';
        queryStr += bodyJson.page_size ? '&page_size=' + bodyJson.page_size : '&page_size=10';
        for (let queryItem in bodyJson.querys) {
            queryStr += "&" + queryItem + "=" + bodyJson.querys[queryItem];
        }
        Axios.GET(queryStr, bodyJson, (result) => {
            this.setState({
                tableData: result.data.results,
                _current: page || 1,
                total: result.data.count,
                loading: false,
                _pageSize: page_size || _pageSize,
            })
            // console.log(result.data)
        })
    }
    // 时间格式化
    dealTime(time) {
        let formatTime = "";
        if (time) {
            let timeArr = time.split("T");
            let timeMinutes = timeArr[1].split(".")[0];
            formatTime = [timeArr[0], timeMinutes].join(" ");
        }
        return formatTime
    }

    render() {
        let { _current, _pageSize, querys, selectedRowKeys, monitorMacItem, } = this.state

        const columns = [
            {
                title: 'mac地址',
                align: 'center',
                dataIndex: 'mac_address',
                width: 120,
                ellipsis: true,
            },
            {
                title: 'IP',
                align: 'center',
                dataIndex: 'ip',
                width: 110,
                ellipsis: true,
            },
            {
                title: '名称',
                align: 'center',
                dataIndex: 'name',
                width: 120,
                ellipsis: true,
            },
            {
                title: '最后一次检查时间',
                align: 'center',
                dataIndex: 'last_check_time',
                width: 140,
                ellipsis: true,
                render: (text) => { return text ? (<span>{this.dealTime(text)}</span>) : (<span></span>) }
            },
            {
                title: '最后一次ping结果',
                align: 'center',
                dataIndex: 'last_ping',
                width: 130,
                ellipsis: true,
                render: (text) => {
                    switch (text) {
                        case false:
                            return (<Tag color={Utils.ColorShow("fail")}>失败</Tag>)
                        case true:
                            return (<Tag color={Utils.ColorShow("success")}>成功</Tag>)
                        default:
                            break
                    }
                }
            },
            {
                title: '最近在线时间',
                align: 'center',
                dataIndex: 'lately_online_time',
                width: 140,
                ellipsis: true,
                render: (text) => { return text ? (<span>{this.dealTime(text)}</span>) : (<span></span>) }
            },
            {
                title: '离线天数',
                align: 'center',
                dataIndex: 'offline_days',
                width: 80,
                ellipsis: true,
            },
            {
                title: 'IP历史记录',
                align: 'center',
                dataIndex: 'ip_history',
                width: 110,
                ellipsis: true,
                render: (text, item, index) => {
                    if (Array.isArray(text)) {
                        if (text.length > 0) {
                            return <Button type="link" size="small" onClick={() => {
                                this.setState({ historyData: text, historyVis: true })
                            }}>历史记录</Button>
                        }
                    }
                }
            },
            {
                title: '描述',
                align: 'center',
                dataIndex: 'comment',
                width: 240,
                ellipsis: true,
            },
            {
                title: '操作',
                align: 'center',
                dataIndex: 'op',
                width: 180,
                ellipsis: true,
                fixed: 'right',
                render: (text, item) => {
                    return (
                        <div>
                            <Button type='primary' size='small' icon="edit" style={{ marginRight: 8 }} onClick={() => {
                                this.setState({ visible: true, monitorMacItem: item });
                            }}>编辑</Button>
                            <Button type='danger' size='small' icon="delete" onClick={() => {
                                let that = this;
                                Modal.confirm({
                                    title: "提示",
                                    content: "你确定删除选中ip吗？",
                                    okType: "danger",
                                    onOk() {
                                        that.deleteMonitor(item);
                                    }
                                })
                            }}>删除</Button>
                        </div>
                    )
                }
            }
        ]
        const hisColumns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                width: 50,
                ellipsis: true,
                render: (text, item, index) => {
                    return <span>{index + 1}</span>
                }
            },
            {
                title: 'IP',
                align: 'center',
                dataIndex: 'ip',
                width: 150,
                ellipsis: true,
            },
            {
                title: '创建时间',
                align: 'center',
                dataIndex: 'create_time',
                width: 150,
                ellipsis: true,
                render: (text) => { return text ? (<span>{this.dealTime(text)}</span>) : (<span></span>) }
            },
        ]

        let scrollX = 0;
        columns.map((item, index) => {
            scrollX += item.width;
        })
        scrollX = scrollX + 260;

        return (
            <div>
                <CustomBreadcrumb arr={[{ title: '首页', to: '/home' }, 'IP管理', 'mac监控']} />
                <div className="searchDiv">
                    <Collapse defaultActiveKey={"key"}>
                        <Collapse.Panel header="筛选条件" key={"key"}>
                            <FilterForm tagClass="macMonitor"
                                filterSubmit={(querys) => {
                                    querys.lately_online_time ? querys.lo_start_time = moment(querys.lately_online_time[0]).format("YYYY-MM-DD HH:mm:ss") : "";
                                    querys.lately_online_time ? querys.lo_end_time = moment(querys.lately_online_time[1]).format("YYYY-MM-DD HH:mm:ss") : "";
                                    delete querys.lately_online_time; // 删除 querys 中的 lately_online_time
                                    this.setState({ querys })
                                    this.update(1, this.state._pageSize, querys)
                                }}
                                resetTab={() => {
                                    this.setState({ querys: {} })
                                    this.update(1, this.state._pageSize, {})
                                }}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </div>
                <Card style={{ marginTop: 8 }} title={<span style={{ fontWeight: 600 }}>mac监控列表</span>}
                    extra={
                        <Row>
                            <Button type='primary' icon='edit' onClick={() => { this.setState({ visible: true, monitorMacItem: {} }); }}>新增监控mac</Button>
                        </Row>
                    }
                >
                    <DyHeadTable
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.loading}
                        size="small"
                        rowKey={(text) => { return text.id }}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"], showSizeChanger: true,
                            onChange: (page, pageSize) => {
                                this.update(page, pageSize, querys)
                            },
                            onShowSizeChange: (current, size) => {
                                this.update(1, size, querys)
                            }
                        }}
                    />
                </Card>
                <Modal title={monitorMacItem.mac_address ? "编辑监控mac" : "新增监控mac"}
                    visible={this.state.visible}
                    onCancel={() => this.setState({ visible: false })}
                    footer={null}
                    width={"60%"}
                    destroyOnClose
                >
                    <Formik onSubmit={(value) => { this.addMonitor(value) }}
                        validate={(values) => {
                            let info = {};
                            if (!values.mac) {
                                info.mac = "请输入mac地址";
                            }
                            if (!values.ip) {
                                info.ip = "请输入ip地址";
                            }
                            if (values.name) {
                                if (values.name.length > 64) {
                                    info.name = "名称不得超过64个字符";
                                }
                            }
                            return info
                        }}
                        initialValues={{
                            mac: monitorMacItem.mac_address || '',
                            ip: monitorMacItem.ip || '',
                            name: monitorMacItem.name || '',
                            comment: monitorMacItem.comment || '',
                        }}
                    >
                        <FormikForm labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                            <FormItem name={"mac"} label={"mac地址"} key={"mac"} required={true} >
                                <FormikInput name={"mac"} placeholder={"请输入mac地址"} allowClear />
                            </FormItem>
                            <FormItem name={"ip"} label={"IP"} key={"ip"} required={true} >
                                <FormikInput name={"ip"} placeholder={"请输入IP"} allowClear />
                            </FormItem>
                            <FormItem name={"name"} label={"名称"} key={"name"} >
                                <FormikInput name={"name"} placeholder={"请输入监控mac名称"} allowClear />
                            </FormItem>
                            <FormItem name={"comment"} label={"描述"} key={"comment"} >
                                <FormikInput name={"comment"} placeholder={"请输入描述信息"} allowClear />
                            </FormItem>
                            <div style={{ textAlign: "right" }}>
                                <Button onClick={() => { this.setState({ visible: false }) }}>取消</Button>
                                <SubmitButton style={{ marginLeft: "10px" }} disabled={false}>保存</SubmitButton>&nbsp;
                            </div>
                        </FormikForm>
                    </Formik>
                </Modal>
                <Modal title="查看ip历史记录" visible={this.state.historyVis}
                    onCancel={() => this.setState({ historyVis: false })}
                    onOk={() => this.setState({ historyVis: false })}
                    okText="关闭"
                    width={"50%"}
                    destroyOnClose
                >
                    <Table
                        dataSource={this.state.historyData}
                        style={{ maxHeight: "400px", overflow: "auto" }}
                        columns={hisColumns}
                        size="small"
                        rowKey={(text) => { return text.key }}
                        pagination={false}
                    />
                </Modal>
            </div>
        );
    }

}

const MacMonitorForm = Form.create()(MacMonitor);
export default MacMonitorForm;