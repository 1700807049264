import React from 'react'
import { Card, Input, Row, Col, Descriptions, Select, InputNumber, message, notification, Icon, Button } from "antd";
import axios from 'axios'
import Utils from "@/utils/utils"

import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { Axis, Chart, Geom, Tooltip } from "bizcharts";
import DataSet from '@antv/data-set';
import socket from "../../../socket";
// 监控告警后端服务器地址  monitor_address 配置地址：public/flow/config.js
const backUp_Url = "http://" + monitor_address;

export default class Detail extends React.Component {

    state = {
        title: {
            prom: '', //查询参数
            hours: 1,
            time: "h",
        },
        proms: [], //监控指标参数集合
        message: {},//路由传递的所有参数
        tableData: [],
        device: {}, //设备信息
        mapList: [],  //图表数据集合
        //dataSource:{}, //存储DataSet格式化后的数据
        dataSource1: []
        // dataSource:[],
        //dv: new DataSet().createView() //使用DataSet管理数据，否则图表不会实时更新
        // ip : '192.1.1.1',
        // job : 'cisco',
        // start : '1586752986',
        // end : '1586756587'
    }
    //使用DataSet管理数据，否则图表不会实时更新
    dv = new DataSet();

    //时间戳转化
    //      time(time = +new Date()) {
    //         let date = new Date(time + 8 * 3600 * 1000); // 增加8小时
    // //         return date.toJSON().substr(0, 19).replace('T', ' ').replace(/-/g, '.');
    //     }
    componentWillMount() {
        this.getMapList();
        this.update();
        this.interval = setInterval(() => this.updateAllMapList(), 1000);  //定时更新图表数据
        //this.getData();
    }
    componentWillUnmount() {
        // 清除定时器
        clearInterval(this.interval);
    }

    //直接一项prom的数据   待淘汰
    getData = (value) => {
        let { tableData, proms, } = this.state;
        let title = value || this.state.title;
        let messages = {};
        let ip = "", job = "";
        try {
            // messages = this.props.location.state;
            messages = this.props.location.state;
            console.log('路由传递信息', message)
            ip = messages['ip']
            job = messages['module']
            for (let i in messages['proms']) {
                proms[i] = messages['proms'][i].index
            }
            // proms = messages['proms']
            if (title.prom == "") {
                //取值
                title.prom = proms[0];
            }

        }
        catch (e) {

            return this.props.history.push('/monitoringAlarm/snmpMonitor');
        }
        let end = Date.parse(new Date()) / 1000;
        let start = "";
        if (title.time == "h") {
            start = end - title.hours * 60 * 60;
        } else if (title.time == "d") {
            start = end - title.hours * 60 * 60 * 24;
        }

        axios.get(backUp_Url + "/snmpexporter/promQuery", {
            params: { // 这里的参数设置为URL参数（根据URL携带参数）
                ip: ip,
                job: job,
                prom: title.prom,
                proms: proms,
                start: start,
                end: end
            }

        }).then((response) => {
            if (response.status === 200) {
                if (response.data.code === 0) {
                    //console.log(response.data.data)
                    //success(response.data)
                    for (let i in response.data.data) {
                        let map = {};
                        map["data"] = response.data.data[i][0] * 1000;
                        //map["data"] = this.time(response.data.data[i][0]*1000).slice(11,19);
                        map["value"] = parseInt(response.data.data[i][1]);
                        tableData[i] = map;

                    }
                    console.log(tableData);
                    this.setState({
                        proms: proms,
                        title: title,
                        tableData: tableData
                    })
                } else {
                    error(response.data.msg)
                    //console.log(response.data.msg)
                }
            } else {
                error(response.data.msg || response.data)
            }
        }).catch((error) => {
            message.error("获取数据失败");
            return this.props.history.push('/monitoringAlarm/snmpMonitor');
        })

    }
    //获取单独一张图表的数据  （现在）一个字段有多种返回值时，选择了第一个
    getData1 = (value, cal) => {
        console.log('传入参数', value)
        let { title } = this.state;
        let tmpValue = value;
        let end = Date.parse(new Date()) / 1000;
        let start = "";
        tmpValue['time'] = value['time'] || title.time;
        tmpValue['hours'] = value['hours'] || title.hours;
        if (tmpValue['time'] == "h") {
            start = end - tmpValue['hours'] * 60 * 60;
        } else if (tmpValue['time'] == "d") {
            start = end - tmpValue['hours'] * 60 * 60 * 24;
        } else if (tmpValue['time'] == "m") {
            start = end - tmpValue['hours'] * 60
        }
        axios.get(backUp_Url + "/snmpexporter/promQuery", {
            params: { // 这里的参数设置为URL参数（根据URL携带参数）
                ip: value.ip,
                job: value.job,
                prom: value.prom,
                //proms : proms,
                start: start,
                end: end
            }

        }).then((response) => {
            if (response.status === 200) {
                if (response.data.code === 0) {
                    //console.log(response.data.data)
                    //success(response.data)
                    let values = response.data.data[0].values;  //现在  一个字段有多种返回值时，选择了第一个
                    //let values = response.data.data;  //现在接口改动
                    let tmpTableData = [] // 一张表的数据
                    for (let i in values) {
                        let map = {};
                        map["data"] = values[i][0] * 1000;
                        //map["data"] = this.time(response.data.data[i][0]*1000).slice(11,19);
                        map["value"] = parseInt(values[i][1]);
                        tmpTableData[i] = map;

                    }
                    tmpValue['tableData'] = tmpTableData;
                    console.log('tmpTableData', tmpValue);
                    cal(tmpValue);
                } else {
                    error(response.data.msg)
                    //console.log(response.data.msg)
                }
            } else {
                error(response.data.msg || response.data)
            }
        }).catch((error) => {
            let str = "获取" + value.describe + "数据失败";
            message.error(str);
            console.log(str)
            // console.log(error)
            //return this.props.history.push('/monitoringAlarm/snmpMonitor');
        })

    }
    // 获取所有图表显示信息
    getMapList = () => {
        let { message, mapList, dataSource } = this.state;
        let messages = {};
        //获取路由传递参数
        if (JSON.stringify(message) == '{}') {
            try {
                if (this.props.location.state) {
                    messages = this.props.location.state;
                    console.log('路由传递信息', messages)
                } else {
                    return this.props.history.push('/monitoringAlarm/snmpMonitor');
                }
            }
            catch (e) {
                return this.props.history.push('/monitoringAlarm/snmpMonitor');
            }
            //message = messages;
            this.setState({
                message: messages,
            })
        }
        // let tmpMap = {};
        // tmpMap['ip'] = messages['ip'];
        // tmpMap['job'] = messages['module'];
        // const proms = messages.proms;
        // console.log(proms);
        let count = 0;
        const _this = this;
        for (let i in messages.proms) {
            let tmpMap = {};
            tmpMap['ip'] = messages['ip'];
            tmpMap['job'] = messages['module'];
            // let tmpMap = Utils.deepCopy(tmpMap2);
            tmpMap['prom'] = messages.proms[i].index;
            tmpMap['display'] = messages.proms[i].display;
            tmpMap['describe'] = messages.proms[i].describe;
            // console.log(messages.proms[i].index, messages.proms[i].display, messages.proms[i].describe)
            // console.log(tmpMap['prom'], tmpMap['display'], tmpMap['describe']);
            // console.log(tmpMap);
            // console.log("======================================================");
            if (tmpMap['display'] == 'yes') {
                this.getData1(tmpMap, (val) => {
                    //let tmpValue = {...val}
                    console.log(val)
                    mapList.push(val);
                    count++;
                    if (count === messages.proms.length) {
                        console.log("mapList", mapList)
                        _this.setState({
                            mapList: mapList
                        })
                    }
                })
            }

        }


    }
    //更新一张图表
    upDateMapList = (value, index) => {
        let { mapList } = this.state;
        const _this = this;
        this.getData1(value, (val) => {
            mapList[index] = val;
            _this.setState({
                mapList: mapList
            })
            // for(let i in mapList){
            //     if(value.prom == mapList[i].prom){
            //         mapList[i] = val
            //         _this.setState({
            //             mapList : mapList
            //         })
            //     }
            // }
        })
    }
    //定时任务，实时刷新所有图表
    updateAllMapList() {
        let { mapList } = this.state;
        console.log('mapList', mapList)
        if (mapList.length == 0) {
            return
        }
        for (let i = 0; i < mapList.length; i++) {
            let tmpMap = mapList[i];
            this.upDateMapList(tmpMap, i)
        }

    }
    //获取设备信息
    update = () => {
        let messages = {};
        //获取路由传递参数
        if (JSON.stringify(message) == '{}') {
            try {
                if (this.props.location.state) {
                    messages = this.props.location.state;
                    console.log('路由传递信息', messages)
                } else {
                    return this.props.history.push('/monitoringAlarm/snmpMonitor');
                }
            }
            catch (e) {
                return this.props.history.push('/monitoringAlarm/snmpMonitor');
            }
        }
        let ip = messages.ip;
        //console.log(ip);
        // this.setState({ loading: true, });
        // let { _pageSize, _current } = this.state;
        let bodyJson = {
            pageSize: 1,
            pageNum: 1,
            querys: {
                "resourceParams.deviceIp": ip

            }
        };
        // bodyJson.pageSize = pageSize || _pageSize;
        // bodyJson.pageNum = pageNum || _current;
        //resourceParams.deviceip:id
        // bodyJson.querys.resourceParams.deviceIp =  '192.1.1.1'
        //console.log(bodyJson);
        //QueryResourceDetail     QueryResourcesPage
        socket.ExecuteAction('QueryResourcesPage',
            bodyJson, "/queryResource", (result) => {
                console.log('设备信息', result);
                let tmpData;
                // if( result.resources[0].resourceParams){
                //     tmpData = result.resources[0].resourceParams;
                // }else{
                //     tmpData = ''
                // }
                if (result.resources.length > 0) {
                    tmpData = result.resources[0].resourceParams;
                } else {
                    tmpData = {}
                }

                this.setState({
                    device: tmpData
                })

                // let Resources = result.resources;
                // Resources.map((item, index) => {
                //     item.key = index;
                //     item.index = index;
                //     return item.id
                // });
                console.log(result)
                // this.setState({
                //     tableData: Resources,
                //     _current: pageNum || 1,
                //     total: result.total,
                //     loading: false,
                // })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };

    render() {
        let { message, title, tableData, mapList, dataSource, dataSource1, device } = this.state;
        let num = -1;
        // this.dv.source(tableData)
        //组件Card 动态添加组件
        const loop = (data) =>
            data.map((tab) => {
                num = num + 1;
                let tmpNum = num;
                console.log('图表数据', tab,);
                const col = {
                    data: {
                        type: "time",
                        tickCount: 8,
                        //tickInterval:250000 ,
                        // mask: "YYYY-MM-DD HH:mm:ss",
                        mask: "HH:mm",
                        range: [0, 1]

                    },
                    value: {
                        min: 0
                    }
                };
                // dataSource[tab.index] = this.dv.createView(tab.index).source(tab.tableData);
                dataSource1[num] = this.dv.createView(tab.index).source(tab.tableData)
                //console.log('dv',this.state.dv)
                //  dataSource[tab.index] = this.dv.source(tab.tableData);
                //  this.setState({
                //      dataSource :dataSource
                //  })
                if (tab.display == 'yes') {
                    return <Col span={12}>
                        <Card style={{ display: 'flex', width: '100%', height: 250, marginTop: 10, float: 'left' }}
                        //title={<span style={{ fontWeight: 600 }}>监控指标</span>}
                        >
                            <span>
                                监控指标
                        <Select style={{ marginLeft: 10, marginRight: 10, width: 250 }} defaultValue={tab.prom} defaultActiveFirstOption='true'
                                    onChange={(value, text) => {
                                        // console.log('select',value,'text',text)
                                        let tmpTab = { ...tab }
                                        tmpTab['prom'] = value;
                                        tmpTab['describe'] = text.props.children;  //同时修改描述，报错时提示才正确
                                        this.upDateMapList(tmpTab, tmpNum);
                                    }
                                    }
                                >
                                    {/*<Option key={tab.prom} value={tab.prom} label={tab.prom}>*/}
                                    {/*{tab.describe}*/}
                                    {/*</Option>*/}
                                    {message.proms.map(item => (
                                        <Option key={item.index} value={item.index} label={item.index}>
                                            {item.describe}
                                        </Option>
                                    ))}
                                </Select>
                        显示时长
                        <InputNumber min={1} defaultValue={1} style={{ marginLeft: 10, width: 60 }} onChange={(value) => {
                                    console.log(value, 'tab', tab)
                                    let tmpTab = { ...tab }
                                    tmpTab['hours'] = value;
                                    this.upDateMapList(tmpTab, tmpNum);
                                    //title["hours"] = value;
                                    //this.getData(title);
                                }} />
                                <Select defaultValue="h" style={{ marginLeft: 10, width: 70 }} onChange={(value) => {
                                    let tmpTab = { ...tab }
                                    tmpTab['time'] = value;
                                    this.upDateMapList(tmpTab, tmpNum);
                                }}>
                                    <Option value="m">分钟</Option>
                                    <Option value="h">小时</Option>
                                    <Option value="d">天</Option>
                                </Select>
                            </span>
                            <Chart height={200} padding={[20, 20, 50, 100]} data={dataSource1[num]} scale={col}
                            // forceFit
                            >
                                <Axis name="data" />
                                <Axis name="value" />
                                <Tooltip />
                                <Geom select={true}
                                    active={true} animate={true} type="lineStack" position="data*value" size={2} color="rgba(0, 146, 255, 1)" />
                            </Chart>
                        </Card>
                    </Col>
                }
            });

        const cols = {
            data: {
                type: "time",
                tickCount: 8,
                mask: "hh:MM"
                // mask: "MM-DD hh:MM:ss"
            },
            value: {
                min: 0
            }
        };
        return (
            <div style={{ width: '100%' }}>
                <CustomBreadcrumb arr={["监控告警", "SNPM监控", { to: '/monitoringAlarm/snmpMonitor', title: "监控设备" }, "设备详情"]} />
                <Card title={<span style={{ fontWeight: 600 }}>设备信息</span>}
                    extra={<Button type="primary" onClick={() => this.props.history.go(-1)} icon="rollback">返回</Button>}>
                    <Descriptions bordered>
                        <Descriptions.Item span={3} label="设备IP">{message.ip}</Descriptions.Item>
                        <Descriptions.Item span={3} label="设备厂商">{device.deviceFirm}</Descriptions.Item>
                        <Descriptions.Item span={3} label="版本信息">{device.deviceVersion}</Descriptions.Item>
                        <Descriptions.Item span={3} label="设备型号">{device.deviceOsVersion}</Descriptions.Item>
                        {/*<Descriptions.Item span={3} label="Billing Mode">Prepaid</Descriptions.Item>*/}
                        {/*<Descriptions.Item span={3} label="Automatic Renewal">YES</Descriptions.Item>*/}
                        {/*<Descriptions.Item span={3} label="IP">XXX.XXX.XXX.XXX</Descriptions.Item>*/}
                        {/*<Descriptions.Item span={3} label="Billing Mode">Prepaid</Descriptions.Item>*/}
                        {/*<Descriptions.Item span={3} label="Automatic Renewal"></Descriptions.Item>*/}
                    </Descriptions>
                </Card>
                <Row gutter={[16, 16]}>
                    {loop(mapList)}
                </Row>



                {/*<Card  style={{ width:'59%',height:300, marginTop: 30 , marginLeft: '2%',float:'left' }}*/}
                {/*title={<span style={{ fontWeight: 600 }}>监控指标</span>}*/}
                {/*>*/}
                {/*<span>*/}
                {/*监控指标*/}
                {/*<Select  style={{ marginLeft : 10,  marginRight : 10, width: 250 }} defaultValue={title.prom}  defaultActiveFirstOption = 'true'*/}
                {/*onChange ={(value)=>{*/}
                {/*title["prom"] = value;*/}
                {/*this.getData(title);*/}
                {/*}*/}
                {/*}*/}
                {/*>*/}
                {/*{proms.map(item => (*/}
                {/*<Option key={item} value={item} label={item}>*/}
                {/*{item}*/}
                {/*</Option>*/}
                {/*))}*/}
                {/*</Select>*/}
                {/*显示时长*/}
                {/*<InputNumber  min={1} defaultValue={1}  style={{ marginLeft : 10, width : 60}} onChange={(value)=>{*/}
                {/*title["hours"] = value;*/}
                {/*this.getData(title);*/}
                {/*}} />*/}
                {/*<Select   defaultValue="h" style={{ marginLeft : 10, width: 70 }} onChange={(value)=>{*/}
                {/*title["time"] = value;*/}
                {/*this.getData(title);*/}
                {/*}}>*/}
                {/*<Option value="h">小时</Option>*/}
                {/*<Option value="d">天</Option>*/}
                {/*</Select>*/}
                {/*</span>*/}
                {/*<Chart   height={150} padding={[ 20, 20, 50, 100]} data={this.dv} scale={cols}*/}
                {/*// forceFit*/}
                {/*>*/}
                {/*<Axis name="data" />*/}
                {/*<Axis name="value"/>*/}
                {/*<Tooltip/>*/}
                {/*<Geom select={true}*/}
                {/*active={true} animate={true}  type="lineStack" tickCount={7} position="data*value" size={2} color="rgba(0, 146, 255, 1)"/>*/}
                {/*</Chart>*/}
                {/*</Card>*/}

                {/*<Card style={{width:'100%',marginTop: 30, float:'left'}}*/}
                {/*title={<span style={{ fontWeight: 600 }}>设备信息</span>}> >*/}
                {/*</Card>*/}
                {/*<Card  style={{ width: '100%',height:'200', marginTop: 30 ,float:'left' }}*/}
                {/*title={<span style={{ fontWeight: 600 }}>监控指标</span>}>*/}
                {/*<span>*/}
                {/*监控指标*/}
                {/*<Select  style={{ marginLeft : 10,  marginRight : 10, width: 250 }} defaultValue={title.prom}  defaultActiveFirstOption = 'true'*/}
                {/*onChange ={(value)=>{*/}
                {/*title["prom"] = value;*/}
                {/*this.getData(title);*/}
                {/*}*/}
                {/*}*/}
                {/*>*/}
                {/*{proms.map(item => (*/}
                {/*<Option key={item} value={item} label={item}>*/}
                {/*{item}*/}
                {/*</Option>*/}
                {/*))}*/}
                {/*</Select>*/}
                {/*显示时长*/}
                {/*<InputNumber  min={1} defaultValue={1}  style={{ marginLeft : 10, width : 60}} onChange={(value)=>{*/}
                {/*title["hours"] = value;*/}
                {/*this.getData(title);*/}
                {/*}} />*/}
                {/*<Select   defaultValue="h" style={{ marginLeft : 10, width: 70 }} onChange={(value)=>{*/}
                {/*title["time"] = value;*/}
                {/*this.getData(title);*/}
                {/*}}>*/}
                {/*<Option value="h">小时</Option>*/}
                {/*<Option value="d">天</Option>*/}
                {/*</Select>*/}
                {/*</span>*/}
                {/*<Chart   padding={[ 20, 20, 50, 100]} data={this.dv} scale={cols} forceFit>*/}
                {/*<Axis name="data" />*/}
                {/*<Axis name="value"/>*/}
                {/*<Tooltip/>*/}
                {/*<Geom select={true}*/}
                {/*active={true} animate={true}  type="lineStack" tickCount={7} position="data*value" size={2} color="rgba(0, 146, 255, 1)"/>*/}
                {/*</Chart>*/}
                {/*</Card>*/}

                {/*<iframe*/}
                {/*style={{width:'100%', height:this.state.iFrameHeight, overflow:'visible'}}*/}
                {/*id='iframe'*/}
                {/*onLoad={() => {*/}
                {/*const obj = ReactDOM.findDOMNode(this);*/}
                {/*//const obj2=document.getElementById('iframe')*/}
                {/*console.log(obj)*/}
                {/*//const iwindow=iframe.contentWindow.document;*/}
                {/*// console.log(iwindow)*/}
                {/*this.setState({*/}
                {/*"iFrameHeight":  900,//obj.contentWindow.document.body.scrollHeight*/}
                {/*"view": obj*/}
                {/*});*/}
                {/*console.log(this.state.view)*/}
                {/*}}*/}
                {/*ref="iframe"*/}
                {/*//srcdoc={this.state.view}*/}
                {/*src={prometheus}*/}
                {/*width="100%"*/}
                {/*allow="payment"*/}
                {/*height={this.state.iFrameHeight}*/}
                {/*scrolling="no"*/}
                {/*frameBorder="0"*/}
                {/*>*/}

                {/*</iframe>*/}

            </div>

        )
    }
}