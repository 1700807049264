import React, { useEffect, useState } from 'react'

// import { ScrollRankingBoard } from '@jiaminghi/data-view-react'
import Charts from "echarts-for-react"
import './Histogram.scss'
import socket from '@/socket/index'
import Utils from "@/utils/utils";
import * as echarts from 'echarts';

import AxiosObj from '@/axios/index';
import TopologyChart from '../../maintain/autoFind/Topology/index';

export default class NetworkTopology extends React.Component {
  state = {
    topoData: {},
    chartData: [],
    flag: {},//是否需要自动排序
    activiKey: "",
}

  componentWillMount() {
    AxiosObj.HTTP_POST("topology", "zoneTopology", {}, (result) => {
      if(result.code === "00") {
        let topoData = {};
        let flag = {};
        result.data.map((item) => {
            let flowData = {};
            if (item.data && item.data.nodes) {//保存过的数据
                flowData = item.data;
                flag[item.area] = false;
            } else {
                flag[item.area] = true;

                flowData = this.actionData(item.topology);
            }
            topoData[item.area] = flowData;
        })
        this.setState({
            topoData, flag,
            activiKey: Object.keys(topoData)[0],
            chartData: topoData[Object.keys(topoData)[0]]
        });
        console.log(result.data)
      }
    })
  }


  render() {
    const { topoData, chartData, activiKey, flag } = this.state;

    return (
      <div className="networkTopologyBoard">
        {Object.keys(topoData).map((area, key) => {
          return (
            <TopologyChart key={key}
              refThis={(s, graph) => { }}
              isLayout={flag[area]}
              id={area}
              data={chartData}
              save={(data, show) => { console.log(data, show) }}
              // save={(data, show) => { this.saveAreaTopo(activiKey, data, show) }}
              onChange={() => { }} 
            />
          )
        })}
      </div>
    )
  }
}
