import React from 'react'
import {
    Input, Form,
    Typography, TreeSelect
} from "antd";
import { connect } from 'react-redux'
import "./index.scss"
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import TolopogyChart from './Topology/index';
class MixTopo extends React.Component {
    state = {
        data: []
    }
    componentWillMount() {
        const data = this.actionData(this.props.data)
        console.log(data);
        this.setState({ data })
    }
    componentDidMount() {

    }

    actionData(data) {
        let nodes = [];
        let edges = [];
        let groups = [];
        let combos = [];
        data.map((item) => {
            let topo_data = item.topo_data;
            if (!topo_data.nodes) {//没保存过的数据
                topo_data = Utils.createTopoData(topo_data);
            }
            nodes = nodes.concat(topo_data.nodes || []);
            edges = edges.concat(topo_data.edges || []);
            groups = groups.concat(topo_data.groups || []);
            combos = combos.concat(topo_data.combos || []);
        });
        let flowData = {
            nodes,
            edges,
            groups,
            combos,
        };
        return flowData;
    }

    render() {
        // const { } = this.props;
        const { data } = this.state;
        return <div>
            <TolopogyChart data={data} isLayout={true} save={(data) => { }}
                onChange={(v) => { }}
                refThis={(s) => { }} />
        </div>
    }

}
export default connect()(MixTopo)