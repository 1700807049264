import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Modal, Icon, message, Tag, Divider, Popconfirm
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import TableTransfer from './TableTransfer';
import { alarmGroups, alarmGroup, groupMember } from '../utils/api';
import FormModal from '../utils/formModal';
import BaseForm from '../utils/baseForm';
import Socket from '@/socket/index';
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import "./index.scss"
import "animate.css"
function AlertGroup(props) {
    const [visible, setVisible] = useState(false);
    const [menberVisible, setMenVisible] = useState(false);
    const [total, setTotal] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const [selectItem, setSelectItem] = useState(null);
    const [selectedRowKeys, setRowKeys] = useState([])
    const [, setUsers] = useState([])
    const [targetKeys, setTargetKeys] = useState([])
    const [userList2, setUsers2] = useState([])
    const [tableData, setTabData] = useState([])
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
        },
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    useEffect(() => {//componentDidMount
        http.setDispatch(null)
        getUsers();
        getData();
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(page = 1, size = _pageSize, query = {}) {
        props.dispatch(isLoading(true));
        http.get(alarmGroups(), { page, size, ...query }, (r) => {
            // const data = r.data.records.map((item) => {
            //     const temps = item.members.map(r => r.name);
            //     item.members = temps;
            //     return item;
            // }); 
            setTabData(r.data.records);
            setTotal(r.data.total);
            set_querys(query);
            set_current(page);
            set_pageSize(size)
            props.dispatch(isLoading(false));
        })
    }
    const usercolums = [
        {
            title: '用户名',
            ellipsis: true,
            dataIndex: 'name',
        },
        {
            title: '名称',
            ellipsis: true,
            dataIndex: 'nickName',
        },
        {
            title: '邮箱',
            ellipsis: true,
            dataIndex: 'mail',
        },
        {
            title: '电话号码',
            ellipsis: true,
            dataIndex: 'phone',
        }
    ]
    const columns = [
        {
            title: '序号',
            ellipsis: true,
            width: 40,
            render: (text, item, c) => { return c + 1 + (_current - 1) * _pageSize }
        },
        {
            title: '组名称',
            dataIndex: 'alarmGroupName',
            ellipsis: true,
        },
        {
            title: '组成员',
            dataIndex: 'members',
            width: "30%",
            ellipsis: true,
            render: (text) => { return (text || []).map((i, k) => (<Tag key={k}>{i.name}</Tag>)) }
            // render: (text, item, c) => { return (text || []).map((i, k) => (<Tag key={k}>{i}</Tag>)) }
        },
        {
            title: '描述',
            dataIndex: 'description',
            ellipsis: true,
        },
        {
            title: '创建人',
            dataIndex: 'user',
            ellipsis: true,
        },
        {
            title: '操作',
            render: (text, item) => {
                return <div>
                    {/* <Button type="link" size="small" icon="edit" onClick={() => { setSelectItem(item); setVisible(true) }}>编辑</Button>
                    <Divider type="vertical" />
                    <Button type="link" icon="delete" size="small" onClick={() => deleteData(item)}>删除</Button> */}
                    <a onClick={() => {
                        setSelectItem(item);
                        setVisible(true)
                    }}><Icon type="edit" />&nbsp;编辑</a>
                    <Divider type="vertical" />
                    {/* <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData(item) }}
                        okText="是"
                        cancelText="否"
                    >
                        <a><Icon type="delete" />&nbsp;删除</a>
                    </Popconfirm> */}
                    <a onClick={() => {
                        setSelectItem(item);
                        const menbers = item.members.map((m) => m.alarmReceiverId)
                        setTargetKeys(menbers)
                        setMenVisible(true)
                    }}><Icon type="usergroup-add" />&nbsp;编辑成员</a>
                </div>
            }
        },
    ]
    function saveDataSource(val) {
        const { alarmGroupId } = selectItem;
        if (alarmGroupId) {//修改
            http.put(alarmGroup(), { alarmGroupId, ...val }, () => {
                getData(1, _pageSize, {});
                message.success("修改成功");
            })
        } else {//新增
            http.post(alarmGroup(), val, () => {
                getData(1, _pageSize, {});
                message.success("新增成功");
            })
        }
        setVisible(false)
    }
    function deleteData(alarmGroupIds) {
        http.delete_body(alarmGroup(), { alarmGroupIds }, () => {
            getData(1, _pageSize, {});
            message.success("删除成功");
        })
    }
    function getUsers() {
        Socket.ExecuteAction('QueryUserFromUrl', { url: "" }, "", (result) => {
            const users = result.data.map((r) => {
                return { title: r.name, key: r.name };
            });
            setUsers(users);
            setUsers2(result.data);
        });
    }
    function TargetKeysChange(keys, direction, moveKeys) {
        console.log(keys, direction, moveKeys);
        const { alarmGroupId } = selectItem
        if (direction === "right") {//新增
            http.post(groupMember(), { alarmGroupId, alarmReceiverIds: moveKeys }, () => {
                getData(1, _pageSize, {});
                setTargetKeys(keys);
                message.success("添加成功");
            })
        } else {//删除
            http.delete_body(groupMember(), { alarmGroupId, alarmReceiverIds: moveKeys }, () => {
                getData(1, _pageSize, {});
                message.success("移出成功");
                setTargetKeys(keys)
            })
        }
    }
    return <div className="monitorAlertGroup">
        <CustomBreadcrumb arr={["监控告警", "告警组"]} />
        <Card title="告警组列表" extra={<div>

        </div>}>
            <div className="searchDiv">
                <BaseForm
                    onSubmit={(val) => { getData(1, _pageSize, val) }}
                    onCancel={() => { getData(1, _pageSize, {}) }}
                    initialValues={{}}
                    formList={[
                        { type: "input", name: "alarmGroupName", label: "组名称", placeholder: "组名称" },
                    ]}
                />
                <div><Button type="primary" icon="file-add" onClick={() => {
                    setSelectItem({})
                    setVisible(true)
                }}>添加</Button>&nbsp;
                  <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData(selectedRowKeys) }}
                        okText="是"
                        cancelText="否"
                    >
                        <Button type="danger" icon="delete" >删除</Button>
                    </Popconfirm>

                </div>
            </div>
            <Table
                dataSource={tableData}
                rowSelection={rowSelection}
                columns={columns}
                size="small"
                rowKey={r => r.alarmGroupId}
                pagination={
                    {
                        current: _current, pageSize: _pageSize, total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            getData(1, size, _querys)
                        },
                        onChange: (page, pageSize) => { getData(page, pageSize, _querys) }
                    }
                }
            />
        </Card>
        <FormModal
            title="编辑告警组信息"
            visible={visible}
            onCancel={() => setVisible(false)}
            onSubmit={(val) => {
                // const lists = val.members;
                // const newMenber = [];
                // userList2.map((r) => {
                //     if (lists.includes(r.name)) {
                //         newMenber.push({ alarmReceiverId: r.id, ReceiverName: r.name, tel: r.tel, emailAddress: r.mail })
                //     }
                // })
                // val.members = newMenber;
                saveDataSource(val);
            }}
            width={"50%"}
            footer={null}
            initialValues={selectItem}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            destroyOnClose
            formList={[
                { type: "input", name: "alarmGroupName", label: "组名称", required: true, placeholder: "组名称" },
                { type: "textArea", name: "description", label: "描述", placeholder: "描述" },
                // {
                //     type: "transfer", name: "members", label: "选择成员", dataSource: userList
                // }
            ]}
        />
        <Modal
            title="编辑告警组成员信息"
            visible={menberVisible}
            onCancel={() => setMenVisible(false)}
            onOk={() => setMenVisible(false)}
            width={"80%"}
            destroyOnClose
            className="alertMenberModal"
        >
            <TableTransfer
                dataSource={userList2.map((r) => { r.key = r.id; r.disabled = false; return r })}
                titles={[<div><Icon type="usergroup-add" twoToneColor="#979797" />成员数据列表</div>,
                <div><Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />已选择成员列表</div>]}
                operations={['添加', '移除']}
                targetKeys={targetKeys}
                showSearch={true}
                onChange={(targetKeys, direction, moveKeys) => { TargetKeysChange(targetKeys, direction, moveKeys) }}
                filterOption={(inputValue, item) =>
                    item.name.indexOf(inputValue) !== -1 || item.nickName.indexOf(inputValue) !== -1
                }
                leftColumns={usercolums}
                rightColumns={usercolums}
            />
        </Modal>
    </div>
}

export default connect()(AlertGroup)