import React from 'react'
import {
    Card, Table, Button, Icon, message, notification, Modal, Menu, Dropdown,
    Tabs, Steps, Typography, Tag, Progress, Select, Collapse, Spin, Checkbox, Descriptions
} from "antd";
import socket from "@/socket/index";
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
// import DyHeadTable from '@/components/DyHeadTable/index';
import DyHeadTable from '@/components/DyHeadTable/cardList.js';
import FlowChar from '@/components/FlowChar/index';
import MonacoEditor from 'react-monaco-editor';
import { exportWord } from "mhtml-to-word"
import './index.scss';
const { confirm } = Modal;
const { Text } = Typography;
class InspectionMan extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _current2: 1,    //当前页码数
        _pageSize: 10,    //当前每页显示条数
        _pageSize2: 10,    //当前每页显示条数
        loading: false,
        querys: {
            "ifTemplate": false,
            "reportTags": "巡检"
        },    //查询条件
        querys2: null,    //查询条件  
        addselectedRowKeys: [],
        HisReportData: [],
        selectIp: [],//选中的IP
        progressObj: {},
        elapsedTimeObj: {},
        visible_report: false,
        visible_insp: false,
        visible_recording: false,
        visible_module_flag: false, // 显示模版对话弹窗
        timeSpin: false,
        step_count: 0,
        taskId: "",
        hisTime: "",
        TabActiveIp: "",
        ipsSort: [],
        moduleName: "",
        flow_data: {},
        templateObj: {},
    };
    componentWillMount() {
        let bodyJson = this.props.location.bodyJson || {};
        const { _current, _pageSize, querys } = this.state;
        this.setState({
            _current: bodyJson.pageNum || _current,
            _pageSize: bodyJson.pageSize || _pageSize,
        })
        this.updata(bodyJson.pageSize, bodyJson.pageNum, bodyJson.querys);
        this.onNotice();
    }
    onNotice() {
        socket.Notice((resp) => {
            let { progressObj, elapsedTimeObj, _pageSize, _current, querys } = this.state;
            if (progressObj.hasOwnProperty(resp.taskName)) {
                progressObj[resp.taskName] = resp.taskRate;
                if (resp.taskRate === 100) {
                    this.updata(_pageSize, _current, querys);
                }
            }
            if (elapsedTimeObj.hasOwnProperty(resp.taskName)) {
                elapsedTimeObj[resp.taskName] = resp.elapsedTime;
            }
            this.setState({
                progressObj, elapsedTimeObj
            })
        });
    }

    getModuleGroup(taskItem) {
        const { _current, _pageSize } = this.state;
        // 获取模版
        socket.ExecuteAction("QueryTasksPage",
            { _current, _pageSize, querys: { reportTags: taskItem.reportTags, ifTemplate: true } }, "/taskQueryTask", (result) => { // 获取该任务类型下的所有模版
                const SelectTaskList = result.tasks
                if (taskItem.template) {
                    const SelTemp = SelectTaskList.filter((item) => { return item._id === taskItem.template });
                    if (SelTemp[0] && SelTemp[0].taskName) {
                        this.setState({
                            moduleName: SelTemp[0]
                        })
                        this.getFlowData(SelTemp[0]._id);
                        this.queryTemplate(SelTemp[0].form_template_id);
                    }
                }
            });
    }
    getFlowData(_id) {
        // 获取流程图数据
        socket.ExecuteAction('QueryTaskDetail', { _id: _id }, "/taskQueryTask", (resp) => {
            let flow_data = resp.task.taskContent;
            this.setState({
                flow_data: flow_data
            })
        })
    }
    queryTemplate = (_id) => {
        socket.ExecuteAction("QueryInspecTemplate",
            { _id }, "/", (result) => {
                if (result.data && result.data[0]) {
                    this.setState({ templateObj: result.data[0] })
                }
            });
    };
    openReport(data) {
        Modal.info({
            title: "预览报告",
            width: "80%",
            content: <div>
                <p><strong>报告名称:{data.name}</strong></p>
                <div dangerouslySetInnerHTML={{ __html: data.template }}></div>
            </div>
        })
    }

    excuteTask = (taskId, taskName) => {
        const { _pageSize, _current, elapsedTimeObj, progressObj, querys } = this.state;
        this.setState({ loading: true });
        socket.Action("OptaskExcute",
            { _id: taskId }, (result) => {
                if (result.retcode === '00') {
                    message.success('执行成功');
                    elapsedTimeObj[taskName] = "0:0:0";
                    progressObj[taskName] = 0;
                    this.setState({ elapsedTimeObj, progressObj });
                    this.updata(_pageSize, _current, querys);
                } else {
                    this.setState({ loading: false });
                    notification.open({
                        message: '执行失败',
                        description: result.error,
                        icon: <Icon type="frown" style={{ color: 'red' }} />,
                    });
                }
            }, "/taskMangerExe");
    };
    TerminalTask = (taskId) => {
        const { _pageSize, _current, querys } = this.state;
        this.setState({ loading: true });
        socket.Action("TerminalTask",
            { _id: taskId }, (result) => {
                if (result.retcode === '00') {
                    message.success('终止成功');
                    this.updata(_pageSize, _current, querys);
                } else {
                    message.error('操作失败');
                    this.setState({ loading: false });
                }
            }, "/taskMangerTer");
    };
    updata = (pageSize, pageNum, querys2) => {
        this.setState({ loading: true, });
        let { _pageSize, _current, querys } = this.state;
        querys = querys2 || querys
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys;
        // console.log(querys2);
        // console.log(querys);
        // console.log(bodyJson);
        socket.ExecuteAction("QueryTasksPage",
            bodyJson, "/taskQueryTask", (result) => {
                // console.log(result);
                let tableData = result.tasks;
                tableData.map((item, index) => { item.key = index; return item });
                this.setState({
                    tableData,
                    _current: pageNum || _current,
                    total: result.total,
                    loading: false,
                    querys,
                    _pageSize: pageSize || _pageSize
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };
    updataDev = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize2, _current2 } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize2;
        bodyJson.pageNum = pageNum || _current2;
        bodyJson.querys = querys || {};
        // console.log(bodyJson);
        socket.ExecuteAction('QueryResourcesPage',
            bodyJson, "/queryResource", (result) => {
                let Resources = result.resources;
                Resources.map((item, index) => {
                    item.key = index;
                    item.index = index;
                    return item
                });
                this.setState({
                    DevTableData: Resources,
                    _current2: pageNum || 1,
                    total2: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });

    };
    updataHisReport = (task) => {
        socket.ExecuteAction('QueryInspecRecordList',
            { taskId: task._id }, "", (result) => {
                // console.log(result);
                let HisReportData = result.data;
                if (Utils.isEmpty(HisReportData)) {
                    message.error("数据为空！");
                } else {
                    this.setState({
                        taskId: task._id,
                        visible_recording: true,
                        HisReportData,
                    })
                }
            }, (error) => {
                Utils.showMessage("error", "查询失败", error);
            });
    };
    DeleteOperation = (taskId) => {
        let thisTemp = this;
        socket.ExecuteAction("DeleteTask",
            { _id: taskId }, "/delTask", () => {
                message.success('删除成功');
                thisTemp.updata();
            });
        this.setState({ visible: false });
    }
    handleMenuClick = (e, item) => {
        this.setState({ resItem: item });
        const { _current, _pageSize, querys } = this.state
        let _this = this;
        let bodyJson = {
            pageNum: _current,      //当前页码数
            pageSize: _pageSize,    //每页显示条数
            querys,    //查询条件
            taskId: item._id
        }
        switch (e.key) {
            case 'log':
                break;
            case 'edit':
                let path = {
                    pathname: `/InspectionMan/newInspection`,
                    bodyJson,
                };
                this.props.history.push(path);
                break;
            case 'report':
                this.updataHisReport(item);
                break;
            case 'del':
                confirm({
                    title: '消息提示',
                    content: '你确定删除该条记录吗？',
                    maskClosable: true,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        _this.DeleteOperation(item._id);;
                    },
                    onCancel() { },
                });
                break;
            case 'mod':
                bodyJson = {
                    pathname: this.props.location.pathname,
                    ...bodyJson
                }
                let modPath = {
                    pathname: `/newTask:${item.template}`,
                    bodyJson
                }
                this.props.history.push(modPath);
                // this.setState({ visible_module_flag: true });
                // _this.getModuleGroup(item);
                break;

        }
    }
    menuButton(resItem) {
        return <Menu onClick={(e) => this.handleMenuClick(e, resItem)}>

            <Menu.Item key="edit">
                <Icon type="form" />
                编辑
      </Menu.Item>
            <Menu.Item key="del">
                <Icon type="delete" />
                删除
      </Menu.Item>
        </Menu>
    }
    tabsOnChange(ip, reportList, modalType) {
        this.setState({ TabActiveIp: ip })
        if (!reportList || modalType === "数据") {
            return;
        };
        let html = reportList[ip] && reportList[ip]["report"];
        if (!document.getElementById("tableReport" + ip)) {
            return;
        }
        document.getElementById("tableReport" + ip).innerHTML = html || "";
    }
    resultSort(dataArr) { // ip排序
        dataArr.sort((ip1, ip2) => {
            let arr1 = ip1.split('_')
            let arr2 = ip2.split('_')
            for (let i = 0; i < 4; i++) {
                if (arr1[i] > arr2[i]) {
                    return 1
                } else if (arr1[i] < arr2[i]) {
                    return -1
                }
            }
        })
        this.setState({
            ipsSort: dataArr
        })
    }
    selectTime(time, hisOne, modalType) {
        let _this = this;
        this.setState({ timeSpin: true })
        socket.ExecuteAction('QueryInspecDetail',
            { _id: hisOne.taskId, createTime: time }, "", (result) => {
                this.resultSort(Object.keys(result.data));
                let { ipsSort } = this.state;
                // let TabActiveIp = Object.keys(result.data)[0] || "";
                let TabActiveIp = this.state.ipsSort[0] || "";
                let reportList = {};
                ipsSort.map((item, index) => {
                    reportList[item] = result.data[item];
                })
                this.setState({
                    hisTime: time,
                    // reportList: result.data,
                    reportList: reportList,
                    timeSpin: false
                });

                _this.tabsOnChange(TabActiveIp, result.data, modalType)
            }, (error) => {
                Utils.showMessage("error", "查询失败", error);
                _this.setState({ timeSpin: false })
            });
    }
    // getTabsInspData(data) {
    //     let params = [];
    //     for (let lab in data) {
    //         let val = data[lab];
    //         let forMatVal = typeof val === "object" ? val[0] : val;
    //         params.push(<Tabs.TabPane key={lab} tab={<span><Icon type="file-text" />{lab}</span>}><MonacoEditor
    //             height={700}
    //             language="java"
    //             theme="vs"
    //             value={forMatVal}
    //             options={{ selectOnLineNumbers: false, readOnly: true }}
    //         /></Tabs.TabPane>);
    //     }
    //     return <div style={{ border: "1px solid #e2e2e2" }}><Tabs tabPosition={"left"}
    //     >{params}</Tabs></div>
    // }
    getTabsInspData(data) {
        const resultKey = Object.keys(data);
        // return <div className={"dataDiv"} >
        //     <Descriptions column={1} bordered>
        //         {resultKey.map((key) => {
        //             return <Descriptions.Item label={key} key={key}>
        //                 {(("" + data[key]).length < 200) ? data[key] :
        //                     <Collapse>
        //                         <Collapse.Panel header="详情" key={key}>
        //                             <MonacoEditor
        //                                 language="java"
        //                                 theme="vs-dark"
        //                                 height="500"
        //                                 options={{ minimap: { enabled: false } }}
        //                                 value={data[key] + ""}
        //                             />
        //                         </Collapse.Panel>
        //                     </Collapse>
        //                 }
        //             </Descriptions.Item>

        //         })}
        //     </Descriptions>
        // </div>
        return <div className={"dataDiv"} > <Collapse accordion>
            {resultKey.map((key) => {
                return <Collapse.Panel header={key} key={key}>
                    <MonacoEditor
                        language="java"
                        theme="vs-dark"
                        height="500"
                        options={{ minimap: { enabled: false }, scrollBeyondLastLine: false, }}
                        value={data[key] + ""}
                    />
                </Collapse.Panel>
            })}
        </Collapse>
        </div>
    }
    showDeleteConfirm(data) {
        confirm({
            title: '巡检数据',
            maskClosable: true,
            content: <div style={{ paddingTop: 10 }}>{this.getTabsInspData(data)}</div>,
            width: "80%",
            icon: <Icon type="file-markdown" />
        });
    }
    renderTabs() {
        let { reportList, modalType } = this.state;
        if (!reportList) {
            return;
        }
        let elemt = []
        for (let ip in reportList) {
            let data = reportList[ip];
            let reg = new RegExp("_", "g");
            let newIp = ip.replace(reg, ".");
            let tempElem = <Tabs.TabPane tab={newIp} key={ip} forceRender={true}>
                {modalType === "报告" ? <div id={"tableReport" + ip} style={{ width: "70%" }}></div> : this.getTabsInspData(data.data)}
            </Tabs.TabPane>
            elemt.push(tempElem);
        }
        return elemt;
    }
    exportReport() {
        let { reportList, hisTime, selectIp } = this.state;
        let elemt = "<h1>设备巡检报告</h1>";

        selectIp.map((ip) => {
            let data = reportList[ip];
            elemt += "<h3>设备:" + ip + "</h3>" + data["report"];
        })
        let timeName = (hisTime.split(" "))[0];
        exportWord({
            mhtml: elemt,
            data: { title: "exportword" },
            filename: "设备巡检报告" + timeName,
            style: '.strong {font-weight: bold;width: 140px;}.IniTable {border-collapse: collapse;font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;}.IniTable td {padding-left: 6px;border: 1px solid black;width: 170px;height: 30px;}'
        })
    }
    exportOneReport(ip) {
        let { reportList, hisTime } = this.state;
        let elemt = "<h1>设备" + ip + "巡检报告</h1>";
        let data = reportList[ip];
        elemt += data["report"];
        let timeName = (hisTime.split(" "))[0];
        exportWord({
            mhtml: elemt,
            data: { title: "exportword" },
            filename: "设备" + ip + "巡检报告" + timeName,
            style: '.strong {font-weight: bold;width: 140px;}.IniTable {border-collapse: collapse;font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;}.IniTable td {padding-left: 6px;border: 1px solid black;width: 170px;height: 30px;}'
        })
    }
    exportInspData() {
        let { reportList, hisTime, selectIp } = this.state;
        let ZipData = [];
        selectIp.map((ip) => {
            // console.log(ip)
            let data = reportList[ip]["data"];
            let elemt = "\r\n设备" + ip + "巡检数据\r\n";
            for (let lab in data) {
                let text = data[lab];
                elemt += "#################################################################################\r\n" + text + "\r\n";
            }
            ZipData.push({ name: ip, data: elemt })
        })
        let timeName = (hisTime.split(" "))[0];
        Utils.downloadZIP("巡检数据" + timeName, ZipData);
    }
    exportOneInspData(ip) {
        let { reportList, hisTime } = this.state;
        let elemt = "\r\n设备" + ip + "巡检数据\r\n";
        let data = reportList[ip]["data"];
        for (let lab in data) {
            let text = data[lab];
            elemt += "#################################################################################\r\n" + text + "\r\n";
        }
        let timeName = (hisTime.split(" "))[0];
        Utils.download("设备" + ip + "巡检数据" + timeName + ".txt", elemt);
    }
    render() {
        let { _current, _pageSize, visible_insp, HisReportData, hisTime, reportList, visible_recording,
            progressObj, elapsedTimeObj, modalType, timeSpin, TabActiveIp, moduleName, visible_module_flag, flow_data, templateObj,
            tableData, querys } = this.state;
        const columns = [
            {
                title: '任务名称',
                align: 'left',
                width: 150,
                dataIndex: 'taskName',
                ellipsis: true,
            }, {
                title: '状态',
                align: 'center',
                width: 80,
                dataIndex: 'taskStatus',
                render: (text) => {
                    // return (<span style={{color:Utils.ColorShow(text),fontWeight:'600'}}>{Utils.Translation(text)}</span>)
                    return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>)
                }
            }, {
                title: '标签',
                align: 'left',
                width: 80,
                dataIndex: 'tags',
                render: (text) => {
                    return Utils.hidenTag(text);
                }
            }, {
                title: '任务进度',
                align: 'center',
                width: 200,
                dataIndex: 'detail',
                render: (text, item) => {
                    let progress = progressObj.hasOwnProperty(item.taskName) ? progressObj[item.taskName] : 0;
                    // let progress = parseInt(Math.random()*99+1);
                    // return <Progress percent={progress} status="active" strokeColor={progress<80?(progress>30?"#0079fe":"#fb6260"):"#00cc00"} />
                    let taskStatus = item.taskStatus;
                    return <Progress percent={taskStatus === 'done' ? 100 : progress}
                        status={taskStatus === 'run' ? "active" : (taskStatus === 'done' ? "success" : "normal")}
                    />
                }
            }, {
                title: '方式',
                align: 'center',
                width: 80,
                dataIndex: 'taskPlan.type',
                render: (text) => {
                    return (<span>{Utils.Translation(text)}</span>)
                }
            }, {
                title: '开始时间',
                align: 'center',
                width: 150,
                dataIndex: 'taskBeginTime'
            }, {
                title: '下次执行时间',
                align: 'center',
                width: 150,
                dataIndex: 'nextTime'
            }, {
                title: '耗时',
                align: 'center',
                width: 150,
                dataIndex: 'costing',
                render: (text, item) => {
                    let elapsedTime = elapsedTimeObj.hasOwnProperty(item.taskName) ? elapsedTimeObj[item.taskName] : text;
                    return <span>{elapsedTime}</span>
                }
            }, {
                title: '操作',
                align: 'center',
                width: 180,
                dataIndex: 'op',
                fixed: "right",
                render: (text, itemTask) => {
                    return <div>
                        {itemTask.taskStatus === 'run' ?
                            <Button type="danger" size="small"
                                onClick={() => { this.TerminalTask(itemTask._id) }}>
                                <Icon type="pause-circle" />终止</Button>
                            : <Button type="primary" size="small"
                                onClick={() => { this.excuteTask(itemTask._id, itemTask.taskName) }}>
                                <Icon type="play-circle" />执行</Button>}&nbsp;
                            <Dropdown.Button overlay={this.menuButton(itemTask)}
                            type="primary" icon={<Icon type="down" />} size="small"
                            onClick={() => { this.handleMenuClick({ key: "report" }, itemTask) }}
                        ><Icon type="diff" />记录
                            </Dropdown.Button>
                    </div>
                }
            }
        ];
        return (
            <div>
                <CustomBreadcrumb arr={["运维管理", "设备巡检"]} />
                <Collapse defaultActiveKey={"key"}>
                    <Collapse.Panel header="筛选条件" key={"key"}>
                        <FilterForm
                            tagClass="task"
                            filterSubmit={(_querys) => {
                                _querys.ifTemplate = false;
                                _querys.reportTags = "巡检";
                                this.updata(this.state._pageSize, 1, _querys)
                            }}
                            resetTab={() => {
                                const querys = {
                                    ifTemplate: false,
                                    reportTags: "巡检"
                                }
                                this.updata(this.state._pageSize, 1, querys)
                            }}
                        />
                    </Collapse.Panel>
                </Collapse>
                <Card title={<span style={{ fontWeight: 600 }}>巡检任务列表</span>}
                    style={{ marginTop: 8 }}
                    extra={
                        <div>
                            <Button type="primary"
                                icon="edit" onClick={() => {
                                    let bodyJson = {
                                        pageNum: _current,      //当前页码数
                                        pageSize: _pageSize,    //每页显示条数
                                        querys,    //查询条件
                                    }
                                    let path = {
                                        pathname: `/InspectionMan/newInspection`,
                                        bodyJson,
                                    };
                                    this.props.history.push(path);
                                }}>添加巡检</Button>
                        </div>}>
                    {/* 
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        rowKey={(text) => { return text._id }}
                        size="small"
                        loading={this.state.loading}
                        // scroll={{ x: 1500 }}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                this.updata(pageSize, page, querys);
                            }
                        }}
                    /> */}
                    <DyHeadTable
                        type="Inspect"
                        dataSource={tableData}
                        columns={columns}
                        rowKey={(text) => { return text._id }}
                        size="small"
                        loading={this.state.loading}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                this.updata(pageSize, page, querys);
                            }
                        }}
                        handleMenuClick={(e, item) => { this.handleMenuClick(e, item) }}
                        excuteTask={(id, name) => { this.excuteTask(id, name) }}
                        TerminalTask={(id) => { this.TerminalTask(id) }}
                    />

                </Card>
                <Modal
                    title={<div><Text strong>巡检{modalType || ''}</Text>&nbsp;&nbsp;&nbsp;
                        巡检时间:
                        <Select
                            style={{ width: 300, marginLeft: 20 }}
                            value={hisTime}
                            onChange={(e) => this.selectTime(e, HisReportData[0] || null, modalType)}>
                            {HisReportData.map((item, key) => (
                                <Select.Option key={key} value={item.createTime}>{item.createTime}</Select.Option>
                            ))}
                        </Select>&nbsp;&nbsp;
                        <Button type="primary" icon="export" onClick={() => {
                            let _this = this;
                            confirm({
                                title: "选择导出的设备",
                                content: <CheckIpList reportList={reportList} onChange={(e) => _this.setState({ selectIp: e })} />,
                                maskClosable: true,
                                icon: <Icon type="export" />,
                                okText: '确定',
                                cancelText: '取消',
                                onOk() {
                                    if (modalType === "报告") { _this.exportReport() } else { _this.exportInspData() }
                                },
                                onCancel() { },
                            })
                        }}>导出巡检{modalType}</Button>&nbsp;
                        <Button type="primary" icon="export" onClick={() => {
                            if (modalType === "报告") { this.exportOneReport(TabActiveIp) } else { this.exportOneInspData(TabActiveIp) }
                        }}>导出{TabActiveIp.replace(new RegExp("_", "g"), ".") + modalType}
                        </Button>
                    </div>}
                    visible={visible_insp}
                    onOk={() => { this.setState({ visible_insp: false }) }}
                    onCancel={() => { this.setState({ visible_insp: false }) }}
                    footer={null}
                    style={{ top: 10, }}
                    width={modalType === "报告" ? "70%" : "95%"}
                    destroyOnClose
                >
                    <Spin spinning={timeSpin}>
                        <Tabs
                            activeKey={TabActiveIp}
                            onChange={(key) => this.tabsOnChange(key, reportList, modalType)}
                            tabPosition={"left"} style={{ height: "99%" }}>
                            {this.renderTabs()}
                        </Tabs>
                    </Spin>
                </Modal>
                <Modal
                    title={<div><Text strong>巡检历史记录</Text></div>}
                    visible={visible_recording}
                    onOk={() => { this.setState({ visible_recording: false }) }}
                    onCancel={() => { this.setState({ visible_recording: false }) }}
                    footer={null}
                    style={{ top: 30, }}
                    width="70%"
                    forceRender
                >
                    <Table
                        columns={[{ title: "序号", render: (a, b, c) => { return <span >{c + 1}</span> } }, { title: "时间", dataIndex: "createTime" }, { title: "执行人", dataIndex: "username", }, {
                            title: "操作",
                            render: (text, HisItem) => {
                                return <div >
                                    <Button icon="diff" type="primary"
                                        onClick={() => {
                                            let _this = this;
                                            this.setState({ modalType: "报告", visible_insp: true })
                                            setTimeout(function () {
                                                _this.selectTime(HisItem["createTime"], HisItem);
                                            }, 10);

                                        }}
                                    >报告</Button>&nbsp;
                                    <Button icon="file-text" type="primary"
                                        onClick={() => {
                                            this.setState({ modalType: "数据", visible_insp: true });
                                            let _this = this;
                                            setTimeout(function () {
                                                _this.selectTime(HisItem["createTime"], HisItem, "数据");
                                            }, 10);
                                        }}
                                    >巡检数据</Button>
                                </div>
                            }
                        }]}
                        size="small"
                        dataSource={HisReportData}
                    // pagination={
                    //     {current: 1, pageSize: 10, total:20,}
                    // }
                    />

                </Modal>
                {/* 模版弹窗 */}
                <Modal
                    title={
                        <div>
                            <span>模版：</span>
                            <a onClick={() => {
                                let path = {
                                    pathname: `/newTask:${moduleName._id}`,
                                    bodyJson: {},
                                    actType: this.state.actType
                                };
                                this.props.history.push(path);
                            }}> {moduleName.taskName} </a>
                        </div>
                    }
                    visible={visible_module_flag}
                    onCancel={() => { this.setState({ visible_module_flag: false }); }}
                    onOk={() => { this.setState({ visible_module_flag: false }); }}
                    width="95%"
                    style={{ top: 10, bottom: 10 }}
                >
                    <div>绑定报告：<Button size="small" style={{ border: 0, color: "#1890ff" }} icon="link" onClick={() => { this.openReport(templateObj); }}>{templateObj.name}</Button></div>
                    <FlowChar
                        onRef={(_this) => { this.child = _this }}
                        initValue={flow_data}
                        onFlowDataChange={(data) => {
                            // 导出文件
                            this.setState({ flow_data: data })
                        }}
                    />
                </Modal>
            </div>
        )
    }
}

const CheckboxGroup = Checkbox.Group;



class CheckIpList extends React.Component {
    state = {
        checkedList: [],
        plainOptions: [],
        indeterminate: false,
        checkAll: false,
    };
    componentWillMount() {
        let reportList = this.props.reportList;
        let IpList = Object.keys(reportList).map((ip) => {
            // return ip.replace(new RegExp("_", "g"), ".");
            return ip;
        });
        this.setState({ plainOptions: IpList })
    }

    onChange = checkedList => {
        let { plainOptions } = this.state;
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
            checkAll: checkedList.length === plainOptions.length,
        });
        // console.log(checkedList)
        this.props.onChange(checkedList);
    };

    onCheckAllChange = e => {
        let { plainOptions } = this.state;
        this.setState({
            checkedList: e.target.checked ? plainOptions : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        this.props.onChange(e.target.checked ? plainOptions : []);
    };

    render() {
        let { plainOptions } = this.state;
        return (
            <div>
                <div style={{ borderBottom: '1px solid #E9E9E9' }}>
                    <Checkbox
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                    >
                        全选
                    </Checkbox>
                </div>
                <br />
                <CheckboxGroup
                    options={plainOptions}
                    value={this.state.checkedList}
                    onChange={this.onChange}
                />
            </div>
        );
    }
}

export default InspectionMan