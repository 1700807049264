import React, { useState, useEffect } from 'react'
import {
    Card, Table, Modal, Icon, Button, message, Switch, Dropdown, Menu, DatePicker, Tag
} from "antd";
const { RangePicker } = DatePicker;
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import DyHeadTable from '@/components/DyHeadTable/index';
import FilterForm from '@/components/FilterForm/index';
import http from '@/axios/axios_utils';
import { showDetail, numberToText } from '../utils/index';
import { alarmRecords, alarmRecordTmp, alarmRecordDetail, alarmRecordTmpExport } from '../utils/api';
import BaseForm from '../utils/baseForm';
import FilesUtils from "@/utils/FilesUtils";
import { connect } from 'react-redux'
import moment from 'moment';
import "./index.scss"
import "animate.css"
//告警记录
function AlertRecord(props) {
    const [visible, setVisible] = useState(false);
    const [recordVis, setRecordVis] = useState(false);
    const [notifyWayVis, setNotifyWayVis] = useState(false);
    const [recordStatus, setRecordStatus] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalData, setModalData] = useState({});
    const [total, setTotal] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const [selectItem, setSelectItem] = useState(null);
    const [selectedRowKeys, setRowKeys] = useState([])
    const [tableData, setTabData] = useState([])
    const [exportTime, setExportTime] = useState(null)
    const [exportVis, setExportVis] = useState(false);
    const columns = [
        {
            title: '规则名称',
            dataIndex: 'ruleName',
            width: 160,
            ellipsis: true,
        },
        {
            title: '规则描述',
            dataIndex: 'description',
            width: 200,
            ellipsis: true,
        },
        {
            title: '当前状态',
            dataIndex: 'status',
            ellipsis: true,
            width: 100,
            render: (text) => { return numberToText("status", text) }
        },
        {
            title: '告警值',
            dataIndex: 'value',
            width: 50,
            ellipsis: true,
        },
        {
            title: 'IP',
            dataIndex: 'label',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return JSON.parse(text).ip
            }
        },
        {
            title: '告警等级',
            dataIndex: 'level',
            width: 100,
            ellipsis: true,
            render: (text) => { return numberToText("level", text) }
        },
        {
            title: '触发时间',
            dataIndex: 'triggerTime',
            width: 140,
            ellipsis: true,
        },
        {
            title: '恢复时间',
            dataIndex: 'recoverTime',
            width: 140,
            ellipsis: true,
        },
        {
            title: '告警方式',
            dataIndex: 'notifyWay',
            width: 140,
            ellipsis: true,
            render: (text) => {
                if (typeof text === "object") {
                    return text.map((t) => numberToText("notifyWay", t));
                } else {
                    return numberToText("notifyWay", text);
                }
            }
        },
        {
            title: '操作',
            width: 140,
            fixed: 'right',
            dataIndex: 'op',
            render: (text, item) => {
                return <div>
                    <a style={{ marginRight: 8 }} onClick={() => {
                        setRecordStatus(item.status === 0 ? false : true) // 初始化告警状态
                        setSelectItem(item); setRecordVis(true)
                    }}><Icon type="edit" />&nbsp;编辑</a>
                    <a onClick={() => {
                        detailData(item.alarmId, item)
                    }}><Icon type="file-text" />&nbsp;详情</a>
                </div>
            }
        },
    ]
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
        },
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    let scrollX = 0;
    columns.map((item, index) => {
        scrollX += item.width;
    })
    scrollX = scrollX + 260;

    useEffect(() => {//componentDidMount;
        getData();
        http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(page = 1, size = _pageSize, query = {}) {
        http.post(alarmRecords(), { page, size, ...query, userId: sessionStorage.getItem("userId") }, (r) => {
            setTabData(r.data.records);
            setTotal(r.data.total);
            set_querys(query);
            set_current(page);
            set_pageSize(size)
        })
    }
    function deleteRecord() {
        if (selectedRowKeys.length <= 0) {
            return message.error("请选择需要删除的记录！")
        }
        Modal.confirm({
            title: '消息提示',
            content: '你确定删除该记录吗？',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                http.delete_body(alarmRecordTmp(), { alarmIds: selectedRowKeys }, (r) => {
                    if (r.code === 0) {
                        message.success(r.msg);
                        getData()
                    } else {
                        message.error(r.msg)
                    }
                })
            },
        });
    }
    function statusChange() {
        let bodyJson = {
            alarmId: selectItem.alarmId,
            status: recordStatus ? 1 : 0,
        }
        http.put(alarmRecordTmp(), bodyJson, (r) => {
            if (r.code === 0) {
                message.success(r.msg);
                setRecordVis(false)
                getData(_current, _pageSize, _querys)
            } else {
                message.error(r.msg)
            }
        })
    }
    function detailData(id, items) {
        http.get(alarmRecordDetail(), { alarmId: id }, (r) => {
            if (r.code === 0) {
                let recordDetails = r.data.map(dataItem => {
                    return dataItem.recordDetails
                })
                setVisible(true);
                console.log(items, r.data)
                setSelectItem({ ...items, recordDetails: r.data });
            } else {
                message.error(r.msg)
            }
        })
    }
    function viewNotifyWay(type, item) {
        if (type === 0) {
            setModalTitle("邮件")
            setModalData(item.emailSendContent || {})
        } else if (type === 1) {
            setModalTitle("短信")
            setModalData(item.smsSendContent || {})
        }
        setNotifyWayVis(true)
    }
    function exportRecord(allFlag) {
        let bodyJson = {
            is_all: allFlag ? true : false,
            startTime: (exportTime && exportTime.length !== 0) ? moment(exportTime[0]).format("YYYY-MM-DD HH:mm:ss") : '',
            endTime: (exportTime && exportTime.length !== 0) ? moment(exportTime[1]).format("YYYY-MM-DD HH:mm:ss") : '',
            userId: sessionStorage.getItem("userId")
        }
        if (!allFlag) {
            if (exportTime && exportTime.length !== 0) { }
            else { return message.error("请选择导出时间段！") }
        }
        let fileName = "告警记录-" + moment(new Date()).format('YYYYMMDDHHmmss')
        // console.log(bodyJson, exportTime)
        FilesUtils.exportFiles(alarmRecordTmpExport(), bodyJson, fileName, () => {
            setExportVis(false); setExportTime(null);
        })
    }
    return <div className="monitorAlertRecord">
        <CustomBreadcrumb arr={["监控告警", "告警记录"]} />
        <Card title="告警记录" extra={<div>
            <Dropdown.Button className="dropDownBtn" type='primary' icon={<Icon type="down" />} style={{ marginRight: 8 }}
                onClick={() => setExportVis(true)}
                overlay={
                    <Menu onClick={() => { exportRecord("is_all") }}>
                        <Menu.Item><Icon type="export" />导出全部告警记录</Menu.Item>
                    </Menu>
                }
            > <Icon type='export' /> 导出告警记录
            </Dropdown.Button>
            <Button type="danger" icon="delete" onClick={() => { deleteRecord() }}>删除</Button>
        </div>}>
            <div className="searchDiv">
                <FilterForm tagClass="alertRecord"
                    filterSubmit={(val) => {
                        const rangeTime = val.rangeTime;
                        let filter = {
                            ruleName: val.ruleName,
                            sort: val.sort,
                            status: val.status,
                            level: val.level,
                            startTime: rangeTime && moment(rangeTime[0]).format("YYYY-MM-DD HH:mm:ss"),
                            endTime: rangeTime && rangeTime[1] && moment(rangeTime[1]).format("YYYY-MM-DD HH:mm:ss"),
                            ip: val.ip,
                            areaId: val.areaId,
                        }
                        getData(1, _pageSize, filter)
                    }}
                    resetTab={() => { getData(1, _pageSize, {}) }}
                />
            </div>
            <DyHeadTable
                dataSource={tableData}
                rowSelection={rowSelection}
                columns={columns}
                size="small"
                rowKey={r => r.alarmId}
                pagination={
                    {
                        current: _current, pageSize: _pageSize, total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            getData(1, size, _querys)
                        },
                        onChange: (page, pageSize) => { getData(page, pageSize, _querys) }
                    }
                }
            />
        </Card>
        <Modal
            title="告警记录详情"
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={() => setVisible(false)}
            width={"70%"}
            destroyOnClose
            style={{ top: 50 }}
            className="alertRecordModal"
        >

            {selectItem && showDetail([
                { label: "规则名称", value: selectItem["ruleName"] },
                { label: "告警状态", value: numberToText("status", selectItem["status"]) },
                { label: "告警值", value: selectItem["value"] },
                { label: "标签", value: selectItem["label"] },
                { label: "告警等级", value: numberToText("level", selectItem["level"]) },
                { label: "告警描述", value: selectItem["description"] },
                { label: "触发时间", value: selectItem["triggerTime"] },
                { label: "恢复时间", value: selectItem["recoverTime"] },
                {
                    // label: "通知方式", value: (typeof selectItem["notifyWay"] === "object") ? selectItem["notifyWay"].map((t) => numberToText("notifyWay", t)) : numberToText("notifyWay", selectItem["notifyWay"]),
                    // clickIcon: (item, e) => { console.log("clickIcon", item, e.target) }
                    label: "通知详情", value: selectItem["recordDetails"],
                    clickIcon: (type, item) => { viewNotifyWay(type, item) }
                },
            ])}
        </Modal>
        <Modal
            title="编辑告警记录"
            visible={recordVis}
            onCancel={() => setRecordVis(false)}
            onOk={() => statusChange()}
            width={"50%"}
            className="alertRecordEditModal"
            destroyOnClose
        >
            {selectItem ? <div>
                当前告警状态：
                <Switch defaultChecked={selectItem.status === 0 ? false : true}
                    checkedChildren="已解决" unCheckedChildren="未解决" onChange={(checked) => setRecordStatus(checked)} />
            </div> : ''}
        </Modal>
        <Modal
            width={800}
            title={modalTitle + '通知'}
            visible={notifyWayVis}
            onCancel={() => setNotifyWayVis(false)}
            onOk={() => setNotifyWayVis()}
            footer={null}
            className="emailListModal"
            destroyOnClose
        >
            <div className="emailListModalDiv">
                <table className="emailTab"><tbody>
                    {modalTitle === '邮件' ? <>
                        {/* 邮件 */}
                        <tr>
                            <td className="title">主题:</td>
                            <td>{modalData.title}</td>
                        </tr>
                        <tr>
                            <td className="title">发件人:</td>
                            <td><Icon type="user" />{modalData.sender}</td>
                        </tr>
                        <tr>
                            <td className="title">收件人:</td>
                            <td><Icon type="mail" />{modalData.receivers}</td>
                        </tr>
                    </> : <>
                            {/* 短信 */}
                            <tr>
                                <td className="title">接收人:</td>
                                <td style={{ display: 'flex' }}><Icon type="user" />
                                    <span className='wrapSpan'>{typeof (modalData.phones) === "object" ? modalData.phones.join('，') : modalData.phones}</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="title">状态:</td>
                                <td><Icon type="message" />{modalData.status === 'ok' ? <Tag color="green">成功</Tag> : <Tag color="red">失败</Tag>}</td>
                            </tr>
                            <tr>
                                <td className="title">提示:</td>
                                <td><Icon type="bulb" />{modalData.tips}</td>
                            </tr>
                        </>}

                    <tr>
                        <td className="title">时间:</td>
                        <td><Icon type="clock-circle" />{modalData.send_time || modalData.time}</td>
                    </tr>
                </tbody></table>

                <div id={"detail"} style={{ maxHeight: 660, overflowY: 'auto' }} className={ modalTitle === '邮件' ? '' : 'newLine' }
                    dangerouslySetInnerHTML={{ __html: modalData.content }}
                />
            </div>
        </Modal>
        <Modal title="选择导出告警记录的时间段" visible={exportVis} destroyOnClose
            onCancel={() => { setExportVis(false); setExportTime(null) }}
            onOk={() => exportRecord()}
        >
            导出时间段：
            <RangePicker
                showTime={{ format: 'HH:mm:ss', defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }}
                format="YYYY-MM-DD HH:mm:ss"
                placeholder={['开始时间', '结束时间']}
                onChange={(values) => setExportTime(values)}
            />
        </Modal>
    </div>
}

export default connect()(AlertRecord)