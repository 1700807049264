/**
 * Created by YIMINE on 2019/5/22.
 */
import React from 'react'
import DataSet from "@antv/data-set";
import Socket from "@/socket/index";
import OverlapHistogram from '@/components/OverlapHistogram/index';
import LineChar from '@/components/LineChar/index';
import PieChar from '@/components/PieChar/index';
import {Card,List,notification} from "antd";
import {
    G2,
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Label,
    Legend,
    View,
    Guide,
    Shape,
    Facet,
    Util
} from "bizcharts";
export default class CharListClass extends React.Component{
    state = {
        dataList:[],
        charType:'line'
    };
    componentWillMount() {
        let {charType} = this.props;
        this.updataCharData();
        this.setState({charType});
    }

    updataCharData = () => {
        this.setState({oidLoading:true});
        Socket.Action('OidFirstPage',
            {"taskId": "1111"}, (result) =>{
                if(result.retcode === '00'){
                    let data = result.data;
                    let dataList = [];
                    data.ipData.map((item)=>{
                        // console.log(item.chartData);
                        dataList.push({
                            title:item.deviceIp+'-'+item.deviceFirm+'-'+item.deviceType+'-'+item.deviceVersion+'-'+item.deviceOsVersion,
                            deviceIp:item.deviceIp,
                            data:item.chartData
                        })
                    });
                    let temp = data.ipList;
                    let selectList = temp.map((item)=>{
                        if(item.selected===1){
                            return item;
                        }
                    });
                    this.setState({dataList,OidFirstPage:data,selectList,oidLoading:false})
                }else {
                    notification.open({
                        message: '失败',
                        description: result.error,
                        icon: <Icon type="frown" style={{ color: 'red' }} />,
                    });
                }
            });
    };

    render(){
        let {dataList,charType}=this.state;
        return (
            <List
                grid={{ gutter: 10, column: 2 }}
                dataSource={dataList}
                // loading={this.state.oidLoading}
                renderItem={(item,key) => {
                    return (<List.Item key={key}>
                            <Card title={item.title} >
                                {
                                    charType==='histogram'?<OverlapHistogram charData={item.data}/>
                                    :(charType==='line'?<LineChar/>:<PieChar/>)
                                }
                            </Card>
                        </List.Item>
                    )
                }}
            />
        );
    }
}