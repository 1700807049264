import React from 'react'
import {
    Card, Table, Button, message, Popconfirm, Icon, Input, Select, Row, Col, Form,
    Modal, Radio, InputNumber, Typography, TimePicker, DatePicker
} from "antd";
import Socket from "../../../socket/index";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import Utils from '@/utils/utils';
import ResUtils from '@/utils/ResUtils';
// import TabForm from './tableForm/TableForm';
import TabForm from '@/components/EditTab/index'
import moment from 'moment';
import "./index.scss"
const { Text } = Typography;
const NowTime = new Date();
class AddForm extends React.Component {
    state = {
        fromList: [
            // { fieId: "ip", label: "设备IP", initialValue: selectNode.ip },
            // { fieId: "os_type", label: "系统类型", initialValue: selectNode.os_type, elemType: "select", list: [{ id: "cisco_ios", name: "cisco_ios" }, { id: "hp_comware", name: "hp_comware" }, { id: "huawei", name: "huawei" }] },
            // { fieId: "username", label: "ssh用户名", initialValue: selectNode.username },
            // { fieId: "password", label: "ssh登录密码", initialValue: selectNode.password, elemType: "password" },
            // { fieId: "total_time", label: "备份时长(月)", type: "number", initialValue: selectNode.total_time },
            // { fieId: "backup_count", label: "备份次数", type: "number", initialValue: selectNode.backup_count },
            // { fieId: "sys_pwd", label: "super密码或者enbale密码", initialValue: selectNode.sys_pwd || selectNode.password, elemType: "password" },
            { fieId: "backup_cycle", label: "自动备份周期", type: "number", initialValue: this.props.selectNode.backup_cycle || 1 },
            { fieId: "time_type", label: "周期时间类型", initialValue: this.props.selectNode.time_type || 'day', elemType: "select", list: [{ id: "hour", name: "小时" }, { id: "day", name: "天" }, { id: "week", name: "周" }] },
            { fieId: "backup_type", label: "备份类型", initialValue: this.props.selectNode.backup_type || 'text', elemType: "select", list: [{ id: "text", name: "text" }, { id: "ftp", name: "ftp" }] },
            // { fieId: "config_name", label: "备份文件名", initialValue: this.props.selectNode.config_name || '无', elemType: "input" },
            { fieId: "total_time", label: "备份保存时长(月)", initialValue: this.props.selectNode.total_time || 24, elemType: "number" },
            { fieId: "start_date", label: "开启时间", initialValue: this.props.selectNode.start_date, elemType: "datePick" },
        ]
    }
    componentWillMount() {
        this.props.onThis(this);
        console.log(this.props.selectNode)
    }
    handleSubmit = e => {
        e.preventDefault();
        let _this = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                _this.props.onSubmit(values);
                _this.props.form.resetFields();
            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { selectNode } = this.props;

        let { fromList } = this.state
        return <div className="addFormDiv">
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} onSubmit={this.handleSubmit}>

                {
                    fromList.map((Item, key) => {
                        if (Item["elemType"] === "select") {
                            return <Form.Item
                                key={key}
                                label={Item.label}
                                hasFeedback
                            >
                                {getFieldDecorator(Item["fieId"], {
                                    initialValue: Item["initialValue"] || "",
                                    rules: [{
                                        type: Item["type"] || "string",
                                        required: true, message: '请输入字符!',
                                    }],

                                })(<Select style={{ width: 400 }} onChange={(e) => {
                                    // if (e === "ftp") {
                                    //     this.setState({
                                    //         fromList: [
                                    //             { fieId: "backup_type", label: "备份类型", initialValue: selectNode.backup_type || 'text', elemType: "select", list: [{ id: "text", name: "text" }, { id: "ftp", name: "ftp" }] },
                                    //         ]
                                    //     })
                                    // } else {
                                    //     this.setState({
                                    //         fromList: [
                                    //             { fieId: "backup_cycle", label: "自动备份周期", type: "number", initialValue: this.props.selectNode.backup_cycle || 1 },
                                    //             { fieId: "time_type", label: "周期时间类型", initialValue: this.props.selectNode.time_type || 'day', elemType: "select", list: [{ id: "hour", name: "小时" }, { id: "day", name: "天" }, { id: "week", name: "周" }] },
                                    //             { fieId: "backup_type", label: "备份类型", initialValue: this.props.selectNode.backup_type || 'text', elemType: "select", list: [{ id: "text", name: "text" }, { id: "ftp", name: "ftp" }] },
                                    //             { fieId: "total_time", label: "备份保存时长(月)", initialValue: this.props.selectNode.total_time || 24, elemType: "number" },
                                    //             { fieId: "start_date", label: "开启时间", initialValue: this.props.selectNode.start_date, elemType: "datePick" },
                                    //         ]
                                    //     })
                                    // }
                                }}>
                                    {Utils.getOptionList(Item.list)}
                                </Select>)}
                            </Form.Item>
                        } else if (Item["elemType"] === "number") {
                            return <Form.Item
                                key={key}
                                label={Item.label}
                                hasFeedback
                            >
                                {getFieldDecorator(Item["fieId"], {
                                    initialValue: Item["initialValue"] || 24,
                                    rules: [{
                                        // type: Item["type"] || "number",
                                        required: true, message: '请输入!',
                                    }],
                                    // getValueFromEvent: (event) => {
                                    //     // console.log(parseInt(event.target.value))
                                    //     return Item["type"] === "number" ? parseInt(event.target.value || "0") : event.target.value;
                                    // },
                                })(<InputNumber style={{ width: 400 }} max={1000} min={0} />)}
                            </Form.Item>
                        } else if (Item["elemType"] === "datePick") {
                            return <Form.Item
                                key={key}
                                label={Item.label}
                                hasFeedback
                            >
                                {getFieldDecorator("start_date", {
                                    initialValue: moment(Item["initialValue"] || Utils.formateDate(NowTime), "YYYY-MM-DD HH:mm:ss "),
                                    rules: [{
                                        required: true, message: '请选择!',
                                    }],
                                })(
                                    <DatePicker style={{ width: 400 }} showTime placeholder="选择时间" format="YYYY-MM-DD HH:mm:ss" />
                                )}
                            </Form.Item>
                        } else {
                            return <Form.Item
                                key={key}
                                label={Item.label}
                                hasFeedback
                            >
                                {getFieldDecorator(Item["fieId"], {
                                    initialValue: Item["initialValue"] || "",
                                    rules: [{
                                        type: Item["type"] || "string",
                                        required: true, message: '请输入字符!',
                                    }],
                                    getValueFromEvent: (event) => {
                                        // console.log(parseInt(event.target.value))
                                        return Item["type"] === "number" ? parseInt(event.target.value || "0") : event.target.value;
                                    },
                                })(<Input style={{ width: 400 }} type={Item["elemType"] === "password" ? "password" : "text"} />)}
                            </Form.Item>
                        }


                    })
                }
                {/* <Form.Item label={"备份保存时长(月)"} >
                    {getFieldDecorator("total_time", {
                        initialValue: selectNode.total_time || 24,
                        rules: [{
                            // pattern: /^(([1-9])|(1[0-2]))$/,
                            required: true, message: '请选择!',
                        }],
                    })(
                        <InputNumber style={{ width: 400 }} max={1000} min={0} />
                    )}
                </Form.Item>
                <Form.Item label={"开启时间"} >
                    {getFieldDecorator("start_date", {
                        initialValue: moment(selectNode.start_date || Utils.formateDate(NowTime), "YYYY-MM-DD HH:mm:ss "),
                        rules: [{
                            required: true, message: '请选择!',
                        }],
                    })(
                        <DatePicker style={{ width: 400 }} showTime placeholder="选择时间" format="YYYY-MM-DD HH:mm:ss" />
                    )} 
                </Form.Item>

                <Form.Item label={"备份说明"} hasFeedback>
                    {getFieldDecorator("details", {
                        initialValue: selectNode.details || "",
                        rules: [{}],
                    })(<Input style={{ height: 50, width: 400 }} />)}
                </Form.Item> */}
                <Form.Item label={"已选设备"} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                    {getFieldDecorator("device", {
                        initialValue: selectNode.map((item) => { return JSON.parse(item) }) || [],
                        rules: [{
                            type: "array",
                            required: true, message: '请选择设备!',
                        }],
                    })(<TabForm
                        columns={[
                            { title: "资源名称", dataIndex: "resourceName", editable: true, },
                            { title: "设备IP", dataIndex: "ip", editable: true, },
                            { title: "FTP备份文件名", dataIndex: "config_name", editable: true, },
                            { title: "设备类型", dataIndex: "deviceType" },
                            { title: "厂商", dataIndex: "deviceFirm", },
                            { title: "型号", dataIndex: "deviceVersion", },
                            { title: "设备名", dataIndex: "deviceId", },
                            { title: 'ssh登录用户名', dataIndex: 'username', },
                            // { title: 'ssh登录密码', dataIndex: 'password', render: () => { return "******" } }
                        ]}
                    />)}
                </Form.Item>
            </Form>
            <p />
        </div>
    }

}
const EditMenuObj = Form.create()(AddForm)
export default EditMenuObj