import React from 'react'
import { Card, Table, Button, Modal, Typography, Descriptions, Row, Col } from "antd";
import Utils from "@/utils/utils";
import AxiosObj from '@/axios/index';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import { MonacoDiffEditor, } from 'react-monaco-editor';
const { Text } = Typography;
import './index.scss';
let perDiv = null;
class BaseLineData extends React.Component {
    state = {
        _current: 1,      //当前页码数
        _pageSize: 20,    //每页显示条数
        querys: {},    //查询条件
        total: 0,
        loading: false,
        tableData: [],
        diffModal: false,
        detailLoading: false,
        detailData: "",
        fileName: "",
        firstConfig: "",
        secondConfig: "",
        deviceIp: "",
        baseConfig: "",
        deviceInfo: {},
    };
    componentWillMount() {
        const deviceInfo = JSON.parse(sessionStorage.getItem("device"));
        let bodyJson = this.props.location.bodyJson || {};
        this.updata(null, null, { ip: deviceInfo.ip });
        this.setState({ total: bodyJson.total, deviceIp: bodyJson.ip || deviceInfo.ip, deviceInfo })
    }
    updata = (pageSize, pageNum, querys) => {
        if (!querys.ip) { this.goBackPage(); return; }
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {
            "ip": querys.ip || "",
            "page_size": pageSize || _pageSize,
            "page_no": pageNum || _current
        };
        console.log(bodyJson);
        AxiosObj.HTTP_POST("backUp", "findBaselineOperationRecords", bodyJson, (result) => {
            console.log(result)
            if (result.data) {
                let tableData = result.data.map((item, key) => { item.key = key; return item; })
                this.setState({
                    tableData,
                    _current: pageNum || 1,
                    total: result.operation_no,
                    loading: false,
                })
            } else {
                this.setState({
                    _current: pageNum || 1,
                    loading: false
                })
            }
        }, (error) => { Utils.showMessage("error", "查询失败", error); });
    };
    ConfigCompare(item) {
        this.setState({
            diffModal: true,
            firstConfig: item.old_baseline,
            secondConfig: item.new_baseline
        })
    }
    goBackPage() {
        let path = {
            pathname: `/configBackup/device`,
            bodyJson: this.props.location.bodyJson,
        };
        this.props.history.push(path);
    }
    configCompareByback(config_one, config_two) {
        AxiosObj.HTTP_POST("backUp", "configCompare", {
            config_one: config_one || "", config_two: config_two || ""
        }, (result) => {
            const diff_result = result.diff_result || {};
            this.setState({
                config_one_diff: diff_result.config_one_diff || "",
                config_two_diff: diff_result.config_two_diff || "",
                diff_lines: diff_result.diff_lines || []
            });
        });
    }
    getOverViews(diff_lines, text) {
        let lineNumber = diff_lines;
        if (this.editor) {
            const temp = this.editor.getLineChanges();
            if (temp) {
                lineNumber = temp.map((item) => {
                    return item["originalStartLineNumber"];
                });
            }
        }
        console.log(lineNumber);
        const list = text.split("\n");
        const plist = list.map((item, key) => {
            if (item === "" && key === list.length - 1) {
                return <div key={key} />
            }
            let e = 1;
            if (!Utils.isEmpty(lineNumber) && lineNumber[key]) {
                e = lineNumber[key];
            }
            return <div key={key} id={"id" + key} className={"overDiv"}
                onClick={() => this.gotoLine(e, "id" + key)}>
                {e + "----" + item}</div>;
        });
        return <div className={"conentDiv"}>{plist}</div>;
    }
    gotoLine(num, divId) {
        if (!num) { messages.error("行数错误！"); return; };
        let clickdiv = document.getElementById(divId);
        if (perDiv) { perDiv.style.backgroundColor = ''; perDiv.style.border = ''; }
        clickdiv.style.backgroundColor = 'rgb(255, 204, 203)';
        clickdiv.style.border = '1px solid #000';
        perDiv = clickdiv;
        let pos = {
            lineNumber: num,
            column: 1
        }
        this.editor.revealLineInCenter(num, num + 1);
        this.editor.setPosition(pos)
        this.editor.focus();
    }
    render() {
        let { _current, _pageSize, diffModal, tableData, deviceInfo } = this.state;

        const columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                render: (text, item, index) => { return <span>{index + 1 + (_current - 1) * _pageSize}</span> }
            },
            {
                title: '操作时间',
                align: 'left',
                dataIndex: 'operation_time',
            },
            {
                title: '操作账户',
                dataIndex: 'operation_user',
                align: "left",
            },
            {
                title: '操作类型',
                dataIndex: 'operation_type',
                align: "center",
                render: (text) => { return text === "set" ? "设置基线" : "更新基线" }
            },
            {
                title: '备份数据',
                align: 'center',
                // width: "80px",
                render: (text, item) => {
                    return <div>
                        {/* <Button type="primary" icon="export" onClick={() => {
                            let time = (item.create_time.split(" "))[0];
                            Utils.download("配置数据" + time + ".txt", item.config);
                        }}>
                            导出配置
                        </Button> */}
                        <Button type="primary" size="small" icon="check-square" onClick={() => {
                            this.ConfigCompare(item)
                            this.configCompareByback(item.old_baseline, item.new_baseline)
                        }}>
                            变更对比
                        </Button>
                        {/* </Dropdown> */}
                    </div>
                }
            }
        ];
        const params = [
            ["设备IP", "ip"],
            ["设备厂商", "deviceFirm"],
            ["设备名称", "deviceId"],
            ["设备类型", "deviceType"],
            ["设备型号", "deviceVersion"],
            ["设备OS版本", "deviceOsVersion"],
            ["设备OS类型", "deviceOsVersion"],
            ["网点信息", "deviceSite"],
        ];
        return (
            <div>
                <CustomBreadcrumb arr={["运维管理", { title: "配置备份", to: "/configBackup/device" }, "基线操作记录"]} />
                <Card title={<span style={{ fontWeight: 600 }}>基线操作记录</span>}
                    style={{ marginTop: 8 }}
                    extra={<div>
                        <Button type="primary" icon={'rollback'}
                            onClick={() => {
                                this.goBackPage()
                            }}>返回备份设备列表</Button>
                    </div>}>
                    <Descriptions bordered size="small">
                        {params.map((rec, m) => {
                            return <Descriptions.Item key={m} label={rec[0]}>{Utils.hidenLongText(deviceInfo[rec[1]], 30)}</Descriptions.Item>
                        })}
                    </Descriptions><br />
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        loading={this.state.loading}
                        size="small"
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.setState({ _pageSize: size });
                                this.updata(size, 1, { ip: this.state.deviceIp })
                            },
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                console.log(page, pageSize);
                                this.updata(pageSize, page, { ip: this.state.deviceIp });
                            }
                        }}
                    />

                </Card>
                <Modal
                    width="100%"
                    title="差异对比"
                    visible={diffModal}
                    onCancel={() => this.setState({ diffModal: false })}
                    onOk={() => this.setState({ diffModal: false })}
                    style={{ top: "20px" }}
                    // destroyOnClose
                    forceRender
                >
                    <div className="baseConfigRow">
                        <Row >
                            <Col span={8}>
                                <div style={{ marginBottom: "8px", textAlign: "center", width: "100%", }}><Text strong >变更概览</Text></div><hr />
                                {this.getOverViews(this.state.diff_lines || [], this.state.config_one_diff || "")}
                            </Col>
                            <Col span={16}>
                                <div style={{ marginBottom: "8px", textAlign: "center", width: "100%" }}>
                                    <Text strong >
                                        <span className="timeSelect">变更前数据</span>
                                        <span className="timeSelect">变更后数据</span>
                                    </Text>
                                </div><hr />
                                <MonacoDiffEditor
                                    height="600"
                                    language="java"
                                    theme={"vs"}
                                    options={{ readOnly: true }}
                                    original={this.state.firstConfig}
                                    value={this.state.secondConfig}
                                    editorDidMount={(editor) => {
                                        this.editor = editor;
                                    }}
                                />
                            </Col>
                        </Row>

                    </div>
                </Modal>
            </div>
        )
    }
}


export default BaseLineData