import React, { useState, useEffect } from 'react'
import {
    Card, Button, message, Row, Col, Typography, Icon, Input, Form, Popconfirm,
} from "antd";
import {
    SubmitButton,
    FormItem,
    Select,
    Checkbox,
    Radio,
    InputNumber
} from "formik-antd"
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik"
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import { alarmGroups, restoreRules, strategy, strategyex, alarmTemplates } from '../utils/api';
import Socket from '@/socket/index';
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
import "./index.scss"
import "animate.css"

function NewForm(props) {
    const [WebHookSta, setWebHookSta] = useState(false);
    const [TemplateSta, setTemplateSta] = useState({});
    const [GroupData, setGroupData] = useState([]);
    const [RestoreRules, setRestoreRules] = useState([]);
    const [Templates, setTemplates] = useState([
        { id: "12234", tempName: "测试模板011", tempType: "邮件" },
        { id: "12334", tempName: "测试模板012", tempType: "邮件" },
        { id: "12434", tempName: "测试模板013", tempType: "短信" }
    ]);
    const [Users, setUsers] = useState([]);
    const [strategyList, setStrategyList] = useState([]);
    const [strategyName, setStrategyName] = useState(JSON.parse(sessionStorage.getItem("alertItem")) ? JSON.parse(sessionStorage.getItem("alertItem")).strategyName : '');
    const [description, setDescription] = useState(JSON.parse(sessionStorage.getItem("alertItem")) ? JSON.parse(sessionStorage.getItem("alertItem")).description : '');
    const [isChangeStrategy, setIsChangeStrategy] = useState(false); // 判断是修改还是新增
    const [initialValues] = useState(JSON.parse(
        '{"notifyWay":"", "strategyName":"", "inhibition":1, "alertUpdate":1, "restoreRule":1, "restoreRuleId":"", "webhookUrl":"", "alertUpdateTime": 1, "emailTemplateId":"", "smsTemplateId":""}'));
    useEffect(() => {//componentDidMount

        getRestoreRuleData();
        getGroupData();
        getUsers();
        getTemplateData();
        if (initialValues.notifyWay && initialValues.notifyWay.includes(2)) {
            setWebHookSta(true);
        }
        if (sessionStorage.getItem("alertItem")) {
            setIsChangeStrategy(true); // 修改
            getAllStrategy();
        } else {
            setIsChangeStrategy(false); // 新增
            setStrategyList([{ ...initialValues }]);
        }
        return () => { //componentWillUnmount
            sessionStorage.removeItem("alertItem");
        }
    }, []);
    function getGroupData(page = 1, size = 9999, query = {}) {
        props.dispatch(isLoading(true));
        http.get(alarmGroups(), { page, size, ...query }, (r) => {
            const data = r.data.records;
            setGroupData(data);
            props.dispatch(isLoading(false));
        })
    }
    function getTemplateData(query = {}) {
        http.get(alarmTemplates(), { ...query }, (r) => {
            setTemplates(r.data.records);
        })
    }
    function getAllStrategy(id) {
        let strategyId = id || JSON.parse(sessionStorage.getItem('alertItem')).strategyId
        props.dispatch(isLoading(true));
        http.get(strategyex(), { strategyId }, (res) => {
            if (res.code === 0) {
                setStrategyList([...res.data.records]);
                setStrategyName(res.data.records[0].strategyName);
                setDescription(res.data.records[0].description);
                props.dispatch(isLoading(false));
            }
            console.log(res.data.records, strategyList)
        })
    }
    function getRestoreRuleData(page = 1, size = 9999, query = {}) {
        http.get(restoreRules(), { page, size, ...query }, (r) => {
            setRestoreRules(r.data.records);
        })
    }
    function getUsers() {
        Socket.ExecuteAction('QueryUserFromUrl', { url: "" }, "", (result) => {
            const users = result.data;
            setUsers(users);
        });
    }
    function saveDataSource(val, strategyIndex) {
        let bodyJson = {
            ...val,
            strategyName: strategyName,
            description: description,
            exceptionExpression: val.exceptionExpression || '',
            exceptionStrategyId: val.exceptionStrategyId || '',
            strategyUpdateTime: val.alertUpdateTime || '',
            prevStrategyId: '',
            nextStrategyId: ''
        }
        if (strategyIndex > 0) {
            bodyJson.prevStrategyId = strategyList[strategyIndex - 1].strategyId;
        }
        if (strategyIndex > 0 && strategyIndex < strategyList.length - 1) {
            bodyJson.nextStrategyId = strategyList[strategyIndex + 1].strategyId;
        }
        if (!bodyJson.strategyName) {
            return
        }
        if (bodyJson.strategyId) {
            http.put(strategy(), bodyJson, () => {
                message.success("修改成功!")
                getAllStrategy();
                // props.history.go(-1);
            })
        } else {
            bodyJson.creator = sessionStorage.getItem('userName');
            http.post(strategy(), bodyJson, () => {
                message.success("新增成功!");
                if (!isChangeStrategy) {
                    onCancel();
                } else {
                    setStrategyList([])
                    getAllStrategy();
                }
            })
        }
    }
    function onCancel() {
        props.history.go(-1);
    }
    function CheckValue(keys, val) {
        let info = {};
        let notifyWayVal = val["notifyWay"];
        if (!notifyWayVal || notifyWayVal.length <= 0) {
            info["notifyWay"] = "不能为空";
        } else {
            notifyWayVal.map(valItem => {
                if (valItem === 0 && val["emailTemplateId"] === "") {
                    info["emailTemplateId"] = "不能为空";
                }
                if (valItem === 1 && val["smsTemplateId"] === "") {
                    info["smsTemplateId"] = "不能为空";
                }
                if (valItem === 2 && val["webhookUrl"] === "") {
                    info["webhookUrl"] = "不能为空";
                }
            })
        }
        // if (val["notifyWay"][0] === 2 && val["webhookUrl"] === "") {
        //     info["webhookUrl"] = "不能为空";
        // }
        keys.map((k) => {
            if (val[k] === 0) {

            } else if (!val[k] && k != "webhookUrl" && k != "notifyWay" && k != "emailTemplateId" && k != "smsTemplateId") {
                info[k] = "不能为空！"
            }
        })
        console.log(info, val)
        return info;
    }
    function addStrategy(index) {
        strategyList.splice(index + 1, 0, initialValues);
        setStrategyList([...strategyList]);
    }
    function deleteStrategy(strategyItem, index) {
        if (strategyItem.strategyId) {
            if (strategyList.length >= 0) {
                http.delete_body(strategyex(), { strategyIds: [strategyItem.strategyId] }, () => {
                    message.success("删除成功!");
                    if (strategyList.length <= 1) {
                        onCancel();
                    } else {
                        if (index === 0) {
                            let id = strategyList[index + 1].strategyId
                            sessionStorage.setItem("alertItem", JSON.stringify(strategyList[index + 1]));
                            setStrategyList([])
                            getAllStrategy(id);
                        } else {
                            getAllStrategy();
                        }
                    }
                })
            }
        } else {
            strategyList.splice(index, 1);
            setStrategyList([...strategyList]);
        }
    }
    function changeInputValue(e, key) {
        if (key === 'strategyName') {
            setStrategyName(e.target.value);
        } else if (key === 'description') {
            setDescription(e.target.value)
        }
    }
    function deleteAllStrategy() {
        let strategyIds = [JSON.parse(sessionStorage.getItem('alertItem')).strategyId]
        http.delete_body(strategy(), { strategyIds }, () => {
            message.success("删除成功!");
            props.history.go(-1);
        })
    }
    return <div className="monitorNewForm">
        <CustomBreadcrumb arr={["监控告警", isChangeStrategy ? "编辑策略" : "新增策略"]} />
        <Card title={isChangeStrategy ? "编辑策略" : "新增策略"}
            extra={<div>
                <Button type="primary" icon="rollback" onClick={() => { onCancel() }}>返回</Button>&nbsp;
            {isChangeStrategy ? <Popconfirm
                    title="你确定删除整个策略吗?"
                    onConfirm={() => { deleteAllStrategy() }}
                    okText="是"
                    cancelText="否"
                ><Button type="danger" icon="delete" >删除整个策略</Button>
                </Popconfirm> : ''}
            </div>}
        >
            <div className="newformTitle">策略信息</div>
            <Formik
                initialValues={{
                    strategyName: strategyName,
                    description: description,
                }}
                validate={(values) => {
                    let info = {}
                    if (!values.strategyName) {
                        info["strategyName"] = "不能为空！"
                    }
                    return info
                }}
            >
                <FormikForm layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 11 }} >
                    <Row>
                        <Col span={12}>
                            <FormItem label={"策略名称"} name={"strategyName"} required={true}>
                                <FormikInput placeholder={"策略名称"} name={"strategyName"}
                                    onBlur={(e) => changeInputValue(e, 'strategyName')} allowClear />
                            </FormItem>
                        </Col>
                        <Col span={12}><FormItem label={"策略描述"} name={"description"}>
                            <FormikInput placeholder={"策略描述"} name={"description"} onBlur={(e) => changeInputValue(e, 'description')} allowClear />
                        </FormItem></Col>
                    </Row>
                </FormikForm>
            </Formik>
            {strategyList.map((strategyItem, index) => {
                return <Formik
                    key={strategyItem.strategyId || index}
                    initialValues={strategyItem || initialValues}
                    validateOnBlur={false} // 关闭失去焦点校验，checkbox会同时触发blue和change校验，且blur校验在onchange后，校验结果有问题
                    onSubmit={(values, actions) => {
                        console.log(values)
                        saveDataSource(values, index)
                        actions.setSubmitting(false)
                    }}
                    validate={(values) => {
                        let info = CheckValue(["notifyWay", "inhibition", "alertUpdate", "webhookUrl"], values);
                        // let info = CheckValue(["notifyWay", "inhibition", "alertUpdate", "restoreRule", "restoreRuleId", "webhookUrl"], values);
                        return info
                    }}
                >
                    {() => {
                        return <FormikForm layout="horizontal"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 11 }}
                            style={{ margin: 'auto', width: '80%' }}
                        >
                            <Card type='inner' className="strategyCard"
                                title={<span style={{ fontWeight: 600 }}>策略{index + 1}</span>}
                                style={{ marginBottom: 20 }}
                                extra={
                                    <Row>
                                        {isChangeStrategy ? <Popconfirm
                                            title="你确定删除这个子策略吗?"
                                            onConfirm={() => { deleteStrategy(strategyItem, index) }}
                                            okText="是"
                                            cancelText="否"
                                        ><Button type="danger" icon="delete" >删除策略</Button>
                                        </Popconfirm> : ''}
                                    </Row>
                                }
                                actions={isChangeStrategy ? [
                                    // <span style={{ display: 'block', padding: '12px 0' }} onClick={() => addStrategy(index)}>
                                    //     <Icon type="plus" key="insert" /> {index === strategyList.length - 1 ? '添加策略' : '插入策略'}
                                    // </span>,
                                    <Button onClick={() => addStrategy(index)} icon='plus' shape="round">
                                        {index === strategyList.length - 1 ? '添加策略' : '插入策略'}
                                    </Button>,
                                    <SubmitButton shape="round" className="subBtn" disabled={false} icon="save">保存</SubmitButton>
                                ] : null}
                            >
                                <div className="newformTitle">通知设置</div>
                                <Row>
                                    <Col span={12}>
                                        <FormItem name={`alarmReceiverId`} label={"通知人"} >
                                            <Select
                                                name={`alarmReceiverId`}
                                                placeholder="通知人"
                                            >
                                                {Users.map((r, index) => <Select.Option key={index} value={r.id}>{r.name}   ({r.mail}/{r.phone})</Select.Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem name={`alarmGroupId`} label={"通知组"} >
                                            <Select
                                                name={`alarmGroupId`}
                                                placeholder="通知组"
                                            >
                                                {GroupData.map((r, index) => <Select.Option key={index} value={r.alarmGroupId}>{r.alarmGroupName}</Select.Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <FormItem name={"notifyWay"} label={"通知方式"} required={true}>
                                            <Checkbox.Group
                                                name="notifyWay"
                                            >
                                                <Checkbox value={0} onChange={(e) => { TemplateSta.email = e.target.checked; setTemplateSta(TemplateSta) }}>邮件</Checkbox>
                                                <Checkbox value={1} onChange={(e) => { TemplateSta.sms = e.target.checked; setTemplateSta(TemplateSta) }}>短信</Checkbox>
                                                {/* <Checkbox value={2} onChange={(e) => { setWebHookSta(e.target.checked) }}>webhook</Checkbox> */}
                                            </Checkbox.Group>
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        {WebHookSta ? <FormItem name={`webhookUrl`} label={"webhook地址"} key={"webhookUrl"} required>
                                            <FormikInput name={`webhookUrl`} placeholder={"邮件地址、电话、网址"} />
                                        </FormItem> : ""}
                                        {(TemplateSta.email || (strategyItem.notifyWay && strategyItem.notifyWay.includes(0))) ? <FormItem name={`emailTemplateId`} label={"邮件模板"} key={"emailTemplateId"} required>
                                            <Select
                                                name={`emailTemplateId`}
                                                placeholder="邮件模板"
                                            >
                                                {Templates.map((r, index) => (r.tempType === "email") ? <Select.Option key={index} value={r.tempId}>{r.tempName}</Select.Option> : "")}
                                            </Select>
                                        </FormItem> : ""}
                                        {(TemplateSta.sms || (strategyItem.notifyWay && strategyItem.notifyWay.includes(1))) ? <FormItem name={`smsTemplateId`} label={"短信模板"} key={"smsTemplateId"} required>
                                            <Select
                                                name={`smsTemplateId`}
                                                placeholder="短信模板"
                                            >
                                                {Templates.map((r, index) => (r.tempType === "sms") ? <Select.Option key={index} value={r.tempId}>{r.tempName}</Select.Option> : "")}
                                            </Select>
                                        </FormItem> : ""}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <FormItem name={`inhibition`} label={"告警抑制"} required={true}>
                                            <InputNumber min={0} name={`inhibition`} style={{ width: 60 }} />分钟内不再重复发送
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem name={`alertUpdate`} label={"告警升级"} key={"alertUpdate"} required={true}>
                                            <Radio.Group
                                                name="alertUpdate"
                                                options={[
                                                    { label: "是", value: 0 },
                                                    { label: "否", value: 1 },
                                                ]}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row className="flex-config">
                                    <Col span={12} className="long">
                                        <FormItem name={`alertUpdateTime`} label={"当前策略"} key={"alertUpdateTime"}>
                                            <InputNumber min={0} name={`alertUpdateTime`} style={{ width: 60 }} />分钟内未恢复，则执行以下策略
                                        </FormItem>
                                    </Col>
                                </Row>
                                {/* <div className="newformTitle">自愈规则</div>

                                <Row>
                                    <Col span={12}>
                                        <FormItem name={`restoreRule`} label={"告警时是否触发自愈规则策略"} key={"restoreRule"} required={true} >
                                            <Radio.Group
                                                name={`restoreRule`}
                                                options={[
                                                    { label: "是", value: 0 },
                                                    { label: "否", value: 1 },
                                                ]}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem name={`restoreRuleId`} label={"自愈规则"} key={"restoreRuleId"} required={true} >
                                            <Select
                                                name={`restoreRuleId`}
                                            >
                                                {RestoreRules.map((r, index) => <Select.Option key={index} value={r.restoreRuleId}>{r.name}</Select.Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row> */}
                            </Card>
                            {isChangeStrategy ? '' : <div style={{ textAlign: "center", borderTop: "1px solid #e2e2e2", paddingTop: 10 }}>
                                <SubmitButton disabled={false} icon="save">保存</SubmitButton>&nbsp;
                                    <Button icon="close" onClick={() => { onCancel() }}>取消</Button>
                            </div>}
                        </FormikForm>
                    }}
                </Formik>
            })}
        </Card>
    </div>
}

export default connect()(NewForm)