import React from 'react'
import { Card, Table, Button, message, Popconfirm, Icon, Input, Tag } from "antd";
import Socket from "../../../socket/index";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import Utils from '@/utils/utils';
let FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];

export default class Task extends React.Component {
    state = {
        filteredInfo: null,
        sortedInfo: null,
        visible: false,
        _current: 1,      //当前页码数
        _pageSize: 10,    //每页显示条数
        loading: false,
    };
    componentWillMount() {
        FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
        this.updata();
    }
    updata = (pageSize, pageNum, querys) => {
        let { _pageSize, _current } = this.state;
        this.setState({ loading: true, });
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        Socket.QueryDataByPage('QueryUsersPage',
            bodyJson, (result) => {
                console.log(result);
                if (result.retcode === '00') {
                    let users = result.users;
                    users.map((item, index) => {
                        item.key = index;
                        item.index = index;
                        return item.id
                    });
                    this.setState({
                        tableData: users,
                        _current: pageNum || 1,
                        _pageSize: pageSize || _pageSize,
                        loading: false,
                        total: result.total,
                    })
                }
            }, "/queryUser");
    };

    setOperations = (operations) => {
        console.log('setOperations--' + operations);
        this.setState({
            filteredInfo: {
                deviceType: operations,
            },
        });
    };
    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };
    deleteUser = (id) => {
        let thisTemp = this;
        Socket.Action("DeleteUser",
            { _id: id }, (result) => {
                console.log(result);
                if (result.retcode === '00') {
                    message.success('删除成功');
                    thisTemp.updata();
                }
            }, "/delUser");
    };

    cancel = () => {
        this.setState({ visible: false });
        message.error('删除取消');
    };

    handleVisibleChange = (visible) => {
        if (!visible) {
            this.setState({ visible });
            return;
        }
        // Determining condition before show the popconfirm.
        if (this.state.condition) {
            this.confirm(); // next step
        } else {
            this.setState({ visible }); // show the popconfirm
        }
    };
    itemMenuFun = (itemMenu, item) => {
        switch (itemMenu.key) {
            case '1': break;
            case '2': break;
            case '3': break;
            case '4':
                let data = JSON.stringify(item);
                this.props.history.push(`/newUser${data}`);
                break;
            case '5': break;
        }
    };
    render() {
        let { _current, _pageSize, loading, querys } = this.state;
        const columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'index',
                render: (text) => { return <span>{text + 1 + (_current - 1) * _pageSize}</span> }
            }, {
                title: '用户名称',
                align: 'center',
                dataIndex: 'nickName'
            }, {
                title: '用户登录名',
                align: 'center',
                dataIndex: 'username'
            }, {
                title: '用户描述',
                align: 'center',
                dataIndex: 'userDesc',
                ellipsis: true,
            },
            {
                title: '绑定角色',
                align: 'center',
                dataIndex: 'role',
                render: (text, item, index) => {
                    return text.map((role, key) => {
                        return <Tag key>{role.name}</Tag>
                    })
                }
            },
            {
                title: '邮箱',
                align: 'center',
                dataIndex: 'mail',
                ellipsis: true,
            }, {
                title: '电话号码',
                align: 'center',
                dataIndex: 'phone',
                ellipsis: true,
            }, {
                title: '操作',
                width: '270px',
                align: 'center',
                render: (text, item) => {
                    return <div>
                        <Button type="primary" size="small" onClick={() => {
                            this.props.history.push(`/newUser:${item._id}`);
                        }}><Icon type="form" />编辑 </Button>&nbsp;
                            <Popconfirm
                            title="确定删除吗?"
                            onVisibleChange={this.handleVisibleChange}
                            onConfirm={() => this.deleteUser(item._id)}
                            onCancel={this.cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" size="small"
                                icon='delete'>删除</Button>
                        </Popconfirm>
                    </div>
                }
            }];
        return (
            <div>
                <CustomBreadcrumb arr={["系统管理", "用户管理"]} />
                <Card>
                    <FilterForm
                        tagClass="user"
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(this.state._pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(this.state._pageSize, 1, {})
                        }}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>用户列表</span>}
                    style={{ marginTop: 8 }}
                    extra={<Button type="primary" icon="edit" style={{ marginLeft: 8 }}
                        onClick={() => { this.props.history.push('/newUser'); }}>新建用户</Button>}>
                    <Table
                        dataSource={this.state.tableData}
                        columns={columns}
                        size="small"
                        loading={loading}
                        onChange={() => this.handleChange}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.updata(size, 1, querys)
                            },
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                console.log(page, pageSize);
                                this.updata(pageSize, page, querys);
                            }
                        }}
                    />

                </Card>
            </div>
        )
    }
}
