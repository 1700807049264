import { Table, Input, Descriptions, Popconfirm, Form, Modal, AutoComplete, Icon, Divider } from 'antd';
import React from "react"
import "./index.scss"
import Utils from "@/utils/utils"

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    state = {
        editing: false,
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;
        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }} className="editable-form">
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} 不能为空！`,
                        },
                    ],
                    initialValue: record[dataIndex],
                })(
                    dataIndex != "os_type" ?
                        <Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} className="inputSty" /> :

                        <AutoComplete
                            ref={node => (this.input = node)}
                            onBlur={this.save}
                            dataSource={["cisco_ios", "hp_comware", "huawei"]}
                        // filterOption={(inputValue, option) =>
                        //     option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        // }
                        >
                            <Input suffix={<Icon type="caret-down" className="inputSty" />} />
                        </AutoComplete>

                )}
            </Form.Item>
        ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                >
                    {children}
                </div>
            );
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                        children
                    )}
            </td>
        );
    }
}

export default class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            ...this.props.columns || [],
            {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) =>
                    this.state.dataSource.length >= 1 ? (
                        <div>
                            <a onClick={() => this.showInfo(record)}>详情</a><Divider type="vertical" />
                            <Popconfirm title="确定删除吗?" onConfirm={() => this.handleDelete(record.key)}>
                                <a>删除</a>
                            </Popconfirm>
                        </div>
                    ) : null,
            },
        ];

        this.state = {
            dataSource: this.props.value || [],
            count: 2,
        };
    }

    showInfo = (record) => {
        Modal.info({
            title: "详细信息",
            width: 500,
            content: <Descriptions bordered size="small" column={1}>
                {
                    Object.keys(record).map((key) => {
                        if (["password", "sys_pwd", "key"].includes(key)) {
                            return null
                        } else {
                            return <Descriptions.Item key={key} label={Utils.Translation(key)}>{record[key]}</Descriptions.Item>
                        }
                    })
                }
            </Descriptions>
        })
    };
    handleDelete = key => {
        const dataSource = [...this.state.dataSource];
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
    };

    handleAdd = () => {
        const { count, dataSource } = this.state;
        const newData = {
            key: count,
            name: `Edward King ${count}`,
            age: 32,
            address: `London, Park Lane no. ${count}`,
        };
        this.setState({
            dataSource: [...dataSource, newData],
            count: count + 1,
        });
    };

    handleSave = row => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData });
        this.props.onChange(newData);
    };

    render() {
        const { dataSource } = this.state;
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map(col => {
            col.ellipsis = true;
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });
        return (
            <div className="EditTabClass">
                {/* <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
                    Add a row
        </Button> */}
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    size="small"
                    dataSource={dataSource}
                    columns={columns}
                    scroll={{ y: 400 }}
                    pagination={false}
                />
            </div>
        );
    }
}