import React from 'react'
import { Card, Table, Input, Typography, Popconfirm, Button, Icon, Tabs, Row, Col, message, Tag, Modal, Progress, List, Menu, Dropdown } from "antd";
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import AxiosObj from '@/axios/index';
import CustomBreadcrumb from "@/components/CustomBreadcrumb/index";
import $ from "jquery";
const { Title } = Typography;
const versionName = sys_config.versionName;
export default class FireWall extends React.Component {
    state = {
        s_current: 1,      //当前页码数
        s_pageSize: 20,    //每页显示条数
        s_querys: { "resourceParams.deviceType": "firewall" },    //查询条件
        s_total: 0,
        s_loading: false,
        s_tableData: [],
        selectedRowKeys: [],
        selectedRows: []
    };
    componentWillMount() {
        this.updataDevice();
    }
    // HTTP_POST(mode, URL, body, success, error)
    updataDevice = (pageSize, pageNum, querys) => {
        Socket.QueryResourcesPage(pageSize, pageNum, querys, this);
    };
    handleMenuClick(e, item,) {
        let _this = this;
        sessionStorage.setItem("device", JSON.stringify(item.resourceParams));
        this.props.history.push("/fireWall/" + e.key);
        // switch (e.key) {
        //     case 'policyDetail':
        //         sessionStorage.setItem("fwIp", item.resourceParams.deviceIp);
        //         this.props.history.push("/fireWall/policyDetail");
        //         break;
        //     case 'redundancyPolicy':
        //         sessionStorage.setItem("fwIp", item.resourceParams.deviceIp);
        //         this.props.history.push("/fireWall/redundancyPolicy");
        //         break;
        // }

    }
    policyAnalyze() {
        const { selectedRowKeys, selectedRows, } = this.state;
        const _this = this;
        if (Utils.isEmpty(selectedRowKeys)) {
            message.error("请勾选设备！");
            return;
        } else {
            this.setState({ s_loading: true })
            AxiosObj.HTTP_POST("fw", "policyAnalyze", { ips: selectedRowKeys }, (result) => {
                Utils.showMessage("success", "提示信息", result.msg);
                this.setState({ s_loading: false })
            });
        }
    }
    render() {
        let { s_current, s_loading, s_pageSize, s_tableData, s_total, s_querys } = this.state;
        const columnsDev = [
            {
                title: '序号',
                width: 40,
                render: (a, b, c) => { return <span>{(c + 1 + (s_current - 1) * s_pageSize)}</span> }
            },
            {
                title: '资源名称',
                align: 'center',
                ellipsis: true,
                dataIndex: 'resourceName',
            }, {
                title: 'IP',
                align: 'center',
                dataIndex: 'resourceParams.deviceIp',
            }, {
                title: '厂商',
                ellipsis: true,
                align: 'center',
                dataIndex: 'resourceParams.deviceFirm',
            }, {
                title: '型号',
                align: 'center',
                ellipsis: true,
                dataIndex: 'resourceParams.deviceVersion'
            }, {
                title: 'OS版本',
                align: 'center',
                ellipsis: true,
                dataIndex: 'resourceParams.deviceOsVersion'
            }, {
                title: '网点信息',
                ellipsis: true,
                align: 'center',
                dataIndex: 'resourceParams.deviceSite'
            }, {
                title: '操作',
                align: 'center',
                render: (text, item, index) => {
                    return (<div>
                        {/* <Dropdown overlay={<Menu onClick={(e) => this.handleMenuClick(e, item)}>
                            <Menu.Item key="policy">
                                <Icon type="file" />策略分析结果
                            </Menu.Item>
                            <Menu.Item key="redundancyPolicy">
                                <Icon type="file" />冗余策略
                            </Menu.Item>
                            <Menu.Item key="conflictPolicy">
                                <Icon type="file-exclamation" />冲突策略
                            </Menu.Item>
                            <Menu.Item key="noEffectPolicy">
                                <Icon type="file-unknown" />失效策略
                            </Menu.Item>
                            <Menu.Item key="noMatchedPolicy">
                                <Icon type="file-text" />未命中策略
                            </Menu.Item>
                        </Menu>
                        }>*/}
                        <Button type="primary" key={index} icon="schedule" size="small"
                            onClick={() => { this.handleMenuClick({ key: "policyDetail" }, item) }}>策略详情
                                {/* <Icon type="down" /> */}
                        </Button>
                        {/* </Dropdown> */}
                    </div>)
                }
            }
        ];
        return (
            <div className="home-body">
                <CustomBreadcrumb arr={["运维管理", "防火墙", "防火墙列表"]} />
                <Card title={<span style={{ fontWeight: 600 }}>防火墙列表</span>}
                // extra={<Button type="primary" icon={'rollback'}
                //     onClick={() => { this.props.history.goBack(); }}>返回任务</Button>}
                >
                    {versionName === "pingan" || versionName === "pinganNanJing" ? '' :
                        <Button type="primary" icon="monitor" onClick={() => { this.policyAnalyze() }}>批量策略分析</Button>}
                    <hr style={{ border: "0.5px solid #e2e2e2" }} />
                    <Table
                        rowSelection={{
                            selectedRowKeys: this.state.selectedRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => {
                                this.setState({ selectedRowKeys, selectedRows });
                            },
                            selections: [{
                                key: 'cencel',
                                text: '清空选择',
                                onSelect: changableRowKeys => {
                                    this.setState({ selectedRowKeys: [] });
                                },
                            },],
                        }}
                        rowKey={(text) => { return text.ip }}
                        columns={columnsDev}
                        dataSource={s_tableData}
                        size="small"
                        loading={s_loading}
                        pagination={{
                            current: s_current, pageSize: s_pageSize, total: s_total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.setState({ s_pageSize: size })
                                this.updataDevice(size, 1, s_querys);
                            },
                            onChange: (page, pageSize) => { this.updataDevice(pageSize, page, s_querys); }
                        }}
                    />

                </Card>
            </div>
        );
    }
}
const style = {
    bgDiv: { textAlign: 'center', border: '1px solid #e2e2e2', width: '80%', margin: 'auto' },
    title: {
        textAlign: 'center', border: '1px solid #e2e2e2',
        background: 'linear-gradient(#4481eb,#04befe)', color: '#FFFFFF'
    }
};