import service from "../zx-utils/request"

export function generateLine(callback) {
    service({
        url: '/zx/generateTable/',
        method: 'get',
        params: ''
    }).then(res => {
        callback(res)
    })
}