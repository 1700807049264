import React from 'react'
import './index.scss'
import Socket from '@/socket/index'
import Utils from '@/utils/utils'
import ResUtils from '@/utils/ResUtils'
import { Card, Icon, Row, Col, Button, Table, Typography, message } from "antd";

import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import StatiTask from "../task/OverView/statiTask";
import ChartMap from "./dataMap"
import "animate.css";
const versionName = sys_config.versionName;
export default class Home extends React.Component {
    state = {
        tableData: [],
        showData: [],
        progressObj: {},
        visible_report: false,
        visible_port: false,
        visible_monitor: false,
        visible_param: false,
        visible_find: false,
        visible_IpConfig: false,
        loading: false,
        dataList: [],
        taskId: null,

        _current: 1,      //当前页码数
        _pageSize: 20,    //每页显示条数
        querys: null,    //查询条件
        total: 0,
        menuList: [],

    };
    componentWillMount() {
        // console.log(bodyJson);
        this.updata(20, 1, {});
        const menuList = sessionStorage.getItem("menuList");
        const permission = JSON.parse(sessionStorage.getItem('FixedBtn'));
        this.setState({ menuList: JSON.parse(menuList),permission });
    };
    updata = (pageSize, pageNum, querys) => {
        console.log(pageSize, pageNum, querys);
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        console.log(bodyJson);
        Socket.ExecuteAction('QueryResourcesPage',
            bodyJson, "/queryResource", (result) => {
                let Resources = result.resources;
                Resources.map((item, index) => {
                    item.key = index;
                    item.index = index;
                    return item.id
                });
                console.log(result)
                this.setState({
                    tableData: Resources,
                    _current: pageNum || 1,
                    total: result.total,
                    loading: false,
                })
            });
    };
    fastLink = (link) => {
        if (Utils.includsKey(this.state.menuList, link)) {
            this.props.history.push(link);
        } else {
            message.info("权限不足！请联系管理员开放！")
        }

    };
    render() {
        let { _current, _pageSize, permission } = this.state;
        let permission_conn = permission.includes("/connResource")
        const columns = [...ResUtils.getColumns(_pageSize, _current), {
            title: '连接',
            width: 100,
            align: "center",
            render: (text, item) => {
                let urlTemp = Socket.getDeviceSSHUrl(item.resourceParams.deviceUser,
                    item.resourceParams.devicePwd, item.resourceParams.deviceIp);
                return <Button type="primary" icon="login" size="small"
                    // href={urlTemp} target="connIframe"
                    disabled = {!permission_conn}
                    onClick={() => {
                        // console.log(urlTemp);
                        window.open(urlTemp, '_blank', "toolbar=yes, location=no, directories=no, status=no, menubar=yes, scrollbars=yes,"
                            + "resizable=no, copyhistory=yes, width=700, height=600");
                    }}
                >连接</Button>;
            }
        },
        ];
        return (
            <div className="home-body01">
                <div className="CustomBreadcrumb"><CustomBreadcrumb arr={["首页"]} /> </div>
                <Row style={{ fontWeight: "bold" }} gutter={15}>
                    {(() => {
                        switch (versionName) {
                            case "pingan": return (
                                <Col span={4}>
                                    <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/command/aclSend') }}>
                                        <div className="LinkSpan" style={{ backgroundColor: "#e46cbb" }}>
                                            <Icon type="tool" className="LinkIcon" />
                                        </div>
                                        <span className="LinkText">配置下发</span>
                                    </div>
                                </Col>
                            )
                            case "pinganNanJing": return (
                                <Col span={4}>
                                    <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/resource') }}>
                                        <div className="LinkSpan" style={{ backgroundColor: "#e46cbb" }}>
                                            <Icon type="tool" className="LinkIcon" />
                                        </div>
                                        <span className="LinkText">资源管理</span>
                                    </div>
                                </Col>
                            )
                            case "pinganTerminal": return
                            default: return (
                                // <Col span={4}>
                                //     <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/InspectionMan') }}>
                                //         <div className="LinkSpan" style={{ backgroundColor: "#e46cbb" }}>
                                //             <Icon type="tool" className="LinkIcon" />
                                //         </div>
                                //         <span className="LinkText">设备巡检</span>
                                //     </div>
                                // </Col>
                                <Col span={4}>
                                    <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/resource') }}>
                                        <div className="LinkSpan" style={{ backgroundColor: "#e46cbb" }}>
                                            <Icon type="tool" className="LinkIcon" />
                                        </div>
                                        <span className="LinkText">资源管理</span>
                                    </div>
                                </Col>
                            )
                        }
                    })()}
                    
                    {(() => {
                        switch (versionName) {
                            case "pingan": return
                            case "pinganNanJing": return (
                                <Col span={4}>
                                    <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/IpAdmin/IPMonitor') }}>
                                        <div className="LinkSpan" style={{ backgroundColor: "#9a66e4" }}>
                                            <Icon type="pie-chart" className="LinkIcon" />
                                        </div>
                                        <span className="LinkText">IP管理</span>
                                    </div>
                                </Col>
                            )
                            case "pinganTerminal": return (
                                <Col span={4}>
                                    <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/IpAdmin/IPMonitor') }}>
                                        <div className="LinkSpan" style={{ backgroundColor: "#9a66e4" }}>
                                            <Icon type="pie-chart" className="LinkIcon" />
                                        </div>
                                        <span className="LinkText">IP管理</span>
                                    </div>
                                </Col>
                            )
                            default: return (
                                // <Col span={4}>
                                //     <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/customizeTask/list') }}>
                                //         <div className="LinkSpan" style={{ backgroundColor: "#9a66e4" }}>
                                //             <Icon type="pie-chart" className="LinkIcon" />
                                //         </div>
                                //         <span className="LinkText">任务概览</span>
                                //     </div>
                                // </Col>
                                <Col span={4}>
                                    <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/command/aclSend') }}>
                                        <div className="LinkSpan" style={{ backgroundColor: "#9a66e4" }}>
                                            <Icon type="pie-chart" className="LinkIcon" />
                                        </div>
                                        <span className="LinkText">配置下发</span>
                                    </div>
                                </Col>
                            )
                        }
                    })()}
                    {versionName === "pinganTerminal" ? '' :
                        <Col span={4}>
                            <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/configBackup/device') }}>
                                <div className="LinkSpan" style={{ backgroundColor: "#ff9900" }}>
                                    <Icon type="file-sync" className="LinkIcon" />
                                </div>
                                <span className="LinkText">配置备份</span>
                            </div>
                        </Col>
                    }
                    {versionName === "pinganTerminal" ? '' :
                        <Col span={4}>
                            <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/Ledger') }}>
                                <div className="LinkSpan" style={{ backgroundColor: "#2d8cf0" }}>
                                    <Icon type="wallet" className="LinkIcon" />
                                </div>
                                <span className="LinkText">MAC地址管理</span>
                            </div>
                        </Col>
                    }
                    
                    {(() => {
                        switch (versionName) {
                            case "pingan": return;
                            case "pinganNanJing": return (
                                <Col span={4}>
                                    <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/dumbTerminal') }}>
                                        <div className="LinkSpan" style={{ backgroundColor: "#ed3f14" }}>
                                            <Icon type="sound" className="LinkIcon" />
                                        </div>
                                        <span className="LinkText">哑终端</span>
                                    </div>
                                </Col>

                            );
                            case "pinganTerminal": return (
                                <Col span={4}>
                                    <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/dumbTerminal') }}>
                                        <div className="LinkSpan" style={{ backgroundColor: "#ed3f14" }}>
                                            <Icon type="sound" className="LinkIcon" />
                                        </div>
                                        <span className="LinkText">哑终端</span>
                                    </div>
                                </Col>
                            );
                            default: return (
                                // <Col span={4}>
                                //     <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/monitorAlert/overview') }}>
                                //         <div className="LinkSpan" style={{ backgroundColor: "#ed3f14" }}>
                                //             <Icon type="sound" className="LinkIcon" />
                                //         </div>
                                //         <span className="LinkText">监控告警</span>
                                //     </div>
                                // </Col>
                                <Col span={4}>
                                    <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/securityBaseline') }}>
                                        <div className="LinkSpan" style={{ backgroundColor: "#ed3f14" }}>
                                            <Icon type="file-search" className="LinkIcon" />
                                        </div>
                                        <span className="LinkText">设备安全检查</span>
                                    </div>
                                </Col>
                            )
                        }
                    })()}
                    <Col span={4}>
                        <div className="LinkCard animated bounceInRight" onClick={() => { this.fastLink('/user') }}>
                            <div className="LinkSpan" style={{ backgroundColor: "#19be6b" }}>
                                <Icon type="team" className="LinkIcon" />
                            </div>
                            <span className="LinkText">用户管理</span>
                        </div>
                    </Col>
                </Row>
                <p />
                <ChartMap className="animated fadeInUp" />
                <p />
                <Card title={<span style={{ fontWeight: 600 }}>设备列表</span>}
                    style={{ marginTop: 8 }}
                    extra={<div>
                        {/* <Button type="primary" size="small" icon="edit" onClick={() => { this.props.history.push('/newResource') }}>
                            新建资源</Button> */}
                    </div>}>
                    <Table
                        dataSource={this.state.tableData || []}
                        columns={columns}
                        loading={this.state.loading}
                        size="small"
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.setState({ _pageSize: size });
                                this.updata(size, 1, this.state.querys)
                            },
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                this.updata(pageSize, page, this.state.querys);
                            }
                        }}
                    />
                </Card>
                <p />
                {/* { versionName === "pingan" || versionName === "pinganNanJing" || versionName === "pinganTerminal" ? "" : <StatiTask />} */}
            </div >
        );
    }
}