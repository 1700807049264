/**
 * Created by YIMINE on 2019/1/24.
 */
import React from 'react'
import { Typography, Table, Modal, message, Button, Tag } from 'antd';
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import InputForm from "./inputFrom";
import FilterForm from "@/components/FilterForm/index";
const { Title } = Typography;
const hrStyle = {
    border: "0.5px solid #e2e2e2"
}

class PwdModal extends React.Component {
    state = {
        deviceCurrent: 0,
        pageCurrent: 0,
        tabloading: false,       //表格加载
        visible_device: false,      //选择设备模态框
        slt_device: null,
        selectedRowKeys: [],
        Tags: [],
        Groups: []
    };
    componentDidMount() {

        var bodyJson = new Object();
        bodyJson.pageSize = 10;
        bodyJson.pageNum = 1;
        bodyJson.querys = {};
        this.updataResources(1000, 1, {});
        this.getTagGroupName();
    }
    getTagGroupName() {
        const thisTemp = this;
        Socket.ExecuteAction('QueryTagsGroups', { tagClass: 'resource' }, "/QueryTagsGroups", (result) => {
            thisTemp.setState({
                Tags: result.tags,
                Groups: result.groupNames,
            });
        });
    }

    updataResources = (pageSize, pageNum, querys) => {
        let bodyJson = {};
        bodyJson.pageSize = pageSize || 10;
        bodyJson.pageNum = pageNum || 1;
        bodyJson.querys = querys || {};
        this.setState({ tabloading: true });
        Socket.ExecuteAction("QueryResourcesPage", bodyJson, "/queryResource", (result) => {
            let Resources = result.resources;
            Resources.map((item, index) => {
                item.key = index;
                item.index = index;
            });
            this.setState({
                deviceData: Resources,
                deviceTotal: result.total,
                deviceCurrent: pageNum,
                tabloading: false,
            })
        });
    }
    UpdateResourcePwd(querys) {
        let bodyJson = querys;
        let deviceIdList = this.state.slt_device.map((item) => { return item._id })
        bodyJson["deviceList"] = deviceIdList;
        if (!bodyJson["newPwd"]) { message.error("密码不能为空！"); return }
        Socket.ExecuteAction("UpdateResourcePwd", bodyJson, "/UpdateResourcePwd", () => { message.success("更新成功！"); this.props.onSumit({}) },
            (error) => { Utils.openNotificationWithIcon("error", "更新错误", error); });
    }

    paramRen = () => {
        const { slt_device, pageCurrent,
            selectedRowKeys, Tags, Groups, tabloading, deviceData } = this.state;
        const rowDevice = {
            onChange: (selectedRowKeys, selectedRows) => {
                let slt_device = selectedRows;
                this.setState({ slt_device, selectedRowKeys });
            },
            selectedRowKeys: selectedRowKeys,
        };
        const formList = [
            {
                label: "标签",
                type: 'SELECT',
                field: "tags",
                isTag: true,
                initialValue: [],
                list: Tags.map((item) => {
                    return { id: item, name: item }
                }),
            },
            {
                label: "分组",
                type: 'SELECT',
                isTag: true,
                field: "groupNames",
                initialValue: [],
                list: Groups.map((item) => {
                    return { id: item, name: item }
                }),
            },
            {
                label: "资源名称",
                type: 'INPUT',
                field: "resourceName",
                initialValue: '',
                placeholder: "资源名称"
            },
            {
                label: "厂商",
                type: 'INPUT',
                field: "resourceParams-deviceFirm",
                initialValue: '',
                placeholder: "厂商"
            },
            {
                label: "型号",
                type: 'INPUT',
                field: "resourceParams-deviceVersion",
                initialValue: '',
                placeholder: "型号"
            },
        ]
        const columnsDevice = [
            {
                title: '名称',
                align: 'left',
                width: "200px",
                dataIndex: 'resourceName'
            }, {
                title: '厂商',
                align: 'left',
                dataIndex: 'resourceParams.deviceFirm',
            }, {
                title: '型号',
                align: 'left',
                dataIndex: 'resourceParams.deviceVersion',
            }, {
                title: '标签',
                align: 'left',
                dataIndex: 'tags',
                width: "200px",
                render: (text) => {
                    return text.map((tag, key) => {
                        return <Tag key={key} color="blue">{tag}</Tag>
                    })
                }
            }, {
                title: '分组',
                align: "left",
                width: "100px",
                dataIndex: 'groupNames',
                render: (text) => {
                    return text.map((groupNames, key) => {
                        return <Tag key={key} color="blue">{groupNames}</Tag>
                    })
                }
            },
        ];
        const stup1 = <div><Title level={3} style={{ textAlign: "center" }}>选择设备</Title> <hr style={hrStyle} />
            <FilterForm
                formList={formList}
                filterSubmit={(querys) => {
                    this.setState({ querys })
                    this.updataResources(1000, 1, querys)
                }}
                resetTab={() => {
                    this.setState({ querys: null })
                    this.updataResources(1000, 1, {})
                }}
            />
            <hr style={hrStyle} />
            <Table
                rowSelection={rowDevice}
                columns={columnsDevice}
                loading={tabloading}
                dataSource={deviceData}
                size='small'
                rowKey={(_record) => { return _record._id }}
                bordered={false}
                scroll={{ y: 440 }}
                pagination={false}
            />
            <hr style={hrStyle} />
            <p style={{ textAlign: "center" }}><Button type="primary" icon="step-forward" onClick={() => { if (slt_device) { this.setState({ pageCurrent: 1 }) } else { message.error("请选择更改密码设备！") } }}>下一步</Button></p>
        </div>
        const stup2 = <div><Title level={3} style={{ textAlign: "center" }}>填写用户名密码</Title> <hr style={hrStyle} />
            <br />
            <InputForm
                filterSubmit={(querys) => {
                    this.UpdateResourcePwd(querys);
                }}
                lastStup={() => {
                    this.setState({ pageCurrent: 0 })
                }}
            />
        </div>
        const content = <div>
            {pageCurrent === 0 ? stup1 : stup2}
        </div>

        return content;
    }
    render() {
        return (
            <Modal
                title="密码变更"
                {...this.props}
                afterClose={() => {
                    this.setState({ pageCurrent: 0, slt_device: null, selectedRowKeys: [] });
                }}
            >
                {this.paramRen()}
            </Modal>
        );
    }
}

export default PwdModal;