/**
 * 配置备份系统前后端API配置
 */
const BASEURL = "http://" + backup_address + "/ZJNMS_V1/backup/";
const APIConfig = {
    MODENAME: "backup",//模块名称
    BASEURL,
    //URL:配置
    addDevice: BASEURL + "addDevice",
    backupBatch: BASEURL + "backupBatch",
    updateDevice: BASEURL + "updateDevice",
    querySetting: BASEURL + "querySetting",
    updateSetting: BASEURL + "updateSetting",
    backupProgress: BASEURL + "backupProgress",
    delBackupDevice: BASEURL + "delBackupDevice",
    startAutoBackup: BASEURL + "startAutoBackup",
    closeAutoBackup: BASEURL + "closeAutoBackup",
    pauseAutoBackup: BASEURL + "pauseAutoBackup",
    findBaselineByIp: BASEURL + "findBaselineByIp",
    findDeviceByPage: BASEURL + "findDeviceByPage",
    findDeviceByAttr: BASEURL + "findDeviceByAttr",
    updateBaselineByIp: BASEURL + "updateBaselineByIp",
    resumeAllAutoBackup: BASEURL + "resumeAllAutoBackup",
    batchFindLatestConfig: BASEURL + "batchFindLatestConfig",
    update_auto_backup_time: BASEURL + "update_auto_backup_time",
    update_auto_backup_time: BASEURL + "update_auto_backup_time",
    backupCmd: BASEURL + "backupCmd",
    addBackupCmd: BASEURL + "addBackupCmd",
    updateBackupCmd: BASEURL + "updateBackupCmd",
    delBackupCmd: BASEURL + "delBackupCmd",

};
export default APIConfig;