import React from 'react'
import { Card, Input, Button, Form, Icon, Progress, Row, Col, Select, Table, Modal, Tooltip, List, Badge, Tree, message, Tabs } from "antd";
import AxiosObj from "../../axios";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import moment from "moment";
import "./index.scss"
import { connect } from 'react-redux'
import { isLoading } from '@/redux/action'
const { Option } = Select;
const { TabPane } = Tabs;
// 监控告警后端服务器地址  monitor_address 配置地址：public/flow/config.js
const email_Url = email_address;

class emailList extends React.Component {
    state = {
        dataSource: [],
        visible: false, //邮件内容弹出框展示
        title: "",  //邮件
        content: "", // 邮件正文
        appendixs: [
            // {
            //     "email_id": 3,
            //     "name": "helloworld.txt",
            //     "id": 5,
            //     "content": "[72,69,76,76,79,32,87,79,82,76,68,33,13,10,13,10,72,65,72,65,72,65,72,65,72,65,13,10,13,10,71,79,79,68,32,87,79,82,68,33]"
            // }
        ],
        loading: false,
        pageSize: 50, //分页设置
        page: 1,
        total: 0,
        state: "", //邮件查询筛选条件
        selectItem: {},
        query: {},//查询条件
        columns: [
            {
                title: '序号',
                align: 'center',
                width: 50,
                dataIndex: 'index',
                render: (text) => { return <span>{text}</span> }
            },
            // {
            //     title: '服务器',
            //     align: 'center',
            //     dataIndex: 'server'
            // },
            // {
            //     title: '端口',
            //     align: 'center',
            //     dataIndex: 'port'
            // },
            // {
            //     title: '发件人',
            //     align: 'center',
            //     dataIndex: 'sender'
            // },
            {
                title: '主题',
                align: 'center',
                ellipsis: true,
                dataIndex: 'title',
            },
            {
                title: '接收人',
                align: 'center',
                ellipsis: true,
                dataIndex: 'receivers'
            },
            {
                title: '发送状态',
                align: 'center',
                width: 100,
                dataIndex: 'state',
                render: (text) => {
                    switch (text) {
                        case 0:
                            return <div className="succcessTag"><Icon type="check-circle" />&nbsp;<span className="text">成功</span></div>
                            break;
                        case 1:
                            return <div className="failTag"><Icon type="close-circle" />&nbsp;<span className="text">失败</span></div>
                            break;
                        case 2:
                            return <div className="defaultTag"><Icon type="info-circle" />&nbsp;<span className="text">未发送</span></div>
                            break;
                        default:
                            return ""
                    }
                }
            },
            {
                title: '发件时间',
                align: 'center',
                ellipsis: true,
                dataIndex: 'send_time',
                sorter: (a, b) => new Date(a.send_time).getTime() - new Date(b.send_time).getTime(),
                //sorter : (a, b) => a - b,

            }, {
                title: '邮件类型',
                align: 'center',
                width: 100,
                dataIndex: 'type',

            },
            {
                title: '邮件内容',
                align: 'center',
                dataIndex: 'content',
                render: (text, item) => {
                    return <Button type="primary" size="small" icon="file-text" onClick={() => {

                        this.setState({
                            visible: true,
                            content: text,
                            appendixs: item.appendixs,
                            title: item.title,
                            selectItem: item,
                        })
                        // setTimeout(function () {
                        //     document.getElementById("detail").innerHTML = text;
                        // }, 200)
                    }

                    }>详情</Button>
                }

            },
        ],
    };
    componentWillMount() {
        this.getEmailList()
    }
    //获取邮件列表
    getEmailList = (page = 1, pageSize = this.state.pageSize, query = {}) => {
        let params = query || {}
        params["page"] = page;
        params["size"] = pageSize;
        // if (!state) {

        // } else {
        // params["state"] = state
        // }
        let url = email_Url + "/emails";
        this.props.dispatch(isLoading(true));
        AxiosObj.MA_HTTP_GET2(url, params, (response) => {
            this.props.dispatch(isLoading(false));
            //console.log(response)
            //console.log(response.data)
            let data = response.data;

            let list = [];
            // console.log(data);
            let total = response.total;
            for (let i in data) {
                let map = {}
                // console.log(i);
                map['key'] = (page - 1) * pageSize + parseInt(i) + 1;
                map['index'] = (page - 1) * pageSize + parseInt(i) + 1;
                map['id'] = data[i].id;
                map['server'] = data[i].server;
                map["port"] = data[i].port;
                map['send_time'] = data[i].send_time;
                map['sender'] = data[i].sender;
                map['receivers'] = data[i].receivers;
                map['state'] = data[i].state;
                map['title'] = data[i].title;
                map['type'] = data[i].type;
                map['content'] = data[i].content;
                map['username'] = data[i].username;
                map['appendixs'] = data[i].appendixs;
                // let statusPassTime = moment(alertList[i].endsAt).format('YYYY-MM-DD HH:mm:ss');
                // map['time'] = statusPassTime;
                // map['describe'] = alertList[i].annotations.description;
                list[i] = map;
            };
            this.setState({
                dataSource: list,
                total: total,
                page: page,
                pageSize: pageSize,
                query,
            })
            // console.log(tendency);

        }, (error) => {
            message.error(error)
        })

    }
    //查询
    onFinish = e => {
        e.preventDefault();
        let { page, pageSize } = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                let state = values.state;
                this.getEmailList(page, pageSize, { state: state });
            }
        });
    };
    //附件下载
    goDown = (id) => {
        let url = email_Url + "/appendix";
        let params = {}
        params['id'] = id;
        AxiosObj.MA_HTTP_GET2(url, params, (response) => {
            //console.log(response)
            //console.log(response.data)
            let data = response.data.content;
            // 将二进制数据转为字符串
            const utf8decoder = new TextDecoder()
            data = JSON.parse(data)
            console.log("data", data)
            const u8arr = new Uint8Array(data)
            const temp = utf8decoder.decode(u8arr)
            // console.log("2进制转化",temp)

            // let url = window.URL.createObjectURL(new Blob([temp], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}))
            let url = window.URL.createObjectURL(new Blob([temp]))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', `${response.data.name}`)
            document.body.appendChild(link)
            link.click();
            document.body.removeChild(link); // 下载完成移除元素

        }, (error) => {
            message.error("下载失败")
        })
    };
    getIconState(state) {
        let IconState = null;
        let IconText = "";
        if (state === 0) {
            IconState = <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />;
            IconText = "发送成功";
        } else if (state === 2) {
            IconState = <Icon type="question-circle" theme="twoTone" twoToneColor="orange" />;
            IconText = "未发送";
        } else {
            IconState = <Icon type="close-circle" theme="twoTone" twoToneColor="#f50" />;
            IconText = "发送失败";
        }
        // return <Tooltip title={IconText}>
        //     {IconState}
        // </Tooltip>
        return IconState
    }
    render() {
        let { dataSource, loading, columns, appendixs, content, selectItem, page, pageSize, query } = this.state;
        const { getFieldDecorator } = this.props.form;
        //附件列表UI组件
        const appendixsGroup = () => {
            console.log("value", appendixs)
            if (!appendixs || typeof appendixs !== "object") {
                return null;
            }
            const elemt = appendixs.map((item) => {
                // return (
                //     <Button style={{ marginRight: 10 }}
                //         onClick={() => { this.goDown(item.id) }}
                //     >
                //         {item.name}
                //     </Button>
                // )
                return (
                    <span className="fileLink" title={item.name}>
                        <a onClick={() => { this.goDown(item.id) }}><Icon type="tags" />{item.name}</a>
                    </span>
                )
            })
            return <div className="fileDiv">{elemt}</div>

        }

        return (<div className=" emailListDiv">
            <CustomBreadcrumb arr={["邮件管理", "邮件列表"]} />
            <Card>
                <Form
                    layout={'inline'}
                    initialValues={{ state: '' }}
                    onSubmit={this.onFinish}

                >
                    <Form.Item label="发送状态">
                        {getFieldDecorator('state', {
                        })(
                            <Select style={{ width: 120 }} onChange={(value) => {
                                console.log(value)
                            }}>
                                <Option value={0}>成功</Option>
                                <Option value={1}>失败</Option>
                                <Option value={2}>未发送</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" icon="search" htmlType="submit" >
                            查询
                        </Button>&nbsp;
                        <Button icon="reload" onClick={() => {
                            this.getEmailList(1, pageSize, {})
                            this.props.form.resetFields();
                        }}>
                            重置
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Card title={<span style={{ fontWeight: 600 }}>邮件发送列表</span>}
                style={{ marginTop: 8 }}
                className="cardDiv"
            >
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    size="small"
                    loading={loading}
                    rowKey={(text) => { return text.id }}
                    pagination={{
                        current: this.state.page, pageSize: this.state.pageSize, total: this.state.total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            this.getEmailList(1, size, query);
                        },
                        onChange: (page, pageSize) => {
                            //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                            console.log(page, pageSize);
                            this.getEmailList(page, pageSize, query)
                        }
                    }}
                    onChange={() => this.handleChange}
                />
                {/* <Tabs tabPosition="left" className="mailTabs" onChange={(e) => {
                    const d = dataSource.filter((i) => i.id + "" === e)[0] || {};
                    this.setState({ selectItem: d })
                }}>
                    {dataSource.map((item, key) => {
                        return <TabPane tab={<div className="mailTitle">
                            <div className="title"><span>{item.title}</span>{this.getIconState(item.state)}</div>
                            <div className="user">
                                <div className="sender"><Icon type="double-left" />{item.sender}</div>
                                <div className="receivers"><Icon type="double-right" />{item.receivers}</div>
                            </div>

                        </div>} key={item.id + ""}>
                            <div className="emailListModalDiv">
                                <table className="emailTab"><tbody>
                                    <tr >
                                        <td className="title">主题:</td>
                                        <td>{selectItem.title}</td>
                                    </tr>
                                    <tr>
                                        <td className="title">发件人:</td>
                                        <td><Icon type="user" />{selectItem.sender}</td>
                                    </tr>
                                    <tr>
                                        <td className="title">收件人:</td>
                                        <td><Icon type="mail" />{selectItem.receivers}</td>
                                    </tr>
                                    <tr>
                                        <td className="title">时间:</td>
                                        <td><Icon type="clock-circle" />{selectItem.send_time}</td>
                                    </tr>
                                </tbody></table>
                                <div>{appendixsGroup()}</div>
                                <div id={"detail"}
                                    dangerouslySetInnerHTML={{ __html: selectItem.content }}
                                />
                            </div>
                        </TabPane>
                    })}
                </Tabs> */}

            </Card>
            {/* <p />
            <Row>
                <Col span={9}>
                    <Card className="emailTitle">
                        <List
                            itemLayout="horizontal"
                            dataSource={dataSource}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={<div className="head">
                                            <div className="title">{this.getIconState(item.state)}{item.title}</div>
                                            <div className="time">{item.send_time}</div>
                                        </div>}
                                        description={<div className="description">{item.sender}<Icon type="forward" />{item.receivers}</div>}
                                        onClick={() => { this.setState({ selectItem: item }) }}
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                </Col>
                <Col span={15}>
                    <Card className="emailContent">
                        <div id={"detailHtml"}
                            dangerouslySetInnerHTML={{ __html: selectItem.content || "" }}
                        />
                    </Card>
                </Col>
            </Row> */}
            <Modal
                width={800}
                title={"主题:" + this.state.title}
                visible={this.state.visible}
                footer={null}
                onCancel={() => {
                    this.setState({
                        visible: false
                    })
                }}
                className="emailListModal"
            >
                <div className="emailListModalDiv">
                    <table className="emailTab"><tbody>
                        <tr >
                            <td className="title">主题:</td>
                            <td>{selectItem.title}</td>
                        </tr>
                        <tr>
                            <td className="title">发件人:</td>
                            <td><Icon type="user" />{selectItem.sender}</td>
                        </tr>
                        <tr>
                            <td className="title">收件人:</td>
                            <td><Icon type="mail" />{selectItem.receivers}</td>
                        </tr>
                        <tr>
                            <td className="title">时间:</td>
                            <td><Icon type="clock-circle" />{selectItem.send_time}</td>
                        </tr>
                    </tbody></table>

                    <div>{appendixsGroup()}</div>
                    <div id={"detail"}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </div>
            </Modal>
        </div>)
    }
}
export default connect()(Form.create()(emailList))