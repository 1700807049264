import React from "react";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import {Button, Card, Col, Form, Input, Modal, Row, Table} from "antd";
import FilterForm from "../../../../components/FilterForm";
import {formList} from "./formList";
import confirm from "antd/es/modal/confirm";
import {deleteObj, batchDelete, fetchList, fetchObj, patchEdit} from "../../zx-api/line-out";

class LineOut extends React.Component {

    state = {
        tableData: [],//表格数据
        loading: false, //加载数据后遮罩层
        tableDataTotal: 0,
        tableDataCurrent: 1,
        tableDataPageSize: 15,
        selectedRowKeys: [],
    }

    componentWillMount() {
        const {tableDataCurrent, tableDataPageSize } = this.state
        this.update(tableDataPageSize, tableDataCurrent)
    }

    //更新数据方法
    update = ( tableDataPageSize, tableDataPageNum, query) => {
        let response = {}
        this.setState({
            loading: true,
            selectedRowKeys: []
        })
        query = query || {}
        query['page'] = tableDataPageNum
        query['size'] = tableDataPageSize
        fetchList(query, res => {
            // alert(JSON.stringify(res))
            res = res.data
            const tableData = res.results
            const tableDataTotal = res.count
            this.setState({ tableData, tableDataTotal, loading: false, tableDataCurrent: tableDataPageNum })
        })
    }

    //弹出详情界面
    tableDataDetail(id){
        this.setState({editObj: {}, isUpdate: true})
        fetchObj(id, res => {
            const editObj = res.data
            this.setState({ editObj, detailVisible: true, editBtnVisible: true, isEdit: true, editDisabled: true,})
            this.props.form.setFieldsValue({...editObj})
        })
    }

    // 编辑按钮
    handleEdit = () => {
        this.setState({editDisabled: false, submitBtnVisible: true, editBtnVisible: false})
    }
    // 编辑提交 新建提交
    handSubmit = () => {
        this.handleOk()

        this.setState({editObj: {}, isUpdate: false})

    }

    // 删除操作
    delete(){
        const { selectedRowKeys } = this.state
        if (selectedRowKeys.length < 2) {
            deleteObj(selectedRowKeys, res => {
                this.update(this.state.tableDataPageSize, 1, {})
            })
        } else {
            batchDelete(selectedRowKeys, res => {
                this.update(this.state.tableDataPageSize, 1, {})
            })
        }
    }

    // 新建操作
    addObj = () => {
        this.props.form.resetFields()
        this.setState({isUpdate: false, detailVisible: true, isEdit: false, submitBtnVisible: true, editBtnVisible: false })
    }

    handleOk = () => {
        this.setState({ detailLoading: true });
        this.props.form.validateFieldsAndScroll((err, value) => {
            let {editObj} = this.state
            editObj = {...editObj, ...value} // 用value的值去覆盖
            console.log(JSON.stringify(editObj))
            patchEdit(editObj, res => {
                alert(JSON.stringify(res))
            })

        })
        // setTimeout(() => {
        //     this.setState({ detailLoading: false, detailVisible: false });
        // }, 3000);
    };

    handleCancel = () => {
        this.setState({ detailVisible: false, isEdit: false, detailLoading: false, editBtnVisible: false,
            submitBtnVisible: false });
    };

    render() {
        const {
            tableDataCurrent, tableDataPageSize, tableDataTotal, query, loading, tableData, selectedRowKeys,
            importFileVisible, importFileLoading, detailVisible, detailLoading, editDisabled, isEdit, editBtnVisible, submitBtnVisible
        } = this.state

        const { getFieldDecorator } = this.props.form;


        const tableDataColumns = [
            {
                title: '专线名称',
                dataIndex: 'netEquName',
                key: 'netEquName',
                render: (text, item, index) => {
                    return (
                        <div>
                            {
                                <span style={{ color: "blue", cursor: "pointer" }} onClick={ this.tableDataDetail.bind( this, item.id ) }>{ text }</span>
                            }
                        </div>
                    )
                }
            },
            {
                title: '专线号',
                dataIndex: 'lineNo',
                key: 'lineNo',
            },
            {
                title: '端口',
                dataIndex: 'port',
                key: 'port',
            },
            {
                title: '带宽',
                dataIndex: 'bandwidth',
                key: 'bandwidth',
            },
            {
                title: '运营商',
                dataIndex: 'operator',
                key: 'operator',
            },
            {
                title: '专线类型',
                dataIndex: 'lineType',
                key: 'lineType',
            },
            {
                title: 'IP地址',
                dataIndex: 'ipAddr',
                key: 'ipAddr',
            },
            {
                title: 'ACL',
                dataIndex: 'ACL',
                key: 'ACL',
            },
            {
                title: '部门',
                dataIndex: 'department',
                key: 'department',
            },
        ]

        //控制表格勾选
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                this.setState({ selectedRowKeys });
            }
        }

        // 编辑框表单布局
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };

        return (
            <div>
                <CustomBreadcrumb arr={["外联专线", "专线列表"]}/>
                <Card>
                    <Row>
                        {/* 筛选 */}
                        <Col span={24}>
                            <FilterForm formList={formList}
                                        filterSubmit={(query) => {
                                            this.setState({query})
                                            this.update(tableDataPageSize, 1, query)
                                        }}
                                        resetTab={() => {
                                            this.setState({query: {}})
                                            this.update(tableDataPageSize, 1, {})
                                        }}
                            />
                        </Col>
                    </Row>
                </Card>
                <Card title={<span>设备列表</span>}
                      extra={
                          <div>
                              <Button type='default' icon='redo' onClick={e => {
                                  this.update(tableDataPageSize, 1, query)
                              }}>刷新</Button>
                              {/*<Button type='default' style={{color: "#fff", backgroundColor: "green"}} onClick={this.addObj}>新建</Button>*/}
                              <Button type='danger' icon='' onClick={
                                  e => {
                                      const _self = this
                                      if (this.state.selectedRowKeys.length > 0){
                                          confirm({
                                              title: '您确认删除吗?',
                                              content: `您确认要删除这${this.state.selectedRowKeys.length}项吗？`,
                                              okText: '删除',
                                              okType: 'danger',
                                              cancelText: '取消',
                                              onOk() {
                                                  _self.delete()
                                              },
                                              onCancel() {

                                              },
                                          });
                                      } else {
                                          alert("请选择要删除的对象！")
                                      }
                                  }
                              } style={ {} }>
                                  删除
                              </Button>
                              <Button type='primary'
                                      icon='export'
                                      onClick={this.exportExcel}
                                      style={{marginLeft: 8}}>导出Excel</Button>

                          </div>
                      }
                      sytle={{marginTop: 18}}>
                    <Table
                        dataSource={ tableData }
                        columns={ tableDataColumns }
                        rowSelection={ rowSelection }
                        rowKey={ value => value.id }
                        loading={loading}
                        size="small"
                        pagination={{
                            current: tableDataCurrent, pageSize: tableDataPageSize, total: tableDataTotal,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                console.log(page, pageSize);
                                this.update(pageSize, page, query)
                            }
                        }}
                    />

                </Card>


                {/*弹出窗口*/}
                <Modal
                    visible={detailVisible}
                    title={`${this.state.isUpdate ? '详情' : '新建'}`}
                    width='1000px'
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            取消
                        </Button>,
                        <Button style={{
                            backgroundColor: "green",
                            color: 'white',
                            visibility: `${editBtnVisible ? 'visible' : 'collapse'}`
                        }} disabled={!isEdit} onClick={this.handleEdit}>
                            编辑
                        </Button>,
                        <Button key="submit" type="primary" loading={detailLoading}
                                style={{visibility: `${submitBtnVisible ? 'visible' : 'collapse'}`}}
                                onClick={this.handleOk}>
                            提交
                        </Button>,
                    ]}
                >
                    <Form onSubmit={this.handSubmit} {...formItemLayout}>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="专线名称">
                                    {
                                        getFieldDecorator('netEquName',
                                            {rules: [{}]}
                                        )(
                                            <Input disabled={editDisabled} placeholder="专线名称"/>)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="专线编号">
                                    {getFieldDecorator('lineNo',
                                        {rules: [{}]})(<Input disabled={editDisabled} placeholder="专线编号"/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="端口">
                                    {
                                        getFieldDecorator('port',
                                            {rules: [{}]}
                                        )(
                                            <Input disabled={editDisabled} placeholder="端口"/>)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="带宽">
                                    {getFieldDecorator('bandwidth',
                                        {rules: [{}]})(<Input disabled={editDisabled} placeholder="带宽"/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="运营商">
                                    {
                                        getFieldDecorator('operator',
                                            {rules: [{}]}
                                        )(
                                            <Input disabled={editDisabled} placeholder="运营商"/>)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="专线类型">
                                    {getFieldDecorator('lineType',
                                        {rules: [{}]})(<Input disabled={editDisabled} placeholder="专线类型"/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="IP地址">
                                    {
                                        getFieldDecorator('ipAddr',
                                            {rules: [{}]}
                                        )(
                                            <Input disabled={editDisabled} placeholder="IP地址"/>)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="ACL">
                                    {getFieldDecorator('ACL',
                                        {rules: [{}]})(<Input disabled={editDisabled} placeholder="ACL"/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="部门">
                                    {
                                        getFieldDecorator('department',
                                            {rules: [{}]}
                                        )(
                                            <Input disabled={editDisabled} placeholder="部门"/>)
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        );
    }
};

const LineOutForm = Form.create()(LineOut)
export default LineOutForm