import React, { Fragment } from 'react';
import { Upload, Button, Icon, For } from 'antd';

class MyUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    listener = () => {
        const { onChange } = this.props;
        $('#hiddenFileBtn').unbind();
        $('#hiddenFileBtn').change(function () {
            var file = this.files[0];
            let _fileData = null;
            if (!file) { return; }
            // $(this).val("");	//清空文件控件的内容
            let _fileName = file.name;
            $('#uploadBtn #btnName').text(file.name + " 读取中");
            //开始读取文件数据
            var reader = new FileReader();
            //读取二进制文件
            reader.readAsArrayBuffer(file);
            //读取UTF-8文件
            // reader.readAsText(file, "UTF-8");
            //文件读取完毕后该函数响应
            reader.onload = function () {
                _fileData = this.result;
                console.log(_fileData);
                //读取完成
                onChange(_fileData);
                $('#uploadBtn #btnName').text(_fileName);
            }
        });
    }
    render() {
        return (
            <Fragment>
                <Button type="primary" icon="pushpin" id="uploadBtn"
                    onClick={() => { this.listener(); $('#hiddenFileBtn').click(); }}>
                    <span id="btnName">上传文件</span>
                </Button>
                <input id="hiddenFileBtn" type="file" multiple="multiple"
                    style={{ display: 'none' }}
                />
            </Fragment>
        );
    }
}
export default MyUpload