/**
 * Created by YIMINE on 2019/1/24.
 */
import React from 'react'
import {
    Card, Form, Input, Radio, Spin, Modal, Icon, Select, message, Table,
    notification, Button, Typography, Row, Col
} from 'antd';
import './index.scss'
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import TimePlan from "../config/task/newTask/timePlan";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import SelectDevice from '@/components/SelectDevice/index';
import ResUtils from "@/utils/ResUtils";
import { Link } from 'react-router-dom';
const RadioGroup = Radio.Group;
const { Option } = Select;
const TextArea = Input.TextArea;
class NewInspect extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _pageSize: 10,    //当前每页显示条数
        loading: false,
        querys: {},    //查询条件  
        RadioValue: 'manual',
        cardTitle: "新建任务",
        taskPlanData: { type: 'manual', content: null },
        visibleTime: false,             //时间计划模态框
        timeBtn: false,             //时间计划按钮
        pageSpinning: false,        //页面加载控制
        searchText: '',
        report_type: ["巡检"],
        Tags: [],
        Groups: [],
        addselectedRowKeys: [],
        selectedRows: [],
        SelectTaskList: [],
        SelectTempList: [],
        checkInput: [],
        task: {},
        AutoObject: null,
        commit: false,
        flow_data: null,
        SelTask: {},
        templateObj: null,
        devVis: false,
        selTemplateId: "",
    };
    componentDidMount() {
        let thisTemp = this;

        let bodyJson = this.props.location.bodyJson || {};
        let IdData = bodyJson.taskId;
        if (IdData) {//修改
            this.setState({ pageSpinning: true });
            Socket.ExecuteAction('QueryTaskDetail', { _id: IdData }, "", (resp) => {
                console.log(resp);
                let task = resp.task;
                let AutoObject = task;
                let cardTitle = '编辑任务';

                let selectedRows = task.deviceList || [];
                const addselectedRowKeys = selectedRows.map((item) => {
                    return JSON.stringify({ resourceName: item.resourceName })
                });
                thisTemp.updataTaskList(task.template);
                thisTemp.setState({
                    checkInput: task.input,
                    pageSpinning: false, addselectedRowKeys, selectedRows,
                    task, cardTitle, RadioValue: task.taskPlan.type, AutoObject,
                    timeBtn: ((task.taskPlan.type) === 'auto' ? true : false)
                });
            }, (error) => {
                message.error('数据返回错误:' + JSON.stringify(error))
            });
        } else {
            this.updataTaskList();
        }
        Socket.QueryData('QueryTagsGroups', { tagClass: 'task' }, (result) => {
            if (result.retcode === '00') {
                thisTemp.setState({
                    Tags: result.tags,
                    Groups: result.groupNames,
                });
            }
        });

        this.updataDev();
    }
    updataTaskList = (tempID) => {
        Socket.ExecuteAction("QueryTasksPage",
            { pageNum: 1, pageSize: 10000, querys: { reportTags: "巡检", ifTemplate: true } }, "/taskQueryTask", (result) => {
                const SelectTempList = result.tasks.map((item) => {
                    return { input: item.input, name: item.taskName, output: item.output, id: item._id };
                });
                const SelectTaskList = result.tasks;
                if (tempID) {
                    const SelTemp = SelectTaskList.filter((item) => { return item._id === tempID });
                    if (SelTemp[0] && SelTemp[0].form_template_id) {
                        this.queryTemplate(SelTemp[0].form_template_id);
                        this.setState({ selTemplateId: SelTemp[0]._id })
                    }
                }
                this.setState({ SelectTaskList, SelectTempList })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };
    onParamChange = (data) => {
        this.setState({ visibleTime: false, taskPlanData: data });
    };
    handleSubmit = () => {

        let thisTemp = this;
        let { taskPlanData, addselectedRowKeys, checkInput, SelTask, selectedRows, } = this.state;
        if (Utils.isEmpty(addselectedRowKeys)) {
            message.error("请选择设备！");
            return;
        }
        thisTemp.setState({ pageSpinning: true });
        // e.preventDefault();
        thisTemp.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                let body = values;
                let taskPlan = {
                    type: taskPlanData.type,
                    content: taskPlanData
                };
                if (taskPlanData.type === 'manual') {
                    taskPlan.type = 'manual'
                } else {
                    taskPlan.type = 'auto'
                }
                body.taskPlan = taskPlan;
                // let tempFlowData = base_flow_data;
                // let group = [];
                // let group = tempFlowData.nodes["1585029213380"].taskdata.input.script.group;
                // addselectedRowKeys.map((resItem, key) => {
                //     let JsonItem = JSON.parse(resItem);
                //     group.push({ key, subtask: values.SelTask, resource: JsonItem.resourceName, deviceIp: JsonItem.deviceIp })
                // })
                // tempFlowData.nodes["1585029213380"].taskdata.input.script.group = group;
                // body.taskContent = tempFlowData;
                body.reportTags = "巡检";

                body.input = checkInput.map((item) => {
                    item.value = document.getElementById(item.variable).value;
                    return item;
                })
                body.output = SelTask.output
                body.template = SelTask._id
                body.deviceList = selectedRows;
                body.ifTemplate = false;
                console.log('body', body);
                thisTemp.setState({ pageSpinning: true });
                if (!thisTemp.state.AutoObject) {
                    Socket.ExecuteAction("AddTask", body, "", () => {
                        message.success('新增成功');
                        thisTemp.goBackPage();
                    }, (error) => {
                        notification.open({
                            message: '新增失败',
                            description: error,
                            icon: <Icon type="frown" style={{ color: 'red' }} />,
                        });
                        thisTemp.setState({ pageSpinning: false });
                    });
                } else {
                    body._id = thisTemp.state.task._id;
                    Socket.ExecuteAction("UpdateTask", body, "", () => {
                        message.success('修改成功');
                        thisTemp.goBackPage();
                    }, (error) => {
                        notification.open({
                            message: '修改失败',
                            description: error,
                            icon: <Icon type="frown" style={{ color: 'red' }} />,
                        });
                        thisTemp.setState({ pageSpinning: false });
                    });
                }
            }
        });
    };
    goBackPage() {
        let bodyJson = this.props.location.bodyJson;
        let path = {
            pathname: '/InspectionMan',
            bodyJson,
        };
        this.props.history.push(path);
    }
    updataDev = (pageSize, pageNum, querys2) => {
        this.setState({ loading: true, });
        let { _pageSize, _current, querys } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys2 || querys;
        console.log(bodyJson);
        Socket.ExecuteAction('QueryResourcesPage',
            bodyJson, "/queryResource", (result) => {
                let Resources = result.resources;
                Resources.map((item, index) => {
                    item.key = index;
                    item.index = index;
                    return item
                });
                this.setState({
                    DevTableData: Resources,
                    _current: pageNum || 1,
                    total: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });

    };
    onChangeRadio = (e) => {
        console.log('radio checked', e.target.value);
        if (e.target.value === 'auto') {
            this.setState({
                timeBtn: true,
                RadioValue: e.target.value,
                visibleTime: true,
            });
        } else {
            this.setState({
                timeBtn: false,
                RadioValue: e.target.value,
                visibleTime: false,
            });
        }
    };
    getTableList() {
        let { selectedRows, addselectedRowKeys } = this.state;
        const columns = [...ResUtils.getColumns(10000, 1), {
            title: "操作",
            render: (a, item, index) => {
                return <Button type="danger" icon="delete" size="small" onClick={() => {
                    const newData = selectedRows.filter((row) => { return row._id != item._id });
                    const newData2 = addselectedRowKeys.filter((row) => {
                        return row != JSON.stringify({ resourceName: item.resourceName })
                    });
                    this.setState({ selectedRows: newData, addselectedRowKeys: newData2 })
                }} />
            }
        }];
        const steps = <div style={{ border: "1px solid #e2e2e2", padding: 10 }}>
            <Table
                dataSource={selectedRows}
                columns={columns}
                size="small"
                scroll={{ y: 500 }}
                pagination={false}
            />
        </div>
        return steps;
    };
    getTableList2() {
        let { _current, _pageSize, querys, total } = this.state;
        const columns = [...ResUtils.getColumns(_pageSize, _current)];
        const rowSelection = {
            selectedRowKeys: this.state.addselectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ addselectedRowKeys: selectedRowKeys, });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ addselectedRowKeys: [] });
                    },
                },
            ],
        };
        const steps = <div style={{ border: "1px solid #e2e2e2", padding: 10 }}>
            <FilterForm
                tagClass="resource"
                filterSubmit={(querys) => {
                    this.setState({ querys2: querys })
                    this.updataDev(this.state._pageSize2, 1, querys)
                }}
                resetTab={() => {
                    this.setState({ querys: {} })
                    this.updataDev(this.state._pageSize2, 1, {})
                }}
            />
            <Table
                dataSource={this.state.DevTableData || []}
                rowSelection={rowSelection}
                columns={columns}
                loading={this.state.loading}
                rowKey={(text, key) => {
                    return JSON.stringify({
                        // deviceIp: text.resourceParams.deviceIp,
                        resourceName: text.resourceName,
                    })
                }}
                size="small"
                pagination={{
                    current: _current, pageSize: _pageSize, total: total,
                    onChange: (page, pageSize) => {
                        this.updataDev(pageSize, page, querys);
                    }
                }}
            />
        </div>
        return steps;
    }
    queryTemplate = (_id) => {
        Socket.ExecuteAction("QueryInspecTemplate",
            { _id }, "/", (result) => {
                if (result.data && result.data[0]) {
                    this.setState({ templateObj: result.data[0] })
                }
            });
    };
    openReport = (data) => {
        Modal.info({
            title: "预览报告",
            width: "80%",
            content: <div>
                <p><strong>报告名称:{data.name}</strong></p>
                <div dangerouslySetInnerHTML={{ __html: data.template }}></div>
            </div>
        })
    }
    render() {
        const { SelectTaskList, SelectTempList, devVis, addselectedRowKeys, checkInput, templateObj,
            cardTitle, timeBtn, task, Tags, pageSpinning } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        return (
            <div className="newInspectDiv">
                <Spin spinning={pageSpinning}>
                    <CustomBreadcrumb arr={["运维管理", { title: "设备巡检", to: "/InspectionMan" }, cardTitle]} />
                    <Card title={<span style={{ fontWeight: 600 }}>{cardTitle}</span>} extra={
                        <Button type="primary" icon="rollback" onClick={() => { this.goBackPage(); }}>返回</Button>
                    }>
                        <Form onSubmit={this.handleSubmit} >
                            <Form.Item
                                label="任务名称"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('taskName', {
                                    initialValue: task.taskName || "",
                                    rules: [{
                                        type: 'string', message: '请输入正确字符！',
                                    }, {
                                        pattern: new RegExp(/^[^\s]+$/, "g"), message: '含有空格等非法字符！',
                                    }, {
                                        required: true, message: '请输入字符!',
                                    }],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item
                                label="任务描述"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('taskDes', {
                                    initialValue: task.taskDes || "",
                                    rules: [{
                                        type: 'string', message: '请输入正确字符！',
                                    },],
                                })(<TextArea style={{ height: 100 }} />)}
                            </Form.Item>
                            <Form.Item
                                label="添加标签"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('tags', {
                                    initialValue: task.tags || [],
                                })(
                                    <Select
                                        mode="multiple"
                                        placeholder="选择标签"
                                    >
                                        {Tags.map(owner => {
                                            return <Option key={owner} value={owner}>
                                                {owner}
                                            </Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item
                                label="执行方式"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('taskPlan.type', {
                                    initialValue: this.state.RadioValue,
                                })(
                                    <div>
                                        <RadioGroup onChange={this.onChangeRadio}
                                            value={this.state.RadioValue}
                                        >
                                            <Radio value="auto">自动</Radio>
                                            <Radio value="manual">手动</Radio>
                                        </RadioGroup>
                                        {timeBtn ? <Button icon='setting'
                                            onClick={() => {
                                                this.setState({ visibleTime: true });
                                            }}
                                        >设置</Button> : ''}
                                    </div>
                                )}
                            </Form.Item>
                            {/* <Form.Item
                                label="任务类型"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('reportTags', {
                                    initialValue: "巡检",
                                    rules: [{
                                        required: true, message: '请选择!',
                                    }],
                                })(
                                    <Select
                                        placeholder="选择当前任务类型"

                                    >
                                        {report_type.map((item, key) => (
                                            <Option key={key} value={item}>
                                                {item}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item> */}
                            <Form.Item
                                label="模板"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('template', {
                                    initialValue: task ? task.template : "",
                                    rules: [{
                                        required: true, message: '请选择!',
                                    }],
                                })(
                                    <Select
                                        placeholder="选择子任务模板"
                                        showSearch
                                        onChange={(key) => {
                                            const SelTemp = SelectTaskList.filter((item) => { return item._id === key });
                                            this.setState({ checkInput: SelTemp[0].input, SelTask: SelTemp[0], templateObj: null });
                                            if (SelTemp[0].form_template_id) {
                                                this.queryTemplate(SelTemp[0].form_template_id);
                                            }
                                        }}
                                    >
                                        {SelectTempList.map((item, key) => {
                                            return <Option key={key} value={item.id}>
                                                {item.name}
                                            </Option>
                                        })}
                                    </Select>
                                )}
                                {templateObj ?
                                    <Link style={{ position: "absolute", right: "-90px" }}
                                        to={{
                                            pathname: `/newTask:${this.state.SelTask._id || this.state.selTemplateId}`,
                                            bodyJson: {
                                                pageNum: this.props.location.bodyJson.pageNum,
                                                pageSize: this.props.location.bodyJson.pageSize,
                                            },
                                            state: {
                                                taskId: this.props.location.bodyJson.taskId || "",
                                                pathname: this.props.location.pathname || ""
                                            }
                                        }}
                                    >
                                        查看模版详情
                                    </Link> : ""}
                            </Form.Item>
                            {templateObj ? <Row><Col span={6} className="lab">
                                <div className="ant-form-item-label">
                                    <label className="ant-form-item" >绑定报告</label>
                                </div></Col><Col span={12}><Button size="small" type="link" icon="link" onClick={() => {
                                    this.openReport(templateObj);
                                }}
                                >{templateObj.name}</Button></Col></Row> : ""}
                        </Form>
                        <Row>
                            <Col span={6} className="lab">
                                <div className="ant-form-item-label">
                                    <label className="ant-form-item-required" >任务参数</label>
                                </div>
                            </Col>
                            <Col span={12} >

                                <table className="paramTab" id="paramTab">
                                    <tbody>
                                        <tr><th>参数名称</th><th>参数值(点击编辑)</th><th>类型</th><th>参数描述</th></tr>
                                        {checkInput.map((item, key) => {
                                            return <tr key={key}><td>{item.label}</td><td
                                                suppressContentEditableWarning="true"
                                                contentEditable="true" >
                                                <Input id={item.variable} value={item.value || ""} size="small" onChange={(e) => {
                                                    item.value = e.target.value;
                                                    let checkInput2 = checkInput;
                                                    checkInput2[key] = item;
                                                    this.setState({ checkInput: checkInput2 })
                                                }} />
                                            </td><td>{item.type}</td><td>{item.description}</td></tr>
                                        })}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Typography.Text strong>已选设备列表:</Typography.Text>&nbsp;&nbsp;&nbsp;
                        {/* <Button icon="check-square" type="primary" onClick={() => this.setState({ devVis: true })}>点击选择设备</Button> */}
                        {Utils.getHR()}
                        {this.getTableList()}
                        <Button style={{ width: "100%", marginTop: 5 }} icon="plus-circle" type="dashed"
                            onClick={() => this.setState({ devVis: true })}>添加设备</Button>
                        {/* {this.getTableList2()}*/}
                        <div style={{ textAlign: "center", marginTop: 30 }}>
                            <Button type="primary" icon="check-circle" style={{ width: 150 }} onClick={() => { this.handleSubmit() }}
                            >保存</Button>
                            <Button type="danger" icon="close-circle" style={{ width: 150, marginLeft: 10 }}
                                onClick={() => { this.goBackPage(); }}>
                                取消</Button>
                        </div>
                        <Modal
                            title="时间计划"
                            visible={this.state.visibleTime}
                            footer={null}
                            onCancel={() => { this.setState({ visibleTime: false, }); }}
                        >
                            <TimePlan initValue={(!Utils.isEmpty(task)) && task.taskPlan.type === 'auto' ? task.taskPlan.content : null}
                                onParamChange={(data) => { this.onParamChange(data) }} />
                        </Modal>
                        {/* <Modal
                    title="选择巡检设备"
                    visible={this.state.visible_insp}
                    onOk={() => { this.setState({ visible_insp: false }) }}
                    onCancel={() => { this.setState({ visible_insp: false }) }}
                    footer={null}
                    style={{ top: 50 }}
                    width="90%"
                    destroyOnClose
                >
                    
                </Modal> */}
                    </Card>
                </Spin>
                <Modal
                    visible={devVis}
                    width="90%"
                    title="选择巡检设备"
                    onOk={() => { this.setState({ devVis: false }) }}
                    onCancel={() => this.setState({ devVis: false, })}
                    style={{ top: 20 }}
                >
                    <SelectDevice
                        onChange={(selectedRowKeys, selectedRows) => {
                            console.log(selectedRowKeys, selectedRows);
                            this.setState({ addselectedRowKeys: selectedRowKeys, selectedRows });
                        }}
                        selectedRowKeys={addselectedRowKeys}
                    />
                </Modal>
            </div>
        );
    }
}
const NewInspectFrom = Form.create()(NewInspect);

export default NewInspectFrom;