import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Modal, Tooltip, Icon, Descriptions, Row, Col, Typography, Input, message
} from "antd";
import AxiosObj from '@/axios/index';
import Utils from '@/utils/utils';
import { connect } from 'react-redux'
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import { MonacoDiffEditor } from "react-monaco-editor"
import MonacaEditor from "react-monaco-editor"
import { isLoading } from '@/redux/action'
import "./index.scss"
const { Text } = Typography;
function AclRecord(props) {
    const [total, set_total] = useState(0);
    const [_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [Recordes, set_Recordes] = useState([]);
    const [detailVis, setDetailVis] = useState(false);
    const [detailNode, setDetailNode] = useState({});
    const [selectedRowKeys, setRowKey] = useState([]);
    const [selectedRows, setRow] = useState([]);
    useEffect(() => {//componentDidMount
        getRecordes();

        return () => { //componentWillUnmount
        }
    }, []);
    const getRecordes = (pageSize, pageNum) => {
        props.dispatch(isLoading(true));
        let bodyJson = {};
        bodyJson.page_size = pageSize || _pageSize;
        bodyJson.page_no = pageNum || _current;
        AxiosObj.HTTP_POST("acl", "aclRecords", bodyJson, (result) => {
            props.dispatch(isLoading(false));
            set_Recordes(result.acl_records);
            set_total(result.count);
            set_current(bodyJson.page_no);
        });
    }
    const delRecordes = () => {
        AxiosObj.HTTP_POST("acl", "delAclRecords", selectedRows, () => {
            getRecordes();
            message.success("删除成功!")
        });
    }
    const findAclRecordsByIp = (ip, pageSize, pageNum) => {
        props.dispatch(isLoading(true));
        let bodyJson = {};
        bodyJson.page_size = pageSize || _pageSize;
        bodyJson.page_no = pageNum || _current;
        bodyJson.ip = ip;
        AxiosObj.HTTP_POST("acl", "findAclRecordsByIp", bodyJson, (result) => {
            set_Recordes(result.data);
            set_total(result.count);
            set_current(bodyJson.page_no);
            props.dispatch(isLoading(false));
        });
    }
    return <div className="sendRecordDiv">
        <CustomBreadcrumb
            arr={["运维管理", "配置下发", "简单下发", "指令下发记录"]}
        />
        <Card className="seardRecordCard">
            <div><Text strong>设备IP:&nbsp;</Text>
                <Input.Search placeholder="根据IP查询记录" style={{ width: 300 }}
                    onSearch={value => { findAclRecordsByIp(value) }} enterButton />&nbsp;
                <Button icon="reload" onClick={() => {
                    getRecordes();
                }} >重置</Button>&nbsp;
                <Button type="danger" icon="delete" onClick={() => {
                    if (selectedRows.length < 1) { message.info("请选择记录!"); return; }
                    Modal.confirm({
                        title: "消息",
                        content: "你确定删除所选信息吗?",
                        onOk() { delRecordes() }
                    })
                }} >删除</Button>
            </div>
        </Card><p />
        <Card title={<div id="aclrecord">{Utils.BoldFont("指令下发记录")}</div>} extra={null
            // <Button icon="select" type="primary" onClick={() => { props.history.push("/command/simple/send") }} >下发指令</Button>
        }>
            <Table
                rowSelection={{
                    selectedRowKeys: selectedRowKeys,
                    onChange: (selectedRowKeys, selectedRows) => {
                        setRowKey(selectedRowKeys);
                        setRow(selectedRows);
                    },
                }}
                rowKey={(row) => { return row.create_time }}
                columns={[
                    {
                        title: "序号", render: (a, b, c) => {
                            return c + 1 + (_current - 1) * _pageSize
                        }
                    },
                    { title: "下发名称", dataIndex: "config_delivery_name" },
                    { title: "下发时间", dataIndex: "create_time" },
                    { title: "执行人", dataIndex: "operator" },
                    {
                        title: "操作", dataIndex: "info", render: (a, b) => {
                            return <div><Button type="primary" icon="file-text" size="small" onClick={() => {
                                setDetailNode(b);
                                setDetailVis(true);
                            }}>详情</Button>
                            </div>
                        }
                    },

                ]}
                dataSource={Recordes}
                size="small"
                pagination={{
                    pageSize: _pageSize, current: _current, total: total,
                    showTotal: (total) => { return <span>共 {total} 项</span> },
                    onChange: (page, pageSize) => {
                        getRecordes(pageSize, page)
                    }
                }}
            />
        </Card>
        <Modal
            title="记录详情"
            visible={detailVis}
            onCancel={() => { setDetailVis(false); }}
            onOk={() => { setDetailVis(false); }}
            width="70%"
            style={{ top: 20 }}
            className="alcModal"
        >
            <Row gutter={0}>
                <Col span={24}>
                    <Text strong>记录信息</Text>
                    <Descriptions column={2} bordered>
                        {Object.keys(detailNode).map((key, index) => {
                            if (!["_id", "ips", "info", "config_cmd", "baseline"].includes(key)) {
                                let lab = key;
                                let span = 1;
                                switch (key) {
                                    case "config_delivery_name": lab = "下发名称"; span = 1; break;
                                    case "create_time": lab = "记录时间"; span = 1; break;
                                    case "details": lab = "描述"; span = 1; break;
                                    case "operator": lab = "执行人"; span = 1; break;
                                    case "query_cmd": lab = "查询命令"; span = 2; break;
                                }
                                return <Descriptions.Item label={lab} key={index} span={span}>{detailNode[key]}</Descriptions.Item>
                            }
                        })}
                        <Descriptions.Item label={"下发指令"} span={2} >
                            <div className="cmdDiv">{detailNode.config_cmd}</div>
                        </Descriptions.Item>
                        {detailNode.baseline ?
                            <Descriptions.Item label={"基线配置"} span={2} >
                                <div className="cmdDiv">{detailNode.baseline}</div>
                            </Descriptions.Item> : ""}
                        <Descriptions.Item label={"下发设备"} span={2}>
                            <Table
                                columns={[
                                    { title: "设备ip", dataIndex: "ip" },
                                    {
                                        title: "下发结果", align: "left", dataIndex: "comparison_result", render: (a, b) => {
                                            const elemt = b.code === "00" ? <Icon type="check-circle" style={{ color: "#87d068" }} /> : <Icon type="close-circle" style={{ color: "#f50" }} />
                                            return <Tooltip title={a}>{elemt}&nbsp;{b.msg}</Tooltip>;
                                        }
                                    },
                                    {
                                        title: "交互信息", ellipsis: true, dataIndex: "details", render: (a, b) => {
                                            // let elemt = <Popover title={"信息"} content={a} trigger="hover">{a}</Popover>;
                                            let elemt = a;
                                            if ((typeof a === "string")) {
                                                if (a.length > 30) {
                                                    elemt = <Button type={b.code === "00" ? "primary" : "danger"} size="small"
                                                        onClick={() => {
                                                            Modal.info({
                                                                width: "80%",
                                                                title: "交互信息",
                                                                okText: "关闭",
                                                                maskClosable: true,
                                                                content: <MonacaEditor
                                                                    theme="vs-dark"
                                                                    value={a}
                                                                    language="shell"
                                                                    options={{ minimap: { enabled: false }, lineNumbers: "off", scrollBeyondLastLine: false, }}
                                                                    height="600"
                                                                />
                                                            })
                                                        }}>详情</Button>
                                                }
                                            } else if ((typeof a === "object")) {
                                                elemt = <Button type={b.code === "00" ? "primary" : "danger"} size="small"
                                                    onClick={() => {
                                                        Modal.info({
                                                            width: "95%",
                                                            title: "交互信息",
                                                            okText: "关闭",
                                                            maskClosable: true,
                                                            content: <div className="alcModal">
                                                                {/* <Row gutter={2}>
                                                                    <Col span={12}><strong>基线ACL</strong><div className="cmdComp">{a.baseline}</div></Col>
                                                                    <Col span={12}><strong>设备ACL</strong><div className="cmdComp">{a.device_acl}</div></Col>
                                                                </Row> */}
                                                                <strong className="pull-left">设备配置</strong><strong className="pull-right">基线配置</strong>
                                                                <MonacoDiffEditor
                                                                    theme="vs"
                                                                    value={a.baseline}
                                                                    original={a.device_config}
                                                                    language="shell"
                                                                    options={{ minimap: { enabled: false }, lineNumbers: "off", scrollBeyondLastLine: false, }}
                                                                    height="600"
                                                                />
                                                            </div>
                                                        })
                                                    }}>详情</Button>
                                            }
                                            return elemt
                                        }
                                    },
                                ]}
                                pagination={false}

                                size="small"
                                scroll={{ y: 800 }}
                                dataSource={detailNode.info}
                            />
                        </Descriptions.Item>
                    </Descriptions>

                </Col>
            </Row>
        </Modal>
        <p />
    </div >
}

export default connect()(React.memo(AclRecord))