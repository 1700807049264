import React, { useState, useEffect } from 'react'
import {
    Card, Button, Row, Col, message
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import { global_config } from '../utils/api';
// import { getSelectTime } from '../utils/index';
import { getFormSelectTime, getFormAutoTime } from '../utils/index';
import BaseFrom from "../../utils/baseForm"
import { connect } from 'react-redux'
import { Formik } from "formik"
import {
    SubmitButton,
    ResetButton,
    AddRowButton,
    RemoveRowButton,
    FormItem,
    Select,
    Form,
    Table,
    Input,
    InputNumber,
    AutoComplete
} from "formik-antd"
import "./index.scss"
import "animate.css"
let FromProps = {}
function PrometheusCfg(props) {
    const [configData, setCfgData] = useState({});
    const selectOptions = [
        { name: "5s", value: "5s" },
        { name: "10s", value: "10s" },
        { name: "15s", value: "15s" },
        { name: "30s", value: "30s" },
        { name: "1m", value: "1m" },
        { name: "5m", value: "5m" },
        { name: "10m", value: "10m" }
    ]
    useEffect(() => {//componentDidMount;
        getData();
        http.setDispatch(props.dispatch)
        return () => { }
    }, []);
    function getData() {
        http.get(global_config(), {}, (r) => {
            const val = r.data.record;
            setCfgData(val);
            const { setValues } = FromProps;
            if (setValues) {
                console.log(val);
                setValues(val);
            }
        })
    }
    function onSubmitting(val) {
        http.put(global_config(), val, (r) => {
            message.success("更新成功!");
            getData();
        })
    }
    return <div className="PrometheusCfgDiv">
        <CustomBreadcrumb arr={["监控告警", "数据采集", "prometheus全局配置"]} />
        <Card title="prometheus全局配置">
            <Formik
                initialValues={{}}
                onSubmit={(values, actions) => {
                    console.log(values);
                    onSubmitting(values);
                    actions.setSubmitting(false)
                }}
            >
                {(fromProps) => {
                    FromProps = fromProps;
                    return <Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 8 }}
                        layout="horizontal"
                    >
                        <div className="fromDiv">
                            {getFormSelectTime("evaluation_interval", "评估间隔")}
                            {getFormAutoTime("scrape_interval", "采集频率/抓取间隔")}
                            {getFormSelectTime("scrape_timeout", "采集超时/抓取超时")}
                        </div>
                        <Row>
                            <Col span={8} />
                            <Col span={8}>
                                <div className="btnDiv">
                                    <SubmitButton disabled={false} icon="save">保存</SubmitButton>&nbsp;
                                    <ResetButton disabled={false} icon="reload" >清空</ResetButton>&nbsp;
                                </div>
                            </Col>
                        </Row>
                    </Form>
                }}
            </Formik>
        </Card>
    </div>
}

export default connect()(PrometheusCfg)