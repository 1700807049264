import React, { useState, useEffect } from 'react'
import {
    Table, Button, Icon, message, Divider, Popconfirm
} from "antd";
import http from '@/axios/axios_utils';
import { numberToText } from '../utils/index';
import FormModal from '../utils/formModal';
import { strategys, alarmTemplates, alarmGroups } from '../utils/api';
const FormikInput = require("formik-antd").Input;
import Socket from '@/socket/index';
import SearchCard from './searchCard';
import { connect } from 'react-redux'
import "./index.scss"
import "animate.css"
// /MonitorAlert/v1/alarmTemplates
function AlertTemplates(props) {
    const [visible, setVisible] = useState(false);
    const [total, setTotal] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const [selectItem, setSelectItem] = useState({});
    const [selectedRowKeys, setRowKeys] = useState([])
    const [tableData, setTabData] = useState([{
        "tempId": "esse24144",
        "tempDescription": "esse",
        "tempName": "non laboris enim",
        "tempType": "aliquip id proident pariatur Lorem",
        "tempContent": "cillum"
    }, {
        "tempId": "esse",
        "tempDescription": "esse",
        "tempName": "non laboris enim",
        "tempType": "aliquip id proident pariatur Lorem",
        "tempContent": "cillum"
    }]);
    const [GroupData, setGroupData] = useState([]);
    const [UserData, setUserData] = useState([]);
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
        },
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'ip',
            ellipsis: true,
            width: 40,
            render: (text, item, c) => { return c + 1 + (_current - 1) * _pageSize }
        },
        {
            title: '模板名称',
            dataIndex: 'tempName',
            ellipsis: true,
        },
        {
            title: '模板类型',
            dataIndex: 'tempType',
            ellipsis: true,
            render: (text) => {
                return text === "sms" ? "短信" : "邮件"
            }
        },
        {
            title: '模板描述',
            dataIndex: 'tempDescription',
            ellipsis: true
        },

        // {
        //     title: '通知方式',
        //     dataIndex: 'notifyWay',
        //     ellipsis: true,
        //     render: (text) => {
        //         if (typeof text === "object") {
        //             return text.map((t) => numberToText("notifyWay", t));
        //         } else {
        //             return numberToText("notifyWay", text);
        //         }
        //     }
        // },
        {
            title: '操作',
            render: (text, item, key) => {
                return <div key={key}>
                    <a onClick={() => { setVisible(true); setSelectItem(item); }}><Icon type="edit" />&nbsp;编辑</a>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData([item.tempId]) }}
                        okText="是"
                        cancelText="否"
                    >
                        <a><Icon type="delete" />&nbsp;删除</a>
                    </Popconfirm>
                </div>
            }
        },
    ]
    useEffect(() => {//componentDidMount; 
        http.setDispatch(props.dispatch);
        // getUserData();
        getData();
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(page = 1, size = _pageSize, query = {}) {
        http.get(alarmTemplates(), { page, size, ...query }, (r) => {
            setTabData(r.data.records);
            setTotal(r.data.total);
            set_querys(query);
            set_current(page);
            set_pageSize(size);
        })
    }
    function saveDataSource(val) {
        if (selectItem.tempName) {
            http.put(alarmTemplates(), val, (r) => {
                message.success(r.msg)
                getData();
            })
        } else {
            http.post(alarmTemplates(), val, (r) => {
                message.success(r.msg)
                getData();
            })
        }
        setVisible(false)
    }
    function deleteData(tempIds) {
        if (tempIds.length < 1) {
            message.error("请选择要删除的数据！")
            return
        }
        http.delete_body(alarmTemplates(), { tempIds }, () => {
            message.success("删除成功!");
            getData();
        })
    }

    return <div className="alertDiv">
        <div className="searchDiv">
            <SearchCard onSubmit={(val) => { getData(1, _pageSize, val) }} reSet={() => { getData() }} />
            <div>
                <Button type="primary" icon="file-add" onClick={() => { setVisible(true); setSelectItem({}) }}>添加</Button>&nbsp;
                <Popconfirm
                    title="你确定删除所选记录吗?"
                    onConfirm={() => { deleteData(selectedRowKeys) }}
                    okText="是"
                    cancelText="否"
                ><Button type="danger" icon="delete" >删除</Button>
                </Popconfirm>

            </div>
        </div>
        <Table
            dataSource={tableData}
            rowSelection={rowSelection}
            columns={columns}
            size="small"
            rowKey={r => r.tempId}
            pagination={
                {
                    current: _current, pageSize: _pageSize, total,
                    showTotal: (total) => { return <span>共 {total} 项</span> },
                    pageSizeOptions: ["20", "50", "100", "500", "1000"],
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) => {
                        getData(1, size, _querys)
                    },
                    onChange: (page, pageSize) => { getData(page, pageSize, _querys) }
                }
            }
        />
        <FormModal
            title="编辑模板"
            visible={visible}
            onCancel={() => setVisible(false)}
            onSubmit={(val) => {

                console.log(val)
                saveDataSource(val);
            }}
            footer={null}
            initialValues={selectItem}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            width={"50%"}
            destroyOnClose
            forceRender
            formList={[
                { type: "input", name: "tempName", label: "模板名称", placeholder: "模板名称", required: true, },
                { type: "select", name: "tempType", label: "模板类型", required: true, selectOptions: [{ name: "邮件", value: "email" }, { name: "短信", value: "sms" }] },
                { type: "textArea", name: "tempDescription", label: "模板描述", required: true, },
                {
                    type: "expandForm", name: "tempContent", label: "模板内容",
                    formContent: <FormikInput.TextArea name={"tempContent"} placeholder={"模板内容"} spellCheck="false" className="codeTextArea" />

                },
            ]}
        />
    </div>
}

export default connect()(AlertTemplates)