import React, { useState, useEffect } from 'react'
import {
    Card, Button
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import { dashboard } from '../utils/api';
import BaseForm from '../utils/baseForm';
import { connect } from 'react-redux'
import "./index.scss"
import "animate.css"

function AlertBigScreen(props) {
    const [selectOptions, setOptions] = useState([])
    const [selectOne, setSelectOne] = useState("")
    useEffect(() => {//componentDidMount;
        getData();
        http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(query = {}) {
        http.get(dashboard(), query, (r) => {
            setOptions(r.data.map(r => { return { name: r.name, value: r.url } }));
            setSelectOne(r.data[0].url || "");
        })
    }
    return (
        <div className="monitorAlertBigScreen">
            <CustomBreadcrumb arr={["监控告警", "告警大屏"]} />
            <Card title="告警信息展示"
                extra={<div> </div>}
            >
                <div className="searchDiv">
                    <BaseForm
                        onSubmit={(val) => {
                            document.getElementById("bigScreenIframe").src = val.url;
                            setSelectOne(val.url)
                        }}
                        initialValues={{ url: selectOne }}
                        formList={[
                            {
                                type: "select", name: "url", label: "切换仪表",
                                selectOptions: selectOptions,
                            },
                        ]}
                        btnName="确定"
                    />
                    <div>
                        <Button type="primary" icon="fullscreen" onClick={() => document.getElementById("full").click()}>
                            <a id="full" href={selectOne} target="_blank" style={{ color: "#fff", marginLeft: 10 }}>全屏</a>
                        </Button>&nbsp;
                        <Button type="primary" icon="file-add" onClick={() => {
                            props.history.push("/monitorAlert/dashBoard");
                        }}>仪表盘管理</Button>
                    </div>
                </div>
                <iframe id="bigScreenIframe" src={selectOptions[0] && selectOptions[0].value} />
            </Card>
        </div>
    )
}

export default connect()(AlertBigScreen)