import React, { useState, useEffect } from 'react'
import {
    Card, Table, Button, Icon, message, Divider, Popconfirm, Tag, Modal, Checkbox, Row, Col
} from "antd";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import http from '@/axios/axios_utils';
import deviceFirmUtils from '@/utils/deviceFirmUtils';
import Axios from 'axios'
import { devices, device } from '../utils/api';
import { connect } from 'react-redux'
import BaseForm from '../../utils/baseForm';
import moment from 'moment';
import "./index.scss"
import "animate.css"
import Utils from '@/utils/utils';
const CheckboxGroup = Checkbox.Group;
const snmp_devices_url = "http://" + (sys_config.dataScrape || "localhost:38209") + "/dataScrape/v1/";

function DataSource(props) {
    const [visible, setVisible] = useState(false);
    const [total, setTotal] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const [selectItem, setSelectItem] = useState(null);
    const [selectedRowKeys, setRowKeys] = useState([])
    const [tableData, setTabData] = useState([])
    const [modalVis, setModalVis] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
        },
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'ip',
            ellipsis: true,
            width: 50,
            render: (text, item, c) => { return c + 1 + (_current - 1) * _pageSize }
        },
        {
            title: '资源名称',
            width: 150,
            dataIndex: 'device_name',
            ellipsis: true,
        },
        {
            title: '资源IP',
            width: 150,
            dataIndex: 'device_ip',
            ellipsis: true
        },
        {
            title: '设备厂商',
            width: 100,
            dataIndex: 'business_name',
            ellipsis: true,
            render: (text, item, index) => Utils.Translation(text)
        },
        {
            title: '设备类型',
            width: 100,
            dataIndex: 'type',
            ellipsis: true,
            render: (text, item, index) => Utils.Translation(text)
        },
        {
            title: 'SNMP模块',
            dataIndex: 'module_info',
            render: (text, item, index) => {
                if (!text) { return null; }
                return text.map((i, k,) => <Tag color="geekblue" key={i.module_name + index}>{i.module_name}</Tag>)
            }
        },
        {
            title: '操作',
            width: 150,
            render: (text, item, i) => {
                return <div key={i}>
                    <a onClick={() => {
                        sessionStorage.setItem("device_ip", item.device_ip);
                        props.history.push("/monitorAlert/dataCollection/detailSnmpPreview")
                    }}>
                        <Icon type="edit" />&nbsp;详情
                    </a>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData([item.device_ip]) }}
                        okText="是"
                        cancelText="否"
                    >
                        <a><Icon type="delete" />&nbsp;删除</a>
                    </Popconfirm>
                </div>
            }
        },
    ]
    useEffect(() => {//componentDidMount;
        getData();
        http.setDispatch(props.dispatch)
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(page = 1, size = _pageSize, query = {}) {
        http.get(devices(), { page, size, ...query, userId: sessionStorage.getItem("userId") }, (r) => {
            setTabData(r.data.records);
            setTotal(r.data.count);
            set_querys(query);
            set_current(page);
            set_pageSize(size);
        })
    }
    function saveDataSource(val) {
        const { dataSourceId } = selectItem;
        if (dataSourceId) {//修改
            http.put(dataSource(), { dataSourceId, ...val }, () => {
                getData(1, _pageSize, {});
                message.success("修改成功");
            })
        } else {//新增
            http.post(dataSource(), val, () => {
                getData(1, _pageSize, {});
                message.success("新增成功");
            })
        }
        setVisible(false)
    }
    function deleteData(ids) {
        if (ids.length === 0) {
            message.error("请选择设备!");
            return;
        }
        http.delete_body(devices(), { devices: ids }, () => {
            getData(1, _pageSize, {});
            message.success("删除成功");
        })

    }
    function exportDevices() {
        if (checkedList.length === 0) {
            return message.error("请选择导出的数据！")
        }
        let bodyJson = {
            ips: selectedRowKeys,
            data_types: checkedList
        }
        Axios({
            method: "POST",
            url: snmp_devices_url + 'export_monitor_excel',
            data: bodyJson,
            responseType: 'blob'
        }).then((result) => {
            const link = document.createElement('a');
            let blob = new Blob([result.data], { type: "application/vnd.ms-excel" }); // 转化为 blob 对象 导出.xls文件
            // let blob = new Blob([result.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }); // 转化为 blob 对象  导出.xlsx文件
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = "SNMP监控设备-" + new Date().toLocaleDateString().replace(/\//g, "-"); // 文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            message.success("导出成功")
            setModalVis(false);
            setCheckedList([]);
            setRowKeys([]);
        })
    }
    return <div className="monitorDataSource">
        <CustomBreadcrumb arr={["监控告警", "数据采集", "SNMP概览管理"]} />
        <Card title="SNMP概览" extra={<div>
            <Button icon="export" type="primary" onClick={() => {
                if (selectedRowKeys.length === 0) {
                    return message.error("请勾选需要导出的设备！")
                }
                setModalVis(true)
            }} >导出设备</Button>&nbsp;
            <Button type="primary" icon="file-add" onClick={() => {
                sessionStorage.removeItem("device_ip");
                props.history.push("/monitorAlert/dataCollection/addSnmpPreview")
            }}>添加</Button>&nbsp;
            <Button icon="close" onClick={() => { props.history.go(-1) }} >返回</Button>&nbsp;
            <Popconfirm
                title="你确定删除所选记录吗?"
                onConfirm={() => { deleteData(selectedRowKeys) }}
                okText="是"
                cancelText="否"
            ><Button type="danger" icon="delete">删除</Button>
            </Popconfirm>
        </div>}>
            <BaseForm
                onSubmit={(val) => {
                    getData(1, undefined, val)
                }}
                onCancel={() => { getData(1, undefined, {}) }}
                initialValues={{}}
                formList={[
                    { type: "input", name: "device_name", label: "资源名称", placeholder: "资源名称" },
                    { type: "input", name: "device_ip", label: "设备IP", placeholder: "设备IP" },
                    {
                        type: "select", name: "business_name", label: "设备厂商", placeholder: "设备厂商",
                        selectOptions: deviceFirmUtils.deviceFirmsOptions()
                    },
                    {
                        type: "select", name: "type", label: "设备类型", placeholder: "设备类型",
                        selectOptions: [{ value: 'switch', name: '交换机' }, { value: 'firewall', name: '防火墙' }, { value: 'router', name: '路由器' }]
                    },
                ]}
            /><p />
            <Table
                dataSource={tableData}
                rowSelection={rowSelection}
                columns={columns}
                size="small"
                rowKey={r => r.device_ip}
                pagination={
                    {
                        current: _current, pageSize: _pageSize, total,
                        showTotal: (total) => { return <span>共 {total} 项</span> },
                        pageSizeOptions: ["20", "50", "100", "500", "1000"],
                        showSizeChanger: true,
                        onShowSizeChange: (current, size) => {
                            getData(1, size, _querys)
                        },
                        onChange: (page, pageSize) => { getData(page, pageSize, _querys); }
                    }
                }
            />
        </Card>
        <Modal
            title="选择导出的数据"
            visible={modalVis}
            onOk={() => { exportDevices() }}
            onCancel={() => setModalVis(false)}
            destroyOnClose
        >
            <CheckboxGroup style={{ width: '100%' }} onChange={(key) => setCheckedList(key)} >
                <Row>
                    <Col span={24} style={{ margin: '8px 10px' }}>
                        <Checkbox value="cpu_usage_rate">cpu使用率</Checkbox>
                    </Col>
                    <Col span={24} style={{ margin: '8px 10px' }}>
                        <Checkbox value="mem_usage_rate">内存使用率</Checkbox>
                    </Col>
                    <Col span={24} style={{ margin: '8px 10px' }}>
                        <Checkbox value="temperature">温度</Checkbox>
                    </Col>
                    <Col span={24} style={{ margin: '8px 10px' }}>
                        <Checkbox value="power_state">电源状态</Checkbox>
                    </Col>
                    <Col span={24} style={{ margin: '8px 10px' }}>
                        <Checkbox value="fan_state">风扇状态</Checkbox>
                    </Col>
                </Row>
            </CheckboxGroup>
        </Modal>
    </div>
}

export default connect()(DataSource)