import React from 'react'
import { Prompt } from 'react-router'
import { Card, Table, message, Modal, Collapse, Tabs, Typography, Divider, Button, List, Alert, Tree, Checkbox, DatePicker, Progress, } from "antd";
import Socket from '@/socket/index'
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import BaseForm from './search';
import MonacoEditor from 'react-monaco-editor';
import Utils from '@/utils/utils';
import axios from 'axios';
import axios_req from 'axios';
import api from '@/config/api/logMange';
import moment from 'moment';
const { TreeNode } = Tree;
const CheckboxGroup = Checkbox.Group;
class DeviceLog extends React.Component {
    time_flag = null
    state = {
        modalVis: false,
        download_flag: false,
        download_progress: 0,
        textVis: false,
        downloadVis: false,
        _current: 1,       //当前页码数
        _pageSize: 50,    //当前每页显示条数
        querys: {
            "query": {
                "match_all": {}
            },
            "sort": [
                // { "_score": { "order": "desc" } },
                { "@timestamp": { "order": "desc" } }
            ]
        },    //查询条件
        loading: false,
        selectedRowKeys: [],
        tableData: [],
        selectIndex: "",//当前索引
        ftp_url: "http://" + (sys_config.log_ftp_url).replace("8899", "8898"),
        indexsList: [],//索引列表
        checkedKeys: [],
        ipTreeData: [],
        checkedList: [],
        downloadTime: [],
        agent_address: "http://" + Socket.getAddress50034() + "/router",// 后台转发url代理
    };
    componentWillMount() {
        this.getESIndexs();
        const { _pageSize, _current, querys } = this.state;
        this.updata(_pageSize, _current, querys);
    }
    getESIndexs() {
        axios.get(api.getIndexs, {}).then((r) => {
            this.createIndexsData(r.data.split("\n"));
        }).catch(function (error) {
            message.error(JSON.stringify(error));
        })
    }
    indexSortFun(temp_arr) {
        let arr2 = [];
        let index_list = [];
        let json = {};
        for (let index = 0, len = temp_arr.length; index < len; index++) {
            let timeStr = temp_arr[index].split("-")[1]
            json[new Date(timeStr).getTime()] = timeStr;
            arr2.push(new Date(timeStr).getTime());
        }
        arr2.sort();
        arr2.reverse();
        for (let j = 0; j < arr2.length; j++) {
            // temp_arr[j] = json[arr2[j]];
            index_list.push("devicelogs-" + json[arr2[j]])
        }
        // return temp_arr;
        return index_list;
    }
    createIndexsData(lines) {
        let indexs = [];
        lines.map((line) => {
            const items = line.split(" ").filter((r) => r.trim() != "");
            const temp = items.length > 3 ? items[2] : "";
            if (temp.trim()) {
                indexs.push(temp);
            }
        });
        indexs = indexs.filter((r) => r != ".kibana_1" && r.trim() != ".kibana_task_manager"); //过滤
        indexs = this.indexSortFun(indexs)  //排序
        this.setState({ indexsList: indexs, selectIndex: indexs[0] || "" });
        // const { _pageSize, _current, querys } = this.state;
        // this.updata(_pageSize, _current, querys, indexs[0] || "");
    }
    updata = (pageSize = 50, pageNum = 1, querys) => {
        // if (!index) { message.error("请重新选择索引!"); return; }
        const _this = this;
        this.setState({ loading: true, });
        querys.from = (pageNum - 1) * pageSize;
        querys.size = pageSize;
        // querys.sort = [{ "_score": { "order": "desc" } }, { "@timestamp": { "order": "desc" } }];
        querys.sort = [{ "@timestamp": { "order": "desc" } }];
        // let deviceIndex = index
        // console.log(api.BASEURL + deviceIndex + "/_search", querys);
        console.log(api.BASEURL + "/_search", querys);
        axios.post(api.BASEURL + "_search", querys).then((res) => {
            const data = res.data.hits;
            let _groupData = data.hits;
            let _dataSize = data.total.value;
            let tabDatatemp = _groupData.map((item, index) => {
                let temp = item["_source"];
                // temp["_score"] = item["_score"];
                return temp
            });
            this.setState({
                // selectIndex: deviceIndex,
                tableData: tabDatatemp,
                _current: pageNum || 1,
                _pageSize: pageSize || _pageSize,
                total: _dataSize,
                loading: false
            })
        }).catch(function (error) {
            message.error(error.toString());
            _this.setState({ loading: false })
        })
    };
    searchSubmit(query) {
        // console.log(query)
        let { _pageSize, selectIndex } = this.state;
        let temp = {
            "from": 0,
            "query": {
                "match_all": {}
            },
            "size": _pageSize,
            "track_total_hits": true
        }
        if (query.file_path) {
            let tempMust = [];
            if (temp.query.bool) {
                tempMust = temp.query.bool.must;
            }
            // tempMust.push({ "match": { "file_path": query.file_path } });
            tempMust.push({
                "regexp": {
                    "file_path": {
                        "value": ".*" + query.file_path + ".*"
                    }
                }
            });
            temp = {
                "from": 0,
                "query": {
                    "bool": {
                        "must": tempMust
                    }
                },
                "size": _pageSize,
                "track_total_hits": true
            }
        }
        if (query.message) {
            let tempMust = [];
            if (temp.query.bool) {
                tempMust = temp.query.bool.must;
            }
            tempMust.push({ "match": { "message": query.message } });
            // tempMust.push({
            //     "regexp": {
            //         "message": {
            //             "value": ".*" + query.message + ".*"
            //         }
            //     }
            // });
            temp = {
                "from": 0,
                "query": {
                    "bool": {
                        "must": tempMust
                    }
                },
                "size": _pageSize,
                "track_total_hits": true
            }
        }
        if (query.rangeTime && query.rangeTime.length != 0) {
            // const begin_time = moment(query.rangeTime[0]).utc().format();
            // const end_time = moment(query.rangeTime[1]).utc().format();
            const begin_time = moment.utc(query.rangeTime[0]).format("YYYY-MM-DDTHH:mm:ss");
            const end_time = moment.utc(query.rangeTime[1]).format("YYYY-MM-DDTHH:mm:ss");
            if (temp.query.bool) {
                temp.query.bool.filter = {
                    "range": {
                        "utc_time": {
                            "lte": end_time,
                            "gte": begin_time
                        }
                    }
                }
            } else {
                temp.query = {
                    "bool": {
                        "filter": {
                            "range": {
                                "utc_time": {
                                    "lte": end_time,
                                    "gte": begin_time
                                }
                            }
                        }
                    }
                }
            }
        }
        console.log(temp)
        this.setState({ querys: temp })
        this.updata(_pageSize, 1, temp,)
    }
    getTable(params, index) {
        return <table className="logParamDiv" index={index + "o"}>
            <tbody>
                {
                    Object.keys(params).map((jsonKey) => {
                        let value = params[jsonKey]
                        if (typeof value === "object") {
                            value = JSON.stringify(value);
                        }
                        return <tr key={jsonKey}><td className="key">{jsonKey}</td><td>{value}</td></tr>
                    })
                }
            </tbody>
        </table>
    }
    delIndex(index) {
        Modal.confirm({
            title: "提示",
            content: <Typography.Text>删除索引将会清除当前索引下的所有数据,确定删除吗？</Typography.Text>,
            onOk: () => {
                axios_req.delete(api.BASEURL + index, {}).then(r => {
                    if (r.status === 200 && r.data.acknowledged) {
                        message.success("删除成功")
                        this.getESIndexs();
                    } else if (r.status === 404) {
                        message.error("不存在该索引！")
                    } else {
                        console.log(r)
                    }
                })
            }
        })
    }
    async getHttpDir() {
        this.setState({ loading: true, })
        let getDirUrl = `http://${sys_config.log_ftp_url}/ZJNMS/log/getLogDir`
        const { data: res, status } = await axios.get(getDirUrl);
        if (status === 200) {
            let ipTreeData = res.data
            this.setState({
                downloadVis: true,
                loading: false,
                ipTreeData
            })
        } else {
            message.error("请求出错")
        }
    }
    renderTreeNodes = (data, p_key) => {
        return data.map(item => {
            let node_key = item.key
            if (p_key) {
                node_key = p_key + "/" + item.key
            }
            console.log(node_key)
            if (item.children) {
                return (
                    <TreeNode title={item.title} dataRef={item} key={node_key} >
                        {this.renderTreeNodes(item.children, item.key)}
                    </TreeNode>
                );
            }
            return <TreeNode  {...item} dataRef={item} key={node_key} />;
        });
    }
    async downloadLogTime() {
        let { downloadTime, checkedList } = this.state
        if (checkedList.length == 0) {
            message.error("请选择要下载的设备")
            return
        }
        if (downloadTime.length < 2) {
            message.error("请选择要下载的时间段")
            return
        }

        let load_data_time = {
            ips: checkedList,
            time: downloadTime
        }
        window.open(`http://${sys_config.log_ftp_url}/ZJNMS/log/downloadLogByTime?data=${JSON.stringify(load_data_time)}`);
        console.log(load_data_time)

    }
    async downloadLog() {
        let { checkedKeys, } = this.state
        if (checkedKeys.length == 0) {
            message.error("请选择需要下载的日志文件！")
            return
        }
        let down_load_data = {}
        for (let index in checkedKeys) {
            let url = checkedKeys[index]
            if (!url.includes("/")) {  //  10.32.79.19/202_06_20.log
                continue;
            } else {
                let [ip, logName] = url.split("/");
                let name_list = down_load_data[ip] || []
                name_list.push(logName)
                down_load_data[ip] = name_list
            }
        }
        window.open(`http://${sys_config.log_ftp_url}/ZJNMS/log/downloadLogByFile?data=${JSON.stringify(down_load_data)}`);
        console.log(down_load_data)
    }
    updataProgress() {
        axios_req.get(`http://${sys_config.log_ftp_url}/ZJNMS/log/get_progress`, {
            params: { username: sessionStorage.getItem("userName") },
        }).then((res) => {
            if (res.status == 200) {
                console.log(res.data.data)
                this.setState({ download_progress: res.data.data })
                if (res.data.data == 100.0) {
                    this.clearIntervalFun()
                }
            }
        }).catch((err) => { this.clearIntervalFun() })
    }
    stopDownload() {
        axios_req.get(`http://${sys_config.log_ftp_url}/ZJNMS/log/stopDownload`, {
            params: { username: sessionStorage.getItem("userName") },
        }).then((res) => {
            if (res.status == 200) {
                console.log(res.data)
                this.clearIntervalFun()
            }
        }).catch((err) => { this.clearIntervalFun() })
    }
    clearIntervalFun() {
        if (this.time_flag) {
            clearInterval(this.time_flag)
        }
    }
    async downloadresult() {
        let { querys, } = this.state
        if (!querys.query || (querys.query && querys.query.match_all)) {
            message.info("请选择查询条件，缩短日志范围！")
            return
        }
        querys["track_total_hits"] = true
        let str_q = JSON.stringify(querys)
        let username = sessionStorage.getItem("userName")
        this.setState({ download_flag: true, download_progress: 0 })
        this.time_flag = setInterval(() => {
            this.updataProgress()
        }, 3000)
        // window.open(`http://${sys_config.log_ftp_url}/ZJNMS/log/downloadQueryResult?url=http://${es_address}/_search&query=${str_q}`);
        axios_req.get(`http://${sys_config.log_ftp_url}/ZJNMS/log/downloadQueryResult?url=http://${es_address}/_search&query=${str_q}&username=${username}`, {
            params: {},
            responseType: "arraybuffer"
        })
            .then((res) => {
                if (res["headers"]["content-type"] == "application/json") {
                    let blob = new Blob([res.data])
                    let reader = new FileReader()
                    reader.readAsText(blob, 'utf-8')
                    reader.onload = function () {
                        let b_data = JSON.parse(reader.result)
                        if (b_data["code"] == "00") {
                            message.success(b_data["msg"])
                        } else {
                            message.error(b_data["msg"])
                        }
                    }
                } else {
                    let blob = new Blob([res.data], {
                        type: res.headers['content-type']
                    });
                    const fileName = `device_log_${new Date().getTime()}.zip`
                    // const fileName = res.headers['content-disposition'];
                    // const title = fileName && (fileName.indexOf('filename=') !== -1) ? fileName.split('=')[1] : 'download';
                    // require('script-loader!file-saver');
                    Utils.saveAs(blob, fileName);
                }
                this.setState({ download_flag: false })
                this.clearIntervalFun()
            }).catch((err) => { this.clearIntervalFun() })
    }
    render() {
        let { _current, _pageSize, querys, selectIndex, indexsList, modalVis, textVis, ftp_url, downloadVis, checkedKeys, loading, tableData, download_flag, download_progress } = this.state;
        const columns = [
            {
                title: '时间',
                align: 'left',
                width: 160,
                render: (text, item) => {
                    if (item["createTime"]) {
                        return item["createTime"];
                    } else if (item["@timestamp"]) {
                        return Utils.logtimestr2beijing(item["@timestamp"]);
                    } else if (item["timestamp"]) {
                        return Utils.utc2beijing(item["timestamp"]);
                    } else {
                        return "";
                    }
                }
            }, {
                title: 'IP 地址',
                align: 'left',
                width: 160,
                render: (text, item) => {
                    if (item["host"] && (typeof item["host"] === "string")) {
                        return item["host"];
                    } else if (item["file_path"]) {
                        //" /mnt/10.32.79.155/2022_05_12.log".split("/")
                        // console.log(item["file_path"])
                        return item["file_path"].split("/")[2];
                    } else if (item["log"]) {
                        //{"offset":110,"file":{"path":"/mnt/192.22.1.250/2022_05_12.log"}}
                        return item["log"]["file"]["path"].split("/")[2];
                    }
                }
            }, {
                title: '日志信息',
                align: 'left',
                ellipsis: true,
                dataIndex: 'message',
                render: (text, item, index) => {
                    return <a key={index + "a"} onClick={() => {
                        Modal.info({
                            title: "详情",
                            width: "60%",
                            content: <Typography.Paragraph copyable editable>{text}</Typography.Paragraph>
                            // content: <MonacoEditor
                            //     language="java"
                            //     theme="vs"
                            //     height="500"
                            //     value={text}
                            //     options={{ selectOnLineNumbers: false }}
                            // />
                        })
                    }}>{text}</a>
                }
            }
        ];


        const formList = [
            // {
            //     label: "索引",
            //     type: 'autoComplete',
            //     name: "index",
            //     width: 300,
            //     selectOptions: indexsList
            // },
            {
                label: "设备IP地址",
                type: 'input',
                name: "file_path",
            },
            {
                label: "日志关键字",
                type: 'input',
                name: "message",
            },
            {
                label: "时间",
                type: 'rangePicker',
                name: "rangeTime",
            }
        ]
        return (
            <div className="LogMangedeviceLog">
                <CustomBreadcrumb arr={["日志管理", "设备日志"]} />
                <Collapse defaultActiveKey={"aa"}>
                    <Collapse.Panel header="查询条件" key="aa" >
                        <BaseForm
                            formList={formList}
                            total={this.state.total}
                            initialValues={{ index: selectIndex }}
                            onSubmit={(query) => { this.searchSubmit(query) }}
                            onCancel={() => {
                                const newquerys = {
                                    "query": {
                                        "match_all": {},
                                    },
                                    "sort": [
                                        // { "_score": { "order": "desc" } },
                                        { "@timestamp": { "order": "desc" } }
                                    ]
                                }
                                this.setState({ querys: newquerys, })
                                this.updata(this.state._pageSize, 1, newquerys,)
                                this.getESIndexs()
                            }}
                        />
                    </Collapse.Panel>
                </Collapse><p />
                <Tabs tabBarExtraContent={
                    <div>

                        {download_flag ?
                            <div style={{ width: 170, float: "left", marginRight: 20 }}><Progress percent={download_progress} size="small" /></div>
                            : null}
                        {download_flag ?
                            (download_progress == 100 ? "文件合并中"
                                : <Button type="danger" size="small" icon="close-circle" onClick={() => this.stopDownload()}>终止下载</Button>)
                            : <Button type="primary" size="small" loading={download_flag} icon="download" onClick={() => this.downloadresult()}>下载查询结果</Button>}
                        <Divider type="vertical" />
                        <Button type="primary" size="small" icon="file-text" onClick={() => this.setState({ textVis: true })}>文本模式</Button>
                        <Divider type="vertical" />
                        <Button type="primary" size="small" icon="unordered-list" onClick={() => this.setState({ modalVis: true })}>索引管理</Button>
                        &nbsp;&nbsp;&nbsp;
                    </div>

                }>
                    <Tabs.TabPane key={"logtable"} tab="设备日志列表">
                        <div className="logtabdiv">
                            {/* <Alert message={<strong>当前索引:{selectIndex}</strong>} type="info" /> */}
                            <Table
                                dataSource={this.state.tableData}
                                columns={columns}
                                size="small"
                                loading={this.state.loading}
                                bordered={false}
                                rowKey={(r, x) => r["@timestamp"] + x}
                                expandedRowRender={(record, index, indent, expanded) => {
                                    return <div style={{ textAlign: "left" }} key={index + "w"}>
                                        <Tabs>
                                            <Tabs.TabPane key={"table" + index} tab="表格">
                                                {this.getTable(record, index)}
                                            </Tabs.TabPane>
                                            <Tabs.TabPane key={"json" + index} tab="JSON">
                                                <MonacoEditor
                                                    key={index + "m"}
                                                    language="java"
                                                    theme="vs"
                                                    width="99%"
                                                    height="500"
                                                    value={JSON.stringify(record, null, 5)}
                                                    options={{ selectOnLineNumbers: false }}
                                                />
                                            </Tabs.TabPane>
                                        </Tabs>
                                    </div>
                                }}
                                pagination={{
                                    current: _current, pageSize: _pageSize, total: this.state.total,
                                    showTotal: (total) => { return <span>共 {total} 项</span> },
                                    pageSizeOptions: ["20", "50", "100", "500", "1000"],
                                    showSizeChanger: true,
                                    onShowSizeChange: (current, size) => {
                                        this.updata(size, 1, querys)
                                    },
                                    onChange: (page, pageSize) => {
                                        this.updata(pageSize, page, querys)
                                    }
                                }}
                            />

                        </div>

                    </Tabs.TabPane>
                    <Tabs.TabPane key={"logdownload"} tab="设备日志下载">
                        <Card>
                            <Button icon="rollback" onClick={() => document.getElementById("logIframe").src = ftp_url}>返回上级</Button>
                            <Divider type="vertical" />
                            <Button type="primary" loading={loading} icon="download" onClick={() => this.getHttpDir()}>批量下载</Button><br />
                            <iframe
                                id="logIframe"
                                src={ftp_url}
                            />
                        </Card>
                    </Tabs.TabPane>
                </Tabs>
                <Modal
                    title="索引管理"
                    visible={modalVis}
                    onOk={() => { this.setState({ modalVis: false }) }}
                    onCancel={() => { this.setState({ modalVis: false }) }}
                    destroyOnClose
                >
                    <div style={{ overflowY: "auto", maxHeight: 600 }}>
                        <List
                            size="small"
                            header={<div>删除索引将会清除当前索引下的所有数据  【索引总数：{indexsList.length}条】</div>}
                            footer={<div></div>}
                            bordered
                            dataSource={indexsList}
                            renderItem={(item, key) => <List.Item key={key + "l"}
                                actions={[<Button key={key + "b"} type="danger" size="small" icon="delete" onClick={() => this.delIndex(item)}></Button>]}>{item}</List.Item>}
                        />
                    </div>
                </Modal>
                <Modal
                    title="日志文本"
                    visible={textVis}
                    onOk={() => { this.setState({ textVis: false }) }}
                    onCancel={() => { this.setState({ textVis: false }) }}
                    width="99%"
                    style={{ top: 10 }}
                    destroyOnClose
                >
                    {/* <div style={{ overflowY: "auto", maxHeight: 600 }}> */}
                    <MonacoEditor
                        key={"logtext"}
                        language="powershell"
                        theme="vs"
                        width="99%"
                        height="800"
                        value={(tableData.map((item) => {
                            let lineStr = `${Utils.logtimestr2beijing(item["@timestamp"])}-【${item["file_path"].split("/")[2]}】-${item["message"]}`
                            return lineStr
                        })).join("\n")}
                        options={{ selectOnLineNumbers: false }}
                    />
                    {/* </div> */}
                </Modal>
                <Modal
                    title="日志下载"
                    visible={downloadVis}
                    onCancel={() => { this.setState({ downloadVis: false }) }}
                    footer={null}
                    width="40%"
                    destroyOnClose
                >
                    <Tabs>
                        <Tabs.TabPane key={"file"} tab="按文件下载">
                            <div className="downlogmodal">
                                <Alert type="info" message="请勾选需要下载的日志文件" />
                                <Tree
                                    checkable
                                    checkedKeys={checkedKeys}
                                    onCheck={(checkedKeys) => this.setState({ checkedKeys })}
                                >
                                    {this.renderTreeNodes(this.state.ipTreeData, "")}
                                </Tree>
                            </div>
                            <Divider />
                            <Button type="primary" icon="download" onClick={() => this.downloadLog()}>下载日志</Button>
                        </Tabs.TabPane>
                        <Tabs.TabPane key={"date"} tab="按日期下载">
                            <div className="downlogmodal">
                                <p>1.选择下载的设备IP</p>
                                <CheckboxGroup
                                    options={this.state.ipTreeData.map((i) => i.key)}
                                    value={this.state.checkedList}
                                    onChange={(checkedList) => this.setState({ checkedList })}
                                /><p />
                                <p>2.选择下载时间段</p>
                                <DatePicker.RangePicker onChange={(moment, dateStr) => this.setState({ downloadTime: dateStr })} />
                            </div>
                            <Divider />
                            <Button type="primary" icon="download" onClick={() => this.downloadLogTime()}>下载日志</Button><p />
                        </Tabs.TabPane>
                    </Tabs>

                </Modal>
                <Prompt
                    message={(location) => {
                        let _this = this
                        if (download_flag) {
                            Modal.confirm({
                                title: '确认离开吗?',
                                content: '日志正在下载，离开将会中断，确认离开吗？',
                                okText: '确定',
                                cancelText: '取消',
                                onOk() {
                                    //操作等等
                                    _this.setState({ download_flag: false })
                                    _this.stopDownload()
                                    _this.props.history.push(location.pathname)
                                },
                                onCancel() {
                                    console.log('Cancel');
                                },
                            });
                            return false
                        } else {
                            return true
                        }
                    }}
                />
            </div>
        )
    }
}


export default DeviceLog