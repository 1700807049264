import React from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import Utils from '@/utils/utils'
import App from './App'
import BaseLogin from './pages/login'
import Admin from './admin'
import Home from './pages/home/index'

/*任务*/
import newTask from './pages/config/task/newTask/index'
import Task from './pages/config/task/index'

import AppStore from './pages/config/task/appStore/index'
import newAppStore from './pages/config/task/appStore/newAppStore/index'
import appDetail from './pages/config/task/appStore/appDetail'
import OverView from './pages/task/OverView/index'
import History from './pages/task/history/index'
import TaskLog from './pages/config/task/log/index'
// 报告模板
import ReportTemplate from './pages/config/task/reportTemp'
/*任务结果*/
import FireWall from './pages/fireWallMan/fireWallList/index'
import FireWallDetail from './pages/fireWallMan/fireWallList/poliDetail'
// import FireWall from './components/taskResult/fireWall'
import Strategy from './pages/task/OverView/strategy/strategy'
import HistoryRecord from './pages/task/OverView/historyRecord/index'
import HistoryList from './pages/task/OverView/historyRecord/list'
/*资源*/
import Resource from './pages/config/resource/index'
import ResourceAutoFind from './pages/config/resource/resourceAutoFind'
import ResourceMonitor from './pages/config/resource/monitor/index'
import newResource from './pages/config/resource/newResource'
import resourceDetail from './pages/config/resource/resourceDetail'
import ResMonitor from './pages/config/resource/monitorPage'
import MonitorList from './pages/config/resource/monitor/index'
/*操作*/
import Operating from './pages/config/operating/index'
import newOperating from './pages/config/operating/newOperating'
/*用户*/
import User from './pages/permission/user/index'
import userInfo from './pages/permission/user/userInfo'
import newUser from './pages/permission/user/newUser'
/*系统*/
import System from './pages/config/system/index'
/*角色*/
import Role from './pages/permission/role/index'
import newRole from './pages/permission/role/newRole'
/**菜单 配置管理*/
import menuMange from './pages/permission/menu/index'
import ConfigMange from './pages/permission/config/index'
// 
import Organi from './pages/permission/organi/index'

/*接口*/
import APIMange from './pages/permission/api/index'

/*error*/
import ShowMyError from './pages/config/system/index-err'
import ErrorPage from './components/ErrorCatch/errorPage'
/*Port*/
import Port from './pages/port/index'
import Test from './components/TestPage/index'
import ConfigTest from './components/TestPage/config'
import PostmanPage from './components/postman/index'
///
import PortManageSwitch from './pages/portManagement/switch/index'
import PortManageTask from './pages/portManagement/task/index'
import PlayRecord from './pages/portManagement/PlayRecord/index'
import PortRecord from './pages/portManagement/PortRecord/index'
import ClosureRecord from './pages/portManagement/ClosureRecord/index'
//防火墙
import FireWallMan from './pages/fireWallMan/index'
import FireWallCfg from './pages/fireWallMan/netConfig'
import FireWallPlayRecord from './pages/fireWallMan/PlayRecord/index'
import IPPlayRecord from './pages/fireWallMan/IPconfig/IPPlayRecord'
import FireWallPolicy from './pages/fireWallMan/firewallPolicy/index'
import WhiteList from './pages/fireWallMan/WhiteList/index'
import FireWallIPconfig from './pages/fireWallMan/IPconfig/index'
import FirewallConfig from './pages/fireWallMan/firewallConfig/index'
import ELineInformation from './pages/fireWallMan/eLineInformation/index'
import ExternalConnection from './pages/fireWallMan/externalConnection/index'
// import RedundancyPolicy from './pages/fireWallMan/fireWallList/reduPolicy'
import Policy from './pages/fireWallMan/fireWallList/Policy'
import ConflictPolicy from './pages/fireWallMan/fireWallList/conflictPolicy'
import NoEffectPolicy from './pages/fireWallMan/fireWallList/noEffectPolicy'
import NoMatchedPolicy from './pages/fireWallMan/fireWallList/noMatchedPolicy'

//密码变更
import PwdHistory from './pages/passwordMan/PwdHistory/index'
import PwdAudits from './pages/passwordMan/PwdAudits/index'
//标签分组管理
import TagList from './pages/tagManagement/tagList/index'
import GroupList from './pages/tagManagement/groupList/index'
//巡检列表
import InspectionList from './pages/inspectionMan/index'
import NewInspection from './pages/inspectionMan/newInspect'
//IP 管理
import MacMonitor from './pages/IpAdmin/macMonitor'
import IpFunction from './pages/IpAdmin/ipfunction'
import Segment from './pages/IpAdmin/segment'
import IpRecord from './pages/IpAdmin/iprecord'
import IpMonitor from './pages/IpAdmin/ipmonitor'
import IpRecycleRecord from './pages/IpAdmin/IpRecycleRecord'
import newSegment from './pages/IpAdmin/newSegment'
import newIpSearch from './pages/IpAdmin/newIpSearch'
//日志
import SysLog from './pages/logMange/sysLog/index'
import DeviceLog from './pages/logMange/deviceLog/index'
import LogRule from './pages/logMange/logRule/index'
import ADLog from './pages/logMange/adLog/index'
import ADhistory from './pages/logMange/adLog/adhistory'
//ftp文件功能
import FtpFile from './pages/ftpFile/index'
{/* DNS */ }
import DNSserver from './pages/dnsServer/index'
{/* 配置备份 */ }
import ConfigBackup from './pages/maintain/configBack/index'
import ConfigBackupCmd from './pages/maintain/configBack/backUpCmd'
import BackData from './pages/maintain/configBack/backData'
import BaseLineData from './pages/maintain/configBack/baseLineData'
import BackupRecords from './pages/maintain/configBack/backupRecords'
// 哑终端管理
import DumbTerminal from './pages/dumbTerminal/index'
import newTerminal from './pages/dumbTerminal/newTerminal'
import DumbAutoFind from "./pages/dumbTerminal/dumbAutoFind"
import UnKnownDumbTerminal from "./pages/dumbTerminal/unKnownDumbTerminal"
import bindDumbRecord from "./pages/dumbTerminal/bindDumbRecord"
import allBindDumbRecord from "./pages/dumbTerminal/allBindDumbRecord"
// /IP management
// 异常页
import Exception404 from './pages/Exception/404'
import Exception401 from './pages/Exception/401'
import Exception403 from './pages/Exception/403'
import Exception500 from './pages/Exception/500'
// 专线管理组件
import Device from "./pages/NetLine/zx-pages/device"
import Operator from "./pages/NetLine/zx-pages/operator"
import LineIn from "./pages/NetLine/zx-pages/line-in"
import LineOut from "./pages/NetLine/zx-pages/line-out"
import Report from "./pages/NetLine/zx-pages/report"
import Settings from "./pages/NetLine/zx-pages/settings"

//监控告警
import MonitoringAlarmOverview from './pages/monitoringAlarm/overview/index'
import SNMPMonitor from './pages/monitoringAlarm/snmpMonitor/index'
import EditSnmpConfig from './pages/monitoringAlarm/snmpMonitor/editSnmpConfig'
import AddSnmpModule from './pages/monitoringAlarm/snmpMonitor/addSnmpModule'
import AddIndex from './pages/monitoringAlarm/snmpMonitor/addIndex'
import AddDevice from './pages/monitoringAlarm/snmpMonitor/addDevice'
import Detail from './pages/monitoringAlarm/snmpMonitor/detail'
import Alarm from './pages/monitoringAlarm/alarm/index'
import AlarmList from './pages/monitoringAlarm/alarm/alertList'
import AddReceiver from './pages/monitoringAlarm/alarm/addReceiver'
import AddRule from './pages/monitoringAlarm/alarm/addRule'
import Storage from './pages/monitoringAlarm/storage/index'
import AddPolicy from './pages/monitoringAlarm/storage/addPolicy'
import HostPing from './pages/monitoringAlarm/hostPing/index'
import deviceCheckDevice from './pages/monitoringAlarm/deviceCheck/decice/index'
import deviceCheckRecord from './pages/monitoringAlarm/deviceCheck/record/index'
//监控告警new
import MonitorOverview from './pages/monitorAlert/overview/index'
import MonitorDataSource from './pages/monitorAlert/dataSource/index'
import MonitorStrategy from './pages/monitorAlert/strategy/index'
import EditMonitorStrategy from './pages/monitorAlert/strategy/newform'
import AlertRule from './pages/monitorAlert/alertRules/index'
import AlertRecord from './pages/monitorAlert/alertRecord/index'
import SelfHealing from './pages/monitorAlert/selfHealing/index'
import AlertGroup from './pages/monitorAlert/alertGroup/index'
import AlertBigScreen from './pages/monitorAlert/alertBigScreen/index'
import DashBoard from './pages/monitorAlert/dashBoard/index'
import MsgTemplate from './pages/monitorAlert/template/index'
//数据采集
import CustomIndicator from './pages/monitorAlert/dataCollection/customIndicator/index.js'
import OidList from './pages/monitorAlert/dataCollection/customIndicator/oidList'
import SnmpModule from './pages/monitorAlert/dataCollection/snmpModule/index.js'
import DetailSnmpModule from './pages/monitorAlert/dataCollection/snmpModule/detailModule'
import SnmpPreview from './pages/monitorAlert/dataCollection/snmpPreview/index.js'
import AddSnmpPreview from './pages/monitorAlert/dataCollection/snmpPreview/addDevice'
import DetailSnmpPreview from './pages/monitorAlert/dataCollection/snmpPreview/detailDevice'
import SnmpJob from './pages/monitorAlert/dataCollection/snmpJob/index.js'
import DetaiJob from './pages/monitorAlert/dataCollection/snmpJob/detaiJob'
import PrometheusCfg from './pages/monitorAlert/dataCollection/config/index'
//大屏展示
import BigScreen from "./pages/BigScreen/main";
import BigScreenDisplay from "./pages/BigScreen/BigScreenDisplay";
import BigScreenNetwork from "./pages/BigScreen/BigScreenNetwork";
import MonitorScreen from "./pages/BigScreen/main/monitorScreen";
import StatisticsScreen from "./pages/BigScreen/main/statisticsScreen";
import bigScreenCfg from "./pages/BigScreen/config";
import DeviceScreen from "./pages/BigScreen/main/deviceScreen";
//设备电子台账Ledger
import LedgerList from "./pages/maintain/ledger";
import HisLedger from "./pages/maintain/ledger/hisLedger";
import MacAddressList from "./pages/maintain/ledger/macAddressList";
import FixedResources from "./pages/maintain/ledger/fixedResources";
// 查看可疑接入
import SuspiciousAccess from "./pages/maintain/suspiciousAccess/index"
import SuspiciousDetails from "./pages/maintain/suspiciousAccess/suspiciousDetails"

//acl指令下发
import AclCommand from "./pages/maintain/aclCommand/task/index";
import CommandItem from "./pages/maintain/aclCommand/commandItem/index";
import AclTempMange from "./pages/maintain/aclCommand/tempMange";
import SimpleSend from "./pages/maintain/aclCommand/index";
import AclRecord from "./pages/maintain/aclCommand/aclRecord";
import AclApprove from "./pages/maintain/aclCommand/task/approve";
import NewAclTask from "./pages/maintain/aclCommand/task/newTask";
import AclExeRecord from "./pages/maintain/aclCommand/task/exeRecord";
import OperationAudit from "./pages/maintain/aclCommand/task/audit";
//自定义运维任务 
import CustomizeTask from "./pages/maintain/customize/list";
import NewCustomizeTask from "./pages/maintain/customize/newList";
import CustomizeTaskResult from "./pages/maintain/customize/result";
//邮件管理
import EmailConfig from './pages/email/emailConfig'
import EmailList from './pages/email/emailList'
import EmailForm from './pages/email/emailForm'
import SmsPage from './pages/sms/index'
//设备自动发现//网络拓扑图
//拓扑图topology
import TopoPage from "./pages/topology/components/index";
import AutoFind from './pages/maintain/autoFind/index'
import FindDev from './pages/maintain/autoFind/findDevice/index'
import NetTolopogy from './pages/maintain/autoFind/netTolopogy/index'
import MapNet from './pages/maintain/autoFind/map/index'
import MapNet2 from './pages/maintain/autoFind/map/index2'
//模拟器
import EmulatorDevice from './pages/deviceEmulator/device/index'
import EmulatorType from './pages/deviceEmulator/type/index'
import CommandRespList from './pages/deviceEmulator/CommandRespInfo/index'
//login
import pinganLogin from './pages/login/pingan/index'
import pinganTerminalLogin from './pages/login/pinganTerminal/index'
import pinganNanJingLogin from './pages/login/pingan2_0/index'
import Login2_0 from './pages/login/new/index'
//安全审计
import securityAudit from './pages/securityAudit/index'
//安全极限
import securityBaseline from './pages/securityBaseline/index'
import baseLineCmd from './pages/securityBaseline/baseLineCmd'
import devicesInfo from './pages/securityBaseline/devicesInfo'
import newCheckDevice from './pages/securityBaseline/newCheckDevice'
import historyDeviceDetail from './pages/securityBaseline/historyDeviceDetail'
//docker容器
import dockerContainers from './pages/docker/containers/index'


export default class ERouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null
        };
    }
    componentWillMount() {
        this.versionName = sys_config.versionName;
        this.login = BaseLogin;
        if (this.versionName === "pingan") {
            this.login = pinganLogin
        } else if (this.versionName == "pinganNanJing") {
            this.login = pinganNanJingLogin
        } else if (this.versionName == "pinganTerminal") {
            this.login = pinganTerminalLogin
        } else if (this.versionName === "login2_0") {
            this.login = Login2_0
        }

    }
    componentDidCatch(error, info) {
        console.log(error, info);
        this.setState({
            error: error,
            errorInfo: info
        });
    }

    render() {
        return (
            <HashRouter>
                <App>
                    <Switch>
                        <Route path="/login" component={this.login} />
                        {/*START大屏展示*/}
                        <Route path="/data_v/main" render={() => {
                            if (sessionStorage.getItem("userName")) {
                                return (<Route path={"/data_v/main"} component={BigScreen} />)
                            } else return <Redirect to={{ pathname: "/login" }} />
                        }} />
                        <Route path="/data_v/bigScreenDisplay" render={() => {
                            if (sessionStorage.getItem("userName")) {
                                return (<Route path={"/data_v/bigScreenDisplay"} component={BigScreenDisplay} />)
                            } else return <Redirect to={{ pathname: "/login" }} />
                        }} />
                        <Route path="/data_v/bigScreenNetwork" render={() => {
                            if (sessionStorage.getItem("userName")) {
                                return (<Route path={"/data_v/bigScreenNetwork"} component={BigScreenNetwork} />)
                            } else return <Redirect to={{ pathname: "/login" }} />
                        }} />
                        <Route path="/data_v/monitorScreen" render={() => {
                            if (sessionStorage.getItem("userName")) {
                                return (<Route path={"/data_v/monitorScreen"} component={MonitorScreen} />)
                            } else return <Redirect to={{ pathname: "/login" }} />
                        }} />
                        <Route path="/data_v/statisticsScreen" render={() => {
                            if (sessionStorage.getItem("userName")) {
                                return (<Route path={"/data_v/statisticsScreen"} component={StatisticsScreen} />)
                            } else return <Redirect to={{ pathname: "/login" }} />
                        }} />
                        <Route path="/data_v/deviceScreen" render={() => {
                            if (sessionStorage.getItem("userName")) {
                                return (<Route path={"/data_v/deviceScreen"} component={DeviceScreen} />)
                            } else return <Redirect to={{ pathname: "/login" }} />
                        }} />
                        {/*END大屏展示*/}

                        <Route path="/" render={() => {
                            if (sessionStorage.getItem("userName")) {
                                Utils.goTOP();
                                return (<Admin>
                                    <Switch>
                                        {/*{if(true){return <Redirect to={Home} />}}*/}
                                        <Route path="/home" component={Home} />
                                        <Route path="/resource" component={Resource} />
                                        <Route path="/resourceAutoFind" component={ResourceAutoFind} />
                                        <Route path="/resourceMonitor" component={ResourceMonitor} />
                                        <Route path="/newResource" component={newResource} />
                                        <Route path="/resourceDetail" component={resourceDetail} />
                                        <Route path="/resMonitor" component={ResMonitor} />
                                        <Route path="/resourceDetail:resId" component={resourceDetail} />
                                        <Route path="/newResource:resId" component={newResource} />
                                        <Route path="/overView" component={OverView} />
                                        <Route path="/history" component={History} />
                                        <Route path="/strategy:taskId" component={Strategy} />
                                        <Route path="/newTask" component={newTask} />
                                        <Route path="/newTask:Id" component={newTask} />
                                        <Route path="/TaskLog:Id" component={TaskLog} />
                                        <Route path="/task" component={Task} />
                                        <Route path="/appStore" component={AppStore} />
                                        <Route path="/newAppStore" component={newAppStore} />
                                        <Route path="/appDetail:Id" component={appDetail} />
                                        <Route path="/operating" component={Operating} />
                                        <Route path="/newOperating" component={newOperating} />
                                        <Route path="/newOperating:Id" component={newOperating} />
                                        <Route path="/user" component={User} />
                                        <Route path="/newUser" component={newUser} />
                                        <Route path="/userInfo" component={userInfo} />
                                        <Route path="/newUser:Id" component={newUser} />
                                        <Route path="/role" component={Role} />
                                        <Route path="/newRole:Id" component={newRole} />
                                        <Route path="/newRole" component={newRole} />
                                        {/*报告模板  */}
                                        <Route path="/reportTemplate" component={ReportTemplate} />
                                        {/* 安全审计 */}
                                        <Route path="/securityAudit" component={securityAudit} exact />
                                        {/* 安全基线 */}
                                        <Route path="/securityBaseline" component={securityBaseline} exact />
                                        <Route path="/baseLineCmd" component={baseLineCmd} exact />
                                        <Route path="/securityBaseline/devicesInfo" component={devicesInfo} exact />
                                        <Route path="/securityBaseline/newCheckDevice" component={newCheckDevice} exact />
                                        <Route path="/securityBaseline/historyDeviceDetail" component={historyDeviceDetail} exact />
                                        {/* 菜单 */}
                                        <Route path="/menuMange" component={menuMange} />
                                        <Route path="/APIMange" component={APIMange} />
                                        <Route path="/config" component={ConfigMange} exact />
                                        {/* 机构 */}

                                        <Route path="/Organi" component={Organi} />
                                        <Route path="/system" component={System} />
                                        <Route path="/taskHistory:taskID" component={HistoryRecord} />
                                        <Route path="/listHistory:taskID" component={HistoryList} />

                                        {/* 端口管理 */}
                                        <Route path="/portManagement/task" component={PortManageTask} />
                                        <Route path="/portManagement/switch" component={PortManageSwitch} />
                                        <Route path="/portManagement/PlayRecord" component={PlayRecord} />
                                        <Route path="/portManagement/PortRecord" component={PortRecord} />
                                        <Route path="/portManagement/ClosureRecord" component={ClosureRecord} />
                                        {/* 防火墙 */}
                                        <Route path="/fireWallMan/config" component={FireWallMan} />
                                        <Route path="/fireWallMan/fwCfg" component={FireWallCfg} />
                                        <Route path="/fireWallMan/IPconfig" component={FireWallIPconfig} />
                                        <Route path="/fireWallMan/FirewallConfig" component={FirewallConfig} />
                                        <Route path="/fireWallMan/PlayRecord" component={FireWallPlayRecord} />
                                        <Route path="/fireWallMan/IPPlayRecord" component={IPPlayRecord} />
                                        <Route path="/fireWallMan/WhiteList" component={WhiteList} />
                                        <Route path="/fireWall/policyDetail" component={FireWallDetail} />
                                        <Route path="/fireWall/firewallPolicy" component={FireWallPolicy} />
                                        <Route path="/fireWall/eLineInformation" component={ELineInformation} />
                                        <Route path="/fireWall/externalConnection" component={ExternalConnection} />
                                        {/* <Route path="/fireWall/redundancyPolicy" component={RedundancyPolicy} /> */}
                                        <Route path="/fireWall/policy" component={Policy} />
                                        <Route path="/fireWall/conflictPolicy" component={ConflictPolicy} />
                                        <Route path="/fireWall/noEffectPolicy" component={NoEffectPolicy} />
                                        <Route path="/fireWall/noMatchedPolicy" component={NoMatchedPolicy} />
                                        {/* 密码变更 */}
                                        <Route path="/passwordMan/PwdHistory" component={PwdHistory} />
                                        <Route path="/passwordMan/PwdAudits" component={PwdAudits} />
                                        {/* 标签管理 */}
                                        <Route path="/tagManagement/tagList" component={TagList} />
                                        <Route path="/tagManagement/groupList" component={GroupList} />
                                        {/* 巡检管理 */}
                                        <Route path="/InspectionMan/newInspection" component={NewInspection} />
                                        <Route path="/InspectionMan" component={InspectionList} />
                                        {/* ip地址管理 */}
                                        {/*  IP management  */}
                                        <Route path={"/IpAdmin/macMonitor"} component={MacMonitor} />
                                        <Route path={"/IpAdmin/ipfunction"} component={IpFunction} />
                                        <Route path={"/IpAdmin/segment"} component={Segment} />
                                        <Route path={"/IpAdmin/iprecord"} component={IpRecord} />
                                        <Route path={"/IpAdmin/ipmonitor"} component={IpMonitor} />
                                        <Route path={"/IpAdmin/ipRecycleRecord"} component={IpRecycleRecord} />
                                        <Route path={"/newSegment"} component={newSegment} />
                                        <Route path={"/newSegment:Id"} component={newSegment} />
                                        <Route path={"/newipSearch:Id"} component={newIpSearch} />
                                        {/* /IP management  */}
                                        <Route path="/fireWall:fwId" component={FireWall} />
                                        <Route path="/fireWall" component={FireWall} />
                                        <Route path="/port:taskId" component={Port} />
                                        <Route path="/Test" component={Test} />
                                        <Route path="/configTest" component={ConfigTest} />
                                        <Route path="/postman" component={PostmanPage} />
                                        {/* 配置备份 */}
                                        <Route path="/configBackup/BackData" component={BackData} />
                                        <Route path="/configBackup/BaseLineData" component={BaseLineData} />
                                        <Route path="/configBackup/BackupRecords" component={BackupRecords} />
                                        <Route path="/configBackup/device" component={ConfigBackup} />
                                        <Route path="/configBackup/cmd" component={ConfigBackupCmd} />
                                        {/* 设备电子MAC台账 */}
                                        <Route path="/Ledger/history" component={HisLedger} />
                                        <Route path="/Ledger/macAddressList" component={MacAddressList} />
                                        <Route path="/Ledger/fixedResources" component={FixedResources} />
                                        <Route path="/Ledger" component={LedgerList} />
                                        {/* 查看可疑接入 */}
                                        <Route path="/suspiciousAccess/suspiciousDetails" component={SuspiciousDetails} />
                                        <Route path="/suspiciousAccess" component={SuspiciousAccess} />
                                        {/* 哑终端管理 */}
                                        <Route path="/dumbTerminal/bindDumbRecord" component={bindDumbRecord} />
                                        <Route path="/dumbTerminal/allBindDumbRecord" component={allBindDumbRecord} />
                                        <Route path="/dumbTerminal/unKnownDumbTerminal" component={UnKnownDumbTerminal} />
                                        <Route path="/dumbTerminal/dumbAutoFind" component={DumbAutoFind} />
                                        <Route path="/dumbTerminal" component={DumbTerminal} />
                                        <Route path="/newTerminal" component={newTerminal} />
                                        {/* <Route path="/newTerminal:Id" component={newTerminal} /> */}

                                        {/* 设备指令下发 */}
                                        <Route path="/command/aclSend" component={AclCommand} />
                                        <Route path="/command/commandItem" component={CommandItem} />
                                        <Route path="/command/approve" component={AclApprove} />
                                        <Route path="/command/newAclTask" component={NewAclTask} />
                                        <Route path="/command/exeRecord" component={AclExeRecord} />
                                        <Route path="/command/operationAudit" component={OperationAudit} />
                                        //简单模式
                                        <Route path="/command/simple/send" component={SimpleSend} exact />
                                        <Route path="/command/simple/template" component={AclTempMange} exact />
                                        <Route path="/command/simple/record" component={(props) => <AclRecord {...props} />} exact />
                                        {/* 监控 */}

                                        <Route path="/monitor" component={MonitorList} />
                                        {/* 日志管理 */}
                                        <Route path="/LogMange/sysFileLog" component={SysLog} />
                                        <Route path="/LogMange/deviceLog" component={DeviceLog} />
                                        <Route path="/LogMange/logRule" component={LogRule} />
                                        {/* AD日志 */}
                                        <Route path="/adlog" component={ADLog} />
                                        <Route path="/adhistory" component={ADhistory} />
                                        {/* DNS */}
                                        <Route path="/dns/dnsServer" component={DNSserver} />
                                        {/* ftp */}
                                        <Route path="/ftpFile" component={FtpFile} />
                                        {/* START专线管理 */}
                                        <Route path="/dedicated/device" component={Device} />
                                        <Route path="/dedicated/operator" component={Operator} />
                                        <Route path="/dedicated/line-out" component={LineOut} />
                                        <Route path="/dedicated/line-in" component={LineIn} />
                                        <Route path="/dedicated/report" component={Report} />
                                        <Route path="/dedicated/settings" component={Settings} />
                                        {/* END专线管理 */}
                                        {/* 监控 */}
                                        <Route path="/monitoringAlarm/overview" component={MonitoringAlarmOverview} />
                                        <Route path="/monitoringAlarm/snmpMonitor/editSnmpConfig" component={EditSnmpConfig} />
                                        <Route path="/monitoringAlarm/snmpMonitor/addSnmpModule" component={AddSnmpModule} />
                                        <Route path="/monitoringAlarm/snmpMonitor/addIndex" component={AddIndex} />
                                        <Route path="/monitoringAlarm/snmpMonitor/addDevice" component={AddDevice} />
                                        <Route path="/monitoringAlarm/snmpMonitor/detail" component={Detail} />
                                        <Route path="/monitoringAlarm/snmpMonitor" component={SNMPMonitor} />
                                        <Route path="/monitoringAlarm/alarm/addReceiver" component={AddReceiver} />
                                        <Route path="/monitoringAlarm/alarm/addRule" component={AddRule} />
                                        <Route path="/monitoringAlarm/alarm/setting" component={Alarm} />
                                        <Route path="/monitoringAlarm/alarm" component={AlarmList} />
                                        <Route path="/monitor" component={MonitorList} exact />
                                        <Route path="/monitoringAlarm/storage/addPolicy" component={AddPolicy} />
                                        <Route path="/monitoringAlarm/storage" component={Storage} />
                                        <Route path="/monitoringAlarm/hostPing" component={HostPing} />
                                        {/* 监控 */}
                                        //监控告警
                                        <Route path="/monitorAlert/overview" component={MonitorOverview} exact />
                                        <Route path="/monitorAlert/dataSource" component={MonitorDataSource} exact />
                                        <Route path="/monitorAlert/strategy" component={MonitorStrategy} exact />
                                        <Route path="/monitorAlert/strategy/editForm" component={EditMonitorStrategy} exact />
                                        <Route path="/monitorAlert/alertRule" component={AlertRule} exact />
                                        <Route path="/monitorAlert/alertRecord" component={AlertRecord} exact />
                                        <Route path="/monitorAlert/selfHealing" component={SelfHealing} exact />
                                        <Route path="/monitorAlert/alertGroup" component={AlertGroup} exact />
                                        <Route path="/monitorAlert/alertBigScreen" component={AlertBigScreen} exact />
                                        <Route path="/monitorAlert/dashBoard" component={DashBoard} exact />
                                        <Route path="/monitorAlert/hostPing" component={HostPing} exact />
                                        <Route path="/monitorAlert/dataCollection/scrapeJob" component={SnmpJob} exact />
                                        <Route path="/monitorAlert/dataCollection/prometheusCfg" component={PrometheusCfg} exact />
                                        <Route path="/monitorAlert/dataCollection/detaiJob" component={DetaiJob} exact />
                                        <Route path="/monitorAlert/dataCollection/snmpModule" component={SnmpModule} exact />
                                        <Route path="/monitorAlert/dataCollection/snmpPreview" component={SnmpPreview} exact />
                                        <Route path="/monitorAlert/dataCollection/addSnmpPreview" component={AddSnmpPreview} exact />
                                        <Route path="/monitorAlert/dataCollection/detailSnmpPreview" component={DetailSnmpPreview} exact />
                                        <Route path="/monitorAlert/dataCollection/detailSnmpModule" component={DetailSnmpModule} exact />
                                        <Route path="/monitorAlert/dataCollection/customIndicator" component={CustomIndicator} exact />
                                        <Route path="/monitorAlert/dataCollection/oidList" component={OidList} exact />
                                        <Route path="/monitorAlert/deviceCheck/devices" component={deviceCheckDevice} exact />
                                        <Route path="/monitorAlert/deviceCheck/records" component={deviceCheckRecord} exact />
                                        <Route path="/monitorAlert/template" component={MsgTemplate} exact />
                                        {/* 自定义运维任务 */}
                                        <Route path="/customizeTask/list" component={CustomizeTask} />
                                        <Route path="/customizeTask/newlist" component={NewCustomizeTask} />
                                        <Route path="/customizeTask/result" component={CustomizeTaskResult} />
                                        {/*  邮件管理*/}
                                        <Route path="/email/emailConfig" component={EmailConfig} />
                                        <Route path="/email/emailList" component={EmailList} />
                                        <Route path="/email/emailForm" component={EmailForm} />
                                        <Route path="/notice/sms" component={SmsPage} />
                                        {/* 自动发现-拓扑 */}
                                        {/* 大屏展示配置 */}
                                        <Route path={"/data_v/bigScreenCfg"} component={bigScreenCfg} />
                                        {/* 大屏展示配置 */}
                                        <Route path="/autoFind/findDevice" component={FindDev} />
                                        <Route path="/autoFind/tolopogy" component={NetTolopogy} />
                                        <Route path="/autoFind" component={AutoFind} />
                                        <Route path="/topology" component={TopoPage} />
                                        <Route path="/map" component={MapNet2} exact />
                                        <Route path="/map2" component={MapNet} exact />
                                        {/* 模拟器 */}
                                        <Route path="/emulator/device" component={EmulatorDevice} exact />
                                        <Route path="/emulator/type" component={EmulatorType} exact />
                                        <Route path="/emulator/commandRespList" component={CommandRespList} exact />
                                        <Route path="/pingan" component={pinganLogin} exact />
                                        {/* 容器管理 */}
                                        <Route path="/docker/containers" component={dockerContainers} exact />
                                        {/* 拓扑 */}
                                        <Route path="/ShowMyError" component={ShowMyError} />
                                        <Route path="/error" component={ErrorPage} />
                                        <Route path="/exception401" component={Exception401} exact />
                                        <Route path="/exception403" component={Exception403} exact />
                                        <Route path="/exception404" component={Exception404} exact />
                                        <Route path="/exception500" component={Exception500} exact />
                                        <Route path="*">
                                            <Exception404 />
                                        </Route>
                                        {/* <Redirect to="/exception404" /> */}
                                        {/* <Redirect to="/login" /> */}
                                        {/*<Route component={Home} />*/}
                                    </Switch>
                                </Admin>)
                            } else if (this.state.error) {
                                return <Redirect to={{ pathname: "/error" }} />
                            } else {
                                return <Redirect to={{ pathname: "/login" }} />
                            }
                        }
                        } />
                    </Switch>
                </App>
            </HashRouter>
        );
    }
}
