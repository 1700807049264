import React, { PureComponent, Fragment } from 'react';
import { Card, Row, Col, Button, Collapse, message, Icon, Modal, Tooltip, Select, Tabs } from 'antd';
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import Utils from "@/utils/utils";
import Socket from "@/socket/index";
import MonacoEditor from "react-monaco-editor";
import cityConfig from "./config.json"
import MapChart from './map';
import http from '@/axios/axios_utils';
import AxiosObj from 'axios';
// import https from 'https';
import LineChart from './line';
import "./index.scss"
// 创建忽略 SSL 的 axios 实例
// const ignoreSSL = AxiosObj.create({
//     httpsAgent: new https.Agent({
//         rejectUnauthorized: false
//     })
// });
export default class Airport extends PureComponent {
    state = {
        city: cityConfig.flow.city,
        data: {},
        sysTime: "",
        flowCfgData: {},
        inLineData: null,
        refreshRate: 3000,
    }
    componentWillMount() {
        this.actionRes();
    }
    componentWillUnmount() {
        clearInterval(this.tempInt);
    }
    actionRes() {
        Socket.getConfig("flow", (res) => {
            this.setState({ flowCfgData: res }, () => {
                this.changeInterval()
            });
            this.requestData(res);
        });
    }
    // const result = await axios.post(
    //     `https://${url}/login`,
    //     body,
    //     {
    //       httpsAgent: new https.Agent({
    //         rejectUnauthorized: false
    //       })
    //     }
    //   ) 
    async requestData(flowCfgData) {
        const _this = this;
        try {
            let query = flowCfgData.query;
            const htpUrl = flowCfgData.url
            const timeInterval = flowCfgData.timeInterval || 10000;
            const probeIdObj = flowCfgData.probeId;
            const address = "http://" + Socket.getAddress() + ":50034/router";
            // const address = "http://localhost:50034/router";
            const values = Object.values(probeIdObj);
            const sysTime = new Date();
            const queryDate = Utils.formateDate(sysTime.getTime() - timeInterval - 1000) + " - " + Utils.formateDate(sysTime.getTime() - 1000);
            query.date = queryDate;
            let inData = {};
            let outData = {};

            let InLineData = {
                time: [],
                indata: [],
                outdata: [],
            }
            let indata2 = [];
            let outdata2 = [];
            for (let key in values) {//更换不同的ID请求数据
                const id = values[key];
                query["probeIds"] = [id];
                const _query = { query: query };
                // console.log(_query.query)
                // const { data: res, status } = await ignoreSSL.get(htpUrl, { params: _query });
                const { data: res, status } = await AxiosObj.post(address, {
                    method: "get",
                    url: htpUrl,
                    headers: null,
                    data: _query
                });
                if (status === 200 && res.data[0]) {
                    const item = res.data[0];
                    const name = this.findKey(probeIdObj, id);
                    inData[name] = item.inboundBitps;
                    outData[name] = item.outboundBitps;

                    let tempData = this.getInOutData(res.data, name);
                    InLineData.time = tempData.time;
                    indata2.push([name, tempData.in]);
                    outdata2.push([name, tempData.out]);
                } else if (this.runInterval) {
                    clearInterval(this.runInterval);
                }
            }
            InLineData.indata = indata2;
            InLineData.outdata = outdata2;
            const newData = { in: inData, out: outData }
            this.setState({
                data: newData, sysTime: Utils.formateDate(sysTime),
                inLineData: InLineData
            });
        } catch (e) {
            clearInterval(_this.runInterval);
        }
    }
    getInOutData(data, name) {
        let backData = data;
        const time = [];
        // let allData = [[name, []]];
        // let itemData = [];
        let sourceData_in = [];
        let sourceData_out = [];
        backData.map((item) => {
            // {
            //     "date": date + 1000 * i,
            //     "probeId": "aaa10",
            //     "totalBitps": 933996192,
            //     "inboundBitps": Math.floor(Math.random() * (max - min + 1)) + min,
            //     "outboundBitps": Math.floor(Math.random() * (max - min + 1)) + min,
            // }
            time.push(Utils.formateDate(item.date).split(" ")[1]);
            sourceData_in.push(item.inboundBitps);
            sourceData_out.push(item.outboundBitps);
        });
        // itemData.push(name)
        // itemData.push(sourceData_in)
        return { time, in: sourceData_in, out: sourceData_out };
    }
    findKey(obj, value, compare = (a, b) => a === b) {

        return Object.keys(obj).find(k => compare(obj[k], value))
    }
    createData(data, flowCfgData) {
        const pidObj = flowCfgData.probeId;
        let inData = {};
        let outData = {};
        data.map((item) => {
            const name = this.findKey(pidObj, item.probeId);
            inData[name] = item.inboundBitps;
            outData[name] = item.outboundBitps;
        })
        const newItem = { in: inData, out: outData };
        this.setState({ data: newItem });
        console.log(newItem)
    }
    setConfig(newdata) {
        Socket.setConfig("flow", newdata, (res) => {
            this.requestData(newdata);
        });
    }
    getConfig() {
        const _this = this;
        Socket.getConfig("flow", (res) => {
            console.log(res)
            this.setState({ flowCfgData: res })
            let tempData = JSON.stringify(res, null, 5);
            Modal.confirm({
                title: "配置信息",
                width: "70%",
                content: <MonacoEditor
                    language="json"
                    theme="vs-dark"
                    value={tempData}
                    height="600"
                    onChange={(val) => { tempData = val; }}
                />,
                onOk() {
                    try {
                        tempData = JSON.parse(tempData);
                        _this.setConfig(tempData);
                    } catch (e) {
                        message.error("格式错误")
                    }

                }
            })
        });
    }

    changeSize(e) {
        const elemt = document.getElementById("contentDiv");
        if (e) {
            elemt.style.maxHeight = 1100;
            elemt.style.overflowY = "auto";
            Utils.launchFullScreen(elemt);
        } else {
            elemt.style.overflowY = "hidden";
            Utils.exitFullScreen(elemt);
        }
    }
    IconClick(value) {
        const { flowCfgData } = this.state;
        switch (value) {
            case "setting": this.getConfig(); break;
            case "reload": this.requestData(flowCfgData); break;
            case "fullscreen-exit":
                this.changeSize(false); break;
            case "fullscreen":
                this.changeSize(true); break;
        }
    }
    changeInterval(flag) {
        const { refreshRate, flowCfgData } = this.state;
        const _this = this;
        if (refreshRate === 0 && this.runInterval) {
            clearInterval(this.runInterval);
        } else {
            clearInterval(this.runInterval);
            this.runInterval = setInterval(() => {
                _this.requestData(flowCfgData);
            }, refreshRate)
        }
        if (flag) message.success("应用成功!");
    }
    render() {
        const { city, data, sysTime, refreshRate, inLineData } = this.state;
        return (
            <div className='mapFlowDiv'>
                <CustomBreadcrumb arr={["运维管理", "网络流量"]} />
                <div id="contentDiv">
                    <div className="headTool">
                        {[
                            ["参数配置", "setting"],
                            ["刷新", "reload"],
                            ["全屏", "fullscreen"],
                            ["退出全屏", "fullscreen-exit"],
                        ].map((item, key) => {
                            return <Fragment key={key}>
                                <Tooltip title={item[0]} placement="bottom">
                                    <Icon className={"iconStyle"} type={item[1]} onClick={() => { this.IconClick(item[1]) }} />
                                </Tooltip>
                            </Fragment>
                        })}
                        <div className="param">刷新频率&nbsp;
                        <Select value={refreshRate} onChange={e => this.setState({ refreshRate: e })}>
                                <Select.Option value={0}>停止</Select.Option>
                                <Select.Option value={1000}>1s</Select.Option>
                                <Select.Option value={2000}>2s</Select.Option>
                                <Select.Option value={3000}>3s</Select.Option>
                                <Select.Option value={5000}>5s</Select.Option>
                                <Select.Option value={10000}>10s</Select.Option>
                                <Select.Option value={30000}>30s</Select.Option>
                                <Select.Option value={60000}>1min</Select.Option>
                                <Select.Option value={300000}>5min</Select.Option>
                            </Select>
                            <Button size="small" icon="check-circle" onClick={() => { this.changeInterval(true) }}>应用</Button>
                        </div>
                    </div>
                    <Tabs>
                        <Tabs.TabPane key="flow" tab="流量图">
                            <div className='totalFlow'>
                                <LineChart title="流入" city="珠海" data={inLineData} /><p />
                                <LineChart title="流出" city="珠海" data={inLineData} />
                                {/* <div className="date">
                                    时间节点&nbsp;:&nbsp;
                                <Icon type="clock-circle" />&nbsp;
                                    {sysTime}
                                </div> */}
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="map" tab="示意图">
                            <MapChart legendChanged={(param) => {
                                this.setState({ city: param.name, })
                            }}
                                data={data}
                            />
                        </Tabs.TabPane>
                    </Tabs>


                </div>
            </div>
        );
    }
}
