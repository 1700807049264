import service from "../zx-utils/request";

// 获取列表
export function fetchList(query, callback) {
    service({
        url: '/zx/eapi/',
        method: 'get',
        params: query
    }).then(res => {
        callback(res)
    })
}


// delete object
export function deleteObj(keys, callback) {
    service({
        url: '/zx/eapi/' + keys[0]+ '/',
        method: 'delete',
        params: {},
    }).then(res => callback(res))
}

// 批量删除
export function batchDelete(keys, callback) {
    service({
        url: '/zx/eapi/',
        method: 'delete',
        params: {keys: keys.toString()},
    }).then(res => callback(res))
}

// 点击编辑，获取编辑对象
export function fetchObj(id, callback) {
    service({
        url: '/zx/eapi/' + id + '/',
        method: 'get',
        params: '',
    }).then(res => callback(res))
}

export function patchEdit(obj, callback) {
    service({
        url: '/zx/eapi/' + obj.id + '/',
        method: 'put',
        params: '',
    }).then( res => callback(res))
}
