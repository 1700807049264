import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from "antd";

import { connect } from 'react-redux'
import ReactEcharts from "echarts-for-react"
import Socket from '@/socket/index'
import Utils from '@/utils/utils'
const colorArray = ["#626c91", "#979797", "#6be6c1", "#c4ebad", "#96dee8"];
function DataMap(props) {
    const [load1, setLoad1] = useState(true);
    const [load2, setLoad2] = useState(true);
    const [load3, setLoad3] = useState(true);
    const [load4, setLoad4] = useState(true);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState({});
    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);
    const style = {
        chart: {
            height: 250
        }
    }
    useEffect(() => {//componentDidMount
        statisDeviceTypeNum()
        statisAreaDeviceTypeNum()
        statisBrandTypeNum()
        statisDeviceOnline()
        return () => { //componentWillUnmount
        }
    }, []);
    const getOptionPie = (_data1) => ({
        title: {
            text: '设备分类统计',
            x: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: "{b} : {c} ({d}%)"
            // formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
            orient: 'horizontal',
            top: 30,
            type: "scroll",
            left: 0,
            itemHeight: 8,
            itemWidth: 8,
            data: _data1.map((i) => i.name)
        },
        color: ["#3fb1e3", "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8"],
        series: [
            {
                name: '分类统计',
                type: 'pie',
                radius: '55%',
                label: { show: true, position: "outside", formatter: "{b}:{c}\n({d}%)", },
                center: ['50%', '60%'],
                data: _data1,
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    });

    const getOptionCloums = (_data3) => ({
        title: {
            text: '设备品牌分布统计',
            x: 'center'
        },
        legend: {
            orient: 'horizontal',
            top: 30,
            type: "scroll",
            left: 0,
            itemHeight: 8,
            itemWidth: 8,
            data: _data3.map((r) => (r._id))
        },
        color: ["#3fb1e3", "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8"],
        tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                // type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: _data3.map((r) => (r._id)),
                axisTick: {
                    alignWithLabel: true
                }
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: '设备数量',
                type: 'bar',
                label: { show: true, position: "top" },
                barWidth: '60%',
                itemStyle: {
                    normal: {
                        //这里是重点
                        color: function (params) {
                            //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                            var colorList = ["#3fb1e3", "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8", '#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622'];
                            return colorList[params.dataIndex]
                        }
                    }
                },
                data: _data3.map((r) => (r.count))
            }
        ]
    });
    const getPieDoughnut = (_data4) => ({
        title: {
            text: '设备在线情况',
            x: 'center'
        },
        legend: {
            orient: 'horizontal',
            top: 30,
            type: "scroll",
            left: 0,
            itemHeight: 8,
            itemWidth: 8,
            data: ['在线', '离线', '未知',]
        },
        series: [
            {
                name: '设备统计',
                type: 'pie',
                radius: ['50%', '70%'],
                center: ['50%', '60%'],
                avoidLabelOverlap: false,
                label: {
                    show: true,
                    position: 'inside',
                    formatter: "{b}{c}({d}%)"

                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '20',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false,
                },
                data: _data4.map((i, key) => {
                    let name = "在线";
                    let color2 = "#6BE6C1";
                    if (i._id === "none") {
                        name = "未知"; color2 = "#626C91"
                    } else if (i._id === "offline") {
                        name = "离线"; color2 = "#f5222d"
                    }
                    return {
                        value: i.count, name,
                        itemStyle: { color: color2 }
                    }
                })
            }
        ]
    })
    const getMutilCloums = (_data2) => ({
        title: {
            text: '按区域分类设备统计',
            x: 'center'
        },
        legend: {
            orient: 'horizontal',
            top: 30,
            type: "scroll",
            left: 0,
            itemHeight: 8,
            itemWidth: 8,
            data: _data2.type
        },
        color: ["#3fb1e3", "#6be6c1", "#a0a7e6", "#626c91", "#c4ebad", "#96dee8"],
        tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        yAxis: {
            type: 'value'
        },
        xAxis: {
            type: 'category',
            data: _data2.area
        },
        series: _data2.series
    })

    function tranl(text) {
        let name = text;
        if (text === "switch") {
            name = "交换机";
        } else if (text === "firewall") {
            name = "防火墙";
        } else if (text === "router") {
            name = "路由器";
        }
        return name;
    }
    function statisDeviceTypeNum() {
        Socket.ExecuteAction('statisDeviceTypeNum',
            {}, "/", (r) => {
                const data = r.data.map((i) => {
                    let name = i._id;
                    if (i._id === "switch") {
                        name = "交换机";
                    } else if (i._id === "firewall") {
                        name = "防火墙";
                    } else if (i._id === "router") {
                        name = "路由器";
                    }
                    return { name, value: i.count }
                })
                setData1(data);
                setLoad1(false);
            });
    }
    function statisAreaDeviceTypeNum() {
        Socket.ExecuteAction('statisAreaDeviceTypeNum',
            {}, "/", (r) => {
                console.log(r)
                // const data = r.data.map((i)=>{
                //     return {name:i._id,value:i.count}
                // })
                // setData1(data);
                createData2(r.data);
                setLoad2(false);
            });

    }
    function statisBrandTypeNum() {
        Socket.ExecuteAction('statisBrandTypeNum',
            {}, "/", (r) => {
                setData3(r.data)
                setLoad3(false);
            });
    }
    function statisDeviceOnline() {
        Socket.ExecuteAction('statisDeviceOnline',
            {}, "/", (r) => {
                setData4(r.data)
                setLoad4(false);
            });
    }
    function createData2(data) {

        const area = Object.keys(data);
        let typeList = []
        area.map((a) => {
            const arr = Object.keys(data[a])//{} 
            typeList = Utils.MergeArray(arr, typeList);
        })

        let series = typeList.map((_type) => {
            return {
                name: tranl(_type),
                type: 'bar',
                stack: '总量',
                label: {
                    show: true,
                },
                // itemStyle: {
                //     color: "#a0a7e6"
                // },
                data: area.map((aa) => {
                    return data[aa][_type]
                })
            }
        })
        let tempData = {
            type: typeList.map((u) => tranl(u)),
            area,
            series
        }
        setData2(tempData)
    }
    return <div className={props.className}>
        <Row gutter={10}>
            <Col span={6}>
                <Card loading={load1}>
                    {/* <PieChar data={[{ item: "成功", count: 40 }, { item: "失败", count: 10 }, { item: "异常", count: 4 }]} /> */}
                    <ReactEcharts
                        option={getOptionPie(data1)}
                        style={style.chart}
                    // onChartReady={this.onChartReady}
                    // onEvents={onEvents} 
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card loading={load2}>
                    <ReactEcharts
                        option={getMutilCloums(data2)}
                        style={style.chart}
                    // onChartReady={this.onChartReady}
                    // onEvents={onEvents} 
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card loading={load3}>
                    <ReactEcharts
                        option={getOptionCloums(data3)}
                        style={style.chart}
                    // onChartReady={this.onChartReady}
                    // onEvents={onEvents} 
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card loading={load4}>
                    <ReactEcharts
                        option={getPieDoughnut(data4)}
                        style={style.chart}
                    // onChartReady={this.onChartReady}
                    // onEvents={onEvents} 
                    />
                </Card>
            </Col>
        </Row>
    </div>
}
export default connect()(React.memo(DataMap))
