/**
 * 系统配置
 */
import React from 'react'
import { Card, Table, Button, message, Popconfirm, Icon, Input, Modal, Form, Tabs, Checkbox, Radio, Divider } from "antd";
import Socket from "@/socket/index";
import {
    SubmitButton,
    FormItem,
} from "formik-antd"
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik"
import Utils from '@/utils/utils';
import MonacaEditor from 'react-monaco-editor';

class SYSconfig extends React.Component {
    state = {
        apiVisible: false,
        loading: false,
        modeData: {},
        activeKey: "command",
        configStr: "",
    };
    componentWillMount() {
        this.getConfig("command");
    }
    getAllConfig = () => {
        Socket.ExecuteAction("QueryTableConfig", {}, "", (res) => {
            this.setState({ configStr: JSON.stringify(res.data, null, 5) })
        });
    };
    addConfigParam = (val) => {
        Socket.ExecuteAction("AddTableConfig", { config: val }, "", (res) => {
            message.success("成功")
        });
    };
    getConfig = (mode) => {
        Socket.getConfig(mode, (res) => {
            console.log(res)
            this.setState({ modeData: res });
        });
    };
    handleSubmit() {
        const { activeKey } = this.state;
        const _this = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                Socket.setConfig(activeKey, values, () => { message.success("保存成功!") });
            }
        });

    };
    tabOnChange(e) {
        this.setState({ activeKey: e })
        if (e === "code") {
            this.getAllConfig();
        }
    }
    render() {
        let { modeData, activeKey, configStr, apiVisible } = this.state;
        const { getFieldDecorator } = this.props.form;
        const columns = [
            {
                title: '序号',
                align: 'center',
                render: (text, item, key) => { return <span>{key + 1}</span> }
            }, {
                title: '接口名称',
                align: 'center',
                dataIndex: 'name'
            }, {
                title: '请求接口名',
                align: 'center',
                dataIndex: 'apiName',

            }, {
                title: '创建时间',
                align: 'center',
                dataIndex: 'createTime'
            }, {
                title: '操作',
                width: '270px',
                align: 'center',
                render: (text, item) => {
                    return <div>
                        <Button type="primary" onClick={() => {
                            this.setState({ modalTitle: "编辑接口", selectOne: item, apiVisible: true, });
                        }}>
                            <Icon type="form" />编辑
                    </Button>
                        <Popconfirm
                            title="确定删除吗?"
                            onConfirm={() => this.delete(item._id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger"
                                style={{ marginLeft: 8 }}
                                icon='delete'>
                                删除
                            </Button>
                        </Popconfirm>
                    </div>
                }
            }];
        const formList = [
            {
                label: "接口名称",
                type: 'INPUT',
                field: "name",
                initialValue: '',
                placeholder: "接口名称"
            },
            {
                label: "请求接口名",
                type: 'INPUT',
                field: "apiName",
                initialValue: '',
                placeholder: "请求接口名"
            },
        ];
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };
        return (
            <div>

                <Card title={<span style={{ fontWeight: 600 }}>系统配置信息</span>}
                    extra={
                        <Button type="primary" icon="edit" style={{ marginLeft: 8 }} onClick={() => { this.setState({ modalTitle: "新建参数", apiVisible: true }); }}>
                            新建参数
                </Button>}
                >
                    {/* <Table
                        dataSource={this.state.tableData}
                        columns={columns}
                        size="small"
                        loading={loading}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                console.log(page, pageSize);
                                this.updata(pageSize, page, {});
                            }
                        }}
                    /> */}
                    <Tabs tabPosition="left" onChange={(e) => { this.tabOnChange(e); }} activeKey={activeKey}>
                        <Tabs.TabPane tab="配置下发" key="command">
                            <Form   {...formItemLayout}>
                                <Form.Item
                                    label="配置下发是否需要审核"
                                >
                                    {getFieldDecorator('approve', {
                                        // valuePropName: 'checked',
                                        initialValue: modeData.approve,
                                    })(<Radio.Group>
                                        <Radio value={"01"}>配置下发不需要审核</Radio>
                                        <Radio value={"02"}>配置下发强制需要审核</Radio>
                                        <Radio value={"03"}>配置下发是否需要审核由下发人员决定</Radio>
                                    </Radio.Group>)}
                                </Form.Item>
                            </Form>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="参数" key="code">
                            <MonacaEditor
                                theme="vs-dark"
                                value={configStr}
                                height="450"
                                // options={}
                                language="json"
                            />
                        </Tabs.TabPane>
                    </Tabs>
                    <div style={{ marginTop: 10, width: "100%", textAlign: "center", borderTop: "1px solid #e2e2e2", paddingTop: 10 }}>
                        <Button type="primary" icon="save" onClick={() => { this.handleSubmit() }}>保存</Button>
                    </div>
                </Card>
                <Modal
                    title={"新建"}
                    visible={apiVisible}
                    onOk={() => { this.commitInfo(); }}
                    onCancel={() => { this.setState({ apiVisible: false }) }}
                    okText="确认"
                    cancelText="取消"
                    footer={null}
                    destroyOnClose
                    afterClose={() => {
                        this.setState({ selectOne: {} })
                    }}
                >
                    <div>
                        <Formik
                            initialValues={{
                                name: "",
                                value: "",
                            }}
                            onSubmit={(values, actions) => {
                                console.log(values);
                                this.addConfigParam(values)
                                actions.setSubmitting(false)
                            }}
                        >
                            {(props2) => {
                                return <FormikForm >
                                    <FormItem
                                        name={"name"}
                                        label={"name"}
                                        required={true}
                                    >
                                        <FormikInput name={"name"} placeholder={"key"} />
                                    </FormItem>
                                    <FormItem
                                        name={"value"}
                                        label={"value"}
                                        required={true}
                                    >
                                        <FormikInput name={"value"} placeholder={"value"} />
                                    </FormItem>
                                    <Divider />
                                    <SubmitButton disabled={false}>新增</SubmitButton>
                                </FormikForm>

                            }}
                        </Formik>
                    </div>
                </Modal>
            </div>
        )
    }
}
const SYSconfigForm = Form.create()(SYSconfig);
export default SYSconfigForm