import { Layout, Button, Checkbox, Card, message } from 'antd';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import CommandRespList from './CommandRespList'
import { Row, Col } from 'antd';
import EditModal from '@/components/EditModal/index';
import http from '@/axios/axios_utils';
import { modelcmdresp, devicecmdresp, AddDeviceCmdResp, AddModelCmdResp } from '../utils/api';
import FormikForm from './from';
import "./index.scss"
const formList = [
    { type: "input", name: "resp_name", label: "名称", placeholder: "名称", required: true, },
    { type: "textArea", name: "desc", label: "描述", placeholder: "描述", required: true, },
    { type: "code", name: "resp", label: "返回信息", placeholder: "返回信息", required: true, },
]
function CommandRespInfoView(props) {
    const [visible, setVisible] = useState(false);
    const [total,] = useState(10);
    const [_pageSize] = useState(20);
    const [_current] = useState(1);
    const [CommandRespListData, setRespDta] = useState(props.data);
    const [tabselectItem, setTabSelectItem] = useState(CommandRespListData[0] || {});
    function saveDataSource(val) {
        if (props.selectItem.ip) {//设备
            const param1 = {
                ip: props.selectItem.ip,
                resp_id: props.default_resp_id,
                cmd: props.cmd,
                resp_name: val.name,
                resp: val.resp,
                desc: val.desc,
            }
            console.log(param1)
            http.put(devicecmdresp(), param1, (r) => {
                message.success("更新成功!");
                props.callback();
                updataAfterTable(val.name)
            })
        } else {//模型
            const param2 = {
                vendor: props.selectItem.vendor || props.selectCmdItem.vendor,
                class: props.selectItem.class || props.selectCmdItem.class,
                type: props.selectItem.type || props.selectCmdItem.type,
                version: props.selectItem.version || props.selectCmdItem.version,
                resp_id: props.default_resp_id,
                cmd: props.cmd,
                resp_name: val.name,
                resp: val.resp,
                desc: val.desc,
            }
            console.log(param2)
            http.put(modelcmdresp(), param2, (r) => {
                message.success("更新成功!");
                props.callback();
                updataAfterTable(val.name)
            })
        }
    }
    function updataAfterTable(name) {
        const newData = CommandRespListData.map((r) => {
            if (r.id === tabselectItem.id) {
                r.name = name;
            }
            return r
        });
        setRespDta(newData);
    }
    function adddataAfterTable(obj) {
        const newData = [...CommandRespListData, obj];
        setRespDta(newData);
    }
    function AddCmdResp(val) {
        const { ip, type, vendor, version } = props.selectItem;
        const param = {
            ip,
            cmd: props.cmd,
            resp_id: new Date().getTime() + "",
            resp_name: val.resp_name,
            resp: val.resp,
            desc: val.desc,
        }
        if (ip) {
            http.post(AddDeviceCmdResp(), param, (r) => {
                message.success("新增成功!");
                props.callback();
                adddataAfterTable({
                    id: param.resp_id,
                    name: param.resp_name,
                    resp: param.resp,
                    desc: param.desc,
                });
                setVisible(false);
            })
        } else {
            val.author = sessionStorage.getItem("userName");
            val.class = props.selectItem.class;
            val.cmd = props.cmd;
            val.type = type;
            val.vendor = vendor;
            val.version = version;
            val.resp_id = new Date().getTime() + "";
            http.post(AddModelCmdResp(), val, (r) => {
                message.success("新增成功!");
                props.callback();
                adddataAfterTable({
                    id: param.resp_id,
                    name: param.resp_name,
                    resp: param.resp,
                    desc: param.desc,
                });
                setVisible(false);
            })
        }

    }
    return (
        <div className="comm-resp-info">

            <div className="site-layout-background">
                <Button type="primary" icon="file-add" onClick={() => setVisible(true)}>添加</Button>&nbsp;
            </div>

            <Row gutter={15}>
                <Col span={9}>
                    <Card className="contentLeft" >
                        {/* <Button type="primary" icon="file-add" onClick={() => setVisible(true)}>添加命令</Button>&nbsp; */}
                        <CommandRespList data={CommandRespListData} default_resp_id={props.default_resp_id}
                            selectItem={props.selectItem}
                            cmd={props.cmd}
                            onRowChange={(r) => setTabSelectItem(r)} />
                    </Card>
                </Col>
                <Col span={15}>
                    <Card
                        className="contentRight"

                    >
                        {/* <UpdateCommandRespInfo></UpdateCommandRespInfo> */}
                        <FormikForm
                            onSubmit={(val) => {
                                saveDataSource(val);
                                props.closeComVisible();
                            }}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                            initialValues={tabselectItem || {}}
                        // formList={formList}
                        />
                    </Card>
                </Col>
            </Row>
            <EditModal
                title="新增"
                visible={visible}
                onCancel={() => setVisible(false)}
                onSubmit={(val) => {
                    console.log(val);
                    AddCmdResp(val);
                }}
                style={{ top: 20 }}
                footer={null}
                width="55%"
                initialValues={{}}
                destroyOnClose
                formList={formList}
            />
        </div>
    );
}
export default CommandRespInfoView;