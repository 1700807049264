import React from 'react'
import { Card, Table, Button, message, Popconfirm, Icon, Input, Tree, Row, Col, Form, Modal, Select } from "antd";
import Socket from "@/socket/index";
import EditModal from '@/components/EditModal/index';
import Utils from '@/utils/utils';
import { GetModelTree, AddDeviceModel, deviceModel } from '../utils/api';
import http from '@/axios/axios_utils';

const { TreeNode } = Tree;
const Search = Input.Search;


class MenuClass extends React.Component {
    state = {
        TreeData: [],
        expandedKeys: [],
        dataList: [],
        searchValue: '',
        autoExpandParent: false,
        visible: false,
        selectNode: {},
        RightMenuStyle: {},
        rightClickNodeTreeItem: null
    };
    componentWillMount() {
        this.getMenuData();
    }

    onDragEnter = info => {
        // console.log(info);
        // expandedKeys 需要受控时设置
        // this.setState({
        //   expandedKeys: info.expandedKeys,
        // });
        // let dragNodesKeys = info.dragNodesKeys;
        // let key = dragNodesKeys[dragNodesKeys.length - 1];
        // let index = info.dropPosition + 2;

        // Socket.ExecuteAction("UpdateMenu", { key, index }, "", (result) => {
        //     message.success("修改成功!");
        // });
    };
    getMenuData = () => {
        http.get(GetModelTree(), {}, (r) => {
            const dataList = [];
            let TreeData = r.data;
            const generateList = (data, parentKey) => {
                const temp = [];
                for (let i = 0; i < data.length; i++) {
                    const node = data[i];
                    let { title } = node;
                    let key = parentKey ? parentKey + "?" + title : title;
                    let newNode = { key, title }
                    dataList.push(newNode);
                    if (node.children) {
                        newNode.children = generateList(node.children, key)
                    }
                    temp.push(newNode);
                }
                return temp;
            };
            TreeData = generateList(TreeData, "");
            console.log(TreeData)
            this.setState({
                TreeData,
                dataList
            });
        })
    };
    saveDataSource = (val) => {
        http.post(AddDeviceModel(), val, (r) => {
            this.getMenuData();
            this.setState({ visible: false })
        })
    };
    updataMenuData = (action, body, newTreeData) => {
        switch (action) {
            case "DeleteMenu":
                http.delete_body(deviceModel(), body, (res) => {
                    this.getMenuData();
                })
                message.success('删除成功');
                this.setState({ rightClickNodeTreeItem: null })
                break;
            case "AddMenu":
                console.log(action, body, newTreeData);
                break;
            case "UpdateMenu":
                console.log(action, body, newTreeData);
                break;
        }
        console.log(body, newTreeData);
    };

    onDrop = info => {
        console.log(info);
        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dropPos = info.node.props.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        const source = info.dragNode.props.pos.split("-");
        // console.log(dropKey, dragKey, dropPos, dropPosition, source);
        let _flag = true;

        if (source.length != dropPos.length) {
            _flag = false;
        } else {
            source.map((item, key) => {
                console.log(item, dropPos[key])
                if (item != dropPos[key] && (key + 1) != source.length) {
                    _flag = false;
                }
            })
        }
        if (!_flag) { message.error("暂不支持跨级操作!"); return };
        let dragNodes = info.dragNode;
        let body = {
            key: dragNodes.props.eventKey,
            parentKey: dragNodes.props.parentKey,
            index: parseInt(dropPos[dropPos.length - 1]) + 1,
            title: dragNodes.props.titleName,
            display: dragNodes.props.display,
            interface: dragNodes.props.interface,
            icon: dragNodes.props.iconName,
        }
        // Socket.ExecuteAction("UpdateMenu", body, "", (result) => {
        //     message.success("修改成功!");
        //     this.getMenuData();
        // });
    };
    getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some(item => item.key === key)) {
                    parentKey = node.key;
                } else if (this.getParentKey(key, node.children)) {
                    parentKey = this.getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };
    onChange = e => {
        const { value } = e.target;
        let expandedKeys = [];
        if (value.trim() != "") {
            expandedKeys = this.state.dataList
                .map(item => {
                    if (item.title.indexOf(value) > -1) {
                        return this.getParentKey(item.key, this.state.TreeData);
                    }
                    return null;
                })
                .filter((item, i, self) => item && self.indexOf(item) === i);
        }
        this.setState({
            expandedKeys,
            searchValue: value,
            autoExpandParent: true,
        });
    };
    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };
    // tree列表上右键事件
    onRightClick = e => {
        const _this = this;
        const keyList = e.node.props.eventKey.split("?");
        let selectNode = {
            vendor: keyList[0] ? keyList[0] : "",
            class: keyList[1] ? keyList[1] : "",
            type: keyList[2] ? keyList[2] : "",
            version: keyList[3] ? keyList[3] : "",
        }
        this.setState({
            selectNode,
            rightClickNodeTreeItem: {
                pageX: e.event.clientX,
                pageY: e.event.clientY,
                idKey: e.node.props["eventKey"],
                categoryName: e.node.props["titleName"]
            }
        }, () => {
            // document.getElementById("TreeRightMenu").onmouseleave = function () {
            //     _this.setState({ rightClickNodeTreeItem: null })
            // }
            document.onclick = (e) => {
                if (e.target.parentNode) {
                    if (e.target.parentNode.id) {
                        if (e.target.parentNode.id != "TreeRightMenu") {
                            _this.setState({ rightClickNodeTreeItem: null })
                        }
                    } else {
                        _this.setState({ rightClickNodeTreeItem: null })
                    }
                }
            }
        });
    };


    //点击右键菜单选项
    clickRightMenu(key, nodeKey) {
        let { TreeData, selectNode } = this.state;
        let newTreeData = TreeData;
        let state = true;
        if (key === "del") {
            state = false;
            this.updataMenuData("DeleteMenu", selectNode, newTreeData);
        }
        this.setState({ visible: state, rightClickNodeTreeItem: null, nodeKey, })
    }
    // 自定义右键菜单内容
    getNodeTreeRightClickMenu = (selectNode) => {
        const { pageX, pageY, idKey, categoryName } = { ...this.state.rightClickNodeTreeItem };
        const RightMenuStyle = {
            position: "fixed",
            // left: `${pageX - 230}px`,
            // top: `${pageY - 118}px`,
            left: `${pageX}px`,
            top: `${pageY}px`,
        };
        const menu = (
            <div style={RightMenuStyle} className="TreeRightMenu" id="TreeRightMenu" >
                {selectNode.version ? "" :
                    <div onClick={() => this.clickRightMenu("addChild", idKey)} className="add"><Icon type="plus-circle" />  新增子节点</div>}
                {/* <div onClick={() => this.clickRightMenu("add", idKey)} className="add"><Icon type="plus-circle" />  新增同级节点</div> */}
                <Popconfirm
                    title="确定删除吗?"
                    onConfirm={() => this.clickRightMenu("del", idKey)}
                    onCancel={() => this.setState({ rightClickNodeTreeItem: null })}
                    okText="Yes"
                    cancelText="No"
                >
                    <div className="delete"><Icon type="delete" />  删除选中节点</div>
                </Popconfirm>
            </div>
        );
        return this.state.rightClickNodeTreeItem == null ? "" : menu;
    };

    addChildNode(_treeData, nodeKey, values) {
        let newtreeData = _treeData.map((node) => {
            if (node["key"] === nodeKey) {
                if (node["children"]) {
                    node["children"].push(values);
                } else {
                    node["children"] = [values];
                }
            } else if (node["children"]) {
                node["children"] = this.addChildNode(node["children"], nodeKey, values);
            }
            return node;
        });
        return newtreeData;
    }
    deleteMenu(_treeData, nodeKey) {
        let newtreeData = [];
        _treeData.filter((node) => {
            if (node["key"] === nodeKey) {

            } else if (node["children"]) {
                node["children"] = this.deleteMenu(node["children"], nodeKey);
                newtreeData.push(node);
            } else {
                return newtreeData.push(node);
            }
        });
        return newtreeData;
    }
    upDataSubmit() {
        // e.preventDefault();
        let _this = this;
        let { actionFlag, TreeData, nodeKey, selectNode } = this.state;
        let newTreeData = TreeData;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                values.parentKey = selectNode.key;
                _this.updataMenuData("AddMenu", values, newTreeData);
                console.log(newTreeData)

                _this.setState({ visible: false });

            }
        });
    }
    changeDataFun(_treeData, nodeObj) {
        let nodeKey = nodeObj["key"];
        let newTreeData = _treeData.map((node) => {
            if (node["key"] === nodeKey) {
                let newNode = nodeObj;
                if (node["children"]) {
                    newNode["children"] = node["children"]
                }
                return newNode;
            } else if (node["children"]) {
                node["children"] = this.changeDataFun(node["children"], nodeObj);
                return node;
            } else {
                return node;
            }
        });
        return newTreeData
    }
    changeTreeData(values) {
        let _this = this;
        let { TreeData, selectNode } = this.state;
        let newTreeData = this.changeDataFun(TreeData, values);
        console.log(newTreeData);
        values.parentKey = selectNode.parentKey;
        _this.updataMenuData("UpdateMenu", values, newTreeData);
    }
    render() {
        let { selectNode, expandedKeys, autoExpandParent, searchValue, visible } = this.state
        const { showIcon = true, checkable = false, onSelect } = this.props;
        const loop = (data, parentKey) =>
            data.map((item, menuIndex) => {
                const index = item.title.indexOf(searchValue);
                const beforeStr = item.title.substr(0, index);
                const afterStr = item.title.substr(index + searchValue.length);
                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span style={{ color: '#f50' }}>{searchValue}</span>
                            {afterStr}
                        </span>
                    ) : (
                            <span>{item.title}</span>
                        );
                if (item.children) {
                    return (
                        <TreeNode icon={<Icon type={item.icon || "file"} />} key={item.key} title={title} display={item.display}
                            titleName={item.title}
                            parentKey={parentKey}
                            interface={item.interface}
                            index={menuIndex}
                            iconName={item.icon}>
                            {loop(item.children, item.key)}
                        </TreeNode>
                    );
                }
                return <TreeNode icon={<Icon type={item.icon || "file"} />} key={item.key} title={title} display={item.display}
                    titleName={item.title}
                    parentKey={parentKey}
                    interface={item.interface}
                    index={menuIndex}
                    iconName={item.icon} />;
            });
        return (<div className="treeDiv">
            {/* <Row>
                    <Col span={8} > */}
            <Search style={{ marginBottom: 8 }} placeholder="Search" onChange={this.onChange} />
            <Tree
                showLine
                showIcon={showIcon}
                checkable={checkable}
                onSelect={(nodeKey, node) => {
                    // console.log(node);
                    const keyList = nodeKey[0] ? nodeKey[0].split("?") : [];
                    let selectNode = {
                        vendor: keyList[0] ? keyList[0] : "",
                        class: keyList[1] ? keyList[1] : "",
                        type: keyList[2] ? keyList[2] : "",
                        version: keyList[3] ? keyList[3] : "",
                    }
                    this.setState({ selectNode, rightClickNodeTreeItem: null })
                    onSelect(nodeKey, node);
                }}
                onRightClick={this.onRightClick}
                onExpand={this.onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                draggable
                blockNode
                onDragEnter={this.onDragEnter}
            // onDrop={this.onDrop}
            >
                {loop(this.state.TreeData, "")}
            </Tree>
            {/* </Col>
                    <Col span={16} onClick={() => this.setState({ rightClickNodeTreeItem: null })}>
                        <EditMenuForm selectNode={selectNode} onSubmit={(values) => { this.changeTreeData(values) }} />
                    </Col>
                </Row> */}
            {this.getNodeTreeRightClickMenu(selectNode)}
            <EditModal
                title="编辑设备信息"
                visible={visible}
                onCancel={() => this.setState({ visible: false })}
                onSubmit={(val) => {
                    this.saveDataSource(val);
                }}
                footer={null}
                width="55%"
                initialValues={selectNode}
                destroyOnClose
                formList={[
                    // { type: "input", label: "IP", name: "ip", required: true, placeholder: "IP" },
                    // { type: "input", label: "主机名", name: "name", required: true, placeholder: "主机名" },
                    { type: "input", label: "厂商", name: "vendor", required: true, placeholder: "厂商" },
                    // {
                    //     type: "select", name: "type", label: "类型", placeholder: "类型",
                    //     selectOptions: [{ name: "prometheus", value: "prometheus" }, { name: "zabbix", value: "zabbix" }]
                    // },
                    { type: "input", label: "类型", name: "class", required: true, placeholder: "类型" },
                    { type: "input", label: "型号", name: "type", required: true, placeholder: "型号" },
                    { type: "input", label: "版本", name: "version", required: true, placeholder: "版本" },
                ]}
            />
        </div>
        );
    }
}
const MenuClassObj = Form.create()(MenuClass)
export default MenuClassObj
