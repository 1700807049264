import React from 'react'
import {
    Card, Table, Switch, DatePicker, Button, Modal, notification, Icon, message,
    Input, Menu, Row, Col, Tabs, Tag, Tree, List
} from "antd";
import './index.scss'
import Socket from '@/socket/index'
import Utils from '@/utils/utils'
import Notification from '@/components/Notification/index';
import CharListArr from '@/components/CharList/index';
import moment from 'moment';
const Search = Input.Search;
const TabPane = Tabs.TabPane;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const { TreeNode } = Tree;
const treeData0 = [
    {
        title: '首页',
        key: '/home',
    }, {
        title: '任务管理',
        key: '/taskManger',
        children: [
            { title: '概览', key: '/overView' },
            { title: '历史', key: '/history' },
        ],
    }, {
        title: '配置管理',
        key: '/configManger',
        children: [{
            title: '资源',
            key: '/resource',
            children: [
                { title: '新建资源', key: '/newResource' },
                { title: '删除资源', key: '/delResource' },
            ],
        }, {
            title: '操作',
            key: '/operating',
            children: [
                { title: '新建操作', key: '/newOperating' },
                { title: '删除操作', key: '/delOperating' },
            ],
        }, {
            title: '任务',
            key: '/task',
            children: [
                { title: '新建任务', key: '/newTask' },
                { title: '删除任务', key: '/delTask' },
            ],
        }, {
            title: '系统',
            key: '/system',
        }],
    }, {
        title: '权限管理',
        key: '/permission',
        children: [
            {
                title: '用户', key: '/user',
                children: [
                    { title: '新建用户', key: '/newUser' },
                    { title: '删除用户', key: '/delUser' },
                    { title: '分配角色', key: '/dealRole' },
                ],
            },
            {
                title: '角色', key: '/role',
                children: [
                    { title: '新建角色', key: '/newRole' },
                    { title: '删除角色', key: '/delRole' },
                    { title: '分配权限', key: '/dealPermission' },
                ],
            },
        ],
    }
];
export default class Visible extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _pageSize: 10,    //当前每页显示条数
        visible: false,
        visible_char: false,
        loading: false,
        total: 0,
        CheckParamKey: ['/home'],
        charType: null,
        treeData: treeData0
    };

    componentWillMount() {
        this.updata();
    }
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {
            pageSize: pageSize || _pageSize,
            pageNum: pageNum || _current,
            querys: querys || {},
        };
        Socket.QueryDataByPage("QueryTasksPage",
            bodyJson, (result) => {
                console.log(result);
                if (result.retcode === '00') {
                    let tableData = result.tasks;
                    tableData.map((item, index) => {
                        item.key = index;
                    });
                    this.setState({
                        tableData,
                        _current: pageNum || 1,
                        total: result.total,
                        loading: false,
                    })
                } else {
                    Notification.error(result.error);
                }
            }, "/system");
    };
    showModal = (type) => {
        this.setState({
            visible: true,
            charType: type,
        });
    };
    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };
    handleOk = (e) => {
        this.setState({
            visible: false,
            visible_char: true,
        });
    };

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    };
    TabCallback = (key) => {
        console.log(key);
    };
    TimeOnChange = (date, dateString) => {
        console.log(date, dateString);
    };
    renderTreeNodes = data => data.map((item) => {
        if (item.children) {
            return (
                <TreeNode title={item.title} key={item.key} dataRef={item}>
                    {this.renderTreeNodes(item.children)}
                </TreeNode>
            );
        }
        return <TreeNode {...item} />;
    })
    render() {
        let { tableData, _current, _pageSize, loading, CheckParamKey,
            treeData, CharList, charType, visible_char } = this.state;

        const charDataColums = [
            {
                // title: '名称',
                // align:'left',
                dataIndex: 'taskName'
            }
        ];
        const rowSelection = {
            type: 'radio',
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
        };

        return (
            <div>
                <Card title="数据可视化" className="chart-card" >
                    <Row>
                        <Col span={4}>
                            <Button icon="line-chart" type="primary" onClick={() => this.showModal('line')} /><span>折线图</span>
                        </Col>
                        <Col span={4} >
                            <Button icon="bar-chart" type="primary" onClick={() => this.showModal('histogram')} /><span>柱状图</span>
                        </Col>
                        <Col span={4}>
                            <Button icon="pie-chart" type="primary" onClick={() => this.showModal('pie')} /><span>饼 图</span>
                        </Col>
                    </Row>
                </Card>
                <Modal
                    title="数据选择-数据源"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={550}
                    okText="确认"
                    cancelText="取消"
                >
                    <Tabs defaultActiveKey="1"
                        tabPosition="left"
                        onChange={this.TabCallback}>
                        <TabPane tab="选择数据源" key="1">
                            <Search
                                placeholder="输入名称"
                                onSearch={(value) => {
                                    let body = { taskName: value };
                                    if (value.replace(/\s+/g, "") === '') {
                                        body = {};
                                    }
                                    this.updata(_pageSize, 1, body);
                                }}
                                enterButton={<Button type="primary" icon="search">搜索</Button>}
                                size="small"
                            />
                            <Table
                                rowSelection={rowSelection}
                                columns={charDataColums}
                                dataSource={tableData}
                                size="small"
                                loading={loading}
                                pagination={{
                                    current: _current, pageSize: _pageSize, total: this.state.total,
                                    onChange: (page, pageSize) => {
                                        //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                        this.updata(pageSize, page, {});
                                    }
                                }}
                                showHeader={false}
                            />
                        </TabPane>
                        <TabPane tab="数据指标" key="2">
                            <Tree
                                checkable
                                checkedKeys={CheckParamKey}
                                onCheck={(CheckParamKey, info) => {
                                    // console.log('onSelect', info);
                                    this.setState({ CheckParamKey });
                                }}
                            >
                                {this.renderTreeNodes(treeData)}
                            </Tree>
                        </TabPane>
                        <TabPane tab="时间区间" key="3">
                            {/*<RangePicker onChange={this.TimeOnChange} />*/}
                            <RangePicker
                                ranges={{
                                    '今天': [moment(), moment()],
                                    '本月': [moment().startOf('month'), moment().endOf('month')],
                                }}
                                showTime
                                format="YYYY/MM/DD HH:mm:ss"
                                onChange={this.TimeOnChange}
                            />

                        </TabPane>
                    </Tabs>
                </Modal>
                <Modal
                    title='数据图表'
                    visible={visible_char}
                    onCancel={() => { this.setState({ visible_char: false }); }}
                    footer={null}
                    width="95%"
                    destroyOnClose={true}
                    style={{ position: 'fixed', top: 25, left: 35, }}
                >

                    <div className="monitorDiv" >
                        <CharListArr charType={charType} />
                    </div>
                </Modal>
            </div>
        )
    }
}
