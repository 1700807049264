import React from "react";
import { FullScreenContainer } from '@jiaminghi/data-view-react'
import './index.scss'
import { fullExit, fullScreen, judgeIsSupportFull } from '../utils'
import { withRouter } from "react-router-dom";
import TopHeader from "./TopHeader";
import DigitalFlop from "./DigitalFlop";
import Histogram from "./Histogram";
import DashBoard from "./DashBoard";
import LineChart from "./LineChart";
import Cards from "./Cards";

class BigScreenDisplay extends React.Component {

    state = {
        isSupportFull: false,
        isFull: true,
        isHider: true,
    };

    componentDidMount() {
        window.addEventListener("resize", this.changeFullStatus);
        this.judgeIsSupportFull();
        fullScreen()
        // this.interval = setTimeout(() => this.tick(), 100);

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.changeFullStatus);
        clearInterval(this.interval);
    }

    // 判断当前浏览器是否支持全屏API
    judgeIsSupportFull = () => {
        let isSupportFull = judgeIsSupportFull();
        this.setState({ isSupportFull });
    };
    // 计算当前是否处于全屏
    changeFullStatus = () => {
        // 判断网页的高度或者宽度是否等于屏幕对应大小
        // true: 当前处于全屏状态
        // false: 当前不处于全屏状态
        let scalRatio = window.devicePixelRatio;
        let screenRatio = document.body.scrollHeight / Math.round((window.screen.height / scalRatio));
        if (
            // document.body.scrollHeight === window.screen.height &&
            // document.body.scrollWidth === window.screen.width
            // 网页的高度或者宽度进行缩放之后，是否等于屏幕对应的大小
            (document.body.scrollHeight === Math.round(window.screen.height / scalRatio.toFixed(2)) &&
                document.body.scrollWidth === Math.round(window.screen.width / scalRatio.toFixed(2))) ||
            (document.body.scrollHeight === Math.round(window.screen.height / (scalRatio / screenRatio).toFixed(2)) &&
                document.body.scrollWidth === Math.round(window.screen.width / (scalRatio / screenRatio).toFixed(2)))
        ) {
            this.setState({ isFull: true });
        } else {
            this.setState({ isFull: false });
        }
        // if (!this.state.isFull) {
        //     this.props.history.push("/home")
        // }

    };
    // click button
    handClick = () => {
        this.toFullExit()
        // this.state.isFull ? this.toFullExit() : fullScreen();
        console.log(JSON.stringify(this.state))
    };

    handleMouseOver = () => {
        this.setState({ isHider: !this.state.isHider })
    }

    tick = e => {
        fullScreen()
    }

    toFullExit = e => {
        fullExit()
        this.props.history.push("/home")
    }

    render() {
        let { isSupportFull, isHider } = this.state;

        if (!isSupportFull) {
            return null;
        }
        return (<div className='data-view'>
            <FullScreenContainer>
                <TopHeader />

                <div className="main-content">
                    <div className="block-top-content">
                        <div className="block-left-content">
                            <Cards />
                            <LineChart />
                        </div>
                        <div className="block-middle-content">
                            <DigitalFlop />
                            <Histogram />
                        </div>
                        <div className="block-right-content">
                            <Cards />
                            <LineChart />
                        </div>
                    </div>
                    <div className="block-bottom-content">
                        <DashBoard />
                    </div>
                </div>
            </FullScreenContainer>
        </div>)
    }
}

export default withRouter(BigScreenDisplay)