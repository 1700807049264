import React from 'react'
import { Button, Card, Col, Form, Icon, Input, message, Modal, Row, Spin, Table, Tag, } from "antd";

import Axios from "../../axios/axios";
import CustomBreadcrumb from '../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import Utils from "../../utils/utils";
import GetTreeDataUtil from "../../utils/GetTreeDataUtil";
import TreeSelect from "antd/es/tree-select";
import Select from "antd/es/select";
import Popconfirm from "antd/es/popconfirm";
import axios from "axios";
const Option = Select.Option
class IpRecord extends React.Component {

    state = {
        _current: 1,
        _pageSize: 20,
        total: 0,
        loading: { spinning: false, tip: '正在操作，请稍后...' },
        tableData: null,
        ipsegment: {},
        treeData: {},
        visible: false,//设置地址分配弹窗不可见
        visible2: false,//分配后返回值
        selectTreeData: {},//下拉框树形数据
        ipSegments: [], //分配IP弹窗联动框用,
        spinning: false,//显示正在加载
        fenpeigei: "",
        confirmLoading: false,
        visible3: false,
        pingResult: { ip: '', result: false }
    }

    componentWillMount() {
        this.update();
        GetTreeDataUtil.getData(treeData => {
            this.setState({ treeData })
        })
    }


    /* 用户方法 */
    update = (pageSize, pageNum, querys) => {
        this.setState({ loading: { spinning: true }, })
        let { _pageSize, _current } = this.state
        let bodyJson = {}
        bodyJson.pageSize = pageSize || _pageSize
        bodyJson.pageNum = pageNum || _current
        bodyJson.querys = querys || {}

        let queryStr = 'ip/ipSearch/?'
        queryStr += bodyJson.pageNum ? '&page=' + bodyJson.pageNum : ''
        queryStr += bodyJson.pageSize ? '&page_size=' + bodyJson.pageSize : '&page_size=20'
        bodyJson.querys.status ? queryStr += '&status=' + bodyJson.querys.status : ''
        bodyJson.querys.ipsegment_start ? queryStr += '&ipSegment_start=' + bodyJson.querys.ipsegment_start : ''
        bodyJson.querys.organization_id ? queryStr += '&organization_id=' + bodyJson.querys.organization_id : ''


        let resolve = (result) => {
            let Resources = {}
            Resources = result.data.results;
            this.setState({
                tableData: Resources,
                _current: pageNum || 1,
                total: result.data.count,
                loading: { spinning: false },
            })
        }

        Axios.GET(queryStr, {}, resolve)
        GetTreeDataUtil.getData(data => {
            this.setState({ selectTreeData: data })
        })

    }

    /*弹窗方法*/
    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({ confirmLoading: true })
        this.props.form.validateFieldsAndScroll(async (err, value) => {
            if (!err) {
                // alert(JSON.stringify(value))
                let body = {}
                body.ipSegment = value.ipSegment || ""
                body.assign = value.assign || ""
                body.changeno = value.changeno || ""
                // body.gongdanhao = value.gongdanhao || ""
                // const {data: res, status} = await React.$http_ip.put("ip/ipAssign/" + body.id)

                Axios.PUT("ip/ipAssign/", body, res => {
                    let ip = JSON.parse(res.data).data
                    this.props.form.resetFields()
                    this.setState({
                        visible: false,
                        confirmLoading: false,
                        visible2: true,
                        assignIp: ip,
                        spinning: false,
                        fenpeigei: body.assign,
                    });
                }, res => {
                    message.error("IP分配失败！")
                })
            }
        })
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
            confirmLoading: false
        });
    };
    /*End弹窗方法*/

    /* 找网段的方法 */
    handleOnChange = value => {
        Axios.GET("ip/ipSegment/?&page=1&page_size=100000000000000&organization_id=" + value.toString(), {}, res => {
            this.props.form.setFieldsValue({
                ipSegment: ""
            })
            this.setState({ ipSegments: res.data.results })
        })
    };

    // ping方法
    pingOne = async id => {
        this.setState({ loading: { spinning: true, tip: '正在ping，请稍后...' } })
        const { data: res, status } = await React.$http_ip.get('ip/ping/' + id + '/')
        if (status !== 200) return message.error('ping失败')
        const pingResult = JSON.parse(res)
        this.setState({ pingResult, visible3: true, loading: { spinning: false } })
        this.update()
    }
    render() {
        const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
        let { _current, _pageSize, querys, ipSegments } = this.state
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 15 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 14 },
            },
        };

        const columns = [
            {
                title: '序号',
                width: 50,
                render: (text, item, index) => {
                    return <span>{index + 1 + (_current - 1) * _pageSize}</span>
                }
            },
            {
                title: 'IP',
                align: 'center',
                dataIndex: 'ip',
            }, {
                title: '网段',
                align: 'center',
                dataIndex: 'ipSegment_name',
            },
            {
                title: '分配到',
                align: 'center',
                dataIndex: 'assign',
            },
            {
                title: '状态',
                align: 'center',
                dataIndex: 'state',
                render: (text) => {
                    switch (text) {
                        case 0:
                            return (<Tag color={Utils.ColorShow('new')}>保留</Tag>)
                            break;
                        case 1:
                            return (<Tag color={Utils.ColorShow("success")}>未分配</Tag>)
                            break;
                        case 2:
                            return (<Tag color='yellow'>已分配</Tag>)
                            break;
                        case 3:
                            return (<Tag color='#f50'>在线(占用)</Tag>)
                            break;
                        default:
                            return (<Tag color={Utils.ColorShow("error")}>错误</Tag>)
                            break;
                    }
                }
            },
            {
                title: '操作',
                align: 'center',
                render: (text, item) => {
                    return (
                        <div>
                            <Popconfirm
                                title={'确定Ping操作吗？'}
                                onConfirm={(i) => {
                                    this.pingOne(item.id)
                                }}
                                // onCancel={() => { message.warn('取消ping') }}
                                okText={'确定'}
                                cancelText={'取消'}
                            >
                                <Button size='small' type='primary' style={{ backgroundColor: "green" }}
                                ><Icon type="thunderbolt" />Ping</Button></Popconfirm>&nbsp;

                            <Button type='primary' size='small' onClick={() => {
                                this.props.history.push(`/newIpSearch:${item.id}`);
                            }
                            }> <Icon type='form' /> 编辑</Button>
                        </div>
                    )
                }
            }
        ]

        const formList = [
            {
                label: '组织',
                type: '组织查询',
                field: 'organization_id',
                initialValue: '',
                tree_value: this.state.value,
                treeData: this.state.treeData,
                placeholder: '组织名称'
            }, {
                label: 'IP地址',
                type: 'INPUT',
                field: 'ipsegment_start',
                initialValue: '',
                placeholder: 'IP地址'
            }, {
                label: '状态',
                type: 'SELECT',
                field: 'status',
                initialValue: '',
                list: [
                    { id: '', name: '全部' },
                    { id: '0', name: '保留' },
                    { id: '1', name: '空闲' },
                    { id: '2', name: '已分配' },
                    { id: '3', name: '在线(占用)' }
                ],
                placeholder: 'IP状态'
            },
        ]
        return (
            <div>
                <CustomBreadcrumb arr={[{ title: '首页', to: '/home' }, 'IP管理', 'IP分配']} />
                <Card>
                    <FilterForm formList={formList}
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.update(this.state._pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.update(this.state._pageSize, 1, {})
                        }}
                    />
                </Card>

                <Card title={<span style={{ fontWeight: 600 }}>IP列表</span>}
                    style={{ marginTop: 8 }}
                    extra={
                        <Row>
                            <Button typeof='default' icon='reload' onClick={() => {
                                this.update()
                            }}>刷新</Button>
                            <Button type={'primary'} style={{ marginLeft: 8 }}
                                icon={"redo"} onClick={this.showModal}>
                                地址分配
                              </Button>
                        </Row>
                    }
                >
                    <Table
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.loading}
                        size="small"
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            showTotal: (total) => { return <span>共 {total} 项</span> },
                            pageSizeOptions: ["20", "50", "100", "500", "1000"],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                this.setState({ _pageSize: size });
                                this.update(size, 1, querys)
                            },
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                console.log(page, pageSize);
                                this.update(pageSize, page, querys)
                            }
                        }}
                    />
                </Card>

                {/*IP分配弹窗*/}
                <Modal
                    title="地址分配"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    confirmLoading={this.state.confirmLoading}
                    maskClosable={false}
                >
                    <Spin indicator={antIcon} spinning={this.state.spinning} />
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item label={"组织"} {...formItemLayout}>
                            {
                                getFieldDecorator('organization', {
                                    rules: [
                                        { required: true, message: "请选择组织" }
                                    ]
                                })(
                                    <TreeSelect
                                        defaultValue={this.state.defvalue}
                                        value={this.state.value}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        treeData={this.state.selectTreeData}
                                        placeholder="选择组织"
                                        treeDefaultExpandAll
                                        onChange={this.handleOnChange}
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item label={"网段"} {...formItemLayout}>
                            {
                                getFieldDecorator('ipSegment', {
                                    rules: [
                                        { required: true, message: "请选择网段" }
                                    ]
                                })(
                                    <Select>
                                        {ipSegments.map(item => (
                                            <Option key={item.id} value={item.id}>{item.name}</Option>
                                        ))}
                                    </Select>
                                )
                            }
                        </Form.Item>
                        <Form.Item label={"分配给"} {...formItemLayout}>
                            {
                                getFieldDecorator('assign', {
                                    rules: [
                                        { required: true, message: "请输入正确字符" },
                                        { max: 250, message: "不能超过250个字符" },
                                    ]
                                })(
                                    <Input placeholder='输入分配给谁用' />
                                )
                            }
                        </Form.Item>
                        {/*<Form.Item label={"工单号"} {...formItemLayout}>
                            {
                                getFieldDecorator('gogndanhao', {})(
                                    <Input />
                                )
                            }
                        </Form.Item>*/}
                        <Form.Item label={"交换机号"} {...formItemLayout}>
                            {
                                getFieldDecorator('changeno', {
                                    rules: [
                                        { type: 'string', message: '请输入正确的字符！' },
                                        { required: false, message: "交换机号不能为空" },
                                        { max: 30, message: "不能超过30个字符" },
                                    ]
                                })(
                                    <Input placeholder='交换机号' />
                                )
                            }
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal title="分配成功"
                    visible={this.state.visible2}
                    onOk={() => { this.setState({ visible2: false }) }}
                    onCancel={() => { this.setState({ visible2: false }) }}
                >
                    <Row>
                        <Col span={8}>{this.state.assignIp}</Col>
                        <Col span={8}><Tag color={"green"}>分配给</Tag></Col>
                        <Col span={8}>{this.state.fenpeigei}</Col>
                    </Row>
                </Modal>

                {/*显示ping以后的结果*/}
                <Modal
                    title="Ping的结果"
                    visible={this.state.visible3}
                    onOk={() => {
                        this.setState({ visible3: false, loading: { spinning: false } })
                    }}
                    onCancel={() => {
                        this.setState({ visible3: false, loading: { spinning: false } })
                    }}
                    width='45em'
                >
                    <div>
                        <Row>
                            <Col span={20}>{this.state.pingResult.ip}</Col>
                            <Col span={4}>{this.state.pingResult.result ? <Tag color='green'>连通</Tag> : <Tag color='red'>断开</Tag>}</Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        );
    }

}

const IpRecordForm = Form.create()(IpRecord);
export default IpRecordForm;

