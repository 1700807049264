import React from 'react'
import { 
    Card, Table, Button, message, Icon, Input, Row, Col, Form, Modal, Typography, Tooltip, Collapse
} from "antd";
import Socket from "@/socket/index";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import Utils from '@/utils/utils';
import DyHeadTable from '@/components/DyHeadTable/index';
import { connect } from 'react-redux'
import ResUtils from '@/utils/ResUtils';
import http from '@/axios/axios_utils';
import FirewallUtils from "@/utils/FirewallUtils";
import "./index.scss"
import { SubmitButton, FormItem, Radio, DatePicker, Select } from "formik-antd";
const FormikInput = require("formik-antd").Input;
const FormikForm = require("formik-antd").Form;
import { Formik } from "formik";
const { Text } = Typography;
const firewall_url = "http://" + backup_address + "/ZJNMS_V1/";

class FirewallConfig extends React.Component {
    state = {
        selectedRowKeys: [],
        selectedRows: [],
        visibleDev: false,
        querys: { "resourceParams.deviceType": "firewall" },
        screenSize: Utils.getScreenSize(),
        tableData: [],
        _pageSize: 20,
        _current: 1,
        total: 0,
        loading: false,
        firewallDevices: [],
        s_src_addr_type: "ip",
        s_dst_addr_type: "ip",
        d_src_addr_type: "ip",
        d_dst_addr_type: "ip",
        sp_data: [],
        snatData: [],
        dnatData: [],
        ruleName: "",
        dataFlag: false,
        formData: {},
    }
    componentWillMount() {

    }
    handleSubmit(values, callback) {
        const { selectedRowKeys } = this.state;
        if (selectedRowKeys.length === 0) {
            Modal.info({
                title: "消息提示",
                content: "请选择下发设备！",
                okText: "确定",
            })
            return
        }
        let bodyJson = {
            fw_ip: selectedRowKeys[0],
            username: sessionStorage.getItem("userName") + "/" + sessionStorage.getItem("nickName"),
            sp_data: {
                rule_name: values.rule_name,
                src_zone_list: values.src_zone_list,
                dst_zone_list: values.dst_zone_list,
                src_addr_list: values.src_addr_list,
                dst_addr_list: values.dst_addr_list,
                d_port_list: values.d_port_list,
                desc: values.desc,
            },
            snat_data: {
                nat_id: values.snat_id,
                trans_to_addr: values.s_trans_addr,
                src_addr: this.getNatData(values.s_src_addr_type, {
                    ip: values.s_src_addr_ip,
                    ips: values.s_src_addr_ips,
                    addr_group_name: values.s_src_addr_name,
                }),
                dst_addr: this.getNatData(values.s_dst_addr_type, {
                    ip: values.s_dst_addr_ip,
                    ips: values.s_dst_addr_ips,
                    addr_group_name: values.s_dst_addr_name,
                })
            },
            dnat_data: {
                nat_id: values.dnat_id,
                trans_to_addr: values.d_trans_addr,
                src_addr: this.getNatData(values.d_src_addr_type, {
                    ip: values.d_src_addr_ip,
                    ips: values.d_src_addr_ips,
                    addr_group_name: values.d_src_addr_name,
                }),
                dst_addr: this.getNatData(values.d_dst_addr_type, {
                    ip: values.d_dst_addr_ip,
                    ips: values.d_dst_addr_ips,
                    addr_group_name: values.d_dst_addr_name,
                })
            }
        }
        http.post(firewall_url + "fw_manage/fwConfiguration", bodyJson, (res) => {
            if(res.code === "00") {
                message.success(res.msg);
                this.setState({
                    selectedRowKeys: [],
                    selectedRows: [],
                    firewallDevices: [],
                    sp_data: [],
                    snatData: [],
                    dnatData: [],
                    ruleName: "",
                    dataFlag: false,
                })
                callback;
            } else {
                message.error(res.msg);
            }
        })
    };
    getTableData(page, pageSize, query) {
        this.setState({ loading: true });
        let { _pageSize, _current, querys } = this.state;
        let bodyQuery = query || querys;
        let bodyJson = {
            pageNum: page || _current,
            pageSize: pageSize || _pageSize,
            querys: bodyQuery,
        };
        Socket.ExecuteAction('QueryResourcesPage', bodyJson, "/queryResource", (result) => {
            let Resources = result.resources;
            Resources.map((item, index) => {
                item.key = index;
                item.index = index;
                return item
            });
            this.setState({
                tableData: Resources,
                _current: page || 1,
                total: result.total,
                loading: false,
                querys: bodyQuery
            })
        });
    }
    getNatData(natAddrType, natValues) {
        if(natAddrType === 'ip') {
            return {
                type: natAddrType,
                ip: natValues.ip
            }
        } else if(natAddrType === 'addr_group') {
            return {
                type: natAddrType,
                addr_group_name: natValues.addr_group_name,
                ips: natValues.ips,
            }
        }
    }
    getFwData(ip, name) {
        let { selectedRowKeys, ruleName } = this.state;
        let bodyJson = {
            fw_ip: ip || selectedRowKeys[0],
            rule_name: name || ruleName
        }
        http.get(firewall_url + "fw_manage/fwDataByRuleName", bodyJson, (res) => {
            if(res.code === "00") {
                let sp_data = res.data.sp_data;
                let snatData = res.data.snat_data;
                let dnatData = res.data.dnat_data;
                this.setState({
                    sp_data: res.data.sp_data,
                    snatData: res.data.snat_data,
                    dnatData: res.data.dnat_data,
                })
                if( (Array.isArray(sp_data) && sp_data.length !== 0) || (Array.isArray(snatData) && snatData.length !== 0) || (Array.isArray(dnatData) && dnatData.length !== 0) ) {
                    this.setState({ dataFlag: true })
                } else {
                    this.setState({ dataFlag: false })
                }
            } else {
                message.error(res.msg);
            }
        })
    }
    blurInput(e) {
        this.setState({ ruleName: e.target.value })
        let { selectedRowKeys } = this.state;
        if(selectedRowKeys.length !== 0) {
            this.getFwData(selectedRowKeys[0], e.target.value)
        }
    }
    selectDevices() {
        let { selectedRowKeys, ruleName, selectedRows } = this.state;
        this.setState({ visibleDev: false, firewallDevices: selectedRows });
        if(ruleName) {
            this.getFwData(selectedRowKeys[0], ruleName)
        }
    }
    reset() {
        let formData = {
            rule_name: '', src_zone_list: [], dst_zone_list: [], src_addr_list: [], dst_addr_list: [], d_port_list: [], desc: '',
            snat_id: '', s_trans_addr: '', s_src_addr_type: 'ip', s_src_addr_ip: '', s_src_addr_name: '', s_src_addr_ips: [],
                s_dst_addr_type: 'ip', s_dst_addr_ip: '', s_dst_addr_name: '', s_dst_addr_ips: [],
            dnat_id: '', d_trans_addr: '', d_src_addr_type: 'ip', d_src_addr_ip: '', d_src_addr_name: '', d_src_addr_ips: [],
                d_dst_addr_type: 'ip', d_dst_addr_ip: '', d_dst_addr_name: '', d_dst_addr_ips: [],
        }
        this.setState({ selectedRowKeys: [], selectedRows: [], firewallDevices: [], formData })
    };
    render() {
        const { selectedRowKeys, querys, visibleDev, selectedRows, screenSize, tableData, _pageSize, _current, sp_data, snatData, dnatData, dataFlag,
            loading, total, firewallDevices, s_src_addr_type, s_dst_addr_type, d_src_addr_type, d_dst_addr_type } = this.state;
        const columns = [...ResUtils.getColumns(10000, 1), {
            title: "操作",
            render: (a, item) => {
                return <Button type="danger" icon="delete" size="small" onClick={() => {
                    this.setState({ selectedRows: [], selectedRowKeys: [], firewallDevices: [] })
                }} />
            }
        }]
        let tempCol = ResUtils.getColumns(_pageSize, _current).slice(1, columns.length-1);
        const rowSelection = {
            type: "radio",
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys, selectedRows });
            },
            selections: [
                {
                    key: 'cencel',
                    text: '清空选择',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [], selectedRows: [], firewallDevices: [] });
                    },
                },
            ],
        };
        let scrollX = 0;
        columns.map((item, index) => {
            scrollX += item.width;
        })
        scrollX = scrollX + 260;

        console.log(dnatData, snatData, sp_data)
        return <div>
            <CustomBreadcrumb arr={["运维管理", "防火墙", { title: "防火墙策略下发", to: "/fireWallMan/FirewallConfig" }]} />
            <Card title={"防火墙策略下发"}>
                <Formik
                    onSubmit={(value, actions) => { this.handleSubmit(value, actions.resetForm()); actions.setSubmitting(false);  }}
                    validate={(values) => {
                        let info = {};
                        if(!values.rule_name) {
                            info.rule_name = "请填写安全策略名称"
                        }
                        return info
                    }}
                    initialValues={{
                        rule_name: '', src_zone_list: [], dst_zone_list: [], src_addr_list: [], dst_addr_list: [], d_port_list: [], desc: '',
                        snat_id: '', s_trans_addr: '', s_src_addr_type: 'ip', s_src_addr_ip: '', s_src_addr_name: '', s_src_addr_ips: [],
                            s_dst_addr_type: 'ip', s_dst_addr_ip: '', s_dst_addr_name: '', s_dst_addr_ips: [],
                        dnat_id: '', d_trans_addr: '', d_src_addr_type: 'ip', d_src_addr_ip: '', d_src_addr_name: '', d_src_addr_ips: [],
                            d_dst_addr_type: 'ip', d_dst_addr_ip: '', d_dst_addr_name: '', d_dst_addr_ips: [],
                    }}
                >
                    <FormikForm labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
                        <Row style={{ marginTop: 20, marginBottom: 30 }}>
                            <Col span={3} style={{ textAlign: 'right' }} >
                                <div className="ant-form-item-label">
                                    <label className="ant-form-item-required" >下发设备</label>
                                </div>
                            </Col>
                            <Col span={19}>
                                <div style={{ zoom: 0.9 }}>
                                    <Table
                                        columns={columns}
                                        dataSource={firewallDevices}
                                        pagination={false}
                                        locale={{ emptyText: <div><Icon type="inbox" />暂无数据</div> }}
                                        size="small"
                                        rowKey={(row) => row._id}
                                        scroll={{ y: screenSize.height * 0.2 }}
                                    />
                                </div>
                                <Button style={{ width: "100%", marginTop: 5 }} icon="plus-circle" type="dashed"
                                    onClick={() => {
                                        this.getTableData();
                                        this.setState({ visibleDev: true })
                                    }}>添加设备</Button>
                            </Col>
                        </Row>
                        {/* 安全策略 */}
                        <Text strong style={{ marginLeft: 40, fontSize: "18px" }}>安全策略：</Text>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    name={"rule_name"}
                                    label={"安全策略名称"}
                                    key={"rule_name"}
                                    required={true}
                                >
                                    <FormikInput onBlur={(e) => this.blurInput(e)} name={"rule_name"} placeholder={"安全策略名称"} allowClear />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    name={"src_zone_list"}
                                    label={"源安全域"}
                                    key={"src_zone_list"}
                                >
                                    <Select mode="tags" tokenSeparators={[',','，',' ']} name={'src_zone_list'} placeholder={"源安全域"} allowClear></Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    name={"dst_zone_list"}
                                    label={"目的安全域"}
                                    key={"dst_zone_list"}
                                >
                                    <Select mode="tags" tokenSeparators={[',','，',' ']} name={'dst_zone_list'} placeholder={"目的安全域"} allowClear></Select>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    name={"src_addr_list"}
                                    label={"源地址"}
                                    key={"src_addr_list"}
                                >
                                    <Select mode="tags" tokenSeparators={[',','，',' ']} name={'src_addr_list'} placeholder={"源地址"} allowClear></Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    name={"dst_addr_list"}
                                    label={"目的地址"}
                                    key={"dst_addr_list"}
                                >
                                    <Select mode="tags" tokenSeparators={[',','，',' ']} name={'dst_addr_list'} placeholder={"目的地址"} allowClear></Select>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    name={"d_port_list"}
                                    label={"目的端口"}
                                    key={"d_port_list"}
                                >
                                    <Select mode="tags" tokenSeparators={[',','，',' ']} name={'d_port_list'} placeholder={"目的端口"} allowClear></Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    name={"desc"}
                                    label={"描述"}
                                    key={"desc"}
                                >
                                    <FormikInput.TextArea autoSize={{ minRows: 2, maxRows: 4 }} name={'desc'} placeholder="描述信息" allowClear />
                                </FormItem>
                            </Col>
                        </Row>
                        <div style={{ display: "flex" }}>
                            {/* 源NAT */}
                            <div style={{ width: "50%" }}>
                                <Text strong style={{ marginLeft: 40, fontSize: "18px" }}>源NAT：</Text>
                                <Row>
                                    <Col span={24}>
                                        <FormItem
                                            name={"snat_id"}
                                            label={"源nat id"}
                                            key={"snat_id"}
                                        >
                                            <FormikInput name={"snat_id"} placeholder={"源nat id"} allowClear />
                                            <Tooltip title="创建新的nat时无需填写，若是修改时则必填">
                                                <Icon className="iconInfo" type="info-circle" />
                                            </Tooltip>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormItem
                                            name={'s_src_addr_type'}
                                            label={"源地址类型"}
                                            key={'s_src_addr_type'}
                                        >
                                            <Radio.Group name={'s_src_addr_type'} onChange={(e) => this.setState({ s_src_addr_type: e.target.value })}>
                                                <Radio name={'s_src_addr_type'} value={"ip"}>ip形式</Radio>
                                                <Radio name={'s_src_addr_type'} value={"addr_group"}>地址组</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    { s_src_addr_type === 'ip' ? <Col span={24}>
                                        <FormItem
                                            name={"s_src_addr_ip"}
                                            label={"源地址ip"}
                                            key={"s_src_addr_ip"}
                                        >
                                            <FormikInput name={"s_src_addr_ip"} placeholder={"源地址ip"} allowClear />
                                        </FormItem>
                                    </Col> :
                                    <>
                                        <Col span={24}>
                                            <FormItem
                                                name={"s_src_addr_name"}
                                                label={"源地址组名称"}
                                                key={"s_src_addr_name"}
                                            >
                                                <FormikInput name={"s_src_addr_name"} placeholder={"源地址组名称"} allowClear />
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem
                                                name={"s_src_addr_ips"}
                                                label={"源地址组ip"}
                                                key={"s_src_addr_ips"}
                                            >
                                                <Select mode="tags" tokenSeparators={[',','，',' ']} name={'s_src_addr_ips'} placeholder={"源地址组ip"} allowClear></Select>
                                            </FormItem>
                                        </Col>
                                    </>}
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormItem
                                            name={'s_dst_addr_type'}
                                            label={"目的地址类型"}
                                            key={'s_dst_addr_type'}
                                        >
                                            <Radio.Group name={'s_dst_addr_type'} onChange={(e) => this.setState({ s_dst_addr_type: e.target.value })}>
                                                <Radio name={'s_dst_addr_type'} value={"ip"}>ip形式</Radio>
                                                <Radio name={'s_dst_addr_type'} value={"addr_group"}>地址组</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    { s_dst_addr_type === 'ip' ? <Col span={24}>
                                        <FormItem
                                            name={"s_dst_addr_ip"}
                                            label={"目的地址ip"}
                                            key={"s_dst_addr_ip"}
                                        >
                                            <FormikInput name={"s_dst_addr_ip"} placeholder={"目的地址ip"} allowClear />
                                        </FormItem>
                                    </Col> :
                                    <>
                                        <Col span={24}>
                                            <FormItem
                                                name={"s_dst_addr_name"}
                                                label={"目的地址组名称"}
                                                key={"s_dst_addr_name"}
                                            >
                                                <FormikInput name={"s_dst_addr_name"} placeholder={"目的地址组名称"} allowClear />
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem
                                                name={"s_dst_addr_ips"}
                                                label={"目的地址组ip"}
                                                key={"s_dst_addr_ips"}
                                            >
                                                <Select mode="tags" tokenSeparators={[',','，',' ']} name={'s_dst_addr_ips'} placeholder={"目的地址组ip"} allowClear></Select>
                                            </FormItem>
                                        </Col>
                                    </>}
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormItem
                                            name={"s_trans_addr"}
                                            label={"源NAT转换后地址"}
                                            key={"s_trans_addr"}
                                        >
                                            <FormikInput name={"s_trans_addr"} placeholder={"源NAT转换后地址"} allowClear />
                                            <Tooltip title="填写ip形式，会根据安全策略名称生成地址组，如“SRC3488”或“DST3488”">
                                                <Icon className="iconInfo" type="info-circle" />
                                            </Tooltip>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ width: "50%" }}>
                                {/* 目的NAT */}
                                <Text strong style={{ marginLeft: 40, fontSize: "18px" }}>目的NAT：</Text>
                                <Row>
                                    <Col span={24}>
                                        <FormItem
                                            name={"dnat_id"}
                                            label={"目的nat id"}
                                            key={"dnat_id"}
                                        >
                                            <FormikInput name={"dnat_id"} placeholder={"目的nat id"} allowClear />
                                            <Tooltip title="创建新的nat时无需填写，若是修改时则必填">
                                                <Icon className="iconInfo" type="info-circle" />
                                            </Tooltip>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormItem
                                            name={'d_src_addr_type'}
                                            label={"源地址类型"}
                                            key={'d_src_addr_type'}
                                        >
                                            <Radio.Group name={'d_src_addr_type'} onChange={(e) => this.setState({ d_src_addr_type: e.target.value })}>
                                                <Radio name={'d_src_addr_type'} value={"ip"}>ip形式</Radio>
                                                <Radio name={'d_src_addr_type'} value={"addr_group"}>地址组</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    { d_src_addr_type === 'ip' ? <Col span={24}>
                                        <FormItem
                                            name={"d_src_addr_ip"}
                                            label={"源地址ip"}
                                            key={"d_src_addr_ip"}
                                        >
                                            <FormikInput name={"d_src_addr_ip"} placeholder={"源地址ip"} allowClear />
                                        </FormItem>
                                    </Col> :
                                    <>
                                        <Col span={24}>
                                            <FormItem
                                                name={"d_src_addr_name"}
                                                label={"源地址组名称"}
                                                key={"d_src_addr_name"}
                                            >
                                                <FormikInput name={"d_src_addr_name"} placeholder={"源地址组名称"} allowClear />
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem
                                                name={"d_src_addr_ips"}
                                                label={"源地址组ip"}
                                                key={"d_src_addr_ips"}
                                            >
                                                <Select mode="tags" tokenSeparators={[',','，',' ']} name={'d_src_addr_ips'} placeholder={"源地址组ip"} allowClear></Select>
                                            </FormItem>
                                        </Col>
                                    </>}
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormItem
                                            name={'d_dst_addr_type'}
                                            label={"目的地址类型"}
                                            key={'d_dst_addr_type'}
                                        >
                                            <Radio.Group name={'d_dst_addr_type'} onChange={(e) => this.setState({ d_dst_addr_type: e.target.value })}>
                                                <Radio name={'d_dst_addr_type'} value={"ip"}>ip形式</Radio>
                                                <Radio name={'d_dst_addr_type'} value={"addr_group"}>地址组</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    { d_dst_addr_type === 'ip' ? <Col span={24}>
                                        <FormItem
                                            name={"d_dst_addr_ip"}
                                            label={"目的地址ip"}
                                            key={"d_dst_addr_ip"}
                                        >
                                            <FormikInput name={"d_dst_addr_ip"} placeholder={"目的地址ip"} allowClear />
                                        </FormItem>
                                    </Col> :
                                    <>
                                        <Col span={24}>
                                            <FormItem
                                                name={"d_dst_addr_name"}
                                                label={"目的地址组名称"}
                                                key={"d_dst_addr_name"}
                                            >
                                                <FormikInput name={"d_dst_addr_name"} placeholder={"目的地址组名称"} allowClear />
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem
                                                name={"d_dst_addr_ips"}
                                                label={"目的地址组ip"}
                                                key={"d_dst_addr_ips"}
                                            >
                                                <Select mode="tags" tokenSeparators={[',','，',' ']} name={'d_dst_addr_ips'} placeholder={"目的地址组ip"} allowClear></Select>
                                            </FormItem>
                                        </Col>
                                    </>}
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormItem
                                            name={"d_trans_addr"}
                                            label={"目的NAT转换后地址"}
                                            key={"d_trans_addr"}
                                        >
                                            <FormikInput name={"d_trans_addr"} placeholder={"目的NAT转换后地址"} allowClear />
                                            <Tooltip title="填写ip形式，会根据安全策略名称生成地址组，如“SRC3488”或“DST3488”">
                                                <Icon className="iconInfo" type="info-circle" />
                                            </Tooltip>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </div>

                        </div>
                        
                        <div style={{ textAlign: "center", marginBottom: 10 }}>
                            <SubmitButton style={{ marginLeft: "10px" }} disabled={false} icon="check-circle">确定</SubmitButton>&nbsp;
                            {/* <Button icon="reload" onClick={() => this.reset()} >清空</Button>&nbsp; */}
                        </div>
                    </FormikForm>
                </Formik>
            </Card>
            <Modal
                title="选择设备"
                visible={visibleDev}
                width={"95%"}
                style={{ top: 10 }}
                onCancel={() => { this.setState({ visibleDev: false, }); }}
                onOk={() => { this.selectDevices() }}
            //不能添加destroyOnClose，会导致选择错误
            >
                <FilterForm
                    tagClass="resource"
                    filterSubmit={(querys) => {
                        this.getTableData(1, _pageSize, { "resourceParams.deviceType": "firewall", ...querys })
                    }}
                    resetTab={() => {
                        this.getTableData(1, _pageSize, { "resourceParams.deviceType": "firewall" })
                    }}
                />
                <div style={{ zoom: 0.9 }}>
                    <DyHeadTable
                        dataSource={tableData}
                        rowSelection={rowSelection}
                        columns={tempCol}
                        loading={loading}
                        rowKey={(text) => { return text.resourceParams.deviceIp }}
                        scroll={{ x: scrollX }}
                        size="small"
                        pagination={{
                            current: _current, pageSize: _pageSize, total: total,
                            onChange: (page, pageSize) => {
                                this.getTableData(page, pageSize, querys);
                            }
                        }}
                    />
                </div>
            </Modal>
            { dataFlag ? <Card style={{ marginTop: 20 }} title="相关数据">
                <Collapse defaultActiveKey={"securityPolice"}>
                    { Array.isArray(sp_data) && sp_data.length !== 0 ? 
                        <Collapse.Panel header="安全策略列表" key={"securityPolice"}>
                            <Table
                                dataSource={sp_data}
                                columns={[{
                                    title: '序号',
                                    width: 50,
                                    dataIndex: 'key',
                                    render: (text, item, index) => {
                                        return <span>{index + 1}</span>
                                    }
                                },...FirewallUtils.getPoliceColumns()]}
                                size="small"
                                rowKey={(text) => {return text._id}}
                                pagination={false}
                            />
                        </Collapse.Panel> : ''}
                    { Array.isArray(snatData) && snatData.length !== 0 ? 
                        <Collapse.Panel header="源nat列表" key={"snat"}>
                            <Table
                                dataSource={snatData}
                                columns={[...FirewallUtils.getSNatColumns()]}
                                size="small"
                                rowKey={(text) => {return text._id}}
                                pagination={false}
                            />
                        </Collapse.Panel> : ''}
                    { Array.isArray(dnatData) && dnatData.length !== 0 ? 
                        <Collapse.Panel header="目的nat列表" key={"dnat"}>
                            <Table
                                dataSource={dnatData}
                                columns={[...FirewallUtils.getDNatColumns()]}
                                size="small"
                                rowKey={(text) => {return text._id}}
                                pagination={false}
                            />
                        </Collapse.Panel> : ''}
                </Collapse>
            </Card> : ''}
        </div >
    }
}
const FirewallConfigForm = Form.create()(FirewallConfig)
export default connect()(FirewallConfigForm)