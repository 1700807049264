

import React from 'react'
import {
    Card, Table, Button, Icon, Input, message, notification, Select, Descriptions, Row, Col, Badge,
    Popconfirm, Tag, Modal, Upload, Spin,
} from "antd";
import socket from "@/socket/index";
import Utils from "@/utils/utils";
export default class BaseModal extends React.Component {
    state = {
        Vis: false,
    };
    componentWillReceiveProps(props) {
        if (props.content) {
            this.setState({ Vis: true })
        }
    }
    render() {
        let { Vis, } = this.state;
        const { btnName, title } = this.props;
        return (
            <Modal
                title="信息"
                visible={Vis}
                onCancel={() => this.setState({ Vis: false, })}
                onOk={() => this.setState({ Vis: false, })}
                afterClose={() => {

                }}
                destroyOnClose
                {...this.props}
            >
                {this.props.content}
            </Modal>

        )
    }
}
