/**
 * 
 */
import React, { Fragment } from 'react'
import MonacoEdit from 'react-monaco-editor'
import { Typography, Input, Table, Modal, Select, message, Button, Drawer, Tag, Tabs, Form, Checkbox, Row, Col } from 'antd';
import $ from 'jquery'
import './index.scss'
import Socket from "@/socket/index";
import Utils from "@/utils/utils";
import TableShow from "@/components/TableShow/index";
import ScriptModal from "@/components/ScriptModal/index";
import SelectForm from "@/components/SelectForm/index";
import GroupElemt from "@/components/ScriptModal/groupElemt";
import FilterForm from "@/components/FilterForm/index";
import TestOperating from "@/pages/config/operating/TestOperating";
const { Title } = Typography;
const Search = Input.Search;
const Option = Select.Option;

class OpScript extends React.Component {
    state = {
        operation: {},
        testVisible: false,
        checked: true,

    }
    componentWillMount() {
        console.log(this.props)
    }
    componentWillReceiveProps(props) {
        //
        const newOP = props.operation || {};
        const nowOP = this.state.operation;
        const StrNewOP = JSON.stringify(newOP);
        if (StrNewOP === JSON.stringify(nowOP) || JSON.stringify({ _id: '' }) === StrNewOP) {

        } else {
            console.log(props);
            this.props.form.resetFields();
            this.setState({ operation: newOP });
        }
    }
    ActionOpt(API, body) {
        Socket.ExecuteAction(API, body, "/newOperation", (resp) => {
            message.success('操作成功');
        });
    }
    getOutInSScript(body, val, tag) {
        let operIn = {}
        let operOut = {}
        let { operation, checked } = this.state;
        const _this = this;
        Socket.ExecuteAction("GetScriptParam", body, "/GetScriptParam", (resp) => {
            operIn.script = resp.in ? resp.in : [];;
            operIn.select = checked ? ["resource"] : [];
            operOut.script = resp.out ? resp.out : [];;
            if (resp.exit) { operation.operationExit = resp.exit };
            operation.operationIn = operIn;
            operation.operationOut = operOut;
            operation.operationType = val.operationType;
            operation.operationName = val.operationName;
            operation.operationScript = val.operationScript;
            console.log(resp);
            console.log(operation);

            if (tag === "edit") {
                if (operation._id) {//修改
                    _this.ActionOpt("UpdateOperation", operation);
                } else {//新增
                    _this.ActionOpt("AddOperation", operation);
                }
            } else if (tag === "test") {
                _this.setState({ operation, testVisible: true });
            } else if (tag === "ok") {

            }

            let objOper = operation;
            console.log(objOper);
            try {
                objOper.operationScript = JSON.parse(objOper.operationScript);
            } catch (e) {
                message.error("脚本有误！");
                return;
            }
            _this.props.onChangeOper(objOper);
        });
    }
    operaAction(tag) {
        const _this = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log(values);
                _this.getOutInSScript({ operationScript: values.operationScript, operationType: values.operationType }, values, tag);
            }
        })
    }
    render() {
        const { checked } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { operation } = this.state;
        const operationScript = operation.operationScript || {};
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 19 },
            }
        }
        return <div className="opScriptDiv">

            <Form onSubmit={this.onSubmit}  >
                <Form.Item label="是否选择资源"  {...{
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 5 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 5 },
                    }
                }} labelAlign="left"  >
                    <Checkbox checked={checked} onChange={(e) => this.setState({ checked: e.target.checked })} />
                </Form.Item>
                <Form.Item label="操作名称"  {...formItemLayout} labelAlign="left">
                    {
                        getFieldDecorator("operationName", {
                            initialValue: operation.operationName || "结点" + new Date().getTime(),
                            rules: [{ required: true, message: '请输入字符!', }]
                        })(<Input />)
                    }
                </Form.Item>
                <Form.Item label="操作类型"  {...formItemLayout} labelAlign="left">
                    {
                        getFieldDecorator("operationType", {
                            initialValue: operation.operationType || "cli",
                            rules: [{ required: true, message: '请选择!', }]
                        })(<Select>
                            <Option value="cli">cli</Option>
                            <Option value="linuxShell">linuxShell</Option>
                            <Option value="python">python</Option>
                            <Option value="perl">perl</Option>
                            <Option value="nodejs">nodejs</Option>
                            <Option value="system">system</Option>
                            <Option value="task">task</Option>
                        </Select>)
                    }
                </Form.Item>

                <Form.Item label="操作脚本"  >
                    {
                        getFieldDecorator("operationScript", {
                            initialValue: JSON.stringify(operationScript, null, 5),
                            rules: [{ required: true, message: '请输入字符!', }]
                        })(<MonacoEdit
                            language="json"
                            theme="vs-dark"
                            options={{ selectOnLineNumbers: false, lineNumbers: "off", minimap: { enabled: false } }}
                            height="500"
                        />)
                    }
                </Form.Item>
                <Form.Item>
                    <Button type="primary" icon="check" onClick={() => {
                        this.operaAction("ok");
                    }} >确定</Button>&nbsp;
                            <Button type="primary" icon="warning" onClick={() => {
                        this.operaAction("test");
                    }} ghost>操作测试</Button>&nbsp;
                            <Button type="primary" icon="check-circle" onClick={() => {
                        this.operaAction("edit");
                    }}>{Utils.isEmptyObject(operationScript) ? "新增" : "更改"}操作</Button>&nbsp;
                    <Button type="primary" icon="link" onClick={() => {
                        this.props.checkOper();
                    }}>绑定</Button>&nbsp;
                    <Button type="primary" icon="close-circle" onClick={() => {
                        this.props.onClose();
                    }}>关闭</Button>
                </Form.Item>
            </Form>
            <Modal
                title="测试"
                visible={this.state.testVisible}
                onCancel={() => { this.setState({ testVisible: false, }); }}
                footer={null}
                width="60%"
                destroyOnClose
            >
                <TestOperating slt_oper={operation} onRef={this.onRef}
                    closeWin={() => { this.setState({ testVisible: false }) }}
                />
            </Modal>
        </div>
    }
}

export default Form.create({})(OpScript)