import React, { useEffect, useState } from 'react'
import { Spin, Row, Col } from "antd";
import Socket from '@/socket/index'
import Utils from '@/utils/utils'
import PieChar from '@/components/PieChar/index2';
import Flower from '@/components/PieChar/index3';
import ColumnChart from '@/components/ColumnChart/index';
import ColumnChart2 from '@/components/ColumnChart/index2';
import LineChar from '@/components/LineChar/echart';
import SlicePie from '@/components/sliceShape/index';
function DataMap(props) {
    const [data1, setData1] = useState([]);
    useEffect(() => {//componentDidMount
        getData()
        let _timer = setInterval(getData, props.timer || 60000);
        return () => clearInterval(_timer)
    }, []);
    function tranl(text) {
        let name = text;
        if (text === "switch") {
            name = "交换机";
        } else if (text === "firewall") {
            name = "防火墙";
        } else if (text === "router") {
            name = "路由器";
        } else if (text === "online") {
            name = "在线";
        } else if (text === "offline") {
            name = "离线";
        }
        return name;
    }

    function getData() {
        let APINAME = "statisDeviceTypeNum"
        if (props.key1 == "dev_firm") {
            APINAME = "statisBrandTypeNum"
        }
        if (props.key1 == "dev_line") {
            APINAME = "statisDeviceOnline"
        }
        Socket.ExecuteAction(APINAME,
            {}, "/", (r) => {
                const data = r.data.map((i) => {
                    let name = tranl(i._id);
                    return { name, value: i.count }
                })
                setData1(data);
            });
    }
    function getChar() {

        if (props.type === "pie") {
            return <PieChar data={data1} unit={props.unit} />
        } else if (props.type === "line") {
            return <LineChar data={data1} unit={props.unit} />
        } else if (props.type === "ring") {
            return <SlicePie data={data1} unit={props.unit} />
        } else if (props.type === "rings") {
            return <Rings data={level_data} unit={props.unit} />
        } else if (props.type === "column2") {
            return <ColumnChart2 data={data1} unit={props.unit} />
        } else {
            return <ColumnChart data={data1} unit={props.unit} />
        }
    }
    return <div className="card-item animated zoomIn" >
        <div className="card-header">
            <div>
                < img src="/image/arrow.svg" alt="" />
                <span style={{ marginLeft: 10, color: '#61ddff' }}>{props.description}</span>
            </div>
        </div>
        {getChar()}
    </div>
}
export default DataMap
