import React from 'react'
import { Select } from 'antd';
import Resource from '../../config/resource/index'
import FilterForm from '@/components/FilterForm/index';
import { Card, Table, Input, Button, Icon, message, Popconfirm, notification, Steps } from "antd";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import Utils from "../../../utils/utils";
import socket from "../../../socket";
import AxiosObj from "../../../axios";
let FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
// 监控告警后端服务器地址 monitor_address 配置地址：public/flow/config.js
const backUp_Url = monitor_address;
export default class AddDevice extends React.Component {
    state = {
        stepCurrent: 0,      //步骤计数
        _current: 1,      //当前页码数
        _pageSize: 10,    //每页显示条数
        querys: null,    //查询条件
        total: 0,
        mibs: [
            // "cisco","huawei"
        ],  //mibs 模块列表
        mib: '',  //选中的mib模块
        loading: false,
        visible_conn: false,
        selectedRowKeys: [],
        FileVis: false,
        ImportVis: false,
        detailModal: false,
        detailLoading: false,
        detailData: { reason: [] },
        fileName: "",
        cpuData: {},
        memoryData: {},
        cardData: {},
        onlineData: {},
        interData: {},
        resItem: {},
        WarnVis: false,
        selectedRows: [],
        deviceFirm: '',  //设备产商
    };
    componentWillMount() {
        FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
        let bodyJson = this.props.location.bodyJson || {};
        // console.log(bodyJson);
        this.updata(bodyJson.pageSize, bodyJson.pageNum, bodyJson.querys);
        this.getMibList();
    }
    //mibs库模块选择
    handleChange(value) {
        console.log(`selected ${value}`);
    }
    //设备列表
    updata = (pageSize, pageNum, querys) => {
        console.log(pageSize, pageNum, querys);
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        console.log(bodyJson);
        socket.ExecuteAction('QueryResourcesPage',
            bodyJson, "/queryResource", (result) => {
                let Resources = result.resources;
                Resources.map((item, index) => {
                    item.key = index;
                    item.index = index;
                    return item.id
                });
                this.setState({
                    tableData: Resources,
                    _current: pageNum || 1,
                    total: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
            });
    };

    //获取mibs模块列表
    getMibList = () => {
        AxiosObj.MA_HTTP_GET(backUp_Url + "/snmpexporter/mibList", (response) => {
            console.log(response)
            //console.log(response.data)
            //
            let mibList = response.data;
            //console.log(mibList);
            let list = [];
            for (let i in mibList) {
                for (let j in mibList[i]) {
                    list[i] = j;
                }
            };
            this.setState({
                mibs: list,
            })
            console.log(list);
            // 注释 callback(response.data)
        }, (error) => {
            message.error(error);
        })

    }

    //添加监控设备
    addDevice = () => {
        let { selectedRows, mib } = this.state;
        if (!selectedRows[0]) {
            message.error("请选择设备!");
            return;
        } else if (!mib) {
            message.error("请选择mib库模块!");
            return;
        }
        let device = {};
        device['name'] = selectedRows[0].resourceName;
        device['ip'] = selectedRows[0].resourceParams.deviceIp;
        device['module'] = mib;
        device['detail'] = selectedRows[0].resourceParams.deviceId;
        //POST 方法第一个参数是与axios中地址的选择
        AxiosObj.HTTP_POST('alarm', '/snmpexporter/addDevice', device, (data) => {
            message.success('success');
            this.props.history.goBack();
        }, (error) => {
            message.error(error);
        });

    }


    render() {
        let { _current, _pageSize, querys, mibs, stepCurrent } = this.state;
        const columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                render: (text, item, index) => { return <span>{text + 1}</span> }
            }, {
                title: '资源名称',
                align: 'center',
                dataIndex: 'resourceName'
            },
            {
                title: '设备IP',
                align: 'center',
                dataIndex: 'resourceParams.deviceIp',
                render: (text, item, index) => {
                    return Utils.Translation(text);
                }
            },
            {
                title: '设备厂商',
                align: 'center',
                dataIndex: 'resourceParams.deviceFirm'
            },
            {
                title: '标签',
                dataIndex: 'tags',
                // width: "200px",
                align: "left",
                render: (text) => {
                    return Utils.hidenTag(text);
                }
            },
            // {
            //     title: '分组',
            //     dataIndex: 'groupNames',
            //     // width: "100px",
            //     align: "left",
            //     render: (text) => {
            //         return Utils.hidenTag(text);
            //     }
            // }
        ];
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                //找出设备厂商
                let deviceFirm = selectedRows[0].resourceParams.deviceFirm;
                if (mibs.indexOf(deviceFirm) >= 0) {
                } else {
                    console.log('没有符合的监控指标模块，请手动选择')
                    deviceFirm = '';
                }
                this.setState({
                    stepCurrent: 1,
                    selectedRowKeys: selectedRowKeys,
                    selectedRows: selectedRows,
                    deviceFirm: deviceFirm,
                    mib: deviceFirm
                });
            },
            selections: [
                {
                    key: 'cancel',
                    text: '清空选择',
                    onSelect: changableRowKeys => {
                        this.setState({ selectedRowKeys: [] });
                    },
                },
            ],
        };
        return (
            <div>
                <CustomBreadcrumb arr={["监控告警", "数据采集", "添加监控设备"]} />
                {/* <Card>
                    <FilterForm
                        tagClass="resource"
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(this.state._pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(this.state._pageSize, 1, {})
                        }}
                    />
                </Card> */}
                <Card title={<span style={{ fontWeight: 600 }}>新增监控设备</span>}>
                    <Steps direction="vertical" current={stepCurrent}>
                        <Steps.Step title="选择监控设备"
                            description={
                                <div>
                                    <FilterForm
                                        tagClass="resource"
                                        filterSubmit={(querys) => {
                                            this.setState({ querys })
                                            this.updata(this.state._pageSize, 1, querys)
                                        }}
                                        resetTab={() => {
                                            this.setState({ querys: {} })
                                            this.updata(this.state._pageSize, 1, {})
                                        }}
                                    />
                                    <Table
                                        dataSource={this.state.tableData}
                                        rowSelection={{
                                            type: 'radio',
                                            ...rowSelection
                                        }}
                                        columns={columns}
                                        loading={this.state.loading}
                                        rowKey={(text) => { return text._id }}
                                        size="small"
                                        pagination={{
                                            current: _current, pageSize: _pageSize, total: this.state.total,
                                            onChange: (page, pageSize) => {
                                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                                console.log(page, pageSize);
                                                this.updata(pageSize, page, querys);
                                            }
                                        }}
                                    />
                                </div>
                            }
                        />
                        <Steps.Step title="选择mib库模块"
                            description={
                                <p>选择mib库模块
                                <Select style={{ marginLeft: 8, width: 120 }} value={this.state.mib}
                                        onChange={(value) => {
                                            if (this.state.selectedRows[0]) {
                                                this.setState({ mib: value, stepCurrent: 2 })
                                            } else {
                                                message.error("请选择监控设备!")
                                            }
                                        }}
                                    >
                                        {mibs.map(item => (
                                            <Option key={item} value={item} label={item}>
                                                {item}
                                            </Option>
                                        ))}
                                    </Select>&nbsp;
                                </p>
                            }
                        />
                        <Steps.Step title="确认完成" description={
                            <div>
                                <Button type="primary" icon="check-circle"
                                    onClick={() => { this.addDevice(); }}>
                                    确认
                                </Button>&nbsp;
                                <Button icon="close-circle"
                                    onClick={() => { this.props.history.goBack(); }}>
                                    返回
                                 </Button>
                            </div>
                        } />
                    </Steps>
                </Card>




            </div>

        )
    }
}