import { Table, Tag, Radio, Modal, Button, Popconfirm, message } from 'antd';
import React from 'react';
import http from '@/axios/axios_utils';
import { GetModelCmdResp, GetDeviceCmdResp, devicecmdresp, modelcmdresp } from './api';

import CommandRespList from '../CommandRespInfo/index';


class CommandListTable extends React.Component {
  state = {
    selectItem: {},
    dataSource: [],
    respsData: [],
    comVisible: false,
    default_resp_id: "",
    cmd: "",
    selectCmdItem: {},
  };
  componentWillMount() {
    const { selectItem, refThis } = this.props;
    refThis(this);
    this.getCommList(selectItem || {});
    this.GetDeviceCmdResp(selectItem || {});
    this.setState({ selectItem })
  }
  componentWillReceiveProps(props) {
    const { selectItem } = props;
    const stateData = this.state.selectItem;
    if (JSON.stringify(stateData) === JSON.stringify(selectItem)) {

    } else {
      if (selectItem.ip) {
        this.GetDeviceCmdResp(selectItem || {});
      } else {
        this.getCommList(selectItem || {});
      }
      this.setState({ selectItem, dataSource: [] })
    }
  }
  getCommList(data) {
    http.get(GetModelCmdResp(), {
      class: data.class,
      type: data.type,
      vendor: data.vendor,
      version: data.version,
    }, (r) => {
      const dataSource1 = r.result || []
      // const { dataSource } = this.state;
      // this.setState({ dataSource: [...dataSource, ...dataSource1] });
      this.setState({ dataSource: dataSource1 });
    })
  }
  GetDeviceCmdResp(data) {
    http.get(GetDeviceCmdResp(), {
      ip: data.ip,
      class: data.class,
      type: data.type,
      vendor: data.vendor,
      version: data.version,
    }, (r) => {
      const dataSource1 = r.result || []
      // const { dataSource } = this.state;
      // this.setState({ dataSource: [...dataSource, ...dataSource1] });
      this.setState({ dataSource: dataSource1 });
    })
  }
  deleteCmd(cmd) {
    const { selectItem } = this.props;
    if (selectItem.ip) {//删除设备命令
      http.delete_body(devicecmdresp(), {
        ip: selectItem.ip,
        cmd,
      }, (r) => {
        message.success("删除成功!")
        this.GetDeviceCmdResp(selectItem || {});
      })
    } else {//删除模型命令
      http.delete_body(modelcmdresp(), {
        cmd,
        class: selectItem.class,
        type: selectItem.type,
        vendor: selectItem.vendor,
        version: selectItem.version,
      }, (r) => {
        message.success("删除成功!");
        this.getCommList(selectItem || {});
      })
    }
  }
  render() {
    const columns = [
      {
        title: '命令',
        dataIndex: 'cmd',
        key: 'cmd',
        align: "left",
        ellipsis: true,
        render: (text, item) => <a onClick={
          () => {
            this.setState({
              comVisible: true, respsData: item.resps || [],
              default_resp_id: item.default_resp_id,
              cmd: item.cmd,
              selectCmdItem: item
            })
          }
        }>{text}</a>,
      },
      {
        title: '默认返回',
        dataIndex: 'resps',
        align: "left",
        key: 'resps',
        ellipsis: true,
        render: text => <span>{text[0].name}</span>
      },
      // {
      //   title: '禁用',
      //   dataIndex: 'Enable',
      //   key: 'Enable',
      //   ellipsis: true,
      // },
      {
        title: '操作',
        key: 'action',
        width: 50,
        render: (text, record) => (
          <Popconfirm
            title="你确定删除当前命令吗?"
            onConfirm={() => { this.deleteCmd(record.cmd) }}
            okText="删除"
            cancelText="取消"
          >
            <Button type="link" size="small" icon="delete" />
          </Popconfirm>

        ),
      },
    ];
    const { dataSource, comVisible, respsData, default_resp_id, selectItem, cmd, selectCmdItem } = this.state;
    return (
      <div style={{ padding: "0 10px" }}>
        <Table
          columns={columns}
          pagination={false}
          dataSource={dataSource}
          size="small"
          scroll={{ y: 400 }}

        />
        <Modal
          title="命令返回"
          visible={comVisible}
          onCancel={() => this.setState({ comVisible: false })}
          footer={null}
          style={{ top: 5 }}
          width="95%"
          destroyOnClose
          className="CommandListTableModal"
        >
          <CommandRespList data={respsData}
            default_resp_id={default_resp_id}
            selectItem={selectItem}
            cmd={cmd}
            selectCmdItem={selectCmdItem}
            closeComVisible={() => this.setState({ comVisible: false })}
            callback={() => {
              this.getCommList(selectItem || {});
              this.GetDeviceCmdResp(selectItem || {});
              this.setState({ selectItem, dataSource: [], })
            }}
          />
        </Modal>
      </div>
    );
  }
}

export default CommandListTable;