import React from 'react'
import { Card, Table, Button, Icon, Input, message, notification, Popconfirm, Tag } from "antd";
import socket from "../../../socket/index";
import Utils from "../../../utils/utils";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import NewBasePage from '@/components/BasePage/newPage';
import FilterForm from '@/components/FilterForm/index';

export default class PortRecord extends React.Component {
    state = {
    };
    componentWillMount() {
    }
    render() {
        const columns = [
            {
                title: '标签名称',
                ellipsis: true,
                align: 'center',
                dataIndex: 'portTag'
            }, {
                title: '端口名称',
                ellipsis: true,
                align: 'center',
                dataIndex: 'portName',
            }, {
                title: '设备IP',
                align: 'center',
                dataIndex: 'deviceIp',
            }, {
                title: '输入信息',
                ellipsis: true,
                align: 'center',
                dataIndex: 'input',
            }, {
                title: '切换前信息',
                ellipsis: true,
                align: 'center',
                dataIndex: 'old',
            }, {
                title: '切换后信息',
                ellipsis: true,
                align: 'center',
                dataIndex: 'new',
            }, {
                title: '切换结果',
                align: 'center',
                dataIndex: 'result',
                render: (text) => {
                    // return (<span style={{color:Utils.ColorShow(text),fontWeight:'600'}}>{Utils.Translation(text)}</span>)
                    return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>)
                }
            }, {
                title: '添加时间',
                align: 'center',
                ellipsis: true,
                dataIndex: 'addTime'
            }, {
                title: '用户IP',
                align: 'center',
                ellipsis: true,
                dataIndex: 'userIP',
                render: () => {
                    return (<span>{sessionStorage.getItem("userIP") || ""}</span>)
                }
            }
        ];
        const FormList = [
            {
                label: "标签名称",
                type: 'INPUT',
                field: "portTag",
                initialValue: '',
                placeholder: "标签名称"
            },
        ]
        const BaseAPI = {
            columns, BaseAPI, FormList,
            BreadcrumbArr: ["端口管理", "端口记录"],
            QueryInterface: { action: "QueryHistorysPage", url: "null" },
            tableTitle: "端口记录列表",
        }
        return (
            <div>
                <NewBasePage {...BaseAPI} />
            </div>
        )
    }
}
