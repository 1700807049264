import React from 'react'
import { Divider, Row, Col, Input, Icon, Modal, message } from "antd";
import Axios from "../../../axios/axios";
export function addIpType(callBack) {
    let name = "";
    Modal.confirm({
        title: "添加ip分类",
        maskClosable: true,
        content: <div><Divider />
            <Row>
                <Col span={5}>分类名称:</Col>
                <Col span={19}><Input onChange={(e) => { name = e.target.value }} placeholder="ip分类" /></Col>
            </Row>
        </div>,
        icon: <Icon type="file-add" />,
        onOk() {
            Axios.POST('ip/ipType/', { name: name }, (res) => {
                let id = res.data.id
                if(res.status === 200 || res.status === 201 || res.status === 204) {
                    message.success("新增成功");
                    if (callBack) callBack(name, id);
                } else {
                    message.error("新增失败");
                }
            })
        }
    })
}
export function editIpType(ipName, ipId, callBack) {
    let name = ipName;
    Modal.confirm({
        title: "编辑ip分类",
        maskClosable: true,
        content: <div><Divider />
            <Row>
                <Col span={5}>分类名称:</Col>
                <Col span={19}><Input defaultValue={ipName} onChange={(e) => { name = e.target.value }} placeholder="ip分类" /></Col>
            </Row>
        </div>,
        icon: <Icon type="form" />,
        onOk() {
            Axios.PUT('ip/ipType/', { id: ipId, name: name }, (res) => {
                console.log(res);
                if(res.status === 200 || res.status === 201 || res.status === 204) {
                    message.success("修改成功");
                    if (callBack) callBack(name, res.data.id);
                } else {
                    message.error("修改失败");
                }
            })
        }
    })
}

export function delIpType(id, name, callBack) {
    Modal.confirm({
        title: '消息提示',
        content: '你确定删除ip分类 ' + name + " 吗?",
        okType: 'danger',
        onOk() {
            let options = {};
            options.url = "ip/ipType/" + id + "/";
            options.data = {};    
            Axios.DELETE(options, (result) => {
                if (result.status === 201 || result.status === 200 || result.status === 204) {
                    message.success("删除成功");
                    if (callBack) callBack(name);
                } else {
                    message.error("删除失败");
                }
                console.log(result)
            })
        },
    });
}