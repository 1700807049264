import React from 'react'
import Axios from "../axios/axios"

export default {

    convertTree(e) {
        // alert(JSON.stringify(e))
        e.map(item => {
            let child = item.children
            if (child !== undefined && item.children.length > 0) {
                this.convertTree(child)
            }
            item.key = item.id
            item.value = item.id
            item.title = item.name
            delete item["id"]
            delete item["name"]
            delete item["pid"]
        })

        return e
    },
    getData (callback) {
        let data
        Axios.GET("ip/organizationTree/", {}, (res) => {
            let treeData = res.data.data
            treeData = this.convertTree(treeData)
            callback(treeData)
        })

        return data
    }
}
