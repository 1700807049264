import React, { useEffect, useState } from 'react'
import Charts from "echarts-for-react"
function PieMap(props) {
    {/* <PieChar data={[{ name: "成功", value: 40 }, { name: "失败", value: 10 }, { name: "异常", value: 4 }]} /> */ }
    // const [data1, setData1] = useState(props.data || []);
    // useEffect(() => {//componentDidMount 
    //     return () => { //componentWillUnmount
    //     }
    // }, []);
    function getOptionn(data) {
        return {
            legend: {
                orient: "horizontal",
                right: 10,
                textStyle: {
                    color: "#7b7b7b"
                    // color: "#fff"
                },
                show: data.length < 5
            },
            tooltip: {
                trigger: 'item',
                // formatter: "{b} : {c} ({d}%)"
                formatter: (param) => {
                    const i_param = Array.isArray(param) ? param[0] : param
                    const u_parm = i_param.data
                    let strArr = '<div>'
                    for (let i in u_parm) {
                        strArr += `<div>${i}:${u_parm[i]}</div>`
                    }
                    return strArr + i_param.marker + "</div>"
                }
            },
            grid: {
                top: "1%",
                left: '3%',
                right: '3%',
                bottom: '2%',
                containLabel: true
            },
            color: ["#3fb1e3", "#6be6c1", "#a0a7e6", '#1890ff', "#ff5338", "#ff5384", "#4456c7", "#ff80a0", '#5ec9db', '#fdc765', '#f27d51', '#6462cc', '#91c7ae', '#749f83', '#f26659', "#c4ebad",],
            series: [
                {
                    type: 'pie',
                    radius: '80%',
                    label: {
                        show: true,
                        position: "outside",
                        formatter: "{b}:{c}\n({d}%)",
                        // color: "#fff",
                        color: "#7b7b7b",
                    },
                    // roseType: 'radius',
                    center: ['50%', '51%'],
                    data: data,
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },
                    labelLine: {
                        lineStyle: {
                            color: "#fff"
                        }
                    }
                }
            ]
        }
    }
    return <Charts option={getOptionn(props.data || [])} />
}
export default React.memo(PieMap)
