/**
 * 通用方法、组件
 */
import React, { memo } from 'react'
import {
    AutoComplete,
    Select,
    Input,
    FormItem
} from "formik-antd"
const { Option } = Select
export function getSelectOptionTime() {
    const content = [
        <Option value={"5s"} key={5}>5s</Option>,
        <Option value={"10s"} key={10}>10s</Option>,
        <Option value={"15s"} key={15}>15s</Option>,
        <Option value={"30s"} key={2}>30s</Option>,
        <Option value={"1m"} key={3}>1m</Option>,
        <Option value={"5m"} key={4}>5m</Option>,
    ]
    return content;
}
export function getFormAutoTime(name, label) {
    return <FormItem
        name={name}
        label={label}
        required
        hasFeedback
    >
        <Input name={name} placeholder={label} />
    </FormItem>;
}
export function getFormSelectTime(name, label,) {
    // const dataSource = ["5s", "10s", "15s", "30s", "1n", "5m", "m", "1m"]
    // const AlertForm = memo(
    //     (props) => { 
    //         return <AutoComplete
    //         // style={{ width: 200 }}
    //         name={name}
    //         dataSource={dataSource}
    //         placeholder="请选择"
    //         filterOption={(inputValue, option) =>
    //             option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    //         }
    //     />;
    //     })
    return <FormItem
        name={name}
        label={label}
        required
        hasFeedback
    >
        <Select name={name}>
            {getSelectOptionTime()}
        </Select>

    </FormItem>


}