

import React from 'react'
import {
    Card, Table, Button, Icon, Input, message, notification, Select, Descriptions, Row, Col, Badge,
    Popconfirm, Tag, Modal, Upload, Spin,
} from "antd";
import socket from "@/socket/index";
import Utils from "@/utils/utils";
import * as XLSX from 'xlsx';
import "./file.scss";
export default class FileModal extends React.Component {
    state = {
        FileVis: false,
        fileSpin: false,
        _fileName: null,
        InfoBack: [],
    };
    componentWillReceiveProps(props) {
        this.setState({ FileVis: props.FileVis })
    }
    listFile() {
        const _this = this;
        const { APIName, title, readFilesChange } = this.props;
        const nameArr = ["防火墙对应网段.xlsx", "防火墙通配符掩码配置.xlsx"]
        $("#fileInput").unbind();
        $("#fileInput").change(function () {
            let file = this.files[0];
            if (!file) { return; }
            let _fileName = file.name;
            if (APIName === "SwitchVlanByTags" && _fileName != "批量切换vlan.xlsx") {
                message.error("文件名应为批量切换vlan.xlsx");
                return;
            }
            if (title === "上传网段配置" && !nameArr.includes(_fileName)) {
                message.error("文件名应为防火墙对应网段.xlsx");
                return;
            }
            if (title === "上传通配符掩码配置" && !nameArr.includes(_fileName)) {
                message.error("文件名应为防火墙通配符掩码配置.xlsx");
                return;
            }
            $('.file_Div').html(_fileName + "上传中...");
            $('.file_Div').css("border", "1px dashed #124ae4");
            $('.file_Div').css("color", "#124ae4");
            _this.onUpload(_fileName);
            if (readFilesChange) {
                _this.readFilesChange2(file);
            }
        })
    }
    readFilesChange2(file) {
        const { readFilesChange } = this.props
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = {};
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {

                    let tempData = [];
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data[sheet] = tempData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    }
                }
                // 最终获取到并且格式化后的 json 数据
                if (readFilesChange) {
                    readFilesChange(data);
                }
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                console.log(e);
                message.error('文件类型不正确！');
            }
        }
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(file);
    }
    onUpload(_fileName) {
        const _this = this;
        let URLPath = 'http://' + socket.getAddress() + ':50034/file_upload';
        if (this.props.APIName === "UploadMIB") {
            URLPath = 'http://' + socket.getAddress() + ':50034/mib';
        }
        $('#fileform').ajaxSubmit({
            url: URLPath,
            type: 'post',
            async: true,
            data: $('#fileform').serialize(),
            dataType: "json",
            success: function (data) {
                if (data.message === "00") {
                    $('.file_Div').html(_fileName);
                    $('.file_Div').css("border", "2px dashed #87d068");
                    $('.file_Div').css("color", "#87d068");
                    if (_this.props.uploadCallBack) {
                        _this.props.uploadCallBack(_fileName);
                    }
                    message.success("上传成功");
                    _this.setState({ _fileName })
                } else {
                    message.error("上传失败！");
                }
            },
        });
    }
    onSumit() {
        const { _fileName } = this.state;
        const { APIName } = this.props;
        const _this = this;
        if (_fileName) {
            if (APIName) {
                _this.setState({ fileSpin: true })
                socket.ExecuteAction(APIName, { fileName: _fileName }, "/upfile",
                    (success) => {
                        let InfoBack = "";
                        if (APIName === "SwitchVlanByTags") {
                            if (success.data && success.data.result && success.data.result === "success") {
                                message.success("切换成功！");
                                InfoBack = <Tag color="#87d068"><Icon type="check-circle" />成功切换</Tag>
                            } else {
                                InfoBack = <div style={{ color: "red" }}><Tag color="red">失败</Tag>{JSON.stringify(success)}</div>
                                notification.open({
                                    message: "失败",
                                    description: JSON.stringify(success),
                                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                                });
                            }
                        } else {
                            message.success("导入成功！");
                        }
                        _this.setState({ fileSpin: false, InfoBack, })
                        if (_this.props.callback) {
                            _this.props.callback();
                        }
                    }, (error) => {
                        message.error("操作失败！" + error);
                        _this.setState({ fileSpin: false, InfoBack: <div style={{ color: "red" }}>{error}</div> })
                    })
            } else {
                _this.setState({ FileVis: false })
            }
        } else {
            message.error("请上传文件");
        }
    }
    InfoBack() {
        let { InfoBack } = this.state;
        return <div>{InfoBack}</div>
    }
    render() {
        let { FileVis, fileSpin } = this.state;
        const { btnName, title } = this.props;
        return (
            <Modal
                title={title}
                visible={FileVis}
                onCancel={() => this.setState({ FileVis: false, InfoBack: "" })}
                // onOk={() => this.onSumit()}
                afterClose={() => {
                    this.setState({ _fileName: null, fileSpin: false });
                    this.props.onCancel();
                }}
                footer={
                    // <Button onClick={() => this.setState({ FileVis: false })} key="1">
                    //     取消
                    // </Button>,
                    btnName ? [<Button type="primary" loading={fileSpin} onClick={() => this.onSumit()} key="2">
                        {btnName}
                    </Button>] : null
                }
                destroyOnClose
            >
                <Spin spinning={fileSpin}>
                    <h3>文件上传：</h3>
                    <form method="post" id="fileform" encType="multipart/form-data">
                        <input type="file" name="image" size="50" id="fileInput"
                            accept=".csv,.zip, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{ display: "none" }} />
                        <div className="file_Div" onClick={() => {
                            $("#fileInput").click();
                            this.listFile();
                        }}>  --------点击上传文件--------
                        </div>
                    </form>
                    <p></p>
                    {this.InfoBack()}
                </Spin>
            </Modal>

        )
    }
}
