/**
 * 屏蔽策略
 */
import React, { useState, useEffect } from 'react'
import {
    Table, Button, Icon, message, Divider, Popconfirm, Tag,
} from "antd";
const FormikSelect = require("formik-antd").Select;
import http from '@/axios/axios_utils';
import FormModal from '../utils/formModal';
import BaseForm from '../utils/baseForm';
import { alarmBlockRules, alarmBlockRule, alarmRules } from '../utils/api';

import { connect } from 'react-redux'
import moment from 'moment';
import "./index.scss"
import "animate.css"

function Shield(props) {
    const [visible, setVisible] = useState(false);
    const [total, setTotal] = useState(0);
    const [_pageSize, set_pageSize] = useState(20);
    const [_current, set_current] = useState(1);
    const [_querys, set_querys] = useState({});
    const [Name_id_Obj, setName_id_Obj] = useState({});
    const [selectItem, setSelectItem] = useState(null);
    const [selectedRowKeys, setRowKeys] = useState([])
    const [tableData, setTabData] = useState([])
    const [alarmRulesData, setRuleData] = useState([])
    const [radioValue, setRadioValue] = useState(0);
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (Keys) => {
            setRowKeys(Keys);
        },
        selections: [
            {
                key: 'cencel',
                text: '清空选择',
                onSelect: () => { setRowKeys([]); },
            },
        ],
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'ip',
            ellipsis: true,
            width: 40,
            render: (text, item, c) => { return c + 1 + (_current - 1) * _pageSize }
        },
        {
            title: '名称',
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '屏蔽类型',
            dataIndex: 'blockType',
            ellipsis: true,
            render: (text, item) => {
                switch (text) {
                    case 0: return "一次";
                    case 1: return "每天";
                    case 2: return "每周";
                }
            }
        },
        {
            title: '屏蔽时间',
            ellipsis: true,
            render: (text, item) => {
                switch (item.blockType) {
                    case 0: return <span>{item.startTime}~{item.endTime}</span>
                    case 1: return <span>{item.dayStartTime}~{item.dayEndTime}</span>
                    case 2: return <>
                        <p style={{ margin: 0 }}>{item.weekList.map(week => {
                            return <span>{weekRepalce(week)} </span>
                        })}</p>
                        <span>{item.dayStartTime}~{item.dayEndTime}</span>
                    </>;
                }
            }
        },
        {
            title: '屏蔽对象',
            dataIndex: 'blockObject',
            algin: "left",
            ellipsis: true,
            render: (text, item) => {
                return <div>
                    <div>{(text.ip_address || []).map((ip, key1) => (<Tag key={key1} color="geekblue">{ip}</Tag>))}</div>
                    <div style={{ marginTop: 2 }}>{(text.alarmRuleIds || []).map((id, key2) => (<Tag key={key2} color="purple">{Name_id_Obj[id]}</Tag>))}</div>
                </div>
            }
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            ellipsis: true,
        },
        {
            title: '操作',
            render: (text, item) => {
                return <div>
                    <a onClick={() => {
                        item.rangeTime = (item.startTime && item.endTime) ? [
                            moment(item.startTime),
                            moment(item.endTime)
                        ] : []
                        item.startDayTime = item.dayStartTime ? moment(item.dayStartTime, 'HH:mm:ss') : '';
                        item.endDayTime = item.dayEndTime ? moment(item.dayEndTime, 'HH:mm:ss') : '';
                        item.weekList = item.weekList || undefined
                        setSelectItem(item);
                        setRadioValue(item.blockType)
                        setVisible(true)
                    }}><Icon type="edit" />&nbsp;编辑</a>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="你确定删除所选记录吗?"
                        onConfirm={() => { deleteData([item.alarmBlockRuleId]) }}
                        okText="是"
                        cancelText="否"
                    >
                        <a><Icon type="delete" />&nbsp;删除</a>
                    </Popconfirm>
                </div>
            }
        },
    ]
    useEffect(() => {//componentDidMount; 
        http.setDispatch(props.dispatch);
        getData();
        getAlarmRuleData();
        return () => { //componentWillUnmount
        }
    }, []);
    function getData(page = 1, size = _pageSize, query = {}) {
        http.get(alarmBlockRules(), { page, size, ...query }, (r) => {
            setTabData(r.data.records);
            setTotal(r.data.total);
            set_querys(query);
            set_current(page);
            set_pageSize(size);
        })
    }
    function getAlarmRuleData(page = 1, size = 9999, query = {}) {
        http.get(alarmRules(), { page, size, ...query }, (r) => {
            const tempObj = {}
            r.data.records.map((item) => {
                tempObj[item.ruleId] = item.ruleName;
            });
            setRuleData(r.data.records);
            setName_id_Obj(tempObj);
        })
    }
    function saveDataSource(val) {
        const { alarmBlockRuleId } = selectItem;
        const rangeTime = val.rangeTime;
        val.creator = sessionStorage.getItem("userName");
        // 传参
        let bodyJson = {
            blockObject: val.blockObject,
            blockType: val.blockType,
            creator: sessionStorage.getItem("userName"),
            name: val.name,
            reason: val.reason,
        };

        if (val.blockType === 0) {
            // 一次
            bodyJson.startTime = rangeTime && moment(rangeTime[0]).format("YYYY-MM-DD HH:mm:ss");
            bodyJson.endTime = rangeTime && rangeTime[1] && moment(rangeTime[1]).format("YYYY-MM-DD HH:mm:ss");
        } else if (val.blockType === 1 || val.blockType === 2) {
            // 每天 & 每周 时间
            bodyJson.dayStartTime = val.startDayTime ? moment(val.startDayTime).format("HH:mm:ss") : '';
            bodyJson.dayEndTime = val.endDayTime ? moment(val.endDayTime).format("HH:mm:ss") : '';
            // 每周
            if (val.blockType === 2) {
                bodyJson.weekList = val.weekList
            }
        }
        delete val.rangeTime;
        delete val.startDayTime;
        delete val.endDayTime;
        if (alarmBlockRuleId) {//修改
            http.put(alarmBlockRule(), { alarmBlockRuleId, ...bodyJson }, () => {
                getData();
                message.success("修改成功");
            })
        } else {//新增 
            http.post(alarmBlockRule(), bodyJson, () => {
                getData();
                message.success("新增成功");
            })
        }
        setVisible(false)
    }
    function deleteData(alarmBlockRuleIds) {
        http.delete_body(alarmBlockRule(), { alarmBlockRuleIds }, () => {
            message.success("删除成功!");
            getData();
        })
    }
    function weekRepalce(dayKey) {
        switch (dayKey) {
            case 0: return '周一';
            case 1: return '周二';
            case 2: return '周三';
            case 3: return '周四';
            case 4: return '周五';
            case 5: return '周六';
            case 6: return '周天';
        }
    }
    return <div className="alertDiv">
        <div className="searchDiv">
            <BaseForm
                onSubmit={(val) => {
                    getData(1, _pageSize, val)
                }}
                onCancel={() => { getData() }}
                initialValues={{}}
                formList={[
                    { type: "input", name: "name", label: "名称", placeholder: "名称" },
                    { type: "input", name: "creator", label: "创建人" },
                ]}
            />
            <div>
                <Button type="primary" icon="file-add" onClick={() => {
                    // 新增初始化数据 必填项初始化，以触发表单校验提示
                    setSelectItem({ name: '', blockType: 0, weekList: undefined, rangeTime: undefined, startDayTime: undefined, endDayTime: undefined })
                    setVisible(true)
                }}>添加</Button>&nbsp;
                <Popconfirm
                    title="你确定删除所选记录吗?"
                    onConfirm={() => { deleteData(selectedRowKeys) }}
                    okText="是"
                    cancelText="否"
                ><Button type="danger" icon="delete" >删除</Button>
                </Popconfirm>

            </div>
        </div>
        <Table
            className='shieldTable'
            dataSource={tableData}
            rowSelection={rowSelection}
            columns={columns}
            size="small"
            rowKey={r => r.alarmBlockRuleId}
            pagination={
                {
                    current: _current, pageSize: _pageSize, total,
                    showTotal: (total) => { return <span>共 {total} 项</span> },
                    pageSizeOptions: ["20", "50", "100", "500", "1000"],
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) => {
                        getData(1, size, _querys)
                    },
                    onChange: (page, pageSize) => { getData(page, pageSize, _querys) }
                }
            }
        />
        <FormModal
            title="编辑屏蔽策略"
            width="50%"
            visible={visible}
            onCancel={() => { setVisible(false); setRadioValue(0); setSelectItem(null) }}
            onSubmit={(val) => {
                if (!val.reason) {
                    val.reason = "";
                }
                if (!val.blockObject) {
                    val.blockObject = {};
                    val.blockObject.ip_address = [];
                    val.blockObject.alarmRuleIds = [];
                }
                saveDataSource(val);
            }}
            footer={null}
            initialValues={selectItem}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            destroyOnClose
            forceRender
            formList={[
                { type: "input", name: "name", label: "名称", placeholder: "名称", required: true, },
                {
                    type: "expandForm", name: "blockObject", label: "屏蔽对象",
                    formContent: <div style={{ border: "1px solid #e2e2e2", padding: "5px 8px" }}>
                        <div><label>IP地址:</label><FormikSelect name="blockObject.ip_address" mode="tags" /></div>
                        <div><label>告警规则:</label>
                            <FormikSelect name="blockObject.alarmRuleIds" mode="multiple" filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                                {
                                    alarmRulesData.map((r, i) => (<FormikSelect.Option key={i} value={r.ruleId}>{r.ruleName}</FormikSelect.Option>))
                                }

                            </FormikSelect>
                        </div>
                    </div>

                },
                {
                    type: "radio", name: "blockType", label: "屏蔽类型", required: true, changeRadio: (value) => { setRadioValue(value) },
                    radioOptions: [
                        { label: '一次', value: 0 },
                        { label: '每天', value: 1 },
                        { label: '每周', value: 2 },
                    ]
                },
                radioValue === 2 ? {
                    type: "checkbox", name: "weekList", label: "重复日期", required: true, checkboxOptions: [
                        { label: '周一', value: 0 },
                        { label: '周二', value: 1 },
                        { label: '周三', value: 2 },
                        { label: '周四', value: 3 },
                        { label: '周五', value: 4 },
                        { label: '周六', value: 5 },
                        { label: '周天', value: 6 },
                    ]
                } : "",
                radioValue === 0 ? ({ type: "rangePicker", name: "rangeTime", label: "起止时间", required: true, }) :
                    { type: "timePicker", name: "startDayTime", label: "每日屏蔽开始时间", placeholder: "开始时间", required: true, },
                radioValue === 0 ? '' : { type: "timePicker", name: "endDayTime", label: "每日屏蔽结束时间", placeholder: "结束时间", required: true, },
                { type: "textArea", name: "reason", label: "原因", placeholder: "原因", }
            ]}
        />
    </div>
}

export default connect()(Shield)