import React from 'react'
import {
    Card, Table, Button, Icon, message, notification, Select, Descriptions, Row, Col, Popconfirm, Tag, Modal, Upload, Spin, Pagination, Input, Tabs, Badge, Tooltip
} from "antd";
import socket from "@/socket/index";
import http from '@/axios/axios_utils';
import Utils from "@/utils/utils";
import CustomBreadcrumb from '@/components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
import FileModal from './FileModal';
import ChangeModal from './ChangeModal';
import DownloadModal from './DownloadModal';
import MonacoEditor from 'react-monaco-editor';
import './index.scss'
import $ from "jquery"
const downloadList_Url = "http://" + socket.getAddress50034() + "/business/";
export default class PortManageSwitch extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _pageSize: 10,    //当前每页显示条数
        loading: false,
        FileVis: false,
        changeVlanVis: false,
        changeSingleVlanVis: false,
        lookConfigVis: false,
        downloadVis: false,
        fileVlanVis: false,
        peixianVlanVis: false,
        spinningSta: false,
        ModalSpin: false,
        vlanInfoVis: false,
        vlanconfigVis: false,
        fileName: null,
        querys: null,    //查询条件
        Tags: [],
        Groups: [],
        vlanData: [],
        tagInfoData: { reason: [] },
        resultState: 0,
        modeVlan: "access",
        errInfo: [],
        selectedRows: null,
        newTagName: "",
        changeDeviceInfo: {},
        tabName: [],
        configInfo:{},
        spinningLoading: false,
        tagName: "",
        tagItem: {},
        listData: [],
        devicesItemDetail: {},

    };
    componentWillMount() {
        // console.log(FixedBtn);
        this.updata();
    }
    componentDidMount() {
        this.onListenPageJump();
    }
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        socket.ExecuteAction("QueryPortTagsPage",
            bodyJson, "/QueryPortTagsPage", (result) => {
                let tableData = result.data;
                tableData.map((item, index) => { item.key = index; return item });
                this.setState({
                    tableData,
                    _current: pageNum || 1,
                    _pageSize: pageSize || _pageSize,
                    total: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };
    getVlanInfo = (tagName, item) => {//获取vlan信息
        if(tagName || item) {
            this.setState({ tagName: tagName, tagItem: item, devicesItemDetail: item.devices })
        }
        this.setState({ spinningSta: true })
        socket.ExecuteAction("QueryPortTagDetail",
            { tag: tagName || this.state.tagName }, "/QueryPortTagDetail", (result) => {
                this.getPortStatus(item || this.state.tagItem, result.data);
                const tagInfoData = result.data || {};
                this.setState({ tagInfoData, changeVlanVis: true, spinningSta: false })
            }, (error) => {
                this.setState({ spinningSta: false })
                this.NotificaFun("查询失败", error);
            });
        // this.setState({
        //     tagInfoData: {
        //         portTag: "portTag",
        //         portMode: "portMode",
        //         portVlanId: "portVlanId",
        //         portVlanName: "portVlanName",
        //         portVlanIp: "portVlanIp",
        //         devices: [{ deviceIp: "deviceIp", deviceFirm: "deviceFirm", deviceType: "deviceType", deviceVersion: "deviceVersion", deviceOsVersion: "deviceOsVersion", portName: "portName" }],
        //     }, changeVlanVis: true, spinningSta: false
        // })
    }
    getPortStatus(portData, tableData) {
        let bodyJson = {
            porttag: portData.portTag,
            devices: portData.devices
        }
        socket.ExecuteAction("QueryPortUpDownStatus",
            bodyJson, "/QueryPortUpDownStatus", (result) => {
                if(result.retcode === "00") {
                    Object.keys(result.data).map((item, index) => {
                        tableData.devices.map((device, key) => {
                            if(item.split("_")[0] === device.deviceIp && item.split("_")[1] === device.portName){
                                device.portStatus = result.data[item];
                            }
                        })
                    })
                    const tagInfoData = tableData || {};
                    this.setState({ tagInfoData, tableLoading: false })
                    // console.log("getPortStatus", result.data, tableData)
                }
            }, (error) => {
                this.setState({ spinningSta: false })
                this.NotificaFun("查询失败", error);
            });
    }
    NotificaFun(message, info) {//信息提示
        notification.open({
            message: message,
            description: info,
            icon: <Icon type="frown" style={{ color: 'red' }} />,
        });
    }
    getAllVlans = () => {//获取所有目标vlan
        const { tagInfoData, } = this.state;
        socket.ExecuteAction("QueryAllVlan",
            { tag: tagInfoData.portTag }, "/QueryAllVlan", (result) => {
                const vlanData = result.data;
                this.setState({ vlanData, })
            }, (error) => {
                this.NotificaFun("查询失败", error);
            });

        // this.setState({ vlanData: [{ vlan: "vlanName", IP: "IP" }, { vlan: "vlanName2", IP: "IP2" }] })
    }
    SwitchVlanByTag = () => {//单个
        const { targetVlan, modeVlan, tagInfoData } = this.state;
        this.setState({ vlanInfoVis: true })
        let newData = Utils.deepCopy(tagInfoData);
        socket.Action("SwitchVlanByTag",
            { tag: tagInfoData.portTag, mode: modeVlan, vlan: targetVlan },
            (ResResult) => {
                let resSta = 2;
                newData = ResResult.data || tagInfoData;
                if (ResResult.retcode === '00') {
                    const result = ResResult.data.result;
                    if (result && result === "success") {
                        message.success("切换成功！");
                        resSta = 1;
                    } else {
                        this.NotificaFun("切换失败!", "");
                    }
                } else {
                    newData.reason = [ResResult.error];
                    this.NotificaFun("切换失败!", ResResult.error);
                }
                this.setState({ tagInfoData: newData, resultState: resSta, vlanInfoVis: false })
            }, "/SwitchVlanByTag");
    }
    DeletePortTag(ID) {
        socket.ExecuteAction("DeletePortTag",
            { _id: ID }, "/DeletePortTag", () => {
                message.success("删除成功！");
                this.updata();
            }, (error) => {
                this.NotificaFun("删除失败", error);
            });
    }
    openModal(title, APIName, btnName) {
        const modalParam = {
            title,
            APIName,
            btnName,
        }
        this.setState({ modalParam, FileVis: true })
    }
    onListenPageJump() {
        let { pageSize, querys } = this.state;
        // console.log($(".ant-pagination-options-quick-jumper"));
        // console.log($(".ant-pagination-options-quick-jumper input"));
        $(".ant-pagination-options-quick-jumper input").unbind();
        $(".ant-pagination-options-quick-jumper input").blur((e) => {
            console.log(parseInt(e.target.value));
            let current = parseInt(e.target.value);
            this.updata(pageSize, current, querys);
        });
    }
    // 查看配置
    lookConfigInfo(item) {
        // 获取设备用户名密码
        const { devicesItemDetail } = this.state;
        let userPwd = {}
        devicesItemDetail.map((deviceItem, index) => {
            let resourceParams = deviceItem.deviceDetail.resourceParams
            if(item.deviceIp === resourceParams.deviceIp) {
                userPwd = {
                    devicePwd: resourceParams.devicePwd || "",
                    deviceUser: resourceParams.deviceUser || "",
                }
            }
        })
        let bodyJson = {
            deviceFirm: item.deviceFirm || "",
            deviceIp: item.deviceIp || "",
            portName: item.portName || "",
            portMode: item.portMode || "",
            portVlan: item.portVlan || "",
           ...userPwd
        }
        // console.log(bodyJson)
        this.setState({ lookConfigVis: true, spinningLoading: true })
        socket.ExecuteAction("QueryDevicePortConfig", bodyJson,"/QueryDevicePortConfig", (result) => {
            if(result.retcode === "00") {
                let resData = result.data;
                this.setState({
                    configInfo: resData,
                    tabName: Object.keys(resData),
                    spinningLoading: false
                })
            } else {
                message.error("加载失败")
            }
        })
    }
    //this.setState({ changeSingleVlanVis: true })
    SwitchVlanByTagsOne(item) {
        const { modeVlan, tagInfoData } = this.state;
        let bodyJson = {
            device: item,
            portTag: tagInfoData.portTag,
            mode: modeVlan,
        }
        this.setState({ changeSingleVlanVis:true, changeDeviceInfo: bodyJson })
    }

    // 获取文件名列表
    getListData() {
        http.get(downloadList_Url + "getDownloadFileList", {}, (res) => {
            if(res.retcode === "00") {
                this.setState({
                    listData: res.data,
                })
            }
        })
    }

    render() {
        let { _current, _pageSize, querys, changeVlanVis, changeSingleVlanVis, lookConfigVis, downloadVis, resultState, tabName, configInfo,
            tagInfoData, vlanData, spinningSta, spinningLoading, FileVis, vlanInfoVis, modalParam, total, changeDeviceInfo, listData } = this.state;
        const columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                render: (text) => { return <span>{text + 1}</span> }
            }, {
                title: '端口标签',
                align: 'center',
                dataIndex: 'portTag'
            }, {
                title: '设备信息',
                align: 'center',
                dataIndex: 'devices',
                render: (text) => {
                    return <Table
                        showHeader={false}
                        pagination={false}
                        size={"small"}
                        dataSource={text}
                        columns={[{
                            title: '设备IP',
                            align: 'center',
                            dataIndex: 'deviceIp'
                        }, {
                            title: '设备厂商',
                            align: 'center',
                            dataIndex: 'deviceFirm'
                        }, {
                            title: '设备类型',
                            align: 'center',
                            dataIndex: 'deviceType'
                        }, {
                            title: '设备版本号',
                            dataIndex: 'deviceVersion',
                        }, {
                            title: 'OS版本',
                            align: 'center',
                            dataIndex: 'deviceOsVersion',
                        }, {
                            title: '端口号',
                            align: 'center',
                            dataIndex: 'portName',
                        },]}
                    />
                }
            }, {
                title: '操作',
                width: '300px',
                align: 'center',
                render: (text, item) => {
                    return <div>
                        <Button type="primary" size="small" onClick={() => {
                            this.getVlanInfo(item.portTag, item);
                        }}>
                            <Icon type="arrow-right" />进入端口
                      </Button>&nbsp;
                        <Popconfirm
                            title="确定删除吗?"
                            onConfirm={() => {
                                this.DeletePortTag(item._id);
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" size="small" icon="delete" >删除</Button>
                        </Popconfirm>

                    </div>
                }
            }
        ];
        const FormList = [
            {
                label: "端口标签",
                type: 'INPUT',
                field: "tagName",
                initialValue: '',
                placeholder: "端口标签"
            },
            {
                label: "设备IP",
                type: 'INPUT',
                field: "deviceIp",
                initialValue: '',
                placeholder: "设备IP"
            },
            {
                label: "端口号",
                type: 'INPUT',
                field: "port",
                initialValue: '',
                placeholder: "端口号"
            },

        ]
        return (
            <div className="portManagement">
                <Spin spinning={spinningSta} style={{ zIndex: 9999 }}>
                    <CustomBreadcrumb arr={["端口管理", "交换机"]} />
                    <Card >
                        <FilterForm
                            formList={FormList}
                            filterSubmit={(querys) => {
                                this.setState({ querys })
                                this.updata(_pageSize, 1, querys)
                            }}
                            resetTab={() => {
                                this.setState({ querys: {} })
                                this.updata(_pageSize, 1, {})
                            }}
                        />
                    </Card>
                    <Card title={<span style={{ fontWeight: 600 }}>交换机列表</span>}
                        extra={<div>
                            <Button type="primary" icon="vertical-align-bottom" onClick={() => {
                                this.getListData();
                                this.setState({ downloadVis: true })
                            }}>下载配置文件</Button>
                            <Button type="primary" icon="import" onClick={() => {
                                this.openModal("上传VLAN配置", "LoadVlanTable", "导入配置");
                            }}>上传VLAN配置</Button>
                            <Button type="primary" icon="import" onClick={() => {
                                this.openModal("导入配线表", "LoadConfigTable", "开始导入");
                            }}>导入配线表</Button>
                            <Button type="primary" icon="retweet" onClick={() => {
                                this.openModal("批量切换", "SwitchVlanByTags", "开始切换");
                            }}>批量切换</Button></div>}
                        style={{ marginTop: 8 }} >
                        <Table
                            dataSource={this.state.tableData}
                            // dataSource={tempData}
                            // rowSelection={rowSelection}
                            columns={columns}
                            size="small"
                            loading={this.state.loading}
                            pagination={false}
                        // pagination={{
                        //     showQuickJumper: true,
                        //     current: _current, pageSize: _pageSize, total: total,
                        //     onChange: (page, pageSize) => {
                        //         console.log(page, pageSize);
                        //         //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                        //         this.updata(pageSize, page, querys);
                        //     },
                        // }}
                        />
                        <Pagination
                            style={{ float: "right", marginTop: 5 }}
                            showQuickJumper
                            current={_current}
                            total={total}
                            showTotal={(total) => { return <span>共 {total} 项</span> }}
                            pageSizeOptions={["10", "20", "50", "100"]}
                            showSizeChanger={true}
                            onShowSizeChange={(current, size) => {
                                this.updata(size, 1, querys);
                            }}
                            onChange={(page, pageSize) => {
                                this.updata(pageSize, page, querys);
                            }}
                        />
                    </Card>

                    {/* 切换整个端口的VLAN */}
                    <Modal
                        title="切换VLAN"
                        visible={changeVlanVis}
                        onCancel={() => this.setState({ changeVlanVis: false, resultState: 0, vlanInfoVis: false })}
                        onOk={() => this.setState({ changeVlanVis: false, vlanInfoVis: false })}
                        footer={null}
                        destroyOnClose
                        width="80%"
                        style={{ top: 20 }}
                    >
                        <div className="changeVlanDiv">
                            <Spin spinning={vlanInfoVis} tip="请稍候">
                                <Descriptions bordered size={"small"} column={1} title={ 
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <p>基本信息</p>
                                        <Button type="primary" icon="reload" style={{ marginRight: "73px" }} onClick={() => {
                                            this.getVlanInfo();
                                        }}>刷新端口信息</Button>
                                    </div>
                                }>
                                    <Descriptions.Item label="端口标签">{tagInfoData.portTag}</Descriptions.Item>
                                    <Descriptions.Item label="设备信息">
                                        <Table
                                            size={"small"}
                                            dataSource={tagInfoData.devices}
                                            columns={[
                                                { title: "设备IP", dataIndex: "deviceIp" },
                                                { title: "设备厂商", dataIndex: "deviceFirm" },
                                                { title: "端口号", dataIndex: "portName" },
                                                { title: "端口类型", dataIndex: "portMode" },
                                                { title: "VLAN", dataIndex: "portVlan" },
                                                { title: "端口状态", align: 'left', width: 70, dataIndex: "portStatus", render: (text, item) => {
                                                    let sta = "default";
                                                    let title = "状态未知";
                                                    if (item.portStatus === "up") {
                                                        sta = "success"; title = "up";
                                                    } else if (item.portStatus === "down") {
                                                        sta = "error"; title = "down";
                                                    }
                                                    if(text) {
                                                        return <div><Tooltip title={title}><Badge status={sta} /></Tooltip>{text}</div>
                                                    } else {
                                                        return <div><Tooltip title={title}><Icon type="question-circle" /></Tooltip>{text}</div>
                                                    }
                                                } },
                                                { title: "操作", width: 300 , render: (text) => {
                                                    return (
                                                        <div>
                                                            <Button type="primary" size="small" icon="edit" style={{ marginRight: 10 }} onClick={() => { this.lookConfigInfo(text) }}>查看配置</Button>
                                                            <Button type="primary" size="small" icon="swap" onClick={() => { this.SwitchVlanByTagsOne(text) }}>切换VLAN</Button>
                                                        </div>
                                                    )
                                                } },
                                            ]}
                                            pagination={false}
                                        />
                                    </Descriptions.Item>
                                </Descriptions>
                                <br /><hr />
                                <Row>
                                    <Col>
                                        <Descriptions title="参数选择" bordered size={"small"} column={1}>
                                            <Descriptions.Item label="端口类型">
                                                <Select defaultValue={this.state.modeVlan}
                                                    onChange={(value) => this.setState({ modeVlan: value })}
                                                    style={{ width: 300 }}>
                                                    <Select.Option key="access">access</Select.Option>
                                                    <Select.Option key="trunk">trunk</Select.Option>
                                                </Select>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="目标VLAN">
                                                {/* onDropdownVisibleChange */}
                                                <Select style={{ width: 300 }}
                                                    onFocus={() => this.getAllVlans()}
                                                    onChange={(value) => this.setState({ targetVlan: value })}
                                                >
                                                    {vlanData.map((itemVlan) => {
                                                        return <Select.Option key={itemVlan.vlan}>{itemVlan.vlan}</Select.Option>
                                                    })}
                                                </Select>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                    <Col>
                                        <Descriptions title="结果展示" bordered size={"small"} column={1}>
                                            <Descriptions.Item label="结果">
                                                {resultState === 1 ? <Tag color="#87d068"><Icon type="check-circle" />切换成功</Tag> :
                                                    (resultState === 2 ? <Tag color="#f50"><Icon type="close-circle" />切换失败</Tag> : '')}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="说明">
                                                {tagInfoData["reason"] ? tagInfoData["reason"].map((info, key) => {
                                                    return <p key={key}>{info}</p>
                                                }) : ''}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                                <br /><hr />
                                <div className="btn-div">
                                    <Button type="primary" ghost onClick={() => { this.setState({ changeVlanVis: false, resultState: 0 }) }}>取消</Button>
                                    <Button type="primary" icon="retweet" style={{ marginLeft: 10 }} onClick={() => {
                                        this.SwitchVlanByTag();
                                    }}>切换</Button>
                                </div>
                            </Spin>
                        </div>
                    </Modal>

                    <FileModal FileVis={FileVis}  {...modalParam}
                        onCancel={() => this.setState({ FileVis: false })} />
                    
                    {/* 单独切换VLAN */}
                    <ChangeModal
                        changeSingleVlanVis={changeSingleVlanVis}
                        changeDeviceInfo={changeDeviceInfo}
                        getAllVlans={() => {this.getAllVlans()}}
                        vlanData={vlanData}
                        onCancel={() => {this.setState({ changeSingleVlanVis: false })}}
                    />

                </Spin>

                <DownloadModal
                    downloadVis={downloadVis}
                    listData={listData}
                    onCancel={() => this.setState({ downloadVis: false, listData: [], })}
                />
                <Modal
                    title="查看配置"
                    visible={lookConfigVis}
                    onCancel={() => this.setState({ lookConfigVis: false, tabName: [], configInfo: {} })}
                    footer={null}
                    destroyOnClose
                    width="80%"
                >
                    <Spin spinning={spinningLoading} style={{ zIndex: 9999 }}>
                        {tabName.length != 0 ? <Tabs>
                            {tabName.map((item, index) => {
                                return (
                                    <Tabs.TabPane tab={item} key={index}>
                                        {/* <TextArea value={configInfo[item]} autoSize={{ minRows: 8, maxRows: 16 }} /> */}
                                        <MonacoEditor
                                            language="java"
                                            theme={"vs"}
                                            width="99%"
                                            height="300"
                                            value={configInfo[item].toString()}
                                        />
                                    </Tabs.TabPane>
                                )
                            })} 
                        </Tabs>: <div style={{ height: 100 }}></div>}
                    </Spin>
                    <br />
                    <div className="btn-div" style={{ textAlign: 'center', marginTop: 20 }}>
                        <Button type="primary" ghost onClick={() => { this.setState({ lookConfigVis: false, tabName: [], configInfo: {} }) }}>取消</Button>
                        <Button type="primary" style={{ marginLeft: 10 }} onClick={() => { this.setState({ lookConfigVis: false, tabName: [], configInfo: {} })}}>确定</Button>
                    </div>
                </Modal>
            </div >
        )
    }
}
