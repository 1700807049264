import React from 'react'
import { Card, Table, Button, Icon, Input, message, notification, Popconfirm, Tag, Modal, Spin, Tabs } from "antd";
import socket from "../../../socket/index";
import Utils from "../../../utils/utils";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import FilterForm from '@/components/FilterForm/index';
const TabPane = Tabs.TabPane;
export default class PlayRecord extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _current2: 1,    //当前页码数
        _pageSize: 10,    //当前每页显示条数
        _pageSize2: 10,    //当前每页显示条数
        loading: false,
        detailLoading: false,
        detailModal: false,
        detailData: [],
        querys: null,    //查询条件
        querys2: null,    //查询条件
    };
    componentWillMount() {
        this.updata();
        // this.updata2();
    }
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        socket.ExecuteAction("QueryFwAuditsPage",
            bodyJson, "/QueryFwAuditsPage", (result) => {
                let tableData = result.data;
                tableData.map((item, index) => { item.key = index; return item });
                this.setState({
                    tableData,
                    _current: pageNum || 1,
                    total: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };
    // updata2 = (pageSize, pageNum, querys) => {
    //     this.setState({ loading: true, });
    //     let { _pageSize2, _current2 } = this.state;
    //     let bodyJson = {};
    //     bodyJson.pageSize = pageSize || _pageSize2;
    //     bodyJson.pageNum = pageNum || _current2;
    //     bodyJson.querys = querys || {};
    //     socket.ExecuteAction("QueryFwAuditsByIpPage",
    //         bodyJson, "/QueryFwAuditsByIpPage", (result) => {
    //             let tableData2 = result.data;
    //             tableData2.map((item, index) => { item.key = index; return item });
    //             this.setState({
    //                 tableData2,
    //                 _current2: pageNum || 1,
    //                 total2: result.total,
    //                 loading: false,
    //             })
    //         }, (error) => {
    //             notification.open({
    //                 message: '查询失败',
    //                 description: error,
    //                 icon: <Icon type="frown" style={{ color: 'red' }} />,
    //             });
    //             this.setState({ loading: false, })
    //         });
    // };
    getPlayDetail(_ID) {
        this.setState({ detailLoading: true });
        socket.ExecuteAction("QueryFwAuditDetail",
            { _id: _ID }, "/QueryFwAuditDetail", (result) => {
                let detailData = result.data;
                this.setState({ detailData, detailLoading: false })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ detailLoading: false, })
            });
    }
    render() {
        let { _current, _pageSize, querys, tableData, loading, total,
            _current2, _pageSize2, querys2, tableData2, total2,
            detailModal, detailData, detailLoading } = this.state;
        const columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                render: (text) => { return <span>{text + 1}</span> }
            }, {
                title: '开始时间',
                align: 'center',
                dataIndex: 'beginTime',
            }, {
                title: '类型',
                align: 'center',
                dataIndex: 'type',
            }, {
                title: '目标App',
                align: 'center',
                dataIndex: 'targetApps',
            }, {
                title: '外援IP',
                align: 'center',
                dataIndex: 'sourceIps',
            }, {
                title: '敏感端口',
                align: 'center',
                dataIndex: 'sensitivePort',
            }, {
                title: '单号',
                align: 'center',
                dataIndex: 'serviceId',
            }, {
                title: '策略有效时间',
                align: 'center',
                dataIndex: 'period',
            }, {
                title: '状态',
                align: 'center',
                dataIndex: 'status',
                render: (text) => {
                    // return (<span style={{color:Utils.ColorShow(text),fontWeight:'600'}}>{Utils.Translation(text)}</span>)
                    return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>)
                }
            }, {
                title: '操作',
                align: 'center',
                render: (text, item) => {
                    return <Button type="primary" icon="form" onClick={() => {
                        this.getPlayDetail(item._id);
                        this.setState({ detailModal: true });
                    }}>详情</Button>
                }
            }
        ];
        const columns2 = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                render: (text) => { return <span>{text + 1}</span> }
            }, {
                title: '开始时间',
                align: 'center',
                dataIndex: 'beginTime',
            }, {
                title: '结束时间',
                align: 'center',
                dataIndex: 'endTime',
            }, {
                title: '类型',
                align: 'center',
                dataIndex: 'type',
            }, {
                title: '单号',
                align: 'center',
                dataIndex: 'serviceId',
            }, {
                title: '策略有效时间',
                align: 'center',
                dataIndex: 'period',
            }, {
                title: '状态',
                align: 'center',
                dataIndex: 'status',
                render: (text) => {
                    // return (<span style={{color:Utils.ColorShow(text),fontWeight:'600'}}>{Utils.Translation(text)}</span>)
                    return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>)
                }
            }, {
                title: '操作',
                align: 'center',
                render: (text, item) => {
                    return <Button type="primary" icon="form" onClick={() => {
                        this.getPlayDetail(item._id);
                        this.setState({ detailModal: true });
                    }}>详情</Button>
                }
            }
        ];
        const FormList = [
            {
                label: "类型",
                type: 'SELECT',
                field: "type",
                initialValue: '',
                list: [{ id: 'add', name: '增加' }, { id: 'delete', name: '删除' }]
            },
            {
                label: "单号",
                type: 'INPUT',
                field: "serviceId",
                initialValue: '',
                placeholder: "单号"
            },

        ]
        return (
            <div>
                <CustomBreadcrumb arr={["防火墙管理", "App操作记录"]} />
                <Card>
                    <FilterForm
                        formList={FormList}
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(_pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(_pageSize, 1, {})
                        }}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>APP操作记录列表</span>}
                    style={{ marginTop: 8 }}>
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        size="small"
                        loading={loading}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: total,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                this.updata(pageSize, page, querys);
                            }
                        }}
                    />
                    {/* <Tabs type="card" animated={true}>
                        <TabPane tab={"应用"} key={"1"}>
                            <Table
                                dataSource={tableData}
                                columns={columns}
                                size="small"
                                loading={loading}
                                pagination={{
                                    current: _current, pageSize: _pageSize, total: total,
                                    onChange: (page, pageSize) => {
                                        //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                        this.updata(pageSize, page, querys);
                                    }
                                }}
                            />
                        </TabPane>
                        <TabPane tab={"IP"} key={"2"}>
                            <Table
                                dataSource={tableData2 || []}
                                columns={columns2}
                                size="small"
                                loading={loading}
                                pagination={{
                                    current: _current2, pageSize: _pageSize2, total: total2,
                                    onChange: (page, pageSize) => {
                                        //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                        this.updata2(pageSize, page, querys2);
                                    }
                                }}
                            />
                        </TabPane>
                    </Tabs> */}

                </Card>
                <Modal
                    title="详情"
                    visible={detailModal}
                    onCancel={() => this.setState({ detailModal: false })}
                    onOk={() => this.setState({ detailModal: false })}
                    width="60%"
                    style={{ top: "20px" }}
                    destroyOnClose
                >
                    <Spin spinning={detailLoading}>
                        {detailData.map((item, key) => {
                            return <p key={key}>{item}</p>
                        })}
                    </Spin>
                </Modal>
            </div>
        )
    }
}
