import React from 'react'
import { Card, Table, Button, Icon, Input, message, notification, Popconfirm, Tag } from "antd";
import socket from "../../../socket/index";
import Utils from "../../../utils/utils";
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/index';
import SelectForm from '../../../components/SelectForm/index';

let FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
export default class PortManageTask extends React.Component {
    state = {
        _current: 1,    //当前页码数
        _pageSize: 10,    //当前每页显示条数
        loading: false,
        querys: null,    //查询条件
        Tags: [],
        Groups: [],
    };
    componentWillMount() {
        // console.log(sessionStorage.getItem('FixedBtn'));
        FixedBtn = JSON.parse(sessionStorage.getItem('FixedBtn')) || [];
        // console.log(FixedBtn);
        this.updata();
    }
    updata = (pageSize, pageNum, querys) => {
        this.setState({ loading: true, });
        let { _pageSize, _current } = this.state;
        let bodyJson = {};
        bodyJson.pageSize = pageSize || _pageSize;
        bodyJson.pageNum = pageNum || _current;
        bodyJson.querys = querys || {};
        socket.ExecuteAction("QueryTasksPage",
            bodyJson, "/taskQueryTask", (result) => {
                let tableData = result.tasks;
                tableData.map((item, index) => { item.key = index; return item });
                this.setState({
                    tableData,
                    _current: pageNum || 1,
                    total: result.total,
                    loading: false,
                })
            }, (error) => {
                notification.open({
                    message: '查询失败',
                    description: error,
                    icon: <Icon type="frown" style={{ color: 'red' }} />,
                });
                this.setState({ loading: false, })
            });
    };
    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };
    DeleteOperation = (taskId) => {
        let thisTemp = this;
        socket.ExecuteAction("DeleteTask",
            { _id: taskId }, "/delTask", (result) => {
                message.success('删除成功');
                thisTemp.updata();
            });
        this.setState({ visible: false });
    }
    cancel = () => {
        this.setState({ visible: false });
        message.error('删除取消');
    };

    render() {
        let { _current, _pageSize, querys } = this.state;
        const columns = [
            {
                title: '序号',
                align: 'center',
                dataIndex: 'key',
                render: (text) => { return <span>{text + 1}</span> }
            }, {
                title: '任务名称',
                align: 'center',
                dataIndex: 'taskName'
            }, {
                title: '状态',
                align: 'center',
                dataIndex: 'taskStatus',
                render: (text) => {
                    // return (<span style={{color:Utils.ColorShow(text),fontWeight:'600'}}>{Utils.Translation(text)}</span>)
                    return (<Tag color={Utils.ColorShow(text)}>{Utils.Translation(text)}</Tag>)
                }
            }, {
                title: '方式',
                align: 'center',
                dataIndex: 'taskPlan.type',
                render: (text) => {
                    return (<span>{Utils.Translation(text)}</span>)
                }
            }, {
                title: '创建时间',
                align: 'center',
                dataIndex: 'createTime'
            }, {
                title: '任务级别',
                align: 'center',
                dataIndex: 'level'
            }, {
                title: '标签',
                align: 'left',
                dataIndex: 'tags',
                render: (text) => {
                    return text.map((tag, key) => {
                        return <Tag key={key} color="blue">{tag}</Tag>
                    })
                }
            }, {
                title: '分组',
                align: 'left',
                dataIndex: 'groupNames',
                render: (text) => {
                    return text.map((groupNames, key) => {
                        return <Tag key={key} color="blue">{groupNames}</Tag>
                    })
                }
            }, {
                title: '任务配置',
                // width:'280px',
                align: 'center',
                render: (text, item) => {
                    return <div>
                        {item.menus.includes('/taskEditTask') ?
                            <Button type="primary" style={{ marginLeft: 5 }} onClick={() => {
                                this.props.history.push(`/newTask:${item._id}`);
                            }}>
                                <Icon type="form" />编辑
                      </Button> : ''}
                        <Button type="primary" icon="copy" style={{ marginLeft: 8 }} onClick={() => {
                            this.props.history.push(`/newTask:${item._id}&copy`);
                        }}>复制
                        </Button>
                        {item.menus.includes('/delTask') ?
                            <Popconfirm
                                title="确定删除吗?"
                                // visible={this.state.visible}
                                onConfirm={() => { this.DeleteOperation(item._id) }}
                                onCancel={this.cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="danger" icon='delete' style={{ marginLeft: 8 }} onClick={() => { }}>
                                    删除
                          </Button>
                            </Popconfirm> : ''}
                    </div>
                }
            }
        ];
        const condition = [
            { key: 'taskName', value: '任务名称' },
            { key: 'taskStatus', value: '任务状态' },
            { key: 'tags', value: '标签' },
            { key: 'groupNames', value: '分组' }];
        const conditionData = {
            taskStatus: {
                mode: "alone",
                data: [{ key: 'run', value: '执行中' }, { key: 'done', value: '完成' }, {
                    key: 'new',
                    value: '新建'
                }, { key: 'terminal', value: '终止' }]
            },
            taskName: "INPUT",
            tags: { mode: "tags", data: [{ key: '19楼', value: '19楼' }] },
            groupNames: { mode: "tags", data: [{ key: '19楼', value: '19楼' }] },
        };
        return (
            <div>
                <CustomBreadcrumb arr={["配置管理", "任务"]} />
                <Card>
                    <SelectForm conditionData={conditionData} condition={condition}
                        tagClass="task"
                        filterSubmit={(querys) => {
                            this.setState({ querys })
                            this.updata(this.state._pageSize, 1, querys)
                        }}
                        resetTab={() => {
                            this.setState({ querys: {} })
                            this.updata(this.state._pageSize, 1, {})
                        }}
                    />
                </Card>
                <Card title={<span style={{ fontWeight: 600 }}>任务列表</span>}
                    style={{ marginTop: 8 }}
                    extra={
                        <div> 
                            {FixedBtn.includes("/newTask") ?
                                <Button type="primary" icon="edit" style={{ marginLeft: 8 }}
                                    onClick={() => { this.props.history.push('/newTask'); }}>
                                    新建任务
                        </Button> : ''}
                        </div>}>

                    <Table
                        dataSource={this.state.tableData}
                        columns={columns}
                        size="small"
                        loading={this.state.loading}
                        onChange={() => this.handleChange}
                        pagination={{
                            current: _current, pageSize: _pageSize, total: this.state.total,
                            onChange: (page, pageSize) => {
                                //页码改变的回调，参数是改变后的页码及每页条数	Function(page, pageSize)
                                this.updata(pageSize, page, querys);
                            }
                        }}
                    />
                </Card>
            </div>
        )
    }
}
