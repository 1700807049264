import React, { memo } from 'react';
import { Button, Input, AutoComplete, Icon } from 'antd';
const SearchInput = memo(

    ({ dataSource = [], graph, onChange }) => {
        const _dataSource = dataSource.map((item) => (item.dev_ip));
        let flag = "";
        return <div>
            <AutoComplete
                allowClear
                //   style={{ width: 200 }}
                dataSource={_dataSource}
                placeholder="设备IP地址"
                filterOption={(inputValue, option) =>
                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(val) => {
                    // const node = dataSource.filter((item) => (item.dev_ip === val));
                    console.log(val);
                    // 动画地移动，并配置动画
                    graph.focusItem(val, true, {
                        easing: 'easeCubic',
                        duration: 400,
                    });
                    graph.setItemState(val, 'selected', true);
                    flag = val;
                    const selNode = dataSource.filter((item) => (item.dev_ip === val));
                    onChange(selNode[0] || {});
                }}
                onChange={(val) => {
                    if (!val) {
                        // 查询所有选中的元素
                        const nodes = graph.findAllByState('node', 'selected');
                        nodes.map((n) => {
                            graph.setItemState(n, 'selected', false);
                        })
                    }
                }}
            >
                <Input suffix={<Icon type="search" className="certain-category-icon" />} />
            </AutoComplete>
        </div>
    }
);

export default SearchInput;
